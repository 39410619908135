import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';

import { BrowserThrottlingCompositeDetector } from '../browser-throttling-composite-detector';

/**
 * @description This component is responsible displaying browser throttling information.
 * It also plays audio sounds when the browser is engages / disengages the throttling.
 */
@Component({
  selector: 'browser-throttling-detector',
  templateUrl: './browser-throttling-detector.component.html',
  styleUrls: ['./browser-throttling-detector.component.scss'],
})
export class BrowserThrottlingDetectorComponent implements OnInit, OnDestroy {
  isThrottling: boolean = false;
  throttlingDuration: number | null = null;
  isAudioEnabled: boolean = false;
  private subscription: Subscription;
  private currentAudio: HTMLAudioElement | null = null;
  private isPlayingSubject = new BehaviorSubject<boolean>(false);
  private audioQueue = new Subject<string>();
  private destroy$ = new Subject<void>();

  private readonly ENGAGED_AUDIO: string =
    'assets/sounds/bt/ElevenLabs_2024-09-21T13_47_12_Charlotte_pre_s50_sb75_se0_b_m2_warning_app_throtttled.mp3';
  private readonly DISENGAGED_AUDIO: string =
    'assets/sounds/bt/ElevenLabs_2024-09-21T13_47_37_Charlotte_pre_s50_sb75_se0_b_m2_info_throttle_disengaged.mp3';

  constructor(
    private browserThrottlingCompositeDetector: BrowserThrottlingCompositeDetector,
  ) {
    this.setupAudioQueue();
  }

  ngOnInit(): void {
    this.subscription =
      this.browserThrottlingCompositeDetector.isThrottling$.subscribe(
        (isThrottling: boolean) => {
          if (isThrottling && !this.isThrottling) {
            this.queueAudio(this.ENGAGED_AUDIO);
          } else if (!isThrottling && this.isThrottling) {
            this.queueAudio(this.DISENGAGED_AUDIO);
          }
          this.isThrottling = isThrottling;
        },
      );
    this.browserThrottlingCompositeDetector
      .getThrottlingDuration$()
      .subscribe((duration: number) => {
        this.throttlingDuration = duration;
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupAudioQueue(): void {
    this.audioQueue
      .pipe(
        concatMap(
          (audioSrc: string): Promise<void> => this.playAudio(audioSrc),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private queueAudio(audioSrc: string): void {
    if (!this.isAudioEnabled) {
      return;
    }
    this.audioQueue.next(audioSrc);
  }

  private playAudio(audioSrc: string, volume: number = 0.8): Promise<void> {
    return new Promise((resolve: () => void) => {
      if (!this.isAudioEnabled) {
        resolve();
        return;
      }

      const audio: HTMLAudioElement = new Audio(audioSrc);
      audio.volume = volume;

      audio.onended = (): void => {
        this.isPlayingSubject.next(false);
        this.currentAudio = null;
        resolve();
      };

      audio
        .play()
        .then((): void => {
          this.currentAudio = audio;
          this.isPlayingSubject.next(true);
        })
        .catch((error) => {
          console.warn('Failed to play audio:', error);
          this.isPlayingSubject.next(false);
          resolve(); // Resolve even on error to continue the queue
        });
    });
  }
}
