import { Injectable } from '@angular/core';

import { Id } from 'communication';

import { LogConfig, Loggable } from '../../../../src/Loggable';
import { ConnectionWebSocketService } from './connection.web-socket.service';
import {
  IWSListener,
  IWSListeners,
  IWSListenerUnsubscribe,
  RITHMIC_INFRA_TYPE,
  WSEventType,
} from './types';
import { IWebSocketService } from './web-socket-service.interface';

/**
 * `WebSocketService` is a registry that maps Connection IDs to WebSockets
 */
@Injectable()
export class WebSocketService extends Loggable implements IWebSocketService {
  private _map = new Map<Id, ConnectionWebSocketService>();
  private _listeners: IWSListeners = Object.values(WSEventType).reduce(
    (accum, event) => {
      accum[event] = new Set();
      return accum;
    },
    {},
  ) as IWSListeners;

  getWebSocket(
    id: Id,
    infraType: RITHMIC_INFRA_TYPE = RITHMIC_INFRA_TYPE.TICKER_PLANT,
  ): ConnectionWebSocketService | undefined {
    if (infraType) {
      return this._map.get(`${id}-${infraType}`);
    }
    return this._map.get(id);
  }
  getTickerPlantWebSocket(id: Id): ConnectionWebSocketService | undefined {
    return this.getWebSocket(id, RITHMIC_INFRA_TYPE.TICKER_PLANT);
  }
  getOrderPlantWebSocket(id: Id): ConnectionWebSocketService | undefined {
    return this.getWebSocket(id, RITHMIC_INFRA_TYPE.ORDER_PLANT);
  }

  send(data: any = {}, connectionId: Id): void {
    const { infraType } = data;
    let service: ConnectionWebSocketService | undefined;
    if (connectionId.toString().includes(infraType)) {
      service = this._map.get(connectionId);
    } else {
      service = this.getWebSocket(connectionId, infraType);
    }
    if (!service) {
      console.warn(
        `No WebSocketService found for connectionId: ${connectionId} (${infraType})`,
        this._map,
      );
      return;
    }

    service.send(data, connectionId);
  }

  startDraining(connectionId: Id): void {
    const service = this._map.get(connectionId);
    if (!service) return;

    service.startDraining(connectionId);
  }

  on(
    type: WSEventType,
    listener: IWSListener,
    callerClass?: string,
  ): IWSListenerUnsubscribe {
    this._listeners[type].add(listener);

    for (const [key, service] of this._map) {
      service.on(type, listener);
    }

    return () => {
      this._listeners[type].delete(listener);
      for (const [key, service] of this._map) {
        service.off(type, listener);
      }
    };
  }

  register(id: Id, service: any) {
    this._map.set(id, service);

    for (const type in this._listeners) {
      if (this._listeners.hasOwnProperty(type)) {
        const listeners = this._listeners[type];

        for (const listener of listeners) service.on(type, listener);
      }
    }
  }

  unregister(id: Id, service: any) {
    if (this._map.get(id) === service) this._map.delete(id);
  }
}
