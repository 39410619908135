// import { Memoize } from "typescript-memoize";
import { IHashCode } from '../hash-map';
import { INVERSE_KEY_CODE_MAP, KEY_CODE_MAP } from '../keycode-map';
import { KeyCode } from '../keycode.enum';
import { KeyCodeUtils } from '../keycode.utils';

export interface IKeyBindingPartDTO {
  keyCode: number;
}

export class KeyBindingPart implements IHashCode {
  get _hmuid_() {
    return this.hashCode();
  }

  constructor(private readonly _keyCode: number) {}

  toUIString() {
    return KeyCodeUtils.toString(KEY_CODE_MAP[this._keyCode]);
  }

  toDTO(): IKeyBindingPartDTO {
    return {
      keyCode: this._keyCode,
    };
  }

  // @Memoize()
  hashCode() {
    return `${this._keyCode}`;
  }

  equals(part: KeyBindingPart) {
    return part._keyCode === this._keyCode;
  }

  static fromKeyboardEvent(event: KeyboardEvent) {
    return new KeyBindingPart(event.keyCode);
  }

  static fromDTO(dto: IKeyBindingPartDTO) {
    return new KeyBindingPart(dto.keyCode);
  }

  static fromKeyCode(keycode: KeyCode) {
    return new KeyBindingPart(INVERSE_KEY_CODE_MAP[keycode]);
  }
}
