<form [formGroup]="form">
  <div class="text-left" *ngIf="blankOption">
    <small class="text-muted px-2 mb-1">Start with</small>
    <nz-select
      [nzOptionHeightPx]="23"
      class="custom-form-control"
      [(ngModel)]="base"
      formControlName="base"
    >
      <nz-option
        nzValue="blank"
        [nzLabel]="blankOption"
        [nzCustomContent]="true"
      >
        <span class="option-label">{{ blankOption }}</span>
      </nz-option>
      <nz-option
        *ngFor="let option of options"
        [nzValue]="option.value"
        [nzLabel]="option.label"
        [nzCustomContent]="true"
      >
        <span class="option-label">{{ option.label }}</span>
      </nz-option>
    </nz-select>
  </div>
  <div class="mt-3 text-left">
    <small class="text-muted px-2 mb-1">{{ name }}</small>
    <input
      nzSize="small"
      class="custom-form-control ant-input"
      type="text"
      nz-input
      formControlName="name"
    />
  </div>
</form>

<div *nzModalFooter>
  <button
    [disabled]="form.invalid"
    class="ant-btn ant-btn-primary"
    (click)="handleOk()"
  >
    Save
  </button>
  <button class="ant-btn modal-btn" (click)="handleCancel()">Cancel</button>
</div>
