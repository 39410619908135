<div class="d-flex version-details" *ngIf="versionDetails">

  <span class="version" *ngIf="versionDetails.webAppVersion">
    Web App Version:
    <span class="value">
    {{ versionDetails.webAppVersion.version }}<span *ngIf="versionDetails.webAppVersion.hash" class="hash">-{{ versionDetails.webAppVersion.hash }}</span></span>
  </span>

  <span class="version" *ngIf="versionDetails.desktopAppVersion">
    Desktop App Version:
    <span class="value">
      {{ versionDetails.desktopAppVersion.version }}<span *ngIf="versionDetails.desktopAppVersion.hash" class="hash">-{{ versionDetails.desktopAppVersion.hash }}</span></span>
  </span>

  <ng-container
    *ngIf="versionDetails.dependencyVersions"
  >
    <ng-container *ngFor="let dependency of versionDetails.getDependencies()">
      <span class="version">
        {{ dependency.name }}:
        <span class="value">{{ dependency.version }}</span>
      </span>
    </ng-container>


  </ng-container>

</div>
