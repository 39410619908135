import { IInstrument } from '../models';
import { DataFeed } from './data-feed';

export interface VolumeData {
  volume: number;
  timestamp: number;
  instrument: IInstrument;
}

export abstract class VolumeDataFeed extends DataFeed<VolumeData> {}
