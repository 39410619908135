import { footprintConfig } from '../fields';
import { Indicator } from './Indicator';

export class FootprintSettings extends Indicator {
  name = 'Footprint';
  config = footprintConfig;

  protected _mapGetSettings(settings: any): any {
    if (settings?.deltaImbalance?.threshold) {
      settings.deltaImbalance.threshold *= 100;
    }

    return settings;
  }

  protected _mapSetSettings(settings: any): any {
    if (settings?.deltaImbalance?.threshold) {
      settings.deltaImbalance.threshold /= 100;
    }

    return settings;
  }
}
