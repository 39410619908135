<div class="inner-buttons">
  <ng-container *ngFor="let item of amountButtons">
    <button
      (click)="setValue(item)"
      (contextmenu)="$event.preventDefault(); editButton(item)"
      *ngIf="!item.edit && !item.black"
      nz-button
    >
      {{ item.value }}
    </button>
    <nz-input-number
      #input
      [class.black-input]="item.black"
      (ngModelChange)="updateValue($event)"
      [ngModel]="currentValue"
      [nzMin]="0"
      (keydown.enter)="$event.preventDefault(); input.blur()"
      *ngIf="item.black"
    >
    </nz-input-number>

    <nz-input-number
      *ngIf="item.edit"
      [nzMin]="0"
      tabindex="1"
      (keyup.enter)="updateItem(item); item.edit = false"
      (focusout)="updateItem(item); item.edit = false"
      [nzAutoFocus]="true"
      (nzBlur)="updateItem(item); item.edit = false"
      [(ngModel)]="item.value"
    >
    </nz-input-number>
  </ng-container>
</div>
