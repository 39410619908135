import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Injectable()
export abstract class AppConnectivityObserver {
  private onlineEvents: Observable<Event>;
  private offlineEvents: Observable<Event>;
  private connectivitySubscriptions: Subscription[];

  protected constructor() {
    this.detectConnectivityChanges();
  }

  protected destroy() {
    this.connectivitySubscriptions.forEach((subscription) =>
      subscription.unsubscribe(),
    );
  }

  protected detectConnectivityChanges() {
    this.onlineEvents = fromEvent(window, 'online');
    this.offlineEvents = fromEvent(window, 'offline');
    this.connectivitySubscriptions = [];
    this.connectivitySubscriptions.push(
      this.onlineEvents.subscribe((event) => this.onOnline(event)),
      this.offlineEvents.subscribe((event) => this.onOffline(event)),
    );
  }

  protected async onOffline(event: Event) {}

  protected async onOnline(event: Event) {}
}
