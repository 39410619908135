import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingOutline } from '@ant-design/icons-angular/icons';
import { NzIconModule } from 'ng-zorro-antd';

import { LoaderComponent } from './loader.component';

@NgModule({
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
  imports: [NzIconModule.forRoot([LoadingOutline]), CommonModule],
  entryComponents: [LoaderComponent],
})
export class LoaderModule {}
