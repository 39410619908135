import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import * as Sentry from '@sentry/angular';

export enum LogLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
}

export interface AppHealthLogEntry {
  timestamp: Date;
  level: LogLevel;
  message: string;
  context?: any;
}

/**
 * @description A service for logging application events and errors.
 *
 * This service provides methods for logging messages at different levels
 * (info, warning, error, and critical). It maintains a log history and
 * allows for retrieving and clearing the log entries.
 *
 * Log entries can be printed to the console, and errors can be automatically
 * reported to Sentry.
 */
@Injectable({
  providedIn: 'root',
})
export class AppHealthLogger {
  private _autoReportErrors: boolean = false;
  private _printToConsole: boolean = false;
  private logEntries: AppHealthLogEntry[] = [];
  private logSubject: BehaviorSubject<AppHealthLogEntry[]> =
    new BehaviorSubject<AppHealthLogEntry[]>([]);

  get autoReportErrors(): boolean {
    return this._autoReportErrors;
  }
  set autoReportErrors(value: boolean) {
    this._autoReportErrors = value;
  }
  get printToConsole(): boolean {
    return this._printToConsole;
  }
  set printToConsole(value: boolean) {
    this._printToConsole = value;
  }

  constructor() {}

  public log(level: LogLevel, message: string, context?: any): void {
    const entry: AppHealthLogEntry = {
      timestamp: new Date(),
      level,
      message,
      context,
    };

    this.logEntries.push(entry);
    this.logSubject.next(this.logEntries);

    if (this._printToConsole) {
      this.consoleLog(entry);
    }

    if (
      this._autoReportErrors &&
      (level === LogLevel.ERROR || level === LogLevel.CRITICAL)
    ) {
      this.reportToSentry(entry);
    }
  }

  private consoleLog(entry: AppHealthLogEntry): void {
    const { timestamp, level, message, context } = entry;
    console.log(
      `[${timestamp.toISOString()}] ${level}: ${message}`,
      context || '',
    );
  }

  private reportToSentry(entry: AppHealthLogEntry): void {
    Sentry.captureEvent({
      message: entry.message,
      level: entry.level.toLowerCase() as Sentry.SeverityLevel,
      extra: {
        context: entry.context,
      },
    });
  }

  public getLogEntries(): Observable<AppHealthLogEntry[]> {
    return this.logSubject.asObservable();
  }

  getAllLogs(): AppHealthLogEntry[] {
    return this.logEntries;
  }

  public clearLogs(): void {
    this.logEntries = [];
    this.logSubject.next(this.logEntries);
  }

  // Convenience methods for different log levels
  public info(message: string, context?: any): void {
    this.log(LogLevel.INFO, message, context);
  }

  public warning(message: string, context?: any): void {
    this.log(LogLevel.WARNING, message, context);
  }

  public error(message: string, context?: any): void {
    this.log(LogLevel.ERROR, message, context);
  }

  public critical(message: string, context?: any): void {
    this.log(LogLevel.CRITICAL, message, context);
  }
}
