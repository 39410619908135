<div>
  <div class="title">
    {{ order.side }} {{ order.type }}

    <ng-container *ngIf="displayedQuantity; else quantityTemplate">{{ displayedQuantity }}</ng-container>
    <ng-template #quantityTemplate>{{ order.quantity }}</ng-template>

    {{ instrument?.productCode }} {{ instrument?.instrumentTimePeriod }}
    at
    <ng-container *ngIf="order.limitPrice != null">
      <ng-container *ngIf="showPricesDesc">Limit Price:</ng-container>
      {{ limitPrice }}
    </ng-container>
    <ng-container *ngIf="order.stopPrice != null">
      <ng-container *ngIf="showPricesDesc">Stop Price:</ng-container>
      {{ stopPrice }}</ng-container
    >
  </div>
  <label class="checkbox" nz-checkbox [(ngModel)]="dontShowAgain"
    >Don't show it again</label
  >
</div>
<div class="footer">
  <button
    (click)="submit()"
    class="confirm-button {{ order.side.toLowerCase() }}"
  >
    {{ prefix }} {{ order.side }}
  </button>
  <button (click)="cancel()" class="cancel-button">No</button>
</div>
