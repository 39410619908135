import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { rti } from './messages-js/otps_proto_pool';

/**
 * @description Service for working with Market Data Infrastructure (Ticker Plant).
 * Market data connection provides real-time market data services.
 * @see R API+.NET - Programmers' Guide - Connections: Connect Points
 * @see R Protocol API 1.2 Templates Specific to Market Data Infrastructure.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RProtocolTickerPlantService {
  /**
   * @description Event triggered when information for a new symbol is being requested.
   * @example 'MNQM4'
   */
  public $requestSearchSymbols: Subject<string> = new Subject();

  /**
   * @description Event which should be triggered when information for
   * a single new symbol has been received.
   */
  public $responseSearchSymbols: Subject<rti.ResponseSearchSymbols> =
    new Subject();

  /**
   * @description Event which should be triggered when information for all currently
   * requested symbols has been received. At the moment Ticker Plant Web Socket API does not
   * have an official way of notyfing about the completion of all requested symbol data.
   */
  public $responseSearchSymbolsCompleted: Subject<void> = new Subject();

  /**
   * @description Stores all symbols data.
   */
  public $symbols: BehaviorSubject<rti.ResponseSearchSymbols[]> =
    new BehaviorSubject([]);

  /**
   * @description Symbols which were requested, but the data is not available yet.
   * @example ['NQM4', 'MNQM4']
   * @example ['ESM4']
   */
  public $currentlyFetchedSymbols: BehaviorSubject<string[]> =
    new BehaviorSubject([]);

  constructor() {
    this.$requestSearchSymbols
      .pipe(untilDestroyed(this))
      .subscribe((symbol: string): void => {
        this._updateCurrentlyFetchedSymbols(symbol);
      });
    this.$responseSearchSymbols
      .pipe(untilDestroyed(this))
      .subscribe((symbolItem: rti.ResponseSearchSymbols): void => {
        this._updateSymbolsList(symbolItem);
      });
  }

  private _updateCurrentlyFetchedSymbols(symbol: string): void {
    const currentlyFetchedSymbols: string[] =
      this.$currentlyFetchedSymbols.getValue();

    if (currentlyFetchedSymbols.includes(symbol)) {
      return;
    }

    this.$currentlyFetchedSymbols.next([
      ...this.$currentlyFetchedSymbols.getValue(),
      symbol,
    ]);
  }

  private _removeFromCurrentlyFetchedSymbols(fetchedSymbol: string): void {
    const valueWithoutNoLongerFetchedSymbol: string[] =
      this.$currentlyFetchedSymbols
        .getValue()
        .filter(
          (currentlyFetchedSymbol: string): boolean =>
            currentlyFetchedSymbol !== fetchedSymbol,
        );

    this.$currentlyFetchedSymbols.next(valueWithoutNoLongerFetchedSymbol);
  }

  private _updateSymbolsList(symbolItem: rti.ResponseSearchSymbols): void {
    let symbols: rti.ResponseSearchSymbols[];

    /***
     * Sometimes R-Protocol web sockets send items which are not real symbol items.
     * For this reason we need to skip those items.
     */
    if (!symbolItem.symbolName) {
      return;
    }

    symbols = this.$symbols.getValue();

    if (symbols.includes(symbolItem)) {
      return;
    }

    this.$symbols.next([...symbols, symbolItem]);
    this._removeFromCurrentlyFetchedSymbols(symbolItem.symbol);

    if (!this.$currentlyFetchedSymbols.getValue().length) {
      this.$responseSearchSymbolsCompleted.next();
    }
  }
}
