<window-header [window]="this"></window-header>
<div class="settings">
  <nz-layout class="nz-layout-tabs">
    <nz-content class="nz-content-tabs">
      <ul class="tabs">
        <li
          [class.active]="activeTab === tab"
          *ngFor="let tab of tabs"
          (click)="setTab(tab)"
        >
          {{ tab }}
        </li>
      </ul>
    </nz-content>
    <nz-footer class="version">
      <span
        >Version:
        <span class="value"
          >{{ appVersion.version
          }}<span *ngIf="appVersion && appVersion.hash" class="hash"
            >-{{ appVersion.hash }}</span
          ></span
        ></span
      >
    </nz-footer>
  </nz-layout>

  <div class="tab-content" *ngIf="activeTab === TABS.GENERAL">
    <ul>
      <li>
        <nz-radio-group
          [nzSize]="'large'"
          [(ngModel)]="autoSaveSetting"
          (ngModelChange)="switchAutoSave($event)"
        >
          <div>
            <label nz-radio [nzValue]="saveDelayValues.fiveMin"
              >Auto save settings every 5 minutes</label
            >
          </div>
          <div>
            <label nz-radio [nzValue]="saveDelayValues.manualSave"
              >Manual save</label
            >
          </div>
        </nz-radio-group>
      </li>
    </ul>
    <div class="{{ activeTab }} mt-4 AccountDetails">
      <formly-form
        [form]="form"
        [(model)]="settings"
        [fields]="selectedConfig"
        (modelChange)="saveSettingsGeneral($event)"
      >
      </formly-form>
    </div>
  </div>

  <div class="tab-content" *ngIf="activeTab === TABS.HOTKEYS">
    <table class="table">
      <thead>
        <tr>
          <th>Functions</th>
          <th>
            <div class="d-flex align-items-center justify-content-between">
              Keys
              <button
                class="clear-all"
                (click)="clearAll()"
                nz-button
                nzType="link"
              >
                Clear All
              </button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of hotkeys; let i = index">
          <td class="hotkey-name">
            <span class="d-flex align-items-center h-100">{{
              entry.name
            }}</span>
          </td>
          <td class="hotkey-reader">
            <hotkey-input
              [value]="entry.binding"
              (valueChange)="updateHotkey($event, entry.key)"
            ></hotkey-input>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <lib-sound-tab-setting
    class="tab-content sounds"
    *ngIf="activeTab === TABS.SOUNDS"
  ></lib-sound-tab-setting>

  <div class="tab-content" *ngIf="activeTab === TABS.ADMIN">
    <div class="{{ activeTab }} mt-4 settings-content">
      <section>
        <formly-form
          [form]="form"
          [(model)]="settings"
          [fields]="selectedConfig"
          (modelChange)="handleAdminSettingsChange($event)"
        >
        </formly-form>
      </section>
    </div>
  </div>
</div>
