import { Context } from "./context";
import { Subscription } from "./subscription";

export class SubscriptionManager {

    private readonly _subscriptions: Array<Subscription> = [];

    subscribe(subscription: Subscription) {
        this._subscriptions.push(subscription);
        return {
            unsubscribe: () => {
                const index = this._subscriptions.indexOf(subscription);
                if (index === -1)
                    return;
                this._subscriptions.splice(index, 1);
            }
        }
    }

    handle(context: Context, command: string) {
        this._subscriptions.forEach(s => {
            if ((s.context == null || s.context.equals(context)) && (s.command == null || s.command === command)) {
                try { s.handle(command); } catch(error) { console.error(error) }
            }
        });
    }

}