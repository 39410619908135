import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WindowHeaderComponent } from './window-header.component';

@NgModule({
  declarations: [WindowHeaderComponent],
  imports: [CommonModule],
  exports: [WindowHeaderComponent],
})
export class WindowHeaderModule {}
