<window-header [window]="this"></window-header>
<div
  class="d-flex flex-column h-100 p-2 pt-3"
  [class.loading-block]="loading"
  [class.block-pointer]="loading"
>
  <div>
    <div class="d-flex">
      <div class="pr-2 session-select">
        <data-select
          label="Name"
          [default]="blankSession"
          [value]="item.id"
          [editCallback]="editSession"
          [repository]="repository"
          [withActions]="true"
          [showTooltip]="true"
          (handleChange)="handleSessionChange($event)"
        ></data-select>
      </div>
      <div class="pl-2 time-zones flex-grow-1">
        <data-select
          dropdownClassName="timezones-select"
          label="Time Zone"
          [value]="item.timezoneId"
          [finder]="timezoneFinder"
          [repository]="timezonesRepository"
          (handleChange)="handleTimezoneChange($event)"
        ></data-select>
      </div>
    </div>
  </div>
  <div class="divider text-muted"></div>
  <cdk-virtual-scroll-viewport itemSize="" class="flex-grow-1">
    <table class="data-table mb-2">
      <tr>
        <th>Start Day</th>
        <th>Start Time</th>
        <th></th>
        <th>End Day</th>
        <th>End Time</th>
        <th></th>
      </tr>
      <tr *ngFor="let workingTime of item.workingTimes">
        <td>
          <nz-select
            class="custom-form-control"
            [nzOptionHeightPx]="24"
            [(ngModel)]="workingTime.startDay"
          >
            <nz-option
              *ngFor="let day of days; let i = index"
              [nzValue]="i"
              [nzLabel]="day"
            ></nz-option>
          </nz-select>
        </td>
        <td>
          <time-select
            [value]="workingTime.startTime"
            (valueChange)="workingTime.startTime = $event"
          ></time-select>
        </td>
        <td></td>
        <td>
          <nz-select
            class="custom-form-control"
            [nzOptionHeightPx]="24"
            [(ngModel)]="workingTime.endDay"
          >
            <nz-option
              *ngFor="let day of days; let i = index"
              [nzValue]="i"
              [nzLabel]="day"
            ></nz-option>
          </nz-select>
        </td>
        <td>
          <time-select
            [value]="workingTime.endTime"
            [step]="5"
            (valueChange)="workingTime.endTime = $event"
          ></time-select>
        </td>
        <td>
          <span class="link" (click)="deleteWorkingTime(workingTime)">
            <i class="icon-delete font-size-inherit"></i>
          </span>
        </td>
      </tr>
    </table>
    <div class="d-flex">
      <button
        nz-button
        nzType="default"
        nzSize="small"
        class="px-4 mr-2 bg-lighter border-lighter text-white"
        (click)="createWorkingTime()"
      >
        Add
      </button>
      <button
        nz-button
        nzType="default"
        nzSize="small"
        class="px-4 mr-2 bg-lighter border-lighter text-white"
        (click)="createMissingWorkingTime()"
      >
        Add Monday through Friday
      </button>
    </div>
  </cdk-virtual-scroll-viewport>
  <div class="d-flex">
    <button
      nz-button
      [disabled]="!item?.id"
      nzType="primary"
      nzSize="small"
      class="px-5 mr-2 bg-darker"
      (click)="save()"
    >
      Save
    </button>
    <button
      nz-button
      nzType="primary"
      nzSize="small"
      class="px-5 mr-auto bg-darker"
      (click)="saveAs()"
    >
      Save As
    </button>
    <button
      nz-button
      nzType="primary"
      nzSize="small"
      class="px-5 mr-2 bg-darker"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      nz-button
      nzType="primary"
      nzSize="small"
      class="px-5"
      (click)="ok()"
    >
      Ok
    </button>
  </div>
</div>
<ng-template #nameForm>
  <div class="text-left">
    <small class="text-muted px-2 mb-1">Name</small>
    <nz-form-control class="custom-form-control">
      <input nz-input type="text" [(ngModel)]="sessionName" />
    </nz-form-control>
  </div>
</ng-template>
<ng-template #success>
  <div class="text-white">
    <i class="icon-check circle-icon text-success font-size-inherit mb-3"></i>
    <div>Session was successfully saved</div>
  </div>
</ng-template>

<ng-template #error>
  <div class="text-white">
    <i
      class="icon-close-window circle-icon text-danger font-size-inherit mb-3"
    ></i>
    <div>Default Sessions cannot be modified.</div>
    <div>Add a New Session for custom times.</div>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="isVisibleSuccess"
  nzTitle="Saving"
  [nzFooter]="null"
  (nzOnCancel)="handleSuccessCancel()"
>
  <div class="text-white">
    <i class="icon-check circle-icon text-success font-size-inherit mb-3"></i>
    <div>Session was successfully saved</div>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="isVisibleError"
  nzTitle="Saving"
  [nzFooter]="null"
  (nzOnCancel)="handleErrorCancel()"
>
  <div class="text-white">
    <i
      class="icon-close-window circle-icon text-danger font-size-inherit mb-3"
    ></i>
    <div>Default Sessions cannot be modified.</div>
    <div>Add a New Session for custom times.</div>
  </div>
</nz-modal>
