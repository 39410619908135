import { IHashCode } from './hash-map';

export class HashSet<T extends IHashCode> implements Set<T> {
  size: number;
  [Symbol.iterator](): IterableIterator<T> {
    throw new Error('Method not implemented.');
  }
  entries(): IterableIterator<[T, T]> {
    throw new Error('Method not implemented.');
  }
  [Symbol.toStringTag]: string;

  private readonly _map: Map<string, T> = new Map();
  private readonly _set: Set<string> = new Set();

  add(value: T): this {
    const hash = value.hashCode();
    this._set.add(hash);
    this._map.set(hash, value);
    return this;
  }
  clear(): void {
    this._set.clear();
    this._map.clear();
  }

  delete(value: T): boolean {
    const hash = value.hashCode();
    this._map.delete(hash);
    return this._set.delete(hash);
  }

  forEach(
    callbackfn: (value: T, value2: T, set: Set<T>) => void,
    thisArg?: any,
  ): void {
    throw new Error('Method not implemented.');
  }

  has(value: T): boolean {
    return this._set.has(value.hashCode());
  }

  keys(): IterableIterator<T> {
    throw new Error('Method not implemented.');
  }
  values(): IterableIterator<T> {
    return this._map.values();
  }
}
