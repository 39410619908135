import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyBinding, SettingsKeyboardListener } from '@tradrr-app/keyboard';
import {
  FieldConfig,
  FieldType,
  getCheckboxes,
  getSwitch,
  IFieldConfig,
} from 'dynamic-form';
import { ILayoutNode, LayoutNode, LayoutNodeEvent } from 'layout';
import * as clonedeep from 'lodash.clonedeep';
import { DebugFlagService } from 'projects/performance-tools/src/lib/debug-flag.service';
import { Themes, ThemesHandler } from 'themes';

import { AppVersion } from '../../../src/app/app.config';
import { HotkeyEvents, SettingsService } from './settings.service';
import { SettingsData } from './types';

export enum SAVE_DALEY {
  FIVE_MIN = 300000,
  AUTO_SAVE = 'AUTO_SAVE',
  MANUAL_SAVE = 'MANUAL_SAVE',
}

enum TABS {
  GENERAL = 'general',
  HOTKEYS = 'hotkeys',
  SOUNDS = 'sounds',
  ADMIN = 'admin',
}

const hotkeyStringRepresentation = {
  [HotkeyEvents.SavePage]: 'Save All Settings',
  // [HotkeyEvents.CenterAllWindows]: 'Center All Windows',
  [HotkeyEvents.OpenOrderTicket]: 'Open Order Ticket',
  [HotkeyEvents.OpenTradingDom]: 'Open Trading DOM',
  [HotkeyEvents.OpenChart]: 'Open New Chart',
  [HotkeyEvents.OpenConnections]: 'Open Connections Window',
  [HotkeyEvents.LockTrading]: 'Lock/Unlock Trading',
};

export const generalFields: IFieldConfig[] = [
  new FieldConfig({
    fieldGroupClassName: '',
    key: 'general',
    fieldGroup: [
      getCheckboxes({
        checkboxes: [
          { label: 'Hide Account Name', key: 'hideAccountName' },
          { label: 'Hide From Left', key: 'hideFromLeft' },
        ],
        label: 'Account Details',
        additionalFields: [
          {
            templateOptions: { min: 0, label: 'Account Digits To Hide' },
            key: 'digitsToHide',
            type: FieldType.Number,
          },
          {
            templateOptions: { label: 'Hide From Right' },
            key: 'hideFromRight',
            type: FieldType.Checkbox,
          },
        ],
        fieldGroupClassName: 'm-t-0',
        extraConfig: {
          className: 'field-item',
          fieldGroupClassName: 'd-flex flex-wrap two-rows-mt-0 p-x-7',
        },
      }),
    ],
  }),
];

const adminFields: IFieldConfig[] = [
  new FieldConfig({
    className: '',
    fieldGroupClassName: '',
    key: 'admin',
    fieldGroup: [
      new FieldConfig({
        key: 'betaFeatures',
        label: 'Beta Features',
        className: 'settings-content__section',
        fieldGroupClassName: 'settings-content__section-aaa',
        fieldGroup: [
          new FieldConfig({
            key: 'chartIndicators',
            className: 'settings-content__fieldset',
            fieldGroupClassName: 'settings-content__switch-list',
            label: 'Chart Indicators',
            fieldGroup: [
              getSwitch('zigZag', 'ZigZag'),
              getSwitch('zigZagOscillator', 'ZigZag Oscillator'),
            ],
          }),
          new FieldConfig({
            key: 'chartIntervals',
            className: 'settings-content__fieldset',
            fieldGroupClassName: 'settings-content__switch-list',
            label: 'Chart Intervals',
            fieldGroup: [
              getSwitch('week', 'Week'),
              getSwitch('month', 'Month'),
              getSwitch('year', 'Year'),
              getSwitch('range', 'Range'),
              getSwitch('renko', 'Renko'),
              getSwitch('volume', 'Volume'),
              getSwitch('ticks', 'Ticks'),
            ],
          }),
          new FieldConfig({
            key: 'chartPeriodsToLoad',
            className: 'settings-content__fieldset',
            fieldGroupClassName: 'settings-content__switch-list',
            label: 'Chart Periods to Load',
            fieldGroup: [getSwitch('years', 'Years')],
          }),
          new FieldConfig({
            key: 'chartBarStyles',
            className: 'settings-content__fieldset',
            fieldGroupClassName: 'settings-content__switch-list',
            label: 'Chart Bar Style',
            fieldGroup: [
              getSwitch('renko', 'Renko'),
              getSwitch('lineBreak', 'Line Break'),
              getSwitch('kagi', 'Kagi'),
              getSwitch('pointAndFigure', 'Point and Figure'),
            ],
          }),
          new FieldConfig({
            key: 'components',
            className: 'settings-content__fieldset',
            fieldGroupClassName: 'settings-content__switch-list',
            label: 'Components',
            fieldGroup: [getSwitch('orderTicket', 'Order Ticket')],
          }),
          new FieldConfig({
            key: 'dom',
            className: 'settings-content__fieldset',
            fieldGroupClassName: 'settings-content__switch-list',
            label: 'DOM',
            fieldGroup: [getSwitch('bracketButton', 'Bracket button')],
          }),
          new FieldConfig({
            key: 'domSettings',
            className: 'settings-content__fieldset',
            fieldGroupClassName: 'settings-content__switch-list',
            label: 'DOM Settings',
            fieldGroup: [
              getSwitch(
                'closeOutstandingOrders',
                'Close Outstanding Orders When Position is Closed',
              ),
              getSwitch('resetOnNewSession', 'Reset on new Session'),
              getSwitch('useCustomTickSize', 'Use Custom Tick Size'),
            ],
          }),
        ],
      }),
    ],
  }),
];

export const SettingsConfig = {
  [TABS.GENERAL]: generalFields,
  [TABS.ADMIN]: adminFields,
};

export interface SettingsComponent extends ILayoutNode {}

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
@UntilDestroy()
@LayoutNode()
export class SettingsComponent implements OnInit {
  autoSaveSetting: number | string;
  isKeyboardRecording = false;
  keyboardListener = new SettingsKeyboardListener();
  saveDelayValues = {
    fiveMin: SAVE_DALEY.FIVE_MIN,
    manualSave: SAVE_DALEY.MANUAL_SAVE,
  };

  settings: SettingsData;
  oldAdminSettings: SettingsData['admin'];
  hotkeysEvents = Object.values(HotkeyEvents);
  hotkeys: { name: string; key: string; binding: KeyBinding }[] = [];

  themes = [Themes.Dark, Themes.Light];

  tabs = [TABS.GENERAL, TABS.HOTKEYS, TABS.SOUNDS];

  activeTab: TABS;

  TABS = TABS;
  form: FormGroup;
  formValueChangesSubscription: Subscription;

  selectedConfig: any;
  private settingsConfig = SettingsConfig;

  get currentTheme() {
    return this.settings.theme as Themes;
  }

  set currentTheme(theme: Themes) {
    if (this.currentTheme !== theme) this._settingsService.changeTheme(theme);
  }

  constructor(
    public themesHandler: ThemesHandler,
    private _settingsService: SettingsService,
    public appVersion: AppVersion,
    public debugFlagService: DebugFlagService,
  ) {
    this.setTabTitle('Settings');
    this.setTabIcon('icon-setting-gear');
    this.setIsSettings(true);
  }

  ngOnInit(): void {
    this.setTab(this.TABS.GENERAL);
    this._settingsService.settings.pipe(untilDestroyed(this)).subscribe((s) => {
      this.oldAdminSettings = clonedeep(this.settings?.admin);
      this.settings = { ...s };
      this._setHotkeys();
      if (s.autoSave && s.autoSaveDelay) this.autoSaveSetting = s.autoSaveDelay;
      else if (s.autoSave) this.autoSaveSetting = SAVE_DALEY.AUTO_SAVE;
      else this.autoSaveSetting = SAVE_DALEY.MANUAL_SAVE;
    });
    this.oldAdminSettings = clonedeep(this.settings?.admin);
    this.debugFlagService.debugMode$.subscribe((isDebugMode: boolean): void => {
      this._toggleAdminMode(isDebugMode);
    });
  }

  private _toggleAdminMode(isDebugMode: boolean): void {
    if (isDebugMode) {
      this.tabs = [...this.tabs, TABS.ADMIN];
    } else {
      this.tabs = this.tabs.filter((tab: TABS): boolean => tab !== TABS.ADMIN);
    }

    if (!isDebugMode && this.activeTab === TABS.ADMIN) {
      this.setTab(this.TABS.GENERAL);
    }
  }

  _setHotkeys(): void {
    const isOrderTicketAvailable: boolean =
      this.settings.admin?.betaFeatures?.components?.orderTicket;
    const availableHotkeysEvents: HotkeyEvents[] = this.hotkeysEvents.filter(
      (hotkeysEvent: HotkeyEvents): boolean => {
        if (
          hotkeysEvent !== HotkeyEvents.OpenOrderTicket ||
          isOrderTicketAvailable
        ) {
          return true;
        }

        return false;
      },
    );

    this.hotkeys = availableHotkeysEvents.map((key: string) => {
      const binding = this.settings.hotkeys[key]
        ? KeyBinding.fromDTO(this.settings.hotkeys[key])
        : new KeyBinding([]);
      return { name: hotkeyStringRepresentation[key], key, binding };
    });
  }

  switchAutoSave(delay: number): void {
    switch (delay) {
      case SAVE_DALEY.FIVE_MIN:
        this._settingsService.setAutoSave(SAVE_DALEY.FIVE_MIN);
        break;

      case SAVE_DALEY.MANUAL_SAVE:
      default:
        this._settingsService.removeAutoSave();
        break;
    }
  }

  handleNodeEvent(name: LayoutNodeEvent, data: any) {
    if (name === LayoutNodeEvent.Event) {
      if (this.isKeyboardRecording && data instanceof KeyboardEvent) {
        this.keyboardListener.handle(data);
        return true;
      }
    }
    return false;
  }

  updateHotkey(item: any, key) {
    this.settings.hotkeys[key] = item.toDTO();
    this._settingsService.saveKeyBinding(this.settings.hotkeys);
  }

  clearAll() {
    this.hotkeysEvents.forEach((key) => {
      this.settings.hotkeys[key] = new KeyBinding([]).toDTO();
    });
    this._settingsService.saveKeyBinding(this.settings.hotkeys);
  }

  setTab(item: TABS): TABS {
    if (this.activeTab === item) {
      return;
    }
    if (item === this.TABS.GENERAL || item === this.TABS.ADMIN) {
      this.selectedConfig = this.settingsConfig[item];
    }

    return (this.activeTab = item);
  }

  saveSettingsGeneral($event): void {
    const q = {
      general: $event.general,
    };
    this._settingsService.save(q);
  }

  handleAdminSettingsChange({ admin }): void {
    const oldChartIndicators: SettingsData['admin']['betaFeatures']['chartIndicators'] =
      clonedeep(this.oldAdminSettings?.betaFeatures?.chartIndicators);
    const newChartIndicators: SettingsData['admin']['betaFeatures']['chartIndicators'] =
      clonedeep(admin.betaFeatures.chartIndicators);
    const oldChartIntervals: SettingsData['admin']['betaFeatures']['chartIntervals'] =
      clonedeep(this.oldAdminSettings?.betaFeatures?.chartIntervals);
    const newChartIntervals: SettingsData['admin']['betaFeatures']['chartIntervals'] =
      clonedeep(admin.betaFeatures.chartIntervals);
    const oldCharPeriodsToLoad: SettingsData['admin']['betaFeatures']['chartPeriodsToLoad'] =
      clonedeep(this.oldAdminSettings?.betaFeatures?.chartPeriodsToLoad);
    const newChartPeriodsToLoad: SettingsData['admin']['betaFeatures']['chartPeriodsToLoad'] =
      clonedeep(admin.betaFeatures.chartPeriodsToLoad);
    const oldChartBarStyle: SettingsData['admin']['betaFeatures']['chartBarStyles'] =
      clonedeep(this.oldAdminSettings?.betaFeatures?.chartBarStyles);
    const newChartBarStyle: SettingsData['admin']['betaFeatures']['chartBarStyles'] =
      clonedeep(admin.betaFeatures.chartBarStyles);
    const oldComponents: SettingsData['admin']['betaFeatures']['components'] =
      clonedeep(this.oldAdminSettings?.betaFeatures?.components);
    const newComponents: SettingsData['admin']['betaFeatures']['components'] =
      clonedeep(admin.betaFeatures.components);
    const oldDom: SettingsData['admin']['betaFeatures']['dom'] = clonedeep(
      this.oldAdminSettings?.betaFeatures?.dom,
    );
    const newDom: SettingsData['admin']['betaFeatures']['dom'] = clonedeep(
      admin.betaFeatures.dom,
    );
    const oldDomSettings: string = JSON.stringify(
      <SettingsData['admin']['betaFeatures']['domSettings']>(
        this.oldAdminSettings?.betaFeatures?.domSettings
      ),
    );
    const newDomSettings: string = JSON.stringify(
      <SettingsData['admin']['betaFeatures']['domSettings']>(
        admin.betaFeatures.domSettings
      ),
    );

    switch (true) {
      case oldChartIndicators?.zigZag !== newChartIndicators.zigZag:
        this._settingsService.zigZagIndicatorChange$.next(
          newChartIndicators.zigZag,
        );
        break;
      case oldChartIndicators?.zigZagOscillator !==
        newChartIndicators.zigZagOscillator:
        this._settingsService.zigZagOscillatorIndicatorChange$.next(
          newChartIndicators.zigZagOscillator,
        );
        break;
      case oldChartIntervals?.week !== newChartIntervals.week:
        this._settingsService.weekIntervalAvailabilityChange$.next(
          newChartIntervals.range,
        );
        break;
      case oldChartIntervals?.month !== newChartIntervals.month:
        this._settingsService.monthIntervalAvailabilityChange$.next(
          newChartIntervals.renko,
        );
        break;
      case oldChartIntervals.year !== newChartIntervals.year:
        this._settingsService.yearIntervalAvailabilityChange$.next(
          newChartIntervals.ticks,
        );
        break;
      case oldChartIntervals?.range !== newChartIntervals.range:
        this._settingsService.rangeIntervalAvailabilityChange$.next(
          newChartIntervals.range,
        );
        break;
      case oldChartIntervals?.renko !== newChartIntervals.renko:
        this._settingsService.renkoIntervalAvailabilityChange$.next(
          newChartIntervals.renko,
        );
        break;
      case oldChartIntervals?.ticks !== newChartIntervals.ticks:
        this._settingsService.ticksIntervalAvailabilityChange$.next(
          newChartIntervals.ticks,
        );
        break;
      case oldChartIntervals?.volume !== newChartIntervals.volume:
        this._settingsService.volumeIntervalAvailabilityChange$.next(
          newChartIntervals.volume,
        );
        break;
      case oldCharPeriodsToLoad?.years !== newChartPeriodsToLoad.years:
        this._settingsService.yearsPeriodToLoadAvailabilityChange$.next(
          newChartPeriodsToLoad.years,
        );
        break;
      case oldChartBarStyle?.kagi !== newChartBarStyle.kagi:
        this._settingsService.kagiBarStyleAvailabilityChange$.next(
          newChartBarStyle.kagi,
        );
        break;
      case oldChartBarStyle?.lineBreak !== newChartBarStyle.lineBreak:
        this._settingsService.lineBreakBarStyleAvailabilityChange$.next(
          newChartBarStyle.lineBreak,
        );
        break;
      case oldChartBarStyle?.pointAndFigure !== newChartBarStyle.pointAndFigure:
        this._settingsService.pointAndFigureBarStyleAvailabilityChange$.next(
          newChartBarStyle.pointAndFigure,
        );
        break;
      case oldChartBarStyle?.renko !== newChartBarStyle.renko:
        this._settingsService.renkoBarStyleAvailabilityChange$.next(
          newChartBarStyle.renko,
        );
        break;
      case oldComponents?.orderTicket !== newComponents.orderTicket:
        this._settingsService.orderTicketAvailabilityChange$.next(
          newComponents.orderTicket,
        );
        break;
      case oldDom?.bracketButton !== newDom.bracketButton:
        this._settingsService.domBracketButton$.next(newDom.bracketButton);
        break;
      case oldDomSettings !== newDomSettings:
        this._settingsService.domSettingsBetaFeaturesAvailabilityChange$.next(
          JSON.parse(newDomSettings),
        );
        break;
      case oldDomSettings !== newDomSettings:
        this._settingsService.domSettingsBetaFeaturesAvailabilityChange$.next(
          JSON.parse(newDomSettings),
        );
        break;
    }

    this._settingsService.save({ admin });
  }
}
