import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { AppHealthLogger } from '../app-health-logs/app-health-logger';
import {
  IMetric,
  IMetricReporter,
  MetricName,
  PerformanceMetricsService,
} from '../performance-metrics.service';
import { UserIdleDetector } from './user-idle-detector';

/**
 * @description This is a metrics reporter for `UserIdleDetector`.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserIdleDetectorMetrics
  extends UserIdleDetector
  implements IMetricReporter, OnDestroy
{
  constructor(
    ngZone: NgZone,
    logger: AppHealthLogger,
    private metricsService: PerformanceMetricsService,
  ) {
    super(ngZone, logger);

    this.metricsService.registerReporter(this);
  }

  collectMetrics(): IMetric[] {
    const metrics: IMetric[] = [];

    if (!this.isWatching()) {
      return metrics;
    }

    const idleState: boolean = this.getIdleStateValue();
    metrics.push({
      name: MetricName.UserIdleState,
      value: idleState ? 1 : 0,
    });

    metrics.push({
      name: MetricName.UserIdleTime,
      value: this.getIdleTime(),
    });

    return metrics;
  }

  resetMetrics(): void {
    this.stopWatching();
    this.startWatching().then((): void => {
      // do nothing
    });
  }

  ngOnDestroy(): void {
    this.metricsService.unregisterReporter(this);
    this.stopWatching();
  }
}
