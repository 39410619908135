import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AccountsManager, Connection } from 'accounts-manager';
import { ConnectionId, Id } from 'communication';
import {
  ConnectionsSubscriptionsMap,
  RealFeed,
  SubscriptionMap,
} from 'real-trading';

interface MarketDataSubscriberStats {
  connectionName: string;
  subscribers: {
    [instrumentId: string]: {
      [dataLevel: string]: {
        [feedType: string]: number;
      };
    };
  };
}

@Component({
  selector: 'lib-market-data-subscriber-stats',
  templateUrl: './market-data-subscriber-stats.component.html',
  styleUrls: ['./market-data-subscriber-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketDataSubscriberStatsComponent implements OnInit {
  subscriberStats: MarketDataSubscriberStats[] = [];

  constructor(private _accountsManager: AccountsManager) {}

  ngOnInit() {
    this.refreshStats();
  }

  getInternalMarketDataSubscriptionStats(): MarketDataSubscriberStats[] {
    const connectionsSubscriptions: ConnectionsSubscriptionsMap =
      RealFeed.getConnectionsSubscriptions();

    const connectionIds: string[] = Array.from(
      Object.keys(connectionsSubscriptions),
    );
    const connections: Connection[] = this._accountsManager.connections;
    const connectionsMap: Map<Id, Connection> = new Map(
      connections.map((connection: Connection) => [connection.id, connection]),
    );
    const subscriberStats: MarketDataSubscriberStats[] = connectionIds.map(
      (connectionId: ConnectionId) => {
        const rithmicConnectionSubscriptions: SubscriptionMap =
          connectionsSubscriptions[connectionId];
        const rithmicConnection: Connection = connectionsMap.get(connectionId);

        const subscribers = {};
        Array.from(Object.keys(rithmicConnectionSubscriptions)).map(
          (instrumentId: string) => {
            subscribers[instrumentId] = {};
            Object.keys(rithmicConnectionSubscriptions[instrumentId]).forEach(
              (feedType) => {
                Object.keys(
                  rithmicConnectionSubscriptions[instrumentId][feedType],
                ).forEach((dataLevel) => {
                  if (!subscribers[instrumentId][dataLevel]) {
                    subscribers[instrumentId][dataLevel] = {};
                  }
                  if (!subscribers[instrumentId][dataLevel][feedType]) {
                    subscribers[instrumentId][dataLevel][feedType] = {};
                  }
                  subscribers[instrumentId][dataLevel][feedType] =
                    rithmicConnectionSubscriptions[instrumentId][feedType][
                      dataLevel
                    ].count;
                });
              },
            );

            return {
              instrumentId,
              subscribers,
            };
          },
        );

        return {
          connectionName: rithmicConnection.name,
          subscribers,
        };
      },
    );

    this.subscriberStats = subscriberStats;

    return subscriberStats;
  }

  refreshStats() {
    this.subscriberStats = this.getInternalMarketDataSubscriptionStats();
  }

  getInstruments(connection: MarketDataSubscriberStats): string[] {
    return Object.keys(connection.subscribers);
  }

  getDataLevels(
    connection: MarketDataSubscriberStats,
    instrumentId: string,
  ): string[] {
    return Object.keys(connection.subscribers[instrumentId]);
  }

  getFeedTypes(
    connection: MarketDataSubscriberStats,
    instrumentId: string,
    dataLevel: string,
  ): string[] {
    return Object.keys(connection.subscribers[instrumentId][dataLevel]);
  }
}
