import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

function wildcardMatchRegExp(text: string, pattern: string): boolean {
  const regexPattern = new RegExp(
    '^' + pattern.replace(/\?/g, '.').replace(/\*/g, '.*') + '$',
  );

  return regexPattern.test(text);
}

export class Config {
  protected _$config = new ReplaySubject<this>(1);

  apply(config: any) {
    // Checks whether config allows the redirectUri to be overwritten by current
    // origin if it matches a predefined wildcard pattern.
    // This allows authentication to redirect to Previews that have dynamic subdomains.
    if (
      config?.identity?.wildcardRedirectUri &&
      wildcardMatchRegExp(
        window.location.origin,
        config.identity.wildcardRedirectUri,
      )
    ) {
      config.identity.redirectUri = window.location.origin;
    }

    Object.assign(this, config);
    this._$config.next(this);
  }

  getConfig(): Observable<this> {
    return this._$config.pipe(filter(Boolean)) as Observable<this>;
  }
}
