import { Inject, Injectable, Injector } from '@angular/core';

import { AccountsManager } from 'accounts-manager';
import {
  AlertType,
  ConnectionId,
  IBaseItem,
  Id,
  RITHMIC_INFRA_TYPE,
  WEB_SOCKET_TYPE,
  WebSocketService,
  WSEventType,
} from 'communication';
import { MessageTypes } from 'notification';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import {
  DataLevel,
  Feed,
  IInstrument,
  OnUpdateFn,
  UnsubscribeFn,
} from 'trading';

import { Loggable } from '../../../../../src/Loggable';
import { FeedRelayService, IFeedRelayConfig } from './feed-relay.service';
import { RealtimeType } from './realtime';
import { WSMessageTypes } from './ws-message-types';

export interface Dto<I extends IBaseItem = any> {
  Value: I[];
  Timestamp: Date;
}

export interface Subscription {
  count: number;
  payload: Dto;
}

export interface SubscriptionMap {
  [instrumentId: string]: {
    [feedType: string]: {
      [dataLevel: string]: Subscription;
    };
  };
}

export interface ConnectionsSubscriptionsMap {
  [connectionId: string]: SubscriptionMap;
}

@Injectable()
export class RealFeed<T, I extends IBaseItem = any>
  extends Loggable
  implements Feed<T>
{
  public static getConnectionsSubscriptions(): ConnectionsSubscriptionsMap {
    return RealFeed._subscriptions;
  }
  private static _subscriptions: ConnectionsSubscriptionsMap = {};
  private static _unsubscribeFns: {
    [connectionId: string]: {
      [instrumentId: string]: {
        [dataLevel in DataLevel]?: () => void;
      };
    };
  } = {};
  private static _pendingRequests: { [connectionId: string]: (() => void)[] } =
    {};

  public windowType: string;
  protected isChildWindow: boolean;
  protected isMainWindow: boolean;

  get webSocketType(): WEB_SOCKET_TYPE {
    return WEB_SOCKET_TYPE.RPROTOCOL;
  }

  get dataLevel(): DataLevel {
    return DataLevel.Level0;
  }

  get useFeedRelay(): boolean {
    return false;
  }
  get provideFeedRelayConfig(): IFeedRelayConfig {
    return {
      flushBufferInterval: null,
    };
  }
  private _isFeedRelayInitialized: boolean = false;

  feedType: RealtimeType;
  infraType = RITHMIC_INFRA_TYPE.TICKER_PLANT;

  protected _webSocketService: WebSocketService;
  private _executors: OnUpdateFn<T>[] = [];

  subscribeType: WSMessageTypes;
  unsubscribeType: WSMessageTypes;

  constructor(
    protected _injector: Injector,
    @Inject(WebSocketRegistryService)
    protected _webSocketRegistryService: WebSocketRegistryService,
    @Inject(AccountsManager) protected _accountsManager: AccountsManager,
    @Inject(FeedRelayService) protected _feedRelay: FeedRelayService,
  ) {
    super();

    this._webSocketService = this._webSocketRegistryService.getWebSocketService(
      this.webSocketType,
    );

    this._webSocketService.on(
      WSEventType.Message,
      this._handleUpdate.bind(this),
    );

    this._webSocketService.on(
      WSEventType.Error,
      this._wsHandleError.bind(this),
    );
    this._webSocketService.on(
      WSEventType.Close,
      this._wsHandleClose.bind(this),
    );

    this.isMainWindow = !(window as any).opener;
    this.isChildWindow = !this.isMainWindow;
    this.windowType = this.isChildWindow ? 'popup' : 'main';
  }

  private setupFeedRelay(): void {
    this._feedRelay.useConfig(this.feedType, this.provideFeedRelayConfig);
    if (this.isMainWindow) {
      this._feedRelay.listenToMainWindows(
        this.feedType,
        (event: MessageEvent): void => {
          this.log(
            `${this.windowType} received message from child window`,
            event.data,
          );
          const data: any = event.data;
          const methodName: string = data.methodName;
          const methodArgs: any = data.methodArgs;
          this[methodName](...methodArgs);
        },
      );
    } else if (this.isChildWindow) {
      this._feedRelay.listenToChildWindows(
        this.feedType,
        (event: MessageEvent): void => {
          const data: any = event.data.data;
          const connectionId: Id = event.data.connectionId;

          if (data?.length > 0) {
            data.forEach((item: any) => this._handleUpdate(item, connectionId));
          } else {
            this._handleUpdate(data, connectionId);
          }
        },
      );
    }
  }

  on(fn: OnUpdateFn<T>): UnsubscribeFn {
    if (!this._isFeedRelayInitialized && this.useFeedRelay) {
      this._isFeedRelayInitialized = true;
      this.setupFeedRelay();
    }
    this._executors.push(fn);

    return () => {
      this._executors = this._executors.filter(
        (executor: OnUpdateFn<T>) => executor !== fn,
      );
    };
  }

  subscribe(data: I | I[], connectionId: Id): void {
    this.warn('subscribe()', data);
    if (this.isMainWindow) {
      this._sendRequest(this.subscribeType, data, connectionId);
      return;
    }
    if (this.isChildWindow && this.useFeedRelay) {
      this.log(
        this.constructor.name,
        'subscribe(): sending to main window',
        data,
      );
      this._feedRelay.sendToMainWindow(this.feedType, {
        methodName: 'subscribe',
        methodArgs: [...(Array.isArray(data) ? data : [data]), connectionId],
        origin: this.constructor.name,
      });
      return;
    }
    console.warn(this.constructor.name, 'subscribe(): not implemented', data);
  }

  unsubscribe(data: I | I[], connectionId: Id): void {
    this.warn('unsubscribe()', data);
    if (this.isMainWindow) {
      this._sendRequest(this.unsubscribeType, data, connectionId);
      return;
    }

    if (this.isChildWindow && this.useFeedRelay) {
      this._feedRelay.sendToMainWindow(this.feedType, {
        methodName: 'unsubscribe',
        methodArgs: [...(Array.isArray(data) ? data : [data]), connectionId],
      });
      return;
    }
    console.warn(this.constructor.name, 'unsubscribe(): not implemented', data);
  }

  private _sendRequest(
    type: WSMessageTypes,
    data: I | I[],
    connectionId: Id,
  ): void {
    this.warn('_sendRequest()', type, data, connectionId);
    const items = Array.isArray(data) ? data : [data];
    items.forEach((item) => this._processRequestItem(type, item, connectionId));
  }

  private _getOrCreateSubscriptionData(
    connectionId: Id,
    itemId: string | Id,
  ): Subscription {
    this.warn('_getOrCreateSubscriptionData()', connectionId, itemId);
    const subscriptions = RealFeed._subscriptions;
    if (!subscriptions[connectionId]) {
      subscriptions[connectionId] = {};
    }
    if (!subscriptions[connectionId][itemId]) {
      subscriptions[connectionId][itemId] = {};
    }
    if (!subscriptions[connectionId][itemId][this.feedType]) {
      subscriptions[connectionId][itemId][this.feedType] = {};
    }
    if (!subscriptions[connectionId][itemId][this.feedType][this.dataLevel]) {
      subscriptions[connectionId][itemId][this.feedType][this.dataLevel] = {
        count: 0,
        payload: {} as Dto,
      };
    }
    return subscriptions[connectionId][itemId][this.feedType][this.dataLevel];
  }

  private _handleUnsubscribe(
    subscriptionData: Subscription,
    item: I,
    connectionId: Id,
  ): void {
    this.warn('_handleUnsubscribe()', subscriptionData, item, connectionId);
    if (!subscriptionData) return;
    subscriptionData.count = Math.max(0, subscriptionData.count - 1);
    if (subscriptionData.count === 0) {
      this._executeUnsubscribe(connectionId, item.id);
    }
  }

  private _executeUnsubscribe(connectionId: Id, itemId: Id): void {
    this.warn('_executeUnsubscribe()', connectionId, itemId);
    const unsubscribeFn =
      RealFeed._unsubscribeFns[connectionId]?.[itemId]?.[this.dataLevel];
    if (unsubscribeFn) {
      unsubscribeFn();
      this._createUnsubscribeRequest(connectionId, { id: itemId });
      delete RealFeed._unsubscribeFns[connectionId][itemId][this.dataLevel];
    }
  }

  private _processRequestItem(
    type: WSMessageTypes,
    item: I,
    connectionId: Id,
  ): void {
    this.warn('_processRequestItem()', type, item, connectionId);
    if (!item) return;

    const subscriptionData = this._getOrCreateSubscriptionData(
      connectionId,
      item.id,
    );

    if (type === this.subscribeType) {
      this._handleSubscribe(subscriptionData, item, connectionId);
    } else {
      this._handleUnsubscribe(subscriptionData, item, connectionId);
    }
  }

  private _createUnsubscribeFunction(
    connectionId: Id,
    itemId: Id | string,
    dto: any,
  ): void {
    this.warn('_createUnsubscribeFunction()', connectionId, itemId, dto);
    if (!RealFeed._unsubscribeFns[connectionId]) {
      RealFeed._unsubscribeFns[connectionId] = {};
    }
    if (!RealFeed._unsubscribeFns[connectionId][itemId]) {
      RealFeed._unsubscribeFns[connectionId][itemId] = {};
    }
    RealFeed._unsubscribeFns[connectionId][itemId][this.dataLevel] = () => {
      this._webSocketService.send(
        {
          Type: this.unsubscribeType,
          dataLevel: this.dataLevel,
          ...dto,
        },
        connectionId,
      );
    };
  }

  private _handleSubscribe(
    subscriptionData: Subscription,
    item: I,
    connectionId: Id,
  ): void {
    this.warn('_handleSubscribe()', subscriptionData, item, connectionId);
    subscriptionData.count++;
    if (subscriptionData.count === 1) {
      const dto: Dto<I> = { Value: [item], Timestamp: new Date() };
      this._createUnsubscribeFunction(connectionId, item.id, dto);
      subscriptionData.payload = dto;
      const connection = this._accountsManager.getConnection(connectionId);
      if (!connection) {
        console.error('Connection not found, ID: ' + connectionId);
        return;
      }
      this._createSubscribeRequest(connection, this.subscribeType, item, dto);
    } else {
      this.log(
        '_handleSubscribe(): Already subscribed',
        subscriptionData,
        item,
        connectionId,
      );
    }
  }

  protected _createSubscribeRequest(connection, type, item, dto) {
    this.warn('_createSubscribeRequest()', connection, type, item, dto);
    // need unsubscribe before subscription to get settle data
    // this._createUnsubscribeRequest(connection.id, item);
    // if (connection?.connected && connection.isLoggedInWithBroker)
    if (connection?.connected && connection.isLoggedInWithBroker) {
      this._webSocketService.send(
        {
          infraType: this.infraType,
          webSocketType: this.webSocketType,
          feedType: this.feedType,
          Type: type,
          dataLevel: this.dataLevel,
          ...dto,
        },
        connection.id,
      );
    } else {
      this.createPendingRequest(type, dto, connection.id);
    }
  }

  protected _createUnsubscribeRequest(connectionId, item) {
    this.warn('_createUnsubscribeRequest()', connectionId, item);
    RealFeed._unsubscribeFns[connectionId][item.id][this.dataLevel]();
  }

  private createPendingRequest(type, payload: any, connectionId) {
    this.warn('createPendingRequest()', type, payload, connectionId);
    const mapKey = this.getMapKey(connectionId);
    if (RealFeed._pendingRequests[mapKey] == null)
      RealFeed._pendingRequests[mapKey] = [];
    const types = {
      infraType: this.infraType,
      webSocketType: this.webSocketType,
      feedType: this.feedType,
      Type: type,
      dataLevel: this.dataLevel,
    };

    RealFeed._pendingRequests[mapKey].push(() => {
      this._webSocketService.send({ ...types, ...payload }, mapKey);
    });
  }

  protected _getHash(instrument: IInstrument, connectionId: Id) {
    return `${connectionId}/${instrument.id}`;
  }

  protected _onSuccessfullyConnect(connectionId: Id) {
    this.warn('_onSuccessfullyConnect()', connectionId);
    const mapKey = this.getMapKey(connectionId);

    const pendingRequests = RealFeed._pendingRequests[mapKey];
    if (!pendingRequests) return;

    pendingRequests.forEach((fn) => fn());
    RealFeed._pendingRequests[mapKey] = [];
  }

  _onDisconnect(connectionId: Id) {
    this.warn('_onDisconnect()', connectionId);
    const feedType = this.feedType;
    const mapKey = this.getMapKey(connectionId);

    const subscriptions = RealFeed._subscriptions[connectionId];

    if (!subscriptions) {
      return;
    }

    // On disconnection, remove all previously active feed subscriptions, and add pending 'subscribe requests' that
    // will be sent when the connection is re-established.
    Object.keys(RealFeed._unsubscribeFns[connectionId]).forEach(
      (instrumentId) =>
        Object.keys(
          RealFeed._unsubscribeFns[connectionId][instrumentId],
        ).forEach((dataLevel) =>
          Object.values(
            RealFeed._unsubscribeFns[connectionId][instrumentId][dataLevel],
          ).forEach((fn: () => void) => fn()),
        ),
    );
    RealFeed._pendingRequests[mapKey] = [];
    Object.values(subscriptions).forEach((feeds: any) => {
      let feedSubs = feeds[feedType];
      if (!feedSubs) {
        return;
      }
      if (feeds[feedType][this.dataLevel]) {
        feedSubs = feeds[feedType][this.dataLevel];
      }
      const { payload } = feedSubs;
      if (payload) {
        this.createPendingRequest(
          WSMessageTypes.SUBSCRIBE,
          payload,
          connectionId,
        );
        if (payload.Value?.length > 0) {
          payload.Value.forEach((instrumentData: any) => {
            this.unsubscribe(instrumentData, connectionId);
          });
        }
      }
    });
  }

  protected _handleUpdate(data, connectionId: Id): boolean {
    const { type, result } = data;

    if (type === 'Message') {
      if (result.value === 'Api-key accepted!') {
        if (result.source === WEB_SOCKET_TYPE.RPROTOCOL) {
          this._onSuccessfullyConnect(connectionId);
        }
      } else if (result.value === 'DrainEvent') {
        // TODO refactor this when replace ReconnectingWebSocket lib with a WebSocket implementation that supports graceful termination
        this._webSocketService.startDraining(connectionId);
        this._onDisconnect(connectionId);
      }
      return;
    }
    if (
      type === MessageTypes.CONNECT &&
      result.connectionId === ConnectionId.MarketData &&
      result.type === AlertType.ConnectionClosed
    ) {
      this._onDisconnect(connectionId);
    }

    if (type !== this.feedType || !result || !this._filter(result)) {
      return;
    }

    const _result = this._getResult(data);

    for (const executor of this._executors) {
      try {
        _result.connectionId = connectionId;
        executor(_result, connectionId);
      } catch (error) {
        console.error('_handleTrade', error);
      }
    }

    if (this.useFeedRelay && !this.isChildWindow) {
      this._feedRelay.broadcast(this.feedType, data, connectionId);
    }

    return true;
  }

  private _wsHandleError(event: ErrorEvent, connectionId) {
    console.log(this.constructor.name, '_wsHandleError()', {
      event,
      connectionId,
    });
    if (connectionId) {
      this._onDisconnect(connectionId);
    }
  }
  private _wsHandleClose(event: ErrorEvent, connectionId) {
    console.log(this.constructor.name, '_wsHandleClose()', {
      event,
      connectionId,
    });
    if (connectionId) {
      this._onDisconnect(connectionId);
    }
  }

  protected _getResult(data) {
    const { result } = data;
    return this._map(result);
  }

  protected _filter(item: T): boolean {
    return true;
  }

  protected _map(item: T): any {
    return item;
  }

  merge(oldItem: I, newItem: I): I {
    return newItem;
  }

  getMapKey(connectionId: Id): Id {
    if (this.infraType) {
      return `${connectionId}-${this.infraType}`;
    }
    return connectionId;
  }
}
