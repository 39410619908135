import { barStatsConfig } from '../fields';
import { Indicator } from './Indicator';

export class BarStatsDebugSettings extends Indicator {
  name = 'Bar Stats Debug';
  config = [];

  protected _mapGetSettings(settings: any) {
    return settings;
  }

  protected _mapSetSettings(settings: any) {
    return settings;
  }
}
