import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IChartSettings } from './settings';

@Injectable({
  providedIn: 'root',
})
export class ChartSettingsService {
  public $chartValueScaleUpdate: Subject<{
    componentId: string;
    oldValueScale: IChartSettings['valueScale'];
    newValueScale: IChartSettings['valueScale'];
  }> = new Subject();
}
