import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotifierService } from 'notifier';
import { SettingsData, SettingsService } from 'settings';

import {
  IChart,
  IStockChartXTimeFrame,
  ITimeFrame,
  StockChartXPeriodicityEnum,
  StockChartXPeriodicityType,
  TimeFrame,
} from '../../chart.model';
import { enumarablePeriodicities } from '../../datafeed/TimeFrame';

declare let StockChartX: any;

@UntilDestroy()
@Component({
  selector: 'frame-selector',
  templateUrl: './frame-selector.component.html',
  styleUrls: ['./frame-selector.component.scss'],
})
export class FrameSelectorComponent {
  timeInterval = 1;
  timePeriodicity: StockChartXPeriodicityEnum = StockChartXPeriodicityEnum.HOUR;

  peridInterval = 3;
  periodPeriodicity: StockChartXPeriodicityEnum =
    StockChartXPeriodicityEnum.DAY;

  isLeaving = false;
  actives = {};
  intervalActives = {};

  readonly _INTERVAL_PERIODS_ORDER: Map<StockChartXPeriodicityType, number> =
    new Map([
      [StockChartXPeriodicityEnum.SECOND, 0],
      [StockChartXPeriodicityEnum.MINUTE, 1],
      [StockChartXPeriodicityEnum.HOUR, 2],
      [StockChartXPeriodicityEnum.DAY, 3],
      [StockChartXPeriodicityEnum.WEEK, 4],
      [StockChartXPeriodicityEnum.MONTH, 5],
      [StockChartXPeriodicityEnum.YEAR, 6],
      [StockChartXPeriodicityEnum.TICK, 7],
      [StockChartXPeriodicityEnum.VOLUME, 8],
      [StockChartXPeriodicityEnum.REVS, 9],
      [StockChartXPeriodicityEnum.RENKO, 10],
      [StockChartXPeriodicityEnum.RANGE, 11],
    ]);

  readonly _PERIODS_TO_LOAD_ORDER: Map<StockChartXPeriodicityType, number> =
    new Map([
      [StockChartXPeriodicityEnum.DAY, 0],
      [StockChartXPeriodicityEnum.WEEK, 1],
      [StockChartXPeriodicityEnum.MONTH, 2],
      [StockChartXPeriodicityEnum.DAY, 3],
      [StockChartXPeriodicityEnum.YEAR, 4],
    ]);

  @Input() chart: IChart;

  @Output() periodAdded = new EventEmitter();
  @Output() intervalAdded = new EventEmitter();

  get timeFrame() {
    return this.chart?.timeFrame;
  }

  set timeFrame(value) {
    const chart = this.chart;
    if (!chart) return;
    chart.timeFrame = value;

    // Among other things, this triggers the Chart's CrossHair to update its time markers' width. As result, this fixes a bug with RevBars
    // where the time markers would only show the Day/Month, but not the time of the bar.
    chart.fireValueChanged(
      StockChartX.ChartEvent.TIME_INTERVAL_CHANGED,
      value.interval,
    );

    this.updateChartBars();
  }

  private _timePeriod: ITimeFrame;

  get timePeriod() {
    return this.chart.periodToLoad;
  }

  set timePeriod(value) {
    if (value === this._timePeriod) return;

    this.chart.periodToLoad = value;
    this.timePeriodChange.emit(value);
    this.updateChartBars();
  }

  @Output() timePeriodChange: EventEmitter<IStockChartXTimeFrame> =
    new EventEmitter();

  customPeriodOptions: StockChartXPeriodicityType[] = [
    StockChartXPeriodicityEnum.DAY,
    StockChartXPeriodicityEnum.WEEK,
    StockChartXPeriodicityEnum.MONTH,
  ];

  customIntervalOptions: StockChartXPeriodicityType[] = [
    StockChartXPeriodicityEnum.SECOND,
    StockChartXPeriodicityEnum.MINUTE,
    StockChartXPeriodicityEnum.HOUR,
    ...this.customPeriodOptions,
    StockChartXPeriodicityEnum.REVS,
  ];

  @Input() intervalOptions = [];
  @Input() periodOptions: any = [];

  constructor(
    private _notifier: NotifierService,
    private _settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    const timeFrame = this.timePeriod;
    const option = this.periodOptions.find((item) => {
      return item.timeFrames.some((frame: ITimeFrame): boolean =>
        compareTimeFrames(timeFrame, frame),
      );
    });
    if (option) this.actives[option.period] = true;

    const timeInterval: IStockChartXTimeFrame = this.timeFrame;
    const intervalOption = this.intervalOptions.find((item) => {
      return item.timeFrames.some((frame: ITimeFrame): boolean =>
        compareTimeFrames(timeInterval, frame),
      );
    });
    if (intervalOption) {
      this.intervalActives[intervalOption.period] = true;
    }

    this._settingsService.settings
      .pipe(take(1))
      .subscribe((settings: SettingsData): void => {
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.WEEK,
          settings?.admin?.betaFeatures?.chartIntervals?.week,
        );
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.MONTH,
          settings?.admin?.betaFeatures?.chartIntervals?.month,
        );
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.YEAR,
          settings?.admin?.betaFeatures?.chartIntervals?.year,
        );
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.RANGE,
          settings?.admin?.betaFeatures?.chartIntervals?.range,
        );
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.RENKO,
          settings?.admin?.betaFeatures?.chartIntervals?.renko,
        );
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.VOLUME,
          settings?.admin?.betaFeatures?.chartIntervals?.volume,
        );
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.TICK,
          settings?.admin?.betaFeatures?.chartIntervals?.ticks,
        );
        this._handlePeriodsToLoad(
          StockChartXPeriodicityEnum.YEAR,
          settings?.admin?.betaFeatures?.chartPeriodsToLoad?.years,
        );
      });
    this._settingsService.weekIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isRangeIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.WEEK,
          isRangeIntervalEnabled,
        );
      });
    this._settingsService.monthIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isRenkoIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.MONTH,
          isRenkoIntervalEnabled,
        );
      });
    this._settingsService.yearIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isTicksIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.YEAR,
          isTicksIntervalEnabled,
        );
      });
    this._settingsService.rangeIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isRangeIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.RANGE,
          isRangeIntervalEnabled,
        );
      });
    this._settingsService.renkoIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isRenkoIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.RENKO,
          isRenkoIntervalEnabled,
        );
      });
    this._settingsService.ticksIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isTicksIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.TICK,
          isTicksIntervalEnabled,
        );
      });
    this._settingsService.volumeIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isVolumeIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          StockChartXPeriodicityEnum.VOLUME,
          isVolumeIntervalEnabled,
        );
      });
    this._settingsService.yearsPeriodToLoadAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isYearsPeriodToLoadEnabled: boolean): void => {
        this._handlePeriodsToLoad(
          StockChartXPeriodicityEnum.YEAR,
          isYearsPeriodToLoadEnabled,
        );
      });
  }

  getTimeFrame(timeFrame: ITimeFrame): string {
    const label: string = this.getTimeFrameLabel(timeFrame.periodicity);
    const suffix = enumarablePeriodicities[timeFrame.periodicity] ? 't' : '';

    return `${timeFrame.interval}${suffix} ${label}`;
  }

  getTimeFrameLabel(periodicity): string {
    return TimeFrame.periodicityToString(periodicity);
  }

  deletePeriod(frame: TimeFrame, option: any): void {
    option.timeFrames = option.timeFrames.filter((item) => item !== frame);
  }

  deleteInterval(frame: any, option: any): void {
    option.timeFrames = option.timeFrames.filter((item) => item !== frame);
  }

  addPeriod(): void {
    const interval = this.peridInterval;
    const periodicity = this.periodPeriodicity;
    const frame = { interval, periodicity };
    this.periodAdded.emit(frame);
  }

  addFrameInterval(): void {
    const interval = this.timeInterval;
    const periodicity = this.timePeriodicity;
    const frame = { interval, periodicity };
    this.intervalAdded.emit(frame);
  }

  selectTimePeriod(frame): void {
    const priceStat: string = 'PriceStats';
    this.timePeriod = frame;
    this._notifier.periodInterval = frame.interval;
    if (
      this._notifier.periodInterval < 3 &&
      this._notifier.priceStat == priceStat
    ) {
      this._notifier.setDisabled(true, false);
    } else if (this._notifier.priceStat == priceStat) {
      this._notifier.setDisabled(false, false);
    }
  }

  updateChartBars(): void {
    if (this.timeFrame == null || this.timeFrame == null) return;
    const periodTime = TimeFrame.timeFrameToTimeInterval(this.timePeriod);
    const intervalTime = TimeFrame.timeFrameToTimeInterval(this.timeFrame);
    const endDate = new Date();
    const startDate = new Date(Date.now() - periodTime);
    startDate.setHours(0, 0, 0, 0);
    this.chart.setDates(startDate, endDate);
    this.chart.sendBarsRequest();

    this.periodOptions = this.periodOptions.map((item) => {
      item.timeFrames = item.timeFrames.map((frame) => {
        frame.disabled =
          intervalTime > TimeFrame.timeFrameToTimeInterval(frame);
        return frame;
      });
      return item;
    });
  }

  isIntervalSelected(frame: ITimeFrame): boolean {
    return compareTimeFrames(this.timeFrame, frame);
  }

  isPeriodSelected(frame: ITimeFrame): boolean {
    return compareTimeFrames(this.timePeriod, frame);
  }

  private _handleIntervalAvailability(
    intervalOption: StockChartXPeriodicityType,
    isIntervalEnabled: boolean,
  ): void {
    const isToggledIntervalOptionDisplayed: boolean =
      this.customIntervalOptions.includes(intervalOption);

    if (isToggledIntervalOptionDisplayed && !isIntervalEnabled) {
      this.customIntervalOptions = this.customIntervalOptions.filter(
        (customIntervalOption: StockChartXPeriodicityType): boolean =>
          customIntervalOption !== intervalOption,
      );
    } else if (!isToggledIntervalOptionDisplayed && isIntervalEnabled) {
      this.customIntervalOptions = this._getSortedIntervalOptions([
        ...this.customIntervalOptions,
        intervalOption,
      ]);
    }
  }
  private _handlePeriodsToLoad(
    periodToLoadOption: StockChartXPeriodicityType,
    isPeriodToLoadEnabled: boolean,
  ): void {
    const isToggledPeriodToLoadOptionDisplayed: boolean =
      this.customPeriodOptions.includes(periodToLoadOption);

    if (isToggledPeriodToLoadOptionDisplayed && !isPeriodToLoadEnabled) {
      this.customPeriodOptions = this.customPeriodOptions.filter(
        (customIntervalOption: StockChartXPeriodicityType): boolean =>
          customIntervalOption !== periodToLoadOption,
      );
    } else if (!isToggledPeriodToLoadOptionDisplayed && isPeriodToLoadEnabled) {
      this.customPeriodOptions = this._getSortedPeriodsToLoadOptions([
        ...this.customPeriodOptions,
        periodToLoadOption,
      ]);
    }
  }

  private _getSortedIntervalOptions(
    unsortedIntervalOptions: StockChartXPeriodicityType[],
  ): StockChartXPeriodicityType[] {
    let sortedCustomIntervalOptions: StockChartXPeriodicityType[] = new Array(
      this._INTERVAL_PERIODS_ORDER.size,
    ).fill(null);

    unsortedIntervalOptions.forEach(
      (unsortedIntervalOption: StockChartXPeriodicityType): void => {
        const index: number = this._INTERVAL_PERIODS_ORDER.get(
          unsortedIntervalOption,
        );

        sortedCustomIntervalOptions[index] = unsortedIntervalOption;
      },
    );

    sortedCustomIntervalOptions = sortedCustomIntervalOptions.filter(
      (sortedCustomIntervalOption: StockChartXPeriodicityType): boolean =>
        sortedCustomIntervalOption !== null,
    );

    return sortedCustomIntervalOptions;
  }

  private _getSortedPeriodsToLoadOptions(
    unsortedPeriodsToLoadOptions: StockChartXPeriodicityType[],
  ): StockChartXPeriodicityType[] {
    let sortedPeriodsToLoadOptions: StockChartXPeriodicityType[] = new Array(
      this._PERIODS_TO_LOAD_ORDER.size,
    ).fill(null);

    unsortedPeriodsToLoadOptions.forEach(
      (unsortedPeriodsToLoadOption: StockChartXPeriodicityType): void => {
        const index: number = this._PERIODS_TO_LOAD_ORDER.get(
          unsortedPeriodsToLoadOption,
        );
        sortedPeriodsToLoadOptions[index] = unsortedPeriodsToLoadOption;
      },
    );

    sortedPeriodsToLoadOptions = sortedPeriodsToLoadOptions.filter(
      (sortedPeriodsToLoadOption: StockChartXPeriodicityType): boolean =>
        sortedPeriodsToLoadOption !== null,
    );

    return sortedPeriodsToLoadOptions;
  }
}

export function compareTimeFrames(obj1: ITimeFrame, obj2: ITimeFrame) {
  if (!obj1 || !obj2) return;

  return (
    obj2.interval === obj1.interval && obj2.periodicity === obj1.periodicity
  );
}
