import { IInstrument } from 'trading';

import { RoundFormatter } from './round.formatter';

const USTreasuryInstrumentsMap = [
  { Name: 'ZT', Precision: 3, Fraction: 32 },
  { Name: 'Z3N', Precision: 3, Fraction: 32 },
  { Name: 'ZF', Precision: 3, Fraction: 32 },
  { Name: 'ZN', Precision: 3, Fraction: 32 },
  { Name: 'TN', Precision: 3, Fraction: 32 },
  { Name: 'ZB', Precision: 2, Fraction: 32 },
  { Name: 'TWE', Precision: 2, Fraction: 32 },
  { Name: 'UB', Precision: 2, Fraction: 32 },
];

export class InstrumentFormatter extends RoundFormatter {
  private _multiplier = 1;

  static forInstrument(instrument?: IInstrument) {
    if (instrument == null) return new RoundFormatter(2);

    InstrumentFormatter.setSlicePrecisionValue(instrument);

    if (instrument.fraction != null && instrument.fraction !== 0)
      return new InstrumentFormatter(instrument);

    return new RoundFormatter(instrument?.precision ?? 2);
  }

  private _getNumbersAfterPoint(number: number): number {
    if (number === null || number === undefined) return 0;

    const numberAsString = number.toString();

    if (numberAsString.includes('.')) {
      return numberAsString.split('.')[1].length;
    }

    return 0;
  }

  constructor(protected _instrument: IInstrument) {
    super(2);

    if (_instrument == null) throw new Error('Please provide instrument');

    InstrumentFormatter.setSlicePrecisionValue(_instrument);

    const step = _instrument.fraction.toString().length;
    this._multiplier = _instrument.fraction / 10 ** step;

    /*
     * 0.32 is 0.BASE_FRACTION
     * https://www.cmegroup.com/markets/interest-rates/us-treasury/ultra-10-year-us-treasury-note.contractSpecs.html#
     * Each futures with fractions have k * 1/32 (k equal 1/2 for example)
     * The problem here is next
     * When we calculated precision for 1/2 * 1/32 we have (1/2 * 1/32) * 0.64 = 0.01, precision equal 2
     * But with some numbers after fractions should be 147'315
     * So (1/2 * 1/32) * 0.32 = 0.005 fix this problem
     * WE USE THIS NUMBER ONLY FOR PRECISION
     * In future to improve calculation we can put (1/2) to instrument DTO
     */
    const _number = (_instrument.tickSize ?? this._instrument.increment) * 0.32;
    const precision = this._getNumbersAfterPoint(_number);

    this.updateDigits(precision);
  }

  format(value: number): string {
    const prefix = value < 0 ? '-' : '';

    if (value < 0) value *= -1;

    const val = Math.floor(value);
    const decimals = value - val;

    let result =
      prefix +
      super.format(val + decimals * this._multiplier).replace('.', "'");

    InstrumentFormatter.setSlicePrecisionValue(this._instrument);
    if (this._instrument.slicePrecision != null) {
      return result.slice(
        0,
        result.indexOf("'") + this._instrument.slicePrecision + 1,
      );
    }

    return result;
  }

  static setSlicePrecisionValue(instrument?: IInstrument) {
    if (instrument == null || instrument.symbol == null) return;

    if (instrument.symbol == null) return;

    if (instrument.slicePrecision == null) {
      let treasuryInstrument = USTreasuryInstrumentsMap.find((i) =>
        instrument.symbol.startsWith(i.Name),
      );
      if (treasuryInstrument != null) {
        instrument.fraction = treasuryInstrument.Fraction;
        instrument.slicePrecision = treasuryInstrument.Precision;
      }
    }
  }
}
