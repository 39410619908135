<window-header (close)="saveState()" [window]="this"></window-header>
<div class="settings-container">
  <div class="menu">
    <div
      *ngFor="let item of items"
      (click)="selectItem(item)"
      [class.active]="item.id === selectedItem?.id"
      class="menu-item"
    >
      {{ item.name }}
      <span *ngIf="!item.isDefault" class="actions ml-auto"
        ><i (click)="rename(item)" class="icon-edit"></i
        ><i (click)="delete(item)" class="icon-delete"></i
      ></span>
    </div>
    <div
      *ngFor="let item of unnamedIndicators; let i = index"
      (click)="selectUntemplated(item)"
      [class.active]="item.id === selectedItem?.id"
      class="menu-item"
    >
      Drawing {{ i + 1 }}
    </div>
  </div>
  <cdk-virtual-scroll-viewport itemSize="">
    <formly-form [(model)]="settings" [form]="form" [fields]="formConfig">
    </formly-form>
    <i (click)="save()" class="icon-save"></i>
  </cdk-virtual-scroll-viewport>
</div>
