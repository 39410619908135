export enum Components {
  Chart = 'chart',
  ChartSettings = 'chartSettings',
  ChartVolumeSettings = 'customVolumeProfileSettings',
  Indicators = 'indicators',
  Watchlist = 'watchlist',
  Positions = 'positions',
  PositionsSettings = 'positions-settings',
  Orders = 'orders',
  OrdersSetting = 'orders-settings',
  OrderForm = 'order-form',
  Accounts = 'accounts',
  Scripting = 'scripting',
  Settings = 'settings',
  Dom = 'dom',
  DomSettings = 'dom-settings',
  NotificationList = 'notification-list',
  SessionManager = 'session-manager',
  IndicatorList = 'indicatorList',
  OrdersPanel = 'ordersPanel',
  MarketWatch = 'marketWatch',
  MarketWatchSettings = 'market-watch-settings',
  AccountInfo = 'account-info',
  CustomVolumeProfile = 'custom-volume-profile',
  PerformanceTools = 'performance-tools',
}

export enum ComponentNames {
  chart = 'Chart',
  orders = 'Orders',
  positions = 'Positions',
  marketWatch = 'MarketWatch',
  dom = 'DOM',
}
