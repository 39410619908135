import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-controller',
  templateUrl: './navbar-controller.component.html',
  styleUrls: ['./navbar-controller.component.scss'],
})
export class NavbarControllerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
