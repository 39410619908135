import { Injectable, OnDestroy } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { AppHealthLogger } from '../app-health-logs/app-health-logger';
import {
  IMetric,
  IMetricReporter,
  MetricName,
  PerformanceMetricsService,
} from '../performance-metrics.service';
import { BrowserThrottlingDetector } from './browser-throttling-detector';

/**
 * @description Metric reporter for `BrowserThrottlingDetector`. Collects various
 * browser throttling metrics, such as battery level, visibility state, timeout
 * and requestAnimationFrame drift, and reports them to the PerformanceMetricsService.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class BrowserThrottlingDetectorMetrics
  extends BrowserThrottlingDetector
  implements IMetricReporter, OnDestroy
{
  constructor(
    private metricsService: PerformanceMetricsService,
    logger: AppHealthLogger,
  ) {
    super(logger);
    this.metricsService.registerReporter(this);
  }

  collectMetrics(): IMetric[] {
    const metrics: IMetric[] = [];

    if (this.batteryStatus !== null) {
      metrics.push({
        name: MetricName.BatterLevel,
        value: this.batteryStatus.level * 100,
      });
      metrics.push({
        name: MetricName.BatterCharging,
        value: this.batteryStatus.charging ? 1 : 0,
      });
    }

    if (this.documentVisibilityState !== null) {
      metrics.push({
        name: MetricName.DocumentVisibility,
        value: this.documentVisibilityState === 'visible' ? 1 : 0,
      });
    }
    if (this.timeoutDrift !== null) {
      metrics.push({
        name: MetricName.TimeoutDrift,
        value: this.timeoutDrift,
      });
    }

    if (this.requestAnimationFrameDrift !== null) {
      metrics.push({
        name: MetricName.RequestAnimationFrameDrift,
        value: this.requestAnimationFrameDrift,
      });
    }

    return metrics;
  }

  resetMetrics(): void {
    this.cleanup();
    this.start();
  }

  ngOnDestroy(): void {
    this.metricsService.unregisterReporter(this);
    this.cleanup();
  }
}
