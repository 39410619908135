import { Injectable } from '@angular/core';

import * as protobuf from 'protobufjs';

import pbMessagesJSONBundle from './messages-json/bundle.json';
import {
  RProtocolMessageTemplateIds,
  RProtocolMessageTemplateNameType,
} from './rprotocol-message-template-ids';

@Injectable({
  providedIn: 'root',
})
export class RProtocolTemplateRegistryService {
  private static _protobufRoot: any;

  // Hash maps holding "Message Type" to "Template Id" mapping
  private _mapTemplateIdToName = new Map<number, string>();
  private _mapTemplateIdToType = new Map<number, protobuf.Type>();
  private _mapTemplateNameToType = new Map<
    RProtocolMessageTemplateNameType,
    protobuf.Type
  >();
  private _mapTemplateNameToId = new Map<
    RProtocolMessageTemplateNameType,
    number
  >();

  private MessageType: protobuf.Type;

  protected get protobufRoot() {
    return RProtocolTemplateRegistryService._protobufRoot;
  }

  protected set protobufRoot(value: any) {
    RProtocolTemplateRegistryService._protobufRoot = value;
  }

  constructor() {
    this.loadProtoJSONBundle();
  }

  public getNameByTemplateId(templateId: number): string {
    const templateName: string = this._mapTemplateIdToName.get(templateId);
    if (!templateName)
      throw new Error(
        `RProtocol Error: Unable to fetch template name associated to ID: ${templateId}`,
      );

    return templateName;
  }

  public getTypeByTemplateId(templateId: number): protobuf.Type {
    const templateType = this._mapTemplateIdToType.get(templateId);
    if (!templateType)
      throw new Error(
        `RProtocol Error: Unable to fetch template associated to ID: ${templateId}`,
      );

    return templateType;
  }

  getTypeByTemplateName(templateNameType: RProtocolMessageTemplateNameType) {
    const templateType = this._mapTemplateNameToType.get(templateNameType);
    if (!templateType)
      throw new Error(
        `RProtocol Error: Unable to fetch template by name: ${templateNameType}`,
      );

    return templateType;
  }
  getIdByTemplateName(templateNameType: RProtocolMessageTemplateNameType) {
    const templateId = this._mapTemplateNameToId.get(templateNameType);
    if (!templateId)
      throw new Error(
        `RProtocol Error: Unable to fetch template ID by name: ${templateNameType}`,
      );

    return templateId;
  }

  loadProtoJSONBundle() {
    this.protobufRoot = protobuf.Root.fromJSON(pbMessagesJSONBundle);
    this.loadProtoMaps();
  }

  loadProtoMaps() {
    Object.keys(RProtocolMessageTemplateIds).forEach(
      (templateName: RProtocolMessageTemplateNameType) => {
        const templateId = RProtocolMessageTemplateIds[templateName];
        const messageType = this.protobufRoot.lookupType(
          templateName,
        ) as protobuf.Type;
        this._mapTemplateIdToName.set(templateId, templateName);
        this._mapTemplateIdToType.set(templateId, messageType);
        this._mapTemplateNameToType.set(templateName, messageType);
        this._mapTemplateNameToId.set(templateName, templateId);
      },
    );
    this.MessageType = this.protobufRoot.lookupType('MessageType');
  }

  // Decode generic protobuf serialized message into a concrete one
  decodeMessage(eventData: ArrayBuffer): protobuf.Message {
    const messagePrefixLength = 4;

    // Reads message length from the first 4 bytes of the message.
    const messageLengthBuffer = new Uint8Array(
      eventData,
      0,
      messagePrefixLength,
    );
    const dv = new DataView(messageLengthBuffer.buffer, 0);
    const messageLength = dv.getUint32(0, false);

    // Reads the message from the buffer starting at the 4th byte.
    const buffer = new Uint8Array(
      eventData,
      messagePrefixLength,
      messageLength,
    );

    // TODO deal with data that contains multiple messages? see https://github.com/protobufjs/protobuf.js/issues/385#issuecomment-170306329
    const messageTypeInstance = this.MessageType.decode(buffer);
    const templateId = (messageTypeInstance as any).templateId;
    const concreteMessageType = this.getTypeByTemplateId(templateId);
    return concreteMessageType.decode(buffer);
  }
  decodeMessageFromBase64(data: string): protobuf.Message {
    return this.decodeMessage(
      Uint8Array.from(atob(data), (c) => c.charCodeAt(0)),
    );
  }

  fromObject(templateType: any, data: any) {
    const messageType = this.getTypeByTemplateName(templateType);
    return messageType.fromObject(data);
  }

  prepareRequestBufferFromObject(
    templateType: RProtocolMessageTemplateNameType,
    data: any,
  ): Uint8Array {
    const templateId: number = this.getIdByTemplateName(templateType);
    data.templateId = templateId;
    const messageType: protobuf.Type = this.getTypeByTemplateName(templateType);
    const message = messageType.fromObject(data);
    return this.prepareRequestBuffer(messageType, message);
  }

  /**
   * Prepares a request buffer by encoding a protobuf message and adding a 4-byte length prefix.
   *
   * @param messageType - The protobuf type of the message to be encoded.
   * @param message - The protobuf message to be encoded.
   * @returns A new Uint8Array containing the encoded message with a 4-byte length prefix.
   */
  prepareRequestBuffer(
    messageType: protobuf.Type,
    message: protobuf.Message,
  ): Uint8Array {
    const buffer = messageType.encode(message).finish();
    // Messages sent to/from the server should always be prefixed with a 4 byte message length.
    // Creates a new buffer `newBuffer` that is a copy of `buffer` except it contains a 4-byte prefix that is the length of `buffer`.
    const newBuffer = new Uint8Array(buffer.length + 4);
    newBuffer.set(
      new Uint8Array([
        // tslint:disable:no-bitwise
        buffer.length >> 24,
        buffer.length >> 16,
        buffer.length >> 8,
        buffer.length,
        // tslint:enable:no-bitwise
      ]),
    );
    // Copy the encoded message into the buffer, starting at index 4 (after the length prefix)
    newBuffer.set(buffer, 4);

    return newBuffer;
  }
}
