import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  shareReplay,
  tap,
} from 'rxjs/operators';

import { UntilDestroy } from '@ngneat/until-destroy';
import { KeyBinding, KeyBindingPart, KeyCode } from '@tradrr-app/keyboard';
import { IDomSettingsBetaFeaturesAvailabilityChange } from 'projects/dom/src/lib/dom-settings/dom-settings.model';
import { ISound } from 'sound';
import { IBaseTemplate } from 'templates';
import { Themes, ThemesHandler } from 'themes';
import { ITimezone } from 'timezones-clock';
import { SaveLoaderService } from 'ui';
import { Workspace } from 'workspace-manager';

import { SettingsStore } from './setting-store';
import { HotkeyEntire, ICommand, SettingsData } from './types';

function createCommand(name: string, UIString: string = name): ICommand {
  return {
    name,
    UIString,
  };
}

export enum HotkeyEvents {
  SavePage = 'saveAll',
  OpenOrderTicket = 'openOrderForm',
  OpenTradingDom = 'openDOM',
  OpenChart = 'openChart',
  OpenConnections = 'openConnections',
  LockTrading = 'lockTrading',
}

export const defaultHotkeyEntries = {
  [HotkeyEvents.SavePage]: new KeyBinding([
    KeyBindingPart.fromKeyCode(KeyCode.Ctrl),
    KeyBindingPart.fromKeyCode(KeyCode.KEY_S),
  ]).toDTO(),
  [HotkeyEvents.OpenOrderTicket]: new KeyBinding([]).toDTO(),
  [HotkeyEvents.OpenTradingDom]: new KeyBinding([]).toDTO(),
  [HotkeyEvents.OpenChart]: new KeyBinding([]).toDTO(),
  [HotkeyEvents.OpenConnections]: new KeyBinding([]).toDTO(),
  [HotkeyEvents.LockTrading]: new KeyBinding([]).toDTO(),
};

export enum NavbarPosition {
  Top = 'Top',
  Bottom = 'Bottom',
}

const defaultSettings: SettingsData = {
  theme: Themes.Dark,
  autoSave: false,
  autoSaveDelay: null,
  language: 'English',
  hotkeys: defaultHotkeyEntries,
  tradingEnabled: true,
  workspaces: [],
  timezones: [],
  localTimezoneTitle: 'Local',
  navbarPosition: NavbarPosition.Top,
  isNavbarHidden: false,
  templates: [],
  indicatorTemplates: [],
  sound: {
    connected: {
      name: 'Connected',
      checked: true,
      selectedSound: 'Apert',
      volume: 80,
    },
    connectionLost: {
      name: 'Connection Lost',
      checked: true,
      selectedSound: 'Beam1',
      volume: 80,
    },
    orderFilled: {
      name: 'Order Filled',
      checked: true,
      selectedSound: 'Ding',
      volume: 100,
    },
    orderCancelled: {
      name: 'Order Cancelled',
      checked: true,
      selectedSound: 'Beep',
      volume: 100,
    },
    orderReplaced: {
      name: 'Order Replaced',
      checked: true,
      selectedSound: 'Close',
      volume: 100,
    },
    orderPending: {
      name: 'Order Pending',
      checked: true,
      selectedSound: 'Blip2',
      volume: 100,
    },
    orderRejected: {
      name: 'Order Rejected',
      checked: true,
      selectedSound: 'Bullet',
      volume: 100,
    },
    targetFilled: {
      name: 'Target Filled',
      checked: true,
      selectedSound: 'Cashreg',
      volume: 80,
    },
    stopFilled: {
      name: 'Stop Filled',
      checked: true,
      selectedSound: 'Buzz',
      volume: 100,
    },
    alert: {
      name: 'Alert',
      checked: false,
      selectedSound: 'Arrowhit',
      volume: 100,
    },
    isPlay: false,
  },
};

@Injectable()
@UntilDestroy()
export class SettingsService {
  private _unsubscribeFunctions = [];

  zigZagIndicatorChange$: Subject<boolean> = new Subject();
  zigZagOscillatorIndicatorChange$: Subject<boolean> = new Subject();
  weekIntervalAvailabilityChange$: Subject<boolean> = new Subject();
  monthIntervalAvailabilityChange$: Subject<boolean> = new Subject();
  yearIntervalAvailabilityChange$: Subject<boolean> = new Subject();
  rangeIntervalAvailabilityChange$: Subject<boolean> = new Subject();
  renkoIntervalAvailabilityChange$: Subject<boolean> = new Subject();
  ticksIntervalAvailabilityChange$: Subject<boolean> = new Subject();
  volumeIntervalAvailabilityChange$: Subject<boolean> = new Subject();
  yearsPeriodToLoadAvailabilityChange$: Subject<boolean> = new Subject();
  kagiBarStyleAvailabilityChange$: Subject<boolean> = new Subject();
  lineBreakBarStyleAvailabilityChange$: Subject<boolean> = new Subject();
  pointAndFigureBarStyleAvailabilityChange$: Subject<boolean> = new Subject();
  renkoBarStyleAvailabilityChange$: Subject<boolean> = new Subject();
  orderTicketAvailabilityChange$: Subject<boolean> = new Subject();
  domBracketButton$: Subject<boolean> = new Subject();
  domSettingsBetaFeaturesAvailabilityChange$: Subject<IDomSettingsBetaFeaturesAvailabilityChange> =
    new Subject();
  settings: BehaviorSubject<SettingsData> = new BehaviorSubject(
    defaultSettings,
  );

  private _isSettingsLoaded$ = new Subject<boolean>();
  isSettingsLoaded$ = this._isSettingsLoaded$.pipe(
    filter((item) => item),
    shareReplay(1),
  );
  isSettingsLoaded = false;

  private $updateState = new Subject<void>();

  private get _settings() {
    return this.settings.value;
  }

  constructor(
    public themeHandler: ThemesHandler,
    private _settingStore: SettingsStore,
    private loaderService: SaveLoaderService,
  ) {}

  public init() {
    this._unsubscribeFunctions.push(
      this.$updateState.pipe(debounceTime(100)).subscribe(() => {
        const hide = this.loaderService.showLoader();
        this._settingStore
          .setItem(this.settings.value)
          .toPromise()
          .finally(() => {
            hide();
          });
      }),
    );

    return this._settingStore.getItem().pipe(
      catchError(() => {
        return of(defaultSettings);
      }),
      tap((s: any) => {
        if (s) {
          this._updateState(s, false);
          this.isSettingsLoaded = true;
          this._isSettingsLoaded$.next(true);
          this._isSettingsLoaded$.complete();
        }
      }),
    );
  }

  getItem(): Observable<SettingsData> {
    return this._settingStore.getItem();
  }

  get<T = any>(key: string) {
    return this.settings.value[key];
  }

  set<T = any>(key: string, value: T) {
    this._updateState({ [key]: value });
  }

  public destroy() {
    this._unsubscribeFunctions.forEach((item) => item());
  }

  setAutoSave(delay?: number): void {
    this._updateState({ autoSave: true, autoSaveDelay: delay ?? null });
  }

  removeAutoSave(): void {
    this._updateState({ autoSave: false, autoSaveDelay: null });
  }

  changeTheme(theme): void {
    this._updateState({ theme });
  }

  updateTradingLock(tradingEnabled: boolean): void {
    this._updateState({ tradingEnabled });
  }

  saveState(): void {
    this.$updateState.next();
  }

  saveKeyBinding(hotkeys: HotkeyEntire) {
    this._updateState({ hotkeys });
  }

  saveWorkspaces(workspaces: Workspace[]) {
    this._updateState({ workspaces });
  }

  changeNavbarPosition(navbarPosition: NavbarPosition): void {
    this._updateState({ navbarPosition });
  }

  updateNavbarVisibility(isNavbarHidden: boolean): void {
    this._updateState({ isNavbarHidden });
  }

  save(settings: object) {
    this._updateState(settings);
  }

  saveTimezones(timezones: ITimezone[]): void {
    this._updateState({ timezones });
  }

  saveLocalTimezoneTitle(localTimezoneTitle: string): void {
    this._updateState({ localTimezoneTitle });
  }

  saveTemplates(
    templates: IBaseTemplate[],
    saveInStorage: boolean = true,
  ): void {
    this._updateState({ templates }, saveInStorage);
  }

  saveIndicatorTemplates(
    indicatorTemplates: any[],
    saveInStorage: boolean = true,
  ): void {
    this._updateState({ indicatorTemplates }, saveInStorage);
  }

  saveSounds(type: string, sound: ISound | boolean): void {
    const setting = this.settings.value.sound;
    setting[type] = sound;
    this._updateState({ sound: setting });
  }

  transformAccountLabel(label: string): string {
    const replacer = '*';
    const hideAccountName =
      this.settings.getValue()?.general?.hideAccountName ?? false;
    const hideFromLeft =
      this.settings.getValue()?.general?.hideFromLeft ?? false;
    const hideFromRight =
      this.settings.getValue()?.general?.hideFromRight ?? false;
    const digitsToHide = this.settings.getValue()?.general?.digitsToHide ?? 0;

    if (hideAccountName) {
      const length: number =
        digitsToHide > label.length ? label.length : digitsToHide;
      let _label = label;
      if (hideFromLeft) {
        _label =
          replacer.repeat(length) + _label.substring(length, label.length);
      }
      if (hideFromRight) {
        _label =
          _label.substring(0, label.length - length) + replacer.repeat(length);
      }
      return _label;
    }
    return label;
  }

  private _updateState(
    settings: Partial<SettingsData>,
    saveInStorage = true,
  ): void {
    try {
      const clonedSettings = jQuery.extend(true, {}, settings);
      this.settings.next({ ...this.settings.value, ...clonedSettings });
      if (saveInStorage) this.saveState();
    } catch (err) {
      console.error(settings);
    }
  }
}
