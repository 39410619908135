<button
  [ngClass]="{
    'icon-lock': !isTradingGloballyEnabled,
    'icon-unlock': isTradingGloballyEnabled
  }"
  [class.locked]="!isTradingGloballyEnabled"
  [title]="isTradingGloballyEnabled ? 'Lock' : 'Unlock'"
  (click)="toggleGlobalTradingLock()"
></button>
<div class="{{ alert?.type }}-alert" *ngIf="alert?.visible">
  {{ alert?.text }}
</div>
