enum TextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface ICellSettings {
  backgroundColor?: string;
  positiveBackgroundColor?: string;
  negativeBackgroundColor?: string;
  positiveHoveredBackgroundColor?: string;
  negativeHoveredBackgroundColor?: string;
  hoveredBackgroundColor?: string;
  color?: string;
  textAlign?: TextAlign;
  highlightBackgroundColor?: string;
  fontSize?: number;
}

export interface ICell {
  component?: string;
  value: string;
  class?: string;
  colSpan?: number;
  settings?: ICellSettings;
  hoverStatusEnabled: boolean;
  negativeValueStatusEnabled?: boolean;

  updateValue(...args: any[]);

  clear();
}

export interface ICellConfig {
  settings?: ICellSettings;
  withHoverStatus?: boolean;
}

export enum CellStatusEnum {
  Negative = 'negative',
  Positive = 'positive',
  Highlight = 'highlight',
  PositiveHighlight = 'positiveHighlight',
  NegativeHighlight = 'negativeHighlight',
  Selected = 'selected',
  Hovered = 'hovered',
  PositiveHovered = 'positiveHovered',
  NegativeHovered = 'negativeHovered',
  Marked = 'priceMarker',
  PositiveMarked = 'positivePriceMarker',
  NegativeMarked = 'negativePriceMarker',
  None = '',
}
