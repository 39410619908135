// @todo Relocate this to Performance Tools module
/**
 * Decorator function to increment a counter for a specific method call
 */
export function IncrementCounter() {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      // Inject the counter increment call
      if (this.counter && typeof this.counter.incrementCount === 'function') {
        this.counter.incrementCount(propertyKey);
      }

      // Call the original method
      return originalMethod.apply(this, args);
    };

    return descriptor;
  };
}

/**
 * Decorator function to track execution time of a method
 */
export function TrackExecutionTime() {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const start = performance.now();
      const result = originalMethod.apply(this, args);
      const end = performance.now();
      const executionTime = end - start;

      if (this.counter && typeof this.counter.addExecutionTime === 'function') {
        this.counter.addExecutionTime(propertyKey, executionTime);
      }

      return result;
    };

    return descriptor;
  };
}

/**
 * Combined decorator to increment counter and track execution time
 */
export function TrackAndCount() {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      // Increment counter
      if (this.counter && typeof this.counter.incrementCount === 'function') {
        this.counter.incrementCount(propertyKey);
      }

      // Track execution time
      const start = performance.now();
      const result = originalMethod.apply(this, args);
      const end = performance.now();
      const executionTime = end - start;

      if (this.counter && typeof this.counter.addExecutionTime === 'function') {
        this.counter.addExecutionTime(propertyKey, executionTime);
      }

      return result;
    };

    return descriptor;
  };
}

function fromEntries<T = any>(entries: [string, T][]): { [key: string]: T } {
  return entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {} as { [key: string]: T },
  );
}

/**
 * @description Helper class to counts calls and execution times of any method.
 */
export class Counter<T> {
  private counters: Map<T, number>;
  private executionTimes: Map<T, number[]>;

  private intervalId: number | null;
  private name: string;

  constructor(name: string = 'Unnamed Counter') {
    this.counters = new Map<T, number>();
    this.executionTimes = new Map<T, number[]>();
    this.intervalId = null;
    this.name = name;
  }

  // Method to increment the count of a specific item
  incrementCount(item: T, incrementValue: number = 1): void {
    const currentCount = this.getCount(item);
    this.counters.set(item, currentCount + incrementValue);
  }

  setCount(item: T, countValue: number = 1): void {
    this.counters.set(item, countValue);
  }

  // Method to get the count of a specific item
  getCount(item: T): number {
    return this.counters.get(item) || 0;
  }

  // Method to add execution time for a specific item
  addExecutionTime(item: T, time: number): void {
    if (!this.executionTimes.has(item)) {
      this.executionTimes.set(item, []);
    }
    this.executionTimes.get(item)!.push(time);
  }

  // Method to get average execution time for a specific item
  getAverageExecutionTime(item: T): number {
    const times = this.executionTimes.get(item);
    if (!times || times.length === 0) {
      return 0;
    }
    const sum = times.reduce((acc, time) => acc + time, 0);
    return Math.round(sum / times.length);
  }

  // Method to display the statistics of all counters and execution times
  displayStats(): void {
    if (!this.counters.size && !this.executionTimes.size) {
      console.log(`${this.name} Statistics: No data available`);
      return;
    }

    const stats: { [key: string]: { count: number; time: number } } = {};

    // Combine counters and execution times
    const allKeys = new Set([
      ...this.counters.keys(),
      ...this.executionTimes.keys(),
    ]);

    allKeys.forEach((key) => {
      const count = this.getCount(key);
      const time = this.getAverageExecutionTime(key);
      stats[String(key)] = { count, time };
    });

    // Sort the stats object by count in descending order
    const sortedStats = fromEntries(
      Object.entries(stats).sort(([, a], [, b]) => b.count - a.count),
    );

    console.log(`${this.name} Statistics:`, sortedStats);
  }

  // Method to reset the count of a specific item
  resetCount(item: T): void {
    this.counters.delete(item);
    this.executionTimes.delete(item);
  }

  // Method to reset all counts
  resetAllCounts(): void {
    this.counters.clear();
    this.executionTimes.clear();
  }

  // Method to start displaying stats every x seconds
  startDisplayingStats(intervalSeconds: number): void {
    if (this.intervalId !== null) {
      return;
    }
    this.intervalId = setInterval(
      () => this.displayStats(),
      intervalSeconds * 1000,
    );
  }

  // Method to stop displaying stats
  stopDisplayingStats(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
