import { IBar, IDetails } from 'chart';
import { IInstrument } from 'trading';

import { normalizeBarDetails } from '../util';

export interface RevBarsResponse {
  bars: IBar[];
  isUp: boolean;
}

export interface IHistoryRequest {
  id: string | number;
  instrument: IInstrument;
  periodicity: string;
  barSize: number;
  barCount?: number;
  startTime?: number;
  endTime?: number;
  priceHistory?: boolean;
  // timeZoneOffset?: TimeSpan;
}

export class RevBarsCalculator {
  public calculateRevBars(
    barSize: number,
    tickSize: number,
    precision: number,
    history: IBar[],
    isPreviousUp: boolean | null,
  ): RevBarsResponse {
    const revInterval: number = barSize;

    let isUp: boolean = isPreviousUp !== null ? isPreviousUp : true;
    const offset: number = revInterval * tickSize;

    const revBars: IBar[] = [];
    revBars.push(history[0]);

    for (let i: number = 1; i < history.length; i++) {
      const bar: IBar = history[i];
      const lastRevBar: IBar = revBars[revBars.length - 1];

      if (isUp) {
        if (bar.close <= lastRevBar.high - offset) {
          isUp = false;
          revBars.push(bar);
        } else {
          const lastIndex: number = revBars.length - 1;
          revBars[lastIndex] = this.update(lastRevBar, bar);
        }
      } else {
        if (bar.close >= lastRevBar.low + offset) {
          isUp = true;
          revBars.push(bar);
        } else {
          const lastIndex: number = revBars.length - 1;
          revBars[lastIndex] = this.update(lastRevBar, bar);
        }
      }
    }

    // Sort bars by timestamp
    revBars.sort(
      (a: IBar, b: IBar): number => a.date.getTime() - b.date.getTime(),
    );

    revBars.forEach((rBar: IBar): void => {
      if (!rBar.details?.length) {
        return;
      }
      // For each bar, sorts its details by price and fills in missing details at price levels that had no trades
      rBar.details = normalizeBarDetails(rBar.details, tickSize, precision);
    });

    return { bars: revBars, isUp } as RevBarsResponse;
  }

  private update(lastRevBar: IBar, bar: IBar): IBar {
    const price: number = bar.close;
    lastRevBar.high = Math.max(
      Math.max(lastRevBar.high, bar.close),
      lastRevBar.open,
    );
    lastRevBar.low = Math.min(
      Math.min(lastRevBar.low, bar.close),
      lastRevBar.open,
    );

    lastRevBar.close = price;
    lastRevBar.volume += bar.volume;

    lastRevBar.details = this.updateBarDetails(lastRevBar.details, bar.details);

    return lastRevBar;
  }

  private updateBarDetails(
    currentDetails: IDetails[],
    newBarDetails: IDetails[],
  ): IDetails[] {
    if (!newBarDetails?.length) return currentDetails;

    for (const newBarDetail of newBarDetails) {
      const detail: IDetails = currentDetails.find(
        (x: IDetails) =>
          Math.abs(x.price - newBarDetail.price) < Number.EPSILON,
      );
      if (detail) {
        currentDetails = currentDetails.map((det: IDetails) => {
          if (Math.abs(det.price - newBarDetail.price) < Number.EPSILON) {
            det.volume += newBarDetail.volume;
            det.tradesCount += newBarDetail.tradesCount;
            det.askInfo.volume += newBarDetail.askInfo.volume;
            det.askInfo.tradesCount += newBarDetail.askInfo.tradesCount;
            det.bidInfo.volume += newBarDetail.bidInfo.volume;
            det.bidInfo.tradesCount += newBarDetail.bidInfo.tradesCount;
          }
          return det;
        });
      } else {
        currentDetails.push(newBarDetail);
      }
    }

    return currentDetails;
  }
}
