<window-header (close)="saveState()" [window]="this"></window-header>
<div class="d-flex flex-column h-100 p-1 perf-metrics-panel">
  <nz-collapse [nzBordered]="false"  class="perf-metrics-panel-collapse">
    <nz-collapse-panel
      *ngFor="let panel of panels; let i = index"
      [nzHeader]="panel.header"
      [(nzActive)]="panelStates[i]"
    >
      <ng-container [ngSwitch]="panel.type">
        <performance-metrics *ngSwitchCase="'metrics'"></performance-metrics>
        <app-stats *ngSwitchCase="'statistics'"></app-stats>
        <user-idle-detector *ngSwitchCase="'idle'"></user-idle-detector>
        <app-health-logs *ngSwitchCase="'health'"></app-health-logs>
        <browser-throttling-detector *ngSwitchCase="'throttling'"></browser-throttling-detector>
        <version-info *ngSwitchCase="'version'"></version-info>
      </ng-container>
    </nz-collapse-panel>
  </nz-collapse>
</div>
