import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { WebSocketFactoryService } from '../services/web-socket-factory.service';
import { WebSocketRegistryService } from '../services/web-socket-registry.service';
import { WebSocketService } from '../services/web-socket.service';
import { CommunicationComponent } from './communication.component';

function webSocketServiceFactory() {
  if (!window.deps.get('WebSocketService')) {
    window.deps.set('WebSocketService', new WebSocketService());
  } else {
  }
  return window.deps.get('WebSocketService');
}

@NgModule({
  declarations: [CommunicationComponent],
  imports: [],
  exports: [CommunicationComponent],
})
export class CommunicationModule {
  static forRoot(
    providers: Provider[],
  ): ModuleWithProviders<CommunicationModule> {
    return {
      ngModule: CommunicationModule,
      providers: [
        ...providers,
        WebSocketFactoryService,
        // WebSocketRegistryService,
        // {
        //   provide: WebSocketService,
        //   useFactory: webSocketServiceFactory
        // },
        {
          provide: WebSocketService,
          useClass: WebSocketRegistryService,
        },
      ],
    };
  }
}
