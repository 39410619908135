import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent extends FieldType {}
