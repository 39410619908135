import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



import { IBar } from 'chart';
import { IBaseItem } from 'communication';
import { DefaultRevBarVersion, RevBarVersions } from 'projects/chart/src/datafeed/bar-handlers/RevsHandler';
import { CustomPeriodicity, HistoryRepository } from 'trading';



import { BaseRepository } from './base-repository';


declare const moment: any;

export interface IHistoryItem extends IBaseItem, IBar {}

export function _mapResponseItem(item: any): IHistoryItem {
  return {
    id: item.id,
    date: moment.utc(item.timestamp).toDate(),
    open: item.openPrice,
    close:
      item.settlementPrice !== 'NaN' ? item.settlementPrice : item.closePrice,
    high: item.highPrice,
    low: item.lowPrice,
    volume: item.volume,
    details: item.details ?? [],
  };
}

const maxTickDateGap = 4 * 24 * 60 * 60 * 1000; // 4 days
const requestGap = 3 * 24 * 60 * 60 * 1000; // 3 days

@Injectable()
export class RealHistoryRepository
  extends BaseRepository<IHistoryItem>
  implements HistoryRepository
{
  protected get suffix(): string {
    return 'v2/History';
  }

  protected _mapResponseItem(item: any): IHistoryItem {
    return _mapResponseItem(item);
  }

  getItems(params: any): Observable<any> {
    if (!params.endDate) params.endDate = new Date();

    if (typeof params.startDate !== 'number')
      params.startDate = params.startDate?.getTime();
    if (typeof params.endDate !== 'number')
      params.endDate = params.endDate.getTime();

    if (params.Exchange === 'FAIRX') {
      params.id = params.Symbol;
    } else if (params?.Symbol) {
      params.id = params.Symbol;
    } else if (params?.productCode) {
      params.id = params.productCode;
      delete params.productCode;
    } else if (params?.id) {
      const [symbol, exchange] = params.id.split('.');
      params.id = symbol;
      params.Exchange = exchange || params.Exchange;
    }
    if (params.Periodicity === CustomPeriodicity.RENKO) {
      return this.makeRequest(params, '/renko');
    } else if (params.Periodicity === CustomPeriodicity.REVS) {
      const { headers, ...allParams } = this._mapItemsParams(params);
      const revBarVersion: RevBarVersions = DefaultRevBarVersion;

      console.log('Loading rev bars', {
        revBarVersion,
        allParams,
        params,
      });

      // vf1 RevBars on Frontend with v1 Tick Data
      if (revBarVersion === 'vf1') {
        params.Periodicity = CustomPeriodicity.TICK;
        params.overrideSuffix = 'History';
      }

      // vf2 RevBars on Frontend with v2 Tick Data
      if (!revBarVersion || revBarVersion === 'vf2') {
        params.Periodicity = CustomPeriodicity.TICK;
        params.overrideSuffix = 'v2/History';
      }

      // RevBars on Backend
      if (['vb1', 'vb2', 'vb3'].includes(revBarVersion)) {
        let versionedUriSegment: string = `/${this.suffix}`;
        switch (revBarVersion) {
          case 'vb1':
            versionedUriSegment = '/RevBars';
            break;
          case 'vb2':
            versionedUriSegment = '/v2/RevBars';
            break;
          case 'vb3':
            versionedUriSegment = '/v3/RevBars';
            break;
        }
        allParams.Periodicity = CustomPeriodicity.TICK;
        return this._http
          .get(
            this._communicationConfig.rithmic.http.url +
              'Indicators/' +
              params.id +
              versionedUriSegment,
            {
              params: new HttpParams({ fromObject: allParams }),
              headers,
            },
          )
          .pipe(
            map(({ result }: any) => {
              return {
                additionalInfo: {
                  isUp: result.isUp,
                },
                ...this._mapItemsResponse(result.bars, params),
              };
            }),
          );
      }
    }

    return super.getItems(params).pipe(
      map((res) => {
        const { requestParams, data } = res;
        if (requestParams.Periodicity === CustomPeriodicity.TICK)
          res.data = res.data.map((item) => {
            item.ticksCount = requestParams.BarSize;
            return item;
          });
        return res;
      }),
    );
  }

  makeRequest(params, path) {
    const { headers, ...allParams } = this._mapItemsParams(params);
    allParams.Periodicity = CustomPeriodicity.TICK;
    return this._http
      .get(
        this._communicationConfig.rithmic.http.url +
          'indicators/' +
          params.id +
          path,
        {
          params: new HttpParams({ fromObject: allParams }),
          headers,
        },
      )
      .pipe(map((item) => this._mapItemsResponse(item, params)));
  }
}
