import { Injectable, Injector } from '@angular/core';

import { IConnectionWebSocket } from 'communication';
import { DebugFlagService } from 'projects/performance-tools/src/lib/debug-flag.service';
import { IConnection } from 'trading';

import { CommunicationConfig } from '../http/communication.config';
import { ConnectionWebSocketService } from './connection.web-socket.service';
import { RProtocolConnectionWebSocketService } from './rprotocol/rprotocol-connection.web-socket.service';
import { RProtocolTemplateRegistryService } from './rprotocol/rprotocol-template-registry.service';
import { RITHMIC_INFRA_TYPE, WEB_SOCKET_TYPE } from './types';

@Injectable()
export class WebSocketFactoryService {
  constructor(
    protected _injector: Injector,
    protected _config: CommunicationConfig,
    protected _templateRegistry: RProtocolTemplateRegistryService,
    protected debugFlagService: DebugFlagService,
  ) {}

  build(
    connection: IConnection,
    type: WEB_SOCKET_TYPE = WEB_SOCKET_TYPE.RAPI,
    infraType: RITHMIC_INFRA_TYPE,
  ): IConnectionWebSocket {
    switch (type) {
      case WEB_SOCKET_TYPE.RAPI:
        return this.buildForRAPI(connection);
      case WEB_SOCKET_TYPE.RPROTOCOL:
        return this.buildForRProtocol(connection, infraType);
      default:
        throw new Error(`Unknown web socket type: ${type}`);
    }
    // return null; // never happens, but to make ts compiler happy.
  }

  buildForRAPI(connection: IConnection): IConnectionWebSocket {
    const instance = new ConnectionWebSocketService(
      this._injector,
      this._config,
    );
    instance.connection = connection;

    return instance;
  }

  buildForRProtocol(
    connection: IConnection,
    infraType: RITHMIC_INFRA_TYPE,
  ): IConnectionWebSocket {
    const websocketConnection: RProtocolConnectionWebSocketService =
      new RProtocolConnectionWebSocketService(
        this._injector,
        this._config,
        this._templateRegistry,
        infraType,
        this.debugFlagService,
      );
    websocketConnection.connection = connection;

    return websocketConnection;
  }
}
