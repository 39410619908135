import { Component, OnInit } from '@angular/core';

import { ILayoutNode, IStateProvider, LayoutNode } from 'layout';

export interface PerformanceToolsComponent extends ILayoutNode {}

interface IPerformanceToolsState {
  panelStates: boolean[];
}

/**
 * @description This component is responsible for displaying the performance tools.
 * It provides a list of panels to display different performance metrics & app health logs.
 */
@Component({
  selector: 'lib-performance-tools',
  templateUrl: './performance-tools.component.html',
  styleUrls: ['./performance-tools.component.scss'],
})
@LayoutNode()
export class PerformanceToolsComponent
  implements OnInit, IStateProvider<IPerformanceToolsState>
{
  panels = [
    { header: 'Metrics', type: 'metrics' },
    { header: 'Statistics', type: 'statistics' },
    { header: 'User Idle Detection', type: 'idle' },
    { header: 'App Health Logs', type: 'health' },
    { header: 'Browser Throttling', type: 'throttling' },
    { header: 'Version Info', type: 'version' },
  ];

  panelStates: boolean[] = new Array(this.panels.length).fill(false);

  constructor() {
    this.setTabIcon('icon-solidgram');
    this.setTabTitle('Performance Tools');
  }

  ngOnInit(): void {}

  saveState(): IPerformanceToolsState {
    return {
      panelStates: this.panelStates,
    };
  }
  loadState(state: IPerformanceToolsState): void {
    if (state?.panelStates) {
      this.panelStates = state.panelStates;
    }
  }
}
