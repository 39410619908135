import { AfterViewInit, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { mergeDeep } from 'base-components';
import { ILayoutNode, LayoutNode } from 'layout';
import * as clone from 'lodash.clonedeep';

import { ChartSettingsService } from './chart-settings.service';
import {
  chartReceiveKey,
  defaultChartSettings,
  IChartSettings,
  IChartSettingsState,
} from './settings';
import {
  generalFields,
  sessionFields,
  tradingFields,
  valueScale,
} from './settings-fields';

export interface ChartSettingsComponent extends ILayoutNode {}

export enum SettingsItems {
  General,
  ChartTrading,
  TradingHours,
  ValueScale,
}

const valueScaleMenuItem = {
  name: 'Value Scale',
  config: clone(valueScale),
  className: 'value-scale',
};

@UntilDestroy()
@Component({
  selector: 'chart-settings',
  templateUrl: 'chart-settings.component.html',
  styleUrls: ['chart-settings.component.scss'],
})
@LayoutNode()
export class ChartSettingsComponent implements AfterViewInit {
  form = new FormGroup({});
  settings: IChartSettings;

  menuItems = [
    {
      name: 'General',
      config: clone(generalFields),
      className: '',
    },
    {
      name: 'Chart Trading',
      config: clone(tradingFields),
      className: 'chart-trading',
    },
    {
      name: 'Trading Hours',
      config: clone(sessionFields),
    },
    valueScaleMenuItem,
  ];
  currentItem = this.menuItems[0];

  private _oldSettings: IChartSettings;

  private _linkKey: string;

  get linkKey() {
    return this._linkKey;
  }

  constructor(private _chartSettingsService: ChartSettingsService) {
    this.setTabTitle('Settings Chart');
    this.setTabIcon('icon-setting-gear');
    this.setIsSettings(true);
  }

  ngAfterViewInit() {
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((newSettings: IChartSettings): void => {
        this.settings = mergeDeep(this.settings, clone(newSettings));

        this.broadcastData(this._linkKey, this.settings);

        if (!newSettings.valueScale) {
          return;
        }

        if (this.currentItem.name === 'Value Scale') {
          this._chartSettingsService.$chartValueScaleUpdate.next({
            componentId: this._linkKey,
            oldValueScale: this._oldSettings.valueScale,
            newValueScale: newSettings?.valueScale,
          });
        }

        this._oldSettings = clone(this.settings);
      });
  }

  loadState(state: IChartSettingsState): void {
    this.settings = state?.settings
      ? state.settings
      : clone(defaultChartSettings);
    this._linkKey = state?.linkKey;
    if (state.menuItem != null) {
      this.selectItem(this.menuItems[state.menuItem]);
    }
    this.addLinkObserver({
      link: chartReceiveKey + this._linkKey,
      handleLinkData: (data) => {
        if (data.type === SettingsItems.ValueScale) {
          this.selectItem(valueScaleMenuItem);
          return;
        }
        try {
          this.settings = clone(data);
        } catch (error) {
          console.error(error);
        }
      },
    });
    this._oldSettings = clone(this.settings);
  }

  saveState(): IChartSettingsState {
    return {
      settings: clone(this.settings),
      linkKey: this._linkKey,
    };
  }

  selectItem(item): void {
    if (this.currentItem === item) {
      return;
    }

    this.currentItem = item;
  }
}
