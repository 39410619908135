export enum RProtocolMessageTemplateNameEnum {
  AccountListUpdates = 'AccountListUpdates',
  AccountPnLPositionUpdate = 'AccountPnLPositionUpdate',
  AccountRmsUpdates = 'AccountRmsUpdates',
  BestBidOffer = 'BestBidOffer',
  BracketUpdates = 'BracketUpdates',
  DepthByOrder = 'DepthByOrder',
  DepthByOrderEndEvent = 'DepthByOrderEndEvent',
  EndOfDayPrices = 'EndOfDayPrices',
  ExchangeOrderNotification = 'ExchangeOrderNotification',
  ForcedLogout = 'ForcedLogout',
  FrontMonthContractUpdate = 'FrontMonthContractUpdate',
  IndicatorPrices = 'IndicatorPrices',
  LastTrade = 'LastTrade',
  InstrumentPnLPositionUpdate = 'InstrumentPnLPositionUpdate',
  MarketMode = 'MarketMode',
  MessageType = 'MessageType',
  OpenInterest = 'OpenInterest',
  OrderBook = 'OrderBook',
  OrderPriceLimits = 'OrderPriceLimits',
  QuoteStatistics = 'QuoteStatistics',
  Reject = 'Reject',
  RequestAcceptAgreement = 'RequestAcceptAgreement',
  RequestAccountList = 'RequestAccountList',
  RequestAccountRmsInfo = 'RequestAccountRmsInfo',
  RequestAccountRmsUpdates = 'RequestAccountRmsUpdates',
  RequestAuxilliaryReferenceData = 'RequestAuxilliaryReferenceData',
  RequestBracketOrder = 'RequestBracketOrder',
  RequestCancelAllOrders = 'RequestCancelAllOrders',
  RequestCancelOrder = 'RequestCancelOrder',
  RequestDepthByOrderSnapshot = 'RequestDepthByOrderSnapshot',
  RequestDepthByOrderUpdates = 'RequestDepthByOrderUpdates',
  RequestEasyToBorrowList = 'RequestEasyToBorrowList',
  RequestExitPosition = 'RequestExitPosition',
  RequestFrontMonthContract = 'RequestFrontMonthContract',
  RequestGetInstrumentByUnderlying = 'RequestGetInstrumentByUnderlying',
  RequestGetVolumeAtPrice = 'RequestGetVolumeAtPrice',
  RequestGiveTickSizeTypeTable = 'RequestGiveTickSizeTypeTable',
  RequestHeartbeat = 'RequestHeartbeat',
  RequestLinkOrders = 'RequestLinkOrders',
  RequestListAcceptedAgreements = 'RequestListAcceptedAgreements',
  RequestListExchangePermissions = 'RequestListExchangePermissions',
  RequestListUnacceptedAgreements = 'RequestListUnacceptedAgreements',
  RequestLogin = 'RequestLogin',
  RequestLoginInfo = 'RequestLoginInfo',
  RequestLogout = 'RequestLogout',
  RequestMarketDataUpdate = 'RequestMarketDataUpdate',
  RequestMarketDataUpdateByUnderlying = 'RequestMarketDataUpdateByUnderlying',
  RequestModifyOrder = 'RequestModifyOrder',
  RequestModifyOrderReferenceData = 'RequestModifyOrderReferenceData',
  RequestNewOrder = 'RequestNewOrder',
  RequestOCOOrder = 'RequestOCOOrder',
  RequestOrderSessionConfig = 'RequestOrderSessionConfig',
  RequestPnLPositionSnapshot = 'RequestPnLPositionSnapshot',
  RequestPnLPositionUpdates = 'RequestPnLPositionUpdates',
  RequestProductCodes = 'RequestProductCodes',
  RequestProductRmsInfo = 'RequestProductRmsInfo',
  RequestReferenceData = 'RequestReferenceData',
  RequestReplayExecutions = 'RequestReplayExecutions',
  RequestResumeBars = 'RequestResumeBars',
  RequestRithmicSystemGatewayInfo = 'RequestRithmicSystemGatewayInfo',
  RequestRithmicSystemInfo = 'RequestRithmicSystemInfo',
  RequestSearchSymbols = 'RequestSearchSymbols',
  RequestSetRithmicMrktDataSelfCertStatus = 'RequestSetRithmicMrktDataSelfCertStatus',
  RequestShowAgreement = 'RequestShowAgreement',
  RequestShowBracketStops = 'RequestShowBracketStops',
  RequestShowBrackets = 'RequestShowBrackets',
  RequestShowOrderHistory = 'RequestShowOrderHistory',
  RequestShowOrderHistoryDates = 'RequestShowOrderHistoryDates',
  RequestShowOrderHistoryDetail = 'RequestShowOrderHistoryDetail',
  RequestShowOrderHistorySummary = 'RequestShowOrderHistorySummary',
  RequestShowOrders = 'RequestShowOrders',
  RequestSubscribeForOrderUpdates = 'RequestSubscribeForOrderUpdates',
  RequestSubscribeToBracketUpdates = 'RequestSubscribeToBracketUpdates',
  RequestTickBarReplay = 'RequestTickBarReplay',
  RequestTickBarUpdate = 'RequestTickBarUpdate',
  RequestTimeBarReplay = 'RequestTimeBarReplay',
  RequestTimeBarUpdate = 'RequestTimeBarUpdate',
  RequestTradeRoutes = 'RequestTradeRoutes',
  RequestUpdateStopBracketLevel = 'RequestUpdateStopBracketLevel',
  RequestUpdateTargetBracketLevel = 'RequestUpdateTargetBracketLevel',
  RequestVolumeProfileMinuteBars = 'RequestVolumeProfileMinuteBars',
  ResponseAcceptAgreement = 'ResponseAcceptAgreement',
  ResponseAccountList = 'ResponseAccountList',
  ResponseAccountRmsInfo = 'ResponseAccountRmsInfo',
  ResponseAccountRmsUpdates = 'ResponseAccountRmsUpdates',
  ResponseAuxilliaryReferenceData = 'ResponseAuxilliaryReferenceData',
  ResponseBracketOrder = 'ResponseBracketOrder',
  ResponseCancelAllOrders = 'ResponseCancelAllOrders',
  ResponseCancelOrder = 'ResponseCancelOrder',
  ResponseDepthByOrderUpdates = 'ResponseDepthByOrderUpdates',
  ResponseDepthByOrderSnapshot = 'ResponseDepthByOrderSnapshot',
  ResponseEasyToBorrowList = 'ResponseEasyToBorrowList',
  ResponseExitPosition = 'ResponseExitPosition',
  ResponseGetInstrumentByUnderlying = 'ResponseGetInstrumentByUnderlying',
  ResponseFrontMonthContract = 'ResponseFrontMonthContract',
  ResponseGetInstrumentByUnderlyingKeys = 'ResponseGetInstrumentByUnderlyingKeys',
  ResponseGetVolumeAtPrice = 'ResponseGetVolumeAtPrice',
  ResponseGiveTickSizeTypeTable = 'ResponseGiveTickSizeTypeTable',
  ResponseHeartbeat = 'ResponseHeartbeat',
  ResponseLinkOrders = 'ResponseLinkOrders',
  ResponseListAcceptedAgreements = 'ResponseListAcceptedAgreements',
  ResponseListExchangePermissions = 'ResponseListExchangePermissions',
  ResponseListUnacceptedAgreements = 'ResponseListUnacceptedAgreements',
  ResponseLogin = 'ResponseLogin',
  ResponseLoginInfo = 'ResponseLoginInfo',
  ResponseLogout = 'ResponseLogout',
  ResponseMarketDataUpdate = 'ResponseMarketDataUpdate',
  ResponseMarketDataUpdateByUnderlying = 'ResponseMarketDataUpdateByUnderlying',
  ResponseModifyOrder = 'ResponseModifyOrder',
  ResponseModifyOrderReferenceData = 'ResponseModifyOrderReferenceData',
  ResponseNewOrder = 'ResponseNewOrder',
  ResponseOCOOrder = 'ResponseOCOOrder',
  ResponseOrderSessionConfig = 'ResponseOrderSessionConfig',
  ResponsePnLPositionSnapshot = 'ResponsePnLPositionSnapshot',
  ResponsePnLPositionUpdates = 'ResponsePnLPositionUpdates',
  ResponseProductCodes = 'ResponseProductCodes',
  ResponseProductRmsInfo = 'ResponseProductRmsInfo',
  ResponseReferenceData = 'ResponseReferenceData',
  ResponseReplayExecutions = 'ResponseReplayExecutions',
  ResponseResumeBars = 'ResponseResumeBars',
  ResponseRithmicSystemGatewayInfo = 'ResponseRithmicSystemGatewayInfo',
  ResponseRithmicSystemInfo = 'ResponseRithmicSystemInfo',
  ResponseSearchSymbols = 'ResponseSearchSymbols',
  ResponseSetRithmicMrktDataSelfCertStatus = 'ResponseSetRithmicMrktDataSelfCertStatus',
  ResponseShowAgreement = 'ResponseShowAgreement',
  ResponseShowBracketStops = 'ResponseShowBracketStops',
  ResponseShowBrackets = 'ResponseShowBrackets',
  ResponseShowOrderHistory = 'ResponseShowOrderHistory',
  ResponseShowOrderHistoryDates = 'ResponseShowOrderHistoryDates',
  ResponseShowOrderHistoryDetail = 'ResponseShowOrderHistoryDetail',
  ResponseShowOrders = 'ResponseShowOrders',
  ResponseShowOrderHistorySummary = 'ResponseShowOrderHistorySummary',
  ResponseSubscribeForOrderUpdates = 'ResponseSubscribeForOrderUpdates',
  ResponseSubscribeToBracketUpdates = 'ResponseSubscribeToBracketUpdates',
  ResponseTickBarReplay = 'ResponseTickBarReplay',
  ResponseTickBarUpdate = 'ResponseTickBarUpdate',
  ResponseTimeBarReplay = 'ResponseTimeBarReplay',
  ResponseTimeBarUpdate = 'ResponseTimeBarUpdate',
  ResponseTradeRoutes = 'ResponseTradeRoutes',
  ResponseUpdateStopBracketLevel = 'ResponseUpdateStopBracketLevel',
  ResponseUpdateTargetBracketLevel = 'ResponseUpdateTargetBracketLevel',
  ResponseVolumeProfileMinuteBars = 'ResponseVolumeProfileMinuteBars',
  RithmicOrderNotification = 'RithmicOrderNotification',
  SymbolMarginRate = 'SymbolMarginRate',
  TickBar = 'TickBar',
  TimeBar = 'TimeBar',
  TradeRoute = 'TradeRoute',
  TradeStatistics = 'TradeStatistics',
  UserAccountUpdate = 'UserAccountUpdate',
  UpdateEasyToBorrowList = 'UpdateEasyToBorrowList',
}
