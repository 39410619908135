import { HashMap, IHashCode } from './hash-map';

export class ReversedHashMap<K, V extends IHashCode>
  extends Map
  implements Map<K, V>
{
  private readonly _reversed: HashMap<V, K> = new HashMap();

  constructor(itt: IterableIterator<[K, V]>) {
    super();
    for (const [k, v] of itt) {
      this.set(k, v);
    }
  }

  clear(): void {
    super.clear();
    this._reversed.clear();
  }

  delete(key: K): boolean {
    const val = super.get(key);
    this._reversed.delete(val);
    return super.delete(key);
  }

  set(k: K, v: V) {
    super.set(k, v);
    this._reversed.set(v, k);
    return this;
  }

  search(v: V): K {
    return this._reversed.get(v);
  }
}
