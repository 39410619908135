export class Browser {
    static get userAgent() { return navigator.userAgent };

    static get isEdge() { return (this.userAgent.indexOf('Edge/') >= 0) }
    static get isOpera() { return (this.userAgent.indexOf('Opera') >= 0) }
    static get isFirefox() { return (this.userAgent.indexOf('Firefox') >= 0) }
    static get isWebKit() { return (this.userAgent.indexOf('AppleWebKit') >= 0) }
    static get isChrome() { return (this.userAgent.indexOf('Chrome') >= 0) }
    static get isSafari() { return (!this.isChrome && (this.userAgent.indexOf('Safari') >= 0)) }
    static get isWebkitWebView() { return (!this.isChrome && !this.isSafari && this.isWebKit) }
    static get isIPad() { return (this.userAgent.indexOf('iPad') >= 0 || (this.isSafari && navigator.maxTouchPoints > 0)) }
    static get isEdgeWebView() { return this.isEdge && (this.userAgent.indexOf('WebView/') >= 0) }
    static get isElectron() { return  (this.userAgent.indexOf('Electron/') >= 0) }
    static get isStandalone() {return  (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) }

}