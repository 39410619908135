import {
  IScxComponentState,
  IStockChartXInstrument,
  IStockChartXTimeFrame,
  StockChartXPeriodicityEnum,
} from 'chart';
import { Id } from 'communication';
import { IBaseTemplate } from 'templates';
import { IAccount } from 'trading';

import { IChartSettings } from './chart-settings/settings';

declare const StockChartX: any;

export * from './models/index';
export * from './datafeed/index';

export interface IChartState extends IScxComponentState {
  isToolbarVisible: boolean;
  showOHLV: boolean;
  showChanges: boolean;
  showChartForm?: boolean;
  enableOrderForm: boolean;
  isTradingEnabled?: boolean;
  link: any;
  showOrderConfirm?: boolean;
  showCancelConfirm: boolean;
  account?: IAccount;
  preferredAccounts?: { [key in Id]: Id }; // connection ID => account ID
  instrument: IStockChartXInstrument;
  componentInstanceId: number;
  settings: IChartSettings;
  intervalOptions?: any;
  periodOptions?: any;
}
export type IChartTemplate = IBaseTemplate<IChartState>;

export type StockChartXOrderSideMarker = typeof StockChartX.OrderSideMarker;

export interface ICustomeVolumeState {
  settings: any;
}

export enum IntervalPeriodEnum {
  AmsRevsBar = 'AMS REVS Bar',
  Seconds = 'Seconds',
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
  Range = 'Range',
  Renko = 'Renko',
  Volume = 'Volume',
  Ticks = 'Ticks',
}

export enum IntervalPeriodToStockChartXPeriodicityEnum {
  Seconds = StockChartXPeriodicityEnum.SECOND,
  Minutes = StockChartXPeriodicityEnum.MINUTE,
  Hours = StockChartXPeriodicityEnum.HOUR,
  Days = StockChartXPeriodicityEnum.DAY,
  Range = StockChartXPeriodicityEnum.RANGE,
  Renko = StockChartXPeriodicityEnum.RENKO,
  Volume = StockChartXPeriodicityEnum.VOLUME,
  Ticks = StockChartXPeriodicityEnum.TICK,
}

export interface IIntervalOption {
  active: boolean;
  period: IntervalPeriodEnum;
  periodicities: string[];
  timeFrames: IStockChartXTimeFrame[];
}

export interface ITogglableIntervalOptions {
  [IntervalPeriodEnum.Range]: IIntervalOption;
  [IntervalPeriodEnum.Renko]: IIntervalOption;
  [IntervalPeriodEnum.Volume]: IIntervalOption;
  [IntervalPeriodEnum.Ticks]: IIntervalOption;
}

export enum PeriodToLoadEnum {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years',
}

export enum PeriodsToLoadToStockChartXPeriodicityEnum {
  Years = StockChartXPeriodicityEnum.YEAR,
}

export interface IPeriodToLoadOption {
  active: boolean;
  period: PeriodToLoadEnum;
  periodicity: string;
  timeFrames: IStockChartXTimeFrame[];
}

export interface ITogglablePeriodToLoadOptions {
  [PeriodToLoadEnum.Years]: IPeriodToLoadOption;
}

export enum PriceStyleEnum {
  heikinAshi = 'heikinAshi',
  bar = 'bar',
  coloredBar = 'coloredBar',
  candle = 'candle',
  hollowCandle = 'hollowCandle',
  renko = 'renko',
  lineBreak = 'lineBreak',
  kagi = 'kagi',
  candleVolume = 'candleVolume',
  equiVolume = 'equiVolume',
  equiVolumeShadow = 'equiVolumeShadow',
  line = 'line',
  mountain = 'mountain',
  pointAndFigure = 'pointAndFigure',
}

export enum PriceStyleNamesEnum {
  heikinAshi = 'Heikin Ashi',
  bar = 'Bars',
  coloredBar = 'Colored Bars',
  candle = 'Candle',
  hollowCandle = 'Hollow Candle',
  renko = 'Renko',
  lineBreak = 'Line Break',
  kagi = 'Kagi',
  candleVolume = 'Candle Volume',
  equiVolume = 'Equi Volume',
  equiVolumeShadow = 'Equi Volume Shadow',
  line = 'Line',
  mountain = 'Mountain',
  pointAndFigure = 'Point And Figure',
}

export type PriceStylesType = {
  [key in PriceStyleEnum]: PriceStyleNamesEnum;
};

export interface IndicatorModule {
  name: string;
  path: string;
}
