import { Injectable } from '@angular/core';

import { DataLevel, IInstrument, IQuote } from 'trading';

import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';
import { WSMessageTypes } from './ws-message-types';

@Injectable()
export class RealLevel2DataFeed extends RealFeed<IQuote, IInstrument> {
  feedType = RealtimeType.Quote;

  subscribeType = WSMessageTypes.SUBSCRIBE_L2;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE_L2;

  get dataLevel(): DataLevel {
    return DataLevel.Level2;
  }

  protected _filter(item: IQuote): boolean {
    return item.level === this.dataLevel;
  }
}
