import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
  clearInterval as clearIntervalWorker,
  setInterval as setIntervalWorker,
} from 'worker-timers';

import { LogConfig, Loggable } from '../../../../src/Loggable';

export enum MetricName {
  // Metrics provided by RProtocolConnectionWebSocketService
  TradeLatency = 'tradeLatency',
  AverageTradeLatency = 'averageTradeLatency',
  DOMTradeLatency = 'domTradeLatency',
  DOMAverageTradeLatency = 'domAverageTradeLatency',
  MessagesPerSec = 'messagesPerSec',
  AverageMessagesPerSec = 'averageMessagesPerSec',
  PeakMessagesPerSec = 'peakMessagesPerSec',
  WebSocketOrderPlantLatency = 'webSocketOrderPlantLatency',
  WebSocketTickerPlantLatency = 'webSocketTickerPlantLatency',
  WebSocketTickerPlantHealth = 'webSocketTickerPlantHealth',
  WebSocketOrderPlantHealth = 'webSocketOrderPlantHealth',

  // Metrics provided by BrowserThrottlingDetectorMetrics
  BatterLevel = 'batteryLevel',
  BatterCharging = 'batteryCharging',
  DocumentVisibility = 'documentVisibility',
  TimeoutDrift = 'timeoutDrift',
  RequestAnimationFrameDrift = 'requestAnimationFrameDrift',

  // Metrics provided by UserIdleDetectorMetrics
  UserIdleState = 'userIdleState',
  UserIdleTime = 'userIdleTime',

  // Metrics provided by UserNetworkLatencyMetrics
  UserNetworkLatency = 'userNetworkLatency',

  // Metrics provided by BrowserThrottlingCompositeDetector
  BrowserThrottlingDetected = 'browserThrottlingDetected',
  BrowserThrottlingDuration = 'browserThrottlingDuration',
}

export interface IMetric {
  name: MetricName;
  value: number;
}

export interface IReporterConfig {
  intervalId: number;
  instance: any;
  reporterType: string;
}

export interface IMetricReporter {
  collectMetrics(): IMetric[];
  resetMetrics(): void;
}

/**
 * @description This is a singleton service acts as a central hub where metrics reporters can send their metrics.
 * It provides methods to report metrics, subscribe to metric updates, and register/unregister reporters.
 */
@Injectable({
  providedIn: 'root',
})
export class PerformanceMetricsService extends Loggable {
  public logConfig: LogConfig = {
    enabled: false,
    configureMetadata: (args: string[]): void => {},
  };

  private metrics: { [key: string]: BehaviorSubject<IMetric | null> } = {};
  private activeReporters: Map<any, IReporterConfig> = new Map<
    any,
    IReporterConfig
  >();

  constructor() {
    super();
  }

  // Method to report a metric
  reportMetric(metricName: MetricName, value: number): void {
    if (!this.metrics[metricName]) {
      this.metrics[metricName] = new BehaviorSubject<IMetric | null>(null);
    }
    const metric: IMetric = { name: metricName, value };
    this.metrics[metricName].next(metric);
  }

  // Report multiple metrics at once
  reportMetrics(metrics: IMetric[]): void {
    metrics.forEach(({ name, value }) => {
      this.reportMetric(name, value);
    });
  }

  // Observable to subscribe to a specific metric
  getMetricUpdates(metricName: MetricName): Observable<IMetric | null> {
    if (!this.metrics[metricName]) {
      this.metrics[metricName] = new BehaviorSubject<IMetric | null>(null);
    }
    return this.metrics[metricName].asObservable();
  }

  // Observable to subscribe to multiple metrics at once
  getMultipleMetricUpdates(
    metricNames: MetricName[],
  ): Observable<{ [key: string]: IMetric | null }> {
    const metricObservables: Observable<IMetric | null>[] = metricNames.map(
      (name: MetricName): Observable<IMetric | null> =>
        this.getMetricUpdates(name),
    );

    return combineLatest(metricObservables).pipe(
      map((values: IMetric[]): { [key: string]: IMetric | null } => {
        const result: { [key: string]: IMetric | null } = {};
        metricNames.forEach((name: MetricName, index: number): void => {
          result[name] = values[index];
        });
        return result;
      }),
      distinctUntilChanged(
        (prev: { [p: string]: IMetric }, curr: { [p: string]: IMetric }) => {
          return metricNames.every(
            (name: MetricName): boolean =>
              prev[name]?.value === curr[name]?.value,
          );
        },
      ),
    );
  }

  destroy(): void {}

  registerReporter(reporter: IMetricReporter, reporterType?: string): void {
    if (!reporterType) {
      reporterType = reporter.constructor.name;
    }

    // Prevents multiple reporters of the same type from being registered
    if (this.activeReporters.has(reporterType)) {
      return;
    }

    const intervalId: number = setIntervalWorker((): void => {
      const metrics: IMetric[] = reporter.collectMetrics();
      if (!metrics?.length) return;
      this.reportMetrics(metrics);
    }, 1000);

    this.activeReporters.set(reporterType, {
      intervalId,
      instance: reporter,
      reporterType,
    } as IReporterConfig);
  }

  unregisterReporter(
    reporterInstance: IMetricReporter,
    reporterType?: string,
  ): void {
    if (!reporterType) {
      reporterType = reporterInstance.constructor.name;
    }

    const reporterConfig: IReporterConfig =
      this.activeReporters.get(reporterType);
    if (!reporterConfig) {
      return;
    }
    clearIntervalWorker(reporterConfig.intervalId);
    this.activeReporters.delete(reporterType);
  }

  resetMetrics(): void {
    // Make a copy of the active reporters
    const reporters: IReporterConfig[] = Array.from(
      this.activeReporters.values(),
    );

    // Unregister all reporters before resetting metrics, then later re-register them back
    Array.from(this.activeReporters.values()).forEach(
      (reporterConfig: IReporterConfig): void => {
        this.unregisterReporter(
          reporterConfig.instance,
          reporterConfig.reporterType,
        );
      },
    );

    Object.entries(this.metrics).forEach(
      ([metricName, metric]: [
        MetricName,
        BehaviorSubject<IMetric | null>,
      ]): void => metric.next({ name: metricName, value: 0 } as IMetric),
    );

    reporters.forEach((reporterConfig: IReporterConfig): void => {
      reporterConfig.instance.resetMetrics();
    });

    // Re-register reporters
    reporters.forEach((reporterConfig: IReporterConfig): void => {
      this.registerReporter(reporterConfig.instance);
    });
  }

  async copyMetricsToClipboard(): Promise<void> {
    const metrics: { [key: string]: number } = {};
    Object.entries(this.metrics).forEach(
      ([metricName, metric]: [
        MetricName,
        BehaviorSubject<IMetric | null>,
      ]): void => {
        metrics[metricName] = metric.value?.value || 0;
      },
    );
    return navigator.clipboard.writeText(JSON.stringify(metrics, null, '  '));
  }

  getAllMetrics(): { [key: string]: number } {
    // Collects all observed metrics as a plain object
    return Object.entries(this.metrics).reduce(
      (
        acc: { [key: string]: number },
        [key, value],
      ): {
        [key: string]: number;
      } => {
        acc[key] = value.value?.value || 0;
        return acc;
      },
      {},
    );
  }
}
