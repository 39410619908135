import { IBar } from 'chart';

import { BarAction, ChartBarHandler } from './ChartBarHandler';

export enum RevBarVersions {
  VB1 = 'vb1', // RevBars calculated on Backend (old method, many bugs)
  VB2 = 'vb2', // RevBarsV2 calculated on Backend, w/ sorted bar details (price levels)
  VB3 = 'vb3', // RevBarsV3 calculated on Backend, w/ sorted bar details (price levels) + improved error correction (limited)
  VF1 = 'vf1', // RevBars calculated on Frontend with v1 Tick Data
  VF2 = 'vf2', // RevBars calculated on Frontend with v2 Tick Data (w/ sorted bar details, more accurate but slower due error-correction)
}

export const DefaultRevBarVersion: RevBarVersions = RevBarVersions.VB3;

/**
 * `RevsBarHandler` computes reversal bars for the live bars only (i.e. it doesn't compute it for history data).
 * It expects the initial history data snapshot sent by the backend to already contain reversal bars (not regular bars).
 */
export class RevsBarHandler extends ChartBarHandler {
  isUp = true;

  _processRealtimeBar(bar: IBar, lastBar = this.getLastBar()): BarAction {
    return this._calculateBarAction(bar, lastBar);
  }

  setAdditionalInfo(additionalInfo: any): void {
    this.isUp = additionalInfo.isUp;
  }

  protected _calculateBarAction(bar: IBar, lastBar: IBar): BarAction {
    // TODO fix this so that it "breaks on new session" rather than at "local user's midnight"
    // if (lastBar.date.getDate() !== bar.date.getDate()) return BarAction.Add;

    const offset: number =
      this.chart.timeFrame.interval * this.chart.instrument.tickSize;

    if (this.isUp) {
      if (bar.close <= lastBar.high - offset) {
        this.isUp = false;
        return BarAction.Add;
      } else {
        // if uncommented, the Zigzag indicator returns infinity, because this sums last Bar and new Bar in recursion for realtime
        // this.updateLastBar(this._mapLastBar(bar, lastBar, true));
        return BarAction.Update;
      }
    } else {
      if (bar.close >= lastBar.low + offset) {
        this.isUp = true;
        return BarAction.Add;
      } else {
        // if uncommented, the Zigzag indicator returns infinity, because this sums last Bar and new Bar in recursion for realtime
        // this.updateLastBar(this._mapLastBar(bar, lastBar, true));
        return BarAction.Update;
      }
    }
  }

  processBars(bars: IBar[]): IBar[] {
    return bars;
    // if (!bars?.length)
    //   return [];
    //
    // const resultBars = [];
    // let lastBar;
    //
    // let arrayStarter = 0;
    // if (!lastBar) {
    //   lastBar = bars[0];
    //   arrayStarter++;
    // }
    // resultBars.push(lastBar);
    // for (let i = arrayStarter; i < bars.length; i++) {
    //   lastBar = resultBars[resultBars.length - 1] ?? bars[i];
    //   const bar = bars[i];
    //   const action: BarAction = this._calculateBarAction(bar, lastBar);
    //   if (action === BarAction.Add) {
    //     resultBars.push(bar);
    //     lastBar = bar;
    //   } else if (action === BarAction.Update) {
    //     lastBar = this._mapLastBar(bar, lastBar, true);
    //     resultBars[resultBars.length - 1] = lastBar;
    //   }
    // }
    // return resultBars;
  }

  clear(): void {
    super.clear();
    this.isUp = true;
  }
}
