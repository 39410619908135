import { ModuleWithProviders, NgModule } from '@angular/core';

import { WorkspacesManager } from './workspaces-manager';
import { WorkspacesStore } from './workspaces-storage';

@NgModule({
  declarations: [],
  imports: [],
  providers: [WorkspacesStore],
})
export class WorkspacesModule {
  static forRoot(): ModuleWithProviders<WorkspacesModule> {
    return {
      ngModule: WorkspacesModule,
      providers: [WorkspacesManager],
    };
  }
}
