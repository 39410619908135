import { KEY_CODE_MAP } from "../keycode-map";
import { KeyCode } from "../keycode.enum";
import { KeyCodeUtils } from "../keycode.utils";
import { KeyBindingPart } from "./key-binding-part";
import {  KeyboardListener } from "./keyboard-listener";

export class SettingsKeyboardListener extends KeyboardListener {

    constructor() {
        super();
    }

    private _onCanceled: Function = () => {};
    private _onFinished: Function = () => {};
    private _onCleared: Function = () => {};

    remove(part: KeyBindingPart) { }

    onKeyDown(event: KeyboardEvent) {
        switch (KEY_CODE_MAP[event.keyCode]) {
            case KeyCode.Backspace: {
                this.clear();
                this._onCleared()
                break;
            }
            case KeyCode.Enter: {
                this.finish();
                break;
            }
            case KeyCode.Escape: {
                this.cancel();
                break;
            }
            default: {
                super.onKeyDown(event);
            }
        }
    }

    onCanceled(fn: Function) {
        this._onCanceled = fn;
    }

    onFinised(fn: Function) {
        this._onFinished = fn;
    }

    onCleared(fn: Function) {
        this._onCleared = fn;
    }

    protected finish() {
        this._onFinished();
    }

    protected cancel() {
        this._onCanceled();
    }

}