import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NullCoalescingPipe } from './null-coalescing.pipe';

@NgModule({
  declarations: [NullCoalescingPipe],
  exports: [NullCoalescingPipe],
  imports: [CommonModule],
  entryComponents: [],
})
export class NullCalescingModule {}
