import { Inject, Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { WEB_SOCKET_TYPE, WebSocketService, WSEventType } from 'communication';
import { NotifierService } from 'notifier';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { Sound, SoundService } from 'sound';

import { reducer } from './handlers';
import {
  Notification,
  NotificationStatus,
  NotificationType,
} from './notification';
import { NotificationId } from './type';

@Injectable()
export class NotificationService extends NotifierService {
  protected _webSocketService: WebSocketService;
  protected _webSocketServicePRO: WebSocketService;
  private _notifications: Notification[] = [];

  public notifications: Subject<Notification[]> = new Subject();
  public newNotifications = this.notifications.pipe(
    map((arr) =>
      arr.filter((item) => item.status === NotificationStatus.UNREADED),
    ),
  );

  constructor(
    private _injector: Injector,
    @Inject(WebSocketRegistryService)
    protected _webSocketRegistryService: WebSocketRegistryService,
  ) {
    super();

    this._webSocketService = this._webSocketRegistryService.getWebSocketService(
      WEB_SOCKET_TYPE.RAPI,
    );
    this._webSocketService.on(
      WSEventType.Message,
      this._handleStream.bind(this),
    );
    this._webSocketServicePRO =
      this._webSocketRegistryService.getWebSocketService(
        WEB_SOCKET_TYPE.RPROTOCOL,
      );
    this._webSocketServicePRO.on(
      WSEventType.Message,
      this._handleStream.bind(this),
    );
  }

  showError(message: any, defaultMessage?: string) {
    const { _message, _title, additionalInfo } = this._prepareErrorMessage(
      message,
      defaultMessage,
    );
    const notification = new Notification({
      body: _message,
      title: _title,
      icon: 'notifcation-error',
      hoverInfo: additionalInfo,
      type: NotificationType.MESSAGE,
    });
    this.addNotification(notification);
  }

  showSuccess(message: string, hoverInfo?: string) {
    const notification = new Notification({
      body: message,
      icon: 'notication-success',
      title: 'Success',
      sound: false,
      hoverInfo,
    });
    this.addNotification(notification);
  }

  addNotification(notification: Notification) {
    this._injector.get(SoundService).play(Sound.ALERT);
    this._notifications.unshift(notification);
    this.notifications.next(this.getNotification());
  }

  public acceptNotification(notificationId: NotificationId): void {
    const index = this._notifications.findIndex((n) => n.id === notificationId);

    if (index === -1) return;

    this._notifications[index].status = NotificationStatus.ACCEPTED;

    this.notifications.next(this.getNotification());
  }

  public acceptAllNotifications() {
    this._notifications.forEach(
      (item) => (item.status = NotificationStatus.ACCEPTED),
    );
    this.notifications.next(this.getNotification());
  }

  public getNotification(): Notification[] {
    let _tempNotifications: Notification[] = [];
    var arrKey = [];
    this._notifications.forEach((item) => {
      if (arrKey.indexOf(item.body + '_' + item.createAt) < 0) {
        arrKey.push(item.body + '_' + item.createAt);
        _tempNotifications.push(item);
      }
    });
    this._notifications = _tempNotifications;
    return this._notifications;
  }

  public getNewNotifications(): Notification[] {
    return this._notifications.filter(
      (item) => item.type === NotificationStatus.UNREADED,
    );
  }

  private _handleStream(msg: any): void {
    const notification = reducer(msg);

    if (notification) {
      this.addNotification(notification);
    }
  }
}
