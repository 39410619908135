import { KeyCode } from "./keycode.enum";
import { UiMap } from "./ui-keycode-map";

export class KeyCodeUtils {
    static toString(keyCode: KeyCode): string {
		return UiMap.keyCodeToStr(keyCode);
    }
    
    static fromString(key: string): KeyCode {
		return UiMap.strToKeyCode(key);
	}
}
