<label class="mr-2">{{ label }}</label>
<nz-select
  class="custom-form-control w-auto flex-grow-1"
  [(nzOpen)]="opened"
  [nzLoading]="loading"
  [nzDropdownClassName]="dropdownClassName"
  [nzDisabled]="disabled"
  [nzOptionHeightPx]="24"
  [(ngModel)]="value"
  (ngModelChange)="handleValueChange()"
>
  <nz-option
    *ngIf="default"
    [nzValue]="default.id"
    [nzLabel]="default.name"
  ></nz-option>
  <nz-option
    *ngFor="let item of items"
    [nzValue]="item.id"
    [nzLabel]="item.name"
    [nzCustomContent]="true"
  >
    <div class="d-flex align-items-center w-100">
      <span
        title="{{ showTooltip ? item.name : null }}"
        [class.name-overflow]="withActions"
        class="mr-auto"
        >{{ item.name }}</span
      >
      <ng-container *ngIf="withActions">
        <span
          *ngFor="let action of actions"
          class="text-muted link data-select-item-action ml-2"
          (click)="executeItemAction(item, action); $event.stopPropagation()"
        >
          <i class="{{ action.icon }} font-size-inherit"></i>
        </span>
      </ng-container>
    </div>
  </nz-option>
</nz-select>
