/*jslint browser: true, unparam: true, todo: true, evil: true*/
/*globals Reflect: false, HTMLElement: true, define: true, MutationObserver: false, requestAnimationFrame: false, performance: false, btoa: false*/
'use strict';

import component from './component';
import defaults from './defaults';
import draw from './draw';
import events from './events';
import touch from './touch';
import intf from './intf';
import contextMenu from './contextMenu';
import dom from './dom';
import publicMethods from './publicMethods';

var webComponent = component();

var modules = [
  defaults,
  draw,
  events,
  touch,
  intf,
  // contextMenu,
  dom,
  publicMethods,
];

function Grid(args) {
  args = args || {};
  var self = {};
  self.isComponent = true;
  self.isChildGrid =
    args.parentNode &&
    /canvas-datagrid-(cell|tree)/.test(args.parentNode.nodeType);
  if (self.isChildGrid) {
    self.intf = document.createElement('canvas');
  } else {
    self.intf = self.isComponent
      ? eval('Reflect.construct(HTMLElement, [], new.target)')
      : document.createElement('canvas');
  }

  self.args = args;
  self.intf.args = args;
  self.applyComponentStyle = webComponent.applyComponentStyle;
  self.hyphenateProperty = webComponent.hyphenateProperty;
  self.dehyphenateProperty = webComponent.dehyphenateProperty;

  self.createGrid = function grid(args) {
    args.component = false;
    return new Grid(args);
  };

  self.setTimeout = args.setTimeout || setTimeout.bind(window);
  self.requestAnimationFrame = args.requestAnimationFrame || requestAnimationFrame.bind(window);

  modules.forEach(function (module) {
    module(self);
  });

  if (self.isChildGrid) {
    self.shadowRoot = args.parentNode.shadowRoot;
    self.parentNode = args.parentNode;
  } else {
    self.shadowRoot = self.intf.attachShadow({ mode: 'open' });
    self.parentNode = self.shadowRoot;
  }

  self.init();
  return self.intf;
}
if (window.HTMLElement) {
  Grid.prototype = Object.create(window.HTMLElement.prototype);
}
// export web component
if (window.customElements) {
  Grid.observedAttributes = webComponent.getObservableAttributes();
  Grid.prototype.disconnectedCallback = webComponent.disconnectedCallback;
  Grid.prototype.attributeChangedCallback =
    webComponent.attributeChangedCallback;
  Grid.prototype.connectedCallback = webComponent.connectedCallback;
  Grid.prototype.adoptedCallback = webComponent.adoptedCallback;
  window.customElements.define('canvas-datagrid', Grid);
}

// export global
if (
  window &&
  !window.canvasDatagrid &&
  !window.require &&
  // Present to exclude global declarations from ES Module bundles
  !window.EXCLUDE_GLOBAL
) {
  window.canvasDatagrid = function (args) {
    return new Grid(args);
  };
}

// export amd loader
export default function canvasDatagrid(args) {
  args = args || {};
  const i = new Grid(args);
  if (args.parentNode && args.parentNode.appendChild) {
    args.parentNode.appendChild(i);
  }
  return i;
}
