import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DynamicFormModule } from 'dynamic-form';
import { HotkeyInputModule } from 'hotkey-input';
import { ComponentStore } from 'lazy-modules';
import {
  NzButtonModule,
  NzDividerModule,
  NzLayoutModule,
  NzMessageServiceModule,
  NzRadioModule,
  NzSelectModule,
} from 'ng-zorro-antd';
import { SoundModule } from 'sound';
import { WindowHeaderModule } from 'window-header';

import { SettingsStore } from './setting-store';
import { SettingsComponent } from './settings.component';
import { SettingsService } from './settings.service';

@NgModule({
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    WindowHeaderModule,
    NzRadioModule,
    NzMessageServiceModule,
    HotkeyInputModule,
    NzButtonModule,
    SoundModule.forRoot(SettingsService),
    DynamicFormModule,
    NzLayoutModule,
    NzDividerModule,
  ],
  exports: [SettingsComponent],
  declarations: [SettingsComponent],
})
export class SettingsModule {
  static forRoot(): ModuleWithProviders<SettingsModule> {
    return {
      ngModule: SettingsModule,
      providers: [SettingsService, SettingsStore],
    };
  }

  get components(): ComponentStore {
    return {
      settings: SettingsComponent,
    };
  }
}
