import { RProtocolMessageTemplateNameEnum } from './rprotocol-message-template-name-enum';

export type IRProtocolMessageTemplateIds = {
  [key in RProtocolMessageTemplateNameEnum]: number;
};

export type RProtocolMessageTemplateNameType =
  keyof typeof RProtocolMessageTemplateIds;

/**
 * RProtocol Message Template Ids
 */
export const RProtocolMessageTemplateIds: IRProtocolMessageTemplateIds = {
  MessageType: 0,

  // 1.1 Templates Shared across Infrastructure Plants
  RequestLogin: 10, // From Client
  ResponseLogin: 11, // From Server
  RequestLogout: 12, // From Client
  ResponseLogout: 13, // From Server
  RequestReferenceData: 14, // From Client
  ResponseReferenceData: 15, // From Server
  RequestRithmicSystemInfo: 16, // From Client
  ResponseRithmicSystemInfo: 17, // From Server
  RequestHeartbeat: 18, // From Client
  ResponseHeartbeat: 19, // From Server
  RequestRithmicSystemGatewayInfo: 20, // From Client
  ResponseRithmicSystemGatewayInfo: 21, // From Server
  Reject: 75, // From Server
  UserAccountUpdate: 76, // From Server
  ForcedLogout: 77, // From Server

  // 1.2 Templates Specific to Market Data Infrastructure
  RequestMarketDataUpdate: 100, // From Client
  ResponseMarketDataUpdate: 101, // From Server
  RequestGetInstrumentByUnderlying: 102, // From Client
  ResponseGetInstrumentByUnderlying: 103, // From Server
  ResponseGetInstrumentByUnderlyingKeys: 104, // From Server
  RequestMarketDataUpdateByUnderlying: 105, // From Client
  ResponseMarketDataUpdateByUnderlying: 106, // From Server
  RequestGiveTickSizeTypeTable: 107, // From Client
  ResponseGiveTickSizeTypeTable: 108, // From Server
  RequestSearchSymbols: 109, // From Client
  ResponseSearchSymbols: 110, // From Server
  RequestProductCodes: 111, // From Client
  ResponseProductCodes: 112, // From Server
  RequestFrontMonthContract: 113, // From Client
  ResponseFrontMonthContract: 114, // From Server
  RequestDepthByOrderSnapshot: 115, // From Client
  ResponseDepthByOrderSnapshot: 116, // From Server
  RequestDepthByOrderUpdates: 117, // From Client
  ResponseDepthByOrderUpdates: 118, // From Server
  RequestGetVolumeAtPrice: 119, // From Client
  ResponseGetVolumeAtPrice: 120, // From Server
  RequestAuxilliaryReferenceData: 121, // From Client
  ResponseAuxilliaryReferenceData: 122, // From Server
  LastTrade: 150, // From Server
  BestBidOffer: 151, // From Server
  TradeStatistics: 152, // From Server
  QuoteStatistics: 153, // From Server
  IndicatorPrices: 154, // From Server
  EndOfDayPrices: 155, // From Server
  OrderBook: 156, // From Server
  MarketMode: 157, // From Server
  OpenInterest: 158, // From Server
  FrontMonthContractUpdate: 159, // From Server
  DepthByOrder: 160, // From Server
  DepthByOrderEndEvent: 161, // From Server
  SymbolMarginRate: 162, // From Server
  OrderPriceLimits: 163, // From Server

  // 1.3 Templates Specific to Order Plant Infrastructure
  RequestLoginInfo: 300, // From Client
  ResponseLoginInfo: 301, // From Server
  RequestAccountList: 302, // From Client
  ResponseAccountList: 303, // From Server
  RequestAccountRmsInfo: 304, // From Client
  ResponseAccountRmsInfo: 305, // From Server
  RequestProductRmsInfo: 306, // From Client
  ResponseProductRmsInfo: 307, // From Server
  RequestSubscribeForOrderUpdates: 308, // From Client
  ResponseSubscribeForOrderUpdates: 309, // From Server
  RequestTradeRoutes: 310, // From Client
  ResponseTradeRoutes: 311, // From Server
  RequestNewOrder: 312, // From Client
  ResponseNewOrder: 313, // From Server
  RequestModifyOrder: 314, // From Client
  ResponseModifyOrder: 315, // From Server
  RequestCancelOrder: 316, // From Client
  ResponseCancelOrder: 317, // From Server
  RequestShowOrderHistoryDates: 318, // From Client
  ResponseShowOrderHistoryDates: 319, // From Server
  RequestShowOrders: 320, // From Client
  ResponseShowOrders: 321, // From Server
  RequestShowOrderHistory: 322, // From Client
  ResponseShowOrderHistory: 323, // From Server
  RequestShowOrderHistorySummary: 324, // From Client
  ResponseShowOrderHistorySummary: 325, // From Server
  RequestShowOrderHistoryDetail: 326, // From Client
  ResponseShowOrderHistoryDetail: 327, // From Server
  RequestOCOOrder: 328, // From Client
  ResponseOCOOrder: 329, // From Server
  RequestBracketOrder: 330, // From Client
  ResponseBracketOrder: 331, // From Server
  RequestUpdateTargetBracketLevel: 332, // From Client
  ResponseUpdateTargetBracketLevel: 333, // From Server
  RequestUpdateStopBracketLevel: 334, // From Client
  ResponseUpdateStopBracketLevel: 335, // From Server
  RequestSubscribeToBracketUpdates: 336, // From Client
  ResponseSubscribeToBracketUpdates: 337, // From Server
  RequestShowBrackets: 338, // From Client
  ResponseShowBrackets: 339, // From Server
  RequestShowBracketStops: 340, // From Client
  ResponseShowBracketStops: 341, // From Server
  RequestListExchangePermissions: 342, // From Client
  ResponseListExchangePermissions: 343, // From Server
  RequestLinkOrders: 344, // From Client
  ResponseLinkOrders: 345, // From Server
  RequestCancelAllOrders: 346, // From Client
  ResponseCancelAllOrders: 347, // From Server
  RequestEasyToBorrowList: 348, // From Client
  ResponseEasyToBorrowList: 349, // From Server
  RequestModifyOrderReferenceData: 3500, // From Client
  ResponseModifyOrderReferenceData: 3501, // From Server
  RequestOrderSessionConfig: 3502, // From Client
  ResponseOrderSessionConfig: 3503, // From Server
  RequestExitPosition: 3504, // From Client
  ResponseExitPosition: 3505, // From Server
  RequestReplayExecutions: 3506, // From Client
  ResponseReplayExecutions: 3507, // From Server
  RequestAccountRmsUpdates: 3508, // From Client
  ResponseAccountRmsUpdates: 3509, // From Server
  TradeRoute: 350, // From Server
  RithmicOrderNotification: 351, // From Server
  ExchangeOrderNotification: 352, // From Server
  BracketUpdates: 353, // From Server
  AccountListUpdates: 354, // From Server
  UpdateEasyToBorrowList: 355, // From Server
  AccountRmsUpdates: 356, // From Server

  // 1.4 Templates Specific to History Plant Infrastructure
  RequestTimeBarUpdate: 200, // From Client
  ResponseTimeBarUpdate: 201, // From Server
  RequestTimeBarReplay: 202, // From Client
  ResponseTimeBarReplay: 203, // From Server
  RequestTickBarUpdate: 204, // From Client
  ResponseTickBarUpdate: 205, // From Server
  RequestTickBarReplay: 206, // From Client
  ResponseTickBarReplay: 207, // From Server
  RequestVolumeProfileMinuteBars: 208, // From Client
  ResponseVolumeProfileMinuteBars: 209, // From Server
  RequestResumeBars: 210, // From Client
  ResponseResumeBars: 211, // From Server
  TimeBar: 250, // From Server
  TickBar: 251, // From Server

  // 1.5 Templates Specific to PnL Plant
  RequestPnLPositionUpdates: 400, // From Client
  ResponsePnLPositionUpdates: 401, // From Server
  RequestPnLPositionSnapshot: 402, // From Client
  ResponsePnLPositionSnapshot: 403, // From Server
  InstrumentPnLPositionUpdate: 450, // From Server
  AccountPnLPositionUpdate: 451, // From Server

  // 1.6 Templates Specific to Repository Plant
  RequestListUnacceptedAgreements: 500, // From Client
  ResponseListUnacceptedAgreements: 501, // From Server
  RequestListAcceptedAgreements: 502, // From Client
  ResponseListAcceptedAgreements: 503, // From Server
  RequestAcceptAgreement: 504, // From Client
  ResponseAcceptAgreement: 505, // From Server
  RequestShowAgreement: 506, // From Client
  ResponseShowAgreement: 507, // From Server
  RequestSetRithmicMrktDataSelfCertStatus: 508, // From Client
  ResponseSetRithmicMrktDataSelfCertStatus: 509, // From Server
};
