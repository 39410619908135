<div>
  {{ message }}
</div>
<div *nzModalFooter>
  <button
    nz-button
    nzType="primary"
    class="ant-btn ant-btn-primary"
    (click)="handleOk()"
  >
    {{ confirmText }}
  </button>
  <button nz-button class="ant-btn modal-btn" (click)="handleCancel()">
    {{ cancelText }}
  </button>
</div>
