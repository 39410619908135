import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Sentry from '@sentry/angular';

import {
  AppHealthLogEntry,
  AppHealthLogger,
} from '../app-health-logs/app-health-logger';
import {
  IMetric,
  IMetricReporter,
  MetricName,
  PerformanceMetricsService,
} from '../performance-metrics.service';

interface ThresholdState {
  exceeds: boolean;
  value: number;
}

interface SustainedState extends ThresholdState {
  isSustained: boolean;
}

interface ThrottlingState {
  isThrottling: boolean;
  driftValue: number;
  latencyValue: number;
}

/**
 * @description Monitors and reports on browser throttling events.
 * @todo This needs to be moved inside a web worker, running it on the main thread is not reliable due to throttling.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class BrowserThrottlingCompositeDetector
  implements IMetricReporter, OnDestroy
{
  private subscriptions: Subscription = new Subscription();
  private destroy$: Subject<void> = new Subject<void>();

  private throttlingDetected$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private throttlingDuration$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private throttlingStartTime: number | null = null;

  private readonly ANIMATION_DRIFT_THRESHOLD_MS: number = 200; // Threshold for RAF or setInterval time drift in ms
  private readonly SUSTAINED_DRIFT_DURATION_MS: number = 5000; // Duration for sustained drift in ms

  private readonly HIGH_TRADE_LATENCY_THRESHOLD_MS: number = 200; // Threshold for high trade latency in ms
  private readonly SUSTAINED_HIGH_LATENCY_DURATION_MS: number = 5000; // Duration for sustained high trade latency in ms

  constructor(
    private metricsService: PerformanceMetricsService,
    private logger: AppHealthLogger,
  ) {
    this.metricsService.registerReporter(this);
  }

  private sustainedDriftAlert(state: SustainedState): void {
    if (state.exceeds) {
      this.logger?.warning(
        `Sustained Drift Alert Started: ${state.value.toFixed(0)}ms`,
      );
    } else {
      this.logger?.info(
        `Sustained Drift Alert Ended: ${state.value.toFixed(0)}ms`,
      );
    }
  }

  private sustainedHighLatencyAlert(state: SustainedState): void {
    if (state.exceeds) {
      this.logger?.warning(
        `Sustained High Trade Latency Alert Started: ${state.value.toFixed(0)}ms`,
      );
    } else {
      this.logger?.info(
        `Sustained High Trade Latency Alert Ended: ${state.value.toFixed(0)}ms`,
      );
    }
  }

  startThrottlingDetection(): void {
    this.logger?.info('Starting Browser Throttling Detection');

    // Unsubscribe from any existing subscriptions
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();

    const rafDrift$: Observable<IMetric> = this.metricsService.getMetricUpdates(
      MetricName.RequestAnimationFrameDrift,
    );

    const timeoutDrift$: Observable<IMetric> =
      this.metricsService.getMetricUpdates(MetricName.TimeoutDrift);

    const tradeLatency$: Observable<IMetric> =
      this.metricsService.getMetricUpdates(MetricName.TradeLatency);

    const driftExceedsThreshold$: Observable<ThresholdState> = combineLatest([
      rafDrift$,
      timeoutDrift$,
    ]).pipe(
      filter(([raf, timeout]) => !!raf && !!timeout),
      takeUntil(this.destroy$),
      map(([raf, timeout]: [IMetric, IMetric]): ThresholdState => {
        const maxDrift: number = Math.max(raf?.value || 0, timeout?.value || 0);
        return {
          exceeds: maxDrift > this.ANIMATION_DRIFT_THRESHOLD_MS,
          value: maxDrift,
        };
      }),
      distinctUntilChanged(
        (prev: ThresholdState, curr: ThresholdState) =>
          // prev.exceeds === curr.exceeds && prev.value === curr.value, // @todo Do we care about testing the value?
          prev.exceeds === curr.exceeds,
      ),
    );

    const highLatency$: Observable<ThresholdState> = tradeLatency$.pipe(
      takeUntil(this.destroy$),
      filter((metric): boolean => !!metric),
      map((metric: IMetric): ThresholdState => {
        const latency = metric?.value || 0;
        return {
          exceeds: latency > this.HIGH_TRADE_LATENCY_THRESHOLD_MS,
          value: latency,
        };
      }),
      distinctUntilChanged(
        (prev: ThresholdState, curr: ThresholdState) =>
          // prev.exceeds === curr.exceeds && prev.value === curr.value, // @todo Do we care about testing the value?
          prev.exceeds === curr.exceeds,
      ),
    );

    const sustainedDrift$: Observable<SustainedState> =
      this.createSustainedConditionObservable(
        driftExceedsThreshold$,
        this.SUSTAINED_DRIFT_DURATION_MS,
        this.sustainedDriftAlert.bind(this),
      );

    const sustainedHighLatency$: Observable<SustainedState> =
      this.createSustainedConditionObservable(
        highLatency$,
        this.SUSTAINED_HIGH_LATENCY_DURATION_MS,
        this.sustainedHighLatencyAlert.bind(this),
      );

    this.subscriptions.add(
      combineLatest([sustainedDrift$, sustainedHighLatency$])
        .pipe(
          takeUntil(this.destroy$),
          map(
            ([drift, latency]: [
              SustainedState,
              SustainedState,
            ]): ThrottlingState => ({
              isThrottling: drift.isSustained && latency.isSustained,
              driftValue: drift.value,
              latencyValue: latency.value,
            }),
          ),
          distinctUntilChanged(
            (prev: ThrottlingState, curr: ThrottlingState) =>
              prev.isThrottling === curr.isThrottling,
          ),
          untilDestroyed(this),
        )
        .subscribe(
          ({ isThrottling, driftValue, latencyValue }: ThrottlingState) => {
            if (isThrottling) {
              if (this.throttlingStartTime === null) {
                this.throttlingStartTime = Date.now();
              }
              this.updateThrottlingDuration();
              this.logger?.critical(
                `Possible Browser Throttling Detected (sustained drift: ${driftValue.toFixed(2)}ms, ` +
                  `sustained trade latency: ${latencyValue.toFixed(2)}ms)`,
              );
              // this.reportThrottlingToSentry(); // @todo Should we report this here as soon as it happens?
            } else {
              const duration: number | null = this.getThrottlingDuration();
              this.logger?.critical(
                `Browser Throttling No Longer Detected (duration: ${duration ? duration.toFixed(0) + 'ms' : 'n/a'})`,
              );
              this.throttlingStartTime = null;
              this.throttlingDuration$.next(0);
            }
            this.throttlingDetected$.next(isThrottling);
          },
        ),
    );
  }

  private createSustainedConditionObservable(
    condition$: Observable<ThresholdState>,
    duration: number,
    alertFn?: (state: SustainedState) => void,
  ): Observable<SustainedState> {
    return condition$.pipe(
      // Skip the initial emission since it's always a false positive
      skip(1),
      switchMap(
        (state: ThresholdState): Observable<SustainedState> =>
          state.exceeds
            ? // @todo When throttled, the `timer()` call would be skewed as well, find a way to avoid this skew
              // (maybe use `worker-timers`? or move the detector into a worker?)
              timer(0, 1000).pipe(
                takeUntil(this.destroy$),
                takeWhile(() => state.exceeds),
                filter((count: number) => count * 1000 >= duration),
                map(() => ({ ...state, isSustained: true })),
              )
            : of({ ...state, isSustained: false }),
      ),
      // @todo Check why this breaks the "Browser Throttling Detected" state
      // distinctUntilChanged(
      //   (prev, curr) =>
      //     prev.exceeds === curr.exceeds &&
      //     prev.isSustained === curr.isSustained,
      // ),
      tap((sustainedState: SustainedState) => {
        if (alertFn) {
          alertFn(sustainedState);
        }
      }),
    );
  }

  private updateThrottlingDuration(): number | null {
    if (this.throttlingStartTime === null) {
      return null;
    }
    const duration: number = this.getThrottlingDuration();
    this.throttlingDuration$.next(duration);

    return duration;
  }

  getThrottlingDuration(): number | null {
    return this.throttlingStartTime
      ? Date.now() - this.throttlingStartTime
      : null;
  }

  private async reportThrottlingToSentry(): Promise<void> {
    const metrics: { [key: string]: number } =
      this.metricsService.getAllMetrics();
    const logs: AppHealthLogEntry[] = this.logger.getAllLogs();
    Sentry.captureMessage('Possible Browser Throttling Detected', {
      extra: {
        metrics,
        logs,
        throttlingDuration: this.throttlingDuration$.value,
      },
    });
  }

  public get isThrottling$(): Observable<boolean> {
    return this.throttlingDetected$.asObservable();
  }

  public getThrottlingDuration$(): Observable<number> {
    return this.throttlingDuration$.asObservable();
  }

  collectMetrics(): IMetric[] {
    return [
      {
        name: MetricName.BrowserThrottlingDetected,
        value: this.throttlingDetected$.value ? 1 : 0,
      },
      {
        name: MetricName.BrowserThrottlingDuration,
        value: this.throttlingDuration$.value,
      },
    ];
  }

  resetMetrics(): void {
    this.throttlingDetected$.next(false);
    this.throttlingDuration$.next(0);
    this.throttlingStartTime = null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.unsubscribe();
    this.metricsService.unregisterReporter(this);
  }
}
