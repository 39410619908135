import { Id } from 'communication';

import { IAccount } from './account';
import { IConnection } from './connection';

export abstract class ConnectionContainer {
  abstract getConnectionByAccountId(accountId: Id): IConnection;
  abstract getAccountsByConnection(connectionId: Id): IAccount[];
  abstract getConnection(connectionId: Id): IConnection;
}
