import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { Id, IWebSocketStats } from 'communication';
import { IConnection } from 'trading';

import { IConnectionWebSocket } from './connection.web-socket.interface';
import { ConnectionWebSocketService } from './connection.web-socket.service';
import { RProtocolConnectionWebSocketService } from './rprotocol/rprotocol-connection.web-socket.service';
import {
  ConnectionHealthIndicator,
  IWebSocketAllStats,
  IWSListener,
  IWSListenerUnsubscribe,
  RITHMIC_INFRA_TYPE,
  WEB_SOCKET_TYPE,
  WSEventType,
} from './types';
import { WebSocketFactoryService } from './web-socket-factory.service';
import { IWebSocketService } from './web-socket-service.interface';
import { WebSocketService } from './web-socket.service';

/**
 * `WebSocketRegistryService` is a unified registry that holds all WebSockets, for both R Protocol and R API.
 * @name WebSocketRegistryService
 */
@Injectable({
  providedIn: 'root',
})
export class WebSocketRegistryService implements IWebSocketService {
  // R API WebSockets
  private _rapiWebSockets = new WebSocketService();

  // R Protocol WebSockets
  private _rproWebSockets = new WebSocketService();

  private _instances = new Map<string, any>();

  private _connectionHealthSubscriptions: Map<
    Id,
    Observable<ConnectionHealthIndicator>
  > = new Map();

  constructor(private _factory: WebSocketFactoryService) {}

  getWebSocket(id: Id): ConnectionWebSocketService {
    throw new Error('Method not implemented.');
  }

  public getWebSocketService(webSocketType: WEB_SOCKET_TYPE): WebSocketService {
    if (webSocketType === WEB_SOCKET_TYPE.RPROTOCOL) {
      return this._rproWebSockets;
    }

    return this._rapiWebSockets;
  }

  private getRegistryForService(service: any): WebSocketService {
    return service instanceof RProtocolConnectionWebSocketService
      ? this._rproWebSockets
      : this._rapiWebSockets;
  }

  getRProtocol(
    connection: IConnection,
    infraType: RITHMIC_INFRA_TYPE,
  ): IConnectionWebSocket {
    return this.get(connection, WEB_SOCKET_TYPE.RPROTOCOL, infraType);
  }

  getRProtocolConnectionsHealthStatus(
    connection: IConnection,
  ): Observable<ConnectionHealthIndicator> {
    if (this._connectionHealthSubscriptions.has(connection.id)) {
      return this._connectionHealthSubscriptions.get(connection.id);
    }

    // Observes the health state of both RProtocol connections (Order Plant and Ticker Plant) and emits the worst one
    const observable: Observable<ConnectionHealthIndicator> = combineLatest([
      this.getRAPI(connection).healthState$,
      this.getRProtocolTickerPlant(connection).healthState$,
      this.getRProtocolOrderPlant(connection).healthState$,
    ]).pipe(
      map(
        ([tradrrPlantHealth, tickerPlantHealth, orderPlantHealth]: [
          ConnectionHealthIndicator,
          ConnectionHealthIndicator,
          ConnectionHealthIndicator,
        ]) => {
          // @todo Replace `null` with an enum (`ConnectionHealthIndicator.Unknown` ?)
          if (
            tradrrPlantHealth === null ||
            tickerPlantHealth === null ||
            orderPlantHealth === null
          ) {
            return null;
          }
          const worstHealth: ConnectionHealthIndicator = Math.min(
            tradrrPlantHealth,
            tickerPlantHealth,
            orderPlantHealth,
          );
          return worstHealth;
        },
      ),
      filter((health: ConnectionHealthIndicator) => {
        if (health === connection.healthIndicator) {
          return false;
        }
        connection.healthIndicator = health;
        return true;
      }),
      distinctUntilChanged(),
    );

    this._connectionHealthSubscriptions.set(connection.id, observable);

    return observable;
  }

  getRProtocolOrderPlant(connection: IConnection): IConnectionWebSocket {
    return this.getRProtocol(connection, RITHMIC_INFRA_TYPE.ORDER_PLANT);
  }

  getRProtocolTickerPlant(connection: IConnection): IConnectionWebSocket {
    return this.getRProtocol(connection, RITHMIC_INFRA_TYPE.TICKER_PLANT);
  }

  getRAPI(connection: IConnection): IConnectionWebSocket {
    return this.get(connection, WEB_SOCKET_TYPE.RAPI);
  }
  get(
    connection: IConnection,
    type: WEB_SOCKET_TYPE = WEB_SOCKET_TYPE.RAPI,
    infraType?: RITHMIC_INFRA_TYPE,
  ): IConnectionWebSocket | RProtocolConnectionWebSocketService {
    /**
     * @example Example 1: 07bc7ad8-24d8-434d-9b6d-5cec6bf36688-RPROTOCOL-TICKER_PLANT
     *          Example 2: 07bc7ad8-24d8-434d-9b6d-5cec6bf36688-RAPI
     */
    const webSocketID: string = `${connection.id}-${type}${infraType ? '-' + infraType : ''}`;
    let newWebsocket: IConnectionWebSocket;

    if (this._instances.has(webSocketID)) {
      return this._instances.get(webSocketID) as ConnectionWebSocketService;
    }

    newWebsocket = this._factory.build(connection, type, infraType);
    newWebsocket.setService(this);

    if (this._instances.has(webSocketID)) {
      return this._instances.get(
        webSocketID,
      ) as RProtocolConnectionWebSocketService;
    }

    this._instances.set(webSocketID, newWebsocket);

    return newWebsocket;
  }

  send(data: any, connectionId: Id): void {
    this._rapiWebSockets.send(data, connectionId);
  }

  sendByWebSocketType(
    webSocketType: WEB_SOCKET_TYPE,
    data: any,
    connectionId: Id,
  ): void {
    if (webSocketType === WEB_SOCKET_TYPE.RPROTOCOL) {
      return this._rproWebSockets.send(data, connectionId);
    }

    this._rapiWebSockets.send(data, connectionId);
  }

  startDraining(connectionId: Id): void {
    this._rapiWebSockets.startDraining(connectionId);
  }

  onRProtocol(
    type: WSEventType,
    listener: IWSListener,
    callerClass?: string,
  ): IWSListenerUnsubscribe {
    return this._rproWebSockets.on(type, listener, callerClass);
  }

  onRAPI(
    type: WSEventType,
    listener: IWSListener,
    callerClass?: string,
  ): IWSListenerUnsubscribe {
    return this._rapiWebSockets.on(type, listener, callerClass);
  }
  onBoth(
    type: WSEventType,
    listener: IWSListener,
    callerClass?: string,
  ): IWSListenerUnsubscribe[] {
    return [
      this.onRProtocol(type, listener, callerClass),
      this.onRAPI(type, listener, callerClass),
    ];
  }

  onByWebSocketType(
    webSocketType: WEB_SOCKET_TYPE,
    type: WSEventType,
    listener: IWSListener,
    callerClass?: string,
  ): IWSListenerUnsubscribe {
    if (webSocketType === WEB_SOCKET_TYPE.RPROTOCOL) {
      return this.onRProtocol(type, listener, callerClass);
    }
    return this.onRAPI(type, listener, callerClass);
  }

  on(
    type: WSEventType,
    listener: IWSListener,
    callerClass?: string,
  ): IWSListenerUnsubscribe {
    return this.onRAPI(type, listener, callerClass);
  }

  private getMapKey(id: Id, service: any): Id {
    if (service instanceof RProtocolConnectionWebSocketService) {
      return `${id}-${service.infraType}`;
    }
    return id;
  }

  register(id: Id, service: any): void {
    const mapKey = this.getMapKey(id, service);
    this.getRegistryForService(service).register(mapKey, service);
  }

  unregister(id: Id, service: any): void {
    const mapKey = this.getMapKey(id, service);
    this.getRegistryForService(service).unregister(mapKey, service);
  }

  getWebSocketsStats(): IWebSocketAllStats {
    const webSocketStats: IWebSocketStats[] = Array.from(
      this._instances.values(),
    ).map((instance: ConnectionWebSocketService) => instance.getStats());

    const busiestWebSocketStats: IWebSocketStats = webSocketStats.reduce(
      (prev: IWebSocketStats, current: IWebSocketStats) =>
        prev.messages > current.messages ? prev : current,
      webSocketStats[0],
    );

    return { webSocketStats, busiestWebSocketStats } as IWebSocketAllStats;
  }
}
