import { AlertType } from 'communication';
import { OrderStatus } from 'trading';

// Need long import otherwise there is circular dependency
import { RealtimeType } from '../../../real-trading/src/trading/repositories/realtime';
import { ConnectionMessageAggregate } from './connection-message.aggregate';
import { MessageTypes } from './enums';
import { Notification } from './notification';

const successConnectionMessage = new ConnectionMessageAggregate();
const failureConnectionMessage = new ConnectionMessageAggregate();

const errorAlerts = [AlertType.ServiceError, AlertType.ShutdownSignal];
const connectionsErrors = [
  AlertType.ConnectionClosed,
  AlertType.LoginFailed,
  AlertType.ConnectionBroken,
  AlertType.ForcedLogout,
];

const connectedAlerts = [AlertType.ConnectionOpened, AlertType.LoginComplete];

export const handlers = {
  DEFAULT: (msg) => {
    if (msg.type === RealtimeType.Delay) {
      return new Notification({
        body: `Check your internet connection. Delay is ${msg.result.timeDelay / 1000}s for ${msg.result.connection.name}.`,
        title: 'Connection',
        timestamp: msg.result.now,
        icon: 'notication-default',
      });
    } else if (msg.type === RealtimeType.Activity) {
      return new Notification({
        body: `Connection ${msg.result.connection.name} doesn't emit data. There may be problems with connection.`,
        title: 'Connection',
        timestamp: Date.now(),
        icon: 'notication-default',
      });
    }
  },

  [MessageTypes.CONNECT]: (msg) => {
    const { type, timestamp, connectionId, technicalError } = msg.result;

    let icon;
    let message;

    if (errorAlerts.includes(type)) {
      icon = 'notifcation-error';
      message = 'Failed to connect';
    } else if (connectedAlerts.includes(type)) {
      icon = 'notication-connected';
      message = msg.result?.message ?? 'Login Complete';
    } else if (connectionsErrors.includes(type)) {
      icon = 'notication-disconnected';
      message = msg.result?.message ?? 'Connection Closed';
    } else {
      icon = 'notication-default';
      message = msg.result.message ?? '';
    }

    const params = {
      msg,
      message,
      connectionId,
      icon,
      type,
      timestamp,
      technicalError,
    };
    if (type === AlertType.LoginComplete)
      return getConnectionNotification(successConnectionMessage, params);

    if (type === AlertType.ConnectionClosed)
      return getConnectionNotification(failureConnectionMessage, params);

    return new Notification({
      body: message,
      type,
      title: 'Connection',
      hoverInfo: technicalError,
      timestamp,
      icon,
    });
  },
  [MessageTypes.ERROR]: (msg) => {
    return new Notification({
      title: 'Error',
      type: msg.type,
      body: msg.result.value,
      timestamp: msg.result.timestamp,
      icon: 'notifcation-error',
    });
  },

  [MessageTypes.ORDER]: (msg) => {
    if (msg.result.status !== OrderStatus.Rejected) return;

    /**
     * @description Workaround. We have found out that when we try to
     * create a Stop Market order in incorrect side the order gets rejected for our original quantity
     * but additionally also seems to get rejected for an order of 0 quantity as if we are
     * creating two orders by sending two requests instead of one.
     * We want to filter out the notification with the value of 0 for quantity.
     * @todo Remove the workaround if we find a better solution to appraoch this issue.
     */
    if (
      msg.result.quantity === 0 &&
      msg.result.averageFillPrice === 0 &&
      msg.result.price === 0
    ) {
      return;
    }

    return new Notification({
      type: msg.type,
      title: `${msg.type} ${msg.result.status}`,
      hoverInfo: msg.result.description,
      body: `${msg.result.type} ${msg.result.side} ${msg.result.quantity} ${msg.result.instrument.symbol}`,
      icon: 'notifcation-error',
    });
  },
};

function getConnectionNotification(
  aggregate,
  { message, msg, connectionId, icon, type, timestamp, technicalError },
) {
  if (aggregate.hasOwnProperty(`${connectionId}`)) {
    aggregate[connectionId] = {};
    aggregate.message += `${msg.result.message ?? message}\n`;

    if (aggregate.isFull()) {
      const notification = new Notification({
        body: aggregate.message,
        title: 'Connection',
        hoverInfo: technicalError,
        icon,
        type,
        timestamp,
      });
      aggregate.clear();
      return notification;
    } else if (aggregate.isRProtocolMessage(msg)) {
      const notification = new Notification({
        body: aggregate.message,
        title: 'Connection',
        hoverInfo: technicalError,
        icon,
        type,
        timestamp,
      });
      aggregate.clear();
      return notification;
    }
    return false;
  }
}

export const reducer = (msg) => {
  const handler = handlers[msg.type] || handlers.DEFAULT;
  return handler(msg);
};
