import { Injectable } from '@angular/core';

import { Bar, IInstrument } from 'trading';

import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';
import { WSMessageTypes } from './ws-message-types';

@Injectable()
export class RealBarDataFeed extends RealFeed<Bar, IInstrument> {
  feedType = RealtimeType.Bar;
  subscribeType = WSMessageTypes.SUBSCRIBE;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE;
}
