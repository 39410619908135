<span class="add-title">Add Symbol</span>
<nz-form-control class="custom-form-control">
  <nz-input-group [nzPrefix]="prefixTemplate">
    <input [formControl]="formControl" nzSize="small" type="text" nz-input />
  </nz-input-group>
</nz-form-control>
<!--<nz-tabset #inputElement [nzSelectedIndex]="tabIndex" (nzSelectedIndexChange)="tabChanged($event)">
  <nz-tab nzTitle="All">
  </nz-tab>
  <nz-tab nzTitle="Futures">
  </nz-tab>
  <nz-tab nzTitle="FutureOption">
  </nz-tab>
  <nz-tab nzTitle="FutureOptionStrategy">
  </nz-tab>
  <nz-tab nzTitle="FutureStrategy">
  </nz-tab>
  <nz-tab nzTitle="Spread">
  </nz-tab>
</nz-tabset>-->
<cdk-virtual-scroll-viewport
  (scrolledIndexChange)="onScroll($event)"
  [itemSize]="27"
>
  <table>
    <tr
      *cdkVirtualFor="let item of items; trackBy: trackByIdx"
      (click)="selectInstrument(item)"
      class="instrument-item"
    >
      <td [title]="item.symbol" class="symbol">{{ item.symbol }}</td>
      <td [title]="item.description" class="description">
        {{ item.description }} ({{ item.stringTypeRepresentation }})
      </td>
      <td class="exchange">{{ item.exchange }}</td>
    </tr>
  </table>
  <ng-template #prefixTemplate>
    <i class="icon-search"></i>
  </ng-template>
</cdk-virtual-scroll-viewport>
