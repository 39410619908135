import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { AppHealthLogger } from 'projects/performance-tools/src/lib/app-health-logs/app-health-logger';
import {
  IMetric,
  IMetricReporter,
} from 'projects/performance-tools/src/lib/performance-metrics.service';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { IConnection } from 'trading';
import {
  clearInterval as clearIntervalWorker,
  setInterval as setIntervalWorker,
} from 'worker-timers';

import { Loggable } from '../../../../src/Loggable';
import { RealtimeType } from '../../../real-trading/src/trading/repositories/realtime';
import { Id } from '../common';
import { CommunicationConfig } from '../http';
import { IConnectionWebSocket } from './connection.web-socket.interface';
import {
  AlertType,
  ConnectionHealthIndicator,
  IWebSocketStats,
  IWSListener,
  IWSListeners,
  IWSListenerUnsubscribe,
  RITHMIC_INFRA_TYPE,
  WSEventType,
} from './types';
import { IWebSocketService } from './web-socket-service.interface';

const DELAY_OFFSET = 3000;
const NOTIFICATIONS_OFFSET = 3 * 60 * 1000;
const MAX_TIME_OFFSET = 10800000; // 3 hours

const INACTIVITY_OFFSET = 2 * 60 * 1000;
const REPEATING_INACTIVITY_OFFSET = 2 * 60 * 60 * 1000;

/**
 * `ConnectionWebSocketService` is a Tradrr WebSocket connection, implemented as a wrapper over a ReconnectingWebSocket
 */
@Injectable({
  providedIn: 'root',
})
export class ConnectionWebSocketService
  extends Loggable
  implements IConnectionWebSocket, IMetricReporter
{
  private _infraType: RITHMIC_INFRA_TYPE;
  get infraType(): RITHMIC_INFRA_TYPE {
    return this._infraType;
  }
  set infraType(value: RITHMIC_INFRA_TYPE) {
    this._infraType = value;
  }
  protected _service: IWebSocketService;
  connection: IConnection;
  connection$ = new BehaviorSubject<boolean>(false);
  loggedIn$ = new BehaviorSubject<boolean>(false);
  reconnection$ = new Subject<IConnection>();
  lastSentNotification = 0;
  protected latencyMs: number;

  // Health check properties
  protected heartbeatIntervalId: number;
  protected heartbeatCheckIntervalId: number;
  protected heartbeatIntervalMs: number = 1000;
  protected heartbeatLastPingTime: number;
  protected heartbeatLastPongTime: number;
  protected missedHeartBeats: number;
  protected readonly healthCheckIntervalMs: number = 1000;
  protected readonly missedHeartbeatsDegradedThreshold: number = 3;
  protected readonly missedHeartbeatsUnhealthyThreshold: number = 8;

  protected healthLogger: AppHealthLogger;

  public logConfig: any = {
    enabled: false,
    configureMetadata: (args: string[]) => {
      if (this.infraType) {
        args.push('[' + this.infraType + ']');
      }
    },
  };

  private _isDraining = false;
  get isDraining(): boolean {
    return this._isDraining;
  }

  get connected(): boolean {
    return this.connection$.value;
  }

  get isLoggedIn(): boolean {
    return this.loggedIn$.value;
  }

  /**
   * Third party library that provides a WebSocket connection which will
   * automatically reconnect if the connection is dropped.
   * @see {@link https://github.com/joewalnes/reconnecting-websocket|ReconnectingWebSocket}
   */
  protected _websocket: ReconnectingWebSocket;
  protected _listeners: IWSListeners;
  protected _eventListeners: { [key in WSEventType]: any };
  protected _statistic: IWebSocketStats = {
    messages: 0,
    events: 0,
    startTime: new Date(),
    maxTime: -Infinity,
    minTime: +Infinity,
    time: {},
    typology: {},
    counters: {},
    upTime: 'n/a',
    peakMessagesPerSec: 0,
    messagesPerSecBuffer: 0,
    messagesPerSec: 0,
    averageMessages: 'n/a',
    averageMessagesValue: 0,
    averageEvents: 'n/a',
    eventsInMessages: 'n/a',
    instanceType: 'n/a',
    infraType: 'n/a',
  };
  protected _lastMessageRateTimestamp: number;

  /**
   * Queue of messages to send once the WebSocket connection is established (i.e. non-authenticated requests)
   * @protected
   */
  protected _delayedMessages = [];

  /**
   * Queue of messages to send once the WebSocket connection is authenticated (i.e. authenticated requests)
   * @protected
   */
  protected _delayedAuthenticatedMessages: Uint8Array[] = [];

  private _lastMessageActivityTime;
  private _isFirstInactivity = false;
  private _inactivityTimeoutId;

  private _lastCheckingTime: number;
  private _lastMsgTime: number;
  private _intervalId: number;

  healthState$: BehaviorSubject<ConnectionHealthIndicator> =
    new BehaviorSubject<ConnectionHealthIndicator>(null);

  constructor(
    protected _injector: Injector,
    protected _config: CommunicationConfig,
    protected useInactivityDetection: boolean = false,
  ) {
    super();
    this.healthLogger = _injector.get(AppHealthLogger);
    this._setListeners();
    this._setEventListeners();
    this.infraType = RITHMIC_INFRA_TYPE.TRADRR_PLANT;

    this.connection$.pipe(filter((i) => i)).subscribe(() => {
      this.log(
        'Processing delayed Non-Auth messages',
        this._delayedMessages.length,
        JSON.parse(JSON.stringify(this._delayedMessages)),
      );
      for (let i = 0; i < this._delayedMessages.length; i++)
        this._websocket.send(this._delayedMessages[i]);
      this._delayedMessages = [];
    });

    this.loggedIn$
      .pipe(
        distinctUntilChanged(),
        filter((i) => i),
      )
      .subscribe(() => {
        // @todo Issue: this block doesn't get called anymore for the Tradrr websocket
        this.log(
          'Processing delayed Auth messages',
          this._delayedAuthenticatedMessages.length,
          JSON.parse(JSON.stringify(this._delayedAuthenticatedMessages)),
        );
        for (let i = 0; i < this._delayedAuthenticatedMessages.length; i++)
          this._websocket.send(this._delayedAuthenticatedMessages[i]);
        this._delayedAuthenticatedMessages = [];
      });
    this._lastMessageActivityTime = Date.now();
    if (useInactivityDetection) {
      this.startInactivityTimer(INACTIVITY_OFFSET);
    }

    if (this.shouldPrintLog()) {
      this.displayStats();
    }
  }

  isTradrrPlant(): boolean {
    return (
      !this.infraType || this.infraType === RITHMIC_INFRA_TYPE.TRADRR_PLANT
    );
  }

  isOrderPlant(): boolean {
    return this.infraType === RITHMIC_INFRA_TYPE.ORDER_PLANT;
  }

  isTickerPlant(): boolean {
    return this.infraType === RITHMIC_INFRA_TYPE.TICKER_PLANT;
  }

  displayStats(): void {
    setInterval((): void => {
      const stats: IWebSocketStats = this.getStats();
      if (Object.keys(stats.counters).length === 0) {
        return;
      }
      this.log('RProtocol Ticker Stats', stats.counters, stats);
    }, 15000);
  }

  getStats(): IWebSocketStats {
    const _statistic: IWebSocketStats = this._statistic;
    const upTime: number = (Date.now() - _statistic.startTime.getTime()) / 1000;
    const averageMessages: number =
      Number.isNaN(_statistic.messages) || Number.isNaN(upTime)
        ? 0
        : _statistic.messages / upTime;

    return {
      ..._statistic,
      upTime: `${upTime} sec`,
      averageMessagesValue: averageMessages,
      averageMessages: `${averageMessages.toFixed(2)} messages/sec`,
      averageEvents: `${(_statistic.events / upTime).toFixed(2)} events/sec`,
      eventsInMessages: `${_statistic.events / _statistic.messages} events/sec`,
      instanceType: this.constructor.name,
      infraType: this.infraType,
    } as IWebSocketStats;
  }

  incrementStatsCounter(msgType: string, symbol: string): void {
    if (!this._statistic.counters[msgType]) {
      this._statistic.counters[msgType] = {};
    }
    if (!this._statistic.counters[msgType][symbol]) {
      this._statistic.counters[msgType][symbol] = 0;
    }
    this._statistic.counters[msgType][symbol]++;
  }

  collectMetrics(): IMetric[] {
    return [];
  }

  resetMetrics(): void {
    this._lastMessageRateTimestamp = performance.now();
    this._statistic = {
      ...this._statistic,
      messages: 0,
      events: 0,
      startTime: new Date(),
      maxTime: -Infinity,
      minTime: +Infinity,
      time: {},
      typology: {},
      counters: {},
      upTime: 'n/a',
      peakMessagesPerSec: 0,
      messagesPerSecBuffer: 0,
      messagesPerSec: 0,
      averageMessages: 'n/a',
      averageMessagesValue: 0,
      averageEvents: 'n/a',
      eventsInMessages: 'n/a',
    };
  }

  setService(service: IWebSocketService): void {
    this._service = service;
  }

  initiateClosing(): void {
    this.stopHeartbeat();
    this.stopHeartBeatCheck();
  }

  startInactivityTimer(timeout) {
    this._inactivityTimeoutId = setTimeout(() => {
      const now = Date.now();
      let newTimeOut;
      const shouldSendMessage =
        !this._isFirstInactivity &&
        this._lastMessageActivityTime >= REPEATING_INACTIVITY_OFFSET &&
        now >= this._lastMessageActivityTime + timeout;
      if (shouldSendMessage) {
        this._isFirstInactivity = true;
        newTimeOut = INACTIVITY_OFFSET - (now - this._lastMessageActivityTime);
        this._executeListeners(WSEventType.Message, {
          type: RealtimeType.Activity,
          result: { connection: this.connection },
        });
        newTimeOut =
          newTimeOut > INACTIVITY_OFFSET / 3 ? newTimeOut : INACTIVITY_OFFSET;
      } else newTimeOut = INACTIVITY_OFFSET;
      this._lastMessageActivityTime = now;
      clearTimeout(this._inactivityTimeoutId);
      this.startInactivityTimer(newTimeOut);
    }, timeout);
  }

  // TODO Refactor: this class acts as both a WebSocket and a Registry of connections singletons
  get(connection: IConnection): ConnectionWebSocketService {
    throw Error('DEPRECATED: use `WebSocketRegistryService` instead');

    // if (!connection) {
    //   throw new Error(`Please provide valid connection`);
    // }
    //
    // const key = connection.id;
    // const constructor = this.constructor as any;
    //
    // if (!constructor.instances) {
    //   constructor.instances = new Map<IConnection, any>();
    //   constructor.instancesCounts = new Map<IConnection, number>();
    // }
    //
    // if (constructor.instances.has(key)) {
    //   return constructor.instances.get(key);
    // }
    //
    // const instance = new ConnectionWebSocketService(
    //   this._injector,
    //   this._config,
    //   this._service
    // );
    // instance.connection = connection;
    //
    // constructor.instances.set(key, instance);
    //
    // return instance;
  }

  destroy(connection: IConnection, code: number = 1000, reason?: string) {
    this.warn(`destroy()`, { code, reason });
    this.close(code, reason);
    this.connection$.next(false);
    this.loggedIn$.next(false);
    // @todo Needed for TRAD-472 ?
    this._service.unregister(this.connection.id, this);
    clearInterval(this._intervalId);
    clearTimeout(this._inactivityTimeoutId);
    this.stopHeartbeat();
    this.stopHeartBeatCheck();
  }

  connect() {
    if (this.connection$.value) {
      return;
    }
    this.healthState$.next(null);

    this._statistic.startTime = new Date();

    const url: string = this._config.rithmic.ws.url;
    this.log('Connecting to: ', url);

    this._websocket = new ReconnectingWebSocket(url, [], {
      minReconnectionDelay: 3000,
      maxRetries: 1,
      connectionTimeout: 8000,
    });

    this._addEventListeners();
    this._service.register(this.connection.id, this);
    setTimeout(() => {
      this.stopHeartBeatCheck();
      this.startHeartBeatCheck();
    }, 3000);
  }

  reconnect() {
    this._websocket.reconnect();

    this._addEventListeners();
    this._service.unregister(this.connection.id, this);
  }

  protected sendHeartbeat(): void {
    this.send({ type: 'Ping', value: Date.now() }, this.connection?.id);
  }

  protected stopHeartBeatCheck(): void {
    if (this.heartbeatCheckIntervalId) {
      clearIntervalWorker(this.heartbeatCheckIntervalId);
    }
    this.heartbeatCheckIntervalId = null;
    this.missedHeartBeats = 0;
  }

  protected heartBeatCheck(): void {
    // Check for failed pings
    const now: number = performance.now();
    const diff: number = now - this.heartbeatLastPongTime;
    let state: ConnectionHealthIndicator = ConnectionHealthIndicator.Healthy;

    const hasNotReceivedAnyPongs: boolean = !this.heartbeatLastPongTime;
    const hasMissedAHeartBeat: boolean =
      diff > this.heartbeatIntervalMs || hasNotReceivedAnyPongs;

    if (!hasMissedAHeartBeat) {
      this.missedHeartBeats = 0;
      this.healthState$.next(state);
      return;
    }

    this.missedHeartBeats += 1;
    if (this.missedHeartBeats >= this.missedHeartbeatsUnhealthyThreshold) {
      state = ConnectionHealthIndicator.Unhealthy;
      this.healthLogger?.critical(`${this.infraType}: Connection is Unhealthy`);
    } else if (
      this.missedHeartBeats >= this.missedHeartbeatsDegradedThreshold
    ) {
      state = ConnectionHealthIndicator.Degraded;
      this.healthLogger?.warning(`${this.infraType}: Connection is Degrading`);
    }

    this.log('Heartbeat check: fail', {
      heartbeatLastPingTime: this.heartbeatLastPingTime?.toFixed(0),
      heartbeatLastPongTime: this.heartbeatLastPongTime?.toFixed(0),
      hasNotReceivedAnyPongs,
      diff: diff?.toFixed(0),
      missedHeartBeats: this.missedHeartBeats,
    });

    this.healthState$.next(state);
  }

  protected startHeartBeatCheck(): void {
    this.heartbeatCheckIntervalId = setIntervalWorker(
      () => this.heartBeatCheck(),
      this.healthCheckIntervalMs,
    );
  }

  protected stopHeartbeat(): void {
    clearIntervalWorker(this.heartbeatIntervalId);
    this.heartbeatIntervalId = null;
    this.heartbeatLastPingTime = null;
    this.heartbeatLastPongTime = null;
    this.missedHeartBeats = 0;
  }

  protected startHeartBeat(): void {
    if (this.heartbeatIntervalId) {
      this.stopHeartbeat();
    }
    this.sendHeartbeat();
    this.heartbeatIntervalId = setIntervalWorker(() => {
      this.heartbeatLastPingTime = performance.now();
      this.sendHeartbeat();
    }, this.heartbeatIntervalMs);
  }

  send(data: any = {}, connectionId: Id): void {
    if (this.connection?.id != connectionId) return;

    const payload = JSON.stringify(data);

    if (!payload) {
      return;
    }

    if (!this.connected) {
      this._delayedMessages.push(payload);
      console.warn(`Message didn\'t send `, payload);
      return;
    }
    this._websocket.send(payload);
  }

  startDraining(connectionId: Id): void {
    if (this.connection?.id !== connectionId) {
      return;
    }
    this._isDraining = true;
  }

  stopDraining(connectionId: Id): void {
    if (this.connection?.id !== connectionId) {
      return;
    }
    this._isDraining = false;
  }

  close(code: number = 1000, reason?: string) {
    this.log(`close()`, { code, reason });

    if (this._inactivityTimeoutId != null)
      clearTimeout(this._inactivityTimeoutId);
    this._websocket.close(code, reason);
  }

  on(type: WSEventType, listener: IWSListener): IWSListenerUnsubscribe {
    this._listeners[type].add(listener);

    return () => {
      this._listeners[type].delete(listener);
    };
  }

  off(type: WSEventType, listener: IWSListener) {
    const listeners = this._listeners[type];

    if (listeners) listeners.delete(listener);
  }

  protected _setListeners() {
    this._listeners = Object.values(WSEventType).reduce((accum, event) => {
      accum[event] = new Set();
      return accum;
    }, {}) as IWSListeners;
  }

  private _setLastCheckDelay() {
    const self = this;
    this._intervalId = setInterval(() => {
      const timeDelay = self._lastCheckingTime - self._lastMsgTime;
      const hasDelay = timeDelay > DELAY_OFFSET && timeDelay < MAX_TIME_OFFSET;
      const shouldSendNtf =
        self._lastCheckingTime >
        self.lastSentNotification + NOTIFICATIONS_OFFSET;
      //  console.table({ lastCheckingTime: self.lastCheckingTime, msg: this.lastMsg, timeDelay,  lastMsgTime: self.lastMsgTime });
      if (hasDelay && shouldSendNtf) {
        self.lastSentNotification = self._lastCheckingTime;
        // this.reconnection$.next(this.connection);
        self._executeListeners(WSEventType.Message, {
          type: RealtimeType.Delay,
          result: {
            timeDelay,
            connection: this.connection,
            now: self._lastCheckingTime,
          },
        });
      }
    }, 500);
  }

  protected _setEventListeners() {
    this._eventListeners = {
      open: (event: Event) => {
        this.healthState$.next(null);
        this._executeListeners(WSEventType.Open, event);
        this.connection$.next(true);
        this.stopHeartbeat();
        setTimeout(() => {
          this.startHeartBeat();
        }, 5000);
      },
      close: (event: CloseEvent) => {
        this._executeListeners(WSEventType.Close, event);
        this._removeEventListeners();
        this._setListeners();
        this.connection$.next(false);
        this.loggedIn$.next(false);
        this.healthState$.next(null);
        this.stopHeartbeat();
      },
      error: (event: ErrorEvent) => {
        this._executeListeners(WSEventType.Error, event);
        this.healthState$.next(null);
        this.stopHeartbeat();
      },
      message: this._handleMessage.bind(this),
    };
  }

  protected _handleMessage(event: MessageEvent) {
    if (!event?.data) return;

    let payload: any;
    this.measureIncomingMessagesRate();

    try {
      payload = JSON.parse(event.data);
      this.log('Received:', payload);
    } catch (e) {
      console.error('Parse error', e);
      return;
    }
    this._handleMessagePayload(payload);
  }

  protected measureIncomingMessagesRate(): void {
    this._statistic.messages++;

    // Measures the instantaneous & peak rate of "messages per sec"
    this._statistic.messagesPerSecBuffer++;
    if (!this._lastMessageRateTimestamp) {
      this._lastMessageRateTimestamp = performance.now();
    } else {
      const timeDiff: number =
        performance.now() - this._lastMessageRateTimestamp;
      if (timeDiff >= 1000) {
        this._statistic.messagesPerSec = this._statistic.messagesPerSecBuffer;
        this._statistic.peakMessagesPerSec = Math.max(
          this._statistic.messagesPerSec,
          this._statistic.peakMessagesPerSec,
        );
        this._statistic.messagesPerSecBuffer = 0;
        this._lastMessageRateTimestamp = performance.now();
      }
    }
  }

  protected _handleMessagePayload(payload: any) {
    if (Array.isArray(payload)) {
      this._statistic.events += payload.length;
      const t0 = window.performance.now();
      for (const item of payload) {
        this._processMessage(item);
      }
      const t1 = window.performance.now();
      const performance = t1 - t0;
      const time = performance.toFixed(0);
      if (!this._statistic.time[time]) this._statistic.time[time] = 1;
      else this._statistic.time[time]++;
      if (performance > this._statistic.maxTime)
        this._statistic.maxTime = performance;
      else if (performance < this._statistic.minTime)
        this._statistic.minTime = performance;
    } else {
      this._processMessage(payload);
      this._statistic.events++;
    }
  }

  _processMessage(payload) {
    if (!payload) {
      return;
    }

    const { type, result } = payload;

    if (type == 'Message' && result.value == 'Api-key accepted!') {
      this.connection$.next(true);
    }
    if (this.isTradrrPlant()) {
      this._checkConnectionDelay(payload);
      this._checkMessageActivity(payload);

      if (payload.type === 'Connect' || payload.type === 'Error') {
        const hasRAPIPlantSuddenlyDisconnected: boolean =
          payload.result.type === AlertType.ConnectionBroken ||
          payload.result.type === AlertType.ForcedLogout;

        const hasRequestFailedDueBrokenConnection: boolean =
          payload.type === 'Error' &&
          payload.result.value.toLowerCase().includes('no connection');

        if (
          hasRAPIPlantSuddenlyDisconnected ||
          hasRequestFailedDueBrokenConnection
        ) {
          this.healthState$.next(ConnectionHealthIndicator.Unhealthy);
        }
      }
    }

    this._executeListeners(WSEventType.Message, payload);
  }

  private _checkConnectionDelay(msg) {
    if (msg.time == null) return;

    this._lastCheckingTime = Date.now();
    this._lastMsgTime = msg.time;
  }

  private _checkMessageActivity(msg) {
    this._lastMessageActivityTime = Date.now();
    if (msg?.type === 'Message' && msg?.result?.value === 'Pong') {
      this.heartbeatLastPongTime = performance.now();
    }
  }

  protected _addEventListeners() {
    this._forEachEventListener((event, listener) => {
      this._websocket.addEventListener(event, listener);
    });
  }

  protected _removeEventListeners() {
    this._forEachEventListener((event, listener) => {
      this._websocket.removeEventListener(event, listener);
    });
  }

  private _forEachEventListener(callback) {
    Object.entries(this._eventListeners).forEach(([type, listener]) => {
      callback(type as WSEventType, listener);
    });
  }

  protected _executeListeners(type: WSEventType, data?: any) {
    const items = this._listeners[type];

    for (const listener of items) {
      try {
        listener(data, this.connection.id);
      } catch (e) {
        console.error(e);
      }
    }
  }
}
