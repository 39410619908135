import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { UntilDestroy } from '@ngneat/until-destroy';

import { UserIdleDetectorMetrics } from './user-idle-detector-metrics';

/**
 * @description This component is responsible for displaying the user idle time.
 * It subscribes to the `UserIdleDetectorMetrics` service and receives the latest
 * idle time value.
 */
@UntilDestroy()
@Component({
  selector: 'user-idle-detector',
  templateUrl: './user-idle-detector.component.html',
  styleUrls: ['./user-idle-detector.component.scss'],
})
export class UserIdleDetectorComponent implements OnInit, OnDestroy {
  idleTime: number = 0;
  private subscription: Subscription;

  constructor(public userIdleDetector: UserIdleDetectorMetrics) {}

  ngOnInit(): void {
    this.subscription = this.userIdleDetector
      .getIdleTimer()
      .subscribe((time: number): number => (this.idleTime = time));
  }

  async startIdleDetection(): Promise<void> {
    await this.userIdleDetector.requestPermissionAndStartWatching();
  }

  ngOnDestroy(): void {
    this.userIdleDetector.stopWatching();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
