import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';

const ignoredUrls = ['config/config.json', 'connect/token', 'account/logout'];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );
  private _rithmicWithCredentials = false;
  private _rithmicApiUrl: string;

  constructor(public auth: AuthService) {
    this._rithmicWithCredentials =
      !!this.auth._appConfig.rithmic?.http?.withCredentials;
    this._rithmicApiUrl = this.auth._appConfig.rithmic?.http?.url;
    this.auth._appConfig.getConfig().subscribe((appConfig) => {
      this._rithmicWithCredentials = !!appConfig.rithmic?.http?.withCredentials;
      this._rithmicApiUrl = appConfig.rithmic?.http?.url;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this._shouldHandleRefreshToken(request)) {
      return this._handleRefreshToken(request, next);
    }

    request = this._prepareRequest(request);
    return next.handle(request);
  }

  private _shouldHandleRefreshToken(request) {
    const isInIgnoreList = ignoredUrls.find((item) =>
      request.url.includes(item),
    );

    return !isInIgnoreList && this.auth.isTokenInvalid();
  }

  private _handleRefreshToken(request, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.auth.refreshToken().pipe(
        switchMap((item) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(item);
          request = this._prepareRequest(request);
          return next.handle(this._prepareRequest(request));
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((data) => data != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this._prepareRequest(request));
        }),
      );
    }
  }

  private _prepareRequest(request) {
    const token = this.auth.getToken();

    // TODO refactor this, move into appropriate repository classes
    const withCredentials =
      this._rithmicWithCredentials &&
      this._rithmicApiUrl &&
      request.url.startsWith(this._rithmicApiUrl);
    request = request.clone({
      ...(token ? { setHeaders: { Authorization: `Bearer ${token}` } } : {}),
      ...(withCredentials ? { withCredentials: true } : {}),
    });

    return request;
  }
}
