import { Component, OnInit } from '@angular/core';

import { DebugFlagService } from '../debug-flag.service';

/**
 * @deprecated Do we still need this? Or should we make a simple panel out of it?
 * @todo Remove this component if not needed.
 *
 * @description This component is responsible for displaying the performance
 * metrics toolbar. It subscribes to the `DebugFlagService` and displays the
 * toolbar only when the debug mode is enabled.
 */
@Component({
  selector: 'performance-metrics-toolbar',
  templateUrl: './performance-metrics-toolbar.component.html',
  styleUrls: ['./performance-metrics-toolbar.component.scss'],
})
export class PerformanceMetricsToolbarComponent implements OnInit {
  isDebugMode: boolean = false;

  constructor(private debugFlagService: DebugFlagService) {
    this.debugFlagService.debugMode$.subscribe((mode: boolean) => {
      this.isDebugMode = mode;
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
