import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  NzCheckboxModule,
  NzDropDownModule,
  NzInputModule,
} from 'ng-zorro-antd';

import { AddTimezoneModalComponent } from './add-timezone-modal/add-timezone-modal.component';
import { ClockComponent } from './clock/clock.component';
import { TimezoneItemComponent } from './timezone-item/timezone-item.component';
import { TimezoneRepository } from './timezone-repository';
import { TimezonesService } from './timezones.service';
import { UtcPipe } from './utc.pipe';

@NgModule({
  imports: [CommonModule, NzDropDownModule, NzCheckboxModule, NzInputModule],
  declarations: [
    ClockComponent,
    TimezoneItemComponent,
    AddTimezoneModalComponent,
    UtcPipe,
  ],
  exports: [ClockComponent],
  providers: [
    TimezonesService,
    {
      provide: TimezoneRepository,
      useClass: TimezoneRepository,
    },
  ],
})
export class TimezonesClockModule {}
