import { IBar } from 'chart';



import { RevBarsCalculator, RevBarsResponse } from './RevBarsCalculator';
import { RevsBarHandler } from './RevsHandler';


/**
 * `RevsBar2Handler` computes reversal bars for both the history data & live bars.
 *  It expects the initial history data snapshot sent by the backend to be regular bars.
 */
export class RevsBar2Handler extends RevsBarHandler {
  calculator = new RevBarsCalculator();

  setAdditionalInfo(additionalInfo: any): void {
    if (typeof additionalInfo?.isUp === 'undefined') {
      // `isUp` can be `undefined` when app is connecting in hybrid mode
      return;
    }
    this.isUp = additionalInfo?.isUp;
  }

  processBars(bars: IBar[]): IBar[] {
    const tickSize: number = this.chart.instrument.tickSize;
    const precision: number = this.chart.instrument.precision;
    const barSize: number = this.chart.timeFrame.interval;
    const response: RevBarsResponse = this.calculator.calculateRevBars(
      barSize,
      tickSize,
      precision,
      bars,
      this.isUp,
    );
    this.setAdditionalInfo(response);
    console.warn('RevsHandler.processBars', { bars, response, _this: this });
    return response.bars;
  }
}
