import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';

import { StorageModule } from 'storage';

import { AuthService, RootAuthService } from './auth.service';
import { TokenInterceptor } from './token.interceptor';

function authServiceFactory(injector: Injector) {
  if (!window.deps.get('AuthService')) {
    window.deps.set('AuthService', injector.get(RootAuthService));
  }
  return window.deps.get('AuthService');
}

@NgModule({
  imports: [CommonModule, StorageModule],
  providers: [
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: RootAuthService,
          useClass: AuthService,
        },
        {
          provide: AuthService,
          useFactory: authServiceFactory,
          deps: [Injector],
        },
      ],
    };
  }
}
