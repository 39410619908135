import { CellStatusEnum, ICellConfig, ICellSettings } from './cell.model';

const textBoldClass: string = ' text-bold';

export abstract class Cell {
  protected _statusPrefix: string;
  protected _hovered = false;
  protected _marked = false;
  protected _visibility = true;

  name: string = '';
  value = '';
  class = '';
  colSpan = 0;
  editable = false;
  manualEdit = true;
  editType: string;
  editValueSetter: Function;
  _bold: boolean;
  settings: ICellSettings = {};
  private _drawed = false; // performance solution
  public get drawed() {
    return this._drawed;
  }
  public set drawed(value) {
    this._drawed = value;
  }
  private _status: string = '';
  public get status(): string {
    return this._status;
  }
  public set status(value: string) {
    this._status = value;
  }
  hoverStatusEnabled: boolean;
  protected _prevStatus = '';
  public get prevStatus(): string {
    return this._prevStatus;
  }
  public set prevStatus(value: string) {
    this._prevStatus = value;
  }
  static mergeStatuses(prefix: string = '', status: string = '') {
    return `${prefix}${status}`;
  }

  get visible() {
    return this._visibility;
  }

  set visible(value) {
    if (this._visibility === value) return;

    this.drawed = false;
    this._visibility = value;
    this._visibilityChange();
  }

  set bold(value: boolean) {
    if (this._bold === value) {
      return;
    }
    this._bold = value;

    if (value) {
      this.class += textBoldClass;
    } else if (this.class.includes(textBoldClass)) {
      this.class.replace(textBoldClass, '');
    }
  }

  set hovered(hovered: boolean) {
    if (!this.hoverStatusEnabled || hovered === this._hovered) {
      return;
    }

    this._hovered = hovered;
    this._setHoveredStatus();
  }

  get hovered(): boolean {
    return this._hovered;
  }

  set marked(marked: boolean) {
    if (!this.hoverStatusEnabled || marked === this._marked) {
      return;
    }

    this._marked = marked;
    this._setMarkedStatus();
  }

  get marked(): boolean {
    return this._marked;
  }

  constructor(config?: ICellConfig) {
    this.settings = config?.settings ?? {};
    this.hoverStatusEnabled = config?.withHoverStatus ?? false;
  }

  abstract updateValue(...args: any[]);

  setStatusPrefix(statusPrefix: string) {
    if (statusPrefix === this._statusPrefix) {
      return;
    }

    const status = this.status.replace(this._statusPrefix, '');
    this._statusPrefix = statusPrefix;

    this.changeStatus(status);
    this.drawed = false;
  }

  protected _setHoveredStatus(): void {
    if (this._hovered) {
      this.setStatusPrefix(
        `${CellStatusEnum.Hovered}${this._statusPrefix ?? ''}`,
      );
    } else {
      this.setStatusPrefix(
        (this._statusPrefix ?? '').replace(CellStatusEnum.Hovered, ''),
      );
    }
  }

  protected _setMarkedStatus(): void {
    if (this._marked) {
      this.setStatusPrefix(
        `${CellStatusEnum.Marked}${this._statusPrefix ?? ''}`,
      );
    } else {
      this.setStatusPrefix(
        (this._statusPrefix ?? '').replace(CellStatusEnum.Marked, ''),
      );
    }
  }

  protected _getStatus(status: string, usePrefix = true) {
    return usePrefix ? Cell.mergeStatuses(this._statusPrefix, status) : status;
  }

  changeStatus(status: string, usePrefix = true): void {
    status = this._getStatus(status, usePrefix);

    if (status == this.status) return;

    this._prevStatus = this.status;
    this.status = status;
    this.drawed = false;
  }

  revertStatus() {
    let currentStatus = this.status;
    this.status = this._prevStatus;
    this._prevStatus = currentStatus;
    this.drawed = false;
  }

  dehightlight() {
    if (this.status == CellStatusEnum.Highlight) this.revertStatus();
  }

  hightlight() {
    this.changeStatus(CellStatusEnum.Highlight);
  }

  mark() {
    if (!this.marked) {
      this.hovered = false;
      this.changeStatus(CellStatusEnum.Marked);
      this.marked = true;
      this.drawed = false;
    }
  }

  unmark() {
    if (this.marked) {
      this.hovered = true;
      this.status = '';
      this.marked = false;
    }
  }

  clear() {
    this.value = '';
    this.drawed = false;
  }

  protected _visibilityChange() {
    throw new Error('Implement visibility change');
  }

  refresh() {}

  toString() {
    return this.value;
  }
}
