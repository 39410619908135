<app-navbar
  [class.window-navbar]="isWindowPopup()"
  *ngIf="layout && shouldShowToolBar()"
  (save)="save()"
  [layout]="layout"
></app-navbar>
<layout></layout>

<context-menu></context-menu>
<ng-template #defaultEmptyContainer>
  <span class="empty-container"> No data to display... </span>
</ng-template>
<ng-template #defaultConnectionContainer>
  <a>
    <div (click)="openAccounts()">
      <span class="empty-container">
        Open connection window <i class="icon-signal"></i
      ></span></div
  ></a>
</ng-template>
