/*jslint browser: true, unparam: true, todo: true*/
/*globals XMLSerializer: false, define: true, Blob: false, MutationObserver: false, requestAnimationFrame: false, performance: false, btoa: false*/
'use strict';

export default function (self) {
  var perfCounters = [],
    cachedImagesDrawn = false,
    drawCount = 0,
    perfWindowSize = 300,
    entityCount = [],
    hiddenFrozenColumnCount = 0,
    scrollDebugCounters = [],
    touchPPSCounters = [];
  self.htmlImageCache = {};

  // more heavyweight version than fillArray defined in intf.js
  // function fillArray(low, high, step, def) {
  //   step = step || 1;
  //   var i = [],
  //     x;
  //   for (x = low; x <= high; x += step) {
  //     i[x] = def === undefined ? x : typeof def === 'function' ? def(x) : def;
  //   }
  //   return i;
  // }
  // function drawPerfLine(w, h, x, y, perfArr, arrIndex, max, color, useAbs) {
  //   var i = w / perfArr.length,
  //     r = h / max;
  //   x += self.canvasOffsetLeft;
  //   y += self.canvasOffsetTop;
  //   self.ctx.beginPath();
  //   self.ctx.moveTo(x, y + h);
  //   perfArr.forEach(function (n) {
  //     var val = arrIndex === undefined ? n : n[arrIndex],
  //       cx,
  //       cy;
  //     if (useAbs) {
  //       val = Math.abs(val);
  //     }
  //     cx = x + i;
  //     cy = y + h - val * r;
  //     self.ctx.lineTo(cx, cy);
  //     x += i;
  //   });
  //   self.ctx.moveTo(x + w, y + h);
  //   self.ctx.strokeStyle = color;
  //   self.ctx.stroke();
  // }
  function drawOnAllImagesLoaded() {
    var loaded = true;
    Object.keys(self.htmlImageCache).forEach(function (html) {
      if (!self.htmlImageCache[html].img.complete) {
        loaded = false;
      }
    });
    if (loaded && !cachedImagesDrawn) {
      cachedImagesDrawn = true;
      self.draw();
    }
  }
  // function drawHtml(cell) {
  //   var img,
  //     v = cell.innerHTML || cell.formattedValue,
  //     cacheKey =
  //       v.toString() + cell.rowIndex.toString() + cell.columnIndex.toString(),
  //     x = Math.round(cell.x + self.canvasOffsetLeft),
  //     y = Math.round(cell.y + self.canvasOffsetTop);
  //   if (self.htmlImageCache[cacheKey]) {
  //     img = self.htmlImageCache[cacheKey].img;
  //     if (
  //       self.htmlImageCache[cacheKey].height !== cell.height ||
  //       self.htmlImageCache[cacheKey].width !== cell.width
  //     ) {
  //       // height and width of the cell has changed, invalidate cache
  //       self.htmlImageCache[cacheKey] = undefined;
  //     } else {
  //       if (!img.complete) {
  //         return;
  //       }
  //       return self.ctx.drawImage(img, x, y);
  //     }
  //   } else {
  //     cachedImagesDrawn = false;
  //   }
  //   img = new Image(cell.width, cell.height);
  //   self.htmlImageCache[cacheKey] = {
  //     img,
  //     width: cell.width,
  //     height: cell.height,
  //   };
  //   img.onload = function () {
  //     self.ctx.drawImage(img, x, y);
  //     drawOnAllImagesLoaded();
  //   };
  //   img.src =
  //     'data:image/svg+xml;base64,' +
  //     btoa(
  //       '<svg xmlns="http://www.w3.org/2000/svg" width="' +
  //       cell.width +
  //       '" height="' +
  //       cell.height +
  //       '">\n' +
  //       '<foreignObject class="node" x="0" y="0" width="100%" height="100%">\n' +
  //       '<body xmlns="http://www.w3.org/1999/xhtml" style="margin:0;padding:0;">\n' +
  //       v +
  //       '\n' +
  //       '</body>' +
  //       '</foreignObject>\n' +
  //       '</svg>\n',
  //     );
  // }
  // function drawOrderByArrow(x, y) {
  //   var mt = self.style.columnHeaderOrderByArrowMarginTop * self.scale,
  //     ml = self.style.columnHeaderOrderByArrowMarginLeft * self.scale,
  //     mr = self.style.columnHeaderOrderByArrowMarginRight * self.scale,
  //     aw = self.style.columnHeaderOrderByArrowWidth * self.scale,
  //     ah = self.style.columnHeaderOrderByArrowHeight * self.scale;
  //   x += self.canvasOffsetLeft;
  //   y += self.canvasOffsetTop;
  //   self.ctx.fillStyle = self.style.columnHeaderOrderByArrowColor;
  //   self.ctx.strokeStyle = self.style.columnHeaderOrderByArrowBorderColor;
  //   self.ctx.beginPath();
  //   x = x + ml;
  //   y = y + mt;
  //   if (self.orderDirection === 'asc') {
  //     self.ctx.moveTo(x, y);
  //     self.ctx.lineTo(x + aw, y);
  //     self.ctx.lineTo(x + aw * 0.5, y + ah);
  //     self.ctx.moveTo(x, y);
  //   } else {
  //     self.ctx.lineTo(x, y + ah);
  //     self.ctx.lineTo(x + aw, y + ah);
  //     self.ctx.lineTo(x + aw * 0.5, y);
  //     self.ctx.lineTo(x, y + ah);
  //   }
  //   self.ctx.stroke();
  //   self.ctx.fill();
  //   return ml + aw + mr;
  // }
  // function drawTreeArrow(cell, x, y) {
  //   var mt = self.style.treeArrowMarginTop * self.scale,
  //     mr = self.style.treeArrowMarginRight * self.scale,
  //     ml = self.style.treeArrowMarginLeft * self.scale,
  //     aw = self.style.treeArrowWidth * self.scale,
  //     ah = self.style.treeArrowHeight * self.scale;
  //   x += self.canvasOffsetLeft;
  //   y += self.canvasOffsetTop;
  //   self.ctx.fillStyle = self.style.treeArrowColor;
  //   self.ctx.strokeStyle = self.style.treeArrowBorderColor;
  //   self.ctx.beginPath();
  //   x = x + ml;
  //   y = y + mt;
  //   if (self.openChildren[cell.rowIndex]) {
  //     self.ctx.moveTo(x, y);
  //     self.ctx.lineTo(x + aw, y);
  //     self.ctx.lineTo(x + aw * 0.5, y + ah);
  //     self.ctx.moveTo(x, y);
  //   } else {
  //     self.ctx.lineTo(x, y);
  //     self.ctx.lineTo(x + ah, y + aw * 0.5);
  //     self.ctx.lineTo(x, y + aw);
  //     self.ctx.lineTo(x, y);
  //   }
  //   self.ctx.stroke();
  //   self.ctx.fill();
  //   return ml + aw + mr;
  // }
  function radiusRect(x, y, w, h, radius) {
    x += self.canvasOffsetLeft;
    y += self.canvasOffsetTop;
    var r = x + w,
      b = y + h;
    self.ctx.beginPath();
    self.ctx.moveTo(x + radius, y);
    self.ctx.lineTo(r - radius, y);
    self.ctx.quadraticCurveTo(r, y, r, y + radius);
    self.ctx.lineTo(r, y + h - radius);
    self.ctx.quadraticCurveTo(r, b, r - radius, b);
    self.ctx.lineTo(x + radius, b);
    self.ctx.quadraticCurveTo(x, b, x, b - radius);
    self.ctx.lineTo(x, y + radius);
    self.ctx.quadraticCurveTo(x, y, x + radius, y);
    self.ctx.closePath();
  }
  function fillRect(x, y, w, h) {
    x += self.canvasOffsetLeft;
    y += self.canvasOffsetTop;
    self.ctx.fillRect(x, y, w, h);
  }
  function strokeRect(x, y, w, h) {
    x += self.canvasOffsetLeft;
    y += self.canvasOffsetTop;
    self.ctx.strokeRect(x, y, w, h);
  }
  // function fillText(text, x, y) {
  //   x += self.canvasOffsetLeft;
  //   y += self.canvasOffsetTop;
  //   self.ctx.fillText(text, x, y);
  // }
  // function fillCircle(x, y, r) {
  //   x += self.canvasOffsetLeft;
  //   y += self.canvasOffsetTop;
  //   self.ctx.beginPath();
  //   self.ctx.arc(x, y, r, 0, 2 * Math.PI);
  //   self.ctx.fill();
  // }
  function strokeCircle(x, y, r) {
    x += self.canvasOffsetLeft;
    y += self.canvasOffsetTop;
    self.ctx.beginPath();
    self.ctx.arc(x, y, r, 0, 2 * Math.PI);
    self.ctx.stroke();
  }

  function drawGrid() {
    self.ctx.lineWidth = self.style.gridBorderWidth;
    self.ctx.strokeStyle = self.style.gridBorderColor;

    var height = self.height;
    var width = self.width;
    const ctx = self.ctx;
    ctx.beginPath();

    let moveRequestColumn;
    let moveRequestColumnX;
    self.forEachColumn((column, x) => {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
      const moveLeft = column.index < self.initialDragIndex;

      if (column.moveRequest) {
        moveRequestColumn = column;
        moveRequestColumnX = moveLeft ? x - column.width : x;
      }
    });
    ctx.stroke();

    var showColumnHeaders = self.intf.attributes.showColumnHeaders;
    var rowHeight = self.style.rowHeight;
    var _y;
    let isPrevBorderDrawed = false;

    self.forEachRow((row, y) => {
      const rowStyles = row.styles;

      _y = showColumnHeaders ? Math.max(y, rowHeight) : y;
      ctx.beginPath();

      if (!isPrevBorderDrawed) {
        ctx.moveTo(0, _y);
        ctx.lineTo(width, _y);
      }

      if (rowStyles && rowStyles.cellsBorderColor) {
        ctx.moveTo(0, _y + rowHeight);
        ctx.lineTo(width, _y + rowHeight);
        const prevStyle = ctx.strokeStyle;
        ctx.strokeStyle = rowStyles.cellsBorderColor;
        self.forEachColumn((column, x) => {
          ctx.moveTo(x, _y);
          ctx.lineTo(x, _y + rowHeight);
        });
        ctx.stroke();
        ctx.strokeStyle = prevStyle;
        isPrevBorderDrawed = true;
      } else {
        ctx.stroke();
        isPrevBorderDrawed = false;
      }
      ctx.closePath();
    });

    strokeRect(0, 0, width, height);

    if (moveRequestColumn) {
      ctx.save();
      const x = moveRequestColumnX + moveRequestColumn.width;
      ctx.beginPath();
      ctx.lineWidth = self.style.gridBorderWidth * 2;
      ctx.strokeStyle = 'grey';
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
      ctx.stroke();
      ctx.restore();
    }
  }

  /**
   * Redraws the grid. No matter what the change, this is the only method required to refresh everything.
   * @memberof canvasDatagrid
   * @name draw
   * @method
   */
  // r = literal row index
  // rd = row data array
  // i = user order index
  // o = literal data index
  // y = y drawing cursor
  // x = x drawing cursor
  // s = visible schema array
  // cx = current x drawing cursor sub calculation var
  // cy = current y drawing cursor sub calculation var
  // a = static cell (like corner cell)
  // p = perf counter
  // l = data length
  // u = current cell
  // h = current height
  // w = current width

  self.bindAnimationFrame = function (fn) {
    self.pending = false;
    self.drawFn = fn;
    self.beforeDrawFns = new Set();
    return (beforeDrawFnOrForce) => {
      if (typeof beforeDrawFnOrForce == 'function')
        self.beforeDrawFns.add(beforeDrawFnOrForce);
      else if (beforeDrawFnOrForce === true) {
        self.needRedraw = true;
      }

      if (self.pending) {
        // console.log('pendingDrawing')
        return;
      }

      self.pending = true;
      requestAnimationFrame(self._draw);
    };
  };

  self._draw = () => {
    const prev = performance.now();

    if (self.beforeDrawFns.size) {
      self.beforeDrawFns.forEach((fn) => fn());
      self.beforeDrawFns.clear();
    }

    self.drawFn();
    self.pending = false;
  };

  self.getTextWidth = (str) => {
    return str == null ? 0 : self.txtctx.measureText(str).width;
  };

  function drawSubtitle(
    ctx,
    title,
    shouldChangeSubtitleColor,
    subtitleColor,
    subtitle,
    columnWidth,
    _x,
    y,
    subtitleTopMargin,
  ) {
    const metrics = ctx.measureText(title);
    const titleHeight =
      Math.abs(metrics.actualBoundingBoxAscent) +
      Math.abs(metrics.actualBoundingBoxDescent);

    let prevColor;
    if (shouldChangeSubtitleColor) {
      prevColor = ctx.fillStyle;
      ctx.fillStyle = subtitleColor;
    }

    ctx.fillText(
      fittingString(ctx, subtitle, columnWidth),
      _x,
      y + titleHeight + subtitleTopMargin,
    );

    if (shouldChangeSubtitleColor) {
      ctx.fillStyle = prevColor;
    }
  }

  self.draw = self.bindAnimationFrame(function () {
    if (self.dispatchEvent('beforedraw', {})) {
      return;
    }
    if (!self.isChildGrid && (!self.height || !self.width)) {
      return;
    }
    if (self.intf.visible === false) {
      return;
    }

    let _class;
    const obj = self.dragStartObject || self.currentCell;
    const status = obj && obj.context;
    if (status == 'grip') {
      _class = 'col-resize';
    } else if (status == 'header') {
      _class = self.dragStartObject ? 'grabbing' : 'grab';
    }

    if (self.intf.classList.length) self.intf.classList = [];

    if (_class) self.intf.classList.add(_class);

    // initial values
    var data = self.data || [];

    self.visibleRowHeights = [];
    // if data length has changed, there is no way to know
    if (data.length > self.orders.rows.length) {
      self.createRowOrders();
    }
    function drawScrollBars() {
      self.ctx.strokeStyle = self.style.scrollBarBorderColor;
      self.ctx.lineWidth = self.style.scrollBarBorderWidth;

      var padding = self.style.scrollBarBoxMargin;
      var size = self.style.scrollBarSize;
      var barSize = size - padding * 2;
      var height = self.height;
      var width = self.width;
      var scrollBox = self.scrollBox;

      if (self.scrollBox.horizontalBarVisible) {
        var fullScroll = scrollBox.scrollWidth + width;
        var _left = scrollBox.scrollLeft / fullScroll;
        var _width = width / fullScroll;
        var barWidth = width - size;

        self.ctx.clearRect(0, height - size, width, size);
        self.txtctx.clearRect(0, height - size, width, size);

        self.ctx.beginPath();
        self.ctx.fillStyle = self.style.scrollBarBackgroundColor;
        self.ctx.rect(0, height - size, barWidth, size);

        self.ctx.stroke();
        self.ctx.fill();

        self.ctx.fillStyle = self.style.scrollBarBoxColor;

        radiusRect(
          barWidth * _left,
          height - size + padding,
          barWidth * _width,
          barSize,
          self.style.scrollBarBoxBorderRadius,
        );
        self.ctx.stroke();
        self.ctx.fill();
      }

      if (self.scrollBox.verticalBarVisible) {
        var fullScroll = scrollBox.scrollHeight + height;
        var _top = scrollBox.scrollTop / fullScroll;
        var _height = height / fullScroll;
        var barHeight = height - size;
        var x = width - size;

        self.ctx.clearRect(x, 0, size, height);
        self.txtctx.clearRect(x, 0, size, height);
        self.ctx.beginPath();
        self.ctx.fillStyle = self.style.scrollBarBackgroundColor;
        self.ctx.rect(x, 0, size, height);
        self.ctx.stroke();
        self.ctx.fill();

        self.ctx.fillStyle = self.style.scrollBarBoxColor;

        radiusRect(
          x + padding,
          barHeight * _top,
          size - padding * 2,
          barHeight * _height,
          self.style.scrollBarBoxBorderRadius,
        );
        self.ctx.stroke();
        self.ctx.fill();
      }
    }

    self.ctx.save();
    self.visibleRows = [];

    const ctx = self.ctx;
    const forceRedraw = self.needRedraw;

    self.visibleCells = [];

    const height = self.height;
    const width = self.width;
    ctx.save();

    self.ctx.clearRect(0, 0, width, height);
    self.ctx.translate(0.5, 0.5);

    self.ctx.fillStyle = self.style.background;
    fillRect(0, 0, width, height);
    ctx.restore();

    ctx.save();
    var background;
    var cellBackground;
    self.forEachColumn((column, x) => {
      background = column.style.backgroundColor;
      if (background) {
        ctx.fillStyle = background;
        ctx.fillRect(x, 0, column.width, height);
      }

      var style = column.style;
      if (style) {
        var isHistogram =
          column.type == 'histogram' && style.histogramEnabled != false;
        var hist, value;
        var rowHeight = self.style.rowHeight;
        var columnWidth = column.width;
        var histogramColor = style.histogramColor;
        ctx.fillStyle = histogramColor;
        // ctx.strokeStyle = style.borderColor;
        // ctx.lineWidth = style.borderWidth  || 0;

        ctx.beginPath();

        const backgroundColorKey = 'BackgroundColor';
        const borderColorKey = 'BorderColor';
        self.forEachRow((row, y) => {
          value = row[column.name];

          cellBackground = style[`${value.status}${backgroundColorKey}`];
          const cellBorder = style[`${value.status}${borderColorKey}`];

          if (cellBackground != null && cellBackground != background) {
            var prevFillStyle = ctx.fillStyle;
            ctx.fillStyle = cellBackground;
            ctx.clearRect(x, y, columnWidth, rowHeight);
            ctx.fillRect(x, y, columnWidth, rowHeight);
            ctx.fillStyle = prevFillStyle;
          }
          if (cellBorder != null) {
            ctx.save();
            ctx.strokeStyle = cellBorder;
            ctx.strokeRect(x, y, columnWidth, rowHeight);
            ctx.restore();
          }

          // if (isHistogram) {
          //   const fillStyle = style[`${value.status}HistogramColor`] || style.histogramColor;

          //   if (fillStyle != histogramColor) {
          //     ctx.fill();
          //     histogramColor = fillStyle;
          //     ctx.fillStyle = histogramColor;
          //   }

          //   hist = value.hist;
          //   if (!hist)
          //     return;

          //   if (style.histogramOrientation == 'right') {
          //     ctx.rect(x + columnWidth * (1 - value.hist), y, columnWidth * value.hist, rowHeight);
          //   } else {
          //     ctx.rect(x, y, columnWidth * value.hist, rowHeight);
          //   }
          // }
        });

        ctx.fill();
        ctx.closePath();

        ctx.beginPath();

        if (isHistogram) {
          self.forEachRow((row, y) => {
            value = row[column.name];
            // cellBackground = style[`${value.status}BackgroundColor`];

            // if (cellBackground != null && cellBackground != background) {
            //   var prevFillStyle = ctx.fillStyle;
            //   ctx.fillStyle = cellBackground;
            //   ctx.fillRect(x, y, columnWidth, rowHeight);
            //   ctx.fillStyle = prevFillStyle;
            // }

            const fillStyle =
              style[`${value.status}HistogramColor`] || style.histogramColor;

            // if (column.name == 'volume')
            //   console.log('value.status', value.status, row._id.value);

            if (fillStyle != histogramColor) {
              ctx.closePath();
              ctx.fill();
              histogramColor = fillStyle;
              ctx.fillStyle = histogramColor;
              ctx.beginPath();
            }

            hist = value.hist;
            if (!hist) return;

            if (style.histogramOrientation == 'right') {
              ctx.rect(
                x + columnWidth * (1 - value.hist),
                y,
                columnWidth * value.hist,
                rowHeight,
              );
            } else {
              ctx.rect(x, y, columnWidth * value.hist, rowHeight);
            }
          });
        }

        ctx.fill();
        ctx.closePath();
      }
    });
    ctx.restore();

    var txtctx = self.txtctx,
      style,
      value,
      textAlign,
      padding = self.padding;

    // *
    // *
    // *
    // * Uncomment for drawing text
    // *
    // *
    // *
    // *

    if (forceRedraw) {
      // txtctx.save();

      txtctx.clearRect(0, 0, width, height);
      // txtctx.translate(0.5, 0.5);
      // txtctx.restore();
      // fillRect(0, 0, width, height);
    }

    self.forEachColumn((column, x) => {
      style = column.style;
      txtctx.font = style.font || self.style.font;
      txtctx.textBaseline = 'middle';

      const alignMap = {
        right: x + (column.width - padding),
        center: x + column.width / 2,
        left: x + padding,
      };

      var rowHeight = self.style.rowHeight;
      var columnWidth = column.width;

      txtctx.save();
      txtctx.beginPath();
      txtctx.rect(x, 0, columnWidth, height);
      txtctx.clip();
      self.forEachRow((row, y) => {
        value = row[column.name];

        if (!value) return;

        const colorKey = 'Color';
        txtctx.fillStyle = style[`${value.status}${colorKey}`] || style.color;

        txtctx.font =
          style[`${value.status}Font`] || style.font || self.style.font;
        var text;

        const alignKey = 'TextAlign';
        textAlign = style[`${value.status}${alignKey}`] || style.textAlign;
        txtctx.textAlign = textAlign != null ? textAlign : 'left';
        textAlign = txtctx.textAlign;

        const _x = alignMap[textAlign] || alignMap.left;

        if (forceRedraw || value.drawed != true) {
          if (!forceRedraw) txtctx.clearRect(x, y, columnWidth, rowHeight);

          if (
            !value.draw ||
            !value.draw({
              ctx: txtctx,
              grid: self,
              column,
              row,
              x,
              y,
              width: column.width,
              height: self.style.rowHeight,
            })
          ) {
            text = column.style.textOverflow
              ? fittingString(txtctx, value.toString(), columnWidth)
              : value.toString();
            txtctx.fillText(text, _x, y + rowHeight / 2);
          }

          value.drawed = true;
        }
      });
      txtctx.restore();
    });

    if (self.intf.attributes.showColumnHeaders) {
      ctx.save();
      ctx.clearRect(0, 0, self.width, self.getHeaderHeight());
      ctx.fillStyle = self.style.background;
      ctx.fillRect(0, 0, self.width, self.style.rowHeight);
      ctx.fillStyle = self.style.columnHeaderCellColor || self.style.color;
      ctx.font = self.style.columnHeaderCellFont || self.style.font;

      const subtitleColor = self.style.subtitleColor;
      const showSubtitles = self.args.style.showSubtitles;
      const subtitleTopMargin = self.style.subtitleTopMargin;
      const shouldChangeSubtitleColor =
        subtitleColor && subtitleColor !== ctx.fillStyle;

      self.forEachColumn((column, x) => {
        if (
          !column.draw ||
          !column.draw({
            ctx,
            grid: self,
            column,
            x,
            y: 0,
            width: column.width,
            height: self.style.rowHeight,
          })
        ) {
          var _x = x + column.width / 2;
          var rowHeight = self.getHeaderHeight();
          var columnWidth = column.width;
          const prevStrokeStyle = ctx.strokeStyle;
          ctx.strokeStyle =
            self.style.gridHeaderBorderColor || self.style.gridBorderColor;

          ctx.save();
          ctx.beginPath();
          ctx.rect(x, 0, columnWidth, rowHeight);
          ctx.strokeRect(x, 0, columnWidth, rowHeight);
          ctx.clip();
          ctx.textBaseline = 'middle';
          ctx.textAlign = 'center';
          ctx.strokeStyle = prevStrokeStyle;

          const subtitle = column.subtitle;
          let y;
          const hasSubtitle = showSubtitles && subtitle != null;
          if (hasSubtitle) y = rowHeight / 3;
          else y = rowHeight / 2;
          const title = fittingString(
            ctx,
            column.title || column.name,
            columnWidth,
          );
          ctx.fillText(title, _x, y);

          if (hasSubtitle) {
            drawSubtitle(
              ctx,
              title,
              shouldChangeSubtitleColor,
              subtitleColor,
              subtitle,
              columnWidth,
              _x,
              y,
              subtitleTopMargin,
            );
          }

          ctx.restore();
        }
      });
      ctx.restore();
      txtctx.clearRect(0, 0, self.width, self.getHeaderHeight());
    }

    drawGrid();
    drawScrollBars();

    if (self.dispatchEvent('afterdraw', {})) {
      return;
    }

    self.ctx.beginPath();
    self.txtctx.beginPath();
    self.ctx.rect(0, 0, width, height);
    self.txtctx.rect(0, 0, width, height);
    self.ctx.clip();
    self.txtctx.clip();
    self.ctx.restore();
    self.txtctx.restore();
    self.needRedraw = false;
  });

  function fittingString(ctx, str, maxWidth) {
    var width = ctx.measureText(str).width;
    var ellipsis = ' …';
    var ellipsisWidth = ctx.measureText(ellipsis).width;

    if (width <= maxWidth || width <= ellipsisWidth) {
      return str;
    } else {
      var len = str.length;
      while (width >= maxWidth - ellipsisWidth && len-- > 0) {
        str = str.substring(0, len);
        width = ctx.measureText(str).width;
      }
      return str + ellipsis;
    }
  }
}
