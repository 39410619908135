import { IDetails, IQuote } from 'chart';

export const areQuotesSorted = (quotes: IQuote[]): boolean => {
  if (!quotes?.length) {
    return false;
  }
  const len: number = quotes.length;
  let prevTime: number = 0;
  for (let i: number = 0; i < len; i++) {
    const quote: IQuote = quotes[i];
    if (!prevTime) {
      prevTime = quote.date.getTime();
      continue;
    }
    if (prevTime > quote.date.getTime()) {
      return false;
    }
  }
  return true;
};

export const hasUnsortedPriceLevels = (details: IDetails[]): boolean => {
  if (!details?.length) {
    return false;
  }
  const len = details.length;
  let prevPrice = 0;
  for (let i = 0; i < len; i++) {
    const entry = details[i];
    if (!prevPrice) {
      prevPrice = entry.price;
      continue;
    }
    if (prevPrice > entry.price) {
      return true;
    }
  }
  return false;
};

export const hasDuplicatePriceLevels = (details: IDetails[]): boolean => {
  if (!details?.length) {
    return false;
  }
  const uniquePriceLevels: number[] = [];
  const duplicatePriceLevels: number[] = [];
  const len: number = details.length;
  for (let i: number = 0; i < len; i++) {
    const entry: IDetails = details[i];
    if (uniquePriceLevels.includes(entry.price)) {
      duplicatePriceLevels.push(entry.price);
    } else {
      uniquePriceLevels.push(entry.price);
    }
  }
  if (duplicatePriceLevels.length > 0) {
    console.log(
      "Last Bar's Details: duplicate price levels: ",
      duplicatePriceLevels,
    );
    return true;
  }

  return false;
};

export const sortPriceLevels = (details: IDetails[]): IDetails[] => {
  return details.sort((a: IDetails, b: IDetails): number => a.price - b.price);
};

/**
 * Sorts, deduplicates and fills missing price levels
 *
 * @param value
 * @param tickSize
 * @param precision
 */
export const normalizeBarDetails = (
  value: IDetails[],
  tickSize: number,
  precision: number,
): IDetails[] => {
  let high = -Infinity;
  let low = Infinity;

  for (let j = 0; j < value.length; j++) {
    const { price } = value[j];
    if (!price || !Number.isFinite(price)) {
      continue;
    }

    high = Math.max(high, price);
    low = Math.min(low, price);
  }
  if (!Number.isFinite(high) || !Number.isFinite(low)) {
    return value;
  }

  let tmpHandledValue = [];
  const tmpHandledMap = {};
  for (let price = low; price <= high; price += tickSize) {
    // FIX: Trad-52 Footprint RTYU3 market issue
    price = +(Math.round(price / tickSize) * tickSize).toFixed(
      precision, // instrument.precision,
    );

    tmpHandledMap[price] = value
      .filter((i) => i.price === price)
      .reduce(
        (total, current) => {
          total.bidInfo.volume += current.bidInfo.volume;
          total.bidInfo.tradesCount += current.bidInfo.tradesCount;

          total.askInfo.volume += current.askInfo.volume;
          total.askInfo.tradesCount += current.askInfo.tradesCount;

          total.volume += current.volume;
          total.tradesCount += current.tradesCount;
          if (current.notes) total.notes.push(...current.notes);
          return total;
        },
        {
          bidInfo: {
            volume: 0,
            tradesCount: 0,
          },
          askInfo: {
            volume: 0,
            tradesCount: 0,
          },
          volume: 0,
          tradesCount: 0,
          price,
          notes: [],
        },
      );

    // (Sergiu) This is slow. Is this needed on every iteration? Doesn't seem so.
    // tmpHandledValue = Object.values(tmpHandledMap).sort(
    //   (a: any, b: any) => a.price - b.price,
    // );
  }

  // TODO sorting a plain array is slow, replace it with a more suitable data structure;
  // maybe use a Red-Black tree, eg. https://www.npmjs.com/package/red-black-tree-typed
  tmpHandledValue = Object.values(tmpHandledMap).sort(
    (a: any, b: any) => a.price - b.price,
  );

  return tmpHandledValue;
};
