import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalStorage } from './local.storage';
import { Storage } from './storage';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: Storage,
      useClass: LocalStorage,
    },
  ],
  exports: [],
})
export class StorageModule {}
