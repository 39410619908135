<div class="d-flex">
  Is Throttling: {{ isThrottling ? "Yes" : "No" }}
</div>

<div class="d-flex">
  Throttling Duration: {{ this.throttlingDuration }} ms
</div>

<div class="d-flex">
  <label nz-checkbox [(ngModel)]="isAudioEnabled">Enable Audio Alerts</label>
</div>
