import { ChangeDetectorRef } from '@angular/core';
import { take } from 'rxjs/operators';

import { AccountsManager } from 'accounts-manager';
import { StringHelper } from 'base-components';
import { RProtocolConnectionWebSocketService } from 'communication';
import { NotificationService } from 'notification';
import { RProtocolOrderPlantService } from 'projects/communication/src/services/rprotocol/rprotocol-order-plant.service';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { RealPositionsRepository } from 'real-trading';
import {
  compareInstruments,
  IPosition,
  OrderAccount,
  PositionsFeed,
  PositionsRepository,
  Side,
} from 'trading';

import { ContextItemField } from '../../../../src/app/services/trading-context.service';
import { ChartObjects } from './chart-objects';

declare const StockChartX: any;

const { uncapitalize } = StringHelper;

export enum PositionSideMarkerOrigination {
  ClickClosePosition = 'ClickClosePosition',
}

export class PositionSideMarkers extends ChartObjects<IPosition> {
  private _accountsManager: AccountsManager =
    this._injector.get(AccountsManager);
  private _rProtocolOrderPlantService: RProtocolOrderPlantService =
    this._injector.get(RProtocolOrderPlantService);
  private _notificationService: NotificationService =
    this._injector.get(NotificationService);
  private _webSocketRegistryService: WebSocketRegistryService =
    this._injector.get(WebSocketRegistryService);
  protected _repository = this._injector.get(PositionsRepository);
  protected _dataFeed = this._injector.get(PositionsFeed) as any;
  protected _cd = this._injector.get(ChangeDetectorRef);

  init() {
    super.init();
    this._tradingContext.pushContext({
      [ContextItemField.Entry]: 'PositionSideMarker',
    });

    this._chart.on(
      StockChartX.PositionSideMarkerEvents.CLOSE_POSITION_CLICKED,
      this.addTradingContext(
        this._closePosition,
        PositionSideMarkerOrigination.ClickClosePosition,
      ),
    );
  }

  handle(model: IPosition) {
    const position = model.id
      ? model
      : RealPositionsRepository.transformPosition(model);
    super.handle(position);
    this._onDataLoaded();
    requestAnimationFrame(this.markForCheck);
  }

  markForCheck = () => {
    this._cd.markForCheck();
  };

  protected _onDataLoaded() {
    super._onDataLoaded();
    this._instance.position = this.items.find((item) =>
      compareInstruments(item.instrument, this._instance.instrument),
    );
  }

  createMarker(model) {
    const marker = new StockChartX.PositionSideMarker({
      position: this._map(model),
    });
    // bar.visible = false;
    return marker;
  }

  shouldBarBeVisible() {
    return true;
  }

  getPositions() {
    return this.items;
  }

  private _closePosition = ($event): void => {
    const { value, tradingContext } = $event;
    const account: OrderAccount = this._accountsManager.getAccountById(
      value.position?.accountId || value.position?.account?.id,
    ) as any as OrderAccount;
    const orderPlantWebSocket: RProtocolConnectionWebSocketService =
      this._webSocketRegistryService.getRProtocolOrderPlant(
        this._accountsManager.getConnectionByAccountId(account.id),
      ) as RProtocolConnectionWebSocketService;

    this._rProtocolOrderPlantService
      .requestExitPosition(
        value.position.instrument,
        account,
        orderPlantWebSocket,
        tradingContext,
      )
      .pipe(take(1))
      .subscribe(
        (): void => {},
        (): void => {
          this._notificationService.showError(`Error while closing position.`);
        },
      );
  };

  protected _isValid(item: IPosition) {
    return item.side !== Side.Closed;
  }

  protected _map(item: IPosition) {
    return {
      ...item,
      id: item.id,
      quantity: item.size,
      price: item.price,
      kind: uncapitalize(item.side),
      accountId: item.accountId,
      instrument: item.instrument,
    };
  }

  destroy() {
    super.destroy();
    this._chart?.off(
      StockChartX.PositionSideMarkerEvents.CLOSE_POSITION_CLICKED,
      this._closePosition,
    );
  }
}
