import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWithCommas',
})
export class NumberWithCommasPipe implements PipeTransform {
  transform(value: number, numbersBeforeComma: number = 3): string | null {
    const regexPattern: string = `\\B(?=(\\d{${numbersBeforeComma}})+(?!\\d))`;
    const regex: RegExp = new RegExp(regexPattern, 'g');

    return value.toFixed(2).toString().replace(regex, ',');
  }
}
