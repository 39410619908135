import { IPosition, OrderSide, Side } from '../../../trading';

/**
 * Trade intent is a representation of user's intent to submit an order or enter/exit a position.
 */
export class TradeIntent {
  private readonly position: IPosition;
  private readonly side: OrderSide;
  private readonly dto: any; // TODO add types ⇒ requires a more extensive refactoring with order forms
  private readonly orderQty: number;

  constructor(position: IPosition, side: OrderSide, dto: any) {
    this.side = side;
    this.position = position;
    this.dto = dto;
    this.orderQty = dto.quantity;
  }

  /**
   * User intends to submit an order on the same side as the position
   */
  get isOrderOnSameSide(): boolean {
    return (
      (this.position.side === Side.Long && this.side === OrderSide.Buy) ||
      (this.position.side === Side.Short && this.side === OrderSide.Sell)
    );
  }

  /**
   * User intends to submit an order to the opposite side of the position
   */
  get isOrderOnOppositeSide(): boolean {
    return (
      (this.position.side === Side.Long && this.side === OrderSide.Sell) ||
      (this.position.side === Side.Short && this.side === OrderSide.Buy)
    );
  }

  /**
   * User intends to submit an order with the same size as the position
   */
  get isOrderSameSizeAsPosition(): boolean {
    return this.position.size === this.orderQty;
  }

  /**
   * User intends to enter a new position
   */
  get isEnteringPosition(): boolean {
    return this.position.size === 0;
  }

  /**
   * User intends to increase the position
   */
  get isScaleInPosition(): boolean {
    return this.position.size > 0 && this.isOrderOnSameSide;
  }

  /**
   * User intends to partially close the position
   */
  get isScaleOutPosition(): boolean {
    return this.isOrderOnOppositeSide && !this.isOrderSameSizeAsPosition;
  }

  /**
   * User intends to close position completely
   */
  get isExitingPosition(): boolean {
    return this.isOrderOnOppositeSide && this.isOrderSameSizeAsPosition;
  }

  toJson(): any {
    return {
      side: this.side,
      position: this.position,
      dto: this.dto,
      orderQty: this.orderQty,
      isOrderOnSameSide: this.isOrderOnSameSide,
      isOrderOnOppositeSide: this.isOrderOnOppositeSide,
      isOrderSameSizeAsPosition: this.isOrderSameSizeAsPosition,
      isEnteringPosition: this.isEnteringPosition,
      isScaleInPosition: this.isScaleInPosition,
      isScaleOutPosition: this.isScaleOutPosition,
      isExitingPosition: this.isExitingPosition,
    };
  }
}
