import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { environment } from 'environment';

import { ISentryConfig } from './app/app.config';
import { AppModule } from './app/app.module';
import { VERSION } from './environments/version';

const sentryConfig: ISentryConfig = environment.sentry;
if (sentryConfig?.enabled) {
  Sentry.init({
    environment: environment.appEnv,
    dsn: sentryConfig.dsn,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    ...(VERSION?.version
      ? { release: `tradrr-client@${VERSION.version}+${VERSION.hash}` }
      : {}),
    tracePropagationTargets: sentryConfig.tracePropagationTargets,
    integrations: [
      Sentry.extraErrorDataIntegration(),
      new Sentry.GlobalHandlers({
        onerror: true,
        onunhandledrejection: true,
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      Sentry.captureConsoleIntegration({ levels: ['error', 'assert'] }),
      Sentry.feedbackIntegration({
        autoInject: false,
      }),
      ...(sentryConfig.replaysSessionSampleRate > 0
        ? [new Sentry.Replay()]
        : []),
    ],
    // Performance Monitoring
    tracesSampleRate: sentryConfig.tracesSampleRate, // 1.0 = Capture 100% of the transactions
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower
    // rate in production.
    replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate, // 0.1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate, // 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref: NgModuleRef<AppModule>) => {
    if ((window as any).handleApp) (window as any).handleApp(ref.instance);
  })
  .catch((err) => console.error(err));
