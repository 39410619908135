import { Injectable } from '@angular/core';

import { IOrder, OrderSide, OrderStatus, OrderType } from 'trading';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor() {}

  public getOrdersGroup(
    orders: IOrder[],
    price: number,
    type: OrderType,
    side: OrderSide,
  ): IOrder[] {
    const ignoredOrderStatuses: OrderStatus[] = [
      OrderStatus.Canceled,
      OrderStatus.Rejected,
      OrderStatus.Filled,
    ];
    const priceType: string =
      type === OrderType.StopMarket ? 'triggerPrice' : 'price';

    return orders.filter(
      (order: IOrder): boolean =>
        order[priceType] === price &&
        order.type === type &&
        order.side === side &&
        !ignoredOrderStatuses.includes(order.status),
    );
  }

  public getOrdersGroupQuantity(orders: IOrder[]): number {
    let quantity: number = 0;

    orders.forEach((order: IOrder): void => {
      quantity += order.quantity - (order?.filledQuantity ?? 0);
    });

    return quantity;
  }
}
