import { Component, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent extends FieldType {}
