import { Injectable } from '@angular/core';

import { Id } from 'communication';

import { FeedRelayStats } from './feed-relay-stats';
import { RealtimeType } from './realtime';

export enum WindowType {
  Main = 'mainWindow',
  Child = 'childWindow',
}

export interface IFeedRelayConfig {
  flushBufferInterval: number | null;
}

export interface IFeedRelayBuffer {
  [key: string]: any[];
}

/**
 * Broadcasts market data from main window and child windows.
 * Relays subscribe/unsubscribe requests from child windows to main window.
 */
@Injectable({
  providedIn: 'root',
})
export class FeedRelayService {
  private buffer: IFeedRelayBuffer = {};
  private bufferLastFlushTime: { [key: string]: number } = {};
  public stats: FeedRelayStats = new FeedRelayStats();

  private channels: { [key: string]: BroadcastChannel } = {};
  private config: { [key: string]: IFeedRelayConfig } = {};

  constructor() {}

  useConfig(messageType: RealtimeType, config: IFeedRelayConfig): void {
    this.config[messageType] = config;
  }

  getChannel(
    messageType: RealtimeType,
    targetWindowType: WindowType,
  ): BroadcastChannel {
    if (!messageType) {
      throw new Error('Unable to fetch channel: messageType is required');
    }
    const key: string = `${messageType}-${targetWindowType}`;
    if (!this.channels[key]) {
      this.channels[key] = new BroadcastChannel(`broadcast-${key}`);
    }
    return this.channels[key];
  }

  listen(
    messageType: RealtimeType,
    targetWindowType: WindowType,
    callbackFn: (event: MessageEvent) => void,
  ): void {
    const channel: BroadcastChannel = this.getChannel(
      messageType,
      targetWindowType,
    );

    channel.addEventListener('message', (event: MessageEvent): void => {
      callbackFn(event);
      this.stats.increaseReceived(messageType);
    });
  }

  // TODO rename this: this is where child windows listen for incoming messages
  listenToChildWindows(
    messageType: RealtimeType,
    callbackFn: (event: MessageEvent) => void,
  ): void {
    const channel: BroadcastChannel = this.getChannel(
      messageType,
      WindowType.Child,
    );

    channel.addEventListener('message', (event: MessageEvent): void => {
      callbackFn(event);
      this.stats.increaseReceived(messageType);
    });
  }

  listenToMainWindows(
    messageType: RealtimeType,
    callbackFn: (event: MessageEvent) => void,
  ): void {
    const channel: BroadcastChannel = this.getChannel(
      messageType,
      WindowType.Main,
    );

    channel.addEventListener('message', (event: MessageEvent): void => {
      callbackFn(event);
      this.stats.increaseReceived(messageType);
    });
  }

  send(
    messageType: RealtimeType,
    targetWindowType: WindowType,
    data: any,
  ): void {
    const channel: BroadcastChannel = this.getChannel(
      messageType,
      targetWindowType,
    );

    channel.postMessage({
      for: targetWindowType,
      ...data,
    });
  }

  sendToMainWindow(messageType: RealtimeType, data: any): void {
    this.send(messageType, WindowType.Main, {
      for: WindowType.Main,
      ...data,
    });
  }

  sendToChildWindows(messageType: RealtimeType, data: any): void {
    this.send(messageType, WindowType.Child, {
      for: WindowType.Child,
      ...data,
    });
  }

  shouldFlushBuffer(messageType: RealtimeType): boolean {
    const flushTimeInterval = this.config[messageType]?.flushBufferInterval;
    let shouldFlush: boolean = true;

    if (flushTimeInterval) {
      if (!this.bufferLastFlushTime[messageType]) {
        this.bufferLastFlushTime[messageType] = performance.now();
      }
      const now: number = performance.now();
      const lastFlushTime: number = this.bufferLastFlushTime[messageType];
      shouldFlush = !lastFlushTime || now - lastFlushTime > flushTimeInterval;
      if (shouldFlush) {
        this.bufferLastFlushTime[messageType] = now;
      }
    }

    return shouldFlush;
  }

  broadcast(messageType: RealtimeType, data: any, connectionId: Id): void {
    if (!this.buffer[messageType]) this.buffer[messageType] = [];
    this.buffer[messageType].push(data);

    // Flush buffer every x ms
    if (this.shouldFlushBuffer(messageType)) {
      this.sendToChildWindows(messageType, {
        data: this.buffer[messageType],
        connectionId,
      });
      this.buffer[messageType] = [];
      this.stats.increaseSent(messageType);
    }
  }
}
