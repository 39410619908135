import { Context } from "./context";

export class ContextManager {

    private readonly _contexts: Map<string, Context> = new Map();

    registerContext(context: Context) {
        this._contexts.set(context.name, context);
    }

    getContext(name: string) { 
        return this._contexts.get(name);
    }

    has(name: string) {
        return this._contexts.has(name);
    }

}