import { BehaviorSubject, Observable } from 'rxjs';

import { RProtocolMessageTemplateNameEnum } from '../../../projects/communication/src/services/rprotocol/rprotocol-message-template-name-enum';

export enum LogEntryType {
  Request = 'request',
  Response = 'response',
}

export interface TradeLogEntry {
  id: string;
  timestamp: number;
  type: LogEntryType;
  templateType?: string;
  content: unknown;
}

export class TradeLogger {
  public static RequestOrderTemplateTypes: RProtocolMessageTemplateNameEnum[] =
    [
      RProtocolMessageTemplateNameEnum.RequestCancelOrder,
      RProtocolMessageTemplateNameEnum.RequestModifyOrder,
      RProtocolMessageTemplateNameEnum.RequestExitPosition,
      RProtocolMessageTemplateNameEnum.RequestNewOrder,
      RProtocolMessageTemplateNameEnum.RequestBracketOrder,
      RProtocolMessageTemplateNameEnum.RequestOCOOrder,
    ];
  public static ResponseOrderTemplateTypes: RProtocolMessageTemplateNameEnum[] =
    [
      RProtocolMessageTemplateNameEnum.ResponseCancelOrder,
      RProtocolMessageTemplateNameEnum.ResponseModifyOrder,
      RProtocolMessageTemplateNameEnum.ResponseExitPosition,
      RProtocolMessageTemplateNameEnum.ResponseNewOrder,
      RProtocolMessageTemplateNameEnum.ResponseBracketOrder,
      RProtocolMessageTemplateNameEnum.ResponseOCOOrder,
      RProtocolMessageTemplateNameEnum.RithmicOrderNotification,
      RProtocolMessageTemplateNameEnum.ExchangeOrderNotification,
    ];

  protected logs: BehaviorSubject<TradeLogEntry[]> = new BehaviorSubject<
    TradeLogEntry[]
  >([]);
  private readonly MAX_ENTRIES: number = 500;
  private readonly EXPIRATION_DAYS: number = 3;

  protected cleanExpiredLogs(): void {
    if (this.logs.value.length === 0) {
      return;
    }
    const now: number = Date.now();
    const expirationTime: number =
      now - this.EXPIRATION_DAYS * 24 * 60 * 60 * 1000;
    const validLogs: TradeLogEntry[] = this.logs.value.filter(
      (log: TradeLogEntry): boolean => log.timestamp > expirationTime,
    );
    this.logs.next(validLogs);
    this.onLogsUpdated();
  }

  logRequest(
    content: unknown,
    additionalData: Partial<TradeLogEntry> = {},
  ): void {
    this.addLogEntry(LogEntryType.Request, content, additionalData);
  }

  logResponse(
    content: unknown,
    additionalData: Partial<TradeLogEntry> = {},
  ): void {
    this.addLogEntry(LogEntryType.Response, content, additionalData);
  }

  private addLogEntry(
    type: LogEntryType,
    content: unknown,
    additionalData: Partial<TradeLogEntry> = {},
  ): void {
    const newEntry: TradeLogEntry = {
      ...additionalData,
      id: this.generateUniqueId(),
      timestamp: Date.now(),
      type,
      content,
    };

    const updatedLogs = [newEntry, ...this.logs.value].slice(
      0,
      this.MAX_ENTRIES,
    );
    this.logs.next(updatedLogs);
    this.onLogsUpdated();
  }

  protected onLogsUpdated(): void {
    // do nothing
  }

  private generateUniqueId(): string {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  clearLogs(): void {
    this.logs.next([]);
  }

  getLogs(): Observable<TradeLogEntry[]> {
    return this.logs.asObservable();
  }

  getAllLogs(): TradeLogEntry[] {
    return this.logs.value;
  }

  logIfOrderRequest(
    templateType: RProtocolMessageTemplateNameEnum,
    requestData: unknown,
  ): void {
    if (!TradeLogger.RequestOrderTemplateTypes.includes(templateType)) {
      return;
    }
    this.logRequest(requestData, { templateType });
  }

  logIfOrderResponse(
    templateType: RProtocolMessageTemplateNameEnum,
    responseData: unknown,
  ): void {
    if (!TradeLogger.ResponseOrderTemplateTypes.includes(templateType)) {
      return;
    }
    if ((responseData as any)?.isSnapshot) {
      return;
    }
    this.logResponse(responseData, { templateType });
  }
}
