import { Injectable } from '@angular/core';

import { IInstrument, VolumeData } from 'trading';

import { IFeedRelayConfig } from './feed-relay.service';
import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';
import { WSMessageTypes } from './ws-message-types';

@Injectable()
export class RealVolumeDatafeed extends RealFeed<VolumeData, IInstrument> {
  feedType = RealtimeType.VolumePrint;
  get useFeedRelay(): boolean {
    return true;
  }
  get provideFeedRelayConfig(): IFeedRelayConfig {
    return {
      flushBufferInterval: 30,
    };
  }

  subscribeType = WSMessageTypes.SUBSCRIBE;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE;
}
