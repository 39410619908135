<button
  nz-button
  nz-popover
  nzPopoverTrigger="click"
  nzPopoverOverlayClassName="tick-overlay {{ overlayClass }}"
  [nzPopoverContent]="slTicksContent"
  [class.turned-off]="!form.value.stopLoss"
  class="sl-tick tick-btn"
>
  {{ form.value.stopLoss ? getTitle() : 'SL: OFF' }}
</button>
<ng-template #slTicksContent>
  <form [formGroup]="form">
    <div class="d-flex align-items-center">
      <span class="checkbox-title">Stop Loss</span>
      <nz-switch
        nzSize="small"
        (ngModelChange)="onValueChange($event)"
        class="checkbox-right"
        formControlName="stopLoss"
      ></nz-switch>
    </div>
    <div class="divider"></div>
    <!-- <div class="d-flex stp-buttons">
      <button [disabled]="isDisabled" class="black-btn popover-btn"
              [class.btn-active]="isCurrentType(slType.MKT)"
              (click)="showAmount = false; updateType(slType.MKT)" nz-button>STP MKT
      </button>
      <button
        [disabled]="isDisabled"
        [class.btn-active]="isCurrentType(slType.LMT)"
        class="black-btn popover-btn" (click)="showAmount = true; updateType(slType.LMT)" nz-button>STP LMT
      </button>
    </div> -->
    <div *ngIf="showAmount" class="d-grid amount-container">
      <label class="amount" for="amount">Amount</label>
      <nz-input-number
        class="amount-control"
        id="amount"
        formControlName="amount"
      ></nz-input-number>
    </div>

    <nz-select
      *ngIf="!IS_ONLY_TICK_UNIT_ENABLED"
      nzOptionHeightPx="24"
      formControlName="unit"
      class="popover-select"
    >
      <nz-option nzValue="points" nzLabel="Points"></nz-option>
      <nz-option nzValue="currency" nzLabel="Currency"></nz-option>
      <nz-option nzValue="ticks" nzLabel="Ticks"></nz-option>
    </nz-select>

    <div class="stop-loss-ticks">
      <p class="stop-loss-ticks__label-wrapper">
        <label
          class="stop-loss-ticks__label"
          for="stopLossUnitItem"
          (click)="focusOnTicksValue()">Ticks</label>:
      </p>
      <nz-input-number
        #stopLossTicksValue
        class="number-input stop-loss-ticks__input"
        formControlName="unitItem"
      ></nz-input-number>
    </div>
  </form>
</ng-template>
