import { IBar } from 'chart';

import { IInstrument } from '../models';
import { DataFeed } from './data-feed';

export interface OHLVData extends IBar {
  instrument: IInstrument;
  netChange?: number;
  percentChange?: number;
}

export abstract class OHLVFeed extends DataFeed<OHLVData> {}
