import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';

import { ILayoutNode } from 'layout';

import { WindowManager } from '../../../simple-window-manager/WindowManager';
import { EVENTS, Position } from './enums';
import { IWindow, IWindowManager } from './interfaces';
import { Bounds, Options, saveData } from './types';

const Shift = 30;
const StartShiftY = 36;
const StartShiftX = Shift;

export type Coords = {
  x: number;
  y: number;
};

@Injectable()
export class WindowManagerService {
  private wm: IWindowManager;
  private bounds: Bounds;
  public windows: BehaviorSubject<IWindow[]> = new BehaviorSubject([]);

  get activeWindow() {
    return this.wm.activeWindow;
  }

  get container(): HTMLElement {
    return this.wm.container;
  }

  public createWindow(options: Options): IWindow {
    const { x, y } = this.calculatePosition(options);

    options.x = x;
    options.y = y;

    const win = this.wm.createWindow(options);

    win.type = options.type;

    this.updateWindows();

    win.on(EVENTS.CLOSE, () => this.windows.next(this.wm.windows));

    return win;
  }

  public createWindowManager(container): void {
    if (this.wm) {
      console.warn('Window manager already exist');
      return;
    }

    this.wm = new WindowManager({
      parent: container.nativeElement,
      snapOffset: 12,
      snapIgnoreOffset: 12,
    });
  }

  public load(config) {
    this.wm.load(config);
    this.updateWindows();
  }

  public saveState(): saveData[] {
    return this.wm.save();
  }

  public closeAll(): void {
    if (!this.wm) return;

    while (this.wm.windows.length) this.wm.windows[0].close();

    this.updateWindows();
  }

  updateWindows({ restoreOrder = false } = {}) {
    this.windows.next(this.wm.windows);
    if (restoreOrder && this.wm.windows?.length > 0) {
      // Rearrange the order of overlapping windows based on their assigned 'order' values stored in user's workspace config
      this.wm.windows = this.wm.windows.sort(
        (a, b) => a.options.order - b.options.order,
      );
      this.wm._reorder();

      // Focus on the new front-most window and Blur the previously active window.
      // The window with the highest `order` value will be the front-most window.
      const previouslyActiveWindow = this.wm.activeWindow;
      const frontWindow = this.wm.windows[this.wm.windows.length - 1];
      previouslyActiveWindow.blur();
      frontWindow.focus();
    }
  }

  public hideAll() {
    this.wm.windows.forEach((win) => {
      win.visible = false;
    });
    this.updateWindows();
  }

  public updateGlobalOffset(): void {
    this.wm.updateGlobalOffset();
  }

  public getWindowByComponent(component: ILayoutNode): IWindow {
    return this.windows.value.find((i) => i.component === component);
  }

  setBounds(bounds: Bounds): void {
    this.bounds = bounds;
    this.wm.setCustomBounds(bounds);
  }

  private calculatePosition(options: Options): Coords {
    const width = options.width || options.minWidth;
    const height = options.height || options.minHeight;

    const containerWidth = this.wm.container.clientWidth;
    const containerHeight = this.wm.container.clientHeight;

    let x = options.x;
    let y = options.y;

    if (typeof x === 'string') {
      switch (x) {
        case Position.LEFT:
          x = 0;
          break;
        case Position.RIGHT:
          x = containerWidth - width;
          break;
        case Position.CENTER:
          x = containerWidth / 2 - width / 2;
          break;

        default:
          x = null;
          break;
      }
    }

    if (typeof y === 'string') {
      switch (y) {
        case Position.TOP:
          y = 0;
          break;
        case Position.BOTTOM:
          y = containerHeight - height;
          break;
        case Position.CENTER:
          y = containerHeight / 2 - height / 2;
          break;

        default:
          y = null;
          break;
      }
    }

    if (x == null && y == null) {
      ({ x, y } = this._getOffsetForNewWindow());
    }

    return { x: x as number, y: y as number };
  }

  /**
   * Use to avoid opening of new windows at the same position.
   */
  private _getOffsetForNewWindow(): Coords {
    const offset: Coords = this._normalizeCordsConsideringBounds({
      x: StartShiftX,
      y: StartShiftY,
    });
    let windowAtSamePosition: IWindow;

    do {
      windowAtSamePosition = this.wm.windows.find(
        (windowItem: IWindow): boolean =>
          windowItem.x === offset.x && windowItem.y === offset.y,
      );
      if (windowAtSamePosition) {
        offset.x += Shift;
        offset.y += Shift;
      } else {
        return offset;
      }
    } while (windowAtSamePosition);
  }

  private _normalizeCordsConsideringBounds(coords: Coords): Coords {
    coords = { ...coords };

    if (!this.bounds) return coords;

    if (this.bounds.top > coords.y) coords.y = this.bounds.top;

    if (this.bounds.left > coords.x) coords.x = this.bounds.left;

    return coords;
  }
}
