import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  interval,
  Observable,
  Subscriber,
  Subscription,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * @description This service estimates user's network latency by loading a small image
 * from a remote server and measuring the load time (round-trip time).
 */
@Injectable({
  providedIn: 'root',
})
export class UserNetworkLatency {
  private readonly PING_URL: string =
    'https://tradrr-builds-dev.s3.us-east-1.amazonaws.com/hello.gif';
  private readonly TIMEOUT: number = 5000; // 5 seconds timeout
  private readonly PING_COUNT: number = 3; // Number of pings to average

  protected latencySubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  private measurementSubscription: Subscription | null = null;

  constructor() {}

  getLatency(): number {
    return this.latencySubject.getValue();
  }

  startRegularMeasurements(intervalSeconds: number = 30): void {
    if (this.measurementSubscription) {
      this.measurementSubscription.unsubscribe();
    }

    this.measurementSubscription = interval(intervalSeconds * 1000)
      .pipe(
        switchMap((): Observable<number> => this.performLatencyMeasurement()),
      )
      .subscribe(
        (latency: number) => this.latencySubject.next(latency),
        (error): void =>
          console.error('Error in regular latency measurement:', error),
      );

    // Perform an initial measurement immediately
    this.performLatencyMeasurement().subscribe(
      (latency: number) => this.latencySubject.next(latency),
      (error): void =>
        console.error('Error in initial latency measurement:', error),
    );
  }

  stopRegularMeasurements(): void {
    if (this.measurementSubscription) {
      this.measurementSubscription.unsubscribe();
      this.measurementSubscription = null;
    }
  }

  /**
   * Measures the network latency by "pinging" an external server.
   * @returns An Observable that emits the average latency in milliseconds.
   */
  private performLatencyMeasurement(): Observable<number> {
    return new Observable<number>((observer: Subscriber<number>) => {
      this.performPings()
        .then((latencies: number[]): void => {
          const averageLatency: number =
            this.calculateAverageLatency(latencies);
          observer.next(averageLatency);
          observer.complete();
        })
        .catch((error) => {
          console.error('Error performing pings:', error);
          observer.error(error);
        });
    });
  }

  private async performPings(): Promise<number[]> {
    const latencies: number[] = [];

    for (let i: number = 0; i < this.PING_COUNT; i++) {
      const startTime: number = performance.now();
      try {
        await this.pingServer();
        const endTime: number = performance.now();
        latencies.push(endTime - startTime);
      } catch (error) {
        console.warn(`Ping ${i + 1} failed:`, error);
      }
      // Add a small delay between pings
      await this.delay(200);
    }

    return latencies;
  }

  private pingServer(): Promise<void> {
    return new Promise((resolve, reject): void => {
      const img: HTMLImageElement = new Image();
      img.onload = (): void => resolve();
      img.onerror = (): void => resolve(); // We resolve on error too, as we just want to measure the round trip time
      img.src = `${this.PING_URL}?cache-bust=${Date.now()}`;

      // Set a timeout
      setTimeout(() => reject(new Error('Ping timeout')), this.TIMEOUT);
    });
  }

  private calculateAverageLatency(latencies: number[]): number {
    if (latencies.length === 0) return -1;
    const sum: number = latencies.reduce(
      (acc: number, val: number) => acc + val,
      0,
    );
    const roundTripLatency: number = Math.round(sum / latencies.length);
    // Divide by two to account for round trip latency
    return roundTripLatency / 2;
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  ngOnDestroy() {
    this.stopRegularMeasurements();
  }
}
