import { Injectable } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import {
  IMetric,
  IMetricReporter,
  MetricName,
  PerformanceMetricsService,
} from './performance-metrics.service';
import { UserNetworkLatency } from './user-network-latency';

/**
 * @description Metrics reporter for `UserNetworkLatencyMetrics`.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserNetworkLatencyMetrics
  extends UserNetworkLatency
  implements IMetricReporter
{
  constructor(private metricsService: PerformanceMetricsService) {
    super();
    this.metricsService.registerReporter(this);
  }

  collectMetrics(): IMetric[] {
    const metrics: IMetric[] = [];
    const latency: number = this.getLatency();
    if (latency > -1) {
      metrics.push({ name: MetricName.UserNetworkLatency, value: latency });
    }

    return metrics;
  }
  resetMetrics(): void {
    this.latencySubject.next(0);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.metricsService.unregisterReporter(this);
  }
}
