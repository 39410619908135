import { ReversedHashMap } from "../reversed-map";
import { ClassOf } from "../types";
import { KeyBinding } from "./key-binding";

export abstract class Context {

    private _bindings: ReversedHashMap<string, KeyBinding>;

    public abstract readonly name: string;

    constructor(bindings: Map<string, KeyBinding> = new Map()) {
        this._bindings = new ReversedHashMap(bindings[Symbol.iterator]());
    };

    set(command: string, binding: KeyBinding) {
        this._bindings.set(command, binding);
    }

    getCommandFromBinding(binding: KeyBinding) {
        return this._bindings.search(binding);
    }

    has(name: string) {
        return this._bindings.has(name);
    }

    equals(context: Context) {
        return this.name === context.name;
    }

}


export function createContext(name: string): ClassOf<Context> {
    return class extends Context {
        get name() {
            return name;
        }
    }
}
