/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function (global, factory) {
  /* global define, require, module */

  /* AMD */ if (typeof define === 'function' && define.amd)
    define(['protobufjs/minimal'], factory);
  /* CommonJS */ else if (
    typeof require === 'function' &&
    typeof module === 'object' &&
    module &&
    module.exports
  )
    module.exports = factory(require('protobufjs/minimal'));
})(this, function ($protobuf) {
  'use strict';

  // Common aliases
  var $Reader = $protobuf.Reader,
    $Writer = $protobuf.Writer,
    $util = $protobuf.util;

  // Exported root namespace
  var $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

  $root.rti = (function () {
    /**
     * Namespace rti.
     * @exports rti
     * @namespace
     */
    var rti = {};

    rti.RequestLogin = (function () {
      /**
       * Properties of a RequestLogin.
       * @memberof rti
       * @interface IRequestLogin
       * @property {number} templateId RequestLogin templateId
       * @property {string|null} [templateVersion] RequestLogin templateVersion
       * @property {Array.<string>|null} [userMsg] RequestLogin userMsg
       * @property {string|null} [user] RequestLogin user
       * @property {string|null} [password] RequestLogin password
       * @property {string|null} [appName] RequestLogin appName
       * @property {string|null} [appVersion] RequestLogin appVersion
       * @property {string|null} [systemName] RequestLogin systemName
       * @property {rti.RequestLogin.SysInfraType|null} [infraType] RequestLogin infraType
       * @property {Array.<string>|null} [macAddr] RequestLogin macAddr
       * @property {string|null} [osVersion] RequestLogin osVersion
       * @property {string|null} [osPlatform] RequestLogin osPlatform
       * @property {boolean|null} [aggregatedQuotes] RequestLogin aggregatedQuotes
       */

      /**
       * Constructs a new RequestLogin.
       * @memberof rti
       * @classdesc Represents a RequestLogin.
       * @implements IRequestLogin
       * @constructor
       * @param {rti.IRequestLogin=} [properties] Properties to set
       */
      function RequestLogin(properties) {
        this.userMsg = [];
        this.macAddr = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestLogin templateId.
       * @member {number} templateId
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.templateId = 0;

      /**
       * RequestLogin templateVersion.
       * @member {string} templateVersion
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.templateVersion = '';

      /**
       * RequestLogin userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.userMsg = $util.emptyArray;

      /**
       * RequestLogin user.
       * @member {string} user
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.user = '';

      /**
       * RequestLogin password.
       * @member {string} password
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.password = '';

      /**
       * RequestLogin appName.
       * @member {string} appName
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.appName = '';

      /**
       * RequestLogin appVersion.
       * @member {string} appVersion
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.appVersion = '';

      /**
       * RequestLogin systemName.
       * @member {string} systemName
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.systemName = '';

      /**
       * RequestLogin infraType.
       * @member {rti.RequestLogin.SysInfraType} infraType
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.infraType = 1;

      /**
       * RequestLogin macAddr.
       * @member {Array.<string>} macAddr
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.macAddr = $util.emptyArray;

      /**
       * RequestLogin osVersion.
       * @member {string} osVersion
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.osVersion = '';

      /**
       * RequestLogin osPlatform.
       * @member {string} osPlatform
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.osPlatform = '';

      /**
       * RequestLogin aggregatedQuotes.
       * @member {boolean} aggregatedQuotes
       * @memberof rti.RequestLogin
       * @instance
       */
      RequestLogin.prototype.aggregatedQuotes = false;

      /**
       * Creates a new RequestLogin instance using the specified properties.
       * @function create
       * @memberof rti.RequestLogin
       * @static
       * @param {rti.IRequestLogin=} [properties] Properties to set
       * @returns {rti.RequestLogin} RequestLogin instance
       */
      RequestLogin.create = function create(properties) {
        return new RequestLogin(properties);
      };

      /**
       * Encodes the specified RequestLogin message. Does not implicitly {@link rti.RequestLogin.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestLogin
       * @static
       * @param {rti.IRequestLogin} message RequestLogin message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestLogin.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.appName != null &&
          Object.hasOwnProperty.call(message, 'appName')
        )
          writer
            .uint32(/* id 130002, wireType 2 =*/ 1040018)
            .string(message.appName);
        if (
          message.password != null &&
          Object.hasOwnProperty.call(message, 'password')
        )
          writer
            .uint32(/* id 130004, wireType 2 =*/ 1040034)
            .string(message.password);
        if (message.user != null && Object.hasOwnProperty.call(message, 'user'))
          writer
            .uint32(/* id 131003, wireType 2 =*/ 1048026)
            .string(message.user);
        if (
          message.appVersion != null &&
          Object.hasOwnProperty.call(message, 'appVersion')
        )
          writer
            .uint32(/* id 131803, wireType 2 =*/ 1054426)
            .string(message.appVersion);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.osPlatform != null &&
          Object.hasOwnProperty.call(message, 'osPlatform')
        )
          writer
            .uint32(/* id 144020, wireType 2 =*/ 1152162)
            .string(message.osPlatform);
        if (
          message.osVersion != null &&
          Object.hasOwnProperty.call(message, 'osVersion')
        )
          writer
            .uint32(/* id 144021, wireType 2 =*/ 1152170)
            .string(message.osVersion);
        if (message.macAddr != null && message.macAddr.length)
          for (var i = 0; i < message.macAddr.length; ++i)
            writer
              .uint32(/* id 144108, wireType 2 =*/ 1152866)
              .string(message.macAddr[i]);
        if (
          message.infraType != null &&
          Object.hasOwnProperty.call(message, 'infraType')
        )
          writer
            .uint32(/* id 153621, wireType 0 =*/ 1228968)
            .int32(message.infraType);
        if (
          message.systemName != null &&
          Object.hasOwnProperty.call(message, 'systemName')
        )
          writer
            .uint32(/* id 153628, wireType 2 =*/ 1229026)
            .string(message.systemName);
        if (
          message.templateVersion != null &&
          Object.hasOwnProperty.call(message, 'templateVersion')
        )
          writer
            .uint32(/* id 153634, wireType 2 =*/ 1229074)
            .string(message.templateVersion);
        if (
          message.aggregatedQuotes != null &&
          Object.hasOwnProperty.call(message, 'aggregatedQuotes')
        )
          writer
            .uint32(/* id 153644, wireType 0 =*/ 1229152)
            .bool(message.aggregatedQuotes);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestLogin message, length delimited. Does not implicitly {@link rti.RequestLogin.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestLogin
       * @static
       * @param {rti.IRequestLogin} message RequestLogin message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestLogin.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestLogin message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestLogin
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestLogin} RequestLogin
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestLogin.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestLogin();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 153634:
              message.templateVersion = reader.string();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 131003:
              message.user = reader.string();
              break;
            case 130004:
              message.password = reader.string();
              break;
            case 130002:
              message.appName = reader.string();
              break;
            case 131803:
              message.appVersion = reader.string();
              break;
            case 153628:
              message.systemName = reader.string();
              break;
            case 153621:
              message.infraType = reader.int32();
              break;
            case 144108:
              if (!(message.macAddr && message.macAddr.length))
                message.macAddr = [];
              message.macAddr.push(reader.string());
              break;
            case 144021:
              message.osVersion = reader.string();
              break;
            case 144020:
              message.osPlatform = reader.string();
              break;
            case 153644:
              message.aggregatedQuotes = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestLogin message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestLogin
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestLogin} RequestLogin
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestLogin.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestLogin message.
       * @function verify
       * @memberof rti.RequestLogin
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestLogin.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (
          message.templateVersion != null &&
          message.hasOwnProperty('templateVersion')
        )
          if (!$util.isString(message.templateVersion))
            return 'templateVersion: string expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.user != null && message.hasOwnProperty('user'))
          if (!$util.isString(message.user)) return 'user: string expected';
        if (message.password != null && message.hasOwnProperty('password'))
          if (!$util.isString(message.password))
            return 'password: string expected';
        if (message.appName != null && message.hasOwnProperty('appName'))
          if (!$util.isString(message.appName))
            return 'appName: string expected';
        if (message.appVersion != null && message.hasOwnProperty('appVersion'))
          if (!$util.isString(message.appVersion))
            return 'appVersion: string expected';
        if (message.systemName != null && message.hasOwnProperty('systemName'))
          if (!$util.isString(message.systemName))
            return 'systemName: string expected';
        if (message.infraType != null && message.hasOwnProperty('infraType'))
          switch (message.infraType) {
            default:
              return 'infraType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
              break;
          }
        if (message.macAddr != null && message.hasOwnProperty('macAddr')) {
          if (!Array.isArray(message.macAddr)) return 'macAddr: array expected';
          for (var i = 0; i < message.macAddr.length; ++i)
            if (!$util.isString(message.macAddr[i]))
              return 'macAddr: string[] expected';
        }
        if (message.osVersion != null && message.hasOwnProperty('osVersion'))
          if (!$util.isString(message.osVersion))
            return 'osVersion: string expected';
        if (message.osPlatform != null && message.hasOwnProperty('osPlatform'))
          if (!$util.isString(message.osPlatform))
            return 'osPlatform: string expected';
        if (
          message.aggregatedQuotes != null &&
          message.hasOwnProperty('aggregatedQuotes')
        )
          if (typeof message.aggregatedQuotes !== 'boolean')
            return 'aggregatedQuotes: boolean expected';
        return null;
      };

      /**
       * Creates a RequestLogin message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestLogin
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestLogin} RequestLogin
       */
      RequestLogin.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestLogin) return object;
        var message = new $root.rti.RequestLogin();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.templateVersion != null)
          message.templateVersion = String(object.templateVersion);
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestLogin.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.user != null) message.user = String(object.user);
        if (object.password != null) message.password = String(object.password);
        if (object.appName != null) message.appName = String(object.appName);
        if (object.appVersion != null)
          message.appVersion = String(object.appVersion);
        if (object.systemName != null)
          message.systemName = String(object.systemName);
        switch (object.infraType) {
          case 'TICKER_PLANT':
          case 1:
            message.infraType = 1;
            break;
          case 'ORDER_PLANT':
          case 2:
            message.infraType = 2;
            break;
          case 'HISTORY_PLANT':
          case 3:
            message.infraType = 3;
            break;
          case 'PNL_PLANT':
          case 4:
            message.infraType = 4;
            break;
          case 'REPOSITORY_PLANT':
          case 5:
            message.infraType = 5;
            break;
        }
        if (object.macAddr) {
          if (!Array.isArray(object.macAddr))
            throw TypeError('.rti.RequestLogin.macAddr: array expected');
          message.macAddr = [];
          for (var i = 0; i < object.macAddr.length; ++i)
            message.macAddr[i] = String(object.macAddr[i]);
        }
        if (object.osVersion != null)
          message.osVersion = String(object.osVersion);
        if (object.osPlatform != null)
          message.osPlatform = String(object.osPlatform);
        if (object.aggregatedQuotes != null)
          message.aggregatedQuotes = Boolean(object.aggregatedQuotes);
        return message;
      };

      /**
       * Creates a plain object from a RequestLogin message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestLogin
       * @static
       * @param {rti.RequestLogin} message RequestLogin
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestLogin.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.macAddr = [];
        }
        if (options.defaults) {
          object.appName = '';
          object.password = '';
          object.user = '';
          object.appVersion = '';
          object.osPlatform = '';
          object.osVersion = '';
          object.infraType = options.enums === String ? 'TICKER_PLANT' : 1;
          object.systemName = '';
          object.templateVersion = '';
          object.aggregatedQuotes = false;
          object.templateId = 0;
        }
        if (message.appName != null && message.hasOwnProperty('appName'))
          object.appName = message.appName;
        if (message.password != null && message.hasOwnProperty('password'))
          object.password = message.password;
        if (message.user != null && message.hasOwnProperty('user'))
          object.user = message.user;
        if (message.appVersion != null && message.hasOwnProperty('appVersion'))
          object.appVersion = message.appVersion;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.osPlatform != null && message.hasOwnProperty('osPlatform'))
          object.osPlatform = message.osPlatform;
        if (message.osVersion != null && message.hasOwnProperty('osVersion'))
          object.osVersion = message.osVersion;
        if (message.macAddr && message.macAddr.length) {
          object.macAddr = [];
          for (var j = 0; j < message.macAddr.length; ++j)
            object.macAddr[j] = message.macAddr[j];
        }
        if (message.infraType != null && message.hasOwnProperty('infraType'))
          object.infraType =
            options.enums === String
              ? $root.rti.RequestLogin.SysInfraType[message.infraType]
              : message.infraType;
        if (message.systemName != null && message.hasOwnProperty('systemName'))
          object.systemName = message.systemName;
        if (
          message.templateVersion != null &&
          message.hasOwnProperty('templateVersion')
        )
          object.templateVersion = message.templateVersion;
        if (
          message.aggregatedQuotes != null &&
          message.hasOwnProperty('aggregatedQuotes')
        )
          object.aggregatedQuotes = message.aggregatedQuotes;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestLogin to JSON.
       * @function toJSON
       * @memberof rti.RequestLogin
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestLogin.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestLogin
       * @function getTypeUrl
       * @memberof rti.RequestLogin
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestLogin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestLogin';
      };

      /**
       * SysInfraType enum.
       * @name rti.RequestLogin.SysInfraType
       * @enum {number}
       * @property {number} TICKER_PLANT=1 TICKER_PLANT value
       * @property {number} ORDER_PLANT=2 ORDER_PLANT value
       * @property {number} HISTORY_PLANT=3 HISTORY_PLANT value
       * @property {number} PNL_PLANT=4 PNL_PLANT value
       * @property {number} REPOSITORY_PLANT=5 REPOSITORY_PLANT value
       */
      RequestLogin.SysInfraType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'TICKER_PLANT')] = 1;
        values[(valuesById[2] = 'ORDER_PLANT')] = 2;
        values[(valuesById[3] = 'HISTORY_PLANT')] = 3;
        values[(valuesById[4] = 'PNL_PLANT')] = 4;
        values[(valuesById[5] = 'REPOSITORY_PLANT')] = 5;
        return values;
      })();

      return RequestLogin;
    })();

    rti.ResponseLogin = (function () {
      /**
       * Properties of a ResponseLogin.
       * @memberof rti
       * @interface IResponseLogin
       * @property {number} templateId ResponseLogin templateId
       * @property {string|null} [templateVersion] ResponseLogin templateVersion
       * @property {Array.<string>|null} [userMsg] ResponseLogin userMsg
       * @property {Array.<string>|null} [rpCode] ResponseLogin rpCode
       * @property {string|null} [fcmId] ResponseLogin fcmId
       * @property {string|null} [ibId] ResponseLogin ibId
       * @property {string|null} [countryCode] ResponseLogin countryCode
       * @property {string|null} [stateCode] ResponseLogin stateCode
       * @property {string|null} [uniqueUserId] ResponseLogin uniqueUserId
       * @property {number|null} [heartbeatInterval] ResponseLogin heartbeatInterval
       */

      /**
       * Constructs a new ResponseLogin.
       * @memberof rti
       * @classdesc Represents a ResponseLogin.
       * @implements IResponseLogin
       * @constructor
       * @param {rti.IResponseLogin=} [properties] Properties to set
       */
      function ResponseLogin(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseLogin templateId.
       * @member {number} templateId
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.templateId = 0;

      /**
       * ResponseLogin templateVersion.
       * @member {string} templateVersion
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.templateVersion = '';

      /**
       * ResponseLogin userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseLogin rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseLogin fcmId.
       * @member {string} fcmId
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.fcmId = '';

      /**
       * ResponseLogin ibId.
       * @member {string} ibId
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.ibId = '';

      /**
       * ResponseLogin countryCode.
       * @member {string} countryCode
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.countryCode = '';

      /**
       * ResponseLogin stateCode.
       * @member {string} stateCode
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.stateCode = '';

      /**
       * ResponseLogin uniqueUserId.
       * @member {string} uniqueUserId
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.uniqueUserId = '';

      /**
       * ResponseLogin heartbeatInterval.
       * @member {number} heartbeatInterval
       * @memberof rti.ResponseLogin
       * @instance
       */
      ResponseLogin.prototype.heartbeatInterval = 0;

      /**
       * Creates a new ResponseLogin instance using the specified properties.
       * @function create
       * @memberof rti.ResponseLogin
       * @static
       * @param {rti.IResponseLogin=} [properties] Properties to set
       * @returns {rti.ResponseLogin} ResponseLogin instance
       */
      ResponseLogin.create = function create(properties) {
        return new ResponseLogin(properties);
      };

      /**
       * Encodes the specified ResponseLogin message. Does not implicitly {@link rti.ResponseLogin.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseLogin
       * @static
       * @param {rti.IResponseLogin} message ResponseLogin message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseLogin.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.uniqueUserId != null &&
          Object.hasOwnProperty.call(message, 'uniqueUserId')
        )
          writer
            .uint32(/* id 153428, wireType 2 =*/ 1227426)
            .string(message.uniqueUserId);
        if (
          message.heartbeatInterval != null &&
          Object.hasOwnProperty.call(message, 'heartbeatInterval')
        )
          writer
            .uint32(/* id 153633, wireType 1 =*/ 1229065)
            .double(message.heartbeatInterval);
        if (
          message.templateVersion != null &&
          Object.hasOwnProperty.call(message, 'templateVersion')
        )
          writer
            .uint32(/* id 153634, wireType 2 =*/ 1229074)
            .string(message.templateVersion);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.countryCode != null &&
          Object.hasOwnProperty.call(message, 'countryCode')
        )
          writer
            .uint32(/* id 154712, wireType 2 =*/ 1237698)
            .string(message.countryCode);
        if (
          message.stateCode != null &&
          Object.hasOwnProperty.call(message, 'stateCode')
        )
          writer
            .uint32(/* id 154713, wireType 2 =*/ 1237706)
            .string(message.stateCode);
        return writer;
      };

      /**
       * Encodes the specified ResponseLogin message, length delimited. Does not implicitly {@link rti.ResponseLogin.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseLogin
       * @static
       * @param {rti.IResponseLogin} message ResponseLogin message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseLogin.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseLogin message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseLogin
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseLogin} ResponseLogin
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseLogin.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseLogin();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 153634:
              message.templateVersion = reader.string();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154712:
              message.countryCode = reader.string();
              break;
            case 154713:
              message.stateCode = reader.string();
              break;
            case 153428:
              message.uniqueUserId = reader.string();
              break;
            case 153633:
              message.heartbeatInterval = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseLogin message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseLogin
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseLogin} ResponseLogin
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseLogin.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseLogin message.
       * @function verify
       * @memberof rti.ResponseLogin
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseLogin.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (
          message.templateVersion != null &&
          message.hasOwnProperty('templateVersion')
        )
          if (!$util.isString(message.templateVersion))
            return 'templateVersion: string expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (
          message.countryCode != null &&
          message.hasOwnProperty('countryCode')
        )
          if (!$util.isString(message.countryCode))
            return 'countryCode: string expected';
        if (message.stateCode != null && message.hasOwnProperty('stateCode'))
          if (!$util.isString(message.stateCode))
            return 'stateCode: string expected';
        if (
          message.uniqueUserId != null &&
          message.hasOwnProperty('uniqueUserId')
        )
          if (!$util.isString(message.uniqueUserId))
            return 'uniqueUserId: string expected';
        if (
          message.heartbeatInterval != null &&
          message.hasOwnProperty('heartbeatInterval')
        )
          if (typeof message.heartbeatInterval !== 'number')
            return 'heartbeatInterval: number expected';
        return null;
      };

      /**
       * Creates a ResponseLogin message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseLogin
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseLogin} ResponseLogin
       */
      ResponseLogin.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseLogin) return object;
        var message = new $root.rti.ResponseLogin();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.templateVersion != null)
          message.templateVersion = String(object.templateVersion);
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseLogin.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseLogin.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.countryCode != null)
          message.countryCode = String(object.countryCode);
        if (object.stateCode != null)
          message.stateCode = String(object.stateCode);
        if (object.uniqueUserId != null)
          message.uniqueUserId = String(object.uniqueUserId);
        if (object.heartbeatInterval != null)
          message.heartbeatInterval = Number(object.heartbeatInterval);
        return message;
      };

      /**
       * Creates a plain object from a ResponseLogin message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseLogin
       * @static
       * @param {rti.ResponseLogin} message ResponseLogin
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseLogin.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.uniqueUserId = '';
          object.heartbeatInterval = 0;
          object.templateVersion = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
          object.countryCode = '';
          object.stateCode = '';
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.uniqueUserId != null &&
          message.hasOwnProperty('uniqueUserId')
        )
          object.uniqueUserId = message.uniqueUserId;
        if (
          message.heartbeatInterval != null &&
          message.hasOwnProperty('heartbeatInterval')
        )
          object.heartbeatInterval =
            options.json && !isFinite(message.heartbeatInterval)
              ? String(message.heartbeatInterval)
              : message.heartbeatInterval;
        if (
          message.templateVersion != null &&
          message.hasOwnProperty('templateVersion')
        )
          object.templateVersion = message.templateVersion;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.countryCode != null &&
          message.hasOwnProperty('countryCode')
        )
          object.countryCode = message.countryCode;
        if (message.stateCode != null && message.hasOwnProperty('stateCode'))
          object.stateCode = message.stateCode;
        return object;
      };

      /**
       * Converts this ResponseLogin to JSON.
       * @function toJSON
       * @memberof rti.ResponseLogin
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseLogin.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseLogin
       * @function getTypeUrl
       * @memberof rti.ResponseLogin
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseLogin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseLogin';
      };

      return ResponseLogin;
    })();

    rti.RequestLogout = (function () {
      /**
       * Properties of a RequestLogout.
       * @memberof rti
       * @interface IRequestLogout
       * @property {number} templateId RequestLogout templateId
       * @property {Array.<string>|null} [userMsg] RequestLogout userMsg
       */

      /**
       * Constructs a new RequestLogout.
       * @memberof rti
       * @classdesc Represents a RequestLogout.
       * @implements IRequestLogout
       * @constructor
       * @param {rti.IRequestLogout=} [properties] Properties to set
       */
      function RequestLogout(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestLogout templateId.
       * @member {number} templateId
       * @memberof rti.RequestLogout
       * @instance
       */
      RequestLogout.prototype.templateId = 0;

      /**
       * RequestLogout userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestLogout
       * @instance
       */
      RequestLogout.prototype.userMsg = $util.emptyArray;

      /**
       * Creates a new RequestLogout instance using the specified properties.
       * @function create
       * @memberof rti.RequestLogout
       * @static
       * @param {rti.IRequestLogout=} [properties] Properties to set
       * @returns {rti.RequestLogout} RequestLogout instance
       */
      RequestLogout.create = function create(properties) {
        return new RequestLogout(properties);
      };

      /**
       * Encodes the specified RequestLogout message. Does not implicitly {@link rti.RequestLogout.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestLogout
       * @static
       * @param {rti.IRequestLogout} message RequestLogout message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestLogout.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestLogout message, length delimited. Does not implicitly {@link rti.RequestLogout.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestLogout
       * @static
       * @param {rti.IRequestLogout} message RequestLogout message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestLogout.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestLogout message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestLogout
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestLogout} RequestLogout
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestLogout.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestLogout();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestLogout message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestLogout
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestLogout} RequestLogout
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestLogout.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestLogout message.
       * @function verify
       * @memberof rti.RequestLogout
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestLogout.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        return null;
      };

      /**
       * Creates a RequestLogout message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestLogout
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestLogout} RequestLogout
       */
      RequestLogout.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestLogout) return object;
        var message = new $root.rti.RequestLogout();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestLogout.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestLogout message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestLogout
       * @static
       * @param {rti.RequestLogout} message RequestLogout
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestLogout.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestLogout to JSON.
       * @function toJSON
       * @memberof rti.RequestLogout
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestLogout.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestLogout
       * @function getTypeUrl
       * @memberof rti.RequestLogout
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestLogout.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestLogout';
      };

      return RequestLogout;
    })();

    rti.ResponseLogout = (function () {
      /**
       * Properties of a ResponseLogout.
       * @memberof rti
       * @interface IResponseLogout
       * @property {number} templateId ResponseLogout templateId
       * @property {Array.<string>|null} [userMsg] ResponseLogout userMsg
       * @property {Array.<string>|null} [rpCode] ResponseLogout rpCode
       */

      /**
       * Constructs a new ResponseLogout.
       * @memberof rti
       * @classdesc Represents a ResponseLogout.
       * @implements IResponseLogout
       * @constructor
       * @param {rti.IResponseLogout=} [properties] Properties to set
       */
      function ResponseLogout(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseLogout templateId.
       * @member {number} templateId
       * @memberof rti.ResponseLogout
       * @instance
       */
      ResponseLogout.prototype.templateId = 0;

      /**
       * ResponseLogout userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseLogout
       * @instance
       */
      ResponseLogout.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseLogout rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseLogout
       * @instance
       */
      ResponseLogout.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseLogout instance using the specified properties.
       * @function create
       * @memberof rti.ResponseLogout
       * @static
       * @param {rti.IResponseLogout=} [properties] Properties to set
       * @returns {rti.ResponseLogout} ResponseLogout instance
       */
      ResponseLogout.create = function create(properties) {
        return new ResponseLogout(properties);
      };

      /**
       * Encodes the specified ResponseLogout message. Does not implicitly {@link rti.ResponseLogout.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseLogout
       * @static
       * @param {rti.IResponseLogout} message ResponseLogout message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseLogout.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseLogout message, length delimited. Does not implicitly {@link rti.ResponseLogout.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseLogout
       * @static
       * @param {rti.IResponseLogout} message ResponseLogout message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseLogout.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseLogout message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseLogout
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseLogout} ResponseLogout
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseLogout.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseLogout();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseLogout message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseLogout
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseLogout} ResponseLogout
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseLogout.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseLogout message.
       * @function verify
       * @memberof rti.ResponseLogout
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseLogout.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseLogout message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseLogout
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseLogout} ResponseLogout
       */
      ResponseLogout.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseLogout) return object;
        var message = new $root.rti.ResponseLogout();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseLogout.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseLogout.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseLogout message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseLogout
       * @static
       * @param {rti.ResponseLogout} message ResponseLogout
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseLogout.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseLogout to JSON.
       * @function toJSON
       * @memberof rti.ResponseLogout
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseLogout.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseLogout
       * @function getTypeUrl
       * @memberof rti.ResponseLogout
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseLogout.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseLogout';
      };

      return ResponseLogout;
    })();

    rti.RequestReferenceData = (function () {
      /**
       * Properties of a RequestReferenceData.
       * @memberof rti
       * @interface IRequestReferenceData
       * @property {number} templateId RequestReferenceData templateId
       * @property {Array.<string>|null} [userMsg] RequestReferenceData userMsg
       * @property {string|null} [symbol] RequestReferenceData symbol
       * @property {string|null} [exchange] RequestReferenceData exchange
       */

      /**
       * Constructs a new RequestReferenceData.
       * @memberof rti
       * @classdesc Represents a RequestReferenceData.
       * @implements IRequestReferenceData
       * @constructor
       * @param {rti.IRequestReferenceData=} [properties] Properties to set
       */
      function RequestReferenceData(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestReferenceData templateId.
       * @member {number} templateId
       * @memberof rti.RequestReferenceData
       * @instance
       */
      RequestReferenceData.prototype.templateId = 0;

      /**
       * RequestReferenceData userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestReferenceData
       * @instance
       */
      RequestReferenceData.prototype.userMsg = $util.emptyArray;

      /**
       * RequestReferenceData symbol.
       * @member {string} symbol
       * @memberof rti.RequestReferenceData
       * @instance
       */
      RequestReferenceData.prototype.symbol = '';

      /**
       * RequestReferenceData exchange.
       * @member {string} exchange
       * @memberof rti.RequestReferenceData
       * @instance
       */
      RequestReferenceData.prototype.exchange = '';

      /**
       * Creates a new RequestReferenceData instance using the specified properties.
       * @function create
       * @memberof rti.RequestReferenceData
       * @static
       * @param {rti.IRequestReferenceData=} [properties] Properties to set
       * @returns {rti.RequestReferenceData} RequestReferenceData instance
       */
      RequestReferenceData.create = function create(properties) {
        return new RequestReferenceData(properties);
      };

      /**
       * Encodes the specified RequestReferenceData message. Does not implicitly {@link rti.RequestReferenceData.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestReferenceData
       * @static
       * @param {rti.IRequestReferenceData} message RequestReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestReferenceData.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestReferenceData message, length delimited. Does not implicitly {@link rti.RequestReferenceData.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestReferenceData
       * @static
       * @param {rti.IRequestReferenceData} message RequestReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestReferenceData.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestReferenceData message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestReferenceData} RequestReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestReferenceData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestReferenceData();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestReferenceData message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestReferenceData} RequestReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestReferenceData.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestReferenceData message.
       * @function verify
       * @memberof rti.RequestReferenceData
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestReferenceData.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        return null;
      };

      /**
       * Creates a RequestReferenceData message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestReferenceData
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestReferenceData} RequestReferenceData
       */
      RequestReferenceData.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestReferenceData) return object;
        var message = new $root.rti.RequestReferenceData();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestReferenceData.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        return message;
      };

      /**
       * Creates a plain object from a RequestReferenceData message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestReferenceData
       * @static
       * @param {rti.RequestReferenceData} message RequestReferenceData
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestReferenceData.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestReferenceData to JSON.
       * @function toJSON
       * @memberof rti.RequestReferenceData
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestReferenceData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestReferenceData
       * @function getTypeUrl
       * @memberof rti.RequestReferenceData
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestReferenceData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestReferenceData';
      };

      return RequestReferenceData;
    })();

    rti.ResponseReferenceData = (function () {
      /**
       * Properties of a ResponseReferenceData.
       * @memberof rti
       * @interface IResponseReferenceData
       * @property {number} templateId ResponseReferenceData templateId
       * @property {Array.<string>|null} [userMsg] ResponseReferenceData userMsg
       * @property {Array.<string>|null} [rpCode] ResponseReferenceData rpCode
       * @property {number|null} [presenceBits] ResponseReferenceData presenceBits
       * @property {number|null} [clearBits] ResponseReferenceData clearBits
       * @property {string|null} [symbol] ResponseReferenceData symbol
       * @property {string|null} [exchange] ResponseReferenceData exchange
       * @property {string|null} [exchangeSymbol] ResponseReferenceData exchangeSymbol
       * @property {string|null} [symbolName] ResponseReferenceData symbolName
       * @property {string|null} [tradingSymbol] ResponseReferenceData tradingSymbol
       * @property {string|null} [tradingExchange] ResponseReferenceData tradingExchange
       * @property {string|null} [productCode] ResponseReferenceData productCode
       * @property {string|null} [instrumentType] ResponseReferenceData instrumentType
       * @property {string|null} [underlyingSymbol] ResponseReferenceData underlyingSymbol
       * @property {string|null} [expirationDate] ResponseReferenceData expirationDate
       * @property {string|null} [currency] ResponseReferenceData currency
       * @property {string|null} [putCallIndicator] ResponseReferenceData putCallIndicator
       * @property {string|null} [tickSizeType] ResponseReferenceData tickSizeType
       * @property {string|null} [priceDisplayFormat] ResponseReferenceData priceDisplayFormat
       * @property {string|null} [isTradable] ResponseReferenceData isTradable
       * @property {string|null} [isUnderlyingForBinaryContrats] ResponseReferenceData isUnderlyingForBinaryContrats
       * @property {number|null} [strikePrice] ResponseReferenceData strikePrice
       * @property {number|null} [ftoqPrice] ResponseReferenceData ftoqPrice
       * @property {number|null} [qtofPrice] ResponseReferenceData qtofPrice
       * @property {number|null} [minQpriceChange] ResponseReferenceData minQpriceChange
       * @property {number|null} [minFpriceChange] ResponseReferenceData minFpriceChange
       * @property {number|null} [singlePointValue] ResponseReferenceData singlePointValue
       */

      /**
       * Constructs a new ResponseReferenceData.
       * @memberof rti
       * @classdesc Represents a ResponseReferenceData.
       * @implements IResponseReferenceData
       * @constructor
       * @param {rti.IResponseReferenceData=} [properties] Properties to set
       */
      function ResponseReferenceData(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseReferenceData templateId.
       * @member {number} templateId
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.templateId = 0;

      /**
       * ResponseReferenceData userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseReferenceData rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseReferenceData presenceBits.
       * @member {number} presenceBits
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.presenceBits = 0;

      /**
       * ResponseReferenceData clearBits.
       * @member {number} clearBits
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.clearBits = 0;

      /**
       * ResponseReferenceData symbol.
       * @member {string} symbol
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.symbol = '';

      /**
       * ResponseReferenceData exchange.
       * @member {string} exchange
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.exchange = '';

      /**
       * ResponseReferenceData exchangeSymbol.
       * @member {string} exchangeSymbol
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.exchangeSymbol = '';

      /**
       * ResponseReferenceData symbolName.
       * @member {string} symbolName
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.symbolName = '';

      /**
       * ResponseReferenceData tradingSymbol.
       * @member {string} tradingSymbol
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.tradingSymbol = '';

      /**
       * ResponseReferenceData tradingExchange.
       * @member {string} tradingExchange
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.tradingExchange = '';

      /**
       * ResponseReferenceData productCode.
       * @member {string} productCode
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.productCode = '';

      /**
       * ResponseReferenceData instrumentType.
       * @member {string} instrumentType
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.instrumentType = '';

      /**
       * ResponseReferenceData underlyingSymbol.
       * @member {string} underlyingSymbol
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.underlyingSymbol = '';

      /**
       * ResponseReferenceData expirationDate.
       * @member {string} expirationDate
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.expirationDate = '';

      /**
       * ResponseReferenceData currency.
       * @member {string} currency
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.currency = '';

      /**
       * ResponseReferenceData putCallIndicator.
       * @member {string} putCallIndicator
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.putCallIndicator = '';

      /**
       * ResponseReferenceData tickSizeType.
       * @member {string} tickSizeType
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.tickSizeType = '';

      /**
       * ResponseReferenceData priceDisplayFormat.
       * @member {string} priceDisplayFormat
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.priceDisplayFormat = '';

      /**
       * ResponseReferenceData isTradable.
       * @member {string} isTradable
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.isTradable = '';

      /**
       * ResponseReferenceData isUnderlyingForBinaryContrats.
       * @member {string} isUnderlyingForBinaryContrats
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.isUnderlyingForBinaryContrats = '';

      /**
       * ResponseReferenceData strikePrice.
       * @member {number} strikePrice
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.strikePrice = 0;

      /**
       * ResponseReferenceData ftoqPrice.
       * @member {number} ftoqPrice
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.ftoqPrice = 0;

      /**
       * ResponseReferenceData qtofPrice.
       * @member {number} qtofPrice
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.qtofPrice = 0;

      /**
       * ResponseReferenceData minQpriceChange.
       * @member {number} minQpriceChange
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.minQpriceChange = 0;

      /**
       * ResponseReferenceData minFpriceChange.
       * @member {number} minFpriceChange
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.minFpriceChange = 0;

      /**
       * ResponseReferenceData singlePointValue.
       * @member {number} singlePointValue
       * @memberof rti.ResponseReferenceData
       * @instance
       */
      ResponseReferenceData.prototype.singlePointValue = 0;

      /**
       * Creates a new ResponseReferenceData instance using the specified properties.
       * @function create
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {rti.IResponseReferenceData=} [properties] Properties to set
       * @returns {rti.ResponseReferenceData} ResponseReferenceData instance
       */
      ResponseReferenceData.create = function create(properties) {
        return new ResponseReferenceData(properties);
      };

      /**
       * Encodes the specified ResponseReferenceData message. Does not implicitly {@link rti.ResponseReferenceData.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {rti.IResponseReferenceData} message ResponseReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseReferenceData.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbolName != null &&
          Object.hasOwnProperty.call(message, 'symbolName')
        )
          writer
            .uint32(/* id 100003, wireType 2 =*/ 800026)
            .string(message.symbolName);
        if (
          message.strikePrice != null &&
          Object.hasOwnProperty.call(message, 'strikePrice')
        )
          writer
            .uint32(/* id 100066, wireType 1 =*/ 800529)
            .double(message.strikePrice);
        if (
          message.expirationDate != null &&
          Object.hasOwnProperty.call(message, 'expirationDate')
        )
          writer
            .uint32(/* id 100067, wireType 2 =*/ 800538)
            .string(message.expirationDate);
        if (
          message.putCallIndicator != null &&
          Object.hasOwnProperty.call(message, 'putCallIndicator')
        )
          writer
            .uint32(/* id 100109, wireType 2 =*/ 800874)
            .string(message.putCallIndicator);
        if (
          message.productCode != null &&
          Object.hasOwnProperty.call(message, 'productCode')
        )
          writer
            .uint32(/* id 100749, wireType 2 =*/ 805994)
            .string(message.productCode);
        if (
          message.underlyingSymbol != null &&
          Object.hasOwnProperty.call(message, 'underlyingSymbol')
        )
          writer
            .uint32(/* id 101026, wireType 2 =*/ 808210)
            .string(message.underlyingSymbol);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.exchangeSymbol != null &&
          Object.hasOwnProperty.call(message, 'exchangeSymbol')
        )
          writer
            .uint32(/* id 110114, wireType 2 =*/ 880914)
            .string(message.exchangeSymbol);
        if (
          message.instrumentType != null &&
          Object.hasOwnProperty.call(message, 'instrumentType')
        )
          writer
            .uint32(/* id 110116, wireType 2 =*/ 880930)
            .string(message.instrumentType);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.tickSizeType != null &&
          Object.hasOwnProperty.call(message, 'tickSizeType')
        )
          writer
            .uint32(/* id 154167, wireType 2 =*/ 1233338)
            .string(message.tickSizeType);
        if (
          message.currency != null &&
          Object.hasOwnProperty.call(message, 'currency')
        )
          writer
            .uint32(/* id 154382, wireType 2 =*/ 1235058)
            .string(message.currency);
        if (
          message.ftoqPrice != null &&
          Object.hasOwnProperty.call(message, 'ftoqPrice')
        )
          writer
            .uint32(/* id 154384, wireType 1 =*/ 1235073)
            .double(message.ftoqPrice);
        if (
          message.qtofPrice != null &&
          Object.hasOwnProperty.call(message, 'qtofPrice')
        )
          writer
            .uint32(/* id 154385, wireType 1 =*/ 1235081)
            .double(message.qtofPrice);
        if (
          message.minQpriceChange != null &&
          Object.hasOwnProperty.call(message, 'minQpriceChange')
        )
          writer
            .uint32(/* id 154386, wireType 1 =*/ 1235089)
            .double(message.minQpriceChange);
        if (
          message.minFpriceChange != null &&
          Object.hasOwnProperty.call(message, 'minFpriceChange')
        )
          writer
            .uint32(/* id 154387, wireType 1 =*/ 1235097)
            .double(message.minFpriceChange);
        if (
          message.singlePointValue != null &&
          Object.hasOwnProperty.call(message, 'singlePointValue')
        )
          writer
            .uint32(/* id 154389, wireType 1 =*/ 1235113)
            .double(message.singlePointValue);
        if (
          message.priceDisplayFormat != null &&
          Object.hasOwnProperty.call(message, 'priceDisplayFormat')
        )
          writer
            .uint32(/* id 154390, wireType 2 =*/ 1235122)
            .string(message.priceDisplayFormat);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        if (
          message.isTradable != null &&
          Object.hasOwnProperty.call(message, 'isTradable')
        )
          writer
            .uint32(/* id 154844, wireType 2 =*/ 1238754)
            .string(message.isTradable);
        if (
          message.isUnderlyingForBinaryContrats != null &&
          Object.hasOwnProperty.call(message, 'isUnderlyingForBinaryContrats')
        )
          writer
            .uint32(/* id 154952, wireType 2 =*/ 1239618)
            .string(message.isUnderlyingForBinaryContrats);
        if (
          message.tradingSymbol != null &&
          Object.hasOwnProperty.call(message, 'tradingSymbol')
        )
          writer
            .uint32(/* id 157095, wireType 2 =*/ 1256762)
            .string(message.tradingSymbol);
        if (
          message.tradingExchange != null &&
          Object.hasOwnProperty.call(message, 'tradingExchange')
        )
          writer
            .uint32(/* id 157096, wireType 2 =*/ 1256770)
            .string(message.tradingExchange);
        return writer;
      };

      /**
       * Encodes the specified ResponseReferenceData message, length delimited. Does not implicitly {@link rti.ResponseReferenceData.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {rti.IResponseReferenceData} message ResponseReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseReferenceData.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseReferenceData message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseReferenceData} ResponseReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseReferenceData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseReferenceData();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 110114:
              message.exchangeSymbol = reader.string();
              break;
            case 100003:
              message.symbolName = reader.string();
              break;
            case 157095:
              message.tradingSymbol = reader.string();
              break;
            case 157096:
              message.tradingExchange = reader.string();
              break;
            case 100749:
              message.productCode = reader.string();
              break;
            case 110116:
              message.instrumentType = reader.string();
              break;
            case 101026:
              message.underlyingSymbol = reader.string();
              break;
            case 100067:
              message.expirationDate = reader.string();
              break;
            case 154382:
              message.currency = reader.string();
              break;
            case 100109:
              message.putCallIndicator = reader.string();
              break;
            case 154167:
              message.tickSizeType = reader.string();
              break;
            case 154390:
              message.priceDisplayFormat = reader.string();
              break;
            case 154844:
              message.isTradable = reader.string();
              break;
            case 154952:
              message.isUnderlyingForBinaryContrats = reader.string();
              break;
            case 100066:
              message.strikePrice = reader.double();
              break;
            case 154384:
              message.ftoqPrice = reader.double();
              break;
            case 154385:
              message.qtofPrice = reader.double();
              break;
            case 154386:
              message.minQpriceChange = reader.double();
              break;
            case 154387:
              message.minFpriceChange = reader.double();
              break;
            case 154389:
              message.singlePointValue = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseReferenceData message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseReferenceData} ResponseReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseReferenceData.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseReferenceData message.
       * @function verify
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseReferenceData.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.exchangeSymbol != null &&
          message.hasOwnProperty('exchangeSymbol')
        )
          if (!$util.isString(message.exchangeSymbol))
            return 'exchangeSymbol: string expected';
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          if (!$util.isString(message.symbolName))
            return 'symbolName: string expected';
        if (
          message.tradingSymbol != null &&
          message.hasOwnProperty('tradingSymbol')
        )
          if (!$util.isString(message.tradingSymbol))
            return 'tradingSymbol: string expected';
        if (
          message.tradingExchange != null &&
          message.hasOwnProperty('tradingExchange')
        )
          if (!$util.isString(message.tradingExchange))
            return 'tradingExchange: string expected';
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          if (!$util.isString(message.productCode))
            return 'productCode: string expected';
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          if (!$util.isString(message.instrumentType))
            return 'instrumentType: string expected';
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        )
          if (!$util.isString(message.underlyingSymbol))
            return 'underlyingSymbol: string expected';
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          if (!$util.isString(message.expirationDate))
            return 'expirationDate: string expected';
        if (message.currency != null && message.hasOwnProperty('currency'))
          if (!$util.isString(message.currency))
            return 'currency: string expected';
        if (
          message.putCallIndicator != null &&
          message.hasOwnProperty('putCallIndicator')
        )
          if (!$util.isString(message.putCallIndicator))
            return 'putCallIndicator: string expected';
        if (
          message.tickSizeType != null &&
          message.hasOwnProperty('tickSizeType')
        )
          if (!$util.isString(message.tickSizeType))
            return 'tickSizeType: string expected';
        if (
          message.priceDisplayFormat != null &&
          message.hasOwnProperty('priceDisplayFormat')
        )
          if (!$util.isString(message.priceDisplayFormat))
            return 'priceDisplayFormat: string expected';
        if (message.isTradable != null && message.hasOwnProperty('isTradable'))
          if (!$util.isString(message.isTradable))
            return 'isTradable: string expected';
        if (
          message.isUnderlyingForBinaryContrats != null &&
          message.hasOwnProperty('isUnderlyingForBinaryContrats')
        )
          if (!$util.isString(message.isUnderlyingForBinaryContrats))
            return 'isUnderlyingForBinaryContrats: string expected';
        if (
          message.strikePrice != null &&
          message.hasOwnProperty('strikePrice')
        )
          if (typeof message.strikePrice !== 'number')
            return 'strikePrice: number expected';
        if (message.ftoqPrice != null && message.hasOwnProperty('ftoqPrice'))
          if (typeof message.ftoqPrice !== 'number')
            return 'ftoqPrice: number expected';
        if (message.qtofPrice != null && message.hasOwnProperty('qtofPrice'))
          if (typeof message.qtofPrice !== 'number')
            return 'qtofPrice: number expected';
        if (
          message.minQpriceChange != null &&
          message.hasOwnProperty('minQpriceChange')
        )
          if (typeof message.minQpriceChange !== 'number')
            return 'minQpriceChange: number expected';
        if (
          message.minFpriceChange != null &&
          message.hasOwnProperty('minFpriceChange')
        )
          if (typeof message.minFpriceChange !== 'number')
            return 'minFpriceChange: number expected';
        if (
          message.singlePointValue != null &&
          message.hasOwnProperty('singlePointValue')
        )
          if (typeof message.singlePointValue !== 'number')
            return 'singlePointValue: number expected';
        return null;
      };

      /**
       * Creates a ResponseReferenceData message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseReferenceData} ResponseReferenceData
       */
      ResponseReferenceData.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseReferenceData) return object;
        var message = new $root.rti.ResponseReferenceData();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseReferenceData.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseReferenceData.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.exchangeSymbol != null)
          message.exchangeSymbol = String(object.exchangeSymbol);
        if (object.symbolName != null)
          message.symbolName = String(object.symbolName);
        if (object.tradingSymbol != null)
          message.tradingSymbol = String(object.tradingSymbol);
        if (object.tradingExchange != null)
          message.tradingExchange = String(object.tradingExchange);
        if (object.productCode != null)
          message.productCode = String(object.productCode);
        if (object.instrumentType != null)
          message.instrumentType = String(object.instrumentType);
        if (object.underlyingSymbol != null)
          message.underlyingSymbol = String(object.underlyingSymbol);
        if (object.expirationDate != null)
          message.expirationDate = String(object.expirationDate);
        if (object.currency != null) message.currency = String(object.currency);
        if (object.putCallIndicator != null)
          message.putCallIndicator = String(object.putCallIndicator);
        if (object.tickSizeType != null)
          message.tickSizeType = String(object.tickSizeType);
        if (object.priceDisplayFormat != null)
          message.priceDisplayFormat = String(object.priceDisplayFormat);
        if (object.isTradable != null)
          message.isTradable = String(object.isTradable);
        if (object.isUnderlyingForBinaryContrats != null)
          message.isUnderlyingForBinaryContrats = String(
            object.isUnderlyingForBinaryContrats,
          );
        if (object.strikePrice != null)
          message.strikePrice = Number(object.strikePrice);
        if (object.ftoqPrice != null)
          message.ftoqPrice = Number(object.ftoqPrice);
        if (object.qtofPrice != null)
          message.qtofPrice = Number(object.qtofPrice);
        if (object.minQpriceChange != null)
          message.minQpriceChange = Number(object.minQpriceChange);
        if (object.minFpriceChange != null)
          message.minFpriceChange = Number(object.minFpriceChange);
        if (object.singlePointValue != null)
          message.singlePointValue = Number(object.singlePointValue);
        return message;
      };

      /**
       * Creates a plain object from a ResponseReferenceData message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {rti.ResponseReferenceData} message ResponseReferenceData
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseReferenceData.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.symbolName = '';
          object.strikePrice = 0;
          object.expirationDate = '';
          object.putCallIndicator = '';
          object.productCode = '';
          object.underlyingSymbol = '';
          object.symbol = '';
          object.exchange = '';
          object.exchangeSymbol = '';
          object.instrumentType = '';
          object.presenceBits = 0;
          object.tickSizeType = '';
          object.currency = '';
          object.ftoqPrice = 0;
          object.qtofPrice = 0;
          object.minQpriceChange = 0;
          object.minFpriceChange = 0;
          object.singlePointValue = 0;
          object.priceDisplayFormat = '';
          object.templateId = 0;
          object.clearBits = 0;
          object.isTradable = '';
          object.isUnderlyingForBinaryContrats = '';
          object.tradingSymbol = '';
          object.tradingExchange = '';
        }
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          object.symbolName = message.symbolName;
        if (
          message.strikePrice != null &&
          message.hasOwnProperty('strikePrice')
        )
          object.strikePrice =
            options.json && !isFinite(message.strikePrice)
              ? String(message.strikePrice)
              : message.strikePrice;
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          object.expirationDate = message.expirationDate;
        if (
          message.putCallIndicator != null &&
          message.hasOwnProperty('putCallIndicator')
        )
          object.putCallIndicator = message.putCallIndicator;
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          object.productCode = message.productCode;
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        )
          object.underlyingSymbol = message.underlyingSymbol;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.exchangeSymbol != null &&
          message.hasOwnProperty('exchangeSymbol')
        )
          object.exchangeSymbol = message.exchangeSymbol;
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          object.instrumentType = message.instrumentType;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (
          message.tickSizeType != null &&
          message.hasOwnProperty('tickSizeType')
        )
          object.tickSizeType = message.tickSizeType;
        if (message.currency != null && message.hasOwnProperty('currency'))
          object.currency = message.currency;
        if (message.ftoqPrice != null && message.hasOwnProperty('ftoqPrice'))
          object.ftoqPrice =
            options.json && !isFinite(message.ftoqPrice)
              ? String(message.ftoqPrice)
              : message.ftoqPrice;
        if (message.qtofPrice != null && message.hasOwnProperty('qtofPrice'))
          object.qtofPrice =
            options.json && !isFinite(message.qtofPrice)
              ? String(message.qtofPrice)
              : message.qtofPrice;
        if (
          message.minQpriceChange != null &&
          message.hasOwnProperty('minQpriceChange')
        )
          object.minQpriceChange =
            options.json && !isFinite(message.minQpriceChange)
              ? String(message.minQpriceChange)
              : message.minQpriceChange;
        if (
          message.minFpriceChange != null &&
          message.hasOwnProperty('minFpriceChange')
        )
          object.minFpriceChange =
            options.json && !isFinite(message.minFpriceChange)
              ? String(message.minFpriceChange)
              : message.minFpriceChange;
        if (
          message.singlePointValue != null &&
          message.hasOwnProperty('singlePointValue')
        )
          object.singlePointValue =
            options.json && !isFinite(message.singlePointValue)
              ? String(message.singlePointValue)
              : message.singlePointValue;
        if (
          message.priceDisplayFormat != null &&
          message.hasOwnProperty('priceDisplayFormat')
        )
          object.priceDisplayFormat = message.priceDisplayFormat;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        if (message.isTradable != null && message.hasOwnProperty('isTradable'))
          object.isTradable = message.isTradable;
        if (
          message.isUnderlyingForBinaryContrats != null &&
          message.hasOwnProperty('isUnderlyingForBinaryContrats')
        )
          object.isUnderlyingForBinaryContrats =
            message.isUnderlyingForBinaryContrats;
        if (
          message.tradingSymbol != null &&
          message.hasOwnProperty('tradingSymbol')
        )
          object.tradingSymbol = message.tradingSymbol;
        if (
          message.tradingExchange != null &&
          message.hasOwnProperty('tradingExchange')
        )
          object.tradingExchange = message.tradingExchange;
        return object;
      };

      /**
       * Converts this ResponseReferenceData to JSON.
       * @function toJSON
       * @memberof rti.ResponseReferenceData
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseReferenceData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseReferenceData
       * @function getTypeUrl
       * @memberof rti.ResponseReferenceData
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseReferenceData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseReferenceData';
      };

      /**
       * PresenceBits enum.
       * @name rti.ResponseReferenceData.PresenceBits
       * @enum {number}
       * @property {number} EXCHANGE_SYMBOL=1 EXCHANGE_SYMBOL value
       * @property {number} SYMBOL_NAME=2 SYMBOL_NAME value
       * @property {number} PRODUCT_CODE=4 PRODUCT_CODE value
       * @property {number} INSTRUMENT_TYPE=8 INSTRUMENT_TYPE value
       * @property {number} UNDERLYING_SYMBOL=16 UNDERLYING_SYMBOL value
       * @property {number} EXPIRATION_DATE=32 EXPIRATION_DATE value
       * @property {number} CURRENCY=64 CURRENCY value
       * @property {number} PUT_CALL_INDICATOR=128 PUT_CALL_INDICATOR value
       * @property {number} STRIKE_PRICE=256 STRIKE_PRICE value
       * @property {number} FPRICE_TO_QPRICE=512 FPRICE_TO_QPRICE value
       * @property {number} QPRICE_TO_FPRICE=1024 QPRICE_TO_FPRICE value
       * @property {number} MIN_QPRICE_CHANGE=2048 MIN_QPRICE_CHANGE value
       * @property {number} MIN_FRPICE_CHANGE=4096 MIN_FRPICE_CHANGE value
       * @property {number} SINGLE_POINT_VALUE=8192 SINGLE_POINT_VALUE value
       * @property {number} TICK_SIZE_TYPE=16384 TICK_SIZE_TYPE value
       * @property {number} PRICE_DISPLAY_FORMAT=32768 PRICE_DISPLAY_FORMAT value
       * @property {number} IS_TRADABLE=65536 IS_TRADABLE value
       * @property {number} TRADING_SYMBOL=131072 TRADING_SYMBOL value
       * @property {number} TRADING_EXCHANGE=262144 TRADING_EXCHANGE value
       * @property {number} IS_UNDERLYING_FOR_BINARY_CONTRACTS=8388608 IS_UNDERLYING_FOR_BINARY_CONTRACTS value
       */
      ResponseReferenceData.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'EXCHANGE_SYMBOL')] = 1;
        values[(valuesById[2] = 'SYMBOL_NAME')] = 2;
        values[(valuesById[4] = 'PRODUCT_CODE')] = 4;
        values[(valuesById[8] = 'INSTRUMENT_TYPE')] = 8;
        values[(valuesById[16] = 'UNDERLYING_SYMBOL')] = 16;
        values[(valuesById[32] = 'EXPIRATION_DATE')] = 32;
        values[(valuesById[64] = 'CURRENCY')] = 64;
        values[(valuesById[128] = 'PUT_CALL_INDICATOR')] = 128;
        values[(valuesById[256] = 'STRIKE_PRICE')] = 256;
        values[(valuesById[512] = 'FPRICE_TO_QPRICE')] = 512;
        values[(valuesById[1024] = 'QPRICE_TO_FPRICE')] = 1024;
        values[(valuesById[2048] = 'MIN_QPRICE_CHANGE')] = 2048;
        values[(valuesById[4096] = 'MIN_FRPICE_CHANGE')] = 4096;
        values[(valuesById[8192] = 'SINGLE_POINT_VALUE')] = 8192;
        values[(valuesById[16384] = 'TICK_SIZE_TYPE')] = 16384;
        values[(valuesById[32768] = 'PRICE_DISPLAY_FORMAT')] = 32768;
        values[(valuesById[65536] = 'IS_TRADABLE')] = 65536;
        values[(valuesById[131072] = 'TRADING_SYMBOL')] = 131072;
        values[(valuesById[262144] = 'TRADING_EXCHANGE')] = 262144;
        values[(valuesById[8388608] = 'IS_UNDERLYING_FOR_BINARY_CONTRACTS')] =
          8388608;
        return values;
      })();

      return ResponseReferenceData;
    })();

    rti.RequestRithmicSystemInfo = (function () {
      /**
       * Properties of a RequestRithmicSystemInfo.
       * @memberof rti
       * @interface IRequestRithmicSystemInfo
       * @property {number} templateId RequestRithmicSystemInfo templateId
       * @property {Array.<string>|null} [userMsg] RequestRithmicSystemInfo userMsg
       */

      /**
       * Constructs a new RequestRithmicSystemInfo.
       * @memberof rti
       * @classdesc Represents a RequestRithmicSystemInfo.
       * @implements IRequestRithmicSystemInfo
       * @constructor
       * @param {rti.IRequestRithmicSystemInfo=} [properties] Properties to set
       */
      function RequestRithmicSystemInfo(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestRithmicSystemInfo templateId.
       * @member {number} templateId
       * @memberof rti.RequestRithmicSystemInfo
       * @instance
       */
      RequestRithmicSystemInfo.prototype.templateId = 0;

      /**
       * RequestRithmicSystemInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestRithmicSystemInfo
       * @instance
       */
      RequestRithmicSystemInfo.prototype.userMsg = $util.emptyArray;

      /**
       * Creates a new RequestRithmicSystemInfo instance using the specified properties.
       * @function create
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {rti.IRequestRithmicSystemInfo=} [properties] Properties to set
       * @returns {rti.RequestRithmicSystemInfo} RequestRithmicSystemInfo instance
       */
      RequestRithmicSystemInfo.create = function create(properties) {
        return new RequestRithmicSystemInfo(properties);
      };

      /**
       * Encodes the specified RequestRithmicSystemInfo message. Does not implicitly {@link rti.RequestRithmicSystemInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {rti.IRequestRithmicSystemInfo} message RequestRithmicSystemInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestRithmicSystemInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestRithmicSystemInfo message, length delimited. Does not implicitly {@link rti.RequestRithmicSystemInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {rti.IRequestRithmicSystemInfo} message RequestRithmicSystemInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestRithmicSystemInfo.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestRithmicSystemInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestRithmicSystemInfo} RequestRithmicSystemInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestRithmicSystemInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestRithmicSystemInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestRithmicSystemInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestRithmicSystemInfo} RequestRithmicSystemInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestRithmicSystemInfo.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestRithmicSystemInfo message.
       * @function verify
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestRithmicSystemInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        return null;
      };

      /**
       * Creates a RequestRithmicSystemInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestRithmicSystemInfo} RequestRithmicSystemInfo
       */
      RequestRithmicSystemInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestRithmicSystemInfo) return object;
        var message = new $root.rti.RequestRithmicSystemInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestRithmicSystemInfo.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestRithmicSystemInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {rti.RequestRithmicSystemInfo} message RequestRithmicSystemInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestRithmicSystemInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestRithmicSystemInfo to JSON.
       * @function toJSON
       * @memberof rti.RequestRithmicSystemInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestRithmicSystemInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestRithmicSystemInfo
       * @function getTypeUrl
       * @memberof rti.RequestRithmicSystemInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestRithmicSystemInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestRithmicSystemInfo';
      };

      return RequestRithmicSystemInfo;
    })();

    rti.ResponseRithmicSystemInfo = (function () {
      /**
       * Properties of a ResponseRithmicSystemInfo.
       * @memberof rti
       * @interface IResponseRithmicSystemInfo
       * @property {number} templateId ResponseRithmicSystemInfo templateId
       * @property {Array.<string>|null} [userMsg] ResponseRithmicSystemInfo userMsg
       * @property {Array.<string>|null} [rpCode] ResponseRithmicSystemInfo rpCode
       * @property {Array.<string>|null} [systemName] ResponseRithmicSystemInfo systemName
       * @property {Array.<boolean>|null} [hasAggregatedQuotes] ResponseRithmicSystemInfo hasAggregatedQuotes
       */

      /**
       * Constructs a new ResponseRithmicSystemInfo.
       * @memberof rti
       * @classdesc Represents a ResponseRithmicSystemInfo.
       * @implements IResponseRithmicSystemInfo
       * @constructor
       * @param {rti.IResponseRithmicSystemInfo=} [properties] Properties to set
       */
      function ResponseRithmicSystemInfo(properties) {
        this.userMsg = [];
        this.rpCode = [];
        this.systemName = [];
        this.hasAggregatedQuotes = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseRithmicSystemInfo templateId.
       * @member {number} templateId
       * @memberof rti.ResponseRithmicSystemInfo
       * @instance
       */
      ResponseRithmicSystemInfo.prototype.templateId = 0;

      /**
       * ResponseRithmicSystemInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseRithmicSystemInfo
       * @instance
       */
      ResponseRithmicSystemInfo.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseRithmicSystemInfo rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseRithmicSystemInfo
       * @instance
       */
      ResponseRithmicSystemInfo.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseRithmicSystemInfo systemName.
       * @member {Array.<string>} systemName
       * @memberof rti.ResponseRithmicSystemInfo
       * @instance
       */
      ResponseRithmicSystemInfo.prototype.systemName = $util.emptyArray;

      /**
       * ResponseRithmicSystemInfo hasAggregatedQuotes.
       * @member {Array.<boolean>} hasAggregatedQuotes
       * @memberof rti.ResponseRithmicSystemInfo
       * @instance
       */
      ResponseRithmicSystemInfo.prototype.hasAggregatedQuotes =
        $util.emptyArray;

      /**
       * Creates a new ResponseRithmicSystemInfo instance using the specified properties.
       * @function create
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {rti.IResponseRithmicSystemInfo=} [properties] Properties to set
       * @returns {rti.ResponseRithmicSystemInfo} ResponseRithmicSystemInfo instance
       */
      ResponseRithmicSystemInfo.create = function create(properties) {
        return new ResponseRithmicSystemInfo(properties);
      };

      /**
       * Encodes the specified ResponseRithmicSystemInfo message. Does not implicitly {@link rti.ResponseRithmicSystemInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {rti.IResponseRithmicSystemInfo} message ResponseRithmicSystemInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseRithmicSystemInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (message.systemName != null && message.systemName.length)
          for (var i = 0; i < message.systemName.length; ++i)
            writer
              .uint32(/* id 153628, wireType 2 =*/ 1229026)
              .string(message.systemName[i]);
        if (
          message.hasAggregatedQuotes != null &&
          message.hasAggregatedQuotes.length
        )
          for (var i = 0; i < message.hasAggregatedQuotes.length; ++i)
            writer
              .uint32(/* id 153649, wireType 0 =*/ 1229192)
              .bool(message.hasAggregatedQuotes[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseRithmicSystemInfo message, length delimited. Does not implicitly {@link rti.ResponseRithmicSystemInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {rti.IResponseRithmicSystemInfo} message ResponseRithmicSystemInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseRithmicSystemInfo.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseRithmicSystemInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseRithmicSystemInfo} ResponseRithmicSystemInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseRithmicSystemInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseRithmicSystemInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 153628:
              if (!(message.systemName && message.systemName.length))
                message.systemName = [];
              message.systemName.push(reader.string());
              break;
            case 153649:
              if (
                !(
                  message.hasAggregatedQuotes &&
                  message.hasAggregatedQuotes.length
                )
              )
                message.hasAggregatedQuotes = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.hasAggregatedQuotes.push(reader.bool());
              } else message.hasAggregatedQuotes.push(reader.bool());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseRithmicSystemInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseRithmicSystemInfo} ResponseRithmicSystemInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseRithmicSystemInfo.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseRithmicSystemInfo message.
       * @function verify
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseRithmicSystemInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.systemName != null &&
          message.hasOwnProperty('systemName')
        ) {
          if (!Array.isArray(message.systemName))
            return 'systemName: array expected';
          for (var i = 0; i < message.systemName.length; ++i)
            if (!$util.isString(message.systemName[i]))
              return 'systemName: string[] expected';
        }
        if (
          message.hasAggregatedQuotes != null &&
          message.hasOwnProperty('hasAggregatedQuotes')
        ) {
          if (!Array.isArray(message.hasAggregatedQuotes))
            return 'hasAggregatedQuotes: array expected';
          for (var i = 0; i < message.hasAggregatedQuotes.length; ++i)
            if (typeof message.hasAggregatedQuotes[i] !== 'boolean')
              return 'hasAggregatedQuotes: boolean[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseRithmicSystemInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseRithmicSystemInfo} ResponseRithmicSystemInfo
       */
      ResponseRithmicSystemInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseRithmicSystemInfo)
          return object;
        var message = new $root.rti.ResponseRithmicSystemInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseRithmicSystemInfo.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseRithmicSystemInfo.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.systemName) {
          if (!Array.isArray(object.systemName))
            throw TypeError(
              '.rti.ResponseRithmicSystemInfo.systemName: array expected',
            );
          message.systemName = [];
          for (var i = 0; i < object.systemName.length; ++i)
            message.systemName[i] = String(object.systemName[i]);
        }
        if (object.hasAggregatedQuotes) {
          if (!Array.isArray(object.hasAggregatedQuotes))
            throw TypeError(
              '.rti.ResponseRithmicSystemInfo.hasAggregatedQuotes: array expected',
            );
          message.hasAggregatedQuotes = [];
          for (var i = 0; i < object.hasAggregatedQuotes.length; ++i)
            message.hasAggregatedQuotes[i] = Boolean(
              object.hasAggregatedQuotes[i],
            );
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseRithmicSystemInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {rti.ResponseRithmicSystemInfo} message ResponseRithmicSystemInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseRithmicSystemInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
          object.systemName = [];
          object.hasAggregatedQuotes = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.systemName && message.systemName.length) {
          object.systemName = [];
          for (var j = 0; j < message.systemName.length; ++j)
            object.systemName[j] = message.systemName[j];
        }
        if (message.hasAggregatedQuotes && message.hasAggregatedQuotes.length) {
          object.hasAggregatedQuotes = [];
          for (var j = 0; j < message.hasAggregatedQuotes.length; ++j)
            object.hasAggregatedQuotes[j] = message.hasAggregatedQuotes[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseRithmicSystemInfo to JSON.
       * @function toJSON
       * @memberof rti.ResponseRithmicSystemInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseRithmicSystemInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseRithmicSystemInfo
       * @function getTypeUrl
       * @memberof rti.ResponseRithmicSystemInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseRithmicSystemInfo.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseRithmicSystemInfo';
      };

      return ResponseRithmicSystemInfo;
    })();

    rti.RequestRithmicSystemGatewayInfo = (function () {
      /**
       * Properties of a RequestRithmicSystemGatewayInfo.
       * @memberof rti
       * @interface IRequestRithmicSystemGatewayInfo
       * @property {number} templateId RequestRithmicSystemGatewayInfo templateId
       * @property {Array.<string>|null} [userMsg] RequestRithmicSystemGatewayInfo userMsg
       * @property {string|null} [systemName] RequestRithmicSystemGatewayInfo systemName
       */

      /**
       * Constructs a new RequestRithmicSystemGatewayInfo.
       * @memberof rti
       * @classdesc Represents a RequestRithmicSystemGatewayInfo.
       * @implements IRequestRithmicSystemGatewayInfo
       * @constructor
       * @param {rti.IRequestRithmicSystemGatewayInfo=} [properties] Properties to set
       */
      function RequestRithmicSystemGatewayInfo(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestRithmicSystemGatewayInfo templateId.
       * @member {number} templateId
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @instance
       */
      RequestRithmicSystemGatewayInfo.prototype.templateId = 0;

      /**
       * RequestRithmicSystemGatewayInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @instance
       */
      RequestRithmicSystemGatewayInfo.prototype.userMsg = $util.emptyArray;

      /**
       * RequestRithmicSystemGatewayInfo systemName.
       * @member {string} systemName
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @instance
       */
      RequestRithmicSystemGatewayInfo.prototype.systemName = '';

      /**
       * Creates a new RequestRithmicSystemGatewayInfo instance using the specified properties.
       * @function create
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {rti.IRequestRithmicSystemGatewayInfo=} [properties] Properties to set
       * @returns {rti.RequestRithmicSystemGatewayInfo} RequestRithmicSystemGatewayInfo instance
       */
      RequestRithmicSystemGatewayInfo.create = function create(properties) {
        return new RequestRithmicSystemGatewayInfo(properties);
      };

      /**
       * Encodes the specified RequestRithmicSystemGatewayInfo message. Does not implicitly {@link rti.RequestRithmicSystemGatewayInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {rti.IRequestRithmicSystemGatewayInfo} message RequestRithmicSystemGatewayInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestRithmicSystemGatewayInfo.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.systemName != null &&
          Object.hasOwnProperty.call(message, 'systemName')
        )
          writer
            .uint32(/* id 153628, wireType 2 =*/ 1229026)
            .string(message.systemName);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestRithmicSystemGatewayInfo message, length delimited. Does not implicitly {@link rti.RequestRithmicSystemGatewayInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {rti.IRequestRithmicSystemGatewayInfo} message RequestRithmicSystemGatewayInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestRithmicSystemGatewayInfo.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestRithmicSystemGatewayInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestRithmicSystemGatewayInfo} RequestRithmicSystemGatewayInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestRithmicSystemGatewayInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestRithmicSystemGatewayInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 153628:
              message.systemName = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestRithmicSystemGatewayInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestRithmicSystemGatewayInfo} RequestRithmicSystemGatewayInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestRithmicSystemGatewayInfo.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestRithmicSystemGatewayInfo message.
       * @function verify
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestRithmicSystemGatewayInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.systemName != null && message.hasOwnProperty('systemName'))
          if (!$util.isString(message.systemName))
            return 'systemName: string expected';
        return null;
      };

      /**
       * Creates a RequestRithmicSystemGatewayInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestRithmicSystemGatewayInfo} RequestRithmicSystemGatewayInfo
       */
      RequestRithmicSystemGatewayInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestRithmicSystemGatewayInfo)
          return object;
        var message = new $root.rti.RequestRithmicSystemGatewayInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestRithmicSystemGatewayInfo.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.systemName != null)
          message.systemName = String(object.systemName);
        return message;
      };

      /**
       * Creates a plain object from a RequestRithmicSystemGatewayInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {rti.RequestRithmicSystemGatewayInfo} message RequestRithmicSystemGatewayInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestRithmicSystemGatewayInfo.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.systemName = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.systemName != null && message.hasOwnProperty('systemName'))
          object.systemName = message.systemName;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestRithmicSystemGatewayInfo to JSON.
       * @function toJSON
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestRithmicSystemGatewayInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestRithmicSystemGatewayInfo
       * @function getTypeUrl
       * @memberof rti.RequestRithmicSystemGatewayInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestRithmicSystemGatewayInfo.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestRithmicSystemGatewayInfo';
      };

      return RequestRithmicSystemGatewayInfo;
    })();

    rti.ResponseRithmicSystemGatewayInfo = (function () {
      /**
       * Properties of a ResponseRithmicSystemGatewayInfo.
       * @memberof rti
       * @interface IResponseRithmicSystemGatewayInfo
       * @property {number} templateId ResponseRithmicSystemGatewayInfo templateId
       * @property {Array.<string>|null} [userMsg] ResponseRithmicSystemGatewayInfo userMsg
       * @property {Array.<string>|null} [rpCode] ResponseRithmicSystemGatewayInfo rpCode
       * @property {string|null} [systemName] ResponseRithmicSystemGatewayInfo systemName
       * @property {Array.<string>|null} [gatewayName] ResponseRithmicSystemGatewayInfo gatewayName
       * @property {Array.<string>|null} [gatewayUri] ResponseRithmicSystemGatewayInfo gatewayUri
       */

      /**
       * Constructs a new ResponseRithmicSystemGatewayInfo.
       * @memberof rti
       * @classdesc Represents a ResponseRithmicSystemGatewayInfo.
       * @implements IResponseRithmicSystemGatewayInfo
       * @constructor
       * @param {rti.IResponseRithmicSystemGatewayInfo=} [properties] Properties to set
       */
      function ResponseRithmicSystemGatewayInfo(properties) {
        this.userMsg = [];
        this.rpCode = [];
        this.gatewayName = [];
        this.gatewayUri = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseRithmicSystemGatewayInfo templateId.
       * @member {number} templateId
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @instance
       */
      ResponseRithmicSystemGatewayInfo.prototype.templateId = 0;

      /**
       * ResponseRithmicSystemGatewayInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @instance
       */
      ResponseRithmicSystemGatewayInfo.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseRithmicSystemGatewayInfo rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @instance
       */
      ResponseRithmicSystemGatewayInfo.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseRithmicSystemGatewayInfo systemName.
       * @member {string} systemName
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @instance
       */
      ResponseRithmicSystemGatewayInfo.prototype.systemName = '';

      /**
       * ResponseRithmicSystemGatewayInfo gatewayName.
       * @member {Array.<string>} gatewayName
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @instance
       */
      ResponseRithmicSystemGatewayInfo.prototype.gatewayName = $util.emptyArray;

      /**
       * ResponseRithmicSystemGatewayInfo gatewayUri.
       * @member {Array.<string>} gatewayUri
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @instance
       */
      ResponseRithmicSystemGatewayInfo.prototype.gatewayUri = $util.emptyArray;

      /**
       * Creates a new ResponseRithmicSystemGatewayInfo instance using the specified properties.
       * @function create
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {rti.IResponseRithmicSystemGatewayInfo=} [properties] Properties to set
       * @returns {rti.ResponseRithmicSystemGatewayInfo} ResponseRithmicSystemGatewayInfo instance
       */
      ResponseRithmicSystemGatewayInfo.create = function create(properties) {
        return new ResponseRithmicSystemGatewayInfo(properties);
      };

      /**
       * Encodes the specified ResponseRithmicSystemGatewayInfo message. Does not implicitly {@link rti.ResponseRithmicSystemGatewayInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {rti.IResponseRithmicSystemGatewayInfo} message ResponseRithmicSystemGatewayInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseRithmicSystemGatewayInfo.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.systemName != null &&
          Object.hasOwnProperty.call(message, 'systemName')
        )
          writer
            .uint32(/* id 153628, wireType 2 =*/ 1229026)
            .string(message.systemName);
        if (message.gatewayName != null && message.gatewayName.length)
          for (var i = 0; i < message.gatewayName.length; ++i)
            writer
              .uint32(/* id 153640, wireType 2 =*/ 1229122)
              .string(message.gatewayName[i]);
        if (message.gatewayUri != null && message.gatewayUri.length)
          for (var i = 0; i < message.gatewayUri.length; ++i)
            writer
              .uint32(/* id 153641, wireType 2 =*/ 1229130)
              .string(message.gatewayUri[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseRithmicSystemGatewayInfo message, length delimited. Does not implicitly {@link rti.ResponseRithmicSystemGatewayInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {rti.IResponseRithmicSystemGatewayInfo} message ResponseRithmicSystemGatewayInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseRithmicSystemGatewayInfo.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseRithmicSystemGatewayInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseRithmicSystemGatewayInfo} ResponseRithmicSystemGatewayInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseRithmicSystemGatewayInfo.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseRithmicSystemGatewayInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 153628:
              message.systemName = reader.string();
              break;
            case 153640:
              if (!(message.gatewayName && message.gatewayName.length))
                message.gatewayName = [];
              message.gatewayName.push(reader.string());
              break;
            case 153641:
              if (!(message.gatewayUri && message.gatewayUri.length))
                message.gatewayUri = [];
              message.gatewayUri.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseRithmicSystemGatewayInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseRithmicSystemGatewayInfo} ResponseRithmicSystemGatewayInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseRithmicSystemGatewayInfo.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseRithmicSystemGatewayInfo message.
       * @function verify
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseRithmicSystemGatewayInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.systemName != null && message.hasOwnProperty('systemName'))
          if (!$util.isString(message.systemName))
            return 'systemName: string expected';
        if (
          message.gatewayName != null &&
          message.hasOwnProperty('gatewayName')
        ) {
          if (!Array.isArray(message.gatewayName))
            return 'gatewayName: array expected';
          for (var i = 0; i < message.gatewayName.length; ++i)
            if (!$util.isString(message.gatewayName[i]))
              return 'gatewayName: string[] expected';
        }
        if (
          message.gatewayUri != null &&
          message.hasOwnProperty('gatewayUri')
        ) {
          if (!Array.isArray(message.gatewayUri))
            return 'gatewayUri: array expected';
          for (var i = 0; i < message.gatewayUri.length; ++i)
            if (!$util.isString(message.gatewayUri[i]))
              return 'gatewayUri: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseRithmicSystemGatewayInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseRithmicSystemGatewayInfo} ResponseRithmicSystemGatewayInfo
       */
      ResponseRithmicSystemGatewayInfo.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseRithmicSystemGatewayInfo)
          return object;
        var message = new $root.rti.ResponseRithmicSystemGatewayInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseRithmicSystemGatewayInfo.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseRithmicSystemGatewayInfo.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.systemName != null)
          message.systemName = String(object.systemName);
        if (object.gatewayName) {
          if (!Array.isArray(object.gatewayName))
            throw TypeError(
              '.rti.ResponseRithmicSystemGatewayInfo.gatewayName: array expected',
            );
          message.gatewayName = [];
          for (var i = 0; i < object.gatewayName.length; ++i)
            message.gatewayName[i] = String(object.gatewayName[i]);
        }
        if (object.gatewayUri) {
          if (!Array.isArray(object.gatewayUri))
            throw TypeError(
              '.rti.ResponseRithmicSystemGatewayInfo.gatewayUri: array expected',
            );
          message.gatewayUri = [];
          for (var i = 0; i < object.gatewayUri.length; ++i)
            message.gatewayUri[i] = String(object.gatewayUri[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseRithmicSystemGatewayInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {rti.ResponseRithmicSystemGatewayInfo} message ResponseRithmicSystemGatewayInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseRithmicSystemGatewayInfo.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
          object.gatewayName = [];
          object.gatewayUri = [];
        }
        if (options.defaults) {
          object.systemName = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.systemName != null && message.hasOwnProperty('systemName'))
          object.systemName = message.systemName;
        if (message.gatewayName && message.gatewayName.length) {
          object.gatewayName = [];
          for (var j = 0; j < message.gatewayName.length; ++j)
            object.gatewayName[j] = message.gatewayName[j];
        }
        if (message.gatewayUri && message.gatewayUri.length) {
          object.gatewayUri = [];
          for (var j = 0; j < message.gatewayUri.length; ++j)
            object.gatewayUri[j] = message.gatewayUri[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseRithmicSystemGatewayInfo to JSON.
       * @function toJSON
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseRithmicSystemGatewayInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseRithmicSystemGatewayInfo
       * @function getTypeUrl
       * @memberof rti.ResponseRithmicSystemGatewayInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseRithmicSystemGatewayInfo.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseRithmicSystemGatewayInfo';
      };

      return ResponseRithmicSystemGatewayInfo;
    })();

    rti.RequestHeartbeat = (function () {
      /**
       * Properties of a RequestHeartbeat.
       * @memberof rti
       * @interface IRequestHeartbeat
       * @property {number} templateId RequestHeartbeat templateId
       * @property {Array.<string>|null} [userMsg] RequestHeartbeat userMsg
       * @property {number|null} [ssboe] RequestHeartbeat ssboe
       * @property {number|null} [usecs] RequestHeartbeat usecs
       */

      /**
       * Constructs a new RequestHeartbeat.
       * @memberof rti
       * @classdesc Represents a RequestHeartbeat.
       * @implements IRequestHeartbeat
       * @constructor
       * @param {rti.IRequestHeartbeat=} [properties] Properties to set
       */
      function RequestHeartbeat(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestHeartbeat templateId.
       * @member {number} templateId
       * @memberof rti.RequestHeartbeat
       * @instance
       */
      RequestHeartbeat.prototype.templateId = 0;

      /**
       * RequestHeartbeat userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestHeartbeat
       * @instance
       */
      RequestHeartbeat.prototype.userMsg = $util.emptyArray;

      /**
       * RequestHeartbeat ssboe.
       * @member {number} ssboe
       * @memberof rti.RequestHeartbeat
       * @instance
       */
      RequestHeartbeat.prototype.ssboe = 0;

      /**
       * RequestHeartbeat usecs.
       * @member {number} usecs
       * @memberof rti.RequestHeartbeat
       * @instance
       */
      RequestHeartbeat.prototype.usecs = 0;

      /**
       * Creates a new RequestHeartbeat instance using the specified properties.
       * @function create
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {rti.IRequestHeartbeat=} [properties] Properties to set
       * @returns {rti.RequestHeartbeat} RequestHeartbeat instance
       */
      RequestHeartbeat.create = function create(properties) {
        return new RequestHeartbeat(properties);
      };

      /**
       * Encodes the specified RequestHeartbeat message. Does not implicitly {@link rti.RequestHeartbeat.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {rti.IRequestHeartbeat} message RequestHeartbeat message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestHeartbeat.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestHeartbeat message, length delimited. Does not implicitly {@link rti.RequestHeartbeat.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {rti.IRequestHeartbeat} message RequestHeartbeat message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestHeartbeat.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestHeartbeat message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestHeartbeat} RequestHeartbeat
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestHeartbeat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestHeartbeat();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestHeartbeat message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestHeartbeat} RequestHeartbeat
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestHeartbeat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestHeartbeat message.
       * @function verify
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestHeartbeat.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a RequestHeartbeat message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestHeartbeat} RequestHeartbeat
       */
      RequestHeartbeat.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestHeartbeat) return object;
        var message = new $root.rti.RequestHeartbeat();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestHeartbeat.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestHeartbeat message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {rti.RequestHeartbeat} message RequestHeartbeat
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestHeartbeat.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.ssboe = 0;
          object.usecs = 0;
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestHeartbeat to JSON.
       * @function toJSON
       * @memberof rti.RequestHeartbeat
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestHeartbeat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestHeartbeat
       * @function getTypeUrl
       * @memberof rti.RequestHeartbeat
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestHeartbeat';
      };

      return RequestHeartbeat;
    })();

    rti.ResponseHeartbeat = (function () {
      /**
       * Properties of a ResponseHeartbeat.
       * @memberof rti
       * @interface IResponseHeartbeat
       * @property {number} templateId ResponseHeartbeat templateId
       * @property {Array.<string>|null} [userMsg] ResponseHeartbeat userMsg
       * @property {Array.<string>|null} [rpCode] ResponseHeartbeat rpCode
       * @property {number|null} [ssboe] ResponseHeartbeat ssboe
       * @property {number|null} [usecs] ResponseHeartbeat usecs
       */

      /**
       * Constructs a new ResponseHeartbeat.
       * @memberof rti
       * @classdesc Represents a ResponseHeartbeat.
       * @implements IResponseHeartbeat
       * @constructor
       * @param {rti.IResponseHeartbeat=} [properties] Properties to set
       */
      function ResponseHeartbeat(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseHeartbeat templateId.
       * @member {number} templateId
       * @memberof rti.ResponseHeartbeat
       * @instance
       */
      ResponseHeartbeat.prototype.templateId = 0;

      /**
       * ResponseHeartbeat userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseHeartbeat
       * @instance
       */
      ResponseHeartbeat.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseHeartbeat rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseHeartbeat
       * @instance
       */
      ResponseHeartbeat.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseHeartbeat ssboe.
       * @member {number} ssboe
       * @memberof rti.ResponseHeartbeat
       * @instance
       */
      ResponseHeartbeat.prototype.ssboe = 0;

      /**
       * ResponseHeartbeat usecs.
       * @member {number} usecs
       * @memberof rti.ResponseHeartbeat
       * @instance
       */
      ResponseHeartbeat.prototype.usecs = 0;

      /**
       * Creates a new ResponseHeartbeat instance using the specified properties.
       * @function create
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {rti.IResponseHeartbeat=} [properties] Properties to set
       * @returns {rti.ResponseHeartbeat} ResponseHeartbeat instance
       */
      ResponseHeartbeat.create = function create(properties) {
        return new ResponseHeartbeat(properties);
      };

      /**
       * Encodes the specified ResponseHeartbeat message. Does not implicitly {@link rti.ResponseHeartbeat.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {rti.IResponseHeartbeat} message ResponseHeartbeat message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseHeartbeat.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseHeartbeat message, length delimited. Does not implicitly {@link rti.ResponseHeartbeat.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {rti.IResponseHeartbeat} message ResponseHeartbeat message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseHeartbeat.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseHeartbeat message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseHeartbeat} ResponseHeartbeat
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseHeartbeat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseHeartbeat();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseHeartbeat message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseHeartbeat} ResponseHeartbeat
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseHeartbeat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseHeartbeat message.
       * @function verify
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseHeartbeat.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a ResponseHeartbeat message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseHeartbeat} ResponseHeartbeat
       */
      ResponseHeartbeat.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseHeartbeat) return object;
        var message = new $root.rti.ResponseHeartbeat();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseHeartbeat.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseHeartbeat.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a ResponseHeartbeat message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {rti.ResponseHeartbeat} message ResponseHeartbeat
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseHeartbeat.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.ssboe = 0;
          object.usecs = 0;
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseHeartbeat to JSON.
       * @function toJSON
       * @memberof rti.ResponseHeartbeat
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseHeartbeat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseHeartbeat
       * @function getTypeUrl
       * @memberof rti.ResponseHeartbeat
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseHeartbeat';
      };

      return ResponseHeartbeat;
    })();

    rti.Reject = (function () {
      /**
       * Properties of a Reject.
       * @memberof rti
       * @interface IReject
       * @property {number} templateId Reject templateId
       * @property {Array.<string>|null} [userMsg] Reject userMsg
       * @property {Array.<string>|null} [rpCode] Reject rpCode
       */

      /**
       * Constructs a new Reject.
       * @memberof rti
       * @classdesc Represents a Reject.
       * @implements IReject
       * @constructor
       * @param {rti.IReject=} [properties] Properties to set
       */
      function Reject(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Reject templateId.
       * @member {number} templateId
       * @memberof rti.Reject
       * @instance
       */
      Reject.prototype.templateId = 0;

      /**
       * Reject userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.Reject
       * @instance
       */
      Reject.prototype.userMsg = $util.emptyArray;

      /**
       * Reject rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.Reject
       * @instance
       */
      Reject.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new Reject instance using the specified properties.
       * @function create
       * @memberof rti.Reject
       * @static
       * @param {rti.IReject=} [properties] Properties to set
       * @returns {rti.Reject} Reject instance
       */
      Reject.create = function create(properties) {
        return new Reject(properties);
      };

      /**
       * Encodes the specified Reject message. Does not implicitly {@link rti.Reject.verify|verify} messages.
       * @function encode
       * @memberof rti.Reject
       * @static
       * @param {rti.IReject} message Reject message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Reject.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified Reject message, length delimited. Does not implicitly {@link rti.Reject.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.Reject
       * @static
       * @param {rti.IReject} message Reject message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Reject.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a Reject message from the specified reader or buffer.
       * @function decode
       * @memberof rti.Reject
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.Reject} Reject
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Reject.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.Reject();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a Reject message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.Reject
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.Reject} Reject
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Reject.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a Reject message.
       * @function verify
       * @memberof rti.Reject
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      Reject.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a Reject message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.Reject
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.Reject} Reject
       */
      Reject.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.Reject) return object;
        var message = new $root.rti.Reject();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.Reject.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.Reject.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a Reject message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.Reject
       * @static
       * @param {rti.Reject} message Reject
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      Reject.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this Reject to JSON.
       * @function toJSON
       * @memberof rti.Reject
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      Reject.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for Reject
       * @function getTypeUrl
       * @memberof rti.Reject
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      Reject.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.Reject';
      };

      return Reject;
    })();

    rti.ForcedLogout = (function () {
      /**
       * Properties of a ForcedLogout.
       * @memberof rti
       * @interface IForcedLogout
       * @property {number} templateId ForcedLogout templateId
       */

      /**
       * Constructs a new ForcedLogout.
       * @memberof rti
       * @classdesc Represents a ForcedLogout.
       * @implements IForcedLogout
       * @constructor
       * @param {rti.IForcedLogout=} [properties] Properties to set
       */
      function ForcedLogout(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ForcedLogout templateId.
       * @member {number} templateId
       * @memberof rti.ForcedLogout
       * @instance
       */
      ForcedLogout.prototype.templateId = 0;

      /**
       * Creates a new ForcedLogout instance using the specified properties.
       * @function create
       * @memberof rti.ForcedLogout
       * @static
       * @param {rti.IForcedLogout=} [properties] Properties to set
       * @returns {rti.ForcedLogout} ForcedLogout instance
       */
      ForcedLogout.create = function create(properties) {
        return new ForcedLogout(properties);
      };

      /**
       * Encodes the specified ForcedLogout message. Does not implicitly {@link rti.ForcedLogout.verify|verify} messages.
       * @function encode
       * @memberof rti.ForcedLogout
       * @static
       * @param {rti.IForcedLogout} message ForcedLogout message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ForcedLogout.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ForcedLogout message, length delimited. Does not implicitly {@link rti.ForcedLogout.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ForcedLogout
       * @static
       * @param {rti.IForcedLogout} message ForcedLogout message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ForcedLogout.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ForcedLogout message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ForcedLogout
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ForcedLogout} ForcedLogout
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ForcedLogout.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ForcedLogout();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ForcedLogout message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ForcedLogout
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ForcedLogout} ForcedLogout
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ForcedLogout.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ForcedLogout message.
       * @function verify
       * @memberof rti.ForcedLogout
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ForcedLogout.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        return null;
      };

      /**
       * Creates a ForcedLogout message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ForcedLogout
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ForcedLogout} ForcedLogout
       */
      ForcedLogout.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ForcedLogout) return object;
        var message = new $root.rti.ForcedLogout();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        return message;
      };

      /**
       * Creates a plain object from a ForcedLogout message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ForcedLogout
       * @static
       * @param {rti.ForcedLogout} message ForcedLogout
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ForcedLogout.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) object.templateId = 0;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ForcedLogout to JSON.
       * @function toJSON
       * @memberof rti.ForcedLogout
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ForcedLogout.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ForcedLogout
       * @function getTypeUrl
       * @memberof rti.ForcedLogout
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ForcedLogout.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ForcedLogout';
      };

      return ForcedLogout;
    })();

    rti.UserAccountUpdate = (function () {
      /**
       * Properties of a UserAccountUpdate.
       * @memberof rti
       * @interface IUserAccountUpdate
       * @property {number} templateId UserAccountUpdate templateId
       * @property {rti.UserAccountUpdate.UpdateType|null} [updateType] UserAccountUpdate updateType
       * @property {rti.UserAccountUpdate.AccessType|null} [accessType] UserAccountUpdate accessType
       * @property {string|null} [sourceUserId] UserAccountUpdate sourceUserId
       * @property {string|null} [user] UserAccountUpdate user
       * @property {string|null} [fcmId] UserAccountUpdate fcmId
       * @property {string|null} [ibId] UserAccountUpdate ibId
       * @property {string|null} [accountId] UserAccountUpdate accountId
       * @property {string|null} [accountName] UserAccountUpdate accountName
       * @property {number|null} [ssboe] UserAccountUpdate ssboe
       * @property {number|null} [usecs] UserAccountUpdate usecs
       */

      /**
       * Constructs a new UserAccountUpdate.
       * @memberof rti
       * @classdesc Represents a UserAccountUpdate.
       * @implements IUserAccountUpdate
       * @constructor
       * @param {rti.IUserAccountUpdate=} [properties] Properties to set
       */
      function UserAccountUpdate(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * UserAccountUpdate templateId.
       * @member {number} templateId
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.templateId = 0;

      /**
       * UserAccountUpdate updateType.
       * @member {rti.UserAccountUpdate.UpdateType} updateType
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.updateType = 1;

      /**
       * UserAccountUpdate accessType.
       * @member {rti.UserAccountUpdate.AccessType} accessType
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.accessType = 0;

      /**
       * UserAccountUpdate sourceUserId.
       * @member {string} sourceUserId
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.sourceUserId = '';

      /**
       * UserAccountUpdate user.
       * @member {string} user
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.user = '';

      /**
       * UserAccountUpdate fcmId.
       * @member {string} fcmId
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.fcmId = '';

      /**
       * UserAccountUpdate ibId.
       * @member {string} ibId
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.ibId = '';

      /**
       * UserAccountUpdate accountId.
       * @member {string} accountId
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.accountId = '';

      /**
       * UserAccountUpdate accountName.
       * @member {string} accountName
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.accountName = '';

      /**
       * UserAccountUpdate ssboe.
       * @member {number} ssboe
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.ssboe = 0;

      /**
       * UserAccountUpdate usecs.
       * @member {number} usecs
       * @memberof rti.UserAccountUpdate
       * @instance
       */
      UserAccountUpdate.prototype.usecs = 0;

      /**
       * Creates a new UserAccountUpdate instance using the specified properties.
       * @function create
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {rti.IUserAccountUpdate=} [properties] Properties to set
       * @returns {rti.UserAccountUpdate} UserAccountUpdate instance
       */
      UserAccountUpdate.create = function create(properties) {
        return new UserAccountUpdate(properties);
      };

      /**
       * Encodes the specified UserAccountUpdate message. Does not implicitly {@link rti.UserAccountUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {rti.IUserAccountUpdate} message UserAccountUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      UserAccountUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.user != null && Object.hasOwnProperty.call(message, 'user'))
          writer
            .uint32(/* id 131003, wireType 2 =*/ 1048026)
            .string(message.user);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.accessType != null &&
          Object.hasOwnProperty.call(message, 'accessType')
        )
          writer
            .uint32(/* id 154000, wireType 0 =*/ 1232000)
            .int32(message.accessType);
        if (
          message.accountName != null &&
          Object.hasOwnProperty.call(message, 'accountName')
        )
          writer
            .uint32(/* id 154002, wireType 2 =*/ 1232018)
            .string(message.accountName);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.sourceUserId != null &&
          Object.hasOwnProperty.call(message, 'sourceUserId')
        )
          writer
            .uint32(/* id 154247, wireType 2 =*/ 1233978)
            .string(message.sourceUserId);
        if (
          message.updateType != null &&
          Object.hasOwnProperty.call(message, 'updateType')
        )
          writer
            .uint32(/* id 154288, wireType 0 =*/ 1234304)
            .int32(message.updateType);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified UserAccountUpdate message, length delimited. Does not implicitly {@link rti.UserAccountUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {rti.IUserAccountUpdate} message UserAccountUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      UserAccountUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a UserAccountUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.UserAccountUpdate} UserAccountUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      UserAccountUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.UserAccountUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 154288:
              message.updateType = reader.int32();
              break;
            case 154000:
              message.accessType = reader.int32();
              break;
            case 154247:
              message.sourceUserId = reader.string();
              break;
            case 131003:
              message.user = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 154002:
              message.accountName = reader.string();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a UserAccountUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.UserAccountUpdate} UserAccountUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      UserAccountUpdate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a UserAccountUpdate message.
       * @function verify
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      UserAccountUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.updateType != null && message.hasOwnProperty('updateType'))
          switch (message.updateType) {
            default:
              return 'updateType: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.accessType != null && message.hasOwnProperty('accessType'))
          switch (message.accessType) {
            default:
              return 'accessType: enum value expected';
            case 0:
            case 1:
              break;
          }
        if (
          message.sourceUserId != null &&
          message.hasOwnProperty('sourceUserId')
        )
          if (!$util.isString(message.sourceUserId))
            return 'sourceUserId: string expected';
        if (message.user != null && message.hasOwnProperty('user'))
          if (!$util.isString(message.user)) return 'user: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (
          message.accountName != null &&
          message.hasOwnProperty('accountName')
        )
          if (!$util.isString(message.accountName))
            return 'accountName: string expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a UserAccountUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.UserAccountUpdate} UserAccountUpdate
       */
      UserAccountUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.UserAccountUpdate) return object;
        var message = new $root.rti.UserAccountUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        switch (object.updateType) {
          case 'ADD':
          case 1:
            message.updateType = 1;
            break;
          case 'REMOVE':
          case 2:
            message.updateType = 2;
            break;
        }
        switch (object.accessType) {
          case 'READ_ONLY':
          case 0:
            message.accessType = 0;
            break;
          case 'READ_WRITE':
          case 1:
            message.accessType = 1;
            break;
        }
        if (object.sourceUserId != null)
          message.sourceUserId = String(object.sourceUserId);
        if (object.user != null) message.user = String(object.user);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.accountName != null)
          message.accountName = String(object.accountName);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a UserAccountUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {rti.UserAccountUpdate} message UserAccountUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      UserAccountUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.user = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.accessType = options.enums === String ? 'READ_ONLY' : 0;
          object.accountName = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.sourceUserId = '';
          object.updateType = options.enums === String ? 'ADD' : 1;
          object.templateId = 0;
        }
        if (message.user != null && message.hasOwnProperty('user'))
          object.user = message.user;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.accessType != null && message.hasOwnProperty('accessType'))
          object.accessType =
            options.enums === String
              ? $root.rti.UserAccountUpdate.AccessType[message.accessType]
              : message.accessType;
        if (
          message.accountName != null &&
          message.hasOwnProperty('accountName')
        )
          object.accountName = message.accountName;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (
          message.sourceUserId != null &&
          message.hasOwnProperty('sourceUserId')
        )
          object.sourceUserId = message.sourceUserId;
        if (message.updateType != null && message.hasOwnProperty('updateType'))
          object.updateType =
            options.enums === String
              ? $root.rti.UserAccountUpdate.UpdateType[message.updateType]
              : message.updateType;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this UserAccountUpdate to JSON.
       * @function toJSON
       * @memberof rti.UserAccountUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      UserAccountUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for UserAccountUpdate
       * @function getTypeUrl
       * @memberof rti.UserAccountUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      UserAccountUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.UserAccountUpdate';
      };

      /**
       * UpdateType enum.
       * @name rti.UserAccountUpdate.UpdateType
       * @enum {number}
       * @property {number} ADD=1 ADD value
       * @property {number} REMOVE=2 REMOVE value
       */
      UserAccountUpdate.UpdateType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'ADD')] = 1;
        values[(valuesById[2] = 'REMOVE')] = 2;
        return values;
      })();

      /**
       * AccessType enum.
       * @name rti.UserAccountUpdate.AccessType
       * @enum {number}
       * @property {number} READ_ONLY=0 READ_ONLY value
       * @property {number} READ_WRITE=1 READ_WRITE value
       */
      UserAccountUpdate.AccessType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[0] = 'READ_ONLY')] = 0;
        values[(valuesById[1] = 'READ_WRITE')] = 1;
        return values;
      })();

      return UserAccountUpdate;
    })();

    rti.RequestMarketDataUpdate = (function () {
      /**
       * Properties of a RequestMarketDataUpdate.
       * @memberof rti
       * @interface IRequestMarketDataUpdate
       * @property {number} templateId RequestMarketDataUpdate templateId
       * @property {Array.<string>|null} [userMsg] RequestMarketDataUpdate userMsg
       * @property {string|null} [symbol] RequestMarketDataUpdate symbol
       * @property {string|null} [exchange] RequestMarketDataUpdate exchange
       * @property {rti.RequestMarketDataUpdate.Request|null} [request] RequestMarketDataUpdate request
       * @property {rti.RequestMarketDataUpdate.UpdateBits|null} [updateBits] RequestMarketDataUpdate updateBits
       */

      /**
       * Constructs a new RequestMarketDataUpdate.
       * @memberof rti
       * @classdesc Represents a RequestMarketDataUpdate.
       * @implements IRequestMarketDataUpdate
       * @constructor
       * @param {rti.IRequestMarketDataUpdate=} [properties] Properties to set
       */
      function RequestMarketDataUpdate(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestMarketDataUpdate templateId.
       * @member {number} templateId
       * @memberof rti.RequestMarketDataUpdate
       * @instance
       */
      RequestMarketDataUpdate.prototype.templateId = 0;

      /**
       * RequestMarketDataUpdate userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestMarketDataUpdate
       * @instance
       */
      RequestMarketDataUpdate.prototype.userMsg = $util.emptyArray;

      /**
       * RequestMarketDataUpdate symbol.
       * @member {string} symbol
       * @memberof rti.RequestMarketDataUpdate
       * @instance
       */
      RequestMarketDataUpdate.prototype.symbol = '';

      /**
       * RequestMarketDataUpdate exchange.
       * @member {string} exchange
       * @memberof rti.RequestMarketDataUpdate
       * @instance
       */
      RequestMarketDataUpdate.prototype.exchange = '';

      /**
       * RequestMarketDataUpdate request.
       * @member {rti.RequestMarketDataUpdate.Request} request
       * @memberof rti.RequestMarketDataUpdate
       * @instance
       */
      RequestMarketDataUpdate.prototype.request = 1;

      /**
       * RequestMarketDataUpdate updateBits.
       * @member {rti.RequestMarketDataUpdate.UpdateBits} updateBits
       * @memberof rti.RequestMarketDataUpdate
       * @instance
       */
      RequestMarketDataUpdate.prototype.updateBits = 1;

      /**
       * Creates a new RequestMarketDataUpdate instance using the specified properties.
       * @function create
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {rti.IRequestMarketDataUpdate=} [properties] Properties to set
       * @returns {rti.RequestMarketDataUpdate} RequestMarketDataUpdate instance
       */
      RequestMarketDataUpdate.create = function create(properties) {
        return new RequestMarketDataUpdate(properties);
      };

      /**
       * Encodes the specified RequestMarketDataUpdate message. Does not implicitly {@link rti.RequestMarketDataUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {rti.IRequestMarketDataUpdate} message RequestMarketDataUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestMarketDataUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.request != null &&
          Object.hasOwnProperty.call(message, 'request')
        )
          writer
            .uint32(/* id 100000, wireType 0 =*/ 800000)
            .int32(message.request);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.updateBits != null &&
          Object.hasOwnProperty.call(message, 'updateBits')
        )
          writer
            .uint32(/* id 154211, wireType 0 =*/ 1233688)
            .int32(message.updateBits);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestMarketDataUpdate message, length delimited. Does not implicitly {@link rti.RequestMarketDataUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {rti.IRequestMarketDataUpdate} message RequestMarketDataUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestMarketDataUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestMarketDataUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestMarketDataUpdate} RequestMarketDataUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestMarketDataUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestMarketDataUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100000:
              message.request = reader.int32();
              break;
            case 154211:
              message.updateBits = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestMarketDataUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestMarketDataUpdate} RequestMarketDataUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestMarketDataUpdate.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestMarketDataUpdate message.
       * @function verify
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestMarketDataUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.request != null && message.hasOwnProperty('request'))
          switch (message.request) {
            default:
              return 'request: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.updateBits != null && message.hasOwnProperty('updateBits'))
          switch (message.updateBits) {
            default:
              return 'updateBits: enum value expected';
            case 1:
            case 2:
            case 4:
            case 8:
            case 16:
            case 32:
            case 64:
            case 128:
            case 256:
            case 512:
            case 1024:
            case 2048:
            case 4096:
            case 8192:
            case 16384:
            case 32768:
            case 65536:
              break;
          }
        return null;
      };

      /**
       * Creates a RequestMarketDataUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestMarketDataUpdate} RequestMarketDataUpdate
       */
      RequestMarketDataUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestMarketDataUpdate) return object;
        var message = new $root.rti.RequestMarketDataUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestMarketDataUpdate.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.request) {
          case 'SUBSCRIBE':
          case 1:
            message.request = 1;
            break;
          case 'UNSUBSCRIBE':
          case 2:
            message.request = 2;
            break;
        }
        switch (object.updateBits) {
          case 'LAST_TRADE':
          case 1:
            message.updateBits = 1;
            break;
          case 'BBO':
          case 2:
            message.updateBits = 2;
            break;
          case 'ORDER_BOOK':
          case 4:
            message.updateBits = 4;
            break;
          case 'OPEN':
          case 8:
            message.updateBits = 8;
            break;
          case 'OPENING_INDICATOR':
          case 16:
            message.updateBits = 16;
            break;
          case 'HIGH_LOW':
          case 32:
            message.updateBits = 32;
            break;
          case 'HIGH_BID_LOW_ASK':
          case 64:
            message.updateBits = 64;
            break;
          case 'CLOSE':
          case 128:
            message.updateBits = 128;
            break;
          case 'CLOSING_INDICATOR':
          case 256:
            message.updateBits = 256;
            break;
          case 'SETTLEMENT':
          case 512:
            message.updateBits = 512;
            break;
          case 'MARKET_MODE':
          case 1024:
            message.updateBits = 1024;
            break;
          case 'OPEN_INTEREST':
          case 2048:
            message.updateBits = 2048;
            break;
          case 'MARGIN_RATE':
          case 4096:
            message.updateBits = 4096;
            break;
          case 'HIGH_PRICE_LIMIT':
          case 8192:
            message.updateBits = 8192;
            break;
          case 'LOW_PRICE_LIMIT':
          case 16384:
            message.updateBits = 16384;
            break;
          case 'PROJECTED_SETTLEMENT':
          case 32768:
            message.updateBits = 32768;
            break;
          case 'ADJUSTED_CLOSE':
          case 65536:
            message.updateBits = 65536;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestMarketDataUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {rti.RequestMarketDataUpdate} message RequestMarketDataUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestMarketDataUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.request = options.enums === String ? 'SUBSCRIBE' : 1;
          object.symbol = '';
          object.exchange = '';
          object.updateBits = options.enums === String ? 'LAST_TRADE' : 1;
          object.templateId = 0;
        }
        if (message.request != null && message.hasOwnProperty('request'))
          object.request =
            options.enums === String
              ? $root.rti.RequestMarketDataUpdate.Request[message.request]
              : message.request;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.updateBits != null && message.hasOwnProperty('updateBits'))
          object.updateBits =
            options.enums === String
              ? $root.rti.RequestMarketDataUpdate.UpdateBits[message.updateBits]
              : message.updateBits;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestMarketDataUpdate to JSON.
       * @function toJSON
       * @memberof rti.RequestMarketDataUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestMarketDataUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestMarketDataUpdate
       * @function getTypeUrl
       * @memberof rti.RequestMarketDataUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestMarketDataUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestMarketDataUpdate';
      };

      /**
       * UpdateBits enum.
       * @name rti.RequestMarketDataUpdate.UpdateBits
       * @enum {number}
       * @property {number} LAST_TRADE=1 LAST_TRADE value
       * @property {number} BBO=2 BBO value
       * @property {number} ORDER_BOOK=4 ORDER_BOOK value
       * @property {number} OPEN=8 OPEN value
       * @property {number} OPENING_INDICATOR=16 OPENING_INDICATOR value
       * @property {number} HIGH_LOW=32 HIGH_LOW value
       * @property {number} HIGH_BID_LOW_ASK=64 HIGH_BID_LOW_ASK value
       * @property {number} CLOSE=128 CLOSE value
       * @property {number} CLOSING_INDICATOR=256 CLOSING_INDICATOR value
       * @property {number} SETTLEMENT=512 SETTLEMENT value
       * @property {number} MARKET_MODE=1024 MARKET_MODE value
       * @property {number} OPEN_INTEREST=2048 OPEN_INTEREST value
       * @property {number} MARGIN_RATE=4096 MARGIN_RATE value
       * @property {number} HIGH_PRICE_LIMIT=8192 HIGH_PRICE_LIMIT value
       * @property {number} LOW_PRICE_LIMIT=16384 LOW_PRICE_LIMIT value
       * @property {number} PROJECTED_SETTLEMENT=32768 PROJECTED_SETTLEMENT value
       * @property {number} ADJUSTED_CLOSE=65536 ADJUSTED_CLOSE value
       */
      RequestMarketDataUpdate.UpdateBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LAST_TRADE')] = 1;
        values[(valuesById[2] = 'BBO')] = 2;
        values[(valuesById[4] = 'ORDER_BOOK')] = 4;
        values[(valuesById[8] = 'OPEN')] = 8;
        values[(valuesById[16] = 'OPENING_INDICATOR')] = 16;
        values[(valuesById[32] = 'HIGH_LOW')] = 32;
        values[(valuesById[64] = 'HIGH_BID_LOW_ASK')] = 64;
        values[(valuesById[128] = 'CLOSE')] = 128;
        values[(valuesById[256] = 'CLOSING_INDICATOR')] = 256;
        values[(valuesById[512] = 'SETTLEMENT')] = 512;
        values[(valuesById[1024] = 'MARKET_MODE')] = 1024;
        values[(valuesById[2048] = 'OPEN_INTEREST')] = 2048;
        values[(valuesById[4096] = 'MARGIN_RATE')] = 4096;
        values[(valuesById[8192] = 'HIGH_PRICE_LIMIT')] = 8192;
        values[(valuesById[16384] = 'LOW_PRICE_LIMIT')] = 16384;
        values[(valuesById[32768] = 'PROJECTED_SETTLEMENT')] = 32768;
        values[(valuesById[65536] = 'ADJUSTED_CLOSE')] = 65536;
        return values;
      })();

      /**
       * Request enum.
       * @name rti.RequestMarketDataUpdate.Request
       * @enum {number}
       * @property {number} SUBSCRIBE=1 SUBSCRIBE value
       * @property {number} UNSUBSCRIBE=2 UNSUBSCRIBE value
       */
      RequestMarketDataUpdate.Request = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SUBSCRIBE')] = 1;
        values[(valuesById[2] = 'UNSUBSCRIBE')] = 2;
        return values;
      })();

      return RequestMarketDataUpdate;
    })();

    rti.ResponseMarketDataUpdate = (function () {
      /**
       * Properties of a ResponseMarketDataUpdate.
       * @memberof rti
       * @interface IResponseMarketDataUpdate
       * @property {number} templateId ResponseMarketDataUpdate templateId
       * @property {Array.<string>|null} [userMsg] ResponseMarketDataUpdate userMsg
       * @property {Array.<string>|null} [rpCode] ResponseMarketDataUpdate rpCode
       */

      /**
       * Constructs a new ResponseMarketDataUpdate.
       * @memberof rti
       * @classdesc Represents a ResponseMarketDataUpdate.
       * @implements IResponseMarketDataUpdate
       * @constructor
       * @param {rti.IResponseMarketDataUpdate=} [properties] Properties to set
       */
      function ResponseMarketDataUpdate(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseMarketDataUpdate templateId.
       * @member {number} templateId
       * @memberof rti.ResponseMarketDataUpdate
       * @instance
       */
      ResponseMarketDataUpdate.prototype.templateId = 0;

      /**
       * ResponseMarketDataUpdate userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseMarketDataUpdate
       * @instance
       */
      ResponseMarketDataUpdate.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseMarketDataUpdate rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseMarketDataUpdate
       * @instance
       */
      ResponseMarketDataUpdate.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseMarketDataUpdate instance using the specified properties.
       * @function create
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {rti.IResponseMarketDataUpdate=} [properties] Properties to set
       * @returns {rti.ResponseMarketDataUpdate} ResponseMarketDataUpdate instance
       */
      ResponseMarketDataUpdate.create = function create(properties) {
        return new ResponseMarketDataUpdate(properties);
      };

      /**
       * Encodes the specified ResponseMarketDataUpdate message. Does not implicitly {@link rti.ResponseMarketDataUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {rti.IResponseMarketDataUpdate} message ResponseMarketDataUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseMarketDataUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseMarketDataUpdate message, length delimited. Does not implicitly {@link rti.ResponseMarketDataUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {rti.IResponseMarketDataUpdate} message ResponseMarketDataUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseMarketDataUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseMarketDataUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseMarketDataUpdate} ResponseMarketDataUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseMarketDataUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseMarketDataUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseMarketDataUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseMarketDataUpdate} ResponseMarketDataUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseMarketDataUpdate.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseMarketDataUpdate message.
       * @function verify
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseMarketDataUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseMarketDataUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseMarketDataUpdate} ResponseMarketDataUpdate
       */
      ResponseMarketDataUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseMarketDataUpdate) return object;
        var message = new $root.rti.ResponseMarketDataUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseMarketDataUpdate.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseMarketDataUpdate.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseMarketDataUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {rti.ResponseMarketDataUpdate} message ResponseMarketDataUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseMarketDataUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseMarketDataUpdate to JSON.
       * @function toJSON
       * @memberof rti.ResponseMarketDataUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseMarketDataUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseMarketDataUpdate
       * @function getTypeUrl
       * @memberof rti.ResponseMarketDataUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseMarketDataUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseMarketDataUpdate';
      };

      return ResponseMarketDataUpdate;
    })();

    rti.RequestAuxilliaryReferenceData = (function () {
      /**
       * Properties of a RequestAuxilliaryReferenceData.
       * @memberof rti
       * @interface IRequestAuxilliaryReferenceData
       * @property {number} templateId RequestAuxilliaryReferenceData templateId
       * @property {Array.<string>|null} [userMsg] RequestAuxilliaryReferenceData userMsg
       * @property {string|null} [symbol] RequestAuxilliaryReferenceData symbol
       * @property {string|null} [exchange] RequestAuxilliaryReferenceData exchange
       */

      /**
       * Constructs a new RequestAuxilliaryReferenceData.
       * @memberof rti
       * @classdesc Represents a RequestAuxilliaryReferenceData.
       * @implements IRequestAuxilliaryReferenceData
       * @constructor
       * @param {rti.IRequestAuxilliaryReferenceData=} [properties] Properties to set
       */
      function RequestAuxilliaryReferenceData(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestAuxilliaryReferenceData templateId.
       * @member {number} templateId
       * @memberof rti.RequestAuxilliaryReferenceData
       * @instance
       */
      RequestAuxilliaryReferenceData.prototype.templateId = 0;

      /**
       * RequestAuxilliaryReferenceData userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestAuxilliaryReferenceData
       * @instance
       */
      RequestAuxilliaryReferenceData.prototype.userMsg = $util.emptyArray;

      /**
       * RequestAuxilliaryReferenceData symbol.
       * @member {string} symbol
       * @memberof rti.RequestAuxilliaryReferenceData
       * @instance
       */
      RequestAuxilliaryReferenceData.prototype.symbol = '';

      /**
       * RequestAuxilliaryReferenceData exchange.
       * @member {string} exchange
       * @memberof rti.RequestAuxilliaryReferenceData
       * @instance
       */
      RequestAuxilliaryReferenceData.prototype.exchange = '';

      /**
       * Creates a new RequestAuxilliaryReferenceData instance using the specified properties.
       * @function create
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {rti.IRequestAuxilliaryReferenceData=} [properties] Properties to set
       * @returns {rti.RequestAuxilliaryReferenceData} RequestAuxilliaryReferenceData instance
       */
      RequestAuxilliaryReferenceData.create = function create(properties) {
        return new RequestAuxilliaryReferenceData(properties);
      };

      /**
       * Encodes the specified RequestAuxilliaryReferenceData message. Does not implicitly {@link rti.RequestAuxilliaryReferenceData.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {rti.IRequestAuxilliaryReferenceData} message RequestAuxilliaryReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAuxilliaryReferenceData.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestAuxilliaryReferenceData message, length delimited. Does not implicitly {@link rti.RequestAuxilliaryReferenceData.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {rti.IRequestAuxilliaryReferenceData} message RequestAuxilliaryReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAuxilliaryReferenceData.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestAuxilliaryReferenceData message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestAuxilliaryReferenceData} RequestAuxilliaryReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAuxilliaryReferenceData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestAuxilliaryReferenceData();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestAuxilliaryReferenceData message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestAuxilliaryReferenceData} RequestAuxilliaryReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAuxilliaryReferenceData.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestAuxilliaryReferenceData message.
       * @function verify
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestAuxilliaryReferenceData.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        return null;
      };

      /**
       * Creates a RequestAuxilliaryReferenceData message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestAuxilliaryReferenceData} RequestAuxilliaryReferenceData
       */
      RequestAuxilliaryReferenceData.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestAuxilliaryReferenceData)
          return object;
        var message = new $root.rti.RequestAuxilliaryReferenceData();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestAuxilliaryReferenceData.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        return message;
      };

      /**
       * Creates a plain object from a RequestAuxilliaryReferenceData message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {rti.RequestAuxilliaryReferenceData} message RequestAuxilliaryReferenceData
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestAuxilliaryReferenceData.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestAuxilliaryReferenceData to JSON.
       * @function toJSON
       * @memberof rti.RequestAuxilliaryReferenceData
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestAuxilliaryReferenceData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestAuxilliaryReferenceData
       * @function getTypeUrl
       * @memberof rti.RequestAuxilliaryReferenceData
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestAuxilliaryReferenceData.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestAuxilliaryReferenceData';
      };

      return RequestAuxilliaryReferenceData;
    })();

    rti.ResponseAuxilliaryReferenceData = (function () {
      /**
       * Properties of a ResponseAuxilliaryReferenceData.
       * @memberof rti
       * @interface IResponseAuxilliaryReferenceData
       * @property {number} templateId ResponseAuxilliaryReferenceData templateId
       * @property {Array.<string>|null} [userMsg] ResponseAuxilliaryReferenceData userMsg
       * @property {Array.<string>|null} [rpCode] ResponseAuxilliaryReferenceData rpCode
       * @property {number|null} [presenceBits] ResponseAuxilliaryReferenceData presenceBits
       * @property {number|null} [clearBits] ResponseAuxilliaryReferenceData clearBits
       * @property {string|null} [symbol] ResponseAuxilliaryReferenceData symbol
       * @property {string|null} [exchange] ResponseAuxilliaryReferenceData exchange
       * @property {string|null} [settlementMethod] ResponseAuxilliaryReferenceData settlementMethod
       * @property {string|null} [firstNoticeDate] ResponseAuxilliaryReferenceData firstNoticeDate
       * @property {string|null} [lastNoticeDate] ResponseAuxilliaryReferenceData lastNoticeDate
       * @property {string|null} [firstTradingDate] ResponseAuxilliaryReferenceData firstTradingDate
       * @property {string|null} [lastTradingDate] ResponseAuxilliaryReferenceData lastTradingDate
       * @property {string|null} [firstDeliveryDate] ResponseAuxilliaryReferenceData firstDeliveryDate
       * @property {string|null} [lastDeliveryDate] ResponseAuxilliaryReferenceData lastDeliveryDate
       * @property {string|null} [firstPositionDate] ResponseAuxilliaryReferenceData firstPositionDate
       * @property {string|null} [lastPositionDate] ResponseAuxilliaryReferenceData lastPositionDate
       * @property {string|null} [unitOfMeasure] ResponseAuxilliaryReferenceData unitOfMeasure
       * @property {number|null} [unitOfMeasureQty] ResponseAuxilliaryReferenceData unitOfMeasureQty
       */

      /**
       * Constructs a new ResponseAuxilliaryReferenceData.
       * @memberof rti
       * @classdesc Represents a ResponseAuxilliaryReferenceData.
       * @implements IResponseAuxilliaryReferenceData
       * @constructor
       * @param {rti.IResponseAuxilliaryReferenceData=} [properties] Properties to set
       */
      function ResponseAuxilliaryReferenceData(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseAuxilliaryReferenceData templateId.
       * @member {number} templateId
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.templateId = 0;

      /**
       * ResponseAuxilliaryReferenceData userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseAuxilliaryReferenceData rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseAuxilliaryReferenceData presenceBits.
       * @member {number} presenceBits
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.presenceBits = 0;

      /**
       * ResponseAuxilliaryReferenceData clearBits.
       * @member {number} clearBits
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.clearBits = 0;

      /**
       * ResponseAuxilliaryReferenceData symbol.
       * @member {string} symbol
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.symbol = '';

      /**
       * ResponseAuxilliaryReferenceData exchange.
       * @member {string} exchange
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.exchange = '';

      /**
       * ResponseAuxilliaryReferenceData settlementMethod.
       * @member {string} settlementMethod
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.settlementMethod = '';

      /**
       * ResponseAuxilliaryReferenceData firstNoticeDate.
       * @member {string} firstNoticeDate
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.firstNoticeDate = '';

      /**
       * ResponseAuxilliaryReferenceData lastNoticeDate.
       * @member {string} lastNoticeDate
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.lastNoticeDate = '';

      /**
       * ResponseAuxilliaryReferenceData firstTradingDate.
       * @member {string} firstTradingDate
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.firstTradingDate = '';

      /**
       * ResponseAuxilliaryReferenceData lastTradingDate.
       * @member {string} lastTradingDate
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.lastTradingDate = '';

      /**
       * ResponseAuxilliaryReferenceData firstDeliveryDate.
       * @member {string} firstDeliveryDate
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.firstDeliveryDate = '';

      /**
       * ResponseAuxilliaryReferenceData lastDeliveryDate.
       * @member {string} lastDeliveryDate
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.lastDeliveryDate = '';

      /**
       * ResponseAuxilliaryReferenceData firstPositionDate.
       * @member {string} firstPositionDate
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.firstPositionDate = '';

      /**
       * ResponseAuxilliaryReferenceData lastPositionDate.
       * @member {string} lastPositionDate
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.lastPositionDate = '';

      /**
       * ResponseAuxilliaryReferenceData unitOfMeasure.
       * @member {string} unitOfMeasure
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.unitOfMeasure = '';

      /**
       * ResponseAuxilliaryReferenceData unitOfMeasureQty.
       * @member {number} unitOfMeasureQty
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       */
      ResponseAuxilliaryReferenceData.prototype.unitOfMeasureQty = 0;

      /**
       * Creates a new ResponseAuxilliaryReferenceData instance using the specified properties.
       * @function create
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {rti.IResponseAuxilliaryReferenceData=} [properties] Properties to set
       * @returns {rti.ResponseAuxilliaryReferenceData} ResponseAuxilliaryReferenceData instance
       */
      ResponseAuxilliaryReferenceData.create = function create(properties) {
        return new ResponseAuxilliaryReferenceData(properties);
      };

      /**
       * Encodes the specified ResponseAuxilliaryReferenceData message. Does not implicitly {@link rti.ResponseAuxilliaryReferenceData.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {rti.IResponseAuxilliaryReferenceData} message ResponseAuxilliaryReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAuxilliaryReferenceData.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.settlementMethod != null &&
          Object.hasOwnProperty.call(message, 'settlementMethod')
        )
          writer
            .uint32(/* id 153294, wireType 2 =*/ 1226354)
            .string(message.settlementMethod);
        if (
          message.lastTradingDate != null &&
          Object.hasOwnProperty.call(message, 'lastTradingDate')
        )
          writer
            .uint32(/* id 154236, wireType 2 =*/ 1233890)
            .string(message.lastTradingDate);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        if (
          message.firstNoticeDate != null &&
          Object.hasOwnProperty.call(message, 'firstNoticeDate')
        )
          writer
            .uint32(/* id 154932, wireType 2 =*/ 1239458)
            .string(message.firstNoticeDate);
        if (
          message.lastNoticeDate != null &&
          Object.hasOwnProperty.call(message, 'lastNoticeDate')
        )
          writer
            .uint32(/* id 154933, wireType 2 =*/ 1239466)
            .string(message.lastNoticeDate);
        if (
          message.firstDeliveryDate != null &&
          Object.hasOwnProperty.call(message, 'firstDeliveryDate')
        )
          writer
            .uint32(/* id 154994, wireType 2 =*/ 1239954)
            .string(message.firstDeliveryDate);
        if (
          message.lastDeliveryDate != null &&
          Object.hasOwnProperty.call(message, 'lastDeliveryDate')
        )
          writer
            .uint32(/* id 154995, wireType 2 =*/ 1239962)
            .string(message.lastDeliveryDate);
        if (
          message.firstTradingDate != null &&
          Object.hasOwnProperty.call(message, 'firstTradingDate')
        )
          writer
            .uint32(/* id 154996, wireType 2 =*/ 1239970)
            .string(message.firstTradingDate);
        if (
          message.firstPositionDate != null &&
          Object.hasOwnProperty.call(message, 'firstPositionDate')
        )
          writer
            .uint32(/* id 154997, wireType 2 =*/ 1239978)
            .string(message.firstPositionDate);
        if (
          message.lastPositionDate != null &&
          Object.hasOwnProperty.call(message, 'lastPositionDate')
        )
          writer
            .uint32(/* id 154998, wireType 2 =*/ 1239986)
            .string(message.lastPositionDate);
        if (
          message.unitOfMeasure != null &&
          Object.hasOwnProperty.call(message, 'unitOfMeasure')
        )
          writer
            .uint32(/* id 157023, wireType 2 =*/ 1256186)
            .string(message.unitOfMeasure);
        if (
          message.unitOfMeasureQty != null &&
          Object.hasOwnProperty.call(message, 'unitOfMeasureQty')
        )
          writer
            .uint32(/* id 157024, wireType 1 =*/ 1256193)
            .double(message.unitOfMeasureQty);
        return writer;
      };

      /**
       * Encodes the specified ResponseAuxilliaryReferenceData message, length delimited. Does not implicitly {@link rti.ResponseAuxilliaryReferenceData.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {rti.IResponseAuxilliaryReferenceData} message ResponseAuxilliaryReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAuxilliaryReferenceData.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseAuxilliaryReferenceData message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseAuxilliaryReferenceData} ResponseAuxilliaryReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAuxilliaryReferenceData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseAuxilliaryReferenceData();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 153294:
              message.settlementMethod = reader.string();
              break;
            case 154932:
              message.firstNoticeDate = reader.string();
              break;
            case 154933:
              message.lastNoticeDate = reader.string();
              break;
            case 154996:
              message.firstTradingDate = reader.string();
              break;
            case 154236:
              message.lastTradingDate = reader.string();
              break;
            case 154994:
              message.firstDeliveryDate = reader.string();
              break;
            case 154995:
              message.lastDeliveryDate = reader.string();
              break;
            case 154997:
              message.firstPositionDate = reader.string();
              break;
            case 154998:
              message.lastPositionDate = reader.string();
              break;
            case 157023:
              message.unitOfMeasure = reader.string();
              break;
            case 157024:
              message.unitOfMeasureQty = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseAuxilliaryReferenceData message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseAuxilliaryReferenceData} ResponseAuxilliaryReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAuxilliaryReferenceData.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseAuxilliaryReferenceData message.
       * @function verify
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseAuxilliaryReferenceData.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.settlementMethod != null &&
          message.hasOwnProperty('settlementMethod')
        )
          if (!$util.isString(message.settlementMethod))
            return 'settlementMethod: string expected';
        if (
          message.firstNoticeDate != null &&
          message.hasOwnProperty('firstNoticeDate')
        )
          if (!$util.isString(message.firstNoticeDate))
            return 'firstNoticeDate: string expected';
        if (
          message.lastNoticeDate != null &&
          message.hasOwnProperty('lastNoticeDate')
        )
          if (!$util.isString(message.lastNoticeDate))
            return 'lastNoticeDate: string expected';
        if (
          message.firstTradingDate != null &&
          message.hasOwnProperty('firstTradingDate')
        )
          if (!$util.isString(message.firstTradingDate))
            return 'firstTradingDate: string expected';
        if (
          message.lastTradingDate != null &&
          message.hasOwnProperty('lastTradingDate')
        )
          if (!$util.isString(message.lastTradingDate))
            return 'lastTradingDate: string expected';
        if (
          message.firstDeliveryDate != null &&
          message.hasOwnProperty('firstDeliveryDate')
        )
          if (!$util.isString(message.firstDeliveryDate))
            return 'firstDeliveryDate: string expected';
        if (
          message.lastDeliveryDate != null &&
          message.hasOwnProperty('lastDeliveryDate')
        )
          if (!$util.isString(message.lastDeliveryDate))
            return 'lastDeliveryDate: string expected';
        if (
          message.firstPositionDate != null &&
          message.hasOwnProperty('firstPositionDate')
        )
          if (!$util.isString(message.firstPositionDate))
            return 'firstPositionDate: string expected';
        if (
          message.lastPositionDate != null &&
          message.hasOwnProperty('lastPositionDate')
        )
          if (!$util.isString(message.lastPositionDate))
            return 'lastPositionDate: string expected';
        if (
          message.unitOfMeasure != null &&
          message.hasOwnProperty('unitOfMeasure')
        )
          if (!$util.isString(message.unitOfMeasure))
            return 'unitOfMeasure: string expected';
        if (
          message.unitOfMeasureQty != null &&
          message.hasOwnProperty('unitOfMeasureQty')
        )
          if (typeof message.unitOfMeasureQty !== 'number')
            return 'unitOfMeasureQty: number expected';
        return null;
      };

      /**
       * Creates a ResponseAuxilliaryReferenceData message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseAuxilliaryReferenceData} ResponseAuxilliaryReferenceData
       */
      ResponseAuxilliaryReferenceData.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseAuxilliaryReferenceData)
          return object;
        var message = new $root.rti.ResponseAuxilliaryReferenceData();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseAuxilliaryReferenceData.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseAuxilliaryReferenceData.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.settlementMethod != null)
          message.settlementMethod = String(object.settlementMethod);
        if (object.firstNoticeDate != null)
          message.firstNoticeDate = String(object.firstNoticeDate);
        if (object.lastNoticeDate != null)
          message.lastNoticeDate = String(object.lastNoticeDate);
        if (object.firstTradingDate != null)
          message.firstTradingDate = String(object.firstTradingDate);
        if (object.lastTradingDate != null)
          message.lastTradingDate = String(object.lastTradingDate);
        if (object.firstDeliveryDate != null)
          message.firstDeliveryDate = String(object.firstDeliveryDate);
        if (object.lastDeliveryDate != null)
          message.lastDeliveryDate = String(object.lastDeliveryDate);
        if (object.firstPositionDate != null)
          message.firstPositionDate = String(object.firstPositionDate);
        if (object.lastPositionDate != null)
          message.lastPositionDate = String(object.lastPositionDate);
        if (object.unitOfMeasure != null)
          message.unitOfMeasure = String(object.unitOfMeasure);
        if (object.unitOfMeasureQty != null)
          message.unitOfMeasureQty = Number(object.unitOfMeasureQty);
        return message;
      };

      /**
       * Creates a plain object from a ResponseAuxilliaryReferenceData message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {rti.ResponseAuxilliaryReferenceData} message ResponseAuxilliaryReferenceData
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseAuxilliaryReferenceData.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.presenceBits = 0;
          object.settlementMethod = '';
          object.lastTradingDate = '';
          object.templateId = 0;
          object.clearBits = 0;
          object.firstNoticeDate = '';
          object.lastNoticeDate = '';
          object.firstDeliveryDate = '';
          object.lastDeliveryDate = '';
          object.firstTradingDate = '';
          object.firstPositionDate = '';
          object.lastPositionDate = '';
          object.unitOfMeasure = '';
          object.unitOfMeasureQty = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (
          message.settlementMethod != null &&
          message.hasOwnProperty('settlementMethod')
        )
          object.settlementMethod = message.settlementMethod;
        if (
          message.lastTradingDate != null &&
          message.hasOwnProperty('lastTradingDate')
        )
          object.lastTradingDate = message.lastTradingDate;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        if (
          message.firstNoticeDate != null &&
          message.hasOwnProperty('firstNoticeDate')
        )
          object.firstNoticeDate = message.firstNoticeDate;
        if (
          message.lastNoticeDate != null &&
          message.hasOwnProperty('lastNoticeDate')
        )
          object.lastNoticeDate = message.lastNoticeDate;
        if (
          message.firstDeliveryDate != null &&
          message.hasOwnProperty('firstDeliveryDate')
        )
          object.firstDeliveryDate = message.firstDeliveryDate;
        if (
          message.lastDeliveryDate != null &&
          message.hasOwnProperty('lastDeliveryDate')
        )
          object.lastDeliveryDate = message.lastDeliveryDate;
        if (
          message.firstTradingDate != null &&
          message.hasOwnProperty('firstTradingDate')
        )
          object.firstTradingDate = message.firstTradingDate;
        if (
          message.firstPositionDate != null &&
          message.hasOwnProperty('firstPositionDate')
        )
          object.firstPositionDate = message.firstPositionDate;
        if (
          message.lastPositionDate != null &&
          message.hasOwnProperty('lastPositionDate')
        )
          object.lastPositionDate = message.lastPositionDate;
        if (
          message.unitOfMeasure != null &&
          message.hasOwnProperty('unitOfMeasure')
        )
          object.unitOfMeasure = message.unitOfMeasure;
        if (
          message.unitOfMeasureQty != null &&
          message.hasOwnProperty('unitOfMeasureQty')
        )
          object.unitOfMeasureQty =
            options.json && !isFinite(message.unitOfMeasureQty)
              ? String(message.unitOfMeasureQty)
              : message.unitOfMeasureQty;
        return object;
      };

      /**
       * Converts this ResponseAuxilliaryReferenceData to JSON.
       * @function toJSON
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseAuxilliaryReferenceData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseAuxilliaryReferenceData
       * @function getTypeUrl
       * @memberof rti.ResponseAuxilliaryReferenceData
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseAuxilliaryReferenceData.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseAuxilliaryReferenceData';
      };

      /**
       * PresenceBits enum.
       * @name rti.ResponseAuxilliaryReferenceData.PresenceBits
       * @enum {number}
       * @property {number} SETTLEMENT_METHOD=1 SETTLEMENT_METHOD value
       * @property {number} FIRST_NOTICE_DATE=2 FIRST_NOTICE_DATE value
       * @property {number} LAST_NOTICE_DATE=4 LAST_NOTICE_DATE value
       * @property {number} FIRST_TRADING_DATE=8 FIRST_TRADING_DATE value
       * @property {number} LAST_TRADING_DATE=16 LAST_TRADING_DATE value
       * @property {number} FIRST_DELIVERY_DATE=32 FIRST_DELIVERY_DATE value
       * @property {number} LAST_DELIVERY_DATE=64 LAST_DELIVERY_DATE value
       * @property {number} FIRST_POSITION_DATE=128 FIRST_POSITION_DATE value
       * @property {number} LAST_POSITION_DATE=256 LAST_POSITION_DATE value
       * @property {number} UNIT_OF_MEASURE=512 UNIT_OF_MEASURE value
       * @property {number} UNIT_OF_MEASURE_QTY=1024 UNIT_OF_MEASURE_QTY value
       */
      ResponseAuxilliaryReferenceData.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SETTLEMENT_METHOD')] = 1;
        values[(valuesById[2] = 'FIRST_NOTICE_DATE')] = 2;
        values[(valuesById[4] = 'LAST_NOTICE_DATE')] = 4;
        values[(valuesById[8] = 'FIRST_TRADING_DATE')] = 8;
        values[(valuesById[16] = 'LAST_TRADING_DATE')] = 16;
        values[(valuesById[32] = 'FIRST_DELIVERY_DATE')] = 32;
        values[(valuesById[64] = 'LAST_DELIVERY_DATE')] = 64;
        values[(valuesById[128] = 'FIRST_POSITION_DATE')] = 128;
        values[(valuesById[256] = 'LAST_POSITION_DATE')] = 256;
        values[(valuesById[512] = 'UNIT_OF_MEASURE')] = 512;
        values[(valuesById[1024] = 'UNIT_OF_MEASURE_QTY')] = 1024;
        return values;
      })();

      return ResponseAuxilliaryReferenceData;
    })();

    rti.RequestGiveTickSizeTypeTable = (function () {
      /**
       * Properties of a RequestGiveTickSizeTypeTable.
       * @memberof rti
       * @interface IRequestGiveTickSizeTypeTable
       * @property {number} templateId RequestGiveTickSizeTypeTable templateId
       * @property {Array.<string>|null} [userMsg] RequestGiveTickSizeTypeTable userMsg
       * @property {string|null} [tickSizeType] RequestGiveTickSizeTypeTable tickSizeType
       */

      /**
       * Constructs a new RequestGiveTickSizeTypeTable.
       * @memberof rti
       * @classdesc Represents a RequestGiveTickSizeTypeTable.
       * @implements IRequestGiveTickSizeTypeTable
       * @constructor
       * @param {rti.IRequestGiveTickSizeTypeTable=} [properties] Properties to set
       */
      function RequestGiveTickSizeTypeTable(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestGiveTickSizeTypeTable templateId.
       * @member {number} templateId
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @instance
       */
      RequestGiveTickSizeTypeTable.prototype.templateId = 0;

      /**
       * RequestGiveTickSizeTypeTable userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @instance
       */
      RequestGiveTickSizeTypeTable.prototype.userMsg = $util.emptyArray;

      /**
       * RequestGiveTickSizeTypeTable tickSizeType.
       * @member {string} tickSizeType
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @instance
       */
      RequestGiveTickSizeTypeTable.prototype.tickSizeType = '';

      /**
       * Creates a new RequestGiveTickSizeTypeTable instance using the specified properties.
       * @function create
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {rti.IRequestGiveTickSizeTypeTable=} [properties] Properties to set
       * @returns {rti.RequestGiveTickSizeTypeTable} RequestGiveTickSizeTypeTable instance
       */
      RequestGiveTickSizeTypeTable.create = function create(properties) {
        return new RequestGiveTickSizeTypeTable(properties);
      };

      /**
       * Encodes the specified RequestGiveTickSizeTypeTable message. Does not implicitly {@link rti.RequestGiveTickSizeTypeTable.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {rti.IRequestGiveTickSizeTypeTable} message RequestGiveTickSizeTypeTable message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestGiveTickSizeTypeTable.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.tickSizeType != null &&
          Object.hasOwnProperty.call(message, 'tickSizeType')
        )
          writer
            .uint32(/* id 154167, wireType 2 =*/ 1233338)
            .string(message.tickSizeType);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestGiveTickSizeTypeTable message, length delimited. Does not implicitly {@link rti.RequestGiveTickSizeTypeTable.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {rti.IRequestGiveTickSizeTypeTable} message RequestGiveTickSizeTypeTable message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestGiveTickSizeTypeTable.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestGiveTickSizeTypeTable message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestGiveTickSizeTypeTable} RequestGiveTickSizeTypeTable
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestGiveTickSizeTypeTable.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestGiveTickSizeTypeTable();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154167:
              message.tickSizeType = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestGiveTickSizeTypeTable message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestGiveTickSizeTypeTable} RequestGiveTickSizeTypeTable
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestGiveTickSizeTypeTable.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestGiveTickSizeTypeTable message.
       * @function verify
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestGiveTickSizeTypeTable.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.tickSizeType != null &&
          message.hasOwnProperty('tickSizeType')
        )
          if (!$util.isString(message.tickSizeType))
            return 'tickSizeType: string expected';
        return null;
      };

      /**
       * Creates a RequestGiveTickSizeTypeTable message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestGiveTickSizeTypeTable} RequestGiveTickSizeTypeTable
       */
      RequestGiveTickSizeTypeTable.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestGiveTickSizeTypeTable)
          return object;
        var message = new $root.rti.RequestGiveTickSizeTypeTable();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestGiveTickSizeTypeTable.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.tickSizeType != null)
          message.tickSizeType = String(object.tickSizeType);
        return message;
      };

      /**
       * Creates a plain object from a RequestGiveTickSizeTypeTable message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {rti.RequestGiveTickSizeTypeTable} message RequestGiveTickSizeTypeTable
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestGiveTickSizeTypeTable.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.tickSizeType = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.tickSizeType != null &&
          message.hasOwnProperty('tickSizeType')
        )
          object.tickSizeType = message.tickSizeType;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestGiveTickSizeTypeTable to JSON.
       * @function toJSON
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestGiveTickSizeTypeTable.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestGiveTickSizeTypeTable
       * @function getTypeUrl
       * @memberof rti.RequestGiveTickSizeTypeTable
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestGiveTickSizeTypeTable.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestGiveTickSizeTypeTable';
      };

      return RequestGiveTickSizeTypeTable;
    })();

    rti.ResponseGiveTickSizeTypeTable = (function () {
      /**
       * Properties of a ResponseGiveTickSizeTypeTable.
       * @memberof rti
       * @interface IResponseGiveTickSizeTypeTable
       * @property {number} templateId ResponseGiveTickSizeTypeTable templateId
       * @property {Array.<string>|null} [userMsg] ResponseGiveTickSizeTypeTable userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseGiveTickSizeTypeTable rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseGiveTickSizeTypeTable rpCode
       * @property {number|null} [presenceBits] ResponseGiveTickSizeTypeTable presenceBits
       * @property {string|null} [tickSizeType] ResponseGiveTickSizeTypeTable tickSizeType
       * @property {string|null} [tickSizeFpOperator] ResponseGiveTickSizeTypeTable tickSizeFpOperator
       * @property {string|null} [tickSizeLpOperator] ResponseGiveTickSizeTypeTable tickSizeLpOperator
       * @property {number|null} [minFpriceChange] ResponseGiveTickSizeTypeTable minFpriceChange
       * @property {number|null} [tickSizeFirstPrice] ResponseGiveTickSizeTypeTable tickSizeFirstPrice
       * @property {number|null} [tickSizeLastPrice] ResponseGiveTickSizeTypeTable tickSizeLastPrice
       */

      /**
       * Constructs a new ResponseGiveTickSizeTypeTable.
       * @memberof rti
       * @classdesc Represents a ResponseGiveTickSizeTypeTable.
       * @implements IResponseGiveTickSizeTypeTable
       * @constructor
       * @param {rti.IResponseGiveTickSizeTypeTable=} [properties] Properties to set
       */
      function ResponseGiveTickSizeTypeTable(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseGiveTickSizeTypeTable templateId.
       * @member {number} templateId
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.templateId = 0;

      /**
       * ResponseGiveTickSizeTypeTable userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseGiveTickSizeTypeTable rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.rqHandlerRpCode =
        $util.emptyArray;

      /**
       * ResponseGiveTickSizeTypeTable rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseGiveTickSizeTypeTable presenceBits.
       * @member {number} presenceBits
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.presenceBits = 0;

      /**
       * ResponseGiveTickSizeTypeTable tickSizeType.
       * @member {string} tickSizeType
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.tickSizeType = '';

      /**
       * ResponseGiveTickSizeTypeTable tickSizeFpOperator.
       * @member {string} tickSizeFpOperator
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.tickSizeFpOperator = '';

      /**
       * ResponseGiveTickSizeTypeTable tickSizeLpOperator.
       * @member {string} tickSizeLpOperator
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.tickSizeLpOperator = '';

      /**
       * ResponseGiveTickSizeTypeTable minFpriceChange.
       * @member {number} minFpriceChange
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.minFpriceChange = 0;

      /**
       * ResponseGiveTickSizeTypeTable tickSizeFirstPrice.
       * @member {number} tickSizeFirstPrice
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.tickSizeFirstPrice = 0;

      /**
       * ResponseGiveTickSizeTypeTable tickSizeLastPrice.
       * @member {number} tickSizeLastPrice
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       */
      ResponseGiveTickSizeTypeTable.prototype.tickSizeLastPrice = 0;

      /**
       * Creates a new ResponseGiveTickSizeTypeTable instance using the specified properties.
       * @function create
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {rti.IResponseGiveTickSizeTypeTable=} [properties] Properties to set
       * @returns {rti.ResponseGiveTickSizeTypeTable} ResponseGiveTickSizeTypeTable instance
       */
      ResponseGiveTickSizeTypeTable.create = function create(properties) {
        return new ResponseGiveTickSizeTypeTable(properties);
      };

      /**
       * Encodes the specified ResponseGiveTickSizeTypeTable message. Does not implicitly {@link rti.ResponseGiveTickSizeTypeTable.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {rti.IResponseGiveTickSizeTypeTable} message ResponseGiveTickSizeTypeTable message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseGiveTickSizeTypeTable.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.tickSizeType != null &&
          Object.hasOwnProperty.call(message, 'tickSizeType')
        )
          writer
            .uint32(/* id 154167, wireType 2 =*/ 1233338)
            .string(message.tickSizeType);
        if (
          message.tickSizeFirstPrice != null &&
          Object.hasOwnProperty.call(message, 'tickSizeFirstPrice')
        )
          writer
            .uint32(/* id 154168, wireType 1 =*/ 1233345)
            .double(message.tickSizeFirstPrice);
        if (
          message.tickSizeLastPrice != null &&
          Object.hasOwnProperty.call(message, 'tickSizeLastPrice')
        )
          writer
            .uint32(/* id 154169, wireType 1 =*/ 1233353)
            .double(message.tickSizeLastPrice);
        if (
          message.tickSizeFpOperator != null &&
          Object.hasOwnProperty.call(message, 'tickSizeFpOperator')
        )
          writer
            .uint32(/* id 154170, wireType 2 =*/ 1233362)
            .string(message.tickSizeFpOperator);
        if (
          message.tickSizeLpOperator != null &&
          Object.hasOwnProperty.call(message, 'tickSizeLpOperator')
        )
          writer
            .uint32(/* id 154171, wireType 2 =*/ 1233370)
            .string(message.tickSizeLpOperator);
        if (
          message.minFpriceChange != null &&
          Object.hasOwnProperty.call(message, 'minFpriceChange')
        )
          writer
            .uint32(/* id 154387, wireType 1 =*/ 1235097)
            .double(message.minFpriceChange);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseGiveTickSizeTypeTable message, length delimited. Does not implicitly {@link rti.ResponseGiveTickSizeTypeTable.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {rti.IResponseGiveTickSizeTypeTable} message ResponseGiveTickSizeTypeTable message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseGiveTickSizeTypeTable.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseGiveTickSizeTypeTable message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseGiveTickSizeTypeTable} ResponseGiveTickSizeTypeTable
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseGiveTickSizeTypeTable.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseGiveTickSizeTypeTable();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154167:
              message.tickSizeType = reader.string();
              break;
            case 154170:
              message.tickSizeFpOperator = reader.string();
              break;
            case 154171:
              message.tickSizeLpOperator = reader.string();
              break;
            case 154387:
              message.minFpriceChange = reader.double();
              break;
            case 154168:
              message.tickSizeFirstPrice = reader.double();
              break;
            case 154169:
              message.tickSizeLastPrice = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseGiveTickSizeTypeTable message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseGiveTickSizeTypeTable} ResponseGiveTickSizeTypeTable
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseGiveTickSizeTypeTable.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseGiveTickSizeTypeTable message.
       * @function verify
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseGiveTickSizeTypeTable.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (
          message.tickSizeType != null &&
          message.hasOwnProperty('tickSizeType')
        )
          if (!$util.isString(message.tickSizeType))
            return 'tickSizeType: string expected';
        if (
          message.tickSizeFpOperator != null &&
          message.hasOwnProperty('tickSizeFpOperator')
        )
          if (!$util.isString(message.tickSizeFpOperator))
            return 'tickSizeFpOperator: string expected';
        if (
          message.tickSizeLpOperator != null &&
          message.hasOwnProperty('tickSizeLpOperator')
        )
          if (!$util.isString(message.tickSizeLpOperator))
            return 'tickSizeLpOperator: string expected';
        if (
          message.minFpriceChange != null &&
          message.hasOwnProperty('minFpriceChange')
        )
          if (typeof message.minFpriceChange !== 'number')
            return 'minFpriceChange: number expected';
        if (
          message.tickSizeFirstPrice != null &&
          message.hasOwnProperty('tickSizeFirstPrice')
        )
          if (typeof message.tickSizeFirstPrice !== 'number')
            return 'tickSizeFirstPrice: number expected';
        if (
          message.tickSizeLastPrice != null &&
          message.hasOwnProperty('tickSizeLastPrice')
        )
          if (typeof message.tickSizeLastPrice !== 'number')
            return 'tickSizeLastPrice: number expected';
        return null;
      };

      /**
       * Creates a ResponseGiveTickSizeTypeTable message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseGiveTickSizeTypeTable} ResponseGiveTickSizeTypeTable
       */
      ResponseGiveTickSizeTypeTable.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseGiveTickSizeTypeTable)
          return object;
        var message = new $root.rti.ResponseGiveTickSizeTypeTable();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseGiveTickSizeTypeTable.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseGiveTickSizeTypeTable.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseGiveTickSizeTypeTable.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.tickSizeType != null)
          message.tickSizeType = String(object.tickSizeType);
        if (object.tickSizeFpOperator != null)
          message.tickSizeFpOperator = String(object.tickSizeFpOperator);
        if (object.tickSizeLpOperator != null)
          message.tickSizeLpOperator = String(object.tickSizeLpOperator);
        if (object.minFpriceChange != null)
          message.minFpriceChange = Number(object.minFpriceChange);
        if (object.tickSizeFirstPrice != null)
          message.tickSizeFirstPrice = Number(object.tickSizeFirstPrice);
        if (object.tickSizeLastPrice != null)
          message.tickSizeLastPrice = Number(object.tickSizeLastPrice);
        return message;
      };

      /**
       * Creates a plain object from a ResponseGiveTickSizeTypeTable message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {rti.ResponseGiveTickSizeTypeTable} message ResponseGiveTickSizeTypeTable
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseGiveTickSizeTypeTable.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.presenceBits = 0;
          object.tickSizeType = '';
          object.tickSizeFirstPrice = 0;
          object.tickSizeLastPrice = 0;
          object.tickSizeFpOperator = '';
          object.tickSizeLpOperator = '';
          object.minFpriceChange = 0;
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (
          message.tickSizeType != null &&
          message.hasOwnProperty('tickSizeType')
        )
          object.tickSizeType = message.tickSizeType;
        if (
          message.tickSizeFirstPrice != null &&
          message.hasOwnProperty('tickSizeFirstPrice')
        )
          object.tickSizeFirstPrice =
            options.json && !isFinite(message.tickSizeFirstPrice)
              ? String(message.tickSizeFirstPrice)
              : message.tickSizeFirstPrice;
        if (
          message.tickSizeLastPrice != null &&
          message.hasOwnProperty('tickSizeLastPrice')
        )
          object.tickSizeLastPrice =
            options.json && !isFinite(message.tickSizeLastPrice)
              ? String(message.tickSizeLastPrice)
              : message.tickSizeLastPrice;
        if (
          message.tickSizeFpOperator != null &&
          message.hasOwnProperty('tickSizeFpOperator')
        )
          object.tickSizeFpOperator = message.tickSizeFpOperator;
        if (
          message.tickSizeLpOperator != null &&
          message.hasOwnProperty('tickSizeLpOperator')
        )
          object.tickSizeLpOperator = message.tickSizeLpOperator;
        if (
          message.minFpriceChange != null &&
          message.hasOwnProperty('minFpriceChange')
        )
          object.minFpriceChange =
            options.json && !isFinite(message.minFpriceChange)
              ? String(message.minFpriceChange)
              : message.minFpriceChange;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseGiveTickSizeTypeTable to JSON.
       * @function toJSON
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseGiveTickSizeTypeTable.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseGiveTickSizeTypeTable
       * @function getTypeUrl
       * @memberof rti.ResponseGiveTickSizeTypeTable
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseGiveTickSizeTypeTable.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseGiveTickSizeTypeTable';
      };

      /**
       * PresenceBits enum.
       * @name rti.ResponseGiveTickSizeTypeTable.PresenceBits
       * @enum {number}
       * @property {number} TICK_SIZE_FIRST_PRICE=1 TICK_SIZE_FIRST_PRICE value
       * @property {number} TICK_SIZE_LAST_PRICE=2 TICK_SIZE_LAST_PRICE value
       * @property {number} TICK_SIZE_FP_OPERATOR=4 TICK_SIZE_FP_OPERATOR value
       * @property {number} TICK_SIZE_LP_OPERATOR=8 TICK_SIZE_LP_OPERATOR value
       */
      ResponseGiveTickSizeTypeTable.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'TICK_SIZE_FIRST_PRICE')] = 1;
        values[(valuesById[2] = 'TICK_SIZE_LAST_PRICE')] = 2;
        values[(valuesById[4] = 'TICK_SIZE_FP_OPERATOR')] = 4;
        values[(valuesById[8] = 'TICK_SIZE_LP_OPERATOR')] = 8;
        return values;
      })();

      return ResponseGiveTickSizeTypeTable;
    })();

    rti.RequestGetInstrumentByUnderlying = (function () {
      /**
       * Properties of a RequestGetInstrumentByUnderlying.
       * @memberof rti
       * @interface IRequestGetInstrumentByUnderlying
       * @property {number} templateId RequestGetInstrumentByUnderlying templateId
       * @property {Array.<string>|null} [userMsg] RequestGetInstrumentByUnderlying userMsg
       * @property {string|null} [underlyingSymbol] RequestGetInstrumentByUnderlying underlyingSymbol
       * @property {string|null} [exchange] RequestGetInstrumentByUnderlying exchange
       * @property {string|null} [expirationDate] RequestGetInstrumentByUnderlying expirationDate
       */

      /**
       * Constructs a new RequestGetInstrumentByUnderlying.
       * @memberof rti
       * @classdesc Represents a RequestGetInstrumentByUnderlying.
       * @implements IRequestGetInstrumentByUnderlying
       * @constructor
       * @param {rti.IRequestGetInstrumentByUnderlying=} [properties] Properties to set
       */
      function RequestGetInstrumentByUnderlying(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestGetInstrumentByUnderlying templateId.
       * @member {number} templateId
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @instance
       */
      RequestGetInstrumentByUnderlying.prototype.templateId = 0;

      /**
       * RequestGetInstrumentByUnderlying userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @instance
       */
      RequestGetInstrumentByUnderlying.prototype.userMsg = $util.emptyArray;

      /**
       * RequestGetInstrumentByUnderlying underlyingSymbol.
       * @member {string} underlyingSymbol
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @instance
       */
      RequestGetInstrumentByUnderlying.prototype.underlyingSymbol = '';

      /**
       * RequestGetInstrumentByUnderlying exchange.
       * @member {string} exchange
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @instance
       */
      RequestGetInstrumentByUnderlying.prototype.exchange = '';

      /**
       * RequestGetInstrumentByUnderlying expirationDate.
       * @member {string} expirationDate
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @instance
       */
      RequestGetInstrumentByUnderlying.prototype.expirationDate = '';

      /**
       * Creates a new RequestGetInstrumentByUnderlying instance using the specified properties.
       * @function create
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {rti.IRequestGetInstrumentByUnderlying=} [properties] Properties to set
       * @returns {rti.RequestGetInstrumentByUnderlying} RequestGetInstrumentByUnderlying instance
       */
      RequestGetInstrumentByUnderlying.create = function create(properties) {
        return new RequestGetInstrumentByUnderlying(properties);
      };

      /**
       * Encodes the specified RequestGetInstrumentByUnderlying message. Does not implicitly {@link rti.RequestGetInstrumentByUnderlying.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {rti.IRequestGetInstrumentByUnderlying} message RequestGetInstrumentByUnderlying message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestGetInstrumentByUnderlying.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (
          message.expirationDate != null &&
          Object.hasOwnProperty.call(message, 'expirationDate')
        )
          writer
            .uint32(/* id 100067, wireType 2 =*/ 800538)
            .string(message.expirationDate);
        if (
          message.underlyingSymbol != null &&
          Object.hasOwnProperty.call(message, 'underlyingSymbol')
        )
          writer
            .uint32(/* id 101026, wireType 2 =*/ 808210)
            .string(message.underlyingSymbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestGetInstrumentByUnderlying message, length delimited. Does not implicitly {@link rti.RequestGetInstrumentByUnderlying.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {rti.IRequestGetInstrumentByUnderlying} message RequestGetInstrumentByUnderlying message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestGetInstrumentByUnderlying.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestGetInstrumentByUnderlying message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestGetInstrumentByUnderlying} RequestGetInstrumentByUnderlying
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestGetInstrumentByUnderlying.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestGetInstrumentByUnderlying();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 101026:
              message.underlyingSymbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100067:
              message.expirationDate = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestGetInstrumentByUnderlying message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestGetInstrumentByUnderlying} RequestGetInstrumentByUnderlying
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestGetInstrumentByUnderlying.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestGetInstrumentByUnderlying message.
       * @function verify
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestGetInstrumentByUnderlying.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        )
          if (!$util.isString(message.underlyingSymbol))
            return 'underlyingSymbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          if (!$util.isString(message.expirationDate))
            return 'expirationDate: string expected';
        return null;
      };

      /**
       * Creates a RequestGetInstrumentByUnderlying message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestGetInstrumentByUnderlying} RequestGetInstrumentByUnderlying
       */
      RequestGetInstrumentByUnderlying.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.RequestGetInstrumentByUnderlying)
          return object;
        var message = new $root.rti.RequestGetInstrumentByUnderlying();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestGetInstrumentByUnderlying.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.underlyingSymbol != null)
          message.underlyingSymbol = String(object.underlyingSymbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.expirationDate != null)
          message.expirationDate = String(object.expirationDate);
        return message;
      };

      /**
       * Creates a plain object from a RequestGetInstrumentByUnderlying message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {rti.RequestGetInstrumentByUnderlying} message RequestGetInstrumentByUnderlying
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestGetInstrumentByUnderlying.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.expirationDate = '';
          object.underlyingSymbol = '';
          object.exchange = '';
          object.templateId = 0;
        }
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          object.expirationDate = message.expirationDate;
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        )
          object.underlyingSymbol = message.underlyingSymbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestGetInstrumentByUnderlying to JSON.
       * @function toJSON
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestGetInstrumentByUnderlying.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestGetInstrumentByUnderlying
       * @function getTypeUrl
       * @memberof rti.RequestGetInstrumentByUnderlying
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestGetInstrumentByUnderlying.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestGetInstrumentByUnderlying';
      };

      return RequestGetInstrumentByUnderlying;
    })();

    rti.ResponseGetInstrumentByUnderlying = (function () {
      /**
       * Properties of a ResponseGetInstrumentByUnderlying.
       * @memberof rti
       * @interface IResponseGetInstrumentByUnderlying
       * @property {number} templateId ResponseGetInstrumentByUnderlying templateId
       * @property {Array.<string>|null} [userMsg] ResponseGetInstrumentByUnderlying userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseGetInstrumentByUnderlying rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseGetInstrumentByUnderlying rpCode
       * @property {number|null} [presenceBits] ResponseGetInstrumentByUnderlying presenceBits
       * @property {number|null} [clearBits] ResponseGetInstrumentByUnderlying clearBits
       * @property {string|null} [symbol] ResponseGetInstrumentByUnderlying symbol
       * @property {string|null} [exchange] ResponseGetInstrumentByUnderlying exchange
       * @property {string|null} [exchangeSymbol] ResponseGetInstrumentByUnderlying exchangeSymbol
       * @property {string|null} [symbolName] ResponseGetInstrumentByUnderlying symbolName
       * @property {string|null} [productCode] ResponseGetInstrumentByUnderlying productCode
       * @property {string|null} [instrumentType] ResponseGetInstrumentByUnderlying instrumentType
       * @property {string|null} [underlyingSymbol] ResponseGetInstrumentByUnderlying underlyingSymbol
       * @property {string|null} [expirationDate] ResponseGetInstrumentByUnderlying expirationDate
       * @property {string|null} [currency] ResponseGetInstrumentByUnderlying currency
       * @property {string|null} [putCallIndicator] ResponseGetInstrumentByUnderlying putCallIndicator
       * @property {string|null} [tickSizeType] ResponseGetInstrumentByUnderlying tickSizeType
       * @property {string|null} [priceDisplayFormat] ResponseGetInstrumentByUnderlying priceDisplayFormat
       * @property {number|null} [strikePrice] ResponseGetInstrumentByUnderlying strikePrice
       * @property {number|null} [ftoqPrice] ResponseGetInstrumentByUnderlying ftoqPrice
       * @property {number|null} [qtofPrice] ResponseGetInstrumentByUnderlying qtofPrice
       * @property {number|null} [minQpriceChange] ResponseGetInstrumentByUnderlying minQpriceChange
       * @property {number|null} [minFpriceChange] ResponseGetInstrumentByUnderlying minFpriceChange
       * @property {number|null} [singlePointValue] ResponseGetInstrumentByUnderlying singlePointValue
       */

      /**
       * Constructs a new ResponseGetInstrumentByUnderlying.
       * @memberof rti
       * @classdesc Represents a ResponseGetInstrumentByUnderlying.
       * @implements IResponseGetInstrumentByUnderlying
       * @constructor
       * @param {rti.IResponseGetInstrumentByUnderlying=} [properties] Properties to set
       */
      function ResponseGetInstrumentByUnderlying(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseGetInstrumentByUnderlying templateId.
       * @member {number} templateId
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.templateId = 0;

      /**
       * ResponseGetInstrumentByUnderlying userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseGetInstrumentByUnderlying rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.rqHandlerRpCode =
        $util.emptyArray;

      /**
       * ResponseGetInstrumentByUnderlying rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseGetInstrumentByUnderlying presenceBits.
       * @member {number} presenceBits
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.presenceBits = 0;

      /**
       * ResponseGetInstrumentByUnderlying clearBits.
       * @member {number} clearBits
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.clearBits = 0;

      /**
       * ResponseGetInstrumentByUnderlying symbol.
       * @member {string} symbol
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.symbol = '';

      /**
       * ResponseGetInstrumentByUnderlying exchange.
       * @member {string} exchange
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.exchange = '';

      /**
       * ResponseGetInstrumentByUnderlying exchangeSymbol.
       * @member {string} exchangeSymbol
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.exchangeSymbol = '';

      /**
       * ResponseGetInstrumentByUnderlying symbolName.
       * @member {string} symbolName
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.symbolName = '';

      /**
       * ResponseGetInstrumentByUnderlying productCode.
       * @member {string} productCode
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.productCode = '';

      /**
       * ResponseGetInstrumentByUnderlying instrumentType.
       * @member {string} instrumentType
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.instrumentType = '';

      /**
       * ResponseGetInstrumentByUnderlying underlyingSymbol.
       * @member {string} underlyingSymbol
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.underlyingSymbol = '';

      /**
       * ResponseGetInstrumentByUnderlying expirationDate.
       * @member {string} expirationDate
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.expirationDate = '';

      /**
       * ResponseGetInstrumentByUnderlying currency.
       * @member {string} currency
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.currency = '';

      /**
       * ResponseGetInstrumentByUnderlying putCallIndicator.
       * @member {string} putCallIndicator
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.putCallIndicator = '';

      /**
       * ResponseGetInstrumentByUnderlying tickSizeType.
       * @member {string} tickSizeType
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.tickSizeType = '';

      /**
       * ResponseGetInstrumentByUnderlying priceDisplayFormat.
       * @member {string} priceDisplayFormat
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.priceDisplayFormat = '';

      /**
       * ResponseGetInstrumentByUnderlying strikePrice.
       * @member {number} strikePrice
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.strikePrice = 0;

      /**
       * ResponseGetInstrumentByUnderlying ftoqPrice.
       * @member {number} ftoqPrice
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.ftoqPrice = 0;

      /**
       * ResponseGetInstrumentByUnderlying qtofPrice.
       * @member {number} qtofPrice
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.qtofPrice = 0;

      /**
       * ResponseGetInstrumentByUnderlying minQpriceChange.
       * @member {number} minQpriceChange
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.minQpriceChange = 0;

      /**
       * ResponseGetInstrumentByUnderlying minFpriceChange.
       * @member {number} minFpriceChange
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.minFpriceChange = 0;

      /**
       * ResponseGetInstrumentByUnderlying singlePointValue.
       * @member {number} singlePointValue
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       */
      ResponseGetInstrumentByUnderlying.prototype.singlePointValue = 0;

      /**
       * Creates a new ResponseGetInstrumentByUnderlying instance using the specified properties.
       * @function create
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {rti.IResponseGetInstrumentByUnderlying=} [properties] Properties to set
       * @returns {rti.ResponseGetInstrumentByUnderlying} ResponseGetInstrumentByUnderlying instance
       */
      ResponseGetInstrumentByUnderlying.create = function create(properties) {
        return new ResponseGetInstrumentByUnderlying(properties);
      };

      /**
       * Encodes the specified ResponseGetInstrumentByUnderlying message. Does not implicitly {@link rti.ResponseGetInstrumentByUnderlying.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {rti.IResponseGetInstrumentByUnderlying} message ResponseGetInstrumentByUnderlying message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseGetInstrumentByUnderlying.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbolName != null &&
          Object.hasOwnProperty.call(message, 'symbolName')
        )
          writer
            .uint32(/* id 100003, wireType 2 =*/ 800026)
            .string(message.symbolName);
        if (
          message.strikePrice != null &&
          Object.hasOwnProperty.call(message, 'strikePrice')
        )
          writer
            .uint32(/* id 100066, wireType 1 =*/ 800529)
            .double(message.strikePrice);
        if (
          message.expirationDate != null &&
          Object.hasOwnProperty.call(message, 'expirationDate')
        )
          writer
            .uint32(/* id 100067, wireType 2 =*/ 800538)
            .string(message.expirationDate);
        if (
          message.putCallIndicator != null &&
          Object.hasOwnProperty.call(message, 'putCallIndicator')
        )
          writer
            .uint32(/* id 100109, wireType 2 =*/ 800874)
            .string(message.putCallIndicator);
        if (
          message.productCode != null &&
          Object.hasOwnProperty.call(message, 'productCode')
        )
          writer
            .uint32(/* id 100749, wireType 2 =*/ 805994)
            .string(message.productCode);
        if (
          message.underlyingSymbol != null &&
          Object.hasOwnProperty.call(message, 'underlyingSymbol')
        )
          writer
            .uint32(/* id 101026, wireType 2 =*/ 808210)
            .string(message.underlyingSymbol);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.exchangeSymbol != null &&
          Object.hasOwnProperty.call(message, 'exchangeSymbol')
        )
          writer
            .uint32(/* id 110114, wireType 2 =*/ 880914)
            .string(message.exchangeSymbol);
        if (
          message.instrumentType != null &&
          Object.hasOwnProperty.call(message, 'instrumentType')
        )
          writer
            .uint32(/* id 110116, wireType 2 =*/ 880930)
            .string(message.instrumentType);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.tickSizeType != null &&
          Object.hasOwnProperty.call(message, 'tickSizeType')
        )
          writer
            .uint32(/* id 154167, wireType 2 =*/ 1233338)
            .string(message.tickSizeType);
        if (
          message.currency != null &&
          Object.hasOwnProperty.call(message, 'currency')
        )
          writer
            .uint32(/* id 154382, wireType 2 =*/ 1235058)
            .string(message.currency);
        if (
          message.ftoqPrice != null &&
          Object.hasOwnProperty.call(message, 'ftoqPrice')
        )
          writer
            .uint32(/* id 154384, wireType 1 =*/ 1235073)
            .double(message.ftoqPrice);
        if (
          message.qtofPrice != null &&
          Object.hasOwnProperty.call(message, 'qtofPrice')
        )
          writer
            .uint32(/* id 154385, wireType 1 =*/ 1235081)
            .double(message.qtofPrice);
        if (
          message.minQpriceChange != null &&
          Object.hasOwnProperty.call(message, 'minQpriceChange')
        )
          writer
            .uint32(/* id 154386, wireType 1 =*/ 1235089)
            .double(message.minQpriceChange);
        if (
          message.minFpriceChange != null &&
          Object.hasOwnProperty.call(message, 'minFpriceChange')
        )
          writer
            .uint32(/* id 154387, wireType 1 =*/ 1235097)
            .double(message.minFpriceChange);
        if (
          message.singlePointValue != null &&
          Object.hasOwnProperty.call(message, 'singlePointValue')
        )
          writer
            .uint32(/* id 154389, wireType 1 =*/ 1235113)
            .double(message.singlePointValue);
        if (
          message.priceDisplayFormat != null &&
          Object.hasOwnProperty.call(message, 'priceDisplayFormat')
        )
          writer
            .uint32(/* id 154390, wireType 2 =*/ 1235122)
            .string(message.priceDisplayFormat);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        return writer;
      };

      /**
       * Encodes the specified ResponseGetInstrumentByUnderlying message, length delimited. Does not implicitly {@link rti.ResponseGetInstrumentByUnderlying.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {rti.IResponseGetInstrumentByUnderlying} message ResponseGetInstrumentByUnderlying message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseGetInstrumentByUnderlying.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseGetInstrumentByUnderlying message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseGetInstrumentByUnderlying} ResponseGetInstrumentByUnderlying
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseGetInstrumentByUnderlying.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseGetInstrumentByUnderlying();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 110114:
              message.exchangeSymbol = reader.string();
              break;
            case 100003:
              message.symbolName = reader.string();
              break;
            case 100749:
              message.productCode = reader.string();
              break;
            case 110116:
              message.instrumentType = reader.string();
              break;
            case 101026:
              message.underlyingSymbol = reader.string();
              break;
            case 100067:
              message.expirationDate = reader.string();
              break;
            case 154382:
              message.currency = reader.string();
              break;
            case 100109:
              message.putCallIndicator = reader.string();
              break;
            case 154167:
              message.tickSizeType = reader.string();
              break;
            case 154390:
              message.priceDisplayFormat = reader.string();
              break;
            case 100066:
              message.strikePrice = reader.double();
              break;
            case 154384:
              message.ftoqPrice = reader.double();
              break;
            case 154385:
              message.qtofPrice = reader.double();
              break;
            case 154386:
              message.minQpriceChange = reader.double();
              break;
            case 154387:
              message.minFpriceChange = reader.double();
              break;
            case 154389:
              message.singlePointValue = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseGetInstrumentByUnderlying message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseGetInstrumentByUnderlying} ResponseGetInstrumentByUnderlying
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseGetInstrumentByUnderlying.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseGetInstrumentByUnderlying message.
       * @function verify
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseGetInstrumentByUnderlying.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.exchangeSymbol != null &&
          message.hasOwnProperty('exchangeSymbol')
        )
          if (!$util.isString(message.exchangeSymbol))
            return 'exchangeSymbol: string expected';
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          if (!$util.isString(message.symbolName))
            return 'symbolName: string expected';
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          if (!$util.isString(message.productCode))
            return 'productCode: string expected';
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          if (!$util.isString(message.instrumentType))
            return 'instrumentType: string expected';
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        )
          if (!$util.isString(message.underlyingSymbol))
            return 'underlyingSymbol: string expected';
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          if (!$util.isString(message.expirationDate))
            return 'expirationDate: string expected';
        if (message.currency != null && message.hasOwnProperty('currency'))
          if (!$util.isString(message.currency))
            return 'currency: string expected';
        if (
          message.putCallIndicator != null &&
          message.hasOwnProperty('putCallIndicator')
        )
          if (!$util.isString(message.putCallIndicator))
            return 'putCallIndicator: string expected';
        if (
          message.tickSizeType != null &&
          message.hasOwnProperty('tickSizeType')
        )
          if (!$util.isString(message.tickSizeType))
            return 'tickSizeType: string expected';
        if (
          message.priceDisplayFormat != null &&
          message.hasOwnProperty('priceDisplayFormat')
        )
          if (!$util.isString(message.priceDisplayFormat))
            return 'priceDisplayFormat: string expected';
        if (
          message.strikePrice != null &&
          message.hasOwnProperty('strikePrice')
        )
          if (typeof message.strikePrice !== 'number')
            return 'strikePrice: number expected';
        if (message.ftoqPrice != null && message.hasOwnProperty('ftoqPrice'))
          if (typeof message.ftoqPrice !== 'number')
            return 'ftoqPrice: number expected';
        if (message.qtofPrice != null && message.hasOwnProperty('qtofPrice'))
          if (typeof message.qtofPrice !== 'number')
            return 'qtofPrice: number expected';
        if (
          message.minQpriceChange != null &&
          message.hasOwnProperty('minQpriceChange')
        )
          if (typeof message.minQpriceChange !== 'number')
            return 'minQpriceChange: number expected';
        if (
          message.minFpriceChange != null &&
          message.hasOwnProperty('minFpriceChange')
        )
          if (typeof message.minFpriceChange !== 'number')
            return 'minFpriceChange: number expected';
        if (
          message.singlePointValue != null &&
          message.hasOwnProperty('singlePointValue')
        )
          if (typeof message.singlePointValue !== 'number')
            return 'singlePointValue: number expected';
        return null;
      };

      /**
       * Creates a ResponseGetInstrumentByUnderlying message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseGetInstrumentByUnderlying} ResponseGetInstrumentByUnderlying
       */
      ResponseGetInstrumentByUnderlying.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseGetInstrumentByUnderlying)
          return object;
        var message = new $root.rti.ResponseGetInstrumentByUnderlying();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseGetInstrumentByUnderlying.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseGetInstrumentByUnderlying.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseGetInstrumentByUnderlying.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.exchangeSymbol != null)
          message.exchangeSymbol = String(object.exchangeSymbol);
        if (object.symbolName != null)
          message.symbolName = String(object.symbolName);
        if (object.productCode != null)
          message.productCode = String(object.productCode);
        if (object.instrumentType != null)
          message.instrumentType = String(object.instrumentType);
        if (object.underlyingSymbol != null)
          message.underlyingSymbol = String(object.underlyingSymbol);
        if (object.expirationDate != null)
          message.expirationDate = String(object.expirationDate);
        if (object.currency != null) message.currency = String(object.currency);
        if (object.putCallIndicator != null)
          message.putCallIndicator = String(object.putCallIndicator);
        if (object.tickSizeType != null)
          message.tickSizeType = String(object.tickSizeType);
        if (object.priceDisplayFormat != null)
          message.priceDisplayFormat = String(object.priceDisplayFormat);
        if (object.strikePrice != null)
          message.strikePrice = Number(object.strikePrice);
        if (object.ftoqPrice != null)
          message.ftoqPrice = Number(object.ftoqPrice);
        if (object.qtofPrice != null)
          message.qtofPrice = Number(object.qtofPrice);
        if (object.minQpriceChange != null)
          message.minQpriceChange = Number(object.minQpriceChange);
        if (object.minFpriceChange != null)
          message.minFpriceChange = Number(object.minFpriceChange);
        if (object.singlePointValue != null)
          message.singlePointValue = Number(object.singlePointValue);
        return message;
      };

      /**
       * Creates a plain object from a ResponseGetInstrumentByUnderlying message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {rti.ResponseGetInstrumentByUnderlying} message ResponseGetInstrumentByUnderlying
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseGetInstrumentByUnderlying.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.symbolName = '';
          object.strikePrice = 0;
          object.expirationDate = '';
          object.putCallIndicator = '';
          object.productCode = '';
          object.underlyingSymbol = '';
          object.symbol = '';
          object.exchange = '';
          object.exchangeSymbol = '';
          object.instrumentType = '';
          object.presenceBits = 0;
          object.tickSizeType = '';
          object.currency = '';
          object.ftoqPrice = 0;
          object.qtofPrice = 0;
          object.minQpriceChange = 0;
          object.minFpriceChange = 0;
          object.singlePointValue = 0;
          object.priceDisplayFormat = '';
          object.templateId = 0;
          object.clearBits = 0;
        }
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          object.symbolName = message.symbolName;
        if (
          message.strikePrice != null &&
          message.hasOwnProperty('strikePrice')
        )
          object.strikePrice =
            options.json && !isFinite(message.strikePrice)
              ? String(message.strikePrice)
              : message.strikePrice;
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          object.expirationDate = message.expirationDate;
        if (
          message.putCallIndicator != null &&
          message.hasOwnProperty('putCallIndicator')
        )
          object.putCallIndicator = message.putCallIndicator;
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          object.productCode = message.productCode;
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        )
          object.underlyingSymbol = message.underlyingSymbol;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.exchangeSymbol != null &&
          message.hasOwnProperty('exchangeSymbol')
        )
          object.exchangeSymbol = message.exchangeSymbol;
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          object.instrumentType = message.instrumentType;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (
          message.tickSizeType != null &&
          message.hasOwnProperty('tickSizeType')
        )
          object.tickSizeType = message.tickSizeType;
        if (message.currency != null && message.hasOwnProperty('currency'))
          object.currency = message.currency;
        if (message.ftoqPrice != null && message.hasOwnProperty('ftoqPrice'))
          object.ftoqPrice =
            options.json && !isFinite(message.ftoqPrice)
              ? String(message.ftoqPrice)
              : message.ftoqPrice;
        if (message.qtofPrice != null && message.hasOwnProperty('qtofPrice'))
          object.qtofPrice =
            options.json && !isFinite(message.qtofPrice)
              ? String(message.qtofPrice)
              : message.qtofPrice;
        if (
          message.minQpriceChange != null &&
          message.hasOwnProperty('minQpriceChange')
        )
          object.minQpriceChange =
            options.json && !isFinite(message.minQpriceChange)
              ? String(message.minQpriceChange)
              : message.minQpriceChange;
        if (
          message.minFpriceChange != null &&
          message.hasOwnProperty('minFpriceChange')
        )
          object.minFpriceChange =
            options.json && !isFinite(message.minFpriceChange)
              ? String(message.minFpriceChange)
              : message.minFpriceChange;
        if (
          message.singlePointValue != null &&
          message.hasOwnProperty('singlePointValue')
        )
          object.singlePointValue =
            options.json && !isFinite(message.singlePointValue)
              ? String(message.singlePointValue)
              : message.singlePointValue;
        if (
          message.priceDisplayFormat != null &&
          message.hasOwnProperty('priceDisplayFormat')
        )
          object.priceDisplayFormat = message.priceDisplayFormat;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        return object;
      };

      /**
       * Converts this ResponseGetInstrumentByUnderlying to JSON.
       * @function toJSON
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseGetInstrumentByUnderlying.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseGetInstrumentByUnderlying
       * @function getTypeUrl
       * @memberof rti.ResponseGetInstrumentByUnderlying
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseGetInstrumentByUnderlying.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseGetInstrumentByUnderlying';
      };

      /**
       * PresenceBits enum.
       * @name rti.ResponseGetInstrumentByUnderlying.PresenceBits
       * @enum {number}
       * @property {number} EXCHANGE_SYMBOL=1 EXCHANGE_SYMBOL value
       * @property {number} SYMBOL_NAME=2 SYMBOL_NAME value
       * @property {number} PRODUCT_CODE=4 PRODUCT_CODE value
       * @property {number} INSTRUMENT_TYPE=8 INSTRUMENT_TYPE value
       * @property {number} UNDERLYING_SYMBOL=16 UNDERLYING_SYMBOL value
       * @property {number} EXPIRATION_DATE=32 EXPIRATION_DATE value
       * @property {number} CURRENCY=64 CURRENCY value
       * @property {number} PUT_CALL_INDICATOR=128 PUT_CALL_INDICATOR value
       * @property {number} STRIKE_PRICE=256 STRIKE_PRICE value
       * @property {number} FPRICE_TO_QPRICE=512 FPRICE_TO_QPRICE value
       * @property {number} QPRICE_TO_FPRICE=1024 QPRICE_TO_FPRICE value
       * @property {number} MIN_QPRICE_CHANGE=2048 MIN_QPRICE_CHANGE value
       * @property {number} MIN_FRPICE_CHANGE=4096 MIN_FRPICE_CHANGE value
       * @property {number} SINGLE_POINT_VALUE=8192 SINGLE_POINT_VALUE value
       * @property {number} TICK_SIZE_TYPE=16384 TICK_SIZE_TYPE value
       * @property {number} PRICE_DISPLAY_FORMAT=32768 PRICE_DISPLAY_FORMAT value
       */
      ResponseGetInstrumentByUnderlying.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'EXCHANGE_SYMBOL')] = 1;
        values[(valuesById[2] = 'SYMBOL_NAME')] = 2;
        values[(valuesById[4] = 'PRODUCT_CODE')] = 4;
        values[(valuesById[8] = 'INSTRUMENT_TYPE')] = 8;
        values[(valuesById[16] = 'UNDERLYING_SYMBOL')] = 16;
        values[(valuesById[32] = 'EXPIRATION_DATE')] = 32;
        values[(valuesById[64] = 'CURRENCY')] = 64;
        values[(valuesById[128] = 'PUT_CALL_INDICATOR')] = 128;
        values[(valuesById[256] = 'STRIKE_PRICE')] = 256;
        values[(valuesById[512] = 'FPRICE_TO_QPRICE')] = 512;
        values[(valuesById[1024] = 'QPRICE_TO_FPRICE')] = 1024;
        values[(valuesById[2048] = 'MIN_QPRICE_CHANGE')] = 2048;
        values[(valuesById[4096] = 'MIN_FRPICE_CHANGE')] = 4096;
        values[(valuesById[8192] = 'SINGLE_POINT_VALUE')] = 8192;
        values[(valuesById[16384] = 'TICK_SIZE_TYPE')] = 16384;
        values[(valuesById[32768] = 'PRICE_DISPLAY_FORMAT')] = 32768;
        return values;
      })();

      return ResponseGetInstrumentByUnderlying;
    })();

    rti.ResponseGetInstrumentByUnderlyingKeys = (function () {
      /**
       * Properties of a ResponseGetInstrumentByUnderlyingKeys.
       * @memberof rti
       * @interface IResponseGetInstrumentByUnderlyingKeys
       * @property {number} templateId ResponseGetInstrumentByUnderlyingKeys templateId
       * @property {Array.<string>|null} [userMsg] ResponseGetInstrumentByUnderlyingKeys userMsg
       * @property {Array.<string>|null} [rpCode] ResponseGetInstrumentByUnderlyingKeys rpCode
       * @property {Array.<string>|null} [underlyingSymbol] ResponseGetInstrumentByUnderlyingKeys underlyingSymbol
       * @property {Array.<string>|null} [exchange] ResponseGetInstrumentByUnderlyingKeys exchange
       * @property {Array.<string>|null} [expirationDate] ResponseGetInstrumentByUnderlyingKeys expirationDate
       */

      /**
       * Constructs a new ResponseGetInstrumentByUnderlyingKeys.
       * @memberof rti
       * @classdesc Represents a ResponseGetInstrumentByUnderlyingKeys.
       * @implements IResponseGetInstrumentByUnderlyingKeys
       * @constructor
       * @param {rti.IResponseGetInstrumentByUnderlyingKeys=} [properties] Properties to set
       */
      function ResponseGetInstrumentByUnderlyingKeys(properties) {
        this.userMsg = [];
        this.rpCode = [];
        this.underlyingSymbol = [];
        this.exchange = [];
        this.expirationDate = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseGetInstrumentByUnderlyingKeys templateId.
       * @member {number} templateId
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @instance
       */
      ResponseGetInstrumentByUnderlyingKeys.prototype.templateId = 0;

      /**
       * ResponseGetInstrumentByUnderlyingKeys userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @instance
       */
      ResponseGetInstrumentByUnderlyingKeys.prototype.userMsg =
        $util.emptyArray;

      /**
       * ResponseGetInstrumentByUnderlyingKeys rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @instance
       */
      ResponseGetInstrumentByUnderlyingKeys.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseGetInstrumentByUnderlyingKeys underlyingSymbol.
       * @member {Array.<string>} underlyingSymbol
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @instance
       */
      ResponseGetInstrumentByUnderlyingKeys.prototype.underlyingSymbol =
        $util.emptyArray;

      /**
       * ResponseGetInstrumentByUnderlyingKeys exchange.
       * @member {Array.<string>} exchange
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @instance
       */
      ResponseGetInstrumentByUnderlyingKeys.prototype.exchange =
        $util.emptyArray;

      /**
       * ResponseGetInstrumentByUnderlyingKeys expirationDate.
       * @member {Array.<string>} expirationDate
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @instance
       */
      ResponseGetInstrumentByUnderlyingKeys.prototype.expirationDate =
        $util.emptyArray;

      /**
       * Creates a new ResponseGetInstrumentByUnderlyingKeys instance using the specified properties.
       * @function create
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {rti.IResponseGetInstrumentByUnderlyingKeys=} [properties] Properties to set
       * @returns {rti.ResponseGetInstrumentByUnderlyingKeys} ResponseGetInstrumentByUnderlyingKeys instance
       */
      ResponseGetInstrumentByUnderlyingKeys.create = function create(
        properties,
      ) {
        return new ResponseGetInstrumentByUnderlyingKeys(properties);
      };

      /**
       * Encodes the specified ResponseGetInstrumentByUnderlyingKeys message. Does not implicitly {@link rti.ResponseGetInstrumentByUnderlyingKeys.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {rti.IResponseGetInstrumentByUnderlyingKeys} message ResponseGetInstrumentByUnderlyingKeys message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseGetInstrumentByUnderlyingKeys.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.expirationDate != null && message.expirationDate.length)
          for (var i = 0; i < message.expirationDate.length; ++i)
            writer
              .uint32(/* id 100067, wireType 2 =*/ 800538)
              .string(message.expirationDate[i]);
        if (message.underlyingSymbol != null && message.underlyingSymbol.length)
          for (var i = 0; i < message.underlyingSymbol.length; ++i)
            writer
              .uint32(/* id 101026, wireType 2 =*/ 808210)
              .string(message.underlyingSymbol[i]);
        if (message.exchange != null && message.exchange.length)
          for (var i = 0; i < message.exchange.length; ++i)
            writer
              .uint32(/* id 110101, wireType 2 =*/ 880810)
              .string(message.exchange[i]);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseGetInstrumentByUnderlyingKeys message, length delimited. Does not implicitly {@link rti.ResponseGetInstrumentByUnderlyingKeys.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {rti.IResponseGetInstrumentByUnderlyingKeys} message ResponseGetInstrumentByUnderlyingKeys message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseGetInstrumentByUnderlyingKeys.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseGetInstrumentByUnderlyingKeys message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseGetInstrumentByUnderlyingKeys} ResponseGetInstrumentByUnderlyingKeys
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseGetInstrumentByUnderlyingKeys.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseGetInstrumentByUnderlyingKeys();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 101026:
              if (
                !(message.underlyingSymbol && message.underlyingSymbol.length)
              )
                message.underlyingSymbol = [];
              message.underlyingSymbol.push(reader.string());
              break;
            case 110101:
              if (!(message.exchange && message.exchange.length))
                message.exchange = [];
              message.exchange.push(reader.string());
              break;
            case 100067:
              if (!(message.expirationDate && message.expirationDate.length))
                message.expirationDate = [];
              message.expirationDate.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseGetInstrumentByUnderlyingKeys message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseGetInstrumentByUnderlyingKeys} ResponseGetInstrumentByUnderlyingKeys
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseGetInstrumentByUnderlyingKeys.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseGetInstrumentByUnderlyingKeys message.
       * @function verify
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseGetInstrumentByUnderlyingKeys.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        ) {
          if (!Array.isArray(message.underlyingSymbol))
            return 'underlyingSymbol: array expected';
          for (var i = 0; i < message.underlyingSymbol.length; ++i)
            if (!$util.isString(message.underlyingSymbol[i]))
              return 'underlyingSymbol: string[] expected';
        }
        if (message.exchange != null && message.hasOwnProperty('exchange')) {
          if (!Array.isArray(message.exchange))
            return 'exchange: array expected';
          for (var i = 0; i < message.exchange.length; ++i)
            if (!$util.isString(message.exchange[i]))
              return 'exchange: string[] expected';
        }
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        ) {
          if (!Array.isArray(message.expirationDate))
            return 'expirationDate: array expected';
          for (var i = 0; i < message.expirationDate.length; ++i)
            if (!$util.isString(message.expirationDate[i]))
              return 'expirationDate: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseGetInstrumentByUnderlyingKeys message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseGetInstrumentByUnderlyingKeys} ResponseGetInstrumentByUnderlyingKeys
       */
      ResponseGetInstrumentByUnderlyingKeys.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseGetInstrumentByUnderlyingKeys)
          return object;
        var message = new $root.rti.ResponseGetInstrumentByUnderlyingKeys();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseGetInstrumentByUnderlyingKeys.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseGetInstrumentByUnderlyingKeys.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.underlyingSymbol) {
          if (!Array.isArray(object.underlyingSymbol))
            throw TypeError(
              '.rti.ResponseGetInstrumentByUnderlyingKeys.underlyingSymbol: array expected',
            );
          message.underlyingSymbol = [];
          for (var i = 0; i < object.underlyingSymbol.length; ++i)
            message.underlyingSymbol[i] = String(object.underlyingSymbol[i]);
        }
        if (object.exchange) {
          if (!Array.isArray(object.exchange))
            throw TypeError(
              '.rti.ResponseGetInstrumentByUnderlyingKeys.exchange: array expected',
            );
          message.exchange = [];
          for (var i = 0; i < object.exchange.length; ++i)
            message.exchange[i] = String(object.exchange[i]);
        }
        if (object.expirationDate) {
          if (!Array.isArray(object.expirationDate))
            throw TypeError(
              '.rti.ResponseGetInstrumentByUnderlyingKeys.expirationDate: array expected',
            );
          message.expirationDate = [];
          for (var i = 0; i < object.expirationDate.length; ++i)
            message.expirationDate[i] = String(object.expirationDate[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseGetInstrumentByUnderlyingKeys message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {rti.ResponseGetInstrumentByUnderlyingKeys} message ResponseGetInstrumentByUnderlyingKeys
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseGetInstrumentByUnderlyingKeys.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.expirationDate = [];
          object.underlyingSymbol = [];
          object.exchange = [];
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.expirationDate && message.expirationDate.length) {
          object.expirationDate = [];
          for (var j = 0; j < message.expirationDate.length; ++j)
            object.expirationDate[j] = message.expirationDate[j];
        }
        if (message.underlyingSymbol && message.underlyingSymbol.length) {
          object.underlyingSymbol = [];
          for (var j = 0; j < message.underlyingSymbol.length; ++j)
            object.underlyingSymbol[j] = message.underlyingSymbol[j];
        }
        if (message.exchange && message.exchange.length) {
          object.exchange = [];
          for (var j = 0; j < message.exchange.length; ++j)
            object.exchange[j] = message.exchange[j];
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseGetInstrumentByUnderlyingKeys to JSON.
       * @function toJSON
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseGetInstrumentByUnderlyingKeys.prototype.toJSON =
        function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

      /**
       * Gets the default type url for ResponseGetInstrumentByUnderlyingKeys
       * @function getTypeUrl
       * @memberof rti.ResponseGetInstrumentByUnderlyingKeys
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseGetInstrumentByUnderlyingKeys.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseGetInstrumentByUnderlyingKeys';
      };

      return ResponseGetInstrumentByUnderlyingKeys;
    })();

    rti.RequestMarketDataUpdateByUnderlying = (function () {
      /**
       * Properties of a RequestMarketDataUpdateByUnderlying.
       * @memberof rti
       * @interface IRequestMarketDataUpdateByUnderlying
       * @property {number} templateId RequestMarketDataUpdateByUnderlying templateId
       * @property {Array.<string>|null} [userMsg] RequestMarketDataUpdateByUnderlying userMsg
       * @property {string|null} [underlyingSymbol] RequestMarketDataUpdateByUnderlying underlyingSymbol
       * @property {string|null} [exchange] RequestMarketDataUpdateByUnderlying exchange
       * @property {string|null} [expirationDate] RequestMarketDataUpdateByUnderlying expirationDate
       * @property {rti.RequestMarketDataUpdateByUnderlying.Request|null} [request] RequestMarketDataUpdateByUnderlying request
       * @property {number|null} [updateBits] RequestMarketDataUpdateByUnderlying updateBits
       */

      /**
       * Constructs a new RequestMarketDataUpdateByUnderlying.
       * @memberof rti
       * @classdesc Represents a RequestMarketDataUpdateByUnderlying.
       * @implements IRequestMarketDataUpdateByUnderlying
       * @constructor
       * @param {rti.IRequestMarketDataUpdateByUnderlying=} [properties] Properties to set
       */
      function RequestMarketDataUpdateByUnderlying(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestMarketDataUpdateByUnderlying templateId.
       * @member {number} templateId
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @instance
       */
      RequestMarketDataUpdateByUnderlying.prototype.templateId = 0;

      /**
       * RequestMarketDataUpdateByUnderlying userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @instance
       */
      RequestMarketDataUpdateByUnderlying.prototype.userMsg = $util.emptyArray;

      /**
       * RequestMarketDataUpdateByUnderlying underlyingSymbol.
       * @member {string} underlyingSymbol
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @instance
       */
      RequestMarketDataUpdateByUnderlying.prototype.underlyingSymbol = '';

      /**
       * RequestMarketDataUpdateByUnderlying exchange.
       * @member {string} exchange
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @instance
       */
      RequestMarketDataUpdateByUnderlying.prototype.exchange = '';

      /**
       * RequestMarketDataUpdateByUnderlying expirationDate.
       * @member {string} expirationDate
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @instance
       */
      RequestMarketDataUpdateByUnderlying.prototype.expirationDate = '';

      /**
       * RequestMarketDataUpdateByUnderlying request.
       * @member {rti.RequestMarketDataUpdateByUnderlying.Request} request
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @instance
       */
      RequestMarketDataUpdateByUnderlying.prototype.request = 1;

      /**
       * RequestMarketDataUpdateByUnderlying updateBits.
       * @member {number} updateBits
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @instance
       */
      RequestMarketDataUpdateByUnderlying.prototype.updateBits = 0;

      /**
       * Creates a new RequestMarketDataUpdateByUnderlying instance using the specified properties.
       * @function create
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {rti.IRequestMarketDataUpdateByUnderlying=} [properties] Properties to set
       * @returns {rti.RequestMarketDataUpdateByUnderlying} RequestMarketDataUpdateByUnderlying instance
       */
      RequestMarketDataUpdateByUnderlying.create = function create(properties) {
        return new RequestMarketDataUpdateByUnderlying(properties);
      };

      /**
       * Encodes the specified RequestMarketDataUpdateByUnderlying message. Does not implicitly {@link rti.RequestMarketDataUpdateByUnderlying.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {rti.IRequestMarketDataUpdateByUnderlying} message RequestMarketDataUpdateByUnderlying message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestMarketDataUpdateByUnderlying.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (
          message.request != null &&
          Object.hasOwnProperty.call(message, 'request')
        )
          writer
            .uint32(/* id 100000, wireType 0 =*/ 800000)
            .int32(message.request);
        if (
          message.expirationDate != null &&
          Object.hasOwnProperty.call(message, 'expirationDate')
        )
          writer
            .uint32(/* id 100067, wireType 2 =*/ 800538)
            .string(message.expirationDate);
        if (
          message.underlyingSymbol != null &&
          Object.hasOwnProperty.call(message, 'underlyingSymbol')
        )
          writer
            .uint32(/* id 101026, wireType 2 =*/ 808210)
            .string(message.underlyingSymbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.updateBits != null &&
          Object.hasOwnProperty.call(message, 'updateBits')
        )
          writer
            .uint32(/* id 154211, wireType 0 =*/ 1233688)
            .uint32(message.updateBits);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestMarketDataUpdateByUnderlying message, length delimited. Does not implicitly {@link rti.RequestMarketDataUpdateByUnderlying.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {rti.IRequestMarketDataUpdateByUnderlying} message RequestMarketDataUpdateByUnderlying message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestMarketDataUpdateByUnderlying.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestMarketDataUpdateByUnderlying message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestMarketDataUpdateByUnderlying} RequestMarketDataUpdateByUnderlying
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestMarketDataUpdateByUnderlying.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestMarketDataUpdateByUnderlying();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 101026:
              message.underlyingSymbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100067:
              message.expirationDate = reader.string();
              break;
            case 100000:
              message.request = reader.int32();
              break;
            case 154211:
              message.updateBits = reader.uint32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestMarketDataUpdateByUnderlying message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestMarketDataUpdateByUnderlying} RequestMarketDataUpdateByUnderlying
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestMarketDataUpdateByUnderlying.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestMarketDataUpdateByUnderlying message.
       * @function verify
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestMarketDataUpdateByUnderlying.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        )
          if (!$util.isString(message.underlyingSymbol))
            return 'underlyingSymbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          if (!$util.isString(message.expirationDate))
            return 'expirationDate: string expected';
        if (message.request != null && message.hasOwnProperty('request'))
          switch (message.request) {
            default:
              return 'request: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.updateBits != null && message.hasOwnProperty('updateBits'))
          if (!$util.isInteger(message.updateBits))
            return 'updateBits: integer expected';
        return null;
      };

      /**
       * Creates a RequestMarketDataUpdateByUnderlying message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestMarketDataUpdateByUnderlying} RequestMarketDataUpdateByUnderlying
       */
      RequestMarketDataUpdateByUnderlying.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.RequestMarketDataUpdateByUnderlying)
          return object;
        var message = new $root.rti.RequestMarketDataUpdateByUnderlying();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestMarketDataUpdateByUnderlying.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.underlyingSymbol != null)
          message.underlyingSymbol = String(object.underlyingSymbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.expirationDate != null)
          message.expirationDate = String(object.expirationDate);
        switch (object.request) {
          case 'SUBSCRIBE':
          case 1:
            message.request = 1;
            break;
          case 'UNSUBSCRIBE':
          case 2:
            message.request = 2;
            break;
        }
        if (object.updateBits != null)
          message.updateBits = object.updateBits >>> 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestMarketDataUpdateByUnderlying message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {rti.RequestMarketDataUpdateByUnderlying} message RequestMarketDataUpdateByUnderlying
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestMarketDataUpdateByUnderlying.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.request = options.enums === String ? 'SUBSCRIBE' : 1;
          object.expirationDate = '';
          object.underlyingSymbol = '';
          object.exchange = '';
          object.updateBits = 0;
          object.templateId = 0;
        }
        if (message.request != null && message.hasOwnProperty('request'))
          object.request =
            options.enums === String
              ? $root.rti.RequestMarketDataUpdateByUnderlying.Request[
                  message.request
                ]
              : message.request;
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          object.expirationDate = message.expirationDate;
        if (
          message.underlyingSymbol != null &&
          message.hasOwnProperty('underlyingSymbol')
        )
          object.underlyingSymbol = message.underlyingSymbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.updateBits != null && message.hasOwnProperty('updateBits'))
          object.updateBits = message.updateBits;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestMarketDataUpdateByUnderlying to JSON.
       * @function toJSON
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestMarketDataUpdateByUnderlying.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestMarketDataUpdateByUnderlying
       * @function getTypeUrl
       * @memberof rti.RequestMarketDataUpdateByUnderlying
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestMarketDataUpdateByUnderlying.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestMarketDataUpdateByUnderlying';
      };

      /**
       * UpdateBits enum.
       * @name rti.RequestMarketDataUpdateByUnderlying.UpdateBits
       * @enum {number}
       * @property {number} LAST_TRADE=1 LAST_TRADE value
       * @property {number} BBO=2 BBO value
       * @property {number} ORDER_BOOK=4 ORDER_BOOK value
       * @property {number} OPEN=8 OPEN value
       * @property {number} OPENING_INDICATOR=16 OPENING_INDICATOR value
       * @property {number} HIGH_LOW=32 HIGH_LOW value
       * @property {number} HIGH_BID_LOW_ASK=64 HIGH_BID_LOW_ASK value
       * @property {number} CLOSE=128 CLOSE value
       * @property {number} CLOSING_INDICATOR=256 CLOSING_INDICATOR value
       * @property {number} SETTLEMENT=512 SETTLEMENT value
       * @property {number} MARKET_MODE=1024 MARKET_MODE value
       * @property {number} OPEN_INTEREST=2048 OPEN_INTEREST value
       * @property {number} MARGIN_RATE=4096 MARGIN_RATE value
       * @property {number} HIGH_PRICE_LIMIT=8192 HIGH_PRICE_LIMIT value
       * @property {number} LOW_PRICE_LIMIT=16384 LOW_PRICE_LIMIT value
       * @property {number} PROJECTED_SETTLEMENT=32768 PROJECTED_SETTLEMENT value
       */
      RequestMarketDataUpdateByUnderlying.UpdateBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LAST_TRADE')] = 1;
        values[(valuesById[2] = 'BBO')] = 2;
        values[(valuesById[4] = 'ORDER_BOOK')] = 4;
        values[(valuesById[8] = 'OPEN')] = 8;
        values[(valuesById[16] = 'OPENING_INDICATOR')] = 16;
        values[(valuesById[32] = 'HIGH_LOW')] = 32;
        values[(valuesById[64] = 'HIGH_BID_LOW_ASK')] = 64;
        values[(valuesById[128] = 'CLOSE')] = 128;
        values[(valuesById[256] = 'CLOSING_INDICATOR')] = 256;
        values[(valuesById[512] = 'SETTLEMENT')] = 512;
        values[(valuesById[1024] = 'MARKET_MODE')] = 1024;
        values[(valuesById[2048] = 'OPEN_INTEREST')] = 2048;
        values[(valuesById[4096] = 'MARGIN_RATE')] = 4096;
        values[(valuesById[8192] = 'HIGH_PRICE_LIMIT')] = 8192;
        values[(valuesById[16384] = 'LOW_PRICE_LIMIT')] = 16384;
        values[(valuesById[32768] = 'PROJECTED_SETTLEMENT')] = 32768;
        return values;
      })();

      /**
       * Request enum.
       * @name rti.RequestMarketDataUpdateByUnderlying.Request
       * @enum {number}
       * @property {number} SUBSCRIBE=1 SUBSCRIBE value
       * @property {number} UNSUBSCRIBE=2 UNSUBSCRIBE value
       */
      RequestMarketDataUpdateByUnderlying.Request = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SUBSCRIBE')] = 1;
        values[(valuesById[2] = 'UNSUBSCRIBE')] = 2;
        return values;
      })();

      return RequestMarketDataUpdateByUnderlying;
    })();

    rti.ResponseMarketDataUpdateByUnderlying = (function () {
      /**
       * Properties of a ResponseMarketDataUpdateByUnderlying.
       * @memberof rti
       * @interface IResponseMarketDataUpdateByUnderlying
       * @property {number} templateId ResponseMarketDataUpdateByUnderlying templateId
       * @property {Array.<string>|null} [userMsg] ResponseMarketDataUpdateByUnderlying userMsg
       * @property {Array.<string>|null} [rpCode] ResponseMarketDataUpdateByUnderlying rpCode
       */

      /**
       * Constructs a new ResponseMarketDataUpdateByUnderlying.
       * @memberof rti
       * @classdesc Represents a ResponseMarketDataUpdateByUnderlying.
       * @implements IResponseMarketDataUpdateByUnderlying
       * @constructor
       * @param {rti.IResponseMarketDataUpdateByUnderlying=} [properties] Properties to set
       */
      function ResponseMarketDataUpdateByUnderlying(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseMarketDataUpdateByUnderlying templateId.
       * @member {number} templateId
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @instance
       */
      ResponseMarketDataUpdateByUnderlying.prototype.templateId = 0;

      /**
       * ResponseMarketDataUpdateByUnderlying userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @instance
       */
      ResponseMarketDataUpdateByUnderlying.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseMarketDataUpdateByUnderlying rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @instance
       */
      ResponseMarketDataUpdateByUnderlying.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseMarketDataUpdateByUnderlying instance using the specified properties.
       * @function create
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {rti.IResponseMarketDataUpdateByUnderlying=} [properties] Properties to set
       * @returns {rti.ResponseMarketDataUpdateByUnderlying} ResponseMarketDataUpdateByUnderlying instance
       */
      ResponseMarketDataUpdateByUnderlying.create = function create(
        properties,
      ) {
        return new ResponseMarketDataUpdateByUnderlying(properties);
      };

      /**
       * Encodes the specified ResponseMarketDataUpdateByUnderlying message. Does not implicitly {@link rti.ResponseMarketDataUpdateByUnderlying.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {rti.IResponseMarketDataUpdateByUnderlying} message ResponseMarketDataUpdateByUnderlying message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseMarketDataUpdateByUnderlying.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseMarketDataUpdateByUnderlying message, length delimited. Does not implicitly {@link rti.ResponseMarketDataUpdateByUnderlying.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {rti.IResponseMarketDataUpdateByUnderlying} message ResponseMarketDataUpdateByUnderlying message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseMarketDataUpdateByUnderlying.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseMarketDataUpdateByUnderlying message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseMarketDataUpdateByUnderlying} ResponseMarketDataUpdateByUnderlying
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseMarketDataUpdateByUnderlying.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseMarketDataUpdateByUnderlying();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseMarketDataUpdateByUnderlying message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseMarketDataUpdateByUnderlying} ResponseMarketDataUpdateByUnderlying
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseMarketDataUpdateByUnderlying.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseMarketDataUpdateByUnderlying message.
       * @function verify
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseMarketDataUpdateByUnderlying.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseMarketDataUpdateByUnderlying message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseMarketDataUpdateByUnderlying} ResponseMarketDataUpdateByUnderlying
       */
      ResponseMarketDataUpdateByUnderlying.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseMarketDataUpdateByUnderlying)
          return object;
        var message = new $root.rti.ResponseMarketDataUpdateByUnderlying();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseMarketDataUpdateByUnderlying.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseMarketDataUpdateByUnderlying.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseMarketDataUpdateByUnderlying message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {rti.ResponseMarketDataUpdateByUnderlying} message ResponseMarketDataUpdateByUnderlying
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseMarketDataUpdateByUnderlying.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseMarketDataUpdateByUnderlying to JSON.
       * @function toJSON
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseMarketDataUpdateByUnderlying.prototype.toJSON =
        function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

      /**
       * Gets the default type url for ResponseMarketDataUpdateByUnderlying
       * @function getTypeUrl
       * @memberof rti.ResponseMarketDataUpdateByUnderlying
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseMarketDataUpdateByUnderlying.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseMarketDataUpdateByUnderlying';
      };

      return ResponseMarketDataUpdateByUnderlying;
    })();

    rti.RequestSearchSymbols = (function () {
      /**
       * Properties of a RequestSearchSymbols.
       * @memberof rti
       * @interface IRequestSearchSymbols
       * @property {number} templateId RequestSearchSymbols templateId
       * @property {Array.<string>|null} [userMsg] RequestSearchSymbols userMsg
       * @property {string|null} [searchText] RequestSearchSymbols searchText
       * @property {string|null} [exchange] RequestSearchSymbols exchange
       * @property {string|null} [productCode] RequestSearchSymbols productCode
       * @property {rti.RequestSearchSymbols.InstrumentType|null} [instrumentType] RequestSearchSymbols instrumentType
       * @property {rti.RequestSearchSymbols.Pattern|null} [pattern] RequestSearchSymbols pattern
       */

      /**
       * Constructs a new RequestSearchSymbols.
       * @memberof rti
       * @classdesc Represents a RequestSearchSymbols.
       * @implements IRequestSearchSymbols
       * @constructor
       * @param {rti.IRequestSearchSymbols=} [properties] Properties to set
       */
      function RequestSearchSymbols(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestSearchSymbols templateId.
       * @member {number} templateId
       * @memberof rti.RequestSearchSymbols
       * @instance
       */
      RequestSearchSymbols.prototype.templateId = 0;

      /**
       * RequestSearchSymbols userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestSearchSymbols
       * @instance
       */
      RequestSearchSymbols.prototype.userMsg = $util.emptyArray;

      /**
       * RequestSearchSymbols searchText.
       * @member {string} searchText
       * @memberof rti.RequestSearchSymbols
       * @instance
       */
      RequestSearchSymbols.prototype.searchText = '';

      /**
       * RequestSearchSymbols exchange.
       * @member {string} exchange
       * @memberof rti.RequestSearchSymbols
       * @instance
       */
      RequestSearchSymbols.prototype.exchange = '';

      /**
       * RequestSearchSymbols productCode.
       * @member {string} productCode
       * @memberof rti.RequestSearchSymbols
       * @instance
       */
      RequestSearchSymbols.prototype.productCode = '';

      /**
       * RequestSearchSymbols instrumentType.
       * @member {rti.RequestSearchSymbols.InstrumentType} instrumentType
       * @memberof rti.RequestSearchSymbols
       * @instance
       */
      RequestSearchSymbols.prototype.instrumentType = 1;

      /**
       * RequestSearchSymbols pattern.
       * @member {rti.RequestSearchSymbols.Pattern} pattern
       * @memberof rti.RequestSearchSymbols
       * @instance
       */
      RequestSearchSymbols.prototype.pattern = 1;

      /**
       * Creates a new RequestSearchSymbols instance using the specified properties.
       * @function create
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {rti.IRequestSearchSymbols=} [properties] Properties to set
       * @returns {rti.RequestSearchSymbols} RequestSearchSymbols instance
       */
      RequestSearchSymbols.create = function create(properties) {
        return new RequestSearchSymbols(properties);
      };

      /**
       * Encodes the specified RequestSearchSymbols message. Does not implicitly {@link rti.RequestSearchSymbols.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {rti.IRequestSearchSymbols} message RequestSearchSymbols message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestSearchSymbols.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.productCode != null &&
          Object.hasOwnProperty.call(message, 'productCode')
        )
          writer
            .uint32(/* id 100749, wireType 2 =*/ 805994)
            .string(message.productCode);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.instrumentType != null &&
          Object.hasOwnProperty.call(message, 'instrumentType')
        )
          writer
            .uint32(/* id 110116, wireType 0 =*/ 880928)
            .int32(message.instrumentType);
        if (
          message.searchText != null &&
          Object.hasOwnProperty.call(message, 'searchText')
        )
          writer
            .uint32(/* id 120008, wireType 2 =*/ 960066)
            .string(message.searchText);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.pattern != null &&
          Object.hasOwnProperty.call(message, 'pattern')
        )
          writer
            .uint32(/* id 155008, wireType 0 =*/ 1240064)
            .int32(message.pattern);
        return writer;
      };

      /**
       * Encodes the specified RequestSearchSymbols message, length delimited. Does not implicitly {@link rti.RequestSearchSymbols.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {rti.IRequestSearchSymbols} message RequestSearchSymbols message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestSearchSymbols.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestSearchSymbols message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestSearchSymbols} RequestSearchSymbols
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestSearchSymbols.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestSearchSymbols();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 120008:
              message.searchText = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100749:
              message.productCode = reader.string();
              break;
            case 110116:
              message.instrumentType = reader.int32();
              break;
            case 155008:
              message.pattern = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestSearchSymbols message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestSearchSymbols} RequestSearchSymbols
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestSearchSymbols.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestSearchSymbols message.
       * @function verify
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestSearchSymbols.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.searchText != null && message.hasOwnProperty('searchText'))
          if (!$util.isString(message.searchText))
            return 'searchText: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          if (!$util.isString(message.productCode))
            return 'productCode: string expected';
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          switch (message.instrumentType) {
            default:
              return 'instrumentType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
              break;
          }
        if (message.pattern != null && message.hasOwnProperty('pattern'))
          switch (message.pattern) {
            default:
              return 'pattern: enum value expected';
            case 1:
            case 2:
              break;
          }
        return null;
      };

      /**
       * Creates a RequestSearchSymbols message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestSearchSymbols} RequestSearchSymbols
       */
      RequestSearchSymbols.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestSearchSymbols) return object;
        var message = new $root.rti.RequestSearchSymbols();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestSearchSymbols.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.searchText != null)
          message.searchText = String(object.searchText);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.productCode != null)
          message.productCode = String(object.productCode);
        switch (object.instrumentType) {
          case 'FUTURE':
          case 1:
            message.instrumentType = 1;
            break;
          case 'FUTURE_OPTION':
          case 2:
            message.instrumentType = 2;
            break;
          case 'FUTURE_STRATEGY':
          case 3:
            message.instrumentType = 3;
            break;
          case 'EQUITY':
          case 4:
            message.instrumentType = 4;
            break;
          case 'EQUITY_OPTION':
          case 5:
            message.instrumentType = 5;
            break;
          case 'EQUITY_STRATEGY':
          case 6:
            message.instrumentType = 6;
            break;
          case 'INDEX':
          case 7:
            message.instrumentType = 7;
            break;
          case 'INDEX_OPTION':
          case 8:
            message.instrumentType = 8;
            break;
          case 'SPREAD':
          case 9:
            message.instrumentType = 9;
            break;
          case 'SYNTHETIC':
          case 10:
            message.instrumentType = 10;
            break;
        }
        switch (object.pattern) {
          case 'EQUALS':
          case 1:
            message.pattern = 1;
            break;
          case 'CONTAINS':
          case 2:
            message.pattern = 2;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestSearchSymbols message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {rti.RequestSearchSymbols} message RequestSearchSymbols
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestSearchSymbols.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.productCode = '';
          object.exchange = '';
          object.instrumentType = options.enums === String ? 'FUTURE' : 1;
          object.searchText = '';
          object.templateId = 0;
          object.pattern = options.enums === String ? 'EQUALS' : 1;
        }
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          object.productCode = message.productCode;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          object.instrumentType =
            options.enums === String
              ? $root.rti.RequestSearchSymbols.InstrumentType[
                  message.instrumentType
                ]
              : message.instrumentType;
        if (message.searchText != null && message.hasOwnProperty('searchText'))
          object.searchText = message.searchText;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.pattern != null && message.hasOwnProperty('pattern'))
          object.pattern =
            options.enums === String
              ? $root.rti.RequestSearchSymbols.Pattern[message.pattern]
              : message.pattern;
        return object;
      };

      /**
       * Converts this RequestSearchSymbols to JSON.
       * @function toJSON
       * @memberof rti.RequestSearchSymbols
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestSearchSymbols.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestSearchSymbols
       * @function getTypeUrl
       * @memberof rti.RequestSearchSymbols
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestSearchSymbols.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestSearchSymbols';
      };

      /**
       * Pattern enum.
       * @name rti.RequestSearchSymbols.Pattern
       * @enum {number}
       * @property {number} EQUALS=1 EQUALS value
       * @property {number} CONTAINS=2 CONTAINS value
       */
      RequestSearchSymbols.Pattern = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'EQUALS')] = 1;
        values[(valuesById[2] = 'CONTAINS')] = 2;
        return values;
      })();

      /**
       * InstrumentType enum.
       * @name rti.RequestSearchSymbols.InstrumentType
       * @enum {number}
       * @property {number} FUTURE=1 FUTURE value
       * @property {number} FUTURE_OPTION=2 FUTURE_OPTION value
       * @property {number} FUTURE_STRATEGY=3 FUTURE_STRATEGY value
       * @property {number} EQUITY=4 EQUITY value
       * @property {number} EQUITY_OPTION=5 EQUITY_OPTION value
       * @property {number} EQUITY_STRATEGY=6 EQUITY_STRATEGY value
       * @property {number} INDEX=7 INDEX value
       * @property {number} INDEX_OPTION=8 INDEX_OPTION value
       * @property {number} SPREAD=9 SPREAD value
       * @property {number} SYNTHETIC=10 SYNTHETIC value
       */
      RequestSearchSymbols.InstrumentType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'FUTURE')] = 1;
        values[(valuesById[2] = 'FUTURE_OPTION')] = 2;
        values[(valuesById[3] = 'FUTURE_STRATEGY')] = 3;
        values[(valuesById[4] = 'EQUITY')] = 4;
        values[(valuesById[5] = 'EQUITY_OPTION')] = 5;
        values[(valuesById[6] = 'EQUITY_STRATEGY')] = 6;
        values[(valuesById[7] = 'INDEX')] = 7;
        values[(valuesById[8] = 'INDEX_OPTION')] = 8;
        values[(valuesById[9] = 'SPREAD')] = 9;
        values[(valuesById[10] = 'SYNTHETIC')] = 10;
        return values;
      })();

      return RequestSearchSymbols;
    })();

    rti.ResponseSearchSymbols = (function () {
      /**
       * Properties of a ResponseSearchSymbols.
       * @memberof rti
       * @interface IResponseSearchSymbols
       * @property {number} templateId ResponseSearchSymbols templateId
       * @property {Array.<string>|null} [userMsg] ResponseSearchSymbols userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseSearchSymbols rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseSearchSymbols rpCode
       * @property {string|null} [symbol] ResponseSearchSymbols symbol
       * @property {string|null} [exchange] ResponseSearchSymbols exchange
       * @property {string|null} [symbolName] ResponseSearchSymbols symbolName
       * @property {string|null} [productCode] ResponseSearchSymbols productCode
       * @property {string|null} [instrumentType] ResponseSearchSymbols instrumentType
       * @property {string|null} [expirationDate] ResponseSearchSymbols expirationDate
       */

      /**
       * Constructs a new ResponseSearchSymbols.
       * @memberof rti
       * @classdesc Represents a ResponseSearchSymbols.
       * @implements IResponseSearchSymbols
       * @constructor
       * @param {rti.IResponseSearchSymbols=} [properties] Properties to set
       */
      function ResponseSearchSymbols(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseSearchSymbols templateId.
       * @member {number} templateId
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.templateId = 0;

      /**
       * ResponseSearchSymbols userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseSearchSymbols rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseSearchSymbols rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseSearchSymbols symbol.
       * @member {string} symbol
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.symbol = '';

      /**
       * ResponseSearchSymbols exchange.
       * @member {string} exchange
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.exchange = '';

      /**
       * ResponseSearchSymbols symbolName.
       * @member {string} symbolName
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.symbolName = '';

      /**
       * ResponseSearchSymbols productCode.
       * @member {string} productCode
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.productCode = '';

      /**
       * ResponseSearchSymbols instrumentType.
       * @member {string} instrumentType
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.instrumentType = '';

      /**
       * ResponseSearchSymbols expirationDate.
       * @member {string} expirationDate
       * @memberof rti.ResponseSearchSymbols
       * @instance
       */
      ResponseSearchSymbols.prototype.expirationDate = '';

      /**
       * Creates a new ResponseSearchSymbols instance using the specified properties.
       * @function create
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {rti.IResponseSearchSymbols=} [properties] Properties to set
       * @returns {rti.ResponseSearchSymbols} ResponseSearchSymbols instance
       */
      ResponseSearchSymbols.create = function create(properties) {
        return new ResponseSearchSymbols(properties);
      };

      /**
       * Encodes the specified ResponseSearchSymbols message. Does not implicitly {@link rti.ResponseSearchSymbols.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {rti.IResponseSearchSymbols} message ResponseSearchSymbols message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseSearchSymbols.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbolName != null &&
          Object.hasOwnProperty.call(message, 'symbolName')
        )
          writer
            .uint32(/* id 100003, wireType 2 =*/ 800026)
            .string(message.symbolName);
        if (
          message.expirationDate != null &&
          Object.hasOwnProperty.call(message, 'expirationDate')
        )
          writer
            .uint32(/* id 100067, wireType 2 =*/ 800538)
            .string(message.expirationDate);
        if (
          message.productCode != null &&
          Object.hasOwnProperty.call(message, 'productCode')
        )
          writer
            .uint32(/* id 100749, wireType 2 =*/ 805994)
            .string(message.productCode);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.instrumentType != null &&
          Object.hasOwnProperty.call(message, 'instrumentType')
        )
          writer
            .uint32(/* id 110116, wireType 2 =*/ 880930)
            .string(message.instrumentType);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseSearchSymbols message, length delimited. Does not implicitly {@link rti.ResponseSearchSymbols.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {rti.IResponseSearchSymbols} message ResponseSearchSymbols message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseSearchSymbols.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseSearchSymbols message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseSearchSymbols} ResponseSearchSymbols
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseSearchSymbols.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseSearchSymbols();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100003:
              message.symbolName = reader.string();
              break;
            case 100749:
              message.productCode = reader.string();
              break;
            case 110116:
              message.instrumentType = reader.string();
              break;
            case 100067:
              message.expirationDate = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseSearchSymbols message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseSearchSymbols} ResponseSearchSymbols
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseSearchSymbols.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseSearchSymbols message.
       * @function verify
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseSearchSymbols.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          if (!$util.isString(message.symbolName))
            return 'symbolName: string expected';
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          if (!$util.isString(message.productCode))
            return 'productCode: string expected';
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          if (!$util.isString(message.instrumentType))
            return 'instrumentType: string expected';
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          if (!$util.isString(message.expirationDate))
            return 'expirationDate: string expected';
        return null;
      };

      /**
       * Creates a ResponseSearchSymbols message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseSearchSymbols} ResponseSearchSymbols
       */
      ResponseSearchSymbols.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseSearchSymbols) return object;
        var message = new $root.rti.ResponseSearchSymbols();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseSearchSymbols.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseSearchSymbols.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseSearchSymbols.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.symbolName != null)
          message.symbolName = String(object.symbolName);
        if (object.productCode != null)
          message.productCode = String(object.productCode);
        if (object.instrumentType != null)
          message.instrumentType = String(object.instrumentType);
        if (object.expirationDate != null)
          message.expirationDate = String(object.expirationDate);
        return message;
      };

      /**
       * Creates a plain object from a ResponseSearchSymbols message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {rti.ResponseSearchSymbols} message ResponseSearchSymbols
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseSearchSymbols.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.symbolName = '';
          object.expirationDate = '';
          object.productCode = '';
          object.symbol = '';
          object.exchange = '';
          object.instrumentType = '';
          object.templateId = 0;
        }
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          object.symbolName = message.symbolName;
        if (
          message.expirationDate != null &&
          message.hasOwnProperty('expirationDate')
        )
          object.expirationDate = message.expirationDate;
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          object.productCode = message.productCode;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          object.instrumentType = message.instrumentType;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseSearchSymbols to JSON.
       * @function toJSON
       * @memberof rti.ResponseSearchSymbols
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseSearchSymbols.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseSearchSymbols
       * @function getTypeUrl
       * @memberof rti.ResponseSearchSymbols
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseSearchSymbols.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseSearchSymbols';
      };

      return ResponseSearchSymbols;
    })();

    rti.RequestProductCodes = (function () {
      /**
       * Properties of a RequestProductCodes.
       * @memberof rti
       * @interface IRequestProductCodes
       * @property {number} templateId RequestProductCodes templateId
       * @property {Array.<string>|null} [userMsg] RequestProductCodes userMsg
       * @property {string|null} [exchange] RequestProductCodes exchange
       * @property {boolean|null} [giveToiProductsOnly] RequestProductCodes giveToiProductsOnly
       */

      /**
       * Constructs a new RequestProductCodes.
       * @memberof rti
       * @classdesc Represents a RequestProductCodes.
       * @implements IRequestProductCodes
       * @constructor
       * @param {rti.IRequestProductCodes=} [properties] Properties to set
       */
      function RequestProductCodes(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestProductCodes templateId.
       * @member {number} templateId
       * @memberof rti.RequestProductCodes
       * @instance
       */
      RequestProductCodes.prototype.templateId = 0;

      /**
       * RequestProductCodes userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestProductCodes
       * @instance
       */
      RequestProductCodes.prototype.userMsg = $util.emptyArray;

      /**
       * RequestProductCodes exchange.
       * @member {string} exchange
       * @memberof rti.RequestProductCodes
       * @instance
       */
      RequestProductCodes.prototype.exchange = '';

      /**
       * RequestProductCodes giveToiProductsOnly.
       * @member {boolean} giveToiProductsOnly
       * @memberof rti.RequestProductCodes
       * @instance
       */
      RequestProductCodes.prototype.giveToiProductsOnly = false;

      /**
       * Creates a new RequestProductCodes instance using the specified properties.
       * @function create
       * @memberof rti.RequestProductCodes
       * @static
       * @param {rti.IRequestProductCodes=} [properties] Properties to set
       * @returns {rti.RequestProductCodes} RequestProductCodes instance
       */
      RequestProductCodes.create = function create(properties) {
        return new RequestProductCodes(properties);
      };

      /**
       * Encodes the specified RequestProductCodes message. Does not implicitly {@link rti.RequestProductCodes.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestProductCodes
       * @static
       * @param {rti.IRequestProductCodes} message RequestProductCodes message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestProductCodes.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.giveToiProductsOnly != null &&
          Object.hasOwnProperty.call(message, 'giveToiProductsOnly')
        )
          writer
            .uint32(/* id 153499, wireType 0 =*/ 1227992)
            .bool(message.giveToiProductsOnly);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestProductCodes message, length delimited. Does not implicitly {@link rti.RequestProductCodes.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestProductCodes
       * @static
       * @param {rti.IRequestProductCodes} message RequestProductCodes message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestProductCodes.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestProductCodes message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestProductCodes
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestProductCodes} RequestProductCodes
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestProductCodes.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestProductCodes();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 153499:
              message.giveToiProductsOnly = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestProductCodes message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestProductCodes
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestProductCodes} RequestProductCodes
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestProductCodes.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestProductCodes message.
       * @function verify
       * @memberof rti.RequestProductCodes
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestProductCodes.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.giveToiProductsOnly != null &&
          message.hasOwnProperty('giveToiProductsOnly')
        )
          if (typeof message.giveToiProductsOnly !== 'boolean')
            return 'giveToiProductsOnly: boolean expected';
        return null;
      };

      /**
       * Creates a RequestProductCodes message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestProductCodes
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestProductCodes} RequestProductCodes
       */
      RequestProductCodes.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestProductCodes) return object;
        var message = new $root.rti.RequestProductCodes();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestProductCodes.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.giveToiProductsOnly != null)
          message.giveToiProductsOnly = Boolean(object.giveToiProductsOnly);
        return message;
      };

      /**
       * Creates a plain object from a RequestProductCodes message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestProductCodes
       * @static
       * @param {rti.RequestProductCodes} message RequestProductCodes
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestProductCodes.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.exchange = '';
          object.giveToiProductsOnly = false;
          object.templateId = 0;
        }
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.giveToiProductsOnly != null &&
          message.hasOwnProperty('giveToiProductsOnly')
        )
          object.giveToiProductsOnly = message.giveToiProductsOnly;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestProductCodes to JSON.
       * @function toJSON
       * @memberof rti.RequestProductCodes
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestProductCodes.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestProductCodes
       * @function getTypeUrl
       * @memberof rti.RequestProductCodes
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestProductCodes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestProductCodes';
      };

      return RequestProductCodes;
    })();

    rti.ResponseProductCodes = (function () {
      /**
       * Properties of a ResponseProductCodes.
       * @memberof rti
       * @interface IResponseProductCodes
       * @property {number} templateId ResponseProductCodes templateId
       * @property {Array.<string>|null} [userMsg] ResponseProductCodes userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseProductCodes rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseProductCodes rpCode
       * @property {string|null} [exchange] ResponseProductCodes exchange
       * @property {string|null} [symbolName] ResponseProductCodes symbolName
       * @property {string|null} [productCode] ResponseProductCodes productCode
       * @property {string|null} [timezoneTimeOfInterest] ResponseProductCodes timezoneTimeOfInterest
       * @property {number|null} [beginTimeOfInterestMsm] ResponseProductCodes beginTimeOfInterestMsm
       * @property {number|null} [endTimeOfInterestMsm] ResponseProductCodes endTimeOfInterestMsm
       */

      /**
       * Constructs a new ResponseProductCodes.
       * @memberof rti
       * @classdesc Represents a ResponseProductCodes.
       * @implements IResponseProductCodes
       * @constructor
       * @param {rti.IResponseProductCodes=} [properties] Properties to set
       */
      function ResponseProductCodes(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseProductCodes templateId.
       * @member {number} templateId
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.templateId = 0;

      /**
       * ResponseProductCodes userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseProductCodes rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseProductCodes rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseProductCodes exchange.
       * @member {string} exchange
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.exchange = '';

      /**
       * ResponseProductCodes symbolName.
       * @member {string} symbolName
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.symbolName = '';

      /**
       * ResponseProductCodes productCode.
       * @member {string} productCode
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.productCode = '';

      /**
       * ResponseProductCodes timezoneTimeOfInterest.
       * @member {string} timezoneTimeOfInterest
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.timezoneTimeOfInterest = '';

      /**
       * ResponseProductCodes beginTimeOfInterestMsm.
       * @member {number} beginTimeOfInterestMsm
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.beginTimeOfInterestMsm = 0;

      /**
       * ResponseProductCodes endTimeOfInterestMsm.
       * @member {number} endTimeOfInterestMsm
       * @memberof rti.ResponseProductCodes
       * @instance
       */
      ResponseProductCodes.prototype.endTimeOfInterestMsm = 0;

      /**
       * Creates a new ResponseProductCodes instance using the specified properties.
       * @function create
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {rti.IResponseProductCodes=} [properties] Properties to set
       * @returns {rti.ResponseProductCodes} ResponseProductCodes instance
       */
      ResponseProductCodes.create = function create(properties) {
        return new ResponseProductCodes(properties);
      };

      /**
       * Encodes the specified ResponseProductCodes message. Does not implicitly {@link rti.ResponseProductCodes.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {rti.IResponseProductCodes} message ResponseProductCodes message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseProductCodes.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbolName != null &&
          Object.hasOwnProperty.call(message, 'symbolName')
        )
          writer
            .uint32(/* id 100003, wireType 2 =*/ 800026)
            .string(message.symbolName);
        if (
          message.productCode != null &&
          Object.hasOwnProperty.call(message, 'productCode')
        )
          writer
            .uint32(/* id 100749, wireType 2 =*/ 805994)
            .string(message.productCode);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.beginTimeOfInterestMsm != null &&
          Object.hasOwnProperty.call(message, 'beginTimeOfInterestMsm')
        )
          writer
            .uint32(/* id 154680, wireType 0 =*/ 1237440)
            .int32(message.beginTimeOfInterestMsm);
        if (
          message.endTimeOfInterestMsm != null &&
          Object.hasOwnProperty.call(message, 'endTimeOfInterestMsm')
        )
          writer
            .uint32(/* id 154681, wireType 0 =*/ 1237448)
            .int32(message.endTimeOfInterestMsm);
        if (
          message.timezoneTimeOfInterest != null &&
          Object.hasOwnProperty.call(message, 'timezoneTimeOfInterest')
        )
          writer
            .uint32(/* id 154682, wireType 2 =*/ 1237458)
            .string(message.timezoneTimeOfInterest);
        return writer;
      };

      /**
       * Encodes the specified ResponseProductCodes message, length delimited. Does not implicitly {@link rti.ResponseProductCodes.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {rti.IResponseProductCodes} message ResponseProductCodes message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseProductCodes.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseProductCodes message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseProductCodes} ResponseProductCodes
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseProductCodes.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseProductCodes();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100003:
              message.symbolName = reader.string();
              break;
            case 100749:
              message.productCode = reader.string();
              break;
            case 154682:
              message.timezoneTimeOfInterest = reader.string();
              break;
            case 154680:
              message.beginTimeOfInterestMsm = reader.int32();
              break;
            case 154681:
              message.endTimeOfInterestMsm = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseProductCodes message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseProductCodes} ResponseProductCodes
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseProductCodes.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseProductCodes message.
       * @function verify
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseProductCodes.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          if (!$util.isString(message.symbolName))
            return 'symbolName: string expected';
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          if (!$util.isString(message.productCode))
            return 'productCode: string expected';
        if (
          message.timezoneTimeOfInterest != null &&
          message.hasOwnProperty('timezoneTimeOfInterest')
        )
          if (!$util.isString(message.timezoneTimeOfInterest))
            return 'timezoneTimeOfInterest: string expected';
        if (
          message.beginTimeOfInterestMsm != null &&
          message.hasOwnProperty('beginTimeOfInterestMsm')
        )
          if (!$util.isInteger(message.beginTimeOfInterestMsm))
            return 'beginTimeOfInterestMsm: integer expected';
        if (
          message.endTimeOfInterestMsm != null &&
          message.hasOwnProperty('endTimeOfInterestMsm')
        )
          if (!$util.isInteger(message.endTimeOfInterestMsm))
            return 'endTimeOfInterestMsm: integer expected';
        return null;
      };

      /**
       * Creates a ResponseProductCodes message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseProductCodes} ResponseProductCodes
       */
      ResponseProductCodes.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseProductCodes) return object;
        var message = new $root.rti.ResponseProductCodes();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseProductCodes.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseProductCodes.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseProductCodes.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.symbolName != null)
          message.symbolName = String(object.symbolName);
        if (object.productCode != null)
          message.productCode = String(object.productCode);
        if (object.timezoneTimeOfInterest != null)
          message.timezoneTimeOfInterest = String(
            object.timezoneTimeOfInterest,
          );
        if (object.beginTimeOfInterestMsm != null)
          message.beginTimeOfInterestMsm = object.beginTimeOfInterestMsm | 0;
        if (object.endTimeOfInterestMsm != null)
          message.endTimeOfInterestMsm = object.endTimeOfInterestMsm | 0;
        return message;
      };

      /**
       * Creates a plain object from a ResponseProductCodes message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {rti.ResponseProductCodes} message ResponseProductCodes
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseProductCodes.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.symbolName = '';
          object.productCode = '';
          object.exchange = '';
          object.templateId = 0;
          object.beginTimeOfInterestMsm = 0;
          object.endTimeOfInterestMsm = 0;
          object.timezoneTimeOfInterest = '';
        }
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          object.symbolName = message.symbolName;
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          object.productCode = message.productCode;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.beginTimeOfInterestMsm != null &&
          message.hasOwnProperty('beginTimeOfInterestMsm')
        )
          object.beginTimeOfInterestMsm = message.beginTimeOfInterestMsm;
        if (
          message.endTimeOfInterestMsm != null &&
          message.hasOwnProperty('endTimeOfInterestMsm')
        )
          object.endTimeOfInterestMsm = message.endTimeOfInterestMsm;
        if (
          message.timezoneTimeOfInterest != null &&
          message.hasOwnProperty('timezoneTimeOfInterest')
        )
          object.timezoneTimeOfInterest = message.timezoneTimeOfInterest;
        return object;
      };

      /**
       * Converts this ResponseProductCodes to JSON.
       * @function toJSON
       * @memberof rti.ResponseProductCodes
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseProductCodes.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseProductCodes
       * @function getTypeUrl
       * @memberof rti.ResponseProductCodes
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseProductCodes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseProductCodes';
      };

      return ResponseProductCodes;
    })();

    rti.RequestFrontMonthContract = (function () {
      /**
       * Properties of a RequestFrontMonthContract.
       * @memberof rti
       * @interface IRequestFrontMonthContract
       * @property {number} templateId RequestFrontMonthContract templateId
       * @property {Array.<string>|null} [userMsg] RequestFrontMonthContract userMsg
       * @property {string|null} [symbol] RequestFrontMonthContract symbol
       * @property {string|null} [exchange] RequestFrontMonthContract exchange
       * @property {boolean|null} [needUpdates] RequestFrontMonthContract needUpdates
       */

      /**
       * Constructs a new RequestFrontMonthContract.
       * @memberof rti
       * @classdesc Represents a RequestFrontMonthContract.
       * @implements IRequestFrontMonthContract
       * @constructor
       * @param {rti.IRequestFrontMonthContract=} [properties] Properties to set
       */
      function RequestFrontMonthContract(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestFrontMonthContract templateId.
       * @member {number} templateId
       * @memberof rti.RequestFrontMonthContract
       * @instance
       */
      RequestFrontMonthContract.prototype.templateId = 0;

      /**
       * RequestFrontMonthContract userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestFrontMonthContract
       * @instance
       */
      RequestFrontMonthContract.prototype.userMsg = $util.emptyArray;

      /**
       * RequestFrontMonthContract symbol.
       * @member {string} symbol
       * @memberof rti.RequestFrontMonthContract
       * @instance
       */
      RequestFrontMonthContract.prototype.symbol = '';

      /**
       * RequestFrontMonthContract exchange.
       * @member {string} exchange
       * @memberof rti.RequestFrontMonthContract
       * @instance
       */
      RequestFrontMonthContract.prototype.exchange = '';

      /**
       * RequestFrontMonthContract needUpdates.
       * @member {boolean} needUpdates
       * @memberof rti.RequestFrontMonthContract
       * @instance
       */
      RequestFrontMonthContract.prototype.needUpdates = false;

      /**
       * Creates a new RequestFrontMonthContract instance using the specified properties.
       * @function create
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {rti.IRequestFrontMonthContract=} [properties] Properties to set
       * @returns {rti.RequestFrontMonthContract} RequestFrontMonthContract instance
       */
      RequestFrontMonthContract.create = function create(properties) {
        return new RequestFrontMonthContract(properties);
      };

      /**
       * Encodes the specified RequestFrontMonthContract message. Does not implicitly {@link rti.RequestFrontMonthContract.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {rti.IRequestFrontMonthContract} message RequestFrontMonthContract message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestFrontMonthContract.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.needUpdates != null &&
          Object.hasOwnProperty.call(message, 'needUpdates')
        )
          writer
            .uint32(/* id 154352, wireType 0 =*/ 1234816)
            .bool(message.needUpdates);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestFrontMonthContract message, length delimited. Does not implicitly {@link rti.RequestFrontMonthContract.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {rti.IRequestFrontMonthContract} message RequestFrontMonthContract message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestFrontMonthContract.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestFrontMonthContract message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestFrontMonthContract} RequestFrontMonthContract
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestFrontMonthContract.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestFrontMonthContract();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 154352:
              message.needUpdates = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestFrontMonthContract message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestFrontMonthContract} RequestFrontMonthContract
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestFrontMonthContract.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestFrontMonthContract message.
       * @function verify
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestFrontMonthContract.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.needUpdates != null &&
          message.hasOwnProperty('needUpdates')
        )
          if (typeof message.needUpdates !== 'boolean')
            return 'needUpdates: boolean expected';
        return null;
      };

      /**
       * Creates a RequestFrontMonthContract message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestFrontMonthContract} RequestFrontMonthContract
       */
      RequestFrontMonthContract.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestFrontMonthContract)
          return object;
        var message = new $root.rti.RequestFrontMonthContract();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestFrontMonthContract.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.needUpdates != null)
          message.needUpdates = Boolean(object.needUpdates);
        return message;
      };

      /**
       * Creates a plain object from a RequestFrontMonthContract message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {rti.RequestFrontMonthContract} message RequestFrontMonthContract
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestFrontMonthContract.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.needUpdates = false;
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.needUpdates != null &&
          message.hasOwnProperty('needUpdates')
        )
          object.needUpdates = message.needUpdates;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestFrontMonthContract to JSON.
       * @function toJSON
       * @memberof rti.RequestFrontMonthContract
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestFrontMonthContract.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestFrontMonthContract
       * @function getTypeUrl
       * @memberof rti.RequestFrontMonthContract
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestFrontMonthContract.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestFrontMonthContract';
      };

      return RequestFrontMonthContract;
    })();

    rti.ResponseFrontMonthContract = (function () {
      /**
       * Properties of a ResponseFrontMonthContract.
       * @memberof rti
       * @interface IResponseFrontMonthContract
       * @property {number} templateId ResponseFrontMonthContract templateId
       * @property {Array.<string>|null} [userMsg] ResponseFrontMonthContract userMsg
       * @property {Array.<string>|null} [rpCode] ResponseFrontMonthContract rpCode
       * @property {string|null} [symbol] ResponseFrontMonthContract symbol
       * @property {string|null} [exchange] ResponseFrontMonthContract exchange
       * @property {boolean|null} [isFrontMonthSymbol] ResponseFrontMonthContract isFrontMonthSymbol
       * @property {string|null} [symbolName] ResponseFrontMonthContract symbolName
       * @property {string|null} [tradingSymbol] ResponseFrontMonthContract tradingSymbol
       * @property {string|null} [tradingExchange] ResponseFrontMonthContract tradingExchange
       */

      /**
       * Constructs a new ResponseFrontMonthContract.
       * @memberof rti
       * @classdesc Represents a ResponseFrontMonthContract.
       * @implements IResponseFrontMonthContract
       * @constructor
       * @param {rti.IResponseFrontMonthContract=} [properties] Properties to set
       */
      function ResponseFrontMonthContract(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseFrontMonthContract templateId.
       * @member {number} templateId
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.templateId = 0;

      /**
       * ResponseFrontMonthContract userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseFrontMonthContract rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseFrontMonthContract symbol.
       * @member {string} symbol
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.symbol = '';

      /**
       * ResponseFrontMonthContract exchange.
       * @member {string} exchange
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.exchange = '';

      /**
       * ResponseFrontMonthContract isFrontMonthSymbol.
       * @member {boolean} isFrontMonthSymbol
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.isFrontMonthSymbol = false;

      /**
       * ResponseFrontMonthContract symbolName.
       * @member {string} symbolName
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.symbolName = '';

      /**
       * ResponseFrontMonthContract tradingSymbol.
       * @member {string} tradingSymbol
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.tradingSymbol = '';

      /**
       * ResponseFrontMonthContract tradingExchange.
       * @member {string} tradingExchange
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       */
      ResponseFrontMonthContract.prototype.tradingExchange = '';

      /**
       * Creates a new ResponseFrontMonthContract instance using the specified properties.
       * @function create
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {rti.IResponseFrontMonthContract=} [properties] Properties to set
       * @returns {rti.ResponseFrontMonthContract} ResponseFrontMonthContract instance
       */
      ResponseFrontMonthContract.create = function create(properties) {
        return new ResponseFrontMonthContract(properties);
      };

      /**
       * Encodes the specified ResponseFrontMonthContract message. Does not implicitly {@link rti.ResponseFrontMonthContract.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {rti.IResponseFrontMonthContract} message ResponseFrontMonthContract message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseFrontMonthContract.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbolName != null &&
          Object.hasOwnProperty.call(message, 'symbolName')
        )
          writer
            .uint32(/* id 100003, wireType 2 =*/ 800026)
            .string(message.symbolName);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.isFrontMonthSymbol != null &&
          Object.hasOwnProperty.call(message, 'isFrontMonthSymbol')
        )
          writer
            .uint32(/* id 149166, wireType 0 =*/ 1193328)
            .bool(message.isFrontMonthSymbol);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.tradingSymbol != null &&
          Object.hasOwnProperty.call(message, 'tradingSymbol')
        )
          writer
            .uint32(/* id 157095, wireType 2 =*/ 1256762)
            .string(message.tradingSymbol);
        if (
          message.tradingExchange != null &&
          Object.hasOwnProperty.call(message, 'tradingExchange')
        )
          writer
            .uint32(/* id 157096, wireType 2 =*/ 1256770)
            .string(message.tradingExchange);
        return writer;
      };

      /**
       * Encodes the specified ResponseFrontMonthContract message, length delimited. Does not implicitly {@link rti.ResponseFrontMonthContract.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {rti.IResponseFrontMonthContract} message ResponseFrontMonthContract message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseFrontMonthContract.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseFrontMonthContract message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseFrontMonthContract} ResponseFrontMonthContract
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseFrontMonthContract.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseFrontMonthContract();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149166:
              message.isFrontMonthSymbol = reader.bool();
              break;
            case 100003:
              message.symbolName = reader.string();
              break;
            case 157095:
              message.tradingSymbol = reader.string();
              break;
            case 157096:
              message.tradingExchange = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseFrontMonthContract message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseFrontMonthContract} ResponseFrontMonthContract
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseFrontMonthContract.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseFrontMonthContract message.
       * @function verify
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseFrontMonthContract.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.isFrontMonthSymbol != null &&
          message.hasOwnProperty('isFrontMonthSymbol')
        )
          if (typeof message.isFrontMonthSymbol !== 'boolean')
            return 'isFrontMonthSymbol: boolean expected';
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          if (!$util.isString(message.symbolName))
            return 'symbolName: string expected';
        if (
          message.tradingSymbol != null &&
          message.hasOwnProperty('tradingSymbol')
        )
          if (!$util.isString(message.tradingSymbol))
            return 'tradingSymbol: string expected';
        if (
          message.tradingExchange != null &&
          message.hasOwnProperty('tradingExchange')
        )
          if (!$util.isString(message.tradingExchange))
            return 'tradingExchange: string expected';
        return null;
      };

      /**
       * Creates a ResponseFrontMonthContract message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseFrontMonthContract} ResponseFrontMonthContract
       */
      ResponseFrontMonthContract.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseFrontMonthContract)
          return object;
        var message = new $root.rti.ResponseFrontMonthContract();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseFrontMonthContract.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseFrontMonthContract.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.isFrontMonthSymbol != null)
          message.isFrontMonthSymbol = Boolean(object.isFrontMonthSymbol);
        if (object.symbolName != null)
          message.symbolName = String(object.symbolName);
        if (object.tradingSymbol != null)
          message.tradingSymbol = String(object.tradingSymbol);
        if (object.tradingExchange != null)
          message.tradingExchange = String(object.tradingExchange);
        return message;
      };

      /**
       * Creates a plain object from a ResponseFrontMonthContract message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {rti.ResponseFrontMonthContract} message ResponseFrontMonthContract
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseFrontMonthContract.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.symbolName = '';
          object.symbol = '';
          object.exchange = '';
          object.isFrontMonthSymbol = false;
          object.templateId = 0;
          object.tradingSymbol = '';
          object.tradingExchange = '';
        }
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          object.symbolName = message.symbolName;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.isFrontMonthSymbol != null &&
          message.hasOwnProperty('isFrontMonthSymbol')
        )
          object.isFrontMonthSymbol = message.isFrontMonthSymbol;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.tradingSymbol != null &&
          message.hasOwnProperty('tradingSymbol')
        )
          object.tradingSymbol = message.tradingSymbol;
        if (
          message.tradingExchange != null &&
          message.hasOwnProperty('tradingExchange')
        )
          object.tradingExchange = message.tradingExchange;
        return object;
      };

      /**
       * Converts this ResponseFrontMonthContract to JSON.
       * @function toJSON
       * @memberof rti.ResponseFrontMonthContract
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseFrontMonthContract.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseFrontMonthContract
       * @function getTypeUrl
       * @memberof rti.ResponseFrontMonthContract
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseFrontMonthContract.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseFrontMonthContract';
      };

      return ResponseFrontMonthContract;
    })();

    rti.RequestDepthByOrderSnapshot = (function () {
      /**
       * Properties of a RequestDepthByOrderSnapshot.
       * @memberof rti
       * @interface IRequestDepthByOrderSnapshot
       * @property {number} templateId RequestDepthByOrderSnapshot templateId
       * @property {Array.<string>|null} [userMsg] RequestDepthByOrderSnapshot userMsg
       * @property {string|null} [symbol] RequestDepthByOrderSnapshot symbol
       * @property {string|null} [exchange] RequestDepthByOrderSnapshot exchange
       * @property {number|null} [depthPrice] RequestDepthByOrderSnapshot depthPrice
       */

      /**
       * Constructs a new RequestDepthByOrderSnapshot.
       * @memberof rti
       * @classdesc Represents a RequestDepthByOrderSnapshot.
       * @implements IRequestDepthByOrderSnapshot
       * @constructor
       * @param {rti.IRequestDepthByOrderSnapshot=} [properties] Properties to set
       */
      function RequestDepthByOrderSnapshot(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestDepthByOrderSnapshot templateId.
       * @member {number} templateId
       * @memberof rti.RequestDepthByOrderSnapshot
       * @instance
       */
      RequestDepthByOrderSnapshot.prototype.templateId = 0;

      /**
       * RequestDepthByOrderSnapshot userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestDepthByOrderSnapshot
       * @instance
       */
      RequestDepthByOrderSnapshot.prototype.userMsg = $util.emptyArray;

      /**
       * RequestDepthByOrderSnapshot symbol.
       * @member {string} symbol
       * @memberof rti.RequestDepthByOrderSnapshot
       * @instance
       */
      RequestDepthByOrderSnapshot.prototype.symbol = '';

      /**
       * RequestDepthByOrderSnapshot exchange.
       * @member {string} exchange
       * @memberof rti.RequestDepthByOrderSnapshot
       * @instance
       */
      RequestDepthByOrderSnapshot.prototype.exchange = '';

      /**
       * RequestDepthByOrderSnapshot depthPrice.
       * @member {number} depthPrice
       * @memberof rti.RequestDepthByOrderSnapshot
       * @instance
       */
      RequestDepthByOrderSnapshot.prototype.depthPrice = 0;

      /**
       * Creates a new RequestDepthByOrderSnapshot instance using the specified properties.
       * @function create
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {rti.IRequestDepthByOrderSnapshot=} [properties] Properties to set
       * @returns {rti.RequestDepthByOrderSnapshot} RequestDepthByOrderSnapshot instance
       */
      RequestDepthByOrderSnapshot.create = function create(properties) {
        return new RequestDepthByOrderSnapshot(properties);
      };

      /**
       * Encodes the specified RequestDepthByOrderSnapshot message. Does not implicitly {@link rti.RequestDepthByOrderSnapshot.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {rti.IRequestDepthByOrderSnapshot} message RequestDepthByOrderSnapshot message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestDepthByOrderSnapshot.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.depthPrice != null &&
          Object.hasOwnProperty.call(message, 'depthPrice')
        )
          writer
            .uint32(/* id 154405, wireType 1 =*/ 1235241)
            .double(message.depthPrice);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestDepthByOrderSnapshot message, length delimited. Does not implicitly {@link rti.RequestDepthByOrderSnapshot.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {rti.IRequestDepthByOrderSnapshot} message RequestDepthByOrderSnapshot message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestDepthByOrderSnapshot.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestDepthByOrderSnapshot message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestDepthByOrderSnapshot} RequestDepthByOrderSnapshot
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestDepthByOrderSnapshot.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestDepthByOrderSnapshot();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 154405:
              message.depthPrice = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestDepthByOrderSnapshot message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestDepthByOrderSnapshot} RequestDepthByOrderSnapshot
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestDepthByOrderSnapshot.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestDepthByOrderSnapshot message.
       * @function verify
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestDepthByOrderSnapshot.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.depthPrice != null && message.hasOwnProperty('depthPrice'))
          if (typeof message.depthPrice !== 'number')
            return 'depthPrice: number expected';
        return null;
      };

      /**
       * Creates a RequestDepthByOrderSnapshot message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestDepthByOrderSnapshot} RequestDepthByOrderSnapshot
       */
      RequestDepthByOrderSnapshot.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestDepthByOrderSnapshot)
          return object;
        var message = new $root.rti.RequestDepthByOrderSnapshot();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestDepthByOrderSnapshot.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.depthPrice != null)
          message.depthPrice = Number(object.depthPrice);
        return message;
      };

      /**
       * Creates a plain object from a RequestDepthByOrderSnapshot message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {rti.RequestDepthByOrderSnapshot} message RequestDepthByOrderSnapshot
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestDepthByOrderSnapshot.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.depthPrice = 0;
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.depthPrice != null && message.hasOwnProperty('depthPrice'))
          object.depthPrice =
            options.json && !isFinite(message.depthPrice)
              ? String(message.depthPrice)
              : message.depthPrice;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestDepthByOrderSnapshot to JSON.
       * @function toJSON
       * @memberof rti.RequestDepthByOrderSnapshot
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestDepthByOrderSnapshot.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestDepthByOrderSnapshot
       * @function getTypeUrl
       * @memberof rti.RequestDepthByOrderSnapshot
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestDepthByOrderSnapshot.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestDepthByOrderSnapshot';
      };

      return RequestDepthByOrderSnapshot;
    })();

    rti.ResponseDepthByOrderSnapshot = (function () {
      /**
       * Properties of a ResponseDepthByOrderSnapshot.
       * @memberof rti
       * @interface IResponseDepthByOrderSnapshot
       * @property {number} templateId ResponseDepthByOrderSnapshot templateId
       * @property {Array.<string>|null} [userMsg] ResponseDepthByOrderSnapshot userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseDepthByOrderSnapshot rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseDepthByOrderSnapshot rpCode
       * @property {string|null} [exchange] ResponseDepthByOrderSnapshot exchange
       * @property {string|null} [symbol] ResponseDepthByOrderSnapshot symbol
       * @property {number|Long|null} [sequenceNumber] ResponseDepthByOrderSnapshot sequenceNumber
       * @property {rti.ResponseDepthByOrderSnapshot.TransactionType|null} [depthSide] ResponseDepthByOrderSnapshot depthSide
       * @property {number|null} [depthPrice] ResponseDepthByOrderSnapshot depthPrice
       * @property {Array.<number>|null} [depthSize] ResponseDepthByOrderSnapshot depthSize
       * @property {Array.<number|Long>|null} [depthOrderPriority] ResponseDepthByOrderSnapshot depthOrderPriority
       * @property {Array.<string>|null} [exchangeOrderId] ResponseDepthByOrderSnapshot exchangeOrderId
       */

      /**
       * Constructs a new ResponseDepthByOrderSnapshot.
       * @memberof rti
       * @classdesc Represents a ResponseDepthByOrderSnapshot.
       * @implements IResponseDepthByOrderSnapshot
       * @constructor
       * @param {rti.IResponseDepthByOrderSnapshot=} [properties] Properties to set
       */
      function ResponseDepthByOrderSnapshot(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        this.depthSize = [];
        this.depthOrderPriority = [];
        this.exchangeOrderId = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseDepthByOrderSnapshot templateId.
       * @member {number} templateId
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.templateId = 0;

      /**
       * ResponseDepthByOrderSnapshot userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseDepthByOrderSnapshot rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseDepthByOrderSnapshot rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseDepthByOrderSnapshot exchange.
       * @member {string} exchange
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.exchange = '';

      /**
       * ResponseDepthByOrderSnapshot symbol.
       * @member {string} symbol
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.symbol = '';

      /**
       * ResponseDepthByOrderSnapshot sequenceNumber.
       * @member {number|Long} sequenceNumber
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.sequenceNumber = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseDepthByOrderSnapshot depthSide.
       * @member {rti.ResponseDepthByOrderSnapshot.TransactionType} depthSide
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.depthSide = 1;

      /**
       * ResponseDepthByOrderSnapshot depthPrice.
       * @member {number} depthPrice
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.depthPrice = 0;

      /**
       * ResponseDepthByOrderSnapshot depthSize.
       * @member {Array.<number>} depthSize
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.depthSize = $util.emptyArray;

      /**
       * ResponseDepthByOrderSnapshot depthOrderPriority.
       * @member {Array.<number|Long>} depthOrderPriority
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.depthOrderPriority =
        $util.emptyArray;

      /**
       * ResponseDepthByOrderSnapshot exchangeOrderId.
       * @member {Array.<string>} exchangeOrderId
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       */
      ResponseDepthByOrderSnapshot.prototype.exchangeOrderId = $util.emptyArray;

      /**
       * Creates a new ResponseDepthByOrderSnapshot instance using the specified properties.
       * @function create
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {rti.IResponseDepthByOrderSnapshot=} [properties] Properties to set
       * @returns {rti.ResponseDepthByOrderSnapshot} ResponseDepthByOrderSnapshot instance
       */
      ResponseDepthByOrderSnapshot.create = function create(properties) {
        return new ResponseDepthByOrderSnapshot(properties);
      };

      /**
       * Encodes the specified ResponseDepthByOrderSnapshot message. Does not implicitly {@link rti.ResponseDepthByOrderSnapshot.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {rti.IResponseDepthByOrderSnapshot} message ResponseDepthByOrderSnapshot message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseDepthByOrderSnapshot.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.sequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'sequenceNumber')
        )
          writer
            .uint32(/* id 112002, wireType 0 =*/ 896016)
            .uint64(message.sequenceNumber);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (message.exchangeOrderId != null && message.exchangeOrderId.length)
          for (var i = 0; i < message.exchangeOrderId.length; ++i)
            writer
              .uint32(/* id 149238, wireType 2 =*/ 1193906)
              .string(message.exchangeOrderId[i]);
        if (
          message.depthSide != null &&
          Object.hasOwnProperty.call(message, 'depthSide')
        )
          writer
            .uint32(/* id 153612, wireType 0 =*/ 1228896)
            .int32(message.depthSide);
        if (
          message.depthOrderPriority != null &&
          message.depthOrderPriority.length
        )
          for (var i = 0; i < message.depthOrderPriority.length; ++i)
            writer
              .uint32(/* id 153613, wireType 0 =*/ 1228904)
              .uint64(message.depthOrderPriority[i]);
        if (
          message.depthPrice != null &&
          Object.hasOwnProperty.call(message, 'depthPrice')
        )
          writer
            .uint32(/* id 154405, wireType 1 =*/ 1235241)
            .double(message.depthPrice);
        if (message.depthSize != null && message.depthSize.length)
          for (var i = 0; i < message.depthSize.length; ++i)
            writer
              .uint32(/* id 154406, wireType 0 =*/ 1235248)
              .int32(message.depthSize[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseDepthByOrderSnapshot message, length delimited. Does not implicitly {@link rti.ResponseDepthByOrderSnapshot.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {rti.IResponseDepthByOrderSnapshot} message ResponseDepthByOrderSnapshot message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseDepthByOrderSnapshot.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseDepthByOrderSnapshot message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseDepthByOrderSnapshot} ResponseDepthByOrderSnapshot
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseDepthByOrderSnapshot.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseDepthByOrderSnapshot();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 112002:
              message.sequenceNumber = reader.uint64();
              break;
            case 153612:
              message.depthSide = reader.int32();
              break;
            case 154405:
              message.depthPrice = reader.double();
              break;
            case 154406:
              if (!(message.depthSize && message.depthSize.length))
                message.depthSize = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.depthSize.push(reader.int32());
              } else message.depthSize.push(reader.int32());
              break;
            case 153613:
              if (
                !(
                  message.depthOrderPriority &&
                  message.depthOrderPriority.length
                )
              )
                message.depthOrderPriority = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.depthOrderPriority.push(reader.uint64());
              } else message.depthOrderPriority.push(reader.uint64());
              break;
            case 149238:
              if (!(message.exchangeOrderId && message.exchangeOrderId.length))
                message.exchangeOrderId = [];
              message.exchangeOrderId.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseDepthByOrderSnapshot message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseDepthByOrderSnapshot} ResponseDepthByOrderSnapshot
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseDepthByOrderSnapshot.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseDepthByOrderSnapshot message.
       * @function verify
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseDepthByOrderSnapshot.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          if (
            !$util.isInteger(message.sequenceNumber) &&
            !(
              message.sequenceNumber &&
              $util.isInteger(message.sequenceNumber.low) &&
              $util.isInteger(message.sequenceNumber.high)
            )
          )
            return 'sequenceNumber: integer|Long expected';
        if (message.depthSide != null && message.hasOwnProperty('depthSide'))
          switch (message.depthSide) {
            default:
              return 'depthSide: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.depthPrice != null && message.hasOwnProperty('depthPrice'))
          if (typeof message.depthPrice !== 'number')
            return 'depthPrice: number expected';
        if (message.depthSize != null && message.hasOwnProperty('depthSize')) {
          if (!Array.isArray(message.depthSize))
            return 'depthSize: array expected';
          for (var i = 0; i < message.depthSize.length; ++i)
            if (!$util.isInteger(message.depthSize[i]))
              return 'depthSize: integer[] expected';
        }
        if (
          message.depthOrderPriority != null &&
          message.hasOwnProperty('depthOrderPriority')
        ) {
          if (!Array.isArray(message.depthOrderPriority))
            return 'depthOrderPriority: array expected';
          for (var i = 0; i < message.depthOrderPriority.length; ++i)
            if (
              !$util.isInteger(message.depthOrderPriority[i]) &&
              !(
                message.depthOrderPriority[i] &&
                $util.isInteger(message.depthOrderPriority[i].low) &&
                $util.isInteger(message.depthOrderPriority[i].high)
              )
            )
              return 'depthOrderPriority: integer|Long[] expected';
        }
        if (
          message.exchangeOrderId != null &&
          message.hasOwnProperty('exchangeOrderId')
        ) {
          if (!Array.isArray(message.exchangeOrderId))
            return 'exchangeOrderId: array expected';
          for (var i = 0; i < message.exchangeOrderId.length; ++i)
            if (!$util.isString(message.exchangeOrderId[i]))
              return 'exchangeOrderId: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseDepthByOrderSnapshot message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseDepthByOrderSnapshot} ResponseDepthByOrderSnapshot
       */
      ResponseDepthByOrderSnapshot.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseDepthByOrderSnapshot)
          return object;
        var message = new $root.rti.ResponseDepthByOrderSnapshot();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseDepthByOrderSnapshot.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseDepthByOrderSnapshot.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseDepthByOrderSnapshot.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.sequenceNumber != null)
          if ($util.Long)
            (message.sequenceNumber = $util.Long.fromValue(
              object.sequenceNumber,
            )).unsigned = true;
          else if (typeof object.sequenceNumber === 'string')
            message.sequenceNumber = parseInt(object.sequenceNumber, 10);
          else if (typeof object.sequenceNumber === 'number')
            message.sequenceNumber = object.sequenceNumber;
          else if (typeof object.sequenceNumber === 'object')
            message.sequenceNumber = new $util.LongBits(
              object.sequenceNumber.low >>> 0,
              object.sequenceNumber.high >>> 0,
            ).toNumber(true);
        switch (object.depthSide) {
          case 'BUY':
          case 1:
            message.depthSide = 1;
            break;
          case 'SELL':
          case 2:
            message.depthSide = 2;
            break;
        }
        if (object.depthPrice != null)
          message.depthPrice = Number(object.depthPrice);
        if (object.depthSize) {
          if (!Array.isArray(object.depthSize))
            throw TypeError(
              '.rti.ResponseDepthByOrderSnapshot.depthSize: array expected',
            );
          message.depthSize = [];
          for (var i = 0; i < object.depthSize.length; ++i)
            message.depthSize[i] = object.depthSize[i] | 0;
        }
        if (object.depthOrderPriority) {
          if (!Array.isArray(object.depthOrderPriority))
            throw TypeError(
              '.rti.ResponseDepthByOrderSnapshot.depthOrderPriority: array expected',
            );
          message.depthOrderPriority = [];
          for (var i = 0; i < object.depthOrderPriority.length; ++i)
            if ($util.Long)
              (message.depthOrderPriority[i] = $util.Long.fromValue(
                object.depthOrderPriority[i],
              )).unsigned = true;
            else if (typeof object.depthOrderPriority[i] === 'string')
              message.depthOrderPriority[i] = parseInt(
                object.depthOrderPriority[i],
                10,
              );
            else if (typeof object.depthOrderPriority[i] === 'number')
              message.depthOrderPriority[i] = object.depthOrderPriority[i];
            else if (typeof object.depthOrderPriority[i] === 'object')
              message.depthOrderPriority[i] = new $util.LongBits(
                object.depthOrderPriority[i].low >>> 0,
                object.depthOrderPriority[i].high >>> 0,
              ).toNumber(true);
        }
        if (object.exchangeOrderId) {
          if (!Array.isArray(object.exchangeOrderId))
            throw TypeError(
              '.rti.ResponseDepthByOrderSnapshot.exchangeOrderId: array expected',
            );
          message.exchangeOrderId = [];
          for (var i = 0; i < object.exchangeOrderId.length; ++i)
            message.exchangeOrderId[i] = String(object.exchangeOrderId[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseDepthByOrderSnapshot message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {rti.ResponseDepthByOrderSnapshot} message ResponseDepthByOrderSnapshot
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseDepthByOrderSnapshot.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
          object.exchangeOrderId = [];
          object.depthOrderPriority = [];
          object.depthSize = [];
        }
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.sequenceNumber =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.sequenceNumber = options.longs === String ? '0' : 0;
          object.depthSide = options.enums === String ? 'BUY' : 1;
          object.depthPrice = 0;
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          if (typeof message.sequenceNumber === 'number')
            object.sequenceNumber =
              options.longs === String
                ? String(message.sequenceNumber)
                : message.sequenceNumber;
          else
            object.sequenceNumber =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.sequenceNumber)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.sequenceNumber.low >>> 0,
                      message.sequenceNumber.high >>> 0,
                    ).toNumber(true)
                  : message.sequenceNumber;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.exchangeOrderId && message.exchangeOrderId.length) {
          object.exchangeOrderId = [];
          for (var j = 0; j < message.exchangeOrderId.length; ++j)
            object.exchangeOrderId[j] = message.exchangeOrderId[j];
        }
        if (message.depthSide != null && message.hasOwnProperty('depthSide'))
          object.depthSide =
            options.enums === String
              ? $root.rti.ResponseDepthByOrderSnapshot.TransactionType[
                  message.depthSide
                ]
              : message.depthSide;
        if (message.depthOrderPriority && message.depthOrderPriority.length) {
          object.depthOrderPriority = [];
          for (var j = 0; j < message.depthOrderPriority.length; ++j)
            if (typeof message.depthOrderPriority[j] === 'number')
              object.depthOrderPriority[j] =
                options.longs === String
                  ? String(message.depthOrderPriority[j])
                  : message.depthOrderPriority[j];
            else
              object.depthOrderPriority[j] =
                options.longs === String
                  ? $util.Long.prototype.toString.call(
                      message.depthOrderPriority[j],
                    )
                  : options.longs === Number
                    ? new $util.LongBits(
                        message.depthOrderPriority[j].low >>> 0,
                        message.depthOrderPriority[j].high >>> 0,
                      ).toNumber(true)
                    : message.depthOrderPriority[j];
        }
        if (message.depthPrice != null && message.hasOwnProperty('depthPrice'))
          object.depthPrice =
            options.json && !isFinite(message.depthPrice)
              ? String(message.depthPrice)
              : message.depthPrice;
        if (message.depthSize && message.depthSize.length) {
          object.depthSize = [];
          for (var j = 0; j < message.depthSize.length; ++j)
            object.depthSize[j] = message.depthSize[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseDepthByOrderSnapshot to JSON.
       * @function toJSON
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseDepthByOrderSnapshot.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseDepthByOrderSnapshot
       * @function getTypeUrl
       * @memberof rti.ResponseDepthByOrderSnapshot
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseDepthByOrderSnapshot.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseDepthByOrderSnapshot';
      };

      /**
       * TransactionType enum.
       * @name rti.ResponseDepthByOrderSnapshot.TransactionType
       * @enum {number}
       * @property {number} BUY=1 BUY value
       * @property {number} SELL=2 SELL value
       */
      ResponseDepthByOrderSnapshot.TransactionType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY')] = 1;
        values[(valuesById[2] = 'SELL')] = 2;
        return values;
      })();

      return ResponseDepthByOrderSnapshot;
    })();

    rti.RequestDepthByOrderUpdates = (function () {
      /**
       * Properties of a RequestDepthByOrderUpdates.
       * @memberof rti
       * @interface IRequestDepthByOrderUpdates
       * @property {number} templateId RequestDepthByOrderUpdates templateId
       * @property {Array.<string>|null} [userMsg] RequestDepthByOrderUpdates userMsg
       * @property {rti.RequestDepthByOrderUpdates.Request|null} [request] RequestDepthByOrderUpdates request
       * @property {string|null} [symbol] RequestDepthByOrderUpdates symbol
       * @property {string|null} [exchange] RequestDepthByOrderUpdates exchange
       * @property {number|null} [depthPrice] RequestDepthByOrderUpdates depthPrice
       */

      /**
       * Constructs a new RequestDepthByOrderUpdates.
       * @memberof rti
       * @classdesc Represents a RequestDepthByOrderUpdates.
       * @implements IRequestDepthByOrderUpdates
       * @constructor
       * @param {rti.IRequestDepthByOrderUpdates=} [properties] Properties to set
       */
      function RequestDepthByOrderUpdates(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestDepthByOrderUpdates templateId.
       * @member {number} templateId
       * @memberof rti.RequestDepthByOrderUpdates
       * @instance
       */
      RequestDepthByOrderUpdates.prototype.templateId = 0;

      /**
       * RequestDepthByOrderUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestDepthByOrderUpdates
       * @instance
       */
      RequestDepthByOrderUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * RequestDepthByOrderUpdates request.
       * @member {rti.RequestDepthByOrderUpdates.Request} request
       * @memberof rti.RequestDepthByOrderUpdates
       * @instance
       */
      RequestDepthByOrderUpdates.prototype.request = 1;

      /**
       * RequestDepthByOrderUpdates symbol.
       * @member {string} symbol
       * @memberof rti.RequestDepthByOrderUpdates
       * @instance
       */
      RequestDepthByOrderUpdates.prototype.symbol = '';

      /**
       * RequestDepthByOrderUpdates exchange.
       * @member {string} exchange
       * @memberof rti.RequestDepthByOrderUpdates
       * @instance
       */
      RequestDepthByOrderUpdates.prototype.exchange = '';

      /**
       * RequestDepthByOrderUpdates depthPrice.
       * @member {number} depthPrice
       * @memberof rti.RequestDepthByOrderUpdates
       * @instance
       */
      RequestDepthByOrderUpdates.prototype.depthPrice = 0;

      /**
       * Creates a new RequestDepthByOrderUpdates instance using the specified properties.
       * @function create
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {rti.IRequestDepthByOrderUpdates=} [properties] Properties to set
       * @returns {rti.RequestDepthByOrderUpdates} RequestDepthByOrderUpdates instance
       */
      RequestDepthByOrderUpdates.create = function create(properties) {
        return new RequestDepthByOrderUpdates(properties);
      };

      /**
       * Encodes the specified RequestDepthByOrderUpdates message. Does not implicitly {@link rti.RequestDepthByOrderUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {rti.IRequestDepthByOrderUpdates} message RequestDepthByOrderUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestDepthByOrderUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.request != null &&
          Object.hasOwnProperty.call(message, 'request')
        )
          writer
            .uint32(/* id 100000, wireType 0 =*/ 800000)
            .int32(message.request);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.depthPrice != null &&
          Object.hasOwnProperty.call(message, 'depthPrice')
        )
          writer
            .uint32(/* id 154405, wireType 1 =*/ 1235241)
            .double(message.depthPrice);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestDepthByOrderUpdates message, length delimited. Does not implicitly {@link rti.RequestDepthByOrderUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {rti.IRequestDepthByOrderUpdates} message RequestDepthByOrderUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestDepthByOrderUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestDepthByOrderUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestDepthByOrderUpdates} RequestDepthByOrderUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestDepthByOrderUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestDepthByOrderUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 100000:
              message.request = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 154405:
              message.depthPrice = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestDepthByOrderUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestDepthByOrderUpdates} RequestDepthByOrderUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestDepthByOrderUpdates.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestDepthByOrderUpdates message.
       * @function verify
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestDepthByOrderUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.request != null && message.hasOwnProperty('request'))
          switch (message.request) {
            default:
              return 'request: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.depthPrice != null && message.hasOwnProperty('depthPrice'))
          if (typeof message.depthPrice !== 'number')
            return 'depthPrice: number expected';
        return null;
      };

      /**
       * Creates a RequestDepthByOrderUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestDepthByOrderUpdates} RequestDepthByOrderUpdates
       */
      RequestDepthByOrderUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestDepthByOrderUpdates)
          return object;
        var message = new $root.rti.RequestDepthByOrderUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestDepthByOrderUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        switch (object.request) {
          case 'SUBSCRIBE':
          case 1:
            message.request = 1;
            break;
          case 'UNSUBSCRIBE':
          case 2:
            message.request = 2;
            break;
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.depthPrice != null)
          message.depthPrice = Number(object.depthPrice);
        return message;
      };

      /**
       * Creates a plain object from a RequestDepthByOrderUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {rti.RequestDepthByOrderUpdates} message RequestDepthByOrderUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestDepthByOrderUpdates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.request = options.enums === String ? 'SUBSCRIBE' : 1;
          object.symbol = '';
          object.exchange = '';
          object.depthPrice = 0;
          object.templateId = 0;
        }
        if (message.request != null && message.hasOwnProperty('request'))
          object.request =
            options.enums === String
              ? $root.rti.RequestDepthByOrderUpdates.Request[message.request]
              : message.request;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.depthPrice != null && message.hasOwnProperty('depthPrice'))
          object.depthPrice =
            options.json && !isFinite(message.depthPrice)
              ? String(message.depthPrice)
              : message.depthPrice;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestDepthByOrderUpdates to JSON.
       * @function toJSON
       * @memberof rti.RequestDepthByOrderUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestDepthByOrderUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestDepthByOrderUpdates
       * @function getTypeUrl
       * @memberof rti.RequestDepthByOrderUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestDepthByOrderUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestDepthByOrderUpdates';
      };

      /**
       * Request enum.
       * @name rti.RequestDepthByOrderUpdates.Request
       * @enum {number}
       * @property {number} SUBSCRIBE=1 SUBSCRIBE value
       * @property {number} UNSUBSCRIBE=2 UNSUBSCRIBE value
       */
      RequestDepthByOrderUpdates.Request = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SUBSCRIBE')] = 1;
        values[(valuesById[2] = 'UNSUBSCRIBE')] = 2;
        return values;
      })();

      return RequestDepthByOrderUpdates;
    })();

    rti.ResponseDepthByOrderUpdates = (function () {
      /**
       * Properties of a ResponseDepthByOrderUpdates.
       * @memberof rti
       * @interface IResponseDepthByOrderUpdates
       * @property {number} templateId ResponseDepthByOrderUpdates templateId
       * @property {Array.<string>|null} [userMsg] ResponseDepthByOrderUpdates userMsg
       * @property {Array.<string>|null} [rpCode] ResponseDepthByOrderUpdates rpCode
       */

      /**
       * Constructs a new ResponseDepthByOrderUpdates.
       * @memberof rti
       * @classdesc Represents a ResponseDepthByOrderUpdates.
       * @implements IResponseDepthByOrderUpdates
       * @constructor
       * @param {rti.IResponseDepthByOrderUpdates=} [properties] Properties to set
       */
      function ResponseDepthByOrderUpdates(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseDepthByOrderUpdates templateId.
       * @member {number} templateId
       * @memberof rti.ResponseDepthByOrderUpdates
       * @instance
       */
      ResponseDepthByOrderUpdates.prototype.templateId = 0;

      /**
       * ResponseDepthByOrderUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseDepthByOrderUpdates
       * @instance
       */
      ResponseDepthByOrderUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseDepthByOrderUpdates rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseDepthByOrderUpdates
       * @instance
       */
      ResponseDepthByOrderUpdates.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseDepthByOrderUpdates instance using the specified properties.
       * @function create
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {rti.IResponseDepthByOrderUpdates=} [properties] Properties to set
       * @returns {rti.ResponseDepthByOrderUpdates} ResponseDepthByOrderUpdates instance
       */
      ResponseDepthByOrderUpdates.create = function create(properties) {
        return new ResponseDepthByOrderUpdates(properties);
      };

      /**
       * Encodes the specified ResponseDepthByOrderUpdates message. Does not implicitly {@link rti.ResponseDepthByOrderUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {rti.IResponseDepthByOrderUpdates} message ResponseDepthByOrderUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseDepthByOrderUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseDepthByOrderUpdates message, length delimited. Does not implicitly {@link rti.ResponseDepthByOrderUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {rti.IResponseDepthByOrderUpdates} message ResponseDepthByOrderUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseDepthByOrderUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseDepthByOrderUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseDepthByOrderUpdates} ResponseDepthByOrderUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseDepthByOrderUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseDepthByOrderUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseDepthByOrderUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseDepthByOrderUpdates} ResponseDepthByOrderUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseDepthByOrderUpdates.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseDepthByOrderUpdates message.
       * @function verify
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseDepthByOrderUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseDepthByOrderUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseDepthByOrderUpdates} ResponseDepthByOrderUpdates
       */
      ResponseDepthByOrderUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseDepthByOrderUpdates)
          return object;
        var message = new $root.rti.ResponseDepthByOrderUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseDepthByOrderUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseDepthByOrderUpdates.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseDepthByOrderUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {rti.ResponseDepthByOrderUpdates} message ResponseDepthByOrderUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseDepthByOrderUpdates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseDepthByOrderUpdates to JSON.
       * @function toJSON
       * @memberof rti.ResponseDepthByOrderUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseDepthByOrderUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseDepthByOrderUpdates
       * @function getTypeUrl
       * @memberof rti.ResponseDepthByOrderUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseDepthByOrderUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseDepthByOrderUpdates';
      };

      return ResponseDepthByOrderUpdates;
    })();

    rti.RequestGetVolumeAtPrice = (function () {
      /**
       * Properties of a RequestGetVolumeAtPrice.
       * @memberof rti
       * @interface IRequestGetVolumeAtPrice
       * @property {number} templateId RequestGetVolumeAtPrice templateId
       * @property {Array.<string>|null} [userMsg] RequestGetVolumeAtPrice userMsg
       * @property {string|null} [symbol] RequestGetVolumeAtPrice symbol
       * @property {string|null} [exchange] RequestGetVolumeAtPrice exchange
       */

      /**
       * Constructs a new RequestGetVolumeAtPrice.
       * @memberof rti
       * @classdesc Represents a RequestGetVolumeAtPrice.
       * @implements IRequestGetVolumeAtPrice
       * @constructor
       * @param {rti.IRequestGetVolumeAtPrice=} [properties] Properties to set
       */
      function RequestGetVolumeAtPrice(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestGetVolumeAtPrice templateId.
       * @member {number} templateId
       * @memberof rti.RequestGetVolumeAtPrice
       * @instance
       */
      RequestGetVolumeAtPrice.prototype.templateId = 0;

      /**
       * RequestGetVolumeAtPrice userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestGetVolumeAtPrice
       * @instance
       */
      RequestGetVolumeAtPrice.prototype.userMsg = $util.emptyArray;

      /**
       * RequestGetVolumeAtPrice symbol.
       * @member {string} symbol
       * @memberof rti.RequestGetVolumeAtPrice
       * @instance
       */
      RequestGetVolumeAtPrice.prototype.symbol = '';

      /**
       * RequestGetVolumeAtPrice exchange.
       * @member {string} exchange
       * @memberof rti.RequestGetVolumeAtPrice
       * @instance
       */
      RequestGetVolumeAtPrice.prototype.exchange = '';

      /**
       * Creates a new RequestGetVolumeAtPrice instance using the specified properties.
       * @function create
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {rti.IRequestGetVolumeAtPrice=} [properties] Properties to set
       * @returns {rti.RequestGetVolumeAtPrice} RequestGetVolumeAtPrice instance
       */
      RequestGetVolumeAtPrice.create = function create(properties) {
        return new RequestGetVolumeAtPrice(properties);
      };

      /**
       * Encodes the specified RequestGetVolumeAtPrice message. Does not implicitly {@link rti.RequestGetVolumeAtPrice.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {rti.IRequestGetVolumeAtPrice} message RequestGetVolumeAtPrice message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestGetVolumeAtPrice.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestGetVolumeAtPrice message, length delimited. Does not implicitly {@link rti.RequestGetVolumeAtPrice.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {rti.IRequestGetVolumeAtPrice} message RequestGetVolumeAtPrice message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestGetVolumeAtPrice.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestGetVolumeAtPrice message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestGetVolumeAtPrice} RequestGetVolumeAtPrice
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestGetVolumeAtPrice.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestGetVolumeAtPrice();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestGetVolumeAtPrice message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestGetVolumeAtPrice} RequestGetVolumeAtPrice
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestGetVolumeAtPrice.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestGetVolumeAtPrice message.
       * @function verify
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestGetVolumeAtPrice.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        return null;
      };

      /**
       * Creates a RequestGetVolumeAtPrice message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestGetVolumeAtPrice} RequestGetVolumeAtPrice
       */
      RequestGetVolumeAtPrice.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestGetVolumeAtPrice) return object;
        var message = new $root.rti.RequestGetVolumeAtPrice();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestGetVolumeAtPrice.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        return message;
      };

      /**
       * Creates a plain object from a RequestGetVolumeAtPrice message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {rti.RequestGetVolumeAtPrice} message RequestGetVolumeAtPrice
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestGetVolumeAtPrice.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestGetVolumeAtPrice to JSON.
       * @function toJSON
       * @memberof rti.RequestGetVolumeAtPrice
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestGetVolumeAtPrice.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestGetVolumeAtPrice
       * @function getTypeUrl
       * @memberof rti.RequestGetVolumeAtPrice
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestGetVolumeAtPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestGetVolumeAtPrice';
      };

      return RequestGetVolumeAtPrice;
    })();

    rti.ResponseGetVolumeAtPrice = (function () {
      /**
       * Properties of a ResponseGetVolumeAtPrice.
       * @memberof rti
       * @interface IResponseGetVolumeAtPrice
       * @property {number} templateId ResponseGetVolumeAtPrice templateId
       * @property {Array.<string>|null} [userMsg] ResponseGetVolumeAtPrice userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseGetVolumeAtPrice rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseGetVolumeAtPrice rpCode
       * @property {string|null} [symbol] ResponseGetVolumeAtPrice symbol
       * @property {string|null} [exchange] ResponseGetVolumeAtPrice exchange
       * @property {Array.<number>|null} [tradePrice] ResponseGetVolumeAtPrice tradePrice
       * @property {Array.<number>|null} [volumeAtPrice] ResponseGetVolumeAtPrice volumeAtPrice
       * @property {number|null} [ssboe] ResponseGetVolumeAtPrice ssboe
       * @property {number|null} [usecs] ResponseGetVolumeAtPrice usecs
       */

      /**
       * Constructs a new ResponseGetVolumeAtPrice.
       * @memberof rti
       * @classdesc Represents a ResponseGetVolumeAtPrice.
       * @implements IResponseGetVolumeAtPrice
       * @constructor
       * @param {rti.IResponseGetVolumeAtPrice=} [properties] Properties to set
       */
      function ResponseGetVolumeAtPrice(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        this.tradePrice = [];
        this.volumeAtPrice = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseGetVolumeAtPrice templateId.
       * @member {number} templateId
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.templateId = 0;

      /**
       * ResponseGetVolumeAtPrice userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseGetVolumeAtPrice rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseGetVolumeAtPrice rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseGetVolumeAtPrice symbol.
       * @member {string} symbol
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.symbol = '';

      /**
       * ResponseGetVolumeAtPrice exchange.
       * @member {string} exchange
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.exchange = '';

      /**
       * ResponseGetVolumeAtPrice tradePrice.
       * @member {Array.<number>} tradePrice
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.tradePrice = $util.emptyArray;

      /**
       * ResponseGetVolumeAtPrice volumeAtPrice.
       * @member {Array.<number>} volumeAtPrice
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.volumeAtPrice = $util.emptyArray;

      /**
       * ResponseGetVolumeAtPrice ssboe.
       * @member {number} ssboe
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.ssboe = 0;

      /**
       * ResponseGetVolumeAtPrice usecs.
       * @member {number} usecs
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       */
      ResponseGetVolumeAtPrice.prototype.usecs = 0;

      /**
       * Creates a new ResponseGetVolumeAtPrice instance using the specified properties.
       * @function create
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {rti.IResponseGetVolumeAtPrice=} [properties] Properties to set
       * @returns {rti.ResponseGetVolumeAtPrice} ResponseGetVolumeAtPrice instance
       */
      ResponseGetVolumeAtPrice.create = function create(properties) {
        return new ResponseGetVolumeAtPrice(properties);
      };

      /**
       * Encodes the specified ResponseGetVolumeAtPrice message. Does not implicitly {@link rti.ResponseGetVolumeAtPrice.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {rti.IResponseGetVolumeAtPrice} message ResponseGetVolumeAtPrice message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseGetVolumeAtPrice.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.tradePrice != null && message.tradePrice.length)
          for (var i = 0; i < message.tradePrice.length; ++i)
            writer
              .uint32(/* id 100006, wireType 1 =*/ 800049)
              .double(message.tradePrice[i]);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (message.volumeAtPrice != null && message.volumeAtPrice.length)
          for (var i = 0; i < message.volumeAtPrice.length; ++i)
            writer
              .uint32(/* id 156980, wireType 0 =*/ 1255840)
              .int32(message.volumeAtPrice[i]);
        return writer;
      };

      /**
       * Encodes the specified ResponseGetVolumeAtPrice message, length delimited. Does not implicitly {@link rti.ResponseGetVolumeAtPrice.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {rti.IResponseGetVolumeAtPrice} message ResponseGetVolumeAtPrice message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseGetVolumeAtPrice.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseGetVolumeAtPrice message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseGetVolumeAtPrice} ResponseGetVolumeAtPrice
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseGetVolumeAtPrice.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseGetVolumeAtPrice();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100006:
              if (!(message.tradePrice && message.tradePrice.length))
                message.tradePrice = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.tradePrice.push(reader.double());
              } else message.tradePrice.push(reader.double());
              break;
            case 156980:
              if (!(message.volumeAtPrice && message.volumeAtPrice.length))
                message.volumeAtPrice = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.volumeAtPrice.push(reader.int32());
              } else message.volumeAtPrice.push(reader.int32());
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseGetVolumeAtPrice message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseGetVolumeAtPrice} ResponseGetVolumeAtPrice
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseGetVolumeAtPrice.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseGetVolumeAtPrice message.
       * @function verify
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseGetVolumeAtPrice.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.tradePrice != null &&
          message.hasOwnProperty('tradePrice')
        ) {
          if (!Array.isArray(message.tradePrice))
            return 'tradePrice: array expected';
          for (var i = 0; i < message.tradePrice.length; ++i)
            if (typeof message.tradePrice[i] !== 'number')
              return 'tradePrice: number[] expected';
        }
        if (
          message.volumeAtPrice != null &&
          message.hasOwnProperty('volumeAtPrice')
        ) {
          if (!Array.isArray(message.volumeAtPrice))
            return 'volumeAtPrice: array expected';
          for (var i = 0; i < message.volumeAtPrice.length; ++i)
            if (!$util.isInteger(message.volumeAtPrice[i]))
              return 'volumeAtPrice: integer[] expected';
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a ResponseGetVolumeAtPrice message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseGetVolumeAtPrice} ResponseGetVolumeAtPrice
       */
      ResponseGetVolumeAtPrice.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseGetVolumeAtPrice) return object;
        var message = new $root.rti.ResponseGetVolumeAtPrice();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseGetVolumeAtPrice.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseGetVolumeAtPrice.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseGetVolumeAtPrice.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.tradePrice) {
          if (!Array.isArray(object.tradePrice))
            throw TypeError(
              '.rti.ResponseGetVolumeAtPrice.tradePrice: array expected',
            );
          message.tradePrice = [];
          for (var i = 0; i < object.tradePrice.length; ++i)
            message.tradePrice[i] = Number(object.tradePrice[i]);
        }
        if (object.volumeAtPrice) {
          if (!Array.isArray(object.volumeAtPrice))
            throw TypeError(
              '.rti.ResponseGetVolumeAtPrice.volumeAtPrice: array expected',
            );
          message.volumeAtPrice = [];
          for (var i = 0; i < object.volumeAtPrice.length; ++i)
            message.volumeAtPrice[i] = object.volumeAtPrice[i] | 0;
        }
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a ResponseGetVolumeAtPrice message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {rti.ResponseGetVolumeAtPrice} message ResponseGetVolumeAtPrice
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseGetVolumeAtPrice.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.tradePrice = [];
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
          object.volumeAtPrice = [];
        }
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.templateId = 0;
        }
        if (message.tradePrice && message.tradePrice.length) {
          object.tradePrice = [];
          for (var j = 0; j < message.tradePrice.length; ++j)
            object.tradePrice[j] =
              options.json && !isFinite(message.tradePrice[j])
                ? String(message.tradePrice[j])
                : message.tradePrice[j];
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.volumeAtPrice && message.volumeAtPrice.length) {
          object.volumeAtPrice = [];
          for (var j = 0; j < message.volumeAtPrice.length; ++j)
            object.volumeAtPrice[j] = message.volumeAtPrice[j];
        }
        return object;
      };

      /**
       * Converts this ResponseGetVolumeAtPrice to JSON.
       * @function toJSON
       * @memberof rti.ResponseGetVolumeAtPrice
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseGetVolumeAtPrice.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseGetVolumeAtPrice
       * @function getTypeUrl
       * @memberof rti.ResponseGetVolumeAtPrice
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseGetVolumeAtPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseGetVolumeAtPrice';
      };

      return ResponseGetVolumeAtPrice;
    })();

    rti.BestBidOffer = (function () {
      /**
       * Properties of a BestBidOffer.
       * @memberof rti
       * @interface IBestBidOffer
       * @property {number} templateId BestBidOffer templateId
       * @property {string|null} [symbol] BestBidOffer symbol
       * @property {string|null} [exchange] BestBidOffer exchange
       * @property {number|null} [presenceBits] BestBidOffer presenceBits
       * @property {number|null} [clearBits] BestBidOffer clearBits
       * @property {boolean|null} [isSnapshot] BestBidOffer isSnapshot
       * @property {number|null} [bidPrice] BestBidOffer bidPrice
       * @property {number|null} [bidSize] BestBidOffer bidSize
       * @property {number|null} [bidOrders] BestBidOffer bidOrders
       * @property {number|null} [bidImplicitSize] BestBidOffer bidImplicitSize
       * @property {string|null} [bidTime] BestBidOffer bidTime
       * @property {number|null} [askPrice] BestBidOffer askPrice
       * @property {number|null} [askSize] BestBidOffer askSize
       * @property {number|null} [askOrders] BestBidOffer askOrders
       * @property {number|null} [askImplicitSize] BestBidOffer askImplicitSize
       * @property {string|null} [askTime] BestBidOffer askTime
       * @property {number|null} [leanPrice] BestBidOffer leanPrice
       * @property {number|null} [ssboe] BestBidOffer ssboe
       * @property {number|null} [usecs] BestBidOffer usecs
       */

      /**
       * Constructs a new BestBidOffer.
       * @memberof rti
       * @classdesc Represents a BestBidOffer.
       * @implements IBestBidOffer
       * @constructor
       * @param {rti.IBestBidOffer=} [properties] Properties to set
       */
      function BestBidOffer(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * BestBidOffer templateId.
       * @member {number} templateId
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.templateId = 0;

      /**
       * BestBidOffer symbol.
       * @member {string} symbol
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.symbol = '';

      /**
       * BestBidOffer exchange.
       * @member {string} exchange
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.exchange = '';

      /**
       * BestBidOffer presenceBits.
       * @member {number} presenceBits
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.presenceBits = 0;

      /**
       * BestBidOffer clearBits.
       * @member {number} clearBits
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.clearBits = 0;

      /**
       * BestBidOffer isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.isSnapshot = false;

      /**
       * BestBidOffer bidPrice.
       * @member {number} bidPrice
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.bidPrice = 0;

      /**
       * BestBidOffer bidSize.
       * @member {number} bidSize
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.bidSize = 0;

      /**
       * BestBidOffer bidOrders.
       * @member {number} bidOrders
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.bidOrders = 0;

      /**
       * BestBidOffer bidImplicitSize.
       * @member {number} bidImplicitSize
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.bidImplicitSize = 0;

      /**
       * BestBidOffer bidTime.
       * @member {string} bidTime
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.bidTime = '';

      /**
       * BestBidOffer askPrice.
       * @member {number} askPrice
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.askPrice = 0;

      /**
       * BestBidOffer askSize.
       * @member {number} askSize
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.askSize = 0;

      /**
       * BestBidOffer askOrders.
       * @member {number} askOrders
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.askOrders = 0;

      /**
       * BestBidOffer askImplicitSize.
       * @member {number} askImplicitSize
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.askImplicitSize = 0;

      /**
       * BestBidOffer askTime.
       * @member {string} askTime
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.askTime = '';

      /**
       * BestBidOffer leanPrice.
       * @member {number} leanPrice
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.leanPrice = 0;

      /**
       * BestBidOffer ssboe.
       * @member {number} ssboe
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.ssboe = 0;

      /**
       * BestBidOffer usecs.
       * @member {number} usecs
       * @memberof rti.BestBidOffer
       * @instance
       */
      BestBidOffer.prototype.usecs = 0;

      /**
       * Creates a new BestBidOffer instance using the specified properties.
       * @function create
       * @memberof rti.BestBidOffer
       * @static
       * @param {rti.IBestBidOffer=} [properties] Properties to set
       * @returns {rti.BestBidOffer} BestBidOffer instance
       */
      BestBidOffer.create = function create(properties) {
        return new BestBidOffer(properties);
      };

      /**
       * Encodes the specified BestBidOffer message. Does not implicitly {@link rti.BestBidOffer.verify|verify} messages.
       * @function encode
       * @memberof rti.BestBidOffer
       * @static
       * @param {rti.IBestBidOffer} message BestBidOffer message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      BestBidOffer.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.bidPrice != null &&
          Object.hasOwnProperty.call(message, 'bidPrice')
        )
          writer
            .uint32(/* id 100022, wireType 1 =*/ 800177)
            .double(message.bidPrice);
        if (
          message.askPrice != null &&
          Object.hasOwnProperty.call(message, 'askPrice')
        )
          writer
            .uint32(/* id 100025, wireType 1 =*/ 800201)
            .double(message.askPrice);
        if (
          message.bidSize != null &&
          Object.hasOwnProperty.call(message, 'bidSize')
        )
          writer
            .uint32(/* id 100030, wireType 0 =*/ 800240)
            .int32(message.bidSize);
        if (
          message.askSize != null &&
          Object.hasOwnProperty.call(message, 'askSize')
        )
          writer
            .uint32(/* id 100031, wireType 0 =*/ 800248)
            .int32(message.askSize);
        if (
          message.bidTime != null &&
          Object.hasOwnProperty.call(message, 'bidTime')
        )
          writer
            .uint32(/* id 100266, wireType 2 =*/ 802130)
            .string(message.bidTime);
        if (
          message.askTime != null &&
          Object.hasOwnProperty.call(message, 'askTime')
        )
          writer
            .uint32(/* id 100267, wireType 2 =*/ 802138)
            .string(message.askTime);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.bidOrders != null &&
          Object.hasOwnProperty.call(message, 'bidOrders')
        )
          writer
            .uint32(/* id 154403, wireType 0 =*/ 1235224)
            .int32(message.bidOrders);
        if (
          message.askOrders != null &&
          Object.hasOwnProperty.call(message, 'askOrders')
        )
          writer
            .uint32(/* id 154404, wireType 0 =*/ 1235232)
            .int32(message.askOrders);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        if (
          message.bidImplicitSize != null &&
          Object.hasOwnProperty.call(message, 'bidImplicitSize')
        )
          writer
            .uint32(/* id 154867, wireType 0 =*/ 1238936)
            .int32(message.bidImplicitSize);
        if (
          message.askImplicitSize != null &&
          Object.hasOwnProperty.call(message, 'askImplicitSize')
        )
          writer
            .uint32(/* id 154868, wireType 0 =*/ 1238944)
            .int32(message.askImplicitSize);
        if (
          message.leanPrice != null &&
          Object.hasOwnProperty.call(message, 'leanPrice')
        )
          writer
            .uint32(/* id 154909, wireType 1 =*/ 1239273)
            .double(message.leanPrice);
        return writer;
      };

      /**
       * Encodes the specified BestBidOffer message, length delimited. Does not implicitly {@link rti.BestBidOffer.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.BestBidOffer
       * @static
       * @param {rti.IBestBidOffer} message BestBidOffer message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      BestBidOffer.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a BestBidOffer message from the specified reader or buffer.
       * @function decode
       * @memberof rti.BestBidOffer
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.BestBidOffer} BestBidOffer
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      BestBidOffer.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.BestBidOffer();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 100022:
              message.bidPrice = reader.double();
              break;
            case 100030:
              message.bidSize = reader.int32();
              break;
            case 154403:
              message.bidOrders = reader.int32();
              break;
            case 154867:
              message.bidImplicitSize = reader.int32();
              break;
            case 100266:
              message.bidTime = reader.string();
              break;
            case 100025:
              message.askPrice = reader.double();
              break;
            case 100031:
              message.askSize = reader.int32();
              break;
            case 154404:
              message.askOrders = reader.int32();
              break;
            case 154868:
              message.askImplicitSize = reader.int32();
              break;
            case 100267:
              message.askTime = reader.string();
              break;
            case 154909:
              message.leanPrice = reader.double();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a BestBidOffer message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.BestBidOffer
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.BestBidOffer} BestBidOffer
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      BestBidOffer.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a BestBidOffer message.
       * @function verify
       * @memberof rti.BestBidOffer
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      BestBidOffer.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (message.bidPrice != null && message.hasOwnProperty('bidPrice'))
          if (typeof message.bidPrice !== 'number')
            return 'bidPrice: number expected';
        if (message.bidSize != null && message.hasOwnProperty('bidSize'))
          if (!$util.isInteger(message.bidSize))
            return 'bidSize: integer expected';
        if (message.bidOrders != null && message.hasOwnProperty('bidOrders'))
          if (!$util.isInteger(message.bidOrders))
            return 'bidOrders: integer expected';
        if (
          message.bidImplicitSize != null &&
          message.hasOwnProperty('bidImplicitSize')
        )
          if (!$util.isInteger(message.bidImplicitSize))
            return 'bidImplicitSize: integer expected';
        if (message.bidTime != null && message.hasOwnProperty('bidTime'))
          if (!$util.isString(message.bidTime))
            return 'bidTime: string expected';
        if (message.askPrice != null && message.hasOwnProperty('askPrice'))
          if (typeof message.askPrice !== 'number')
            return 'askPrice: number expected';
        if (message.askSize != null && message.hasOwnProperty('askSize'))
          if (!$util.isInteger(message.askSize))
            return 'askSize: integer expected';
        if (message.askOrders != null && message.hasOwnProperty('askOrders'))
          if (!$util.isInteger(message.askOrders))
            return 'askOrders: integer expected';
        if (
          message.askImplicitSize != null &&
          message.hasOwnProperty('askImplicitSize')
        )
          if (!$util.isInteger(message.askImplicitSize))
            return 'askImplicitSize: integer expected';
        if (message.askTime != null && message.hasOwnProperty('askTime'))
          if (!$util.isString(message.askTime))
            return 'askTime: string expected';
        if (message.leanPrice != null && message.hasOwnProperty('leanPrice'))
          if (typeof message.leanPrice !== 'number')
            return 'leanPrice: number expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a BestBidOffer message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.BestBidOffer
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.BestBidOffer} BestBidOffer
       */
      BestBidOffer.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.BestBidOffer) return object;
        var message = new $root.rti.BestBidOffer();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.bidPrice != null) message.bidPrice = Number(object.bidPrice);
        if (object.bidSize != null) message.bidSize = object.bidSize | 0;
        if (object.bidOrders != null) message.bidOrders = object.bidOrders | 0;
        if (object.bidImplicitSize != null)
          message.bidImplicitSize = object.bidImplicitSize | 0;
        if (object.bidTime != null) message.bidTime = String(object.bidTime);
        if (object.askPrice != null) message.askPrice = Number(object.askPrice);
        if (object.askSize != null) message.askSize = object.askSize | 0;
        if (object.askOrders != null) message.askOrders = object.askOrders | 0;
        if (object.askImplicitSize != null)
          message.askImplicitSize = object.askImplicitSize | 0;
        if (object.askTime != null) message.askTime = String(object.askTime);
        if (object.leanPrice != null)
          message.leanPrice = Number(object.leanPrice);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a BestBidOffer message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.BestBidOffer
       * @static
       * @param {rti.BestBidOffer} message BestBidOffer
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      BestBidOffer.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.bidPrice = 0;
          object.askPrice = 0;
          object.bidSize = 0;
          object.askSize = 0;
          object.bidTime = '';
          object.askTime = '';
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.presenceBits = 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.bidOrders = 0;
          object.askOrders = 0;
          object.templateId = 0;
          object.clearBits = 0;
          object.bidImplicitSize = 0;
          object.askImplicitSize = 0;
          object.leanPrice = 0;
        }
        if (message.bidPrice != null && message.hasOwnProperty('bidPrice'))
          object.bidPrice =
            options.json && !isFinite(message.bidPrice)
              ? String(message.bidPrice)
              : message.bidPrice;
        if (message.askPrice != null && message.hasOwnProperty('askPrice'))
          object.askPrice =
            options.json && !isFinite(message.askPrice)
              ? String(message.askPrice)
              : message.askPrice;
        if (message.bidSize != null && message.hasOwnProperty('bidSize'))
          object.bidSize = message.bidSize;
        if (message.askSize != null && message.hasOwnProperty('askSize'))
          object.askSize = message.askSize;
        if (message.bidTime != null && message.hasOwnProperty('bidTime'))
          object.bidTime = message.bidTime;
        if (message.askTime != null && message.hasOwnProperty('askTime'))
          object.askTime = message.askTime;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.bidOrders != null && message.hasOwnProperty('bidOrders'))
          object.bidOrders = message.bidOrders;
        if (message.askOrders != null && message.hasOwnProperty('askOrders'))
          object.askOrders = message.askOrders;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        if (
          message.bidImplicitSize != null &&
          message.hasOwnProperty('bidImplicitSize')
        )
          object.bidImplicitSize = message.bidImplicitSize;
        if (
          message.askImplicitSize != null &&
          message.hasOwnProperty('askImplicitSize')
        )
          object.askImplicitSize = message.askImplicitSize;
        if (message.leanPrice != null && message.hasOwnProperty('leanPrice'))
          object.leanPrice =
            options.json && !isFinite(message.leanPrice)
              ? String(message.leanPrice)
              : message.leanPrice;
        return object;
      };

      /**
       * Converts this BestBidOffer to JSON.
       * @function toJSON
       * @memberof rti.BestBidOffer
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      BestBidOffer.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for BestBidOffer
       * @function getTypeUrl
       * @memberof rti.BestBidOffer
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      BestBidOffer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.BestBidOffer';
      };

      /**
       * PresenceBits enum.
       * @name rti.BestBidOffer.PresenceBits
       * @enum {number}
       * @property {number} BID=1 BID value
       * @property {number} ASK=2 ASK value
       * @property {number} LEAN_PRICE=4 LEAN_PRICE value
       */
      BestBidOffer.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BID')] = 1;
        values[(valuesById[2] = 'ASK')] = 2;
        values[(valuesById[4] = 'LEAN_PRICE')] = 4;
        return values;
      })();

      return BestBidOffer;
    })();

    rti.OrderBook = (function () {
      /**
       * Properties of an OrderBook.
       * @memberof rti
       * @interface IOrderBook
       * @property {number} templateId OrderBook templateId
       * @property {string|null} [symbol] OrderBook symbol
       * @property {string|null} [exchange] OrderBook exchange
       * @property {number|null} [presenceBits] OrderBook presenceBits
       * @property {rti.OrderBook.UpdateType|null} [updateType] OrderBook updateType
       * @property {Array.<number>|null} [bidPrice] OrderBook bidPrice
       * @property {Array.<number>|null} [bidSize] OrderBook bidSize
       * @property {Array.<number>|null} [bidOrders] OrderBook bidOrders
       * @property {Array.<number>|null} [implBidSize] OrderBook implBidSize
       * @property {Array.<number>|null} [askPrice] OrderBook askPrice
       * @property {Array.<number>|null} [askSize] OrderBook askSize
       * @property {Array.<number>|null} [askOrders] OrderBook askOrders
       * @property {Array.<number>|null} [implAskSize] OrderBook implAskSize
       * @property {number|null} [ssboe] OrderBook ssboe
       * @property {number|null} [usecs] OrderBook usecs
       */

      /**
       * Constructs a new OrderBook.
       * @memberof rti
       * @classdesc Represents an OrderBook.
       * @implements IOrderBook
       * @constructor
       * @param {rti.IOrderBook=} [properties] Properties to set
       */
      function OrderBook(properties) {
        this.bidPrice = [];
        this.bidSize = [];
        this.bidOrders = [];
        this.implBidSize = [];
        this.askPrice = [];
        this.askSize = [];
        this.askOrders = [];
        this.implAskSize = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * OrderBook templateId.
       * @member {number} templateId
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.templateId = 0;

      /**
       * OrderBook symbol.
       * @member {string} symbol
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.symbol = '';

      /**
       * OrderBook exchange.
       * @member {string} exchange
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.exchange = '';

      /**
       * OrderBook presenceBits.
       * @member {number} presenceBits
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.presenceBits = 0;

      /**
       * OrderBook updateType.
       * @member {rti.OrderBook.UpdateType} updateType
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.updateType = 1;

      /**
       * OrderBook bidPrice.
       * @member {Array.<number>} bidPrice
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.bidPrice = $util.emptyArray;

      /**
       * OrderBook bidSize.
       * @member {Array.<number>} bidSize
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.bidSize = $util.emptyArray;

      /**
       * OrderBook bidOrders.
       * @member {Array.<number>} bidOrders
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.bidOrders = $util.emptyArray;

      /**
       * OrderBook implBidSize.
       * @member {Array.<number>} implBidSize
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.implBidSize = $util.emptyArray;

      /**
       * OrderBook askPrice.
       * @member {Array.<number>} askPrice
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.askPrice = $util.emptyArray;

      /**
       * OrderBook askSize.
       * @member {Array.<number>} askSize
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.askSize = $util.emptyArray;

      /**
       * OrderBook askOrders.
       * @member {Array.<number>} askOrders
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.askOrders = $util.emptyArray;

      /**
       * OrderBook implAskSize.
       * @member {Array.<number>} implAskSize
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.implAskSize = $util.emptyArray;

      /**
       * OrderBook ssboe.
       * @member {number} ssboe
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.ssboe = 0;

      /**
       * OrderBook usecs.
       * @member {number} usecs
       * @memberof rti.OrderBook
       * @instance
       */
      OrderBook.prototype.usecs = 0;

      /**
       * Creates a new OrderBook instance using the specified properties.
       * @function create
       * @memberof rti.OrderBook
       * @static
       * @param {rti.IOrderBook=} [properties] Properties to set
       * @returns {rti.OrderBook} OrderBook instance
       */
      OrderBook.create = function create(properties) {
        return new OrderBook(properties);
      };

      /**
       * Encodes the specified OrderBook message. Does not implicitly {@link rti.OrderBook.verify|verify} messages.
       * @function encode
       * @memberof rti.OrderBook
       * @static
       * @param {rti.IOrderBook} message OrderBook message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OrderBook.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (message.bidPrice != null && message.bidPrice.length)
          for (var i = 0; i < message.bidPrice.length; ++i)
            writer
              .uint32(/* id 154282, wireType 1 =*/ 1234257)
              .double(message.bidPrice[i]);
        if (message.bidSize != null && message.bidSize.length)
          for (var i = 0; i < message.bidSize.length; ++i)
            writer
              .uint32(/* id 154283, wireType 0 =*/ 1234264)
              .int32(message.bidSize[i]);
        if (message.askPrice != null && message.askPrice.length)
          for (var i = 0; i < message.askPrice.length; ++i)
            writer
              .uint32(/* id 154284, wireType 1 =*/ 1234273)
              .double(message.askPrice[i]);
        if (message.askSize != null && message.askSize.length)
          for (var i = 0; i < message.askSize.length; ++i)
            writer
              .uint32(/* id 154285, wireType 0 =*/ 1234280)
              .int32(message.askSize[i]);
        if (message.bidOrders != null && message.bidOrders.length)
          for (var i = 0; i < message.bidOrders.length; ++i)
            writer
              .uint32(/* id 154401, wireType 0 =*/ 1235208)
              .int32(message.bidOrders[i]);
        if (message.askOrders != null && message.askOrders.length)
          for (var i = 0; i < message.askOrders.length; ++i)
            writer
              .uint32(/* id 154402, wireType 0 =*/ 1235216)
              .int32(message.askOrders[i]);
        if (message.implBidSize != null && message.implBidSize.length)
          for (var i = 0; i < message.implBidSize.length; ++i)
            writer
              .uint32(/* id 154412, wireType 0 =*/ 1235296)
              .int32(message.implBidSize[i]);
        if (message.implAskSize != null && message.implAskSize.length)
          for (var i = 0; i < message.implAskSize.length; ++i)
            writer
              .uint32(/* id 154415, wireType 0 =*/ 1235320)
              .int32(message.implAskSize[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.updateType != null &&
          Object.hasOwnProperty.call(message, 'updateType')
        )
          writer
            .uint32(/* id 157608, wireType 0 =*/ 1260864)
            .int32(message.updateType);
        return writer;
      };

      /**
       * Encodes the specified OrderBook message, length delimited. Does not implicitly {@link rti.OrderBook.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.OrderBook
       * @static
       * @param {rti.IOrderBook} message OrderBook message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OrderBook.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an OrderBook message from the specified reader or buffer.
       * @function decode
       * @memberof rti.OrderBook
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.OrderBook} OrderBook
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OrderBook.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.OrderBook();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 157608:
              message.updateType = reader.int32();
              break;
            case 154282:
              if (!(message.bidPrice && message.bidPrice.length))
                message.bidPrice = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.bidPrice.push(reader.double());
              } else message.bidPrice.push(reader.double());
              break;
            case 154283:
              if (!(message.bidSize && message.bidSize.length))
                message.bidSize = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.bidSize.push(reader.int32());
              } else message.bidSize.push(reader.int32());
              break;
            case 154401:
              if (!(message.bidOrders && message.bidOrders.length))
                message.bidOrders = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.bidOrders.push(reader.int32());
              } else message.bidOrders.push(reader.int32());
              break;
            case 154412:
              if (!(message.implBidSize && message.implBidSize.length))
                message.implBidSize = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.implBidSize.push(reader.int32());
              } else message.implBidSize.push(reader.int32());
              break;
            case 154284:
              if (!(message.askPrice && message.askPrice.length))
                message.askPrice = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.askPrice.push(reader.double());
              } else message.askPrice.push(reader.double());
              break;
            case 154285:
              if (!(message.askSize && message.askSize.length))
                message.askSize = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.askSize.push(reader.int32());
              } else message.askSize.push(reader.int32());
              break;
            case 154402:
              if (!(message.askOrders && message.askOrders.length))
                message.askOrders = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.askOrders.push(reader.int32());
              } else message.askOrders.push(reader.int32());
              break;
            case 154415:
              if (!(message.implAskSize && message.implAskSize.length))
                message.implAskSize = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.implAskSize.push(reader.int32());
              } else message.implAskSize.push(reader.int32());
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an OrderBook message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.OrderBook
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.OrderBook} OrderBook
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OrderBook.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an OrderBook message.
       * @function verify
       * @memberof rti.OrderBook
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      OrderBook.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.updateType != null && message.hasOwnProperty('updateType'))
          switch (message.updateType) {
            default:
              return 'updateType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
              break;
          }
        if (message.bidPrice != null && message.hasOwnProperty('bidPrice')) {
          if (!Array.isArray(message.bidPrice))
            return 'bidPrice: array expected';
          for (var i = 0; i < message.bidPrice.length; ++i)
            if (typeof message.bidPrice[i] !== 'number')
              return 'bidPrice: number[] expected';
        }
        if (message.bidSize != null && message.hasOwnProperty('bidSize')) {
          if (!Array.isArray(message.bidSize)) return 'bidSize: array expected';
          for (var i = 0; i < message.bidSize.length; ++i)
            if (!$util.isInteger(message.bidSize[i]))
              return 'bidSize: integer[] expected';
        }
        if (message.bidOrders != null && message.hasOwnProperty('bidOrders')) {
          if (!Array.isArray(message.bidOrders))
            return 'bidOrders: array expected';
          for (var i = 0; i < message.bidOrders.length; ++i)
            if (!$util.isInteger(message.bidOrders[i]))
              return 'bidOrders: integer[] expected';
        }
        if (
          message.implBidSize != null &&
          message.hasOwnProperty('implBidSize')
        ) {
          if (!Array.isArray(message.implBidSize))
            return 'implBidSize: array expected';
          for (var i = 0; i < message.implBidSize.length; ++i)
            if (!$util.isInteger(message.implBidSize[i]))
              return 'implBidSize: integer[] expected';
        }
        if (message.askPrice != null && message.hasOwnProperty('askPrice')) {
          if (!Array.isArray(message.askPrice))
            return 'askPrice: array expected';
          for (var i = 0; i < message.askPrice.length; ++i)
            if (typeof message.askPrice[i] !== 'number')
              return 'askPrice: number[] expected';
        }
        if (message.askSize != null && message.hasOwnProperty('askSize')) {
          if (!Array.isArray(message.askSize)) return 'askSize: array expected';
          for (var i = 0; i < message.askSize.length; ++i)
            if (!$util.isInteger(message.askSize[i]))
              return 'askSize: integer[] expected';
        }
        if (message.askOrders != null && message.hasOwnProperty('askOrders')) {
          if (!Array.isArray(message.askOrders))
            return 'askOrders: array expected';
          for (var i = 0; i < message.askOrders.length; ++i)
            if (!$util.isInteger(message.askOrders[i]))
              return 'askOrders: integer[] expected';
        }
        if (
          message.implAskSize != null &&
          message.hasOwnProperty('implAskSize')
        ) {
          if (!Array.isArray(message.implAskSize))
            return 'implAskSize: array expected';
          for (var i = 0; i < message.implAskSize.length; ++i)
            if (!$util.isInteger(message.implAskSize[i]))
              return 'implAskSize: integer[] expected';
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates an OrderBook message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.OrderBook
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.OrderBook} OrderBook
       */
      OrderBook.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.OrderBook) return object;
        var message = new $root.rti.OrderBook();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        switch (object.updateType) {
          case 'CLEAR_ORDER_BOOK':
          case 1:
            message.updateType = 1;
            break;
          case 'NO_BOOK':
          case 2:
            message.updateType = 2;
            break;
          case 'SNAPSHOT_IMAGE':
          case 3:
            message.updateType = 3;
            break;
          case 'BEGIN':
          case 4:
            message.updateType = 4;
            break;
          case 'MIDDLE':
          case 5:
            message.updateType = 5;
            break;
          case 'END':
          case 6:
            message.updateType = 6;
            break;
          case 'SOLO':
          case 7:
            message.updateType = 7;
            break;
        }
        if (object.bidPrice) {
          if (!Array.isArray(object.bidPrice))
            throw TypeError('.rti.OrderBook.bidPrice: array expected');
          message.bidPrice = [];
          for (var i = 0; i < object.bidPrice.length; ++i)
            message.bidPrice[i] = Number(object.bidPrice[i]);
        }
        if (object.bidSize) {
          if (!Array.isArray(object.bidSize))
            throw TypeError('.rti.OrderBook.bidSize: array expected');
          message.bidSize = [];
          for (var i = 0; i < object.bidSize.length; ++i)
            message.bidSize[i] = object.bidSize[i] | 0;
        }
        if (object.bidOrders) {
          if (!Array.isArray(object.bidOrders))
            throw TypeError('.rti.OrderBook.bidOrders: array expected');
          message.bidOrders = [];
          for (var i = 0; i < object.bidOrders.length; ++i)
            message.bidOrders[i] = object.bidOrders[i] | 0;
        }
        if (object.implBidSize) {
          if (!Array.isArray(object.implBidSize))
            throw TypeError('.rti.OrderBook.implBidSize: array expected');
          message.implBidSize = [];
          for (var i = 0; i < object.implBidSize.length; ++i)
            message.implBidSize[i] = object.implBidSize[i] | 0;
        }
        if (object.askPrice) {
          if (!Array.isArray(object.askPrice))
            throw TypeError('.rti.OrderBook.askPrice: array expected');
          message.askPrice = [];
          for (var i = 0; i < object.askPrice.length; ++i)
            message.askPrice[i] = Number(object.askPrice[i]);
        }
        if (object.askSize) {
          if (!Array.isArray(object.askSize))
            throw TypeError('.rti.OrderBook.askSize: array expected');
          message.askSize = [];
          for (var i = 0; i < object.askSize.length; ++i)
            message.askSize[i] = object.askSize[i] | 0;
        }
        if (object.askOrders) {
          if (!Array.isArray(object.askOrders))
            throw TypeError('.rti.OrderBook.askOrders: array expected');
          message.askOrders = [];
          for (var i = 0; i < object.askOrders.length; ++i)
            message.askOrders[i] = object.askOrders[i] | 0;
        }
        if (object.implAskSize) {
          if (!Array.isArray(object.implAskSize))
            throw TypeError('.rti.OrderBook.implAskSize: array expected');
          message.implAskSize = [];
          for (var i = 0; i < object.implAskSize.length; ++i)
            message.implAskSize[i] = object.implAskSize[i] | 0;
        }
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from an OrderBook message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.OrderBook
       * @static
       * @param {rti.OrderBook} message OrderBook
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      OrderBook.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.bidPrice = [];
          object.bidSize = [];
          object.askPrice = [];
          object.askSize = [];
          object.bidOrders = [];
          object.askOrders = [];
          object.implBidSize = [];
          object.implAskSize = [];
        }
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.presenceBits = 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.templateId = 0;
          object.updateType = options.enums === String ? 'CLEAR_ORDER_BOOK' : 1;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.bidPrice && message.bidPrice.length) {
          object.bidPrice = [];
          for (var j = 0; j < message.bidPrice.length; ++j)
            object.bidPrice[j] =
              options.json && !isFinite(message.bidPrice[j])
                ? String(message.bidPrice[j])
                : message.bidPrice[j];
        }
        if (message.bidSize && message.bidSize.length) {
          object.bidSize = [];
          for (var j = 0; j < message.bidSize.length; ++j)
            object.bidSize[j] = message.bidSize[j];
        }
        if (message.askPrice && message.askPrice.length) {
          object.askPrice = [];
          for (var j = 0; j < message.askPrice.length; ++j)
            object.askPrice[j] =
              options.json && !isFinite(message.askPrice[j])
                ? String(message.askPrice[j])
                : message.askPrice[j];
        }
        if (message.askSize && message.askSize.length) {
          object.askSize = [];
          for (var j = 0; j < message.askSize.length; ++j)
            object.askSize[j] = message.askSize[j];
        }
        if (message.bidOrders && message.bidOrders.length) {
          object.bidOrders = [];
          for (var j = 0; j < message.bidOrders.length; ++j)
            object.bidOrders[j] = message.bidOrders[j];
        }
        if (message.askOrders && message.askOrders.length) {
          object.askOrders = [];
          for (var j = 0; j < message.askOrders.length; ++j)
            object.askOrders[j] = message.askOrders[j];
        }
        if (message.implBidSize && message.implBidSize.length) {
          object.implBidSize = [];
          for (var j = 0; j < message.implBidSize.length; ++j)
            object.implBidSize[j] = message.implBidSize[j];
        }
        if (message.implAskSize && message.implAskSize.length) {
          object.implAskSize = [];
          for (var j = 0; j < message.implAskSize.length; ++j)
            object.implAskSize[j] = message.implAskSize[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.updateType != null && message.hasOwnProperty('updateType'))
          object.updateType =
            options.enums === String
              ? $root.rti.OrderBook.UpdateType[message.updateType]
              : message.updateType;
        return object;
      };

      /**
       * Converts this OrderBook to JSON.
       * @function toJSON
       * @memberof rti.OrderBook
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      OrderBook.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for OrderBook
       * @function getTypeUrl
       * @memberof rti.OrderBook
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      OrderBook.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.OrderBook';
      };

      /**
       * PresenceBits enum.
       * @name rti.OrderBook.PresenceBits
       * @enum {number}
       * @property {number} BID=1 BID value
       * @property {number} ASK=2 ASK value
       */
      OrderBook.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BID')] = 1;
        values[(valuesById[2] = 'ASK')] = 2;
        return values;
      })();

      /**
       * UpdateType enum.
       * @name rti.OrderBook.UpdateType
       * @enum {number}
       * @property {number} CLEAR_ORDER_BOOK=1 CLEAR_ORDER_BOOK value
       * @property {number} NO_BOOK=2 NO_BOOK value
       * @property {number} SNAPSHOT_IMAGE=3 SNAPSHOT_IMAGE value
       * @property {number} BEGIN=4 BEGIN value
       * @property {number} MIDDLE=5 MIDDLE value
       * @property {number} END=6 END value
       * @property {number} SOLO=7 SOLO value
       */
      OrderBook.UpdateType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'CLEAR_ORDER_BOOK')] = 1;
        values[(valuesById[2] = 'NO_BOOK')] = 2;
        values[(valuesById[3] = 'SNAPSHOT_IMAGE')] = 3;
        values[(valuesById[4] = 'BEGIN')] = 4;
        values[(valuesById[5] = 'MIDDLE')] = 5;
        values[(valuesById[6] = 'END')] = 6;
        values[(valuesById[7] = 'SOLO')] = 7;
        return values;
      })();

      return OrderBook;
    })();

    rti.LastTrade = (function () {
      /**
       * Properties of a LastTrade.
       * @memberof rti
       * @interface ILastTrade
       * @property {number} templateId LastTrade templateId
       * @property {string|null} [symbol] LastTrade symbol
       * @property {string|null} [exchange] LastTrade exchange
       * @property {number|null} [presenceBits] LastTrade presenceBits
       * @property {number|null} [clearBits] LastTrade clearBits
       * @property {boolean|null} [isSnapshot] LastTrade isSnapshot
       * @property {number|null} [tradePrice] LastTrade tradePrice
       * @property {number|null} [tradeSize] LastTrade tradeSize
       * @property {rti.LastTrade.TransactionType|null} [aggressor] LastTrade aggressor
       * @property {string|null} [exchangeOrderId] LastTrade exchangeOrderId
       * @property {string|null} [aggressorExchangeOrderId] LastTrade aggressorExchangeOrderId
       * @property {number|null} [netChange] LastTrade netChange
       * @property {number|null} [percentChange] LastTrade percentChange
       * @property {number|Long|null} [volume] LastTrade volume
       * @property {number|null} [vwap] LastTrade vwap
       * @property {string|null} [tradeTime] LastTrade tradeTime
       * @property {number|null} [ssboe] LastTrade ssboe
       * @property {number|null} [usecs] LastTrade usecs
       * @property {number|null} [sourceSsboe] LastTrade sourceSsboe
       * @property {number|null} [sourceUsecs] LastTrade sourceUsecs
       * @property {number|null} [sourceNsecs] LastTrade sourceNsecs
       * @property {number|null} [jopSsboe] LastTrade jopSsboe
       * @property {number|null} [jopNsecs] LastTrade jopNsecs
       */

      /**
       * Constructs a new LastTrade.
       * @memberof rti
       * @classdesc Represents a LastTrade.
       * @implements ILastTrade
       * @constructor
       * @param {rti.ILastTrade=} [properties] Properties to set
       */
      function LastTrade(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * LastTrade templateId.
       * @member {number} templateId
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.templateId = 0;

      /**
       * LastTrade symbol.
       * @member {string} symbol
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.symbol = '';

      /**
       * LastTrade exchange.
       * @member {string} exchange
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.exchange = '';

      /**
       * LastTrade presenceBits.
       * @member {number} presenceBits
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.presenceBits = 0;

      /**
       * LastTrade clearBits.
       * @member {number} clearBits
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.clearBits = 0;

      /**
       * LastTrade isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.isSnapshot = false;

      /**
       * LastTrade tradePrice.
       * @member {number} tradePrice
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.tradePrice = 0;

      /**
       * LastTrade tradeSize.
       * @member {number} tradeSize
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.tradeSize = 0;

      /**
       * LastTrade aggressor.
       * @member {rti.LastTrade.TransactionType} aggressor
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.aggressor = 1;

      /**
       * LastTrade exchangeOrderId.
       * @member {string} exchangeOrderId
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.exchangeOrderId = '';

      /**
       * LastTrade aggressorExchangeOrderId.
       * @member {string} aggressorExchangeOrderId
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.aggressorExchangeOrderId = '';

      /**
       * LastTrade netChange.
       * @member {number} netChange
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.netChange = 0;

      /**
       * LastTrade percentChange.
       * @member {number} percentChange
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.percentChange = 0;

      /**
       * LastTrade volume.
       * @member {number|Long} volume
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.volume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * LastTrade vwap.
       * @member {number} vwap
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.vwap = 0;

      /**
       * LastTrade tradeTime.
       * @member {string} tradeTime
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.tradeTime = '';

      /**
       * LastTrade ssboe.
       * @member {number} ssboe
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.ssboe = 0;

      /**
       * LastTrade usecs.
       * @member {number} usecs
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.usecs = 0;

      /**
       * LastTrade sourceSsboe.
       * @member {number} sourceSsboe
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.sourceSsboe = 0;

      /**
       * LastTrade sourceUsecs.
       * @member {number} sourceUsecs
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.sourceUsecs = 0;

      /**
       * LastTrade sourceNsecs.
       * @member {number} sourceNsecs
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.sourceNsecs = 0;

      /**
       * LastTrade jopSsboe.
       * @member {number} jopSsboe
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.jopSsboe = 0;

      /**
       * LastTrade jopNsecs.
       * @member {number} jopNsecs
       * @memberof rti.LastTrade
       * @instance
       */
      LastTrade.prototype.jopNsecs = 0;

      /**
       * Creates a new LastTrade instance using the specified properties.
       * @function create
       * @memberof rti.LastTrade
       * @static
       * @param {rti.ILastTrade=} [properties] Properties to set
       * @returns {rti.LastTrade} LastTrade instance
       */
      LastTrade.create = function create(properties) {
        return new LastTrade(properties);
      };

      /**
       * Encodes the specified LastTrade message. Does not implicitly {@link rti.LastTrade.verify|verify} messages.
       * @function encode
       * @memberof rti.LastTrade
       * @static
       * @param {rti.ILastTrade} message LastTrade message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      LastTrade.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.tradePrice != null &&
          Object.hasOwnProperty.call(message, 'tradePrice')
        )
          writer
            .uint32(/* id 100006, wireType 1 =*/ 800049)
            .double(message.tradePrice);
        if (
          message.netChange != null &&
          Object.hasOwnProperty.call(message, 'netChange')
        )
          writer
            .uint32(/* id 100011, wireType 1 =*/ 800089)
            .double(message.netChange);
        if (
          message.volume != null &&
          Object.hasOwnProperty.call(message, 'volume')
        )
          writer
            .uint32(/* id 100032, wireType 0 =*/ 800256)
            .uint64(message.volume);
        if (
          message.percentChange != null &&
          Object.hasOwnProperty.call(message, 'percentChange')
        )
          writer
            .uint32(/* id 100056, wireType 1 =*/ 800449)
            .double(message.percentChange);
        if (
          message.tradeSize != null &&
          Object.hasOwnProperty.call(message, 'tradeSize')
        )
          writer
            .uint32(/* id 100178, wireType 0 =*/ 801424)
            .int32(message.tradeSize);
        if (
          message.tradeTime != null &&
          Object.hasOwnProperty.call(message, 'tradeTime')
        )
          writer
            .uint32(/* id 100379, wireType 2 =*/ 803034)
            .string(message.tradeTime);
        if (message.vwap != null && Object.hasOwnProperty.call(message, 'vwap'))
          writer
            .uint32(/* id 101379, wireType 1 =*/ 811033)
            .double(message.vwap);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.aggressor != null &&
          Object.hasOwnProperty.call(message, 'aggressor')
        )
          writer
            .uint32(/* id 112003, wireType 0 =*/ 896024)
            .int32(message.aggressor);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.exchangeOrderId != null &&
          Object.hasOwnProperty.call(message, 'exchangeOrderId')
        )
          writer
            .uint32(/* id 149238, wireType 2 =*/ 1193906)
            .string(message.exchangeOrderId);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.sourceSsboe != null &&
          Object.hasOwnProperty.call(message, 'sourceSsboe')
        )
          writer
            .uint32(/* id 150400, wireType 0 =*/ 1203200)
            .int32(message.sourceSsboe);
        if (
          message.sourceUsecs != null &&
          Object.hasOwnProperty.call(message, 'sourceUsecs')
        )
          writer
            .uint32(/* id 150401, wireType 0 =*/ 1203208)
            .int32(message.sourceUsecs);
        if (
          message.sourceNsecs != null &&
          Object.hasOwnProperty.call(message, 'sourceNsecs')
        )
          writer
            .uint32(/* id 150404, wireType 0 =*/ 1203232)
            .int32(message.sourceNsecs);
        if (
          message.jopSsboe != null &&
          Object.hasOwnProperty.call(message, 'jopSsboe')
        )
          writer
            .uint32(/* id 150600, wireType 0 =*/ 1204800)
            .int32(message.jopSsboe);
        if (
          message.jopNsecs != null &&
          Object.hasOwnProperty.call(message, 'jopNsecs')
        )
          writer
            .uint32(/* id 150604, wireType 0 =*/ 1204832)
            .int32(message.jopNsecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        if (
          message.aggressorExchangeOrderId != null &&
          Object.hasOwnProperty.call(message, 'aggressorExchangeOrderId')
        )
          writer
            .uint32(/* id 154641, wireType 2 =*/ 1237130)
            .string(message.aggressorExchangeOrderId);
        return writer;
      };

      /**
       * Encodes the specified LastTrade message, length delimited. Does not implicitly {@link rti.LastTrade.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.LastTrade
       * @static
       * @param {rti.ILastTrade} message LastTrade message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      LastTrade.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a LastTrade message from the specified reader or buffer.
       * @function decode
       * @memberof rti.LastTrade
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.LastTrade} LastTrade
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      LastTrade.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.LastTrade();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 100006:
              message.tradePrice = reader.double();
              break;
            case 100178:
              message.tradeSize = reader.int32();
              break;
            case 112003:
              message.aggressor = reader.int32();
              break;
            case 149238:
              message.exchangeOrderId = reader.string();
              break;
            case 154641:
              message.aggressorExchangeOrderId = reader.string();
              break;
            case 100011:
              message.netChange = reader.double();
              break;
            case 100056:
              message.percentChange = reader.double();
              break;
            case 100032:
              message.volume = reader.uint64();
              break;
            case 101379:
              message.vwap = reader.double();
              break;
            case 100379:
              message.tradeTime = reader.string();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            case 150400:
              message.sourceSsboe = reader.int32();
              break;
            case 150401:
              message.sourceUsecs = reader.int32();
              break;
            case 150404:
              message.sourceNsecs = reader.int32();
              break;
            case 150600:
              message.jopSsboe = reader.int32();
              break;
            case 150604:
              message.jopNsecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a LastTrade message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.LastTrade
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.LastTrade} LastTrade
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      LastTrade.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a LastTrade message.
       * @function verify
       * @memberof rti.LastTrade
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      LastTrade.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (message.tradePrice != null && message.hasOwnProperty('tradePrice'))
          if (typeof message.tradePrice !== 'number')
            return 'tradePrice: number expected';
        if (message.tradeSize != null && message.hasOwnProperty('tradeSize'))
          if (!$util.isInteger(message.tradeSize))
            return 'tradeSize: integer expected';
        if (message.aggressor != null && message.hasOwnProperty('aggressor'))
          switch (message.aggressor) {
            default:
              return 'aggressor: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.exchangeOrderId != null &&
          message.hasOwnProperty('exchangeOrderId')
        )
          if (!$util.isString(message.exchangeOrderId))
            return 'exchangeOrderId: string expected';
        if (
          message.aggressorExchangeOrderId != null &&
          message.hasOwnProperty('aggressorExchangeOrderId')
        )
          if (!$util.isString(message.aggressorExchangeOrderId))
            return 'aggressorExchangeOrderId: string expected';
        if (message.netChange != null && message.hasOwnProperty('netChange'))
          if (typeof message.netChange !== 'number')
            return 'netChange: number expected';
        if (
          message.percentChange != null &&
          message.hasOwnProperty('percentChange')
        )
          if (typeof message.percentChange !== 'number')
            return 'percentChange: number expected';
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (
            !$util.isInteger(message.volume) &&
            !(
              message.volume &&
              $util.isInteger(message.volume.low) &&
              $util.isInteger(message.volume.high)
            )
          )
            return 'volume: integer|Long expected';
        if (message.vwap != null && message.hasOwnProperty('vwap'))
          if (typeof message.vwap !== 'number') return 'vwap: number expected';
        if (message.tradeTime != null && message.hasOwnProperty('tradeTime'))
          if (!$util.isString(message.tradeTime))
            return 'tradeTime: string expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        if (
          message.sourceSsboe != null &&
          message.hasOwnProperty('sourceSsboe')
        )
          if (!$util.isInteger(message.sourceSsboe))
            return 'sourceSsboe: integer expected';
        if (
          message.sourceUsecs != null &&
          message.hasOwnProperty('sourceUsecs')
        )
          if (!$util.isInteger(message.sourceUsecs))
            return 'sourceUsecs: integer expected';
        if (
          message.sourceNsecs != null &&
          message.hasOwnProperty('sourceNsecs')
        )
          if (!$util.isInteger(message.sourceNsecs))
            return 'sourceNsecs: integer expected';
        if (message.jopSsboe != null && message.hasOwnProperty('jopSsboe'))
          if (!$util.isInteger(message.jopSsboe))
            return 'jopSsboe: integer expected';
        if (message.jopNsecs != null && message.hasOwnProperty('jopNsecs'))
          if (!$util.isInteger(message.jopNsecs))
            return 'jopNsecs: integer expected';
        return null;
      };

      /**
       * Creates a LastTrade message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.LastTrade
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.LastTrade} LastTrade
       */
      LastTrade.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.LastTrade) return object;
        var message = new $root.rti.LastTrade();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.tradePrice != null)
          message.tradePrice = Number(object.tradePrice);
        if (object.tradeSize != null) message.tradeSize = object.tradeSize | 0;
        switch (object.aggressor) {
          case 'BUY':
          case 1:
            message.aggressor = 1;
            break;
          case 'SELL':
          case 2:
            message.aggressor = 2;
            break;
        }
        if (object.exchangeOrderId != null)
          message.exchangeOrderId = String(object.exchangeOrderId);
        if (object.aggressorExchangeOrderId != null)
          message.aggressorExchangeOrderId = String(
            object.aggressorExchangeOrderId,
          );
        if (object.netChange != null)
          message.netChange = Number(object.netChange);
        if (object.percentChange != null)
          message.percentChange = Number(object.percentChange);
        if (object.volume != null)
          if ($util.Long)
            (message.volume = $util.Long.fromValue(object.volume)).unsigned =
              true;
          else if (typeof object.volume === 'string')
            message.volume = parseInt(object.volume, 10);
          else if (typeof object.volume === 'number')
            message.volume = object.volume;
          else if (typeof object.volume === 'object')
            message.volume = new $util.LongBits(
              object.volume.low >>> 0,
              object.volume.high >>> 0,
            ).toNumber(true);
        if (object.vwap != null) message.vwap = Number(object.vwap);
        if (object.tradeTime != null)
          message.tradeTime = String(object.tradeTime);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        if (object.sourceSsboe != null)
          message.sourceSsboe = object.sourceSsboe | 0;
        if (object.sourceUsecs != null)
          message.sourceUsecs = object.sourceUsecs | 0;
        if (object.sourceNsecs != null)
          message.sourceNsecs = object.sourceNsecs | 0;
        if (object.jopSsboe != null) message.jopSsboe = object.jopSsboe | 0;
        if (object.jopNsecs != null) message.jopNsecs = object.jopNsecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a LastTrade message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.LastTrade
       * @static
       * @param {rti.LastTrade} message LastTrade
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      LastTrade.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.tradePrice = 0;
          object.netChange = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.volume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.volume = options.longs === String ? '0' : 0;
          object.percentChange = 0;
          object.tradeSize = 0;
          object.tradeTime = '';
          object.vwap = 0;
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.aggressor = options.enums === String ? 'BUY' : 1;
          object.presenceBits = 0;
          object.exchangeOrderId = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.sourceSsboe = 0;
          object.sourceUsecs = 0;
          object.sourceNsecs = 0;
          object.jopSsboe = 0;
          object.jopNsecs = 0;
          object.templateId = 0;
          object.clearBits = 0;
          object.aggressorExchangeOrderId = '';
        }
        if (message.tradePrice != null && message.hasOwnProperty('tradePrice'))
          object.tradePrice =
            options.json && !isFinite(message.tradePrice)
              ? String(message.tradePrice)
              : message.tradePrice;
        if (message.netChange != null && message.hasOwnProperty('netChange'))
          object.netChange =
            options.json && !isFinite(message.netChange)
              ? String(message.netChange)
              : message.netChange;
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (typeof message.volume === 'number')
            object.volume =
              options.longs === String
                ? String(message.volume)
                : message.volume;
          else
            object.volume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.volume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.volume.low >>> 0,
                      message.volume.high >>> 0,
                    ).toNumber(true)
                  : message.volume;
        if (
          message.percentChange != null &&
          message.hasOwnProperty('percentChange')
        )
          object.percentChange =
            options.json && !isFinite(message.percentChange)
              ? String(message.percentChange)
              : message.percentChange;
        if (message.tradeSize != null && message.hasOwnProperty('tradeSize'))
          object.tradeSize = message.tradeSize;
        if (message.tradeTime != null && message.hasOwnProperty('tradeTime'))
          object.tradeTime = message.tradeTime;
        if (message.vwap != null && message.hasOwnProperty('vwap'))
          object.vwap =
            options.json && !isFinite(message.vwap)
              ? String(message.vwap)
              : message.vwap;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (message.aggressor != null && message.hasOwnProperty('aggressor'))
          object.aggressor =
            options.enums === String
              ? $root.rti.LastTrade.TransactionType[message.aggressor]
              : message.aggressor;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (
          message.exchangeOrderId != null &&
          message.hasOwnProperty('exchangeOrderId')
        )
          object.exchangeOrderId = message.exchangeOrderId;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (
          message.sourceSsboe != null &&
          message.hasOwnProperty('sourceSsboe')
        )
          object.sourceSsboe = message.sourceSsboe;
        if (
          message.sourceUsecs != null &&
          message.hasOwnProperty('sourceUsecs')
        )
          object.sourceUsecs = message.sourceUsecs;
        if (
          message.sourceNsecs != null &&
          message.hasOwnProperty('sourceNsecs')
        )
          object.sourceNsecs = message.sourceNsecs;
        if (message.jopSsboe != null && message.hasOwnProperty('jopSsboe'))
          object.jopSsboe = message.jopSsboe;
        if (message.jopNsecs != null && message.hasOwnProperty('jopNsecs'))
          object.jopNsecs = message.jopNsecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        if (
          message.aggressorExchangeOrderId != null &&
          message.hasOwnProperty('aggressorExchangeOrderId')
        )
          object.aggressorExchangeOrderId = message.aggressorExchangeOrderId;
        return object;
      };

      /**
       * Converts this LastTrade to JSON.
       * @function toJSON
       * @memberof rti.LastTrade
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      LastTrade.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for LastTrade
       * @function getTypeUrl
       * @memberof rti.LastTrade
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      LastTrade.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.LastTrade';
      };

      /**
       * PresenceBits enum.
       * @name rti.LastTrade.PresenceBits
       * @enum {number}
       * @property {number} LAST_TRADE=1 LAST_TRADE value
       * @property {number} NET_CHANGE=2 NET_CHANGE value
       * @property {number} PRECENT_CHANGE=4 PRECENT_CHANGE value
       * @property {number} VOLUME=8 VOLUME value
       * @property {number} VWAP=16 VWAP value
       */
      LastTrade.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LAST_TRADE')] = 1;
        values[(valuesById[2] = 'NET_CHANGE')] = 2;
        values[(valuesById[4] = 'PRECENT_CHANGE')] = 4;
        values[(valuesById[8] = 'VOLUME')] = 8;
        values[(valuesById[16] = 'VWAP')] = 16;
        return values;
      })();

      /**
       * TransactionType enum.
       * @name rti.LastTrade.TransactionType
       * @enum {number}
       * @property {number} BUY=1 BUY value
       * @property {number} SELL=2 SELL value
       */
      LastTrade.TransactionType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY')] = 1;
        values[(valuesById[2] = 'SELL')] = 2;
        return values;
      })();

      return LastTrade;
    })();

    rti.TradeStatistics = (function () {
      /**
       * Properties of a TradeStatistics.
       * @memberof rti
       * @interface ITradeStatistics
       * @property {number} templateId TradeStatistics templateId
       * @property {string|null} [symbol] TradeStatistics symbol
       * @property {string|null} [exchange] TradeStatistics exchange
       * @property {number|null} [presenceBits] TradeStatistics presenceBits
       * @property {number|null} [clearBits] TradeStatistics clearBits
       * @property {boolean|null} [isSnapshot] TradeStatistics isSnapshot
       * @property {number|null} [openPrice] TradeStatistics openPrice
       * @property {number|null} [highPrice] TradeStatistics highPrice
       * @property {number|null} [lowPrice] TradeStatistics lowPrice
       * @property {number|null} [ssboe] TradeStatistics ssboe
       * @property {number|null} [usecs] TradeStatistics usecs
       * @property {number|null} [sourceSsboe] TradeStatistics sourceSsboe
       * @property {number|null} [sourceUsecs] TradeStatistics sourceUsecs
       * @property {number|null} [sourceNsecs] TradeStatistics sourceNsecs
       * @property {number|null} [jopSsboe] TradeStatistics jopSsboe
       * @property {number|null} [jopNsecs] TradeStatistics jopNsecs
       */

      /**
       * Constructs a new TradeStatistics.
       * @memberof rti
       * @classdesc Represents a TradeStatistics.
       * @implements ITradeStatistics
       * @constructor
       * @param {rti.ITradeStatistics=} [properties] Properties to set
       */
      function TradeStatistics(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * TradeStatistics templateId.
       * @member {number} templateId
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.templateId = 0;

      /**
       * TradeStatistics symbol.
       * @member {string} symbol
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.symbol = '';

      /**
       * TradeStatistics exchange.
       * @member {string} exchange
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.exchange = '';

      /**
       * TradeStatistics presenceBits.
       * @member {number} presenceBits
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.presenceBits = 0;

      /**
       * TradeStatistics clearBits.
       * @member {number} clearBits
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.clearBits = 0;

      /**
       * TradeStatistics isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.isSnapshot = false;

      /**
       * TradeStatistics openPrice.
       * @member {number} openPrice
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.openPrice = 0;

      /**
       * TradeStatistics highPrice.
       * @member {number} highPrice
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.highPrice = 0;

      /**
       * TradeStatistics lowPrice.
       * @member {number} lowPrice
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.lowPrice = 0;

      /**
       * TradeStatistics ssboe.
       * @member {number} ssboe
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.ssboe = 0;

      /**
       * TradeStatistics usecs.
       * @member {number} usecs
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.usecs = 0;

      /**
       * TradeStatistics sourceSsboe.
       * @member {number} sourceSsboe
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.sourceSsboe = 0;

      /**
       * TradeStatistics sourceUsecs.
       * @member {number} sourceUsecs
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.sourceUsecs = 0;

      /**
       * TradeStatistics sourceNsecs.
       * @member {number} sourceNsecs
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.sourceNsecs = 0;

      /**
       * TradeStatistics jopSsboe.
       * @member {number} jopSsboe
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.jopSsboe = 0;

      /**
       * TradeStatistics jopNsecs.
       * @member {number} jopNsecs
       * @memberof rti.TradeStatistics
       * @instance
       */
      TradeStatistics.prototype.jopNsecs = 0;

      /**
       * Creates a new TradeStatistics instance using the specified properties.
       * @function create
       * @memberof rti.TradeStatistics
       * @static
       * @param {rti.ITradeStatistics=} [properties] Properties to set
       * @returns {rti.TradeStatistics} TradeStatistics instance
       */
      TradeStatistics.create = function create(properties) {
        return new TradeStatistics(properties);
      };

      /**
       * Encodes the specified TradeStatistics message. Does not implicitly {@link rti.TradeStatistics.verify|verify} messages.
       * @function encode
       * @memberof rti.TradeStatistics
       * @static
       * @param {rti.ITradeStatistics} message TradeStatistics message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TradeStatistics.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.highPrice != null &&
          Object.hasOwnProperty.call(message, 'highPrice')
        )
          writer
            .uint32(/* id 100012, wireType 1 =*/ 800097)
            .double(message.highPrice);
        if (
          message.lowPrice != null &&
          Object.hasOwnProperty.call(message, 'lowPrice')
        )
          writer
            .uint32(/* id 100013, wireType 1 =*/ 800105)
            .double(message.lowPrice);
        if (
          message.openPrice != null &&
          Object.hasOwnProperty.call(message, 'openPrice')
        )
          writer
            .uint32(/* id 100019, wireType 1 =*/ 800153)
            .double(message.openPrice);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.sourceSsboe != null &&
          Object.hasOwnProperty.call(message, 'sourceSsboe')
        )
          writer
            .uint32(/* id 150400, wireType 0 =*/ 1203200)
            .int32(message.sourceSsboe);
        if (
          message.sourceUsecs != null &&
          Object.hasOwnProperty.call(message, 'sourceUsecs')
        )
          writer
            .uint32(/* id 150401, wireType 0 =*/ 1203208)
            .int32(message.sourceUsecs);
        if (
          message.sourceNsecs != null &&
          Object.hasOwnProperty.call(message, 'sourceNsecs')
        )
          writer
            .uint32(/* id 150404, wireType 0 =*/ 1203232)
            .int32(message.sourceNsecs);
        if (
          message.jopSsboe != null &&
          Object.hasOwnProperty.call(message, 'jopSsboe')
        )
          writer
            .uint32(/* id 150600, wireType 0 =*/ 1204800)
            .int32(message.jopSsboe);
        if (
          message.jopNsecs != null &&
          Object.hasOwnProperty.call(message, 'jopNsecs')
        )
          writer
            .uint32(/* id 150604, wireType 0 =*/ 1204832)
            .int32(message.jopNsecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        return writer;
      };

      /**
       * Encodes the specified TradeStatistics message, length delimited. Does not implicitly {@link rti.TradeStatistics.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.TradeStatistics
       * @static
       * @param {rti.ITradeStatistics} message TradeStatistics message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TradeStatistics.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a TradeStatistics message from the specified reader or buffer.
       * @function decode
       * @memberof rti.TradeStatistics
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.TradeStatistics} TradeStatistics
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TradeStatistics.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.TradeStatistics();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 100019:
              message.openPrice = reader.double();
              break;
            case 100012:
              message.highPrice = reader.double();
              break;
            case 100013:
              message.lowPrice = reader.double();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            case 150400:
              message.sourceSsboe = reader.int32();
              break;
            case 150401:
              message.sourceUsecs = reader.int32();
              break;
            case 150404:
              message.sourceNsecs = reader.int32();
              break;
            case 150600:
              message.jopSsboe = reader.int32();
              break;
            case 150604:
              message.jopNsecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a TradeStatistics message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.TradeStatistics
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.TradeStatistics} TradeStatistics
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TradeStatistics.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a TradeStatistics message.
       * @function verify
       * @memberof rti.TradeStatistics
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      TradeStatistics.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          if (typeof message.openPrice !== 'number')
            return 'openPrice: number expected';
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          if (typeof message.highPrice !== 'number')
            return 'highPrice: number expected';
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          if (typeof message.lowPrice !== 'number')
            return 'lowPrice: number expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        if (
          message.sourceSsboe != null &&
          message.hasOwnProperty('sourceSsboe')
        )
          if (!$util.isInteger(message.sourceSsboe))
            return 'sourceSsboe: integer expected';
        if (
          message.sourceUsecs != null &&
          message.hasOwnProperty('sourceUsecs')
        )
          if (!$util.isInteger(message.sourceUsecs))
            return 'sourceUsecs: integer expected';
        if (
          message.sourceNsecs != null &&
          message.hasOwnProperty('sourceNsecs')
        )
          if (!$util.isInteger(message.sourceNsecs))
            return 'sourceNsecs: integer expected';
        if (message.jopSsboe != null && message.hasOwnProperty('jopSsboe'))
          if (!$util.isInteger(message.jopSsboe))
            return 'jopSsboe: integer expected';
        if (message.jopNsecs != null && message.hasOwnProperty('jopNsecs'))
          if (!$util.isInteger(message.jopNsecs))
            return 'jopNsecs: integer expected';
        return null;
      };

      /**
       * Creates a TradeStatistics message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.TradeStatistics
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.TradeStatistics} TradeStatistics
       */
      TradeStatistics.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.TradeStatistics) return object;
        var message = new $root.rti.TradeStatistics();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.openPrice != null)
          message.openPrice = Number(object.openPrice);
        if (object.highPrice != null)
          message.highPrice = Number(object.highPrice);
        if (object.lowPrice != null) message.lowPrice = Number(object.lowPrice);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        if (object.sourceSsboe != null)
          message.sourceSsboe = object.sourceSsboe | 0;
        if (object.sourceUsecs != null)
          message.sourceUsecs = object.sourceUsecs | 0;
        if (object.sourceNsecs != null)
          message.sourceNsecs = object.sourceNsecs | 0;
        if (object.jopSsboe != null) message.jopSsboe = object.jopSsboe | 0;
        if (object.jopNsecs != null) message.jopNsecs = object.jopNsecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a TradeStatistics message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.TradeStatistics
       * @static
       * @param {rti.TradeStatistics} message TradeStatistics
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      TradeStatistics.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.highPrice = 0;
          object.lowPrice = 0;
          object.openPrice = 0;
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.presenceBits = 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.sourceSsboe = 0;
          object.sourceUsecs = 0;
          object.sourceNsecs = 0;
          object.jopSsboe = 0;
          object.jopNsecs = 0;
          object.templateId = 0;
          object.clearBits = 0;
        }
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          object.highPrice =
            options.json && !isFinite(message.highPrice)
              ? String(message.highPrice)
              : message.highPrice;
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          object.lowPrice =
            options.json && !isFinite(message.lowPrice)
              ? String(message.lowPrice)
              : message.lowPrice;
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          object.openPrice =
            options.json && !isFinite(message.openPrice)
              ? String(message.openPrice)
              : message.openPrice;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (
          message.sourceSsboe != null &&
          message.hasOwnProperty('sourceSsboe')
        )
          object.sourceSsboe = message.sourceSsboe;
        if (
          message.sourceUsecs != null &&
          message.hasOwnProperty('sourceUsecs')
        )
          object.sourceUsecs = message.sourceUsecs;
        if (
          message.sourceNsecs != null &&
          message.hasOwnProperty('sourceNsecs')
        )
          object.sourceNsecs = message.sourceNsecs;
        if (message.jopSsboe != null && message.hasOwnProperty('jopSsboe'))
          object.jopSsboe = message.jopSsboe;
        if (message.jopNsecs != null && message.hasOwnProperty('jopNsecs'))
          object.jopNsecs = message.jopNsecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        return object;
      };

      /**
       * Converts this TradeStatistics to JSON.
       * @function toJSON
       * @memberof rti.TradeStatistics
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      TradeStatistics.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for TradeStatistics
       * @function getTypeUrl
       * @memberof rti.TradeStatistics
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      TradeStatistics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.TradeStatistics';
      };

      /**
       * PresenceBits enum.
       * @name rti.TradeStatistics.PresenceBits
       * @enum {number}
       * @property {number} OPEN=1 OPEN value
       * @property {number} HIGH=2 HIGH value
       * @property {number} LOW=4 LOW value
       */
      TradeStatistics.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'OPEN')] = 1;
        values[(valuesById[2] = 'HIGH')] = 2;
        values[(valuesById[4] = 'LOW')] = 4;
        return values;
      })();

      return TradeStatistics;
    })();

    rti.QuoteStatistics = (function () {
      /**
       * Properties of a QuoteStatistics.
       * @memberof rti
       * @interface IQuoteStatistics
       * @property {number} templateId QuoteStatistics templateId
       * @property {string|null} [symbol] QuoteStatistics symbol
       * @property {string|null} [exchange] QuoteStatistics exchange
       * @property {number|null} [presenceBits] QuoteStatistics presenceBits
       * @property {number|null} [clearBits] QuoteStatistics clearBits
       * @property {boolean|null} [isSnapshot] QuoteStatistics isSnapshot
       * @property {number|null} [highestBidPrice] QuoteStatistics highestBidPrice
       * @property {number|null} [lowestAskPrice] QuoteStatistics lowestAskPrice
       * @property {number|null} [ssboe] QuoteStatistics ssboe
       * @property {number|null} [usecs] QuoteStatistics usecs
       */

      /**
       * Constructs a new QuoteStatistics.
       * @memberof rti
       * @classdesc Represents a QuoteStatistics.
       * @implements IQuoteStatistics
       * @constructor
       * @param {rti.IQuoteStatistics=} [properties] Properties to set
       */
      function QuoteStatistics(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * QuoteStatistics templateId.
       * @member {number} templateId
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.templateId = 0;

      /**
       * QuoteStatistics symbol.
       * @member {string} symbol
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.symbol = '';

      /**
       * QuoteStatistics exchange.
       * @member {string} exchange
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.exchange = '';

      /**
       * QuoteStatistics presenceBits.
       * @member {number} presenceBits
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.presenceBits = 0;

      /**
       * QuoteStatistics clearBits.
       * @member {number} clearBits
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.clearBits = 0;

      /**
       * QuoteStatistics isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.isSnapshot = false;

      /**
       * QuoteStatistics highestBidPrice.
       * @member {number} highestBidPrice
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.highestBidPrice = 0;

      /**
       * QuoteStatistics lowestAskPrice.
       * @member {number} lowestAskPrice
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.lowestAskPrice = 0;

      /**
       * QuoteStatistics ssboe.
       * @member {number} ssboe
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.ssboe = 0;

      /**
       * QuoteStatistics usecs.
       * @member {number} usecs
       * @memberof rti.QuoteStatistics
       * @instance
       */
      QuoteStatistics.prototype.usecs = 0;

      /**
       * Creates a new QuoteStatistics instance using the specified properties.
       * @function create
       * @memberof rti.QuoteStatistics
       * @static
       * @param {rti.IQuoteStatistics=} [properties] Properties to set
       * @returns {rti.QuoteStatistics} QuoteStatistics instance
       */
      QuoteStatistics.create = function create(properties) {
        return new QuoteStatistics(properties);
      };

      /**
       * Encodes the specified QuoteStatistics message. Does not implicitly {@link rti.QuoteStatistics.verify|verify} messages.
       * @function encode
       * @memberof rti.QuoteStatistics
       * @static
       * @param {rti.IQuoteStatistics} message QuoteStatistics message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      QuoteStatistics.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.highestBidPrice != null &&
          Object.hasOwnProperty.call(message, 'highestBidPrice')
        )
          writer
            .uint32(/* id 154195, wireType 1 =*/ 1233561)
            .double(message.highestBidPrice);
        if (
          message.lowestAskPrice != null &&
          Object.hasOwnProperty.call(message, 'lowestAskPrice')
        )
          writer
            .uint32(/* id 154197, wireType 1 =*/ 1233577)
            .double(message.lowestAskPrice);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        return writer;
      };

      /**
       * Encodes the specified QuoteStatistics message, length delimited. Does not implicitly {@link rti.QuoteStatistics.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.QuoteStatistics
       * @static
       * @param {rti.IQuoteStatistics} message QuoteStatistics message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      QuoteStatistics.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a QuoteStatistics message from the specified reader or buffer.
       * @function decode
       * @memberof rti.QuoteStatistics
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.QuoteStatistics} QuoteStatistics
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      QuoteStatistics.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.QuoteStatistics();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 154195:
              message.highestBidPrice = reader.double();
              break;
            case 154197:
              message.lowestAskPrice = reader.double();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a QuoteStatistics message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.QuoteStatistics
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.QuoteStatistics} QuoteStatistics
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      QuoteStatistics.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a QuoteStatistics message.
       * @function verify
       * @memberof rti.QuoteStatistics
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      QuoteStatistics.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (
          message.highestBidPrice != null &&
          message.hasOwnProperty('highestBidPrice')
        )
          if (typeof message.highestBidPrice !== 'number')
            return 'highestBidPrice: number expected';
        if (
          message.lowestAskPrice != null &&
          message.hasOwnProperty('lowestAskPrice')
        )
          if (typeof message.lowestAskPrice !== 'number')
            return 'lowestAskPrice: number expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a QuoteStatistics message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.QuoteStatistics
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.QuoteStatistics} QuoteStatistics
       */
      QuoteStatistics.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.QuoteStatistics) return object;
        var message = new $root.rti.QuoteStatistics();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.highestBidPrice != null)
          message.highestBidPrice = Number(object.highestBidPrice);
        if (object.lowestAskPrice != null)
          message.lowestAskPrice = Number(object.lowestAskPrice);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a QuoteStatistics message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.QuoteStatistics
       * @static
       * @param {rti.QuoteStatistics} message QuoteStatistics
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      QuoteStatistics.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.presenceBits = 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.highestBidPrice = 0;
          object.lowestAskPrice = 0;
          object.templateId = 0;
          object.clearBits = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (
          message.highestBidPrice != null &&
          message.hasOwnProperty('highestBidPrice')
        )
          object.highestBidPrice =
            options.json && !isFinite(message.highestBidPrice)
              ? String(message.highestBidPrice)
              : message.highestBidPrice;
        if (
          message.lowestAskPrice != null &&
          message.hasOwnProperty('lowestAskPrice')
        )
          object.lowestAskPrice =
            options.json && !isFinite(message.lowestAskPrice)
              ? String(message.lowestAskPrice)
              : message.lowestAskPrice;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        return object;
      };

      /**
       * Converts this QuoteStatistics to JSON.
       * @function toJSON
       * @memberof rti.QuoteStatistics
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      QuoteStatistics.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for QuoteStatistics
       * @function getTypeUrl
       * @memberof rti.QuoteStatistics
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      QuoteStatistics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.QuoteStatistics';
      };

      /**
       * PresenceBits enum.
       * @name rti.QuoteStatistics.PresenceBits
       * @enum {number}
       * @property {number} HIGHEST_BID=1 HIGHEST_BID value
       * @property {number} LOWEST_ASK=2 LOWEST_ASK value
       */
      QuoteStatistics.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'HIGHEST_BID')] = 1;
        values[(valuesById[2] = 'LOWEST_ASK')] = 2;
        return values;
      })();

      return QuoteStatistics;
    })();

    rti.IndicatorPrices = (function () {
      /**
       * Properties of an IndicatorPrices.
       * @memberof rti
       * @interface IIndicatorPrices
       * @property {number} templateId IndicatorPrices templateId
       * @property {string|null} [symbol] IndicatorPrices symbol
       * @property {string|null} [exchange] IndicatorPrices exchange
       * @property {number|null} [presenceBits] IndicatorPrices presenceBits
       * @property {number|null} [clearBits] IndicatorPrices clearBits
       * @property {boolean|null} [isSnapshot] IndicatorPrices isSnapshot
       * @property {number|null} [openingIndicator] IndicatorPrices openingIndicator
       * @property {number|null} [closingIndicator] IndicatorPrices closingIndicator
       * @property {number|null} [ssboe] IndicatorPrices ssboe
       * @property {number|null} [usecs] IndicatorPrices usecs
       */

      /**
       * Constructs a new IndicatorPrices.
       * @memberof rti
       * @classdesc Represents an IndicatorPrices.
       * @implements IIndicatorPrices
       * @constructor
       * @param {rti.IIndicatorPrices=} [properties] Properties to set
       */
      function IndicatorPrices(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * IndicatorPrices templateId.
       * @member {number} templateId
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.templateId = 0;

      /**
       * IndicatorPrices symbol.
       * @member {string} symbol
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.symbol = '';

      /**
       * IndicatorPrices exchange.
       * @member {string} exchange
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.exchange = '';

      /**
       * IndicatorPrices presenceBits.
       * @member {number} presenceBits
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.presenceBits = 0;

      /**
       * IndicatorPrices clearBits.
       * @member {number} clearBits
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.clearBits = 0;

      /**
       * IndicatorPrices isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.isSnapshot = false;

      /**
       * IndicatorPrices openingIndicator.
       * @member {number} openingIndicator
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.openingIndicator = 0;

      /**
       * IndicatorPrices closingIndicator.
       * @member {number} closingIndicator
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.closingIndicator = 0;

      /**
       * IndicatorPrices ssboe.
       * @member {number} ssboe
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.ssboe = 0;

      /**
       * IndicatorPrices usecs.
       * @member {number} usecs
       * @memberof rti.IndicatorPrices
       * @instance
       */
      IndicatorPrices.prototype.usecs = 0;

      /**
       * Creates a new IndicatorPrices instance using the specified properties.
       * @function create
       * @memberof rti.IndicatorPrices
       * @static
       * @param {rti.IIndicatorPrices=} [properties] Properties to set
       * @returns {rti.IndicatorPrices} IndicatorPrices instance
       */
      IndicatorPrices.create = function create(properties) {
        return new IndicatorPrices(properties);
      };

      /**
       * Encodes the specified IndicatorPrices message. Does not implicitly {@link rti.IndicatorPrices.verify|verify} messages.
       * @function encode
       * @memberof rti.IndicatorPrices
       * @static
       * @param {rti.IIndicatorPrices} message IndicatorPrices message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      IndicatorPrices.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.closingIndicator != null &&
          Object.hasOwnProperty.call(message, 'closingIndicator')
        )
          writer
            .uint32(/* id 154064, wireType 1 =*/ 1232513)
            .double(message.closingIndicator);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.openingIndicator != null &&
          Object.hasOwnProperty.call(message, 'openingIndicator')
        )
          writer
            .uint32(/* id 154522, wireType 1 =*/ 1236177)
            .double(message.openingIndicator);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        return writer;
      };

      /**
       * Encodes the specified IndicatorPrices message, length delimited. Does not implicitly {@link rti.IndicatorPrices.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.IndicatorPrices
       * @static
       * @param {rti.IIndicatorPrices} message IndicatorPrices message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      IndicatorPrices.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an IndicatorPrices message from the specified reader or buffer.
       * @function decode
       * @memberof rti.IndicatorPrices
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.IndicatorPrices} IndicatorPrices
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      IndicatorPrices.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.IndicatorPrices();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 154522:
              message.openingIndicator = reader.double();
              break;
            case 154064:
              message.closingIndicator = reader.double();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an IndicatorPrices message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.IndicatorPrices
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.IndicatorPrices} IndicatorPrices
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      IndicatorPrices.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an IndicatorPrices message.
       * @function verify
       * @memberof rti.IndicatorPrices
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      IndicatorPrices.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (
          message.openingIndicator != null &&
          message.hasOwnProperty('openingIndicator')
        )
          if (typeof message.openingIndicator !== 'number')
            return 'openingIndicator: number expected';
        if (
          message.closingIndicator != null &&
          message.hasOwnProperty('closingIndicator')
        )
          if (typeof message.closingIndicator !== 'number')
            return 'closingIndicator: number expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates an IndicatorPrices message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.IndicatorPrices
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.IndicatorPrices} IndicatorPrices
       */
      IndicatorPrices.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.IndicatorPrices) return object;
        var message = new $root.rti.IndicatorPrices();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.openingIndicator != null)
          message.openingIndicator = Number(object.openingIndicator);
        if (object.closingIndicator != null)
          message.closingIndicator = Number(object.closingIndicator);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from an IndicatorPrices message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.IndicatorPrices
       * @static
       * @param {rti.IndicatorPrices} message IndicatorPrices
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      IndicatorPrices.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.presenceBits = 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.closingIndicator = 0;
          object.templateId = 0;
          object.openingIndicator = 0;
          object.clearBits = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (
          message.closingIndicator != null &&
          message.hasOwnProperty('closingIndicator')
        )
          object.closingIndicator =
            options.json && !isFinite(message.closingIndicator)
              ? String(message.closingIndicator)
              : message.closingIndicator;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.openingIndicator != null &&
          message.hasOwnProperty('openingIndicator')
        )
          object.openingIndicator =
            options.json && !isFinite(message.openingIndicator)
              ? String(message.openingIndicator)
              : message.openingIndicator;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        return object;
      };

      /**
       * Converts this IndicatorPrices to JSON.
       * @function toJSON
       * @memberof rti.IndicatorPrices
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      IndicatorPrices.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for IndicatorPrices
       * @function getTypeUrl
       * @memberof rti.IndicatorPrices
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      IndicatorPrices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.IndicatorPrices';
      };

      /**
       * PresenceBits enum.
       * @name rti.IndicatorPrices.PresenceBits
       * @enum {number}
       * @property {number} OPENING_INDICATOR=1 OPENING_INDICATOR value
       * @property {number} CLOSING_INDICATOR=2 CLOSING_INDICATOR value
       */
      IndicatorPrices.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'OPENING_INDICATOR')] = 1;
        values[(valuesById[2] = 'CLOSING_INDICATOR')] = 2;
        return values;
      })();

      return IndicatorPrices;
    })();

    rti.OpenInterest = (function () {
      /**
       * Properties of an OpenInterest.
       * @memberof rti
       * @interface IOpenInterest
       * @property {number} templateId OpenInterest templateId
       * @property {string|null} [symbol] OpenInterest symbol
       * @property {string|null} [exchange] OpenInterest exchange
       * @property {boolean|null} [isSnapshot] OpenInterest isSnapshot
       * @property {boolean|null} [shouldClear] OpenInterest shouldClear
       * @property {number|Long|null} [openInterest] OpenInterest openInterest
       * @property {number|null} [ssboe] OpenInterest ssboe
       * @property {number|null} [usecs] OpenInterest usecs
       */

      /**
       * Constructs a new OpenInterest.
       * @memberof rti
       * @classdesc Represents an OpenInterest.
       * @implements IOpenInterest
       * @constructor
       * @param {rti.IOpenInterest=} [properties] Properties to set
       */
      function OpenInterest(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * OpenInterest templateId.
       * @member {number} templateId
       * @memberof rti.OpenInterest
       * @instance
       */
      OpenInterest.prototype.templateId = 0;

      /**
       * OpenInterest symbol.
       * @member {string} symbol
       * @memberof rti.OpenInterest
       * @instance
       */
      OpenInterest.prototype.symbol = '';

      /**
       * OpenInterest exchange.
       * @member {string} exchange
       * @memberof rti.OpenInterest
       * @instance
       */
      OpenInterest.prototype.exchange = '';

      /**
       * OpenInterest isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.OpenInterest
       * @instance
       */
      OpenInterest.prototype.isSnapshot = false;

      /**
       * OpenInterest shouldClear.
       * @member {boolean} shouldClear
       * @memberof rti.OpenInterest
       * @instance
       */
      OpenInterest.prototype.shouldClear = false;

      /**
       * OpenInterest openInterest.
       * @member {number|Long} openInterest
       * @memberof rti.OpenInterest
       * @instance
       */
      OpenInterest.prototype.openInterest = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * OpenInterest ssboe.
       * @member {number} ssboe
       * @memberof rti.OpenInterest
       * @instance
       */
      OpenInterest.prototype.ssboe = 0;

      /**
       * OpenInterest usecs.
       * @member {number} usecs
       * @memberof rti.OpenInterest
       * @instance
       */
      OpenInterest.prototype.usecs = 0;

      /**
       * Creates a new OpenInterest instance using the specified properties.
       * @function create
       * @memberof rti.OpenInterest
       * @static
       * @param {rti.IOpenInterest=} [properties] Properties to set
       * @returns {rti.OpenInterest} OpenInterest instance
       */
      OpenInterest.create = function create(properties) {
        return new OpenInterest(properties);
      };

      /**
       * Encodes the specified OpenInterest message. Does not implicitly {@link rti.OpenInterest.verify|verify} messages.
       * @function encode
       * @memberof rti.OpenInterest
       * @static
       * @param {rti.IOpenInterest} message OpenInterest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OpenInterest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.openInterest != null &&
          Object.hasOwnProperty.call(message, 'openInterest')
        )
          writer
            .uint32(/* id 100064, wireType 0 =*/ 800512)
            .uint64(message.openInterest);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.shouldClear != null &&
          Object.hasOwnProperty.call(message, 'shouldClear')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .bool(message.shouldClear);
        return writer;
      };

      /**
       * Encodes the specified OpenInterest message, length delimited. Does not implicitly {@link rti.OpenInterest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.OpenInterest
       * @static
       * @param {rti.IOpenInterest} message OpenInterest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OpenInterest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an OpenInterest message from the specified reader or buffer.
       * @function decode
       * @memberof rti.OpenInterest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.OpenInterest} OpenInterest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OpenInterest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.OpenInterest();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 154571:
              message.shouldClear = reader.bool();
              break;
            case 100064:
              message.openInterest = reader.uint64();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an OpenInterest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.OpenInterest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.OpenInterest} OpenInterest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OpenInterest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an OpenInterest message.
       * @function verify
       * @memberof rti.OpenInterest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      OpenInterest.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (
          message.shouldClear != null &&
          message.hasOwnProperty('shouldClear')
        )
          if (typeof message.shouldClear !== 'boolean')
            return 'shouldClear: boolean expected';
        if (
          message.openInterest != null &&
          message.hasOwnProperty('openInterest')
        )
          if (
            !$util.isInteger(message.openInterest) &&
            !(
              message.openInterest &&
              $util.isInteger(message.openInterest.low) &&
              $util.isInteger(message.openInterest.high)
            )
          )
            return 'openInterest: integer|Long expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates an OpenInterest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.OpenInterest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.OpenInterest} OpenInterest
       */
      OpenInterest.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.OpenInterest) return object;
        var message = new $root.rti.OpenInterest();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.shouldClear != null)
          message.shouldClear = Boolean(object.shouldClear);
        if (object.openInterest != null)
          if ($util.Long)
            (message.openInterest = $util.Long.fromValue(
              object.openInterest,
            )).unsigned = true;
          else if (typeof object.openInterest === 'string')
            message.openInterest = parseInt(object.openInterest, 10);
          else if (typeof object.openInterest === 'number')
            message.openInterest = object.openInterest;
          else if (typeof object.openInterest === 'object')
            message.openInterest = new $util.LongBits(
              object.openInterest.low >>> 0,
              object.openInterest.high >>> 0,
            ).toNumber(true);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from an OpenInterest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.OpenInterest
       * @static
       * @param {rti.OpenInterest} message OpenInterest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      OpenInterest.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.openInterest =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.openInterest = options.longs === String ? '0' : 0;
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.ssboe = 0;
          object.usecs = 0;
          object.templateId = 0;
          object.shouldClear = false;
        }
        if (
          message.openInterest != null &&
          message.hasOwnProperty('openInterest')
        )
          if (typeof message.openInterest === 'number')
            object.openInterest =
              options.longs === String
                ? String(message.openInterest)
                : message.openInterest;
          else
            object.openInterest =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.openInterest)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.openInterest.low >>> 0,
                      message.openInterest.high >>> 0,
                    ).toNumber(true)
                  : message.openInterest;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.shouldClear != null &&
          message.hasOwnProperty('shouldClear')
        )
          object.shouldClear = message.shouldClear;
        return object;
      };

      /**
       * Converts this OpenInterest to JSON.
       * @function toJSON
       * @memberof rti.OpenInterest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      OpenInterest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for OpenInterest
       * @function getTypeUrl
       * @memberof rti.OpenInterest
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      OpenInterest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.OpenInterest';
      };

      return OpenInterest;
    })();

    rti.EndOfDayPrices = (function () {
      /**
       * Properties of an EndOfDayPrices.
       * @memberof rti
       * @interface IEndOfDayPrices
       * @property {number} templateId EndOfDayPrices templateId
       * @property {string|null} [symbol] EndOfDayPrices symbol
       * @property {string|null} [exchange] EndOfDayPrices exchange
       * @property {number|null} [presenceBits] EndOfDayPrices presenceBits
       * @property {number|null} [clearBits] EndOfDayPrices clearBits
       * @property {boolean|null} [isSnapshot] EndOfDayPrices isSnapshot
       * @property {number|null} [closePrice] EndOfDayPrices closePrice
       * @property {string|null} [closeDate] EndOfDayPrices closeDate
       * @property {number|null} [adjustedClosePrice] EndOfDayPrices adjustedClosePrice
       * @property {number|null} [settlementPrice] EndOfDayPrices settlementPrice
       * @property {string|null} [settlementDate] EndOfDayPrices settlementDate
       * @property {string|null} [settlementPriceType] EndOfDayPrices settlementPriceType
       * @property {number|null} [projectedSettlementPrice] EndOfDayPrices projectedSettlementPrice
       * @property {number|null} [ssboe] EndOfDayPrices ssboe
       * @property {number|null} [usecs] EndOfDayPrices usecs
       */

      /**
       * Constructs a new EndOfDayPrices.
       * @memberof rti
       * @classdesc Represents an EndOfDayPrices.
       * @implements IEndOfDayPrices
       * @constructor
       * @param {rti.IEndOfDayPrices=} [properties] Properties to set
       */
      function EndOfDayPrices(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * EndOfDayPrices templateId.
       * @member {number} templateId
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.templateId = 0;

      /**
       * EndOfDayPrices symbol.
       * @member {string} symbol
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.symbol = '';

      /**
       * EndOfDayPrices exchange.
       * @member {string} exchange
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.exchange = '';

      /**
       * EndOfDayPrices presenceBits.
       * @member {number} presenceBits
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.presenceBits = 0;

      /**
       * EndOfDayPrices clearBits.
       * @member {number} clearBits
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.clearBits = 0;

      /**
       * EndOfDayPrices isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.isSnapshot = false;

      /**
       * EndOfDayPrices closePrice.
       * @member {number} closePrice
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.closePrice = 0;

      /**
       * EndOfDayPrices closeDate.
       * @member {string} closeDate
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.closeDate = '';

      /**
       * EndOfDayPrices adjustedClosePrice.
       * @member {number} adjustedClosePrice
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.adjustedClosePrice = 0;

      /**
       * EndOfDayPrices settlementPrice.
       * @member {number} settlementPrice
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.settlementPrice = 0;

      /**
       * EndOfDayPrices settlementDate.
       * @member {string} settlementDate
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.settlementDate = '';

      /**
       * EndOfDayPrices settlementPriceType.
       * @member {string} settlementPriceType
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.settlementPriceType = '';

      /**
       * EndOfDayPrices projectedSettlementPrice.
       * @member {number} projectedSettlementPrice
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.projectedSettlementPrice = 0;

      /**
       * EndOfDayPrices ssboe.
       * @member {number} ssboe
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.ssboe = 0;

      /**
       * EndOfDayPrices usecs.
       * @member {number} usecs
       * @memberof rti.EndOfDayPrices
       * @instance
       */
      EndOfDayPrices.prototype.usecs = 0;

      /**
       * Creates a new EndOfDayPrices instance using the specified properties.
       * @function create
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {rti.IEndOfDayPrices=} [properties] Properties to set
       * @returns {rti.EndOfDayPrices} EndOfDayPrices instance
       */
      EndOfDayPrices.create = function create(properties) {
        return new EndOfDayPrices(properties);
      };

      /**
       * Encodes the specified EndOfDayPrices message. Does not implicitly {@link rti.EndOfDayPrices.verify|verify} messages.
       * @function encode
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {rti.IEndOfDayPrices} message EndOfDayPrices message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EndOfDayPrices.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.closePrice != null &&
          Object.hasOwnProperty.call(message, 'closePrice')
        )
          writer
            .uint32(/* id 100021, wireType 1 =*/ 800169)
            .double(message.closePrice);
        if (
          message.settlementPrice != null &&
          Object.hasOwnProperty.call(message, 'settlementPrice')
        )
          writer
            .uint32(/* id 100070, wireType 1 =*/ 800561)
            .double(message.settlementPrice);
        if (
          message.closeDate != null &&
          Object.hasOwnProperty.call(message, 'closeDate')
        )
          writer
            .uint32(/* id 100079, wireType 2 =*/ 800634)
            .string(message.closeDate);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.adjustedClosePrice != null &&
          Object.hasOwnProperty.call(message, 'adjustedClosePrice')
        )
          writer
            .uint32(/* id 154124, wireType 1 =*/ 1232993)
            .double(message.adjustedClosePrice);
        if (
          message.settlementDate != null &&
          Object.hasOwnProperty.call(message, 'settlementDate')
        )
          writer
            .uint32(/* id 154132, wireType 2 =*/ 1233058)
            .string(message.settlementDate);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        if (
          message.settlementPriceType != null &&
          Object.hasOwnProperty.call(message, 'settlementPriceType')
        )
          writer
            .uint32(/* id 154637, wireType 2 =*/ 1237098)
            .string(message.settlementPriceType);
        if (
          message.projectedSettlementPrice != null &&
          Object.hasOwnProperty.call(message, 'projectedSettlementPrice')
        )
          writer
            .uint32(/* id 155005, wireType 1 =*/ 1240041)
            .double(message.projectedSettlementPrice);
        return writer;
      };

      /**
       * Encodes the specified EndOfDayPrices message, length delimited. Does not implicitly {@link rti.EndOfDayPrices.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {rti.IEndOfDayPrices} message EndOfDayPrices message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EndOfDayPrices.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an EndOfDayPrices message from the specified reader or buffer.
       * @function decode
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.EndOfDayPrices} EndOfDayPrices
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EndOfDayPrices.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.EndOfDayPrices();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 100021:
              message.closePrice = reader.double();
              break;
            case 100079:
              message.closeDate = reader.string();
              break;
            case 154124:
              message.adjustedClosePrice = reader.double();
              break;
            case 100070:
              message.settlementPrice = reader.double();
              break;
            case 154132:
              message.settlementDate = reader.string();
              break;
            case 154637:
              message.settlementPriceType = reader.string();
              break;
            case 155005:
              message.projectedSettlementPrice = reader.double();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an EndOfDayPrices message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.EndOfDayPrices} EndOfDayPrices
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EndOfDayPrices.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an EndOfDayPrices message.
       * @function verify
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      EndOfDayPrices.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          if (typeof message.closePrice !== 'number')
            return 'closePrice: number expected';
        if (message.closeDate != null && message.hasOwnProperty('closeDate'))
          if (!$util.isString(message.closeDate))
            return 'closeDate: string expected';
        if (
          message.adjustedClosePrice != null &&
          message.hasOwnProperty('adjustedClosePrice')
        )
          if (typeof message.adjustedClosePrice !== 'number')
            return 'adjustedClosePrice: number expected';
        if (
          message.settlementPrice != null &&
          message.hasOwnProperty('settlementPrice')
        )
          if (typeof message.settlementPrice !== 'number')
            return 'settlementPrice: number expected';
        if (
          message.settlementDate != null &&
          message.hasOwnProperty('settlementDate')
        )
          if (!$util.isString(message.settlementDate))
            return 'settlementDate: string expected';
        if (
          message.settlementPriceType != null &&
          message.hasOwnProperty('settlementPriceType')
        )
          if (!$util.isString(message.settlementPriceType))
            return 'settlementPriceType: string expected';
        if (
          message.projectedSettlementPrice != null &&
          message.hasOwnProperty('projectedSettlementPrice')
        )
          if (typeof message.projectedSettlementPrice !== 'number')
            return 'projectedSettlementPrice: number expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates an EndOfDayPrices message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.EndOfDayPrices} EndOfDayPrices
       */
      EndOfDayPrices.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.EndOfDayPrices) return object;
        var message = new $root.rti.EndOfDayPrices();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.closePrice != null)
          message.closePrice = Number(object.closePrice);
        if (object.closeDate != null)
          message.closeDate = String(object.closeDate);
        if (object.adjustedClosePrice != null)
          message.adjustedClosePrice = Number(object.adjustedClosePrice);
        if (object.settlementPrice != null)
          message.settlementPrice = Number(object.settlementPrice);
        if (object.settlementDate != null)
          message.settlementDate = String(object.settlementDate);
        if (object.settlementPriceType != null)
          message.settlementPriceType = String(object.settlementPriceType);
        if (object.projectedSettlementPrice != null)
          message.projectedSettlementPrice = Number(
            object.projectedSettlementPrice,
          );
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from an EndOfDayPrices message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {rti.EndOfDayPrices} message EndOfDayPrices
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      EndOfDayPrices.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.closePrice = 0;
          object.settlementPrice = 0;
          object.closeDate = '';
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.presenceBits = 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.adjustedClosePrice = 0;
          object.settlementDate = '';
          object.templateId = 0;
          object.clearBits = 0;
          object.settlementPriceType = '';
          object.projectedSettlementPrice = 0;
        }
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          object.closePrice =
            options.json && !isFinite(message.closePrice)
              ? String(message.closePrice)
              : message.closePrice;
        if (
          message.settlementPrice != null &&
          message.hasOwnProperty('settlementPrice')
        )
          object.settlementPrice =
            options.json && !isFinite(message.settlementPrice)
              ? String(message.settlementPrice)
              : message.settlementPrice;
        if (message.closeDate != null && message.hasOwnProperty('closeDate'))
          object.closeDate = message.closeDate;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (
          message.adjustedClosePrice != null &&
          message.hasOwnProperty('adjustedClosePrice')
        )
          object.adjustedClosePrice =
            options.json && !isFinite(message.adjustedClosePrice)
              ? String(message.adjustedClosePrice)
              : message.adjustedClosePrice;
        if (
          message.settlementDate != null &&
          message.hasOwnProperty('settlementDate')
        )
          object.settlementDate = message.settlementDate;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        if (
          message.settlementPriceType != null &&
          message.hasOwnProperty('settlementPriceType')
        )
          object.settlementPriceType = message.settlementPriceType;
        if (
          message.projectedSettlementPrice != null &&
          message.hasOwnProperty('projectedSettlementPrice')
        )
          object.projectedSettlementPrice =
            options.json && !isFinite(message.projectedSettlementPrice)
              ? String(message.projectedSettlementPrice)
              : message.projectedSettlementPrice;
        return object;
      };

      /**
       * Converts this EndOfDayPrices to JSON.
       * @function toJSON
       * @memberof rti.EndOfDayPrices
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      EndOfDayPrices.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for EndOfDayPrices
       * @function getTypeUrl
       * @memberof rti.EndOfDayPrices
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      EndOfDayPrices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.EndOfDayPrices';
      };

      /**
       * PresenceBits enum.
       * @name rti.EndOfDayPrices.PresenceBits
       * @enum {number}
       * @property {number} CLOSE=1 CLOSE value
       * @property {number} SETTLEMENT=2 SETTLEMENT value
       * @property {number} PROJECTED_SETTLEMENT=4 PROJECTED_SETTLEMENT value
       * @property {number} ADJUSTED_CLOSE=8 ADJUSTED_CLOSE value
       */
      EndOfDayPrices.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'CLOSE')] = 1;
        values[(valuesById[2] = 'SETTLEMENT')] = 2;
        values[(valuesById[4] = 'PROJECTED_SETTLEMENT')] = 4;
        values[(valuesById[8] = 'ADJUSTED_CLOSE')] = 8;
        return values;
      })();

      return EndOfDayPrices;
    })();

    rti.MarketMode = (function () {
      /**
       * Properties of a MarketMode.
       * @memberof rti
       * @interface IMarketMode
       * @property {number} templateId MarketMode templateId
       * @property {string|null} [symbol] MarketMode symbol
       * @property {string|null} [exchange] MarketMode exchange
       * @property {string|null} [marketMode] MarketMode marketMode
       * @property {string|null} [haltReason] MarketMode haltReason
       * @property {string|null} [tradeDate] MarketMode tradeDate
       * @property {number|null} [ssboe] MarketMode ssboe
       * @property {number|null} [usecs] MarketMode usecs
       */

      /**
       * Constructs a new MarketMode.
       * @memberof rti
       * @classdesc Represents a MarketMode.
       * @implements IMarketMode
       * @constructor
       * @param {rti.IMarketMode=} [properties] Properties to set
       */
      function MarketMode(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * MarketMode templateId.
       * @member {number} templateId
       * @memberof rti.MarketMode
       * @instance
       */
      MarketMode.prototype.templateId = 0;

      /**
       * MarketMode symbol.
       * @member {string} symbol
       * @memberof rti.MarketMode
       * @instance
       */
      MarketMode.prototype.symbol = '';

      /**
       * MarketMode exchange.
       * @member {string} exchange
       * @memberof rti.MarketMode
       * @instance
       */
      MarketMode.prototype.exchange = '';

      /**
       * MarketMode marketMode.
       * @member {string} marketMode
       * @memberof rti.MarketMode
       * @instance
       */
      MarketMode.prototype.marketMode = '';

      /**
       * MarketMode haltReason.
       * @member {string} haltReason
       * @memberof rti.MarketMode
       * @instance
       */
      MarketMode.prototype.haltReason = '';

      /**
       * MarketMode tradeDate.
       * @member {string} tradeDate
       * @memberof rti.MarketMode
       * @instance
       */
      MarketMode.prototype.tradeDate = '';

      /**
       * MarketMode ssboe.
       * @member {number} ssboe
       * @memberof rti.MarketMode
       * @instance
       */
      MarketMode.prototype.ssboe = 0;

      /**
       * MarketMode usecs.
       * @member {number} usecs
       * @memberof rti.MarketMode
       * @instance
       */
      MarketMode.prototype.usecs = 0;

      /**
       * Creates a new MarketMode instance using the specified properties.
       * @function create
       * @memberof rti.MarketMode
       * @static
       * @param {rti.IMarketMode=} [properties] Properties to set
       * @returns {rti.MarketMode} MarketMode instance
       */
      MarketMode.create = function create(properties) {
        return new MarketMode(properties);
      };

      /**
       * Encodes the specified MarketMode message. Does not implicitly {@link rti.MarketMode.verify|verify} messages.
       * @function encode
       * @memberof rti.MarketMode
       * @static
       * @param {rti.IMarketMode} message MarketMode message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      MarketMode.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.tradeDate != null &&
          Object.hasOwnProperty.call(message, 'tradeDate')
        )
          writer
            .uint32(/* id 100016, wireType 2 =*/ 800130)
            .string(message.tradeDate);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.marketMode != null &&
          Object.hasOwnProperty.call(message, 'marketMode')
        )
          writer
            .uint32(/* id 154106, wireType 2 =*/ 1232850)
            .string(message.marketMode);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.haltReason != null &&
          Object.hasOwnProperty.call(message, 'haltReason')
        )
          writer
            .uint32(/* id 154838, wireType 2 =*/ 1238706)
            .string(message.haltReason);
        return writer;
      };

      /**
       * Encodes the specified MarketMode message, length delimited. Does not implicitly {@link rti.MarketMode.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.MarketMode
       * @static
       * @param {rti.IMarketMode} message MarketMode message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      MarketMode.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a MarketMode message from the specified reader or buffer.
       * @function decode
       * @memberof rti.MarketMode
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.MarketMode} MarketMode
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      MarketMode.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.MarketMode();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 154106:
              message.marketMode = reader.string();
              break;
            case 154838:
              message.haltReason = reader.string();
              break;
            case 100016:
              message.tradeDate = reader.string();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a MarketMode message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.MarketMode
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.MarketMode} MarketMode
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      MarketMode.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a MarketMode message.
       * @function verify
       * @memberof rti.MarketMode
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      MarketMode.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.marketMode != null && message.hasOwnProperty('marketMode'))
          if (!$util.isString(message.marketMode))
            return 'marketMode: string expected';
        if (message.haltReason != null && message.hasOwnProperty('haltReason'))
          if (!$util.isString(message.haltReason))
            return 'haltReason: string expected';
        if (message.tradeDate != null && message.hasOwnProperty('tradeDate'))
          if (!$util.isString(message.tradeDate))
            return 'tradeDate: string expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a MarketMode message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.MarketMode
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.MarketMode} MarketMode
       */
      MarketMode.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.MarketMode) return object;
        var message = new $root.rti.MarketMode();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.marketMode != null)
          message.marketMode = String(object.marketMode);
        if (object.haltReason != null)
          message.haltReason = String(object.haltReason);
        if (object.tradeDate != null)
          message.tradeDate = String(object.tradeDate);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a MarketMode message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.MarketMode
       * @static
       * @param {rti.MarketMode} message MarketMode
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      MarketMode.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.tradeDate = '';
          object.symbol = '';
          object.exchange = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.marketMode = '';
          object.templateId = 0;
          object.haltReason = '';
        }
        if (message.tradeDate != null && message.hasOwnProperty('tradeDate'))
          object.tradeDate = message.tradeDate;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.marketMode != null && message.hasOwnProperty('marketMode'))
          object.marketMode = message.marketMode;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.haltReason != null && message.hasOwnProperty('haltReason'))
          object.haltReason = message.haltReason;
        return object;
      };

      /**
       * Converts this MarketMode to JSON.
       * @function toJSON
       * @memberof rti.MarketMode
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      MarketMode.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for MarketMode
       * @function getTypeUrl
       * @memberof rti.MarketMode
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      MarketMode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.MarketMode';
      };

      return MarketMode;
    })();

    rti.FrontMonthContractUpdate = (function () {
      /**
       * Properties of a FrontMonthContractUpdate.
       * @memberof rti
       * @interface IFrontMonthContractUpdate
       * @property {number} templateId FrontMonthContractUpdate templateId
       * @property {string|null} [symbol] FrontMonthContractUpdate symbol
       * @property {string|null} [exchange] FrontMonthContractUpdate exchange
       * @property {boolean|null} [isFrontMonthSymbol] FrontMonthContractUpdate isFrontMonthSymbol
       * @property {string|null} [symbolName] FrontMonthContractUpdate symbolName
       * @property {string|null} [tradingSymbol] FrontMonthContractUpdate tradingSymbol
       * @property {string|null} [tradingExchange] FrontMonthContractUpdate tradingExchange
       */

      /**
       * Constructs a new FrontMonthContractUpdate.
       * @memberof rti
       * @classdesc Represents a FrontMonthContractUpdate.
       * @implements IFrontMonthContractUpdate
       * @constructor
       * @param {rti.IFrontMonthContractUpdate=} [properties] Properties to set
       */
      function FrontMonthContractUpdate(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * FrontMonthContractUpdate templateId.
       * @member {number} templateId
       * @memberof rti.FrontMonthContractUpdate
       * @instance
       */
      FrontMonthContractUpdate.prototype.templateId = 0;

      /**
       * FrontMonthContractUpdate symbol.
       * @member {string} symbol
       * @memberof rti.FrontMonthContractUpdate
       * @instance
       */
      FrontMonthContractUpdate.prototype.symbol = '';

      /**
       * FrontMonthContractUpdate exchange.
       * @member {string} exchange
       * @memberof rti.FrontMonthContractUpdate
       * @instance
       */
      FrontMonthContractUpdate.prototype.exchange = '';

      /**
       * FrontMonthContractUpdate isFrontMonthSymbol.
       * @member {boolean} isFrontMonthSymbol
       * @memberof rti.FrontMonthContractUpdate
       * @instance
       */
      FrontMonthContractUpdate.prototype.isFrontMonthSymbol = false;

      /**
       * FrontMonthContractUpdate symbolName.
       * @member {string} symbolName
       * @memberof rti.FrontMonthContractUpdate
       * @instance
       */
      FrontMonthContractUpdate.prototype.symbolName = '';

      /**
       * FrontMonthContractUpdate tradingSymbol.
       * @member {string} tradingSymbol
       * @memberof rti.FrontMonthContractUpdate
       * @instance
       */
      FrontMonthContractUpdate.prototype.tradingSymbol = '';

      /**
       * FrontMonthContractUpdate tradingExchange.
       * @member {string} tradingExchange
       * @memberof rti.FrontMonthContractUpdate
       * @instance
       */
      FrontMonthContractUpdate.prototype.tradingExchange = '';

      /**
       * Creates a new FrontMonthContractUpdate instance using the specified properties.
       * @function create
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {rti.IFrontMonthContractUpdate=} [properties] Properties to set
       * @returns {rti.FrontMonthContractUpdate} FrontMonthContractUpdate instance
       */
      FrontMonthContractUpdate.create = function create(properties) {
        return new FrontMonthContractUpdate(properties);
      };

      /**
       * Encodes the specified FrontMonthContractUpdate message. Does not implicitly {@link rti.FrontMonthContractUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {rti.IFrontMonthContractUpdate} message FrontMonthContractUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FrontMonthContractUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbolName != null &&
          Object.hasOwnProperty.call(message, 'symbolName')
        )
          writer
            .uint32(/* id 100003, wireType 2 =*/ 800026)
            .string(message.symbolName);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isFrontMonthSymbol != null &&
          Object.hasOwnProperty.call(message, 'isFrontMonthSymbol')
        )
          writer
            .uint32(/* id 149166, wireType 0 =*/ 1193328)
            .bool(message.isFrontMonthSymbol);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.tradingSymbol != null &&
          Object.hasOwnProperty.call(message, 'tradingSymbol')
        )
          writer
            .uint32(/* id 157095, wireType 2 =*/ 1256762)
            .string(message.tradingSymbol);
        if (
          message.tradingExchange != null &&
          Object.hasOwnProperty.call(message, 'tradingExchange')
        )
          writer
            .uint32(/* id 157096, wireType 2 =*/ 1256770)
            .string(message.tradingExchange);
        return writer;
      };

      /**
       * Encodes the specified FrontMonthContractUpdate message, length delimited. Does not implicitly {@link rti.FrontMonthContractUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {rti.IFrontMonthContractUpdate} message FrontMonthContractUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FrontMonthContractUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a FrontMonthContractUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.FrontMonthContractUpdate} FrontMonthContractUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FrontMonthContractUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.FrontMonthContractUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149166:
              message.isFrontMonthSymbol = reader.bool();
              break;
            case 100003:
              message.symbolName = reader.string();
              break;
            case 157095:
              message.tradingSymbol = reader.string();
              break;
            case 157096:
              message.tradingExchange = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a FrontMonthContractUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.FrontMonthContractUpdate} FrontMonthContractUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FrontMonthContractUpdate.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a FrontMonthContractUpdate message.
       * @function verify
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      FrontMonthContractUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.isFrontMonthSymbol != null &&
          message.hasOwnProperty('isFrontMonthSymbol')
        )
          if (typeof message.isFrontMonthSymbol !== 'boolean')
            return 'isFrontMonthSymbol: boolean expected';
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          if (!$util.isString(message.symbolName))
            return 'symbolName: string expected';
        if (
          message.tradingSymbol != null &&
          message.hasOwnProperty('tradingSymbol')
        )
          if (!$util.isString(message.tradingSymbol))
            return 'tradingSymbol: string expected';
        if (
          message.tradingExchange != null &&
          message.hasOwnProperty('tradingExchange')
        )
          if (!$util.isString(message.tradingExchange))
            return 'tradingExchange: string expected';
        return null;
      };

      /**
       * Creates a FrontMonthContractUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.FrontMonthContractUpdate} FrontMonthContractUpdate
       */
      FrontMonthContractUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.FrontMonthContractUpdate) return object;
        var message = new $root.rti.FrontMonthContractUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.isFrontMonthSymbol != null)
          message.isFrontMonthSymbol = Boolean(object.isFrontMonthSymbol);
        if (object.symbolName != null)
          message.symbolName = String(object.symbolName);
        if (object.tradingSymbol != null)
          message.tradingSymbol = String(object.tradingSymbol);
        if (object.tradingExchange != null)
          message.tradingExchange = String(object.tradingExchange);
        return message;
      };

      /**
       * Creates a plain object from a FrontMonthContractUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {rti.FrontMonthContractUpdate} message FrontMonthContractUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      FrontMonthContractUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.symbolName = '';
          object.symbol = '';
          object.exchange = '';
          object.isFrontMonthSymbol = false;
          object.templateId = 0;
          object.tradingSymbol = '';
          object.tradingExchange = '';
        }
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          object.symbolName = message.symbolName;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.isFrontMonthSymbol != null &&
          message.hasOwnProperty('isFrontMonthSymbol')
        )
          object.isFrontMonthSymbol = message.isFrontMonthSymbol;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.tradingSymbol != null &&
          message.hasOwnProperty('tradingSymbol')
        )
          object.tradingSymbol = message.tradingSymbol;
        if (
          message.tradingExchange != null &&
          message.hasOwnProperty('tradingExchange')
        )
          object.tradingExchange = message.tradingExchange;
        return object;
      };

      /**
       * Converts this FrontMonthContractUpdate to JSON.
       * @function toJSON
       * @memberof rti.FrontMonthContractUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      FrontMonthContractUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for FrontMonthContractUpdate
       * @function getTypeUrl
       * @memberof rti.FrontMonthContractUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      FrontMonthContractUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.FrontMonthContractUpdate';
      };

      return FrontMonthContractUpdate;
    })();

    rti.DepthByOrder = (function () {
      /**
       * Properties of a DepthByOrder.
       * @memberof rti
       * @interface IDepthByOrder
       * @property {number} templateId DepthByOrder templateId
       * @property {string|null} [symbol] DepthByOrder symbol
       * @property {string|null} [exchange] DepthByOrder exchange
       * @property {number|Long|null} [sequenceNumber] DepthByOrder sequenceNumber
       * @property {Array.<rti.DepthByOrder.UpdateType>|null} [updateType] DepthByOrder updateType
       * @property {Array.<rti.DepthByOrder.TransactionType>|null} [transactionType] DepthByOrder transactionType
       * @property {Array.<number>|null} [depthPrice] DepthByOrder depthPrice
       * @property {Array.<number>|null} [prevDepthPrice] DepthByOrder prevDepthPrice
       * @property {Array.<boolean>|null} [prevDepthPriceFlag] DepthByOrder prevDepthPriceFlag
       * @property {Array.<number>|null} [depthSize] DepthByOrder depthSize
       * @property {Array.<number|Long>|null} [depthOrderPriority] DepthByOrder depthOrderPriority
       * @property {Array.<string>|null} [exchangeOrderId] DepthByOrder exchangeOrderId
       * @property {number|null} [ssboe] DepthByOrder ssboe
       * @property {number|null} [usecs] DepthByOrder usecs
       * @property {number|null} [sourceSsboe] DepthByOrder sourceSsboe
       * @property {number|null} [sourceUsecs] DepthByOrder sourceUsecs
       * @property {number|null} [sourceNsecs] DepthByOrder sourceNsecs
       * @property {number|null} [jopSsboe] DepthByOrder jopSsboe
       * @property {number|null} [jopNsecs] DepthByOrder jopNsecs
       */

      /**
       * Constructs a new DepthByOrder.
       * @memberof rti
       * @classdesc Represents a DepthByOrder.
       * @implements IDepthByOrder
       * @constructor
       * @param {rti.IDepthByOrder=} [properties] Properties to set
       */
      function DepthByOrder(properties) {
        this.updateType = [];
        this.transactionType = [];
        this.depthPrice = [];
        this.prevDepthPrice = [];
        this.prevDepthPriceFlag = [];
        this.depthSize = [];
        this.depthOrderPriority = [];
        this.exchangeOrderId = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * DepthByOrder templateId.
       * @member {number} templateId
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.templateId = 0;

      /**
       * DepthByOrder symbol.
       * @member {string} symbol
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.symbol = '';

      /**
       * DepthByOrder exchange.
       * @member {string} exchange
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.exchange = '';

      /**
       * DepthByOrder sequenceNumber.
       * @member {number|Long} sequenceNumber
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.sequenceNumber = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * DepthByOrder updateType.
       * @member {Array.<rti.DepthByOrder.UpdateType>} updateType
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.updateType = $util.emptyArray;

      /**
       * DepthByOrder transactionType.
       * @member {Array.<rti.DepthByOrder.TransactionType>} transactionType
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.transactionType = $util.emptyArray;

      /**
       * DepthByOrder depthPrice.
       * @member {Array.<number>} depthPrice
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.depthPrice = $util.emptyArray;

      /**
       * DepthByOrder prevDepthPrice.
       * @member {Array.<number>} prevDepthPrice
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.prevDepthPrice = $util.emptyArray;

      /**
       * DepthByOrder prevDepthPriceFlag.
       * @member {Array.<boolean>} prevDepthPriceFlag
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.prevDepthPriceFlag = $util.emptyArray;

      /**
       * DepthByOrder depthSize.
       * @member {Array.<number>} depthSize
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.depthSize = $util.emptyArray;

      /**
       * DepthByOrder depthOrderPriority.
       * @member {Array.<number|Long>} depthOrderPriority
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.depthOrderPriority = $util.emptyArray;

      /**
       * DepthByOrder exchangeOrderId.
       * @member {Array.<string>} exchangeOrderId
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.exchangeOrderId = $util.emptyArray;

      /**
       * DepthByOrder ssboe.
       * @member {number} ssboe
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.ssboe = 0;

      /**
       * DepthByOrder usecs.
       * @member {number} usecs
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.usecs = 0;

      /**
       * DepthByOrder sourceSsboe.
       * @member {number} sourceSsboe
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.sourceSsboe = 0;

      /**
       * DepthByOrder sourceUsecs.
       * @member {number} sourceUsecs
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.sourceUsecs = 0;

      /**
       * DepthByOrder sourceNsecs.
       * @member {number} sourceNsecs
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.sourceNsecs = 0;

      /**
       * DepthByOrder jopSsboe.
       * @member {number} jopSsboe
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.jopSsboe = 0;

      /**
       * DepthByOrder jopNsecs.
       * @member {number} jopNsecs
       * @memberof rti.DepthByOrder
       * @instance
       */
      DepthByOrder.prototype.jopNsecs = 0;

      /**
       * Creates a new DepthByOrder instance using the specified properties.
       * @function create
       * @memberof rti.DepthByOrder
       * @static
       * @param {rti.IDepthByOrder=} [properties] Properties to set
       * @returns {rti.DepthByOrder} DepthByOrder instance
       */
      DepthByOrder.create = function create(properties) {
        return new DepthByOrder(properties);
      };

      /**
       * Encodes the specified DepthByOrder message. Does not implicitly {@link rti.DepthByOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.DepthByOrder
       * @static
       * @param {rti.IDepthByOrder} message DepthByOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DepthByOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.updateType != null && message.updateType.length)
          for (var i = 0; i < message.updateType.length; ++i)
            writer
              .uint32(/* id 110121, wireType 0 =*/ 880968)
              .int32(message.updateType[i]);
        if (
          message.sequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'sequenceNumber')
        )
          writer
            .uint32(/* id 112002, wireType 0 =*/ 896016)
            .uint64(message.sequenceNumber);
        if (message.exchangeOrderId != null && message.exchangeOrderId.length)
          for (var i = 0; i < message.exchangeOrderId.length; ++i)
            writer
              .uint32(/* id 149238, wireType 2 =*/ 1193906)
              .string(message.exchangeOrderId[i]);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.sourceSsboe != null &&
          Object.hasOwnProperty.call(message, 'sourceSsboe')
        )
          writer
            .uint32(/* id 150400, wireType 0 =*/ 1203200)
            .int32(message.sourceSsboe);
        if (
          message.sourceUsecs != null &&
          Object.hasOwnProperty.call(message, 'sourceUsecs')
        )
          writer
            .uint32(/* id 150401, wireType 0 =*/ 1203208)
            .int32(message.sourceUsecs);
        if (
          message.sourceNsecs != null &&
          Object.hasOwnProperty.call(message, 'sourceNsecs')
        )
          writer
            .uint32(/* id 150404, wireType 0 =*/ 1203232)
            .int32(message.sourceNsecs);
        if (
          message.jopSsboe != null &&
          Object.hasOwnProperty.call(message, 'jopSsboe')
        )
          writer
            .uint32(/* id 150600, wireType 0 =*/ 1204800)
            .int32(message.jopSsboe);
        if (
          message.jopNsecs != null &&
          Object.hasOwnProperty.call(message, 'jopNsecs')
        )
          writer
            .uint32(/* id 150604, wireType 0 =*/ 1204832)
            .int32(message.jopNsecs);
        if (message.transactionType != null && message.transactionType.length)
          for (var i = 0; i < message.transactionType.length; ++i)
            writer
              .uint32(/* id 153612, wireType 0 =*/ 1228896)
              .int32(message.transactionType[i]);
        if (
          message.depthOrderPriority != null &&
          message.depthOrderPriority.length
        )
          for (var i = 0; i < message.depthOrderPriority.length; ++i)
            writer
              .uint32(/* id 153613, wireType 0 =*/ 1228904)
              .uint64(message.depthOrderPriority[i]);
        if (message.depthPrice != null && message.depthPrice.length)
          for (var i = 0; i < message.depthPrice.length; ++i)
            writer
              .uint32(/* id 154405, wireType 1 =*/ 1235241)
              .double(message.depthPrice[i]);
        if (message.depthSize != null && message.depthSize.length)
          for (var i = 0; i < message.depthSize.length; ++i)
            writer
              .uint32(/* id 154406, wireType 0 =*/ 1235248)
              .int32(message.depthSize[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (message.prevDepthPrice != null && message.prevDepthPrice.length)
          for (var i = 0; i < message.prevDepthPrice.length; ++i)
            writer
              .uint32(/* id 154906, wireType 1 =*/ 1239249)
              .double(message.prevDepthPrice[i]);
        if (
          message.prevDepthPriceFlag != null &&
          message.prevDepthPriceFlag.length
        )
          for (var i = 0; i < message.prevDepthPriceFlag.length; ++i)
            writer
              .uint32(/* id 154930, wireType 0 =*/ 1239440)
              .bool(message.prevDepthPriceFlag[i]);
        return writer;
      };

      /**
       * Encodes the specified DepthByOrder message, length delimited. Does not implicitly {@link rti.DepthByOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.DepthByOrder
       * @static
       * @param {rti.IDepthByOrder} message DepthByOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DepthByOrder.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a DepthByOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.DepthByOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.DepthByOrder} DepthByOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DepthByOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.DepthByOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 112002:
              message.sequenceNumber = reader.uint64();
              break;
            case 110121:
              if (!(message.updateType && message.updateType.length))
                message.updateType = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.updateType.push(reader.int32());
              } else message.updateType.push(reader.int32());
              break;
            case 153612:
              if (!(message.transactionType && message.transactionType.length))
                message.transactionType = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.transactionType.push(reader.int32());
              } else message.transactionType.push(reader.int32());
              break;
            case 154405:
              if (!(message.depthPrice && message.depthPrice.length))
                message.depthPrice = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.depthPrice.push(reader.double());
              } else message.depthPrice.push(reader.double());
              break;
            case 154906:
              if (!(message.prevDepthPrice && message.prevDepthPrice.length))
                message.prevDepthPrice = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.prevDepthPrice.push(reader.double());
              } else message.prevDepthPrice.push(reader.double());
              break;
            case 154930:
              if (
                !(
                  message.prevDepthPriceFlag &&
                  message.prevDepthPriceFlag.length
                )
              )
                message.prevDepthPriceFlag = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.prevDepthPriceFlag.push(reader.bool());
              } else message.prevDepthPriceFlag.push(reader.bool());
              break;
            case 154406:
              if (!(message.depthSize && message.depthSize.length))
                message.depthSize = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.depthSize.push(reader.int32());
              } else message.depthSize.push(reader.int32());
              break;
            case 153613:
              if (
                !(
                  message.depthOrderPriority &&
                  message.depthOrderPriority.length
                )
              )
                message.depthOrderPriority = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.depthOrderPriority.push(reader.uint64());
              } else message.depthOrderPriority.push(reader.uint64());
              break;
            case 149238:
              if (!(message.exchangeOrderId && message.exchangeOrderId.length))
                message.exchangeOrderId = [];
              message.exchangeOrderId.push(reader.string());
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            case 150400:
              message.sourceSsboe = reader.int32();
              break;
            case 150401:
              message.sourceUsecs = reader.int32();
              break;
            case 150404:
              message.sourceNsecs = reader.int32();
              break;
            case 150600:
              message.jopSsboe = reader.int32();
              break;
            case 150604:
              message.jopNsecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a DepthByOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.DepthByOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.DepthByOrder} DepthByOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DepthByOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a DepthByOrder message.
       * @function verify
       * @memberof rti.DepthByOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      DepthByOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          if (
            !$util.isInteger(message.sequenceNumber) &&
            !(
              message.sequenceNumber &&
              $util.isInteger(message.sequenceNumber.low) &&
              $util.isInteger(message.sequenceNumber.high)
            )
          )
            return 'sequenceNumber: integer|Long expected';
        if (
          message.updateType != null &&
          message.hasOwnProperty('updateType')
        ) {
          if (!Array.isArray(message.updateType))
            return 'updateType: array expected';
          for (var i = 0; i < message.updateType.length; ++i)
            switch (message.updateType[i]) {
              default:
                return 'updateType: enum value[] expected';
              case 1:
              case 2:
              case 3:
                break;
            }
        }
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        ) {
          if (!Array.isArray(message.transactionType))
            return 'transactionType: array expected';
          for (var i = 0; i < message.transactionType.length; ++i)
            switch (message.transactionType[i]) {
              default:
                return 'transactionType: enum value[] expected';
              case 1:
              case 2:
                break;
            }
        }
        if (
          message.depthPrice != null &&
          message.hasOwnProperty('depthPrice')
        ) {
          if (!Array.isArray(message.depthPrice))
            return 'depthPrice: array expected';
          for (var i = 0; i < message.depthPrice.length; ++i)
            if (typeof message.depthPrice[i] !== 'number')
              return 'depthPrice: number[] expected';
        }
        if (
          message.prevDepthPrice != null &&
          message.hasOwnProperty('prevDepthPrice')
        ) {
          if (!Array.isArray(message.prevDepthPrice))
            return 'prevDepthPrice: array expected';
          for (var i = 0; i < message.prevDepthPrice.length; ++i)
            if (typeof message.prevDepthPrice[i] !== 'number')
              return 'prevDepthPrice: number[] expected';
        }
        if (
          message.prevDepthPriceFlag != null &&
          message.hasOwnProperty('prevDepthPriceFlag')
        ) {
          if (!Array.isArray(message.prevDepthPriceFlag))
            return 'prevDepthPriceFlag: array expected';
          for (var i = 0; i < message.prevDepthPriceFlag.length; ++i)
            if (typeof message.prevDepthPriceFlag[i] !== 'boolean')
              return 'prevDepthPriceFlag: boolean[] expected';
        }
        if (message.depthSize != null && message.hasOwnProperty('depthSize')) {
          if (!Array.isArray(message.depthSize))
            return 'depthSize: array expected';
          for (var i = 0; i < message.depthSize.length; ++i)
            if (!$util.isInteger(message.depthSize[i]))
              return 'depthSize: integer[] expected';
        }
        if (
          message.depthOrderPriority != null &&
          message.hasOwnProperty('depthOrderPriority')
        ) {
          if (!Array.isArray(message.depthOrderPriority))
            return 'depthOrderPriority: array expected';
          for (var i = 0; i < message.depthOrderPriority.length; ++i)
            if (
              !$util.isInteger(message.depthOrderPriority[i]) &&
              !(
                message.depthOrderPriority[i] &&
                $util.isInteger(message.depthOrderPriority[i].low) &&
                $util.isInteger(message.depthOrderPriority[i].high)
              )
            )
              return 'depthOrderPriority: integer|Long[] expected';
        }
        if (
          message.exchangeOrderId != null &&
          message.hasOwnProperty('exchangeOrderId')
        ) {
          if (!Array.isArray(message.exchangeOrderId))
            return 'exchangeOrderId: array expected';
          for (var i = 0; i < message.exchangeOrderId.length; ++i)
            if (!$util.isString(message.exchangeOrderId[i]))
              return 'exchangeOrderId: string[] expected';
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        if (
          message.sourceSsboe != null &&
          message.hasOwnProperty('sourceSsboe')
        )
          if (!$util.isInteger(message.sourceSsboe))
            return 'sourceSsboe: integer expected';
        if (
          message.sourceUsecs != null &&
          message.hasOwnProperty('sourceUsecs')
        )
          if (!$util.isInteger(message.sourceUsecs))
            return 'sourceUsecs: integer expected';
        if (
          message.sourceNsecs != null &&
          message.hasOwnProperty('sourceNsecs')
        )
          if (!$util.isInteger(message.sourceNsecs))
            return 'sourceNsecs: integer expected';
        if (message.jopSsboe != null && message.hasOwnProperty('jopSsboe'))
          if (!$util.isInteger(message.jopSsboe))
            return 'jopSsboe: integer expected';
        if (message.jopNsecs != null && message.hasOwnProperty('jopNsecs'))
          if (!$util.isInteger(message.jopNsecs))
            return 'jopNsecs: integer expected';
        return null;
      };

      /**
       * Creates a DepthByOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.DepthByOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.DepthByOrder} DepthByOrder
       */
      DepthByOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.DepthByOrder) return object;
        var message = new $root.rti.DepthByOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.sequenceNumber != null)
          if ($util.Long)
            (message.sequenceNumber = $util.Long.fromValue(
              object.sequenceNumber,
            )).unsigned = true;
          else if (typeof object.sequenceNumber === 'string')
            message.sequenceNumber = parseInt(object.sequenceNumber, 10);
          else if (typeof object.sequenceNumber === 'number')
            message.sequenceNumber = object.sequenceNumber;
          else if (typeof object.sequenceNumber === 'object')
            message.sequenceNumber = new $util.LongBits(
              object.sequenceNumber.low >>> 0,
              object.sequenceNumber.high >>> 0,
            ).toNumber(true);
        if (object.updateType) {
          if (!Array.isArray(object.updateType))
            throw TypeError('.rti.DepthByOrder.updateType: array expected');
          message.updateType = [];
          for (var i = 0; i < object.updateType.length; ++i)
            switch (object.updateType[i]) {
              default:
              case 'NEW':
              case 1:
                message.updateType[i] = 1;
                break;
              case 'CHANGE':
              case 2:
                message.updateType[i] = 2;
                break;
              case 'DELETE':
              case 3:
                message.updateType[i] = 3;
                break;
            }
        }
        if (object.transactionType) {
          if (!Array.isArray(object.transactionType))
            throw TypeError(
              '.rti.DepthByOrder.transactionType: array expected',
            );
          message.transactionType = [];
          for (var i = 0; i < object.transactionType.length; ++i)
            switch (object.transactionType[i]) {
              default:
              case 'BUY':
              case 1:
                message.transactionType[i] = 1;
                break;
              case 'SELL':
              case 2:
                message.transactionType[i] = 2;
                break;
            }
        }
        if (object.depthPrice) {
          if (!Array.isArray(object.depthPrice))
            throw TypeError('.rti.DepthByOrder.depthPrice: array expected');
          message.depthPrice = [];
          for (var i = 0; i < object.depthPrice.length; ++i)
            message.depthPrice[i] = Number(object.depthPrice[i]);
        }
        if (object.prevDepthPrice) {
          if (!Array.isArray(object.prevDepthPrice))
            throw TypeError('.rti.DepthByOrder.prevDepthPrice: array expected');
          message.prevDepthPrice = [];
          for (var i = 0; i < object.prevDepthPrice.length; ++i)
            message.prevDepthPrice[i] = Number(object.prevDepthPrice[i]);
        }
        if (object.prevDepthPriceFlag) {
          if (!Array.isArray(object.prevDepthPriceFlag))
            throw TypeError(
              '.rti.DepthByOrder.prevDepthPriceFlag: array expected',
            );
          message.prevDepthPriceFlag = [];
          for (var i = 0; i < object.prevDepthPriceFlag.length; ++i)
            message.prevDepthPriceFlag[i] = Boolean(
              object.prevDepthPriceFlag[i],
            );
        }
        if (object.depthSize) {
          if (!Array.isArray(object.depthSize))
            throw TypeError('.rti.DepthByOrder.depthSize: array expected');
          message.depthSize = [];
          for (var i = 0; i < object.depthSize.length; ++i)
            message.depthSize[i] = object.depthSize[i] | 0;
        }
        if (object.depthOrderPriority) {
          if (!Array.isArray(object.depthOrderPriority))
            throw TypeError(
              '.rti.DepthByOrder.depthOrderPriority: array expected',
            );
          message.depthOrderPriority = [];
          for (var i = 0; i < object.depthOrderPriority.length; ++i)
            if ($util.Long)
              (message.depthOrderPriority[i] = $util.Long.fromValue(
                object.depthOrderPriority[i],
              )).unsigned = true;
            else if (typeof object.depthOrderPriority[i] === 'string')
              message.depthOrderPriority[i] = parseInt(
                object.depthOrderPriority[i],
                10,
              );
            else if (typeof object.depthOrderPriority[i] === 'number')
              message.depthOrderPriority[i] = object.depthOrderPriority[i];
            else if (typeof object.depthOrderPriority[i] === 'object')
              message.depthOrderPriority[i] = new $util.LongBits(
                object.depthOrderPriority[i].low >>> 0,
                object.depthOrderPriority[i].high >>> 0,
              ).toNumber(true);
        }
        if (object.exchangeOrderId) {
          if (!Array.isArray(object.exchangeOrderId))
            throw TypeError(
              '.rti.DepthByOrder.exchangeOrderId: array expected',
            );
          message.exchangeOrderId = [];
          for (var i = 0; i < object.exchangeOrderId.length; ++i)
            message.exchangeOrderId[i] = String(object.exchangeOrderId[i]);
        }
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        if (object.sourceSsboe != null)
          message.sourceSsboe = object.sourceSsboe | 0;
        if (object.sourceUsecs != null)
          message.sourceUsecs = object.sourceUsecs | 0;
        if (object.sourceNsecs != null)
          message.sourceNsecs = object.sourceNsecs | 0;
        if (object.jopSsboe != null) message.jopSsboe = object.jopSsboe | 0;
        if (object.jopNsecs != null) message.jopNsecs = object.jopNsecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a DepthByOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.DepthByOrder
       * @static
       * @param {rti.DepthByOrder} message DepthByOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      DepthByOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.updateType = [];
          object.exchangeOrderId = [];
          object.transactionType = [];
          object.depthOrderPriority = [];
          object.depthPrice = [];
          object.depthSize = [];
          object.prevDepthPrice = [];
          object.prevDepthPriceFlag = [];
        }
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.sequenceNumber =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.sequenceNumber = options.longs === String ? '0' : 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.sourceSsboe = 0;
          object.sourceUsecs = 0;
          object.sourceNsecs = 0;
          object.jopSsboe = 0;
          object.jopNsecs = 0;
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.updateType && message.updateType.length) {
          object.updateType = [];
          for (var j = 0; j < message.updateType.length; ++j)
            object.updateType[j] =
              options.enums === String
                ? $root.rti.DepthByOrder.UpdateType[message.updateType[j]]
                : message.updateType[j];
        }
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          if (typeof message.sequenceNumber === 'number')
            object.sequenceNumber =
              options.longs === String
                ? String(message.sequenceNumber)
                : message.sequenceNumber;
          else
            object.sequenceNumber =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.sequenceNumber)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.sequenceNumber.low >>> 0,
                      message.sequenceNumber.high >>> 0,
                    ).toNumber(true)
                  : message.sequenceNumber;
        if (message.exchangeOrderId && message.exchangeOrderId.length) {
          object.exchangeOrderId = [];
          for (var j = 0; j < message.exchangeOrderId.length; ++j)
            object.exchangeOrderId[j] = message.exchangeOrderId[j];
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (
          message.sourceSsboe != null &&
          message.hasOwnProperty('sourceSsboe')
        )
          object.sourceSsboe = message.sourceSsboe;
        if (
          message.sourceUsecs != null &&
          message.hasOwnProperty('sourceUsecs')
        )
          object.sourceUsecs = message.sourceUsecs;
        if (
          message.sourceNsecs != null &&
          message.hasOwnProperty('sourceNsecs')
        )
          object.sourceNsecs = message.sourceNsecs;
        if (message.jopSsboe != null && message.hasOwnProperty('jopSsboe'))
          object.jopSsboe = message.jopSsboe;
        if (message.jopNsecs != null && message.hasOwnProperty('jopNsecs'))
          object.jopNsecs = message.jopNsecs;
        if (message.transactionType && message.transactionType.length) {
          object.transactionType = [];
          for (var j = 0; j < message.transactionType.length; ++j)
            object.transactionType[j] =
              options.enums === String
                ? $root.rti.DepthByOrder.TransactionType[
                    message.transactionType[j]
                  ]
                : message.transactionType[j];
        }
        if (message.depthOrderPriority && message.depthOrderPriority.length) {
          object.depthOrderPriority = [];
          for (var j = 0; j < message.depthOrderPriority.length; ++j)
            if (typeof message.depthOrderPriority[j] === 'number')
              object.depthOrderPriority[j] =
                options.longs === String
                  ? String(message.depthOrderPriority[j])
                  : message.depthOrderPriority[j];
            else
              object.depthOrderPriority[j] =
                options.longs === String
                  ? $util.Long.prototype.toString.call(
                      message.depthOrderPriority[j],
                    )
                  : options.longs === Number
                    ? new $util.LongBits(
                        message.depthOrderPriority[j].low >>> 0,
                        message.depthOrderPriority[j].high >>> 0,
                      ).toNumber(true)
                    : message.depthOrderPriority[j];
        }
        if (message.depthPrice && message.depthPrice.length) {
          object.depthPrice = [];
          for (var j = 0; j < message.depthPrice.length; ++j)
            object.depthPrice[j] =
              options.json && !isFinite(message.depthPrice[j])
                ? String(message.depthPrice[j])
                : message.depthPrice[j];
        }
        if (message.depthSize && message.depthSize.length) {
          object.depthSize = [];
          for (var j = 0; j < message.depthSize.length; ++j)
            object.depthSize[j] = message.depthSize[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.prevDepthPrice && message.prevDepthPrice.length) {
          object.prevDepthPrice = [];
          for (var j = 0; j < message.prevDepthPrice.length; ++j)
            object.prevDepthPrice[j] =
              options.json && !isFinite(message.prevDepthPrice[j])
                ? String(message.prevDepthPrice[j])
                : message.prevDepthPrice[j];
        }
        if (message.prevDepthPriceFlag && message.prevDepthPriceFlag.length) {
          object.prevDepthPriceFlag = [];
          for (var j = 0; j < message.prevDepthPriceFlag.length; ++j)
            object.prevDepthPriceFlag[j] = message.prevDepthPriceFlag[j];
        }
        return object;
      };

      /**
       * Converts this DepthByOrder to JSON.
       * @function toJSON
       * @memberof rti.DepthByOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      DepthByOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for DepthByOrder
       * @function getTypeUrl
       * @memberof rti.DepthByOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      DepthByOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.DepthByOrder';
      };

      /**
       * TransactionType enum.
       * @name rti.DepthByOrder.TransactionType
       * @enum {number}
       * @property {number} BUY=1 BUY value
       * @property {number} SELL=2 SELL value
       */
      DepthByOrder.TransactionType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY')] = 1;
        values[(valuesById[2] = 'SELL')] = 2;
        return values;
      })();

      /**
       * UpdateType enum.
       * @name rti.DepthByOrder.UpdateType
       * @enum {number}
       * @property {number} NEW=1 NEW value
       * @property {number} CHANGE=2 CHANGE value
       * @property {number} DELETE=3 DELETE value
       */
      DepthByOrder.UpdateType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'NEW')] = 1;
        values[(valuesById[2] = 'CHANGE')] = 2;
        values[(valuesById[3] = 'DELETE')] = 3;
        return values;
      })();

      return DepthByOrder;
    })();

    rti.DepthByOrderEndEvent = (function () {
      /**
       * Properties of a DepthByOrderEndEvent.
       * @memberof rti
       * @interface IDepthByOrderEndEvent
       * @property {number} templateId DepthByOrderEndEvent templateId
       * @property {Array.<string>|null} [symbol] DepthByOrderEndEvent symbol
       * @property {Array.<string>|null} [exchange] DepthByOrderEndEvent exchange
       * @property {number|Long|null} [sequenceNumber] DepthByOrderEndEvent sequenceNumber
       * @property {number|null} [ssboe] DepthByOrderEndEvent ssboe
       * @property {number|null} [usecs] DepthByOrderEndEvent usecs
       */

      /**
       * Constructs a new DepthByOrderEndEvent.
       * @memberof rti
       * @classdesc Represents a DepthByOrderEndEvent.
       * @implements IDepthByOrderEndEvent
       * @constructor
       * @param {rti.IDepthByOrderEndEvent=} [properties] Properties to set
       */
      function DepthByOrderEndEvent(properties) {
        this.symbol = [];
        this.exchange = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * DepthByOrderEndEvent templateId.
       * @member {number} templateId
       * @memberof rti.DepthByOrderEndEvent
       * @instance
       */
      DepthByOrderEndEvent.prototype.templateId = 0;

      /**
       * DepthByOrderEndEvent symbol.
       * @member {Array.<string>} symbol
       * @memberof rti.DepthByOrderEndEvent
       * @instance
       */
      DepthByOrderEndEvent.prototype.symbol = $util.emptyArray;

      /**
       * DepthByOrderEndEvent exchange.
       * @member {Array.<string>} exchange
       * @memberof rti.DepthByOrderEndEvent
       * @instance
       */
      DepthByOrderEndEvent.prototype.exchange = $util.emptyArray;

      /**
       * DepthByOrderEndEvent sequenceNumber.
       * @member {number|Long} sequenceNumber
       * @memberof rti.DepthByOrderEndEvent
       * @instance
       */
      DepthByOrderEndEvent.prototype.sequenceNumber = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * DepthByOrderEndEvent ssboe.
       * @member {number} ssboe
       * @memberof rti.DepthByOrderEndEvent
       * @instance
       */
      DepthByOrderEndEvent.prototype.ssboe = 0;

      /**
       * DepthByOrderEndEvent usecs.
       * @member {number} usecs
       * @memberof rti.DepthByOrderEndEvent
       * @instance
       */
      DepthByOrderEndEvent.prototype.usecs = 0;

      /**
       * Creates a new DepthByOrderEndEvent instance using the specified properties.
       * @function create
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {rti.IDepthByOrderEndEvent=} [properties] Properties to set
       * @returns {rti.DepthByOrderEndEvent} DepthByOrderEndEvent instance
       */
      DepthByOrderEndEvent.create = function create(properties) {
        return new DepthByOrderEndEvent(properties);
      };

      /**
       * Encodes the specified DepthByOrderEndEvent message. Does not implicitly {@link rti.DepthByOrderEndEvent.verify|verify} messages.
       * @function encode
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {rti.IDepthByOrderEndEvent} message DepthByOrderEndEvent message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DepthByOrderEndEvent.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.symbol != null && message.symbol.length)
          for (var i = 0; i < message.symbol.length; ++i)
            writer
              .uint32(/* id 110100, wireType 2 =*/ 880802)
              .string(message.symbol[i]);
        if (message.exchange != null && message.exchange.length)
          for (var i = 0; i < message.exchange.length; ++i)
            writer
              .uint32(/* id 110101, wireType 2 =*/ 880810)
              .string(message.exchange[i]);
        if (
          message.sequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'sequenceNumber')
        )
          writer
            .uint32(/* id 112002, wireType 0 =*/ 896016)
            .uint64(message.sequenceNumber);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified DepthByOrderEndEvent message, length delimited. Does not implicitly {@link rti.DepthByOrderEndEvent.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {rti.IDepthByOrderEndEvent} message DepthByOrderEndEvent message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DepthByOrderEndEvent.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a DepthByOrderEndEvent message from the specified reader or buffer.
       * @function decode
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.DepthByOrderEndEvent} DepthByOrderEndEvent
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DepthByOrderEndEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.DepthByOrderEndEvent();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              if (!(message.symbol && message.symbol.length))
                message.symbol = [];
              message.symbol.push(reader.string());
              break;
            case 110101:
              if (!(message.exchange && message.exchange.length))
                message.exchange = [];
              message.exchange.push(reader.string());
              break;
            case 112002:
              message.sequenceNumber = reader.uint64();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a DepthByOrderEndEvent message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.DepthByOrderEndEvent} DepthByOrderEndEvent
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DepthByOrderEndEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a DepthByOrderEndEvent message.
       * @function verify
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      DepthByOrderEndEvent.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol')) {
          if (!Array.isArray(message.symbol)) return 'symbol: array expected';
          for (var i = 0; i < message.symbol.length; ++i)
            if (!$util.isString(message.symbol[i]))
              return 'symbol: string[] expected';
        }
        if (message.exchange != null && message.hasOwnProperty('exchange')) {
          if (!Array.isArray(message.exchange))
            return 'exchange: array expected';
          for (var i = 0; i < message.exchange.length; ++i)
            if (!$util.isString(message.exchange[i]))
              return 'exchange: string[] expected';
        }
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          if (
            !$util.isInteger(message.sequenceNumber) &&
            !(
              message.sequenceNumber &&
              $util.isInteger(message.sequenceNumber.low) &&
              $util.isInteger(message.sequenceNumber.high)
            )
          )
            return 'sequenceNumber: integer|Long expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a DepthByOrderEndEvent message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.DepthByOrderEndEvent} DepthByOrderEndEvent
       */
      DepthByOrderEndEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.DepthByOrderEndEvent) return object;
        var message = new $root.rti.DepthByOrderEndEvent();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol) {
          if (!Array.isArray(object.symbol))
            throw TypeError('.rti.DepthByOrderEndEvent.symbol: array expected');
          message.symbol = [];
          for (var i = 0; i < object.symbol.length; ++i)
            message.symbol[i] = String(object.symbol[i]);
        }
        if (object.exchange) {
          if (!Array.isArray(object.exchange))
            throw TypeError(
              '.rti.DepthByOrderEndEvent.exchange: array expected',
            );
          message.exchange = [];
          for (var i = 0; i < object.exchange.length; ++i)
            message.exchange[i] = String(object.exchange[i]);
        }
        if (object.sequenceNumber != null)
          if ($util.Long)
            (message.sequenceNumber = $util.Long.fromValue(
              object.sequenceNumber,
            )).unsigned = true;
          else if (typeof object.sequenceNumber === 'string')
            message.sequenceNumber = parseInt(object.sequenceNumber, 10);
          else if (typeof object.sequenceNumber === 'number')
            message.sequenceNumber = object.sequenceNumber;
          else if (typeof object.sequenceNumber === 'object')
            message.sequenceNumber = new $util.LongBits(
              object.sequenceNumber.low >>> 0,
              object.sequenceNumber.high >>> 0,
            ).toNumber(true);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a DepthByOrderEndEvent message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {rti.DepthByOrderEndEvent} message DepthByOrderEndEvent
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      DepthByOrderEndEvent.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.symbol = [];
          object.exchange = [];
        }
        if (options.defaults) {
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.sequenceNumber =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.sequenceNumber = options.longs === String ? '0' : 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.templateId = 0;
        }
        if (message.symbol && message.symbol.length) {
          object.symbol = [];
          for (var j = 0; j < message.symbol.length; ++j)
            object.symbol[j] = message.symbol[j];
        }
        if (message.exchange && message.exchange.length) {
          object.exchange = [];
          for (var j = 0; j < message.exchange.length; ++j)
            object.exchange[j] = message.exchange[j];
        }
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          if (typeof message.sequenceNumber === 'number')
            object.sequenceNumber =
              options.longs === String
                ? String(message.sequenceNumber)
                : message.sequenceNumber;
          else
            object.sequenceNumber =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.sequenceNumber)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.sequenceNumber.low >>> 0,
                      message.sequenceNumber.high >>> 0,
                    ).toNumber(true)
                  : message.sequenceNumber;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this DepthByOrderEndEvent to JSON.
       * @function toJSON
       * @memberof rti.DepthByOrderEndEvent
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      DepthByOrderEndEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for DepthByOrderEndEvent
       * @function getTypeUrl
       * @memberof rti.DepthByOrderEndEvent
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      DepthByOrderEndEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.DepthByOrderEndEvent';
      };

      return DepthByOrderEndEvent;
    })();

    rti.SymbolMarginRate = (function () {
      /**
       * Properties of a SymbolMarginRate.
       * @memberof rti
       * @interface ISymbolMarginRate
       * @property {number} templateId SymbolMarginRate templateId
       * @property {string|null} [symbol] SymbolMarginRate symbol
       * @property {string|null} [exchange] SymbolMarginRate exchange
       * @property {boolean|null} [isSnapshot] SymbolMarginRate isSnapshot
       * @property {number|null} [marginRate] SymbolMarginRate marginRate
       */

      /**
       * Constructs a new SymbolMarginRate.
       * @memberof rti
       * @classdesc Represents a SymbolMarginRate.
       * @implements ISymbolMarginRate
       * @constructor
       * @param {rti.ISymbolMarginRate=} [properties] Properties to set
       */
      function SymbolMarginRate(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * SymbolMarginRate templateId.
       * @member {number} templateId
       * @memberof rti.SymbolMarginRate
       * @instance
       */
      SymbolMarginRate.prototype.templateId = 0;

      /**
       * SymbolMarginRate symbol.
       * @member {string} symbol
       * @memberof rti.SymbolMarginRate
       * @instance
       */
      SymbolMarginRate.prototype.symbol = '';

      /**
       * SymbolMarginRate exchange.
       * @member {string} exchange
       * @memberof rti.SymbolMarginRate
       * @instance
       */
      SymbolMarginRate.prototype.exchange = '';

      /**
       * SymbolMarginRate isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.SymbolMarginRate
       * @instance
       */
      SymbolMarginRate.prototype.isSnapshot = false;

      /**
       * SymbolMarginRate marginRate.
       * @member {number} marginRate
       * @memberof rti.SymbolMarginRate
       * @instance
       */
      SymbolMarginRate.prototype.marginRate = 0;

      /**
       * Creates a new SymbolMarginRate instance using the specified properties.
       * @function create
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {rti.ISymbolMarginRate=} [properties] Properties to set
       * @returns {rti.SymbolMarginRate} SymbolMarginRate instance
       */
      SymbolMarginRate.create = function create(properties) {
        return new SymbolMarginRate(properties);
      };

      /**
       * Encodes the specified SymbolMarginRate message. Does not implicitly {@link rti.SymbolMarginRate.verify|verify} messages.
       * @function encode
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {rti.ISymbolMarginRate} message SymbolMarginRate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      SymbolMarginRate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.marginRate != null &&
          Object.hasOwnProperty.call(message, 'marginRate')
        )
          writer
            .uint32(/* id 154103, wireType 1 =*/ 1232825)
            .double(message.marginRate);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified SymbolMarginRate message, length delimited. Does not implicitly {@link rti.SymbolMarginRate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {rti.ISymbolMarginRate} message SymbolMarginRate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      SymbolMarginRate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a SymbolMarginRate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.SymbolMarginRate} SymbolMarginRate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      SymbolMarginRate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.SymbolMarginRate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 154103:
              message.marginRate = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a SymbolMarginRate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.SymbolMarginRate} SymbolMarginRate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      SymbolMarginRate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a SymbolMarginRate message.
       * @function verify
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      SymbolMarginRate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (message.marginRate != null && message.hasOwnProperty('marginRate'))
          if (typeof message.marginRate !== 'number')
            return 'marginRate: number expected';
        return null;
      };

      /**
       * Creates a SymbolMarginRate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.SymbolMarginRate} SymbolMarginRate
       */
      SymbolMarginRate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.SymbolMarginRate) return object;
        var message = new $root.rti.SymbolMarginRate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.marginRate != null)
          message.marginRate = Number(object.marginRate);
        return message;
      };

      /**
       * Creates a plain object from a SymbolMarginRate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {rti.SymbolMarginRate} message SymbolMarginRate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      SymbolMarginRate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.marginRate = 0;
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (message.marginRate != null && message.hasOwnProperty('marginRate'))
          object.marginRate =
            options.json && !isFinite(message.marginRate)
              ? String(message.marginRate)
              : message.marginRate;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this SymbolMarginRate to JSON.
       * @function toJSON
       * @memberof rti.SymbolMarginRate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      SymbolMarginRate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for SymbolMarginRate
       * @function getTypeUrl
       * @memberof rti.SymbolMarginRate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      SymbolMarginRate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.SymbolMarginRate';
      };

      return SymbolMarginRate;
    })();

    rti.OrderPriceLimits = (function () {
      /**
       * Properties of an OrderPriceLimits.
       * @memberof rti
       * @interface IOrderPriceLimits
       * @property {number} templateId OrderPriceLimits templateId
       * @property {string|null} [symbol] OrderPriceLimits symbol
       * @property {string|null} [exchange] OrderPriceLimits exchange
       * @property {number|null} [presenceBits] OrderPriceLimits presenceBits
       * @property {number|null} [clearBits] OrderPriceLimits clearBits
       * @property {boolean|null} [isSnapshot] OrderPriceLimits isSnapshot
       * @property {number|null} [highPriceLimit] OrderPriceLimits highPriceLimit
       * @property {number|null} [lowPriceLimit] OrderPriceLimits lowPriceLimit
       * @property {number|null} [ssboe] OrderPriceLimits ssboe
       * @property {number|null} [usecs] OrderPriceLimits usecs
       */

      /**
       * Constructs a new OrderPriceLimits.
       * @memberof rti
       * @classdesc Represents an OrderPriceLimits.
       * @implements IOrderPriceLimits
       * @constructor
       * @param {rti.IOrderPriceLimits=} [properties] Properties to set
       */
      function OrderPriceLimits(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * OrderPriceLimits templateId.
       * @member {number} templateId
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.templateId = 0;

      /**
       * OrderPriceLimits symbol.
       * @member {string} symbol
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.symbol = '';

      /**
       * OrderPriceLimits exchange.
       * @member {string} exchange
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.exchange = '';

      /**
       * OrderPriceLimits presenceBits.
       * @member {number} presenceBits
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.presenceBits = 0;

      /**
       * OrderPriceLimits clearBits.
       * @member {number} clearBits
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.clearBits = 0;

      /**
       * OrderPriceLimits isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.isSnapshot = false;

      /**
       * OrderPriceLimits highPriceLimit.
       * @member {number} highPriceLimit
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.highPriceLimit = 0;

      /**
       * OrderPriceLimits lowPriceLimit.
       * @member {number} lowPriceLimit
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.lowPriceLimit = 0;

      /**
       * OrderPriceLimits ssboe.
       * @member {number} ssboe
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.ssboe = 0;

      /**
       * OrderPriceLimits usecs.
       * @member {number} usecs
       * @memberof rti.OrderPriceLimits
       * @instance
       */
      OrderPriceLimits.prototype.usecs = 0;

      /**
       * Creates a new OrderPriceLimits instance using the specified properties.
       * @function create
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {rti.IOrderPriceLimits=} [properties] Properties to set
       * @returns {rti.OrderPriceLimits} OrderPriceLimits instance
       */
      OrderPriceLimits.create = function create(properties) {
        return new OrderPriceLimits(properties);
      };

      /**
       * Encodes the specified OrderPriceLimits message. Does not implicitly {@link rti.OrderPriceLimits.verify|verify} messages.
       * @function encode
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {rti.IOrderPriceLimits} message OrderPriceLimits message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OrderPriceLimits.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .uint32(message.presenceBits);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.highPriceLimit != null &&
          Object.hasOwnProperty.call(message, 'highPriceLimit')
        )
          writer
            .uint32(/* id 154079, wireType 1 =*/ 1232633)
            .double(message.highPriceLimit);
        if (
          message.lowPriceLimit != null &&
          Object.hasOwnProperty.call(message, 'lowPriceLimit')
        )
          writer
            .uint32(/* id 154101, wireType 1 =*/ 1232809)
            .double(message.lowPriceLimit);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.clearBits != null &&
          Object.hasOwnProperty.call(message, 'clearBits')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .uint32(message.clearBits);
        return writer;
      };

      /**
       * Encodes the specified OrderPriceLimits message, length delimited. Does not implicitly {@link rti.OrderPriceLimits.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {rti.IOrderPriceLimits} message OrderPriceLimits message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OrderPriceLimits.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an OrderPriceLimits message from the specified reader or buffer.
       * @function decode
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.OrderPriceLimits} OrderPriceLimits
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OrderPriceLimits.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.OrderPriceLimits();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 149138:
              message.presenceBits = reader.uint32();
              break;
            case 154571:
              message.clearBits = reader.uint32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 154079:
              message.highPriceLimit = reader.double();
              break;
            case 154101:
              message.lowPriceLimit = reader.double();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an OrderPriceLimits message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.OrderPriceLimits} OrderPriceLimits
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OrderPriceLimits.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an OrderPriceLimits message.
       * @function verify
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      OrderPriceLimits.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          if (!$util.isInteger(message.clearBits))
            return 'clearBits: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (
          message.highPriceLimit != null &&
          message.hasOwnProperty('highPriceLimit')
        )
          if (typeof message.highPriceLimit !== 'number')
            return 'highPriceLimit: number expected';
        if (
          message.lowPriceLimit != null &&
          message.hasOwnProperty('lowPriceLimit')
        )
          if (typeof message.lowPriceLimit !== 'number')
            return 'lowPriceLimit: number expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates an OrderPriceLimits message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.OrderPriceLimits} OrderPriceLimits
       */
      OrderPriceLimits.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.OrderPriceLimits) return object;
        var message = new $root.rti.OrderPriceLimits();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.clearBits != null)
          message.clearBits = object.clearBits >>> 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.highPriceLimit != null)
          message.highPriceLimit = Number(object.highPriceLimit);
        if (object.lowPriceLimit != null)
          message.lowPriceLimit = Number(object.lowPriceLimit);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from an OrderPriceLimits message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {rti.OrderPriceLimits} message OrderPriceLimits
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      OrderPriceLimits.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.isSnapshot = false;
          object.presenceBits = 0;
          object.ssboe = 0;
          object.usecs = 0;
          object.highPriceLimit = 0;
          object.lowPriceLimit = 0;
          object.templateId = 0;
          object.clearBits = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (
          message.highPriceLimit != null &&
          message.hasOwnProperty('highPriceLimit')
        )
          object.highPriceLimit =
            options.json && !isFinite(message.highPriceLimit)
              ? String(message.highPriceLimit)
              : message.highPriceLimit;
        if (
          message.lowPriceLimit != null &&
          message.hasOwnProperty('lowPriceLimit')
        )
          object.lowPriceLimit =
            options.json && !isFinite(message.lowPriceLimit)
              ? String(message.lowPriceLimit)
              : message.lowPriceLimit;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.clearBits != null && message.hasOwnProperty('clearBits'))
          object.clearBits = message.clearBits;
        return object;
      };

      /**
       * Converts this OrderPriceLimits to JSON.
       * @function toJSON
       * @memberof rti.OrderPriceLimits
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      OrderPriceLimits.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for OrderPriceLimits
       * @function getTypeUrl
       * @memberof rti.OrderPriceLimits
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      OrderPriceLimits.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.OrderPriceLimits';
      };

      /**
       * PresenceBits enum.
       * @name rti.OrderPriceLimits.PresenceBits
       * @enum {number}
       * @property {number} HIGH_PRICE_LIMIT=1 HIGH_PRICE_LIMIT value
       * @property {number} LOW_PRICE_LIMIT=2 LOW_PRICE_LIMIT value
       */
      OrderPriceLimits.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'HIGH_PRICE_LIMIT')] = 1;
        values[(valuesById[2] = 'LOW_PRICE_LIMIT')] = 2;
        return values;
      })();

      return OrderPriceLimits;
    })();

    rti.RequestLoginInfo = (function () {
      /**
       * Properties of a RequestLoginInfo.
       * @memberof rti
       * @interface IRequestLoginInfo
       * @property {number} templateId RequestLoginInfo templateId
       * @property {Array.<string>|null} [userMsg] RequestLoginInfo userMsg
       */

      /**
       * Constructs a new RequestLoginInfo.
       * @memberof rti
       * @classdesc Represents a RequestLoginInfo.
       * @implements IRequestLoginInfo
       * @constructor
       * @param {rti.IRequestLoginInfo=} [properties] Properties to set
       */
      function RequestLoginInfo(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestLoginInfo templateId.
       * @member {number} templateId
       * @memberof rti.RequestLoginInfo
       * @instance
       */
      RequestLoginInfo.prototype.templateId = 0;

      /**
       * RequestLoginInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestLoginInfo
       * @instance
       */
      RequestLoginInfo.prototype.userMsg = $util.emptyArray;

      /**
       * Creates a new RequestLoginInfo instance using the specified properties.
       * @function create
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {rti.IRequestLoginInfo=} [properties] Properties to set
       * @returns {rti.RequestLoginInfo} RequestLoginInfo instance
       */
      RequestLoginInfo.create = function create(properties) {
        return new RequestLoginInfo(properties);
      };

      /**
       * Encodes the specified RequestLoginInfo message. Does not implicitly {@link rti.RequestLoginInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {rti.IRequestLoginInfo} message RequestLoginInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestLoginInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestLoginInfo message, length delimited. Does not implicitly {@link rti.RequestLoginInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {rti.IRequestLoginInfo} message RequestLoginInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestLoginInfo.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestLoginInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestLoginInfo} RequestLoginInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestLoginInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestLoginInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestLoginInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestLoginInfo} RequestLoginInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestLoginInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestLoginInfo message.
       * @function verify
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestLoginInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        return null;
      };

      /**
       * Creates a RequestLoginInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestLoginInfo} RequestLoginInfo
       */
      RequestLoginInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestLoginInfo) return object;
        var message = new $root.rti.RequestLoginInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestLoginInfo.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestLoginInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {rti.RequestLoginInfo} message RequestLoginInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestLoginInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestLoginInfo to JSON.
       * @function toJSON
       * @memberof rti.RequestLoginInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestLoginInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestLoginInfo
       * @function getTypeUrl
       * @memberof rti.RequestLoginInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestLoginInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestLoginInfo';
      };

      return RequestLoginInfo;
    })();

    rti.ResponseLoginInfo = (function () {
      /**
       * Properties of a ResponseLoginInfo.
       * @memberof rti
       * @interface IResponseLoginInfo
       * @property {number} templateId ResponseLoginInfo templateId
       * @property {Array.<string>|null} [userMsg] ResponseLoginInfo userMsg
       * @property {Array.<string>|null} [rpCode] ResponseLoginInfo rpCode
       * @property {string|null} [fcmId] ResponseLoginInfo fcmId
       * @property {string|null} [ibId] ResponseLoginInfo ibId
       * @property {string|null} [firstName] ResponseLoginInfo firstName
       * @property {string|null} [lastName] ResponseLoginInfo lastName
       * @property {rti.ResponseLoginInfo.UserType|null} [userType] ResponseLoginInfo userType
       */

      /**
       * Constructs a new ResponseLoginInfo.
       * @memberof rti
       * @classdesc Represents a ResponseLoginInfo.
       * @implements IResponseLoginInfo
       * @constructor
       * @param {rti.IResponseLoginInfo=} [properties] Properties to set
       */
      function ResponseLoginInfo(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseLoginInfo templateId.
       * @member {number} templateId
       * @memberof rti.ResponseLoginInfo
       * @instance
       */
      ResponseLoginInfo.prototype.templateId = 0;

      /**
       * ResponseLoginInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseLoginInfo
       * @instance
       */
      ResponseLoginInfo.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseLoginInfo rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseLoginInfo
       * @instance
       */
      ResponseLoginInfo.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseLoginInfo fcmId.
       * @member {string} fcmId
       * @memberof rti.ResponseLoginInfo
       * @instance
       */
      ResponseLoginInfo.prototype.fcmId = '';

      /**
       * ResponseLoginInfo ibId.
       * @member {string} ibId
       * @memberof rti.ResponseLoginInfo
       * @instance
       */
      ResponseLoginInfo.prototype.ibId = '';

      /**
       * ResponseLoginInfo firstName.
       * @member {string} firstName
       * @memberof rti.ResponseLoginInfo
       * @instance
       */
      ResponseLoginInfo.prototype.firstName = '';

      /**
       * ResponseLoginInfo lastName.
       * @member {string} lastName
       * @memberof rti.ResponseLoginInfo
       * @instance
       */
      ResponseLoginInfo.prototype.lastName = '';

      /**
       * ResponseLoginInfo userType.
       * @member {rti.ResponseLoginInfo.UserType} userType
       * @memberof rti.ResponseLoginInfo
       * @instance
       */
      ResponseLoginInfo.prototype.userType = 0;

      /**
       * Creates a new ResponseLoginInfo instance using the specified properties.
       * @function create
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {rti.IResponseLoginInfo=} [properties] Properties to set
       * @returns {rti.ResponseLoginInfo} ResponseLoginInfo instance
       */
      ResponseLoginInfo.create = function create(properties) {
        return new ResponseLoginInfo(properties);
      };

      /**
       * Encodes the specified ResponseLoginInfo message. Does not implicitly {@link rti.ResponseLoginInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {rti.IResponseLoginInfo} message ResponseLoginInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseLoginInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.userType != null &&
          Object.hasOwnProperty.call(message, 'userType')
        )
          writer
            .uint32(/* id 154036, wireType 0 =*/ 1232288)
            .int32(message.userType);
        if (
          message.firstName != null &&
          Object.hasOwnProperty.call(message, 'firstName')
        )
          writer
            .uint32(/* id 154216, wireType 2 =*/ 1233730)
            .string(message.firstName);
        if (
          message.lastName != null &&
          Object.hasOwnProperty.call(message, 'lastName')
        )
          writer
            .uint32(/* id 154217, wireType 2 =*/ 1233738)
            .string(message.lastName);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseLoginInfo message, length delimited. Does not implicitly {@link rti.ResponseLoginInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {rti.IResponseLoginInfo} message ResponseLoginInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseLoginInfo.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseLoginInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseLoginInfo} ResponseLoginInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseLoginInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseLoginInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154216:
              message.firstName = reader.string();
              break;
            case 154217:
              message.lastName = reader.string();
              break;
            case 154036:
              message.userType = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseLoginInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseLoginInfo} ResponseLoginInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseLoginInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseLoginInfo message.
       * @function verify
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseLoginInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.firstName != null && message.hasOwnProperty('firstName'))
          if (!$util.isString(message.firstName))
            return 'firstName: string expected';
        if (message.lastName != null && message.hasOwnProperty('lastName'))
          if (!$util.isString(message.lastName))
            return 'lastName: string expected';
        if (message.userType != null && message.hasOwnProperty('userType'))
          switch (message.userType) {
            default:
              return 'userType: enum value expected';
            case 0:
            case 1:
            case 2:
            case 3:
              break;
          }
        return null;
      };

      /**
       * Creates a ResponseLoginInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseLoginInfo} ResponseLoginInfo
       */
      ResponseLoginInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseLoginInfo) return object;
        var message = new $root.rti.ResponseLoginInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseLoginInfo.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseLoginInfo.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.firstName != null)
          message.firstName = String(object.firstName);
        if (object.lastName != null) message.lastName = String(object.lastName);
        switch (object.userType) {
          case 'USER_TYPE_ADMIN':
          case 0:
            message.userType = 0;
            break;
          case 'USER_TYPE_FCM':
          case 1:
            message.userType = 1;
            break;
          case 'USER_TYPE_IB':
          case 2:
            message.userType = 2;
            break;
          case 'USER_TYPE_TRADER':
          case 3:
            message.userType = 3;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseLoginInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {rti.ResponseLoginInfo} message ResponseLoginInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseLoginInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.fcmId = '';
          object.ibId = '';
          object.userType = options.enums === String ? 'USER_TYPE_ADMIN' : 0;
          object.firstName = '';
          object.lastName = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.userType != null && message.hasOwnProperty('userType'))
          object.userType =
            options.enums === String
              ? $root.rti.ResponseLoginInfo.UserType[message.userType]
              : message.userType;
        if (message.firstName != null && message.hasOwnProperty('firstName'))
          object.firstName = message.firstName;
        if (message.lastName != null && message.hasOwnProperty('lastName'))
          object.lastName = message.lastName;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseLoginInfo to JSON.
       * @function toJSON
       * @memberof rti.ResponseLoginInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseLoginInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseLoginInfo
       * @function getTypeUrl
       * @memberof rti.ResponseLoginInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseLoginInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseLoginInfo';
      };

      /**
       * UserType enum.
       * @name rti.ResponseLoginInfo.UserType
       * @enum {number}
       * @property {number} USER_TYPE_ADMIN=0 USER_TYPE_ADMIN value
       * @property {number} USER_TYPE_FCM=1 USER_TYPE_FCM value
       * @property {number} USER_TYPE_IB=2 USER_TYPE_IB value
       * @property {number} USER_TYPE_TRADER=3 USER_TYPE_TRADER value
       */
      ResponseLoginInfo.UserType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[0] = 'USER_TYPE_ADMIN')] = 0;
        values[(valuesById[1] = 'USER_TYPE_FCM')] = 1;
        values[(valuesById[2] = 'USER_TYPE_IB')] = 2;
        values[(valuesById[3] = 'USER_TYPE_TRADER')] = 3;
        return values;
      })();

      return ResponseLoginInfo;
    })();

    rti.RequestAccountList = (function () {
      /**
       * Properties of a RequestAccountList.
       * @memberof rti
       * @interface IRequestAccountList
       * @property {number} templateId RequestAccountList templateId
       * @property {Array.<string>|null} [userMsg] RequestAccountList userMsg
       * @property {string|null} [fcmId] RequestAccountList fcmId
       * @property {string|null} [ibId] RequestAccountList ibId
       * @property {rti.RequestAccountList.UserType|null} [userType] RequestAccountList userType
       */

      /**
       * Constructs a new RequestAccountList.
       * @memberof rti
       * @classdesc Represents a RequestAccountList.
       * @implements IRequestAccountList
       * @constructor
       * @param {rti.IRequestAccountList=} [properties] Properties to set
       */
      function RequestAccountList(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestAccountList templateId.
       * @member {number} templateId
       * @memberof rti.RequestAccountList
       * @instance
       */
      RequestAccountList.prototype.templateId = 0;

      /**
       * RequestAccountList userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestAccountList
       * @instance
       */
      RequestAccountList.prototype.userMsg = $util.emptyArray;

      /**
       * RequestAccountList fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestAccountList
       * @instance
       */
      RequestAccountList.prototype.fcmId = '';

      /**
       * RequestAccountList ibId.
       * @member {string} ibId
       * @memberof rti.RequestAccountList
       * @instance
       */
      RequestAccountList.prototype.ibId = '';

      /**
       * RequestAccountList userType.
       * @member {rti.RequestAccountList.UserType} userType
       * @memberof rti.RequestAccountList
       * @instance
       */
      RequestAccountList.prototype.userType = 1;

      /**
       * Creates a new RequestAccountList instance using the specified properties.
       * @function create
       * @memberof rti.RequestAccountList
       * @static
       * @param {rti.IRequestAccountList=} [properties] Properties to set
       * @returns {rti.RequestAccountList} RequestAccountList instance
       */
      RequestAccountList.create = function create(properties) {
        return new RequestAccountList(properties);
      };

      /**
       * Encodes the specified RequestAccountList message. Does not implicitly {@link rti.RequestAccountList.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestAccountList
       * @static
       * @param {rti.IRequestAccountList} message RequestAccountList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAccountList.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.userType != null &&
          Object.hasOwnProperty.call(message, 'userType')
        )
          writer
            .uint32(/* id 154036, wireType 0 =*/ 1232288)
            .int32(message.userType);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestAccountList message, length delimited. Does not implicitly {@link rti.RequestAccountList.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestAccountList
       * @static
       * @param {rti.IRequestAccountList} message RequestAccountList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAccountList.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestAccountList message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestAccountList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestAccountList} RequestAccountList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAccountList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestAccountList();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154036:
              message.userType = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestAccountList message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestAccountList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestAccountList} RequestAccountList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAccountList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestAccountList message.
       * @function verify
       * @memberof rti.RequestAccountList
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestAccountList.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.userType != null && message.hasOwnProperty('userType'))
          switch (message.userType) {
            default:
              return 'userType: enum value expected';
            case 1:
            case 2:
            case 3:
              break;
          }
        return null;
      };

      /**
       * Creates a RequestAccountList message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestAccountList
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestAccountList} RequestAccountList
       */
      RequestAccountList.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestAccountList) return object;
        var message = new $root.rti.RequestAccountList();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestAccountList.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        switch (object.userType) {
          case 'USER_TYPE_FCM':
          case 1:
            message.userType = 1;
            break;
          case 'USER_TYPE_IB':
          case 2:
            message.userType = 2;
            break;
          case 'USER_TYPE_TRADER':
          case 3:
            message.userType = 3;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestAccountList message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestAccountList
       * @static
       * @param {rti.RequestAccountList} message RequestAccountList
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestAccountList.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.fcmId = '';
          object.ibId = '';
          object.userType = options.enums === String ? 'USER_TYPE_FCM' : 1;
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.userType != null && message.hasOwnProperty('userType'))
          object.userType =
            options.enums === String
              ? $root.rti.RequestAccountList.UserType[message.userType]
              : message.userType;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestAccountList to JSON.
       * @function toJSON
       * @memberof rti.RequestAccountList
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestAccountList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestAccountList
       * @function getTypeUrl
       * @memberof rti.RequestAccountList
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestAccountList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestAccountList';
      };

      /**
       * UserType enum.
       * @name rti.RequestAccountList.UserType
       * @enum {number}
       * @property {number} USER_TYPE_FCM=1 USER_TYPE_FCM value
       * @property {number} USER_TYPE_IB=2 USER_TYPE_IB value
       * @property {number} USER_TYPE_TRADER=3 USER_TYPE_TRADER value
       */
      RequestAccountList.UserType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'USER_TYPE_FCM')] = 1;
        values[(valuesById[2] = 'USER_TYPE_IB')] = 2;
        values[(valuesById[3] = 'USER_TYPE_TRADER')] = 3;
        return values;
      })();

      return RequestAccountList;
    })();

    rti.ResponseAccountList = (function () {
      /**
       * Properties of a ResponseAccountList.
       * @memberof rti
       * @interface IResponseAccountList
       * @property {number} templateId ResponseAccountList templateId
       * @property {Array.<string>|null} [userMsg] ResponseAccountList userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseAccountList rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseAccountList rpCode
       * @property {string|null} [fcmId] ResponseAccountList fcmId
       * @property {string|null} [ibId] ResponseAccountList ibId
       * @property {string|null} [accountId] ResponseAccountList accountId
       * @property {string|null} [accountName] ResponseAccountList accountName
       * @property {string|null} [accountCurrency] ResponseAccountList accountCurrency
       * @property {string|null} [accountAutoLiquidate] ResponseAccountList accountAutoLiquidate
       * @property {string|null} [autoLiqThresholdCurrentValue] ResponseAccountList autoLiqThresholdCurrentValue
       */

      /**
       * Constructs a new ResponseAccountList.
       * @memberof rti
       * @classdesc Represents a ResponseAccountList.
       * @implements IResponseAccountList
       * @constructor
       * @param {rti.IResponseAccountList=} [properties] Properties to set
       */
      function ResponseAccountList(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseAccountList templateId.
       * @member {number} templateId
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.templateId = 0;

      /**
       * ResponseAccountList userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseAccountList rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseAccountList rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseAccountList fcmId.
       * @member {string} fcmId
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.fcmId = '';

      /**
       * ResponseAccountList ibId.
       * @member {string} ibId
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.ibId = '';

      /**
       * ResponseAccountList accountId.
       * @member {string} accountId
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.accountId = '';

      /**
       * ResponseAccountList accountName.
       * @member {string} accountName
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.accountName = '';

      /**
       * ResponseAccountList accountCurrency.
       * @member {string} accountCurrency
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.accountCurrency = '';

      /**
       * ResponseAccountList accountAutoLiquidate.
       * @member {string} accountAutoLiquidate
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.accountAutoLiquidate = '';

      /**
       * ResponseAccountList autoLiqThresholdCurrentValue.
       * @member {string} autoLiqThresholdCurrentValue
       * @memberof rti.ResponseAccountList
       * @instance
       */
      ResponseAccountList.prototype.autoLiqThresholdCurrentValue = '';

      /**
       * Creates a new ResponseAccountList instance using the specified properties.
       * @function create
       * @memberof rti.ResponseAccountList
       * @static
       * @param {rti.IResponseAccountList=} [properties] Properties to set
       * @returns {rti.ResponseAccountList} ResponseAccountList instance
       */
      ResponseAccountList.create = function create(properties) {
        return new ResponseAccountList(properties);
      };

      /**
       * Encodes the specified ResponseAccountList message. Does not implicitly {@link rti.ResponseAccountList.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseAccountList
       * @static
       * @param {rti.IResponseAccountList} message ResponseAccountList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAccountList.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.accountAutoLiquidate != null &&
          Object.hasOwnProperty.call(message, 'accountAutoLiquidate')
        )
          writer
            .uint32(/* id 131035, wireType 2 =*/ 1048282)
            .string(message.accountAutoLiquidate);
        if (
          message.autoLiqThresholdCurrentValue != null &&
          Object.hasOwnProperty.call(message, 'autoLiqThresholdCurrentValue')
        )
          writer
            .uint32(/* id 131040, wireType 2 =*/ 1048322)
            .string(message.autoLiqThresholdCurrentValue);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.accountName != null &&
          Object.hasOwnProperty.call(message, 'accountName')
        )
          writer
            .uint32(/* id 154002, wireType 2 =*/ 1232018)
            .string(message.accountName);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.accountCurrency != null &&
          Object.hasOwnProperty.call(message, 'accountCurrency')
        )
          writer
            .uint32(/* id 154383, wireType 2 =*/ 1235066)
            .string(message.accountCurrency);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseAccountList message, length delimited. Does not implicitly {@link rti.ResponseAccountList.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseAccountList
       * @static
       * @param {rti.IResponseAccountList} message ResponseAccountList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAccountList.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseAccountList message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseAccountList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseAccountList} ResponseAccountList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAccountList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseAccountList();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 154002:
              message.accountName = reader.string();
              break;
            case 154383:
              message.accountCurrency = reader.string();
              break;
            case 131035:
              message.accountAutoLiquidate = reader.string();
              break;
            case 131040:
              message.autoLiqThresholdCurrentValue = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseAccountList message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseAccountList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseAccountList} ResponseAccountList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAccountList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseAccountList message.
       * @function verify
       * @memberof rti.ResponseAccountList
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseAccountList.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (
          message.accountName != null &&
          message.hasOwnProperty('accountName')
        )
          if (!$util.isString(message.accountName))
            return 'accountName: string expected';
        if (
          message.accountCurrency != null &&
          message.hasOwnProperty('accountCurrency')
        )
          if (!$util.isString(message.accountCurrency))
            return 'accountCurrency: string expected';
        if (
          message.accountAutoLiquidate != null &&
          message.hasOwnProperty('accountAutoLiquidate')
        )
          if (!$util.isString(message.accountAutoLiquidate))
            return 'accountAutoLiquidate: string expected';
        if (
          message.autoLiqThresholdCurrentValue != null &&
          message.hasOwnProperty('autoLiqThresholdCurrentValue')
        )
          if (!$util.isString(message.autoLiqThresholdCurrentValue))
            return 'autoLiqThresholdCurrentValue: string expected';
        return null;
      };

      /**
       * Creates a ResponseAccountList message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseAccountList
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseAccountList} ResponseAccountList
       */
      ResponseAccountList.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseAccountList) return object;
        var message = new $root.rti.ResponseAccountList();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseAccountList.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseAccountList.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseAccountList.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.accountName != null)
          message.accountName = String(object.accountName);
        if (object.accountCurrency != null)
          message.accountCurrency = String(object.accountCurrency);
        if (object.accountAutoLiquidate != null)
          message.accountAutoLiquidate = String(object.accountAutoLiquidate);
        if (object.autoLiqThresholdCurrentValue != null)
          message.autoLiqThresholdCurrentValue = String(
            object.autoLiqThresholdCurrentValue,
          );
        return message;
      };

      /**
       * Creates a plain object from a ResponseAccountList message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseAccountList
       * @static
       * @param {rti.ResponseAccountList} message ResponseAccountList
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseAccountList.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.accountAutoLiquidate = '';
          object.autoLiqThresholdCurrentValue = '';
          object.accountName = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.accountCurrency = '';
          object.templateId = 0;
        }
        if (
          message.accountAutoLiquidate != null &&
          message.hasOwnProperty('accountAutoLiquidate')
        )
          object.accountAutoLiquidate = message.accountAutoLiquidate;
        if (
          message.autoLiqThresholdCurrentValue != null &&
          message.hasOwnProperty('autoLiqThresholdCurrentValue')
        )
          object.autoLiqThresholdCurrentValue =
            message.autoLiqThresholdCurrentValue;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.accountName != null &&
          message.hasOwnProperty('accountName')
        )
          object.accountName = message.accountName;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (
          message.accountCurrency != null &&
          message.hasOwnProperty('accountCurrency')
        )
          object.accountCurrency = message.accountCurrency;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseAccountList to JSON.
       * @function toJSON
       * @memberof rti.ResponseAccountList
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseAccountList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseAccountList
       * @function getTypeUrl
       * @memberof rti.ResponseAccountList
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseAccountList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseAccountList';
      };

      return ResponseAccountList;
    })();

    rti.RequestAccountRmsInfo = (function () {
      /**
       * Properties of a RequestAccountRmsInfo.
       * @memberof rti
       * @interface IRequestAccountRmsInfo
       * @property {number} templateId RequestAccountRmsInfo templateId
       * @property {Array.<string>|null} [userMsg] RequestAccountRmsInfo userMsg
       * @property {string|null} [fcmId] RequestAccountRmsInfo fcmId
       * @property {string|null} [ibId] RequestAccountRmsInfo ibId
       * @property {rti.RequestAccountRmsInfo.UserType|null} [userType] RequestAccountRmsInfo userType
       */

      /**
       * Constructs a new RequestAccountRmsInfo.
       * @memberof rti
       * @classdesc Represents a RequestAccountRmsInfo.
       * @implements IRequestAccountRmsInfo
       * @constructor
       * @param {rti.IRequestAccountRmsInfo=} [properties] Properties to set
       */
      function RequestAccountRmsInfo(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestAccountRmsInfo templateId.
       * @member {number} templateId
       * @memberof rti.RequestAccountRmsInfo
       * @instance
       */
      RequestAccountRmsInfo.prototype.templateId = 0;

      /**
       * RequestAccountRmsInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestAccountRmsInfo
       * @instance
       */
      RequestAccountRmsInfo.prototype.userMsg = $util.emptyArray;

      /**
       * RequestAccountRmsInfo fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestAccountRmsInfo
       * @instance
       */
      RequestAccountRmsInfo.prototype.fcmId = '';

      /**
       * RequestAccountRmsInfo ibId.
       * @member {string} ibId
       * @memberof rti.RequestAccountRmsInfo
       * @instance
       */
      RequestAccountRmsInfo.prototype.ibId = '';

      /**
       * RequestAccountRmsInfo userType.
       * @member {rti.RequestAccountRmsInfo.UserType} userType
       * @memberof rti.RequestAccountRmsInfo
       * @instance
       */
      RequestAccountRmsInfo.prototype.userType = 1;

      /**
       * Creates a new RequestAccountRmsInfo instance using the specified properties.
       * @function create
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {rti.IRequestAccountRmsInfo=} [properties] Properties to set
       * @returns {rti.RequestAccountRmsInfo} RequestAccountRmsInfo instance
       */
      RequestAccountRmsInfo.create = function create(properties) {
        return new RequestAccountRmsInfo(properties);
      };

      /**
       * Encodes the specified RequestAccountRmsInfo message. Does not implicitly {@link rti.RequestAccountRmsInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {rti.IRequestAccountRmsInfo} message RequestAccountRmsInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAccountRmsInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.userType != null &&
          Object.hasOwnProperty.call(message, 'userType')
        )
          writer
            .uint32(/* id 154036, wireType 0 =*/ 1232288)
            .int32(message.userType);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestAccountRmsInfo message, length delimited. Does not implicitly {@link rti.RequestAccountRmsInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {rti.IRequestAccountRmsInfo} message RequestAccountRmsInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAccountRmsInfo.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestAccountRmsInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestAccountRmsInfo} RequestAccountRmsInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAccountRmsInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestAccountRmsInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154036:
              message.userType = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestAccountRmsInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestAccountRmsInfo} RequestAccountRmsInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAccountRmsInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestAccountRmsInfo message.
       * @function verify
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestAccountRmsInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.userType != null && message.hasOwnProperty('userType'))
          switch (message.userType) {
            default:
              return 'userType: enum value expected';
            case 1:
            case 2:
            case 3:
              break;
          }
        return null;
      };

      /**
       * Creates a RequestAccountRmsInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestAccountRmsInfo} RequestAccountRmsInfo
       */
      RequestAccountRmsInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestAccountRmsInfo) return object;
        var message = new $root.rti.RequestAccountRmsInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestAccountRmsInfo.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        switch (object.userType) {
          case 'USER_TYPE_FCM':
          case 1:
            message.userType = 1;
            break;
          case 'USER_TYPE_IB':
          case 2:
            message.userType = 2;
            break;
          case 'USER_TYPE_TRADER':
          case 3:
            message.userType = 3;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestAccountRmsInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {rti.RequestAccountRmsInfo} message RequestAccountRmsInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestAccountRmsInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.fcmId = '';
          object.ibId = '';
          object.userType = options.enums === String ? 'USER_TYPE_FCM' : 1;
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.userType != null && message.hasOwnProperty('userType'))
          object.userType =
            options.enums === String
              ? $root.rti.RequestAccountRmsInfo.UserType[message.userType]
              : message.userType;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestAccountRmsInfo to JSON.
       * @function toJSON
       * @memberof rti.RequestAccountRmsInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestAccountRmsInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestAccountRmsInfo
       * @function getTypeUrl
       * @memberof rti.RequestAccountRmsInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestAccountRmsInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestAccountRmsInfo';
      };

      /**
       * UserType enum.
       * @name rti.RequestAccountRmsInfo.UserType
       * @enum {number}
       * @property {number} USER_TYPE_FCM=1 USER_TYPE_FCM value
       * @property {number} USER_TYPE_IB=2 USER_TYPE_IB value
       * @property {number} USER_TYPE_TRADER=3 USER_TYPE_TRADER value
       */
      RequestAccountRmsInfo.UserType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'USER_TYPE_FCM')] = 1;
        values[(valuesById[2] = 'USER_TYPE_IB')] = 2;
        values[(valuesById[3] = 'USER_TYPE_TRADER')] = 3;
        return values;
      })();

      return RequestAccountRmsInfo;
    })();

    rti.ResponseAccountRmsInfo = (function () {
      /**
       * Properties of a ResponseAccountRmsInfo.
       * @memberof rti
       * @interface IResponseAccountRmsInfo
       * @property {number} templateId ResponseAccountRmsInfo templateId
       * @property {Array.<string>|null} [userMsg] ResponseAccountRmsInfo userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseAccountRmsInfo rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseAccountRmsInfo rpCode
       * @property {number|null} [presenceBits] ResponseAccountRmsInfo presenceBits
       * @property {string|null} [fcmId] ResponseAccountRmsInfo fcmId
       * @property {string|null} [ibId] ResponseAccountRmsInfo ibId
       * @property {string|null} [accountId] ResponseAccountRmsInfo accountId
       * @property {string|null} [currency] ResponseAccountRmsInfo currency
       * @property {string|null} [status] ResponseAccountRmsInfo status
       * @property {string|null} [algorithm] ResponseAccountRmsInfo algorithm
       * @property {string|null} [autoLiquidateCriteria] ResponseAccountRmsInfo autoLiquidateCriteria
       * @property {rti.ResponseAccountRmsInfo.AutoLiquidateFlag|null} [autoLiquidate] ResponseAccountRmsInfo autoLiquidate
       * @property {rti.ResponseAccountRmsInfo.AutoLiquidateFlag|null} [disableOnAutoLiquidate] ResponseAccountRmsInfo disableOnAutoLiquidate
       * @property {number|null} [autoLiquidateThreshold] ResponseAccountRmsInfo autoLiquidateThreshold
       * @property {number|null} [autoLiquidateMaxMinAccountBalance] ResponseAccountRmsInfo autoLiquidateMaxMinAccountBalance
       * @property {number|null} [lossLimit] ResponseAccountRmsInfo lossLimit
       * @property {number|null} [minAccountBalance] ResponseAccountRmsInfo minAccountBalance
       * @property {number|null} [minMarginBalance] ResponseAccountRmsInfo minMarginBalance
       * @property {number|null} [defaultCommission] ResponseAccountRmsInfo defaultCommission
       * @property {number|null} [buyLimit] ResponseAccountRmsInfo buyLimit
       * @property {number|null} [maxOrderQuantity] ResponseAccountRmsInfo maxOrderQuantity
       * @property {number|null} [sellLimit] ResponseAccountRmsInfo sellLimit
       * @property {boolean|null} [checkMinAccountBalance] ResponseAccountRmsInfo checkMinAccountBalance
       */

      /**
       * Constructs a new ResponseAccountRmsInfo.
       * @memberof rti
       * @classdesc Represents a ResponseAccountRmsInfo.
       * @implements IResponseAccountRmsInfo
       * @constructor
       * @param {rti.IResponseAccountRmsInfo=} [properties] Properties to set
       */
      function ResponseAccountRmsInfo(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseAccountRmsInfo templateId.
       * @member {number} templateId
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.templateId = 0;

      /**
       * ResponseAccountRmsInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseAccountRmsInfo rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseAccountRmsInfo rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseAccountRmsInfo presenceBits.
       * @member {number} presenceBits
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.presenceBits = 0;

      /**
       * ResponseAccountRmsInfo fcmId.
       * @member {string} fcmId
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.fcmId = '';

      /**
       * ResponseAccountRmsInfo ibId.
       * @member {string} ibId
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.ibId = '';

      /**
       * ResponseAccountRmsInfo accountId.
       * @member {string} accountId
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.accountId = '';

      /**
       * ResponseAccountRmsInfo currency.
       * @member {string} currency
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.currency = '';

      /**
       * ResponseAccountRmsInfo status.
       * @member {string} status
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.status = '';

      /**
       * ResponseAccountRmsInfo algorithm.
       * @member {string} algorithm
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.algorithm = '';

      /**
       * ResponseAccountRmsInfo autoLiquidateCriteria.
       * @member {string} autoLiquidateCriteria
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.autoLiquidateCriteria = '';

      /**
       * ResponseAccountRmsInfo autoLiquidate.
       * @member {rti.ResponseAccountRmsInfo.AutoLiquidateFlag} autoLiquidate
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.autoLiquidate = 1;

      /**
       * ResponseAccountRmsInfo disableOnAutoLiquidate.
       * @member {rti.ResponseAccountRmsInfo.AutoLiquidateFlag} disableOnAutoLiquidate
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.disableOnAutoLiquidate = 1;

      /**
       * ResponseAccountRmsInfo autoLiquidateThreshold.
       * @member {number} autoLiquidateThreshold
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.autoLiquidateThreshold = 0;

      /**
       * ResponseAccountRmsInfo autoLiquidateMaxMinAccountBalance.
       * @member {number} autoLiquidateMaxMinAccountBalance
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.autoLiquidateMaxMinAccountBalance = 0;

      /**
       * ResponseAccountRmsInfo lossLimit.
       * @member {number} lossLimit
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.lossLimit = 0;

      /**
       * ResponseAccountRmsInfo minAccountBalance.
       * @member {number} minAccountBalance
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.minAccountBalance = 0;

      /**
       * ResponseAccountRmsInfo minMarginBalance.
       * @member {number} minMarginBalance
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.minMarginBalance = 0;

      /**
       * ResponseAccountRmsInfo defaultCommission.
       * @member {number} defaultCommission
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.defaultCommission = 0;

      /**
       * ResponseAccountRmsInfo buyLimit.
       * @member {number} buyLimit
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.buyLimit = 0;

      /**
       * ResponseAccountRmsInfo maxOrderQuantity.
       * @member {number} maxOrderQuantity
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.maxOrderQuantity = 0;

      /**
       * ResponseAccountRmsInfo sellLimit.
       * @member {number} sellLimit
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.sellLimit = 0;

      /**
       * ResponseAccountRmsInfo checkMinAccountBalance.
       * @member {boolean} checkMinAccountBalance
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       */
      ResponseAccountRmsInfo.prototype.checkMinAccountBalance = false;

      /**
       * Creates a new ResponseAccountRmsInfo instance using the specified properties.
       * @function create
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {rti.IResponseAccountRmsInfo=} [properties] Properties to set
       * @returns {rti.ResponseAccountRmsInfo} ResponseAccountRmsInfo instance
       */
      ResponseAccountRmsInfo.create = function create(properties) {
        return new ResponseAccountRmsInfo(properties);
      };

      /**
       * Encodes the specified ResponseAccountRmsInfo message. Does not implicitly {@link rti.ResponseAccountRmsInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {rti.IResponseAccountRmsInfo} message ResponseAccountRmsInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAccountRmsInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.maxOrderQuantity != null &&
          Object.hasOwnProperty.call(message, 'maxOrderQuantity')
        )
          writer
            .uint32(/* id 110105, wireType 0 =*/ 880840)
            .int32(message.maxOrderQuantity);
        if (
          message.autoLiquidate != null &&
          Object.hasOwnProperty.call(message, 'autoLiquidate')
        )
          writer
            .uint32(/* id 131035, wireType 0 =*/ 1048280)
            .int32(message.autoLiquidate);
        if (
          message.autoLiquidateCriteria != null &&
          Object.hasOwnProperty.call(message, 'autoLiquidateCriteria')
        )
          writer
            .uint32(/* id 131036, wireType 2 =*/ 1048290)
            .string(message.autoLiquidateCriteria);
        if (
          message.autoLiquidateThreshold != null &&
          Object.hasOwnProperty.call(message, 'autoLiquidateThreshold')
        )
          writer
            .uint32(/* id 131037, wireType 1 =*/ 1048297)
            .double(message.autoLiquidateThreshold);
        if (
          message.disableOnAutoLiquidate != null &&
          Object.hasOwnProperty.call(message, 'disableOnAutoLiquidate')
        )
          writer
            .uint32(/* id 131038, wireType 0 =*/ 1048304)
            .int32(message.disableOnAutoLiquidate);
        if (
          message.autoLiquidateMaxMinAccountBalance != null &&
          Object.hasOwnProperty.call(
            message,
            'autoLiquidateMaxMinAccountBalance',
          )
        )
          writer
            .uint32(/* id 131039, wireType 1 =*/ 1048313)
            .double(message.autoLiquidateMaxMinAccountBalance);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.algorithm != null &&
          Object.hasOwnProperty.call(message, 'algorithm')
        )
          writer
            .uint32(/* id 150142, wireType 2 =*/ 1201138)
            .string(message.algorithm);
        if (
          message.defaultCommission != null &&
          Object.hasOwnProperty.call(message, 'defaultCommission')
        )
          writer
            .uint32(/* id 153368, wireType 1 =*/ 1226945)
            .double(message.defaultCommission);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 153622, wireType 0 =*/ 1228976)
            .uint32(message.presenceBits);
        if (
          message.status != null &&
          Object.hasOwnProperty.call(message, 'status')
        )
          writer
            .uint32(/* id 154003, wireType 2 =*/ 1232026)
            .string(message.status);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.buyLimit != null &&
          Object.hasOwnProperty.call(message, 'buyLimit')
        )
          writer
            .uint32(/* id 154009, wireType 0 =*/ 1232072)
            .int32(message.buyLimit);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.lossLimit != null &&
          Object.hasOwnProperty.call(message, 'lossLimit')
        )
          writer
            .uint32(/* id 154019, wireType 1 =*/ 1232153)
            .double(message.lossLimit);
        if (
          message.sellLimit != null &&
          Object.hasOwnProperty.call(message, 'sellLimit')
        )
          writer
            .uint32(/* id 154035, wireType 0 =*/ 1232280)
            .int32(message.sellLimit);
        if (
          message.currency != null &&
          Object.hasOwnProperty.call(message, 'currency')
        )
          writer
            .uint32(/* id 154383, wireType 2 =*/ 1235066)
            .string(message.currency);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.minAccountBalance != null &&
          Object.hasOwnProperty.call(message, 'minAccountBalance')
        )
          writer
            .uint32(/* id 156968, wireType 1 =*/ 1255745)
            .double(message.minAccountBalance);
        if (
          message.checkMinAccountBalance != null &&
          Object.hasOwnProperty.call(message, 'checkMinAccountBalance')
        )
          writer
            .uint32(/* id 156972, wireType 0 =*/ 1255776)
            .bool(message.checkMinAccountBalance);
        if (
          message.minMarginBalance != null &&
          Object.hasOwnProperty.call(message, 'minMarginBalance')
        )
          writer
            .uint32(/* id 156976, wireType 1 =*/ 1255809)
            .double(message.minMarginBalance);
        return writer;
      };

      /**
       * Encodes the specified ResponseAccountRmsInfo message, length delimited. Does not implicitly {@link rti.ResponseAccountRmsInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {rti.IResponseAccountRmsInfo} message ResponseAccountRmsInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAccountRmsInfo.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseAccountRmsInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseAccountRmsInfo} ResponseAccountRmsInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAccountRmsInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseAccountRmsInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 153622:
              message.presenceBits = reader.uint32();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 154383:
              message.currency = reader.string();
              break;
            case 154003:
              message.status = reader.string();
              break;
            case 150142:
              message.algorithm = reader.string();
              break;
            case 131036:
              message.autoLiquidateCriteria = reader.string();
              break;
            case 131035:
              message.autoLiquidate = reader.int32();
              break;
            case 131038:
              message.disableOnAutoLiquidate = reader.int32();
              break;
            case 131037:
              message.autoLiquidateThreshold = reader.double();
              break;
            case 131039:
              message.autoLiquidateMaxMinAccountBalance = reader.double();
              break;
            case 154019:
              message.lossLimit = reader.double();
              break;
            case 156968:
              message.minAccountBalance = reader.double();
              break;
            case 156976:
              message.minMarginBalance = reader.double();
              break;
            case 153368:
              message.defaultCommission = reader.double();
              break;
            case 154009:
              message.buyLimit = reader.int32();
              break;
            case 110105:
              message.maxOrderQuantity = reader.int32();
              break;
            case 154035:
              message.sellLimit = reader.int32();
              break;
            case 156972:
              message.checkMinAccountBalance = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseAccountRmsInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseAccountRmsInfo} ResponseAccountRmsInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAccountRmsInfo.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseAccountRmsInfo message.
       * @function verify
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseAccountRmsInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.currency != null && message.hasOwnProperty('currency'))
          if (!$util.isString(message.currency))
            return 'currency: string expected';
        if (message.status != null && message.hasOwnProperty('status'))
          if (!$util.isString(message.status)) return 'status: string expected';
        if (message.algorithm != null && message.hasOwnProperty('algorithm'))
          if (!$util.isString(message.algorithm))
            return 'algorithm: string expected';
        if (
          message.autoLiquidateCriteria != null &&
          message.hasOwnProperty('autoLiquidateCriteria')
        )
          if (!$util.isString(message.autoLiquidateCriteria))
            return 'autoLiquidateCriteria: string expected';
        if (
          message.autoLiquidate != null &&
          message.hasOwnProperty('autoLiquidate')
        )
          switch (message.autoLiquidate) {
            default:
              return 'autoLiquidate: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.disableOnAutoLiquidate != null &&
          message.hasOwnProperty('disableOnAutoLiquidate')
        )
          switch (message.disableOnAutoLiquidate) {
            default:
              return 'disableOnAutoLiquidate: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.autoLiquidateThreshold != null &&
          message.hasOwnProperty('autoLiquidateThreshold')
        )
          if (typeof message.autoLiquidateThreshold !== 'number')
            return 'autoLiquidateThreshold: number expected';
        if (
          message.autoLiquidateMaxMinAccountBalance != null &&
          message.hasOwnProperty('autoLiquidateMaxMinAccountBalance')
        )
          if (typeof message.autoLiquidateMaxMinAccountBalance !== 'number')
            return 'autoLiquidateMaxMinAccountBalance: number expected';
        if (message.lossLimit != null && message.hasOwnProperty('lossLimit'))
          if (typeof message.lossLimit !== 'number')
            return 'lossLimit: number expected';
        if (
          message.minAccountBalance != null &&
          message.hasOwnProperty('minAccountBalance')
        )
          if (typeof message.minAccountBalance !== 'number')
            return 'minAccountBalance: number expected';
        if (
          message.minMarginBalance != null &&
          message.hasOwnProperty('minMarginBalance')
        )
          if (typeof message.minMarginBalance !== 'number')
            return 'minMarginBalance: number expected';
        if (
          message.defaultCommission != null &&
          message.hasOwnProperty('defaultCommission')
        )
          if (typeof message.defaultCommission !== 'number')
            return 'defaultCommission: number expected';
        if (message.buyLimit != null && message.hasOwnProperty('buyLimit'))
          if (!$util.isInteger(message.buyLimit))
            return 'buyLimit: integer expected';
        if (
          message.maxOrderQuantity != null &&
          message.hasOwnProperty('maxOrderQuantity')
        )
          if (!$util.isInteger(message.maxOrderQuantity))
            return 'maxOrderQuantity: integer expected';
        if (message.sellLimit != null && message.hasOwnProperty('sellLimit'))
          if (!$util.isInteger(message.sellLimit))
            return 'sellLimit: integer expected';
        if (
          message.checkMinAccountBalance != null &&
          message.hasOwnProperty('checkMinAccountBalance')
        )
          if (typeof message.checkMinAccountBalance !== 'boolean')
            return 'checkMinAccountBalance: boolean expected';
        return null;
      };

      /**
       * Creates a ResponseAccountRmsInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseAccountRmsInfo} ResponseAccountRmsInfo
       */
      ResponseAccountRmsInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseAccountRmsInfo) return object;
        var message = new $root.rti.ResponseAccountRmsInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseAccountRmsInfo.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseAccountRmsInfo.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseAccountRmsInfo.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.currency != null) message.currency = String(object.currency);
        if (object.status != null) message.status = String(object.status);
        if (object.algorithm != null)
          message.algorithm = String(object.algorithm);
        if (object.autoLiquidateCriteria != null)
          message.autoLiquidateCriteria = String(object.autoLiquidateCriteria);
        switch (object.autoLiquidate) {
          case 'ENABLED':
          case 1:
            message.autoLiquidate = 1;
            break;
          case 'DISABLED':
          case 2:
            message.autoLiquidate = 2;
            break;
        }
        switch (object.disableOnAutoLiquidate) {
          case 'ENABLED':
          case 1:
            message.disableOnAutoLiquidate = 1;
            break;
          case 'DISABLED':
          case 2:
            message.disableOnAutoLiquidate = 2;
            break;
        }
        if (object.autoLiquidateThreshold != null)
          message.autoLiquidateThreshold = Number(
            object.autoLiquidateThreshold,
          );
        if (object.autoLiquidateMaxMinAccountBalance != null)
          message.autoLiquidateMaxMinAccountBalance = Number(
            object.autoLiquidateMaxMinAccountBalance,
          );
        if (object.lossLimit != null)
          message.lossLimit = Number(object.lossLimit);
        if (object.minAccountBalance != null)
          message.minAccountBalance = Number(object.minAccountBalance);
        if (object.minMarginBalance != null)
          message.minMarginBalance = Number(object.minMarginBalance);
        if (object.defaultCommission != null)
          message.defaultCommission = Number(object.defaultCommission);
        if (object.buyLimit != null) message.buyLimit = object.buyLimit | 0;
        if (object.maxOrderQuantity != null)
          message.maxOrderQuantity = object.maxOrderQuantity | 0;
        if (object.sellLimit != null) message.sellLimit = object.sellLimit | 0;
        if (object.checkMinAccountBalance != null)
          message.checkMinAccountBalance = Boolean(
            object.checkMinAccountBalance,
          );
        return message;
      };

      /**
       * Creates a plain object from a ResponseAccountRmsInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {rti.ResponseAccountRmsInfo} message ResponseAccountRmsInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseAccountRmsInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.maxOrderQuantity = 0;
          object.autoLiquidate = options.enums === String ? 'ENABLED' : 1;
          object.autoLiquidateCriteria = '';
          object.autoLiquidateThreshold = 0;
          object.disableOnAutoLiquidate =
            options.enums === String ? 'ENABLED' : 1;
          object.autoLiquidateMaxMinAccountBalance = 0;
          object.algorithm = '';
          object.defaultCommission = 0;
          object.presenceBits = 0;
          object.status = '';
          object.accountId = '';
          object.buyLimit = 0;
          object.fcmId = '';
          object.ibId = '';
          object.lossLimit = 0;
          object.sellLimit = 0;
          object.currency = '';
          object.templateId = 0;
          object.minAccountBalance = 0;
          object.checkMinAccountBalance = false;
          object.minMarginBalance = 0;
        }
        if (
          message.maxOrderQuantity != null &&
          message.hasOwnProperty('maxOrderQuantity')
        )
          object.maxOrderQuantity = message.maxOrderQuantity;
        if (
          message.autoLiquidate != null &&
          message.hasOwnProperty('autoLiquidate')
        )
          object.autoLiquidate =
            options.enums === String
              ? $root.rti.ResponseAccountRmsInfo.AutoLiquidateFlag[
                  message.autoLiquidate
                ]
              : message.autoLiquidate;
        if (
          message.autoLiquidateCriteria != null &&
          message.hasOwnProperty('autoLiquidateCriteria')
        )
          object.autoLiquidateCriteria = message.autoLiquidateCriteria;
        if (
          message.autoLiquidateThreshold != null &&
          message.hasOwnProperty('autoLiquidateThreshold')
        )
          object.autoLiquidateThreshold =
            options.json && !isFinite(message.autoLiquidateThreshold)
              ? String(message.autoLiquidateThreshold)
              : message.autoLiquidateThreshold;
        if (
          message.disableOnAutoLiquidate != null &&
          message.hasOwnProperty('disableOnAutoLiquidate')
        )
          object.disableOnAutoLiquidate =
            options.enums === String
              ? $root.rti.ResponseAccountRmsInfo.AutoLiquidateFlag[
                  message.disableOnAutoLiquidate
                ]
              : message.disableOnAutoLiquidate;
        if (
          message.autoLiquidateMaxMinAccountBalance != null &&
          message.hasOwnProperty('autoLiquidateMaxMinAccountBalance')
        )
          object.autoLiquidateMaxMinAccountBalance =
            options.json && !isFinite(message.autoLiquidateMaxMinAccountBalance)
              ? String(message.autoLiquidateMaxMinAccountBalance)
              : message.autoLiquidateMaxMinAccountBalance;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.algorithm != null && message.hasOwnProperty('algorithm'))
          object.algorithm = message.algorithm;
        if (
          message.defaultCommission != null &&
          message.hasOwnProperty('defaultCommission')
        )
          object.defaultCommission =
            options.json && !isFinite(message.defaultCommission)
              ? String(message.defaultCommission)
              : message.defaultCommission;
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.status != null && message.hasOwnProperty('status'))
          object.status = message.status;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.buyLimit != null && message.hasOwnProperty('buyLimit'))
          object.buyLimit = message.buyLimit;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.lossLimit != null && message.hasOwnProperty('lossLimit'))
          object.lossLimit =
            options.json && !isFinite(message.lossLimit)
              ? String(message.lossLimit)
              : message.lossLimit;
        if (message.sellLimit != null && message.hasOwnProperty('sellLimit'))
          object.sellLimit = message.sellLimit;
        if (message.currency != null && message.hasOwnProperty('currency'))
          object.currency = message.currency;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.minAccountBalance != null &&
          message.hasOwnProperty('minAccountBalance')
        )
          object.minAccountBalance =
            options.json && !isFinite(message.minAccountBalance)
              ? String(message.minAccountBalance)
              : message.minAccountBalance;
        if (
          message.checkMinAccountBalance != null &&
          message.hasOwnProperty('checkMinAccountBalance')
        )
          object.checkMinAccountBalance = message.checkMinAccountBalance;
        if (
          message.minMarginBalance != null &&
          message.hasOwnProperty('minMarginBalance')
        )
          object.minMarginBalance =
            options.json && !isFinite(message.minMarginBalance)
              ? String(message.minMarginBalance)
              : message.minMarginBalance;
        return object;
      };

      /**
       * Converts this ResponseAccountRmsInfo to JSON.
       * @function toJSON
       * @memberof rti.ResponseAccountRmsInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseAccountRmsInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseAccountRmsInfo
       * @function getTypeUrl
       * @memberof rti.ResponseAccountRmsInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseAccountRmsInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseAccountRmsInfo';
      };

      /**
       * PresenceBits enum.
       * @name rti.ResponseAccountRmsInfo.PresenceBits
       * @enum {number}
       * @property {number} BUY_LIMIT=1 BUY_LIMIT value
       * @property {number} SELL_LIMIT=2 SELL_LIMIT value
       * @property {number} LOSS_LIMIT=4 LOSS_LIMIT value
       * @property {number} MAX_ORDER_QUANTITY=8 MAX_ORDER_QUANTITY value
       * @property {number} MIN_ACCOUNT_BALANCE=16 MIN_ACCOUNT_BALANCE value
       * @property {number} MIN_MARGIN_BALANCE=32 MIN_MARGIN_BALANCE value
       * @property {number} ALGORITHM=64 ALGORITHM value
       * @property {number} STATUS=128 STATUS value
       * @property {number} CURRENCY=256 CURRENCY value
       * @property {number} DEFAULT_COMMISSION=512 DEFAULT_COMMISSION value
       */
      ResponseAccountRmsInfo.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY_LIMIT')] = 1;
        values[(valuesById[2] = 'SELL_LIMIT')] = 2;
        values[(valuesById[4] = 'LOSS_LIMIT')] = 4;
        values[(valuesById[8] = 'MAX_ORDER_QUANTITY')] = 8;
        values[(valuesById[16] = 'MIN_ACCOUNT_BALANCE')] = 16;
        values[(valuesById[32] = 'MIN_MARGIN_BALANCE')] = 32;
        values[(valuesById[64] = 'ALGORITHM')] = 64;
        values[(valuesById[128] = 'STATUS')] = 128;
        values[(valuesById[256] = 'CURRENCY')] = 256;
        values[(valuesById[512] = 'DEFAULT_COMMISSION')] = 512;
        return values;
      })();

      /**
       * AutoLiquidateFlag enum.
       * @name rti.ResponseAccountRmsInfo.AutoLiquidateFlag
       * @enum {number}
       * @property {number} ENABLED=1 ENABLED value
       * @property {number} DISABLED=2 DISABLED value
       */
      ResponseAccountRmsInfo.AutoLiquidateFlag = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'ENABLED')] = 1;
        values[(valuesById[2] = 'DISABLED')] = 2;
        return values;
      })();

      return ResponseAccountRmsInfo;
    })();

    rti.RequestAccountRmsUpdates = (function () {
      /**
       * Properties of a RequestAccountRmsUpdates.
       * @memberof rti
       * @interface IRequestAccountRmsUpdates
       * @property {number} templateId RequestAccountRmsUpdates templateId
       * @property {Array.<string>|null} [userMsg] RequestAccountRmsUpdates userMsg
       * @property {string|null} [fcmId] RequestAccountRmsUpdates fcmId
       * @property {string|null} [ibId] RequestAccountRmsUpdates ibId
       * @property {string|null} [accountId] RequestAccountRmsUpdates accountId
       * @property {string|null} [request] RequestAccountRmsUpdates request
       * @property {number|null} [updateBits] RequestAccountRmsUpdates updateBits
       */

      /**
       * Constructs a new RequestAccountRmsUpdates.
       * @memberof rti
       * @classdesc Represents a RequestAccountRmsUpdates.
       * @implements IRequestAccountRmsUpdates
       * @constructor
       * @param {rti.IRequestAccountRmsUpdates=} [properties] Properties to set
       */
      function RequestAccountRmsUpdates(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestAccountRmsUpdates templateId.
       * @member {number} templateId
       * @memberof rti.RequestAccountRmsUpdates
       * @instance
       */
      RequestAccountRmsUpdates.prototype.templateId = 0;

      /**
       * RequestAccountRmsUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestAccountRmsUpdates
       * @instance
       */
      RequestAccountRmsUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * RequestAccountRmsUpdates fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestAccountRmsUpdates
       * @instance
       */
      RequestAccountRmsUpdates.prototype.fcmId = '';

      /**
       * RequestAccountRmsUpdates ibId.
       * @member {string} ibId
       * @memberof rti.RequestAccountRmsUpdates
       * @instance
       */
      RequestAccountRmsUpdates.prototype.ibId = '';

      /**
       * RequestAccountRmsUpdates accountId.
       * @member {string} accountId
       * @memberof rti.RequestAccountRmsUpdates
       * @instance
       */
      RequestAccountRmsUpdates.prototype.accountId = '';

      /**
       * RequestAccountRmsUpdates request.
       * @member {string} request
       * @memberof rti.RequestAccountRmsUpdates
       * @instance
       */
      RequestAccountRmsUpdates.prototype.request = '';

      /**
       * RequestAccountRmsUpdates updateBits.
       * @member {number} updateBits
       * @memberof rti.RequestAccountRmsUpdates
       * @instance
       */
      RequestAccountRmsUpdates.prototype.updateBits = 0;

      /**
       * Creates a new RequestAccountRmsUpdates instance using the specified properties.
       * @function create
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {rti.IRequestAccountRmsUpdates=} [properties] Properties to set
       * @returns {rti.RequestAccountRmsUpdates} RequestAccountRmsUpdates instance
       */
      RequestAccountRmsUpdates.create = function create(properties) {
        return new RequestAccountRmsUpdates(properties);
      };

      /**
       * Encodes the specified RequestAccountRmsUpdates message. Does not implicitly {@link rti.RequestAccountRmsUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {rti.IRequestAccountRmsUpdates} message RequestAccountRmsUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAccountRmsUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.request != null &&
          Object.hasOwnProperty.call(message, 'request')
        )
          writer
            .uint32(/* id 100000, wireType 2 =*/ 800002)
            .string(message.request);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.updateBits != null &&
          Object.hasOwnProperty.call(message, 'updateBits')
        )
          writer
            .uint32(/* id 154211, wireType 0 =*/ 1233688)
            .int32(message.updateBits);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestAccountRmsUpdates message, length delimited. Does not implicitly {@link rti.RequestAccountRmsUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {rti.IRequestAccountRmsUpdates} message RequestAccountRmsUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAccountRmsUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestAccountRmsUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestAccountRmsUpdates} RequestAccountRmsUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAccountRmsUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestAccountRmsUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 100000:
              message.request = reader.string();
              break;
            case 154211:
              message.updateBits = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestAccountRmsUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestAccountRmsUpdates} RequestAccountRmsUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAccountRmsUpdates.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestAccountRmsUpdates message.
       * @function verify
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestAccountRmsUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.request != null && message.hasOwnProperty('request'))
          if (!$util.isString(message.request))
            return 'request: string expected';
        if (message.updateBits != null && message.hasOwnProperty('updateBits'))
          if (!$util.isInteger(message.updateBits))
            return 'updateBits: integer expected';
        return null;
      };

      /**
       * Creates a RequestAccountRmsUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestAccountRmsUpdates} RequestAccountRmsUpdates
       */
      RequestAccountRmsUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestAccountRmsUpdates) return object;
        var message = new $root.rti.RequestAccountRmsUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestAccountRmsUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.request != null) message.request = String(object.request);
        if (object.updateBits != null)
          message.updateBits = object.updateBits | 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestAccountRmsUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {rti.RequestAccountRmsUpdates} message RequestAccountRmsUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestAccountRmsUpdates.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.request = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.updateBits = 0;
          object.templateId = 0;
        }
        if (message.request != null && message.hasOwnProperty('request'))
          object.request = message.request;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.updateBits != null && message.hasOwnProperty('updateBits'))
          object.updateBits = message.updateBits;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestAccountRmsUpdates to JSON.
       * @function toJSON
       * @memberof rti.RequestAccountRmsUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestAccountRmsUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestAccountRmsUpdates
       * @function getTypeUrl
       * @memberof rti.RequestAccountRmsUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestAccountRmsUpdates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestAccountRmsUpdates';
      };

      /**
       * UpdateBits enum.
       * @name rti.RequestAccountRmsUpdates.UpdateBits
       * @enum {number}
       * @property {number} AUTO_LIQ_THRESHOLD_CURRENT_VALUE=1 AUTO_LIQ_THRESHOLD_CURRENT_VALUE value
       */
      RequestAccountRmsUpdates.UpdateBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'AUTO_LIQ_THRESHOLD_CURRENT_VALUE')] = 1;
        return values;
      })();

      return RequestAccountRmsUpdates;
    })();

    rti.ResponseAccountRmsUpdates = (function () {
      /**
       * Properties of a ResponseAccountRmsUpdates.
       * @memberof rti
       * @interface IResponseAccountRmsUpdates
       * @property {number} templateId ResponseAccountRmsUpdates templateId
       * @property {Array.<string>|null} [userMsg] ResponseAccountRmsUpdates userMsg
       * @property {Array.<string>|null} [rpCode] ResponseAccountRmsUpdates rpCode
       */

      /**
       * Constructs a new ResponseAccountRmsUpdates.
       * @memberof rti
       * @classdesc Represents a ResponseAccountRmsUpdates.
       * @implements IResponseAccountRmsUpdates
       * @constructor
       * @param {rti.IResponseAccountRmsUpdates=} [properties] Properties to set
       */
      function ResponseAccountRmsUpdates(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseAccountRmsUpdates templateId.
       * @member {number} templateId
       * @memberof rti.ResponseAccountRmsUpdates
       * @instance
       */
      ResponseAccountRmsUpdates.prototype.templateId = 0;

      /**
       * ResponseAccountRmsUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseAccountRmsUpdates
       * @instance
       */
      ResponseAccountRmsUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseAccountRmsUpdates rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseAccountRmsUpdates
       * @instance
       */
      ResponseAccountRmsUpdates.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseAccountRmsUpdates instance using the specified properties.
       * @function create
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {rti.IResponseAccountRmsUpdates=} [properties] Properties to set
       * @returns {rti.ResponseAccountRmsUpdates} ResponseAccountRmsUpdates instance
       */
      ResponseAccountRmsUpdates.create = function create(properties) {
        return new ResponseAccountRmsUpdates(properties);
      };

      /**
       * Encodes the specified ResponseAccountRmsUpdates message. Does not implicitly {@link rti.ResponseAccountRmsUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {rti.IResponseAccountRmsUpdates} message ResponseAccountRmsUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAccountRmsUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseAccountRmsUpdates message, length delimited. Does not implicitly {@link rti.ResponseAccountRmsUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {rti.IResponseAccountRmsUpdates} message ResponseAccountRmsUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAccountRmsUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseAccountRmsUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseAccountRmsUpdates} ResponseAccountRmsUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAccountRmsUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseAccountRmsUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseAccountRmsUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseAccountRmsUpdates} ResponseAccountRmsUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAccountRmsUpdates.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseAccountRmsUpdates message.
       * @function verify
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseAccountRmsUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseAccountRmsUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseAccountRmsUpdates} ResponseAccountRmsUpdates
       */
      ResponseAccountRmsUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseAccountRmsUpdates)
          return object;
        var message = new $root.rti.ResponseAccountRmsUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseAccountRmsUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseAccountRmsUpdates.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseAccountRmsUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {rti.ResponseAccountRmsUpdates} message ResponseAccountRmsUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseAccountRmsUpdates.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseAccountRmsUpdates to JSON.
       * @function toJSON
       * @memberof rti.ResponseAccountRmsUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseAccountRmsUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseAccountRmsUpdates
       * @function getTypeUrl
       * @memberof rti.ResponseAccountRmsUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseAccountRmsUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseAccountRmsUpdates';
      };

      return ResponseAccountRmsUpdates;
    })();

    rti.RequestProductRmsInfo = (function () {
      /**
       * Properties of a RequestProductRmsInfo.
       * @memberof rti
       * @interface IRequestProductRmsInfo
       * @property {number} templateId RequestProductRmsInfo templateId
       * @property {Array.<string>|null} [userMsg] RequestProductRmsInfo userMsg
       * @property {string|null} [fcmId] RequestProductRmsInfo fcmId
       * @property {string|null} [ibId] RequestProductRmsInfo ibId
       * @property {string|null} [accountId] RequestProductRmsInfo accountId
       */

      /**
       * Constructs a new RequestProductRmsInfo.
       * @memberof rti
       * @classdesc Represents a RequestProductRmsInfo.
       * @implements IRequestProductRmsInfo
       * @constructor
       * @param {rti.IRequestProductRmsInfo=} [properties] Properties to set
       */
      function RequestProductRmsInfo(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestProductRmsInfo templateId.
       * @member {number} templateId
       * @memberof rti.RequestProductRmsInfo
       * @instance
       */
      RequestProductRmsInfo.prototype.templateId = 0;

      /**
       * RequestProductRmsInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestProductRmsInfo
       * @instance
       */
      RequestProductRmsInfo.prototype.userMsg = $util.emptyArray;

      /**
       * RequestProductRmsInfo fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestProductRmsInfo
       * @instance
       */
      RequestProductRmsInfo.prototype.fcmId = '';

      /**
       * RequestProductRmsInfo ibId.
       * @member {string} ibId
       * @memberof rti.RequestProductRmsInfo
       * @instance
       */
      RequestProductRmsInfo.prototype.ibId = '';

      /**
       * RequestProductRmsInfo accountId.
       * @member {string} accountId
       * @memberof rti.RequestProductRmsInfo
       * @instance
       */
      RequestProductRmsInfo.prototype.accountId = '';

      /**
       * Creates a new RequestProductRmsInfo instance using the specified properties.
       * @function create
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {rti.IRequestProductRmsInfo=} [properties] Properties to set
       * @returns {rti.RequestProductRmsInfo} RequestProductRmsInfo instance
       */
      RequestProductRmsInfo.create = function create(properties) {
        return new RequestProductRmsInfo(properties);
      };

      /**
       * Encodes the specified RequestProductRmsInfo message. Does not implicitly {@link rti.RequestProductRmsInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {rti.IRequestProductRmsInfo} message RequestProductRmsInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestProductRmsInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestProductRmsInfo message, length delimited. Does not implicitly {@link rti.RequestProductRmsInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {rti.IRequestProductRmsInfo} message RequestProductRmsInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestProductRmsInfo.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestProductRmsInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestProductRmsInfo} RequestProductRmsInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestProductRmsInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestProductRmsInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestProductRmsInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestProductRmsInfo} RequestProductRmsInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestProductRmsInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestProductRmsInfo message.
       * @function verify
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestProductRmsInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        return null;
      };

      /**
       * Creates a RequestProductRmsInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestProductRmsInfo} RequestProductRmsInfo
       */
      RequestProductRmsInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestProductRmsInfo) return object;
        var message = new $root.rti.RequestProductRmsInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestProductRmsInfo.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        return message;
      };

      /**
       * Creates a plain object from a RequestProductRmsInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {rti.RequestProductRmsInfo} message RequestProductRmsInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestProductRmsInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestProductRmsInfo to JSON.
       * @function toJSON
       * @memberof rti.RequestProductRmsInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestProductRmsInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestProductRmsInfo
       * @function getTypeUrl
       * @memberof rti.RequestProductRmsInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestProductRmsInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestProductRmsInfo';
      };

      return RequestProductRmsInfo;
    })();

    rti.ResponseProductRmsInfo = (function () {
      /**
       * Properties of a ResponseProductRmsInfo.
       * @memberof rti
       * @interface IResponseProductRmsInfo
       * @property {number} templateId ResponseProductRmsInfo templateId
       * @property {Array.<string>|null} [userMsg] ResponseProductRmsInfo userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseProductRmsInfo rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseProductRmsInfo rpCode
       * @property {number|null} [presenceBits] ResponseProductRmsInfo presenceBits
       * @property {string|null} [fcmId] ResponseProductRmsInfo fcmId
       * @property {string|null} [ibId] ResponseProductRmsInfo ibId
       * @property {string|null} [accountId] ResponseProductRmsInfo accountId
       * @property {string|null} [productCode] ResponseProductRmsInfo productCode
       * @property {number|null} [lossLimit] ResponseProductRmsInfo lossLimit
       * @property {number|null} [commissionFillRate] ResponseProductRmsInfo commissionFillRate
       * @property {number|null} [buyMarginRate] ResponseProductRmsInfo buyMarginRate
       * @property {number|null} [sellMarginRate] ResponseProductRmsInfo sellMarginRate
       * @property {number|null} [buyLimit] ResponseProductRmsInfo buyLimit
       * @property {number|null} [maxOrderQuantity] ResponseProductRmsInfo maxOrderQuantity
       * @property {number|null} [sellLimit] ResponseProductRmsInfo sellLimit
       */

      /**
       * Constructs a new ResponseProductRmsInfo.
       * @memberof rti
       * @classdesc Represents a ResponseProductRmsInfo.
       * @implements IResponseProductRmsInfo
       * @constructor
       * @param {rti.IResponseProductRmsInfo=} [properties] Properties to set
       */
      function ResponseProductRmsInfo(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseProductRmsInfo templateId.
       * @member {number} templateId
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.templateId = 0;

      /**
       * ResponseProductRmsInfo userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseProductRmsInfo rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseProductRmsInfo rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseProductRmsInfo presenceBits.
       * @member {number} presenceBits
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.presenceBits = 0;

      /**
       * ResponseProductRmsInfo fcmId.
       * @member {string} fcmId
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.fcmId = '';

      /**
       * ResponseProductRmsInfo ibId.
       * @member {string} ibId
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.ibId = '';

      /**
       * ResponseProductRmsInfo accountId.
       * @member {string} accountId
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.accountId = '';

      /**
       * ResponseProductRmsInfo productCode.
       * @member {string} productCode
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.productCode = '';

      /**
       * ResponseProductRmsInfo lossLimit.
       * @member {number} lossLimit
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.lossLimit = 0;

      /**
       * ResponseProductRmsInfo commissionFillRate.
       * @member {number} commissionFillRate
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.commissionFillRate = 0;

      /**
       * ResponseProductRmsInfo buyMarginRate.
       * @member {number} buyMarginRate
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.buyMarginRate = 0;

      /**
       * ResponseProductRmsInfo sellMarginRate.
       * @member {number} sellMarginRate
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.sellMarginRate = 0;

      /**
       * ResponseProductRmsInfo buyLimit.
       * @member {number} buyLimit
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.buyLimit = 0;

      /**
       * ResponseProductRmsInfo maxOrderQuantity.
       * @member {number} maxOrderQuantity
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.maxOrderQuantity = 0;

      /**
       * ResponseProductRmsInfo sellLimit.
       * @member {number} sellLimit
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       */
      ResponseProductRmsInfo.prototype.sellLimit = 0;

      /**
       * Creates a new ResponseProductRmsInfo instance using the specified properties.
       * @function create
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {rti.IResponseProductRmsInfo=} [properties] Properties to set
       * @returns {rti.ResponseProductRmsInfo} ResponseProductRmsInfo instance
       */
      ResponseProductRmsInfo.create = function create(properties) {
        return new ResponseProductRmsInfo(properties);
      };

      /**
       * Encodes the specified ResponseProductRmsInfo message. Does not implicitly {@link rti.ResponseProductRmsInfo.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {rti.IResponseProductRmsInfo} message ResponseProductRmsInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseProductRmsInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.productCode != null &&
          Object.hasOwnProperty.call(message, 'productCode')
        )
          writer
            .uint32(/* id 100749, wireType 2 =*/ 805994)
            .string(message.productCode);
        if (
          message.maxOrderQuantity != null &&
          Object.hasOwnProperty.call(message, 'maxOrderQuantity')
        )
          writer
            .uint32(/* id 110105, wireType 0 =*/ 880840)
            .int32(message.maxOrderQuantity);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.presenceBits != null &&
          Object.hasOwnProperty.call(message, 'presenceBits')
        )
          writer
            .uint32(/* id 153622, wireType 0 =*/ 1228976)
            .uint32(message.presenceBits);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.buyLimit != null &&
          Object.hasOwnProperty.call(message, 'buyLimit')
        )
          writer
            .uint32(/* id 154009, wireType 0 =*/ 1232072)
            .int32(message.buyLimit);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.lossLimit != null &&
          Object.hasOwnProperty.call(message, 'lossLimit')
        )
          writer
            .uint32(/* id 154019, wireType 1 =*/ 1232153)
            .double(message.lossLimit);
        if (
          message.sellLimit != null &&
          Object.hasOwnProperty.call(message, 'sellLimit')
        )
          writer
            .uint32(/* id 154035, wireType 0 =*/ 1232280)
            .int32(message.sellLimit);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.commissionFillRate != null &&
          Object.hasOwnProperty.call(message, 'commissionFillRate')
        )
          writer
            .uint32(/* id 156969, wireType 1 =*/ 1255753)
            .double(message.commissionFillRate);
        if (
          message.buyMarginRate != null &&
          Object.hasOwnProperty.call(message, 'buyMarginRate')
        )
          writer
            .uint32(/* id 157003, wireType 1 =*/ 1256025)
            .double(message.buyMarginRate);
        if (
          message.sellMarginRate != null &&
          Object.hasOwnProperty.call(message, 'sellMarginRate')
        )
          writer
            .uint32(/* id 157004, wireType 1 =*/ 1256033)
            .double(message.sellMarginRate);
        return writer;
      };

      /**
       * Encodes the specified ResponseProductRmsInfo message, length delimited. Does not implicitly {@link rti.ResponseProductRmsInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {rti.IResponseProductRmsInfo} message ResponseProductRmsInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseProductRmsInfo.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseProductRmsInfo message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseProductRmsInfo} ResponseProductRmsInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseProductRmsInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseProductRmsInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 153622:
              message.presenceBits = reader.uint32();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 100749:
              message.productCode = reader.string();
              break;
            case 154019:
              message.lossLimit = reader.double();
              break;
            case 156969:
              message.commissionFillRate = reader.double();
              break;
            case 157003:
              message.buyMarginRate = reader.double();
              break;
            case 157004:
              message.sellMarginRate = reader.double();
              break;
            case 154009:
              message.buyLimit = reader.int32();
              break;
            case 110105:
              message.maxOrderQuantity = reader.int32();
              break;
            case 154035:
              message.sellLimit = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseProductRmsInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseProductRmsInfo} ResponseProductRmsInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseProductRmsInfo.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseProductRmsInfo message.
       * @function verify
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseProductRmsInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          if (!$util.isInteger(message.presenceBits))
            return 'presenceBits: integer expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          if (!$util.isString(message.productCode))
            return 'productCode: string expected';
        if (message.lossLimit != null && message.hasOwnProperty('lossLimit'))
          if (typeof message.lossLimit !== 'number')
            return 'lossLimit: number expected';
        if (
          message.commissionFillRate != null &&
          message.hasOwnProperty('commissionFillRate')
        )
          if (typeof message.commissionFillRate !== 'number')
            return 'commissionFillRate: number expected';
        if (
          message.buyMarginRate != null &&
          message.hasOwnProperty('buyMarginRate')
        )
          if (typeof message.buyMarginRate !== 'number')
            return 'buyMarginRate: number expected';
        if (
          message.sellMarginRate != null &&
          message.hasOwnProperty('sellMarginRate')
        )
          if (typeof message.sellMarginRate !== 'number')
            return 'sellMarginRate: number expected';
        if (message.buyLimit != null && message.hasOwnProperty('buyLimit'))
          if (!$util.isInteger(message.buyLimit))
            return 'buyLimit: integer expected';
        if (
          message.maxOrderQuantity != null &&
          message.hasOwnProperty('maxOrderQuantity')
        )
          if (!$util.isInteger(message.maxOrderQuantity))
            return 'maxOrderQuantity: integer expected';
        if (message.sellLimit != null && message.hasOwnProperty('sellLimit'))
          if (!$util.isInteger(message.sellLimit))
            return 'sellLimit: integer expected';
        return null;
      };

      /**
       * Creates a ResponseProductRmsInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseProductRmsInfo} ResponseProductRmsInfo
       */
      ResponseProductRmsInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseProductRmsInfo) return object;
        var message = new $root.rti.ResponseProductRmsInfo();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseProductRmsInfo.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseProductRmsInfo.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseProductRmsInfo.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.presenceBits != null)
          message.presenceBits = object.presenceBits >>> 0;
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.productCode != null)
          message.productCode = String(object.productCode);
        if (object.lossLimit != null)
          message.lossLimit = Number(object.lossLimit);
        if (object.commissionFillRate != null)
          message.commissionFillRate = Number(object.commissionFillRate);
        if (object.buyMarginRate != null)
          message.buyMarginRate = Number(object.buyMarginRate);
        if (object.sellMarginRate != null)
          message.sellMarginRate = Number(object.sellMarginRate);
        if (object.buyLimit != null) message.buyLimit = object.buyLimit | 0;
        if (object.maxOrderQuantity != null)
          message.maxOrderQuantity = object.maxOrderQuantity | 0;
        if (object.sellLimit != null) message.sellLimit = object.sellLimit | 0;
        return message;
      };

      /**
       * Creates a plain object from a ResponseProductRmsInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {rti.ResponseProductRmsInfo} message ResponseProductRmsInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseProductRmsInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.productCode = '';
          object.maxOrderQuantity = 0;
          object.presenceBits = 0;
          object.accountId = '';
          object.buyLimit = 0;
          object.fcmId = '';
          object.ibId = '';
          object.lossLimit = 0;
          object.sellLimit = 0;
          object.templateId = 0;
          object.commissionFillRate = 0;
          object.buyMarginRate = 0;
          object.sellMarginRate = 0;
        }
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          object.productCode = message.productCode;
        if (
          message.maxOrderQuantity != null &&
          message.hasOwnProperty('maxOrderQuantity')
        )
          object.maxOrderQuantity = message.maxOrderQuantity;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.presenceBits != null &&
          message.hasOwnProperty('presenceBits')
        )
          object.presenceBits = message.presenceBits;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.buyLimit != null && message.hasOwnProperty('buyLimit'))
          object.buyLimit = message.buyLimit;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.lossLimit != null && message.hasOwnProperty('lossLimit'))
          object.lossLimit =
            options.json && !isFinite(message.lossLimit)
              ? String(message.lossLimit)
              : message.lossLimit;
        if (message.sellLimit != null && message.hasOwnProperty('sellLimit'))
          object.sellLimit = message.sellLimit;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.commissionFillRate != null &&
          message.hasOwnProperty('commissionFillRate')
        )
          object.commissionFillRate =
            options.json && !isFinite(message.commissionFillRate)
              ? String(message.commissionFillRate)
              : message.commissionFillRate;
        if (
          message.buyMarginRate != null &&
          message.hasOwnProperty('buyMarginRate')
        )
          object.buyMarginRate =
            options.json && !isFinite(message.buyMarginRate)
              ? String(message.buyMarginRate)
              : message.buyMarginRate;
        if (
          message.sellMarginRate != null &&
          message.hasOwnProperty('sellMarginRate')
        )
          object.sellMarginRate =
            options.json && !isFinite(message.sellMarginRate)
              ? String(message.sellMarginRate)
              : message.sellMarginRate;
        return object;
      };

      /**
       * Converts this ResponseProductRmsInfo to JSON.
       * @function toJSON
       * @memberof rti.ResponseProductRmsInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseProductRmsInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseProductRmsInfo
       * @function getTypeUrl
       * @memberof rti.ResponseProductRmsInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseProductRmsInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseProductRmsInfo';
      };

      /**
       * PresenceBits enum.
       * @name rti.ResponseProductRmsInfo.PresenceBits
       * @enum {number}
       * @property {number} BUY_LIMIT=1 BUY_LIMIT value
       * @property {number} SELL_LIMIT=2 SELL_LIMIT value
       * @property {number} LOSS_LIMIT=4 LOSS_LIMIT value
       * @property {number} MAX_ORDER_QUANTITY=8 MAX_ORDER_QUANTITY value
       * @property {number} BUY_MARGIN_RATE=16 BUY_MARGIN_RATE value
       * @property {number} SELL_MARGIN_RATE=32 SELL_MARGIN_RATE value
       * @property {number} COMMISSION_FILL_RATE=64 COMMISSION_FILL_RATE value
       */
      ResponseProductRmsInfo.PresenceBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY_LIMIT')] = 1;
        values[(valuesById[2] = 'SELL_LIMIT')] = 2;
        values[(valuesById[4] = 'LOSS_LIMIT')] = 4;
        values[(valuesById[8] = 'MAX_ORDER_QUANTITY')] = 8;
        values[(valuesById[16] = 'BUY_MARGIN_RATE')] = 16;
        values[(valuesById[32] = 'SELL_MARGIN_RATE')] = 32;
        values[(valuesById[64] = 'COMMISSION_FILL_RATE')] = 64;
        return values;
      })();

      return ResponseProductRmsInfo;
    })();

    rti.RequestSubscribeForOrderUpdates = (function () {
      /**
       * Properties of a RequestSubscribeForOrderUpdates.
       * @memberof rti
       * @interface IRequestSubscribeForOrderUpdates
       * @property {number} templateId RequestSubscribeForOrderUpdates templateId
       * @property {Array.<string>|null} [userMsg] RequestSubscribeForOrderUpdates userMsg
       * @property {string|null} [fcmId] RequestSubscribeForOrderUpdates fcmId
       * @property {string|null} [ibId] RequestSubscribeForOrderUpdates ibId
       * @property {string|null} [accountId] RequestSubscribeForOrderUpdates accountId
       */

      /**
       * Constructs a new RequestSubscribeForOrderUpdates.
       * @memberof rti
       * @classdesc Represents a RequestSubscribeForOrderUpdates.
       * @implements IRequestSubscribeForOrderUpdates
       * @constructor
       * @param {rti.IRequestSubscribeForOrderUpdates=} [properties] Properties to set
       */
      function RequestSubscribeForOrderUpdates(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestSubscribeForOrderUpdates templateId.
       * @member {number} templateId
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @instance
       */
      RequestSubscribeForOrderUpdates.prototype.templateId = 0;

      /**
       * RequestSubscribeForOrderUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @instance
       */
      RequestSubscribeForOrderUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * RequestSubscribeForOrderUpdates fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @instance
       */
      RequestSubscribeForOrderUpdates.prototype.fcmId = '';

      /**
       * RequestSubscribeForOrderUpdates ibId.
       * @member {string} ibId
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @instance
       */
      RequestSubscribeForOrderUpdates.prototype.ibId = '';

      /**
       * RequestSubscribeForOrderUpdates accountId.
       * @member {string} accountId
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @instance
       */
      RequestSubscribeForOrderUpdates.prototype.accountId = '';

      /**
       * Creates a new RequestSubscribeForOrderUpdates instance using the specified properties.
       * @function create
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {rti.IRequestSubscribeForOrderUpdates=} [properties] Properties to set
       * @returns {rti.RequestSubscribeForOrderUpdates} RequestSubscribeForOrderUpdates instance
       */
      RequestSubscribeForOrderUpdates.create = function create(properties) {
        return new RequestSubscribeForOrderUpdates(properties);
      };

      /**
       * Encodes the specified RequestSubscribeForOrderUpdates message. Does not implicitly {@link rti.RequestSubscribeForOrderUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {rti.IRequestSubscribeForOrderUpdates} message RequestSubscribeForOrderUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestSubscribeForOrderUpdates.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestSubscribeForOrderUpdates message, length delimited. Does not implicitly {@link rti.RequestSubscribeForOrderUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {rti.IRequestSubscribeForOrderUpdates} message RequestSubscribeForOrderUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestSubscribeForOrderUpdates.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestSubscribeForOrderUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestSubscribeForOrderUpdates} RequestSubscribeForOrderUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestSubscribeForOrderUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestSubscribeForOrderUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestSubscribeForOrderUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestSubscribeForOrderUpdates} RequestSubscribeForOrderUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestSubscribeForOrderUpdates.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestSubscribeForOrderUpdates message.
       * @function verify
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestSubscribeForOrderUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        return null;
      };

      /**
       * Creates a RequestSubscribeForOrderUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestSubscribeForOrderUpdates} RequestSubscribeForOrderUpdates
       */
      RequestSubscribeForOrderUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestSubscribeForOrderUpdates)
          return object;
        var message = new $root.rti.RequestSubscribeForOrderUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestSubscribeForOrderUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        return message;
      };

      /**
       * Creates a plain object from a RequestSubscribeForOrderUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {rti.RequestSubscribeForOrderUpdates} message RequestSubscribeForOrderUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestSubscribeForOrderUpdates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestSubscribeForOrderUpdates to JSON.
       * @function toJSON
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestSubscribeForOrderUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestSubscribeForOrderUpdates
       * @function getTypeUrl
       * @memberof rti.RequestSubscribeForOrderUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestSubscribeForOrderUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestSubscribeForOrderUpdates';
      };

      return RequestSubscribeForOrderUpdates;
    })();

    rti.ResponseSubscribeForOrderUpdates = (function () {
      /**
       * Properties of a ResponseSubscribeForOrderUpdates.
       * @memberof rti
       * @interface IResponseSubscribeForOrderUpdates
       * @property {number} templateId ResponseSubscribeForOrderUpdates templateId
       * @property {Array.<string>|null} [userMsg] ResponseSubscribeForOrderUpdates userMsg
       * @property {Array.<string>|null} [rpCode] ResponseSubscribeForOrderUpdates rpCode
       */

      /**
       * Constructs a new ResponseSubscribeForOrderUpdates.
       * @memberof rti
       * @classdesc Represents a ResponseSubscribeForOrderUpdates.
       * @implements IResponseSubscribeForOrderUpdates
       * @constructor
       * @param {rti.IResponseSubscribeForOrderUpdates=} [properties] Properties to set
       */
      function ResponseSubscribeForOrderUpdates(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseSubscribeForOrderUpdates templateId.
       * @member {number} templateId
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @instance
       */
      ResponseSubscribeForOrderUpdates.prototype.templateId = 0;

      /**
       * ResponseSubscribeForOrderUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @instance
       */
      ResponseSubscribeForOrderUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseSubscribeForOrderUpdates rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @instance
       */
      ResponseSubscribeForOrderUpdates.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseSubscribeForOrderUpdates instance using the specified properties.
       * @function create
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {rti.IResponseSubscribeForOrderUpdates=} [properties] Properties to set
       * @returns {rti.ResponseSubscribeForOrderUpdates} ResponseSubscribeForOrderUpdates instance
       */
      ResponseSubscribeForOrderUpdates.create = function create(properties) {
        return new ResponseSubscribeForOrderUpdates(properties);
      };

      /**
       * Encodes the specified ResponseSubscribeForOrderUpdates message. Does not implicitly {@link rti.ResponseSubscribeForOrderUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {rti.IResponseSubscribeForOrderUpdates} message ResponseSubscribeForOrderUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseSubscribeForOrderUpdates.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseSubscribeForOrderUpdates message, length delimited. Does not implicitly {@link rti.ResponseSubscribeForOrderUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {rti.IResponseSubscribeForOrderUpdates} message ResponseSubscribeForOrderUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseSubscribeForOrderUpdates.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseSubscribeForOrderUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseSubscribeForOrderUpdates} ResponseSubscribeForOrderUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseSubscribeForOrderUpdates.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseSubscribeForOrderUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseSubscribeForOrderUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseSubscribeForOrderUpdates} ResponseSubscribeForOrderUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseSubscribeForOrderUpdates.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseSubscribeForOrderUpdates message.
       * @function verify
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseSubscribeForOrderUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseSubscribeForOrderUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseSubscribeForOrderUpdates} ResponseSubscribeForOrderUpdates
       */
      ResponseSubscribeForOrderUpdates.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseSubscribeForOrderUpdates)
          return object;
        var message = new $root.rti.ResponseSubscribeForOrderUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseSubscribeForOrderUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseSubscribeForOrderUpdates.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseSubscribeForOrderUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {rti.ResponseSubscribeForOrderUpdates} message ResponseSubscribeForOrderUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseSubscribeForOrderUpdates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseSubscribeForOrderUpdates to JSON.
       * @function toJSON
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseSubscribeForOrderUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseSubscribeForOrderUpdates
       * @function getTypeUrl
       * @memberof rti.ResponseSubscribeForOrderUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseSubscribeForOrderUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseSubscribeForOrderUpdates';
      };

      return ResponseSubscribeForOrderUpdates;
    })();

    rti.RequestTradeRoutes = (function () {
      /**
       * Properties of a RequestTradeRoutes.
       * @memberof rti
       * @interface IRequestTradeRoutes
       * @property {number} templateId RequestTradeRoutes templateId
       * @property {Array.<string>|null} [userMsg] RequestTradeRoutes userMsg
       * @property {boolean|null} [subscribeForUpdates] RequestTradeRoutes subscribeForUpdates
       */

      /**
       * Constructs a new RequestTradeRoutes.
       * @memberof rti
       * @classdesc Represents a RequestTradeRoutes.
       * @implements IRequestTradeRoutes
       * @constructor
       * @param {rti.IRequestTradeRoutes=} [properties] Properties to set
       */
      function RequestTradeRoutes(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestTradeRoutes templateId.
       * @member {number} templateId
       * @memberof rti.RequestTradeRoutes
       * @instance
       */
      RequestTradeRoutes.prototype.templateId = 0;

      /**
       * RequestTradeRoutes userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestTradeRoutes
       * @instance
       */
      RequestTradeRoutes.prototype.userMsg = $util.emptyArray;

      /**
       * RequestTradeRoutes subscribeForUpdates.
       * @member {boolean} subscribeForUpdates
       * @memberof rti.RequestTradeRoutes
       * @instance
       */
      RequestTradeRoutes.prototype.subscribeForUpdates = false;

      /**
       * Creates a new RequestTradeRoutes instance using the specified properties.
       * @function create
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {rti.IRequestTradeRoutes=} [properties] Properties to set
       * @returns {rti.RequestTradeRoutes} RequestTradeRoutes instance
       */
      RequestTradeRoutes.create = function create(properties) {
        return new RequestTradeRoutes(properties);
      };

      /**
       * Encodes the specified RequestTradeRoutes message. Does not implicitly {@link rti.RequestTradeRoutes.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {rti.IRequestTradeRoutes} message RequestTradeRoutes message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTradeRoutes.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.subscribeForUpdates != null &&
          Object.hasOwnProperty.call(message, 'subscribeForUpdates')
        )
          writer
            .uint32(/* id 154352, wireType 0 =*/ 1234816)
            .bool(message.subscribeForUpdates);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestTradeRoutes message, length delimited. Does not implicitly {@link rti.RequestTradeRoutes.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {rti.IRequestTradeRoutes} message RequestTradeRoutes message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTradeRoutes.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestTradeRoutes message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestTradeRoutes} RequestTradeRoutes
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTradeRoutes.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestTradeRoutes();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154352:
              message.subscribeForUpdates = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestTradeRoutes message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestTradeRoutes} RequestTradeRoutes
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTradeRoutes.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestTradeRoutes message.
       * @function verify
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestTradeRoutes.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.subscribeForUpdates != null &&
          message.hasOwnProperty('subscribeForUpdates')
        )
          if (typeof message.subscribeForUpdates !== 'boolean')
            return 'subscribeForUpdates: boolean expected';
        return null;
      };

      /**
       * Creates a RequestTradeRoutes message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestTradeRoutes} RequestTradeRoutes
       */
      RequestTradeRoutes.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestTradeRoutes) return object;
        var message = new $root.rti.RequestTradeRoutes();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestTradeRoutes.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.subscribeForUpdates != null)
          message.subscribeForUpdates = Boolean(object.subscribeForUpdates);
        return message;
      };

      /**
       * Creates a plain object from a RequestTradeRoutes message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {rti.RequestTradeRoutes} message RequestTradeRoutes
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestTradeRoutes.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.subscribeForUpdates = false;
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.subscribeForUpdates != null &&
          message.hasOwnProperty('subscribeForUpdates')
        )
          object.subscribeForUpdates = message.subscribeForUpdates;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestTradeRoutes to JSON.
       * @function toJSON
       * @memberof rti.RequestTradeRoutes
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestTradeRoutes.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestTradeRoutes
       * @function getTypeUrl
       * @memberof rti.RequestTradeRoutes
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestTradeRoutes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestTradeRoutes';
      };

      return RequestTradeRoutes;
    })();

    rti.ResponseTradeRoutes = (function () {
      /**
       * Properties of a ResponseTradeRoutes.
       * @memberof rti
       * @interface IResponseTradeRoutes
       * @property {number} templateId ResponseTradeRoutes templateId
       * @property {Array.<string>|null} [userMsg] ResponseTradeRoutes userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseTradeRoutes rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseTradeRoutes rpCode
       * @property {string|null} [fcmId] ResponseTradeRoutes fcmId
       * @property {string|null} [ibId] ResponseTradeRoutes ibId
       * @property {string|null} [exchange] ResponseTradeRoutes exchange
       * @property {string|null} [tradeRoute] ResponseTradeRoutes tradeRoute
       * @property {string|null} [status] ResponseTradeRoutes status
       * @property {boolean|null} [isDefault] ResponseTradeRoutes isDefault
       */

      /**
       * Constructs a new ResponseTradeRoutes.
       * @memberof rti
       * @classdesc Represents a ResponseTradeRoutes.
       * @implements IResponseTradeRoutes
       * @constructor
       * @param {rti.IResponseTradeRoutes=} [properties] Properties to set
       */
      function ResponseTradeRoutes(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseTradeRoutes templateId.
       * @member {number} templateId
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.templateId = 0;

      /**
       * ResponseTradeRoutes userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseTradeRoutes rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseTradeRoutes rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseTradeRoutes fcmId.
       * @member {string} fcmId
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.fcmId = '';

      /**
       * ResponseTradeRoutes ibId.
       * @member {string} ibId
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.ibId = '';

      /**
       * ResponseTradeRoutes exchange.
       * @member {string} exchange
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.exchange = '';

      /**
       * ResponseTradeRoutes tradeRoute.
       * @member {string} tradeRoute
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.tradeRoute = '';

      /**
       * ResponseTradeRoutes status.
       * @member {string} status
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.status = '';

      /**
       * ResponseTradeRoutes isDefault.
       * @member {boolean} isDefault
       * @memberof rti.ResponseTradeRoutes
       * @instance
       */
      ResponseTradeRoutes.prototype.isDefault = false;

      /**
       * Creates a new ResponseTradeRoutes instance using the specified properties.
       * @function create
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {rti.IResponseTradeRoutes=} [properties] Properties to set
       * @returns {rti.ResponseTradeRoutes} ResponseTradeRoutes instance
       */
      ResponseTradeRoutes.create = function create(properties) {
        return new ResponseTradeRoutes(properties);
      };

      /**
       * Encodes the specified ResponseTradeRoutes message. Does not implicitly {@link rti.ResponseTradeRoutes.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {rti.IResponseTradeRoutes} message ResponseTradeRoutes message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTradeRoutes.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.tradeRoute != null &&
          Object.hasOwnProperty.call(message, 'tradeRoute')
        )
          writer
            .uint32(/* id 112016, wireType 2 =*/ 896130)
            .string(message.tradeRoute);
        if (
          message.status != null &&
          Object.hasOwnProperty.call(message, 'status')
        )
          writer
            .uint32(/* id 131407, wireType 2 =*/ 1051258)
            .string(message.status);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.isDefault != null &&
          Object.hasOwnProperty.call(message, 'isDefault')
        )
          writer
            .uint32(/* id 154689, wireType 0 =*/ 1237512)
            .bool(message.isDefault);
        return writer;
      };

      /**
       * Encodes the specified ResponseTradeRoutes message, length delimited. Does not implicitly {@link rti.ResponseTradeRoutes.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {rti.IResponseTradeRoutes} message ResponseTradeRoutes message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTradeRoutes.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseTradeRoutes message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseTradeRoutes} ResponseTradeRoutes
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTradeRoutes.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseTradeRoutes();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 112016:
              message.tradeRoute = reader.string();
              break;
            case 131407:
              message.status = reader.string();
              break;
            case 154689:
              message.isDefault = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseTradeRoutes message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseTradeRoutes} ResponseTradeRoutes
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTradeRoutes.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseTradeRoutes message.
       * @function verify
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseTradeRoutes.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          if (!$util.isString(message.tradeRoute))
            return 'tradeRoute: string expected';
        if (message.status != null && message.hasOwnProperty('status'))
          if (!$util.isString(message.status)) return 'status: string expected';
        if (message.isDefault != null && message.hasOwnProperty('isDefault'))
          if (typeof message.isDefault !== 'boolean')
            return 'isDefault: boolean expected';
        return null;
      };

      /**
       * Creates a ResponseTradeRoutes message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseTradeRoutes} ResponseTradeRoutes
       */
      ResponseTradeRoutes.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseTradeRoutes) return object;
        var message = new $root.rti.ResponseTradeRoutes();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseTradeRoutes.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseTradeRoutes.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseTradeRoutes.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.tradeRoute != null)
          message.tradeRoute = String(object.tradeRoute);
        if (object.status != null) message.status = String(object.status);
        if (object.isDefault != null)
          message.isDefault = Boolean(object.isDefault);
        return message;
      };

      /**
       * Creates a plain object from a ResponseTradeRoutes message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {rti.ResponseTradeRoutes} message ResponseTradeRoutes
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseTradeRoutes.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.exchange = '';
          object.tradeRoute = '';
          object.status = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
          object.isDefault = false;
        }
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          object.tradeRoute = message.tradeRoute;
        if (message.status != null && message.hasOwnProperty('status'))
          object.status = message.status;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.isDefault != null && message.hasOwnProperty('isDefault'))
          object.isDefault = message.isDefault;
        return object;
      };

      /**
       * Converts this ResponseTradeRoutes to JSON.
       * @function toJSON
       * @memberof rti.ResponseTradeRoutes
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseTradeRoutes.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseTradeRoutes
       * @function getTypeUrl
       * @memberof rti.ResponseTradeRoutes
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseTradeRoutes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseTradeRoutes';
      };

      return ResponseTradeRoutes;
    })();

    rti.RequestNewOrder = (function () {
      /**
       * Properties of a RequestNewOrder.
       * @memberof rti
       * @interface IRequestNewOrder
       * @property {number} templateId RequestNewOrder templateId
       * @property {Array.<string>|null} [userMsg] RequestNewOrder userMsg
       * @property {string|null} [userTag] RequestNewOrder userTag
       * @property {string|null} [windowName] RequestNewOrder windowName
       * @property {string|null} [fcmId] RequestNewOrder fcmId
       * @property {string|null} [ibId] RequestNewOrder ibId
       * @property {string|null} [accountId] RequestNewOrder accountId
       * @property {string|null} [symbol] RequestNewOrder symbol
       * @property {string|null} [exchange] RequestNewOrder exchange
       * @property {number|null} [quantity] RequestNewOrder quantity
       * @property {number|null} [price] RequestNewOrder price
       * @property {number|null} [triggerPrice] RequestNewOrder triggerPrice
       * @property {rti.RequestNewOrder.TransactionType|null} [transactionType] RequestNewOrder transactionType
       * @property {rti.RequestNewOrder.Duration|null} [duration] RequestNewOrder duration
       * @property {rti.RequestNewOrder.PriceType|null} [priceType] RequestNewOrder priceType
       * @property {string|null} [tradeRoute] RequestNewOrder tradeRoute
       * @property {rti.RequestNewOrder.OrderPlacement|null} [manualOrAuto] RequestNewOrder manualOrAuto
       * @property {number|null} [releaseAtSsboe] RequestNewOrder releaseAtSsboe
       * @property {number|null} [releaseAtUsecs] RequestNewOrder releaseAtUsecs
       * @property {number|null} [cancelAtSsboe] RequestNewOrder cancelAtSsboe
       * @property {number|null} [cancelAtUsecs] RequestNewOrder cancelAtUsecs
       * @property {number|null} [cancelAfterSecs] RequestNewOrder cancelAfterSecs
       * @property {string|null} [ifTouchedSymbol] RequestNewOrder ifTouchedSymbol
       * @property {string|null} [ifTouchedExchange] RequestNewOrder ifTouchedExchange
       * @property {rti.RequestNewOrder.Condition|null} [ifTouchedCondition] RequestNewOrder ifTouchedCondition
       * @property {rti.RequestNewOrder.PriceField|null} [ifTouchedPriceField] RequestNewOrder ifTouchedPriceField
       * @property {number|null} [ifTouchedPrice] RequestNewOrder ifTouchedPrice
       */

      /**
       * Constructs a new RequestNewOrder.
       * @memberof rti
       * @classdesc Represents a RequestNewOrder.
       * @implements IRequestNewOrder
       * @constructor
       * @param {rti.IRequestNewOrder=} [properties] Properties to set
       */
      function RequestNewOrder(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestNewOrder templateId.
       * @member {number} templateId
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.templateId = 0;

      /**
       * RequestNewOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.userMsg = $util.emptyArray;

      /**
       * RequestNewOrder userTag.
       * @member {string} userTag
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.userTag = '';

      /**
       * RequestNewOrder windowName.
       * @member {string} windowName
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.windowName = '';

      /**
       * RequestNewOrder fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.fcmId = '';

      /**
       * RequestNewOrder ibId.
       * @member {string} ibId
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.ibId = '';

      /**
       * RequestNewOrder accountId.
       * @member {string} accountId
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.accountId = '';

      /**
       * RequestNewOrder symbol.
       * @member {string} symbol
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.symbol = '';

      /**
       * RequestNewOrder exchange.
       * @member {string} exchange
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.exchange = '';

      /**
       * RequestNewOrder quantity.
       * @member {number} quantity
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.quantity = 0;

      /**
       * RequestNewOrder price.
       * @member {number} price
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.price = 0;

      /**
       * RequestNewOrder triggerPrice.
       * @member {number} triggerPrice
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.triggerPrice = 0;

      /**
       * RequestNewOrder transactionType.
       * @member {rti.RequestNewOrder.TransactionType} transactionType
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.transactionType = 1;

      /**
       * RequestNewOrder duration.
       * @member {rti.RequestNewOrder.Duration} duration
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.duration = 1;

      /**
       * RequestNewOrder priceType.
       * @member {rti.RequestNewOrder.PriceType} priceType
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.priceType = 1;

      /**
       * RequestNewOrder tradeRoute.
       * @member {string} tradeRoute
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.tradeRoute = '';

      /**
       * RequestNewOrder manualOrAuto.
       * @member {rti.RequestNewOrder.OrderPlacement} manualOrAuto
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.manualOrAuto = 1;

      /**
       * RequestNewOrder releaseAtSsboe.
       * @member {number} releaseAtSsboe
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.releaseAtSsboe = 0;

      /**
       * RequestNewOrder releaseAtUsecs.
       * @member {number} releaseAtUsecs
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.releaseAtUsecs = 0;

      /**
       * RequestNewOrder cancelAtSsboe.
       * @member {number} cancelAtSsboe
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.cancelAtSsboe = 0;

      /**
       * RequestNewOrder cancelAtUsecs.
       * @member {number} cancelAtUsecs
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.cancelAtUsecs = 0;

      /**
       * RequestNewOrder cancelAfterSecs.
       * @member {number} cancelAfterSecs
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.cancelAfterSecs = 0;

      /**
       * RequestNewOrder ifTouchedSymbol.
       * @member {string} ifTouchedSymbol
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.ifTouchedSymbol = '';

      /**
       * RequestNewOrder ifTouchedExchange.
       * @member {string} ifTouchedExchange
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.ifTouchedExchange = '';

      /**
       * RequestNewOrder ifTouchedCondition.
       * @member {rti.RequestNewOrder.Condition} ifTouchedCondition
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.ifTouchedCondition = 1;

      /**
       * RequestNewOrder ifTouchedPriceField.
       * @member {rti.RequestNewOrder.PriceField} ifTouchedPriceField
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.ifTouchedPriceField = 1;

      /**
       * RequestNewOrder ifTouchedPrice.
       * @member {number} ifTouchedPrice
       * @memberof rti.RequestNewOrder
       * @instance
       */
      RequestNewOrder.prototype.ifTouchedPrice = 0;

      /**
       * Creates a new RequestNewOrder instance using the specified properties.
       * @function create
       * @memberof rti.RequestNewOrder
       * @static
       * @param {rti.IRequestNewOrder=} [properties] Properties to set
       * @returns {rti.RequestNewOrder} RequestNewOrder instance
       */
      RequestNewOrder.create = function create(properties) {
        return new RequestNewOrder(properties);
      };

      /**
       * Encodes the specified RequestNewOrder message. Does not implicitly {@link rti.RequestNewOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestNewOrder
       * @static
       * @param {rti.IRequestNewOrder} message RequestNewOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestNewOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.price != null &&
          Object.hasOwnProperty.call(message, 'price')
        )
          writer
            .uint32(/* id 110306, wireType 1 =*/ 882449)
            .double(message.price);
        if (
          message.transactionType != null &&
          Object.hasOwnProperty.call(message, 'transactionType')
        )
          writer
            .uint32(/* id 112003, wireType 0 =*/ 896024)
            .int32(message.transactionType);
        if (
          message.quantity != null &&
          Object.hasOwnProperty.call(message, 'quantity')
        )
          writer
            .uint32(/* id 112004, wireType 0 =*/ 896032)
            .int32(message.quantity);
        if (
          message.duration != null &&
          Object.hasOwnProperty.call(message, 'duration')
        )
          writer
            .uint32(/* id 112005, wireType 0 =*/ 896040)
            .int32(message.duration);
        if (
          message.priceType != null &&
          Object.hasOwnProperty.call(message, 'priceType')
        )
          writer
            .uint32(/* id 112008, wireType 0 =*/ 896064)
            .int32(message.priceType);
        if (
          message.tradeRoute != null &&
          Object.hasOwnProperty.call(message, 'tradeRoute')
        )
          writer
            .uint32(/* id 112016, wireType 2 =*/ 896130)
            .string(message.tradeRoute);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.triggerPrice != null &&
          Object.hasOwnProperty.call(message, 'triggerPrice')
        )
          writer
            .uint32(/* id 149247, wireType 1 =*/ 1193977)
            .double(message.triggerPrice);
        if (
          message.ifTouchedPrice != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedPrice')
        )
          writer
            .uint32(/* id 153632, wireType 1 =*/ 1229057)
            .double(message.ifTouchedPrice);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.userTag != null &&
          Object.hasOwnProperty.call(message, 'userTag')
        )
          writer
            .uint32(/* id 154119, wireType 2 =*/ 1232954)
            .string(message.userTag);
        if (
          message.ifTouchedSymbol != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedSymbol')
        )
          writer
            .uint32(/* id 154451, wireType 2 =*/ 1235610)
            .string(message.ifTouchedSymbol);
        if (
          message.ifTouchedExchange != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedExchange')
        )
          writer
            .uint32(/* id 154452, wireType 2 =*/ 1235618)
            .string(message.ifTouchedExchange);
        if (
          message.ifTouchedCondition != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedCondition')
        )
          writer
            .uint32(/* id 154453, wireType 0 =*/ 1235624)
            .int32(message.ifTouchedCondition);
        if (
          message.ifTouchedPriceField != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedPriceField')
        )
          writer
            .uint32(/* id 154454, wireType 0 =*/ 1235632)
            .int32(message.ifTouchedPriceField);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.releaseAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'releaseAtSsboe')
        )
          writer
            .uint32(/* id 154487, wireType 0 =*/ 1235896)
            .int32(message.releaseAtSsboe);
        if (
          message.cancelAfterSecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAfterSecs')
        )
          writer
            .uint32(/* id 154488, wireType 0 =*/ 1235904)
            .int32(message.cancelAfterSecs);
        if (
          message.releaseAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'releaseAtUsecs')
        )
          writer
            .uint32(/* id 154549, wireType 0 =*/ 1236392)
            .int32(message.releaseAtUsecs);
        if (
          message.windowName != null &&
          Object.hasOwnProperty.call(message, 'windowName')
        )
          writer
            .uint32(/* id 154629, wireType 2 =*/ 1237034)
            .string(message.windowName);
        if (
          message.manualOrAuto != null &&
          Object.hasOwnProperty.call(message, 'manualOrAuto')
        )
          writer
            .uint32(/* id 154710, wireType 0 =*/ 1237680)
            .int32(message.manualOrAuto);
        if (
          message.cancelAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'cancelAtSsboe')
        )
          writer
            .uint32(/* id 157085, wireType 0 =*/ 1256680)
            .int32(message.cancelAtSsboe);
        if (
          message.cancelAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAtUsecs')
        )
          writer
            .uint32(/* id 157086, wireType 0 =*/ 1256688)
            .int32(message.cancelAtUsecs);
        return writer;
      };

      /**
       * Encodes the specified RequestNewOrder message, length delimited. Does not implicitly {@link rti.RequestNewOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestNewOrder
       * @static
       * @param {rti.IRequestNewOrder} message RequestNewOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestNewOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestNewOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestNewOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestNewOrder} RequestNewOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestNewOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestNewOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154119:
              message.userTag = reader.string();
              break;
            case 154629:
              message.windowName = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 112004:
              message.quantity = reader.int32();
              break;
            case 110306:
              message.price = reader.double();
              break;
            case 149247:
              message.triggerPrice = reader.double();
              break;
            case 112003:
              message.transactionType = reader.int32();
              break;
            case 112005:
              message.duration = reader.int32();
              break;
            case 112008:
              message.priceType = reader.int32();
              break;
            case 112016:
              message.tradeRoute = reader.string();
              break;
            case 154710:
              message.manualOrAuto = reader.int32();
              break;
            case 154487:
              message.releaseAtSsboe = reader.int32();
              break;
            case 154549:
              message.releaseAtUsecs = reader.int32();
              break;
            case 157085:
              message.cancelAtSsboe = reader.int32();
              break;
            case 157086:
              message.cancelAtUsecs = reader.int32();
              break;
            case 154488:
              message.cancelAfterSecs = reader.int32();
              break;
            case 154451:
              message.ifTouchedSymbol = reader.string();
              break;
            case 154452:
              message.ifTouchedExchange = reader.string();
              break;
            case 154453:
              message.ifTouchedCondition = reader.int32();
              break;
            case 154454:
              message.ifTouchedPriceField = reader.int32();
              break;
            case 153632:
              message.ifTouchedPrice = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestNewOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestNewOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestNewOrder} RequestNewOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestNewOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestNewOrder message.
       * @function verify
       * @memberof rti.RequestNewOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestNewOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          if (!$util.isString(message.userTag))
            return 'userTag: string expected';
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          if (!$util.isString(message.windowName))
            return 'windowName: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          if (!$util.isInteger(message.quantity))
            return 'quantity: integer expected';
        if (message.price != null && message.hasOwnProperty('price'))
          if (typeof message.price !== 'number')
            return 'price: number expected';
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          if (typeof message.triggerPrice !== 'number')
            return 'triggerPrice: number expected';
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        )
          switch (message.transactionType) {
            default:
              return 'transactionType: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.duration != null && message.hasOwnProperty('duration'))
          switch (message.duration) {
            default:
              return 'duration: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          switch (message.priceType) {
            default:
              return 'priceType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          if (!$util.isString(message.tradeRoute))
            return 'tradeRoute: string expected';
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          switch (message.manualOrAuto) {
            default:
              return 'manualOrAuto: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.releaseAtSsboe != null &&
          message.hasOwnProperty('releaseAtSsboe')
        )
          if (!$util.isInteger(message.releaseAtSsboe))
            return 'releaseAtSsboe: integer expected';
        if (
          message.releaseAtUsecs != null &&
          message.hasOwnProperty('releaseAtUsecs')
        )
          if (!$util.isInteger(message.releaseAtUsecs))
            return 'releaseAtUsecs: integer expected';
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          if (!$util.isInteger(message.cancelAtSsboe))
            return 'cancelAtSsboe: integer expected';
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          if (!$util.isInteger(message.cancelAtUsecs))
            return 'cancelAtUsecs: integer expected';
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          if (!$util.isInteger(message.cancelAfterSecs))
            return 'cancelAfterSecs: integer expected';
        if (
          message.ifTouchedSymbol != null &&
          message.hasOwnProperty('ifTouchedSymbol')
        )
          if (!$util.isString(message.ifTouchedSymbol))
            return 'ifTouchedSymbol: string expected';
        if (
          message.ifTouchedExchange != null &&
          message.hasOwnProperty('ifTouchedExchange')
        )
          if (!$util.isString(message.ifTouchedExchange))
            return 'ifTouchedExchange: string expected';
        if (
          message.ifTouchedCondition != null &&
          message.hasOwnProperty('ifTouchedCondition')
        )
          switch (message.ifTouchedCondition) {
            default:
              return 'ifTouchedCondition: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (
          message.ifTouchedPriceField != null &&
          message.hasOwnProperty('ifTouchedPriceField')
        )
          switch (message.ifTouchedPriceField) {
            default:
              return 'ifTouchedPriceField: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.ifTouchedPrice != null &&
          message.hasOwnProperty('ifTouchedPrice')
        )
          if (typeof message.ifTouchedPrice !== 'number')
            return 'ifTouchedPrice: number expected';
        return null;
      };

      /**
       * Creates a RequestNewOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestNewOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestNewOrder} RequestNewOrder
       */
      RequestNewOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestNewOrder) return object;
        var message = new $root.rti.RequestNewOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestNewOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.userTag != null) message.userTag = String(object.userTag);
        if (object.windowName != null)
          message.windowName = String(object.windowName);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.quantity != null) message.quantity = object.quantity | 0;
        if (object.price != null) message.price = Number(object.price);
        if (object.triggerPrice != null)
          message.triggerPrice = Number(object.triggerPrice);
        switch (object.transactionType) {
          case 'BUY':
          case 1:
            message.transactionType = 1;
            break;
          case 'SELL':
          case 2:
            message.transactionType = 2;
            break;
        }
        switch (object.duration) {
          case 'DAY':
          case 1:
            message.duration = 1;
            break;
          case 'GTC':
          case 2:
            message.duration = 2;
            break;
          case 'IOC':
          case 3:
            message.duration = 3;
            break;
          case 'FOK':
          case 4:
            message.duration = 4;
            break;
        }
        switch (object.priceType) {
          case 'LIMIT':
          case 1:
            message.priceType = 1;
            break;
          case 'MARKET':
          case 2:
            message.priceType = 2;
            break;
          case 'STOP_LIMIT':
          case 3:
            message.priceType = 3;
            break;
          case 'STOP_MARKET':
          case 4:
            message.priceType = 4;
            break;
          case 'MARKET_IF_TOUCHED':
          case 5:
            message.priceType = 5;
            break;
          case 'LIMIT_IF_TOUCHED':
          case 6:
            message.priceType = 6;
            break;
        }
        if (object.tradeRoute != null)
          message.tradeRoute = String(object.tradeRoute);
        switch (object.manualOrAuto) {
          case 'MANUAL':
          case 1:
            message.manualOrAuto = 1;
            break;
          case 'AUTO':
          case 2:
            message.manualOrAuto = 2;
            break;
        }
        if (object.releaseAtSsboe != null)
          message.releaseAtSsboe = object.releaseAtSsboe | 0;
        if (object.releaseAtUsecs != null)
          message.releaseAtUsecs = object.releaseAtUsecs | 0;
        if (object.cancelAtSsboe != null)
          message.cancelAtSsboe = object.cancelAtSsboe | 0;
        if (object.cancelAtUsecs != null)
          message.cancelAtUsecs = object.cancelAtUsecs | 0;
        if (object.cancelAfterSecs != null)
          message.cancelAfterSecs = object.cancelAfterSecs | 0;
        if (object.ifTouchedSymbol != null)
          message.ifTouchedSymbol = String(object.ifTouchedSymbol);
        if (object.ifTouchedExchange != null)
          message.ifTouchedExchange = String(object.ifTouchedExchange);
        switch (object.ifTouchedCondition) {
          case 'EQUAL_TO':
          case 1:
            message.ifTouchedCondition = 1;
            break;
          case 'NOT_EQUAL_TO':
          case 2:
            message.ifTouchedCondition = 2;
            break;
          case 'GREATER_THAN':
          case 3:
            message.ifTouchedCondition = 3;
            break;
          case 'GREATER_THAN_EQUAL_TO':
          case 4:
            message.ifTouchedCondition = 4;
            break;
          case 'LESSER_THAN':
          case 5:
            message.ifTouchedCondition = 5;
            break;
          case 'LESSER_THAN_EQUAL_TO':
          case 6:
            message.ifTouchedCondition = 6;
            break;
        }
        switch (object.ifTouchedPriceField) {
          case 'BID_PRICE':
          case 1:
            message.ifTouchedPriceField = 1;
            break;
          case 'OFFER_PRICE':
          case 2:
            message.ifTouchedPriceField = 2;
            break;
          case 'TRADE_PRICE':
          case 3:
            message.ifTouchedPriceField = 3;
            break;
          case 'LEAN_PRICE':
          case 4:
            message.ifTouchedPriceField = 4;
            break;
        }
        if (object.ifTouchedPrice != null)
          message.ifTouchedPrice = Number(object.ifTouchedPrice);
        return message;
      };

      /**
       * Creates a plain object from a RequestNewOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestNewOrder
       * @static
       * @param {rti.RequestNewOrder} message RequestNewOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestNewOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.price = 0;
          object.transactionType = options.enums === String ? 'BUY' : 1;
          object.quantity = 0;
          object.duration = options.enums === String ? 'DAY' : 1;
          object.priceType = options.enums === String ? 'LIMIT' : 1;
          object.tradeRoute = '';
          object.triggerPrice = 0;
          object.ifTouchedPrice = 0;
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.userTag = '';
          object.ifTouchedSymbol = '';
          object.ifTouchedExchange = '';
          object.ifTouchedCondition = options.enums === String ? 'EQUAL_TO' : 1;
          object.ifTouchedPriceField =
            options.enums === String ? 'BID_PRICE' : 1;
          object.templateId = 0;
          object.releaseAtSsboe = 0;
          object.cancelAfterSecs = 0;
          object.releaseAtUsecs = 0;
          object.windowName = '';
          object.manualOrAuto = options.enums === String ? 'MANUAL' : 1;
          object.cancelAtSsboe = 0;
          object.cancelAtUsecs = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.price != null && message.hasOwnProperty('price'))
          object.price =
            options.json && !isFinite(message.price)
              ? String(message.price)
              : message.price;
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        )
          object.transactionType =
            options.enums === String
              ? $root.rti.RequestNewOrder.TransactionType[
                  message.transactionType
                ]
              : message.transactionType;
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          object.quantity = message.quantity;
        if (message.duration != null && message.hasOwnProperty('duration'))
          object.duration =
            options.enums === String
              ? $root.rti.RequestNewOrder.Duration[message.duration]
              : message.duration;
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          object.priceType =
            options.enums === String
              ? $root.rti.RequestNewOrder.PriceType[message.priceType]
              : message.priceType;
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          object.tradeRoute = message.tradeRoute;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          object.triggerPrice =
            options.json && !isFinite(message.triggerPrice)
              ? String(message.triggerPrice)
              : message.triggerPrice;
        if (
          message.ifTouchedPrice != null &&
          message.hasOwnProperty('ifTouchedPrice')
        )
          object.ifTouchedPrice =
            options.json && !isFinite(message.ifTouchedPrice)
              ? String(message.ifTouchedPrice)
              : message.ifTouchedPrice;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          object.userTag = message.userTag;
        if (
          message.ifTouchedSymbol != null &&
          message.hasOwnProperty('ifTouchedSymbol')
        )
          object.ifTouchedSymbol = message.ifTouchedSymbol;
        if (
          message.ifTouchedExchange != null &&
          message.hasOwnProperty('ifTouchedExchange')
        )
          object.ifTouchedExchange = message.ifTouchedExchange;
        if (
          message.ifTouchedCondition != null &&
          message.hasOwnProperty('ifTouchedCondition')
        )
          object.ifTouchedCondition =
            options.enums === String
              ? $root.rti.RequestNewOrder.Condition[message.ifTouchedCondition]
              : message.ifTouchedCondition;
        if (
          message.ifTouchedPriceField != null &&
          message.hasOwnProperty('ifTouchedPriceField')
        )
          object.ifTouchedPriceField =
            options.enums === String
              ? $root.rti.RequestNewOrder.PriceField[
                  message.ifTouchedPriceField
                ]
              : message.ifTouchedPriceField;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.releaseAtSsboe != null &&
          message.hasOwnProperty('releaseAtSsboe')
        )
          object.releaseAtSsboe = message.releaseAtSsboe;
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          object.cancelAfterSecs = message.cancelAfterSecs;
        if (
          message.releaseAtUsecs != null &&
          message.hasOwnProperty('releaseAtUsecs')
        )
          object.releaseAtUsecs = message.releaseAtUsecs;
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          object.windowName = message.windowName;
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          object.manualOrAuto =
            options.enums === String
              ? $root.rti.RequestNewOrder.OrderPlacement[message.manualOrAuto]
              : message.manualOrAuto;
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          object.cancelAtSsboe = message.cancelAtSsboe;
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          object.cancelAtUsecs = message.cancelAtUsecs;
        return object;
      };

      /**
       * Converts this RequestNewOrder to JSON.
       * @function toJSON
       * @memberof rti.RequestNewOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestNewOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestNewOrder
       * @function getTypeUrl
       * @memberof rti.RequestNewOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestNewOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestNewOrder';
      };

      /**
       * TransactionType enum.
       * @name rti.RequestNewOrder.TransactionType
       * @enum {number}
       * @property {number} BUY=1 BUY value
       * @property {number} SELL=2 SELL value
       */
      RequestNewOrder.TransactionType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY')] = 1;
        values[(valuesById[2] = 'SELL')] = 2;
        return values;
      })();

      /**
       * OrderPlacement enum.
       * @name rti.RequestNewOrder.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      RequestNewOrder.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      /**
       * Duration enum.
       * @name rti.RequestNewOrder.Duration
       * @enum {number}
       * @property {number} DAY=1 DAY value
       * @property {number} GTC=2 GTC value
       * @property {number} IOC=3 IOC value
       * @property {number} FOK=4 FOK value
       */
      RequestNewOrder.Duration = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'DAY')] = 1;
        values[(valuesById[2] = 'GTC')] = 2;
        values[(valuesById[3] = 'IOC')] = 3;
        values[(valuesById[4] = 'FOK')] = 4;
        return values;
      })();

      /**
       * PriceType enum.
       * @name rti.RequestNewOrder.PriceType
       * @enum {number}
       * @property {number} LIMIT=1 LIMIT value
       * @property {number} MARKET=2 MARKET value
       * @property {number} STOP_LIMIT=3 STOP_LIMIT value
       * @property {number} STOP_MARKET=4 STOP_MARKET value
       * @property {number} MARKET_IF_TOUCHED=5 MARKET_IF_TOUCHED value
       * @property {number} LIMIT_IF_TOUCHED=6 LIMIT_IF_TOUCHED value
       */
      RequestNewOrder.PriceType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LIMIT')] = 1;
        values[(valuesById[2] = 'MARKET')] = 2;
        values[(valuesById[3] = 'STOP_LIMIT')] = 3;
        values[(valuesById[4] = 'STOP_MARKET')] = 4;
        values[(valuesById[5] = 'MARKET_IF_TOUCHED')] = 5;
        values[(valuesById[6] = 'LIMIT_IF_TOUCHED')] = 6;
        return values;
      })();

      /**
       * PriceField enum.
       * @name rti.RequestNewOrder.PriceField
       * @enum {number}
       * @property {number} BID_PRICE=1 BID_PRICE value
       * @property {number} OFFER_PRICE=2 OFFER_PRICE value
       * @property {number} TRADE_PRICE=3 TRADE_PRICE value
       * @property {number} LEAN_PRICE=4 LEAN_PRICE value
       */
      RequestNewOrder.PriceField = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BID_PRICE')] = 1;
        values[(valuesById[2] = 'OFFER_PRICE')] = 2;
        values[(valuesById[3] = 'TRADE_PRICE')] = 3;
        values[(valuesById[4] = 'LEAN_PRICE')] = 4;
        return values;
      })();

      /**
       * Condition enum.
       * @name rti.RequestNewOrder.Condition
       * @enum {number}
       * @property {number} EQUAL_TO=1 EQUAL_TO value
       * @property {number} NOT_EQUAL_TO=2 NOT_EQUAL_TO value
       * @property {number} GREATER_THAN=3 GREATER_THAN value
       * @property {number} GREATER_THAN_EQUAL_TO=4 GREATER_THAN_EQUAL_TO value
       * @property {number} LESSER_THAN=5 LESSER_THAN value
       * @property {number} LESSER_THAN_EQUAL_TO=6 LESSER_THAN_EQUAL_TO value
       */
      RequestNewOrder.Condition = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'EQUAL_TO')] = 1;
        values[(valuesById[2] = 'NOT_EQUAL_TO')] = 2;
        values[(valuesById[3] = 'GREATER_THAN')] = 3;
        values[(valuesById[4] = 'GREATER_THAN_EQUAL_TO')] = 4;
        values[(valuesById[5] = 'LESSER_THAN')] = 5;
        values[(valuesById[6] = 'LESSER_THAN_EQUAL_TO')] = 6;
        return values;
      })();

      return RequestNewOrder;
    })();

    rti.ResponseNewOrder = (function () {
      /**
       * Properties of a ResponseNewOrder.
       * @memberof rti
       * @interface IResponseNewOrder
       * @property {number} templateId ResponseNewOrder templateId
       * @property {Array.<string>|null} [userMsg] ResponseNewOrder userMsg
       * @property {string|null} [userTag] ResponseNewOrder userTag
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseNewOrder rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseNewOrder rpCode
       * @property {string|null} [basketId] ResponseNewOrder basketId
       * @property {number|null} [ssboe] ResponseNewOrder ssboe
       * @property {number|null} [usecs] ResponseNewOrder usecs
       */

      /**
       * Constructs a new ResponseNewOrder.
       * @memberof rti
       * @classdesc Represents a ResponseNewOrder.
       * @implements IResponseNewOrder
       * @constructor
       * @param {rti.IResponseNewOrder=} [properties] Properties to set
       */
      function ResponseNewOrder(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseNewOrder templateId.
       * @member {number} templateId
       * @memberof rti.ResponseNewOrder
       * @instance
       */
      ResponseNewOrder.prototype.templateId = 0;

      /**
       * ResponseNewOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseNewOrder
       * @instance
       */
      ResponseNewOrder.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseNewOrder userTag.
       * @member {string} userTag
       * @memberof rti.ResponseNewOrder
       * @instance
       */
      ResponseNewOrder.prototype.userTag = '';

      /**
       * ResponseNewOrder rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseNewOrder
       * @instance
       */
      ResponseNewOrder.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseNewOrder rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseNewOrder
       * @instance
       */
      ResponseNewOrder.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseNewOrder basketId.
       * @member {string} basketId
       * @memberof rti.ResponseNewOrder
       * @instance
       */
      ResponseNewOrder.prototype.basketId = '';

      /**
       * ResponseNewOrder ssboe.
       * @member {number} ssboe
       * @memberof rti.ResponseNewOrder
       * @instance
       */
      ResponseNewOrder.prototype.ssboe = 0;

      /**
       * ResponseNewOrder usecs.
       * @member {number} usecs
       * @memberof rti.ResponseNewOrder
       * @instance
       */
      ResponseNewOrder.prototype.usecs = 0;

      /**
       * Creates a new ResponseNewOrder instance using the specified properties.
       * @function create
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {rti.IResponseNewOrder=} [properties] Properties to set
       * @returns {rti.ResponseNewOrder} ResponseNewOrder instance
       */
      ResponseNewOrder.create = function create(properties) {
        return new ResponseNewOrder(properties);
      };

      /**
       * Encodes the specified ResponseNewOrder message. Does not implicitly {@link rti.ResponseNewOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {rti.IResponseNewOrder} message ResponseNewOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseNewOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.userTag != null &&
          Object.hasOwnProperty.call(message, 'userTag')
        )
          writer
            .uint32(/* id 154119, wireType 2 =*/ 1232954)
            .string(message.userTag);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseNewOrder message, length delimited. Does not implicitly {@link rti.ResponseNewOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {rti.IResponseNewOrder} message ResponseNewOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseNewOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseNewOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseNewOrder} ResponseNewOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseNewOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseNewOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154119:
              message.userTag = reader.string();
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseNewOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseNewOrder} ResponseNewOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseNewOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseNewOrder message.
       * @function verify
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseNewOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          if (!$util.isString(message.userTag))
            return 'userTag: string expected';
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a ResponseNewOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseNewOrder} ResponseNewOrder
       */
      ResponseNewOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseNewOrder) return object;
        var message = new $root.rti.ResponseNewOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseNewOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.userTag != null) message.userTag = String(object.userTag);
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseNewOrder.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseNewOrder.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a ResponseNewOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {rti.ResponseNewOrder} message ResponseNewOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseNewOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.basketId = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.userTag = '';
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          object.userTag = message.userTag;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseNewOrder to JSON.
       * @function toJSON
       * @memberof rti.ResponseNewOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseNewOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseNewOrder
       * @function getTypeUrl
       * @memberof rti.ResponseNewOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseNewOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseNewOrder';
      };

      return ResponseNewOrder;
    })();

    rti.RequestModifyOrder = (function () {
      /**
       * Properties of a RequestModifyOrder.
       * @memberof rti
       * @interface IRequestModifyOrder
       * @property {number} templateId RequestModifyOrder templateId
       * @property {Array.<string>|null} [userMsg] RequestModifyOrder userMsg
       * @property {string|null} [windowName] RequestModifyOrder windowName
       * @property {string|null} [fcmId] RequestModifyOrder fcmId
       * @property {string|null} [ibId] RequestModifyOrder ibId
       * @property {string|null} [accountId] RequestModifyOrder accountId
       * @property {string|null} [basketId] RequestModifyOrder basketId
       * @property {string|null} [symbol] RequestModifyOrder symbol
       * @property {string|null} [exchange] RequestModifyOrder exchange
       * @property {number|null} [quantity] RequestModifyOrder quantity
       * @property {number|null} [price] RequestModifyOrder price
       * @property {number|null} [triggerPrice] RequestModifyOrder triggerPrice
       * @property {rti.RequestModifyOrder.PriceType|null} [priceType] RequestModifyOrder priceType
       * @property {rti.RequestModifyOrder.OrderPlacement|null} [manualOrAuto] RequestModifyOrder manualOrAuto
       * @property {string|null} [ifTouchedSymbol] RequestModifyOrder ifTouchedSymbol
       * @property {string|null} [ifTouchedExchange] RequestModifyOrder ifTouchedExchange
       * @property {rti.RequestModifyOrder.Condition|null} [ifTouchedCondition] RequestModifyOrder ifTouchedCondition
       * @property {rti.RequestModifyOrder.PriceField|null} [ifTouchedPriceField] RequestModifyOrder ifTouchedPriceField
       * @property {number|null} [ifTouchedPrice] RequestModifyOrder ifTouchedPrice
       */

      /**
       * Constructs a new RequestModifyOrder.
       * @memberof rti
       * @classdesc Represents a RequestModifyOrder.
       * @implements IRequestModifyOrder
       * @constructor
       * @param {rti.IRequestModifyOrder=} [properties] Properties to set
       */
      function RequestModifyOrder(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestModifyOrder templateId.
       * @member {number} templateId
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.templateId = 0;

      /**
       * RequestModifyOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.userMsg = $util.emptyArray;

      /**
       * RequestModifyOrder windowName.
       * @member {string} windowName
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.windowName = '';

      /**
       * RequestModifyOrder fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.fcmId = '';

      /**
       * RequestModifyOrder ibId.
       * @member {string} ibId
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.ibId = '';

      /**
       * RequestModifyOrder accountId.
       * @member {string} accountId
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.accountId = '';

      /**
       * RequestModifyOrder basketId.
       * @member {string} basketId
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.basketId = '';

      /**
       * RequestModifyOrder symbol.
       * @member {string} symbol
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.symbol = '';

      /**
       * RequestModifyOrder exchange.
       * @member {string} exchange
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.exchange = '';

      /**
       * RequestModifyOrder quantity.
       * @member {number} quantity
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.quantity = 0;

      /**
       * RequestModifyOrder price.
       * @member {number} price
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.price = 0;

      /**
       * RequestModifyOrder triggerPrice.
       * @member {number} triggerPrice
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.triggerPrice = 0;

      /**
       * RequestModifyOrder priceType.
       * @member {rti.RequestModifyOrder.PriceType} priceType
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.priceType = 1;

      /**
       * RequestModifyOrder manualOrAuto.
       * @member {rti.RequestModifyOrder.OrderPlacement} manualOrAuto
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.manualOrAuto = 1;

      /**
       * RequestModifyOrder ifTouchedSymbol.
       * @member {string} ifTouchedSymbol
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.ifTouchedSymbol = '';

      /**
       * RequestModifyOrder ifTouchedExchange.
       * @member {string} ifTouchedExchange
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.ifTouchedExchange = '';

      /**
       * RequestModifyOrder ifTouchedCondition.
       * @member {rti.RequestModifyOrder.Condition} ifTouchedCondition
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.ifTouchedCondition = 1;

      /**
       * RequestModifyOrder ifTouchedPriceField.
       * @member {rti.RequestModifyOrder.PriceField} ifTouchedPriceField
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.ifTouchedPriceField = 1;

      /**
       * RequestModifyOrder ifTouchedPrice.
       * @member {number} ifTouchedPrice
       * @memberof rti.RequestModifyOrder
       * @instance
       */
      RequestModifyOrder.prototype.ifTouchedPrice = 0;

      /**
       * Creates a new RequestModifyOrder instance using the specified properties.
       * @function create
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {rti.IRequestModifyOrder=} [properties] Properties to set
       * @returns {rti.RequestModifyOrder} RequestModifyOrder instance
       */
      RequestModifyOrder.create = function create(properties) {
        return new RequestModifyOrder(properties);
      };

      /**
       * Encodes the specified RequestModifyOrder message. Does not implicitly {@link rti.RequestModifyOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {rti.IRequestModifyOrder} message RequestModifyOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestModifyOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (
          message.price != null &&
          Object.hasOwnProperty.call(message, 'price')
        )
          writer
            .uint32(/* id 110306, wireType 1 =*/ 882449)
            .double(message.price);
        if (
          message.quantity != null &&
          Object.hasOwnProperty.call(message, 'quantity')
        )
          writer
            .uint32(/* id 112004, wireType 0 =*/ 896032)
            .int32(message.quantity);
        if (
          message.priceType != null &&
          Object.hasOwnProperty.call(message, 'priceType')
        )
          writer
            .uint32(/* id 112008, wireType 0 =*/ 896064)
            .int32(message.priceType);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.triggerPrice != null &&
          Object.hasOwnProperty.call(message, 'triggerPrice')
        )
          writer
            .uint32(/* id 149247, wireType 1 =*/ 1193977)
            .double(message.triggerPrice);
        if (
          message.ifTouchedPrice != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedPrice')
        )
          writer
            .uint32(/* id 153632, wireType 1 =*/ 1229057)
            .double(message.ifTouchedPrice);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.ifTouchedSymbol != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedSymbol')
        )
          writer
            .uint32(/* id 154451, wireType 2 =*/ 1235610)
            .string(message.ifTouchedSymbol);
        if (
          message.ifTouchedExchange != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedExchange')
        )
          writer
            .uint32(/* id 154452, wireType 2 =*/ 1235618)
            .string(message.ifTouchedExchange);
        if (
          message.ifTouchedCondition != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedCondition')
        )
          writer
            .uint32(/* id 154453, wireType 0 =*/ 1235624)
            .int32(message.ifTouchedCondition);
        if (
          message.ifTouchedPriceField != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedPriceField')
        )
          writer
            .uint32(/* id 154454, wireType 0 =*/ 1235632)
            .int32(message.ifTouchedPriceField);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.windowName != null &&
          Object.hasOwnProperty.call(message, 'windowName')
        )
          writer
            .uint32(/* id 154629, wireType 2 =*/ 1237034)
            .string(message.windowName);
        if (
          message.manualOrAuto != null &&
          Object.hasOwnProperty.call(message, 'manualOrAuto')
        )
          writer
            .uint32(/* id 154710, wireType 0 =*/ 1237680)
            .int32(message.manualOrAuto);
        return writer;
      };

      /**
       * Encodes the specified RequestModifyOrder message, length delimited. Does not implicitly {@link rti.RequestModifyOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {rti.IRequestModifyOrder} message RequestModifyOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestModifyOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestModifyOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestModifyOrder} RequestModifyOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestModifyOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestModifyOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154629:
              message.windowName = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 112004:
              message.quantity = reader.int32();
              break;
            case 110306:
              message.price = reader.double();
              break;
            case 149247:
              message.triggerPrice = reader.double();
              break;
            case 112008:
              message.priceType = reader.int32();
              break;
            case 154710:
              message.manualOrAuto = reader.int32();
              break;
            case 154451:
              message.ifTouchedSymbol = reader.string();
              break;
            case 154452:
              message.ifTouchedExchange = reader.string();
              break;
            case 154453:
              message.ifTouchedCondition = reader.int32();
              break;
            case 154454:
              message.ifTouchedPriceField = reader.int32();
              break;
            case 153632:
              message.ifTouchedPrice = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestModifyOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestModifyOrder} RequestModifyOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestModifyOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestModifyOrder message.
       * @function verify
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestModifyOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          if (!$util.isString(message.windowName))
            return 'windowName: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          if (!$util.isInteger(message.quantity))
            return 'quantity: integer expected';
        if (message.price != null && message.hasOwnProperty('price'))
          if (typeof message.price !== 'number')
            return 'price: number expected';
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          if (typeof message.triggerPrice !== 'number')
            return 'triggerPrice: number expected';
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          switch (message.priceType) {
            default:
              return 'priceType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          switch (message.manualOrAuto) {
            default:
              return 'manualOrAuto: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.ifTouchedSymbol != null &&
          message.hasOwnProperty('ifTouchedSymbol')
        )
          if (!$util.isString(message.ifTouchedSymbol))
            return 'ifTouchedSymbol: string expected';
        if (
          message.ifTouchedExchange != null &&
          message.hasOwnProperty('ifTouchedExchange')
        )
          if (!$util.isString(message.ifTouchedExchange))
            return 'ifTouchedExchange: string expected';
        if (
          message.ifTouchedCondition != null &&
          message.hasOwnProperty('ifTouchedCondition')
        )
          switch (message.ifTouchedCondition) {
            default:
              return 'ifTouchedCondition: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (
          message.ifTouchedPriceField != null &&
          message.hasOwnProperty('ifTouchedPriceField')
        )
          switch (message.ifTouchedPriceField) {
            default:
              return 'ifTouchedPriceField: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.ifTouchedPrice != null &&
          message.hasOwnProperty('ifTouchedPrice')
        )
          if (typeof message.ifTouchedPrice !== 'number')
            return 'ifTouchedPrice: number expected';
        return null;
      };

      /**
       * Creates a RequestModifyOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestModifyOrder} RequestModifyOrder
       */
      RequestModifyOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestModifyOrder) return object;
        var message = new $root.rti.RequestModifyOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestModifyOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.windowName != null)
          message.windowName = String(object.windowName);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.quantity != null) message.quantity = object.quantity | 0;
        if (object.price != null) message.price = Number(object.price);
        if (object.triggerPrice != null)
          message.triggerPrice = Number(object.triggerPrice);
        switch (object.priceType) {
          case 'LIMIT':
          case 1:
            message.priceType = 1;
            break;
          case 'MARKET':
          case 2:
            message.priceType = 2;
            break;
          case 'STOP_LIMIT':
          case 3:
            message.priceType = 3;
            break;
          case 'STOP_MARKET':
          case 4:
            message.priceType = 4;
            break;
          case 'MARKET_IF_TOUCHED':
          case 5:
            message.priceType = 5;
            break;
          case 'LIMIT_IF_TOUCHED':
          case 6:
            message.priceType = 6;
            break;
        }
        switch (object.manualOrAuto) {
          case 'MANUAL':
          case 1:
            message.manualOrAuto = 1;
            break;
          case 'AUTO':
          case 2:
            message.manualOrAuto = 2;
            break;
        }
        if (object.ifTouchedSymbol != null)
          message.ifTouchedSymbol = String(object.ifTouchedSymbol);
        if (object.ifTouchedExchange != null)
          message.ifTouchedExchange = String(object.ifTouchedExchange);
        switch (object.ifTouchedCondition) {
          case 'EQUAL_TO':
          case 1:
            message.ifTouchedCondition = 1;
            break;
          case 'NOT_EQUAL_TO':
          case 2:
            message.ifTouchedCondition = 2;
            break;
          case 'GREATER_THAN':
          case 3:
            message.ifTouchedCondition = 3;
            break;
          case 'GREATER_THAN_EQUAL_TO':
          case 4:
            message.ifTouchedCondition = 4;
            break;
          case 'LESSER_THAN':
          case 5:
            message.ifTouchedCondition = 5;
            break;
          case 'LESSER_THAN_EQUAL_TO':
          case 6:
            message.ifTouchedCondition = 6;
            break;
        }
        switch (object.ifTouchedPriceField) {
          case 'BID_PRICE':
          case 1:
            message.ifTouchedPriceField = 1;
            break;
          case 'OFFER_PRICE':
          case 2:
            message.ifTouchedPriceField = 2;
            break;
          case 'TRADE_PRICE':
          case 3:
            message.ifTouchedPriceField = 3;
            break;
          case 'LEAN_PRICE':
          case 4:
            message.ifTouchedPriceField = 4;
            break;
        }
        if (object.ifTouchedPrice != null)
          message.ifTouchedPrice = Number(object.ifTouchedPrice);
        return message;
      };

      /**
       * Creates a plain object from a RequestModifyOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {rti.RequestModifyOrder} message RequestModifyOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestModifyOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.basketId = '';
          object.price = 0;
          object.quantity = 0;
          object.priceType = options.enums === String ? 'LIMIT' : 1;
          object.triggerPrice = 0;
          object.ifTouchedPrice = 0;
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.ifTouchedSymbol = '';
          object.ifTouchedExchange = '';
          object.ifTouchedCondition = options.enums === String ? 'EQUAL_TO' : 1;
          object.ifTouchedPriceField =
            options.enums === String ? 'BID_PRICE' : 1;
          object.templateId = 0;
          object.windowName = '';
          object.manualOrAuto = options.enums === String ? 'MANUAL' : 1;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.price != null && message.hasOwnProperty('price'))
          object.price =
            options.json && !isFinite(message.price)
              ? String(message.price)
              : message.price;
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          object.quantity = message.quantity;
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          object.priceType =
            options.enums === String
              ? $root.rti.RequestModifyOrder.PriceType[message.priceType]
              : message.priceType;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          object.triggerPrice =
            options.json && !isFinite(message.triggerPrice)
              ? String(message.triggerPrice)
              : message.triggerPrice;
        if (
          message.ifTouchedPrice != null &&
          message.hasOwnProperty('ifTouchedPrice')
        )
          object.ifTouchedPrice =
            options.json && !isFinite(message.ifTouchedPrice)
              ? String(message.ifTouchedPrice)
              : message.ifTouchedPrice;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (
          message.ifTouchedSymbol != null &&
          message.hasOwnProperty('ifTouchedSymbol')
        )
          object.ifTouchedSymbol = message.ifTouchedSymbol;
        if (
          message.ifTouchedExchange != null &&
          message.hasOwnProperty('ifTouchedExchange')
        )
          object.ifTouchedExchange = message.ifTouchedExchange;
        if (
          message.ifTouchedCondition != null &&
          message.hasOwnProperty('ifTouchedCondition')
        )
          object.ifTouchedCondition =
            options.enums === String
              ? $root.rti.RequestModifyOrder.Condition[
                  message.ifTouchedCondition
                ]
              : message.ifTouchedCondition;
        if (
          message.ifTouchedPriceField != null &&
          message.hasOwnProperty('ifTouchedPriceField')
        )
          object.ifTouchedPriceField =
            options.enums === String
              ? $root.rti.RequestModifyOrder.PriceField[
                  message.ifTouchedPriceField
                ]
              : message.ifTouchedPriceField;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          object.windowName = message.windowName;
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          object.manualOrAuto =
            options.enums === String
              ? $root.rti.RequestModifyOrder.OrderPlacement[
                  message.manualOrAuto
                ]
              : message.manualOrAuto;
        return object;
      };

      /**
       * Converts this RequestModifyOrder to JSON.
       * @function toJSON
       * @memberof rti.RequestModifyOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestModifyOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestModifyOrder
       * @function getTypeUrl
       * @memberof rti.RequestModifyOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestModifyOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestModifyOrder';
      };

      /**
       * PriceType enum.
       * @name rti.RequestModifyOrder.PriceType
       * @enum {number}
       * @property {number} LIMIT=1 LIMIT value
       * @property {number} MARKET=2 MARKET value
       * @property {number} STOP_LIMIT=3 STOP_LIMIT value
       * @property {number} STOP_MARKET=4 STOP_MARKET value
       * @property {number} MARKET_IF_TOUCHED=5 MARKET_IF_TOUCHED value
       * @property {number} LIMIT_IF_TOUCHED=6 LIMIT_IF_TOUCHED value
       */
      RequestModifyOrder.PriceType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LIMIT')] = 1;
        values[(valuesById[2] = 'MARKET')] = 2;
        values[(valuesById[3] = 'STOP_LIMIT')] = 3;
        values[(valuesById[4] = 'STOP_MARKET')] = 4;
        values[(valuesById[5] = 'MARKET_IF_TOUCHED')] = 5;
        values[(valuesById[6] = 'LIMIT_IF_TOUCHED')] = 6;
        return values;
      })();

      /**
       * PriceField enum.
       * @name rti.RequestModifyOrder.PriceField
       * @enum {number}
       * @property {number} BID_PRICE=1 BID_PRICE value
       * @property {number} OFFER_PRICE=2 OFFER_PRICE value
       * @property {number} TRADE_PRICE=3 TRADE_PRICE value
       * @property {number} LEAN_PRICE=4 LEAN_PRICE value
       */
      RequestModifyOrder.PriceField = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BID_PRICE')] = 1;
        values[(valuesById[2] = 'OFFER_PRICE')] = 2;
        values[(valuesById[3] = 'TRADE_PRICE')] = 3;
        values[(valuesById[4] = 'LEAN_PRICE')] = 4;
        return values;
      })();

      /**
       * Condition enum.
       * @name rti.RequestModifyOrder.Condition
       * @enum {number}
       * @property {number} EQUAL_TO=1 EQUAL_TO value
       * @property {number} NOT_EQUAL_TO=2 NOT_EQUAL_TO value
       * @property {number} GREATER_THAN=3 GREATER_THAN value
       * @property {number} GREATER_THAN_EQUAL_TO=4 GREATER_THAN_EQUAL_TO value
       * @property {number} LESSER_THAN=5 LESSER_THAN value
       * @property {number} LESSER_THAN_EQUAL_TO=6 LESSER_THAN_EQUAL_TO value
       */
      RequestModifyOrder.Condition = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'EQUAL_TO')] = 1;
        values[(valuesById[2] = 'NOT_EQUAL_TO')] = 2;
        values[(valuesById[3] = 'GREATER_THAN')] = 3;
        values[(valuesById[4] = 'GREATER_THAN_EQUAL_TO')] = 4;
        values[(valuesById[5] = 'LESSER_THAN')] = 5;
        values[(valuesById[6] = 'LESSER_THAN_EQUAL_TO')] = 6;
        return values;
      })();

      /**
       * OrderPlacement enum.
       * @name rti.RequestModifyOrder.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      RequestModifyOrder.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      return RequestModifyOrder;
    })();

    rti.ResponseModifyOrder = (function () {
      /**
       * Properties of a ResponseModifyOrder.
       * @memberof rti
       * @interface IResponseModifyOrder
       * @property {number} templateId ResponseModifyOrder templateId
       * @property {Array.<string>|null} [userMsg] ResponseModifyOrder userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseModifyOrder rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseModifyOrder rpCode
       * @property {string|null} [basketId] ResponseModifyOrder basketId
       * @property {number|null} [ssboe] ResponseModifyOrder ssboe
       * @property {number|null} [usecs] ResponseModifyOrder usecs
       */

      /**
       * Constructs a new ResponseModifyOrder.
       * @memberof rti
       * @classdesc Represents a ResponseModifyOrder.
       * @implements IResponseModifyOrder
       * @constructor
       * @param {rti.IResponseModifyOrder=} [properties] Properties to set
       */
      function ResponseModifyOrder(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseModifyOrder templateId.
       * @member {number} templateId
       * @memberof rti.ResponseModifyOrder
       * @instance
       */
      ResponseModifyOrder.prototype.templateId = 0;

      /**
       * ResponseModifyOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseModifyOrder
       * @instance
       */
      ResponseModifyOrder.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseModifyOrder rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseModifyOrder
       * @instance
       */
      ResponseModifyOrder.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseModifyOrder rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseModifyOrder
       * @instance
       */
      ResponseModifyOrder.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseModifyOrder basketId.
       * @member {string} basketId
       * @memberof rti.ResponseModifyOrder
       * @instance
       */
      ResponseModifyOrder.prototype.basketId = '';

      /**
       * ResponseModifyOrder ssboe.
       * @member {number} ssboe
       * @memberof rti.ResponseModifyOrder
       * @instance
       */
      ResponseModifyOrder.prototype.ssboe = 0;

      /**
       * ResponseModifyOrder usecs.
       * @member {number} usecs
       * @memberof rti.ResponseModifyOrder
       * @instance
       */
      ResponseModifyOrder.prototype.usecs = 0;

      /**
       * Creates a new ResponseModifyOrder instance using the specified properties.
       * @function create
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {rti.IResponseModifyOrder=} [properties] Properties to set
       * @returns {rti.ResponseModifyOrder} ResponseModifyOrder instance
       */
      ResponseModifyOrder.create = function create(properties) {
        return new ResponseModifyOrder(properties);
      };

      /**
       * Encodes the specified ResponseModifyOrder message. Does not implicitly {@link rti.ResponseModifyOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {rti.IResponseModifyOrder} message ResponseModifyOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseModifyOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseModifyOrder message, length delimited. Does not implicitly {@link rti.ResponseModifyOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {rti.IResponseModifyOrder} message ResponseModifyOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseModifyOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseModifyOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseModifyOrder} ResponseModifyOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseModifyOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseModifyOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseModifyOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseModifyOrder} ResponseModifyOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseModifyOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseModifyOrder message.
       * @function verify
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseModifyOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a ResponseModifyOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseModifyOrder} ResponseModifyOrder
       */
      ResponseModifyOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseModifyOrder) return object;
        var message = new $root.rti.ResponseModifyOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseModifyOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseModifyOrder.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseModifyOrder.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a ResponseModifyOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {rti.ResponseModifyOrder} message ResponseModifyOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseModifyOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.basketId = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseModifyOrder to JSON.
       * @function toJSON
       * @memberof rti.ResponseModifyOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseModifyOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseModifyOrder
       * @function getTypeUrl
       * @memberof rti.ResponseModifyOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseModifyOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseModifyOrder';
      };

      return ResponseModifyOrder;
    })();

    rti.RequestModifyOrderReferenceData = (function () {
      /**
       * Properties of a RequestModifyOrderReferenceData.
       * @memberof rti
       * @interface IRequestModifyOrderReferenceData
       * @property {number} templateId RequestModifyOrderReferenceData templateId
       * @property {Array.<string>|null} [userMsg] RequestModifyOrderReferenceData userMsg
       * @property {string|null} [userTag] RequestModifyOrderReferenceData userTag
       * @property {string|null} [fcmId] RequestModifyOrderReferenceData fcmId
       * @property {string|null} [ibId] RequestModifyOrderReferenceData ibId
       * @property {string|null} [accountId] RequestModifyOrderReferenceData accountId
       * @property {string|null} [basketId] RequestModifyOrderReferenceData basketId
       */

      /**
       * Constructs a new RequestModifyOrderReferenceData.
       * @memberof rti
       * @classdesc Represents a RequestModifyOrderReferenceData.
       * @implements IRequestModifyOrderReferenceData
       * @constructor
       * @param {rti.IRequestModifyOrderReferenceData=} [properties] Properties to set
       */
      function RequestModifyOrderReferenceData(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestModifyOrderReferenceData templateId.
       * @member {number} templateId
       * @memberof rti.RequestModifyOrderReferenceData
       * @instance
       */
      RequestModifyOrderReferenceData.prototype.templateId = 0;

      /**
       * RequestModifyOrderReferenceData userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestModifyOrderReferenceData
       * @instance
       */
      RequestModifyOrderReferenceData.prototype.userMsg = $util.emptyArray;

      /**
       * RequestModifyOrderReferenceData userTag.
       * @member {string} userTag
       * @memberof rti.RequestModifyOrderReferenceData
       * @instance
       */
      RequestModifyOrderReferenceData.prototype.userTag = '';

      /**
       * RequestModifyOrderReferenceData fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestModifyOrderReferenceData
       * @instance
       */
      RequestModifyOrderReferenceData.prototype.fcmId = '';

      /**
       * RequestModifyOrderReferenceData ibId.
       * @member {string} ibId
       * @memberof rti.RequestModifyOrderReferenceData
       * @instance
       */
      RequestModifyOrderReferenceData.prototype.ibId = '';

      /**
       * RequestModifyOrderReferenceData accountId.
       * @member {string} accountId
       * @memberof rti.RequestModifyOrderReferenceData
       * @instance
       */
      RequestModifyOrderReferenceData.prototype.accountId = '';

      /**
       * RequestModifyOrderReferenceData basketId.
       * @member {string} basketId
       * @memberof rti.RequestModifyOrderReferenceData
       * @instance
       */
      RequestModifyOrderReferenceData.prototype.basketId = '';

      /**
       * Creates a new RequestModifyOrderReferenceData instance using the specified properties.
       * @function create
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {rti.IRequestModifyOrderReferenceData=} [properties] Properties to set
       * @returns {rti.RequestModifyOrderReferenceData} RequestModifyOrderReferenceData instance
       */
      RequestModifyOrderReferenceData.create = function create(properties) {
        return new RequestModifyOrderReferenceData(properties);
      };

      /**
       * Encodes the specified RequestModifyOrderReferenceData message. Does not implicitly {@link rti.RequestModifyOrderReferenceData.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {rti.IRequestModifyOrderReferenceData} message RequestModifyOrderReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestModifyOrderReferenceData.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.userTag != null &&
          Object.hasOwnProperty.call(message, 'userTag')
        )
          writer
            .uint32(/* id 154119, wireType 2 =*/ 1232954)
            .string(message.userTag);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestModifyOrderReferenceData message, length delimited. Does not implicitly {@link rti.RequestModifyOrderReferenceData.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {rti.IRequestModifyOrderReferenceData} message RequestModifyOrderReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestModifyOrderReferenceData.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestModifyOrderReferenceData message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestModifyOrderReferenceData} RequestModifyOrderReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestModifyOrderReferenceData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestModifyOrderReferenceData();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154119:
              message.userTag = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestModifyOrderReferenceData message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestModifyOrderReferenceData} RequestModifyOrderReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestModifyOrderReferenceData.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestModifyOrderReferenceData message.
       * @function verify
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestModifyOrderReferenceData.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          if (!$util.isString(message.userTag))
            return 'userTag: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        return null;
      };

      /**
       * Creates a RequestModifyOrderReferenceData message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestModifyOrderReferenceData} RequestModifyOrderReferenceData
       */
      RequestModifyOrderReferenceData.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestModifyOrderReferenceData)
          return object;
        var message = new $root.rti.RequestModifyOrderReferenceData();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestModifyOrderReferenceData.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.userTag != null) message.userTag = String(object.userTag);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.basketId != null) message.basketId = String(object.basketId);
        return message;
      };

      /**
       * Creates a plain object from a RequestModifyOrderReferenceData message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {rti.RequestModifyOrderReferenceData} message RequestModifyOrderReferenceData
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestModifyOrderReferenceData.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.basketId = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.userTag = '';
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          object.userTag = message.userTag;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestModifyOrderReferenceData to JSON.
       * @function toJSON
       * @memberof rti.RequestModifyOrderReferenceData
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestModifyOrderReferenceData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestModifyOrderReferenceData
       * @function getTypeUrl
       * @memberof rti.RequestModifyOrderReferenceData
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestModifyOrderReferenceData.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestModifyOrderReferenceData';
      };

      return RequestModifyOrderReferenceData;
    })();

    rti.ResponseModifyOrderReferenceData = (function () {
      /**
       * Properties of a ResponseModifyOrderReferenceData.
       * @memberof rti
       * @interface IResponseModifyOrderReferenceData
       * @property {number} templateId ResponseModifyOrderReferenceData templateId
       * @property {Array.<string>|null} [userMsg] ResponseModifyOrderReferenceData userMsg
       * @property {Array.<string>|null} [rpCode] ResponseModifyOrderReferenceData rpCode
       */

      /**
       * Constructs a new ResponseModifyOrderReferenceData.
       * @memberof rti
       * @classdesc Represents a ResponseModifyOrderReferenceData.
       * @implements IResponseModifyOrderReferenceData
       * @constructor
       * @param {rti.IResponseModifyOrderReferenceData=} [properties] Properties to set
       */
      function ResponseModifyOrderReferenceData(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseModifyOrderReferenceData templateId.
       * @member {number} templateId
       * @memberof rti.ResponseModifyOrderReferenceData
       * @instance
       */
      ResponseModifyOrderReferenceData.prototype.templateId = 0;

      /**
       * ResponseModifyOrderReferenceData userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseModifyOrderReferenceData
       * @instance
       */
      ResponseModifyOrderReferenceData.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseModifyOrderReferenceData rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseModifyOrderReferenceData
       * @instance
       */
      ResponseModifyOrderReferenceData.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseModifyOrderReferenceData instance using the specified properties.
       * @function create
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {rti.IResponseModifyOrderReferenceData=} [properties] Properties to set
       * @returns {rti.ResponseModifyOrderReferenceData} ResponseModifyOrderReferenceData instance
       */
      ResponseModifyOrderReferenceData.create = function create(properties) {
        return new ResponseModifyOrderReferenceData(properties);
      };

      /**
       * Encodes the specified ResponseModifyOrderReferenceData message. Does not implicitly {@link rti.ResponseModifyOrderReferenceData.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {rti.IResponseModifyOrderReferenceData} message ResponseModifyOrderReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseModifyOrderReferenceData.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseModifyOrderReferenceData message, length delimited. Does not implicitly {@link rti.ResponseModifyOrderReferenceData.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {rti.IResponseModifyOrderReferenceData} message ResponseModifyOrderReferenceData message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseModifyOrderReferenceData.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseModifyOrderReferenceData message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseModifyOrderReferenceData} ResponseModifyOrderReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseModifyOrderReferenceData.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseModifyOrderReferenceData();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseModifyOrderReferenceData message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseModifyOrderReferenceData} ResponseModifyOrderReferenceData
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseModifyOrderReferenceData.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseModifyOrderReferenceData message.
       * @function verify
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseModifyOrderReferenceData.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseModifyOrderReferenceData message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseModifyOrderReferenceData} ResponseModifyOrderReferenceData
       */
      ResponseModifyOrderReferenceData.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseModifyOrderReferenceData)
          return object;
        var message = new $root.rti.ResponseModifyOrderReferenceData();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseModifyOrderReferenceData.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseModifyOrderReferenceData.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseModifyOrderReferenceData message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {rti.ResponseModifyOrderReferenceData} message ResponseModifyOrderReferenceData
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseModifyOrderReferenceData.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseModifyOrderReferenceData to JSON.
       * @function toJSON
       * @memberof rti.ResponseModifyOrderReferenceData
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseModifyOrderReferenceData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseModifyOrderReferenceData
       * @function getTypeUrl
       * @memberof rti.ResponseModifyOrderReferenceData
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseModifyOrderReferenceData.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseModifyOrderReferenceData';
      };

      return ResponseModifyOrderReferenceData;
    })();

    rti.RequestCancelOrder = (function () {
      /**
       * Properties of a RequestCancelOrder.
       * @memberof rti
       * @interface IRequestCancelOrder
       * @property {number} templateId RequestCancelOrder templateId
       * @property {Array.<string>|null} [userMsg] RequestCancelOrder userMsg
       * @property {string|null} [windowName] RequestCancelOrder windowName
       * @property {string|null} [fcmId] RequestCancelOrder fcmId
       * @property {string|null} [ibId] RequestCancelOrder ibId
       * @property {string|null} [accountId] RequestCancelOrder accountId
       * @property {string|null} [basketId] RequestCancelOrder basketId
       * @property {rti.RequestCancelOrder.OrderPlacement|null} [manualOrAuto] RequestCancelOrder manualOrAuto
       */

      /**
       * Constructs a new RequestCancelOrder.
       * @memberof rti
       * @classdesc Represents a RequestCancelOrder.
       * @implements IRequestCancelOrder
       * @constructor
       * @param {rti.IRequestCancelOrder=} [properties] Properties to set
       */
      function RequestCancelOrder(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestCancelOrder templateId.
       * @member {number} templateId
       * @memberof rti.RequestCancelOrder
       * @instance
       */
      RequestCancelOrder.prototype.templateId = 0;

      /**
       * RequestCancelOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestCancelOrder
       * @instance
       */
      RequestCancelOrder.prototype.userMsg = $util.emptyArray;

      /**
       * RequestCancelOrder windowName.
       * @member {string} windowName
       * @memberof rti.RequestCancelOrder
       * @instance
       */
      RequestCancelOrder.prototype.windowName = '';

      /**
       * RequestCancelOrder fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestCancelOrder
       * @instance
       */
      RequestCancelOrder.prototype.fcmId = '';

      /**
       * RequestCancelOrder ibId.
       * @member {string} ibId
       * @memberof rti.RequestCancelOrder
       * @instance
       */
      RequestCancelOrder.prototype.ibId = '';

      /**
       * RequestCancelOrder accountId.
       * @member {string} accountId
       * @memberof rti.RequestCancelOrder
       * @instance
       */
      RequestCancelOrder.prototype.accountId = '';

      /**
       * RequestCancelOrder basketId.
       * @member {string} basketId
       * @memberof rti.RequestCancelOrder
       * @instance
       */
      RequestCancelOrder.prototype.basketId = '';

      /**
       * RequestCancelOrder manualOrAuto.
       * @member {rti.RequestCancelOrder.OrderPlacement} manualOrAuto
       * @memberof rti.RequestCancelOrder
       * @instance
       */
      RequestCancelOrder.prototype.manualOrAuto = 1;

      /**
       * Creates a new RequestCancelOrder instance using the specified properties.
       * @function create
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {rti.IRequestCancelOrder=} [properties] Properties to set
       * @returns {rti.RequestCancelOrder} RequestCancelOrder instance
       */
      RequestCancelOrder.create = function create(properties) {
        return new RequestCancelOrder(properties);
      };

      /**
       * Encodes the specified RequestCancelOrder message. Does not implicitly {@link rti.RequestCancelOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {rti.IRequestCancelOrder} message RequestCancelOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestCancelOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.windowName != null &&
          Object.hasOwnProperty.call(message, 'windowName')
        )
          writer
            .uint32(/* id 154629, wireType 2 =*/ 1237034)
            .string(message.windowName);
        if (
          message.manualOrAuto != null &&
          Object.hasOwnProperty.call(message, 'manualOrAuto')
        )
          writer
            .uint32(/* id 154710, wireType 0 =*/ 1237680)
            .int32(message.manualOrAuto);
        return writer;
      };

      /**
       * Encodes the specified RequestCancelOrder message, length delimited. Does not implicitly {@link rti.RequestCancelOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {rti.IRequestCancelOrder} message RequestCancelOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestCancelOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestCancelOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestCancelOrder} RequestCancelOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestCancelOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestCancelOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154629:
              message.windowName = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 154710:
              message.manualOrAuto = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestCancelOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestCancelOrder} RequestCancelOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestCancelOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestCancelOrder message.
       * @function verify
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestCancelOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          if (!$util.isString(message.windowName))
            return 'windowName: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          switch (message.manualOrAuto) {
            default:
              return 'manualOrAuto: enum value expected';
            case 1:
            case 2:
              break;
          }
        return null;
      };

      /**
       * Creates a RequestCancelOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestCancelOrder} RequestCancelOrder
       */
      RequestCancelOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestCancelOrder) return object;
        var message = new $root.rti.RequestCancelOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestCancelOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.windowName != null)
          message.windowName = String(object.windowName);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.basketId != null) message.basketId = String(object.basketId);
        switch (object.manualOrAuto) {
          case 'MANUAL':
          case 1:
            message.manualOrAuto = 1;
            break;
          case 'AUTO':
          case 2:
            message.manualOrAuto = 2;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestCancelOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {rti.RequestCancelOrder} message RequestCancelOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestCancelOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.basketId = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
          object.windowName = '';
          object.manualOrAuto = options.enums === String ? 'MANUAL' : 1;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          object.windowName = message.windowName;
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          object.manualOrAuto =
            options.enums === String
              ? $root.rti.RequestCancelOrder.OrderPlacement[
                  message.manualOrAuto
                ]
              : message.manualOrAuto;
        return object;
      };

      /**
       * Converts this RequestCancelOrder to JSON.
       * @function toJSON
       * @memberof rti.RequestCancelOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestCancelOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestCancelOrder
       * @function getTypeUrl
       * @memberof rti.RequestCancelOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestCancelOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestCancelOrder';
      };

      /**
       * OrderPlacement enum.
       * @name rti.RequestCancelOrder.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      RequestCancelOrder.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      return RequestCancelOrder;
    })();

    rti.ResponseCancelOrder = (function () {
      /**
       * Properties of a ResponseCancelOrder.
       * @memberof rti
       * @interface IResponseCancelOrder
       * @property {number} templateId ResponseCancelOrder templateId
       * @property {Array.<string>|null} [userMsg] ResponseCancelOrder userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseCancelOrder rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseCancelOrder rpCode
       * @property {string|null} [basketId] ResponseCancelOrder basketId
       * @property {number|null} [ssboe] ResponseCancelOrder ssboe
       * @property {number|null} [usecs] ResponseCancelOrder usecs
       */

      /**
       * Constructs a new ResponseCancelOrder.
       * @memberof rti
       * @classdesc Represents a ResponseCancelOrder.
       * @implements IResponseCancelOrder
       * @constructor
       * @param {rti.IResponseCancelOrder=} [properties] Properties to set
       */
      function ResponseCancelOrder(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseCancelOrder templateId.
       * @member {number} templateId
       * @memberof rti.ResponseCancelOrder
       * @instance
       */
      ResponseCancelOrder.prototype.templateId = 0;

      /**
       * ResponseCancelOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseCancelOrder
       * @instance
       */
      ResponseCancelOrder.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseCancelOrder rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseCancelOrder
       * @instance
       */
      ResponseCancelOrder.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseCancelOrder rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseCancelOrder
       * @instance
       */
      ResponseCancelOrder.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseCancelOrder basketId.
       * @member {string} basketId
       * @memberof rti.ResponseCancelOrder
       * @instance
       */
      ResponseCancelOrder.prototype.basketId = '';

      /**
       * ResponseCancelOrder ssboe.
       * @member {number} ssboe
       * @memberof rti.ResponseCancelOrder
       * @instance
       */
      ResponseCancelOrder.prototype.ssboe = 0;

      /**
       * ResponseCancelOrder usecs.
       * @member {number} usecs
       * @memberof rti.ResponseCancelOrder
       * @instance
       */
      ResponseCancelOrder.prototype.usecs = 0;

      /**
       * Creates a new ResponseCancelOrder instance using the specified properties.
       * @function create
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {rti.IResponseCancelOrder=} [properties] Properties to set
       * @returns {rti.ResponseCancelOrder} ResponseCancelOrder instance
       */
      ResponseCancelOrder.create = function create(properties) {
        return new ResponseCancelOrder(properties);
      };

      /**
       * Encodes the specified ResponseCancelOrder message. Does not implicitly {@link rti.ResponseCancelOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {rti.IResponseCancelOrder} message ResponseCancelOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseCancelOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseCancelOrder message, length delimited. Does not implicitly {@link rti.ResponseCancelOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {rti.IResponseCancelOrder} message ResponseCancelOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseCancelOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseCancelOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseCancelOrder} ResponseCancelOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseCancelOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseCancelOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseCancelOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseCancelOrder} ResponseCancelOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseCancelOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseCancelOrder message.
       * @function verify
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseCancelOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a ResponseCancelOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseCancelOrder} ResponseCancelOrder
       */
      ResponseCancelOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseCancelOrder) return object;
        var message = new $root.rti.ResponseCancelOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseCancelOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseCancelOrder.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseCancelOrder.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a ResponseCancelOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {rti.ResponseCancelOrder} message ResponseCancelOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseCancelOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.basketId = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseCancelOrder to JSON.
       * @function toJSON
       * @memberof rti.ResponseCancelOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseCancelOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseCancelOrder
       * @function getTypeUrl
       * @memberof rti.ResponseCancelOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseCancelOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseCancelOrder';
      };

      return ResponseCancelOrder;
    })();

    rti.RequestCancelAllOrders = (function () {
      /**
       * Properties of a RequestCancelAllOrders.
       * @memberof rti
       * @interface IRequestCancelAllOrders
       * @property {number} templateId RequestCancelAllOrders templateId
       * @property {Array.<string>|null} [userMsg] RequestCancelAllOrders userMsg
       * @property {string|null} [fcmId] RequestCancelAllOrders fcmId
       * @property {string|null} [ibId] RequestCancelAllOrders ibId
       * @property {string|null} [accountId] RequestCancelAllOrders accountId
       * @property {rti.RequestCancelAllOrders.UserType|null} [userType] RequestCancelAllOrders userType
       * @property {rti.RequestCancelAllOrders.OrderPlacement|null} [manualOrAuto] RequestCancelAllOrders manualOrAuto
       */

      /**
       * Constructs a new RequestCancelAllOrders.
       * @memberof rti
       * @classdesc Represents a RequestCancelAllOrders.
       * @implements IRequestCancelAllOrders
       * @constructor
       * @param {rti.IRequestCancelAllOrders=} [properties] Properties to set
       */
      function RequestCancelAllOrders(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestCancelAllOrders templateId.
       * @member {number} templateId
       * @memberof rti.RequestCancelAllOrders
       * @instance
       */
      RequestCancelAllOrders.prototype.templateId = 0;

      /**
       * RequestCancelAllOrders userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestCancelAllOrders
       * @instance
       */
      RequestCancelAllOrders.prototype.userMsg = $util.emptyArray;

      /**
       * RequestCancelAllOrders fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestCancelAllOrders
       * @instance
       */
      RequestCancelAllOrders.prototype.fcmId = '';

      /**
       * RequestCancelAllOrders ibId.
       * @member {string} ibId
       * @memberof rti.RequestCancelAllOrders
       * @instance
       */
      RequestCancelAllOrders.prototype.ibId = '';

      /**
       * RequestCancelAllOrders accountId.
       * @member {string} accountId
       * @memberof rti.RequestCancelAllOrders
       * @instance
       */
      RequestCancelAllOrders.prototype.accountId = '';

      /**
       * RequestCancelAllOrders userType.
       * @member {rti.RequestCancelAllOrders.UserType} userType
       * @memberof rti.RequestCancelAllOrders
       * @instance
       */
      RequestCancelAllOrders.prototype.userType = 0;

      /**
       * RequestCancelAllOrders manualOrAuto.
       * @member {rti.RequestCancelAllOrders.OrderPlacement} manualOrAuto
       * @memberof rti.RequestCancelAllOrders
       * @instance
       */
      RequestCancelAllOrders.prototype.manualOrAuto = 1;

      /**
       * Creates a new RequestCancelAllOrders instance using the specified properties.
       * @function create
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {rti.IRequestCancelAllOrders=} [properties] Properties to set
       * @returns {rti.RequestCancelAllOrders} RequestCancelAllOrders instance
       */
      RequestCancelAllOrders.create = function create(properties) {
        return new RequestCancelAllOrders(properties);
      };

      /**
       * Encodes the specified RequestCancelAllOrders message. Does not implicitly {@link rti.RequestCancelAllOrders.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {rti.IRequestCancelAllOrders} message RequestCancelAllOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestCancelAllOrders.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.userType != null &&
          Object.hasOwnProperty.call(message, 'userType')
        )
          writer
            .uint32(/* id 154036, wireType 0 =*/ 1232288)
            .int32(message.userType);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.manualOrAuto != null &&
          Object.hasOwnProperty.call(message, 'manualOrAuto')
        )
          writer
            .uint32(/* id 154710, wireType 0 =*/ 1237680)
            .int32(message.manualOrAuto);
        return writer;
      };

      /**
       * Encodes the specified RequestCancelAllOrders message, length delimited. Does not implicitly {@link rti.RequestCancelAllOrders.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {rti.IRequestCancelAllOrders} message RequestCancelAllOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestCancelAllOrders.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestCancelAllOrders message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestCancelAllOrders} RequestCancelAllOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestCancelAllOrders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestCancelAllOrders();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 154036:
              message.userType = reader.int32();
              break;
            case 154710:
              message.manualOrAuto = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestCancelAllOrders message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestCancelAllOrders} RequestCancelAllOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestCancelAllOrders.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestCancelAllOrders message.
       * @function verify
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestCancelAllOrders.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.userType != null && message.hasOwnProperty('userType'))
          switch (message.userType) {
            default:
              return 'userType: enum value expected';
            case 0:
            case 1:
            case 2:
            case 3:
              break;
          }
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          switch (message.manualOrAuto) {
            default:
              return 'manualOrAuto: enum value expected';
            case 1:
            case 2:
              break;
          }
        return null;
      };

      /**
       * Creates a RequestCancelAllOrders message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestCancelAllOrders} RequestCancelAllOrders
       */
      RequestCancelAllOrders.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestCancelAllOrders) return object;
        var message = new $root.rti.RequestCancelAllOrders();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestCancelAllOrders.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        switch (object.userType) {
          case 'USER_TYPE_ADMIN':
          case 0:
            message.userType = 0;
            break;
          case 'USER_TYPE_FCM':
          case 1:
            message.userType = 1;
            break;
          case 'USER_TYPE_IB':
          case 2:
            message.userType = 2;
            break;
          case 'USER_TYPE_TRADER':
          case 3:
            message.userType = 3;
            break;
        }
        switch (object.manualOrAuto) {
          case 'MANUAL':
          case 1:
            message.manualOrAuto = 1;
            break;
          case 'AUTO':
          case 2:
            message.manualOrAuto = 2;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestCancelAllOrders message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {rti.RequestCancelAllOrders} message RequestCancelAllOrders
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestCancelAllOrders.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.userType = options.enums === String ? 'USER_TYPE_ADMIN' : 0;
          object.templateId = 0;
          object.manualOrAuto = options.enums === String ? 'MANUAL' : 1;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.userType != null && message.hasOwnProperty('userType'))
          object.userType =
            options.enums === String
              ? $root.rti.RequestCancelAllOrders.UserType[message.userType]
              : message.userType;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          object.manualOrAuto =
            options.enums === String
              ? $root.rti.RequestCancelAllOrders.OrderPlacement[
                  message.manualOrAuto
                ]
              : message.manualOrAuto;
        return object;
      };

      /**
       * Converts this RequestCancelAllOrders to JSON.
       * @function toJSON
       * @memberof rti.RequestCancelAllOrders
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestCancelAllOrders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestCancelAllOrders
       * @function getTypeUrl
       * @memberof rti.RequestCancelAllOrders
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestCancelAllOrders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestCancelAllOrders';
      };

      /**
       * OrderPlacement enum.
       * @name rti.RequestCancelAllOrders.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      RequestCancelAllOrders.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      /**
       * UserType enum.
       * @name rti.RequestCancelAllOrders.UserType
       * @enum {number}
       * @property {number} USER_TYPE_ADMIN=0 USER_TYPE_ADMIN value
       * @property {number} USER_TYPE_FCM=1 USER_TYPE_FCM value
       * @property {number} USER_TYPE_IB=2 USER_TYPE_IB value
       * @property {number} USER_TYPE_TRADER=3 USER_TYPE_TRADER value
       */
      RequestCancelAllOrders.UserType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[0] = 'USER_TYPE_ADMIN')] = 0;
        values[(valuesById[1] = 'USER_TYPE_FCM')] = 1;
        values[(valuesById[2] = 'USER_TYPE_IB')] = 2;
        values[(valuesById[3] = 'USER_TYPE_TRADER')] = 3;
        return values;
      })();

      return RequestCancelAllOrders;
    })();

    rti.ResponseCancelAllOrders = (function () {
      /**
       * Properties of a ResponseCancelAllOrders.
       * @memberof rti
       * @interface IResponseCancelAllOrders
       * @property {number} templateId ResponseCancelAllOrders templateId
       * @property {Array.<string>|null} [userMsg] ResponseCancelAllOrders userMsg
       * @property {Array.<string>|null} [rpCode] ResponseCancelAllOrders rpCode
       */

      /**
       * Constructs a new ResponseCancelAllOrders.
       * @memberof rti
       * @classdesc Represents a ResponseCancelAllOrders.
       * @implements IResponseCancelAllOrders
       * @constructor
       * @param {rti.IResponseCancelAllOrders=} [properties] Properties to set
       */
      function ResponseCancelAllOrders(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseCancelAllOrders templateId.
       * @member {number} templateId
       * @memberof rti.ResponseCancelAllOrders
       * @instance
       */
      ResponseCancelAllOrders.prototype.templateId = 0;

      /**
       * ResponseCancelAllOrders userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseCancelAllOrders
       * @instance
       */
      ResponseCancelAllOrders.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseCancelAllOrders rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseCancelAllOrders
       * @instance
       */
      ResponseCancelAllOrders.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseCancelAllOrders instance using the specified properties.
       * @function create
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {rti.IResponseCancelAllOrders=} [properties] Properties to set
       * @returns {rti.ResponseCancelAllOrders} ResponseCancelAllOrders instance
       */
      ResponseCancelAllOrders.create = function create(properties) {
        return new ResponseCancelAllOrders(properties);
      };

      /**
       * Encodes the specified ResponseCancelAllOrders message. Does not implicitly {@link rti.ResponseCancelAllOrders.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {rti.IResponseCancelAllOrders} message ResponseCancelAllOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseCancelAllOrders.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseCancelAllOrders message, length delimited. Does not implicitly {@link rti.ResponseCancelAllOrders.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {rti.IResponseCancelAllOrders} message ResponseCancelAllOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseCancelAllOrders.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseCancelAllOrders message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseCancelAllOrders} ResponseCancelAllOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseCancelAllOrders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseCancelAllOrders();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseCancelAllOrders message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseCancelAllOrders} ResponseCancelAllOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseCancelAllOrders.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseCancelAllOrders message.
       * @function verify
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseCancelAllOrders.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseCancelAllOrders message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseCancelAllOrders} ResponseCancelAllOrders
       */
      ResponseCancelAllOrders.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseCancelAllOrders) return object;
        var message = new $root.rti.ResponseCancelAllOrders();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseCancelAllOrders.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseCancelAllOrders.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseCancelAllOrders message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {rti.ResponseCancelAllOrders} message ResponseCancelAllOrders
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseCancelAllOrders.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseCancelAllOrders to JSON.
       * @function toJSON
       * @memberof rti.ResponseCancelAllOrders
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseCancelAllOrders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseCancelAllOrders
       * @function getTypeUrl
       * @memberof rti.ResponseCancelAllOrders
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseCancelAllOrders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseCancelAllOrders';
      };

      return ResponseCancelAllOrders;
    })();

    rti.RequestShowOrders = (function () {
      /**
       * Properties of a RequestShowOrders.
       * @memberof rti
       * @interface IRequestShowOrders
       * @property {number} templateId RequestShowOrders templateId
       * @property {Array.<string>|null} [userMsg] RequestShowOrders userMsg
       * @property {string|null} [fcmId] RequestShowOrders fcmId
       * @property {string|null} [ibId] RequestShowOrders ibId
       * @property {string|null} [accountId] RequestShowOrders accountId
       */

      /**
       * Constructs a new RequestShowOrders.
       * @memberof rti
       * @classdesc Represents a RequestShowOrders.
       * @implements IRequestShowOrders
       * @constructor
       * @param {rti.IRequestShowOrders=} [properties] Properties to set
       */
      function RequestShowOrders(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestShowOrders templateId.
       * @member {number} templateId
       * @memberof rti.RequestShowOrders
       * @instance
       */
      RequestShowOrders.prototype.templateId = 0;

      /**
       * RequestShowOrders userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestShowOrders
       * @instance
       */
      RequestShowOrders.prototype.userMsg = $util.emptyArray;

      /**
       * RequestShowOrders fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestShowOrders
       * @instance
       */
      RequestShowOrders.prototype.fcmId = '';

      /**
       * RequestShowOrders ibId.
       * @member {string} ibId
       * @memberof rti.RequestShowOrders
       * @instance
       */
      RequestShowOrders.prototype.ibId = '';

      /**
       * RequestShowOrders accountId.
       * @member {string} accountId
       * @memberof rti.RequestShowOrders
       * @instance
       */
      RequestShowOrders.prototype.accountId = '';

      /**
       * Creates a new RequestShowOrders instance using the specified properties.
       * @function create
       * @memberof rti.RequestShowOrders
       * @static
       * @param {rti.IRequestShowOrders=} [properties] Properties to set
       * @returns {rti.RequestShowOrders} RequestShowOrders instance
       */
      RequestShowOrders.create = function create(properties) {
        return new RequestShowOrders(properties);
      };

      /**
       * Encodes the specified RequestShowOrders message. Does not implicitly {@link rti.RequestShowOrders.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestShowOrders
       * @static
       * @param {rti.IRequestShowOrders} message RequestShowOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrders.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestShowOrders message, length delimited. Does not implicitly {@link rti.RequestShowOrders.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestShowOrders
       * @static
       * @param {rti.IRequestShowOrders} message RequestShowOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrders.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestShowOrders message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestShowOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestShowOrders} RequestShowOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestShowOrders();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestShowOrders message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestShowOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestShowOrders} RequestShowOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrders.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestShowOrders message.
       * @function verify
       * @memberof rti.RequestShowOrders
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestShowOrders.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        return null;
      };

      /**
       * Creates a RequestShowOrders message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestShowOrders
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestShowOrders} RequestShowOrders
       */
      RequestShowOrders.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestShowOrders) return object;
        var message = new $root.rti.RequestShowOrders();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestShowOrders.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        return message;
      };

      /**
       * Creates a plain object from a RequestShowOrders message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestShowOrders
       * @static
       * @param {rti.RequestShowOrders} message RequestShowOrders
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestShowOrders.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestShowOrders to JSON.
       * @function toJSON
       * @memberof rti.RequestShowOrders
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestShowOrders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestShowOrders
       * @function getTypeUrl
       * @memberof rti.RequestShowOrders
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestShowOrders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestShowOrders';
      };

      return RequestShowOrders;
    })();

    rti.ResponseShowOrders = (function () {
      /**
       * Properties of a ResponseShowOrders.
       * @memberof rti
       * @interface IResponseShowOrders
       * @property {number} templateId ResponseShowOrders templateId
       * @property {Array.<string>|null} [userMsg] ResponseShowOrders userMsg
       * @property {Array.<string>|null} [rpCode] ResponseShowOrders rpCode
       */

      /**
       * Constructs a new ResponseShowOrders.
       * @memberof rti
       * @classdesc Represents a ResponseShowOrders.
       * @implements IResponseShowOrders
       * @constructor
       * @param {rti.IResponseShowOrders=} [properties] Properties to set
       */
      function ResponseShowOrders(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseShowOrders templateId.
       * @member {number} templateId
       * @memberof rti.ResponseShowOrders
       * @instance
       */
      ResponseShowOrders.prototype.templateId = 0;

      /**
       * ResponseShowOrders userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseShowOrders
       * @instance
       */
      ResponseShowOrders.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseShowOrders rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseShowOrders
       * @instance
       */
      ResponseShowOrders.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseShowOrders instance using the specified properties.
       * @function create
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {rti.IResponseShowOrders=} [properties] Properties to set
       * @returns {rti.ResponseShowOrders} ResponseShowOrders instance
       */
      ResponseShowOrders.create = function create(properties) {
        return new ResponseShowOrders(properties);
      };

      /**
       * Encodes the specified ResponseShowOrders message. Does not implicitly {@link rti.ResponseShowOrders.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {rti.IResponseShowOrders} message ResponseShowOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrders.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseShowOrders message, length delimited. Does not implicitly {@link rti.ResponseShowOrders.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {rti.IResponseShowOrders} message ResponseShowOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrders.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseShowOrders message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseShowOrders} ResponseShowOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseShowOrders();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseShowOrders message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseShowOrders} ResponseShowOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrders.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseShowOrders message.
       * @function verify
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseShowOrders.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseShowOrders message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseShowOrders} ResponseShowOrders
       */
      ResponseShowOrders.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseShowOrders) return object;
        var message = new $root.rti.ResponseShowOrders();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseShowOrders.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseShowOrders.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseShowOrders message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {rti.ResponseShowOrders} message ResponseShowOrders
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseShowOrders.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseShowOrders to JSON.
       * @function toJSON
       * @memberof rti.ResponseShowOrders
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseShowOrders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseShowOrders
       * @function getTypeUrl
       * @memberof rti.ResponseShowOrders
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseShowOrders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseShowOrders';
      };

      return ResponseShowOrders;
    })();

    rti.RequestShowOrderHistory = (function () {
      /**
       * Properties of a RequestShowOrderHistory.
       * @memberof rti
       * @interface IRequestShowOrderHistory
       * @property {number} templateId RequestShowOrderHistory templateId
       * @property {Array.<string>|null} [userMsg] RequestShowOrderHistory userMsg
       * @property {string|null} [fcmId] RequestShowOrderHistory fcmId
       * @property {string|null} [ibId] RequestShowOrderHistory ibId
       * @property {string|null} [accountId] RequestShowOrderHistory accountId
       * @property {string|null} [basketId] RequestShowOrderHistory basketId
       */

      /**
       * Constructs a new RequestShowOrderHistory.
       * @memberof rti
       * @classdesc Represents a RequestShowOrderHistory.
       * @implements IRequestShowOrderHistory
       * @constructor
       * @param {rti.IRequestShowOrderHistory=} [properties] Properties to set
       */
      function RequestShowOrderHistory(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestShowOrderHistory templateId.
       * @member {number} templateId
       * @memberof rti.RequestShowOrderHistory
       * @instance
       */
      RequestShowOrderHistory.prototype.templateId = 0;

      /**
       * RequestShowOrderHistory userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestShowOrderHistory
       * @instance
       */
      RequestShowOrderHistory.prototype.userMsg = $util.emptyArray;

      /**
       * RequestShowOrderHistory fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestShowOrderHistory
       * @instance
       */
      RequestShowOrderHistory.prototype.fcmId = '';

      /**
       * RequestShowOrderHistory ibId.
       * @member {string} ibId
       * @memberof rti.RequestShowOrderHistory
       * @instance
       */
      RequestShowOrderHistory.prototype.ibId = '';

      /**
       * RequestShowOrderHistory accountId.
       * @member {string} accountId
       * @memberof rti.RequestShowOrderHistory
       * @instance
       */
      RequestShowOrderHistory.prototype.accountId = '';

      /**
       * RequestShowOrderHistory basketId.
       * @member {string} basketId
       * @memberof rti.RequestShowOrderHistory
       * @instance
       */
      RequestShowOrderHistory.prototype.basketId = '';

      /**
       * Creates a new RequestShowOrderHistory instance using the specified properties.
       * @function create
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {rti.IRequestShowOrderHistory=} [properties] Properties to set
       * @returns {rti.RequestShowOrderHistory} RequestShowOrderHistory instance
       */
      RequestShowOrderHistory.create = function create(properties) {
        return new RequestShowOrderHistory(properties);
      };

      /**
       * Encodes the specified RequestShowOrderHistory message. Does not implicitly {@link rti.RequestShowOrderHistory.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {rti.IRequestShowOrderHistory} message RequestShowOrderHistory message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrderHistory.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestShowOrderHistory message, length delimited. Does not implicitly {@link rti.RequestShowOrderHistory.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {rti.IRequestShowOrderHistory} message RequestShowOrderHistory message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrderHistory.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestShowOrderHistory message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestShowOrderHistory} RequestShowOrderHistory
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrderHistory.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestShowOrderHistory();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestShowOrderHistory message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestShowOrderHistory} RequestShowOrderHistory
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrderHistory.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestShowOrderHistory message.
       * @function verify
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestShowOrderHistory.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        return null;
      };

      /**
       * Creates a RequestShowOrderHistory message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestShowOrderHistory} RequestShowOrderHistory
       */
      RequestShowOrderHistory.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestShowOrderHistory) return object;
        var message = new $root.rti.RequestShowOrderHistory();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestShowOrderHistory.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.basketId != null) message.basketId = String(object.basketId);
        return message;
      };

      /**
       * Creates a plain object from a RequestShowOrderHistory message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {rti.RequestShowOrderHistory} message RequestShowOrderHistory
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestShowOrderHistory.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.basketId = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestShowOrderHistory to JSON.
       * @function toJSON
       * @memberof rti.RequestShowOrderHistory
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestShowOrderHistory.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestShowOrderHistory
       * @function getTypeUrl
       * @memberof rti.RequestShowOrderHistory
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestShowOrderHistory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestShowOrderHistory';
      };

      return RequestShowOrderHistory;
    })();

    rti.ResponseShowOrderHistory = (function () {
      /**
       * Properties of a ResponseShowOrderHistory.
       * @memberof rti
       * @interface IResponseShowOrderHistory
       * @property {number} templateId ResponseShowOrderHistory templateId
       * @property {Array.<string>|null} [userMsg] ResponseShowOrderHistory userMsg
       * @property {Array.<string>|null} [rpCode] ResponseShowOrderHistory rpCode
       */

      /**
       * Constructs a new ResponseShowOrderHistory.
       * @memberof rti
       * @classdesc Represents a ResponseShowOrderHistory.
       * @implements IResponseShowOrderHistory
       * @constructor
       * @param {rti.IResponseShowOrderHistory=} [properties] Properties to set
       */
      function ResponseShowOrderHistory(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseShowOrderHistory templateId.
       * @member {number} templateId
       * @memberof rti.ResponseShowOrderHistory
       * @instance
       */
      ResponseShowOrderHistory.prototype.templateId = 0;

      /**
       * ResponseShowOrderHistory userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseShowOrderHistory
       * @instance
       */
      ResponseShowOrderHistory.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseShowOrderHistory rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseShowOrderHistory
       * @instance
       */
      ResponseShowOrderHistory.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseShowOrderHistory instance using the specified properties.
       * @function create
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {rti.IResponseShowOrderHistory=} [properties] Properties to set
       * @returns {rti.ResponseShowOrderHistory} ResponseShowOrderHistory instance
       */
      ResponseShowOrderHistory.create = function create(properties) {
        return new ResponseShowOrderHistory(properties);
      };

      /**
       * Encodes the specified ResponseShowOrderHistory message. Does not implicitly {@link rti.ResponseShowOrderHistory.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {rti.IResponseShowOrderHistory} message ResponseShowOrderHistory message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrderHistory.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseShowOrderHistory message, length delimited. Does not implicitly {@link rti.ResponseShowOrderHistory.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {rti.IResponseShowOrderHistory} message ResponseShowOrderHistory message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrderHistory.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseShowOrderHistory message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseShowOrderHistory} ResponseShowOrderHistory
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrderHistory.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseShowOrderHistory();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseShowOrderHistory message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseShowOrderHistory} ResponseShowOrderHistory
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrderHistory.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseShowOrderHistory message.
       * @function verify
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseShowOrderHistory.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseShowOrderHistory message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseShowOrderHistory} ResponseShowOrderHistory
       */
      ResponseShowOrderHistory.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseShowOrderHistory) return object;
        var message = new $root.rti.ResponseShowOrderHistory();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseShowOrderHistory.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseShowOrderHistory.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseShowOrderHistory message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {rti.ResponseShowOrderHistory} message ResponseShowOrderHistory
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseShowOrderHistory.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseShowOrderHistory to JSON.
       * @function toJSON
       * @memberof rti.ResponseShowOrderHistory
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseShowOrderHistory.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseShowOrderHistory
       * @function getTypeUrl
       * @memberof rti.ResponseShowOrderHistory
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseShowOrderHistory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseShowOrderHistory';
      };

      return ResponseShowOrderHistory;
    })();

    rti.RequestShowOrderHistorySummary = (function () {
      /**
       * Properties of a RequestShowOrderHistorySummary.
       * @memberof rti
       * @interface IRequestShowOrderHistorySummary
       * @property {number} templateId RequestShowOrderHistorySummary templateId
       * @property {Array.<string>|null} [userMsg] RequestShowOrderHistorySummary userMsg
       * @property {string|null} [fcmId] RequestShowOrderHistorySummary fcmId
       * @property {string|null} [ibId] RequestShowOrderHistorySummary ibId
       * @property {string|null} [accountId] RequestShowOrderHistorySummary accountId
       * @property {string|null} [date] RequestShowOrderHistorySummary date
       */

      /**
       * Constructs a new RequestShowOrderHistorySummary.
       * @memberof rti
       * @classdesc Represents a RequestShowOrderHistorySummary.
       * @implements IRequestShowOrderHistorySummary
       * @constructor
       * @param {rti.IRequestShowOrderHistorySummary=} [properties] Properties to set
       */
      function RequestShowOrderHistorySummary(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestShowOrderHistorySummary templateId.
       * @member {number} templateId
       * @memberof rti.RequestShowOrderHistorySummary
       * @instance
       */
      RequestShowOrderHistorySummary.prototype.templateId = 0;

      /**
       * RequestShowOrderHistorySummary userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestShowOrderHistorySummary
       * @instance
       */
      RequestShowOrderHistorySummary.prototype.userMsg = $util.emptyArray;

      /**
       * RequestShowOrderHistorySummary fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestShowOrderHistorySummary
       * @instance
       */
      RequestShowOrderHistorySummary.prototype.fcmId = '';

      /**
       * RequestShowOrderHistorySummary ibId.
       * @member {string} ibId
       * @memberof rti.RequestShowOrderHistorySummary
       * @instance
       */
      RequestShowOrderHistorySummary.prototype.ibId = '';

      /**
       * RequestShowOrderHistorySummary accountId.
       * @member {string} accountId
       * @memberof rti.RequestShowOrderHistorySummary
       * @instance
       */
      RequestShowOrderHistorySummary.prototype.accountId = '';

      /**
       * RequestShowOrderHistorySummary date.
       * @member {string} date
       * @memberof rti.RequestShowOrderHistorySummary
       * @instance
       */
      RequestShowOrderHistorySummary.prototype.date = '';

      /**
       * Creates a new RequestShowOrderHistorySummary instance using the specified properties.
       * @function create
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {rti.IRequestShowOrderHistorySummary=} [properties] Properties to set
       * @returns {rti.RequestShowOrderHistorySummary} RequestShowOrderHistorySummary instance
       */
      RequestShowOrderHistorySummary.create = function create(properties) {
        return new RequestShowOrderHistorySummary(properties);
      };

      /**
       * Encodes the specified RequestShowOrderHistorySummary message. Does not implicitly {@link rti.RequestShowOrderHistorySummary.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {rti.IRequestShowOrderHistorySummary} message RequestShowOrderHistorySummary message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrderHistorySummary.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.date != null && Object.hasOwnProperty.call(message, 'date'))
          writer
            .uint32(/* id 110615, wireType 2 =*/ 884922)
            .string(message.date);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestShowOrderHistorySummary message, length delimited. Does not implicitly {@link rti.RequestShowOrderHistorySummary.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {rti.IRequestShowOrderHistorySummary} message RequestShowOrderHistorySummary message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrderHistorySummary.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestShowOrderHistorySummary message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestShowOrderHistorySummary} RequestShowOrderHistorySummary
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrderHistorySummary.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestShowOrderHistorySummary();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110615:
              message.date = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestShowOrderHistorySummary message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestShowOrderHistorySummary} RequestShowOrderHistorySummary
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrderHistorySummary.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestShowOrderHistorySummary message.
       * @function verify
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestShowOrderHistorySummary.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.date != null && message.hasOwnProperty('date'))
          if (!$util.isString(message.date)) return 'date: string expected';
        return null;
      };

      /**
       * Creates a RequestShowOrderHistorySummary message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestShowOrderHistorySummary} RequestShowOrderHistorySummary
       */
      RequestShowOrderHistorySummary.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestShowOrderHistorySummary)
          return object;
        var message = new $root.rti.RequestShowOrderHistorySummary();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestShowOrderHistorySummary.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.date != null) message.date = String(object.date);
        return message;
      };

      /**
       * Creates a plain object from a RequestShowOrderHistorySummary message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {rti.RequestShowOrderHistorySummary} message RequestShowOrderHistorySummary
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestShowOrderHistorySummary.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.date = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.date != null && message.hasOwnProperty('date'))
          object.date = message.date;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestShowOrderHistorySummary to JSON.
       * @function toJSON
       * @memberof rti.RequestShowOrderHistorySummary
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestShowOrderHistorySummary.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestShowOrderHistorySummary
       * @function getTypeUrl
       * @memberof rti.RequestShowOrderHistorySummary
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestShowOrderHistorySummary.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestShowOrderHistorySummary';
      };

      return RequestShowOrderHistorySummary;
    })();

    rti.ResponseShowOrderHistorySummary = (function () {
      /**
       * Properties of a ResponseShowOrderHistorySummary.
       * @memberof rti
       * @interface IResponseShowOrderHistorySummary
       * @property {number} templateId ResponseShowOrderHistorySummary templateId
       * @property {Array.<string>|null} [userMsg] ResponseShowOrderHistorySummary userMsg
       * @property {Array.<string>|null} [rpCode] ResponseShowOrderHistorySummary rpCode
       */

      /**
       * Constructs a new ResponseShowOrderHistorySummary.
       * @memberof rti
       * @classdesc Represents a ResponseShowOrderHistorySummary.
       * @implements IResponseShowOrderHistorySummary
       * @constructor
       * @param {rti.IResponseShowOrderHistorySummary=} [properties] Properties to set
       */
      function ResponseShowOrderHistorySummary(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseShowOrderHistorySummary templateId.
       * @member {number} templateId
       * @memberof rti.ResponseShowOrderHistorySummary
       * @instance
       */
      ResponseShowOrderHistorySummary.prototype.templateId = 0;

      /**
       * ResponseShowOrderHistorySummary userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseShowOrderHistorySummary
       * @instance
       */
      ResponseShowOrderHistorySummary.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseShowOrderHistorySummary rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseShowOrderHistorySummary
       * @instance
       */
      ResponseShowOrderHistorySummary.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseShowOrderHistorySummary instance using the specified properties.
       * @function create
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {rti.IResponseShowOrderHistorySummary=} [properties] Properties to set
       * @returns {rti.ResponseShowOrderHistorySummary} ResponseShowOrderHistorySummary instance
       */
      ResponseShowOrderHistorySummary.create = function create(properties) {
        return new ResponseShowOrderHistorySummary(properties);
      };

      /**
       * Encodes the specified ResponseShowOrderHistorySummary message. Does not implicitly {@link rti.ResponseShowOrderHistorySummary.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {rti.IResponseShowOrderHistorySummary} message ResponseShowOrderHistorySummary message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrderHistorySummary.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseShowOrderHistorySummary message, length delimited. Does not implicitly {@link rti.ResponseShowOrderHistorySummary.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {rti.IResponseShowOrderHistorySummary} message ResponseShowOrderHistorySummary message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrderHistorySummary.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseShowOrderHistorySummary message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseShowOrderHistorySummary} ResponseShowOrderHistorySummary
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrderHistorySummary.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseShowOrderHistorySummary();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseShowOrderHistorySummary message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseShowOrderHistorySummary} ResponseShowOrderHistorySummary
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrderHistorySummary.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseShowOrderHistorySummary message.
       * @function verify
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseShowOrderHistorySummary.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseShowOrderHistorySummary message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseShowOrderHistorySummary} ResponseShowOrderHistorySummary
       */
      ResponseShowOrderHistorySummary.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseShowOrderHistorySummary)
          return object;
        var message = new $root.rti.ResponseShowOrderHistorySummary();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseShowOrderHistorySummary.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseShowOrderHistorySummary.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseShowOrderHistorySummary message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {rti.ResponseShowOrderHistorySummary} message ResponseShowOrderHistorySummary
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseShowOrderHistorySummary.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseShowOrderHistorySummary to JSON.
       * @function toJSON
       * @memberof rti.ResponseShowOrderHistorySummary
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseShowOrderHistorySummary.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseShowOrderHistorySummary
       * @function getTypeUrl
       * @memberof rti.ResponseShowOrderHistorySummary
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseShowOrderHistorySummary.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseShowOrderHistorySummary';
      };

      return ResponseShowOrderHistorySummary;
    })();

    rti.RequestShowOrderHistoryDetail = (function () {
      /**
       * Properties of a RequestShowOrderHistoryDetail.
       * @memberof rti
       * @interface IRequestShowOrderHistoryDetail
       * @property {number} templateId RequestShowOrderHistoryDetail templateId
       * @property {Array.<string>|null} [userMsg] RequestShowOrderHistoryDetail userMsg
       * @property {string|null} [fcmId] RequestShowOrderHistoryDetail fcmId
       * @property {string|null} [ibId] RequestShowOrderHistoryDetail ibId
       * @property {string|null} [accountId] RequestShowOrderHistoryDetail accountId
       * @property {string|null} [basketId] RequestShowOrderHistoryDetail basketId
       * @property {string|null} [date] RequestShowOrderHistoryDetail date
       */

      /**
       * Constructs a new RequestShowOrderHistoryDetail.
       * @memberof rti
       * @classdesc Represents a RequestShowOrderHistoryDetail.
       * @implements IRequestShowOrderHistoryDetail
       * @constructor
       * @param {rti.IRequestShowOrderHistoryDetail=} [properties] Properties to set
       */
      function RequestShowOrderHistoryDetail(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestShowOrderHistoryDetail templateId.
       * @member {number} templateId
       * @memberof rti.RequestShowOrderHistoryDetail
       * @instance
       */
      RequestShowOrderHistoryDetail.prototype.templateId = 0;

      /**
       * RequestShowOrderHistoryDetail userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestShowOrderHistoryDetail
       * @instance
       */
      RequestShowOrderHistoryDetail.prototype.userMsg = $util.emptyArray;

      /**
       * RequestShowOrderHistoryDetail fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestShowOrderHistoryDetail
       * @instance
       */
      RequestShowOrderHistoryDetail.prototype.fcmId = '';

      /**
       * RequestShowOrderHistoryDetail ibId.
       * @member {string} ibId
       * @memberof rti.RequestShowOrderHistoryDetail
       * @instance
       */
      RequestShowOrderHistoryDetail.prototype.ibId = '';

      /**
       * RequestShowOrderHistoryDetail accountId.
       * @member {string} accountId
       * @memberof rti.RequestShowOrderHistoryDetail
       * @instance
       */
      RequestShowOrderHistoryDetail.prototype.accountId = '';

      /**
       * RequestShowOrderHistoryDetail basketId.
       * @member {string} basketId
       * @memberof rti.RequestShowOrderHistoryDetail
       * @instance
       */
      RequestShowOrderHistoryDetail.prototype.basketId = '';

      /**
       * RequestShowOrderHistoryDetail date.
       * @member {string} date
       * @memberof rti.RequestShowOrderHistoryDetail
       * @instance
       */
      RequestShowOrderHistoryDetail.prototype.date = '';

      /**
       * Creates a new RequestShowOrderHistoryDetail instance using the specified properties.
       * @function create
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {rti.IRequestShowOrderHistoryDetail=} [properties] Properties to set
       * @returns {rti.RequestShowOrderHistoryDetail} RequestShowOrderHistoryDetail instance
       */
      RequestShowOrderHistoryDetail.create = function create(properties) {
        return new RequestShowOrderHistoryDetail(properties);
      };

      /**
       * Encodes the specified RequestShowOrderHistoryDetail message. Does not implicitly {@link rti.RequestShowOrderHistoryDetail.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {rti.IRequestShowOrderHistoryDetail} message RequestShowOrderHistoryDetail message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrderHistoryDetail.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.date != null && Object.hasOwnProperty.call(message, 'date'))
          writer
            .uint32(/* id 110615, wireType 2 =*/ 884922)
            .string(message.date);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestShowOrderHistoryDetail message, length delimited. Does not implicitly {@link rti.RequestShowOrderHistoryDetail.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {rti.IRequestShowOrderHistoryDetail} message RequestShowOrderHistoryDetail message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrderHistoryDetail.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestShowOrderHistoryDetail message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestShowOrderHistoryDetail} RequestShowOrderHistoryDetail
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrderHistoryDetail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestShowOrderHistoryDetail();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 110615:
              message.date = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestShowOrderHistoryDetail message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestShowOrderHistoryDetail} RequestShowOrderHistoryDetail
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrderHistoryDetail.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestShowOrderHistoryDetail message.
       * @function verify
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestShowOrderHistoryDetail.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.date != null && message.hasOwnProperty('date'))
          if (!$util.isString(message.date)) return 'date: string expected';
        return null;
      };

      /**
       * Creates a RequestShowOrderHistoryDetail message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestShowOrderHistoryDetail} RequestShowOrderHistoryDetail
       */
      RequestShowOrderHistoryDetail.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestShowOrderHistoryDetail)
          return object;
        var message = new $root.rti.RequestShowOrderHistoryDetail();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestShowOrderHistoryDetail.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.date != null) message.date = String(object.date);
        return message;
      };

      /**
       * Creates a plain object from a RequestShowOrderHistoryDetail message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {rti.RequestShowOrderHistoryDetail} message RequestShowOrderHistoryDetail
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestShowOrderHistoryDetail.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.basketId = '';
          object.date = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.date != null && message.hasOwnProperty('date'))
          object.date = message.date;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestShowOrderHistoryDetail to JSON.
       * @function toJSON
       * @memberof rti.RequestShowOrderHistoryDetail
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestShowOrderHistoryDetail.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestShowOrderHistoryDetail
       * @function getTypeUrl
       * @memberof rti.RequestShowOrderHistoryDetail
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestShowOrderHistoryDetail.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestShowOrderHistoryDetail';
      };

      return RequestShowOrderHistoryDetail;
    })();

    rti.ResponseShowOrderHistoryDetail = (function () {
      /**
       * Properties of a ResponseShowOrderHistoryDetail.
       * @memberof rti
       * @interface IResponseShowOrderHistoryDetail
       * @property {number} templateId ResponseShowOrderHistoryDetail templateId
       * @property {Array.<string>|null} [userMsg] ResponseShowOrderHistoryDetail userMsg
       * @property {Array.<string>|null} [rpCode] ResponseShowOrderHistoryDetail rpCode
       */

      /**
       * Constructs a new ResponseShowOrderHistoryDetail.
       * @memberof rti
       * @classdesc Represents a ResponseShowOrderHistoryDetail.
       * @implements IResponseShowOrderHistoryDetail
       * @constructor
       * @param {rti.IResponseShowOrderHistoryDetail=} [properties] Properties to set
       */
      function ResponseShowOrderHistoryDetail(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseShowOrderHistoryDetail templateId.
       * @member {number} templateId
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @instance
       */
      ResponseShowOrderHistoryDetail.prototype.templateId = 0;

      /**
       * ResponseShowOrderHistoryDetail userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @instance
       */
      ResponseShowOrderHistoryDetail.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseShowOrderHistoryDetail rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @instance
       */
      ResponseShowOrderHistoryDetail.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseShowOrderHistoryDetail instance using the specified properties.
       * @function create
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {rti.IResponseShowOrderHistoryDetail=} [properties] Properties to set
       * @returns {rti.ResponseShowOrderHistoryDetail} ResponseShowOrderHistoryDetail instance
       */
      ResponseShowOrderHistoryDetail.create = function create(properties) {
        return new ResponseShowOrderHistoryDetail(properties);
      };

      /**
       * Encodes the specified ResponseShowOrderHistoryDetail message. Does not implicitly {@link rti.ResponseShowOrderHistoryDetail.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {rti.IResponseShowOrderHistoryDetail} message ResponseShowOrderHistoryDetail message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrderHistoryDetail.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseShowOrderHistoryDetail message, length delimited. Does not implicitly {@link rti.ResponseShowOrderHistoryDetail.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {rti.IResponseShowOrderHistoryDetail} message ResponseShowOrderHistoryDetail message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrderHistoryDetail.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseShowOrderHistoryDetail message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseShowOrderHistoryDetail} ResponseShowOrderHistoryDetail
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrderHistoryDetail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseShowOrderHistoryDetail();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseShowOrderHistoryDetail message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseShowOrderHistoryDetail} ResponseShowOrderHistoryDetail
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrderHistoryDetail.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseShowOrderHistoryDetail message.
       * @function verify
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseShowOrderHistoryDetail.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseShowOrderHistoryDetail message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseShowOrderHistoryDetail} ResponseShowOrderHistoryDetail
       */
      ResponseShowOrderHistoryDetail.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseShowOrderHistoryDetail)
          return object;
        var message = new $root.rti.ResponseShowOrderHistoryDetail();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseShowOrderHistoryDetail.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseShowOrderHistoryDetail.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseShowOrderHistoryDetail message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {rti.ResponseShowOrderHistoryDetail} message ResponseShowOrderHistoryDetail
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseShowOrderHistoryDetail.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseShowOrderHistoryDetail to JSON.
       * @function toJSON
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseShowOrderHistoryDetail.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseShowOrderHistoryDetail
       * @function getTypeUrl
       * @memberof rti.ResponseShowOrderHistoryDetail
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseShowOrderHistoryDetail.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseShowOrderHistoryDetail';
      };

      return ResponseShowOrderHistoryDetail;
    })();

    rti.RequestShowOrderHistoryDates = (function () {
      /**
       * Properties of a RequestShowOrderHistoryDates.
       * @memberof rti
       * @interface IRequestShowOrderHistoryDates
       * @property {number} templateId RequestShowOrderHistoryDates templateId
       * @property {Array.<string>|null} [userMsg] RequestShowOrderHistoryDates userMsg
       */

      /**
       * Constructs a new RequestShowOrderHistoryDates.
       * @memberof rti
       * @classdesc Represents a RequestShowOrderHistoryDates.
       * @implements IRequestShowOrderHistoryDates
       * @constructor
       * @param {rti.IRequestShowOrderHistoryDates=} [properties] Properties to set
       */
      function RequestShowOrderHistoryDates(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestShowOrderHistoryDates templateId.
       * @member {number} templateId
       * @memberof rti.RequestShowOrderHistoryDates
       * @instance
       */
      RequestShowOrderHistoryDates.prototype.templateId = 0;

      /**
       * RequestShowOrderHistoryDates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestShowOrderHistoryDates
       * @instance
       */
      RequestShowOrderHistoryDates.prototype.userMsg = $util.emptyArray;

      /**
       * Creates a new RequestShowOrderHistoryDates instance using the specified properties.
       * @function create
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {rti.IRequestShowOrderHistoryDates=} [properties] Properties to set
       * @returns {rti.RequestShowOrderHistoryDates} RequestShowOrderHistoryDates instance
       */
      RequestShowOrderHistoryDates.create = function create(properties) {
        return new RequestShowOrderHistoryDates(properties);
      };

      /**
       * Encodes the specified RequestShowOrderHistoryDates message. Does not implicitly {@link rti.RequestShowOrderHistoryDates.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {rti.IRequestShowOrderHistoryDates} message RequestShowOrderHistoryDates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrderHistoryDates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestShowOrderHistoryDates message, length delimited. Does not implicitly {@link rti.RequestShowOrderHistoryDates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {rti.IRequestShowOrderHistoryDates} message RequestShowOrderHistoryDates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowOrderHistoryDates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestShowOrderHistoryDates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestShowOrderHistoryDates} RequestShowOrderHistoryDates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrderHistoryDates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestShowOrderHistoryDates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestShowOrderHistoryDates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestShowOrderHistoryDates} RequestShowOrderHistoryDates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowOrderHistoryDates.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestShowOrderHistoryDates message.
       * @function verify
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestShowOrderHistoryDates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        return null;
      };

      /**
       * Creates a RequestShowOrderHistoryDates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestShowOrderHistoryDates} RequestShowOrderHistoryDates
       */
      RequestShowOrderHistoryDates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestShowOrderHistoryDates)
          return object;
        var message = new $root.rti.RequestShowOrderHistoryDates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestShowOrderHistoryDates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestShowOrderHistoryDates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {rti.RequestShowOrderHistoryDates} message RequestShowOrderHistoryDates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestShowOrderHistoryDates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestShowOrderHistoryDates to JSON.
       * @function toJSON
       * @memberof rti.RequestShowOrderHistoryDates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestShowOrderHistoryDates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestShowOrderHistoryDates
       * @function getTypeUrl
       * @memberof rti.RequestShowOrderHistoryDates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestShowOrderHistoryDates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestShowOrderHistoryDates';
      };

      return RequestShowOrderHistoryDates;
    })();

    rti.ResponseShowOrderHistoryDates = (function () {
      /**
       * Properties of a ResponseShowOrderHistoryDates.
       * @memberof rti
       * @interface IResponseShowOrderHistoryDates
       * @property {number} templateId ResponseShowOrderHistoryDates templateId
       * @property {Array.<string>|null} [userMsg] ResponseShowOrderHistoryDates userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseShowOrderHistoryDates rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseShowOrderHistoryDates rpCode
       * @property {Array.<string>|null} [date] ResponseShowOrderHistoryDates date
       */

      /**
       * Constructs a new ResponseShowOrderHistoryDates.
       * @memberof rti
       * @classdesc Represents a ResponseShowOrderHistoryDates.
       * @implements IResponseShowOrderHistoryDates
       * @constructor
       * @param {rti.IResponseShowOrderHistoryDates=} [properties] Properties to set
       */
      function ResponseShowOrderHistoryDates(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        this.date = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseShowOrderHistoryDates templateId.
       * @member {number} templateId
       * @memberof rti.ResponseShowOrderHistoryDates
       * @instance
       */
      ResponseShowOrderHistoryDates.prototype.templateId = 0;

      /**
       * ResponseShowOrderHistoryDates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseShowOrderHistoryDates
       * @instance
       */
      ResponseShowOrderHistoryDates.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseShowOrderHistoryDates rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseShowOrderHistoryDates
       * @instance
       */
      ResponseShowOrderHistoryDates.prototype.rqHandlerRpCode =
        $util.emptyArray;

      /**
       * ResponseShowOrderHistoryDates rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseShowOrderHistoryDates
       * @instance
       */
      ResponseShowOrderHistoryDates.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseShowOrderHistoryDates date.
       * @member {Array.<string>} date
       * @memberof rti.ResponseShowOrderHistoryDates
       * @instance
       */
      ResponseShowOrderHistoryDates.prototype.date = $util.emptyArray;

      /**
       * Creates a new ResponseShowOrderHistoryDates instance using the specified properties.
       * @function create
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {rti.IResponseShowOrderHistoryDates=} [properties] Properties to set
       * @returns {rti.ResponseShowOrderHistoryDates} ResponseShowOrderHistoryDates instance
       */
      ResponseShowOrderHistoryDates.create = function create(properties) {
        return new ResponseShowOrderHistoryDates(properties);
      };

      /**
       * Encodes the specified ResponseShowOrderHistoryDates message. Does not implicitly {@link rti.ResponseShowOrderHistoryDates.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {rti.IResponseShowOrderHistoryDates} message ResponseShowOrderHistoryDates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrderHistoryDates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.date != null && message.date.length)
          for (var i = 0; i < message.date.length; ++i)
            writer
              .uint32(/* id 110615, wireType 2 =*/ 884922)
              .string(message.date[i]);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseShowOrderHistoryDates message, length delimited. Does not implicitly {@link rti.ResponseShowOrderHistoryDates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {rti.IResponseShowOrderHistoryDates} message ResponseShowOrderHistoryDates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowOrderHistoryDates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseShowOrderHistoryDates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseShowOrderHistoryDates} ResponseShowOrderHistoryDates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrderHistoryDates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseShowOrderHistoryDates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110615:
              if (!(message.date && message.date.length)) message.date = [];
              message.date.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseShowOrderHistoryDates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseShowOrderHistoryDates} ResponseShowOrderHistoryDates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowOrderHistoryDates.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseShowOrderHistoryDates message.
       * @function verify
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseShowOrderHistoryDates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.date != null && message.hasOwnProperty('date')) {
          if (!Array.isArray(message.date)) return 'date: array expected';
          for (var i = 0; i < message.date.length; ++i)
            if (!$util.isString(message.date[i]))
              return 'date: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseShowOrderHistoryDates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseShowOrderHistoryDates} ResponseShowOrderHistoryDates
       */
      ResponseShowOrderHistoryDates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseShowOrderHistoryDates)
          return object;
        var message = new $root.rti.ResponseShowOrderHistoryDates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseShowOrderHistoryDates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseShowOrderHistoryDates.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseShowOrderHistoryDates.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.date) {
          if (!Array.isArray(object.date))
            throw TypeError(
              '.rti.ResponseShowOrderHistoryDates.date: array expected',
            );
          message.date = [];
          for (var i = 0; i < object.date.length; ++i)
            message.date[i] = String(object.date[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseShowOrderHistoryDates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {rti.ResponseShowOrderHistoryDates} message ResponseShowOrderHistoryDates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseShowOrderHistoryDates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.date = [];
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.date && message.date.length) {
          object.date = [];
          for (var j = 0; j < message.date.length; ++j)
            object.date[j] = message.date[j];
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseShowOrderHistoryDates to JSON.
       * @function toJSON
       * @memberof rti.ResponseShowOrderHistoryDates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseShowOrderHistoryDates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseShowOrderHistoryDates
       * @function getTypeUrl
       * @memberof rti.ResponseShowOrderHistoryDates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseShowOrderHistoryDates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseShowOrderHistoryDates';
      };

      return ResponseShowOrderHistoryDates;
    })();

    rti.RequestOCOOrder = (function () {
      /**
       * Properties of a RequestOCOOrder.
       * @memberof rti
       * @interface IRequestOCOOrder
       * @property {number} templateId RequestOCOOrder templateId
       * @property {Array.<string>|null} [userMsg] RequestOCOOrder userMsg
       * @property {Array.<string>|null} [userTag] RequestOCOOrder userTag
       * @property {Array.<string>|null} [windowName] RequestOCOOrder windowName
       * @property {string|null} [fcmId] RequestOCOOrder fcmId
       * @property {string|null} [ibId] RequestOCOOrder ibId
       * @property {string|null} [accountId] RequestOCOOrder accountId
       * @property {Array.<string>|null} [symbol] RequestOCOOrder symbol
       * @property {Array.<string>|null} [exchange] RequestOCOOrder exchange
       * @property {Array.<number>|null} [quantity] RequestOCOOrder quantity
       * @property {Array.<number>|null} [price] RequestOCOOrder price
       * @property {Array.<number>|null} [triggerPrice] RequestOCOOrder triggerPrice
       * @property {Array.<rti.RequestOCOOrder.TransactionType>|null} [transactionType] RequestOCOOrder transactionType
       * @property {Array.<rti.RequestOCOOrder.Duration>|null} [duration] RequestOCOOrder duration
       * @property {Array.<rti.RequestOCOOrder.PriceType>|null} [priceType] RequestOCOOrder priceType
       * @property {Array.<string>|null} [tradeRoute] RequestOCOOrder tradeRoute
       * @property {Array.<rti.RequestOCOOrder.OrderPlacement>|null} [manualOrAuto] RequestOCOOrder manualOrAuto
       * @property {number|null} [cancelAtSsboe] RequestOCOOrder cancelAtSsboe
       * @property {number|null} [cancelAtUsecs] RequestOCOOrder cancelAtUsecs
       * @property {number|null} [cancelAfterSecs] RequestOCOOrder cancelAfterSecs
       */

      /**
       * Constructs a new RequestOCOOrder.
       * @memberof rti
       * @classdesc Represents a RequestOCOOrder.
       * @implements IRequestOCOOrder
       * @constructor
       * @param {rti.IRequestOCOOrder=} [properties] Properties to set
       */
      function RequestOCOOrder(properties) {
        this.userMsg = [];
        this.userTag = [];
        this.windowName = [];
        this.symbol = [];
        this.exchange = [];
        this.quantity = [];
        this.price = [];
        this.triggerPrice = [];
        this.transactionType = [];
        this.duration = [];
        this.priceType = [];
        this.tradeRoute = [];
        this.manualOrAuto = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestOCOOrder templateId.
       * @member {number} templateId
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.templateId = 0;

      /**
       * RequestOCOOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.userMsg = $util.emptyArray;

      /**
       * RequestOCOOrder userTag.
       * @member {Array.<string>} userTag
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.userTag = $util.emptyArray;

      /**
       * RequestOCOOrder windowName.
       * @member {Array.<string>} windowName
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.windowName = $util.emptyArray;

      /**
       * RequestOCOOrder fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.fcmId = '';

      /**
       * RequestOCOOrder ibId.
       * @member {string} ibId
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.ibId = '';

      /**
       * RequestOCOOrder accountId.
       * @member {string} accountId
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.accountId = '';

      /**
       * RequestOCOOrder symbol.
       * @member {Array.<string>} symbol
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.symbol = $util.emptyArray;

      /**
       * RequestOCOOrder exchange.
       * @member {Array.<string>} exchange
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.exchange = $util.emptyArray;

      /**
       * RequestOCOOrder quantity.
       * @member {Array.<number>} quantity
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.quantity = $util.emptyArray;

      /**
       * RequestOCOOrder price.
       * @member {Array.<number>} price
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.price = $util.emptyArray;

      /**
       * RequestOCOOrder triggerPrice.
       * @member {Array.<number>} triggerPrice
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.triggerPrice = $util.emptyArray;

      /**
       * RequestOCOOrder transactionType.
       * @member {Array.<rti.RequestOCOOrder.TransactionType>} transactionType
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.transactionType = $util.emptyArray;

      /**
       * RequestOCOOrder duration.
       * @member {Array.<rti.RequestOCOOrder.Duration>} duration
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.duration = $util.emptyArray;

      /**
       * RequestOCOOrder priceType.
       * @member {Array.<rti.RequestOCOOrder.PriceType>} priceType
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.priceType = $util.emptyArray;

      /**
       * RequestOCOOrder tradeRoute.
       * @member {Array.<string>} tradeRoute
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.tradeRoute = $util.emptyArray;

      /**
       * RequestOCOOrder manualOrAuto.
       * @member {Array.<rti.RequestOCOOrder.OrderPlacement>} manualOrAuto
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.manualOrAuto = $util.emptyArray;

      /**
       * RequestOCOOrder cancelAtSsboe.
       * @member {number} cancelAtSsboe
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.cancelAtSsboe = 0;

      /**
       * RequestOCOOrder cancelAtUsecs.
       * @member {number} cancelAtUsecs
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.cancelAtUsecs = 0;

      /**
       * RequestOCOOrder cancelAfterSecs.
       * @member {number} cancelAfterSecs
       * @memberof rti.RequestOCOOrder
       * @instance
       */
      RequestOCOOrder.prototype.cancelAfterSecs = 0;

      /**
       * Creates a new RequestOCOOrder instance using the specified properties.
       * @function create
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {rti.IRequestOCOOrder=} [properties] Properties to set
       * @returns {rti.RequestOCOOrder} RequestOCOOrder instance
       */
      RequestOCOOrder.create = function create(properties) {
        return new RequestOCOOrder(properties);
      };

      /**
       * Encodes the specified RequestOCOOrder message. Does not implicitly {@link rti.RequestOCOOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {rti.IRequestOCOOrder} message RequestOCOOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestOCOOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.symbol != null && message.symbol.length)
          for (var i = 0; i < message.symbol.length; ++i)
            writer
              .uint32(/* id 110100, wireType 2 =*/ 880802)
              .string(message.symbol[i]);
        if (message.exchange != null && message.exchange.length)
          for (var i = 0; i < message.exchange.length; ++i)
            writer
              .uint32(/* id 110101, wireType 2 =*/ 880810)
              .string(message.exchange[i]);
        if (message.price != null && message.price.length)
          for (var i = 0; i < message.price.length; ++i)
            writer
              .uint32(/* id 110306, wireType 1 =*/ 882449)
              .double(message.price[i]);
        if (message.transactionType != null && message.transactionType.length)
          for (var i = 0; i < message.transactionType.length; ++i)
            writer
              .uint32(/* id 112003, wireType 0 =*/ 896024)
              .int32(message.transactionType[i]);
        if (message.quantity != null && message.quantity.length)
          for (var i = 0; i < message.quantity.length; ++i)
            writer
              .uint32(/* id 112004, wireType 0 =*/ 896032)
              .int32(message.quantity[i]);
        if (message.duration != null && message.duration.length)
          for (var i = 0; i < message.duration.length; ++i)
            writer
              .uint32(/* id 112005, wireType 0 =*/ 896040)
              .int32(message.duration[i]);
        if (message.priceType != null && message.priceType.length)
          for (var i = 0; i < message.priceType.length; ++i)
            writer
              .uint32(/* id 112008, wireType 0 =*/ 896064)
              .int32(message.priceType[i]);
        if (message.tradeRoute != null && message.tradeRoute.length)
          for (var i = 0; i < message.tradeRoute.length; ++i)
            writer
              .uint32(/* id 112016, wireType 2 =*/ 896130)
              .string(message.tradeRoute[i]);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.triggerPrice != null && message.triggerPrice.length)
          for (var i = 0; i < message.triggerPrice.length; ++i)
            writer
              .uint32(/* id 149247, wireType 1 =*/ 1193977)
              .double(message.triggerPrice[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (message.userTag != null && message.userTag.length)
          for (var i = 0; i < message.userTag.length; ++i)
            writer
              .uint32(/* id 154119, wireType 2 =*/ 1232954)
              .string(message.userTag[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.cancelAfterSecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAfterSecs')
        )
          writer
            .uint32(/* id 154488, wireType 0 =*/ 1235904)
            .int32(message.cancelAfterSecs);
        if (message.windowName != null && message.windowName.length)
          for (var i = 0; i < message.windowName.length; ++i)
            writer
              .uint32(/* id 154629, wireType 2 =*/ 1237034)
              .string(message.windowName[i]);
        if (message.manualOrAuto != null && message.manualOrAuto.length)
          for (var i = 0; i < message.manualOrAuto.length; ++i)
            writer
              .uint32(/* id 154710, wireType 0 =*/ 1237680)
              .int32(message.manualOrAuto[i]);
        if (
          message.cancelAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'cancelAtSsboe')
        )
          writer
            .uint32(/* id 157085, wireType 0 =*/ 1256680)
            .int32(message.cancelAtSsboe);
        if (
          message.cancelAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAtUsecs')
        )
          writer
            .uint32(/* id 157086, wireType 0 =*/ 1256688)
            .int32(message.cancelAtUsecs);
        return writer;
      };

      /**
       * Encodes the specified RequestOCOOrder message, length delimited. Does not implicitly {@link rti.RequestOCOOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {rti.IRequestOCOOrder} message RequestOCOOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestOCOOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestOCOOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestOCOOrder} RequestOCOOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestOCOOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestOCOOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154119:
              if (!(message.userTag && message.userTag.length))
                message.userTag = [];
              message.userTag.push(reader.string());
              break;
            case 154629:
              if (!(message.windowName && message.windowName.length))
                message.windowName = [];
              message.windowName.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110100:
              if (!(message.symbol && message.symbol.length))
                message.symbol = [];
              message.symbol.push(reader.string());
              break;
            case 110101:
              if (!(message.exchange && message.exchange.length))
                message.exchange = [];
              message.exchange.push(reader.string());
              break;
            case 112004:
              if (!(message.quantity && message.quantity.length))
                message.quantity = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.quantity.push(reader.int32());
              } else message.quantity.push(reader.int32());
              break;
            case 110306:
              if (!(message.price && message.price.length)) message.price = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.price.push(reader.double());
              } else message.price.push(reader.double());
              break;
            case 149247:
              if (!(message.triggerPrice && message.triggerPrice.length))
                message.triggerPrice = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.triggerPrice.push(reader.double());
              } else message.triggerPrice.push(reader.double());
              break;
            case 112003:
              if (!(message.transactionType && message.transactionType.length))
                message.transactionType = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.transactionType.push(reader.int32());
              } else message.transactionType.push(reader.int32());
              break;
            case 112005:
              if (!(message.duration && message.duration.length))
                message.duration = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.duration.push(reader.int32());
              } else message.duration.push(reader.int32());
              break;
            case 112008:
              if (!(message.priceType && message.priceType.length))
                message.priceType = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.priceType.push(reader.int32());
              } else message.priceType.push(reader.int32());
              break;
            case 112016:
              if (!(message.tradeRoute && message.tradeRoute.length))
                message.tradeRoute = [];
              message.tradeRoute.push(reader.string());
              break;
            case 154710:
              if (!(message.manualOrAuto && message.manualOrAuto.length))
                message.manualOrAuto = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.manualOrAuto.push(reader.int32());
              } else message.manualOrAuto.push(reader.int32());
              break;
            case 157085:
              message.cancelAtSsboe = reader.int32();
              break;
            case 157086:
              message.cancelAtUsecs = reader.int32();
              break;
            case 154488:
              message.cancelAfterSecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestOCOOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestOCOOrder} RequestOCOOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestOCOOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestOCOOrder message.
       * @function verify
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestOCOOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.userTag != null && message.hasOwnProperty('userTag')) {
          if (!Array.isArray(message.userTag)) return 'userTag: array expected';
          for (var i = 0; i < message.userTag.length; ++i)
            if (!$util.isString(message.userTag[i]))
              return 'userTag: string[] expected';
        }
        if (
          message.windowName != null &&
          message.hasOwnProperty('windowName')
        ) {
          if (!Array.isArray(message.windowName))
            return 'windowName: array expected';
          for (var i = 0; i < message.windowName.length; ++i)
            if (!$util.isString(message.windowName[i]))
              return 'windowName: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol')) {
          if (!Array.isArray(message.symbol)) return 'symbol: array expected';
          for (var i = 0; i < message.symbol.length; ++i)
            if (!$util.isString(message.symbol[i]))
              return 'symbol: string[] expected';
        }
        if (message.exchange != null && message.hasOwnProperty('exchange')) {
          if (!Array.isArray(message.exchange))
            return 'exchange: array expected';
          for (var i = 0; i < message.exchange.length; ++i)
            if (!$util.isString(message.exchange[i]))
              return 'exchange: string[] expected';
        }
        if (message.quantity != null && message.hasOwnProperty('quantity')) {
          if (!Array.isArray(message.quantity))
            return 'quantity: array expected';
          for (var i = 0; i < message.quantity.length; ++i)
            if (!$util.isInteger(message.quantity[i]))
              return 'quantity: integer[] expected';
        }
        if (message.price != null && message.hasOwnProperty('price')) {
          if (!Array.isArray(message.price)) return 'price: array expected';
          for (var i = 0; i < message.price.length; ++i)
            if (typeof message.price[i] !== 'number')
              return 'price: number[] expected';
        }
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        ) {
          if (!Array.isArray(message.triggerPrice))
            return 'triggerPrice: array expected';
          for (var i = 0; i < message.triggerPrice.length; ++i)
            if (typeof message.triggerPrice[i] !== 'number')
              return 'triggerPrice: number[] expected';
        }
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        ) {
          if (!Array.isArray(message.transactionType))
            return 'transactionType: array expected';
          for (var i = 0; i < message.transactionType.length; ++i)
            switch (message.transactionType[i]) {
              default:
                return 'transactionType: enum value[] expected';
              case 1:
              case 2:
                break;
            }
        }
        if (message.duration != null && message.hasOwnProperty('duration')) {
          if (!Array.isArray(message.duration))
            return 'duration: array expected';
          for (var i = 0; i < message.duration.length; ++i)
            switch (message.duration[i]) {
              default:
                return 'duration: enum value[] expected';
              case 1:
              case 2:
              case 3:
              case 4:
                break;
            }
        }
        if (message.priceType != null && message.hasOwnProperty('priceType')) {
          if (!Array.isArray(message.priceType))
            return 'priceType: array expected';
          for (var i = 0; i < message.priceType.length; ++i)
            switch (message.priceType[i]) {
              default:
                return 'priceType: enum value[] expected';
              case 1:
              case 2:
              case 3:
              case 4:
                break;
            }
        }
        if (
          message.tradeRoute != null &&
          message.hasOwnProperty('tradeRoute')
        ) {
          if (!Array.isArray(message.tradeRoute))
            return 'tradeRoute: array expected';
          for (var i = 0; i < message.tradeRoute.length; ++i)
            if (!$util.isString(message.tradeRoute[i]))
              return 'tradeRoute: string[] expected';
        }
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        ) {
          if (!Array.isArray(message.manualOrAuto))
            return 'manualOrAuto: array expected';
          for (var i = 0; i < message.manualOrAuto.length; ++i)
            switch (message.manualOrAuto[i]) {
              default:
                return 'manualOrAuto: enum value[] expected';
              case 1:
              case 2:
                break;
            }
        }
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          if (!$util.isInteger(message.cancelAtSsboe))
            return 'cancelAtSsboe: integer expected';
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          if (!$util.isInteger(message.cancelAtUsecs))
            return 'cancelAtUsecs: integer expected';
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          if (!$util.isInteger(message.cancelAfterSecs))
            return 'cancelAfterSecs: integer expected';
        return null;
      };

      /**
       * Creates a RequestOCOOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestOCOOrder} RequestOCOOrder
       */
      RequestOCOOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestOCOOrder) return object;
        var message = new $root.rti.RequestOCOOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestOCOOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.userTag) {
          if (!Array.isArray(object.userTag))
            throw TypeError('.rti.RequestOCOOrder.userTag: array expected');
          message.userTag = [];
          for (var i = 0; i < object.userTag.length; ++i)
            message.userTag[i] = String(object.userTag[i]);
        }
        if (object.windowName) {
          if (!Array.isArray(object.windowName))
            throw TypeError('.rti.RequestOCOOrder.windowName: array expected');
          message.windowName = [];
          for (var i = 0; i < object.windowName.length; ++i)
            message.windowName[i] = String(object.windowName[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.symbol) {
          if (!Array.isArray(object.symbol))
            throw TypeError('.rti.RequestOCOOrder.symbol: array expected');
          message.symbol = [];
          for (var i = 0; i < object.symbol.length; ++i)
            message.symbol[i] = String(object.symbol[i]);
        }
        if (object.exchange) {
          if (!Array.isArray(object.exchange))
            throw TypeError('.rti.RequestOCOOrder.exchange: array expected');
          message.exchange = [];
          for (var i = 0; i < object.exchange.length; ++i)
            message.exchange[i] = String(object.exchange[i]);
        }
        if (object.quantity) {
          if (!Array.isArray(object.quantity))
            throw TypeError('.rti.RequestOCOOrder.quantity: array expected');
          message.quantity = [];
          for (var i = 0; i < object.quantity.length; ++i)
            message.quantity[i] = object.quantity[i] | 0;
        }
        if (object.price) {
          if (!Array.isArray(object.price))
            throw TypeError('.rti.RequestOCOOrder.price: array expected');
          message.price = [];
          for (var i = 0; i < object.price.length; ++i)
            message.price[i] = Number(object.price[i]);
        }
        if (object.triggerPrice) {
          if (!Array.isArray(object.triggerPrice))
            throw TypeError(
              '.rti.RequestOCOOrder.triggerPrice: array expected',
            );
          message.triggerPrice = [];
          for (var i = 0; i < object.triggerPrice.length; ++i)
            message.triggerPrice[i] = Number(object.triggerPrice[i]);
        }
        if (object.transactionType) {
          if (!Array.isArray(object.transactionType))
            throw TypeError(
              '.rti.RequestOCOOrder.transactionType: array expected',
            );
          message.transactionType = [];
          for (var i = 0; i < object.transactionType.length; ++i)
            switch (object.transactionType[i]) {
              default:
              case 'BUY':
              case 1:
                message.transactionType[i] = 1;
                break;
              case 'SELL':
              case 2:
                message.transactionType[i] = 2;
                break;
            }
        }
        if (object.duration) {
          if (!Array.isArray(object.duration))
            throw TypeError('.rti.RequestOCOOrder.duration: array expected');
          message.duration = [];
          for (var i = 0; i < object.duration.length; ++i)
            switch (object.duration[i]) {
              default:
              case 'DAY':
              case 1:
                message.duration[i] = 1;
                break;
              case 'GTC':
              case 2:
                message.duration[i] = 2;
                break;
              case 'IOC':
              case 3:
                message.duration[i] = 3;
                break;
              case 'FOK':
              case 4:
                message.duration[i] = 4;
                break;
            }
        }
        if (object.priceType) {
          if (!Array.isArray(object.priceType))
            throw TypeError('.rti.RequestOCOOrder.priceType: array expected');
          message.priceType = [];
          for (var i = 0; i < object.priceType.length; ++i)
            switch (object.priceType[i]) {
              default:
              case 'LIMIT':
              case 1:
                message.priceType[i] = 1;
                break;
              case 'MARKET':
              case 2:
                message.priceType[i] = 2;
                break;
              case 'STOP_LIMIT':
              case 3:
                message.priceType[i] = 3;
                break;
              case 'STOP_MARKET':
              case 4:
                message.priceType[i] = 4;
                break;
            }
        }
        if (object.tradeRoute) {
          if (!Array.isArray(object.tradeRoute))
            throw TypeError('.rti.RequestOCOOrder.tradeRoute: array expected');
          message.tradeRoute = [];
          for (var i = 0; i < object.tradeRoute.length; ++i)
            message.tradeRoute[i] = String(object.tradeRoute[i]);
        }
        if (object.manualOrAuto) {
          if (!Array.isArray(object.manualOrAuto))
            throw TypeError(
              '.rti.RequestOCOOrder.manualOrAuto: array expected',
            );
          message.manualOrAuto = [];
          for (var i = 0; i < object.manualOrAuto.length; ++i)
            switch (object.manualOrAuto[i]) {
              default:
              case 'MANUAL':
              case 1:
                message.manualOrAuto[i] = 1;
                break;
              case 'AUTO':
              case 2:
                message.manualOrAuto[i] = 2;
                break;
            }
        }
        if (object.cancelAtSsboe != null)
          message.cancelAtSsboe = object.cancelAtSsboe | 0;
        if (object.cancelAtUsecs != null)
          message.cancelAtUsecs = object.cancelAtUsecs | 0;
        if (object.cancelAfterSecs != null)
          message.cancelAfterSecs = object.cancelAfterSecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestOCOOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {rti.RequestOCOOrder} message RequestOCOOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestOCOOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.symbol = [];
          object.exchange = [];
          object.price = [];
          object.transactionType = [];
          object.quantity = [];
          object.duration = [];
          object.priceType = [];
          object.tradeRoute = [];
          object.userMsg = [];
          object.triggerPrice = [];
          object.userTag = [];
          object.windowName = [];
          object.manualOrAuto = [];
        }
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
          object.cancelAfterSecs = 0;
          object.cancelAtSsboe = 0;
          object.cancelAtUsecs = 0;
        }
        if (message.symbol && message.symbol.length) {
          object.symbol = [];
          for (var j = 0; j < message.symbol.length; ++j)
            object.symbol[j] = message.symbol[j];
        }
        if (message.exchange && message.exchange.length) {
          object.exchange = [];
          for (var j = 0; j < message.exchange.length; ++j)
            object.exchange[j] = message.exchange[j];
        }
        if (message.price && message.price.length) {
          object.price = [];
          for (var j = 0; j < message.price.length; ++j)
            object.price[j] =
              options.json && !isFinite(message.price[j])
                ? String(message.price[j])
                : message.price[j];
        }
        if (message.transactionType && message.transactionType.length) {
          object.transactionType = [];
          for (var j = 0; j < message.transactionType.length; ++j)
            object.transactionType[j] =
              options.enums === String
                ? $root.rti.RequestOCOOrder.TransactionType[
                    message.transactionType[j]
                  ]
                : message.transactionType[j];
        }
        if (message.quantity && message.quantity.length) {
          object.quantity = [];
          for (var j = 0; j < message.quantity.length; ++j)
            object.quantity[j] = message.quantity[j];
        }
        if (message.duration && message.duration.length) {
          object.duration = [];
          for (var j = 0; j < message.duration.length; ++j)
            object.duration[j] =
              options.enums === String
                ? $root.rti.RequestOCOOrder.Duration[message.duration[j]]
                : message.duration[j];
        }
        if (message.priceType && message.priceType.length) {
          object.priceType = [];
          for (var j = 0; j < message.priceType.length; ++j)
            object.priceType[j] =
              options.enums === String
                ? $root.rti.RequestOCOOrder.PriceType[message.priceType[j]]
                : message.priceType[j];
        }
        if (message.tradeRoute && message.tradeRoute.length) {
          object.tradeRoute = [];
          for (var j = 0; j < message.tradeRoute.length; ++j)
            object.tradeRoute[j] = message.tradeRoute[j];
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.triggerPrice && message.triggerPrice.length) {
          object.triggerPrice = [];
          for (var j = 0; j < message.triggerPrice.length; ++j)
            object.triggerPrice[j] =
              options.json && !isFinite(message.triggerPrice[j])
                ? String(message.triggerPrice[j])
                : message.triggerPrice[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.userTag && message.userTag.length) {
          object.userTag = [];
          for (var j = 0; j < message.userTag.length; ++j)
            object.userTag[j] = message.userTag[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          object.cancelAfterSecs = message.cancelAfterSecs;
        if (message.windowName && message.windowName.length) {
          object.windowName = [];
          for (var j = 0; j < message.windowName.length; ++j)
            object.windowName[j] = message.windowName[j];
        }
        if (message.manualOrAuto && message.manualOrAuto.length) {
          object.manualOrAuto = [];
          for (var j = 0; j < message.manualOrAuto.length; ++j)
            object.manualOrAuto[j] =
              options.enums === String
                ? $root.rti.RequestOCOOrder.OrderPlacement[
                    message.manualOrAuto[j]
                  ]
                : message.manualOrAuto[j];
        }
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          object.cancelAtSsboe = message.cancelAtSsboe;
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          object.cancelAtUsecs = message.cancelAtUsecs;
        return object;
      };

      /**
       * Converts this RequestOCOOrder to JSON.
       * @function toJSON
       * @memberof rti.RequestOCOOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestOCOOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestOCOOrder
       * @function getTypeUrl
       * @memberof rti.RequestOCOOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestOCOOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestOCOOrder';
      };

      /**
       * TransactionType enum.
       * @name rti.RequestOCOOrder.TransactionType
       * @enum {number}
       * @property {number} BUY=1 BUY value
       * @property {number} SELL=2 SELL value
       */
      RequestOCOOrder.TransactionType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY')] = 1;
        values[(valuesById[2] = 'SELL')] = 2;
        return values;
      })();

      /**
       * Duration enum.
       * @name rti.RequestOCOOrder.Duration
       * @enum {number}
       * @property {number} DAY=1 DAY value
       * @property {number} GTC=2 GTC value
       * @property {number} IOC=3 IOC value
       * @property {number} FOK=4 FOK value
       */
      RequestOCOOrder.Duration = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'DAY')] = 1;
        values[(valuesById[2] = 'GTC')] = 2;
        values[(valuesById[3] = 'IOC')] = 3;
        values[(valuesById[4] = 'FOK')] = 4;
        return values;
      })();

      /**
       * PriceType enum.
       * @name rti.RequestOCOOrder.PriceType
       * @enum {number}
       * @property {number} LIMIT=1 LIMIT value
       * @property {number} MARKET=2 MARKET value
       * @property {number} STOP_LIMIT=3 STOP_LIMIT value
       * @property {number} STOP_MARKET=4 STOP_MARKET value
       */
      RequestOCOOrder.PriceType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LIMIT')] = 1;
        values[(valuesById[2] = 'MARKET')] = 2;
        values[(valuesById[3] = 'STOP_LIMIT')] = 3;
        values[(valuesById[4] = 'STOP_MARKET')] = 4;
        return values;
      })();

      /**
       * OrderPlacement enum.
       * @name rti.RequestOCOOrder.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      RequestOCOOrder.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      return RequestOCOOrder;
    })();

    rti.ResponseOCOOrder = (function () {
      /**
       * Properties of a ResponseOCOOrder.
       * @memberof rti
       * @interface IResponseOCOOrder
       * @property {number} templateId ResponseOCOOrder templateId
       * @property {Array.<string>|null} [userMsg] ResponseOCOOrder userMsg
       * @property {Array.<string>|null} [userTag] ResponseOCOOrder userTag
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseOCOOrder rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseOCOOrder rpCode
       * @property {Array.<string>|null} [basketId] ResponseOCOOrder basketId
       * @property {Array.<number>|null} [ssboe] ResponseOCOOrder ssboe
       * @property {Array.<number>|null} [usecs] ResponseOCOOrder usecs
       */

      /**
       * Constructs a new ResponseOCOOrder.
       * @memberof rti
       * @classdesc Represents a ResponseOCOOrder.
       * @implements IResponseOCOOrder
       * @constructor
       * @param {rti.IResponseOCOOrder=} [properties] Properties to set
       */
      function ResponseOCOOrder(properties) {
        this.userMsg = [];
        this.userTag = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        this.basketId = [];
        this.ssboe = [];
        this.usecs = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseOCOOrder templateId.
       * @member {number} templateId
       * @memberof rti.ResponseOCOOrder
       * @instance
       */
      ResponseOCOOrder.prototype.templateId = 0;

      /**
       * ResponseOCOOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseOCOOrder
       * @instance
       */
      ResponseOCOOrder.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseOCOOrder userTag.
       * @member {Array.<string>} userTag
       * @memberof rti.ResponseOCOOrder
       * @instance
       */
      ResponseOCOOrder.prototype.userTag = $util.emptyArray;

      /**
       * ResponseOCOOrder rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseOCOOrder
       * @instance
       */
      ResponseOCOOrder.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseOCOOrder rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseOCOOrder
       * @instance
       */
      ResponseOCOOrder.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseOCOOrder basketId.
       * @member {Array.<string>} basketId
       * @memberof rti.ResponseOCOOrder
       * @instance
       */
      ResponseOCOOrder.prototype.basketId = $util.emptyArray;

      /**
       * ResponseOCOOrder ssboe.
       * @member {Array.<number>} ssboe
       * @memberof rti.ResponseOCOOrder
       * @instance
       */
      ResponseOCOOrder.prototype.ssboe = $util.emptyArray;

      /**
       * ResponseOCOOrder usecs.
       * @member {Array.<number>} usecs
       * @memberof rti.ResponseOCOOrder
       * @instance
       */
      ResponseOCOOrder.prototype.usecs = $util.emptyArray;

      /**
       * Creates a new ResponseOCOOrder instance using the specified properties.
       * @function create
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {rti.IResponseOCOOrder=} [properties] Properties to set
       * @returns {rti.ResponseOCOOrder} ResponseOCOOrder instance
       */
      ResponseOCOOrder.create = function create(properties) {
        return new ResponseOCOOrder(properties);
      };

      /**
       * Encodes the specified ResponseOCOOrder message. Does not implicitly {@link rti.ResponseOCOOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {rti.IResponseOCOOrder} message ResponseOCOOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseOCOOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.basketId != null && message.basketId.length)
          for (var i = 0; i < message.basketId.length; ++i)
            writer
              .uint32(/* id 110300, wireType 2 =*/ 882402)
              .string(message.basketId[i]);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (message.ssboe != null && message.ssboe.length)
          for (var i = 0; i < message.ssboe.length; ++i)
            writer
              .uint32(/* id 150100, wireType 0 =*/ 1200800)
              .int32(message.ssboe[i]);
        if (message.usecs != null && message.usecs.length)
          for (var i = 0; i < message.usecs.length; ++i)
            writer
              .uint32(/* id 150101, wireType 0 =*/ 1200808)
              .int32(message.usecs[i]);
        if (message.userTag != null && message.userTag.length)
          for (var i = 0; i < message.userTag.length; ++i)
            writer
              .uint32(/* id 154119, wireType 2 =*/ 1232954)
              .string(message.userTag[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseOCOOrder message, length delimited. Does not implicitly {@link rti.ResponseOCOOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {rti.IResponseOCOOrder} message ResponseOCOOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseOCOOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseOCOOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseOCOOrder} ResponseOCOOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseOCOOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseOCOOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154119:
              if (!(message.userTag && message.userTag.length))
                message.userTag = [];
              message.userTag.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110300:
              if (!(message.basketId && message.basketId.length))
                message.basketId = [];
              message.basketId.push(reader.string());
              break;
            case 150100:
              if (!(message.ssboe && message.ssboe.length)) message.ssboe = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.ssboe.push(reader.int32());
              } else message.ssboe.push(reader.int32());
              break;
            case 150101:
              if (!(message.usecs && message.usecs.length)) message.usecs = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.usecs.push(reader.int32());
              } else message.usecs.push(reader.int32());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseOCOOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseOCOOrder} ResponseOCOOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseOCOOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseOCOOrder message.
       * @function verify
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseOCOOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.userTag != null && message.hasOwnProperty('userTag')) {
          if (!Array.isArray(message.userTag)) return 'userTag: array expected';
          for (var i = 0; i < message.userTag.length; ++i)
            if (!$util.isString(message.userTag[i]))
              return 'userTag: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId')) {
          if (!Array.isArray(message.basketId))
            return 'basketId: array expected';
          for (var i = 0; i < message.basketId.length; ++i)
            if (!$util.isString(message.basketId[i]))
              return 'basketId: string[] expected';
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe')) {
          if (!Array.isArray(message.ssboe)) return 'ssboe: array expected';
          for (var i = 0; i < message.ssboe.length; ++i)
            if (!$util.isInteger(message.ssboe[i]))
              return 'ssboe: integer[] expected';
        }
        if (message.usecs != null && message.hasOwnProperty('usecs')) {
          if (!Array.isArray(message.usecs)) return 'usecs: array expected';
          for (var i = 0; i < message.usecs.length; ++i)
            if (!$util.isInteger(message.usecs[i]))
              return 'usecs: integer[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseOCOOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseOCOOrder} ResponseOCOOrder
       */
      ResponseOCOOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseOCOOrder) return object;
        var message = new $root.rti.ResponseOCOOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseOCOOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.userTag) {
          if (!Array.isArray(object.userTag))
            throw TypeError('.rti.ResponseOCOOrder.userTag: array expected');
          message.userTag = [];
          for (var i = 0; i < object.userTag.length; ++i)
            message.userTag[i] = String(object.userTag[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseOCOOrder.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseOCOOrder.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.basketId) {
          if (!Array.isArray(object.basketId))
            throw TypeError('.rti.ResponseOCOOrder.basketId: array expected');
          message.basketId = [];
          for (var i = 0; i < object.basketId.length; ++i)
            message.basketId[i] = String(object.basketId[i]);
        }
        if (object.ssboe) {
          if (!Array.isArray(object.ssboe))
            throw TypeError('.rti.ResponseOCOOrder.ssboe: array expected');
          message.ssboe = [];
          for (var i = 0; i < object.ssboe.length; ++i)
            message.ssboe[i] = object.ssboe[i] | 0;
        }
        if (object.usecs) {
          if (!Array.isArray(object.usecs))
            throw TypeError('.rti.ResponseOCOOrder.usecs: array expected');
          message.usecs = [];
          for (var i = 0; i < object.usecs.length; ++i)
            message.usecs[i] = object.usecs[i] | 0;
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseOCOOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {rti.ResponseOCOOrder} message ResponseOCOOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseOCOOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.basketId = [];
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
          object.ssboe = [];
          object.usecs = [];
          object.userTag = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.basketId && message.basketId.length) {
          object.basketId = [];
          for (var j = 0; j < message.basketId.length; ++j)
            object.basketId[j] = message.basketId[j];
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.ssboe && message.ssboe.length) {
          object.ssboe = [];
          for (var j = 0; j < message.ssboe.length; ++j)
            object.ssboe[j] = message.ssboe[j];
        }
        if (message.usecs && message.usecs.length) {
          object.usecs = [];
          for (var j = 0; j < message.usecs.length; ++j)
            object.usecs[j] = message.usecs[j];
        }
        if (message.userTag && message.userTag.length) {
          object.userTag = [];
          for (var j = 0; j < message.userTag.length; ++j)
            object.userTag[j] = message.userTag[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseOCOOrder to JSON.
       * @function toJSON
       * @memberof rti.ResponseOCOOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseOCOOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseOCOOrder
       * @function getTypeUrl
       * @memberof rti.ResponseOCOOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseOCOOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseOCOOrder';
      };

      return ResponseOCOOrder;
    })();

    rti.RequestBracketOrder = (function () {
      /**
       * Properties of a RequestBracketOrder.
       * @memberof rti
       * @interface IRequestBracketOrder
       * @property {number} templateId RequestBracketOrder templateId
       * @property {Array.<string>|null} [userMsg] RequestBracketOrder userMsg
       * @property {string|null} [userTag] RequestBracketOrder userTag
       * @property {string|null} [windowName] RequestBracketOrder windowName
       * @property {string|null} [fcmId] RequestBracketOrder fcmId
       * @property {string|null} [ibId] RequestBracketOrder ibId
       * @property {string|null} [accountId] RequestBracketOrder accountId
       * @property {string|null} [symbol] RequestBracketOrder symbol
       * @property {string|null} [exchange] RequestBracketOrder exchange
       * @property {number|null} [quantity] RequestBracketOrder quantity
       * @property {number|null} [price] RequestBracketOrder price
       * @property {number|null} [triggerPrice] RequestBracketOrder triggerPrice
       * @property {rti.RequestBracketOrder.TransactionType|null} [transactionType] RequestBracketOrder transactionType
       * @property {rti.RequestBracketOrder.Duration|null} [duration] RequestBracketOrder duration
       * @property {rti.RequestBracketOrder.PriceType|null} [priceType] RequestBracketOrder priceType
       * @property {string|null} [tradeRoute] RequestBracketOrder tradeRoute
       * @property {rti.RequestBracketOrder.OrderPlacement|null} [manualOrAuto] RequestBracketOrder manualOrAuto
       * @property {rti.RequestBracketOrder.UserType|null} [userType] RequestBracketOrder userType
       * @property {rti.RequestBracketOrder.BracketType|null} [bracketType] RequestBracketOrder bracketType
       * @property {number|null} [breakEvenTicks] RequestBracketOrder breakEvenTicks
       * @property {number|null} [breakEvenTriggerTicks] RequestBracketOrder breakEvenTriggerTicks
       * @property {number|null} [targetQuantity] RequestBracketOrder targetQuantity
       * @property {number|null} [targetTicks] RequestBracketOrder targetTicks
       * @property {number|null} [stopQuantity] RequestBracketOrder stopQuantity
       * @property {number|null} [stopTicks] RequestBracketOrder stopTicks
       * @property {number|null} [trailingStopTriggerTicks] RequestBracketOrder trailingStopTriggerTicks
       * @property {boolean|null} [trailingStopByLastTradePrice] RequestBracketOrder trailingStopByLastTradePrice
       * @property {boolean|null} [targetMarketOrderIfTouched] RequestBracketOrder targetMarketOrderIfTouched
       * @property {boolean|null} [stopMarketOnReject] RequestBracketOrder stopMarketOnReject
       * @property {number|null} [targetMarketAtSsboe] RequestBracketOrder targetMarketAtSsboe
       * @property {number|null} [targetMarketAtUsecs] RequestBracketOrder targetMarketAtUsecs
       * @property {number|null} [stopMarketAtSsboe] RequestBracketOrder stopMarketAtSsboe
       * @property {number|null} [stopMarketAtUsecs] RequestBracketOrder stopMarketAtUsecs
       * @property {number|null} [targetMarketOrderAfterSecs] RequestBracketOrder targetMarketOrderAfterSecs
       * @property {number|null} [releaseAtSsboe] RequestBracketOrder releaseAtSsboe
       * @property {number|null} [releaseAtUsecs] RequestBracketOrder releaseAtUsecs
       * @property {number|null} [cancelAtSsboe] RequestBracketOrder cancelAtSsboe
       * @property {number|null} [cancelAtUsecs] RequestBracketOrder cancelAtUsecs
       * @property {number|null} [cancelAfterSecs] RequestBracketOrder cancelAfterSecs
       * @property {string|null} [ifTouchedSymbol] RequestBracketOrder ifTouchedSymbol
       * @property {string|null} [ifTouchedExchange] RequestBracketOrder ifTouchedExchange
       * @property {rti.RequestBracketOrder.Condition|null} [ifTouchedCondition] RequestBracketOrder ifTouchedCondition
       * @property {rti.RequestBracketOrder.PriceField|null} [ifTouchedPriceField] RequestBracketOrder ifTouchedPriceField
       * @property {number|null} [ifTouchedPrice] RequestBracketOrder ifTouchedPrice
       */

      /**
       * Constructs a new RequestBracketOrder.
       * @memberof rti
       * @classdesc Represents a RequestBracketOrder.
       * @implements IRequestBracketOrder
       * @constructor
       * @param {rti.IRequestBracketOrder=} [properties] Properties to set
       */
      function RequestBracketOrder(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestBracketOrder templateId.
       * @member {number} templateId
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.templateId = 0;

      /**
       * RequestBracketOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.userMsg = $util.emptyArray;

      /**
       * RequestBracketOrder userTag.
       * @member {string} userTag
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.userTag = '';

      /**
       * RequestBracketOrder windowName.
       * @member {string} windowName
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.windowName = '';

      /**
       * RequestBracketOrder fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.fcmId = '';

      /**
       * RequestBracketOrder ibId.
       * @member {string} ibId
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.ibId = '';

      /**
       * RequestBracketOrder accountId.
       * @member {string} accountId
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.accountId = '';

      /**
       * RequestBracketOrder symbol.
       * @member {string} symbol
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.symbol = '';

      /**
       * RequestBracketOrder exchange.
       * @member {string} exchange
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.exchange = '';

      /**
       * RequestBracketOrder quantity.
       * @member {number} quantity
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.quantity = 0;

      /**
       * RequestBracketOrder price.
       * @member {number} price
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.price = 0;

      /**
       * RequestBracketOrder triggerPrice.
       * @member {number} triggerPrice
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.triggerPrice = 0;

      /**
       * RequestBracketOrder transactionType.
       * @member {rti.RequestBracketOrder.TransactionType} transactionType
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.transactionType = 1;

      /**
       * RequestBracketOrder duration.
       * @member {rti.RequestBracketOrder.Duration} duration
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.duration = 1;

      /**
       * RequestBracketOrder priceType.
       * @member {rti.RequestBracketOrder.PriceType} priceType
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.priceType = 1;

      /**
       * RequestBracketOrder tradeRoute.
       * @member {string} tradeRoute
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.tradeRoute = '';

      /**
       * RequestBracketOrder manualOrAuto.
       * @member {rti.RequestBracketOrder.OrderPlacement} manualOrAuto
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.manualOrAuto = 1;

      /**
       * RequestBracketOrder userType.
       * @member {rti.RequestBracketOrder.UserType} userType
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.userType = 0;

      /**
       * RequestBracketOrder bracketType.
       * @member {rti.RequestBracketOrder.BracketType} bracketType
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.bracketType = 1;

      /**
       * RequestBracketOrder breakEvenTicks.
       * @member {number} breakEvenTicks
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.breakEvenTicks = 0;

      /**
       * RequestBracketOrder breakEvenTriggerTicks.
       * @member {number} breakEvenTriggerTicks
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.breakEvenTriggerTicks = 0;

      /**
       * RequestBracketOrder targetQuantity.
       * @member {number} targetQuantity
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.targetQuantity = 0;

      /**
       * RequestBracketOrder targetTicks.
       * @member {number} targetTicks
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.targetTicks = 0;

      /**
       * RequestBracketOrder stopQuantity.
       * @member {number} stopQuantity
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.stopQuantity = 0;

      /**
       * RequestBracketOrder stopTicks.
       * @member {number} stopTicks
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.stopTicks = 0;

      /**
       * RequestBracketOrder trailingStopTriggerTicks.
       * @member {number} trailingStopTriggerTicks
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.trailingStopTriggerTicks = 0;

      /**
       * RequestBracketOrder trailingStopByLastTradePrice.
       * @member {boolean} trailingStopByLastTradePrice
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.trailingStopByLastTradePrice = false;

      /**
       * RequestBracketOrder targetMarketOrderIfTouched.
       * @member {boolean} targetMarketOrderIfTouched
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.targetMarketOrderIfTouched = false;

      /**
       * RequestBracketOrder stopMarketOnReject.
       * @member {boolean} stopMarketOnReject
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.stopMarketOnReject = false;

      /**
       * RequestBracketOrder targetMarketAtSsboe.
       * @member {number} targetMarketAtSsboe
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.targetMarketAtSsboe = 0;

      /**
       * RequestBracketOrder targetMarketAtUsecs.
       * @member {number} targetMarketAtUsecs
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.targetMarketAtUsecs = 0;

      /**
       * RequestBracketOrder stopMarketAtSsboe.
       * @member {number} stopMarketAtSsboe
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.stopMarketAtSsboe = 0;

      /**
       * RequestBracketOrder stopMarketAtUsecs.
       * @member {number} stopMarketAtUsecs
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.stopMarketAtUsecs = 0;

      /**
       * RequestBracketOrder targetMarketOrderAfterSecs.
       * @member {number} targetMarketOrderAfterSecs
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.targetMarketOrderAfterSecs = 0;

      /**
       * RequestBracketOrder releaseAtSsboe.
       * @member {number} releaseAtSsboe
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.releaseAtSsboe = 0;

      /**
       * RequestBracketOrder releaseAtUsecs.
       * @member {number} releaseAtUsecs
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.releaseAtUsecs = 0;

      /**
       * RequestBracketOrder cancelAtSsboe.
       * @member {number} cancelAtSsboe
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.cancelAtSsboe = 0;

      /**
       * RequestBracketOrder cancelAtUsecs.
       * @member {number} cancelAtUsecs
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.cancelAtUsecs = 0;

      /**
       * RequestBracketOrder cancelAfterSecs.
       * @member {number} cancelAfterSecs
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.cancelAfterSecs = 0;

      /**
       * RequestBracketOrder ifTouchedSymbol.
       * @member {string} ifTouchedSymbol
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.ifTouchedSymbol = '';

      /**
       * RequestBracketOrder ifTouchedExchange.
       * @member {string} ifTouchedExchange
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.ifTouchedExchange = '';

      /**
       * RequestBracketOrder ifTouchedCondition.
       * @member {rti.RequestBracketOrder.Condition} ifTouchedCondition
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.ifTouchedCondition = 1;

      /**
       * RequestBracketOrder ifTouchedPriceField.
       * @member {rti.RequestBracketOrder.PriceField} ifTouchedPriceField
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.ifTouchedPriceField = 1;

      /**
       * RequestBracketOrder ifTouchedPrice.
       * @member {number} ifTouchedPrice
       * @memberof rti.RequestBracketOrder
       * @instance
       */
      RequestBracketOrder.prototype.ifTouchedPrice = 0;

      /**
       * Creates a new RequestBracketOrder instance using the specified properties.
       * @function create
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {rti.IRequestBracketOrder=} [properties] Properties to set
       * @returns {rti.RequestBracketOrder} RequestBracketOrder instance
       */
      RequestBracketOrder.create = function create(properties) {
        return new RequestBracketOrder(properties);
      };

      /**
       * Encodes the specified RequestBracketOrder message. Does not implicitly {@link rti.RequestBracketOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {rti.IRequestBracketOrder} message RequestBracketOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestBracketOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.price != null &&
          Object.hasOwnProperty.call(message, 'price')
        )
          writer
            .uint32(/* id 110306, wireType 1 =*/ 882449)
            .double(message.price);
        if (
          message.transactionType != null &&
          Object.hasOwnProperty.call(message, 'transactionType')
        )
          writer
            .uint32(/* id 112003, wireType 0 =*/ 896024)
            .int32(message.transactionType);
        if (
          message.quantity != null &&
          Object.hasOwnProperty.call(message, 'quantity')
        )
          writer
            .uint32(/* id 112004, wireType 0 =*/ 896032)
            .int32(message.quantity);
        if (
          message.duration != null &&
          Object.hasOwnProperty.call(message, 'duration')
        )
          writer
            .uint32(/* id 112005, wireType 0 =*/ 896040)
            .int32(message.duration);
        if (
          message.priceType != null &&
          Object.hasOwnProperty.call(message, 'priceType')
        )
          writer
            .uint32(/* id 112008, wireType 0 =*/ 896064)
            .int32(message.priceType);
        if (
          message.tradeRoute != null &&
          Object.hasOwnProperty.call(message, 'tradeRoute')
        )
          writer
            .uint32(/* id 112016, wireType 2 =*/ 896130)
            .string(message.tradeRoute);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.triggerPrice != null &&
          Object.hasOwnProperty.call(message, 'triggerPrice')
        )
          writer
            .uint32(/* id 149247, wireType 1 =*/ 1193977)
            .double(message.triggerPrice);
        if (
          message.ifTouchedPrice != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedPrice')
        )
          writer
            .uint32(/* id 153632, wireType 1 =*/ 1229057)
            .double(message.ifTouchedPrice);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.userType != null &&
          Object.hasOwnProperty.call(message, 'userType')
        )
          writer
            .uint32(/* id 154036, wireType 0 =*/ 1232288)
            .int32(message.userType);
        if (
          message.userTag != null &&
          Object.hasOwnProperty.call(message, 'userTag')
        )
          writer
            .uint32(/* id 154119, wireType 2 =*/ 1232954)
            .string(message.userTag);
        if (
          message.ifTouchedSymbol != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedSymbol')
        )
          writer
            .uint32(/* id 154451, wireType 2 =*/ 1235610)
            .string(message.ifTouchedSymbol);
        if (
          message.ifTouchedExchange != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedExchange')
        )
          writer
            .uint32(/* id 154452, wireType 2 =*/ 1235618)
            .string(message.ifTouchedExchange);
        if (
          message.ifTouchedCondition != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedCondition')
        )
          writer
            .uint32(/* id 154453, wireType 0 =*/ 1235624)
            .int32(message.ifTouchedCondition);
        if (
          message.ifTouchedPriceField != null &&
          Object.hasOwnProperty.call(message, 'ifTouchedPriceField')
        )
          writer
            .uint32(/* id 154454, wireType 0 =*/ 1235632)
            .int32(message.ifTouchedPriceField);
        if (
          message.targetTicks != null &&
          Object.hasOwnProperty.call(message, 'targetTicks')
        )
          writer
            .uint32(/* id 154456, wireType 0 =*/ 1235648)
            .int32(message.targetTicks);
        if (
          message.targetQuantity != null &&
          Object.hasOwnProperty.call(message, 'targetQuantity')
        )
          writer
            .uint32(/* id 154457, wireType 0 =*/ 1235656)
            .int32(message.targetQuantity);
        if (
          message.stopTicks != null &&
          Object.hasOwnProperty.call(message, 'stopTicks')
        )
          writer
            .uint32(/* id 154458, wireType 0 =*/ 1235664)
            .int32(message.stopTicks);
        if (
          message.stopQuantity != null &&
          Object.hasOwnProperty.call(message, 'stopQuantity')
        )
          writer
            .uint32(/* id 154459, wireType 0 =*/ 1235672)
            .int32(message.stopQuantity);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.releaseAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'releaseAtSsboe')
        )
          writer
            .uint32(/* id 154487, wireType 0 =*/ 1235896)
            .int32(message.releaseAtSsboe);
        if (
          message.cancelAfterSecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAfterSecs')
        )
          writer
            .uint32(/* id 154488, wireType 0 =*/ 1235904)
            .int32(message.cancelAfterSecs);
        if (
          message.releaseAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'releaseAtUsecs')
        )
          writer
            .uint32(/* id 154549, wireType 0 =*/ 1236392)
            .int32(message.releaseAtUsecs);
        if (
          message.windowName != null &&
          Object.hasOwnProperty.call(message, 'windowName')
        )
          writer
            .uint32(/* id 154629, wireType 2 =*/ 1237034)
            .string(message.windowName);
        if (
          message.manualOrAuto != null &&
          Object.hasOwnProperty.call(message, 'manualOrAuto')
        )
          writer
            .uint32(/* id 154710, wireType 0 =*/ 1237680)
            .int32(message.manualOrAuto);
        if (
          message.stopMarketOnReject != null &&
          Object.hasOwnProperty.call(message, 'stopMarketOnReject')
        )
          writer
            .uint32(/* id 154857, wireType 0 =*/ 1238856)
            .bool(message.stopMarketOnReject);
        if (
          message.trailingStopByLastTradePrice != null &&
          Object.hasOwnProperty.call(message, 'trailingStopByLastTradePrice')
        )
          writer
            .uint32(/* id 157062, wireType 0 =*/ 1256496)
            .bool(message.trailingStopByLastTradePrice);
        if (
          message.cancelAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'cancelAtSsboe')
        )
          writer
            .uint32(/* id 157085, wireType 0 =*/ 1256680)
            .int32(message.cancelAtSsboe);
        if (
          message.cancelAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAtUsecs')
        )
          writer
            .uint32(/* id 157086, wireType 0 =*/ 1256688)
            .int32(message.cancelAtUsecs);
        if (
          message.bracketType != null &&
          Object.hasOwnProperty.call(message, 'bracketType')
        )
          writer
            .uint32(/* id 157087, wireType 0 =*/ 1256696)
            .int32(message.bracketType);
        if (
          message.trailingStopTriggerTicks != null &&
          Object.hasOwnProperty.call(message, 'trailingStopTriggerTicks')
        )
          writer
            .uint32(/* id 157124, wireType 0 =*/ 1256992)
            .int32(message.trailingStopTriggerTicks);
        if (
          message.targetMarketAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'targetMarketAtSsboe')
        )
          writer
            .uint32(/* id 157145, wireType 0 =*/ 1257160)
            .int32(message.targetMarketAtSsboe);
        if (
          message.targetMarketAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'targetMarketAtUsecs')
        )
          writer
            .uint32(/* id 157146, wireType 0 =*/ 1257168)
            .int32(message.targetMarketAtUsecs);
        if (
          message.stopMarketAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'stopMarketAtSsboe')
        )
          writer
            .uint32(/* id 157147, wireType 0 =*/ 1257176)
            .int32(message.stopMarketAtSsboe);
        if (
          message.stopMarketAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'stopMarketAtUsecs')
        )
          writer
            .uint32(/* id 157148, wireType 0 =*/ 1257184)
            .int32(message.stopMarketAtUsecs);
        if (
          message.targetMarketOrderAfterSecs != null &&
          Object.hasOwnProperty.call(message, 'targetMarketOrderAfterSecs')
        )
          writer
            .uint32(/* id 157149, wireType 0 =*/ 1257192)
            .int32(message.targetMarketOrderAfterSecs);
        if (
          message.targetMarketOrderIfTouched != null &&
          Object.hasOwnProperty.call(message, 'targetMarketOrderIfTouched')
        )
          writer
            .uint32(/* id 157151, wireType 0 =*/ 1257208)
            .bool(message.targetMarketOrderIfTouched);
        if (
          message.breakEvenTicks != null &&
          Object.hasOwnProperty.call(message, 'breakEvenTicks')
        )
          writer
            .uint32(/* id 157170, wireType 0 =*/ 1257360)
            .int32(message.breakEvenTicks);
        if (
          message.breakEvenTriggerTicks != null &&
          Object.hasOwnProperty.call(message, 'breakEvenTriggerTicks')
        )
          writer
            .uint32(/* id 157172, wireType 0 =*/ 1257376)
            .int32(message.breakEvenTriggerTicks);
        return writer;
      };

      /**
       * Encodes the specified RequestBracketOrder message, length delimited. Does not implicitly {@link rti.RequestBracketOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {rti.IRequestBracketOrder} message RequestBracketOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestBracketOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestBracketOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestBracketOrder} RequestBracketOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestBracketOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestBracketOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154119:
              message.userTag = reader.string();
              break;
            case 154629:
              message.windowName = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 112004:
              message.quantity = reader.int32();
              break;
            case 110306:
              message.price = reader.double();
              break;
            case 149247:
              message.triggerPrice = reader.double();
              break;
            case 112003:
              message.transactionType = reader.int32();
              break;
            case 112005:
              message.duration = reader.int32();
              break;
            case 112008:
              message.priceType = reader.int32();
              break;
            case 112016:
              message.tradeRoute = reader.string();
              break;
            case 154710:
              message.manualOrAuto = reader.int32();
              break;
            case 154036:
              message.userType = reader.int32();
              break;
            case 157087:
              message.bracketType = reader.int32();
              break;
            case 157170:
              message.breakEvenTicks = reader.int32();
              break;
            case 157172:
              message.breakEvenTriggerTicks = reader.int32();
              break;
            case 154457:
              message.targetQuantity = reader.int32();
              break;
            case 154456:
              message.targetTicks = reader.int32();
              break;
            case 154459:
              message.stopQuantity = reader.int32();
              break;
            case 154458:
              message.stopTicks = reader.int32();
              break;
            case 157124:
              message.trailingStopTriggerTicks = reader.int32();
              break;
            case 157062:
              message.trailingStopByLastTradePrice = reader.bool();
              break;
            case 157151:
              message.targetMarketOrderIfTouched = reader.bool();
              break;
            case 154857:
              message.stopMarketOnReject = reader.bool();
              break;
            case 157145:
              message.targetMarketAtSsboe = reader.int32();
              break;
            case 157146:
              message.targetMarketAtUsecs = reader.int32();
              break;
            case 157147:
              message.stopMarketAtSsboe = reader.int32();
              break;
            case 157148:
              message.stopMarketAtUsecs = reader.int32();
              break;
            case 157149:
              message.targetMarketOrderAfterSecs = reader.int32();
              break;
            case 154487:
              message.releaseAtSsboe = reader.int32();
              break;
            case 154549:
              message.releaseAtUsecs = reader.int32();
              break;
            case 157085:
              message.cancelAtSsboe = reader.int32();
              break;
            case 157086:
              message.cancelAtUsecs = reader.int32();
              break;
            case 154488:
              message.cancelAfterSecs = reader.int32();
              break;
            case 154451:
              message.ifTouchedSymbol = reader.string();
              break;
            case 154452:
              message.ifTouchedExchange = reader.string();
              break;
            case 154453:
              message.ifTouchedCondition = reader.int32();
              break;
            case 154454:
              message.ifTouchedPriceField = reader.int32();
              break;
            case 153632:
              message.ifTouchedPrice = reader.double();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestBracketOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestBracketOrder} RequestBracketOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestBracketOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestBracketOrder message.
       * @function verify
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestBracketOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          if (!$util.isString(message.userTag))
            return 'userTag: string expected';
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          if (!$util.isString(message.windowName))
            return 'windowName: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          if (!$util.isInteger(message.quantity))
            return 'quantity: integer expected';
        if (message.price != null && message.hasOwnProperty('price'))
          if (typeof message.price !== 'number')
            return 'price: number expected';
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          if (typeof message.triggerPrice !== 'number')
            return 'triggerPrice: number expected';
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        )
          switch (message.transactionType) {
            default:
              return 'transactionType: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.duration != null && message.hasOwnProperty('duration'))
          switch (message.duration) {
            default:
              return 'duration: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          switch (message.priceType) {
            default:
              return 'priceType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          if (!$util.isString(message.tradeRoute))
            return 'tradeRoute: string expected';
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          switch (message.manualOrAuto) {
            default:
              return 'manualOrAuto: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.userType != null && message.hasOwnProperty('userType'))
          switch (message.userType) {
            default:
              return 'userType: enum value expected';
            case 0:
            case 1:
            case 2:
            case 3:
              break;
          }
        if (
          message.bracketType != null &&
          message.hasOwnProperty('bracketType')
        )
          switch (message.bracketType) {
            default:
              return 'bracketType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (
          message.breakEvenTicks != null &&
          message.hasOwnProperty('breakEvenTicks')
        )
          if (!$util.isInteger(message.breakEvenTicks))
            return 'breakEvenTicks: integer expected';
        if (
          message.breakEvenTriggerTicks != null &&
          message.hasOwnProperty('breakEvenTriggerTicks')
        )
          if (!$util.isInteger(message.breakEvenTriggerTicks))
            return 'breakEvenTriggerTicks: integer expected';
        if (
          message.targetQuantity != null &&
          message.hasOwnProperty('targetQuantity')
        )
          if (!$util.isInteger(message.targetQuantity))
            return 'targetQuantity: integer expected';
        if (
          message.targetTicks != null &&
          message.hasOwnProperty('targetTicks')
        )
          if (!$util.isInteger(message.targetTicks))
            return 'targetTicks: integer expected';
        if (
          message.stopQuantity != null &&
          message.hasOwnProperty('stopQuantity')
        )
          if (!$util.isInteger(message.stopQuantity))
            return 'stopQuantity: integer expected';
        if (message.stopTicks != null && message.hasOwnProperty('stopTicks'))
          if (!$util.isInteger(message.stopTicks))
            return 'stopTicks: integer expected';
        if (
          message.trailingStopTriggerTicks != null &&
          message.hasOwnProperty('trailingStopTriggerTicks')
        )
          if (!$util.isInteger(message.trailingStopTriggerTicks))
            return 'trailingStopTriggerTicks: integer expected';
        if (
          message.trailingStopByLastTradePrice != null &&
          message.hasOwnProperty('trailingStopByLastTradePrice')
        )
          if (typeof message.trailingStopByLastTradePrice !== 'boolean')
            return 'trailingStopByLastTradePrice: boolean expected';
        if (
          message.targetMarketOrderIfTouched != null &&
          message.hasOwnProperty('targetMarketOrderIfTouched')
        )
          if (typeof message.targetMarketOrderIfTouched !== 'boolean')
            return 'targetMarketOrderIfTouched: boolean expected';
        if (
          message.stopMarketOnReject != null &&
          message.hasOwnProperty('stopMarketOnReject')
        )
          if (typeof message.stopMarketOnReject !== 'boolean')
            return 'stopMarketOnReject: boolean expected';
        if (
          message.targetMarketAtSsboe != null &&
          message.hasOwnProperty('targetMarketAtSsboe')
        )
          if (!$util.isInteger(message.targetMarketAtSsboe))
            return 'targetMarketAtSsboe: integer expected';
        if (
          message.targetMarketAtUsecs != null &&
          message.hasOwnProperty('targetMarketAtUsecs')
        )
          if (!$util.isInteger(message.targetMarketAtUsecs))
            return 'targetMarketAtUsecs: integer expected';
        if (
          message.stopMarketAtSsboe != null &&
          message.hasOwnProperty('stopMarketAtSsboe')
        )
          if (!$util.isInteger(message.stopMarketAtSsboe))
            return 'stopMarketAtSsboe: integer expected';
        if (
          message.stopMarketAtUsecs != null &&
          message.hasOwnProperty('stopMarketAtUsecs')
        )
          if (!$util.isInteger(message.stopMarketAtUsecs))
            return 'stopMarketAtUsecs: integer expected';
        if (
          message.targetMarketOrderAfterSecs != null &&
          message.hasOwnProperty('targetMarketOrderAfterSecs')
        )
          if (!$util.isInteger(message.targetMarketOrderAfterSecs))
            return 'targetMarketOrderAfterSecs: integer expected';
        if (
          message.releaseAtSsboe != null &&
          message.hasOwnProperty('releaseAtSsboe')
        )
          if (!$util.isInteger(message.releaseAtSsboe))
            return 'releaseAtSsboe: integer expected';
        if (
          message.releaseAtUsecs != null &&
          message.hasOwnProperty('releaseAtUsecs')
        )
          if (!$util.isInteger(message.releaseAtUsecs))
            return 'releaseAtUsecs: integer expected';
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          if (!$util.isInteger(message.cancelAtSsboe))
            return 'cancelAtSsboe: integer expected';
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          if (!$util.isInteger(message.cancelAtUsecs))
            return 'cancelAtUsecs: integer expected';
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          if (!$util.isInteger(message.cancelAfterSecs))
            return 'cancelAfterSecs: integer expected';
        if (
          message.ifTouchedSymbol != null &&
          message.hasOwnProperty('ifTouchedSymbol')
        )
          if (!$util.isString(message.ifTouchedSymbol))
            return 'ifTouchedSymbol: string expected';
        if (
          message.ifTouchedExchange != null &&
          message.hasOwnProperty('ifTouchedExchange')
        )
          if (!$util.isString(message.ifTouchedExchange))
            return 'ifTouchedExchange: string expected';
        if (
          message.ifTouchedCondition != null &&
          message.hasOwnProperty('ifTouchedCondition')
        )
          switch (message.ifTouchedCondition) {
            default:
              return 'ifTouchedCondition: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (
          message.ifTouchedPriceField != null &&
          message.hasOwnProperty('ifTouchedPriceField')
        )
          switch (message.ifTouchedPriceField) {
            default:
              return 'ifTouchedPriceField: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.ifTouchedPrice != null &&
          message.hasOwnProperty('ifTouchedPrice')
        )
          if (typeof message.ifTouchedPrice !== 'number')
            return 'ifTouchedPrice: number expected';
        return null;
      };

      /**
       * Creates a RequestBracketOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestBracketOrder} RequestBracketOrder
       */
      RequestBracketOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestBracketOrder) return object;
        var message = new $root.rti.RequestBracketOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestBracketOrder.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.userTag != null) message.userTag = String(object.userTag);
        if (object.windowName != null)
          message.windowName = String(object.windowName);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.quantity != null) message.quantity = object.quantity | 0;
        if (object.price != null) message.price = Number(object.price);
        if (object.triggerPrice != null)
          message.triggerPrice = Number(object.triggerPrice);
        switch (object.transactionType) {
          case 'BUY':
          case 1:
            message.transactionType = 1;
            break;
          case 'SELL':
          case 2:
            message.transactionType = 2;
            break;
        }
        switch (object.duration) {
          case 'DAY':
          case 1:
            message.duration = 1;
            break;
          case 'GTC':
          case 2:
            message.duration = 2;
            break;
          case 'IOC':
          case 3:
            message.duration = 3;
            break;
          case 'FOK':
          case 4:
            message.duration = 4;
            break;
        }
        switch (object.priceType) {
          case 'LIMIT':
          case 1:
            message.priceType = 1;
            break;
          case 'MARKET':
          case 2:
            message.priceType = 2;
            break;
          case 'STOP_LIMIT':
          case 3:
            message.priceType = 3;
            break;
          case 'STOP_MARKET':
          case 4:
            message.priceType = 4;
            break;
          case 'MARKET_IF_TOUCHED':
          case 5:
            message.priceType = 5;
            break;
          case 'LIMIT_IF_TOUCHED':
          case 6:
            message.priceType = 6;
            break;
        }
        if (object.tradeRoute != null)
          message.tradeRoute = String(object.tradeRoute);
        switch (object.manualOrAuto) {
          case 'MANUAL':
          case 1:
            message.manualOrAuto = 1;
            break;
          case 'AUTO':
          case 2:
            message.manualOrAuto = 2;
            break;
        }
        switch (object.userType) {
          case 'USER_TYPE_ADMIN':
          case 0:
            message.userType = 0;
            break;
          case 'USER_TYPE_FCM':
          case 1:
            message.userType = 1;
            break;
          case 'USER_TYPE_IB':
          case 2:
            message.userType = 2;
            break;
          case 'USER_TYPE_TRADER':
          case 3:
            message.userType = 3;
            break;
        }
        switch (object.bracketType) {
          case 'STOP_ONLY':
          case 1:
            message.bracketType = 1;
            break;
          case 'TARGET_ONLY':
          case 2:
            message.bracketType = 2;
            break;
          case 'TARGET_AND_STOP':
          case 3:
            message.bracketType = 3;
            break;
          case 'STOP_ONLY_STATIC':
          case 4:
            message.bracketType = 4;
            break;
          case 'TARGET_ONLY_STATIC':
          case 5:
            message.bracketType = 5;
            break;
          case 'TARGET_AND_STOP_STATIC':
          case 6:
            message.bracketType = 6;
            break;
        }
        if (object.breakEvenTicks != null)
          message.breakEvenTicks = object.breakEvenTicks | 0;
        if (object.breakEvenTriggerTicks != null)
          message.breakEvenTriggerTicks = object.breakEvenTriggerTicks | 0;
        if (object.targetQuantity != null)
          message.targetQuantity = object.targetQuantity | 0;
        if (object.targetTicks != null)
          message.targetTicks = object.targetTicks | 0;
        if (object.stopQuantity != null)
          message.stopQuantity = object.stopQuantity | 0;
        if (object.stopTicks != null) message.stopTicks = object.stopTicks | 0;
        if (object.trailingStopTriggerTicks != null)
          message.trailingStopTriggerTicks =
            object.trailingStopTriggerTicks | 0;
        if (object.trailingStopByLastTradePrice != null)
          message.trailingStopByLastTradePrice = Boolean(
            object.trailingStopByLastTradePrice,
          );
        if (object.targetMarketOrderIfTouched != null)
          message.targetMarketOrderIfTouched = Boolean(
            object.targetMarketOrderIfTouched,
          );
        if (object.stopMarketOnReject != null)
          message.stopMarketOnReject = Boolean(object.stopMarketOnReject);
        if (object.targetMarketAtSsboe != null)
          message.targetMarketAtSsboe = object.targetMarketAtSsboe | 0;
        if (object.targetMarketAtUsecs != null)
          message.targetMarketAtUsecs = object.targetMarketAtUsecs | 0;
        if (object.stopMarketAtSsboe != null)
          message.stopMarketAtSsboe = object.stopMarketAtSsboe | 0;
        if (object.stopMarketAtUsecs != null)
          message.stopMarketAtUsecs = object.stopMarketAtUsecs | 0;
        if (object.targetMarketOrderAfterSecs != null)
          message.targetMarketOrderAfterSecs =
            object.targetMarketOrderAfterSecs | 0;
        if (object.releaseAtSsboe != null)
          message.releaseAtSsboe = object.releaseAtSsboe | 0;
        if (object.releaseAtUsecs != null)
          message.releaseAtUsecs = object.releaseAtUsecs | 0;
        if (object.cancelAtSsboe != null)
          message.cancelAtSsboe = object.cancelAtSsboe | 0;
        if (object.cancelAtUsecs != null)
          message.cancelAtUsecs = object.cancelAtUsecs | 0;
        if (object.cancelAfterSecs != null)
          message.cancelAfterSecs = object.cancelAfterSecs | 0;
        if (object.ifTouchedSymbol != null)
          message.ifTouchedSymbol = String(object.ifTouchedSymbol);
        if (object.ifTouchedExchange != null)
          message.ifTouchedExchange = String(object.ifTouchedExchange);
        switch (object.ifTouchedCondition) {
          case 'EQUAL_TO':
          case 1:
            message.ifTouchedCondition = 1;
            break;
          case 'NOT_EQUAL_TO':
          case 2:
            message.ifTouchedCondition = 2;
            break;
          case 'GREATER_THAN':
          case 3:
            message.ifTouchedCondition = 3;
            break;
          case 'GREATER_THAN_EQUAL_TO':
          case 4:
            message.ifTouchedCondition = 4;
            break;
          case 'LESSER_THAN':
          case 5:
            message.ifTouchedCondition = 5;
            break;
          case 'LESSER_THAN_EQUAL_TO':
          case 6:
            message.ifTouchedCondition = 6;
            break;
        }
        switch (object.ifTouchedPriceField) {
          case 'BID_PRICE':
          case 1:
            message.ifTouchedPriceField = 1;
            break;
          case 'OFFER_PRICE':
          case 2:
            message.ifTouchedPriceField = 2;
            break;
          case 'TRADE_PRICE':
          case 3:
            message.ifTouchedPriceField = 3;
            break;
          case 'LEAN_PRICE':
          case 4:
            message.ifTouchedPriceField = 4;
            break;
        }
        if (object.ifTouchedPrice != null)
          message.ifTouchedPrice = Number(object.ifTouchedPrice);
        return message;
      };

      /**
       * Creates a plain object from a RequestBracketOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {rti.RequestBracketOrder} message RequestBracketOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestBracketOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.price = 0;
          object.transactionType = options.enums === String ? 'BUY' : 1;
          object.quantity = 0;
          object.duration = options.enums === String ? 'DAY' : 1;
          object.priceType = options.enums === String ? 'LIMIT' : 1;
          object.tradeRoute = '';
          object.triggerPrice = 0;
          object.ifTouchedPrice = 0;
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.userType = options.enums === String ? 'USER_TYPE_ADMIN' : 0;
          object.userTag = '';
          object.ifTouchedSymbol = '';
          object.ifTouchedExchange = '';
          object.ifTouchedCondition = options.enums === String ? 'EQUAL_TO' : 1;
          object.ifTouchedPriceField =
            options.enums === String ? 'BID_PRICE' : 1;
          object.targetTicks = 0;
          object.targetQuantity = 0;
          object.stopTicks = 0;
          object.stopQuantity = 0;
          object.templateId = 0;
          object.releaseAtSsboe = 0;
          object.cancelAfterSecs = 0;
          object.releaseAtUsecs = 0;
          object.windowName = '';
          object.manualOrAuto = options.enums === String ? 'MANUAL' : 1;
          object.stopMarketOnReject = false;
          object.trailingStopByLastTradePrice = false;
          object.cancelAtSsboe = 0;
          object.cancelAtUsecs = 0;
          object.bracketType = options.enums === String ? 'STOP_ONLY' : 1;
          object.trailingStopTriggerTicks = 0;
          object.targetMarketAtSsboe = 0;
          object.targetMarketAtUsecs = 0;
          object.stopMarketAtSsboe = 0;
          object.stopMarketAtUsecs = 0;
          object.targetMarketOrderAfterSecs = 0;
          object.targetMarketOrderIfTouched = false;
          object.breakEvenTicks = 0;
          object.breakEvenTriggerTicks = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.price != null && message.hasOwnProperty('price'))
          object.price =
            options.json && !isFinite(message.price)
              ? String(message.price)
              : message.price;
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        )
          object.transactionType =
            options.enums === String
              ? $root.rti.RequestBracketOrder.TransactionType[
                  message.transactionType
                ]
              : message.transactionType;
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          object.quantity = message.quantity;
        if (message.duration != null && message.hasOwnProperty('duration'))
          object.duration =
            options.enums === String
              ? $root.rti.RequestBracketOrder.Duration[message.duration]
              : message.duration;
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          object.priceType =
            options.enums === String
              ? $root.rti.RequestBracketOrder.PriceType[message.priceType]
              : message.priceType;
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          object.tradeRoute = message.tradeRoute;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          object.triggerPrice =
            options.json && !isFinite(message.triggerPrice)
              ? String(message.triggerPrice)
              : message.triggerPrice;
        if (
          message.ifTouchedPrice != null &&
          message.hasOwnProperty('ifTouchedPrice')
        )
          object.ifTouchedPrice =
            options.json && !isFinite(message.ifTouchedPrice)
              ? String(message.ifTouchedPrice)
              : message.ifTouchedPrice;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.userType != null && message.hasOwnProperty('userType'))
          object.userType =
            options.enums === String
              ? $root.rti.RequestBracketOrder.UserType[message.userType]
              : message.userType;
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          object.userTag = message.userTag;
        if (
          message.ifTouchedSymbol != null &&
          message.hasOwnProperty('ifTouchedSymbol')
        )
          object.ifTouchedSymbol = message.ifTouchedSymbol;
        if (
          message.ifTouchedExchange != null &&
          message.hasOwnProperty('ifTouchedExchange')
        )
          object.ifTouchedExchange = message.ifTouchedExchange;
        if (
          message.ifTouchedCondition != null &&
          message.hasOwnProperty('ifTouchedCondition')
        )
          object.ifTouchedCondition =
            options.enums === String
              ? $root.rti.RequestBracketOrder.Condition[
                  message.ifTouchedCondition
                ]
              : message.ifTouchedCondition;
        if (
          message.ifTouchedPriceField != null &&
          message.hasOwnProperty('ifTouchedPriceField')
        )
          object.ifTouchedPriceField =
            options.enums === String
              ? $root.rti.RequestBracketOrder.PriceField[
                  message.ifTouchedPriceField
                ]
              : message.ifTouchedPriceField;
        if (
          message.targetTicks != null &&
          message.hasOwnProperty('targetTicks')
        )
          object.targetTicks = message.targetTicks;
        if (
          message.targetQuantity != null &&
          message.hasOwnProperty('targetQuantity')
        )
          object.targetQuantity = message.targetQuantity;
        if (message.stopTicks != null && message.hasOwnProperty('stopTicks'))
          object.stopTicks = message.stopTicks;
        if (
          message.stopQuantity != null &&
          message.hasOwnProperty('stopQuantity')
        )
          object.stopQuantity = message.stopQuantity;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.releaseAtSsboe != null &&
          message.hasOwnProperty('releaseAtSsboe')
        )
          object.releaseAtSsboe = message.releaseAtSsboe;
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          object.cancelAfterSecs = message.cancelAfterSecs;
        if (
          message.releaseAtUsecs != null &&
          message.hasOwnProperty('releaseAtUsecs')
        )
          object.releaseAtUsecs = message.releaseAtUsecs;
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          object.windowName = message.windowName;
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          object.manualOrAuto =
            options.enums === String
              ? $root.rti.RequestBracketOrder.OrderPlacement[
                  message.manualOrAuto
                ]
              : message.manualOrAuto;
        if (
          message.stopMarketOnReject != null &&
          message.hasOwnProperty('stopMarketOnReject')
        )
          object.stopMarketOnReject = message.stopMarketOnReject;
        if (
          message.trailingStopByLastTradePrice != null &&
          message.hasOwnProperty('trailingStopByLastTradePrice')
        )
          object.trailingStopByLastTradePrice =
            message.trailingStopByLastTradePrice;
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          object.cancelAtSsboe = message.cancelAtSsboe;
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          object.cancelAtUsecs = message.cancelAtUsecs;
        if (
          message.bracketType != null &&
          message.hasOwnProperty('bracketType')
        )
          object.bracketType =
            options.enums === String
              ? $root.rti.RequestBracketOrder.BracketType[message.bracketType]
              : message.bracketType;
        if (
          message.trailingStopTriggerTicks != null &&
          message.hasOwnProperty('trailingStopTriggerTicks')
        )
          object.trailingStopTriggerTicks = message.trailingStopTriggerTicks;
        if (
          message.targetMarketAtSsboe != null &&
          message.hasOwnProperty('targetMarketAtSsboe')
        )
          object.targetMarketAtSsboe = message.targetMarketAtSsboe;
        if (
          message.targetMarketAtUsecs != null &&
          message.hasOwnProperty('targetMarketAtUsecs')
        )
          object.targetMarketAtUsecs = message.targetMarketAtUsecs;
        if (
          message.stopMarketAtSsboe != null &&
          message.hasOwnProperty('stopMarketAtSsboe')
        )
          object.stopMarketAtSsboe = message.stopMarketAtSsboe;
        if (
          message.stopMarketAtUsecs != null &&
          message.hasOwnProperty('stopMarketAtUsecs')
        )
          object.stopMarketAtUsecs = message.stopMarketAtUsecs;
        if (
          message.targetMarketOrderAfterSecs != null &&
          message.hasOwnProperty('targetMarketOrderAfterSecs')
        )
          object.targetMarketOrderAfterSecs =
            message.targetMarketOrderAfterSecs;
        if (
          message.targetMarketOrderIfTouched != null &&
          message.hasOwnProperty('targetMarketOrderIfTouched')
        )
          object.targetMarketOrderIfTouched =
            message.targetMarketOrderIfTouched;
        if (
          message.breakEvenTicks != null &&
          message.hasOwnProperty('breakEvenTicks')
        )
          object.breakEvenTicks = message.breakEvenTicks;
        if (
          message.breakEvenTriggerTicks != null &&
          message.hasOwnProperty('breakEvenTriggerTicks')
        )
          object.breakEvenTriggerTicks = message.breakEvenTriggerTicks;
        return object;
      };

      /**
       * Converts this RequestBracketOrder to JSON.
       * @function toJSON
       * @memberof rti.RequestBracketOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestBracketOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestBracketOrder
       * @function getTypeUrl
       * @memberof rti.RequestBracketOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestBracketOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestBracketOrder';
      };

      /**
       * UserType enum.
       * @name rti.RequestBracketOrder.UserType
       * @enum {number}
       * @property {number} USER_TYPE_ADMIN=0 USER_TYPE_ADMIN value
       * @property {number} USER_TYPE_FCM=1 USER_TYPE_FCM value
       * @property {number} USER_TYPE_IB=2 USER_TYPE_IB value
       * @property {number} USER_TYPE_TRADER=3 USER_TYPE_TRADER value
       */
      RequestBracketOrder.UserType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[0] = 'USER_TYPE_ADMIN')] = 0;
        values[(valuesById[1] = 'USER_TYPE_FCM')] = 1;
        values[(valuesById[2] = 'USER_TYPE_IB')] = 2;
        values[(valuesById[3] = 'USER_TYPE_TRADER')] = 3;
        return values;
      })();

      /**
       * BracketType enum.
       * @name rti.RequestBracketOrder.BracketType
       * @enum {number}
       * @property {number} STOP_ONLY=1 STOP_ONLY value
       * @property {number} TARGET_ONLY=2 TARGET_ONLY value
       * @property {number} TARGET_AND_STOP=3 TARGET_AND_STOP value
       * @property {number} STOP_ONLY_STATIC=4 STOP_ONLY_STATIC value
       * @property {number} TARGET_ONLY_STATIC=5 TARGET_ONLY_STATIC value
       * @property {number} TARGET_AND_STOP_STATIC=6 TARGET_AND_STOP_STATIC value
       */
      RequestBracketOrder.BracketType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'STOP_ONLY')] = 1;
        values[(valuesById[2] = 'TARGET_ONLY')] = 2;
        values[(valuesById[3] = 'TARGET_AND_STOP')] = 3;
        values[(valuesById[4] = 'STOP_ONLY_STATIC')] = 4;
        values[(valuesById[5] = 'TARGET_ONLY_STATIC')] = 5;
        values[(valuesById[6] = 'TARGET_AND_STOP_STATIC')] = 6;
        return values;
      })();

      /**
       * TransactionType enum.
       * @name rti.RequestBracketOrder.TransactionType
       * @enum {number}
       * @property {number} BUY=1 BUY value
       * @property {number} SELL=2 SELL value
       */
      RequestBracketOrder.TransactionType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY')] = 1;
        values[(valuesById[2] = 'SELL')] = 2;
        return values;
      })();

      /**
       * Duration enum.
       * @name rti.RequestBracketOrder.Duration
       * @enum {number}
       * @property {number} DAY=1 DAY value
       * @property {number} GTC=2 GTC value
       * @property {number} IOC=3 IOC value
       * @property {number} FOK=4 FOK value
       */
      RequestBracketOrder.Duration = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'DAY')] = 1;
        values[(valuesById[2] = 'GTC')] = 2;
        values[(valuesById[3] = 'IOC')] = 3;
        values[(valuesById[4] = 'FOK')] = 4;
        return values;
      })();

      /**
       * PriceType enum.
       * @name rti.RequestBracketOrder.PriceType
       * @enum {number}
       * @property {number} LIMIT=1 LIMIT value
       * @property {number} MARKET=2 MARKET value
       * @property {number} STOP_LIMIT=3 STOP_LIMIT value
       * @property {number} STOP_MARKET=4 STOP_MARKET value
       * @property {number} MARKET_IF_TOUCHED=5 MARKET_IF_TOUCHED value
       * @property {number} LIMIT_IF_TOUCHED=6 LIMIT_IF_TOUCHED value
       */
      RequestBracketOrder.PriceType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LIMIT')] = 1;
        values[(valuesById[2] = 'MARKET')] = 2;
        values[(valuesById[3] = 'STOP_LIMIT')] = 3;
        values[(valuesById[4] = 'STOP_MARKET')] = 4;
        values[(valuesById[5] = 'MARKET_IF_TOUCHED')] = 5;
        values[(valuesById[6] = 'LIMIT_IF_TOUCHED')] = 6;
        return values;
      })();

      /**
       * OrderPlacement enum.
       * @name rti.RequestBracketOrder.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      RequestBracketOrder.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      /**
       * PriceField enum.
       * @name rti.RequestBracketOrder.PriceField
       * @enum {number}
       * @property {number} BID_PRICE=1 BID_PRICE value
       * @property {number} OFFER_PRICE=2 OFFER_PRICE value
       * @property {number} TRADE_PRICE=3 TRADE_PRICE value
       * @property {number} LEAN_PRICE=4 LEAN_PRICE value
       */
      RequestBracketOrder.PriceField = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BID_PRICE')] = 1;
        values[(valuesById[2] = 'OFFER_PRICE')] = 2;
        values[(valuesById[3] = 'TRADE_PRICE')] = 3;
        values[(valuesById[4] = 'LEAN_PRICE')] = 4;
        return values;
      })();

      /**
       * Condition enum.
       * @name rti.RequestBracketOrder.Condition
       * @enum {number}
       * @property {number} EQUAL_TO=1 EQUAL_TO value
       * @property {number} NOT_EQUAL_TO=2 NOT_EQUAL_TO value
       * @property {number} GREATER_THAN=3 GREATER_THAN value
       * @property {number} GREATER_THAN_EQUAL_TO=4 GREATER_THAN_EQUAL_TO value
       * @property {number} LESSER_THAN=5 LESSER_THAN value
       * @property {number} LESSER_THAN_EQUAL_TO=6 LESSER_THAN_EQUAL_TO value
       */
      RequestBracketOrder.Condition = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'EQUAL_TO')] = 1;
        values[(valuesById[2] = 'NOT_EQUAL_TO')] = 2;
        values[(valuesById[3] = 'GREATER_THAN')] = 3;
        values[(valuesById[4] = 'GREATER_THAN_EQUAL_TO')] = 4;
        values[(valuesById[5] = 'LESSER_THAN')] = 5;
        values[(valuesById[6] = 'LESSER_THAN_EQUAL_TO')] = 6;
        return values;
      })();

      return RequestBracketOrder;
    })();

    rti.ResponseBracketOrder = (function () {
      /**
       * Properties of a ResponseBracketOrder.
       * @memberof rti
       * @interface IResponseBracketOrder
       * @property {number} templateId ResponseBracketOrder templateId
       * @property {Array.<string>|null} [userMsg] ResponseBracketOrder userMsg
       * @property {string|null} [userTag] ResponseBracketOrder userTag
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseBracketOrder rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseBracketOrder rpCode
       * @property {string|null} [basketId] ResponseBracketOrder basketId
       * @property {number|null} [ssboe] ResponseBracketOrder ssboe
       * @property {number|null} [usecs] ResponseBracketOrder usecs
       */

      /**
       * Constructs a new ResponseBracketOrder.
       * @memberof rti
       * @classdesc Represents a ResponseBracketOrder.
       * @implements IResponseBracketOrder
       * @constructor
       * @param {rti.IResponseBracketOrder=} [properties] Properties to set
       */
      function ResponseBracketOrder(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseBracketOrder templateId.
       * @member {number} templateId
       * @memberof rti.ResponseBracketOrder
       * @instance
       */
      ResponseBracketOrder.prototype.templateId = 0;

      /**
       * ResponseBracketOrder userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseBracketOrder
       * @instance
       */
      ResponseBracketOrder.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseBracketOrder userTag.
       * @member {string} userTag
       * @memberof rti.ResponseBracketOrder
       * @instance
       */
      ResponseBracketOrder.prototype.userTag = '';

      /**
       * ResponseBracketOrder rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseBracketOrder
       * @instance
       */
      ResponseBracketOrder.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseBracketOrder rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseBracketOrder
       * @instance
       */
      ResponseBracketOrder.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseBracketOrder basketId.
       * @member {string} basketId
       * @memberof rti.ResponseBracketOrder
       * @instance
       */
      ResponseBracketOrder.prototype.basketId = '';

      /**
       * ResponseBracketOrder ssboe.
       * @member {number} ssboe
       * @memberof rti.ResponseBracketOrder
       * @instance
       */
      ResponseBracketOrder.prototype.ssboe = 0;

      /**
       * ResponseBracketOrder usecs.
       * @member {number} usecs
       * @memberof rti.ResponseBracketOrder
       * @instance
       */
      ResponseBracketOrder.prototype.usecs = 0;

      /**
       * Creates a new ResponseBracketOrder instance using the specified properties.
       * @function create
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {rti.IResponseBracketOrder=} [properties] Properties to set
       * @returns {rti.ResponseBracketOrder} ResponseBracketOrder instance
       */
      ResponseBracketOrder.create = function create(properties) {
        return new ResponseBracketOrder(properties);
      };

      /**
       * Encodes the specified ResponseBracketOrder message. Does not implicitly {@link rti.ResponseBracketOrder.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {rti.IResponseBracketOrder} message ResponseBracketOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseBracketOrder.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.userTag != null &&
          Object.hasOwnProperty.call(message, 'userTag')
        )
          writer
            .uint32(/* id 154119, wireType 2 =*/ 1232954)
            .string(message.userTag);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseBracketOrder message, length delimited. Does not implicitly {@link rti.ResponseBracketOrder.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {rti.IResponseBracketOrder} message ResponseBracketOrder message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseBracketOrder.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseBracketOrder message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseBracketOrder} ResponseBracketOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseBracketOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseBracketOrder();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154119:
              message.userTag = reader.string();
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseBracketOrder message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseBracketOrder} ResponseBracketOrder
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseBracketOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseBracketOrder message.
       * @function verify
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseBracketOrder.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          if (!$util.isString(message.userTag))
            return 'userTag: string expected';
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a ResponseBracketOrder message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseBracketOrder} ResponseBracketOrder
       */
      ResponseBracketOrder.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseBracketOrder) return object;
        var message = new $root.rti.ResponseBracketOrder();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseBracketOrder.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.userTag != null) message.userTag = String(object.userTag);
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseBracketOrder.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseBracketOrder.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a ResponseBracketOrder message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {rti.ResponseBracketOrder} message ResponseBracketOrder
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseBracketOrder.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.basketId = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.userTag = '';
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          object.userTag = message.userTag;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseBracketOrder to JSON.
       * @function toJSON
       * @memberof rti.ResponseBracketOrder
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseBracketOrder.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseBracketOrder
       * @function getTypeUrl
       * @memberof rti.ResponseBracketOrder
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseBracketOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseBracketOrder';
      };

      return ResponseBracketOrder;
    })();

    rti.RequestShowBrackets = (function () {
      /**
       * Properties of a RequestShowBrackets.
       * @memberof rti
       * @interface IRequestShowBrackets
       * @property {number} templateId RequestShowBrackets templateId
       * @property {Array.<string>|null} [userMsg] RequestShowBrackets userMsg
       * @property {string|null} [fcmId] RequestShowBrackets fcmId
       * @property {string|null} [ibId] RequestShowBrackets ibId
       * @property {string|null} [accountId] RequestShowBrackets accountId
       */

      /**
       * Constructs a new RequestShowBrackets.
       * @memberof rti
       * @classdesc Represents a RequestShowBrackets.
       * @implements IRequestShowBrackets
       * @constructor
       * @param {rti.IRequestShowBrackets=} [properties] Properties to set
       */
      function RequestShowBrackets(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestShowBrackets templateId.
       * @member {number} templateId
       * @memberof rti.RequestShowBrackets
       * @instance
       */
      RequestShowBrackets.prototype.templateId = 0;

      /**
       * RequestShowBrackets userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestShowBrackets
       * @instance
       */
      RequestShowBrackets.prototype.userMsg = $util.emptyArray;

      /**
       * RequestShowBrackets fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestShowBrackets
       * @instance
       */
      RequestShowBrackets.prototype.fcmId = '';

      /**
       * RequestShowBrackets ibId.
       * @member {string} ibId
       * @memberof rti.RequestShowBrackets
       * @instance
       */
      RequestShowBrackets.prototype.ibId = '';

      /**
       * RequestShowBrackets accountId.
       * @member {string} accountId
       * @memberof rti.RequestShowBrackets
       * @instance
       */
      RequestShowBrackets.prototype.accountId = '';

      /**
       * Creates a new RequestShowBrackets instance using the specified properties.
       * @function create
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {rti.IRequestShowBrackets=} [properties] Properties to set
       * @returns {rti.RequestShowBrackets} RequestShowBrackets instance
       */
      RequestShowBrackets.create = function create(properties) {
        return new RequestShowBrackets(properties);
      };

      /**
       * Encodes the specified RequestShowBrackets message. Does not implicitly {@link rti.RequestShowBrackets.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {rti.IRequestShowBrackets} message RequestShowBrackets message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowBrackets.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestShowBrackets message, length delimited. Does not implicitly {@link rti.RequestShowBrackets.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {rti.IRequestShowBrackets} message RequestShowBrackets message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowBrackets.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestShowBrackets message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestShowBrackets} RequestShowBrackets
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowBrackets.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestShowBrackets();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestShowBrackets message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestShowBrackets} RequestShowBrackets
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowBrackets.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestShowBrackets message.
       * @function verify
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestShowBrackets.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        return null;
      };

      /**
       * Creates a RequestShowBrackets message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestShowBrackets} RequestShowBrackets
       */
      RequestShowBrackets.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestShowBrackets) return object;
        var message = new $root.rti.RequestShowBrackets();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestShowBrackets.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        return message;
      };

      /**
       * Creates a plain object from a RequestShowBrackets message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {rti.RequestShowBrackets} message RequestShowBrackets
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestShowBrackets.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestShowBrackets to JSON.
       * @function toJSON
       * @memberof rti.RequestShowBrackets
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestShowBrackets.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestShowBrackets
       * @function getTypeUrl
       * @memberof rti.RequestShowBrackets
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestShowBrackets.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestShowBrackets';
      };

      return RequestShowBrackets;
    })();

    rti.ResponseShowBrackets = (function () {
      /**
       * Properties of a ResponseShowBrackets.
       * @memberof rti
       * @interface IResponseShowBrackets
       * @property {number} templateId ResponseShowBrackets templateId
       * @property {Array.<string>|null} [userMsg] ResponseShowBrackets userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseShowBrackets rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseShowBrackets rpCode
       * @property {string|null} [basketId] ResponseShowBrackets basketId
       * @property {string|null} [targetQuantity] ResponseShowBrackets targetQuantity
       * @property {string|null} [targetQuantityReleased] ResponseShowBrackets targetQuantityReleased
       * @property {string|null} [targetTicks] ResponseShowBrackets targetTicks
       */

      /**
       * Constructs a new ResponseShowBrackets.
       * @memberof rti
       * @classdesc Represents a ResponseShowBrackets.
       * @implements IResponseShowBrackets
       * @constructor
       * @param {rti.IResponseShowBrackets=} [properties] Properties to set
       */
      function ResponseShowBrackets(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseShowBrackets templateId.
       * @member {number} templateId
       * @memberof rti.ResponseShowBrackets
       * @instance
       */
      ResponseShowBrackets.prototype.templateId = 0;

      /**
       * ResponseShowBrackets userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseShowBrackets
       * @instance
       */
      ResponseShowBrackets.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseShowBrackets rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseShowBrackets
       * @instance
       */
      ResponseShowBrackets.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseShowBrackets rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseShowBrackets
       * @instance
       */
      ResponseShowBrackets.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseShowBrackets basketId.
       * @member {string} basketId
       * @memberof rti.ResponseShowBrackets
       * @instance
       */
      ResponseShowBrackets.prototype.basketId = '';

      /**
       * ResponseShowBrackets targetQuantity.
       * @member {string} targetQuantity
       * @memberof rti.ResponseShowBrackets
       * @instance
       */
      ResponseShowBrackets.prototype.targetQuantity = '';

      /**
       * ResponseShowBrackets targetQuantityReleased.
       * @member {string} targetQuantityReleased
       * @memberof rti.ResponseShowBrackets
       * @instance
       */
      ResponseShowBrackets.prototype.targetQuantityReleased = '';

      /**
       * ResponseShowBrackets targetTicks.
       * @member {string} targetTicks
       * @memberof rti.ResponseShowBrackets
       * @instance
       */
      ResponseShowBrackets.prototype.targetTicks = '';

      /**
       * Creates a new ResponseShowBrackets instance using the specified properties.
       * @function create
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {rti.IResponseShowBrackets=} [properties] Properties to set
       * @returns {rti.ResponseShowBrackets} ResponseShowBrackets instance
       */
      ResponseShowBrackets.create = function create(properties) {
        return new ResponseShowBrackets(properties);
      };

      /**
       * Encodes the specified ResponseShowBrackets message. Does not implicitly {@link rti.ResponseShowBrackets.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {rti.IResponseShowBrackets} message ResponseShowBrackets message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowBrackets.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.targetTicks != null &&
          Object.hasOwnProperty.call(message, 'targetTicks')
        )
          writer
            .uint32(/* id 154456, wireType 2 =*/ 1235650)
            .string(message.targetTicks);
        if (
          message.targetQuantity != null &&
          Object.hasOwnProperty.call(message, 'targetQuantity')
        )
          writer
            .uint32(/* id 154457, wireType 2 =*/ 1235658)
            .string(message.targetQuantity);
        if (
          message.targetQuantityReleased != null &&
          Object.hasOwnProperty.call(message, 'targetQuantityReleased')
        )
          writer
            .uint32(/* id 154460, wireType 2 =*/ 1235682)
            .string(message.targetQuantityReleased);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseShowBrackets message, length delimited. Does not implicitly {@link rti.ResponseShowBrackets.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {rti.IResponseShowBrackets} message ResponseShowBrackets message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowBrackets.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseShowBrackets message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseShowBrackets} ResponseShowBrackets
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowBrackets.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseShowBrackets();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 154457:
              message.targetQuantity = reader.string();
              break;
            case 154460:
              message.targetQuantityReleased = reader.string();
              break;
            case 154456:
              message.targetTicks = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseShowBrackets message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseShowBrackets} ResponseShowBrackets
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowBrackets.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseShowBrackets message.
       * @function verify
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseShowBrackets.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (
          message.targetQuantity != null &&
          message.hasOwnProperty('targetQuantity')
        )
          if (!$util.isString(message.targetQuantity))
            return 'targetQuantity: string expected';
        if (
          message.targetQuantityReleased != null &&
          message.hasOwnProperty('targetQuantityReleased')
        )
          if (!$util.isString(message.targetQuantityReleased))
            return 'targetQuantityReleased: string expected';
        if (
          message.targetTicks != null &&
          message.hasOwnProperty('targetTicks')
        )
          if (!$util.isString(message.targetTicks))
            return 'targetTicks: string expected';
        return null;
      };

      /**
       * Creates a ResponseShowBrackets message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseShowBrackets} ResponseShowBrackets
       */
      ResponseShowBrackets.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseShowBrackets) return object;
        var message = new $root.rti.ResponseShowBrackets();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseShowBrackets.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseShowBrackets.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseShowBrackets.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.targetQuantity != null)
          message.targetQuantity = String(object.targetQuantity);
        if (object.targetQuantityReleased != null)
          message.targetQuantityReleased = String(
            object.targetQuantityReleased,
          );
        if (object.targetTicks != null)
          message.targetTicks = String(object.targetTicks);
        return message;
      };

      /**
       * Creates a plain object from a ResponseShowBrackets message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {rti.ResponseShowBrackets} message ResponseShowBrackets
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseShowBrackets.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.basketId = '';
          object.targetTicks = '';
          object.targetQuantity = '';
          object.targetQuantityReleased = '';
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.targetTicks != null &&
          message.hasOwnProperty('targetTicks')
        )
          object.targetTicks = message.targetTicks;
        if (
          message.targetQuantity != null &&
          message.hasOwnProperty('targetQuantity')
        )
          object.targetQuantity = message.targetQuantity;
        if (
          message.targetQuantityReleased != null &&
          message.hasOwnProperty('targetQuantityReleased')
        )
          object.targetQuantityReleased = message.targetQuantityReleased;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseShowBrackets to JSON.
       * @function toJSON
       * @memberof rti.ResponseShowBrackets
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseShowBrackets.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseShowBrackets
       * @function getTypeUrl
       * @memberof rti.ResponseShowBrackets
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseShowBrackets.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseShowBrackets';
      };

      return ResponseShowBrackets;
    })();

    rti.RequestShowBracketStops = (function () {
      /**
       * Properties of a RequestShowBracketStops.
       * @memberof rti
       * @interface IRequestShowBracketStops
       * @property {number} templateId RequestShowBracketStops templateId
       * @property {Array.<string>|null} [userMsg] RequestShowBracketStops userMsg
       * @property {string|null} [fcmId] RequestShowBracketStops fcmId
       * @property {string|null} [ibId] RequestShowBracketStops ibId
       * @property {string|null} [accountId] RequestShowBracketStops accountId
       */

      /**
       * Constructs a new RequestShowBracketStops.
       * @memberof rti
       * @classdesc Represents a RequestShowBracketStops.
       * @implements IRequestShowBracketStops
       * @constructor
       * @param {rti.IRequestShowBracketStops=} [properties] Properties to set
       */
      function RequestShowBracketStops(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestShowBracketStops templateId.
       * @member {number} templateId
       * @memberof rti.RequestShowBracketStops
       * @instance
       */
      RequestShowBracketStops.prototype.templateId = 0;

      /**
       * RequestShowBracketStops userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestShowBracketStops
       * @instance
       */
      RequestShowBracketStops.prototype.userMsg = $util.emptyArray;

      /**
       * RequestShowBracketStops fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestShowBracketStops
       * @instance
       */
      RequestShowBracketStops.prototype.fcmId = '';

      /**
       * RequestShowBracketStops ibId.
       * @member {string} ibId
       * @memberof rti.RequestShowBracketStops
       * @instance
       */
      RequestShowBracketStops.prototype.ibId = '';

      /**
       * RequestShowBracketStops accountId.
       * @member {string} accountId
       * @memberof rti.RequestShowBracketStops
       * @instance
       */
      RequestShowBracketStops.prototype.accountId = '';

      /**
       * Creates a new RequestShowBracketStops instance using the specified properties.
       * @function create
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {rti.IRequestShowBracketStops=} [properties] Properties to set
       * @returns {rti.RequestShowBracketStops} RequestShowBracketStops instance
       */
      RequestShowBracketStops.create = function create(properties) {
        return new RequestShowBracketStops(properties);
      };

      /**
       * Encodes the specified RequestShowBracketStops message. Does not implicitly {@link rti.RequestShowBracketStops.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {rti.IRequestShowBracketStops} message RequestShowBracketStops message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowBracketStops.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestShowBracketStops message, length delimited. Does not implicitly {@link rti.RequestShowBracketStops.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {rti.IRequestShowBracketStops} message RequestShowBracketStops message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowBracketStops.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestShowBracketStops message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestShowBracketStops} RequestShowBracketStops
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowBracketStops.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestShowBracketStops();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestShowBracketStops message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestShowBracketStops} RequestShowBracketStops
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowBracketStops.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestShowBracketStops message.
       * @function verify
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestShowBracketStops.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        return null;
      };

      /**
       * Creates a RequestShowBracketStops message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestShowBracketStops} RequestShowBracketStops
       */
      RequestShowBracketStops.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestShowBracketStops) return object;
        var message = new $root.rti.RequestShowBracketStops();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestShowBracketStops.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        return message;
      };

      /**
       * Creates a plain object from a RequestShowBracketStops message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {rti.RequestShowBracketStops} message RequestShowBracketStops
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestShowBracketStops.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestShowBracketStops to JSON.
       * @function toJSON
       * @memberof rti.RequestShowBracketStops
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestShowBracketStops.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestShowBracketStops
       * @function getTypeUrl
       * @memberof rti.RequestShowBracketStops
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestShowBracketStops.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestShowBracketStops';
      };

      return RequestShowBracketStops;
    })();

    rti.ResponseShowBracketStops = (function () {
      /**
       * Properties of a ResponseShowBracketStops.
       * @memberof rti
       * @interface IResponseShowBracketStops
       * @property {number} templateId ResponseShowBracketStops templateId
       * @property {Array.<string>|null} [userMsg] ResponseShowBracketStops userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseShowBracketStops rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseShowBracketStops rpCode
       * @property {string|null} [basketId] ResponseShowBracketStops basketId
       * @property {string|null} [stopQuantity] ResponseShowBracketStops stopQuantity
       * @property {string|null} [stopQuantityReleased] ResponseShowBracketStops stopQuantityReleased
       * @property {string|null} [stopTicks] ResponseShowBracketStops stopTicks
       * @property {string|null} [bracketTrailingFieldId] ResponseShowBracketStops bracketTrailingFieldId
       * @property {string|null} [trailingStopTriggerTicks] ResponseShowBracketStops trailingStopTriggerTicks
       */

      /**
       * Constructs a new ResponseShowBracketStops.
       * @memberof rti
       * @classdesc Represents a ResponseShowBracketStops.
       * @implements IResponseShowBracketStops
       * @constructor
       * @param {rti.IResponseShowBracketStops=} [properties] Properties to set
       */
      function ResponseShowBracketStops(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseShowBracketStops templateId.
       * @member {number} templateId
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.templateId = 0;

      /**
       * ResponseShowBracketStops userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseShowBracketStops rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseShowBracketStops rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseShowBracketStops basketId.
       * @member {string} basketId
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.basketId = '';

      /**
       * ResponseShowBracketStops stopQuantity.
       * @member {string} stopQuantity
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.stopQuantity = '';

      /**
       * ResponseShowBracketStops stopQuantityReleased.
       * @member {string} stopQuantityReleased
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.stopQuantityReleased = '';

      /**
       * ResponseShowBracketStops stopTicks.
       * @member {string} stopTicks
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.stopTicks = '';

      /**
       * ResponseShowBracketStops bracketTrailingFieldId.
       * @member {string} bracketTrailingFieldId
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.bracketTrailingFieldId = '';

      /**
       * ResponseShowBracketStops trailingStopTriggerTicks.
       * @member {string} trailingStopTriggerTicks
       * @memberof rti.ResponseShowBracketStops
       * @instance
       */
      ResponseShowBracketStops.prototype.trailingStopTriggerTicks = '';

      /**
       * Creates a new ResponseShowBracketStops instance using the specified properties.
       * @function create
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {rti.IResponseShowBracketStops=} [properties] Properties to set
       * @returns {rti.ResponseShowBracketStops} ResponseShowBracketStops instance
       */
      ResponseShowBracketStops.create = function create(properties) {
        return new ResponseShowBracketStops(properties);
      };

      /**
       * Encodes the specified ResponseShowBracketStops message. Does not implicitly {@link rti.ResponseShowBracketStops.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {rti.IResponseShowBracketStops} message ResponseShowBracketStops message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowBracketStops.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.stopTicks != null &&
          Object.hasOwnProperty.call(message, 'stopTicks')
        )
          writer
            .uint32(/* id 154458, wireType 2 =*/ 1235666)
            .string(message.stopTicks);
        if (
          message.stopQuantity != null &&
          Object.hasOwnProperty.call(message, 'stopQuantity')
        )
          writer
            .uint32(/* id 154459, wireType 2 =*/ 1235674)
            .string(message.stopQuantity);
        if (
          message.stopQuantityReleased != null &&
          Object.hasOwnProperty.call(message, 'stopQuantityReleased')
        )
          writer
            .uint32(/* id 154466, wireType 2 =*/ 1235730)
            .string(message.stopQuantityReleased);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.bracketTrailingFieldId != null &&
          Object.hasOwnProperty.call(message, 'bracketTrailingFieldId')
        )
          writer
            .uint32(/* id 157062, wireType 2 =*/ 1256498)
            .string(message.bracketTrailingFieldId);
        if (
          message.trailingStopTriggerTicks != null &&
          Object.hasOwnProperty.call(message, 'trailingStopTriggerTicks')
        )
          writer
            .uint32(/* id 157124, wireType 2 =*/ 1256994)
            .string(message.trailingStopTriggerTicks);
        return writer;
      };

      /**
       * Encodes the specified ResponseShowBracketStops message, length delimited. Does not implicitly {@link rti.ResponseShowBracketStops.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {rti.IResponseShowBracketStops} message ResponseShowBracketStops message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowBracketStops.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseShowBracketStops message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseShowBracketStops} ResponseShowBracketStops
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowBracketStops.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseShowBracketStops();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 154459:
              message.stopQuantity = reader.string();
              break;
            case 154466:
              message.stopQuantityReleased = reader.string();
              break;
            case 154458:
              message.stopTicks = reader.string();
              break;
            case 157062:
              message.bracketTrailingFieldId = reader.string();
              break;
            case 157124:
              message.trailingStopTriggerTicks = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseShowBracketStops message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseShowBracketStops} ResponseShowBracketStops
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowBracketStops.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseShowBracketStops message.
       * @function verify
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseShowBracketStops.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (
          message.stopQuantity != null &&
          message.hasOwnProperty('stopQuantity')
        )
          if (!$util.isString(message.stopQuantity))
            return 'stopQuantity: string expected';
        if (
          message.stopQuantityReleased != null &&
          message.hasOwnProperty('stopQuantityReleased')
        )
          if (!$util.isString(message.stopQuantityReleased))
            return 'stopQuantityReleased: string expected';
        if (message.stopTicks != null && message.hasOwnProperty('stopTicks'))
          if (!$util.isString(message.stopTicks))
            return 'stopTicks: string expected';
        if (
          message.bracketTrailingFieldId != null &&
          message.hasOwnProperty('bracketTrailingFieldId')
        )
          if (!$util.isString(message.bracketTrailingFieldId))
            return 'bracketTrailingFieldId: string expected';
        if (
          message.trailingStopTriggerTicks != null &&
          message.hasOwnProperty('trailingStopTriggerTicks')
        )
          if (!$util.isString(message.trailingStopTriggerTicks))
            return 'trailingStopTriggerTicks: string expected';
        return null;
      };

      /**
       * Creates a ResponseShowBracketStops message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseShowBracketStops} ResponseShowBracketStops
       */
      ResponseShowBracketStops.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseShowBracketStops) return object;
        var message = new $root.rti.ResponseShowBracketStops();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseShowBracketStops.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseShowBracketStops.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseShowBracketStops.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.stopQuantity != null)
          message.stopQuantity = String(object.stopQuantity);
        if (object.stopQuantityReleased != null)
          message.stopQuantityReleased = String(object.stopQuantityReleased);
        if (object.stopTicks != null)
          message.stopTicks = String(object.stopTicks);
        if (object.bracketTrailingFieldId != null)
          message.bracketTrailingFieldId = String(
            object.bracketTrailingFieldId,
          );
        if (object.trailingStopTriggerTicks != null)
          message.trailingStopTriggerTicks = String(
            object.trailingStopTriggerTicks,
          );
        return message;
      };

      /**
       * Creates a plain object from a ResponseShowBracketStops message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {rti.ResponseShowBracketStops} message ResponseShowBracketStops
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseShowBracketStops.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.basketId = '';
          object.stopTicks = '';
          object.stopQuantity = '';
          object.stopQuantityReleased = '';
          object.templateId = 0;
          object.bracketTrailingFieldId = '';
          object.trailingStopTriggerTicks = '';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.stopTicks != null && message.hasOwnProperty('stopTicks'))
          object.stopTicks = message.stopTicks;
        if (
          message.stopQuantity != null &&
          message.hasOwnProperty('stopQuantity')
        )
          object.stopQuantity = message.stopQuantity;
        if (
          message.stopQuantityReleased != null &&
          message.hasOwnProperty('stopQuantityReleased')
        )
          object.stopQuantityReleased = message.stopQuantityReleased;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.bracketTrailingFieldId != null &&
          message.hasOwnProperty('bracketTrailingFieldId')
        )
          object.bracketTrailingFieldId = message.bracketTrailingFieldId;
        if (
          message.trailingStopTriggerTicks != null &&
          message.hasOwnProperty('trailingStopTriggerTicks')
        )
          object.trailingStopTriggerTicks = message.trailingStopTriggerTicks;
        return object;
      };

      /**
       * Converts this ResponseShowBracketStops to JSON.
       * @function toJSON
       * @memberof rti.ResponseShowBracketStops
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseShowBracketStops.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseShowBracketStops
       * @function getTypeUrl
       * @memberof rti.ResponseShowBracketStops
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseShowBracketStops.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseShowBracketStops';
      };

      return ResponseShowBracketStops;
    })();

    rti.RequestUpdateTargetBracketLevel = (function () {
      /**
       * Properties of a RequestUpdateTargetBracketLevel.
       * @memberof rti
       * @interface IRequestUpdateTargetBracketLevel
       * @property {number} templateId RequestUpdateTargetBracketLevel templateId
       * @property {Array.<string>|null} [userMsg] RequestUpdateTargetBracketLevel userMsg
       * @property {string|null} [fcmId] RequestUpdateTargetBracketLevel fcmId
       * @property {string|null} [ibId] RequestUpdateTargetBracketLevel ibId
       * @property {string|null} [accountId] RequestUpdateTargetBracketLevel accountId
       * @property {string|null} [basketId] RequestUpdateTargetBracketLevel basketId
       * @property {number|null} [level] RequestUpdateTargetBracketLevel level
       * @property {number|null} [targetTicks] RequestUpdateTargetBracketLevel targetTicks
       */

      /**
       * Constructs a new RequestUpdateTargetBracketLevel.
       * @memberof rti
       * @classdesc Represents a RequestUpdateTargetBracketLevel.
       * @implements IRequestUpdateTargetBracketLevel
       * @constructor
       * @param {rti.IRequestUpdateTargetBracketLevel=} [properties] Properties to set
       */
      function RequestUpdateTargetBracketLevel(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestUpdateTargetBracketLevel templateId.
       * @member {number} templateId
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       */
      RequestUpdateTargetBracketLevel.prototype.templateId = 0;

      /**
       * RequestUpdateTargetBracketLevel userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       */
      RequestUpdateTargetBracketLevel.prototype.userMsg = $util.emptyArray;

      /**
       * RequestUpdateTargetBracketLevel fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       */
      RequestUpdateTargetBracketLevel.prototype.fcmId = '';

      /**
       * RequestUpdateTargetBracketLevel ibId.
       * @member {string} ibId
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       */
      RequestUpdateTargetBracketLevel.prototype.ibId = '';

      /**
       * RequestUpdateTargetBracketLevel accountId.
       * @member {string} accountId
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       */
      RequestUpdateTargetBracketLevel.prototype.accountId = '';

      /**
       * RequestUpdateTargetBracketLevel basketId.
       * @member {string} basketId
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       */
      RequestUpdateTargetBracketLevel.prototype.basketId = '';

      /**
       * RequestUpdateTargetBracketLevel level.
       * @member {number} level
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       */
      RequestUpdateTargetBracketLevel.prototype.level = 0;

      /**
       * RequestUpdateTargetBracketLevel targetTicks.
       * @member {number} targetTicks
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       */
      RequestUpdateTargetBracketLevel.prototype.targetTicks = 0;

      /**
       * Creates a new RequestUpdateTargetBracketLevel instance using the specified properties.
       * @function create
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {rti.IRequestUpdateTargetBracketLevel=} [properties] Properties to set
       * @returns {rti.RequestUpdateTargetBracketLevel} RequestUpdateTargetBracketLevel instance
       */
      RequestUpdateTargetBracketLevel.create = function create(properties) {
        return new RequestUpdateTargetBracketLevel(properties);
      };

      /**
       * Encodes the specified RequestUpdateTargetBracketLevel message. Does not implicitly {@link rti.RequestUpdateTargetBracketLevel.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {rti.IRequestUpdateTargetBracketLevel} message RequestUpdateTargetBracketLevel message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestUpdateTargetBracketLevel.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.level != null &&
          Object.hasOwnProperty.call(message, 'level')
        )
          writer
            .uint32(/* id 154244, wireType 0 =*/ 1233952)
            .int32(message.level);
        if (
          message.targetTicks != null &&
          Object.hasOwnProperty.call(message, 'targetTicks')
        )
          writer
            .uint32(/* id 154456, wireType 0 =*/ 1235648)
            .int32(message.targetTicks);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestUpdateTargetBracketLevel message, length delimited. Does not implicitly {@link rti.RequestUpdateTargetBracketLevel.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {rti.IRequestUpdateTargetBracketLevel} message RequestUpdateTargetBracketLevel message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestUpdateTargetBracketLevel.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestUpdateTargetBracketLevel message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestUpdateTargetBracketLevel} RequestUpdateTargetBracketLevel
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestUpdateTargetBracketLevel.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestUpdateTargetBracketLevel();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 154244:
              message.level = reader.int32();
              break;
            case 154456:
              message.targetTicks = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestUpdateTargetBracketLevel message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestUpdateTargetBracketLevel} RequestUpdateTargetBracketLevel
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestUpdateTargetBracketLevel.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestUpdateTargetBracketLevel message.
       * @function verify
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestUpdateTargetBracketLevel.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.level != null && message.hasOwnProperty('level'))
          if (!$util.isInteger(message.level)) return 'level: integer expected';
        if (
          message.targetTicks != null &&
          message.hasOwnProperty('targetTicks')
        )
          if (!$util.isInteger(message.targetTicks))
            return 'targetTicks: integer expected';
        return null;
      };

      /**
       * Creates a RequestUpdateTargetBracketLevel message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestUpdateTargetBracketLevel} RequestUpdateTargetBracketLevel
       */
      RequestUpdateTargetBracketLevel.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestUpdateTargetBracketLevel)
          return object;
        var message = new $root.rti.RequestUpdateTargetBracketLevel();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestUpdateTargetBracketLevel.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.level != null) message.level = object.level | 0;
        if (object.targetTicks != null)
          message.targetTicks = object.targetTicks | 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestUpdateTargetBracketLevel message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {rti.RequestUpdateTargetBracketLevel} message RequestUpdateTargetBracketLevel
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestUpdateTargetBracketLevel.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.basketId = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.level = 0;
          object.targetTicks = 0;
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.level != null && message.hasOwnProperty('level'))
          object.level = message.level;
        if (
          message.targetTicks != null &&
          message.hasOwnProperty('targetTicks')
        )
          object.targetTicks = message.targetTicks;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestUpdateTargetBracketLevel to JSON.
       * @function toJSON
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestUpdateTargetBracketLevel.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestUpdateTargetBracketLevel
       * @function getTypeUrl
       * @memberof rti.RequestUpdateTargetBracketLevel
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestUpdateTargetBracketLevel.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestUpdateTargetBracketLevel';
      };

      return RequestUpdateTargetBracketLevel;
    })();

    rti.ResponseUpdateTargetBracketLevel = (function () {
      /**
       * Properties of a ResponseUpdateTargetBracketLevel.
       * @memberof rti
       * @interface IResponseUpdateTargetBracketLevel
       * @property {number} templateId ResponseUpdateTargetBracketLevel templateId
       * @property {Array.<string>|null} [userMsg] ResponseUpdateTargetBracketLevel userMsg
       * @property {Array.<string>|null} [rpCode] ResponseUpdateTargetBracketLevel rpCode
       */

      /**
       * Constructs a new ResponseUpdateTargetBracketLevel.
       * @memberof rti
       * @classdesc Represents a ResponseUpdateTargetBracketLevel.
       * @implements IResponseUpdateTargetBracketLevel
       * @constructor
       * @param {rti.IResponseUpdateTargetBracketLevel=} [properties] Properties to set
       */
      function ResponseUpdateTargetBracketLevel(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseUpdateTargetBracketLevel templateId.
       * @member {number} templateId
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @instance
       */
      ResponseUpdateTargetBracketLevel.prototype.templateId = 0;

      /**
       * ResponseUpdateTargetBracketLevel userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @instance
       */
      ResponseUpdateTargetBracketLevel.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseUpdateTargetBracketLevel rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @instance
       */
      ResponseUpdateTargetBracketLevel.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseUpdateTargetBracketLevel instance using the specified properties.
       * @function create
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {rti.IResponseUpdateTargetBracketLevel=} [properties] Properties to set
       * @returns {rti.ResponseUpdateTargetBracketLevel} ResponseUpdateTargetBracketLevel instance
       */
      ResponseUpdateTargetBracketLevel.create = function create(properties) {
        return new ResponseUpdateTargetBracketLevel(properties);
      };

      /**
       * Encodes the specified ResponseUpdateTargetBracketLevel message. Does not implicitly {@link rti.ResponseUpdateTargetBracketLevel.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {rti.IResponseUpdateTargetBracketLevel} message ResponseUpdateTargetBracketLevel message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseUpdateTargetBracketLevel.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseUpdateTargetBracketLevel message, length delimited. Does not implicitly {@link rti.ResponseUpdateTargetBracketLevel.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {rti.IResponseUpdateTargetBracketLevel} message ResponseUpdateTargetBracketLevel message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseUpdateTargetBracketLevel.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseUpdateTargetBracketLevel message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseUpdateTargetBracketLevel} ResponseUpdateTargetBracketLevel
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseUpdateTargetBracketLevel.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseUpdateTargetBracketLevel();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseUpdateTargetBracketLevel message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseUpdateTargetBracketLevel} ResponseUpdateTargetBracketLevel
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseUpdateTargetBracketLevel.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseUpdateTargetBracketLevel message.
       * @function verify
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseUpdateTargetBracketLevel.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseUpdateTargetBracketLevel message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseUpdateTargetBracketLevel} ResponseUpdateTargetBracketLevel
       */
      ResponseUpdateTargetBracketLevel.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseUpdateTargetBracketLevel)
          return object;
        var message = new $root.rti.ResponseUpdateTargetBracketLevel();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseUpdateTargetBracketLevel.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseUpdateTargetBracketLevel.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseUpdateTargetBracketLevel message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {rti.ResponseUpdateTargetBracketLevel} message ResponseUpdateTargetBracketLevel
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseUpdateTargetBracketLevel.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseUpdateTargetBracketLevel to JSON.
       * @function toJSON
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseUpdateTargetBracketLevel.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseUpdateTargetBracketLevel
       * @function getTypeUrl
       * @memberof rti.ResponseUpdateTargetBracketLevel
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseUpdateTargetBracketLevel.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseUpdateTargetBracketLevel';
      };

      return ResponseUpdateTargetBracketLevel;
    })();

    rti.RequestUpdateStopBracketLevel = (function () {
      /**
       * Properties of a RequestUpdateStopBracketLevel.
       * @memberof rti
       * @interface IRequestUpdateStopBracketLevel
       * @property {number} templateId RequestUpdateStopBracketLevel templateId
       * @property {Array.<string>|null} [userMsg] RequestUpdateStopBracketLevel userMsg
       * @property {string|null} [fcmId] RequestUpdateStopBracketLevel fcmId
       * @property {string|null} [ibId] RequestUpdateStopBracketLevel ibId
       * @property {string|null} [accountId] RequestUpdateStopBracketLevel accountId
       * @property {string|null} [basketId] RequestUpdateStopBracketLevel basketId
       * @property {number|null} [level] RequestUpdateStopBracketLevel level
       * @property {number|null} [stopTicks] RequestUpdateStopBracketLevel stopTicks
       */

      /**
       * Constructs a new RequestUpdateStopBracketLevel.
       * @memberof rti
       * @classdesc Represents a RequestUpdateStopBracketLevel.
       * @implements IRequestUpdateStopBracketLevel
       * @constructor
       * @param {rti.IRequestUpdateStopBracketLevel=} [properties] Properties to set
       */
      function RequestUpdateStopBracketLevel(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestUpdateStopBracketLevel templateId.
       * @member {number} templateId
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       */
      RequestUpdateStopBracketLevel.prototype.templateId = 0;

      /**
       * RequestUpdateStopBracketLevel userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       */
      RequestUpdateStopBracketLevel.prototype.userMsg = $util.emptyArray;

      /**
       * RequestUpdateStopBracketLevel fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       */
      RequestUpdateStopBracketLevel.prototype.fcmId = '';

      /**
       * RequestUpdateStopBracketLevel ibId.
       * @member {string} ibId
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       */
      RequestUpdateStopBracketLevel.prototype.ibId = '';

      /**
       * RequestUpdateStopBracketLevel accountId.
       * @member {string} accountId
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       */
      RequestUpdateStopBracketLevel.prototype.accountId = '';

      /**
       * RequestUpdateStopBracketLevel basketId.
       * @member {string} basketId
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       */
      RequestUpdateStopBracketLevel.prototype.basketId = '';

      /**
       * RequestUpdateStopBracketLevel level.
       * @member {number} level
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       */
      RequestUpdateStopBracketLevel.prototype.level = 0;

      /**
       * RequestUpdateStopBracketLevel stopTicks.
       * @member {number} stopTicks
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       */
      RequestUpdateStopBracketLevel.prototype.stopTicks = 0;

      /**
       * Creates a new RequestUpdateStopBracketLevel instance using the specified properties.
       * @function create
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {rti.IRequestUpdateStopBracketLevel=} [properties] Properties to set
       * @returns {rti.RequestUpdateStopBracketLevel} RequestUpdateStopBracketLevel instance
       */
      RequestUpdateStopBracketLevel.create = function create(properties) {
        return new RequestUpdateStopBracketLevel(properties);
      };

      /**
       * Encodes the specified RequestUpdateStopBracketLevel message. Does not implicitly {@link rti.RequestUpdateStopBracketLevel.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {rti.IRequestUpdateStopBracketLevel} message RequestUpdateStopBracketLevel message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestUpdateStopBracketLevel.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.level != null &&
          Object.hasOwnProperty.call(message, 'level')
        )
          writer
            .uint32(/* id 154244, wireType 0 =*/ 1233952)
            .int32(message.level);
        if (
          message.stopTicks != null &&
          Object.hasOwnProperty.call(message, 'stopTicks')
        )
          writer
            .uint32(/* id 154458, wireType 0 =*/ 1235664)
            .int32(message.stopTicks);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestUpdateStopBracketLevel message, length delimited. Does not implicitly {@link rti.RequestUpdateStopBracketLevel.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {rti.IRequestUpdateStopBracketLevel} message RequestUpdateStopBracketLevel message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestUpdateStopBracketLevel.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestUpdateStopBracketLevel message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestUpdateStopBracketLevel} RequestUpdateStopBracketLevel
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestUpdateStopBracketLevel.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestUpdateStopBracketLevel();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 154244:
              message.level = reader.int32();
              break;
            case 154458:
              message.stopTicks = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestUpdateStopBracketLevel message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestUpdateStopBracketLevel} RequestUpdateStopBracketLevel
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestUpdateStopBracketLevel.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestUpdateStopBracketLevel message.
       * @function verify
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestUpdateStopBracketLevel.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.level != null && message.hasOwnProperty('level'))
          if (!$util.isInteger(message.level)) return 'level: integer expected';
        if (message.stopTicks != null && message.hasOwnProperty('stopTicks'))
          if (!$util.isInteger(message.stopTicks))
            return 'stopTicks: integer expected';
        return null;
      };

      /**
       * Creates a RequestUpdateStopBracketLevel message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestUpdateStopBracketLevel} RequestUpdateStopBracketLevel
       */
      RequestUpdateStopBracketLevel.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestUpdateStopBracketLevel)
          return object;
        var message = new $root.rti.RequestUpdateStopBracketLevel();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestUpdateStopBracketLevel.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.level != null) message.level = object.level | 0;
        if (object.stopTicks != null) message.stopTicks = object.stopTicks | 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestUpdateStopBracketLevel message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {rti.RequestUpdateStopBracketLevel} message RequestUpdateStopBracketLevel
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestUpdateStopBracketLevel.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.basketId = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.level = 0;
          object.stopTicks = 0;
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.level != null && message.hasOwnProperty('level'))
          object.level = message.level;
        if (message.stopTicks != null && message.hasOwnProperty('stopTicks'))
          object.stopTicks = message.stopTicks;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestUpdateStopBracketLevel to JSON.
       * @function toJSON
       * @memberof rti.RequestUpdateStopBracketLevel
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestUpdateStopBracketLevel.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestUpdateStopBracketLevel
       * @function getTypeUrl
       * @memberof rti.RequestUpdateStopBracketLevel
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestUpdateStopBracketLevel.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestUpdateStopBracketLevel';
      };

      return RequestUpdateStopBracketLevel;
    })();

    rti.ResponseUpdateStopBracketLevel = (function () {
      /**
       * Properties of a ResponseUpdateStopBracketLevel.
       * @memberof rti
       * @interface IResponseUpdateStopBracketLevel
       * @property {number} templateId ResponseUpdateStopBracketLevel templateId
       * @property {Array.<string>|null} [userMsg] ResponseUpdateStopBracketLevel userMsg
       * @property {Array.<string>|null} [rpCode] ResponseUpdateStopBracketLevel rpCode
       */

      /**
       * Constructs a new ResponseUpdateStopBracketLevel.
       * @memberof rti
       * @classdesc Represents a ResponseUpdateStopBracketLevel.
       * @implements IResponseUpdateStopBracketLevel
       * @constructor
       * @param {rti.IResponseUpdateStopBracketLevel=} [properties] Properties to set
       */
      function ResponseUpdateStopBracketLevel(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseUpdateStopBracketLevel templateId.
       * @member {number} templateId
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @instance
       */
      ResponseUpdateStopBracketLevel.prototype.templateId = 0;

      /**
       * ResponseUpdateStopBracketLevel userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @instance
       */
      ResponseUpdateStopBracketLevel.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseUpdateStopBracketLevel rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @instance
       */
      ResponseUpdateStopBracketLevel.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseUpdateStopBracketLevel instance using the specified properties.
       * @function create
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {rti.IResponseUpdateStopBracketLevel=} [properties] Properties to set
       * @returns {rti.ResponseUpdateStopBracketLevel} ResponseUpdateStopBracketLevel instance
       */
      ResponseUpdateStopBracketLevel.create = function create(properties) {
        return new ResponseUpdateStopBracketLevel(properties);
      };

      /**
       * Encodes the specified ResponseUpdateStopBracketLevel message. Does not implicitly {@link rti.ResponseUpdateStopBracketLevel.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {rti.IResponseUpdateStopBracketLevel} message ResponseUpdateStopBracketLevel message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseUpdateStopBracketLevel.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseUpdateStopBracketLevel message, length delimited. Does not implicitly {@link rti.ResponseUpdateStopBracketLevel.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {rti.IResponseUpdateStopBracketLevel} message ResponseUpdateStopBracketLevel message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseUpdateStopBracketLevel.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseUpdateStopBracketLevel message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseUpdateStopBracketLevel} ResponseUpdateStopBracketLevel
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseUpdateStopBracketLevel.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseUpdateStopBracketLevel();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseUpdateStopBracketLevel message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseUpdateStopBracketLevel} ResponseUpdateStopBracketLevel
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseUpdateStopBracketLevel.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseUpdateStopBracketLevel message.
       * @function verify
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseUpdateStopBracketLevel.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseUpdateStopBracketLevel message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseUpdateStopBracketLevel} ResponseUpdateStopBracketLevel
       */
      ResponseUpdateStopBracketLevel.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseUpdateStopBracketLevel)
          return object;
        var message = new $root.rti.ResponseUpdateStopBracketLevel();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseUpdateStopBracketLevel.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseUpdateStopBracketLevel.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseUpdateStopBracketLevel message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {rti.ResponseUpdateStopBracketLevel} message ResponseUpdateStopBracketLevel
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseUpdateStopBracketLevel.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseUpdateStopBracketLevel to JSON.
       * @function toJSON
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseUpdateStopBracketLevel.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseUpdateStopBracketLevel
       * @function getTypeUrl
       * @memberof rti.ResponseUpdateStopBracketLevel
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseUpdateStopBracketLevel.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseUpdateStopBracketLevel';
      };

      return ResponseUpdateStopBracketLevel;
    })();

    rti.RequestSubscribeToBracketUpdates = (function () {
      /**
       * Properties of a RequestSubscribeToBracketUpdates.
       * @memberof rti
       * @interface IRequestSubscribeToBracketUpdates
       * @property {number} templateId RequestSubscribeToBracketUpdates templateId
       * @property {Array.<string>|null} [userMsg] RequestSubscribeToBracketUpdates userMsg
       * @property {string|null} [fcmId] RequestSubscribeToBracketUpdates fcmId
       * @property {string|null} [ibId] RequestSubscribeToBracketUpdates ibId
       * @property {string|null} [accountId] RequestSubscribeToBracketUpdates accountId
       */

      /**
       * Constructs a new RequestSubscribeToBracketUpdates.
       * @memberof rti
       * @classdesc Represents a RequestSubscribeToBracketUpdates.
       * @implements IRequestSubscribeToBracketUpdates
       * @constructor
       * @param {rti.IRequestSubscribeToBracketUpdates=} [properties] Properties to set
       */
      function RequestSubscribeToBracketUpdates(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestSubscribeToBracketUpdates templateId.
       * @member {number} templateId
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @instance
       */
      RequestSubscribeToBracketUpdates.prototype.templateId = 0;

      /**
       * RequestSubscribeToBracketUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @instance
       */
      RequestSubscribeToBracketUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * RequestSubscribeToBracketUpdates fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @instance
       */
      RequestSubscribeToBracketUpdates.prototype.fcmId = '';

      /**
       * RequestSubscribeToBracketUpdates ibId.
       * @member {string} ibId
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @instance
       */
      RequestSubscribeToBracketUpdates.prototype.ibId = '';

      /**
       * RequestSubscribeToBracketUpdates accountId.
       * @member {string} accountId
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @instance
       */
      RequestSubscribeToBracketUpdates.prototype.accountId = '';

      /**
       * Creates a new RequestSubscribeToBracketUpdates instance using the specified properties.
       * @function create
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {rti.IRequestSubscribeToBracketUpdates=} [properties] Properties to set
       * @returns {rti.RequestSubscribeToBracketUpdates} RequestSubscribeToBracketUpdates instance
       */
      RequestSubscribeToBracketUpdates.create = function create(properties) {
        return new RequestSubscribeToBracketUpdates(properties);
      };

      /**
       * Encodes the specified RequestSubscribeToBracketUpdates message. Does not implicitly {@link rti.RequestSubscribeToBracketUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {rti.IRequestSubscribeToBracketUpdates} message RequestSubscribeToBracketUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestSubscribeToBracketUpdates.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestSubscribeToBracketUpdates message, length delimited. Does not implicitly {@link rti.RequestSubscribeToBracketUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {rti.IRequestSubscribeToBracketUpdates} message RequestSubscribeToBracketUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestSubscribeToBracketUpdates.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestSubscribeToBracketUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestSubscribeToBracketUpdates} RequestSubscribeToBracketUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestSubscribeToBracketUpdates.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestSubscribeToBracketUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestSubscribeToBracketUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestSubscribeToBracketUpdates} RequestSubscribeToBracketUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestSubscribeToBracketUpdates.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestSubscribeToBracketUpdates message.
       * @function verify
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestSubscribeToBracketUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        return null;
      };

      /**
       * Creates a RequestSubscribeToBracketUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestSubscribeToBracketUpdates} RequestSubscribeToBracketUpdates
       */
      RequestSubscribeToBracketUpdates.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.RequestSubscribeToBracketUpdates)
          return object;
        var message = new $root.rti.RequestSubscribeToBracketUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestSubscribeToBracketUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        return message;
      };

      /**
       * Creates a plain object from a RequestSubscribeToBracketUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {rti.RequestSubscribeToBracketUpdates} message RequestSubscribeToBracketUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestSubscribeToBracketUpdates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestSubscribeToBracketUpdates to JSON.
       * @function toJSON
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestSubscribeToBracketUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestSubscribeToBracketUpdates
       * @function getTypeUrl
       * @memberof rti.RequestSubscribeToBracketUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestSubscribeToBracketUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestSubscribeToBracketUpdates';
      };

      return RequestSubscribeToBracketUpdates;
    })();

    rti.ResponseSubscribeToBracketUpdates = (function () {
      /**
       * Properties of a ResponseSubscribeToBracketUpdates.
       * @memberof rti
       * @interface IResponseSubscribeToBracketUpdates
       * @property {number} templateId ResponseSubscribeToBracketUpdates templateId
       * @property {Array.<string>|null} [userMsg] ResponseSubscribeToBracketUpdates userMsg
       * @property {Array.<string>|null} [rpCode] ResponseSubscribeToBracketUpdates rpCode
       */

      /**
       * Constructs a new ResponseSubscribeToBracketUpdates.
       * @memberof rti
       * @classdesc Represents a ResponseSubscribeToBracketUpdates.
       * @implements IResponseSubscribeToBracketUpdates
       * @constructor
       * @param {rti.IResponseSubscribeToBracketUpdates=} [properties] Properties to set
       */
      function ResponseSubscribeToBracketUpdates(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseSubscribeToBracketUpdates templateId.
       * @member {number} templateId
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @instance
       */
      ResponseSubscribeToBracketUpdates.prototype.templateId = 0;

      /**
       * ResponseSubscribeToBracketUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @instance
       */
      ResponseSubscribeToBracketUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseSubscribeToBracketUpdates rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @instance
       */
      ResponseSubscribeToBracketUpdates.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseSubscribeToBracketUpdates instance using the specified properties.
       * @function create
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {rti.IResponseSubscribeToBracketUpdates=} [properties] Properties to set
       * @returns {rti.ResponseSubscribeToBracketUpdates} ResponseSubscribeToBracketUpdates instance
       */
      ResponseSubscribeToBracketUpdates.create = function create(properties) {
        return new ResponseSubscribeToBracketUpdates(properties);
      };

      /**
       * Encodes the specified ResponseSubscribeToBracketUpdates message. Does not implicitly {@link rti.ResponseSubscribeToBracketUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {rti.IResponseSubscribeToBracketUpdates} message ResponseSubscribeToBracketUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseSubscribeToBracketUpdates.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseSubscribeToBracketUpdates message, length delimited. Does not implicitly {@link rti.ResponseSubscribeToBracketUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {rti.IResponseSubscribeToBracketUpdates} message ResponseSubscribeToBracketUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseSubscribeToBracketUpdates.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseSubscribeToBracketUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseSubscribeToBracketUpdates} ResponseSubscribeToBracketUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseSubscribeToBracketUpdates.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseSubscribeToBracketUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseSubscribeToBracketUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseSubscribeToBracketUpdates} ResponseSubscribeToBracketUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseSubscribeToBracketUpdates.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseSubscribeToBracketUpdates message.
       * @function verify
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseSubscribeToBracketUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseSubscribeToBracketUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseSubscribeToBracketUpdates} ResponseSubscribeToBracketUpdates
       */
      ResponseSubscribeToBracketUpdates.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseSubscribeToBracketUpdates)
          return object;
        var message = new $root.rti.ResponseSubscribeToBracketUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseSubscribeToBracketUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseSubscribeToBracketUpdates.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseSubscribeToBracketUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {rti.ResponseSubscribeToBracketUpdates} message ResponseSubscribeToBracketUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseSubscribeToBracketUpdates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseSubscribeToBracketUpdates to JSON.
       * @function toJSON
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseSubscribeToBracketUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseSubscribeToBracketUpdates
       * @function getTypeUrl
       * @memberof rti.ResponseSubscribeToBracketUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseSubscribeToBracketUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseSubscribeToBracketUpdates';
      };

      return ResponseSubscribeToBracketUpdates;
    })();

    rti.RequestListExchangePermissions = (function () {
      /**
       * Properties of a RequestListExchangePermissions.
       * @memberof rti
       * @interface IRequestListExchangePermissions
       * @property {number} templateId RequestListExchangePermissions templateId
       * @property {Array.<string>|null} [userMsg] RequestListExchangePermissions userMsg
       * @property {string|null} [user] RequestListExchangePermissions user
       */

      /**
       * Constructs a new RequestListExchangePermissions.
       * @memberof rti
       * @classdesc Represents a RequestListExchangePermissions.
       * @implements IRequestListExchangePermissions
       * @constructor
       * @param {rti.IRequestListExchangePermissions=} [properties] Properties to set
       */
      function RequestListExchangePermissions(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestListExchangePermissions templateId.
       * @member {number} templateId
       * @memberof rti.RequestListExchangePermissions
       * @instance
       */
      RequestListExchangePermissions.prototype.templateId = 0;

      /**
       * RequestListExchangePermissions userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestListExchangePermissions
       * @instance
       */
      RequestListExchangePermissions.prototype.userMsg = $util.emptyArray;

      /**
       * RequestListExchangePermissions user.
       * @member {string} user
       * @memberof rti.RequestListExchangePermissions
       * @instance
       */
      RequestListExchangePermissions.prototype.user = '';

      /**
       * Creates a new RequestListExchangePermissions instance using the specified properties.
       * @function create
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {rti.IRequestListExchangePermissions=} [properties] Properties to set
       * @returns {rti.RequestListExchangePermissions} RequestListExchangePermissions instance
       */
      RequestListExchangePermissions.create = function create(properties) {
        return new RequestListExchangePermissions(properties);
      };

      /**
       * Encodes the specified RequestListExchangePermissions message. Does not implicitly {@link rti.RequestListExchangePermissions.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {rti.IRequestListExchangePermissions} message RequestListExchangePermissions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestListExchangePermissions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.user != null && Object.hasOwnProperty.call(message, 'user'))
          writer
            .uint32(/* id 154220, wireType 2 =*/ 1233762)
            .string(message.user);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestListExchangePermissions message, length delimited. Does not implicitly {@link rti.RequestListExchangePermissions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {rti.IRequestListExchangePermissions} message RequestListExchangePermissions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestListExchangePermissions.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestListExchangePermissions message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestListExchangePermissions} RequestListExchangePermissions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestListExchangePermissions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestListExchangePermissions();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154220:
              message.user = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestListExchangePermissions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestListExchangePermissions} RequestListExchangePermissions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestListExchangePermissions.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestListExchangePermissions message.
       * @function verify
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestListExchangePermissions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.user != null && message.hasOwnProperty('user'))
          if (!$util.isString(message.user)) return 'user: string expected';
        return null;
      };

      /**
       * Creates a RequestListExchangePermissions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestListExchangePermissions} RequestListExchangePermissions
       */
      RequestListExchangePermissions.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestListExchangePermissions)
          return object;
        var message = new $root.rti.RequestListExchangePermissions();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestListExchangePermissions.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.user != null) message.user = String(object.user);
        return message;
      };

      /**
       * Creates a plain object from a RequestListExchangePermissions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {rti.RequestListExchangePermissions} message RequestListExchangePermissions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestListExchangePermissions.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.user = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.user != null && message.hasOwnProperty('user'))
          object.user = message.user;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestListExchangePermissions to JSON.
       * @function toJSON
       * @memberof rti.RequestListExchangePermissions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestListExchangePermissions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestListExchangePermissions
       * @function getTypeUrl
       * @memberof rti.RequestListExchangePermissions
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestListExchangePermissions.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestListExchangePermissions';
      };

      return RequestListExchangePermissions;
    })();

    rti.ResponseListExchangePermissions = (function () {
      /**
       * Properties of a ResponseListExchangePermissions.
       * @memberof rti
       * @interface IResponseListExchangePermissions
       * @property {number} templateId ResponseListExchangePermissions templateId
       * @property {Array.<string>|null} [userMsg] ResponseListExchangePermissions userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseListExchangePermissions rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseListExchangePermissions rpCode
       * @property {string|null} [exchange] ResponseListExchangePermissions exchange
       * @property {rti.ResponseListExchangePermissions.EntitlementFlag|null} [entitlementFlag] ResponseListExchangePermissions entitlementFlag
       */

      /**
       * Constructs a new ResponseListExchangePermissions.
       * @memberof rti
       * @classdesc Represents a ResponseListExchangePermissions.
       * @implements IResponseListExchangePermissions
       * @constructor
       * @param {rti.IResponseListExchangePermissions=} [properties] Properties to set
       */
      function ResponseListExchangePermissions(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseListExchangePermissions templateId.
       * @member {number} templateId
       * @memberof rti.ResponseListExchangePermissions
       * @instance
       */
      ResponseListExchangePermissions.prototype.templateId = 0;

      /**
       * ResponseListExchangePermissions userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseListExchangePermissions
       * @instance
       */
      ResponseListExchangePermissions.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseListExchangePermissions rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseListExchangePermissions
       * @instance
       */
      ResponseListExchangePermissions.prototype.rqHandlerRpCode =
        $util.emptyArray;

      /**
       * ResponseListExchangePermissions rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseListExchangePermissions
       * @instance
       */
      ResponseListExchangePermissions.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseListExchangePermissions exchange.
       * @member {string} exchange
       * @memberof rti.ResponseListExchangePermissions
       * @instance
       */
      ResponseListExchangePermissions.prototype.exchange = '';

      /**
       * ResponseListExchangePermissions entitlementFlag.
       * @member {rti.ResponseListExchangePermissions.EntitlementFlag} entitlementFlag
       * @memberof rti.ResponseListExchangePermissions
       * @instance
       */
      ResponseListExchangePermissions.prototype.entitlementFlag = 1;

      /**
       * Creates a new ResponseListExchangePermissions instance using the specified properties.
       * @function create
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {rti.IResponseListExchangePermissions=} [properties] Properties to set
       * @returns {rti.ResponseListExchangePermissions} ResponseListExchangePermissions instance
       */
      ResponseListExchangePermissions.create = function create(properties) {
        return new ResponseListExchangePermissions(properties);
      };

      /**
       * Encodes the specified ResponseListExchangePermissions message. Does not implicitly {@link rti.ResponseListExchangePermissions.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {rti.IResponseListExchangePermissions} message ResponseListExchangePermissions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseListExchangePermissions.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.entitlementFlag != null &&
          Object.hasOwnProperty.call(message, 'entitlementFlag')
        )
          writer
            .uint32(/* id 153400, wireType 0 =*/ 1227200)
            .int32(message.entitlementFlag);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseListExchangePermissions message, length delimited. Does not implicitly {@link rti.ResponseListExchangePermissions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {rti.IResponseListExchangePermissions} message ResponseListExchangePermissions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseListExchangePermissions.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseListExchangePermissions message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseListExchangePermissions} ResponseListExchangePermissions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseListExchangePermissions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseListExchangePermissions();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 153400:
              message.entitlementFlag = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseListExchangePermissions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseListExchangePermissions} ResponseListExchangePermissions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseListExchangePermissions.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseListExchangePermissions message.
       * @function verify
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseListExchangePermissions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.entitlementFlag != null &&
          message.hasOwnProperty('entitlementFlag')
        )
          switch (message.entitlementFlag) {
            default:
              return 'entitlementFlag: enum value expected';
            case 1:
            case 2:
              break;
          }
        return null;
      };

      /**
       * Creates a ResponseListExchangePermissions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseListExchangePermissions} ResponseListExchangePermissions
       */
      ResponseListExchangePermissions.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseListExchangePermissions)
          return object;
        var message = new $root.rti.ResponseListExchangePermissions();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseListExchangePermissions.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseListExchangePermissions.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseListExchangePermissions.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.entitlementFlag) {
          case 'ENABLED':
          case 1:
            message.entitlementFlag = 1;
            break;
          case 'DISABLED':
          case 2:
            message.entitlementFlag = 2;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseListExchangePermissions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {rti.ResponseListExchangePermissions} message ResponseListExchangePermissions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseListExchangePermissions.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.exchange = '';
          object.entitlementFlag = options.enums === String ? 'ENABLED' : 1;
          object.templateId = 0;
        }
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.entitlementFlag != null &&
          message.hasOwnProperty('entitlementFlag')
        )
          object.entitlementFlag =
            options.enums === String
              ? $root.rti.ResponseListExchangePermissions.EntitlementFlag[
                  message.entitlementFlag
                ]
              : message.entitlementFlag;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseListExchangePermissions to JSON.
       * @function toJSON
       * @memberof rti.ResponseListExchangePermissions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseListExchangePermissions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseListExchangePermissions
       * @function getTypeUrl
       * @memberof rti.ResponseListExchangePermissions
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseListExchangePermissions.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseListExchangePermissions';
      };

      /**
       * EntitlementFlag enum.
       * @name rti.ResponseListExchangePermissions.EntitlementFlag
       * @enum {number}
       * @property {number} ENABLED=1 ENABLED value
       * @property {number} DISABLED=2 DISABLED value
       */
      ResponseListExchangePermissions.EntitlementFlag = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'ENABLED')] = 1;
        values[(valuesById[2] = 'DISABLED')] = 2;
        return values;
      })();

      return ResponseListExchangePermissions;
    })();

    rti.RequestLinkOrders = (function () {
      /**
       * Properties of a RequestLinkOrders.
       * @memberof rti
       * @interface IRequestLinkOrders
       * @property {number} templateId RequestLinkOrders templateId
       * @property {Array.<string>|null} [userMsg] RequestLinkOrders userMsg
       * @property {Array.<string>|null} [fcmId] RequestLinkOrders fcmId
       * @property {Array.<string>|null} [ibId] RequestLinkOrders ibId
       * @property {Array.<string>|null} [accountId] RequestLinkOrders accountId
       * @property {Array.<string>|null} [basketId] RequestLinkOrders basketId
       */

      /**
       * Constructs a new RequestLinkOrders.
       * @memberof rti
       * @classdesc Represents a RequestLinkOrders.
       * @implements IRequestLinkOrders
       * @constructor
       * @param {rti.IRequestLinkOrders=} [properties] Properties to set
       */
      function RequestLinkOrders(properties) {
        this.userMsg = [];
        this.fcmId = [];
        this.ibId = [];
        this.accountId = [];
        this.basketId = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestLinkOrders templateId.
       * @member {number} templateId
       * @memberof rti.RequestLinkOrders
       * @instance
       */
      RequestLinkOrders.prototype.templateId = 0;

      /**
       * RequestLinkOrders userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestLinkOrders
       * @instance
       */
      RequestLinkOrders.prototype.userMsg = $util.emptyArray;

      /**
       * RequestLinkOrders fcmId.
       * @member {Array.<string>} fcmId
       * @memberof rti.RequestLinkOrders
       * @instance
       */
      RequestLinkOrders.prototype.fcmId = $util.emptyArray;

      /**
       * RequestLinkOrders ibId.
       * @member {Array.<string>} ibId
       * @memberof rti.RequestLinkOrders
       * @instance
       */
      RequestLinkOrders.prototype.ibId = $util.emptyArray;

      /**
       * RequestLinkOrders accountId.
       * @member {Array.<string>} accountId
       * @memberof rti.RequestLinkOrders
       * @instance
       */
      RequestLinkOrders.prototype.accountId = $util.emptyArray;

      /**
       * RequestLinkOrders basketId.
       * @member {Array.<string>} basketId
       * @memberof rti.RequestLinkOrders
       * @instance
       */
      RequestLinkOrders.prototype.basketId = $util.emptyArray;

      /**
       * Creates a new RequestLinkOrders instance using the specified properties.
       * @function create
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {rti.IRequestLinkOrders=} [properties] Properties to set
       * @returns {rti.RequestLinkOrders} RequestLinkOrders instance
       */
      RequestLinkOrders.create = function create(properties) {
        return new RequestLinkOrders(properties);
      };

      /**
       * Encodes the specified RequestLinkOrders message. Does not implicitly {@link rti.RequestLinkOrders.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {rti.IRequestLinkOrders} message RequestLinkOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestLinkOrders.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.basketId != null && message.basketId.length)
          for (var i = 0; i < message.basketId.length; ++i)
            writer
              .uint32(/* id 110300, wireType 2 =*/ 882402)
              .string(message.basketId[i]);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.accountId != null && message.accountId.length)
          for (var i = 0; i < message.accountId.length; ++i)
            writer
              .uint32(/* id 154008, wireType 2 =*/ 1232066)
              .string(message.accountId[i]);
        if (message.fcmId != null && message.fcmId.length)
          for (var i = 0; i < message.fcmId.length; ++i)
            writer
              .uint32(/* id 154013, wireType 2 =*/ 1232106)
              .string(message.fcmId[i]);
        if (message.ibId != null && message.ibId.length)
          for (var i = 0; i < message.ibId.length; ++i)
            writer
              .uint32(/* id 154014, wireType 2 =*/ 1232114)
              .string(message.ibId[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestLinkOrders message, length delimited. Does not implicitly {@link rti.RequestLinkOrders.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {rti.IRequestLinkOrders} message RequestLinkOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestLinkOrders.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestLinkOrders message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestLinkOrders} RequestLinkOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestLinkOrders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestLinkOrders();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              if (!(message.fcmId && message.fcmId.length)) message.fcmId = [];
              message.fcmId.push(reader.string());
              break;
            case 154014:
              if (!(message.ibId && message.ibId.length)) message.ibId = [];
              message.ibId.push(reader.string());
              break;
            case 154008:
              if (!(message.accountId && message.accountId.length))
                message.accountId = [];
              message.accountId.push(reader.string());
              break;
            case 110300:
              if (!(message.basketId && message.basketId.length))
                message.basketId = [];
              message.basketId.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestLinkOrders message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestLinkOrders} RequestLinkOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestLinkOrders.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestLinkOrders message.
       * @function verify
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestLinkOrders.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId')) {
          if (!Array.isArray(message.fcmId)) return 'fcmId: array expected';
          for (var i = 0; i < message.fcmId.length; ++i)
            if (!$util.isString(message.fcmId[i]))
              return 'fcmId: string[] expected';
        }
        if (message.ibId != null && message.hasOwnProperty('ibId')) {
          if (!Array.isArray(message.ibId)) return 'ibId: array expected';
          for (var i = 0; i < message.ibId.length; ++i)
            if (!$util.isString(message.ibId[i]))
              return 'ibId: string[] expected';
        }
        if (message.accountId != null && message.hasOwnProperty('accountId')) {
          if (!Array.isArray(message.accountId))
            return 'accountId: array expected';
          for (var i = 0; i < message.accountId.length; ++i)
            if (!$util.isString(message.accountId[i]))
              return 'accountId: string[] expected';
        }
        if (message.basketId != null && message.hasOwnProperty('basketId')) {
          if (!Array.isArray(message.basketId))
            return 'basketId: array expected';
          for (var i = 0; i < message.basketId.length; ++i)
            if (!$util.isString(message.basketId[i]))
              return 'basketId: string[] expected';
        }
        return null;
      };

      /**
       * Creates a RequestLinkOrders message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestLinkOrders} RequestLinkOrders
       */
      RequestLinkOrders.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestLinkOrders) return object;
        var message = new $root.rti.RequestLinkOrders();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestLinkOrders.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId) {
          if (!Array.isArray(object.fcmId))
            throw TypeError('.rti.RequestLinkOrders.fcmId: array expected');
          message.fcmId = [];
          for (var i = 0; i < object.fcmId.length; ++i)
            message.fcmId[i] = String(object.fcmId[i]);
        }
        if (object.ibId) {
          if (!Array.isArray(object.ibId))
            throw TypeError('.rti.RequestLinkOrders.ibId: array expected');
          message.ibId = [];
          for (var i = 0; i < object.ibId.length; ++i)
            message.ibId[i] = String(object.ibId[i]);
        }
        if (object.accountId) {
          if (!Array.isArray(object.accountId))
            throw TypeError('.rti.RequestLinkOrders.accountId: array expected');
          message.accountId = [];
          for (var i = 0; i < object.accountId.length; ++i)
            message.accountId[i] = String(object.accountId[i]);
        }
        if (object.basketId) {
          if (!Array.isArray(object.basketId))
            throw TypeError('.rti.RequestLinkOrders.basketId: array expected');
          message.basketId = [];
          for (var i = 0; i < object.basketId.length; ++i)
            message.basketId[i] = String(object.basketId[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestLinkOrders message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {rti.RequestLinkOrders} message RequestLinkOrders
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestLinkOrders.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.basketId = [];
          object.userMsg = [];
          object.accountId = [];
          object.fcmId = [];
          object.ibId = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.basketId && message.basketId.length) {
          object.basketId = [];
          for (var j = 0; j < message.basketId.length; ++j)
            object.basketId[j] = message.basketId[j];
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId && message.accountId.length) {
          object.accountId = [];
          for (var j = 0; j < message.accountId.length; ++j)
            object.accountId[j] = message.accountId[j];
        }
        if (message.fcmId && message.fcmId.length) {
          object.fcmId = [];
          for (var j = 0; j < message.fcmId.length; ++j)
            object.fcmId[j] = message.fcmId[j];
        }
        if (message.ibId && message.ibId.length) {
          object.ibId = [];
          for (var j = 0; j < message.ibId.length; ++j)
            object.ibId[j] = message.ibId[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestLinkOrders to JSON.
       * @function toJSON
       * @memberof rti.RequestLinkOrders
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestLinkOrders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestLinkOrders
       * @function getTypeUrl
       * @memberof rti.RequestLinkOrders
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestLinkOrders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestLinkOrders';
      };

      return RequestLinkOrders;
    })();

    rti.ResponseLinkOrders = (function () {
      /**
       * Properties of a ResponseLinkOrders.
       * @memberof rti
       * @interface IResponseLinkOrders
       * @property {number} templateId ResponseLinkOrders templateId
       * @property {Array.<string>|null} [userMsg] ResponseLinkOrders userMsg
       * @property {Array.<string>|null} [rpCode] ResponseLinkOrders rpCode
       */

      /**
       * Constructs a new ResponseLinkOrders.
       * @memberof rti
       * @classdesc Represents a ResponseLinkOrders.
       * @implements IResponseLinkOrders
       * @constructor
       * @param {rti.IResponseLinkOrders=} [properties] Properties to set
       */
      function ResponseLinkOrders(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseLinkOrders templateId.
       * @member {number} templateId
       * @memberof rti.ResponseLinkOrders
       * @instance
       */
      ResponseLinkOrders.prototype.templateId = 0;

      /**
       * ResponseLinkOrders userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseLinkOrders
       * @instance
       */
      ResponseLinkOrders.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseLinkOrders rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseLinkOrders
       * @instance
       */
      ResponseLinkOrders.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseLinkOrders instance using the specified properties.
       * @function create
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {rti.IResponseLinkOrders=} [properties] Properties to set
       * @returns {rti.ResponseLinkOrders} ResponseLinkOrders instance
       */
      ResponseLinkOrders.create = function create(properties) {
        return new ResponseLinkOrders(properties);
      };

      /**
       * Encodes the specified ResponseLinkOrders message. Does not implicitly {@link rti.ResponseLinkOrders.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {rti.IResponseLinkOrders} message ResponseLinkOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseLinkOrders.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseLinkOrders message, length delimited. Does not implicitly {@link rti.ResponseLinkOrders.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {rti.IResponseLinkOrders} message ResponseLinkOrders message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseLinkOrders.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseLinkOrders message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseLinkOrders} ResponseLinkOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseLinkOrders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseLinkOrders();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseLinkOrders message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseLinkOrders} ResponseLinkOrders
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseLinkOrders.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseLinkOrders message.
       * @function verify
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseLinkOrders.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseLinkOrders message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseLinkOrders} ResponseLinkOrders
       */
      ResponseLinkOrders.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseLinkOrders) return object;
        var message = new $root.rti.ResponseLinkOrders();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseLinkOrders.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseLinkOrders.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseLinkOrders message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {rti.ResponseLinkOrders} message ResponseLinkOrders
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseLinkOrders.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseLinkOrders to JSON.
       * @function toJSON
       * @memberof rti.ResponseLinkOrders
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseLinkOrders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseLinkOrders
       * @function getTypeUrl
       * @memberof rti.ResponseLinkOrders
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseLinkOrders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseLinkOrders';
      };

      return ResponseLinkOrders;
    })();

    rti.RequestEasyToBorrowList = (function () {
      /**
       * Properties of a RequestEasyToBorrowList.
       * @memberof rti
       * @interface IRequestEasyToBorrowList
       * @property {number} templateId RequestEasyToBorrowList templateId
       * @property {Array.<string>|null} [userMsg] RequestEasyToBorrowList userMsg
       * @property {rti.RequestEasyToBorrowList.Request|null} [request] RequestEasyToBorrowList request
       */

      /**
       * Constructs a new RequestEasyToBorrowList.
       * @memberof rti
       * @classdesc Represents a RequestEasyToBorrowList.
       * @implements IRequestEasyToBorrowList
       * @constructor
       * @param {rti.IRequestEasyToBorrowList=} [properties] Properties to set
       */
      function RequestEasyToBorrowList(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestEasyToBorrowList templateId.
       * @member {number} templateId
       * @memberof rti.RequestEasyToBorrowList
       * @instance
       */
      RequestEasyToBorrowList.prototype.templateId = 0;

      /**
       * RequestEasyToBorrowList userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestEasyToBorrowList
       * @instance
       */
      RequestEasyToBorrowList.prototype.userMsg = $util.emptyArray;

      /**
       * RequestEasyToBorrowList request.
       * @member {rti.RequestEasyToBorrowList.Request} request
       * @memberof rti.RequestEasyToBorrowList
       * @instance
       */
      RequestEasyToBorrowList.prototype.request = 1;

      /**
       * Creates a new RequestEasyToBorrowList instance using the specified properties.
       * @function create
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {rti.IRequestEasyToBorrowList=} [properties] Properties to set
       * @returns {rti.RequestEasyToBorrowList} RequestEasyToBorrowList instance
       */
      RequestEasyToBorrowList.create = function create(properties) {
        return new RequestEasyToBorrowList(properties);
      };

      /**
       * Encodes the specified RequestEasyToBorrowList message. Does not implicitly {@link rti.RequestEasyToBorrowList.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {rti.IRequestEasyToBorrowList} message RequestEasyToBorrowList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestEasyToBorrowList.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.request != null &&
          Object.hasOwnProperty.call(message, 'request')
        )
          writer
            .uint32(/* id 100000, wireType 0 =*/ 800000)
            .int32(message.request);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestEasyToBorrowList message, length delimited. Does not implicitly {@link rti.RequestEasyToBorrowList.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {rti.IRequestEasyToBorrowList} message RequestEasyToBorrowList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestEasyToBorrowList.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestEasyToBorrowList message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestEasyToBorrowList} RequestEasyToBorrowList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestEasyToBorrowList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestEasyToBorrowList();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 100000:
              message.request = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestEasyToBorrowList message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestEasyToBorrowList} RequestEasyToBorrowList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestEasyToBorrowList.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestEasyToBorrowList message.
       * @function verify
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestEasyToBorrowList.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.request != null && message.hasOwnProperty('request'))
          switch (message.request) {
            default:
              return 'request: enum value expected';
            case 1:
            case 2:
              break;
          }
        return null;
      };

      /**
       * Creates a RequestEasyToBorrowList message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestEasyToBorrowList} RequestEasyToBorrowList
       */
      RequestEasyToBorrowList.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestEasyToBorrowList) return object;
        var message = new $root.rti.RequestEasyToBorrowList();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestEasyToBorrowList.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        switch (object.request) {
          case 'SUBSCRIBE':
          case 1:
            message.request = 1;
            break;
          case 'UNSUBSCRIBE':
          case 2:
            message.request = 2;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestEasyToBorrowList message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {rti.RequestEasyToBorrowList} message RequestEasyToBorrowList
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestEasyToBorrowList.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.request = options.enums === String ? 'SUBSCRIBE' : 1;
          object.templateId = 0;
        }
        if (message.request != null && message.hasOwnProperty('request'))
          object.request =
            options.enums === String
              ? $root.rti.RequestEasyToBorrowList.Request[message.request]
              : message.request;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestEasyToBorrowList to JSON.
       * @function toJSON
       * @memberof rti.RequestEasyToBorrowList
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestEasyToBorrowList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestEasyToBorrowList
       * @function getTypeUrl
       * @memberof rti.RequestEasyToBorrowList
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestEasyToBorrowList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestEasyToBorrowList';
      };

      /**
       * Request enum.
       * @name rti.RequestEasyToBorrowList.Request
       * @enum {number}
       * @property {number} SUBSCRIBE=1 SUBSCRIBE value
       * @property {number} UNSUBSCRIBE=2 UNSUBSCRIBE value
       */
      RequestEasyToBorrowList.Request = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SUBSCRIBE')] = 1;
        values[(valuesById[2] = 'UNSUBSCRIBE')] = 2;
        return values;
      })();

      return RequestEasyToBorrowList;
    })();

    rti.ResponseEasyToBorrowList = (function () {
      /**
       * Properties of a ResponseEasyToBorrowList.
       * @memberof rti
       * @interface IResponseEasyToBorrowList
       * @property {number} templateId ResponseEasyToBorrowList templateId
       * @property {Array.<string>|null} [userMsg] ResponseEasyToBorrowList userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseEasyToBorrowList rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseEasyToBorrowList rpCode
       * @property {string|null} [brokerDealer] ResponseEasyToBorrowList brokerDealer
       * @property {string|null} [symbol] ResponseEasyToBorrowList symbol
       * @property {string|null} [symbolName] ResponseEasyToBorrowList symbolName
       * @property {number|null} [qtyAvailable] ResponseEasyToBorrowList qtyAvailable
       * @property {number|null} [qtyNeeded] ResponseEasyToBorrowList qtyNeeded
       * @property {boolean|null} [borrowable] ResponseEasyToBorrowList borrowable
       */

      /**
       * Constructs a new ResponseEasyToBorrowList.
       * @memberof rti
       * @classdesc Represents a ResponseEasyToBorrowList.
       * @implements IResponseEasyToBorrowList
       * @constructor
       * @param {rti.IResponseEasyToBorrowList=} [properties] Properties to set
       */
      function ResponseEasyToBorrowList(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseEasyToBorrowList templateId.
       * @member {number} templateId
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.templateId = 0;

      /**
       * ResponseEasyToBorrowList userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseEasyToBorrowList rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseEasyToBorrowList rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseEasyToBorrowList brokerDealer.
       * @member {string} brokerDealer
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.brokerDealer = '';

      /**
       * ResponseEasyToBorrowList symbol.
       * @member {string} symbol
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.symbol = '';

      /**
       * ResponseEasyToBorrowList symbolName.
       * @member {string} symbolName
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.symbolName = '';

      /**
       * ResponseEasyToBorrowList qtyAvailable.
       * @member {number} qtyAvailable
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.qtyAvailable = 0;

      /**
       * ResponseEasyToBorrowList qtyNeeded.
       * @member {number} qtyNeeded
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.qtyNeeded = 0;

      /**
       * ResponseEasyToBorrowList borrowable.
       * @member {boolean} borrowable
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       */
      ResponseEasyToBorrowList.prototype.borrowable = false;

      /**
       * Creates a new ResponseEasyToBorrowList instance using the specified properties.
       * @function create
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {rti.IResponseEasyToBorrowList=} [properties] Properties to set
       * @returns {rti.ResponseEasyToBorrowList} ResponseEasyToBorrowList instance
       */
      ResponseEasyToBorrowList.create = function create(properties) {
        return new ResponseEasyToBorrowList(properties);
      };

      /**
       * Encodes the specified ResponseEasyToBorrowList message. Does not implicitly {@link rti.ResponseEasyToBorrowList.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {rti.IResponseEasyToBorrowList} message ResponseEasyToBorrowList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseEasyToBorrowList.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbolName != null &&
          Object.hasOwnProperty.call(message, 'symbolName')
        )
          writer
            .uint32(/* id 100003, wireType 2 =*/ 800026)
            .string(message.symbolName);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.borrowable != null &&
          Object.hasOwnProperty.call(message, 'borrowable')
        )
          writer
            .uint32(/* id 110353, wireType 0 =*/ 882824)
            .bool(message.borrowable);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.brokerDealer != null &&
          Object.hasOwnProperty.call(message, 'brokerDealer')
        )
          writer
            .uint32(/* id 154612, wireType 2 =*/ 1236898)
            .string(message.brokerDealer);
        if (
          message.qtyAvailable != null &&
          Object.hasOwnProperty.call(message, 'qtyAvailable')
        )
          writer
            .uint32(/* id 154613, wireType 0 =*/ 1236904)
            .int32(message.qtyAvailable);
        if (
          message.qtyNeeded != null &&
          Object.hasOwnProperty.call(message, 'qtyNeeded')
        )
          writer
            .uint32(/* id 154614, wireType 0 =*/ 1236912)
            .int32(message.qtyNeeded);
        return writer;
      };

      /**
       * Encodes the specified ResponseEasyToBorrowList message, length delimited. Does not implicitly {@link rti.ResponseEasyToBorrowList.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {rti.IResponseEasyToBorrowList} message ResponseEasyToBorrowList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseEasyToBorrowList.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseEasyToBorrowList message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseEasyToBorrowList} ResponseEasyToBorrowList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseEasyToBorrowList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseEasyToBorrowList();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 154612:
              message.brokerDealer = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 100003:
              message.symbolName = reader.string();
              break;
            case 154613:
              message.qtyAvailable = reader.int32();
              break;
            case 154614:
              message.qtyNeeded = reader.int32();
              break;
            case 110353:
              message.borrowable = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseEasyToBorrowList message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseEasyToBorrowList} ResponseEasyToBorrowList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseEasyToBorrowList.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseEasyToBorrowList message.
       * @function verify
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseEasyToBorrowList.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.brokerDealer != null &&
          message.hasOwnProperty('brokerDealer')
        )
          if (!$util.isString(message.brokerDealer))
            return 'brokerDealer: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          if (!$util.isString(message.symbolName))
            return 'symbolName: string expected';
        if (
          message.qtyAvailable != null &&
          message.hasOwnProperty('qtyAvailable')
        )
          if (!$util.isInteger(message.qtyAvailable))
            return 'qtyAvailable: integer expected';
        if (message.qtyNeeded != null && message.hasOwnProperty('qtyNeeded'))
          if (!$util.isInteger(message.qtyNeeded))
            return 'qtyNeeded: integer expected';
        if (message.borrowable != null && message.hasOwnProperty('borrowable'))
          if (typeof message.borrowable !== 'boolean')
            return 'borrowable: boolean expected';
        return null;
      };

      /**
       * Creates a ResponseEasyToBorrowList message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseEasyToBorrowList} ResponseEasyToBorrowList
       */
      ResponseEasyToBorrowList.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseEasyToBorrowList) return object;
        var message = new $root.rti.ResponseEasyToBorrowList();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseEasyToBorrowList.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseEasyToBorrowList.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseEasyToBorrowList.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.brokerDealer != null)
          message.brokerDealer = String(object.brokerDealer);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.symbolName != null)
          message.symbolName = String(object.symbolName);
        if (object.qtyAvailable != null)
          message.qtyAvailable = object.qtyAvailable | 0;
        if (object.qtyNeeded != null) message.qtyNeeded = object.qtyNeeded | 0;
        if (object.borrowable != null)
          message.borrowable = Boolean(object.borrowable);
        return message;
      };

      /**
       * Creates a plain object from a ResponseEasyToBorrowList message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {rti.ResponseEasyToBorrowList} message ResponseEasyToBorrowList
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseEasyToBorrowList.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.symbolName = '';
          object.symbol = '';
          object.borrowable = false;
          object.templateId = 0;
          object.brokerDealer = '';
          object.qtyAvailable = 0;
          object.qtyNeeded = 0;
        }
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          object.symbolName = message.symbolName;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.borrowable != null && message.hasOwnProperty('borrowable'))
          object.borrowable = message.borrowable;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.brokerDealer != null &&
          message.hasOwnProperty('brokerDealer')
        )
          object.brokerDealer = message.brokerDealer;
        if (
          message.qtyAvailable != null &&
          message.hasOwnProperty('qtyAvailable')
        )
          object.qtyAvailable = message.qtyAvailable;
        if (message.qtyNeeded != null && message.hasOwnProperty('qtyNeeded'))
          object.qtyNeeded = message.qtyNeeded;
        return object;
      };

      /**
       * Converts this ResponseEasyToBorrowList to JSON.
       * @function toJSON
       * @memberof rti.ResponseEasyToBorrowList
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseEasyToBorrowList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseEasyToBorrowList
       * @function getTypeUrl
       * @memberof rti.ResponseEasyToBorrowList
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseEasyToBorrowList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseEasyToBorrowList';
      };

      return ResponseEasyToBorrowList;
    })();

    rti.RequestOrderSessionConfig = (function () {
      /**
       * Properties of a RequestOrderSessionConfig.
       * @memberof rti
       * @interface IRequestOrderSessionConfig
       * @property {number} templateId RequestOrderSessionConfig templateId
       * @property {Array.<string>|null} [userMsg] RequestOrderSessionConfig userMsg
       * @property {boolean|null} [shouldDeferRequest] RequestOrderSessionConfig shouldDeferRequest
       */

      /**
       * Constructs a new RequestOrderSessionConfig.
       * @memberof rti
       * @classdesc Represents a RequestOrderSessionConfig.
       * @implements IRequestOrderSessionConfig
       * @constructor
       * @param {rti.IRequestOrderSessionConfig=} [properties] Properties to set
       */
      function RequestOrderSessionConfig(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestOrderSessionConfig templateId.
       * @member {number} templateId
       * @memberof rti.RequestOrderSessionConfig
       * @instance
       */
      RequestOrderSessionConfig.prototype.templateId = 0;

      /**
       * RequestOrderSessionConfig userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestOrderSessionConfig
       * @instance
       */
      RequestOrderSessionConfig.prototype.userMsg = $util.emptyArray;

      /**
       * RequestOrderSessionConfig shouldDeferRequest.
       * @member {boolean} shouldDeferRequest
       * @memberof rti.RequestOrderSessionConfig
       * @instance
       */
      RequestOrderSessionConfig.prototype.shouldDeferRequest = false;

      /**
       * Creates a new RequestOrderSessionConfig instance using the specified properties.
       * @function create
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {rti.IRequestOrderSessionConfig=} [properties] Properties to set
       * @returns {rti.RequestOrderSessionConfig} RequestOrderSessionConfig instance
       */
      RequestOrderSessionConfig.create = function create(properties) {
        return new RequestOrderSessionConfig(properties);
      };

      /**
       * Encodes the specified RequestOrderSessionConfig message. Does not implicitly {@link rti.RequestOrderSessionConfig.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {rti.IRequestOrderSessionConfig} message RequestOrderSessionConfig message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestOrderSessionConfig.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.shouldDeferRequest != null &&
          Object.hasOwnProperty.call(message, 'shouldDeferRequest')
        )
          writer
            .uint32(/* id 157750, wireType 0 =*/ 1262000)
            .bool(message.shouldDeferRequest);
        return writer;
      };

      /**
       * Encodes the specified RequestOrderSessionConfig message, length delimited. Does not implicitly {@link rti.RequestOrderSessionConfig.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {rti.IRequestOrderSessionConfig} message RequestOrderSessionConfig message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestOrderSessionConfig.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestOrderSessionConfig message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestOrderSessionConfig} RequestOrderSessionConfig
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestOrderSessionConfig.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestOrderSessionConfig();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 157750:
              message.shouldDeferRequest = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestOrderSessionConfig message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestOrderSessionConfig} RequestOrderSessionConfig
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestOrderSessionConfig.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestOrderSessionConfig message.
       * @function verify
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestOrderSessionConfig.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.shouldDeferRequest != null &&
          message.hasOwnProperty('shouldDeferRequest')
        )
          if (typeof message.shouldDeferRequest !== 'boolean')
            return 'shouldDeferRequest: boolean expected';
        return null;
      };

      /**
       * Creates a RequestOrderSessionConfig message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestOrderSessionConfig} RequestOrderSessionConfig
       */
      RequestOrderSessionConfig.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestOrderSessionConfig)
          return object;
        var message = new $root.rti.RequestOrderSessionConfig();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestOrderSessionConfig.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.shouldDeferRequest != null)
          message.shouldDeferRequest = Boolean(object.shouldDeferRequest);
        return message;
      };

      /**
       * Creates a plain object from a RequestOrderSessionConfig message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {rti.RequestOrderSessionConfig} message RequestOrderSessionConfig
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestOrderSessionConfig.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.templateId = 0;
          object.shouldDeferRequest = false;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.shouldDeferRequest != null &&
          message.hasOwnProperty('shouldDeferRequest')
        )
          object.shouldDeferRequest = message.shouldDeferRequest;
        return object;
      };

      /**
       * Converts this RequestOrderSessionConfig to JSON.
       * @function toJSON
       * @memberof rti.RequestOrderSessionConfig
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestOrderSessionConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestOrderSessionConfig
       * @function getTypeUrl
       * @memberof rti.RequestOrderSessionConfig
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestOrderSessionConfig.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestOrderSessionConfig';
      };

      return RequestOrderSessionConfig;
    })();

    rti.ResponseOrderSessionConfig = (function () {
      /**
       * Properties of a ResponseOrderSessionConfig.
       * @memberof rti
       * @interface IResponseOrderSessionConfig
       * @property {number} templateId ResponseOrderSessionConfig templateId
       * @property {Array.<string>|null} [userMsg] ResponseOrderSessionConfig userMsg
       * @property {Array.<string>|null} [rpCode] ResponseOrderSessionConfig rpCode
       */

      /**
       * Constructs a new ResponseOrderSessionConfig.
       * @memberof rti
       * @classdesc Represents a ResponseOrderSessionConfig.
       * @implements IResponseOrderSessionConfig
       * @constructor
       * @param {rti.IResponseOrderSessionConfig=} [properties] Properties to set
       */
      function ResponseOrderSessionConfig(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseOrderSessionConfig templateId.
       * @member {number} templateId
       * @memberof rti.ResponseOrderSessionConfig
       * @instance
       */
      ResponseOrderSessionConfig.prototype.templateId = 0;

      /**
       * ResponseOrderSessionConfig userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseOrderSessionConfig
       * @instance
       */
      ResponseOrderSessionConfig.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseOrderSessionConfig rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseOrderSessionConfig
       * @instance
       */
      ResponseOrderSessionConfig.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseOrderSessionConfig instance using the specified properties.
       * @function create
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {rti.IResponseOrderSessionConfig=} [properties] Properties to set
       * @returns {rti.ResponseOrderSessionConfig} ResponseOrderSessionConfig instance
       */
      ResponseOrderSessionConfig.create = function create(properties) {
        return new ResponseOrderSessionConfig(properties);
      };

      /**
       * Encodes the specified ResponseOrderSessionConfig message. Does not implicitly {@link rti.ResponseOrderSessionConfig.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {rti.IResponseOrderSessionConfig} message ResponseOrderSessionConfig message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseOrderSessionConfig.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseOrderSessionConfig message, length delimited. Does not implicitly {@link rti.ResponseOrderSessionConfig.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {rti.IResponseOrderSessionConfig} message ResponseOrderSessionConfig message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseOrderSessionConfig.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseOrderSessionConfig message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseOrderSessionConfig} ResponseOrderSessionConfig
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseOrderSessionConfig.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseOrderSessionConfig();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseOrderSessionConfig message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseOrderSessionConfig} ResponseOrderSessionConfig
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseOrderSessionConfig.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseOrderSessionConfig message.
       * @function verify
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseOrderSessionConfig.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseOrderSessionConfig message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseOrderSessionConfig} ResponseOrderSessionConfig
       */
      ResponseOrderSessionConfig.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseOrderSessionConfig)
          return object;
        var message = new $root.rti.ResponseOrderSessionConfig();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseOrderSessionConfig.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseOrderSessionConfig.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseOrderSessionConfig message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {rti.ResponseOrderSessionConfig} message ResponseOrderSessionConfig
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseOrderSessionConfig.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseOrderSessionConfig to JSON.
       * @function toJSON
       * @memberof rti.ResponseOrderSessionConfig
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseOrderSessionConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseOrderSessionConfig
       * @function getTypeUrl
       * @memberof rti.ResponseOrderSessionConfig
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseOrderSessionConfig.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseOrderSessionConfig';
      };

      return ResponseOrderSessionConfig;
    })();

    rti.RequestExitPosition = (function () {
      /**
       * Properties of a RequestExitPosition.
       * @memberof rti
       * @interface IRequestExitPosition
       * @property {number} templateId RequestExitPosition templateId
       * @property {Array.<string>|null} [userMsg] RequestExitPosition userMsg
       * @property {string|null} [windowName] RequestExitPosition windowName
       * @property {string|null} [fcmId] RequestExitPosition fcmId
       * @property {string|null} [ibId] RequestExitPosition ibId
       * @property {string|null} [accountId] RequestExitPosition accountId
       * @property {string|null} [symbol] RequestExitPosition symbol
       * @property {string|null} [exchange] RequestExitPosition exchange
       * @property {string|null} [tradingAlgorithm] RequestExitPosition tradingAlgorithm
       * @property {rti.RequestExitPosition.OrderPlacement|null} [manualOrAuto] RequestExitPosition manualOrAuto
       */

      /**
       * Constructs a new RequestExitPosition.
       * @memberof rti
       * @classdesc Represents a RequestExitPosition.
       * @implements IRequestExitPosition
       * @constructor
       * @param {rti.IRequestExitPosition=} [properties] Properties to set
       */
      function RequestExitPosition(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestExitPosition templateId.
       * @member {number} templateId
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.templateId = 0;

      /**
       * RequestExitPosition userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.userMsg = $util.emptyArray;

      /**
       * RequestExitPosition windowName.
       * @member {string} windowName
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.windowName = '';

      /**
       * RequestExitPosition fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.fcmId = '';

      /**
       * RequestExitPosition ibId.
       * @member {string} ibId
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.ibId = '';

      /**
       * RequestExitPosition accountId.
       * @member {string} accountId
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.accountId = '';

      /**
       * RequestExitPosition symbol.
       * @member {string} symbol
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.symbol = '';

      /**
       * RequestExitPosition exchange.
       * @member {string} exchange
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.exchange = '';

      /**
       * RequestExitPosition tradingAlgorithm.
       * @member {string} tradingAlgorithm
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.tradingAlgorithm = '';

      /**
       * RequestExitPosition manualOrAuto.
       * @member {rti.RequestExitPosition.OrderPlacement} manualOrAuto
       * @memberof rti.RequestExitPosition
       * @instance
       */
      RequestExitPosition.prototype.manualOrAuto = 1;

      /**
       * Creates a new RequestExitPosition instance using the specified properties.
       * @function create
       * @memberof rti.RequestExitPosition
       * @static
       * @param {rti.IRequestExitPosition=} [properties] Properties to set
       * @returns {rti.RequestExitPosition} RequestExitPosition instance
       */
      RequestExitPosition.create = function create(properties) {
        return new RequestExitPosition(properties);
      };

      /**
       * Encodes the specified RequestExitPosition message. Does not implicitly {@link rti.RequestExitPosition.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestExitPosition
       * @static
       * @param {rti.IRequestExitPosition} message RequestExitPosition message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestExitPosition.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.windowName != null &&
          Object.hasOwnProperty.call(message, 'windowName')
        )
          writer
            .uint32(/* id 154629, wireType 2 =*/ 1237034)
            .string(message.windowName);
        if (
          message.tradingAlgorithm != null &&
          Object.hasOwnProperty.call(message, 'tradingAlgorithm')
        )
          writer
            .uint32(/* id 154698, wireType 2 =*/ 1237586)
            .string(message.tradingAlgorithm);
        if (
          message.manualOrAuto != null &&
          Object.hasOwnProperty.call(message, 'manualOrAuto')
        )
          writer
            .uint32(/* id 154710, wireType 0 =*/ 1237680)
            .int32(message.manualOrAuto);
        return writer;
      };

      /**
       * Encodes the specified RequestExitPosition message, length delimited. Does not implicitly {@link rti.RequestExitPosition.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestExitPosition
       * @static
       * @param {rti.IRequestExitPosition} message RequestExitPosition message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestExitPosition.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestExitPosition message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestExitPosition
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestExitPosition} RequestExitPosition
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestExitPosition.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestExitPosition();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154629:
              message.windowName = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 154698:
              message.tradingAlgorithm = reader.string();
              break;
            case 154710:
              message.manualOrAuto = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestExitPosition message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestExitPosition
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestExitPosition} RequestExitPosition
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestExitPosition.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestExitPosition message.
       * @function verify
       * @memberof rti.RequestExitPosition
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestExitPosition.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          if (!$util.isString(message.windowName))
            return 'windowName: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.tradingAlgorithm != null &&
          message.hasOwnProperty('tradingAlgorithm')
        )
          if (!$util.isString(message.tradingAlgorithm))
            return 'tradingAlgorithm: string expected';
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          switch (message.manualOrAuto) {
            default:
              return 'manualOrAuto: enum value expected';
            case 1:
            case 2:
              break;
          }
        return null;
      };

      /**
       * Creates a RequestExitPosition message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestExitPosition
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestExitPosition} RequestExitPosition
       */
      RequestExitPosition.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestExitPosition) return object;
        var message = new $root.rti.RequestExitPosition();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestExitPosition.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.windowName != null)
          message.windowName = String(object.windowName);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.tradingAlgorithm != null)
          message.tradingAlgorithm = String(object.tradingAlgorithm);
        switch (object.manualOrAuto) {
          case 'MANUAL':
          case 1:
            message.manualOrAuto = 1;
            break;
          case 'AUTO':
          case 2:
            message.manualOrAuto = 2;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestExitPosition message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestExitPosition
       * @static
       * @param {rti.RequestExitPosition} message RequestExitPosition
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestExitPosition.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
          object.windowName = '';
          object.tradingAlgorithm = '';
          object.manualOrAuto = options.enums === String ? 'MANUAL' : 1;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          object.windowName = message.windowName;
        if (
          message.tradingAlgorithm != null &&
          message.hasOwnProperty('tradingAlgorithm')
        )
          object.tradingAlgorithm = message.tradingAlgorithm;
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          object.manualOrAuto =
            options.enums === String
              ? $root.rti.RequestExitPosition.OrderPlacement[
                  message.manualOrAuto
                ]
              : message.manualOrAuto;
        return object;
      };

      /**
       * Converts this RequestExitPosition to JSON.
       * @function toJSON
       * @memberof rti.RequestExitPosition
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestExitPosition.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestExitPosition
       * @function getTypeUrl
       * @memberof rti.RequestExitPosition
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestExitPosition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestExitPosition';
      };

      /**
       * OrderPlacement enum.
       * @name rti.RequestExitPosition.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      RequestExitPosition.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      return RequestExitPosition;
    })();

    rti.ResponseExitPosition = (function () {
      /**
       * Properties of a ResponseExitPosition.
       * @memberof rti
       * @interface IResponseExitPosition
       * @property {number} templateId ResponseExitPosition templateId
       * @property {Array.<string>|null} [userMsg] ResponseExitPosition userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseExitPosition rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseExitPosition rpCode
       * @property {string|null} [symbol] ResponseExitPosition symbol
       * @property {string|null} [exchange] ResponseExitPosition exchange
       */

      /**
       * Constructs a new ResponseExitPosition.
       * @memberof rti
       * @classdesc Represents a ResponseExitPosition.
       * @implements IResponseExitPosition
       * @constructor
       * @param {rti.IResponseExitPosition=} [properties] Properties to set
       */
      function ResponseExitPosition(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseExitPosition templateId.
       * @member {number} templateId
       * @memberof rti.ResponseExitPosition
       * @instance
       */
      ResponseExitPosition.prototype.templateId = 0;

      /**
       * ResponseExitPosition userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseExitPosition
       * @instance
       */
      ResponseExitPosition.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseExitPosition rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseExitPosition
       * @instance
       */
      ResponseExitPosition.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseExitPosition rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseExitPosition
       * @instance
       */
      ResponseExitPosition.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseExitPosition symbol.
       * @member {string} symbol
       * @memberof rti.ResponseExitPosition
       * @instance
       */
      ResponseExitPosition.prototype.symbol = '';

      /**
       * ResponseExitPosition exchange.
       * @member {string} exchange
       * @memberof rti.ResponseExitPosition
       * @instance
       */
      ResponseExitPosition.prototype.exchange = '';

      /**
       * Creates a new ResponseExitPosition instance using the specified properties.
       * @function create
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {rti.IResponseExitPosition=} [properties] Properties to set
       * @returns {rti.ResponseExitPosition} ResponseExitPosition instance
       */
      ResponseExitPosition.create = function create(properties) {
        return new ResponseExitPosition(properties);
      };

      /**
       * Encodes the specified ResponseExitPosition message. Does not implicitly {@link rti.ResponseExitPosition.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {rti.IResponseExitPosition} message ResponseExitPosition message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseExitPosition.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseExitPosition message, length delimited. Does not implicitly {@link rti.ResponseExitPosition.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {rti.IResponseExitPosition} message ResponseExitPosition message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseExitPosition.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseExitPosition message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseExitPosition} ResponseExitPosition
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseExitPosition.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseExitPosition();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseExitPosition message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseExitPosition} ResponseExitPosition
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseExitPosition.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseExitPosition message.
       * @function verify
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseExitPosition.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        return null;
      };

      /**
       * Creates a ResponseExitPosition message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseExitPosition} ResponseExitPosition
       */
      ResponseExitPosition.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseExitPosition) return object;
        var message = new $root.rti.ResponseExitPosition();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseExitPosition.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseExitPosition.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseExitPosition.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        return message;
      };

      /**
       * Creates a plain object from a ResponseExitPosition message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {rti.ResponseExitPosition} message ResponseExitPosition
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseExitPosition.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseExitPosition to JSON.
       * @function toJSON
       * @memberof rti.ResponseExitPosition
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseExitPosition.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseExitPosition
       * @function getTypeUrl
       * @memberof rti.ResponseExitPosition
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseExitPosition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseExitPosition';
      };

      return ResponseExitPosition;
    })();

    rti.RequestReplayExecutions = (function () {
      /**
       * Properties of a RequestReplayExecutions.
       * @memberof rti
       * @interface IRequestReplayExecutions
       * @property {number} templateId RequestReplayExecutions templateId
       * @property {Array.<string>|null} [userMsg] RequestReplayExecutions userMsg
       * @property {string|null} [fcmId] RequestReplayExecutions fcmId
       * @property {string|null} [ibId] RequestReplayExecutions ibId
       * @property {string|null} [accountId] RequestReplayExecutions accountId
       * @property {number|null} [startIndex] RequestReplayExecutions startIndex
       * @property {number|null} [finishIndex] RequestReplayExecutions finishIndex
       */

      /**
       * Constructs a new RequestReplayExecutions.
       * @memberof rti
       * @classdesc Represents a RequestReplayExecutions.
       * @implements IRequestReplayExecutions
       * @constructor
       * @param {rti.IRequestReplayExecutions=} [properties] Properties to set
       */
      function RequestReplayExecutions(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestReplayExecutions templateId.
       * @member {number} templateId
       * @memberof rti.RequestReplayExecutions
       * @instance
       */
      RequestReplayExecutions.prototype.templateId = 0;

      /**
       * RequestReplayExecutions userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestReplayExecutions
       * @instance
       */
      RequestReplayExecutions.prototype.userMsg = $util.emptyArray;

      /**
       * RequestReplayExecutions fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestReplayExecutions
       * @instance
       */
      RequestReplayExecutions.prototype.fcmId = '';

      /**
       * RequestReplayExecutions ibId.
       * @member {string} ibId
       * @memberof rti.RequestReplayExecutions
       * @instance
       */
      RequestReplayExecutions.prototype.ibId = '';

      /**
       * RequestReplayExecutions accountId.
       * @member {string} accountId
       * @memberof rti.RequestReplayExecutions
       * @instance
       */
      RequestReplayExecutions.prototype.accountId = '';

      /**
       * RequestReplayExecutions startIndex.
       * @member {number} startIndex
       * @memberof rti.RequestReplayExecutions
       * @instance
       */
      RequestReplayExecutions.prototype.startIndex = 0;

      /**
       * RequestReplayExecutions finishIndex.
       * @member {number} finishIndex
       * @memberof rti.RequestReplayExecutions
       * @instance
       */
      RequestReplayExecutions.prototype.finishIndex = 0;

      /**
       * Creates a new RequestReplayExecutions instance using the specified properties.
       * @function create
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {rti.IRequestReplayExecutions=} [properties] Properties to set
       * @returns {rti.RequestReplayExecutions} RequestReplayExecutions instance
       */
      RequestReplayExecutions.create = function create(properties) {
        return new RequestReplayExecutions(properties);
      };

      /**
       * Encodes the specified RequestReplayExecutions message. Does not implicitly {@link rti.RequestReplayExecutions.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {rti.IRequestReplayExecutions} message RequestReplayExecutions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestReplayExecutions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.startIndex != null &&
          Object.hasOwnProperty.call(message, 'startIndex')
        )
          writer
            .uint32(/* id 153002, wireType 0 =*/ 1224016)
            .int32(message.startIndex);
        if (
          message.finishIndex != null &&
          Object.hasOwnProperty.call(message, 'finishIndex')
        )
          writer
            .uint32(/* id 153003, wireType 0 =*/ 1224024)
            .int32(message.finishIndex);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestReplayExecutions message, length delimited. Does not implicitly {@link rti.RequestReplayExecutions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {rti.IRequestReplayExecutions} message RequestReplayExecutions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestReplayExecutions.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestReplayExecutions message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestReplayExecutions} RequestReplayExecutions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestReplayExecutions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestReplayExecutions();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 153002:
              message.startIndex = reader.int32();
              break;
            case 153003:
              message.finishIndex = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestReplayExecutions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestReplayExecutions} RequestReplayExecutions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestReplayExecutions.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestReplayExecutions message.
       * @function verify
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestReplayExecutions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.startIndex != null && message.hasOwnProperty('startIndex'))
          if (!$util.isInteger(message.startIndex))
            return 'startIndex: integer expected';
        if (
          message.finishIndex != null &&
          message.hasOwnProperty('finishIndex')
        )
          if (!$util.isInteger(message.finishIndex))
            return 'finishIndex: integer expected';
        return null;
      };

      /**
       * Creates a RequestReplayExecutions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestReplayExecutions} RequestReplayExecutions
       */
      RequestReplayExecutions.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestReplayExecutions) return object;
        var message = new $root.rti.RequestReplayExecutions();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestReplayExecutions.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.startIndex != null)
          message.startIndex = object.startIndex | 0;
        if (object.finishIndex != null)
          message.finishIndex = object.finishIndex | 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestReplayExecutions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {rti.RequestReplayExecutions} message RequestReplayExecutions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestReplayExecutions.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.startIndex = 0;
          object.finishIndex = 0;
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.startIndex != null && message.hasOwnProperty('startIndex'))
          object.startIndex = message.startIndex;
        if (
          message.finishIndex != null &&
          message.hasOwnProperty('finishIndex')
        )
          object.finishIndex = message.finishIndex;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestReplayExecutions to JSON.
       * @function toJSON
       * @memberof rti.RequestReplayExecutions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestReplayExecutions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestReplayExecutions
       * @function getTypeUrl
       * @memberof rti.RequestReplayExecutions
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestReplayExecutions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestReplayExecutions';
      };

      return RequestReplayExecutions;
    })();

    rti.ResponseReplayExecutions = (function () {
      /**
       * Properties of a ResponseReplayExecutions.
       * @memberof rti
       * @interface IResponseReplayExecutions
       * @property {number} templateId ResponseReplayExecutions templateId
       * @property {Array.<string>|null} [userMsg] ResponseReplayExecutions userMsg
       * @property {Array.<string>|null} [rpCode] ResponseReplayExecutions rpCode
       */

      /**
       * Constructs a new ResponseReplayExecutions.
       * @memberof rti
       * @classdesc Represents a ResponseReplayExecutions.
       * @implements IResponseReplayExecutions
       * @constructor
       * @param {rti.IResponseReplayExecutions=} [properties] Properties to set
       */
      function ResponseReplayExecutions(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseReplayExecutions templateId.
       * @member {number} templateId
       * @memberof rti.ResponseReplayExecutions
       * @instance
       */
      ResponseReplayExecutions.prototype.templateId = 0;

      /**
       * ResponseReplayExecutions userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseReplayExecutions
       * @instance
       */
      ResponseReplayExecutions.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseReplayExecutions rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseReplayExecutions
       * @instance
       */
      ResponseReplayExecutions.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseReplayExecutions instance using the specified properties.
       * @function create
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {rti.IResponseReplayExecutions=} [properties] Properties to set
       * @returns {rti.ResponseReplayExecutions} ResponseReplayExecutions instance
       */
      ResponseReplayExecutions.create = function create(properties) {
        return new ResponseReplayExecutions(properties);
      };

      /**
       * Encodes the specified ResponseReplayExecutions message. Does not implicitly {@link rti.ResponseReplayExecutions.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {rti.IResponseReplayExecutions} message ResponseReplayExecutions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseReplayExecutions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseReplayExecutions message, length delimited. Does not implicitly {@link rti.ResponseReplayExecutions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {rti.IResponseReplayExecutions} message ResponseReplayExecutions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseReplayExecutions.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseReplayExecutions message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseReplayExecutions} ResponseReplayExecutions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseReplayExecutions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseReplayExecutions();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseReplayExecutions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseReplayExecutions} ResponseReplayExecutions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseReplayExecutions.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseReplayExecutions message.
       * @function verify
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseReplayExecutions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseReplayExecutions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseReplayExecutions} ResponseReplayExecutions
       */
      ResponseReplayExecutions.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseReplayExecutions) return object;
        var message = new $root.rti.ResponseReplayExecutions();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseReplayExecutions.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseReplayExecutions.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseReplayExecutions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {rti.ResponseReplayExecutions} message ResponseReplayExecutions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseReplayExecutions.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseReplayExecutions to JSON.
       * @function toJSON
       * @memberof rti.ResponseReplayExecutions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseReplayExecutions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseReplayExecutions
       * @function getTypeUrl
       * @memberof rti.ResponseReplayExecutions
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseReplayExecutions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseReplayExecutions';
      };

      return ResponseReplayExecutions;
    })();

    rti.TradeRoute = (function () {
      /**
       * Properties of a TradeRoute.
       * @memberof rti
       * @interface ITradeRoute
       * @property {number} templateId TradeRoute templateId
       * @property {string|null} [fcmId] TradeRoute fcmId
       * @property {string|null} [ibId] TradeRoute ibId
       * @property {string|null} [exchange] TradeRoute exchange
       * @property {string|null} [tradeRoute] TradeRoute tradeRoute
       * @property {string|null} [status] TradeRoute status
       * @property {boolean|null} [isDefault] TradeRoute isDefault
       */

      /**
       * Constructs a new TradeRoute.
       * @memberof rti
       * @classdesc Represents a TradeRoute.
       * @implements ITradeRoute
       * @constructor
       * @param {rti.ITradeRoute=} [properties] Properties to set
       */
      function TradeRoute(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * TradeRoute templateId.
       * @member {number} templateId
       * @memberof rti.TradeRoute
       * @instance
       */
      TradeRoute.prototype.templateId = 0;

      /**
       * TradeRoute fcmId.
       * @member {string} fcmId
       * @memberof rti.TradeRoute
       * @instance
       */
      TradeRoute.prototype.fcmId = '';

      /**
       * TradeRoute ibId.
       * @member {string} ibId
       * @memberof rti.TradeRoute
       * @instance
       */
      TradeRoute.prototype.ibId = '';

      /**
       * TradeRoute exchange.
       * @member {string} exchange
       * @memberof rti.TradeRoute
       * @instance
       */
      TradeRoute.prototype.exchange = '';

      /**
       * TradeRoute tradeRoute.
       * @member {string} tradeRoute
       * @memberof rti.TradeRoute
       * @instance
       */
      TradeRoute.prototype.tradeRoute = '';

      /**
       * TradeRoute status.
       * @member {string} status
       * @memberof rti.TradeRoute
       * @instance
       */
      TradeRoute.prototype.status = '';

      /**
       * TradeRoute isDefault.
       * @member {boolean} isDefault
       * @memberof rti.TradeRoute
       * @instance
       */
      TradeRoute.prototype.isDefault = false;

      /**
       * Creates a new TradeRoute instance using the specified properties.
       * @function create
       * @memberof rti.TradeRoute
       * @static
       * @param {rti.ITradeRoute=} [properties] Properties to set
       * @returns {rti.TradeRoute} TradeRoute instance
       */
      TradeRoute.create = function create(properties) {
        return new TradeRoute(properties);
      };

      /**
       * Encodes the specified TradeRoute message. Does not implicitly {@link rti.TradeRoute.verify|verify} messages.
       * @function encode
       * @memberof rti.TradeRoute
       * @static
       * @param {rti.ITradeRoute} message TradeRoute message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TradeRoute.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.tradeRoute != null &&
          Object.hasOwnProperty.call(message, 'tradeRoute')
        )
          writer
            .uint32(/* id 112016, wireType 2 =*/ 896130)
            .string(message.tradeRoute);
        if (
          message.status != null &&
          Object.hasOwnProperty.call(message, 'status')
        )
          writer
            .uint32(/* id 131407, wireType 2 =*/ 1051258)
            .string(message.status);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.isDefault != null &&
          Object.hasOwnProperty.call(message, 'isDefault')
        )
          writer
            .uint32(/* id 154689, wireType 0 =*/ 1237512)
            .bool(message.isDefault);
        return writer;
      };

      /**
       * Encodes the specified TradeRoute message, length delimited. Does not implicitly {@link rti.TradeRoute.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.TradeRoute
       * @static
       * @param {rti.ITradeRoute} message TradeRoute message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TradeRoute.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a TradeRoute message from the specified reader or buffer.
       * @function decode
       * @memberof rti.TradeRoute
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.TradeRoute} TradeRoute
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TradeRoute.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.TradeRoute();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 112016:
              message.tradeRoute = reader.string();
              break;
            case 131407:
              message.status = reader.string();
              break;
            case 154689:
              message.isDefault = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a TradeRoute message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.TradeRoute
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.TradeRoute} TradeRoute
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TradeRoute.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a TradeRoute message.
       * @function verify
       * @memberof rti.TradeRoute
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      TradeRoute.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          if (!$util.isString(message.tradeRoute))
            return 'tradeRoute: string expected';
        if (message.status != null && message.hasOwnProperty('status'))
          if (!$util.isString(message.status)) return 'status: string expected';
        if (message.isDefault != null && message.hasOwnProperty('isDefault'))
          if (typeof message.isDefault !== 'boolean')
            return 'isDefault: boolean expected';
        return null;
      };

      /**
       * Creates a TradeRoute message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.TradeRoute
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.TradeRoute} TradeRoute
       */
      TradeRoute.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.TradeRoute) return object;
        var message = new $root.rti.TradeRoute();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.tradeRoute != null)
          message.tradeRoute = String(object.tradeRoute);
        if (object.status != null) message.status = String(object.status);
        if (object.isDefault != null)
          message.isDefault = Boolean(object.isDefault);
        return message;
      };

      /**
       * Creates a plain object from a TradeRoute message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.TradeRoute
       * @static
       * @param {rti.TradeRoute} message TradeRoute
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      TradeRoute.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.exchange = '';
          object.tradeRoute = '';
          object.status = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
          object.isDefault = false;
        }
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          object.tradeRoute = message.tradeRoute;
        if (message.status != null && message.hasOwnProperty('status'))
          object.status = message.status;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (message.isDefault != null && message.hasOwnProperty('isDefault'))
          object.isDefault = message.isDefault;
        return object;
      };

      /**
       * Converts this TradeRoute to JSON.
       * @function toJSON
       * @memberof rti.TradeRoute
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      TradeRoute.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for TradeRoute
       * @function getTypeUrl
       * @memberof rti.TradeRoute
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      TradeRoute.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.TradeRoute';
      };

      return TradeRoute;
    })();

    rti.BracketUpdates = (function () {
      /**
       * Properties of a BracketUpdates.
       * @memberof rti
       * @interface IBracketUpdates
       * @property {number} templateId BracketUpdates templateId
       * @property {string|null} [fcmId] BracketUpdates fcmId
       * @property {string|null} [ibId] BracketUpdates ibId
       * @property {string|null} [accountId] BracketUpdates accountId
       * @property {string|null} [basketId] BracketUpdates basketId
       * @property {number|null} [stopTicks] BracketUpdates stopTicks
       * @property {number|null} [stopQuantity] BracketUpdates stopQuantity
       * @property {number|null} [stopQuantityReleased] BracketUpdates stopQuantityReleased
       * @property {number|null} [targetTicks] BracketUpdates targetTicks
       * @property {number|null} [targetQuantity] BracketUpdates targetQuantity
       * @property {number|null} [targetQuantityReleased] BracketUpdates targetQuantityReleased
       */

      /**
       * Constructs a new BracketUpdates.
       * @memberof rti
       * @classdesc Represents a BracketUpdates.
       * @implements IBracketUpdates
       * @constructor
       * @param {rti.IBracketUpdates=} [properties] Properties to set
       */
      function BracketUpdates(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * BracketUpdates templateId.
       * @member {number} templateId
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.templateId = 0;

      /**
       * BracketUpdates fcmId.
       * @member {string} fcmId
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.fcmId = '';

      /**
       * BracketUpdates ibId.
       * @member {string} ibId
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.ibId = '';

      /**
       * BracketUpdates accountId.
       * @member {string} accountId
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.accountId = '';

      /**
       * BracketUpdates basketId.
       * @member {string} basketId
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.basketId = '';

      /**
       * BracketUpdates stopTicks.
       * @member {number} stopTicks
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.stopTicks = 0;

      /**
       * BracketUpdates stopQuantity.
       * @member {number} stopQuantity
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.stopQuantity = 0;

      /**
       * BracketUpdates stopQuantityReleased.
       * @member {number} stopQuantityReleased
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.stopQuantityReleased = 0;

      /**
       * BracketUpdates targetTicks.
       * @member {number} targetTicks
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.targetTicks = 0;

      /**
       * BracketUpdates targetQuantity.
       * @member {number} targetQuantity
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.targetQuantity = 0;

      /**
       * BracketUpdates targetQuantityReleased.
       * @member {number} targetQuantityReleased
       * @memberof rti.BracketUpdates
       * @instance
       */
      BracketUpdates.prototype.targetQuantityReleased = 0;

      /**
       * Creates a new BracketUpdates instance using the specified properties.
       * @function create
       * @memberof rti.BracketUpdates
       * @static
       * @param {rti.IBracketUpdates=} [properties] Properties to set
       * @returns {rti.BracketUpdates} BracketUpdates instance
       */
      BracketUpdates.create = function create(properties) {
        return new BracketUpdates(properties);
      };

      /**
       * Encodes the specified BracketUpdates message. Does not implicitly {@link rti.BracketUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.BracketUpdates
       * @static
       * @param {rti.IBracketUpdates} message BracketUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      BracketUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.targetTicks != null &&
          Object.hasOwnProperty.call(message, 'targetTicks')
        )
          writer
            .uint32(/* id 154456, wireType 0 =*/ 1235648)
            .int32(message.targetTicks);
        if (
          message.targetQuantity != null &&
          Object.hasOwnProperty.call(message, 'targetQuantity')
        )
          writer
            .uint32(/* id 154457, wireType 0 =*/ 1235656)
            .int32(message.targetQuantity);
        if (
          message.stopTicks != null &&
          Object.hasOwnProperty.call(message, 'stopTicks')
        )
          writer
            .uint32(/* id 154458, wireType 0 =*/ 1235664)
            .int32(message.stopTicks);
        if (
          message.stopQuantity != null &&
          Object.hasOwnProperty.call(message, 'stopQuantity')
        )
          writer
            .uint32(/* id 154459, wireType 0 =*/ 1235672)
            .int32(message.stopQuantity);
        if (
          message.targetQuantityReleased != null &&
          Object.hasOwnProperty.call(message, 'targetQuantityReleased')
        )
          writer
            .uint32(/* id 154460, wireType 0 =*/ 1235680)
            .int32(message.targetQuantityReleased);
        if (
          message.stopQuantityReleased != null &&
          Object.hasOwnProperty.call(message, 'stopQuantityReleased')
        )
          writer
            .uint32(/* id 154466, wireType 0 =*/ 1235728)
            .int32(message.stopQuantityReleased);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified BracketUpdates message, length delimited. Does not implicitly {@link rti.BracketUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.BracketUpdates
       * @static
       * @param {rti.IBracketUpdates} message BracketUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      BracketUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a BracketUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.BracketUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.BracketUpdates} BracketUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      BracketUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.BracketUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 154458:
              message.stopTicks = reader.int32();
              break;
            case 154459:
              message.stopQuantity = reader.int32();
              break;
            case 154466:
              message.stopQuantityReleased = reader.int32();
              break;
            case 154456:
              message.targetTicks = reader.int32();
              break;
            case 154457:
              message.targetQuantity = reader.int32();
              break;
            case 154460:
              message.targetQuantityReleased = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a BracketUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.BracketUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.BracketUpdates} BracketUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      BracketUpdates.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a BracketUpdates message.
       * @function verify
       * @memberof rti.BracketUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      BracketUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (message.stopTicks != null && message.hasOwnProperty('stopTicks'))
          if (!$util.isInteger(message.stopTicks))
            return 'stopTicks: integer expected';
        if (
          message.stopQuantity != null &&
          message.hasOwnProperty('stopQuantity')
        )
          if (!$util.isInteger(message.stopQuantity))
            return 'stopQuantity: integer expected';
        if (
          message.stopQuantityReleased != null &&
          message.hasOwnProperty('stopQuantityReleased')
        )
          if (!$util.isInteger(message.stopQuantityReleased))
            return 'stopQuantityReleased: integer expected';
        if (
          message.targetTicks != null &&
          message.hasOwnProperty('targetTicks')
        )
          if (!$util.isInteger(message.targetTicks))
            return 'targetTicks: integer expected';
        if (
          message.targetQuantity != null &&
          message.hasOwnProperty('targetQuantity')
        )
          if (!$util.isInteger(message.targetQuantity))
            return 'targetQuantity: integer expected';
        if (
          message.targetQuantityReleased != null &&
          message.hasOwnProperty('targetQuantityReleased')
        )
          if (!$util.isInteger(message.targetQuantityReleased))
            return 'targetQuantityReleased: integer expected';
        return null;
      };

      /**
       * Creates a BracketUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.BracketUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.BracketUpdates} BracketUpdates
       */
      BracketUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.BracketUpdates) return object;
        var message = new $root.rti.BracketUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.stopTicks != null) message.stopTicks = object.stopTicks | 0;
        if (object.stopQuantity != null)
          message.stopQuantity = object.stopQuantity | 0;
        if (object.stopQuantityReleased != null)
          message.stopQuantityReleased = object.stopQuantityReleased | 0;
        if (object.targetTicks != null)
          message.targetTicks = object.targetTicks | 0;
        if (object.targetQuantity != null)
          message.targetQuantity = object.targetQuantity | 0;
        if (object.targetQuantityReleased != null)
          message.targetQuantityReleased = object.targetQuantityReleased | 0;
        return message;
      };

      /**
       * Creates a plain object from a BracketUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.BracketUpdates
       * @static
       * @param {rti.BracketUpdates} message BracketUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      BracketUpdates.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.basketId = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.targetTicks = 0;
          object.targetQuantity = 0;
          object.stopTicks = 0;
          object.stopQuantity = 0;
          object.targetQuantityReleased = 0;
          object.stopQuantityReleased = 0;
          object.templateId = 0;
        }
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (
          message.targetTicks != null &&
          message.hasOwnProperty('targetTicks')
        )
          object.targetTicks = message.targetTicks;
        if (
          message.targetQuantity != null &&
          message.hasOwnProperty('targetQuantity')
        )
          object.targetQuantity = message.targetQuantity;
        if (message.stopTicks != null && message.hasOwnProperty('stopTicks'))
          object.stopTicks = message.stopTicks;
        if (
          message.stopQuantity != null &&
          message.hasOwnProperty('stopQuantity')
        )
          object.stopQuantity = message.stopQuantity;
        if (
          message.targetQuantityReleased != null &&
          message.hasOwnProperty('targetQuantityReleased')
        )
          object.targetQuantityReleased = message.targetQuantityReleased;
        if (
          message.stopQuantityReleased != null &&
          message.hasOwnProperty('stopQuantityReleased')
        )
          object.stopQuantityReleased = message.stopQuantityReleased;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this BracketUpdates to JSON.
       * @function toJSON
       * @memberof rti.BracketUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      BracketUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for BracketUpdates
       * @function getTypeUrl
       * @memberof rti.BracketUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      BracketUpdates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.BracketUpdates';
      };

      return BracketUpdates;
    })();

    rti.RithmicOrderNotification = (function () {
      /**
       * Properties of a RithmicOrderNotification.
       * @memberof rti
       * @interface IRithmicOrderNotification
       * @property {number} templateId RithmicOrderNotification templateId
       * @property {string|null} [userTag] RithmicOrderNotification userTag
       * @property {rti.RithmicOrderNotification.NotifyType|null} [notifyType] RithmicOrderNotification notifyType
       * @property {boolean|null} [isSnapshot] RithmicOrderNotification isSnapshot
       * @property {string|null} [status] RithmicOrderNotification status
       * @property {string|null} [basketId] RithmicOrderNotification basketId
       * @property {string|null} [originalBasketId] RithmicOrderNotification originalBasketId
       * @property {string|null} [linkedBasketIds] RithmicOrderNotification linkedBasketIds
       * @property {string|null} [fcmId] RithmicOrderNotification fcmId
       * @property {string|null} [ibId] RithmicOrderNotification ibId
       * @property {string|null} [userId] RithmicOrderNotification userId
       * @property {string|null} [accountId] RithmicOrderNotification accountId
       * @property {string|null} [symbol] RithmicOrderNotification symbol
       * @property {string|null} [exchange] RithmicOrderNotification exchange
       * @property {string|null} [tradeExchange] RithmicOrderNotification tradeExchange
       * @property {string|null} [tradeRoute] RithmicOrderNotification tradeRoute
       * @property {string|null} [exchangeOrderId] RithmicOrderNotification exchangeOrderId
       * @property {string|null} [instrumentType] RithmicOrderNotification instrumentType
       * @property {string|null} [completionReason] RithmicOrderNotification completionReason
       * @property {number|null} [quantity] RithmicOrderNotification quantity
       * @property {number|null} [quanReleasePending] RithmicOrderNotification quanReleasePending
       * @property {number|null} [price] RithmicOrderNotification price
       * @property {number|null} [triggerPrice] RithmicOrderNotification triggerPrice
       * @property {rti.RithmicOrderNotification.TransactionType|null} [transactionType] RithmicOrderNotification transactionType
       * @property {rti.RithmicOrderNotification.Duration|null} [duration] RithmicOrderNotification duration
       * @property {rti.RithmicOrderNotification.PriceType|null} [priceType] RithmicOrderNotification priceType
       * @property {rti.RithmicOrderNotification.PriceType|null} [origPriceType] RithmicOrderNotification origPriceType
       * @property {rti.RithmicOrderNotification.OrderPlacement|null} [manualOrAuto] RithmicOrderNotification manualOrAuto
       * @property {rti.RithmicOrderNotification.BracketType|null} [bracketType] RithmicOrderNotification bracketType
       * @property {number|null} [avgFillPrice] RithmicOrderNotification avgFillPrice
       * @property {number|null} [totalFillSize] RithmicOrderNotification totalFillSize
       * @property {number|null} [totalUnfilledSize] RithmicOrderNotification totalUnfilledSize
       * @property {string|null} [sequenceNumber] RithmicOrderNotification sequenceNumber
       * @property {string|null} [origSequenceNumber] RithmicOrderNotification origSequenceNumber
       * @property {string|null} [corSequenceNumber] RithmicOrderNotification corSequenceNumber
       * @property {string|null} [currency] RithmicOrderNotification currency
       * @property {string|null} [countryCode] RithmicOrderNotification countryCode
       * @property {string|null} [text] RithmicOrderNotification text
       * @property {string|null} [reportText] RithmicOrderNotification reportText
       * @property {string|null} [remarks] RithmicOrderNotification remarks
       * @property {string|null} [windowName] RithmicOrderNotification windowName
       * @property {string|null} [originatorWindowName] RithmicOrderNotification originatorWindowName
       * @property {number|null} [cancelAtSsboe] RithmicOrderNotification cancelAtSsboe
       * @property {number|null} [cancelAtUsecs] RithmicOrderNotification cancelAtUsecs
       * @property {number|null} [cancelAfterSecs] RithmicOrderNotification cancelAfterSecs
       * @property {number|null} [ssboe] RithmicOrderNotification ssboe
       * @property {number|null} [usecs] RithmicOrderNotification usecs
       */

      /**
       * Constructs a new RithmicOrderNotification.
       * @memberof rti
       * @classdesc Represents a RithmicOrderNotification.
       * @implements IRithmicOrderNotification
       * @constructor
       * @param {rti.IRithmicOrderNotification=} [properties] Properties to set
       */
      function RithmicOrderNotification(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RithmicOrderNotification templateId.
       * @member {number} templateId
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.templateId = 0;

      /**
       * RithmicOrderNotification userTag.
       * @member {string} userTag
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.userTag = '';

      /**
       * RithmicOrderNotification notifyType.
       * @member {rti.RithmicOrderNotification.NotifyType} notifyType
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.notifyType = 1;

      /**
       * RithmicOrderNotification isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.isSnapshot = false;

      /**
       * RithmicOrderNotification status.
       * @member {string} status
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.status = '';

      /**
       * RithmicOrderNotification basketId.
       * @member {string} basketId
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.basketId = '';

      /**
       * RithmicOrderNotification originalBasketId.
       * @member {string} originalBasketId
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.originalBasketId = '';

      /**
       * RithmicOrderNotification linkedBasketIds.
       * @member {string} linkedBasketIds
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.linkedBasketIds = '';

      /**
       * RithmicOrderNotification fcmId.
       * @member {string} fcmId
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.fcmId = '';

      /**
       * RithmicOrderNotification ibId.
       * @member {string} ibId
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.ibId = '';

      /**
       * RithmicOrderNotification userId.
       * @member {string} userId
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.userId = '';

      /**
       * RithmicOrderNotification accountId.
       * @member {string} accountId
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.accountId = '';

      /**
       * RithmicOrderNotification symbol.
       * @member {string} symbol
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.symbol = '';

      /**
       * RithmicOrderNotification exchange.
       * @member {string} exchange
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.exchange = '';

      /**
       * RithmicOrderNotification tradeExchange.
       * @member {string} tradeExchange
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.tradeExchange = '';

      /**
       * RithmicOrderNotification tradeRoute.
       * @member {string} tradeRoute
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.tradeRoute = '';

      /**
       * RithmicOrderNotification exchangeOrderId.
       * @member {string} exchangeOrderId
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.exchangeOrderId = '';

      /**
       * RithmicOrderNotification instrumentType.
       * @member {string} instrumentType
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.instrumentType = '';

      /**
       * RithmicOrderNotification completionReason.
       * @member {string} completionReason
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.completionReason = '';

      /**
       * RithmicOrderNotification quantity.
       * @member {number} quantity
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.quantity = 0;

      /**
       * RithmicOrderNotification quanReleasePending.
       * @member {number} quanReleasePending
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.quanReleasePending = 0;

      /**
       * RithmicOrderNotification price.
       * @member {number} price
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.price = 0;

      /**
       * RithmicOrderNotification triggerPrice.
       * @member {number} triggerPrice
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.triggerPrice = 0;

      /**
       * RithmicOrderNotification transactionType.
       * @member {rti.RithmicOrderNotification.TransactionType} transactionType
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.transactionType = 1;

      /**
       * RithmicOrderNotification duration.
       * @member {rti.RithmicOrderNotification.Duration} duration
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.duration = 1;

      /**
       * RithmicOrderNotification priceType.
       * @member {rti.RithmicOrderNotification.PriceType} priceType
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.priceType = 1;

      /**
       * RithmicOrderNotification origPriceType.
       * @member {rti.RithmicOrderNotification.PriceType} origPriceType
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.origPriceType = 1;

      /**
       * RithmicOrderNotification manualOrAuto.
       * @member {rti.RithmicOrderNotification.OrderPlacement} manualOrAuto
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.manualOrAuto = 1;

      /**
       * RithmicOrderNotification bracketType.
       * @member {rti.RithmicOrderNotification.BracketType} bracketType
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.bracketType = 1;

      /**
       * RithmicOrderNotification avgFillPrice.
       * @member {number} avgFillPrice
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.avgFillPrice = 0;

      /**
       * RithmicOrderNotification totalFillSize.
       * @member {number} totalFillSize
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.totalFillSize = 0;

      /**
       * RithmicOrderNotification totalUnfilledSize.
       * @member {number} totalUnfilledSize
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.totalUnfilledSize = 0;

      /**
       * RithmicOrderNotification sequenceNumber.
       * @member {string} sequenceNumber
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.sequenceNumber = '';

      /**
       * RithmicOrderNotification origSequenceNumber.
       * @member {string} origSequenceNumber
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.origSequenceNumber = '';

      /**
       * RithmicOrderNotification corSequenceNumber.
       * @member {string} corSequenceNumber
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.corSequenceNumber = '';

      /**
       * RithmicOrderNotification currency.
       * @member {string} currency
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.currency = '';

      /**
       * RithmicOrderNotification countryCode.
       * @member {string} countryCode
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.countryCode = '';

      /**
       * RithmicOrderNotification text.
       * @member {string} text
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.text = '';

      /**
       * RithmicOrderNotification reportText.
       * @member {string} reportText
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.reportText = '';

      /**
       * RithmicOrderNotification remarks.
       * @member {string} remarks
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.remarks = '';

      /**
       * RithmicOrderNotification windowName.
       * @member {string} windowName
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.windowName = '';

      /**
       * RithmicOrderNotification originatorWindowName.
       * @member {string} originatorWindowName
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.originatorWindowName = '';

      /**
       * RithmicOrderNotification cancelAtSsboe.
       * @member {number} cancelAtSsboe
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.cancelAtSsboe = 0;

      /**
       * RithmicOrderNotification cancelAtUsecs.
       * @member {number} cancelAtUsecs
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.cancelAtUsecs = 0;

      /**
       * RithmicOrderNotification cancelAfterSecs.
       * @member {number} cancelAfterSecs
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.cancelAfterSecs = 0;

      /**
       * RithmicOrderNotification ssboe.
       * @member {number} ssboe
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.ssboe = 0;

      /**
       * RithmicOrderNotification usecs.
       * @member {number} usecs
       * @memberof rti.RithmicOrderNotification
       * @instance
       */
      RithmicOrderNotification.prototype.usecs = 0;

      /**
       * Creates a new RithmicOrderNotification instance using the specified properties.
       * @function create
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {rti.IRithmicOrderNotification=} [properties] Properties to set
       * @returns {rti.RithmicOrderNotification} RithmicOrderNotification instance
       */
      RithmicOrderNotification.create = function create(properties) {
        return new RithmicOrderNotification(properties);
      };

      /**
       * Encodes the specified RithmicOrderNotification message. Does not implicitly {@link rti.RithmicOrderNotification.verify|verify} messages.
       * @function encode
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {rti.IRithmicOrderNotification} message RithmicOrderNotification message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RithmicOrderNotification.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.instrumentType != null &&
          Object.hasOwnProperty.call(message, 'instrumentType')
        )
          writer
            .uint32(/* id 110116, wireType 2 =*/ 880930)
            .string(message.instrumentType);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (
          message.status != null &&
          Object.hasOwnProperty.call(message, 'status')
        )
          writer
            .uint32(/* id 110303, wireType 2 =*/ 882426)
            .string(message.status);
        if (
          message.price != null &&
          Object.hasOwnProperty.call(message, 'price')
        )
          writer
            .uint32(/* id 110306, wireType 1 =*/ 882449)
            .double(message.price);
        if (
          message.avgFillPrice != null &&
          Object.hasOwnProperty.call(message, 'avgFillPrice')
        )
          writer
            .uint32(/* id 110322, wireType 1 =*/ 882577)
            .double(message.avgFillPrice);
        if (
          message.linkedBasketIds != null &&
          Object.hasOwnProperty.call(message, 'linkedBasketIds')
        )
          writer
            .uint32(/* id 110358, wireType 2 =*/ 882866)
            .string(message.linkedBasketIds);
        if (
          message.sequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'sequenceNumber')
        )
          writer
            .uint32(/* id 112002, wireType 2 =*/ 896018)
            .string(message.sequenceNumber);
        if (
          message.transactionType != null &&
          Object.hasOwnProperty.call(message, 'transactionType')
        )
          writer
            .uint32(/* id 112003, wireType 0 =*/ 896024)
            .int32(message.transactionType);
        if (
          message.quantity != null &&
          Object.hasOwnProperty.call(message, 'quantity')
        )
          writer
            .uint32(/* id 112004, wireType 0 =*/ 896032)
            .int32(message.quantity);
        if (
          message.duration != null &&
          Object.hasOwnProperty.call(message, 'duration')
        )
          writer
            .uint32(/* id 112005, wireType 0 =*/ 896040)
            .int32(message.duration);
        if (
          message.priceType != null &&
          Object.hasOwnProperty.call(message, 'priceType')
        )
          writer
            .uint32(/* id 112008, wireType 0 =*/ 896064)
            .int32(message.priceType);
        if (
          message.tradeRoute != null &&
          Object.hasOwnProperty.call(message, 'tradeRoute')
        )
          writer
            .uint32(/* id 112016, wireType 2 =*/ 896130)
            .string(message.tradeRoute);
        if (
          message.tradeExchange != null &&
          Object.hasOwnProperty.call(message, 'tradeExchange')
        )
          writer
            .uint32(/* id 112021, wireType 2 =*/ 896170)
            .string(message.tradeExchange);
        if (
          message.quanReleasePending != null &&
          Object.hasOwnProperty.call(message, 'quanReleasePending')
        )
          writer
            .uint32(/* id 112027, wireType 0 =*/ 896216)
            .int32(message.quanReleasePending);
        if (message.text != null && Object.hasOwnProperty.call(message, 'text'))
          writer
            .uint32(/* id 120008, wireType 2 =*/ 960066)
            .string(message.text);
        if (
          message.reportText != null &&
          Object.hasOwnProperty.call(message, 'reportText')
        )
          writer
            .uint32(/* id 120028, wireType 2 =*/ 960226)
            .string(message.reportText);
        if (
          message.userId != null &&
          Object.hasOwnProperty.call(message, 'userId')
        )
          writer
            .uint32(/* id 131003, wireType 2 =*/ 1048026)
            .string(message.userId);
        if (
          message.exchangeOrderId != null &&
          Object.hasOwnProperty.call(message, 'exchangeOrderId')
        )
          writer
            .uint32(/* id 149238, wireType 2 =*/ 1193906)
            .string(message.exchangeOrderId);
        if (
          message.triggerPrice != null &&
          Object.hasOwnProperty.call(message, 'triggerPrice')
        )
          writer
            .uint32(/* id 149247, wireType 1 =*/ 1193977)
            .double(message.triggerPrice);
        if (
          message.origSequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'origSequenceNumber')
        )
          writer
            .uint32(/* id 149263, wireType 2 =*/ 1194106)
            .string(message.origSequenceNumber);
        if (
          message.corSequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'corSequenceNumber')
        )
          writer
            .uint32(/* id 149264, wireType 2 =*/ 1194114)
            .string(message.corSequenceNumber);
        if (
          message.completionReason != null &&
          Object.hasOwnProperty.call(message, 'completionReason')
        )
          writer
            .uint32(/* id 149273, wireType 2 =*/ 1194186)
            .string(message.completionReason);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.notifyType != null &&
          Object.hasOwnProperty.call(message, 'notifyType')
        )
          writer
            .uint32(/* id 153625, wireType 0 =*/ 1229000)
            .int32(message.notifyType);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.totalFillSize != null &&
          Object.hasOwnProperty.call(message, 'totalFillSize')
        )
          writer
            .uint32(/* id 154111, wireType 0 =*/ 1232888)
            .int32(message.totalFillSize);
        if (
          message.totalUnfilledSize != null &&
          Object.hasOwnProperty.call(message, 'totalUnfilledSize')
        )
          writer
            .uint32(/* id 154112, wireType 0 =*/ 1232896)
            .int32(message.totalUnfilledSize);
        if (
          message.userTag != null &&
          Object.hasOwnProperty.call(message, 'userTag')
        )
          writer
            .uint32(/* id 154119, wireType 2 =*/ 1232954)
            .string(message.userTag);
        if (
          message.countryCode != null &&
          Object.hasOwnProperty.call(message, 'countryCode')
        )
          writer
            .uint32(/* id 154172, wireType 2 =*/ 1233378)
            .string(message.countryCode);
        if (
          message.currency != null &&
          Object.hasOwnProperty.call(message, 'currency')
        )
          writer
            .uint32(/* id 154382, wireType 2 =*/ 1235058)
            .string(message.currency);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.cancelAfterSecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAfterSecs')
        )
          writer
            .uint32(/* id 154488, wireType 0 =*/ 1235904)
            .int32(message.cancelAfterSecs);
        if (
          message.originalBasketId != null &&
          Object.hasOwnProperty.call(message, 'originalBasketId')
        )
          writer
            .uint32(/* id 154497, wireType 2 =*/ 1235978)
            .string(message.originalBasketId);
        if (
          message.windowName != null &&
          Object.hasOwnProperty.call(message, 'windowName')
        )
          writer
            .uint32(/* id 154629, wireType 2 =*/ 1237034)
            .string(message.windowName);
        if (
          message.originatorWindowName != null &&
          Object.hasOwnProperty.call(message, 'originatorWindowName')
        )
          writer
            .uint32(/* id 154671, wireType 2 =*/ 1237370)
            .string(message.originatorWindowName);
        if (
          message.manualOrAuto != null &&
          Object.hasOwnProperty.call(message, 'manualOrAuto')
        )
          writer
            .uint32(/* id 154710, wireType 0 =*/ 1237680)
            .int32(message.manualOrAuto);
        if (
          message.origPriceType != null &&
          Object.hasOwnProperty.call(message, 'origPriceType')
        )
          writer
            .uint32(/* id 154770, wireType 0 =*/ 1238160)
            .int32(message.origPriceType);
        if (
          message.remarks != null &&
          Object.hasOwnProperty.call(message, 'remarks')
        )
          writer
            .uint32(/* id 154806, wireType 2 =*/ 1238450)
            .string(message.remarks);
        if (
          message.cancelAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'cancelAtSsboe')
        )
          writer
            .uint32(/* id 157085, wireType 0 =*/ 1256680)
            .int32(message.cancelAtSsboe);
        if (
          message.cancelAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAtUsecs')
        )
          writer
            .uint32(/* id 157086, wireType 0 =*/ 1256688)
            .int32(message.cancelAtUsecs);
        if (
          message.bracketType != null &&
          Object.hasOwnProperty.call(message, 'bracketType')
        )
          writer
            .uint32(/* id 157087, wireType 0 =*/ 1256696)
            .int32(message.bracketType);
        return writer;
      };

      /**
       * Encodes the specified RithmicOrderNotification message, length delimited. Does not implicitly {@link rti.RithmicOrderNotification.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {rti.IRithmicOrderNotification} message RithmicOrderNotification message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RithmicOrderNotification.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RithmicOrderNotification message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RithmicOrderNotification} RithmicOrderNotification
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RithmicOrderNotification.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RithmicOrderNotification();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 154119:
              message.userTag = reader.string();
              break;
            case 153625:
              message.notifyType = reader.int32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 110303:
              message.status = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 154497:
              message.originalBasketId = reader.string();
              break;
            case 110358:
              message.linkedBasketIds = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 131003:
              message.userId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 112021:
              message.tradeExchange = reader.string();
              break;
            case 112016:
              message.tradeRoute = reader.string();
              break;
            case 149238:
              message.exchangeOrderId = reader.string();
              break;
            case 110116:
              message.instrumentType = reader.string();
              break;
            case 149273:
              message.completionReason = reader.string();
              break;
            case 112004:
              message.quantity = reader.int32();
              break;
            case 112027:
              message.quanReleasePending = reader.int32();
              break;
            case 110306:
              message.price = reader.double();
              break;
            case 149247:
              message.triggerPrice = reader.double();
              break;
            case 112003:
              message.transactionType = reader.int32();
              break;
            case 112005:
              message.duration = reader.int32();
              break;
            case 112008:
              message.priceType = reader.int32();
              break;
            case 154770:
              message.origPriceType = reader.int32();
              break;
            case 154710:
              message.manualOrAuto = reader.int32();
              break;
            case 157087:
              message.bracketType = reader.int32();
              break;
            case 110322:
              message.avgFillPrice = reader.double();
              break;
            case 154111:
              message.totalFillSize = reader.int32();
              break;
            case 154112:
              message.totalUnfilledSize = reader.int32();
              break;
            case 112002:
              message.sequenceNumber = reader.string();
              break;
            case 149263:
              message.origSequenceNumber = reader.string();
              break;
            case 149264:
              message.corSequenceNumber = reader.string();
              break;
            case 154382:
              message.currency = reader.string();
              break;
            case 154172:
              message.countryCode = reader.string();
              break;
            case 120008:
              message.text = reader.string();
              break;
            case 120028:
              message.reportText = reader.string();
              break;
            case 154806:
              message.remarks = reader.string();
              break;
            case 154629:
              message.windowName = reader.string();
              break;
            case 154671:
              message.originatorWindowName = reader.string();
              break;
            case 157085:
              message.cancelAtSsboe = reader.int32();
              break;
            case 157086:
              message.cancelAtUsecs = reader.int32();
              break;
            case 154488:
              message.cancelAfterSecs = reader.int32();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RithmicOrderNotification message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RithmicOrderNotification} RithmicOrderNotification
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RithmicOrderNotification.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RithmicOrderNotification message.
       * @function verify
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RithmicOrderNotification.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          if (!$util.isString(message.userTag))
            return 'userTag: string expected';
        if (message.notifyType != null && message.hasOwnProperty('notifyType'))
          switch (message.notifyType) {
            default:
              return 'notifyType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
              break;
          }
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (message.status != null && message.hasOwnProperty('status'))
          if (!$util.isString(message.status)) return 'status: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (
          message.originalBasketId != null &&
          message.hasOwnProperty('originalBasketId')
        )
          if (!$util.isString(message.originalBasketId))
            return 'originalBasketId: string expected';
        if (
          message.linkedBasketIds != null &&
          message.hasOwnProperty('linkedBasketIds')
        )
          if (!$util.isString(message.linkedBasketIds))
            return 'linkedBasketIds: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.userId != null && message.hasOwnProperty('userId'))
          if (!$util.isString(message.userId)) return 'userId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.tradeExchange != null &&
          message.hasOwnProperty('tradeExchange')
        )
          if (!$util.isString(message.tradeExchange))
            return 'tradeExchange: string expected';
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          if (!$util.isString(message.tradeRoute))
            return 'tradeRoute: string expected';
        if (
          message.exchangeOrderId != null &&
          message.hasOwnProperty('exchangeOrderId')
        )
          if (!$util.isString(message.exchangeOrderId))
            return 'exchangeOrderId: string expected';
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          if (!$util.isString(message.instrumentType))
            return 'instrumentType: string expected';
        if (
          message.completionReason != null &&
          message.hasOwnProperty('completionReason')
        )
          if (!$util.isString(message.completionReason))
            return 'completionReason: string expected';
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          if (!$util.isInteger(message.quantity))
            return 'quantity: integer expected';
        if (
          message.quanReleasePending != null &&
          message.hasOwnProperty('quanReleasePending')
        )
          if (!$util.isInteger(message.quanReleasePending))
            return 'quanReleasePending: integer expected';
        if (message.price != null && message.hasOwnProperty('price'))
          if (typeof message.price !== 'number')
            return 'price: number expected';
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          if (typeof message.triggerPrice !== 'number')
            return 'triggerPrice: number expected';
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        )
          switch (message.transactionType) {
            default:
              return 'transactionType: enum value expected';
            case 1:
            case 2:
            case 3:
              break;
          }
        if (message.duration != null && message.hasOwnProperty('duration'))
          switch (message.duration) {
            default:
              return 'duration: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          switch (message.priceType) {
            default:
              return 'priceType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.origPriceType != null &&
          message.hasOwnProperty('origPriceType')
        )
          switch (message.origPriceType) {
            default:
              return 'origPriceType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          switch (message.manualOrAuto) {
            default:
              return 'manualOrAuto: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.bracketType != null &&
          message.hasOwnProperty('bracketType')
        )
          switch (message.bracketType) {
            default:
              return 'bracketType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (
          message.avgFillPrice != null &&
          message.hasOwnProperty('avgFillPrice')
        )
          if (typeof message.avgFillPrice !== 'number')
            return 'avgFillPrice: number expected';
        if (
          message.totalFillSize != null &&
          message.hasOwnProperty('totalFillSize')
        )
          if (!$util.isInteger(message.totalFillSize))
            return 'totalFillSize: integer expected';
        if (
          message.totalUnfilledSize != null &&
          message.hasOwnProperty('totalUnfilledSize')
        )
          if (!$util.isInteger(message.totalUnfilledSize))
            return 'totalUnfilledSize: integer expected';
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          if (!$util.isString(message.sequenceNumber))
            return 'sequenceNumber: string expected';
        if (
          message.origSequenceNumber != null &&
          message.hasOwnProperty('origSequenceNumber')
        )
          if (!$util.isString(message.origSequenceNumber))
            return 'origSequenceNumber: string expected';
        if (
          message.corSequenceNumber != null &&
          message.hasOwnProperty('corSequenceNumber')
        )
          if (!$util.isString(message.corSequenceNumber))
            return 'corSequenceNumber: string expected';
        if (message.currency != null && message.hasOwnProperty('currency'))
          if (!$util.isString(message.currency))
            return 'currency: string expected';
        if (
          message.countryCode != null &&
          message.hasOwnProperty('countryCode')
        )
          if (!$util.isString(message.countryCode))
            return 'countryCode: string expected';
        if (message.text != null && message.hasOwnProperty('text'))
          if (!$util.isString(message.text)) return 'text: string expected';
        if (message.reportText != null && message.hasOwnProperty('reportText'))
          if (!$util.isString(message.reportText))
            return 'reportText: string expected';
        if (message.remarks != null && message.hasOwnProperty('remarks'))
          if (!$util.isString(message.remarks))
            return 'remarks: string expected';
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          if (!$util.isString(message.windowName))
            return 'windowName: string expected';
        if (
          message.originatorWindowName != null &&
          message.hasOwnProperty('originatorWindowName')
        )
          if (!$util.isString(message.originatorWindowName))
            return 'originatorWindowName: string expected';
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          if (!$util.isInteger(message.cancelAtSsboe))
            return 'cancelAtSsboe: integer expected';
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          if (!$util.isInteger(message.cancelAtUsecs))
            return 'cancelAtUsecs: integer expected';
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          if (!$util.isInteger(message.cancelAfterSecs))
            return 'cancelAfterSecs: integer expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates a RithmicOrderNotification message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RithmicOrderNotification} RithmicOrderNotification
       */
      RithmicOrderNotification.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RithmicOrderNotification) return object;
        var message = new $root.rti.RithmicOrderNotification();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userTag != null) message.userTag = String(object.userTag);
        switch (object.notifyType) {
          case 'ORDER_RCVD_FROM_CLNT':
          case 1:
            message.notifyType = 1;
            break;
          case 'MODIFY_RCVD_FROM_CLNT':
          case 2:
            message.notifyType = 2;
            break;
          case 'CANCEL_RCVD_FROM_CLNT':
          case 3:
            message.notifyType = 3;
            break;
          case 'OPEN_PENDING':
          case 4:
            message.notifyType = 4;
            break;
          case 'MODIFY_PENDING':
          case 5:
            message.notifyType = 5;
            break;
          case 'CANCEL_PENDING':
          case 6:
            message.notifyType = 6;
            break;
          case 'ORDER_RCVD_BY_EXCH_GTWY':
          case 7:
            message.notifyType = 7;
            break;
          case 'MODIFY_RCVD_BY_EXCH_GTWY':
          case 8:
            message.notifyType = 8;
            break;
          case 'CANCEL_RCVD_BY_EXCH_GTWY':
          case 9:
            message.notifyType = 9;
            break;
          case 'ORDER_SENT_TO_EXCH':
          case 10:
            message.notifyType = 10;
            break;
          case 'MODIFY_SENT_TO_EXCH':
          case 11:
            message.notifyType = 11;
            break;
          case 'CANCEL_SENT_TO_EXCH':
          case 12:
            message.notifyType = 12;
            break;
          case 'OPEN':
          case 13:
            message.notifyType = 13;
            break;
          case 'MODIFIED':
          case 14:
            message.notifyType = 14;
            break;
          case 'COMPLETE':
          case 15:
            message.notifyType = 15;
            break;
          case 'MODIFICATION_FAILED':
          case 16:
            message.notifyType = 16;
            break;
          case 'CANCELLATION_FAILED':
          case 17:
            message.notifyType = 17;
            break;
          case 'TRIGGER_PENDING':
          case 18:
            message.notifyType = 18;
            break;
          case 'GENERIC':
          case 19:
            message.notifyType = 19;
            break;
          case 'LINK_ORDERS_FAILED':
          case 20:
            message.notifyType = 20;
            break;
        }
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.status != null) message.status = String(object.status);
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.originalBasketId != null)
          message.originalBasketId = String(object.originalBasketId);
        if (object.linkedBasketIds != null)
          message.linkedBasketIds = String(object.linkedBasketIds);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.userId != null) message.userId = String(object.userId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.tradeExchange != null)
          message.tradeExchange = String(object.tradeExchange);
        if (object.tradeRoute != null)
          message.tradeRoute = String(object.tradeRoute);
        if (object.exchangeOrderId != null)
          message.exchangeOrderId = String(object.exchangeOrderId);
        if (object.instrumentType != null)
          message.instrumentType = String(object.instrumentType);
        if (object.completionReason != null)
          message.completionReason = String(object.completionReason);
        if (object.quantity != null) message.quantity = object.quantity | 0;
        if (object.quanReleasePending != null)
          message.quanReleasePending = object.quanReleasePending | 0;
        if (object.price != null) message.price = Number(object.price);
        if (object.triggerPrice != null)
          message.triggerPrice = Number(object.triggerPrice);
        switch (object.transactionType) {
          case 'BUY':
          case 1:
            message.transactionType = 1;
            break;
          case 'SELL':
          case 2:
            message.transactionType = 2;
            break;
          case 'SS':
          case 3:
            message.transactionType = 3;
            break;
        }
        switch (object.duration) {
          case 'DAY':
          case 1:
            message.duration = 1;
            break;
          case 'GTC':
          case 2:
            message.duration = 2;
            break;
          case 'IOC':
          case 3:
            message.duration = 3;
            break;
          case 'FOK':
          case 4:
            message.duration = 4;
            break;
        }
        switch (object.priceType) {
          case 'LIMIT':
          case 1:
            message.priceType = 1;
            break;
          case 'MARKET':
          case 2:
            message.priceType = 2;
            break;
          case 'STOP_LIMIT':
          case 3:
            message.priceType = 3;
            break;
          case 'STOP_MARKET':
          case 4:
            message.priceType = 4;
            break;
        }
        switch (object.origPriceType) {
          case 'LIMIT':
          case 1:
            message.origPriceType = 1;
            break;
          case 'MARKET':
          case 2:
            message.origPriceType = 2;
            break;
          case 'STOP_LIMIT':
          case 3:
            message.origPriceType = 3;
            break;
          case 'STOP_MARKET':
          case 4:
            message.origPriceType = 4;
            break;
        }
        switch (object.manualOrAuto) {
          case 'MANUAL':
          case 1:
            message.manualOrAuto = 1;
            break;
          case 'AUTO':
          case 2:
            message.manualOrAuto = 2;
            break;
        }
        switch (object.bracketType) {
          case 'STOP_ONLY':
          case 1:
            message.bracketType = 1;
            break;
          case 'TARGET_ONLY':
          case 2:
            message.bracketType = 2;
            break;
          case 'TARGET_AND_STOP':
          case 3:
            message.bracketType = 3;
            break;
          case 'STOP_ONLY_STATIC':
          case 4:
            message.bracketType = 4;
            break;
          case 'TARGET_ONLY_STATIC':
          case 5:
            message.bracketType = 5;
            break;
          case 'TARGET_AND_STOP_STATIC':
          case 6:
            message.bracketType = 6;
            break;
        }
        if (object.avgFillPrice != null)
          message.avgFillPrice = Number(object.avgFillPrice);
        if (object.totalFillSize != null)
          message.totalFillSize = object.totalFillSize | 0;
        if (object.totalUnfilledSize != null)
          message.totalUnfilledSize = object.totalUnfilledSize | 0;
        if (object.sequenceNumber != null)
          message.sequenceNumber = String(object.sequenceNumber);
        if (object.origSequenceNumber != null)
          message.origSequenceNumber = String(object.origSequenceNumber);
        if (object.corSequenceNumber != null)
          message.corSequenceNumber = String(object.corSequenceNumber);
        if (object.currency != null) message.currency = String(object.currency);
        if (object.countryCode != null)
          message.countryCode = String(object.countryCode);
        if (object.text != null) message.text = String(object.text);
        if (object.reportText != null)
          message.reportText = String(object.reportText);
        if (object.remarks != null) message.remarks = String(object.remarks);
        if (object.windowName != null)
          message.windowName = String(object.windowName);
        if (object.originatorWindowName != null)
          message.originatorWindowName = String(object.originatorWindowName);
        if (object.cancelAtSsboe != null)
          message.cancelAtSsboe = object.cancelAtSsboe | 0;
        if (object.cancelAtUsecs != null)
          message.cancelAtUsecs = object.cancelAtUsecs | 0;
        if (object.cancelAfterSecs != null)
          message.cancelAfterSecs = object.cancelAfterSecs | 0;
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from a RithmicOrderNotification message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {rti.RithmicOrderNotification} message RithmicOrderNotification
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RithmicOrderNotification.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.instrumentType = '';
          object.isSnapshot = false;
          object.basketId = '';
          object.status = '';
          object.price = 0;
          object.avgFillPrice = 0;
          object.linkedBasketIds = '';
          object.sequenceNumber = '';
          object.transactionType = options.enums === String ? 'BUY' : 1;
          object.quantity = 0;
          object.duration = options.enums === String ? 'DAY' : 1;
          object.priceType = options.enums === String ? 'LIMIT' : 1;
          object.tradeRoute = '';
          object.tradeExchange = '';
          object.quanReleasePending = 0;
          object.text = '';
          object.reportText = '';
          object.userId = '';
          object.exchangeOrderId = '';
          object.triggerPrice = 0;
          object.origSequenceNumber = '';
          object.corSequenceNumber = '';
          object.completionReason = '';
          object.ssboe = 0;
          object.usecs = 0;
          object.notifyType =
            options.enums === String ? 'ORDER_RCVD_FROM_CLNT' : 1;
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.totalFillSize = 0;
          object.totalUnfilledSize = 0;
          object.userTag = '';
          object.countryCode = '';
          object.currency = '';
          object.templateId = 0;
          object.cancelAfterSecs = 0;
          object.originalBasketId = '';
          object.windowName = '';
          object.originatorWindowName = '';
          object.manualOrAuto = options.enums === String ? 'MANUAL' : 1;
          object.origPriceType = options.enums === String ? 'LIMIT' : 1;
          object.remarks = '';
          object.cancelAtSsboe = 0;
          object.cancelAtUsecs = 0;
          object.bracketType = options.enums === String ? 'STOP_ONLY' : 1;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          object.instrumentType = message.instrumentType;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.status != null && message.hasOwnProperty('status'))
          object.status = message.status;
        if (message.price != null && message.hasOwnProperty('price'))
          object.price =
            options.json && !isFinite(message.price)
              ? String(message.price)
              : message.price;
        if (
          message.avgFillPrice != null &&
          message.hasOwnProperty('avgFillPrice')
        )
          object.avgFillPrice =
            options.json && !isFinite(message.avgFillPrice)
              ? String(message.avgFillPrice)
              : message.avgFillPrice;
        if (
          message.linkedBasketIds != null &&
          message.hasOwnProperty('linkedBasketIds')
        )
          object.linkedBasketIds = message.linkedBasketIds;
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          object.sequenceNumber = message.sequenceNumber;
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        )
          object.transactionType =
            options.enums === String
              ? $root.rti.RithmicOrderNotification.TransactionType[
                  message.transactionType
                ]
              : message.transactionType;
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          object.quantity = message.quantity;
        if (message.duration != null && message.hasOwnProperty('duration'))
          object.duration =
            options.enums === String
              ? $root.rti.RithmicOrderNotification.Duration[message.duration]
              : message.duration;
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          object.priceType =
            options.enums === String
              ? $root.rti.RithmicOrderNotification.PriceType[message.priceType]
              : message.priceType;
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          object.tradeRoute = message.tradeRoute;
        if (
          message.tradeExchange != null &&
          message.hasOwnProperty('tradeExchange')
        )
          object.tradeExchange = message.tradeExchange;
        if (
          message.quanReleasePending != null &&
          message.hasOwnProperty('quanReleasePending')
        )
          object.quanReleasePending = message.quanReleasePending;
        if (message.text != null && message.hasOwnProperty('text'))
          object.text = message.text;
        if (message.reportText != null && message.hasOwnProperty('reportText'))
          object.reportText = message.reportText;
        if (message.userId != null && message.hasOwnProperty('userId'))
          object.userId = message.userId;
        if (
          message.exchangeOrderId != null &&
          message.hasOwnProperty('exchangeOrderId')
        )
          object.exchangeOrderId = message.exchangeOrderId;
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          object.triggerPrice =
            options.json && !isFinite(message.triggerPrice)
              ? String(message.triggerPrice)
              : message.triggerPrice;
        if (
          message.origSequenceNumber != null &&
          message.hasOwnProperty('origSequenceNumber')
        )
          object.origSequenceNumber = message.origSequenceNumber;
        if (
          message.corSequenceNumber != null &&
          message.hasOwnProperty('corSequenceNumber')
        )
          object.corSequenceNumber = message.corSequenceNumber;
        if (
          message.completionReason != null &&
          message.hasOwnProperty('completionReason')
        )
          object.completionReason = message.completionReason;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.notifyType != null && message.hasOwnProperty('notifyType'))
          object.notifyType =
            options.enums === String
              ? $root.rti.RithmicOrderNotification.NotifyType[
                  message.notifyType
                ]
              : message.notifyType;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (
          message.totalFillSize != null &&
          message.hasOwnProperty('totalFillSize')
        )
          object.totalFillSize = message.totalFillSize;
        if (
          message.totalUnfilledSize != null &&
          message.hasOwnProperty('totalUnfilledSize')
        )
          object.totalUnfilledSize = message.totalUnfilledSize;
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          object.userTag = message.userTag;
        if (
          message.countryCode != null &&
          message.hasOwnProperty('countryCode')
        )
          object.countryCode = message.countryCode;
        if (message.currency != null && message.hasOwnProperty('currency'))
          object.currency = message.currency;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          object.cancelAfterSecs = message.cancelAfterSecs;
        if (
          message.originalBasketId != null &&
          message.hasOwnProperty('originalBasketId')
        )
          object.originalBasketId = message.originalBasketId;
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          object.windowName = message.windowName;
        if (
          message.originatorWindowName != null &&
          message.hasOwnProperty('originatorWindowName')
        )
          object.originatorWindowName = message.originatorWindowName;
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          object.manualOrAuto =
            options.enums === String
              ? $root.rti.RithmicOrderNotification.OrderPlacement[
                  message.manualOrAuto
                ]
              : message.manualOrAuto;
        if (
          message.origPriceType != null &&
          message.hasOwnProperty('origPriceType')
        )
          object.origPriceType =
            options.enums === String
              ? $root.rti.RithmicOrderNotification.PriceType[
                  message.origPriceType
                ]
              : message.origPriceType;
        if (message.remarks != null && message.hasOwnProperty('remarks'))
          object.remarks = message.remarks;
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          object.cancelAtSsboe = message.cancelAtSsboe;
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          object.cancelAtUsecs = message.cancelAtUsecs;
        if (
          message.bracketType != null &&
          message.hasOwnProperty('bracketType')
        )
          object.bracketType =
            options.enums === String
              ? $root.rti.RithmicOrderNotification.BracketType[
                  message.bracketType
                ]
              : message.bracketType;
        return object;
      };

      /**
       * Converts this RithmicOrderNotification to JSON.
       * @function toJSON
       * @memberof rti.RithmicOrderNotification
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RithmicOrderNotification.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RithmicOrderNotification
       * @function getTypeUrl
       * @memberof rti.RithmicOrderNotification
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RithmicOrderNotification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RithmicOrderNotification';
      };

      /**
       * NotifyType enum.
       * @name rti.RithmicOrderNotification.NotifyType
       * @enum {number}
       * @property {number} ORDER_RCVD_FROM_CLNT=1 ORDER_RCVD_FROM_CLNT value
       * @property {number} MODIFY_RCVD_FROM_CLNT=2 MODIFY_RCVD_FROM_CLNT value
       * @property {number} CANCEL_RCVD_FROM_CLNT=3 CANCEL_RCVD_FROM_CLNT value
       * @property {number} OPEN_PENDING=4 OPEN_PENDING value
       * @property {number} MODIFY_PENDING=5 MODIFY_PENDING value
       * @property {number} CANCEL_PENDING=6 CANCEL_PENDING value
       * @property {number} ORDER_RCVD_BY_EXCH_GTWY=7 ORDER_RCVD_BY_EXCH_GTWY value
       * @property {number} MODIFY_RCVD_BY_EXCH_GTWY=8 MODIFY_RCVD_BY_EXCH_GTWY value
       * @property {number} CANCEL_RCVD_BY_EXCH_GTWY=9 CANCEL_RCVD_BY_EXCH_GTWY value
       * @property {number} ORDER_SENT_TO_EXCH=10 ORDER_SENT_TO_EXCH value
       * @property {number} MODIFY_SENT_TO_EXCH=11 MODIFY_SENT_TO_EXCH value
       * @property {number} CANCEL_SENT_TO_EXCH=12 CANCEL_SENT_TO_EXCH value
       * @property {number} OPEN=13 OPEN value
       * @property {number} MODIFIED=14 MODIFIED value
       * @property {number} COMPLETE=15 COMPLETE value
       * @property {number} MODIFICATION_FAILED=16 MODIFICATION_FAILED value
       * @property {number} CANCELLATION_FAILED=17 CANCELLATION_FAILED value
       * @property {number} TRIGGER_PENDING=18 TRIGGER_PENDING value
       * @property {number} GENERIC=19 GENERIC value
       * @property {number} LINK_ORDERS_FAILED=20 LINK_ORDERS_FAILED value
       */
      RithmicOrderNotification.NotifyType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'ORDER_RCVD_FROM_CLNT')] = 1;
        values[(valuesById[2] = 'MODIFY_RCVD_FROM_CLNT')] = 2;
        values[(valuesById[3] = 'CANCEL_RCVD_FROM_CLNT')] = 3;
        values[(valuesById[4] = 'OPEN_PENDING')] = 4;
        values[(valuesById[5] = 'MODIFY_PENDING')] = 5;
        values[(valuesById[6] = 'CANCEL_PENDING')] = 6;
        values[(valuesById[7] = 'ORDER_RCVD_BY_EXCH_GTWY')] = 7;
        values[(valuesById[8] = 'MODIFY_RCVD_BY_EXCH_GTWY')] = 8;
        values[(valuesById[9] = 'CANCEL_RCVD_BY_EXCH_GTWY')] = 9;
        values[(valuesById[10] = 'ORDER_SENT_TO_EXCH')] = 10;
        values[(valuesById[11] = 'MODIFY_SENT_TO_EXCH')] = 11;
        values[(valuesById[12] = 'CANCEL_SENT_TO_EXCH')] = 12;
        values[(valuesById[13] = 'OPEN')] = 13;
        values[(valuesById[14] = 'MODIFIED')] = 14;
        values[(valuesById[15] = 'COMPLETE')] = 15;
        values[(valuesById[16] = 'MODIFICATION_FAILED')] = 16;
        values[(valuesById[17] = 'CANCELLATION_FAILED')] = 17;
        values[(valuesById[18] = 'TRIGGER_PENDING')] = 18;
        values[(valuesById[19] = 'GENERIC')] = 19;
        values[(valuesById[20] = 'LINK_ORDERS_FAILED')] = 20;
        return values;
      })();

      /**
       * TransactionType enum.
       * @name rti.RithmicOrderNotification.TransactionType
       * @enum {number}
       * @property {number} BUY=1 BUY value
       * @property {number} SELL=2 SELL value
       * @property {number} SS=3 SS value
       */
      RithmicOrderNotification.TransactionType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY')] = 1;
        values[(valuesById[2] = 'SELL')] = 2;
        values[(valuesById[3] = 'SS')] = 3;
        return values;
      })();

      /**
       * Duration enum.
       * @name rti.RithmicOrderNotification.Duration
       * @enum {number}
       * @property {number} DAY=1 DAY value
       * @property {number} GTC=2 GTC value
       * @property {number} IOC=3 IOC value
       * @property {number} FOK=4 FOK value
       */
      RithmicOrderNotification.Duration = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'DAY')] = 1;
        values[(valuesById[2] = 'GTC')] = 2;
        values[(valuesById[3] = 'IOC')] = 3;
        values[(valuesById[4] = 'FOK')] = 4;
        return values;
      })();

      /**
       * PriceType enum.
       * @name rti.RithmicOrderNotification.PriceType
       * @enum {number}
       * @property {number} LIMIT=1 LIMIT value
       * @property {number} MARKET=2 MARKET value
       * @property {number} STOP_LIMIT=3 STOP_LIMIT value
       * @property {number} STOP_MARKET=4 STOP_MARKET value
       */
      RithmicOrderNotification.PriceType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LIMIT')] = 1;
        values[(valuesById[2] = 'MARKET')] = 2;
        values[(valuesById[3] = 'STOP_LIMIT')] = 3;
        values[(valuesById[4] = 'STOP_MARKET')] = 4;
        return values;
      })();

      /**
       * BracketType enum.
       * @name rti.RithmicOrderNotification.BracketType
       * @enum {number}
       * @property {number} STOP_ONLY=1 STOP_ONLY value
       * @property {number} TARGET_ONLY=2 TARGET_ONLY value
       * @property {number} TARGET_AND_STOP=3 TARGET_AND_STOP value
       * @property {number} STOP_ONLY_STATIC=4 STOP_ONLY_STATIC value
       * @property {number} TARGET_ONLY_STATIC=5 TARGET_ONLY_STATIC value
       * @property {number} TARGET_AND_STOP_STATIC=6 TARGET_AND_STOP_STATIC value
       */
      RithmicOrderNotification.BracketType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'STOP_ONLY')] = 1;
        values[(valuesById[2] = 'TARGET_ONLY')] = 2;
        values[(valuesById[3] = 'TARGET_AND_STOP')] = 3;
        values[(valuesById[4] = 'STOP_ONLY_STATIC')] = 4;
        values[(valuesById[5] = 'TARGET_ONLY_STATIC')] = 5;
        values[(valuesById[6] = 'TARGET_AND_STOP_STATIC')] = 6;
        return values;
      })();

      /**
       * OrderPlacement enum.
       * @name rti.RithmicOrderNotification.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      RithmicOrderNotification.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      return RithmicOrderNotification;
    })();

    rti.ExchangeOrderNotification = (function () {
      /**
       * Properties of an ExchangeOrderNotification.
       * @memberof rti
       * @interface IExchangeOrderNotification
       * @property {number} templateId ExchangeOrderNotification templateId
       * @property {string|null} [userTag] ExchangeOrderNotification userTag
       * @property {rti.ExchangeOrderNotification.NotifyType|null} [notifyType] ExchangeOrderNotification notifyType
       * @property {boolean|null} [isSnapshot] ExchangeOrderNotification isSnapshot
       * @property {boolean|null} [isRithmicInternalMsg] ExchangeOrderNotification isRithmicInternalMsg
       * @property {string|null} [reportType] ExchangeOrderNotification reportType
       * @property {string|null} [status] ExchangeOrderNotification status
       * @property {string|null} [basketId] ExchangeOrderNotification basketId
       * @property {string|null} [originalBasketId] ExchangeOrderNotification originalBasketId
       * @property {string|null} [linkedBasketIds] ExchangeOrderNotification linkedBasketIds
       * @property {string|null} [fcmId] ExchangeOrderNotification fcmId
       * @property {string|null} [ibId] ExchangeOrderNotification ibId
       * @property {string|null} [userId] ExchangeOrderNotification userId
       * @property {string|null} [accountId] ExchangeOrderNotification accountId
       * @property {string|null} [symbol] ExchangeOrderNotification symbol
       * @property {string|null} [exchange] ExchangeOrderNotification exchange
       * @property {string|null} [tradeExchange] ExchangeOrderNotification tradeExchange
       * @property {string|null} [tradeRoute] ExchangeOrderNotification tradeRoute
       * @property {string|null} [exchangeOrderId] ExchangeOrderNotification exchangeOrderId
       * @property {string|null} [tpExchangeOrderId] ExchangeOrderNotification tpExchangeOrderId
       * @property {string|null} [instrumentType] ExchangeOrderNotification instrumentType
       * @property {number|null} [quantity] ExchangeOrderNotification quantity
       * @property {number|null} [price] ExchangeOrderNotification price
       * @property {number|null} [triggerPrice] ExchangeOrderNotification triggerPrice
       * @property {rti.ExchangeOrderNotification.TransactionType|null} [transactionType] ExchangeOrderNotification transactionType
       * @property {rti.ExchangeOrderNotification.Duration|null} [duration] ExchangeOrderNotification duration
       * @property {rti.ExchangeOrderNotification.PriceType|null} [priceType] ExchangeOrderNotification priceType
       * @property {rti.ExchangeOrderNotification.PriceType|null} [origPriceType] ExchangeOrderNotification origPriceType
       * @property {rti.ExchangeOrderNotification.OrderPlacement|null} [manualOrAuto] ExchangeOrderNotification manualOrAuto
       * @property {rti.ExchangeOrderNotification.BracketType|null} [bracketType] ExchangeOrderNotification bracketType
       * @property {number|null} [confirmedSize] ExchangeOrderNotification confirmedSize
       * @property {string|null} [confirmedTime] ExchangeOrderNotification confirmedTime
       * @property {string|null} [confirmedDate] ExchangeOrderNotification confirmedDate
       * @property {string|null} [confirmedId] ExchangeOrderNotification confirmedId
       * @property {number|null} [modifiedSize] ExchangeOrderNotification modifiedSize
       * @property {string|null} [modifiedTime] ExchangeOrderNotification modifiedTime
       * @property {string|null} [modifiedDate] ExchangeOrderNotification modifiedDate
       * @property {string|null} [modifyId] ExchangeOrderNotification modifyId
       * @property {number|null} [cancelledSize] ExchangeOrderNotification cancelledSize
       * @property {string|null} [cancelledTime] ExchangeOrderNotification cancelledTime
       * @property {string|null} [cancelledDate] ExchangeOrderNotification cancelledDate
       * @property {string|null} [cancelledId] ExchangeOrderNotification cancelledId
       * @property {number|null} [fillPrice] ExchangeOrderNotification fillPrice
       * @property {number|null} [fillSize] ExchangeOrderNotification fillSize
       * @property {string|null} [fillTime] ExchangeOrderNotification fillTime
       * @property {string|null} [fillDate] ExchangeOrderNotification fillDate
       * @property {string|null} [fillId] ExchangeOrderNotification fillId
       * @property {number|null} [avgFillPrice] ExchangeOrderNotification avgFillPrice
       * @property {number|null} [totalFillSize] ExchangeOrderNotification totalFillSize
       * @property {number|null} [totalUnfilledSize] ExchangeOrderNotification totalUnfilledSize
       * @property {string|null} [triggerId] ExchangeOrderNotification triggerId
       * @property {string|null} [sequenceNumber] ExchangeOrderNotification sequenceNumber
       * @property {string|null} [origSequenceNumber] ExchangeOrderNotification origSequenceNumber
       * @property {string|null} [corSequenceNumber] ExchangeOrderNotification corSequenceNumber
       * @property {string|null} [currency] ExchangeOrderNotification currency
       * @property {string|null} [countryCode] ExchangeOrderNotification countryCode
       * @property {string|null} [text] ExchangeOrderNotification text
       * @property {string|null} [reportText] ExchangeOrderNotification reportText
       * @property {string|null} [remarks] ExchangeOrderNotification remarks
       * @property {string|null} [windowName] ExchangeOrderNotification windowName
       * @property {string|null} [originatorWindowName] ExchangeOrderNotification originatorWindowName
       * @property {number|null} [cancelAtSsboe] ExchangeOrderNotification cancelAtSsboe
       * @property {number|null} [cancelAtUsecs] ExchangeOrderNotification cancelAtUsecs
       * @property {number|null} [cancelAfterSecs] ExchangeOrderNotification cancelAfterSecs
       * @property {number|null} [ssboe] ExchangeOrderNotification ssboe
       * @property {number|null} [usecs] ExchangeOrderNotification usecs
       * @property {number|null} [exchReceiptSsboe] ExchangeOrderNotification exchReceiptSsboe
       * @property {number|null} [exchReceiptNsecs] ExchangeOrderNotification exchReceiptNsecs
       */

      /**
       * Constructs a new ExchangeOrderNotification.
       * @memberof rti
       * @classdesc Represents an ExchangeOrderNotification.
       * @implements IExchangeOrderNotification
       * @constructor
       * @param {rti.IExchangeOrderNotification=} [properties] Properties to set
       */
      function ExchangeOrderNotification(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ExchangeOrderNotification templateId.
       * @member {number} templateId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.templateId = 0;

      /**
       * ExchangeOrderNotification userTag.
       * @member {string} userTag
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.userTag = '';

      /**
       * ExchangeOrderNotification notifyType.
       * @member {rti.ExchangeOrderNotification.NotifyType} notifyType
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.notifyType = 1;

      /**
       * ExchangeOrderNotification isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.isSnapshot = false;

      /**
       * ExchangeOrderNotification isRithmicInternalMsg.
       * @member {boolean} isRithmicInternalMsg
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.isRithmicInternalMsg = false;

      /**
       * ExchangeOrderNotification reportType.
       * @member {string} reportType
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.reportType = '';

      /**
       * ExchangeOrderNotification status.
       * @member {string} status
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.status = '';

      /**
       * ExchangeOrderNotification basketId.
       * @member {string} basketId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.basketId = '';

      /**
       * ExchangeOrderNotification originalBasketId.
       * @member {string} originalBasketId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.originalBasketId = '';

      /**
       * ExchangeOrderNotification linkedBasketIds.
       * @member {string} linkedBasketIds
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.linkedBasketIds = '';

      /**
       * ExchangeOrderNotification fcmId.
       * @member {string} fcmId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.fcmId = '';

      /**
       * ExchangeOrderNotification ibId.
       * @member {string} ibId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.ibId = '';

      /**
       * ExchangeOrderNotification userId.
       * @member {string} userId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.userId = '';

      /**
       * ExchangeOrderNotification accountId.
       * @member {string} accountId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.accountId = '';

      /**
       * ExchangeOrderNotification symbol.
       * @member {string} symbol
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.symbol = '';

      /**
       * ExchangeOrderNotification exchange.
       * @member {string} exchange
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.exchange = '';

      /**
       * ExchangeOrderNotification tradeExchange.
       * @member {string} tradeExchange
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.tradeExchange = '';

      /**
       * ExchangeOrderNotification tradeRoute.
       * @member {string} tradeRoute
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.tradeRoute = '';

      /**
       * ExchangeOrderNotification exchangeOrderId.
       * @member {string} exchangeOrderId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.exchangeOrderId = '';

      /**
       * ExchangeOrderNotification tpExchangeOrderId.
       * @member {string} tpExchangeOrderId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.tpExchangeOrderId = '';

      /**
       * ExchangeOrderNotification instrumentType.
       * @member {string} instrumentType
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.instrumentType = '';

      /**
       * ExchangeOrderNotification quantity.
       * @member {number} quantity
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.quantity = 0;

      /**
       * ExchangeOrderNotification price.
       * @member {number} price
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.price = 0;

      /**
       * ExchangeOrderNotification triggerPrice.
       * @member {number} triggerPrice
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.triggerPrice = 0;

      /**
       * ExchangeOrderNotification transactionType.
       * @member {rti.ExchangeOrderNotification.TransactionType} transactionType
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.transactionType = 1;

      /**
       * ExchangeOrderNotification duration.
       * @member {rti.ExchangeOrderNotification.Duration} duration
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.duration = 1;

      /**
       * ExchangeOrderNotification priceType.
       * @member {rti.ExchangeOrderNotification.PriceType} priceType
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.priceType = 1;

      /**
       * ExchangeOrderNotification origPriceType.
       * @member {rti.ExchangeOrderNotification.PriceType} origPriceType
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.origPriceType = 1;

      /**
       * ExchangeOrderNotification manualOrAuto.
       * @member {rti.ExchangeOrderNotification.OrderPlacement} manualOrAuto
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.manualOrAuto = 1;

      /**
       * ExchangeOrderNotification bracketType.
       * @member {rti.ExchangeOrderNotification.BracketType} bracketType
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.bracketType = 1;

      /**
       * ExchangeOrderNotification confirmedSize.
       * @member {number} confirmedSize
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.confirmedSize = 0;

      /**
       * ExchangeOrderNotification confirmedTime.
       * @member {string} confirmedTime
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.confirmedTime = '';

      /**
       * ExchangeOrderNotification confirmedDate.
       * @member {string} confirmedDate
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.confirmedDate = '';

      /**
       * ExchangeOrderNotification confirmedId.
       * @member {string} confirmedId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.confirmedId = '';

      /**
       * ExchangeOrderNotification modifiedSize.
       * @member {number} modifiedSize
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.modifiedSize = 0;

      /**
       * ExchangeOrderNotification modifiedTime.
       * @member {string} modifiedTime
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.modifiedTime = '';

      /**
       * ExchangeOrderNotification modifiedDate.
       * @member {string} modifiedDate
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.modifiedDate = '';

      /**
       * ExchangeOrderNotification modifyId.
       * @member {string} modifyId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.modifyId = '';

      /**
       * ExchangeOrderNotification cancelledSize.
       * @member {number} cancelledSize
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.cancelledSize = 0;

      /**
       * ExchangeOrderNotification cancelledTime.
       * @member {string} cancelledTime
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.cancelledTime = '';

      /**
       * ExchangeOrderNotification cancelledDate.
       * @member {string} cancelledDate
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.cancelledDate = '';

      /**
       * ExchangeOrderNotification cancelledId.
       * @member {string} cancelledId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.cancelledId = '';

      /**
       * ExchangeOrderNotification fillPrice.
       * @member {number} fillPrice
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.fillPrice = 0;

      /**
       * ExchangeOrderNotification fillSize.
       * @member {number} fillSize
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.fillSize = 0;

      /**
       * ExchangeOrderNotification fillTime.
       * @member {string} fillTime
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.fillTime = '';

      /**
       * ExchangeOrderNotification fillDate.
       * @member {string} fillDate
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.fillDate = '';

      /**
       * ExchangeOrderNotification fillId.
       * @member {string} fillId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.fillId = '';

      /**
       * ExchangeOrderNotification avgFillPrice.
       * @member {number} avgFillPrice
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.avgFillPrice = 0;

      /**
       * ExchangeOrderNotification totalFillSize.
       * @member {number} totalFillSize
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.totalFillSize = 0;

      /**
       * ExchangeOrderNotification totalUnfilledSize.
       * @member {number} totalUnfilledSize
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.totalUnfilledSize = 0;

      /**
       * ExchangeOrderNotification triggerId.
       * @member {string} triggerId
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.triggerId = '';

      /**
       * ExchangeOrderNotification sequenceNumber.
       * @member {string} sequenceNumber
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.sequenceNumber = '';

      /**
       * ExchangeOrderNotification origSequenceNumber.
       * @member {string} origSequenceNumber
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.origSequenceNumber = '';

      /**
       * ExchangeOrderNotification corSequenceNumber.
       * @member {string} corSequenceNumber
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.corSequenceNumber = '';

      /**
       * ExchangeOrderNotification currency.
       * @member {string} currency
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.currency = '';

      /**
       * ExchangeOrderNotification countryCode.
       * @member {string} countryCode
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.countryCode = '';

      /**
       * ExchangeOrderNotification text.
       * @member {string} text
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.text = '';

      /**
       * ExchangeOrderNotification reportText.
       * @member {string} reportText
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.reportText = '';

      /**
       * ExchangeOrderNotification remarks.
       * @member {string} remarks
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.remarks = '';

      /**
       * ExchangeOrderNotification windowName.
       * @member {string} windowName
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.windowName = '';

      /**
       * ExchangeOrderNotification originatorWindowName.
       * @member {string} originatorWindowName
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.originatorWindowName = '';

      /**
       * ExchangeOrderNotification cancelAtSsboe.
       * @member {number} cancelAtSsboe
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.cancelAtSsboe = 0;

      /**
       * ExchangeOrderNotification cancelAtUsecs.
       * @member {number} cancelAtUsecs
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.cancelAtUsecs = 0;

      /**
       * ExchangeOrderNotification cancelAfterSecs.
       * @member {number} cancelAfterSecs
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.cancelAfterSecs = 0;

      /**
       * ExchangeOrderNotification ssboe.
       * @member {number} ssboe
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.ssboe = 0;

      /**
       * ExchangeOrderNotification usecs.
       * @member {number} usecs
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.usecs = 0;

      /**
       * ExchangeOrderNotification exchReceiptSsboe.
       * @member {number} exchReceiptSsboe
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.exchReceiptSsboe = 0;

      /**
       * ExchangeOrderNotification exchReceiptNsecs.
       * @member {number} exchReceiptNsecs
       * @memberof rti.ExchangeOrderNotification
       * @instance
       */
      ExchangeOrderNotification.prototype.exchReceiptNsecs = 0;

      /**
       * Creates a new ExchangeOrderNotification instance using the specified properties.
       * @function create
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {rti.IExchangeOrderNotification=} [properties] Properties to set
       * @returns {rti.ExchangeOrderNotification} ExchangeOrderNotification instance
       */
      ExchangeOrderNotification.create = function create(properties) {
        return new ExchangeOrderNotification(properties);
      };

      /**
       * Encodes the specified ExchangeOrderNotification message. Does not implicitly {@link rti.ExchangeOrderNotification.verify|verify} messages.
       * @function encode
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {rti.IExchangeOrderNotification} message ExchangeOrderNotification message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ExchangeOrderNotification.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.instrumentType != null &&
          Object.hasOwnProperty.call(message, 'instrumentType')
        )
          writer
            .uint32(/* id 110116, wireType 2 =*/ 880930)
            .string(message.instrumentType);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.basketId != null &&
          Object.hasOwnProperty.call(message, 'basketId')
        )
          writer
            .uint32(/* id 110300, wireType 2 =*/ 882402)
            .string(message.basketId);
        if (
          message.status != null &&
          Object.hasOwnProperty.call(message, 'status')
        )
          writer
            .uint32(/* id 110303, wireType 2 =*/ 882426)
            .string(message.status);
        if (
          message.price != null &&
          Object.hasOwnProperty.call(message, 'price')
        )
          writer
            .uint32(/* id 110306, wireType 1 =*/ 882449)
            .double(message.price);
        if (
          message.fillPrice != null &&
          Object.hasOwnProperty.call(message, 'fillPrice')
        )
          writer
            .uint32(/* id 110307, wireType 1 =*/ 882457)
            .double(message.fillPrice);
        if (
          message.fillSize != null &&
          Object.hasOwnProperty.call(message, 'fillSize')
        )
          writer
            .uint32(/* id 110308, wireType 0 =*/ 882464)
            .int32(message.fillSize);
        if (
          message.fillTime != null &&
          Object.hasOwnProperty.call(message, 'fillTime')
        )
          writer
            .uint32(/* id 110309, wireType 2 =*/ 882474)
            .string(message.fillTime);
        if (
          message.fillDate != null &&
          Object.hasOwnProperty.call(message, 'fillDate')
        )
          writer
            .uint32(/* id 110310, wireType 2 =*/ 882482)
            .string(message.fillDate);
        if (
          message.fillId != null &&
          Object.hasOwnProperty.call(message, 'fillId')
        )
          writer
            .uint32(/* id 110311, wireType 2 =*/ 882490)
            .string(message.fillId);
        if (
          message.cancelledSize != null &&
          Object.hasOwnProperty.call(message, 'cancelledSize')
        )
          writer
            .uint32(/* id 110318, wireType 0 =*/ 882544)
            .int32(message.cancelledSize);
        if (
          message.cancelledTime != null &&
          Object.hasOwnProperty.call(message, 'cancelledTime')
        )
          writer
            .uint32(/* id 110319, wireType 2 =*/ 882554)
            .string(message.cancelledTime);
        if (
          message.cancelledDate != null &&
          Object.hasOwnProperty.call(message, 'cancelledDate')
        )
          writer
            .uint32(/* id 110320, wireType 2 =*/ 882562)
            .string(message.cancelledDate);
        if (
          message.cancelledId != null &&
          Object.hasOwnProperty.call(message, 'cancelledId')
        )
          writer
            .uint32(/* id 110321, wireType 2 =*/ 882570)
            .string(message.cancelledId);
        if (
          message.avgFillPrice != null &&
          Object.hasOwnProperty.call(message, 'avgFillPrice')
        )
          writer
            .uint32(/* id 110322, wireType 1 =*/ 882577)
            .double(message.avgFillPrice);
        if (
          message.confirmedTime != null &&
          Object.hasOwnProperty.call(message, 'confirmedTime')
        )
          writer
            .uint32(/* id 110326, wireType 2 =*/ 882610)
            .string(message.confirmedTime);
        if (
          message.confirmedDate != null &&
          Object.hasOwnProperty.call(message, 'confirmedDate')
        )
          writer
            .uint32(/* id 110327, wireType 2 =*/ 882618)
            .string(message.confirmedDate);
        if (
          message.confirmedSize != null &&
          Object.hasOwnProperty.call(message, 'confirmedSize')
        )
          writer
            .uint32(/* id 110329, wireType 0 =*/ 882632)
            .int32(message.confirmedSize);
        if (
          message.confirmedId != null &&
          Object.hasOwnProperty.call(message, 'confirmedId')
        )
          writer
            .uint32(/* id 110330, wireType 2 =*/ 882642)
            .string(message.confirmedId);
        if (
          message.linkedBasketIds != null &&
          Object.hasOwnProperty.call(message, 'linkedBasketIds')
        )
          writer
            .uint32(/* id 110358, wireType 2 =*/ 882866)
            .string(message.linkedBasketIds);
        if (
          message.sequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'sequenceNumber')
        )
          writer
            .uint32(/* id 112002, wireType 2 =*/ 896018)
            .string(message.sequenceNumber);
        if (
          message.transactionType != null &&
          Object.hasOwnProperty.call(message, 'transactionType')
        )
          writer
            .uint32(/* id 112003, wireType 0 =*/ 896024)
            .int32(message.transactionType);
        if (
          message.quantity != null &&
          Object.hasOwnProperty.call(message, 'quantity')
        )
          writer
            .uint32(/* id 112004, wireType 0 =*/ 896032)
            .int32(message.quantity);
        if (
          message.duration != null &&
          Object.hasOwnProperty.call(message, 'duration')
        )
          writer
            .uint32(/* id 112005, wireType 0 =*/ 896040)
            .int32(message.duration);
        if (
          message.priceType != null &&
          Object.hasOwnProperty.call(message, 'priceType')
        )
          writer
            .uint32(/* id 112008, wireType 0 =*/ 896064)
            .int32(message.priceType);
        if (
          message.tradeRoute != null &&
          Object.hasOwnProperty.call(message, 'tradeRoute')
        )
          writer
            .uint32(/* id 112016, wireType 2 =*/ 896130)
            .string(message.tradeRoute);
        if (
          message.tradeExchange != null &&
          Object.hasOwnProperty.call(message, 'tradeExchange')
        )
          writer
            .uint32(/* id 112021, wireType 2 =*/ 896170)
            .string(message.tradeExchange);
        if (
          message.reportType != null &&
          Object.hasOwnProperty.call(message, 'reportType')
        )
          writer
            .uint32(/* id 120001, wireType 2 =*/ 960010)
            .string(message.reportType);
        if (message.text != null && Object.hasOwnProperty.call(message, 'text'))
          writer
            .uint32(/* id 120008, wireType 2 =*/ 960066)
            .string(message.text);
        if (
          message.reportText != null &&
          Object.hasOwnProperty.call(message, 'reportText')
        )
          writer
            .uint32(/* id 120028, wireType 2 =*/ 960226)
            .string(message.reportText);
        if (
          message.userId != null &&
          Object.hasOwnProperty.call(message, 'userId')
        )
          writer
            .uint32(/* id 131003, wireType 2 =*/ 1048026)
            .string(message.userId);
        if (
          message.exchangeOrderId != null &&
          Object.hasOwnProperty.call(message, 'exchangeOrderId')
        )
          writer
            .uint32(/* id 149238, wireType 2 =*/ 1193906)
            .string(message.exchangeOrderId);
        if (
          message.modifyId != null &&
          Object.hasOwnProperty.call(message, 'modifyId')
        )
          writer
            .uint32(/* id 149244, wireType 2 =*/ 1193954)
            .string(message.modifyId);
        if (
          message.triggerPrice != null &&
          Object.hasOwnProperty.call(message, 'triggerPrice')
        )
          writer
            .uint32(/* id 149247, wireType 1 =*/ 1193977)
            .double(message.triggerPrice);
        if (
          message.origSequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'origSequenceNumber')
        )
          writer
            .uint32(/* id 149263, wireType 2 =*/ 1194106)
            .string(message.origSequenceNumber);
        if (
          message.corSequenceNumber != null &&
          Object.hasOwnProperty.call(message, 'corSequenceNumber')
        )
          writer
            .uint32(/* id 149264, wireType 2 =*/ 1194114)
            .string(message.corSequenceNumber);
        if (
          message.triggerId != null &&
          Object.hasOwnProperty.call(message, 'triggerId')
        )
          writer
            .uint32(/* id 149266, wireType 2 =*/ 1194130)
            .string(message.triggerId);
        if (
          message.modifiedSize != null &&
          Object.hasOwnProperty.call(message, 'modifiedSize')
        )
          writer
            .uint32(/* id 149267, wireType 0 =*/ 1194136)
            .int32(message.modifiedSize);
        if (
          message.modifiedTime != null &&
          Object.hasOwnProperty.call(message, 'modifiedTime')
        )
          writer
            .uint32(/* id 149268, wireType 2 =*/ 1194146)
            .string(message.modifiedTime);
        if (
          message.modifiedDate != null &&
          Object.hasOwnProperty.call(message, 'modifiedDate')
        )
          writer
            .uint32(/* id 149269, wireType 2 =*/ 1194154)
            .string(message.modifiedDate);
        if (
          message.isRithmicInternalMsg != null &&
          Object.hasOwnProperty.call(message, 'isRithmicInternalMsg')
        )
          writer
            .uint32(/* id 149373, wireType 0 =*/ 1194984)
            .bool(message.isRithmicInternalMsg);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.exchReceiptSsboe != null &&
          Object.hasOwnProperty.call(message, 'exchReceiptSsboe')
        )
          writer
            .uint32(/* id 150405, wireType 0 =*/ 1203240)
            .int32(message.exchReceiptSsboe);
        if (
          message.exchReceiptNsecs != null &&
          Object.hasOwnProperty.call(message, 'exchReceiptNsecs')
        )
          writer
            .uint32(/* id 150406, wireType 0 =*/ 1203248)
            .int32(message.exchReceiptNsecs);
        if (
          message.notifyType != null &&
          Object.hasOwnProperty.call(message, 'notifyType')
        )
          writer
            .uint32(/* id 153625, wireType 0 =*/ 1229000)
            .int32(message.notifyType);
        if (
          message.tpExchangeOrderId != null &&
          Object.hasOwnProperty.call(message, 'tpExchangeOrderId')
        )
          writer
            .uint32(/* id 153647, wireType 2 =*/ 1229178)
            .string(message.tpExchangeOrderId);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.totalFillSize != null &&
          Object.hasOwnProperty.call(message, 'totalFillSize')
        )
          writer
            .uint32(/* id 154111, wireType 0 =*/ 1232888)
            .int32(message.totalFillSize);
        if (
          message.totalUnfilledSize != null &&
          Object.hasOwnProperty.call(message, 'totalUnfilledSize')
        )
          writer
            .uint32(/* id 154112, wireType 0 =*/ 1232896)
            .int32(message.totalUnfilledSize);
        if (
          message.userTag != null &&
          Object.hasOwnProperty.call(message, 'userTag')
        )
          writer
            .uint32(/* id 154119, wireType 2 =*/ 1232954)
            .string(message.userTag);
        if (
          message.countryCode != null &&
          Object.hasOwnProperty.call(message, 'countryCode')
        )
          writer
            .uint32(/* id 154172, wireType 2 =*/ 1233378)
            .string(message.countryCode);
        if (
          message.currency != null &&
          Object.hasOwnProperty.call(message, 'currency')
        )
          writer
            .uint32(/* id 154382, wireType 2 =*/ 1235058)
            .string(message.currency);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.cancelAfterSecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAfterSecs')
        )
          writer
            .uint32(/* id 154488, wireType 0 =*/ 1235904)
            .int32(message.cancelAfterSecs);
        if (
          message.originalBasketId != null &&
          Object.hasOwnProperty.call(message, 'originalBasketId')
        )
          writer
            .uint32(/* id 154497, wireType 2 =*/ 1235978)
            .string(message.originalBasketId);
        if (
          message.windowName != null &&
          Object.hasOwnProperty.call(message, 'windowName')
        )
          writer
            .uint32(/* id 154629, wireType 2 =*/ 1237034)
            .string(message.windowName);
        if (
          message.originatorWindowName != null &&
          Object.hasOwnProperty.call(message, 'originatorWindowName')
        )
          writer
            .uint32(/* id 154671, wireType 2 =*/ 1237370)
            .string(message.originatorWindowName);
        if (
          message.manualOrAuto != null &&
          Object.hasOwnProperty.call(message, 'manualOrAuto')
        )
          writer
            .uint32(/* id 154710, wireType 0 =*/ 1237680)
            .int32(message.manualOrAuto);
        if (
          message.origPriceType != null &&
          Object.hasOwnProperty.call(message, 'origPriceType')
        )
          writer
            .uint32(/* id 154770, wireType 0 =*/ 1238160)
            .int32(message.origPriceType);
        if (
          message.remarks != null &&
          Object.hasOwnProperty.call(message, 'remarks')
        )
          writer
            .uint32(/* id 154806, wireType 2 =*/ 1238450)
            .string(message.remarks);
        if (
          message.cancelAtSsboe != null &&
          Object.hasOwnProperty.call(message, 'cancelAtSsboe')
        )
          writer
            .uint32(/* id 157085, wireType 0 =*/ 1256680)
            .int32(message.cancelAtSsboe);
        if (
          message.cancelAtUsecs != null &&
          Object.hasOwnProperty.call(message, 'cancelAtUsecs')
        )
          writer
            .uint32(/* id 157086, wireType 0 =*/ 1256688)
            .int32(message.cancelAtUsecs);
        if (
          message.bracketType != null &&
          Object.hasOwnProperty.call(message, 'bracketType')
        )
          writer
            .uint32(/* id 157087, wireType 0 =*/ 1256696)
            .int32(message.bracketType);
        return writer;
      };

      /**
       * Encodes the specified ExchangeOrderNotification message, length delimited. Does not implicitly {@link rti.ExchangeOrderNotification.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {rti.IExchangeOrderNotification} message ExchangeOrderNotification message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ExchangeOrderNotification.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an ExchangeOrderNotification message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ExchangeOrderNotification} ExchangeOrderNotification
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ExchangeOrderNotification.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ExchangeOrderNotification();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 154119:
              message.userTag = reader.string();
              break;
            case 153625:
              message.notifyType = reader.int32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 149373:
              message.isRithmicInternalMsg = reader.bool();
              break;
            case 120001:
              message.reportType = reader.string();
              break;
            case 110303:
              message.status = reader.string();
              break;
            case 110300:
              message.basketId = reader.string();
              break;
            case 154497:
              message.originalBasketId = reader.string();
              break;
            case 110358:
              message.linkedBasketIds = reader.string();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 131003:
              message.userId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 112021:
              message.tradeExchange = reader.string();
              break;
            case 112016:
              message.tradeRoute = reader.string();
              break;
            case 149238:
              message.exchangeOrderId = reader.string();
              break;
            case 153647:
              message.tpExchangeOrderId = reader.string();
              break;
            case 110116:
              message.instrumentType = reader.string();
              break;
            case 112004:
              message.quantity = reader.int32();
              break;
            case 110306:
              message.price = reader.double();
              break;
            case 149247:
              message.triggerPrice = reader.double();
              break;
            case 112003:
              message.transactionType = reader.int32();
              break;
            case 112005:
              message.duration = reader.int32();
              break;
            case 112008:
              message.priceType = reader.int32();
              break;
            case 154770:
              message.origPriceType = reader.int32();
              break;
            case 154710:
              message.manualOrAuto = reader.int32();
              break;
            case 157087:
              message.bracketType = reader.int32();
              break;
            case 110329:
              message.confirmedSize = reader.int32();
              break;
            case 110326:
              message.confirmedTime = reader.string();
              break;
            case 110327:
              message.confirmedDate = reader.string();
              break;
            case 110330:
              message.confirmedId = reader.string();
              break;
            case 149267:
              message.modifiedSize = reader.int32();
              break;
            case 149268:
              message.modifiedTime = reader.string();
              break;
            case 149269:
              message.modifiedDate = reader.string();
              break;
            case 149244:
              message.modifyId = reader.string();
              break;
            case 110318:
              message.cancelledSize = reader.int32();
              break;
            case 110319:
              message.cancelledTime = reader.string();
              break;
            case 110320:
              message.cancelledDate = reader.string();
              break;
            case 110321:
              message.cancelledId = reader.string();
              break;
            case 110307:
              message.fillPrice = reader.double();
              break;
            case 110308:
              message.fillSize = reader.int32();
              break;
            case 110309:
              message.fillTime = reader.string();
              break;
            case 110310:
              message.fillDate = reader.string();
              break;
            case 110311:
              message.fillId = reader.string();
              break;
            case 110322:
              message.avgFillPrice = reader.double();
              break;
            case 154111:
              message.totalFillSize = reader.int32();
              break;
            case 154112:
              message.totalUnfilledSize = reader.int32();
              break;
            case 149266:
              message.triggerId = reader.string();
              break;
            case 112002:
              message.sequenceNumber = reader.string();
              break;
            case 149263:
              message.origSequenceNumber = reader.string();
              break;
            case 149264:
              message.corSequenceNumber = reader.string();
              break;
            case 154382:
              message.currency = reader.string();
              break;
            case 154172:
              message.countryCode = reader.string();
              break;
            case 120008:
              message.text = reader.string();
              break;
            case 120028:
              message.reportText = reader.string();
              break;
            case 154806:
              message.remarks = reader.string();
              break;
            case 154629:
              message.windowName = reader.string();
              break;
            case 154671:
              message.originatorWindowName = reader.string();
              break;
            case 157085:
              message.cancelAtSsboe = reader.int32();
              break;
            case 157086:
              message.cancelAtUsecs = reader.int32();
              break;
            case 154488:
              message.cancelAfterSecs = reader.int32();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            case 150405:
              message.exchReceiptSsboe = reader.int32();
              break;
            case 150406:
              message.exchReceiptNsecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an ExchangeOrderNotification message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ExchangeOrderNotification} ExchangeOrderNotification
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ExchangeOrderNotification.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an ExchangeOrderNotification message.
       * @function verify
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ExchangeOrderNotification.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          if (!$util.isString(message.userTag))
            return 'userTag: string expected';
        if (message.notifyType != null && message.hasOwnProperty('notifyType'))
          switch (message.notifyType) {
            default:
              return 'notifyType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
              break;
          }
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (
          message.isRithmicInternalMsg != null &&
          message.hasOwnProperty('isRithmicInternalMsg')
        )
          if (typeof message.isRithmicInternalMsg !== 'boolean')
            return 'isRithmicInternalMsg: boolean expected';
        if (message.reportType != null && message.hasOwnProperty('reportType'))
          if (!$util.isString(message.reportType))
            return 'reportType: string expected';
        if (message.status != null && message.hasOwnProperty('status'))
          if (!$util.isString(message.status)) return 'status: string expected';
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          if (!$util.isString(message.basketId))
            return 'basketId: string expected';
        if (
          message.originalBasketId != null &&
          message.hasOwnProperty('originalBasketId')
        )
          if (!$util.isString(message.originalBasketId))
            return 'originalBasketId: string expected';
        if (
          message.linkedBasketIds != null &&
          message.hasOwnProperty('linkedBasketIds')
        )
          if (!$util.isString(message.linkedBasketIds))
            return 'linkedBasketIds: string expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.userId != null && message.hasOwnProperty('userId'))
          if (!$util.isString(message.userId)) return 'userId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.tradeExchange != null &&
          message.hasOwnProperty('tradeExchange')
        )
          if (!$util.isString(message.tradeExchange))
            return 'tradeExchange: string expected';
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          if (!$util.isString(message.tradeRoute))
            return 'tradeRoute: string expected';
        if (
          message.exchangeOrderId != null &&
          message.hasOwnProperty('exchangeOrderId')
        )
          if (!$util.isString(message.exchangeOrderId))
            return 'exchangeOrderId: string expected';
        if (
          message.tpExchangeOrderId != null &&
          message.hasOwnProperty('tpExchangeOrderId')
        )
          if (!$util.isString(message.tpExchangeOrderId))
            return 'tpExchangeOrderId: string expected';
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          if (!$util.isString(message.instrumentType))
            return 'instrumentType: string expected';
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          if (!$util.isInteger(message.quantity))
            return 'quantity: integer expected';
        if (message.price != null && message.hasOwnProperty('price'))
          if (typeof message.price !== 'number')
            return 'price: number expected';
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          if (typeof message.triggerPrice !== 'number')
            return 'triggerPrice: number expected';
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        )
          switch (message.transactionType) {
            default:
              return 'transactionType: enum value expected';
            case 1:
            case 2:
            case 3:
              break;
          }
        if (message.duration != null && message.hasOwnProperty('duration'))
          switch (message.duration) {
            default:
              return 'duration: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          switch (message.priceType) {
            default:
              return 'priceType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.origPriceType != null &&
          message.hasOwnProperty('origPriceType')
        )
          switch (message.origPriceType) {
            default:
              return 'origPriceType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          switch (message.manualOrAuto) {
            default:
              return 'manualOrAuto: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.bracketType != null &&
          message.hasOwnProperty('bracketType')
        )
          switch (message.bracketType) {
            default:
              return 'bracketType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              break;
          }
        if (
          message.confirmedSize != null &&
          message.hasOwnProperty('confirmedSize')
        )
          if (!$util.isInteger(message.confirmedSize))
            return 'confirmedSize: integer expected';
        if (
          message.confirmedTime != null &&
          message.hasOwnProperty('confirmedTime')
        )
          if (!$util.isString(message.confirmedTime))
            return 'confirmedTime: string expected';
        if (
          message.confirmedDate != null &&
          message.hasOwnProperty('confirmedDate')
        )
          if (!$util.isString(message.confirmedDate))
            return 'confirmedDate: string expected';
        if (
          message.confirmedId != null &&
          message.hasOwnProperty('confirmedId')
        )
          if (!$util.isString(message.confirmedId))
            return 'confirmedId: string expected';
        if (
          message.modifiedSize != null &&
          message.hasOwnProperty('modifiedSize')
        )
          if (!$util.isInteger(message.modifiedSize))
            return 'modifiedSize: integer expected';
        if (
          message.modifiedTime != null &&
          message.hasOwnProperty('modifiedTime')
        )
          if (!$util.isString(message.modifiedTime))
            return 'modifiedTime: string expected';
        if (
          message.modifiedDate != null &&
          message.hasOwnProperty('modifiedDate')
        )
          if (!$util.isString(message.modifiedDate))
            return 'modifiedDate: string expected';
        if (message.modifyId != null && message.hasOwnProperty('modifyId'))
          if (!$util.isString(message.modifyId))
            return 'modifyId: string expected';
        if (
          message.cancelledSize != null &&
          message.hasOwnProperty('cancelledSize')
        )
          if (!$util.isInteger(message.cancelledSize))
            return 'cancelledSize: integer expected';
        if (
          message.cancelledTime != null &&
          message.hasOwnProperty('cancelledTime')
        )
          if (!$util.isString(message.cancelledTime))
            return 'cancelledTime: string expected';
        if (
          message.cancelledDate != null &&
          message.hasOwnProperty('cancelledDate')
        )
          if (!$util.isString(message.cancelledDate))
            return 'cancelledDate: string expected';
        if (
          message.cancelledId != null &&
          message.hasOwnProperty('cancelledId')
        )
          if (!$util.isString(message.cancelledId))
            return 'cancelledId: string expected';
        if (message.fillPrice != null && message.hasOwnProperty('fillPrice'))
          if (typeof message.fillPrice !== 'number')
            return 'fillPrice: number expected';
        if (message.fillSize != null && message.hasOwnProperty('fillSize'))
          if (!$util.isInteger(message.fillSize))
            return 'fillSize: integer expected';
        if (message.fillTime != null && message.hasOwnProperty('fillTime'))
          if (!$util.isString(message.fillTime))
            return 'fillTime: string expected';
        if (message.fillDate != null && message.hasOwnProperty('fillDate'))
          if (!$util.isString(message.fillDate))
            return 'fillDate: string expected';
        if (message.fillId != null && message.hasOwnProperty('fillId'))
          if (!$util.isString(message.fillId)) return 'fillId: string expected';
        if (
          message.avgFillPrice != null &&
          message.hasOwnProperty('avgFillPrice')
        )
          if (typeof message.avgFillPrice !== 'number')
            return 'avgFillPrice: number expected';
        if (
          message.totalFillSize != null &&
          message.hasOwnProperty('totalFillSize')
        )
          if (!$util.isInteger(message.totalFillSize))
            return 'totalFillSize: integer expected';
        if (
          message.totalUnfilledSize != null &&
          message.hasOwnProperty('totalUnfilledSize')
        )
          if (!$util.isInteger(message.totalUnfilledSize))
            return 'totalUnfilledSize: integer expected';
        if (message.triggerId != null && message.hasOwnProperty('triggerId'))
          if (!$util.isString(message.triggerId))
            return 'triggerId: string expected';
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          if (!$util.isString(message.sequenceNumber))
            return 'sequenceNumber: string expected';
        if (
          message.origSequenceNumber != null &&
          message.hasOwnProperty('origSequenceNumber')
        )
          if (!$util.isString(message.origSequenceNumber))
            return 'origSequenceNumber: string expected';
        if (
          message.corSequenceNumber != null &&
          message.hasOwnProperty('corSequenceNumber')
        )
          if (!$util.isString(message.corSequenceNumber))
            return 'corSequenceNumber: string expected';
        if (message.currency != null && message.hasOwnProperty('currency'))
          if (!$util.isString(message.currency))
            return 'currency: string expected';
        if (
          message.countryCode != null &&
          message.hasOwnProperty('countryCode')
        )
          if (!$util.isString(message.countryCode))
            return 'countryCode: string expected';
        if (message.text != null && message.hasOwnProperty('text'))
          if (!$util.isString(message.text)) return 'text: string expected';
        if (message.reportText != null && message.hasOwnProperty('reportText'))
          if (!$util.isString(message.reportText))
            return 'reportText: string expected';
        if (message.remarks != null && message.hasOwnProperty('remarks'))
          if (!$util.isString(message.remarks))
            return 'remarks: string expected';
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          if (!$util.isString(message.windowName))
            return 'windowName: string expected';
        if (
          message.originatorWindowName != null &&
          message.hasOwnProperty('originatorWindowName')
        )
          if (!$util.isString(message.originatorWindowName))
            return 'originatorWindowName: string expected';
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          if (!$util.isInteger(message.cancelAtSsboe))
            return 'cancelAtSsboe: integer expected';
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          if (!$util.isInteger(message.cancelAtUsecs))
            return 'cancelAtUsecs: integer expected';
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          if (!$util.isInteger(message.cancelAfterSecs))
            return 'cancelAfterSecs: integer expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        if (
          message.exchReceiptSsboe != null &&
          message.hasOwnProperty('exchReceiptSsboe')
        )
          if (!$util.isInteger(message.exchReceiptSsboe))
            return 'exchReceiptSsboe: integer expected';
        if (
          message.exchReceiptNsecs != null &&
          message.hasOwnProperty('exchReceiptNsecs')
        )
          if (!$util.isInteger(message.exchReceiptNsecs))
            return 'exchReceiptNsecs: integer expected';
        return null;
      };

      /**
       * Creates an ExchangeOrderNotification message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ExchangeOrderNotification} ExchangeOrderNotification
       */
      ExchangeOrderNotification.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ExchangeOrderNotification)
          return object;
        var message = new $root.rti.ExchangeOrderNotification();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userTag != null) message.userTag = String(object.userTag);
        switch (object.notifyType) {
          case 'STATUS':
          case 1:
            message.notifyType = 1;
            break;
          case 'MODIFY':
          case 2:
            message.notifyType = 2;
            break;
          case 'CANCEL':
          case 3:
            message.notifyType = 3;
            break;
          case 'TRIGGER':
          case 4:
            message.notifyType = 4;
            break;
          case 'FILL':
          case 5:
            message.notifyType = 5;
            break;
          case 'REJECT':
          case 6:
            message.notifyType = 6;
            break;
          case 'NOT_MODIFIED':
          case 7:
            message.notifyType = 7;
            break;
          case 'NOT_CANCELLED':
          case 8:
            message.notifyType = 8;
            break;
          case 'GENERIC':
          case 9:
            message.notifyType = 9;
            break;
        }
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.isRithmicInternalMsg != null)
          message.isRithmicInternalMsg = Boolean(object.isRithmicInternalMsg);
        if (object.reportType != null)
          message.reportType = String(object.reportType);
        if (object.status != null) message.status = String(object.status);
        if (object.basketId != null) message.basketId = String(object.basketId);
        if (object.originalBasketId != null)
          message.originalBasketId = String(object.originalBasketId);
        if (object.linkedBasketIds != null)
          message.linkedBasketIds = String(object.linkedBasketIds);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.userId != null) message.userId = String(object.userId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.tradeExchange != null)
          message.tradeExchange = String(object.tradeExchange);
        if (object.tradeRoute != null)
          message.tradeRoute = String(object.tradeRoute);
        if (object.exchangeOrderId != null)
          message.exchangeOrderId = String(object.exchangeOrderId);
        if (object.tpExchangeOrderId != null)
          message.tpExchangeOrderId = String(object.tpExchangeOrderId);
        if (object.instrumentType != null)
          message.instrumentType = String(object.instrumentType);
        if (object.quantity != null) message.quantity = object.quantity | 0;
        if (object.price != null) message.price = Number(object.price);
        if (object.triggerPrice != null)
          message.triggerPrice = Number(object.triggerPrice);
        switch (object.transactionType) {
          case 'BUY':
          case 1:
            message.transactionType = 1;
            break;
          case 'SELL':
          case 2:
            message.transactionType = 2;
            break;
          case 'SS':
          case 3:
            message.transactionType = 3;
            break;
        }
        switch (object.duration) {
          case 'DAY':
          case 1:
            message.duration = 1;
            break;
          case 'GTC':
          case 2:
            message.duration = 2;
            break;
          case 'IOC':
          case 3:
            message.duration = 3;
            break;
          case 'FOK':
          case 4:
            message.duration = 4;
            break;
        }
        switch (object.priceType) {
          case 'LIMIT':
          case 1:
            message.priceType = 1;
            break;
          case 'MARKET':
          case 2:
            message.priceType = 2;
            break;
          case 'STOP_LIMIT':
          case 3:
            message.priceType = 3;
            break;
          case 'STOP_MARKET':
          case 4:
            message.priceType = 4;
            break;
        }
        switch (object.origPriceType) {
          case 'LIMIT':
          case 1:
            message.origPriceType = 1;
            break;
          case 'MARKET':
          case 2:
            message.origPriceType = 2;
            break;
          case 'STOP_LIMIT':
          case 3:
            message.origPriceType = 3;
            break;
          case 'STOP_MARKET':
          case 4:
            message.origPriceType = 4;
            break;
        }
        switch (object.manualOrAuto) {
          case 'MANUAL':
          case 1:
            message.manualOrAuto = 1;
            break;
          case 'AUTO':
          case 2:
            message.manualOrAuto = 2;
            break;
        }
        switch (object.bracketType) {
          case 'STOP_ONLY':
          case 1:
            message.bracketType = 1;
            break;
          case 'TARGET_ONLY':
          case 2:
            message.bracketType = 2;
            break;
          case 'TARGET_AND_STOP':
          case 3:
            message.bracketType = 3;
            break;
          case 'STOP_ONLY_STATIC':
          case 4:
            message.bracketType = 4;
            break;
          case 'TARGET_ONLY_STATIC':
          case 5:
            message.bracketType = 5;
            break;
          case 'TARGET_AND_STOP_STATIC':
          case 6:
            message.bracketType = 6;
            break;
        }
        if (object.confirmedSize != null)
          message.confirmedSize = object.confirmedSize | 0;
        if (object.confirmedTime != null)
          message.confirmedTime = String(object.confirmedTime);
        if (object.confirmedDate != null)
          message.confirmedDate = String(object.confirmedDate);
        if (object.confirmedId != null)
          message.confirmedId = String(object.confirmedId);
        if (object.modifiedSize != null)
          message.modifiedSize = object.modifiedSize | 0;
        if (object.modifiedTime != null)
          message.modifiedTime = String(object.modifiedTime);
        if (object.modifiedDate != null)
          message.modifiedDate = String(object.modifiedDate);
        if (object.modifyId != null) message.modifyId = String(object.modifyId);
        if (object.cancelledSize != null)
          message.cancelledSize = object.cancelledSize | 0;
        if (object.cancelledTime != null)
          message.cancelledTime = String(object.cancelledTime);
        if (object.cancelledDate != null)
          message.cancelledDate = String(object.cancelledDate);
        if (object.cancelledId != null)
          message.cancelledId = String(object.cancelledId);
        if (object.fillPrice != null)
          message.fillPrice = Number(object.fillPrice);
        if (object.fillSize != null) message.fillSize = object.fillSize | 0;
        if (object.fillTime != null) message.fillTime = String(object.fillTime);
        if (object.fillDate != null) message.fillDate = String(object.fillDate);
        if (object.fillId != null) message.fillId = String(object.fillId);
        if (object.avgFillPrice != null)
          message.avgFillPrice = Number(object.avgFillPrice);
        if (object.totalFillSize != null)
          message.totalFillSize = object.totalFillSize | 0;
        if (object.totalUnfilledSize != null)
          message.totalUnfilledSize = object.totalUnfilledSize | 0;
        if (object.triggerId != null)
          message.triggerId = String(object.triggerId);
        if (object.sequenceNumber != null)
          message.sequenceNumber = String(object.sequenceNumber);
        if (object.origSequenceNumber != null)
          message.origSequenceNumber = String(object.origSequenceNumber);
        if (object.corSequenceNumber != null)
          message.corSequenceNumber = String(object.corSequenceNumber);
        if (object.currency != null) message.currency = String(object.currency);
        if (object.countryCode != null)
          message.countryCode = String(object.countryCode);
        if (object.text != null) message.text = String(object.text);
        if (object.reportText != null)
          message.reportText = String(object.reportText);
        if (object.remarks != null) message.remarks = String(object.remarks);
        if (object.windowName != null)
          message.windowName = String(object.windowName);
        if (object.originatorWindowName != null)
          message.originatorWindowName = String(object.originatorWindowName);
        if (object.cancelAtSsboe != null)
          message.cancelAtSsboe = object.cancelAtSsboe | 0;
        if (object.cancelAtUsecs != null)
          message.cancelAtUsecs = object.cancelAtUsecs | 0;
        if (object.cancelAfterSecs != null)
          message.cancelAfterSecs = object.cancelAfterSecs | 0;
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        if (object.exchReceiptSsboe != null)
          message.exchReceiptSsboe = object.exchReceiptSsboe | 0;
        if (object.exchReceiptNsecs != null)
          message.exchReceiptNsecs = object.exchReceiptNsecs | 0;
        return message;
      };

      /**
       * Creates a plain object from an ExchangeOrderNotification message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {rti.ExchangeOrderNotification} message ExchangeOrderNotification
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ExchangeOrderNotification.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.instrumentType = '';
          object.isSnapshot = false;
          object.basketId = '';
          object.status = '';
          object.price = 0;
          object.fillPrice = 0;
          object.fillSize = 0;
          object.fillTime = '';
          object.fillDate = '';
          object.fillId = '';
          object.cancelledSize = 0;
          object.cancelledTime = '';
          object.cancelledDate = '';
          object.cancelledId = '';
          object.avgFillPrice = 0;
          object.confirmedTime = '';
          object.confirmedDate = '';
          object.confirmedSize = 0;
          object.confirmedId = '';
          object.linkedBasketIds = '';
          object.sequenceNumber = '';
          object.transactionType = options.enums === String ? 'BUY' : 1;
          object.quantity = 0;
          object.duration = options.enums === String ? 'DAY' : 1;
          object.priceType = options.enums === String ? 'LIMIT' : 1;
          object.tradeRoute = '';
          object.tradeExchange = '';
          object.reportType = '';
          object.text = '';
          object.reportText = '';
          object.userId = '';
          object.exchangeOrderId = '';
          object.modifyId = '';
          object.triggerPrice = 0;
          object.origSequenceNumber = '';
          object.corSequenceNumber = '';
          object.triggerId = '';
          object.modifiedSize = 0;
          object.modifiedTime = '';
          object.modifiedDate = '';
          object.isRithmicInternalMsg = false;
          object.ssboe = 0;
          object.usecs = 0;
          object.exchReceiptSsboe = 0;
          object.exchReceiptNsecs = 0;
          object.notifyType = options.enums === String ? 'STATUS' : 1;
          object.tpExchangeOrderId = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.totalFillSize = 0;
          object.totalUnfilledSize = 0;
          object.userTag = '';
          object.countryCode = '';
          object.currency = '';
          object.templateId = 0;
          object.cancelAfterSecs = 0;
          object.originalBasketId = '';
          object.windowName = '';
          object.originatorWindowName = '';
          object.manualOrAuto = options.enums === String ? 'MANUAL' : 1;
          object.origPriceType = options.enums === String ? 'LIMIT' : 1;
          object.remarks = '';
          object.cancelAtSsboe = 0;
          object.cancelAtUsecs = 0;
          object.bracketType = options.enums === String ? 'STOP_ONLY' : 1;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          object.instrumentType = message.instrumentType;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (message.basketId != null && message.hasOwnProperty('basketId'))
          object.basketId = message.basketId;
        if (message.status != null && message.hasOwnProperty('status'))
          object.status = message.status;
        if (message.price != null && message.hasOwnProperty('price'))
          object.price =
            options.json && !isFinite(message.price)
              ? String(message.price)
              : message.price;
        if (message.fillPrice != null && message.hasOwnProperty('fillPrice'))
          object.fillPrice =
            options.json && !isFinite(message.fillPrice)
              ? String(message.fillPrice)
              : message.fillPrice;
        if (message.fillSize != null && message.hasOwnProperty('fillSize'))
          object.fillSize = message.fillSize;
        if (message.fillTime != null && message.hasOwnProperty('fillTime'))
          object.fillTime = message.fillTime;
        if (message.fillDate != null && message.hasOwnProperty('fillDate'))
          object.fillDate = message.fillDate;
        if (message.fillId != null && message.hasOwnProperty('fillId'))
          object.fillId = message.fillId;
        if (
          message.cancelledSize != null &&
          message.hasOwnProperty('cancelledSize')
        )
          object.cancelledSize = message.cancelledSize;
        if (
          message.cancelledTime != null &&
          message.hasOwnProperty('cancelledTime')
        )
          object.cancelledTime = message.cancelledTime;
        if (
          message.cancelledDate != null &&
          message.hasOwnProperty('cancelledDate')
        )
          object.cancelledDate = message.cancelledDate;
        if (
          message.cancelledId != null &&
          message.hasOwnProperty('cancelledId')
        )
          object.cancelledId = message.cancelledId;
        if (
          message.avgFillPrice != null &&
          message.hasOwnProperty('avgFillPrice')
        )
          object.avgFillPrice =
            options.json && !isFinite(message.avgFillPrice)
              ? String(message.avgFillPrice)
              : message.avgFillPrice;
        if (
          message.confirmedTime != null &&
          message.hasOwnProperty('confirmedTime')
        )
          object.confirmedTime = message.confirmedTime;
        if (
          message.confirmedDate != null &&
          message.hasOwnProperty('confirmedDate')
        )
          object.confirmedDate = message.confirmedDate;
        if (
          message.confirmedSize != null &&
          message.hasOwnProperty('confirmedSize')
        )
          object.confirmedSize = message.confirmedSize;
        if (
          message.confirmedId != null &&
          message.hasOwnProperty('confirmedId')
        )
          object.confirmedId = message.confirmedId;
        if (
          message.linkedBasketIds != null &&
          message.hasOwnProperty('linkedBasketIds')
        )
          object.linkedBasketIds = message.linkedBasketIds;
        if (
          message.sequenceNumber != null &&
          message.hasOwnProperty('sequenceNumber')
        )
          object.sequenceNumber = message.sequenceNumber;
        if (
          message.transactionType != null &&
          message.hasOwnProperty('transactionType')
        )
          object.transactionType =
            options.enums === String
              ? $root.rti.ExchangeOrderNotification.TransactionType[
                  message.transactionType
                ]
              : message.transactionType;
        if (message.quantity != null && message.hasOwnProperty('quantity'))
          object.quantity = message.quantity;
        if (message.duration != null && message.hasOwnProperty('duration'))
          object.duration =
            options.enums === String
              ? $root.rti.ExchangeOrderNotification.Duration[message.duration]
              : message.duration;
        if (message.priceType != null && message.hasOwnProperty('priceType'))
          object.priceType =
            options.enums === String
              ? $root.rti.ExchangeOrderNotification.PriceType[message.priceType]
              : message.priceType;
        if (message.tradeRoute != null && message.hasOwnProperty('tradeRoute'))
          object.tradeRoute = message.tradeRoute;
        if (
          message.tradeExchange != null &&
          message.hasOwnProperty('tradeExchange')
        )
          object.tradeExchange = message.tradeExchange;
        if (message.reportType != null && message.hasOwnProperty('reportType'))
          object.reportType = message.reportType;
        if (message.text != null && message.hasOwnProperty('text'))
          object.text = message.text;
        if (message.reportText != null && message.hasOwnProperty('reportText'))
          object.reportText = message.reportText;
        if (message.userId != null && message.hasOwnProperty('userId'))
          object.userId = message.userId;
        if (
          message.exchangeOrderId != null &&
          message.hasOwnProperty('exchangeOrderId')
        )
          object.exchangeOrderId = message.exchangeOrderId;
        if (message.modifyId != null && message.hasOwnProperty('modifyId'))
          object.modifyId = message.modifyId;
        if (
          message.triggerPrice != null &&
          message.hasOwnProperty('triggerPrice')
        )
          object.triggerPrice =
            options.json && !isFinite(message.triggerPrice)
              ? String(message.triggerPrice)
              : message.triggerPrice;
        if (
          message.origSequenceNumber != null &&
          message.hasOwnProperty('origSequenceNumber')
        )
          object.origSequenceNumber = message.origSequenceNumber;
        if (
          message.corSequenceNumber != null &&
          message.hasOwnProperty('corSequenceNumber')
        )
          object.corSequenceNumber = message.corSequenceNumber;
        if (message.triggerId != null && message.hasOwnProperty('triggerId'))
          object.triggerId = message.triggerId;
        if (
          message.modifiedSize != null &&
          message.hasOwnProperty('modifiedSize')
        )
          object.modifiedSize = message.modifiedSize;
        if (
          message.modifiedTime != null &&
          message.hasOwnProperty('modifiedTime')
        )
          object.modifiedTime = message.modifiedTime;
        if (
          message.modifiedDate != null &&
          message.hasOwnProperty('modifiedDate')
        )
          object.modifiedDate = message.modifiedDate;
        if (
          message.isRithmicInternalMsg != null &&
          message.hasOwnProperty('isRithmicInternalMsg')
        )
          object.isRithmicInternalMsg = message.isRithmicInternalMsg;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (
          message.exchReceiptSsboe != null &&
          message.hasOwnProperty('exchReceiptSsboe')
        )
          object.exchReceiptSsboe = message.exchReceiptSsboe;
        if (
          message.exchReceiptNsecs != null &&
          message.hasOwnProperty('exchReceiptNsecs')
        )
          object.exchReceiptNsecs = message.exchReceiptNsecs;
        if (message.notifyType != null && message.hasOwnProperty('notifyType'))
          object.notifyType =
            options.enums === String
              ? $root.rti.ExchangeOrderNotification.NotifyType[
                  message.notifyType
                ]
              : message.notifyType;
        if (
          message.tpExchangeOrderId != null &&
          message.hasOwnProperty('tpExchangeOrderId')
        )
          object.tpExchangeOrderId = message.tpExchangeOrderId;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (
          message.totalFillSize != null &&
          message.hasOwnProperty('totalFillSize')
        )
          object.totalFillSize = message.totalFillSize;
        if (
          message.totalUnfilledSize != null &&
          message.hasOwnProperty('totalUnfilledSize')
        )
          object.totalUnfilledSize = message.totalUnfilledSize;
        if (message.userTag != null && message.hasOwnProperty('userTag'))
          object.userTag = message.userTag;
        if (
          message.countryCode != null &&
          message.hasOwnProperty('countryCode')
        )
          object.countryCode = message.countryCode;
        if (message.currency != null && message.hasOwnProperty('currency'))
          object.currency = message.currency;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.cancelAfterSecs != null &&
          message.hasOwnProperty('cancelAfterSecs')
        )
          object.cancelAfterSecs = message.cancelAfterSecs;
        if (
          message.originalBasketId != null &&
          message.hasOwnProperty('originalBasketId')
        )
          object.originalBasketId = message.originalBasketId;
        if (message.windowName != null && message.hasOwnProperty('windowName'))
          object.windowName = message.windowName;
        if (
          message.originatorWindowName != null &&
          message.hasOwnProperty('originatorWindowName')
        )
          object.originatorWindowName = message.originatorWindowName;
        if (
          message.manualOrAuto != null &&
          message.hasOwnProperty('manualOrAuto')
        )
          object.manualOrAuto =
            options.enums === String
              ? $root.rti.ExchangeOrderNotification.OrderPlacement[
                  message.manualOrAuto
                ]
              : message.manualOrAuto;
        if (
          message.origPriceType != null &&
          message.hasOwnProperty('origPriceType')
        )
          object.origPriceType =
            options.enums === String
              ? $root.rti.ExchangeOrderNotification.PriceType[
                  message.origPriceType
                ]
              : message.origPriceType;
        if (message.remarks != null && message.hasOwnProperty('remarks'))
          object.remarks = message.remarks;
        if (
          message.cancelAtSsboe != null &&
          message.hasOwnProperty('cancelAtSsboe')
        )
          object.cancelAtSsboe = message.cancelAtSsboe;
        if (
          message.cancelAtUsecs != null &&
          message.hasOwnProperty('cancelAtUsecs')
        )
          object.cancelAtUsecs = message.cancelAtUsecs;
        if (
          message.bracketType != null &&
          message.hasOwnProperty('bracketType')
        )
          object.bracketType =
            options.enums === String
              ? $root.rti.ExchangeOrderNotification.BracketType[
                  message.bracketType
                ]
              : message.bracketType;
        return object;
      };

      /**
       * Converts this ExchangeOrderNotification to JSON.
       * @function toJSON
       * @memberof rti.ExchangeOrderNotification
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ExchangeOrderNotification.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ExchangeOrderNotification
       * @function getTypeUrl
       * @memberof rti.ExchangeOrderNotification
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ExchangeOrderNotification.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ExchangeOrderNotification';
      };

      /**
       * NotifyType enum.
       * @name rti.ExchangeOrderNotification.NotifyType
       * @enum {number}
       * @property {number} STATUS=1 STATUS value
       * @property {number} MODIFY=2 MODIFY value
       * @property {number} CANCEL=3 CANCEL value
       * @property {number} TRIGGER=4 TRIGGER value
       * @property {number} FILL=5 FILL value
       * @property {number} REJECT=6 REJECT value
       * @property {number} NOT_MODIFIED=7 NOT_MODIFIED value
       * @property {number} NOT_CANCELLED=8 NOT_CANCELLED value
       * @property {number} GENERIC=9 GENERIC value
       */
      ExchangeOrderNotification.NotifyType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'STATUS')] = 1;
        values[(valuesById[2] = 'MODIFY')] = 2;
        values[(valuesById[3] = 'CANCEL')] = 3;
        values[(valuesById[4] = 'TRIGGER')] = 4;
        values[(valuesById[5] = 'FILL')] = 5;
        values[(valuesById[6] = 'REJECT')] = 6;
        values[(valuesById[7] = 'NOT_MODIFIED')] = 7;
        values[(valuesById[8] = 'NOT_CANCELLED')] = 8;
        values[(valuesById[9] = 'GENERIC')] = 9;
        return values;
      })();

      /**
       * TransactionType enum.
       * @name rti.ExchangeOrderNotification.TransactionType
       * @enum {number}
       * @property {number} BUY=1 BUY value
       * @property {number} SELL=2 SELL value
       * @property {number} SS=3 SS value
       */
      ExchangeOrderNotification.TransactionType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'BUY')] = 1;
        values[(valuesById[2] = 'SELL')] = 2;
        values[(valuesById[3] = 'SS')] = 3;
        return values;
      })();

      /**
       * Duration enum.
       * @name rti.ExchangeOrderNotification.Duration
       * @enum {number}
       * @property {number} DAY=1 DAY value
       * @property {number} GTC=2 GTC value
       * @property {number} IOC=3 IOC value
       * @property {number} FOK=4 FOK value
       */
      ExchangeOrderNotification.Duration = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'DAY')] = 1;
        values[(valuesById[2] = 'GTC')] = 2;
        values[(valuesById[3] = 'IOC')] = 3;
        values[(valuesById[4] = 'FOK')] = 4;
        return values;
      })();

      /**
       * PriceType enum.
       * @name rti.ExchangeOrderNotification.PriceType
       * @enum {number}
       * @property {number} LIMIT=1 LIMIT value
       * @property {number} MARKET=2 MARKET value
       * @property {number} STOP_LIMIT=3 STOP_LIMIT value
       * @property {number} STOP_MARKET=4 STOP_MARKET value
       */
      ExchangeOrderNotification.PriceType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'LIMIT')] = 1;
        values[(valuesById[2] = 'MARKET')] = 2;
        values[(valuesById[3] = 'STOP_LIMIT')] = 3;
        values[(valuesById[4] = 'STOP_MARKET')] = 4;
        return values;
      })();

      /**
       * BracketType enum.
       * @name rti.ExchangeOrderNotification.BracketType
       * @enum {number}
       * @property {number} STOP_ONLY=1 STOP_ONLY value
       * @property {number} TARGET_ONLY=2 TARGET_ONLY value
       * @property {number} TARGET_AND_STOP=3 TARGET_AND_STOP value
       * @property {number} STOP_ONLY_STATIC=4 STOP_ONLY_STATIC value
       * @property {number} TARGET_ONLY_STATIC=5 TARGET_ONLY_STATIC value
       * @property {number} TARGET_AND_STOP_STATIC=6 TARGET_AND_STOP_STATIC value
       */
      ExchangeOrderNotification.BracketType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'STOP_ONLY')] = 1;
        values[(valuesById[2] = 'TARGET_ONLY')] = 2;
        values[(valuesById[3] = 'TARGET_AND_STOP')] = 3;
        values[(valuesById[4] = 'STOP_ONLY_STATIC')] = 4;
        values[(valuesById[5] = 'TARGET_ONLY_STATIC')] = 5;
        values[(valuesById[6] = 'TARGET_AND_STOP_STATIC')] = 6;
        return values;
      })();

      /**
       * OrderPlacement enum.
       * @name rti.ExchangeOrderNotification.OrderPlacement
       * @enum {number}
       * @property {number} MANUAL=1 MANUAL value
       * @property {number} AUTO=2 AUTO value
       */
      ExchangeOrderNotification.OrderPlacement = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'MANUAL')] = 1;
        values[(valuesById[2] = 'AUTO')] = 2;
        return values;
      })();

      return ExchangeOrderNotification;
    })();

    rti.AccountListUpdates = (function () {
      /**
       * Properties of an AccountListUpdates.
       * @memberof rti
       * @interface IAccountListUpdates
       * @property {number} templateId AccountListUpdates templateId
       * @property {string|null} [fcmId] AccountListUpdates fcmId
       * @property {string|null} [ibId] AccountListUpdates ibId
       * @property {string|null} [accountId] AccountListUpdates accountId
       * @property {string|null} [autoLiqThresholdCurrentValue] AccountListUpdates autoLiqThresholdCurrentValue
       */

      /**
       * Constructs a new AccountListUpdates.
       * @memberof rti
       * @classdesc Represents an AccountListUpdates.
       * @implements IAccountListUpdates
       * @constructor
       * @param {rti.IAccountListUpdates=} [properties] Properties to set
       */
      function AccountListUpdates(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * AccountListUpdates templateId.
       * @member {number} templateId
       * @memberof rti.AccountListUpdates
       * @instance
       */
      AccountListUpdates.prototype.templateId = 0;

      /**
       * AccountListUpdates fcmId.
       * @member {string} fcmId
       * @memberof rti.AccountListUpdates
       * @instance
       */
      AccountListUpdates.prototype.fcmId = '';

      /**
       * AccountListUpdates ibId.
       * @member {string} ibId
       * @memberof rti.AccountListUpdates
       * @instance
       */
      AccountListUpdates.prototype.ibId = '';

      /**
       * AccountListUpdates accountId.
       * @member {string} accountId
       * @memberof rti.AccountListUpdates
       * @instance
       */
      AccountListUpdates.prototype.accountId = '';

      /**
       * AccountListUpdates autoLiqThresholdCurrentValue.
       * @member {string} autoLiqThresholdCurrentValue
       * @memberof rti.AccountListUpdates
       * @instance
       */
      AccountListUpdates.prototype.autoLiqThresholdCurrentValue = '';

      /**
       * Creates a new AccountListUpdates instance using the specified properties.
       * @function create
       * @memberof rti.AccountListUpdates
       * @static
       * @param {rti.IAccountListUpdates=} [properties] Properties to set
       * @returns {rti.AccountListUpdates} AccountListUpdates instance
       */
      AccountListUpdates.create = function create(properties) {
        return new AccountListUpdates(properties);
      };

      /**
       * Encodes the specified AccountListUpdates message. Does not implicitly {@link rti.AccountListUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.AccountListUpdates
       * @static
       * @param {rti.IAccountListUpdates} message AccountListUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      AccountListUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.autoLiqThresholdCurrentValue != null &&
          Object.hasOwnProperty.call(message, 'autoLiqThresholdCurrentValue')
        )
          writer
            .uint32(/* id 131040, wireType 2 =*/ 1048322)
            .string(message.autoLiqThresholdCurrentValue);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified AccountListUpdates message, length delimited. Does not implicitly {@link rti.AccountListUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.AccountListUpdates
       * @static
       * @param {rti.IAccountListUpdates} message AccountListUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      AccountListUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an AccountListUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.AccountListUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.AccountListUpdates} AccountListUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      AccountListUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.AccountListUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 131040:
              message.autoLiqThresholdCurrentValue = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an AccountListUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.AccountListUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.AccountListUpdates} AccountListUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      AccountListUpdates.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an AccountListUpdates message.
       * @function verify
       * @memberof rti.AccountListUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      AccountListUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (
          message.autoLiqThresholdCurrentValue != null &&
          message.hasOwnProperty('autoLiqThresholdCurrentValue')
        )
          if (!$util.isString(message.autoLiqThresholdCurrentValue))
            return 'autoLiqThresholdCurrentValue: string expected';
        return null;
      };

      /**
       * Creates an AccountListUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.AccountListUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.AccountListUpdates} AccountListUpdates
       */
      AccountListUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.AccountListUpdates) return object;
        var message = new $root.rti.AccountListUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.autoLiqThresholdCurrentValue != null)
          message.autoLiqThresholdCurrentValue = String(
            object.autoLiqThresholdCurrentValue,
          );
        return message;
      };

      /**
       * Creates a plain object from an AccountListUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.AccountListUpdates
       * @static
       * @param {rti.AccountListUpdates} message AccountListUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      AccountListUpdates.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.autoLiqThresholdCurrentValue = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (
          message.autoLiqThresholdCurrentValue != null &&
          message.hasOwnProperty('autoLiqThresholdCurrentValue')
        )
          object.autoLiqThresholdCurrentValue =
            message.autoLiqThresholdCurrentValue;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this AccountListUpdates to JSON.
       * @function toJSON
       * @memberof rti.AccountListUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      AccountListUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for AccountListUpdates
       * @function getTypeUrl
       * @memberof rti.AccountListUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      AccountListUpdates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.AccountListUpdates';
      };

      return AccountListUpdates;
    })();

    rti.UpdateEasyToBorrowList = (function () {
      /**
       * Properties of an UpdateEasyToBorrowList.
       * @memberof rti
       * @interface IUpdateEasyToBorrowList
       * @property {number} templateId UpdateEasyToBorrowList templateId
       * @property {string|null} [brokerDealer] UpdateEasyToBorrowList brokerDealer
       * @property {string|null} [symbol] UpdateEasyToBorrowList symbol
       * @property {string|null} [symbolName] UpdateEasyToBorrowList symbolName
       * @property {number|null} [qtyAvailable] UpdateEasyToBorrowList qtyAvailable
       * @property {number|null} [qtyNeeded] UpdateEasyToBorrowList qtyNeeded
       * @property {boolean|null} [borrowable] UpdateEasyToBorrowList borrowable
       */

      /**
       * Constructs a new UpdateEasyToBorrowList.
       * @memberof rti
       * @classdesc Represents an UpdateEasyToBorrowList.
       * @implements IUpdateEasyToBorrowList
       * @constructor
       * @param {rti.IUpdateEasyToBorrowList=} [properties] Properties to set
       */
      function UpdateEasyToBorrowList(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * UpdateEasyToBorrowList templateId.
       * @member {number} templateId
       * @memberof rti.UpdateEasyToBorrowList
       * @instance
       */
      UpdateEasyToBorrowList.prototype.templateId = 0;

      /**
       * UpdateEasyToBorrowList brokerDealer.
       * @member {string} brokerDealer
       * @memberof rti.UpdateEasyToBorrowList
       * @instance
       */
      UpdateEasyToBorrowList.prototype.brokerDealer = '';

      /**
       * UpdateEasyToBorrowList symbol.
       * @member {string} symbol
       * @memberof rti.UpdateEasyToBorrowList
       * @instance
       */
      UpdateEasyToBorrowList.prototype.symbol = '';

      /**
       * UpdateEasyToBorrowList symbolName.
       * @member {string} symbolName
       * @memberof rti.UpdateEasyToBorrowList
       * @instance
       */
      UpdateEasyToBorrowList.prototype.symbolName = '';

      /**
       * UpdateEasyToBorrowList qtyAvailable.
       * @member {number} qtyAvailable
       * @memberof rti.UpdateEasyToBorrowList
       * @instance
       */
      UpdateEasyToBorrowList.prototype.qtyAvailable = 0;

      /**
       * UpdateEasyToBorrowList qtyNeeded.
       * @member {number} qtyNeeded
       * @memberof rti.UpdateEasyToBorrowList
       * @instance
       */
      UpdateEasyToBorrowList.prototype.qtyNeeded = 0;

      /**
       * UpdateEasyToBorrowList borrowable.
       * @member {boolean} borrowable
       * @memberof rti.UpdateEasyToBorrowList
       * @instance
       */
      UpdateEasyToBorrowList.prototype.borrowable = false;

      /**
       * Creates a new UpdateEasyToBorrowList instance using the specified properties.
       * @function create
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {rti.IUpdateEasyToBorrowList=} [properties] Properties to set
       * @returns {rti.UpdateEasyToBorrowList} UpdateEasyToBorrowList instance
       */
      UpdateEasyToBorrowList.create = function create(properties) {
        return new UpdateEasyToBorrowList(properties);
      };

      /**
       * Encodes the specified UpdateEasyToBorrowList message. Does not implicitly {@link rti.UpdateEasyToBorrowList.verify|verify} messages.
       * @function encode
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {rti.IUpdateEasyToBorrowList} message UpdateEasyToBorrowList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      UpdateEasyToBorrowList.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbolName != null &&
          Object.hasOwnProperty.call(message, 'symbolName')
        )
          writer
            .uint32(/* id 100003, wireType 2 =*/ 800026)
            .string(message.symbolName);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.borrowable != null &&
          Object.hasOwnProperty.call(message, 'borrowable')
        )
          writer
            .uint32(/* id 110353, wireType 0 =*/ 882824)
            .bool(message.borrowable);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.brokerDealer != null &&
          Object.hasOwnProperty.call(message, 'brokerDealer')
        )
          writer
            .uint32(/* id 154612, wireType 2 =*/ 1236898)
            .string(message.brokerDealer);
        if (
          message.qtyAvailable != null &&
          Object.hasOwnProperty.call(message, 'qtyAvailable')
        )
          writer
            .uint32(/* id 154613, wireType 0 =*/ 1236904)
            .int32(message.qtyAvailable);
        if (
          message.qtyNeeded != null &&
          Object.hasOwnProperty.call(message, 'qtyNeeded')
        )
          writer
            .uint32(/* id 154614, wireType 0 =*/ 1236912)
            .int32(message.qtyNeeded);
        return writer;
      };

      /**
       * Encodes the specified UpdateEasyToBorrowList message, length delimited. Does not implicitly {@link rti.UpdateEasyToBorrowList.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {rti.IUpdateEasyToBorrowList} message UpdateEasyToBorrowList message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      UpdateEasyToBorrowList.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an UpdateEasyToBorrowList message from the specified reader or buffer.
       * @function decode
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.UpdateEasyToBorrowList} UpdateEasyToBorrowList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      UpdateEasyToBorrowList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.UpdateEasyToBorrowList();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 154612:
              message.brokerDealer = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 100003:
              message.symbolName = reader.string();
              break;
            case 154613:
              message.qtyAvailable = reader.int32();
              break;
            case 154614:
              message.qtyNeeded = reader.int32();
              break;
            case 110353:
              message.borrowable = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an UpdateEasyToBorrowList message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.UpdateEasyToBorrowList} UpdateEasyToBorrowList
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      UpdateEasyToBorrowList.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an UpdateEasyToBorrowList message.
       * @function verify
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      UpdateEasyToBorrowList.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (
          message.brokerDealer != null &&
          message.hasOwnProperty('brokerDealer')
        )
          if (!$util.isString(message.brokerDealer))
            return 'brokerDealer: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          if (!$util.isString(message.symbolName))
            return 'symbolName: string expected';
        if (
          message.qtyAvailable != null &&
          message.hasOwnProperty('qtyAvailable')
        )
          if (!$util.isInteger(message.qtyAvailable))
            return 'qtyAvailable: integer expected';
        if (message.qtyNeeded != null && message.hasOwnProperty('qtyNeeded'))
          if (!$util.isInteger(message.qtyNeeded))
            return 'qtyNeeded: integer expected';
        if (message.borrowable != null && message.hasOwnProperty('borrowable'))
          if (typeof message.borrowable !== 'boolean')
            return 'borrowable: boolean expected';
        return null;
      };

      /**
       * Creates an UpdateEasyToBorrowList message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.UpdateEasyToBorrowList} UpdateEasyToBorrowList
       */
      UpdateEasyToBorrowList.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.UpdateEasyToBorrowList) return object;
        var message = new $root.rti.UpdateEasyToBorrowList();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.brokerDealer != null)
          message.brokerDealer = String(object.brokerDealer);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.symbolName != null)
          message.symbolName = String(object.symbolName);
        if (object.qtyAvailable != null)
          message.qtyAvailable = object.qtyAvailable | 0;
        if (object.qtyNeeded != null) message.qtyNeeded = object.qtyNeeded | 0;
        if (object.borrowable != null)
          message.borrowable = Boolean(object.borrowable);
        return message;
      };

      /**
       * Creates a plain object from an UpdateEasyToBorrowList message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {rti.UpdateEasyToBorrowList} message UpdateEasyToBorrowList
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      UpdateEasyToBorrowList.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.symbolName = '';
          object.symbol = '';
          object.borrowable = false;
          object.templateId = 0;
          object.brokerDealer = '';
          object.qtyAvailable = 0;
          object.qtyNeeded = 0;
        }
        if (message.symbolName != null && message.hasOwnProperty('symbolName'))
          object.symbolName = message.symbolName;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.borrowable != null && message.hasOwnProperty('borrowable'))
          object.borrowable = message.borrowable;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.brokerDealer != null &&
          message.hasOwnProperty('brokerDealer')
        )
          object.brokerDealer = message.brokerDealer;
        if (
          message.qtyAvailable != null &&
          message.hasOwnProperty('qtyAvailable')
        )
          object.qtyAvailable = message.qtyAvailable;
        if (message.qtyNeeded != null && message.hasOwnProperty('qtyNeeded'))
          object.qtyNeeded = message.qtyNeeded;
        return object;
      };

      /**
       * Converts this UpdateEasyToBorrowList to JSON.
       * @function toJSON
       * @memberof rti.UpdateEasyToBorrowList
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      UpdateEasyToBorrowList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for UpdateEasyToBorrowList
       * @function getTypeUrl
       * @memberof rti.UpdateEasyToBorrowList
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      UpdateEasyToBorrowList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.UpdateEasyToBorrowList';
      };

      return UpdateEasyToBorrowList;
    })();

    rti.AccountRmsUpdates = (function () {
      /**
       * Properties of an AccountRmsUpdates.
       * @memberof rti
       * @interface IAccountRmsUpdates
       * @property {number} templateId AccountRmsUpdates templateId
       * @property {number|null} [updateBits] AccountRmsUpdates updateBits
       * @property {string|null} [fcmId] AccountRmsUpdates fcmId
       * @property {string|null} [ibId] AccountRmsUpdates ibId
       * @property {string|null} [accountId] AccountRmsUpdates accountId
       * @property {string|null} [autoLiqThresholdCurrentValue] AccountRmsUpdates autoLiqThresholdCurrentValue
       * @property {string|null} [autoLiqPeakAccountBalance] AccountRmsUpdates autoLiqPeakAccountBalance
       * @property {string|null} [autoLiqPeakAccountBalanceSsboe] AccountRmsUpdates autoLiqPeakAccountBalanceSsboe
       */

      /**
       * Constructs a new AccountRmsUpdates.
       * @memberof rti
       * @classdesc Represents an AccountRmsUpdates.
       * @implements IAccountRmsUpdates
       * @constructor
       * @param {rti.IAccountRmsUpdates=} [properties] Properties to set
       */
      function AccountRmsUpdates(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * AccountRmsUpdates templateId.
       * @member {number} templateId
       * @memberof rti.AccountRmsUpdates
       * @instance
       */
      AccountRmsUpdates.prototype.templateId = 0;

      /**
       * AccountRmsUpdates updateBits.
       * @member {number} updateBits
       * @memberof rti.AccountRmsUpdates
       * @instance
       */
      AccountRmsUpdates.prototype.updateBits = 0;

      /**
       * AccountRmsUpdates fcmId.
       * @member {string} fcmId
       * @memberof rti.AccountRmsUpdates
       * @instance
       */
      AccountRmsUpdates.prototype.fcmId = '';

      /**
       * AccountRmsUpdates ibId.
       * @member {string} ibId
       * @memberof rti.AccountRmsUpdates
       * @instance
       */
      AccountRmsUpdates.prototype.ibId = '';

      /**
       * AccountRmsUpdates accountId.
       * @member {string} accountId
       * @memberof rti.AccountRmsUpdates
       * @instance
       */
      AccountRmsUpdates.prototype.accountId = '';

      /**
       * AccountRmsUpdates autoLiqThresholdCurrentValue.
       * @member {string} autoLiqThresholdCurrentValue
       * @memberof rti.AccountRmsUpdates
       * @instance
       */
      AccountRmsUpdates.prototype.autoLiqThresholdCurrentValue = '';

      /**
       * AccountRmsUpdates autoLiqPeakAccountBalance.
       * @member {string} autoLiqPeakAccountBalance
       * @memberof rti.AccountRmsUpdates
       * @instance
       */
      AccountRmsUpdates.prototype.autoLiqPeakAccountBalance = '';

      /**
       * AccountRmsUpdates autoLiqPeakAccountBalanceSsboe.
       * @member {string} autoLiqPeakAccountBalanceSsboe
       * @memberof rti.AccountRmsUpdates
       * @instance
       */
      AccountRmsUpdates.prototype.autoLiqPeakAccountBalanceSsboe = '';

      /**
       * Creates a new AccountRmsUpdates instance using the specified properties.
       * @function create
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {rti.IAccountRmsUpdates=} [properties] Properties to set
       * @returns {rti.AccountRmsUpdates} AccountRmsUpdates instance
       */
      AccountRmsUpdates.create = function create(properties) {
        return new AccountRmsUpdates(properties);
      };

      /**
       * Encodes the specified AccountRmsUpdates message. Does not implicitly {@link rti.AccountRmsUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {rti.IAccountRmsUpdates} message AccountRmsUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      AccountRmsUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.autoLiqThresholdCurrentValue != null &&
          Object.hasOwnProperty.call(message, 'autoLiqThresholdCurrentValue')
        )
          writer
            .uint32(/* id 131040, wireType 2 =*/ 1048322)
            .string(message.autoLiqThresholdCurrentValue);
        if (
          message.autoLiqPeakAccountBalance != null &&
          Object.hasOwnProperty.call(message, 'autoLiqPeakAccountBalance')
        )
          writer
            .uint32(/* id 131049, wireType 2 =*/ 1048394)
            .string(message.autoLiqPeakAccountBalance);
        if (
          message.autoLiqPeakAccountBalanceSsboe != null &&
          Object.hasOwnProperty.call(message, 'autoLiqPeakAccountBalanceSsboe')
        )
          writer
            .uint32(/* id 131050, wireType 2 =*/ 1048402)
            .string(message.autoLiqPeakAccountBalanceSsboe);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.updateBits != null &&
          Object.hasOwnProperty.call(message, 'updateBits')
        )
          writer
            .uint32(/* id 154211, wireType 0 =*/ 1233688)
            .int32(message.updateBits);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified AccountRmsUpdates message, length delimited. Does not implicitly {@link rti.AccountRmsUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {rti.IAccountRmsUpdates} message AccountRmsUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      AccountRmsUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an AccountRmsUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.AccountRmsUpdates} AccountRmsUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      AccountRmsUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.AccountRmsUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 154211:
              message.updateBits = reader.int32();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 131040:
              message.autoLiqThresholdCurrentValue = reader.string();
              break;
            case 131049:
              message.autoLiqPeakAccountBalance = reader.string();
              break;
            case 131050:
              message.autoLiqPeakAccountBalanceSsboe = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an AccountRmsUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.AccountRmsUpdates} AccountRmsUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      AccountRmsUpdates.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an AccountRmsUpdates message.
       * @function verify
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      AccountRmsUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.updateBits != null && message.hasOwnProperty('updateBits'))
          if (!$util.isInteger(message.updateBits))
            return 'updateBits: integer expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (
          message.autoLiqThresholdCurrentValue != null &&
          message.hasOwnProperty('autoLiqThresholdCurrentValue')
        )
          if (!$util.isString(message.autoLiqThresholdCurrentValue))
            return 'autoLiqThresholdCurrentValue: string expected';
        if (
          message.autoLiqPeakAccountBalance != null &&
          message.hasOwnProperty('autoLiqPeakAccountBalance')
        )
          if (!$util.isString(message.autoLiqPeakAccountBalance))
            return 'autoLiqPeakAccountBalance: string expected';
        if (
          message.autoLiqPeakAccountBalanceSsboe != null &&
          message.hasOwnProperty('autoLiqPeakAccountBalanceSsboe')
        )
          if (!$util.isString(message.autoLiqPeakAccountBalanceSsboe))
            return 'autoLiqPeakAccountBalanceSsboe: string expected';
        return null;
      };

      /**
       * Creates an AccountRmsUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.AccountRmsUpdates} AccountRmsUpdates
       */
      AccountRmsUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.AccountRmsUpdates) return object;
        var message = new $root.rti.AccountRmsUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.updateBits != null)
          message.updateBits = object.updateBits | 0;
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.autoLiqThresholdCurrentValue != null)
          message.autoLiqThresholdCurrentValue = String(
            object.autoLiqThresholdCurrentValue,
          );
        if (object.autoLiqPeakAccountBalance != null)
          message.autoLiqPeakAccountBalance = String(
            object.autoLiqPeakAccountBalance,
          );
        if (object.autoLiqPeakAccountBalanceSsboe != null)
          message.autoLiqPeakAccountBalanceSsboe = String(
            object.autoLiqPeakAccountBalanceSsboe,
          );
        return message;
      };

      /**
       * Creates a plain object from an AccountRmsUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {rti.AccountRmsUpdates} message AccountRmsUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      AccountRmsUpdates.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.autoLiqThresholdCurrentValue = '';
          object.autoLiqPeakAccountBalance = '';
          object.autoLiqPeakAccountBalanceSsboe = '';
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.updateBits = 0;
          object.templateId = 0;
        }
        if (
          message.autoLiqThresholdCurrentValue != null &&
          message.hasOwnProperty('autoLiqThresholdCurrentValue')
        )
          object.autoLiqThresholdCurrentValue =
            message.autoLiqThresholdCurrentValue;
        if (
          message.autoLiqPeakAccountBalance != null &&
          message.hasOwnProperty('autoLiqPeakAccountBalance')
        )
          object.autoLiqPeakAccountBalance = message.autoLiqPeakAccountBalance;
        if (
          message.autoLiqPeakAccountBalanceSsboe != null &&
          message.hasOwnProperty('autoLiqPeakAccountBalanceSsboe')
        )
          object.autoLiqPeakAccountBalanceSsboe =
            message.autoLiqPeakAccountBalanceSsboe;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.updateBits != null && message.hasOwnProperty('updateBits'))
          object.updateBits = message.updateBits;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this AccountRmsUpdates to JSON.
       * @function toJSON
       * @memberof rti.AccountRmsUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      AccountRmsUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for AccountRmsUpdates
       * @function getTypeUrl
       * @memberof rti.AccountRmsUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      AccountRmsUpdates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.AccountRmsUpdates';
      };

      /**
       * UpdateBits enum.
       * @name rti.AccountRmsUpdates.UpdateBits
       * @enum {number}
       * @property {number} AUTO_LIQ_THRESHOLD_CURRENT_VALUE=1 AUTO_LIQ_THRESHOLD_CURRENT_VALUE value
       */
      AccountRmsUpdates.UpdateBits = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'AUTO_LIQ_THRESHOLD_CURRENT_VALUE')] = 1;
        return values;
      })();

      return AccountRmsUpdates;
    })();

    rti.RequestPnLPositionUpdates = (function () {
      /**
       * Properties of a RequestPnLPositionUpdates.
       * @memberof rti
       * @interface IRequestPnLPositionUpdates
       * @property {number} templateId RequestPnLPositionUpdates templateId
       * @property {Array.<string>|null} [userMsg] RequestPnLPositionUpdates userMsg
       * @property {rti.RequestPnLPositionUpdates.Request|null} [request] RequestPnLPositionUpdates request
       * @property {string|null} [fcmId] RequestPnLPositionUpdates fcmId
       * @property {string|null} [ibId] RequestPnLPositionUpdates ibId
       * @property {string|null} [accountId] RequestPnLPositionUpdates accountId
       */

      /**
       * Constructs a new RequestPnLPositionUpdates.
       * @memberof rti
       * @classdesc Represents a RequestPnLPositionUpdates.
       * @implements IRequestPnLPositionUpdates
       * @constructor
       * @param {rti.IRequestPnLPositionUpdates=} [properties] Properties to set
       */
      function RequestPnLPositionUpdates(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestPnLPositionUpdates templateId.
       * @member {number} templateId
       * @memberof rti.RequestPnLPositionUpdates
       * @instance
       */
      RequestPnLPositionUpdates.prototype.templateId = 0;

      /**
       * RequestPnLPositionUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestPnLPositionUpdates
       * @instance
       */
      RequestPnLPositionUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * RequestPnLPositionUpdates request.
       * @member {rti.RequestPnLPositionUpdates.Request} request
       * @memberof rti.RequestPnLPositionUpdates
       * @instance
       */
      RequestPnLPositionUpdates.prototype.request = 1;

      /**
       * RequestPnLPositionUpdates fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestPnLPositionUpdates
       * @instance
       */
      RequestPnLPositionUpdates.prototype.fcmId = '';

      /**
       * RequestPnLPositionUpdates ibId.
       * @member {string} ibId
       * @memberof rti.RequestPnLPositionUpdates
       * @instance
       */
      RequestPnLPositionUpdates.prototype.ibId = '';

      /**
       * RequestPnLPositionUpdates accountId.
       * @member {string} accountId
       * @memberof rti.RequestPnLPositionUpdates
       * @instance
       */
      RequestPnLPositionUpdates.prototype.accountId = '';

      /**
       * Creates a new RequestPnLPositionUpdates instance using the specified properties.
       * @function create
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {rti.IRequestPnLPositionUpdates=} [properties] Properties to set
       * @returns {rti.RequestPnLPositionUpdates} RequestPnLPositionUpdates instance
       */
      RequestPnLPositionUpdates.create = function create(properties) {
        return new RequestPnLPositionUpdates(properties);
      };

      /**
       * Encodes the specified RequestPnLPositionUpdates message. Does not implicitly {@link rti.RequestPnLPositionUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {rti.IRequestPnLPositionUpdates} message RequestPnLPositionUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestPnLPositionUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.request != null &&
          Object.hasOwnProperty.call(message, 'request')
        )
          writer
            .uint32(/* id 100000, wireType 0 =*/ 800000)
            .int32(message.request);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestPnLPositionUpdates message, length delimited. Does not implicitly {@link rti.RequestPnLPositionUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {rti.IRequestPnLPositionUpdates} message RequestPnLPositionUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestPnLPositionUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestPnLPositionUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestPnLPositionUpdates} RequestPnLPositionUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestPnLPositionUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestPnLPositionUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 100000:
              message.request = reader.int32();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestPnLPositionUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestPnLPositionUpdates} RequestPnLPositionUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestPnLPositionUpdates.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestPnLPositionUpdates message.
       * @function verify
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestPnLPositionUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.request != null && message.hasOwnProperty('request'))
          switch (message.request) {
            default:
              return 'request: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        return null;
      };

      /**
       * Creates a RequestPnLPositionUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestPnLPositionUpdates} RequestPnLPositionUpdates
       */
      RequestPnLPositionUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestPnLPositionUpdates)
          return object;
        var message = new $root.rti.RequestPnLPositionUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestPnLPositionUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        switch (object.request) {
          case 'SUBSCRIBE':
          case 1:
            message.request = 1;
            break;
          case 'UNSUBSCRIBE':
          case 2:
            message.request = 2;
            break;
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        return message;
      };

      /**
       * Creates a plain object from a RequestPnLPositionUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {rti.RequestPnLPositionUpdates} message RequestPnLPositionUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestPnLPositionUpdates.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.request = options.enums === String ? 'SUBSCRIBE' : 1;
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.request != null && message.hasOwnProperty('request'))
          object.request =
            options.enums === String
              ? $root.rti.RequestPnLPositionUpdates.Request[message.request]
              : message.request;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestPnLPositionUpdates to JSON.
       * @function toJSON
       * @memberof rti.RequestPnLPositionUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestPnLPositionUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestPnLPositionUpdates
       * @function getTypeUrl
       * @memberof rti.RequestPnLPositionUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestPnLPositionUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestPnLPositionUpdates';
      };

      /**
       * Request enum.
       * @name rti.RequestPnLPositionUpdates.Request
       * @enum {number}
       * @property {number} SUBSCRIBE=1 SUBSCRIBE value
       * @property {number} UNSUBSCRIBE=2 UNSUBSCRIBE value
       */
      RequestPnLPositionUpdates.Request = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SUBSCRIBE')] = 1;
        values[(valuesById[2] = 'UNSUBSCRIBE')] = 2;
        return values;
      })();

      return RequestPnLPositionUpdates;
    })();

    rti.ResponsePnLPositionUpdates = (function () {
      /**
       * Properties of a ResponsePnLPositionUpdates.
       * @memberof rti
       * @interface IResponsePnLPositionUpdates
       * @property {number} templateId ResponsePnLPositionUpdates templateId
       * @property {Array.<string>|null} [userMsg] ResponsePnLPositionUpdates userMsg
       * @property {Array.<string>|null} [rpCode] ResponsePnLPositionUpdates rpCode
       */

      /**
       * Constructs a new ResponsePnLPositionUpdates.
       * @memberof rti
       * @classdesc Represents a ResponsePnLPositionUpdates.
       * @implements IResponsePnLPositionUpdates
       * @constructor
       * @param {rti.IResponsePnLPositionUpdates=} [properties] Properties to set
       */
      function ResponsePnLPositionUpdates(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponsePnLPositionUpdates templateId.
       * @member {number} templateId
       * @memberof rti.ResponsePnLPositionUpdates
       * @instance
       */
      ResponsePnLPositionUpdates.prototype.templateId = 0;

      /**
       * ResponsePnLPositionUpdates userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponsePnLPositionUpdates
       * @instance
       */
      ResponsePnLPositionUpdates.prototype.userMsg = $util.emptyArray;

      /**
       * ResponsePnLPositionUpdates rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponsePnLPositionUpdates
       * @instance
       */
      ResponsePnLPositionUpdates.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponsePnLPositionUpdates instance using the specified properties.
       * @function create
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {rti.IResponsePnLPositionUpdates=} [properties] Properties to set
       * @returns {rti.ResponsePnLPositionUpdates} ResponsePnLPositionUpdates instance
       */
      ResponsePnLPositionUpdates.create = function create(properties) {
        return new ResponsePnLPositionUpdates(properties);
      };

      /**
       * Encodes the specified ResponsePnLPositionUpdates message. Does not implicitly {@link rti.ResponsePnLPositionUpdates.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {rti.IResponsePnLPositionUpdates} message ResponsePnLPositionUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponsePnLPositionUpdates.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponsePnLPositionUpdates message, length delimited. Does not implicitly {@link rti.ResponsePnLPositionUpdates.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {rti.IResponsePnLPositionUpdates} message ResponsePnLPositionUpdates message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponsePnLPositionUpdates.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponsePnLPositionUpdates message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponsePnLPositionUpdates} ResponsePnLPositionUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponsePnLPositionUpdates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponsePnLPositionUpdates();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponsePnLPositionUpdates message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponsePnLPositionUpdates} ResponsePnLPositionUpdates
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponsePnLPositionUpdates.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponsePnLPositionUpdates message.
       * @function verify
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponsePnLPositionUpdates.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponsePnLPositionUpdates message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponsePnLPositionUpdates} ResponsePnLPositionUpdates
       */
      ResponsePnLPositionUpdates.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponsePnLPositionUpdates)
          return object;
        var message = new $root.rti.ResponsePnLPositionUpdates();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponsePnLPositionUpdates.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponsePnLPositionUpdates.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponsePnLPositionUpdates message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {rti.ResponsePnLPositionUpdates} message ResponsePnLPositionUpdates
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponsePnLPositionUpdates.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponsePnLPositionUpdates to JSON.
       * @function toJSON
       * @memberof rti.ResponsePnLPositionUpdates
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponsePnLPositionUpdates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponsePnLPositionUpdates
       * @function getTypeUrl
       * @memberof rti.ResponsePnLPositionUpdates
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponsePnLPositionUpdates.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponsePnLPositionUpdates';
      };

      return ResponsePnLPositionUpdates;
    })();

    rti.RequestPnLPositionSnapshot = (function () {
      /**
       * Properties of a RequestPnLPositionSnapshot.
       * @memberof rti
       * @interface IRequestPnLPositionSnapshot
       * @property {number} templateId RequestPnLPositionSnapshot templateId
       * @property {Array.<string>|null} [userMsg] RequestPnLPositionSnapshot userMsg
       * @property {string|null} [fcmId] RequestPnLPositionSnapshot fcmId
       * @property {string|null} [ibId] RequestPnLPositionSnapshot ibId
       * @property {string|null} [accountId] RequestPnLPositionSnapshot accountId
       */

      /**
       * Constructs a new RequestPnLPositionSnapshot.
       * @memberof rti
       * @classdesc Represents a RequestPnLPositionSnapshot.
       * @implements IRequestPnLPositionSnapshot
       * @constructor
       * @param {rti.IRequestPnLPositionSnapshot=} [properties] Properties to set
       */
      function RequestPnLPositionSnapshot(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestPnLPositionSnapshot templateId.
       * @member {number} templateId
       * @memberof rti.RequestPnLPositionSnapshot
       * @instance
       */
      RequestPnLPositionSnapshot.prototype.templateId = 0;

      /**
       * RequestPnLPositionSnapshot userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestPnLPositionSnapshot
       * @instance
       */
      RequestPnLPositionSnapshot.prototype.userMsg = $util.emptyArray;

      /**
       * RequestPnLPositionSnapshot fcmId.
       * @member {string} fcmId
       * @memberof rti.RequestPnLPositionSnapshot
       * @instance
       */
      RequestPnLPositionSnapshot.prototype.fcmId = '';

      /**
       * RequestPnLPositionSnapshot ibId.
       * @member {string} ibId
       * @memberof rti.RequestPnLPositionSnapshot
       * @instance
       */
      RequestPnLPositionSnapshot.prototype.ibId = '';

      /**
       * RequestPnLPositionSnapshot accountId.
       * @member {string} accountId
       * @memberof rti.RequestPnLPositionSnapshot
       * @instance
       */
      RequestPnLPositionSnapshot.prototype.accountId = '';

      /**
       * Creates a new RequestPnLPositionSnapshot instance using the specified properties.
       * @function create
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {rti.IRequestPnLPositionSnapshot=} [properties] Properties to set
       * @returns {rti.RequestPnLPositionSnapshot} RequestPnLPositionSnapshot instance
       */
      RequestPnLPositionSnapshot.create = function create(properties) {
        return new RequestPnLPositionSnapshot(properties);
      };

      /**
       * Encodes the specified RequestPnLPositionSnapshot message. Does not implicitly {@link rti.RequestPnLPositionSnapshot.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {rti.IRequestPnLPositionSnapshot} message RequestPnLPositionSnapshot message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestPnLPositionSnapshot.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestPnLPositionSnapshot message, length delimited. Does not implicitly {@link rti.RequestPnLPositionSnapshot.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {rti.IRequestPnLPositionSnapshot} message RequestPnLPositionSnapshot message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestPnLPositionSnapshot.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestPnLPositionSnapshot message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestPnLPositionSnapshot} RequestPnLPositionSnapshot
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestPnLPositionSnapshot.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestPnLPositionSnapshot();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestPnLPositionSnapshot message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestPnLPositionSnapshot} RequestPnLPositionSnapshot
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestPnLPositionSnapshot.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestPnLPositionSnapshot message.
       * @function verify
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestPnLPositionSnapshot.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        return null;
      };

      /**
       * Creates a RequestPnLPositionSnapshot message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestPnLPositionSnapshot} RequestPnLPositionSnapshot
       */
      RequestPnLPositionSnapshot.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestPnLPositionSnapshot)
          return object;
        var message = new $root.rti.RequestPnLPositionSnapshot();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestPnLPositionSnapshot.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        return message;
      };

      /**
       * Creates a plain object from a RequestPnLPositionSnapshot message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {rti.RequestPnLPositionSnapshot} message RequestPnLPositionSnapshot
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestPnLPositionSnapshot.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestPnLPositionSnapshot to JSON.
       * @function toJSON
       * @memberof rti.RequestPnLPositionSnapshot
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestPnLPositionSnapshot.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestPnLPositionSnapshot
       * @function getTypeUrl
       * @memberof rti.RequestPnLPositionSnapshot
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestPnLPositionSnapshot.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestPnLPositionSnapshot';
      };

      return RequestPnLPositionSnapshot;
    })();

    rti.ResponsePnLPositionSnapshot = (function () {
      /**
       * Properties of a ResponsePnLPositionSnapshot.
       * @memberof rti
       * @interface IResponsePnLPositionSnapshot
       * @property {number} templateId ResponsePnLPositionSnapshot templateId
       * @property {Array.<string>|null} [userMsg] ResponsePnLPositionSnapshot userMsg
       * @property {Array.<string>|null} [rpCode] ResponsePnLPositionSnapshot rpCode
       */

      /**
       * Constructs a new ResponsePnLPositionSnapshot.
       * @memberof rti
       * @classdesc Represents a ResponsePnLPositionSnapshot.
       * @implements IResponsePnLPositionSnapshot
       * @constructor
       * @param {rti.IResponsePnLPositionSnapshot=} [properties] Properties to set
       */
      function ResponsePnLPositionSnapshot(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponsePnLPositionSnapshot templateId.
       * @member {number} templateId
       * @memberof rti.ResponsePnLPositionSnapshot
       * @instance
       */
      ResponsePnLPositionSnapshot.prototype.templateId = 0;

      /**
       * ResponsePnLPositionSnapshot userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponsePnLPositionSnapshot
       * @instance
       */
      ResponsePnLPositionSnapshot.prototype.userMsg = $util.emptyArray;

      /**
       * ResponsePnLPositionSnapshot rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponsePnLPositionSnapshot
       * @instance
       */
      ResponsePnLPositionSnapshot.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponsePnLPositionSnapshot instance using the specified properties.
       * @function create
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {rti.IResponsePnLPositionSnapshot=} [properties] Properties to set
       * @returns {rti.ResponsePnLPositionSnapshot} ResponsePnLPositionSnapshot instance
       */
      ResponsePnLPositionSnapshot.create = function create(properties) {
        return new ResponsePnLPositionSnapshot(properties);
      };

      /**
       * Encodes the specified ResponsePnLPositionSnapshot message. Does not implicitly {@link rti.ResponsePnLPositionSnapshot.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {rti.IResponsePnLPositionSnapshot} message ResponsePnLPositionSnapshot message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponsePnLPositionSnapshot.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponsePnLPositionSnapshot message, length delimited. Does not implicitly {@link rti.ResponsePnLPositionSnapshot.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {rti.IResponsePnLPositionSnapshot} message ResponsePnLPositionSnapshot message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponsePnLPositionSnapshot.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponsePnLPositionSnapshot message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponsePnLPositionSnapshot} ResponsePnLPositionSnapshot
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponsePnLPositionSnapshot.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponsePnLPositionSnapshot();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponsePnLPositionSnapshot message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponsePnLPositionSnapshot} ResponsePnLPositionSnapshot
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponsePnLPositionSnapshot.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponsePnLPositionSnapshot message.
       * @function verify
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponsePnLPositionSnapshot.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponsePnLPositionSnapshot message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponsePnLPositionSnapshot} ResponsePnLPositionSnapshot
       */
      ResponsePnLPositionSnapshot.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponsePnLPositionSnapshot)
          return object;
        var message = new $root.rti.ResponsePnLPositionSnapshot();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponsePnLPositionSnapshot.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponsePnLPositionSnapshot.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponsePnLPositionSnapshot message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {rti.ResponsePnLPositionSnapshot} message ResponsePnLPositionSnapshot
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponsePnLPositionSnapshot.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponsePnLPositionSnapshot to JSON.
       * @function toJSON
       * @memberof rti.ResponsePnLPositionSnapshot
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponsePnLPositionSnapshot.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponsePnLPositionSnapshot
       * @function getTypeUrl
       * @memberof rti.ResponsePnLPositionSnapshot
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponsePnLPositionSnapshot.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponsePnLPositionSnapshot';
      };

      return ResponsePnLPositionSnapshot;
    })();

    rti.AccountPnLPositionUpdate = (function () {
      /**
       * Properties of an AccountPnLPositionUpdate.
       * @memberof rti
       * @interface IAccountPnLPositionUpdate
       * @property {number} templateId AccountPnLPositionUpdate templateId
       * @property {boolean|null} [isSnapshot] AccountPnLPositionUpdate isSnapshot
       * @property {string|null} [fcmId] AccountPnLPositionUpdate fcmId
       * @property {string|null} [ibId] AccountPnLPositionUpdate ibId
       * @property {string|null} [accountId] AccountPnLPositionUpdate accountId
       * @property {number|null} [fillBuyQty] AccountPnLPositionUpdate fillBuyQty
       * @property {number|null} [fillSellQty] AccountPnLPositionUpdate fillSellQty
       * @property {number|null} [orderBuyQty] AccountPnLPositionUpdate orderBuyQty
       * @property {number|null} [orderSellQty] AccountPnLPositionUpdate orderSellQty
       * @property {number|null} [buyQty] AccountPnLPositionUpdate buyQty
       * @property {number|null} [sellQty] AccountPnLPositionUpdate sellQty
       * @property {string|null} [openLongOptionsValue] AccountPnLPositionUpdate openLongOptionsValue
       * @property {string|null} [openShortOptionsValue] AccountPnLPositionUpdate openShortOptionsValue
       * @property {string|null} [closedOptionsValue] AccountPnLPositionUpdate closedOptionsValue
       * @property {string|null} [optionCashReserved] AccountPnLPositionUpdate optionCashReserved
       * @property {string|null} [rmsAccountCommission] AccountPnLPositionUpdate rmsAccountCommission
       * @property {string|null} [openPositionPnl] AccountPnLPositionUpdate openPositionPnl
       * @property {number|null} [openPositionQuantity] AccountPnLPositionUpdate openPositionQuantity
       * @property {string|null} [closedPositionPnl] AccountPnLPositionUpdate closedPositionPnl
       * @property {number|null} [closedPositionQuantity] AccountPnLPositionUpdate closedPositionQuantity
       * @property {number|null} [netQuantity] AccountPnLPositionUpdate netQuantity
       * @property {string|null} [excessBuyMargin] AccountPnLPositionUpdate excessBuyMargin
       * @property {string|null} [marginBalance] AccountPnLPositionUpdate marginBalance
       * @property {string|null} [minMarginBalance] AccountPnLPositionUpdate minMarginBalance
       * @property {string|null} [minAccountBalance] AccountPnLPositionUpdate minAccountBalance
       * @property {string|null} [accountBalance] AccountPnLPositionUpdate accountBalance
       * @property {string|null} [cashOnHand] AccountPnLPositionUpdate cashOnHand
       * @property {string|null} [optionClosedPnl] AccountPnLPositionUpdate optionClosedPnl
       * @property {string|null} [percentMaximumAllowableLoss] AccountPnLPositionUpdate percentMaximumAllowableLoss
       * @property {string|null} [optionOpenPnl] AccountPnLPositionUpdate optionOpenPnl
       * @property {string|null} [mtmAccount] AccountPnLPositionUpdate mtmAccount
       * @property {string|null} [availableBuyingPower] AccountPnLPositionUpdate availableBuyingPower
       * @property {string|null} [usedBuyingPower] AccountPnLPositionUpdate usedBuyingPower
       * @property {string|null} [reservedBuyingPower] AccountPnLPositionUpdate reservedBuyingPower
       * @property {string|null} [excessSellMargin] AccountPnLPositionUpdate excessSellMargin
       * @property {string|null} [dayOpenPnl] AccountPnLPositionUpdate dayOpenPnl
       * @property {string|null} [dayClosedPnl] AccountPnLPositionUpdate dayClosedPnl
       * @property {string|null} [dayPnl] AccountPnLPositionUpdate dayPnl
       * @property {string|null} [dayOpenPnlOffset] AccountPnLPositionUpdate dayOpenPnlOffset
       * @property {string|null} [dayClosedPnlOffset] AccountPnLPositionUpdate dayClosedPnlOffset
       * @property {number|null} [ssboe] AccountPnLPositionUpdate ssboe
       * @property {number|null} [usecs] AccountPnLPositionUpdate usecs
       */

      /**
       * Constructs a new AccountPnLPositionUpdate.
       * @memberof rti
       * @classdesc Represents an AccountPnLPositionUpdate.
       * @implements IAccountPnLPositionUpdate
       * @constructor
       * @param {rti.IAccountPnLPositionUpdate=} [properties] Properties to set
       */
      function AccountPnLPositionUpdate(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * AccountPnLPositionUpdate templateId.
       * @member {number} templateId
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.templateId = 0;

      /**
       * AccountPnLPositionUpdate isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.isSnapshot = false;

      /**
       * AccountPnLPositionUpdate fcmId.
       * @member {string} fcmId
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.fcmId = '';

      /**
       * AccountPnLPositionUpdate ibId.
       * @member {string} ibId
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.ibId = '';

      /**
       * AccountPnLPositionUpdate accountId.
       * @member {string} accountId
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.accountId = '';

      /**
       * AccountPnLPositionUpdate fillBuyQty.
       * @member {number} fillBuyQty
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.fillBuyQty = 0;

      /**
       * AccountPnLPositionUpdate fillSellQty.
       * @member {number} fillSellQty
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.fillSellQty = 0;

      /**
       * AccountPnLPositionUpdate orderBuyQty.
       * @member {number} orderBuyQty
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.orderBuyQty = 0;

      /**
       * AccountPnLPositionUpdate orderSellQty.
       * @member {number} orderSellQty
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.orderSellQty = 0;

      /**
       * AccountPnLPositionUpdate buyQty.
       * @member {number} buyQty
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.buyQty = 0;

      /**
       * AccountPnLPositionUpdate sellQty.
       * @member {number} sellQty
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.sellQty = 0;

      /**
       * AccountPnLPositionUpdate openLongOptionsValue.
       * @member {string} openLongOptionsValue
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.openLongOptionsValue = '';

      /**
       * AccountPnLPositionUpdate openShortOptionsValue.
       * @member {string} openShortOptionsValue
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.openShortOptionsValue = '';

      /**
       * AccountPnLPositionUpdate closedOptionsValue.
       * @member {string} closedOptionsValue
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.closedOptionsValue = '';

      /**
       * AccountPnLPositionUpdate optionCashReserved.
       * @member {string} optionCashReserved
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.optionCashReserved = '';

      /**
       * AccountPnLPositionUpdate rmsAccountCommission.
       * @member {string} rmsAccountCommission
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.rmsAccountCommission = '';

      /**
       * AccountPnLPositionUpdate openPositionPnl.
       * @member {string} openPositionPnl
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.openPositionPnl = '';

      /**
       * AccountPnLPositionUpdate openPositionQuantity.
       * @member {number} openPositionQuantity
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.openPositionQuantity = 0;

      /**
       * AccountPnLPositionUpdate closedPositionPnl.
       * @member {string} closedPositionPnl
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.closedPositionPnl = '';

      /**
       * AccountPnLPositionUpdate closedPositionQuantity.
       * @member {number} closedPositionQuantity
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.closedPositionQuantity = 0;

      /**
       * AccountPnLPositionUpdate netQuantity.
       * @member {number} netQuantity
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.netQuantity = 0;

      /**
       * AccountPnLPositionUpdate excessBuyMargin.
       * @member {string} excessBuyMargin
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.excessBuyMargin = '';

      /**
       * AccountPnLPositionUpdate marginBalance.
       * @member {string} marginBalance
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.marginBalance = '';

      /**
       * AccountPnLPositionUpdate minMarginBalance.
       * @member {string} minMarginBalance
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.minMarginBalance = '';

      /**
       * AccountPnLPositionUpdate minAccountBalance.
       * @member {string} minAccountBalance
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.minAccountBalance = '';

      /**
       * AccountPnLPositionUpdate accountBalance.
       * @member {string} accountBalance
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.accountBalance = '';

      /**
       * AccountPnLPositionUpdate cashOnHand.
       * @member {string} cashOnHand
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.cashOnHand = '';

      /**
       * AccountPnLPositionUpdate optionClosedPnl.
       * @member {string} optionClosedPnl
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.optionClosedPnl = '';

      /**
       * AccountPnLPositionUpdate percentMaximumAllowableLoss.
       * @member {string} percentMaximumAllowableLoss
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.percentMaximumAllowableLoss = '';

      /**
       * AccountPnLPositionUpdate optionOpenPnl.
       * @member {string} optionOpenPnl
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.optionOpenPnl = '';

      /**
       * AccountPnLPositionUpdate mtmAccount.
       * @member {string} mtmAccount
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.mtmAccount = '';

      /**
       * AccountPnLPositionUpdate availableBuyingPower.
       * @member {string} availableBuyingPower
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.availableBuyingPower = '';

      /**
       * AccountPnLPositionUpdate usedBuyingPower.
       * @member {string} usedBuyingPower
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.usedBuyingPower = '';

      /**
       * AccountPnLPositionUpdate reservedBuyingPower.
       * @member {string} reservedBuyingPower
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.reservedBuyingPower = '';

      /**
       * AccountPnLPositionUpdate excessSellMargin.
       * @member {string} excessSellMargin
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.excessSellMargin = '';

      /**
       * AccountPnLPositionUpdate dayOpenPnl.
       * @member {string} dayOpenPnl
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.dayOpenPnl = '';

      /**
       * AccountPnLPositionUpdate dayClosedPnl.
       * @member {string} dayClosedPnl
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.dayClosedPnl = '';

      /**
       * AccountPnLPositionUpdate dayPnl.
       * @member {string} dayPnl
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.dayPnl = '';

      /**
       * AccountPnLPositionUpdate dayOpenPnlOffset.
       * @member {string} dayOpenPnlOffset
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.dayOpenPnlOffset = '';

      /**
       * AccountPnLPositionUpdate dayClosedPnlOffset.
       * @member {string} dayClosedPnlOffset
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.dayClosedPnlOffset = '';

      /**
       * AccountPnLPositionUpdate ssboe.
       * @member {number} ssboe
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.ssboe = 0;

      /**
       * AccountPnLPositionUpdate usecs.
       * @member {number} usecs
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       */
      AccountPnLPositionUpdate.prototype.usecs = 0;

      /**
       * Creates a new AccountPnLPositionUpdate instance using the specified properties.
       * @function create
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {rti.IAccountPnLPositionUpdate=} [properties] Properties to set
       * @returns {rti.AccountPnLPositionUpdate} AccountPnLPositionUpdate instance
       */
      AccountPnLPositionUpdate.create = function create(properties) {
        return new AccountPnLPositionUpdate(properties);
      };

      /**
       * Encodes the specified AccountPnLPositionUpdate message. Does not implicitly {@link rti.AccountPnLPositionUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {rti.IAccountPnLPositionUpdate} message AccountPnLPositionUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      AccountPnLPositionUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.orderBuyQty != null &&
          Object.hasOwnProperty.call(message, 'orderBuyQty')
        )
          writer
            .uint32(/* id 154037, wireType 0 =*/ 1232296)
            .int32(message.orderBuyQty);
        if (
          message.orderSellQty != null &&
          Object.hasOwnProperty.call(message, 'orderSellQty')
        )
          writer
            .uint32(/* id 154038, wireType 0 =*/ 1232304)
            .int32(message.orderSellQty);
        if (
          message.fillBuyQty != null &&
          Object.hasOwnProperty.call(message, 'fillBuyQty')
        )
          writer
            .uint32(/* id 154041, wireType 0 =*/ 1232328)
            .int32(message.fillBuyQty);
        if (
          message.fillSellQty != null &&
          Object.hasOwnProperty.call(message, 'fillSellQty')
        )
          writer
            .uint32(/* id 154042, wireType 0 =*/ 1232336)
            .int32(message.fillSellQty);
        if (
          message.buyQty != null &&
          Object.hasOwnProperty.call(message, 'buyQty')
        )
          writer
            .uint32(/* id 154260, wireType 0 =*/ 1234080)
            .int32(message.buyQty);
        if (
          message.sellQty != null &&
          Object.hasOwnProperty.call(message, 'sellQty')
        )
          writer
            .uint32(/* id 154261, wireType 0 =*/ 1234088)
            .int32(message.sellQty);
        if (
          message.mtmAccount != null &&
          Object.hasOwnProperty.call(message, 'mtmAccount')
        )
          writer
            .uint32(/* id 154262, wireType 2 =*/ 1234098)
            .string(message.mtmAccount);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.openPositionPnl != null &&
          Object.hasOwnProperty.call(message, 'openPositionPnl')
        )
          writer
            .uint32(/* id 156961, wireType 2 =*/ 1255690)
            .string(message.openPositionPnl);
        if (
          message.openPositionQuantity != null &&
          Object.hasOwnProperty.call(message, 'openPositionQuantity')
        )
          writer
            .uint32(/* id 156962, wireType 0 =*/ 1255696)
            .int32(message.openPositionQuantity);
        if (
          message.closedPositionPnl != null &&
          Object.hasOwnProperty.call(message, 'closedPositionPnl')
        )
          writer
            .uint32(/* id 156963, wireType 2 =*/ 1255706)
            .string(message.closedPositionPnl);
        if (
          message.closedPositionQuantity != null &&
          Object.hasOwnProperty.call(message, 'closedPositionQuantity')
        )
          writer
            .uint32(/* id 156964, wireType 0 =*/ 1255712)
            .int32(message.closedPositionQuantity);
        if (
          message.percentMaximumAllowableLoss != null &&
          Object.hasOwnProperty.call(message, 'percentMaximumAllowableLoss')
        )
          writer
            .uint32(/* id 156965, wireType 2 =*/ 1255722)
            .string(message.percentMaximumAllowableLoss);
        if (
          message.netQuantity != null &&
          Object.hasOwnProperty.call(message, 'netQuantity')
        )
          writer
            .uint32(/* id 156967, wireType 0 =*/ 1255736)
            .int32(message.netQuantity);
        if (
          message.minAccountBalance != null &&
          Object.hasOwnProperty.call(message, 'minAccountBalance')
        )
          writer
            .uint32(/* id 156968, wireType 2 =*/ 1255746)
            .string(message.minAccountBalance);
        if (
          message.accountBalance != null &&
          Object.hasOwnProperty.call(message, 'accountBalance')
        )
          writer
            .uint32(/* id 156970, wireType 2 =*/ 1255762)
            .string(message.accountBalance);
        if (
          message.cashOnHand != null &&
          Object.hasOwnProperty.call(message, 'cashOnHand')
        )
          writer
            .uint32(/* id 156971, wireType 2 =*/ 1255770)
            .string(message.cashOnHand);
        if (
          message.minMarginBalance != null &&
          Object.hasOwnProperty.call(message, 'minMarginBalance')
        )
          writer
            .uint32(/* id 156976, wireType 2 =*/ 1255810)
            .string(message.minMarginBalance);
        if (
          message.marginBalance != null &&
          Object.hasOwnProperty.call(message, 'marginBalance')
        )
          writer
            .uint32(/* id 156977, wireType 2 =*/ 1255818)
            .string(message.marginBalance);
        if (
          message.excessBuyMargin != null &&
          Object.hasOwnProperty.call(message, 'excessBuyMargin')
        )
          writer
            .uint32(/* id 156991, wireType 2 =*/ 1255930)
            .string(message.excessBuyMargin);
        if (
          message.excessSellMargin != null &&
          Object.hasOwnProperty.call(message, 'excessSellMargin')
        )
          writer
            .uint32(/* id 156992, wireType 2 =*/ 1255938)
            .string(message.excessSellMargin);
        if (
          message.reservedBuyingPower != null &&
          Object.hasOwnProperty.call(message, 'reservedBuyingPower')
        )
          writer
            .uint32(/* id 157013, wireType 2 =*/ 1256106)
            .string(message.reservedBuyingPower);
        if (
          message.usedBuyingPower != null &&
          Object.hasOwnProperty.call(message, 'usedBuyingPower')
        )
          writer
            .uint32(/* id 157014, wireType 2 =*/ 1256114)
            .string(message.usedBuyingPower);
        if (
          message.availableBuyingPower != null &&
          Object.hasOwnProperty.call(message, 'availableBuyingPower')
        )
          writer
            .uint32(/* id 157015, wireType 2 =*/ 1256122)
            .string(message.availableBuyingPower);
        if (
          message.openLongOptionsValue != null &&
          Object.hasOwnProperty.call(message, 'openLongOptionsValue')
        )
          writer
            .uint32(/* id 157105, wireType 2 =*/ 1256842)
            .string(message.openLongOptionsValue);
        if (
          message.openShortOptionsValue != null &&
          Object.hasOwnProperty.call(message, 'openShortOptionsValue')
        )
          writer
            .uint32(/* id 157106, wireType 2 =*/ 1256850)
            .string(message.openShortOptionsValue);
        if (
          message.closedOptionsValue != null &&
          Object.hasOwnProperty.call(message, 'closedOptionsValue')
        )
          writer
            .uint32(/* id 157107, wireType 2 =*/ 1256858)
            .string(message.closedOptionsValue);
        if (
          message.optionCashReserved != null &&
          Object.hasOwnProperty.call(message, 'optionCashReserved')
        )
          writer
            .uint32(/* id 157111, wireType 2 =*/ 1256890)
            .string(message.optionCashReserved);
        if (
          message.rmsAccountCommission != null &&
          Object.hasOwnProperty.call(message, 'rmsAccountCommission')
        )
          writer
            .uint32(/* id 157113, wireType 2 =*/ 1256906)
            .string(message.rmsAccountCommission);
        if (
          message.optionOpenPnl != null &&
          Object.hasOwnProperty.call(message, 'optionOpenPnl')
        )
          writer
            .uint32(/* id 157117, wireType 2 =*/ 1256938)
            .string(message.optionOpenPnl);
        if (
          message.optionClosedPnl != null &&
          Object.hasOwnProperty.call(message, 'optionClosedPnl')
        )
          writer
            .uint32(/* id 157118, wireType 2 =*/ 1256946)
            .string(message.optionClosedPnl);
        if (
          message.dayOpenPnl != null &&
          Object.hasOwnProperty.call(message, 'dayOpenPnl')
        )
          writer
            .uint32(/* id 157954, wireType 2 =*/ 1263634)
            .string(message.dayOpenPnl);
        if (
          message.dayClosedPnl != null &&
          Object.hasOwnProperty.call(message, 'dayClosedPnl')
        )
          writer
            .uint32(/* id 157955, wireType 2 =*/ 1263642)
            .string(message.dayClosedPnl);
        if (
          message.dayPnl != null &&
          Object.hasOwnProperty.call(message, 'dayPnl')
        )
          writer
            .uint32(/* id 157956, wireType 2 =*/ 1263650)
            .string(message.dayPnl);
        if (
          message.dayOpenPnlOffset != null &&
          Object.hasOwnProperty.call(message, 'dayOpenPnlOffset')
        )
          writer
            .uint32(/* id 157957, wireType 2 =*/ 1263658)
            .string(message.dayOpenPnlOffset);
        if (
          message.dayClosedPnlOffset != null &&
          Object.hasOwnProperty.call(message, 'dayClosedPnlOffset')
        )
          writer
            .uint32(/* id 157958, wireType 2 =*/ 1263666)
            .string(message.dayClosedPnlOffset);
        return writer;
      };

      /**
       * Encodes the specified AccountPnLPositionUpdate message, length delimited. Does not implicitly {@link rti.AccountPnLPositionUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {rti.IAccountPnLPositionUpdate} message AccountPnLPositionUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      AccountPnLPositionUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an AccountPnLPositionUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.AccountPnLPositionUpdate} AccountPnLPositionUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      AccountPnLPositionUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.AccountPnLPositionUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 154041:
              message.fillBuyQty = reader.int32();
              break;
            case 154042:
              message.fillSellQty = reader.int32();
              break;
            case 154037:
              message.orderBuyQty = reader.int32();
              break;
            case 154038:
              message.orderSellQty = reader.int32();
              break;
            case 154260:
              message.buyQty = reader.int32();
              break;
            case 154261:
              message.sellQty = reader.int32();
              break;
            case 157105:
              message.openLongOptionsValue = reader.string();
              break;
            case 157106:
              message.openShortOptionsValue = reader.string();
              break;
            case 157107:
              message.closedOptionsValue = reader.string();
              break;
            case 157111:
              message.optionCashReserved = reader.string();
              break;
            case 157113:
              message.rmsAccountCommission = reader.string();
              break;
            case 156961:
              message.openPositionPnl = reader.string();
              break;
            case 156962:
              message.openPositionQuantity = reader.int32();
              break;
            case 156963:
              message.closedPositionPnl = reader.string();
              break;
            case 156964:
              message.closedPositionQuantity = reader.int32();
              break;
            case 156967:
              message.netQuantity = reader.int32();
              break;
            case 156991:
              message.excessBuyMargin = reader.string();
              break;
            case 156977:
              message.marginBalance = reader.string();
              break;
            case 156976:
              message.minMarginBalance = reader.string();
              break;
            case 156968:
              message.minAccountBalance = reader.string();
              break;
            case 156970:
              message.accountBalance = reader.string();
              break;
            case 156971:
              message.cashOnHand = reader.string();
              break;
            case 157118:
              message.optionClosedPnl = reader.string();
              break;
            case 156965:
              message.percentMaximumAllowableLoss = reader.string();
              break;
            case 157117:
              message.optionOpenPnl = reader.string();
              break;
            case 154262:
              message.mtmAccount = reader.string();
              break;
            case 157015:
              message.availableBuyingPower = reader.string();
              break;
            case 157014:
              message.usedBuyingPower = reader.string();
              break;
            case 157013:
              message.reservedBuyingPower = reader.string();
              break;
            case 156992:
              message.excessSellMargin = reader.string();
              break;
            case 157954:
              message.dayOpenPnl = reader.string();
              break;
            case 157955:
              message.dayClosedPnl = reader.string();
              break;
            case 157956:
              message.dayPnl = reader.string();
              break;
            case 157957:
              message.dayOpenPnlOffset = reader.string();
              break;
            case 157958:
              message.dayClosedPnlOffset = reader.string();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an AccountPnLPositionUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.AccountPnLPositionUpdate} AccountPnLPositionUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      AccountPnLPositionUpdate.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an AccountPnLPositionUpdate message.
       * @function verify
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      AccountPnLPositionUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.fillBuyQty != null && message.hasOwnProperty('fillBuyQty'))
          if (!$util.isInteger(message.fillBuyQty))
            return 'fillBuyQty: integer expected';
        if (
          message.fillSellQty != null &&
          message.hasOwnProperty('fillSellQty')
        )
          if (!$util.isInteger(message.fillSellQty))
            return 'fillSellQty: integer expected';
        if (
          message.orderBuyQty != null &&
          message.hasOwnProperty('orderBuyQty')
        )
          if (!$util.isInteger(message.orderBuyQty))
            return 'orderBuyQty: integer expected';
        if (
          message.orderSellQty != null &&
          message.hasOwnProperty('orderSellQty')
        )
          if (!$util.isInteger(message.orderSellQty))
            return 'orderSellQty: integer expected';
        if (message.buyQty != null && message.hasOwnProperty('buyQty'))
          if (!$util.isInteger(message.buyQty))
            return 'buyQty: integer expected';
        if (message.sellQty != null && message.hasOwnProperty('sellQty'))
          if (!$util.isInteger(message.sellQty))
            return 'sellQty: integer expected';
        if (
          message.openLongOptionsValue != null &&
          message.hasOwnProperty('openLongOptionsValue')
        )
          if (!$util.isString(message.openLongOptionsValue))
            return 'openLongOptionsValue: string expected';
        if (
          message.openShortOptionsValue != null &&
          message.hasOwnProperty('openShortOptionsValue')
        )
          if (!$util.isString(message.openShortOptionsValue))
            return 'openShortOptionsValue: string expected';
        if (
          message.closedOptionsValue != null &&
          message.hasOwnProperty('closedOptionsValue')
        )
          if (!$util.isString(message.closedOptionsValue))
            return 'closedOptionsValue: string expected';
        if (
          message.optionCashReserved != null &&
          message.hasOwnProperty('optionCashReserved')
        )
          if (!$util.isString(message.optionCashReserved))
            return 'optionCashReserved: string expected';
        if (
          message.rmsAccountCommission != null &&
          message.hasOwnProperty('rmsAccountCommission')
        )
          if (!$util.isString(message.rmsAccountCommission))
            return 'rmsAccountCommission: string expected';
        if (
          message.openPositionPnl != null &&
          message.hasOwnProperty('openPositionPnl')
        )
          if (!$util.isString(message.openPositionPnl))
            return 'openPositionPnl: string expected';
        if (
          message.openPositionQuantity != null &&
          message.hasOwnProperty('openPositionQuantity')
        )
          if (!$util.isInteger(message.openPositionQuantity))
            return 'openPositionQuantity: integer expected';
        if (
          message.closedPositionPnl != null &&
          message.hasOwnProperty('closedPositionPnl')
        )
          if (!$util.isString(message.closedPositionPnl))
            return 'closedPositionPnl: string expected';
        if (
          message.closedPositionQuantity != null &&
          message.hasOwnProperty('closedPositionQuantity')
        )
          if (!$util.isInteger(message.closedPositionQuantity))
            return 'closedPositionQuantity: integer expected';
        if (
          message.netQuantity != null &&
          message.hasOwnProperty('netQuantity')
        )
          if (!$util.isInteger(message.netQuantity))
            return 'netQuantity: integer expected';
        if (
          message.excessBuyMargin != null &&
          message.hasOwnProperty('excessBuyMargin')
        )
          if (!$util.isString(message.excessBuyMargin))
            return 'excessBuyMargin: string expected';
        if (
          message.marginBalance != null &&
          message.hasOwnProperty('marginBalance')
        )
          if (!$util.isString(message.marginBalance))
            return 'marginBalance: string expected';
        if (
          message.minMarginBalance != null &&
          message.hasOwnProperty('minMarginBalance')
        )
          if (!$util.isString(message.minMarginBalance))
            return 'minMarginBalance: string expected';
        if (
          message.minAccountBalance != null &&
          message.hasOwnProperty('minAccountBalance')
        )
          if (!$util.isString(message.minAccountBalance))
            return 'minAccountBalance: string expected';
        if (
          message.accountBalance != null &&
          message.hasOwnProperty('accountBalance')
        )
          if (!$util.isString(message.accountBalance))
            return 'accountBalance: string expected';
        if (message.cashOnHand != null && message.hasOwnProperty('cashOnHand'))
          if (!$util.isString(message.cashOnHand))
            return 'cashOnHand: string expected';
        if (
          message.optionClosedPnl != null &&
          message.hasOwnProperty('optionClosedPnl')
        )
          if (!$util.isString(message.optionClosedPnl))
            return 'optionClosedPnl: string expected';
        if (
          message.percentMaximumAllowableLoss != null &&
          message.hasOwnProperty('percentMaximumAllowableLoss')
        )
          if (!$util.isString(message.percentMaximumAllowableLoss))
            return 'percentMaximumAllowableLoss: string expected';
        if (
          message.optionOpenPnl != null &&
          message.hasOwnProperty('optionOpenPnl')
        )
          if (!$util.isString(message.optionOpenPnl))
            return 'optionOpenPnl: string expected';
        if (message.mtmAccount != null && message.hasOwnProperty('mtmAccount'))
          if (!$util.isString(message.mtmAccount))
            return 'mtmAccount: string expected';
        if (
          message.availableBuyingPower != null &&
          message.hasOwnProperty('availableBuyingPower')
        )
          if (!$util.isString(message.availableBuyingPower))
            return 'availableBuyingPower: string expected';
        if (
          message.usedBuyingPower != null &&
          message.hasOwnProperty('usedBuyingPower')
        )
          if (!$util.isString(message.usedBuyingPower))
            return 'usedBuyingPower: string expected';
        if (
          message.reservedBuyingPower != null &&
          message.hasOwnProperty('reservedBuyingPower')
        )
          if (!$util.isString(message.reservedBuyingPower))
            return 'reservedBuyingPower: string expected';
        if (
          message.excessSellMargin != null &&
          message.hasOwnProperty('excessSellMargin')
        )
          if (!$util.isString(message.excessSellMargin))
            return 'excessSellMargin: string expected';
        if (message.dayOpenPnl != null && message.hasOwnProperty('dayOpenPnl'))
          if (!$util.isString(message.dayOpenPnl))
            return 'dayOpenPnl: string expected';
        if (
          message.dayClosedPnl != null &&
          message.hasOwnProperty('dayClosedPnl')
        )
          if (!$util.isString(message.dayClosedPnl))
            return 'dayClosedPnl: string expected';
        if (message.dayPnl != null && message.hasOwnProperty('dayPnl'))
          if (!$util.isString(message.dayPnl)) return 'dayPnl: string expected';
        if (
          message.dayOpenPnlOffset != null &&
          message.hasOwnProperty('dayOpenPnlOffset')
        )
          if (!$util.isString(message.dayOpenPnlOffset))
            return 'dayOpenPnlOffset: string expected';
        if (
          message.dayClosedPnlOffset != null &&
          message.hasOwnProperty('dayClosedPnlOffset')
        )
          if (!$util.isString(message.dayClosedPnlOffset))
            return 'dayClosedPnlOffset: string expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates an AccountPnLPositionUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.AccountPnLPositionUpdate} AccountPnLPositionUpdate
       */
      AccountPnLPositionUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.AccountPnLPositionUpdate) return object;
        var message = new $root.rti.AccountPnLPositionUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.fillBuyQty != null)
          message.fillBuyQty = object.fillBuyQty | 0;
        if (object.fillSellQty != null)
          message.fillSellQty = object.fillSellQty | 0;
        if (object.orderBuyQty != null)
          message.orderBuyQty = object.orderBuyQty | 0;
        if (object.orderSellQty != null)
          message.orderSellQty = object.orderSellQty | 0;
        if (object.buyQty != null) message.buyQty = object.buyQty | 0;
        if (object.sellQty != null) message.sellQty = object.sellQty | 0;
        if (object.openLongOptionsValue != null)
          message.openLongOptionsValue = String(object.openLongOptionsValue);
        if (object.openShortOptionsValue != null)
          message.openShortOptionsValue = String(object.openShortOptionsValue);
        if (object.closedOptionsValue != null)
          message.closedOptionsValue = String(object.closedOptionsValue);
        if (object.optionCashReserved != null)
          message.optionCashReserved = String(object.optionCashReserved);
        if (object.rmsAccountCommission != null)
          message.rmsAccountCommission = String(object.rmsAccountCommission);
        if (object.openPositionPnl != null)
          message.openPositionPnl = String(object.openPositionPnl);
        if (object.openPositionQuantity != null)
          message.openPositionQuantity = object.openPositionQuantity | 0;
        if (object.closedPositionPnl != null)
          message.closedPositionPnl = String(object.closedPositionPnl);
        if (object.closedPositionQuantity != null)
          message.closedPositionQuantity = object.closedPositionQuantity | 0;
        if (object.netQuantity != null)
          message.netQuantity = object.netQuantity | 0;
        if (object.excessBuyMargin != null)
          message.excessBuyMargin = String(object.excessBuyMargin);
        if (object.marginBalance != null)
          message.marginBalance = String(object.marginBalance);
        if (object.minMarginBalance != null)
          message.minMarginBalance = String(object.minMarginBalance);
        if (object.minAccountBalance != null)
          message.minAccountBalance = String(object.minAccountBalance);
        if (object.accountBalance != null)
          message.accountBalance = String(object.accountBalance);
        if (object.cashOnHand != null)
          message.cashOnHand = String(object.cashOnHand);
        if (object.optionClosedPnl != null)
          message.optionClosedPnl = String(object.optionClosedPnl);
        if (object.percentMaximumAllowableLoss != null)
          message.percentMaximumAllowableLoss = String(
            object.percentMaximumAllowableLoss,
          );
        if (object.optionOpenPnl != null)
          message.optionOpenPnl = String(object.optionOpenPnl);
        if (object.mtmAccount != null)
          message.mtmAccount = String(object.mtmAccount);
        if (object.availableBuyingPower != null)
          message.availableBuyingPower = String(object.availableBuyingPower);
        if (object.usedBuyingPower != null)
          message.usedBuyingPower = String(object.usedBuyingPower);
        if (object.reservedBuyingPower != null)
          message.reservedBuyingPower = String(object.reservedBuyingPower);
        if (object.excessSellMargin != null)
          message.excessSellMargin = String(object.excessSellMargin);
        if (object.dayOpenPnl != null)
          message.dayOpenPnl = String(object.dayOpenPnl);
        if (object.dayClosedPnl != null)
          message.dayClosedPnl = String(object.dayClosedPnl);
        if (object.dayPnl != null) message.dayPnl = String(object.dayPnl);
        if (object.dayOpenPnlOffset != null)
          message.dayOpenPnlOffset = String(object.dayOpenPnlOffset);
        if (object.dayClosedPnlOffset != null)
          message.dayClosedPnlOffset = String(object.dayClosedPnlOffset);
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from an AccountPnLPositionUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {rti.AccountPnLPositionUpdate} message AccountPnLPositionUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      AccountPnLPositionUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.isSnapshot = false;
          object.ssboe = 0;
          object.usecs = 0;
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.orderBuyQty = 0;
          object.orderSellQty = 0;
          object.fillBuyQty = 0;
          object.fillSellQty = 0;
          object.buyQty = 0;
          object.sellQty = 0;
          object.mtmAccount = '';
          object.templateId = 0;
          object.openPositionPnl = '';
          object.openPositionQuantity = 0;
          object.closedPositionPnl = '';
          object.closedPositionQuantity = 0;
          object.percentMaximumAllowableLoss = '';
          object.netQuantity = 0;
          object.minAccountBalance = '';
          object.accountBalance = '';
          object.cashOnHand = '';
          object.minMarginBalance = '';
          object.marginBalance = '';
          object.excessBuyMargin = '';
          object.excessSellMargin = '';
          object.reservedBuyingPower = '';
          object.usedBuyingPower = '';
          object.availableBuyingPower = '';
          object.openLongOptionsValue = '';
          object.openShortOptionsValue = '';
          object.closedOptionsValue = '';
          object.optionCashReserved = '';
          object.rmsAccountCommission = '';
          object.optionOpenPnl = '';
          object.optionClosedPnl = '';
          object.dayOpenPnl = '';
          object.dayClosedPnl = '';
          object.dayPnl = '';
          object.dayOpenPnlOffset = '';
          object.dayClosedPnlOffset = '';
        }
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (
          message.orderBuyQty != null &&
          message.hasOwnProperty('orderBuyQty')
        )
          object.orderBuyQty = message.orderBuyQty;
        if (
          message.orderSellQty != null &&
          message.hasOwnProperty('orderSellQty')
        )
          object.orderSellQty = message.orderSellQty;
        if (message.fillBuyQty != null && message.hasOwnProperty('fillBuyQty'))
          object.fillBuyQty = message.fillBuyQty;
        if (
          message.fillSellQty != null &&
          message.hasOwnProperty('fillSellQty')
        )
          object.fillSellQty = message.fillSellQty;
        if (message.buyQty != null && message.hasOwnProperty('buyQty'))
          object.buyQty = message.buyQty;
        if (message.sellQty != null && message.hasOwnProperty('sellQty'))
          object.sellQty = message.sellQty;
        if (message.mtmAccount != null && message.hasOwnProperty('mtmAccount'))
          object.mtmAccount = message.mtmAccount;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.openPositionPnl != null &&
          message.hasOwnProperty('openPositionPnl')
        )
          object.openPositionPnl = message.openPositionPnl;
        if (
          message.openPositionQuantity != null &&
          message.hasOwnProperty('openPositionQuantity')
        )
          object.openPositionQuantity = message.openPositionQuantity;
        if (
          message.closedPositionPnl != null &&
          message.hasOwnProperty('closedPositionPnl')
        )
          object.closedPositionPnl = message.closedPositionPnl;
        if (
          message.closedPositionQuantity != null &&
          message.hasOwnProperty('closedPositionQuantity')
        )
          object.closedPositionQuantity = message.closedPositionQuantity;
        if (
          message.percentMaximumAllowableLoss != null &&
          message.hasOwnProperty('percentMaximumAllowableLoss')
        )
          object.percentMaximumAllowableLoss =
            message.percentMaximumAllowableLoss;
        if (
          message.netQuantity != null &&
          message.hasOwnProperty('netQuantity')
        )
          object.netQuantity = message.netQuantity;
        if (
          message.minAccountBalance != null &&
          message.hasOwnProperty('minAccountBalance')
        )
          object.minAccountBalance = message.minAccountBalance;
        if (
          message.accountBalance != null &&
          message.hasOwnProperty('accountBalance')
        )
          object.accountBalance = message.accountBalance;
        if (message.cashOnHand != null && message.hasOwnProperty('cashOnHand'))
          object.cashOnHand = message.cashOnHand;
        if (
          message.minMarginBalance != null &&
          message.hasOwnProperty('minMarginBalance')
        )
          object.minMarginBalance = message.minMarginBalance;
        if (
          message.marginBalance != null &&
          message.hasOwnProperty('marginBalance')
        )
          object.marginBalance = message.marginBalance;
        if (
          message.excessBuyMargin != null &&
          message.hasOwnProperty('excessBuyMargin')
        )
          object.excessBuyMargin = message.excessBuyMargin;
        if (
          message.excessSellMargin != null &&
          message.hasOwnProperty('excessSellMargin')
        )
          object.excessSellMargin = message.excessSellMargin;
        if (
          message.reservedBuyingPower != null &&
          message.hasOwnProperty('reservedBuyingPower')
        )
          object.reservedBuyingPower = message.reservedBuyingPower;
        if (
          message.usedBuyingPower != null &&
          message.hasOwnProperty('usedBuyingPower')
        )
          object.usedBuyingPower = message.usedBuyingPower;
        if (
          message.availableBuyingPower != null &&
          message.hasOwnProperty('availableBuyingPower')
        )
          object.availableBuyingPower = message.availableBuyingPower;
        if (
          message.openLongOptionsValue != null &&
          message.hasOwnProperty('openLongOptionsValue')
        )
          object.openLongOptionsValue = message.openLongOptionsValue;
        if (
          message.openShortOptionsValue != null &&
          message.hasOwnProperty('openShortOptionsValue')
        )
          object.openShortOptionsValue = message.openShortOptionsValue;
        if (
          message.closedOptionsValue != null &&
          message.hasOwnProperty('closedOptionsValue')
        )
          object.closedOptionsValue = message.closedOptionsValue;
        if (
          message.optionCashReserved != null &&
          message.hasOwnProperty('optionCashReserved')
        )
          object.optionCashReserved = message.optionCashReserved;
        if (
          message.rmsAccountCommission != null &&
          message.hasOwnProperty('rmsAccountCommission')
        )
          object.rmsAccountCommission = message.rmsAccountCommission;
        if (
          message.optionOpenPnl != null &&
          message.hasOwnProperty('optionOpenPnl')
        )
          object.optionOpenPnl = message.optionOpenPnl;
        if (
          message.optionClosedPnl != null &&
          message.hasOwnProperty('optionClosedPnl')
        )
          object.optionClosedPnl = message.optionClosedPnl;
        if (message.dayOpenPnl != null && message.hasOwnProperty('dayOpenPnl'))
          object.dayOpenPnl = message.dayOpenPnl;
        if (
          message.dayClosedPnl != null &&
          message.hasOwnProperty('dayClosedPnl')
        )
          object.dayClosedPnl = message.dayClosedPnl;
        if (message.dayPnl != null && message.hasOwnProperty('dayPnl'))
          object.dayPnl = message.dayPnl;
        if (
          message.dayOpenPnlOffset != null &&
          message.hasOwnProperty('dayOpenPnlOffset')
        )
          object.dayOpenPnlOffset = message.dayOpenPnlOffset;
        if (
          message.dayClosedPnlOffset != null &&
          message.hasOwnProperty('dayClosedPnlOffset')
        )
          object.dayClosedPnlOffset = message.dayClosedPnlOffset;
        return object;
      };

      /**
       * Converts this AccountPnLPositionUpdate to JSON.
       * @function toJSON
       * @memberof rti.AccountPnLPositionUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      AccountPnLPositionUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for AccountPnLPositionUpdate
       * @function getTypeUrl
       * @memberof rti.AccountPnLPositionUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      AccountPnLPositionUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.AccountPnLPositionUpdate';
      };

      return AccountPnLPositionUpdate;
    })();

    rti.InstrumentPnLPositionUpdate = (function () {
      /**
       * Properties of an InstrumentPnLPositionUpdate.
       * @memberof rti
       * @interface IInstrumentPnLPositionUpdate
       * @property {number} templateId InstrumentPnLPositionUpdate templateId
       * @property {boolean|null} [isSnapshot] InstrumentPnLPositionUpdate isSnapshot
       * @property {string|null} [fcmId] InstrumentPnLPositionUpdate fcmId
       * @property {string|null} [ibId] InstrumentPnLPositionUpdate ibId
       * @property {string|null} [accountId] InstrumentPnLPositionUpdate accountId
       * @property {string|null} [symbol] InstrumentPnLPositionUpdate symbol
       * @property {string|null} [exchange] InstrumentPnLPositionUpdate exchange
       * @property {string|null} [productCode] InstrumentPnLPositionUpdate productCode
       * @property {string|null} [instrumentType] InstrumentPnLPositionUpdate instrumentType
       * @property {number|null} [fillBuyQty] InstrumentPnLPositionUpdate fillBuyQty
       * @property {number|null} [fillSellQty] InstrumentPnLPositionUpdate fillSellQty
       * @property {number|null} [orderBuyQty] InstrumentPnLPositionUpdate orderBuyQty
       * @property {number|null} [orderSellQty] InstrumentPnLPositionUpdate orderSellQty
       * @property {number|null} [buyQty] InstrumentPnLPositionUpdate buyQty
       * @property {number|null} [sellQty] InstrumentPnLPositionUpdate sellQty
       * @property {number|null} [avgOpenFillPrice] InstrumentPnLPositionUpdate avgOpenFillPrice
       * @property {number|null} [dayOpenPnl] InstrumentPnLPositionUpdate dayOpenPnl
       * @property {number|null} [dayClosedPnl] InstrumentPnLPositionUpdate dayClosedPnl
       * @property {number|null} [dayPnl] InstrumentPnLPositionUpdate dayPnl
       * @property {number|null} [dayOpenPnlOffset] InstrumentPnLPositionUpdate dayOpenPnlOffset
       * @property {number|null} [dayClosedPnlOffset] InstrumentPnLPositionUpdate dayClosedPnlOffset
       * @property {string|null} [mtmSecurity] InstrumentPnLPositionUpdate mtmSecurity
       * @property {string|null} [openLongOptionsValue] InstrumentPnLPositionUpdate openLongOptionsValue
       * @property {string|null} [openShortOptionsValue] InstrumentPnLPositionUpdate openShortOptionsValue
       * @property {string|null} [closedOptionsValue] InstrumentPnLPositionUpdate closedOptionsValue
       * @property {string|null} [optionCashReserved] InstrumentPnLPositionUpdate optionCashReserved
       * @property {string|null} [openPositionPnl] InstrumentPnLPositionUpdate openPositionPnl
       * @property {number|null} [openPositionQuantity] InstrumentPnLPositionUpdate openPositionQuantity
       * @property {string|null} [closedPositionPnl] InstrumentPnLPositionUpdate closedPositionPnl
       * @property {number|null} [closedPositionQuantity] InstrumentPnLPositionUpdate closedPositionQuantity
       * @property {number|null} [netQuantity] InstrumentPnLPositionUpdate netQuantity
       * @property {number|null} [ssboe] InstrumentPnLPositionUpdate ssboe
       * @property {number|null} [usecs] InstrumentPnLPositionUpdate usecs
       */

      /**
       * Constructs a new InstrumentPnLPositionUpdate.
       * @memberof rti
       * @classdesc Represents an InstrumentPnLPositionUpdate.
       * @implements IInstrumentPnLPositionUpdate
       * @constructor
       * @param {rti.IInstrumentPnLPositionUpdate=} [properties] Properties to set
       */
      function InstrumentPnLPositionUpdate(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * InstrumentPnLPositionUpdate templateId.
       * @member {number} templateId
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.templateId = 0;

      /**
       * InstrumentPnLPositionUpdate isSnapshot.
       * @member {boolean} isSnapshot
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.isSnapshot = false;

      /**
       * InstrumentPnLPositionUpdate fcmId.
       * @member {string} fcmId
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.fcmId = '';

      /**
       * InstrumentPnLPositionUpdate ibId.
       * @member {string} ibId
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.ibId = '';

      /**
       * InstrumentPnLPositionUpdate accountId.
       * @member {string} accountId
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.accountId = '';

      /**
       * InstrumentPnLPositionUpdate symbol.
       * @member {string} symbol
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.symbol = '';

      /**
       * InstrumentPnLPositionUpdate exchange.
       * @member {string} exchange
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.exchange = '';

      /**
       * InstrumentPnLPositionUpdate productCode.
       * @member {string} productCode
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.productCode = '';

      /**
       * InstrumentPnLPositionUpdate instrumentType.
       * @member {string} instrumentType
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.instrumentType = '';

      /**
       * InstrumentPnLPositionUpdate fillBuyQty.
       * @member {number} fillBuyQty
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.fillBuyQty = 0;

      /**
       * InstrumentPnLPositionUpdate fillSellQty.
       * @member {number} fillSellQty
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.fillSellQty = 0;

      /**
       * InstrumentPnLPositionUpdate orderBuyQty.
       * @member {number} orderBuyQty
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.orderBuyQty = 0;

      /**
       * InstrumentPnLPositionUpdate orderSellQty.
       * @member {number} orderSellQty
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.orderSellQty = 0;

      /**
       * InstrumentPnLPositionUpdate buyQty.
       * @member {number} buyQty
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.buyQty = 0;

      /**
       * InstrumentPnLPositionUpdate sellQty.
       * @member {number} sellQty
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.sellQty = 0;

      /**
       * InstrumentPnLPositionUpdate avgOpenFillPrice.
       * @member {number} avgOpenFillPrice
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.avgOpenFillPrice = 0;

      /**
       * InstrumentPnLPositionUpdate dayOpenPnl.
       * @member {number} dayOpenPnl
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.dayOpenPnl = 0;

      /**
       * InstrumentPnLPositionUpdate dayClosedPnl.
       * @member {number} dayClosedPnl
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.dayClosedPnl = 0;

      /**
       * InstrumentPnLPositionUpdate dayPnl.
       * @member {number} dayPnl
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.dayPnl = 0;

      /**
       * InstrumentPnLPositionUpdate dayOpenPnlOffset.
       * @member {number} dayOpenPnlOffset
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.dayOpenPnlOffset = 0;

      /**
       * InstrumentPnLPositionUpdate dayClosedPnlOffset.
       * @member {number} dayClosedPnlOffset
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.dayClosedPnlOffset = 0;

      /**
       * InstrumentPnLPositionUpdate mtmSecurity.
       * @member {string} mtmSecurity
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.mtmSecurity = '';

      /**
       * InstrumentPnLPositionUpdate openLongOptionsValue.
       * @member {string} openLongOptionsValue
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.openLongOptionsValue = '';

      /**
       * InstrumentPnLPositionUpdate openShortOptionsValue.
       * @member {string} openShortOptionsValue
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.openShortOptionsValue = '';

      /**
       * InstrumentPnLPositionUpdate closedOptionsValue.
       * @member {string} closedOptionsValue
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.closedOptionsValue = '';

      /**
       * InstrumentPnLPositionUpdate optionCashReserved.
       * @member {string} optionCashReserved
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.optionCashReserved = '';

      /**
       * InstrumentPnLPositionUpdate openPositionPnl.
       * @member {string} openPositionPnl
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.openPositionPnl = '';

      /**
       * InstrumentPnLPositionUpdate openPositionQuantity.
       * @member {number} openPositionQuantity
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.openPositionQuantity = 0;

      /**
       * InstrumentPnLPositionUpdate closedPositionPnl.
       * @member {string} closedPositionPnl
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.closedPositionPnl = '';

      /**
       * InstrumentPnLPositionUpdate closedPositionQuantity.
       * @member {number} closedPositionQuantity
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.closedPositionQuantity = 0;

      /**
       * InstrumentPnLPositionUpdate netQuantity.
       * @member {number} netQuantity
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.netQuantity = 0;

      /**
       * InstrumentPnLPositionUpdate ssboe.
       * @member {number} ssboe
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.ssboe = 0;

      /**
       * InstrumentPnLPositionUpdate usecs.
       * @member {number} usecs
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       */
      InstrumentPnLPositionUpdate.prototype.usecs = 0;

      /**
       * Creates a new InstrumentPnLPositionUpdate instance using the specified properties.
       * @function create
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {rti.IInstrumentPnLPositionUpdate=} [properties] Properties to set
       * @returns {rti.InstrumentPnLPositionUpdate} InstrumentPnLPositionUpdate instance
       */
      InstrumentPnLPositionUpdate.create = function create(properties) {
        return new InstrumentPnLPositionUpdate(properties);
      };

      /**
       * Encodes the specified InstrumentPnLPositionUpdate message. Does not implicitly {@link rti.InstrumentPnLPositionUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {rti.IInstrumentPnLPositionUpdate} message InstrumentPnLPositionUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      InstrumentPnLPositionUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.productCode != null &&
          Object.hasOwnProperty.call(message, 'productCode')
        )
          writer
            .uint32(/* id 100749, wireType 2 =*/ 805994)
            .string(message.productCode);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.instrumentType != null &&
          Object.hasOwnProperty.call(message, 'instrumentType')
        )
          writer
            .uint32(/* id 110116, wireType 2 =*/ 880930)
            .string(message.instrumentType);
        if (
          message.isSnapshot != null &&
          Object.hasOwnProperty.call(message, 'isSnapshot')
        )
          writer
            .uint32(/* id 110121, wireType 0 =*/ 880968)
            .bool(message.isSnapshot);
        if (
          message.ssboe != null &&
          Object.hasOwnProperty.call(message, 'ssboe')
        )
          writer
            .uint32(/* id 150100, wireType 0 =*/ 1200800)
            .int32(message.ssboe);
        if (
          message.usecs != null &&
          Object.hasOwnProperty.call(message, 'usecs')
        )
          writer
            .uint32(/* id 150101, wireType 0 =*/ 1200808)
            .int32(message.usecs);
        if (
          message.accountId != null &&
          Object.hasOwnProperty.call(message, 'accountId')
        )
          writer
            .uint32(/* id 154008, wireType 2 =*/ 1232066)
            .string(message.accountId);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        if (
          message.orderBuyQty != null &&
          Object.hasOwnProperty.call(message, 'orderBuyQty')
        )
          writer
            .uint32(/* id 154037, wireType 0 =*/ 1232296)
            .int32(message.orderBuyQty);
        if (
          message.orderSellQty != null &&
          Object.hasOwnProperty.call(message, 'orderSellQty')
        )
          writer
            .uint32(/* id 154038, wireType 0 =*/ 1232304)
            .int32(message.orderSellQty);
        if (
          message.fillBuyQty != null &&
          Object.hasOwnProperty.call(message, 'fillBuyQty')
        )
          writer
            .uint32(/* id 154041, wireType 0 =*/ 1232328)
            .int32(message.fillBuyQty);
        if (
          message.fillSellQty != null &&
          Object.hasOwnProperty.call(message, 'fillSellQty')
        )
          writer
            .uint32(/* id 154042, wireType 0 =*/ 1232336)
            .int32(message.fillSellQty);
        if (
          message.buyQty != null &&
          Object.hasOwnProperty.call(message, 'buyQty')
        )
          writer
            .uint32(/* id 154260, wireType 0 =*/ 1234080)
            .int32(message.buyQty);
        if (
          message.sellQty != null &&
          Object.hasOwnProperty.call(message, 'sellQty')
        )
          writer
            .uint32(/* id 154261, wireType 0 =*/ 1234088)
            .int32(message.sellQty);
        if (
          message.mtmSecurity != null &&
          Object.hasOwnProperty.call(message, 'mtmSecurity')
        )
          writer
            .uint32(/* id 154263, wireType 2 =*/ 1234106)
            .string(message.mtmSecurity);
        if (
          message.avgOpenFillPrice != null &&
          Object.hasOwnProperty.call(message, 'avgOpenFillPrice')
        )
          writer
            .uint32(/* id 154434, wireType 1 =*/ 1235473)
            .double(message.avgOpenFillPrice);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.openPositionPnl != null &&
          Object.hasOwnProperty.call(message, 'openPositionPnl')
        )
          writer
            .uint32(/* id 156961, wireType 2 =*/ 1255690)
            .string(message.openPositionPnl);
        if (
          message.openPositionQuantity != null &&
          Object.hasOwnProperty.call(message, 'openPositionQuantity')
        )
          writer
            .uint32(/* id 156962, wireType 0 =*/ 1255696)
            .int32(message.openPositionQuantity);
        if (
          message.closedPositionPnl != null &&
          Object.hasOwnProperty.call(message, 'closedPositionPnl')
        )
          writer
            .uint32(/* id 156963, wireType 2 =*/ 1255706)
            .string(message.closedPositionPnl);
        if (
          message.closedPositionQuantity != null &&
          Object.hasOwnProperty.call(message, 'closedPositionQuantity')
        )
          writer
            .uint32(/* id 156964, wireType 0 =*/ 1255712)
            .int32(message.closedPositionQuantity);
        if (
          message.netQuantity != null &&
          Object.hasOwnProperty.call(message, 'netQuantity')
        )
          writer
            .uint32(/* id 156967, wireType 0 =*/ 1255736)
            .int32(message.netQuantity);
        if (
          message.openLongOptionsValue != null &&
          Object.hasOwnProperty.call(message, 'openLongOptionsValue')
        )
          writer
            .uint32(/* id 157105, wireType 2 =*/ 1256842)
            .string(message.openLongOptionsValue);
        if (
          message.openShortOptionsValue != null &&
          Object.hasOwnProperty.call(message, 'openShortOptionsValue')
        )
          writer
            .uint32(/* id 157106, wireType 2 =*/ 1256850)
            .string(message.openShortOptionsValue);
        if (
          message.closedOptionsValue != null &&
          Object.hasOwnProperty.call(message, 'closedOptionsValue')
        )
          writer
            .uint32(/* id 157107, wireType 2 =*/ 1256858)
            .string(message.closedOptionsValue);
        if (
          message.optionCashReserved != null &&
          Object.hasOwnProperty.call(message, 'optionCashReserved')
        )
          writer
            .uint32(/* id 157111, wireType 2 =*/ 1256890)
            .string(message.optionCashReserved);
        if (
          message.dayOpenPnl != null &&
          Object.hasOwnProperty.call(message, 'dayOpenPnl')
        )
          writer
            .uint32(/* id 157954, wireType 1 =*/ 1263633)
            .double(message.dayOpenPnl);
        if (
          message.dayClosedPnl != null &&
          Object.hasOwnProperty.call(message, 'dayClosedPnl')
        )
          writer
            .uint32(/* id 157955, wireType 1 =*/ 1263641)
            .double(message.dayClosedPnl);
        if (
          message.dayPnl != null &&
          Object.hasOwnProperty.call(message, 'dayPnl')
        )
          writer
            .uint32(/* id 157956, wireType 1 =*/ 1263649)
            .double(message.dayPnl);
        if (
          message.dayOpenPnlOffset != null &&
          Object.hasOwnProperty.call(message, 'dayOpenPnlOffset')
        )
          writer
            .uint32(/* id 157957, wireType 1 =*/ 1263657)
            .double(message.dayOpenPnlOffset);
        if (
          message.dayClosedPnlOffset != null &&
          Object.hasOwnProperty.call(message, 'dayClosedPnlOffset')
        )
          writer
            .uint32(/* id 157958, wireType 1 =*/ 1263665)
            .double(message.dayClosedPnlOffset);
        return writer;
      };

      /**
       * Encodes the specified InstrumentPnLPositionUpdate message, length delimited. Does not implicitly {@link rti.InstrumentPnLPositionUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {rti.IInstrumentPnLPositionUpdate} message InstrumentPnLPositionUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      InstrumentPnLPositionUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes an InstrumentPnLPositionUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.InstrumentPnLPositionUpdate} InstrumentPnLPositionUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      InstrumentPnLPositionUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.InstrumentPnLPositionUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110121:
              message.isSnapshot = reader.bool();
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 154008:
              message.accountId = reader.string();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100749:
              message.productCode = reader.string();
              break;
            case 110116:
              message.instrumentType = reader.string();
              break;
            case 154041:
              message.fillBuyQty = reader.int32();
              break;
            case 154042:
              message.fillSellQty = reader.int32();
              break;
            case 154037:
              message.orderBuyQty = reader.int32();
              break;
            case 154038:
              message.orderSellQty = reader.int32();
              break;
            case 154260:
              message.buyQty = reader.int32();
              break;
            case 154261:
              message.sellQty = reader.int32();
              break;
            case 154434:
              message.avgOpenFillPrice = reader.double();
              break;
            case 157954:
              message.dayOpenPnl = reader.double();
              break;
            case 157955:
              message.dayClosedPnl = reader.double();
              break;
            case 157956:
              message.dayPnl = reader.double();
              break;
            case 157957:
              message.dayOpenPnlOffset = reader.double();
              break;
            case 157958:
              message.dayClosedPnlOffset = reader.double();
              break;
            case 154263:
              message.mtmSecurity = reader.string();
              break;
            case 157105:
              message.openLongOptionsValue = reader.string();
              break;
            case 157106:
              message.openShortOptionsValue = reader.string();
              break;
            case 157107:
              message.closedOptionsValue = reader.string();
              break;
            case 157111:
              message.optionCashReserved = reader.string();
              break;
            case 156961:
              message.openPositionPnl = reader.string();
              break;
            case 156962:
              message.openPositionQuantity = reader.int32();
              break;
            case 156963:
              message.closedPositionPnl = reader.string();
              break;
            case 156964:
              message.closedPositionQuantity = reader.int32();
              break;
            case 156967:
              message.netQuantity = reader.int32();
              break;
            case 150100:
              message.ssboe = reader.int32();
              break;
            case 150101:
              message.usecs = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes an InstrumentPnLPositionUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.InstrumentPnLPositionUpdate} InstrumentPnLPositionUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      InstrumentPnLPositionUpdate.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies an InstrumentPnLPositionUpdate message.
       * @function verify
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      InstrumentPnLPositionUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          if (typeof message.isSnapshot !== 'boolean')
            return 'isSnapshot: boolean expected';
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          if (!$util.isString(message.accountId))
            return 'accountId: string expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          if (!$util.isString(message.productCode))
            return 'productCode: string expected';
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          if (!$util.isString(message.instrumentType))
            return 'instrumentType: string expected';
        if (message.fillBuyQty != null && message.hasOwnProperty('fillBuyQty'))
          if (!$util.isInteger(message.fillBuyQty))
            return 'fillBuyQty: integer expected';
        if (
          message.fillSellQty != null &&
          message.hasOwnProperty('fillSellQty')
        )
          if (!$util.isInteger(message.fillSellQty))
            return 'fillSellQty: integer expected';
        if (
          message.orderBuyQty != null &&
          message.hasOwnProperty('orderBuyQty')
        )
          if (!$util.isInteger(message.orderBuyQty))
            return 'orderBuyQty: integer expected';
        if (
          message.orderSellQty != null &&
          message.hasOwnProperty('orderSellQty')
        )
          if (!$util.isInteger(message.orderSellQty))
            return 'orderSellQty: integer expected';
        if (message.buyQty != null && message.hasOwnProperty('buyQty'))
          if (!$util.isInteger(message.buyQty))
            return 'buyQty: integer expected';
        if (message.sellQty != null && message.hasOwnProperty('sellQty'))
          if (!$util.isInteger(message.sellQty))
            return 'sellQty: integer expected';
        if (
          message.avgOpenFillPrice != null &&
          message.hasOwnProperty('avgOpenFillPrice')
        )
          if (typeof message.avgOpenFillPrice !== 'number')
            return 'avgOpenFillPrice: number expected';
        if (message.dayOpenPnl != null && message.hasOwnProperty('dayOpenPnl'))
          if (typeof message.dayOpenPnl !== 'number')
            return 'dayOpenPnl: number expected';
        if (
          message.dayClosedPnl != null &&
          message.hasOwnProperty('dayClosedPnl')
        )
          if (typeof message.dayClosedPnl !== 'number')
            return 'dayClosedPnl: number expected';
        if (message.dayPnl != null && message.hasOwnProperty('dayPnl'))
          if (typeof message.dayPnl !== 'number')
            return 'dayPnl: number expected';
        if (
          message.dayOpenPnlOffset != null &&
          message.hasOwnProperty('dayOpenPnlOffset')
        )
          if (typeof message.dayOpenPnlOffset !== 'number')
            return 'dayOpenPnlOffset: number expected';
        if (
          message.dayClosedPnlOffset != null &&
          message.hasOwnProperty('dayClosedPnlOffset')
        )
          if (typeof message.dayClosedPnlOffset !== 'number')
            return 'dayClosedPnlOffset: number expected';
        if (
          message.mtmSecurity != null &&
          message.hasOwnProperty('mtmSecurity')
        )
          if (!$util.isString(message.mtmSecurity))
            return 'mtmSecurity: string expected';
        if (
          message.openLongOptionsValue != null &&
          message.hasOwnProperty('openLongOptionsValue')
        )
          if (!$util.isString(message.openLongOptionsValue))
            return 'openLongOptionsValue: string expected';
        if (
          message.openShortOptionsValue != null &&
          message.hasOwnProperty('openShortOptionsValue')
        )
          if (!$util.isString(message.openShortOptionsValue))
            return 'openShortOptionsValue: string expected';
        if (
          message.closedOptionsValue != null &&
          message.hasOwnProperty('closedOptionsValue')
        )
          if (!$util.isString(message.closedOptionsValue))
            return 'closedOptionsValue: string expected';
        if (
          message.optionCashReserved != null &&
          message.hasOwnProperty('optionCashReserved')
        )
          if (!$util.isString(message.optionCashReserved))
            return 'optionCashReserved: string expected';
        if (
          message.openPositionPnl != null &&
          message.hasOwnProperty('openPositionPnl')
        )
          if (!$util.isString(message.openPositionPnl))
            return 'openPositionPnl: string expected';
        if (
          message.openPositionQuantity != null &&
          message.hasOwnProperty('openPositionQuantity')
        )
          if (!$util.isInteger(message.openPositionQuantity))
            return 'openPositionQuantity: integer expected';
        if (
          message.closedPositionPnl != null &&
          message.hasOwnProperty('closedPositionPnl')
        )
          if (!$util.isString(message.closedPositionPnl))
            return 'closedPositionPnl: string expected';
        if (
          message.closedPositionQuantity != null &&
          message.hasOwnProperty('closedPositionQuantity')
        )
          if (!$util.isInteger(message.closedPositionQuantity))
            return 'closedPositionQuantity: integer expected';
        if (
          message.netQuantity != null &&
          message.hasOwnProperty('netQuantity')
        )
          if (!$util.isInteger(message.netQuantity))
            return 'netQuantity: integer expected';
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          if (!$util.isInteger(message.ssboe)) return 'ssboe: integer expected';
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          if (!$util.isInteger(message.usecs)) return 'usecs: integer expected';
        return null;
      };

      /**
       * Creates an InstrumentPnLPositionUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.InstrumentPnLPositionUpdate} InstrumentPnLPositionUpdate
       */
      InstrumentPnLPositionUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.InstrumentPnLPositionUpdate)
          return object;
        var message = new $root.rti.InstrumentPnLPositionUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.isSnapshot != null)
          message.isSnapshot = Boolean(object.isSnapshot);
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.accountId != null)
          message.accountId = String(object.accountId);
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.productCode != null)
          message.productCode = String(object.productCode);
        if (object.instrumentType != null)
          message.instrumentType = String(object.instrumentType);
        if (object.fillBuyQty != null)
          message.fillBuyQty = object.fillBuyQty | 0;
        if (object.fillSellQty != null)
          message.fillSellQty = object.fillSellQty | 0;
        if (object.orderBuyQty != null)
          message.orderBuyQty = object.orderBuyQty | 0;
        if (object.orderSellQty != null)
          message.orderSellQty = object.orderSellQty | 0;
        if (object.buyQty != null) message.buyQty = object.buyQty | 0;
        if (object.sellQty != null) message.sellQty = object.sellQty | 0;
        if (object.avgOpenFillPrice != null)
          message.avgOpenFillPrice = Number(object.avgOpenFillPrice);
        if (object.dayOpenPnl != null)
          message.dayOpenPnl = Number(object.dayOpenPnl);
        if (object.dayClosedPnl != null)
          message.dayClosedPnl = Number(object.dayClosedPnl);
        if (object.dayPnl != null) message.dayPnl = Number(object.dayPnl);
        if (object.dayOpenPnlOffset != null)
          message.dayOpenPnlOffset = Number(object.dayOpenPnlOffset);
        if (object.dayClosedPnlOffset != null)
          message.dayClosedPnlOffset = Number(object.dayClosedPnlOffset);
        if (object.mtmSecurity != null)
          message.mtmSecurity = String(object.mtmSecurity);
        if (object.openLongOptionsValue != null)
          message.openLongOptionsValue = String(object.openLongOptionsValue);
        if (object.openShortOptionsValue != null)
          message.openShortOptionsValue = String(object.openShortOptionsValue);
        if (object.closedOptionsValue != null)
          message.closedOptionsValue = String(object.closedOptionsValue);
        if (object.optionCashReserved != null)
          message.optionCashReserved = String(object.optionCashReserved);
        if (object.openPositionPnl != null)
          message.openPositionPnl = String(object.openPositionPnl);
        if (object.openPositionQuantity != null)
          message.openPositionQuantity = object.openPositionQuantity | 0;
        if (object.closedPositionPnl != null)
          message.closedPositionPnl = String(object.closedPositionPnl);
        if (object.closedPositionQuantity != null)
          message.closedPositionQuantity = object.closedPositionQuantity | 0;
        if (object.netQuantity != null)
          message.netQuantity = object.netQuantity | 0;
        if (object.ssboe != null) message.ssboe = object.ssboe | 0;
        if (object.usecs != null) message.usecs = object.usecs | 0;
        return message;
      };

      /**
       * Creates a plain object from an InstrumentPnLPositionUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {rti.InstrumentPnLPositionUpdate} message InstrumentPnLPositionUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      InstrumentPnLPositionUpdate.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.productCode = '';
          object.symbol = '';
          object.exchange = '';
          object.instrumentType = '';
          object.isSnapshot = false;
          object.ssboe = 0;
          object.usecs = 0;
          object.accountId = '';
          object.fcmId = '';
          object.ibId = '';
          object.orderBuyQty = 0;
          object.orderSellQty = 0;
          object.fillBuyQty = 0;
          object.fillSellQty = 0;
          object.buyQty = 0;
          object.sellQty = 0;
          object.mtmSecurity = '';
          object.avgOpenFillPrice = 0;
          object.templateId = 0;
          object.openPositionPnl = '';
          object.openPositionQuantity = 0;
          object.closedPositionPnl = '';
          object.closedPositionQuantity = 0;
          object.netQuantity = 0;
          object.openLongOptionsValue = '';
          object.openShortOptionsValue = '';
          object.closedOptionsValue = '';
          object.optionCashReserved = '';
          object.dayOpenPnl = 0;
          object.dayClosedPnl = 0;
          object.dayPnl = 0;
          object.dayOpenPnlOffset = 0;
          object.dayClosedPnlOffset = 0;
        }
        if (
          message.productCode != null &&
          message.hasOwnProperty('productCode')
        )
          object.productCode = message.productCode;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.instrumentType != null &&
          message.hasOwnProperty('instrumentType')
        )
          object.instrumentType = message.instrumentType;
        if (message.isSnapshot != null && message.hasOwnProperty('isSnapshot'))
          object.isSnapshot = message.isSnapshot;
        if (message.ssboe != null && message.hasOwnProperty('ssboe'))
          object.ssboe = message.ssboe;
        if (message.usecs != null && message.hasOwnProperty('usecs'))
          object.usecs = message.usecs;
        if (message.accountId != null && message.hasOwnProperty('accountId'))
          object.accountId = message.accountId;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (
          message.orderBuyQty != null &&
          message.hasOwnProperty('orderBuyQty')
        )
          object.orderBuyQty = message.orderBuyQty;
        if (
          message.orderSellQty != null &&
          message.hasOwnProperty('orderSellQty')
        )
          object.orderSellQty = message.orderSellQty;
        if (message.fillBuyQty != null && message.hasOwnProperty('fillBuyQty'))
          object.fillBuyQty = message.fillBuyQty;
        if (
          message.fillSellQty != null &&
          message.hasOwnProperty('fillSellQty')
        )
          object.fillSellQty = message.fillSellQty;
        if (message.buyQty != null && message.hasOwnProperty('buyQty'))
          object.buyQty = message.buyQty;
        if (message.sellQty != null && message.hasOwnProperty('sellQty'))
          object.sellQty = message.sellQty;
        if (
          message.mtmSecurity != null &&
          message.hasOwnProperty('mtmSecurity')
        )
          object.mtmSecurity = message.mtmSecurity;
        if (
          message.avgOpenFillPrice != null &&
          message.hasOwnProperty('avgOpenFillPrice')
        )
          object.avgOpenFillPrice =
            options.json && !isFinite(message.avgOpenFillPrice)
              ? String(message.avgOpenFillPrice)
              : message.avgOpenFillPrice;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.openPositionPnl != null &&
          message.hasOwnProperty('openPositionPnl')
        )
          object.openPositionPnl = message.openPositionPnl;
        if (
          message.openPositionQuantity != null &&
          message.hasOwnProperty('openPositionQuantity')
        )
          object.openPositionQuantity = message.openPositionQuantity;
        if (
          message.closedPositionPnl != null &&
          message.hasOwnProperty('closedPositionPnl')
        )
          object.closedPositionPnl = message.closedPositionPnl;
        if (
          message.closedPositionQuantity != null &&
          message.hasOwnProperty('closedPositionQuantity')
        )
          object.closedPositionQuantity = message.closedPositionQuantity;
        if (
          message.netQuantity != null &&
          message.hasOwnProperty('netQuantity')
        )
          object.netQuantity = message.netQuantity;
        if (
          message.openLongOptionsValue != null &&
          message.hasOwnProperty('openLongOptionsValue')
        )
          object.openLongOptionsValue = message.openLongOptionsValue;
        if (
          message.openShortOptionsValue != null &&
          message.hasOwnProperty('openShortOptionsValue')
        )
          object.openShortOptionsValue = message.openShortOptionsValue;
        if (
          message.closedOptionsValue != null &&
          message.hasOwnProperty('closedOptionsValue')
        )
          object.closedOptionsValue = message.closedOptionsValue;
        if (
          message.optionCashReserved != null &&
          message.hasOwnProperty('optionCashReserved')
        )
          object.optionCashReserved = message.optionCashReserved;
        if (message.dayOpenPnl != null && message.hasOwnProperty('dayOpenPnl'))
          object.dayOpenPnl =
            options.json && !isFinite(message.dayOpenPnl)
              ? String(message.dayOpenPnl)
              : message.dayOpenPnl;
        if (
          message.dayClosedPnl != null &&
          message.hasOwnProperty('dayClosedPnl')
        )
          object.dayClosedPnl =
            options.json && !isFinite(message.dayClosedPnl)
              ? String(message.dayClosedPnl)
              : message.dayClosedPnl;
        if (message.dayPnl != null && message.hasOwnProperty('dayPnl'))
          object.dayPnl =
            options.json && !isFinite(message.dayPnl)
              ? String(message.dayPnl)
              : message.dayPnl;
        if (
          message.dayOpenPnlOffset != null &&
          message.hasOwnProperty('dayOpenPnlOffset')
        )
          object.dayOpenPnlOffset =
            options.json && !isFinite(message.dayOpenPnlOffset)
              ? String(message.dayOpenPnlOffset)
              : message.dayOpenPnlOffset;
        if (
          message.dayClosedPnlOffset != null &&
          message.hasOwnProperty('dayClosedPnlOffset')
        )
          object.dayClosedPnlOffset =
            options.json && !isFinite(message.dayClosedPnlOffset)
              ? String(message.dayClosedPnlOffset)
              : message.dayClosedPnlOffset;
        return object;
      };

      /**
       * Converts this InstrumentPnLPositionUpdate to JSON.
       * @function toJSON
       * @memberof rti.InstrumentPnLPositionUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      InstrumentPnLPositionUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for InstrumentPnLPositionUpdate
       * @function getTypeUrl
       * @memberof rti.InstrumentPnLPositionUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      InstrumentPnLPositionUpdate.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.InstrumentPnLPositionUpdate';
      };

      return InstrumentPnLPositionUpdate;
    })();

    rti.RequestTickBarReplay = (function () {
      /**
       * Properties of a RequestTickBarReplay.
       * @memberof rti
       * @interface IRequestTickBarReplay
       * @property {number} templateId RequestTickBarReplay templateId
       * @property {Array.<string>|null} [userMsg] RequestTickBarReplay userMsg
       * @property {string|null} [symbol] RequestTickBarReplay symbol
       * @property {string|null} [exchange] RequestTickBarReplay exchange
       * @property {rti.RequestTickBarReplay.BarType|null} [barType] RequestTickBarReplay barType
       * @property {rti.RequestTickBarReplay.BarSubType|null} [barSubType] RequestTickBarReplay barSubType
       * @property {string|null} [barTypeSpecifier] RequestTickBarReplay barTypeSpecifier
       * @property {number|null} [startIndex] RequestTickBarReplay startIndex
       * @property {number|null} [finishIndex] RequestTickBarReplay finishIndex
       * @property {number|null} [userMaxCount] RequestTickBarReplay userMaxCount
       * @property {number|null} [customSessionOpenSsm] RequestTickBarReplay customSessionOpenSsm
       * @property {number|null} [customSessionCloseSsm] RequestTickBarReplay customSessionCloseSsm
       * @property {rti.RequestTickBarReplay.Direction|null} [direction] RequestTickBarReplay direction
       * @property {rti.RequestTickBarReplay.TimeOrder|null} [timeOrder] RequestTickBarReplay timeOrder
       * @property {boolean|null} [resumeBars] RequestTickBarReplay resumeBars
       */

      /**
       * Constructs a new RequestTickBarReplay.
       * @memberof rti
       * @classdesc Represents a RequestTickBarReplay.
       * @implements IRequestTickBarReplay
       * @constructor
       * @param {rti.IRequestTickBarReplay=} [properties] Properties to set
       */
      function RequestTickBarReplay(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestTickBarReplay templateId.
       * @member {number} templateId
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.templateId = 0;

      /**
       * RequestTickBarReplay userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.userMsg = $util.emptyArray;

      /**
       * RequestTickBarReplay symbol.
       * @member {string} symbol
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.symbol = '';

      /**
       * RequestTickBarReplay exchange.
       * @member {string} exchange
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.exchange = '';

      /**
       * RequestTickBarReplay barType.
       * @member {rti.RequestTickBarReplay.BarType} barType
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.barType = 1;

      /**
       * RequestTickBarReplay barSubType.
       * @member {rti.RequestTickBarReplay.BarSubType} barSubType
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.barSubType = 1;

      /**
       * RequestTickBarReplay barTypeSpecifier.
       * @member {string} barTypeSpecifier
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.barTypeSpecifier = '';

      /**
       * RequestTickBarReplay startIndex.
       * @member {number} startIndex
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.startIndex = 0;

      /**
       * RequestTickBarReplay finishIndex.
       * @member {number} finishIndex
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.finishIndex = 0;

      /**
       * RequestTickBarReplay userMaxCount.
       * @member {number} userMaxCount
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.userMaxCount = 0;

      /**
       * RequestTickBarReplay customSessionOpenSsm.
       * @member {number} customSessionOpenSsm
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.customSessionOpenSsm = 0;

      /**
       * RequestTickBarReplay customSessionCloseSsm.
       * @member {number} customSessionCloseSsm
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.customSessionCloseSsm = 0;

      /**
       * RequestTickBarReplay direction.
       * @member {rti.RequestTickBarReplay.Direction} direction
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.direction = 1;

      /**
       * RequestTickBarReplay timeOrder.
       * @member {rti.RequestTickBarReplay.TimeOrder} timeOrder
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.timeOrder = 1;

      /**
       * RequestTickBarReplay resumeBars.
       * @member {boolean} resumeBars
       * @memberof rti.RequestTickBarReplay
       * @instance
       */
      RequestTickBarReplay.prototype.resumeBars = false;

      /**
       * Creates a new RequestTickBarReplay instance using the specified properties.
       * @function create
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {rti.IRequestTickBarReplay=} [properties] Properties to set
       * @returns {rti.RequestTickBarReplay} RequestTickBarReplay instance
       */
      RequestTickBarReplay.create = function create(properties) {
        return new RequestTickBarReplay(properties);
      };

      /**
       * Encodes the specified RequestTickBarReplay message. Does not implicitly {@link rti.RequestTickBarReplay.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {rti.IRequestTickBarReplay} message RequestTickBarReplay message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTickBarReplay.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.barType != null &&
          Object.hasOwnProperty.call(message, 'barType')
        )
          writer
            .uint32(/* id 119200, wireType 0 =*/ 953600)
            .int32(message.barType);
        if (
          message.barSubType != null &&
          Object.hasOwnProperty.call(message, 'barSubType')
        )
          writer
            .uint32(/* id 119208, wireType 0 =*/ 953664)
            .int32(message.barSubType);
        if (
          message.customSessionOpenSsm != null &&
          Object.hasOwnProperty.call(message, 'customSessionOpenSsm')
        )
          writer
            .uint32(/* id 119209, wireType 0 =*/ 953672)
            .int32(message.customSessionOpenSsm);
        if (
          message.customSessionCloseSsm != null &&
          Object.hasOwnProperty.call(message, 'customSessionCloseSsm')
        )
          writer
            .uint32(/* id 119210, wireType 0 =*/ 953680)
            .int32(message.customSessionCloseSsm);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.barTypeSpecifier != null &&
          Object.hasOwnProperty.call(message, 'barTypeSpecifier')
        )
          writer
            .uint32(/* id 148162, wireType 2 =*/ 1185298)
            .string(message.barTypeSpecifier);
        if (
          message.direction != null &&
          Object.hasOwnProperty.call(message, 'direction')
        )
          writer
            .uint32(/* id 149253, wireType 0 =*/ 1194024)
            .int32(message.direction);
        if (
          message.timeOrder != null &&
          Object.hasOwnProperty.call(message, 'timeOrder')
        )
          writer
            .uint32(/* id 149307, wireType 0 =*/ 1194456)
            .int32(message.timeOrder);
        if (
          message.startIndex != null &&
          Object.hasOwnProperty.call(message, 'startIndex')
        )
          writer
            .uint32(/* id 153002, wireType 0 =*/ 1224016)
            .int32(message.startIndex);
        if (
          message.finishIndex != null &&
          Object.hasOwnProperty.call(message, 'finishIndex')
        )
          writer
            .uint32(/* id 153003, wireType 0 =*/ 1224024)
            .int32(message.finishIndex);
        if (
          message.resumeBars != null &&
          Object.hasOwnProperty.call(message, 'resumeBars')
        )
          writer
            .uint32(/* id 153642, wireType 0 =*/ 1229136)
            .bool(message.resumeBars);
        if (
          message.userMaxCount != null &&
          Object.hasOwnProperty.call(message, 'userMaxCount')
        )
          writer
            .uint32(/* id 154020, wireType 0 =*/ 1232160)
            .int32(message.userMaxCount);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestTickBarReplay message, length delimited. Does not implicitly {@link rti.RequestTickBarReplay.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {rti.IRequestTickBarReplay} message RequestTickBarReplay message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTickBarReplay.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestTickBarReplay message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestTickBarReplay} RequestTickBarReplay
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTickBarReplay.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestTickBarReplay();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 119200:
              message.barType = reader.int32();
              break;
            case 119208:
              message.barSubType = reader.int32();
              break;
            case 148162:
              message.barTypeSpecifier = reader.string();
              break;
            case 153002:
              message.startIndex = reader.int32();
              break;
            case 153003:
              message.finishIndex = reader.int32();
              break;
            case 154020:
              message.userMaxCount = reader.int32();
              break;
            case 119209:
              message.customSessionOpenSsm = reader.int32();
              break;
            case 119210:
              message.customSessionCloseSsm = reader.int32();
              break;
            case 149253:
              message.direction = reader.int32();
              break;
            case 149307:
              message.timeOrder = reader.int32();
              break;
            case 153642:
              message.resumeBars = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestTickBarReplay message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestTickBarReplay} RequestTickBarReplay
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTickBarReplay.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestTickBarReplay message.
       * @function verify
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestTickBarReplay.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.barType != null && message.hasOwnProperty('barType'))
          switch (message.barType) {
            default:
              return 'barType: enum value expected';
            case 1:
            case 2:
            case 3:
              break;
          }
        if (message.barSubType != null && message.hasOwnProperty('barSubType'))
          switch (message.barSubType) {
            default:
              return 'barSubType: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.barTypeSpecifier != null &&
          message.hasOwnProperty('barTypeSpecifier')
        )
          if (!$util.isString(message.barTypeSpecifier))
            return 'barTypeSpecifier: string expected';
        if (message.startIndex != null && message.hasOwnProperty('startIndex'))
          if (!$util.isInteger(message.startIndex))
            return 'startIndex: integer expected';
        if (
          message.finishIndex != null &&
          message.hasOwnProperty('finishIndex')
        )
          if (!$util.isInteger(message.finishIndex))
            return 'finishIndex: integer expected';
        if (
          message.userMaxCount != null &&
          message.hasOwnProperty('userMaxCount')
        )
          if (!$util.isInteger(message.userMaxCount))
            return 'userMaxCount: integer expected';
        if (
          message.customSessionOpenSsm != null &&
          message.hasOwnProperty('customSessionOpenSsm')
        )
          if (!$util.isInteger(message.customSessionOpenSsm))
            return 'customSessionOpenSsm: integer expected';
        if (
          message.customSessionCloseSsm != null &&
          message.hasOwnProperty('customSessionCloseSsm')
        )
          if (!$util.isInteger(message.customSessionCloseSsm))
            return 'customSessionCloseSsm: integer expected';
        if (message.direction != null && message.hasOwnProperty('direction'))
          switch (message.direction) {
            default:
              return 'direction: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.timeOrder != null && message.hasOwnProperty('timeOrder'))
          switch (message.timeOrder) {
            default:
              return 'timeOrder: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.resumeBars != null && message.hasOwnProperty('resumeBars'))
          if (typeof message.resumeBars !== 'boolean')
            return 'resumeBars: boolean expected';
        return null;
      };

      /**
       * Creates a RequestTickBarReplay message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestTickBarReplay} RequestTickBarReplay
       */
      RequestTickBarReplay.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestTickBarReplay) return object;
        var message = new $root.rti.RequestTickBarReplay();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestTickBarReplay.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.barType) {
          case 'TICK_BAR':
          case 1:
            message.barType = 1;
            break;
          case 'RANGE_BAR':
          case 2:
            message.barType = 2;
            break;
          case 'VOLUME_BAR':
          case 3:
            message.barType = 3;
            break;
        }
        switch (object.barSubType) {
          case 'REGULAR':
          case 1:
            message.barSubType = 1;
            break;
          case 'CUSTOM':
          case 2:
            message.barSubType = 2;
            break;
        }
        if (object.barTypeSpecifier != null)
          message.barTypeSpecifier = String(object.barTypeSpecifier);
        if (object.startIndex != null)
          message.startIndex = object.startIndex | 0;
        if (object.finishIndex != null)
          message.finishIndex = object.finishIndex | 0;
        if (object.userMaxCount != null)
          message.userMaxCount = object.userMaxCount | 0;
        if (object.customSessionOpenSsm != null)
          message.customSessionOpenSsm = object.customSessionOpenSsm | 0;
        if (object.customSessionCloseSsm != null)
          message.customSessionCloseSsm = object.customSessionCloseSsm | 0;
        switch (object.direction) {
          case 'FIRST':
          case 1:
            message.direction = 1;
            break;
          case 'LAST':
          case 2:
            message.direction = 2;
            break;
        }
        switch (object.timeOrder) {
          case 'FORWARDS':
          case 1:
            message.timeOrder = 1;
            break;
          case 'BACKWARDS':
          case 2:
            message.timeOrder = 2;
            break;
        }
        if (object.resumeBars != null)
          message.resumeBars = Boolean(object.resumeBars);
        return message;
      };

      /**
       * Creates a plain object from a RequestTickBarReplay message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {rti.RequestTickBarReplay} message RequestTickBarReplay
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestTickBarReplay.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.barType = options.enums === String ? 'TICK_BAR' : 1;
          object.barSubType = options.enums === String ? 'REGULAR' : 1;
          object.customSessionOpenSsm = 0;
          object.customSessionCloseSsm = 0;
          object.barTypeSpecifier = '';
          object.direction = options.enums === String ? 'FIRST' : 1;
          object.timeOrder = options.enums === String ? 'FORWARDS' : 1;
          object.startIndex = 0;
          object.finishIndex = 0;
          object.resumeBars = false;
          object.userMaxCount = 0;
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.barType != null && message.hasOwnProperty('barType'))
          object.barType =
            options.enums === String
              ? $root.rti.RequestTickBarReplay.BarType[message.barType]
              : message.barType;
        if (message.barSubType != null && message.hasOwnProperty('barSubType'))
          object.barSubType =
            options.enums === String
              ? $root.rti.RequestTickBarReplay.BarSubType[message.barSubType]
              : message.barSubType;
        if (
          message.customSessionOpenSsm != null &&
          message.hasOwnProperty('customSessionOpenSsm')
        )
          object.customSessionOpenSsm = message.customSessionOpenSsm;
        if (
          message.customSessionCloseSsm != null &&
          message.hasOwnProperty('customSessionCloseSsm')
        )
          object.customSessionCloseSsm = message.customSessionCloseSsm;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.barTypeSpecifier != null &&
          message.hasOwnProperty('barTypeSpecifier')
        )
          object.barTypeSpecifier = message.barTypeSpecifier;
        if (message.direction != null && message.hasOwnProperty('direction'))
          object.direction =
            options.enums === String
              ? $root.rti.RequestTickBarReplay.Direction[message.direction]
              : message.direction;
        if (message.timeOrder != null && message.hasOwnProperty('timeOrder'))
          object.timeOrder =
            options.enums === String
              ? $root.rti.RequestTickBarReplay.TimeOrder[message.timeOrder]
              : message.timeOrder;
        if (message.startIndex != null && message.hasOwnProperty('startIndex'))
          object.startIndex = message.startIndex;
        if (
          message.finishIndex != null &&
          message.hasOwnProperty('finishIndex')
        )
          object.finishIndex = message.finishIndex;
        if (message.resumeBars != null && message.hasOwnProperty('resumeBars'))
          object.resumeBars = message.resumeBars;
        if (
          message.userMaxCount != null &&
          message.hasOwnProperty('userMaxCount')
        )
          object.userMaxCount = message.userMaxCount;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestTickBarReplay to JSON.
       * @function toJSON
       * @memberof rti.RequestTickBarReplay
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestTickBarReplay.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestTickBarReplay
       * @function getTypeUrl
       * @memberof rti.RequestTickBarReplay
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestTickBarReplay.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestTickBarReplay';
      };

      /**
       * BarType enum.
       * @name rti.RequestTickBarReplay.BarType
       * @enum {number}
       * @property {number} TICK_BAR=1 TICK_BAR value
       * @property {number} RANGE_BAR=2 RANGE_BAR value
       * @property {number} VOLUME_BAR=3 VOLUME_BAR value
       */
      RequestTickBarReplay.BarType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'TICK_BAR')] = 1;
        values[(valuesById[2] = 'RANGE_BAR')] = 2;
        values[(valuesById[3] = 'VOLUME_BAR')] = 3;
        return values;
      })();

      /**
       * BarSubType enum.
       * @name rti.RequestTickBarReplay.BarSubType
       * @enum {number}
       * @property {number} REGULAR=1 REGULAR value
       * @property {number} CUSTOM=2 CUSTOM value
       */
      RequestTickBarReplay.BarSubType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'REGULAR')] = 1;
        values[(valuesById[2] = 'CUSTOM')] = 2;
        return values;
      })();

      /**
       * Direction enum.
       * @name rti.RequestTickBarReplay.Direction
       * @enum {number}
       * @property {number} FIRST=1 FIRST value
       * @property {number} LAST=2 LAST value
       */
      RequestTickBarReplay.Direction = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'FIRST')] = 1;
        values[(valuesById[2] = 'LAST')] = 2;
        return values;
      })();

      /**
       * TimeOrder enum.
       * @name rti.RequestTickBarReplay.TimeOrder
       * @enum {number}
       * @property {number} FORWARDS=1 FORWARDS value
       * @property {number} BACKWARDS=2 BACKWARDS value
       */
      RequestTickBarReplay.TimeOrder = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'FORWARDS')] = 1;
        values[(valuesById[2] = 'BACKWARDS')] = 2;
        return values;
      })();

      return RequestTickBarReplay;
    })();

    rti.ResponseTickBarReplay = (function () {
      /**
       * Properties of a ResponseTickBarReplay.
       * @memberof rti
       * @interface IResponseTickBarReplay
       * @property {number} templateId ResponseTickBarReplay templateId
       * @property {string|null} [requestKey] ResponseTickBarReplay requestKey
       * @property {Array.<string>|null} [userMsg] ResponseTickBarReplay userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseTickBarReplay rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseTickBarReplay rpCode
       * @property {string|null} [symbol] ResponseTickBarReplay symbol
       * @property {string|null} [exchange] ResponseTickBarReplay exchange
       * @property {rti.ResponseTickBarReplay.BarType|null} [type] ResponseTickBarReplay type
       * @property {rti.ResponseTickBarReplay.BarSubType|null} [subType] ResponseTickBarReplay subType
       * @property {string|null} [typeSpecifier] ResponseTickBarReplay typeSpecifier
       * @property {number|Long|null} [numTrades] ResponseTickBarReplay numTrades
       * @property {number|Long|null} [volume] ResponseTickBarReplay volume
       * @property {number|Long|null} [bidVolume] ResponseTickBarReplay bidVolume
       * @property {number|Long|null} [askVolume] ResponseTickBarReplay askVolume
       * @property {number|null} [openPrice] ResponseTickBarReplay openPrice
       * @property {number|null} [closePrice] ResponseTickBarReplay closePrice
       * @property {number|null} [highPrice] ResponseTickBarReplay highPrice
       * @property {number|null} [lowPrice] ResponseTickBarReplay lowPrice
       * @property {number|null} [customSessionOpenSsm] ResponseTickBarReplay customSessionOpenSsm
       * @property {Array.<number>|null} [dataBarSsboe] ResponseTickBarReplay dataBarSsboe
       * @property {Array.<number>|null} [dataBarUsecs] ResponseTickBarReplay dataBarUsecs
       */

      /**
       * Constructs a new ResponseTickBarReplay.
       * @memberof rti
       * @classdesc Represents a ResponseTickBarReplay.
       * @implements IResponseTickBarReplay
       * @constructor
       * @param {rti.IResponseTickBarReplay=} [properties] Properties to set
       */
      function ResponseTickBarReplay(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        this.dataBarSsboe = [];
        this.dataBarUsecs = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseTickBarReplay templateId.
       * @member {number} templateId
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.templateId = 0;

      /**
       * ResponseTickBarReplay requestKey.
       * @member {string} requestKey
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.requestKey = '';

      /**
       * ResponseTickBarReplay userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseTickBarReplay rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseTickBarReplay rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseTickBarReplay symbol.
       * @member {string} symbol
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.symbol = '';

      /**
       * ResponseTickBarReplay exchange.
       * @member {string} exchange
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.exchange = '';

      /**
       * ResponseTickBarReplay type.
       * @member {rti.ResponseTickBarReplay.BarType} type
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.type = 1;

      /**
       * ResponseTickBarReplay subType.
       * @member {rti.ResponseTickBarReplay.BarSubType} subType
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.subType = 1;

      /**
       * ResponseTickBarReplay typeSpecifier.
       * @member {string} typeSpecifier
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.typeSpecifier = '';

      /**
       * ResponseTickBarReplay numTrades.
       * @member {number|Long} numTrades
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.numTrades = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseTickBarReplay volume.
       * @member {number|Long} volume
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.volume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseTickBarReplay bidVolume.
       * @member {number|Long} bidVolume
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.bidVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseTickBarReplay askVolume.
       * @member {number|Long} askVolume
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.askVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseTickBarReplay openPrice.
       * @member {number} openPrice
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.openPrice = 0;

      /**
       * ResponseTickBarReplay closePrice.
       * @member {number} closePrice
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.closePrice = 0;

      /**
       * ResponseTickBarReplay highPrice.
       * @member {number} highPrice
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.highPrice = 0;

      /**
       * ResponseTickBarReplay lowPrice.
       * @member {number} lowPrice
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.lowPrice = 0;

      /**
       * ResponseTickBarReplay customSessionOpenSsm.
       * @member {number} customSessionOpenSsm
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.customSessionOpenSsm = 0;

      /**
       * ResponseTickBarReplay dataBarSsboe.
       * @member {Array.<number>} dataBarSsboe
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.dataBarSsboe = $util.emptyArray;

      /**
       * ResponseTickBarReplay dataBarUsecs.
       * @member {Array.<number>} dataBarUsecs
       * @memberof rti.ResponseTickBarReplay
       * @instance
       */
      ResponseTickBarReplay.prototype.dataBarUsecs = $util.emptyArray;

      /**
       * Creates a new ResponseTickBarReplay instance using the specified properties.
       * @function create
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {rti.IResponseTickBarReplay=} [properties] Properties to set
       * @returns {rti.ResponseTickBarReplay} ResponseTickBarReplay instance
       */
      ResponseTickBarReplay.create = function create(properties) {
        return new ResponseTickBarReplay(properties);
      };

      /**
       * Encodes the specified ResponseTickBarReplay message. Does not implicitly {@link rti.ResponseTickBarReplay.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {rti.IResponseTickBarReplay} message ResponseTickBarReplay message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTickBarReplay.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.highPrice != null &&
          Object.hasOwnProperty.call(message, 'highPrice')
        )
          writer
            .uint32(/* id 100012, wireType 1 =*/ 800097)
            .double(message.highPrice);
        if (
          message.lowPrice != null &&
          Object.hasOwnProperty.call(message, 'lowPrice')
        )
          writer
            .uint32(/* id 100013, wireType 1 =*/ 800105)
            .double(message.lowPrice);
        if (
          message.openPrice != null &&
          Object.hasOwnProperty.call(message, 'openPrice')
        )
          writer
            .uint32(/* id 100019, wireType 1 =*/ 800153)
            .double(message.openPrice);
        if (
          message.closePrice != null &&
          Object.hasOwnProperty.call(message, 'closePrice')
        )
          writer
            .uint32(/* id 100021, wireType 1 =*/ 800169)
            .double(message.closePrice);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
          writer
            .uint32(/* id 119200, wireType 0 =*/ 953600)
            .int32(message.type);
        if (message.dataBarSsboe != null && message.dataBarSsboe.length)
          for (var i = 0; i < message.dataBarSsboe.length; ++i)
            writer
              .uint32(/* id 119202, wireType 0 =*/ 953616)
              .int32(message.dataBarSsboe[i]);
        if (message.dataBarUsecs != null && message.dataBarUsecs.length)
          for (var i = 0; i < message.dataBarUsecs.length; ++i)
            writer
              .uint32(/* id 119203, wireType 0 =*/ 953624)
              .int32(message.dataBarUsecs[i]);
        if (
          message.numTrades != null &&
          Object.hasOwnProperty.call(message, 'numTrades')
        )
          writer
            .uint32(/* id 119204, wireType 0 =*/ 953632)
            .uint64(message.numTrades);
        if (
          message.volume != null &&
          Object.hasOwnProperty.call(message, 'volume')
        )
          writer
            .uint32(/* id 119205, wireType 0 =*/ 953640)
            .uint64(message.volume);
        if (
          message.subType != null &&
          Object.hasOwnProperty.call(message, 'subType')
        )
          writer
            .uint32(/* id 119208, wireType 0 =*/ 953664)
            .int32(message.subType);
        if (
          message.customSessionOpenSsm != null &&
          Object.hasOwnProperty.call(message, 'customSessionOpenSsm')
        )
          writer
            .uint32(/* id 119209, wireType 0 =*/ 953672)
            .int32(message.customSessionOpenSsm);
        if (
          message.bidVolume != null &&
          Object.hasOwnProperty.call(message, 'bidVolume')
        )
          writer
            .uint32(/* id 119213, wireType 0 =*/ 953704)
            .uint64(message.bidVolume);
        if (
          message.askVolume != null &&
          Object.hasOwnProperty.call(message, 'askVolume')
        )
          writer
            .uint32(/* id 119214, wireType 0 =*/ 953712)
            .uint64(message.askVolume);
        if (
          message.requestKey != null &&
          Object.hasOwnProperty.call(message, 'requestKey')
        )
          writer
            .uint32(/* id 132758, wireType 2 =*/ 1062066)
            .string(message.requestKey);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.typeSpecifier != null &&
          Object.hasOwnProperty.call(message, 'typeSpecifier')
        )
          writer
            .uint32(/* id 148162, wireType 2 =*/ 1185298)
            .string(message.typeSpecifier);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseTickBarReplay message, length delimited. Does not implicitly {@link rti.ResponseTickBarReplay.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {rti.IResponseTickBarReplay} message ResponseTickBarReplay message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTickBarReplay.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseTickBarReplay message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseTickBarReplay} ResponseTickBarReplay
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTickBarReplay.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseTickBarReplay();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132758:
              message.requestKey = reader.string();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 119200:
              message.type = reader.int32();
              break;
            case 119208:
              message.subType = reader.int32();
              break;
            case 148162:
              message.typeSpecifier = reader.string();
              break;
            case 119204:
              message.numTrades = reader.uint64();
              break;
            case 119205:
              message.volume = reader.uint64();
              break;
            case 119213:
              message.bidVolume = reader.uint64();
              break;
            case 119214:
              message.askVolume = reader.uint64();
              break;
            case 100019:
              message.openPrice = reader.double();
              break;
            case 100021:
              message.closePrice = reader.double();
              break;
            case 100012:
              message.highPrice = reader.double();
              break;
            case 100013:
              message.lowPrice = reader.double();
              break;
            case 119209:
              message.customSessionOpenSsm = reader.int32();
              break;
            case 119202:
              if (!(message.dataBarSsboe && message.dataBarSsboe.length))
                message.dataBarSsboe = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.dataBarSsboe.push(reader.int32());
              } else message.dataBarSsboe.push(reader.int32());
              break;
            case 119203:
              if (!(message.dataBarUsecs && message.dataBarUsecs.length))
                message.dataBarUsecs = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.dataBarUsecs.push(reader.int32());
              } else message.dataBarUsecs.push(reader.int32());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseTickBarReplay message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseTickBarReplay} ResponseTickBarReplay
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTickBarReplay.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseTickBarReplay message.
       * @function verify
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseTickBarReplay.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.requestKey != null && message.hasOwnProperty('requestKey'))
          if (!$util.isString(message.requestKey))
            return 'requestKey: string expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.type != null && message.hasOwnProperty('type'))
          switch (message.type) {
            default:
              return 'type: enum value expected';
            case 1:
            case 2:
            case 3:
              break;
          }
        if (message.subType != null && message.hasOwnProperty('subType'))
          switch (message.subType) {
            default:
              return 'subType: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.typeSpecifier != null &&
          message.hasOwnProperty('typeSpecifier')
        )
          if (!$util.isString(message.typeSpecifier))
            return 'typeSpecifier: string expected';
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (
            !$util.isInteger(message.numTrades) &&
            !(
              message.numTrades &&
              $util.isInteger(message.numTrades.low) &&
              $util.isInteger(message.numTrades.high)
            )
          )
            return 'numTrades: integer|Long expected';
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (
            !$util.isInteger(message.volume) &&
            !(
              message.volume &&
              $util.isInteger(message.volume.low) &&
              $util.isInteger(message.volume.high)
            )
          )
            return 'volume: integer|Long expected';
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (
            !$util.isInteger(message.bidVolume) &&
            !(
              message.bidVolume &&
              $util.isInteger(message.bidVolume.low) &&
              $util.isInteger(message.bidVolume.high)
            )
          )
            return 'bidVolume: integer|Long expected';
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (
            !$util.isInteger(message.askVolume) &&
            !(
              message.askVolume &&
              $util.isInteger(message.askVolume.low) &&
              $util.isInteger(message.askVolume.high)
            )
          )
            return 'askVolume: integer|Long expected';
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          if (typeof message.openPrice !== 'number')
            return 'openPrice: number expected';
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          if (typeof message.closePrice !== 'number')
            return 'closePrice: number expected';
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          if (typeof message.highPrice !== 'number')
            return 'highPrice: number expected';
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          if (typeof message.lowPrice !== 'number')
            return 'lowPrice: number expected';
        if (
          message.customSessionOpenSsm != null &&
          message.hasOwnProperty('customSessionOpenSsm')
        )
          if (!$util.isInteger(message.customSessionOpenSsm))
            return 'customSessionOpenSsm: integer expected';
        if (
          message.dataBarSsboe != null &&
          message.hasOwnProperty('dataBarSsboe')
        ) {
          if (!Array.isArray(message.dataBarSsboe))
            return 'dataBarSsboe: array expected';
          for (var i = 0; i < message.dataBarSsboe.length; ++i)
            if (!$util.isInteger(message.dataBarSsboe[i]))
              return 'dataBarSsboe: integer[] expected';
        }
        if (
          message.dataBarUsecs != null &&
          message.hasOwnProperty('dataBarUsecs')
        ) {
          if (!Array.isArray(message.dataBarUsecs))
            return 'dataBarUsecs: array expected';
          for (var i = 0; i < message.dataBarUsecs.length; ++i)
            if (!$util.isInteger(message.dataBarUsecs[i]))
              return 'dataBarUsecs: integer[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseTickBarReplay message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseTickBarReplay} ResponseTickBarReplay
       */
      ResponseTickBarReplay.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseTickBarReplay) return object;
        var message = new $root.rti.ResponseTickBarReplay();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.requestKey != null)
          message.requestKey = String(object.requestKey);
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseTickBarReplay.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseTickBarReplay.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseTickBarReplay.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.type) {
          case 'TICK_BAR':
          case 1:
            message.type = 1;
            break;
          case 'RANGE_BAR':
          case 2:
            message.type = 2;
            break;
          case 'VOLUME_BAR':
          case 3:
            message.type = 3;
            break;
        }
        switch (object.subType) {
          case 'REGULAR':
          case 1:
            message.subType = 1;
            break;
          case 'CUSTOM':
          case 2:
            message.subType = 2;
            break;
        }
        if (object.typeSpecifier != null)
          message.typeSpecifier = String(object.typeSpecifier);
        if (object.numTrades != null)
          if ($util.Long)
            (message.numTrades = $util.Long.fromValue(
              object.numTrades,
            )).unsigned = true;
          else if (typeof object.numTrades === 'string')
            message.numTrades = parseInt(object.numTrades, 10);
          else if (typeof object.numTrades === 'number')
            message.numTrades = object.numTrades;
          else if (typeof object.numTrades === 'object')
            message.numTrades = new $util.LongBits(
              object.numTrades.low >>> 0,
              object.numTrades.high >>> 0,
            ).toNumber(true);
        if (object.volume != null)
          if ($util.Long)
            (message.volume = $util.Long.fromValue(object.volume)).unsigned =
              true;
          else if (typeof object.volume === 'string')
            message.volume = parseInt(object.volume, 10);
          else if (typeof object.volume === 'number')
            message.volume = object.volume;
          else if (typeof object.volume === 'object')
            message.volume = new $util.LongBits(
              object.volume.low >>> 0,
              object.volume.high >>> 0,
            ).toNumber(true);
        if (object.bidVolume != null)
          if ($util.Long)
            (message.bidVolume = $util.Long.fromValue(
              object.bidVolume,
            )).unsigned = true;
          else if (typeof object.bidVolume === 'string')
            message.bidVolume = parseInt(object.bidVolume, 10);
          else if (typeof object.bidVolume === 'number')
            message.bidVolume = object.bidVolume;
          else if (typeof object.bidVolume === 'object')
            message.bidVolume = new $util.LongBits(
              object.bidVolume.low >>> 0,
              object.bidVolume.high >>> 0,
            ).toNumber(true);
        if (object.askVolume != null)
          if ($util.Long)
            (message.askVolume = $util.Long.fromValue(
              object.askVolume,
            )).unsigned = true;
          else if (typeof object.askVolume === 'string')
            message.askVolume = parseInt(object.askVolume, 10);
          else if (typeof object.askVolume === 'number')
            message.askVolume = object.askVolume;
          else if (typeof object.askVolume === 'object')
            message.askVolume = new $util.LongBits(
              object.askVolume.low >>> 0,
              object.askVolume.high >>> 0,
            ).toNumber(true);
        if (object.openPrice != null)
          message.openPrice = Number(object.openPrice);
        if (object.closePrice != null)
          message.closePrice = Number(object.closePrice);
        if (object.highPrice != null)
          message.highPrice = Number(object.highPrice);
        if (object.lowPrice != null) message.lowPrice = Number(object.lowPrice);
        if (object.customSessionOpenSsm != null)
          message.customSessionOpenSsm = object.customSessionOpenSsm | 0;
        if (object.dataBarSsboe) {
          if (!Array.isArray(object.dataBarSsboe))
            throw TypeError(
              '.rti.ResponseTickBarReplay.dataBarSsboe: array expected',
            );
          message.dataBarSsboe = [];
          for (var i = 0; i < object.dataBarSsboe.length; ++i)
            message.dataBarSsboe[i] = object.dataBarSsboe[i] | 0;
        }
        if (object.dataBarUsecs) {
          if (!Array.isArray(object.dataBarUsecs))
            throw TypeError(
              '.rti.ResponseTickBarReplay.dataBarUsecs: array expected',
            );
          message.dataBarUsecs = [];
          for (var i = 0; i < object.dataBarUsecs.length; ++i)
            message.dataBarUsecs[i] = object.dataBarUsecs[i] | 0;
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseTickBarReplay message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {rti.ResponseTickBarReplay} message ResponseTickBarReplay
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseTickBarReplay.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.dataBarSsboe = [];
          object.dataBarUsecs = [];
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.highPrice = 0;
          object.lowPrice = 0;
          object.openPrice = 0;
          object.closePrice = 0;
          object.symbol = '';
          object.exchange = '';
          object.type = options.enums === String ? 'TICK_BAR' : 1;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.numTrades =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.numTrades = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.volume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.volume = options.longs === String ? '0' : 0;
          object.subType = options.enums === String ? 'REGULAR' : 1;
          object.customSessionOpenSsm = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.bidVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.bidVolume = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.askVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.askVolume = options.longs === String ? '0' : 0;
          object.requestKey = '';
          object.typeSpecifier = '';
          object.templateId = 0;
        }
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          object.highPrice =
            options.json && !isFinite(message.highPrice)
              ? String(message.highPrice)
              : message.highPrice;
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          object.lowPrice =
            options.json && !isFinite(message.lowPrice)
              ? String(message.lowPrice)
              : message.lowPrice;
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          object.openPrice =
            options.json && !isFinite(message.openPrice)
              ? String(message.openPrice)
              : message.openPrice;
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          object.closePrice =
            options.json && !isFinite(message.closePrice)
              ? String(message.closePrice)
              : message.closePrice;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.type != null && message.hasOwnProperty('type'))
          object.type =
            options.enums === String
              ? $root.rti.ResponseTickBarReplay.BarType[message.type]
              : message.type;
        if (message.dataBarSsboe && message.dataBarSsboe.length) {
          object.dataBarSsboe = [];
          for (var j = 0; j < message.dataBarSsboe.length; ++j)
            object.dataBarSsboe[j] = message.dataBarSsboe[j];
        }
        if (message.dataBarUsecs && message.dataBarUsecs.length) {
          object.dataBarUsecs = [];
          for (var j = 0; j < message.dataBarUsecs.length; ++j)
            object.dataBarUsecs[j] = message.dataBarUsecs[j];
        }
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (typeof message.numTrades === 'number')
            object.numTrades =
              options.longs === String
                ? String(message.numTrades)
                : message.numTrades;
          else
            object.numTrades =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.numTrades)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.numTrades.low >>> 0,
                      message.numTrades.high >>> 0,
                    ).toNumber(true)
                  : message.numTrades;
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (typeof message.volume === 'number')
            object.volume =
              options.longs === String
                ? String(message.volume)
                : message.volume;
          else
            object.volume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.volume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.volume.low >>> 0,
                      message.volume.high >>> 0,
                    ).toNumber(true)
                  : message.volume;
        if (message.subType != null && message.hasOwnProperty('subType'))
          object.subType =
            options.enums === String
              ? $root.rti.ResponseTickBarReplay.BarSubType[message.subType]
              : message.subType;
        if (
          message.customSessionOpenSsm != null &&
          message.hasOwnProperty('customSessionOpenSsm')
        )
          object.customSessionOpenSsm = message.customSessionOpenSsm;
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (typeof message.bidVolume === 'number')
            object.bidVolume =
              options.longs === String
                ? String(message.bidVolume)
                : message.bidVolume;
          else
            object.bidVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.bidVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.bidVolume.low >>> 0,
                      message.bidVolume.high >>> 0,
                    ).toNumber(true)
                  : message.bidVolume;
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (typeof message.askVolume === 'number')
            object.askVolume =
              options.longs === String
                ? String(message.askVolume)
                : message.askVolume;
          else
            object.askVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.askVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.askVolume.low >>> 0,
                      message.askVolume.high >>> 0,
                    ).toNumber(true)
                  : message.askVolume;
        if (message.requestKey != null && message.hasOwnProperty('requestKey'))
          object.requestKey = message.requestKey;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.typeSpecifier != null &&
          message.hasOwnProperty('typeSpecifier')
        )
          object.typeSpecifier = message.typeSpecifier;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseTickBarReplay to JSON.
       * @function toJSON
       * @memberof rti.ResponseTickBarReplay
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseTickBarReplay.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseTickBarReplay
       * @function getTypeUrl
       * @memberof rti.ResponseTickBarReplay
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseTickBarReplay.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseTickBarReplay';
      };

      /**
       * BarType enum.
       * @name rti.ResponseTickBarReplay.BarType
       * @enum {number}
       * @property {number} TICK_BAR=1 TICK_BAR value
       * @property {number} RANGE_BAR=2 RANGE_BAR value
       * @property {number} VOLUME_BAR=3 VOLUME_BAR value
       */
      ResponseTickBarReplay.BarType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'TICK_BAR')] = 1;
        values[(valuesById[2] = 'RANGE_BAR')] = 2;
        values[(valuesById[3] = 'VOLUME_BAR')] = 3;
        return values;
      })();

      /**
       * BarSubType enum.
       * @name rti.ResponseTickBarReplay.BarSubType
       * @enum {number}
       * @property {number} REGULAR=1 REGULAR value
       * @property {number} CUSTOM=2 CUSTOM value
       */
      ResponseTickBarReplay.BarSubType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'REGULAR')] = 1;
        values[(valuesById[2] = 'CUSTOM')] = 2;
        return values;
      })();

      return ResponseTickBarReplay;
    })();

    rti.RequestTickBarUpdate = (function () {
      /**
       * Properties of a RequestTickBarUpdate.
       * @memberof rti
       * @interface IRequestTickBarUpdate
       * @property {number} templateId RequestTickBarUpdate templateId
       * @property {Array.<string>|null} [userMsg] RequestTickBarUpdate userMsg
       * @property {string|null} [symbol] RequestTickBarUpdate symbol
       * @property {string|null} [exchange] RequestTickBarUpdate exchange
       * @property {rti.RequestTickBarUpdate.Request|null} [request] RequestTickBarUpdate request
       * @property {rti.RequestTickBarUpdate.BarType|null} [barType] RequestTickBarUpdate barType
       * @property {rti.RequestTickBarUpdate.BarSubType|null} [barSubType] RequestTickBarUpdate barSubType
       * @property {string|null} [barTypeSpecifier] RequestTickBarUpdate barTypeSpecifier
       * @property {number|null} [customSessionOpenSsm] RequestTickBarUpdate customSessionOpenSsm
       * @property {number|null} [customSessionCloseSsm] RequestTickBarUpdate customSessionCloseSsm
       */

      /**
       * Constructs a new RequestTickBarUpdate.
       * @memberof rti
       * @classdesc Represents a RequestTickBarUpdate.
       * @implements IRequestTickBarUpdate
       * @constructor
       * @param {rti.IRequestTickBarUpdate=} [properties] Properties to set
       */
      function RequestTickBarUpdate(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestTickBarUpdate templateId.
       * @member {number} templateId
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.templateId = 0;

      /**
       * RequestTickBarUpdate userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.userMsg = $util.emptyArray;

      /**
       * RequestTickBarUpdate symbol.
       * @member {string} symbol
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.symbol = '';

      /**
       * RequestTickBarUpdate exchange.
       * @member {string} exchange
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.exchange = '';

      /**
       * RequestTickBarUpdate request.
       * @member {rti.RequestTickBarUpdate.Request} request
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.request = 1;

      /**
       * RequestTickBarUpdate barType.
       * @member {rti.RequestTickBarUpdate.BarType} barType
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.barType = 1;

      /**
       * RequestTickBarUpdate barSubType.
       * @member {rti.RequestTickBarUpdate.BarSubType} barSubType
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.barSubType = 1;

      /**
       * RequestTickBarUpdate barTypeSpecifier.
       * @member {string} barTypeSpecifier
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.barTypeSpecifier = '';

      /**
       * RequestTickBarUpdate customSessionOpenSsm.
       * @member {number} customSessionOpenSsm
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.customSessionOpenSsm = 0;

      /**
       * RequestTickBarUpdate customSessionCloseSsm.
       * @member {number} customSessionCloseSsm
       * @memberof rti.RequestTickBarUpdate
       * @instance
       */
      RequestTickBarUpdate.prototype.customSessionCloseSsm = 0;

      /**
       * Creates a new RequestTickBarUpdate instance using the specified properties.
       * @function create
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {rti.IRequestTickBarUpdate=} [properties] Properties to set
       * @returns {rti.RequestTickBarUpdate} RequestTickBarUpdate instance
       */
      RequestTickBarUpdate.create = function create(properties) {
        return new RequestTickBarUpdate(properties);
      };

      /**
       * Encodes the specified RequestTickBarUpdate message. Does not implicitly {@link rti.RequestTickBarUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {rti.IRequestTickBarUpdate} message RequestTickBarUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTickBarUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.request != null &&
          Object.hasOwnProperty.call(message, 'request')
        )
          writer
            .uint32(/* id 100000, wireType 0 =*/ 800000)
            .int32(message.request);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.barType != null &&
          Object.hasOwnProperty.call(message, 'barType')
        )
          writer
            .uint32(/* id 119200, wireType 0 =*/ 953600)
            .int32(message.barType);
        if (
          message.barSubType != null &&
          Object.hasOwnProperty.call(message, 'barSubType')
        )
          writer
            .uint32(/* id 119208, wireType 0 =*/ 953664)
            .int32(message.barSubType);
        if (
          message.customSessionOpenSsm != null &&
          Object.hasOwnProperty.call(message, 'customSessionOpenSsm')
        )
          writer
            .uint32(/* id 119209, wireType 0 =*/ 953672)
            .int32(message.customSessionOpenSsm);
        if (
          message.customSessionCloseSsm != null &&
          Object.hasOwnProperty.call(message, 'customSessionCloseSsm')
        )
          writer
            .uint32(/* id 119210, wireType 0 =*/ 953680)
            .int32(message.customSessionCloseSsm);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.barTypeSpecifier != null &&
          Object.hasOwnProperty.call(message, 'barTypeSpecifier')
        )
          writer
            .uint32(/* id 148162, wireType 2 =*/ 1185298)
            .string(message.barTypeSpecifier);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestTickBarUpdate message, length delimited. Does not implicitly {@link rti.RequestTickBarUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {rti.IRequestTickBarUpdate} message RequestTickBarUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTickBarUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestTickBarUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestTickBarUpdate} RequestTickBarUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTickBarUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestTickBarUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100000:
              message.request = reader.int32();
              break;
            case 119200:
              message.barType = reader.int32();
              break;
            case 119208:
              message.barSubType = reader.int32();
              break;
            case 148162:
              message.barTypeSpecifier = reader.string();
              break;
            case 119209:
              message.customSessionOpenSsm = reader.int32();
              break;
            case 119210:
              message.customSessionCloseSsm = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestTickBarUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestTickBarUpdate} RequestTickBarUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTickBarUpdate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestTickBarUpdate message.
       * @function verify
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestTickBarUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.request != null && message.hasOwnProperty('request'))
          switch (message.request) {
            default:
              return 'request: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.barType != null && message.hasOwnProperty('barType'))
          switch (message.barType) {
            default:
              return 'barType: enum value expected';
            case 1:
            case 2:
            case 3:
              break;
          }
        if (message.barSubType != null && message.hasOwnProperty('barSubType'))
          switch (message.barSubType) {
            default:
              return 'barSubType: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.barTypeSpecifier != null &&
          message.hasOwnProperty('barTypeSpecifier')
        )
          if (!$util.isString(message.barTypeSpecifier))
            return 'barTypeSpecifier: string expected';
        if (
          message.customSessionOpenSsm != null &&
          message.hasOwnProperty('customSessionOpenSsm')
        )
          if (!$util.isInteger(message.customSessionOpenSsm))
            return 'customSessionOpenSsm: integer expected';
        if (
          message.customSessionCloseSsm != null &&
          message.hasOwnProperty('customSessionCloseSsm')
        )
          if (!$util.isInteger(message.customSessionCloseSsm))
            return 'customSessionCloseSsm: integer expected';
        return null;
      };

      /**
       * Creates a RequestTickBarUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestTickBarUpdate} RequestTickBarUpdate
       */
      RequestTickBarUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestTickBarUpdate) return object;
        var message = new $root.rti.RequestTickBarUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestTickBarUpdate.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.request) {
          case 'SUBSCRIBE':
          case 1:
            message.request = 1;
            break;
          case 'UNSUBSCRIBE':
          case 2:
            message.request = 2;
            break;
        }
        switch (object.barType) {
          case 'TICK_BAR':
          case 1:
            message.barType = 1;
            break;
          case 'RANGE_BAR':
          case 2:
            message.barType = 2;
            break;
          case 'VOLUME_BAR':
          case 3:
            message.barType = 3;
            break;
        }
        switch (object.barSubType) {
          case 'REGULAR':
          case 1:
            message.barSubType = 1;
            break;
          case 'CUSTOM':
          case 2:
            message.barSubType = 2;
            break;
        }
        if (object.barTypeSpecifier != null)
          message.barTypeSpecifier = String(object.barTypeSpecifier);
        if (object.customSessionOpenSsm != null)
          message.customSessionOpenSsm = object.customSessionOpenSsm | 0;
        if (object.customSessionCloseSsm != null)
          message.customSessionCloseSsm = object.customSessionCloseSsm | 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestTickBarUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {rti.RequestTickBarUpdate} message RequestTickBarUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestTickBarUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.request = options.enums === String ? 'SUBSCRIBE' : 1;
          object.symbol = '';
          object.exchange = '';
          object.barType = options.enums === String ? 'TICK_BAR' : 1;
          object.barSubType = options.enums === String ? 'REGULAR' : 1;
          object.customSessionOpenSsm = 0;
          object.customSessionCloseSsm = 0;
          object.barTypeSpecifier = '';
          object.templateId = 0;
        }
        if (message.request != null && message.hasOwnProperty('request'))
          object.request =
            options.enums === String
              ? $root.rti.RequestTickBarUpdate.Request[message.request]
              : message.request;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.barType != null && message.hasOwnProperty('barType'))
          object.barType =
            options.enums === String
              ? $root.rti.RequestTickBarUpdate.BarType[message.barType]
              : message.barType;
        if (message.barSubType != null && message.hasOwnProperty('barSubType'))
          object.barSubType =
            options.enums === String
              ? $root.rti.RequestTickBarUpdate.BarSubType[message.barSubType]
              : message.barSubType;
        if (
          message.customSessionOpenSsm != null &&
          message.hasOwnProperty('customSessionOpenSsm')
        )
          object.customSessionOpenSsm = message.customSessionOpenSsm;
        if (
          message.customSessionCloseSsm != null &&
          message.hasOwnProperty('customSessionCloseSsm')
        )
          object.customSessionCloseSsm = message.customSessionCloseSsm;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.barTypeSpecifier != null &&
          message.hasOwnProperty('barTypeSpecifier')
        )
          object.barTypeSpecifier = message.barTypeSpecifier;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestTickBarUpdate to JSON.
       * @function toJSON
       * @memberof rti.RequestTickBarUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestTickBarUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestTickBarUpdate
       * @function getTypeUrl
       * @memberof rti.RequestTickBarUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestTickBarUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestTickBarUpdate';
      };

      /**
       * BarType enum.
       * @name rti.RequestTickBarUpdate.BarType
       * @enum {number}
       * @property {number} TICK_BAR=1 TICK_BAR value
       * @property {number} RANGE_BAR=2 RANGE_BAR value
       * @property {number} VOLUME_BAR=3 VOLUME_BAR value
       */
      RequestTickBarUpdate.BarType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'TICK_BAR')] = 1;
        values[(valuesById[2] = 'RANGE_BAR')] = 2;
        values[(valuesById[3] = 'VOLUME_BAR')] = 3;
        return values;
      })();

      /**
       * BarSubType enum.
       * @name rti.RequestTickBarUpdate.BarSubType
       * @enum {number}
       * @property {number} REGULAR=1 REGULAR value
       * @property {number} CUSTOM=2 CUSTOM value
       */
      RequestTickBarUpdate.BarSubType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'REGULAR')] = 1;
        values[(valuesById[2] = 'CUSTOM')] = 2;
        return values;
      })();

      /**
       * Request enum.
       * @name rti.RequestTickBarUpdate.Request
       * @enum {number}
       * @property {number} SUBSCRIBE=1 SUBSCRIBE value
       * @property {number} UNSUBSCRIBE=2 UNSUBSCRIBE value
       */
      RequestTickBarUpdate.Request = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SUBSCRIBE')] = 1;
        values[(valuesById[2] = 'UNSUBSCRIBE')] = 2;
        return values;
      })();

      return RequestTickBarUpdate;
    })();

    rti.ResponseTickBarUpdate = (function () {
      /**
       * Properties of a ResponseTickBarUpdate.
       * @memberof rti
       * @interface IResponseTickBarUpdate
       * @property {number} templateId ResponseTickBarUpdate templateId
       * @property {Array.<string>|null} [userMsg] ResponseTickBarUpdate userMsg
       * @property {Array.<string>|null} [rpCode] ResponseTickBarUpdate rpCode
       */

      /**
       * Constructs a new ResponseTickBarUpdate.
       * @memberof rti
       * @classdesc Represents a ResponseTickBarUpdate.
       * @implements IResponseTickBarUpdate
       * @constructor
       * @param {rti.IResponseTickBarUpdate=} [properties] Properties to set
       */
      function ResponseTickBarUpdate(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseTickBarUpdate templateId.
       * @member {number} templateId
       * @memberof rti.ResponseTickBarUpdate
       * @instance
       */
      ResponseTickBarUpdate.prototype.templateId = 0;

      /**
       * ResponseTickBarUpdate userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseTickBarUpdate
       * @instance
       */
      ResponseTickBarUpdate.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseTickBarUpdate rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseTickBarUpdate
       * @instance
       */
      ResponseTickBarUpdate.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseTickBarUpdate instance using the specified properties.
       * @function create
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {rti.IResponseTickBarUpdate=} [properties] Properties to set
       * @returns {rti.ResponseTickBarUpdate} ResponseTickBarUpdate instance
       */
      ResponseTickBarUpdate.create = function create(properties) {
        return new ResponseTickBarUpdate(properties);
      };

      /**
       * Encodes the specified ResponseTickBarUpdate message. Does not implicitly {@link rti.ResponseTickBarUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {rti.IResponseTickBarUpdate} message ResponseTickBarUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTickBarUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseTickBarUpdate message, length delimited. Does not implicitly {@link rti.ResponseTickBarUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {rti.IResponseTickBarUpdate} message ResponseTickBarUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTickBarUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseTickBarUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseTickBarUpdate} ResponseTickBarUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTickBarUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseTickBarUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseTickBarUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseTickBarUpdate} ResponseTickBarUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTickBarUpdate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseTickBarUpdate message.
       * @function verify
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseTickBarUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseTickBarUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseTickBarUpdate} ResponseTickBarUpdate
       */
      ResponseTickBarUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseTickBarUpdate) return object;
        var message = new $root.rti.ResponseTickBarUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseTickBarUpdate.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseTickBarUpdate.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseTickBarUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {rti.ResponseTickBarUpdate} message ResponseTickBarUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseTickBarUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseTickBarUpdate to JSON.
       * @function toJSON
       * @memberof rti.ResponseTickBarUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseTickBarUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseTickBarUpdate
       * @function getTypeUrl
       * @memberof rti.ResponseTickBarUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseTickBarUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseTickBarUpdate';
      };

      return ResponseTickBarUpdate;
    })();

    rti.RequestTimeBarReplay = (function () {
      /**
       * Properties of a RequestTimeBarReplay.
       * @memberof rti
       * @interface IRequestTimeBarReplay
       * @property {number} templateId RequestTimeBarReplay templateId
       * @property {Array.<string>|null} [userMsg] RequestTimeBarReplay userMsg
       * @property {string|null} [symbol] RequestTimeBarReplay symbol
       * @property {string|null} [exchange] RequestTimeBarReplay exchange
       * @property {rti.RequestTimeBarReplay.BarType|null} [barType] RequestTimeBarReplay barType
       * @property {number|null} [barTypePeriod] RequestTimeBarReplay barTypePeriod
       * @property {number|null} [startIndex] RequestTimeBarReplay startIndex
       * @property {number|null} [finishIndex] RequestTimeBarReplay finishIndex
       * @property {number|null} [userMaxCount] RequestTimeBarReplay userMaxCount
       * @property {rti.RequestTimeBarReplay.Direction|null} [direction] RequestTimeBarReplay direction
       * @property {rti.RequestTimeBarReplay.TimeOrder|null} [timeOrder] RequestTimeBarReplay timeOrder
       * @property {boolean|null} [resumeBars] RequestTimeBarReplay resumeBars
       */

      /**
       * Constructs a new RequestTimeBarReplay.
       * @memberof rti
       * @classdesc Represents a RequestTimeBarReplay.
       * @implements IRequestTimeBarReplay
       * @constructor
       * @param {rti.IRequestTimeBarReplay=} [properties] Properties to set
       */
      function RequestTimeBarReplay(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestTimeBarReplay templateId.
       * @member {number} templateId
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.templateId = 0;

      /**
       * RequestTimeBarReplay userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.userMsg = $util.emptyArray;

      /**
       * RequestTimeBarReplay symbol.
       * @member {string} symbol
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.symbol = '';

      /**
       * RequestTimeBarReplay exchange.
       * @member {string} exchange
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.exchange = '';

      /**
       * RequestTimeBarReplay barType.
       * @member {rti.RequestTimeBarReplay.BarType} barType
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.barType = 1;

      /**
       * RequestTimeBarReplay barTypePeriod.
       * @member {number} barTypePeriod
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.barTypePeriod = 0;

      /**
       * RequestTimeBarReplay startIndex.
       * @member {number} startIndex
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.startIndex = 0;

      /**
       * RequestTimeBarReplay finishIndex.
       * @member {number} finishIndex
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.finishIndex = 0;

      /**
       * RequestTimeBarReplay userMaxCount.
       * @member {number} userMaxCount
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.userMaxCount = 0;

      /**
       * RequestTimeBarReplay direction.
       * @member {rti.RequestTimeBarReplay.Direction} direction
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.direction = 1;

      /**
       * RequestTimeBarReplay timeOrder.
       * @member {rti.RequestTimeBarReplay.TimeOrder} timeOrder
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.timeOrder = 1;

      /**
       * RequestTimeBarReplay resumeBars.
       * @member {boolean} resumeBars
       * @memberof rti.RequestTimeBarReplay
       * @instance
       */
      RequestTimeBarReplay.prototype.resumeBars = false;

      /**
       * Creates a new RequestTimeBarReplay instance using the specified properties.
       * @function create
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {rti.IRequestTimeBarReplay=} [properties] Properties to set
       * @returns {rti.RequestTimeBarReplay} RequestTimeBarReplay instance
       */
      RequestTimeBarReplay.create = function create(properties) {
        return new RequestTimeBarReplay(properties);
      };

      /**
       * Encodes the specified RequestTimeBarReplay message. Does not implicitly {@link rti.RequestTimeBarReplay.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {rti.IRequestTimeBarReplay} message RequestTimeBarReplay message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTimeBarReplay.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.barTypePeriod != null &&
          Object.hasOwnProperty.call(message, 'barTypePeriod')
        )
          writer
            .uint32(/* id 119112, wireType 0 =*/ 952896)
            .int32(message.barTypePeriod);
        if (
          message.barType != null &&
          Object.hasOwnProperty.call(message, 'barType')
        )
          writer
            .uint32(/* id 119200, wireType 0 =*/ 953600)
            .int32(message.barType);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.direction != null &&
          Object.hasOwnProperty.call(message, 'direction')
        )
          writer
            .uint32(/* id 149253, wireType 0 =*/ 1194024)
            .int32(message.direction);
        if (
          message.timeOrder != null &&
          Object.hasOwnProperty.call(message, 'timeOrder')
        )
          writer
            .uint32(/* id 149307, wireType 0 =*/ 1194456)
            .int32(message.timeOrder);
        if (
          message.startIndex != null &&
          Object.hasOwnProperty.call(message, 'startIndex')
        )
          writer
            .uint32(/* id 153002, wireType 0 =*/ 1224016)
            .int32(message.startIndex);
        if (
          message.finishIndex != null &&
          Object.hasOwnProperty.call(message, 'finishIndex')
        )
          writer
            .uint32(/* id 153003, wireType 0 =*/ 1224024)
            .int32(message.finishIndex);
        if (
          message.resumeBars != null &&
          Object.hasOwnProperty.call(message, 'resumeBars')
        )
          writer
            .uint32(/* id 153642, wireType 0 =*/ 1229136)
            .bool(message.resumeBars);
        if (
          message.userMaxCount != null &&
          Object.hasOwnProperty.call(message, 'userMaxCount')
        )
          writer
            .uint32(/* id 154020, wireType 0 =*/ 1232160)
            .int32(message.userMaxCount);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestTimeBarReplay message, length delimited. Does not implicitly {@link rti.RequestTimeBarReplay.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {rti.IRequestTimeBarReplay} message RequestTimeBarReplay message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTimeBarReplay.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestTimeBarReplay message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestTimeBarReplay} RequestTimeBarReplay
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTimeBarReplay.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestTimeBarReplay();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 119200:
              message.barType = reader.int32();
              break;
            case 119112:
              message.barTypePeriod = reader.int32();
              break;
            case 153002:
              message.startIndex = reader.int32();
              break;
            case 153003:
              message.finishIndex = reader.int32();
              break;
            case 154020:
              message.userMaxCount = reader.int32();
              break;
            case 149253:
              message.direction = reader.int32();
              break;
            case 149307:
              message.timeOrder = reader.int32();
              break;
            case 153642:
              message.resumeBars = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestTimeBarReplay message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestTimeBarReplay} RequestTimeBarReplay
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTimeBarReplay.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestTimeBarReplay message.
       * @function verify
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestTimeBarReplay.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.barType != null && message.hasOwnProperty('barType'))
          switch (message.barType) {
            default:
              return 'barType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.barTypePeriod != null &&
          message.hasOwnProperty('barTypePeriod')
        )
          if (!$util.isInteger(message.barTypePeriod))
            return 'barTypePeriod: integer expected';
        if (message.startIndex != null && message.hasOwnProperty('startIndex'))
          if (!$util.isInteger(message.startIndex))
            return 'startIndex: integer expected';
        if (
          message.finishIndex != null &&
          message.hasOwnProperty('finishIndex')
        )
          if (!$util.isInteger(message.finishIndex))
            return 'finishIndex: integer expected';
        if (
          message.userMaxCount != null &&
          message.hasOwnProperty('userMaxCount')
        )
          if (!$util.isInteger(message.userMaxCount))
            return 'userMaxCount: integer expected';
        if (message.direction != null && message.hasOwnProperty('direction'))
          switch (message.direction) {
            default:
              return 'direction: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.timeOrder != null && message.hasOwnProperty('timeOrder'))
          switch (message.timeOrder) {
            default:
              return 'timeOrder: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.resumeBars != null && message.hasOwnProperty('resumeBars'))
          if (typeof message.resumeBars !== 'boolean')
            return 'resumeBars: boolean expected';
        return null;
      };

      /**
       * Creates a RequestTimeBarReplay message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestTimeBarReplay} RequestTimeBarReplay
       */
      RequestTimeBarReplay.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestTimeBarReplay) return object;
        var message = new $root.rti.RequestTimeBarReplay();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestTimeBarReplay.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.barType) {
          case 'SECOND_BAR':
          case 1:
            message.barType = 1;
            break;
          case 'MINUTE_BAR':
          case 2:
            message.barType = 2;
            break;
          case 'DAILY_BAR':
          case 3:
            message.barType = 3;
            break;
          case 'WEEKLY_BAR':
          case 4:
            message.barType = 4;
            break;
        }
        if (object.barTypePeriod != null)
          message.barTypePeriod = object.barTypePeriod | 0;
        if (object.startIndex != null)
          message.startIndex = object.startIndex | 0;
        if (object.finishIndex != null)
          message.finishIndex = object.finishIndex | 0;
        if (object.userMaxCount != null)
          message.userMaxCount = object.userMaxCount | 0;
        switch (object.direction) {
          case 'FIRST':
          case 1:
            message.direction = 1;
            break;
          case 'LAST':
          case 2:
            message.direction = 2;
            break;
        }
        switch (object.timeOrder) {
          case 'FORWARDS':
          case 1:
            message.timeOrder = 1;
            break;
          case 'BACKWARDS':
          case 2:
            message.timeOrder = 2;
            break;
        }
        if (object.resumeBars != null)
          message.resumeBars = Boolean(object.resumeBars);
        return message;
      };

      /**
       * Creates a plain object from a RequestTimeBarReplay message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {rti.RequestTimeBarReplay} message RequestTimeBarReplay
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestTimeBarReplay.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.barTypePeriod = 0;
          object.barType = options.enums === String ? 'SECOND_BAR' : 1;
          object.direction = options.enums === String ? 'FIRST' : 1;
          object.timeOrder = options.enums === String ? 'FORWARDS' : 1;
          object.startIndex = 0;
          object.finishIndex = 0;
          object.resumeBars = false;
          object.userMaxCount = 0;
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.barTypePeriod != null &&
          message.hasOwnProperty('barTypePeriod')
        )
          object.barTypePeriod = message.barTypePeriod;
        if (message.barType != null && message.hasOwnProperty('barType'))
          object.barType =
            options.enums === String
              ? $root.rti.RequestTimeBarReplay.BarType[message.barType]
              : message.barType;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.direction != null && message.hasOwnProperty('direction'))
          object.direction =
            options.enums === String
              ? $root.rti.RequestTimeBarReplay.Direction[message.direction]
              : message.direction;
        if (message.timeOrder != null && message.hasOwnProperty('timeOrder'))
          object.timeOrder =
            options.enums === String
              ? $root.rti.RequestTimeBarReplay.TimeOrder[message.timeOrder]
              : message.timeOrder;
        if (message.startIndex != null && message.hasOwnProperty('startIndex'))
          object.startIndex = message.startIndex;
        if (
          message.finishIndex != null &&
          message.hasOwnProperty('finishIndex')
        )
          object.finishIndex = message.finishIndex;
        if (message.resumeBars != null && message.hasOwnProperty('resumeBars'))
          object.resumeBars = message.resumeBars;
        if (
          message.userMaxCount != null &&
          message.hasOwnProperty('userMaxCount')
        )
          object.userMaxCount = message.userMaxCount;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestTimeBarReplay to JSON.
       * @function toJSON
       * @memberof rti.RequestTimeBarReplay
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestTimeBarReplay.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestTimeBarReplay
       * @function getTypeUrl
       * @memberof rti.RequestTimeBarReplay
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestTimeBarReplay.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestTimeBarReplay';
      };

      /**
       * BarType enum.
       * @name rti.RequestTimeBarReplay.BarType
       * @enum {number}
       * @property {number} SECOND_BAR=1 SECOND_BAR value
       * @property {number} MINUTE_BAR=2 MINUTE_BAR value
       * @property {number} DAILY_BAR=3 DAILY_BAR value
       * @property {number} WEEKLY_BAR=4 WEEKLY_BAR value
       */
      RequestTimeBarReplay.BarType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SECOND_BAR')] = 1;
        values[(valuesById[2] = 'MINUTE_BAR')] = 2;
        values[(valuesById[3] = 'DAILY_BAR')] = 3;
        values[(valuesById[4] = 'WEEKLY_BAR')] = 4;
        return values;
      })();

      /**
       * Direction enum.
       * @name rti.RequestTimeBarReplay.Direction
       * @enum {number}
       * @property {number} FIRST=1 FIRST value
       * @property {number} LAST=2 LAST value
       */
      RequestTimeBarReplay.Direction = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'FIRST')] = 1;
        values[(valuesById[2] = 'LAST')] = 2;
        return values;
      })();

      /**
       * TimeOrder enum.
       * @name rti.RequestTimeBarReplay.TimeOrder
       * @enum {number}
       * @property {number} FORWARDS=1 FORWARDS value
       * @property {number} BACKWARDS=2 BACKWARDS value
       */
      RequestTimeBarReplay.TimeOrder = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'FORWARDS')] = 1;
        values[(valuesById[2] = 'BACKWARDS')] = 2;
        return values;
      })();

      return RequestTimeBarReplay;
    })();

    rti.ResponseTimeBarReplay = (function () {
      /**
       * Properties of a ResponseTimeBarReplay.
       * @memberof rti
       * @interface IResponseTimeBarReplay
       * @property {number} templateId ResponseTimeBarReplay templateId
       * @property {string|null} [requestKey] ResponseTimeBarReplay requestKey
       * @property {Array.<string>|null} [userMsg] ResponseTimeBarReplay userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseTimeBarReplay rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseTimeBarReplay rpCode
       * @property {string|null} [symbol] ResponseTimeBarReplay symbol
       * @property {string|null} [exchange] ResponseTimeBarReplay exchange
       * @property {rti.ResponseTimeBarReplay.BarType|null} [type] ResponseTimeBarReplay type
       * @property {string|null} [period] ResponseTimeBarReplay period
       * @property {number|null} [marker] ResponseTimeBarReplay marker
       * @property {number|Long|null} [numTrades] ResponseTimeBarReplay numTrades
       * @property {number|Long|null} [volume] ResponseTimeBarReplay volume
       * @property {number|Long|null} [bidVolume] ResponseTimeBarReplay bidVolume
       * @property {number|Long|null} [askVolume] ResponseTimeBarReplay askVolume
       * @property {number|null} [openPrice] ResponseTimeBarReplay openPrice
       * @property {number|null} [closePrice] ResponseTimeBarReplay closePrice
       * @property {number|null} [highPrice] ResponseTimeBarReplay highPrice
       * @property {number|null} [lowPrice] ResponseTimeBarReplay lowPrice
       * @property {number|null} [settlementPrice] ResponseTimeBarReplay settlementPrice
       * @property {boolean|null} [hasSettlementPrice] ResponseTimeBarReplay hasSettlementPrice
       * @property {boolean|null} [mustClearSettlementPrice] ResponseTimeBarReplay mustClearSettlementPrice
       */

      /**
       * Constructs a new ResponseTimeBarReplay.
       * @memberof rti
       * @classdesc Represents a ResponseTimeBarReplay.
       * @implements IResponseTimeBarReplay
       * @constructor
       * @param {rti.IResponseTimeBarReplay=} [properties] Properties to set
       */
      function ResponseTimeBarReplay(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseTimeBarReplay templateId.
       * @member {number} templateId
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.templateId = 0;

      /**
       * ResponseTimeBarReplay requestKey.
       * @member {string} requestKey
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.requestKey = '';

      /**
       * ResponseTimeBarReplay userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseTimeBarReplay rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseTimeBarReplay rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseTimeBarReplay symbol.
       * @member {string} symbol
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.symbol = '';

      /**
       * ResponseTimeBarReplay exchange.
       * @member {string} exchange
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.exchange = '';

      /**
       * ResponseTimeBarReplay type.
       * @member {rti.ResponseTimeBarReplay.BarType} type
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.type = 1;

      /**
       * ResponseTimeBarReplay period.
       * @member {string} period
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.period = '';

      /**
       * ResponseTimeBarReplay marker.
       * @member {number} marker
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.marker = 0;

      /**
       * ResponseTimeBarReplay numTrades.
       * @member {number|Long} numTrades
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.numTrades = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseTimeBarReplay volume.
       * @member {number|Long} volume
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.volume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseTimeBarReplay bidVolume.
       * @member {number|Long} bidVolume
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.bidVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseTimeBarReplay askVolume.
       * @member {number|Long} askVolume
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.askVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseTimeBarReplay openPrice.
       * @member {number} openPrice
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.openPrice = 0;

      /**
       * ResponseTimeBarReplay closePrice.
       * @member {number} closePrice
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.closePrice = 0;

      /**
       * ResponseTimeBarReplay highPrice.
       * @member {number} highPrice
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.highPrice = 0;

      /**
       * ResponseTimeBarReplay lowPrice.
       * @member {number} lowPrice
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.lowPrice = 0;

      /**
       * ResponseTimeBarReplay settlementPrice.
       * @member {number} settlementPrice
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.settlementPrice = 0;

      /**
       * ResponseTimeBarReplay hasSettlementPrice.
       * @member {boolean} hasSettlementPrice
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.hasSettlementPrice = false;

      /**
       * ResponseTimeBarReplay mustClearSettlementPrice.
       * @member {boolean} mustClearSettlementPrice
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       */
      ResponseTimeBarReplay.prototype.mustClearSettlementPrice = false;

      /**
       * Creates a new ResponseTimeBarReplay instance using the specified properties.
       * @function create
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {rti.IResponseTimeBarReplay=} [properties] Properties to set
       * @returns {rti.ResponseTimeBarReplay} ResponseTimeBarReplay instance
       */
      ResponseTimeBarReplay.create = function create(properties) {
        return new ResponseTimeBarReplay(properties);
      };

      /**
       * Encodes the specified ResponseTimeBarReplay message. Does not implicitly {@link rti.ResponseTimeBarReplay.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {rti.IResponseTimeBarReplay} message ResponseTimeBarReplay message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTimeBarReplay.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.highPrice != null &&
          Object.hasOwnProperty.call(message, 'highPrice')
        )
          writer
            .uint32(/* id 100012, wireType 1 =*/ 800097)
            .double(message.highPrice);
        if (
          message.lowPrice != null &&
          Object.hasOwnProperty.call(message, 'lowPrice')
        )
          writer
            .uint32(/* id 100013, wireType 1 =*/ 800105)
            .double(message.lowPrice);
        if (
          message.openPrice != null &&
          Object.hasOwnProperty.call(message, 'openPrice')
        )
          writer
            .uint32(/* id 100019, wireType 1 =*/ 800153)
            .double(message.openPrice);
        if (
          message.closePrice != null &&
          Object.hasOwnProperty.call(message, 'closePrice')
        )
          writer
            .uint32(/* id 100021, wireType 1 =*/ 800169)
            .double(message.closePrice);
        if (
          message.settlementPrice != null &&
          Object.hasOwnProperty.call(message, 'settlementPrice')
        )
          writer
            .uint32(/* id 100070, wireType 1 =*/ 800561)
            .double(message.settlementPrice);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.marker != null &&
          Object.hasOwnProperty.call(message, 'marker')
        )
          writer
            .uint32(/* id 119100, wireType 0 =*/ 952800)
            .int32(message.marker);
        if (
          message.numTrades != null &&
          Object.hasOwnProperty.call(message, 'numTrades')
        )
          writer
            .uint32(/* id 119109, wireType 0 =*/ 952872)
            .uint64(message.numTrades);
        if (
          message.volume != null &&
          Object.hasOwnProperty.call(message, 'volume')
        )
          writer
            .uint32(/* id 119110, wireType 0 =*/ 952880)
            .uint64(message.volume);
        if (
          message.period != null &&
          Object.hasOwnProperty.call(message, 'period')
        )
          writer
            .uint32(/* id 119112, wireType 2 =*/ 952898)
            .string(message.period);
        if (
          message.bidVolume != null &&
          Object.hasOwnProperty.call(message, 'bidVolume')
        )
          writer
            .uint32(/* id 119117, wireType 0 =*/ 952936)
            .uint64(message.bidVolume);
        if (
          message.askVolume != null &&
          Object.hasOwnProperty.call(message, 'askVolume')
        )
          writer
            .uint32(/* id 119118, wireType 0 =*/ 952944)
            .uint64(message.askVolume);
        if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
          writer
            .uint32(/* id 119200, wireType 0 =*/ 953600)
            .int32(message.type);
        if (
          message.requestKey != null &&
          Object.hasOwnProperty.call(message, 'requestKey')
        )
          writer
            .uint32(/* id 132758, wireType 2 =*/ 1062066)
            .string(message.requestKey);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.hasSettlementPrice != null &&
          Object.hasOwnProperty.call(message, 'hasSettlementPrice')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .bool(message.hasSettlementPrice);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.mustClearSettlementPrice != null &&
          Object.hasOwnProperty.call(message, 'mustClearSettlementPrice')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .bool(message.mustClearSettlementPrice);
        return writer;
      };

      /**
       * Encodes the specified ResponseTimeBarReplay message, length delimited. Does not implicitly {@link rti.ResponseTimeBarReplay.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {rti.IResponseTimeBarReplay} message ResponseTimeBarReplay message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTimeBarReplay.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseTimeBarReplay message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseTimeBarReplay} ResponseTimeBarReplay
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTimeBarReplay.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseTimeBarReplay();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132758:
              message.requestKey = reader.string();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 119200:
              message.type = reader.int32();
              break;
            case 119112:
              message.period = reader.string();
              break;
            case 119100:
              message.marker = reader.int32();
              break;
            case 119109:
              message.numTrades = reader.uint64();
              break;
            case 119110:
              message.volume = reader.uint64();
              break;
            case 119117:
              message.bidVolume = reader.uint64();
              break;
            case 119118:
              message.askVolume = reader.uint64();
              break;
            case 100019:
              message.openPrice = reader.double();
              break;
            case 100021:
              message.closePrice = reader.double();
              break;
            case 100012:
              message.highPrice = reader.double();
              break;
            case 100013:
              message.lowPrice = reader.double();
              break;
            case 100070:
              message.settlementPrice = reader.double();
              break;
            case 149138:
              message.hasSettlementPrice = reader.bool();
              break;
            case 154571:
              message.mustClearSettlementPrice = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseTimeBarReplay message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseTimeBarReplay} ResponseTimeBarReplay
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTimeBarReplay.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseTimeBarReplay message.
       * @function verify
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseTimeBarReplay.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.requestKey != null && message.hasOwnProperty('requestKey'))
          if (!$util.isString(message.requestKey))
            return 'requestKey: string expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.type != null && message.hasOwnProperty('type'))
          switch (message.type) {
            default:
              return 'type: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (message.period != null && message.hasOwnProperty('period'))
          if (!$util.isString(message.period)) return 'period: string expected';
        if (message.marker != null && message.hasOwnProperty('marker'))
          if (!$util.isInteger(message.marker))
            return 'marker: integer expected';
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (
            !$util.isInteger(message.numTrades) &&
            !(
              message.numTrades &&
              $util.isInteger(message.numTrades.low) &&
              $util.isInteger(message.numTrades.high)
            )
          )
            return 'numTrades: integer|Long expected';
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (
            !$util.isInteger(message.volume) &&
            !(
              message.volume &&
              $util.isInteger(message.volume.low) &&
              $util.isInteger(message.volume.high)
            )
          )
            return 'volume: integer|Long expected';
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (
            !$util.isInteger(message.bidVolume) &&
            !(
              message.bidVolume &&
              $util.isInteger(message.bidVolume.low) &&
              $util.isInteger(message.bidVolume.high)
            )
          )
            return 'bidVolume: integer|Long expected';
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (
            !$util.isInteger(message.askVolume) &&
            !(
              message.askVolume &&
              $util.isInteger(message.askVolume.low) &&
              $util.isInteger(message.askVolume.high)
            )
          )
            return 'askVolume: integer|Long expected';
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          if (typeof message.openPrice !== 'number')
            return 'openPrice: number expected';
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          if (typeof message.closePrice !== 'number')
            return 'closePrice: number expected';
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          if (typeof message.highPrice !== 'number')
            return 'highPrice: number expected';
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          if (typeof message.lowPrice !== 'number')
            return 'lowPrice: number expected';
        if (
          message.settlementPrice != null &&
          message.hasOwnProperty('settlementPrice')
        )
          if (typeof message.settlementPrice !== 'number')
            return 'settlementPrice: number expected';
        if (
          message.hasSettlementPrice != null &&
          message.hasOwnProperty('hasSettlementPrice')
        )
          if (typeof message.hasSettlementPrice !== 'boolean')
            return 'hasSettlementPrice: boolean expected';
        if (
          message.mustClearSettlementPrice != null &&
          message.hasOwnProperty('mustClearSettlementPrice')
        )
          if (typeof message.mustClearSettlementPrice !== 'boolean')
            return 'mustClearSettlementPrice: boolean expected';
        return null;
      };

      /**
       * Creates a ResponseTimeBarReplay message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseTimeBarReplay} ResponseTimeBarReplay
       */
      ResponseTimeBarReplay.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseTimeBarReplay) return object;
        var message = new $root.rti.ResponseTimeBarReplay();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.requestKey != null)
          message.requestKey = String(object.requestKey);
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseTimeBarReplay.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseTimeBarReplay.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseTimeBarReplay.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.type) {
          case 'SECOND_BAR':
          case 1:
            message.type = 1;
            break;
          case 'MINUTE_BAR':
          case 2:
            message.type = 2;
            break;
          case 'DAILY_BAR':
          case 3:
            message.type = 3;
            break;
          case 'WEEKLY_BAR':
          case 4:
            message.type = 4;
            break;
        }
        if (object.period != null) message.period = String(object.period);
        if (object.marker != null) message.marker = object.marker | 0;
        if (object.numTrades != null)
          if ($util.Long)
            (message.numTrades = $util.Long.fromValue(
              object.numTrades,
            )).unsigned = true;
          else if (typeof object.numTrades === 'string')
            message.numTrades = parseInt(object.numTrades, 10);
          else if (typeof object.numTrades === 'number')
            message.numTrades = object.numTrades;
          else if (typeof object.numTrades === 'object')
            message.numTrades = new $util.LongBits(
              object.numTrades.low >>> 0,
              object.numTrades.high >>> 0,
            ).toNumber(true);
        if (object.volume != null)
          if ($util.Long)
            (message.volume = $util.Long.fromValue(object.volume)).unsigned =
              true;
          else if (typeof object.volume === 'string')
            message.volume = parseInt(object.volume, 10);
          else if (typeof object.volume === 'number')
            message.volume = object.volume;
          else if (typeof object.volume === 'object')
            message.volume = new $util.LongBits(
              object.volume.low >>> 0,
              object.volume.high >>> 0,
            ).toNumber(true);
        if (object.bidVolume != null)
          if ($util.Long)
            (message.bidVolume = $util.Long.fromValue(
              object.bidVolume,
            )).unsigned = true;
          else if (typeof object.bidVolume === 'string')
            message.bidVolume = parseInt(object.bidVolume, 10);
          else if (typeof object.bidVolume === 'number')
            message.bidVolume = object.bidVolume;
          else if (typeof object.bidVolume === 'object')
            message.bidVolume = new $util.LongBits(
              object.bidVolume.low >>> 0,
              object.bidVolume.high >>> 0,
            ).toNumber(true);
        if (object.askVolume != null)
          if ($util.Long)
            (message.askVolume = $util.Long.fromValue(
              object.askVolume,
            )).unsigned = true;
          else if (typeof object.askVolume === 'string')
            message.askVolume = parseInt(object.askVolume, 10);
          else if (typeof object.askVolume === 'number')
            message.askVolume = object.askVolume;
          else if (typeof object.askVolume === 'object')
            message.askVolume = new $util.LongBits(
              object.askVolume.low >>> 0,
              object.askVolume.high >>> 0,
            ).toNumber(true);
        if (object.openPrice != null)
          message.openPrice = Number(object.openPrice);
        if (object.closePrice != null)
          message.closePrice = Number(object.closePrice);
        if (object.highPrice != null)
          message.highPrice = Number(object.highPrice);
        if (object.lowPrice != null) message.lowPrice = Number(object.lowPrice);
        if (object.settlementPrice != null)
          message.settlementPrice = Number(object.settlementPrice);
        if (object.hasSettlementPrice != null)
          message.hasSettlementPrice = Boolean(object.hasSettlementPrice);
        if (object.mustClearSettlementPrice != null)
          message.mustClearSettlementPrice = Boolean(
            object.mustClearSettlementPrice,
          );
        return message;
      };

      /**
       * Creates a plain object from a ResponseTimeBarReplay message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {rti.ResponseTimeBarReplay} message ResponseTimeBarReplay
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseTimeBarReplay.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.highPrice = 0;
          object.lowPrice = 0;
          object.openPrice = 0;
          object.closePrice = 0;
          object.settlementPrice = 0;
          object.symbol = '';
          object.exchange = '';
          object.marker = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.numTrades =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.numTrades = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.volume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.volume = options.longs === String ? '0' : 0;
          object.period = '';
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.bidVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.bidVolume = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.askVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.askVolume = options.longs === String ? '0' : 0;
          object.type = options.enums === String ? 'SECOND_BAR' : 1;
          object.requestKey = '';
          object.hasSettlementPrice = false;
          object.templateId = 0;
          object.mustClearSettlementPrice = false;
        }
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          object.highPrice =
            options.json && !isFinite(message.highPrice)
              ? String(message.highPrice)
              : message.highPrice;
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          object.lowPrice =
            options.json && !isFinite(message.lowPrice)
              ? String(message.lowPrice)
              : message.lowPrice;
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          object.openPrice =
            options.json && !isFinite(message.openPrice)
              ? String(message.openPrice)
              : message.openPrice;
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          object.closePrice =
            options.json && !isFinite(message.closePrice)
              ? String(message.closePrice)
              : message.closePrice;
        if (
          message.settlementPrice != null &&
          message.hasOwnProperty('settlementPrice')
        )
          object.settlementPrice =
            options.json && !isFinite(message.settlementPrice)
              ? String(message.settlementPrice)
              : message.settlementPrice;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.marker != null && message.hasOwnProperty('marker'))
          object.marker = message.marker;
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (typeof message.numTrades === 'number')
            object.numTrades =
              options.longs === String
                ? String(message.numTrades)
                : message.numTrades;
          else
            object.numTrades =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.numTrades)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.numTrades.low >>> 0,
                      message.numTrades.high >>> 0,
                    ).toNumber(true)
                  : message.numTrades;
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (typeof message.volume === 'number')
            object.volume =
              options.longs === String
                ? String(message.volume)
                : message.volume;
          else
            object.volume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.volume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.volume.low >>> 0,
                      message.volume.high >>> 0,
                    ).toNumber(true)
                  : message.volume;
        if (message.period != null && message.hasOwnProperty('period'))
          object.period = message.period;
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (typeof message.bidVolume === 'number')
            object.bidVolume =
              options.longs === String
                ? String(message.bidVolume)
                : message.bidVolume;
          else
            object.bidVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.bidVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.bidVolume.low >>> 0,
                      message.bidVolume.high >>> 0,
                    ).toNumber(true)
                  : message.bidVolume;
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (typeof message.askVolume === 'number')
            object.askVolume =
              options.longs === String
                ? String(message.askVolume)
                : message.askVolume;
          else
            object.askVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.askVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.askVolume.low >>> 0,
                      message.askVolume.high >>> 0,
                    ).toNumber(true)
                  : message.askVolume;
        if (message.type != null && message.hasOwnProperty('type'))
          object.type =
            options.enums === String
              ? $root.rti.ResponseTimeBarReplay.BarType[message.type]
              : message.type;
        if (message.requestKey != null && message.hasOwnProperty('requestKey'))
          object.requestKey = message.requestKey;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.hasSettlementPrice != null &&
          message.hasOwnProperty('hasSettlementPrice')
        )
          object.hasSettlementPrice = message.hasSettlementPrice;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.mustClearSettlementPrice != null &&
          message.hasOwnProperty('mustClearSettlementPrice')
        )
          object.mustClearSettlementPrice = message.mustClearSettlementPrice;
        return object;
      };

      /**
       * Converts this ResponseTimeBarReplay to JSON.
       * @function toJSON
       * @memberof rti.ResponseTimeBarReplay
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseTimeBarReplay.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseTimeBarReplay
       * @function getTypeUrl
       * @memberof rti.ResponseTimeBarReplay
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseTimeBarReplay.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseTimeBarReplay';
      };

      /**
       * BarType enum.
       * @name rti.ResponseTimeBarReplay.BarType
       * @enum {number}
       * @property {number} SECOND_BAR=1 SECOND_BAR value
       * @property {number} MINUTE_BAR=2 MINUTE_BAR value
       * @property {number} DAILY_BAR=3 DAILY_BAR value
       * @property {number} WEEKLY_BAR=4 WEEKLY_BAR value
       */
      ResponseTimeBarReplay.BarType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SECOND_BAR')] = 1;
        values[(valuesById[2] = 'MINUTE_BAR')] = 2;
        values[(valuesById[3] = 'DAILY_BAR')] = 3;
        values[(valuesById[4] = 'WEEKLY_BAR')] = 4;
        return values;
      })();

      return ResponseTimeBarReplay;
    })();

    rti.RequestTimeBarUpdate = (function () {
      /**
       * Properties of a RequestTimeBarUpdate.
       * @memberof rti
       * @interface IRequestTimeBarUpdate
       * @property {number} templateId RequestTimeBarUpdate templateId
       * @property {Array.<string>|null} [userMsg] RequestTimeBarUpdate userMsg
       * @property {string|null} [symbol] RequestTimeBarUpdate symbol
       * @property {string|null} [exchange] RequestTimeBarUpdate exchange
       * @property {rti.RequestTimeBarUpdate.Request|null} [request] RequestTimeBarUpdate request
       * @property {rti.RequestTimeBarUpdate.BarType|null} [barType] RequestTimeBarUpdate barType
       * @property {number|null} [barTypePeriod] RequestTimeBarUpdate barTypePeriod
       */

      /**
       * Constructs a new RequestTimeBarUpdate.
       * @memberof rti
       * @classdesc Represents a RequestTimeBarUpdate.
       * @implements IRequestTimeBarUpdate
       * @constructor
       * @param {rti.IRequestTimeBarUpdate=} [properties] Properties to set
       */
      function RequestTimeBarUpdate(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestTimeBarUpdate templateId.
       * @member {number} templateId
       * @memberof rti.RequestTimeBarUpdate
       * @instance
       */
      RequestTimeBarUpdate.prototype.templateId = 0;

      /**
       * RequestTimeBarUpdate userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestTimeBarUpdate
       * @instance
       */
      RequestTimeBarUpdate.prototype.userMsg = $util.emptyArray;

      /**
       * RequestTimeBarUpdate symbol.
       * @member {string} symbol
       * @memberof rti.RequestTimeBarUpdate
       * @instance
       */
      RequestTimeBarUpdate.prototype.symbol = '';

      /**
       * RequestTimeBarUpdate exchange.
       * @member {string} exchange
       * @memberof rti.RequestTimeBarUpdate
       * @instance
       */
      RequestTimeBarUpdate.prototype.exchange = '';

      /**
       * RequestTimeBarUpdate request.
       * @member {rti.RequestTimeBarUpdate.Request} request
       * @memberof rti.RequestTimeBarUpdate
       * @instance
       */
      RequestTimeBarUpdate.prototype.request = 1;

      /**
       * RequestTimeBarUpdate barType.
       * @member {rti.RequestTimeBarUpdate.BarType} barType
       * @memberof rti.RequestTimeBarUpdate
       * @instance
       */
      RequestTimeBarUpdate.prototype.barType = 1;

      /**
       * RequestTimeBarUpdate barTypePeriod.
       * @member {number} barTypePeriod
       * @memberof rti.RequestTimeBarUpdate
       * @instance
       */
      RequestTimeBarUpdate.prototype.barTypePeriod = 0;

      /**
       * Creates a new RequestTimeBarUpdate instance using the specified properties.
       * @function create
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {rti.IRequestTimeBarUpdate=} [properties] Properties to set
       * @returns {rti.RequestTimeBarUpdate} RequestTimeBarUpdate instance
       */
      RequestTimeBarUpdate.create = function create(properties) {
        return new RequestTimeBarUpdate(properties);
      };

      /**
       * Encodes the specified RequestTimeBarUpdate message. Does not implicitly {@link rti.RequestTimeBarUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {rti.IRequestTimeBarUpdate} message RequestTimeBarUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTimeBarUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.request != null &&
          Object.hasOwnProperty.call(message, 'request')
        )
          writer
            .uint32(/* id 100000, wireType 0 =*/ 800000)
            .int32(message.request);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.barTypePeriod != null &&
          Object.hasOwnProperty.call(message, 'barTypePeriod')
        )
          writer
            .uint32(/* id 119112, wireType 0 =*/ 952896)
            .int32(message.barTypePeriod);
        if (
          message.barType != null &&
          Object.hasOwnProperty.call(message, 'barType')
        )
          writer
            .uint32(/* id 119200, wireType 0 =*/ 953600)
            .int32(message.barType);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestTimeBarUpdate message, length delimited. Does not implicitly {@link rti.RequestTimeBarUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {rti.IRequestTimeBarUpdate} message RequestTimeBarUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestTimeBarUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestTimeBarUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestTimeBarUpdate} RequestTimeBarUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTimeBarUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestTimeBarUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 100000:
              message.request = reader.int32();
              break;
            case 119200:
              message.barType = reader.int32();
              break;
            case 119112:
              message.barTypePeriod = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestTimeBarUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestTimeBarUpdate} RequestTimeBarUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestTimeBarUpdate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestTimeBarUpdate message.
       * @function verify
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestTimeBarUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.request != null && message.hasOwnProperty('request'))
          switch (message.request) {
            default:
              return 'request: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (message.barType != null && message.hasOwnProperty('barType'))
          switch (message.barType) {
            default:
              return 'barType: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (
          message.barTypePeriod != null &&
          message.hasOwnProperty('barTypePeriod')
        )
          if (!$util.isInteger(message.barTypePeriod))
            return 'barTypePeriod: integer expected';
        return null;
      };

      /**
       * Creates a RequestTimeBarUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestTimeBarUpdate} RequestTimeBarUpdate
       */
      RequestTimeBarUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestTimeBarUpdate) return object;
        var message = new $root.rti.RequestTimeBarUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestTimeBarUpdate.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.request) {
          case 'SUBSCRIBE':
          case 1:
            message.request = 1;
            break;
          case 'UNSUBSCRIBE':
          case 2:
            message.request = 2;
            break;
        }
        switch (object.barType) {
          case 'SECOND_BAR':
          case 1:
            message.barType = 1;
            break;
          case 'MINUTE_BAR':
          case 2:
            message.barType = 2;
            break;
          case 'DAILY_BAR':
          case 3:
            message.barType = 3;
            break;
          case 'WEEKLY_BAR':
          case 4:
            message.barType = 4;
            break;
        }
        if (object.barTypePeriod != null)
          message.barTypePeriod = object.barTypePeriod | 0;
        return message;
      };

      /**
       * Creates a plain object from a RequestTimeBarUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {rti.RequestTimeBarUpdate} message RequestTimeBarUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestTimeBarUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.request = options.enums === String ? 'SUBSCRIBE' : 1;
          object.symbol = '';
          object.exchange = '';
          object.barTypePeriod = 0;
          object.barType = options.enums === String ? 'SECOND_BAR' : 1;
          object.templateId = 0;
        }
        if (message.request != null && message.hasOwnProperty('request'))
          object.request =
            options.enums === String
              ? $root.rti.RequestTimeBarUpdate.Request[message.request]
              : message.request;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.barTypePeriod != null &&
          message.hasOwnProperty('barTypePeriod')
        )
          object.barTypePeriod = message.barTypePeriod;
        if (message.barType != null && message.hasOwnProperty('barType'))
          object.barType =
            options.enums === String
              ? $root.rti.RequestTimeBarUpdate.BarType[message.barType]
              : message.barType;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestTimeBarUpdate to JSON.
       * @function toJSON
       * @memberof rti.RequestTimeBarUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestTimeBarUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestTimeBarUpdate
       * @function getTypeUrl
       * @memberof rti.RequestTimeBarUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestTimeBarUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestTimeBarUpdate';
      };

      /**
       * BarType enum.
       * @name rti.RequestTimeBarUpdate.BarType
       * @enum {number}
       * @property {number} SECOND_BAR=1 SECOND_BAR value
       * @property {number} MINUTE_BAR=2 MINUTE_BAR value
       * @property {number} DAILY_BAR=3 DAILY_BAR value
       * @property {number} WEEKLY_BAR=4 WEEKLY_BAR value
       */
      RequestTimeBarUpdate.BarType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SECOND_BAR')] = 1;
        values[(valuesById[2] = 'MINUTE_BAR')] = 2;
        values[(valuesById[3] = 'DAILY_BAR')] = 3;
        values[(valuesById[4] = 'WEEKLY_BAR')] = 4;
        return values;
      })();

      /**
       * Request enum.
       * @name rti.RequestTimeBarUpdate.Request
       * @enum {number}
       * @property {number} SUBSCRIBE=1 SUBSCRIBE value
       * @property {number} UNSUBSCRIBE=2 UNSUBSCRIBE value
       */
      RequestTimeBarUpdate.Request = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SUBSCRIBE')] = 1;
        values[(valuesById[2] = 'UNSUBSCRIBE')] = 2;
        return values;
      })();

      return RequestTimeBarUpdate;
    })();

    rti.ResponseTimeBarUpdate = (function () {
      /**
       * Properties of a ResponseTimeBarUpdate.
       * @memberof rti
       * @interface IResponseTimeBarUpdate
       * @property {number} templateId ResponseTimeBarUpdate templateId
       * @property {Array.<string>|null} [userMsg] ResponseTimeBarUpdate userMsg
       * @property {Array.<string>|null} [rpCode] ResponseTimeBarUpdate rpCode
       */

      /**
       * Constructs a new ResponseTimeBarUpdate.
       * @memberof rti
       * @classdesc Represents a ResponseTimeBarUpdate.
       * @implements IResponseTimeBarUpdate
       * @constructor
       * @param {rti.IResponseTimeBarUpdate=} [properties] Properties to set
       */
      function ResponseTimeBarUpdate(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseTimeBarUpdate templateId.
       * @member {number} templateId
       * @memberof rti.ResponseTimeBarUpdate
       * @instance
       */
      ResponseTimeBarUpdate.prototype.templateId = 0;

      /**
       * ResponseTimeBarUpdate userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseTimeBarUpdate
       * @instance
       */
      ResponseTimeBarUpdate.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseTimeBarUpdate rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseTimeBarUpdate
       * @instance
       */
      ResponseTimeBarUpdate.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseTimeBarUpdate instance using the specified properties.
       * @function create
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {rti.IResponseTimeBarUpdate=} [properties] Properties to set
       * @returns {rti.ResponseTimeBarUpdate} ResponseTimeBarUpdate instance
       */
      ResponseTimeBarUpdate.create = function create(properties) {
        return new ResponseTimeBarUpdate(properties);
      };

      /**
       * Encodes the specified ResponseTimeBarUpdate message. Does not implicitly {@link rti.ResponseTimeBarUpdate.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {rti.IResponseTimeBarUpdate} message ResponseTimeBarUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTimeBarUpdate.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseTimeBarUpdate message, length delimited. Does not implicitly {@link rti.ResponseTimeBarUpdate.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {rti.IResponseTimeBarUpdate} message ResponseTimeBarUpdate message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseTimeBarUpdate.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseTimeBarUpdate message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseTimeBarUpdate} ResponseTimeBarUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTimeBarUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseTimeBarUpdate();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseTimeBarUpdate message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseTimeBarUpdate} ResponseTimeBarUpdate
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseTimeBarUpdate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseTimeBarUpdate message.
       * @function verify
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseTimeBarUpdate.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseTimeBarUpdate message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseTimeBarUpdate} ResponseTimeBarUpdate
       */
      ResponseTimeBarUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseTimeBarUpdate) return object;
        var message = new $root.rti.ResponseTimeBarUpdate();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseTimeBarUpdate.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseTimeBarUpdate.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseTimeBarUpdate message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {rti.ResponseTimeBarUpdate} message ResponseTimeBarUpdate
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseTimeBarUpdate.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseTimeBarUpdate to JSON.
       * @function toJSON
       * @memberof rti.ResponseTimeBarUpdate
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseTimeBarUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseTimeBarUpdate
       * @function getTypeUrl
       * @memberof rti.ResponseTimeBarUpdate
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseTimeBarUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseTimeBarUpdate';
      };

      return ResponseTimeBarUpdate;
    })();

    rti.RequestVolumeProfileMinuteBars = (function () {
      /**
       * Properties of a RequestVolumeProfileMinuteBars.
       * @memberof rti
       * @interface IRequestVolumeProfileMinuteBars
       * @property {number} templateId RequestVolumeProfileMinuteBars templateId
       * @property {Array.<string>|null} [userMsg] RequestVolumeProfileMinuteBars userMsg
       * @property {string|null} [symbol] RequestVolumeProfileMinuteBars symbol
       * @property {string|null} [exchange] RequestVolumeProfileMinuteBars exchange
       * @property {number|null} [barTypePeriod] RequestVolumeProfileMinuteBars barTypePeriod
       * @property {number|null} [startIndex] RequestVolumeProfileMinuteBars startIndex
       * @property {number|null} [finishIndex] RequestVolumeProfileMinuteBars finishIndex
       * @property {number|null} [userMaxCount] RequestVolumeProfileMinuteBars userMaxCount
       * @property {boolean|null} [resumeBars] RequestVolumeProfileMinuteBars resumeBars
       */

      /**
       * Constructs a new RequestVolumeProfileMinuteBars.
       * @memberof rti
       * @classdesc Represents a RequestVolumeProfileMinuteBars.
       * @implements IRequestVolumeProfileMinuteBars
       * @constructor
       * @param {rti.IRequestVolumeProfileMinuteBars=} [properties] Properties to set
       */
      function RequestVolumeProfileMinuteBars(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestVolumeProfileMinuteBars templateId.
       * @member {number} templateId
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.templateId = 0;

      /**
       * RequestVolumeProfileMinuteBars userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.userMsg = $util.emptyArray;

      /**
       * RequestVolumeProfileMinuteBars symbol.
       * @member {string} symbol
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.symbol = '';

      /**
       * RequestVolumeProfileMinuteBars exchange.
       * @member {string} exchange
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.exchange = '';

      /**
       * RequestVolumeProfileMinuteBars barTypePeriod.
       * @member {number} barTypePeriod
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.barTypePeriod = 0;

      /**
       * RequestVolumeProfileMinuteBars startIndex.
       * @member {number} startIndex
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.startIndex = 0;

      /**
       * RequestVolumeProfileMinuteBars finishIndex.
       * @member {number} finishIndex
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.finishIndex = 0;

      /**
       * RequestVolumeProfileMinuteBars userMaxCount.
       * @member {number} userMaxCount
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.userMaxCount = 0;

      /**
       * RequestVolumeProfileMinuteBars resumeBars.
       * @member {boolean} resumeBars
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       */
      RequestVolumeProfileMinuteBars.prototype.resumeBars = false;

      /**
       * Creates a new RequestVolumeProfileMinuteBars instance using the specified properties.
       * @function create
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {rti.IRequestVolumeProfileMinuteBars=} [properties] Properties to set
       * @returns {rti.RequestVolumeProfileMinuteBars} RequestVolumeProfileMinuteBars instance
       */
      RequestVolumeProfileMinuteBars.create = function create(properties) {
        return new RequestVolumeProfileMinuteBars(properties);
      };

      /**
       * Encodes the specified RequestVolumeProfileMinuteBars message. Does not implicitly {@link rti.RequestVolumeProfileMinuteBars.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {rti.IRequestVolumeProfileMinuteBars} message RequestVolumeProfileMinuteBars message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestVolumeProfileMinuteBars.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.barTypePeriod != null &&
          Object.hasOwnProperty.call(message, 'barTypePeriod')
        )
          writer
            .uint32(/* id 119215, wireType 0 =*/ 953720)
            .int32(message.barTypePeriod);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.startIndex != null &&
          Object.hasOwnProperty.call(message, 'startIndex')
        )
          writer
            .uint32(/* id 153002, wireType 0 =*/ 1224016)
            .int32(message.startIndex);
        if (
          message.finishIndex != null &&
          Object.hasOwnProperty.call(message, 'finishIndex')
        )
          writer
            .uint32(/* id 153003, wireType 0 =*/ 1224024)
            .int32(message.finishIndex);
        if (
          message.resumeBars != null &&
          Object.hasOwnProperty.call(message, 'resumeBars')
        )
          writer
            .uint32(/* id 153642, wireType 0 =*/ 1229136)
            .bool(message.resumeBars);
        if (
          message.userMaxCount != null &&
          Object.hasOwnProperty.call(message, 'userMaxCount')
        )
          writer
            .uint32(/* id 154020, wireType 0 =*/ 1232160)
            .int32(message.userMaxCount);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestVolumeProfileMinuteBars message, length delimited. Does not implicitly {@link rti.RequestVolumeProfileMinuteBars.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {rti.IRequestVolumeProfileMinuteBars} message RequestVolumeProfileMinuteBars message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestVolumeProfileMinuteBars.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestVolumeProfileMinuteBars message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestVolumeProfileMinuteBars} RequestVolumeProfileMinuteBars
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestVolumeProfileMinuteBars.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestVolumeProfileMinuteBars();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 119215:
              message.barTypePeriod = reader.int32();
              break;
            case 153002:
              message.startIndex = reader.int32();
              break;
            case 153003:
              message.finishIndex = reader.int32();
              break;
            case 154020:
              message.userMaxCount = reader.int32();
              break;
            case 153642:
              message.resumeBars = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestVolumeProfileMinuteBars message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestVolumeProfileMinuteBars} RequestVolumeProfileMinuteBars
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestVolumeProfileMinuteBars.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestVolumeProfileMinuteBars message.
       * @function verify
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestVolumeProfileMinuteBars.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (
          message.barTypePeriod != null &&
          message.hasOwnProperty('barTypePeriod')
        )
          if (!$util.isInteger(message.barTypePeriod))
            return 'barTypePeriod: integer expected';
        if (message.startIndex != null && message.hasOwnProperty('startIndex'))
          if (!$util.isInteger(message.startIndex))
            return 'startIndex: integer expected';
        if (
          message.finishIndex != null &&
          message.hasOwnProperty('finishIndex')
        )
          if (!$util.isInteger(message.finishIndex))
            return 'finishIndex: integer expected';
        if (
          message.userMaxCount != null &&
          message.hasOwnProperty('userMaxCount')
        )
          if (!$util.isInteger(message.userMaxCount))
            return 'userMaxCount: integer expected';
        if (message.resumeBars != null && message.hasOwnProperty('resumeBars'))
          if (typeof message.resumeBars !== 'boolean')
            return 'resumeBars: boolean expected';
        return null;
      };

      /**
       * Creates a RequestVolumeProfileMinuteBars message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestVolumeProfileMinuteBars} RequestVolumeProfileMinuteBars
       */
      RequestVolumeProfileMinuteBars.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestVolumeProfileMinuteBars)
          return object;
        var message = new $root.rti.RequestVolumeProfileMinuteBars();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestVolumeProfileMinuteBars.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.barTypePeriod != null)
          message.barTypePeriod = object.barTypePeriod | 0;
        if (object.startIndex != null)
          message.startIndex = object.startIndex | 0;
        if (object.finishIndex != null)
          message.finishIndex = object.finishIndex | 0;
        if (object.userMaxCount != null)
          message.userMaxCount = object.userMaxCount | 0;
        if (object.resumeBars != null)
          message.resumeBars = Boolean(object.resumeBars);
        return message;
      };

      /**
       * Creates a plain object from a RequestVolumeProfileMinuteBars message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {rti.RequestVolumeProfileMinuteBars} message RequestVolumeProfileMinuteBars
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestVolumeProfileMinuteBars.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.symbol = '';
          object.exchange = '';
          object.barTypePeriod = 0;
          object.startIndex = 0;
          object.finishIndex = 0;
          object.resumeBars = false;
          object.userMaxCount = 0;
          object.templateId = 0;
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (
          message.barTypePeriod != null &&
          message.hasOwnProperty('barTypePeriod')
        )
          object.barTypePeriod = message.barTypePeriod;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.startIndex != null && message.hasOwnProperty('startIndex'))
          object.startIndex = message.startIndex;
        if (
          message.finishIndex != null &&
          message.hasOwnProperty('finishIndex')
        )
          object.finishIndex = message.finishIndex;
        if (message.resumeBars != null && message.hasOwnProperty('resumeBars'))
          object.resumeBars = message.resumeBars;
        if (
          message.userMaxCount != null &&
          message.hasOwnProperty('userMaxCount')
        )
          object.userMaxCount = message.userMaxCount;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestVolumeProfileMinuteBars to JSON.
       * @function toJSON
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestVolumeProfileMinuteBars.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestVolumeProfileMinuteBars
       * @function getTypeUrl
       * @memberof rti.RequestVolumeProfileMinuteBars
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestVolumeProfileMinuteBars.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestVolumeProfileMinuteBars';
      };

      return RequestVolumeProfileMinuteBars;
    })();

    rti.ResponseVolumeProfileMinuteBars = (function () {
      /**
       * Properties of a ResponseVolumeProfileMinuteBars.
       * @memberof rti
       * @interface IResponseVolumeProfileMinuteBars
       * @property {number} templateId ResponseVolumeProfileMinuteBars templateId
       * @property {string|null} [requestKey] ResponseVolumeProfileMinuteBars requestKey
       * @property {Array.<string>|null} [userMsg] ResponseVolumeProfileMinuteBars userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseVolumeProfileMinuteBars rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseVolumeProfileMinuteBars rpCode
       * @property {string|null} [symbol] ResponseVolumeProfileMinuteBars symbol
       * @property {string|null} [exchange] ResponseVolumeProfileMinuteBars exchange
       * @property {string|null} [period] ResponseVolumeProfileMinuteBars period
       * @property {number|null} [marker] ResponseVolumeProfileMinuteBars marker
       * @property {number|Long|null} [numTrades] ResponseVolumeProfileMinuteBars numTrades
       * @property {number|Long|null} [volume] ResponseVolumeProfileMinuteBars volume
       * @property {number|Long|null} [bidVolume] ResponseVolumeProfileMinuteBars bidVolume
       * @property {number|Long|null} [askVolume] ResponseVolumeProfileMinuteBars askVolume
       * @property {number|null} [openPrice] ResponseVolumeProfileMinuteBars openPrice
       * @property {number|null} [closePrice] ResponseVolumeProfileMinuteBars closePrice
       * @property {number|null} [highPrice] ResponseVolumeProfileMinuteBars highPrice
       * @property {number|null} [lowPrice] ResponseVolumeProfileMinuteBars lowPrice
       * @property {Array.<number>|null} [profilePrice] ResponseVolumeProfileMinuteBars profilePrice
       * @property {Array.<number>|null} [profileNoAggressorVolume] ResponseVolumeProfileMinuteBars profileNoAggressorVolume
       * @property {Array.<number>|null} [profileBidVolume] ResponseVolumeProfileMinuteBars profileBidVolume
       * @property {Array.<number>|null} [profileAskVolume] ResponseVolumeProfileMinuteBars profileAskVolume
       * @property {Array.<number>|null} [profileNoAggressorTrades] ResponseVolumeProfileMinuteBars profileNoAggressorTrades
       * @property {Array.<number>|null} [profileBidAggressorTrades] ResponseVolumeProfileMinuteBars profileBidAggressorTrades
       * @property {Array.<number>|null} [profileAskAggressorTrades] ResponseVolumeProfileMinuteBars profileAskAggressorTrades
       */

      /**
       * Constructs a new ResponseVolumeProfileMinuteBars.
       * @memberof rti
       * @classdesc Represents a ResponseVolumeProfileMinuteBars.
       * @implements IResponseVolumeProfileMinuteBars
       * @constructor
       * @param {rti.IResponseVolumeProfileMinuteBars=} [properties] Properties to set
       */
      function ResponseVolumeProfileMinuteBars(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        this.profilePrice = [];
        this.profileNoAggressorVolume = [];
        this.profileBidVolume = [];
        this.profileAskVolume = [];
        this.profileNoAggressorTrades = [];
        this.profileBidAggressorTrades = [];
        this.profileAskAggressorTrades = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseVolumeProfileMinuteBars templateId.
       * @member {number} templateId
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.templateId = 0;

      /**
       * ResponseVolumeProfileMinuteBars requestKey.
       * @member {string} requestKey
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.requestKey = '';

      /**
       * ResponseVolumeProfileMinuteBars userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.rqHandlerRpCode =
        $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars symbol.
       * @member {string} symbol
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.symbol = '';

      /**
       * ResponseVolumeProfileMinuteBars exchange.
       * @member {string} exchange
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.exchange = '';

      /**
       * ResponseVolumeProfileMinuteBars period.
       * @member {string} period
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.period = '';

      /**
       * ResponseVolumeProfileMinuteBars marker.
       * @member {number} marker
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.marker = 0;

      /**
       * ResponseVolumeProfileMinuteBars numTrades.
       * @member {number|Long} numTrades
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.numTrades = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseVolumeProfileMinuteBars volume.
       * @member {number|Long} volume
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.volume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseVolumeProfileMinuteBars bidVolume.
       * @member {number|Long} bidVolume
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.bidVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseVolumeProfileMinuteBars askVolume.
       * @member {number|Long} askVolume
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.askVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * ResponseVolumeProfileMinuteBars openPrice.
       * @member {number} openPrice
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.openPrice = 0;

      /**
       * ResponseVolumeProfileMinuteBars closePrice.
       * @member {number} closePrice
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.closePrice = 0;

      /**
       * ResponseVolumeProfileMinuteBars highPrice.
       * @member {number} highPrice
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.highPrice = 0;

      /**
       * ResponseVolumeProfileMinuteBars lowPrice.
       * @member {number} lowPrice
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.lowPrice = 0;

      /**
       * ResponseVolumeProfileMinuteBars profilePrice.
       * @member {Array.<number>} profilePrice
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.profilePrice = $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars profileNoAggressorVolume.
       * @member {Array.<number>} profileNoAggressorVolume
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.profileNoAggressorVolume =
        $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars profileBidVolume.
       * @member {Array.<number>} profileBidVolume
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.profileBidVolume =
        $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars profileAskVolume.
       * @member {Array.<number>} profileAskVolume
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.profileAskVolume =
        $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars profileNoAggressorTrades.
       * @member {Array.<number>} profileNoAggressorTrades
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.profileNoAggressorTrades =
        $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars profileBidAggressorTrades.
       * @member {Array.<number>} profileBidAggressorTrades
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.profileBidAggressorTrades =
        $util.emptyArray;

      /**
       * ResponseVolumeProfileMinuteBars profileAskAggressorTrades.
       * @member {Array.<number>} profileAskAggressorTrades
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       */
      ResponseVolumeProfileMinuteBars.prototype.profileAskAggressorTrades =
        $util.emptyArray;

      /**
       * Creates a new ResponseVolumeProfileMinuteBars instance using the specified properties.
       * @function create
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {rti.IResponseVolumeProfileMinuteBars=} [properties] Properties to set
       * @returns {rti.ResponseVolumeProfileMinuteBars} ResponseVolumeProfileMinuteBars instance
       */
      ResponseVolumeProfileMinuteBars.create = function create(properties) {
        return new ResponseVolumeProfileMinuteBars(properties);
      };

      /**
       * Encodes the specified ResponseVolumeProfileMinuteBars message. Does not implicitly {@link rti.ResponseVolumeProfileMinuteBars.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {rti.IResponseVolumeProfileMinuteBars} message ResponseVolumeProfileMinuteBars message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseVolumeProfileMinuteBars.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (
          message.highPrice != null &&
          Object.hasOwnProperty.call(message, 'highPrice')
        )
          writer
            .uint32(/* id 100012, wireType 1 =*/ 800097)
            .double(message.highPrice);
        if (
          message.lowPrice != null &&
          Object.hasOwnProperty.call(message, 'lowPrice')
        )
          writer
            .uint32(/* id 100013, wireType 1 =*/ 800105)
            .double(message.lowPrice);
        if (
          message.openPrice != null &&
          Object.hasOwnProperty.call(message, 'openPrice')
        )
          writer
            .uint32(/* id 100019, wireType 1 =*/ 800153)
            .double(message.openPrice);
        if (
          message.closePrice != null &&
          Object.hasOwnProperty.call(message, 'closePrice')
        )
          writer
            .uint32(/* id 100021, wireType 1 =*/ 800169)
            .double(message.closePrice);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.marker != null &&
          Object.hasOwnProperty.call(message, 'marker')
        )
          writer
            .uint32(/* id 119100, wireType 0 =*/ 952800)
            .int32(message.marker);
        if (
          message.numTrades != null &&
          Object.hasOwnProperty.call(message, 'numTrades')
        )
          writer
            .uint32(/* id 119204, wireType 0 =*/ 953632)
            .uint64(message.numTrades);
        if (
          message.volume != null &&
          Object.hasOwnProperty.call(message, 'volume')
        )
          writer
            .uint32(/* id 119205, wireType 0 =*/ 953640)
            .uint64(message.volume);
        if (
          message.bidVolume != null &&
          Object.hasOwnProperty.call(message, 'bidVolume')
        )
          writer
            .uint32(/* id 119213, wireType 0 =*/ 953704)
            .uint64(message.bidVolume);
        if (
          message.askVolume != null &&
          Object.hasOwnProperty.call(message, 'askVolume')
        )
          writer
            .uint32(/* id 119214, wireType 0 =*/ 953712)
            .uint64(message.askVolume);
        if (
          message.period != null &&
          Object.hasOwnProperty.call(message, 'period')
        )
          writer
            .uint32(/* id 119215, wireType 2 =*/ 953722)
            .string(message.period);
        if (message.profilePrice != null && message.profilePrice.length)
          for (var i = 0; i < message.profilePrice.length; ++i)
            writer
              .uint32(/* id 119216, wireType 1 =*/ 953729)
              .double(message.profilePrice[i]);
        if (
          message.profileNoAggressorVolume != null &&
          message.profileNoAggressorVolume.length
        )
          for (var i = 0; i < message.profileNoAggressorVolume.length; ++i)
            writer
              .uint32(/* id 119217, wireType 0 =*/ 953736)
              .int32(message.profileNoAggressorVolume[i]);
        if (message.profileBidVolume != null && message.profileBidVolume.length)
          for (var i = 0; i < message.profileBidVolume.length; ++i)
            writer
              .uint32(/* id 119218, wireType 0 =*/ 953744)
              .int32(message.profileBidVolume[i]);
        if (message.profileAskVolume != null && message.profileAskVolume.length)
          for (var i = 0; i < message.profileAskVolume.length; ++i)
            writer
              .uint32(/* id 119219, wireType 0 =*/ 953752)
              .int32(message.profileAskVolume[i]);
        if (
          message.profileNoAggressorTrades != null &&
          message.profileNoAggressorTrades.length
        )
          for (var i = 0; i < message.profileNoAggressorTrades.length; ++i)
            writer
              .uint32(/* id 119220, wireType 0 =*/ 953760)
              .int32(message.profileNoAggressorTrades[i]);
        if (
          message.profileBidAggressorTrades != null &&
          message.profileBidAggressorTrades.length
        )
          for (var i = 0; i < message.profileBidAggressorTrades.length; ++i)
            writer
              .uint32(/* id 119221, wireType 0 =*/ 953768)
              .int32(message.profileBidAggressorTrades[i]);
        if (
          message.profileAskAggressorTrades != null &&
          message.profileAskAggressorTrades.length
        )
          for (var i = 0; i < message.profileAskAggressorTrades.length; ++i)
            writer
              .uint32(/* id 119222, wireType 0 =*/ 953776)
              .int32(message.profileAskAggressorTrades[i]);
        if (
          message.requestKey != null &&
          Object.hasOwnProperty.call(message, 'requestKey')
        )
          writer
            .uint32(/* id 132758, wireType 2 =*/ 1062066)
            .string(message.requestKey);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseVolumeProfileMinuteBars message, length delimited. Does not implicitly {@link rti.ResponseVolumeProfileMinuteBars.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {rti.IResponseVolumeProfileMinuteBars} message ResponseVolumeProfileMinuteBars message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseVolumeProfileMinuteBars.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseVolumeProfileMinuteBars message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseVolumeProfileMinuteBars} ResponseVolumeProfileMinuteBars
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseVolumeProfileMinuteBars.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseVolumeProfileMinuteBars();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132758:
              message.requestKey = reader.string();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 119215:
              message.period = reader.string();
              break;
            case 119100:
              message.marker = reader.int32();
              break;
            case 119204:
              message.numTrades = reader.uint64();
              break;
            case 119205:
              message.volume = reader.uint64();
              break;
            case 119213:
              message.bidVolume = reader.uint64();
              break;
            case 119214:
              message.askVolume = reader.uint64();
              break;
            case 100019:
              message.openPrice = reader.double();
              break;
            case 100021:
              message.closePrice = reader.double();
              break;
            case 100012:
              message.highPrice = reader.double();
              break;
            case 100013:
              message.lowPrice = reader.double();
              break;
            case 119216:
              if (!(message.profilePrice && message.profilePrice.length))
                message.profilePrice = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.profilePrice.push(reader.double());
              } else message.profilePrice.push(reader.double());
              break;
            case 119217:
              if (
                !(
                  message.profileNoAggressorVolume &&
                  message.profileNoAggressorVolume.length
                )
              )
                message.profileNoAggressorVolume = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.profileNoAggressorVolume.push(reader.int32());
              } else message.profileNoAggressorVolume.push(reader.int32());
              break;
            case 119218:
              if (
                !(message.profileBidVolume && message.profileBidVolume.length)
              )
                message.profileBidVolume = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.profileBidVolume.push(reader.int32());
              } else message.profileBidVolume.push(reader.int32());
              break;
            case 119219:
              if (
                !(message.profileAskVolume && message.profileAskVolume.length)
              )
                message.profileAskVolume = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.profileAskVolume.push(reader.int32());
              } else message.profileAskVolume.push(reader.int32());
              break;
            case 119220:
              if (
                !(
                  message.profileNoAggressorTrades &&
                  message.profileNoAggressorTrades.length
                )
              )
                message.profileNoAggressorTrades = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.profileNoAggressorTrades.push(reader.int32());
              } else message.profileNoAggressorTrades.push(reader.int32());
              break;
            case 119221:
              if (
                !(
                  message.profileBidAggressorTrades &&
                  message.profileBidAggressorTrades.length
                )
              )
                message.profileBidAggressorTrades = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.profileBidAggressorTrades.push(reader.int32());
              } else message.profileBidAggressorTrades.push(reader.int32());
              break;
            case 119222:
              if (
                !(
                  message.profileAskAggressorTrades &&
                  message.profileAskAggressorTrades.length
                )
              )
                message.profileAskAggressorTrades = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.profileAskAggressorTrades.push(reader.int32());
              } else message.profileAskAggressorTrades.push(reader.int32());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseVolumeProfileMinuteBars message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseVolumeProfileMinuteBars} ResponseVolumeProfileMinuteBars
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseVolumeProfileMinuteBars.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseVolumeProfileMinuteBars message.
       * @function verify
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseVolumeProfileMinuteBars.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.requestKey != null && message.hasOwnProperty('requestKey'))
          if (!$util.isString(message.requestKey))
            return 'requestKey: string expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.period != null && message.hasOwnProperty('period'))
          if (!$util.isString(message.period)) return 'period: string expected';
        if (message.marker != null && message.hasOwnProperty('marker'))
          if (!$util.isInteger(message.marker))
            return 'marker: integer expected';
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (
            !$util.isInteger(message.numTrades) &&
            !(
              message.numTrades &&
              $util.isInteger(message.numTrades.low) &&
              $util.isInteger(message.numTrades.high)
            )
          )
            return 'numTrades: integer|Long expected';
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (
            !$util.isInteger(message.volume) &&
            !(
              message.volume &&
              $util.isInteger(message.volume.low) &&
              $util.isInteger(message.volume.high)
            )
          )
            return 'volume: integer|Long expected';
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (
            !$util.isInteger(message.bidVolume) &&
            !(
              message.bidVolume &&
              $util.isInteger(message.bidVolume.low) &&
              $util.isInteger(message.bidVolume.high)
            )
          )
            return 'bidVolume: integer|Long expected';
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (
            !$util.isInteger(message.askVolume) &&
            !(
              message.askVolume &&
              $util.isInteger(message.askVolume.low) &&
              $util.isInteger(message.askVolume.high)
            )
          )
            return 'askVolume: integer|Long expected';
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          if (typeof message.openPrice !== 'number')
            return 'openPrice: number expected';
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          if (typeof message.closePrice !== 'number')
            return 'closePrice: number expected';
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          if (typeof message.highPrice !== 'number')
            return 'highPrice: number expected';
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          if (typeof message.lowPrice !== 'number')
            return 'lowPrice: number expected';
        if (
          message.profilePrice != null &&
          message.hasOwnProperty('profilePrice')
        ) {
          if (!Array.isArray(message.profilePrice))
            return 'profilePrice: array expected';
          for (var i = 0; i < message.profilePrice.length; ++i)
            if (typeof message.profilePrice[i] !== 'number')
              return 'profilePrice: number[] expected';
        }
        if (
          message.profileNoAggressorVolume != null &&
          message.hasOwnProperty('profileNoAggressorVolume')
        ) {
          if (!Array.isArray(message.profileNoAggressorVolume))
            return 'profileNoAggressorVolume: array expected';
          for (var i = 0; i < message.profileNoAggressorVolume.length; ++i)
            if (!$util.isInteger(message.profileNoAggressorVolume[i]))
              return 'profileNoAggressorVolume: integer[] expected';
        }
        if (
          message.profileBidVolume != null &&
          message.hasOwnProperty('profileBidVolume')
        ) {
          if (!Array.isArray(message.profileBidVolume))
            return 'profileBidVolume: array expected';
          for (var i = 0; i < message.profileBidVolume.length; ++i)
            if (!$util.isInteger(message.profileBidVolume[i]))
              return 'profileBidVolume: integer[] expected';
        }
        if (
          message.profileAskVolume != null &&
          message.hasOwnProperty('profileAskVolume')
        ) {
          if (!Array.isArray(message.profileAskVolume))
            return 'profileAskVolume: array expected';
          for (var i = 0; i < message.profileAskVolume.length; ++i)
            if (!$util.isInteger(message.profileAskVolume[i]))
              return 'profileAskVolume: integer[] expected';
        }
        if (
          message.profileNoAggressorTrades != null &&
          message.hasOwnProperty('profileNoAggressorTrades')
        ) {
          if (!Array.isArray(message.profileNoAggressorTrades))
            return 'profileNoAggressorTrades: array expected';
          for (var i = 0; i < message.profileNoAggressorTrades.length; ++i)
            if (!$util.isInteger(message.profileNoAggressorTrades[i]))
              return 'profileNoAggressorTrades: integer[] expected';
        }
        if (
          message.profileBidAggressorTrades != null &&
          message.hasOwnProperty('profileBidAggressorTrades')
        ) {
          if (!Array.isArray(message.profileBidAggressorTrades))
            return 'profileBidAggressorTrades: array expected';
          for (var i = 0; i < message.profileBidAggressorTrades.length; ++i)
            if (!$util.isInteger(message.profileBidAggressorTrades[i]))
              return 'profileBidAggressorTrades: integer[] expected';
        }
        if (
          message.profileAskAggressorTrades != null &&
          message.hasOwnProperty('profileAskAggressorTrades')
        ) {
          if (!Array.isArray(message.profileAskAggressorTrades))
            return 'profileAskAggressorTrades: array expected';
          for (var i = 0; i < message.profileAskAggressorTrades.length; ++i)
            if (!$util.isInteger(message.profileAskAggressorTrades[i]))
              return 'profileAskAggressorTrades: integer[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseVolumeProfileMinuteBars message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseVolumeProfileMinuteBars} ResponseVolumeProfileMinuteBars
       */
      ResponseVolumeProfileMinuteBars.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseVolumeProfileMinuteBars)
          return object;
        var message = new $root.rti.ResponseVolumeProfileMinuteBars();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.requestKey != null)
          message.requestKey = String(object.requestKey);
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        if (object.period != null) message.period = String(object.period);
        if (object.marker != null) message.marker = object.marker | 0;
        if (object.numTrades != null)
          if ($util.Long)
            (message.numTrades = $util.Long.fromValue(
              object.numTrades,
            )).unsigned = true;
          else if (typeof object.numTrades === 'string')
            message.numTrades = parseInt(object.numTrades, 10);
          else if (typeof object.numTrades === 'number')
            message.numTrades = object.numTrades;
          else if (typeof object.numTrades === 'object')
            message.numTrades = new $util.LongBits(
              object.numTrades.low >>> 0,
              object.numTrades.high >>> 0,
            ).toNumber(true);
        if (object.volume != null)
          if ($util.Long)
            (message.volume = $util.Long.fromValue(object.volume)).unsigned =
              true;
          else if (typeof object.volume === 'string')
            message.volume = parseInt(object.volume, 10);
          else if (typeof object.volume === 'number')
            message.volume = object.volume;
          else if (typeof object.volume === 'object')
            message.volume = new $util.LongBits(
              object.volume.low >>> 0,
              object.volume.high >>> 0,
            ).toNumber(true);
        if (object.bidVolume != null)
          if ($util.Long)
            (message.bidVolume = $util.Long.fromValue(
              object.bidVolume,
            )).unsigned = true;
          else if (typeof object.bidVolume === 'string')
            message.bidVolume = parseInt(object.bidVolume, 10);
          else if (typeof object.bidVolume === 'number')
            message.bidVolume = object.bidVolume;
          else if (typeof object.bidVolume === 'object')
            message.bidVolume = new $util.LongBits(
              object.bidVolume.low >>> 0,
              object.bidVolume.high >>> 0,
            ).toNumber(true);
        if (object.askVolume != null)
          if ($util.Long)
            (message.askVolume = $util.Long.fromValue(
              object.askVolume,
            )).unsigned = true;
          else if (typeof object.askVolume === 'string')
            message.askVolume = parseInt(object.askVolume, 10);
          else if (typeof object.askVolume === 'number')
            message.askVolume = object.askVolume;
          else if (typeof object.askVolume === 'object')
            message.askVolume = new $util.LongBits(
              object.askVolume.low >>> 0,
              object.askVolume.high >>> 0,
            ).toNumber(true);
        if (object.openPrice != null)
          message.openPrice = Number(object.openPrice);
        if (object.closePrice != null)
          message.closePrice = Number(object.closePrice);
        if (object.highPrice != null)
          message.highPrice = Number(object.highPrice);
        if (object.lowPrice != null) message.lowPrice = Number(object.lowPrice);
        if (object.profilePrice) {
          if (!Array.isArray(object.profilePrice))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.profilePrice: array expected',
            );
          message.profilePrice = [];
          for (var i = 0; i < object.profilePrice.length; ++i)
            message.profilePrice[i] = Number(object.profilePrice[i]);
        }
        if (object.profileNoAggressorVolume) {
          if (!Array.isArray(object.profileNoAggressorVolume))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.profileNoAggressorVolume: array expected',
            );
          message.profileNoAggressorVolume = [];
          for (var i = 0; i < object.profileNoAggressorVolume.length; ++i)
            message.profileNoAggressorVolume[i] =
              object.profileNoAggressorVolume[i] | 0;
        }
        if (object.profileBidVolume) {
          if (!Array.isArray(object.profileBidVolume))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.profileBidVolume: array expected',
            );
          message.profileBidVolume = [];
          for (var i = 0; i < object.profileBidVolume.length; ++i)
            message.profileBidVolume[i] = object.profileBidVolume[i] | 0;
        }
        if (object.profileAskVolume) {
          if (!Array.isArray(object.profileAskVolume))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.profileAskVolume: array expected',
            );
          message.profileAskVolume = [];
          for (var i = 0; i < object.profileAskVolume.length; ++i)
            message.profileAskVolume[i] = object.profileAskVolume[i] | 0;
        }
        if (object.profileNoAggressorTrades) {
          if (!Array.isArray(object.profileNoAggressorTrades))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.profileNoAggressorTrades: array expected',
            );
          message.profileNoAggressorTrades = [];
          for (var i = 0; i < object.profileNoAggressorTrades.length; ++i)
            message.profileNoAggressorTrades[i] =
              object.profileNoAggressorTrades[i] | 0;
        }
        if (object.profileBidAggressorTrades) {
          if (!Array.isArray(object.profileBidAggressorTrades))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.profileBidAggressorTrades: array expected',
            );
          message.profileBidAggressorTrades = [];
          for (var i = 0; i < object.profileBidAggressorTrades.length; ++i)
            message.profileBidAggressorTrades[i] =
              object.profileBidAggressorTrades[i] | 0;
        }
        if (object.profileAskAggressorTrades) {
          if (!Array.isArray(object.profileAskAggressorTrades))
            throw TypeError(
              '.rti.ResponseVolumeProfileMinuteBars.profileAskAggressorTrades: array expected',
            );
          message.profileAskAggressorTrades = [];
          for (var i = 0; i < object.profileAskAggressorTrades.length; ++i)
            message.profileAskAggressorTrades[i] =
              object.profileAskAggressorTrades[i] | 0;
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseVolumeProfileMinuteBars message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {rti.ResponseVolumeProfileMinuteBars} message ResponseVolumeProfileMinuteBars
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseVolumeProfileMinuteBars.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.profilePrice = [];
          object.profileNoAggressorVolume = [];
          object.profileBidVolume = [];
          object.profileAskVolume = [];
          object.profileNoAggressorTrades = [];
          object.profileBidAggressorTrades = [];
          object.profileAskAggressorTrades = [];
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.highPrice = 0;
          object.lowPrice = 0;
          object.openPrice = 0;
          object.closePrice = 0;
          object.symbol = '';
          object.exchange = '';
          object.marker = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.numTrades =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.numTrades = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.volume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.volume = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.bidVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.bidVolume = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.askVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.askVolume = options.longs === String ? '0' : 0;
          object.period = '';
          object.requestKey = '';
          object.templateId = 0;
        }
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          object.highPrice =
            options.json && !isFinite(message.highPrice)
              ? String(message.highPrice)
              : message.highPrice;
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          object.lowPrice =
            options.json && !isFinite(message.lowPrice)
              ? String(message.lowPrice)
              : message.lowPrice;
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          object.openPrice =
            options.json && !isFinite(message.openPrice)
              ? String(message.openPrice)
              : message.openPrice;
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          object.closePrice =
            options.json && !isFinite(message.closePrice)
              ? String(message.closePrice)
              : message.closePrice;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.marker != null && message.hasOwnProperty('marker'))
          object.marker = message.marker;
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (typeof message.numTrades === 'number')
            object.numTrades =
              options.longs === String
                ? String(message.numTrades)
                : message.numTrades;
          else
            object.numTrades =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.numTrades)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.numTrades.low >>> 0,
                      message.numTrades.high >>> 0,
                    ).toNumber(true)
                  : message.numTrades;
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (typeof message.volume === 'number')
            object.volume =
              options.longs === String
                ? String(message.volume)
                : message.volume;
          else
            object.volume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.volume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.volume.low >>> 0,
                      message.volume.high >>> 0,
                    ).toNumber(true)
                  : message.volume;
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (typeof message.bidVolume === 'number')
            object.bidVolume =
              options.longs === String
                ? String(message.bidVolume)
                : message.bidVolume;
          else
            object.bidVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.bidVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.bidVolume.low >>> 0,
                      message.bidVolume.high >>> 0,
                    ).toNumber(true)
                  : message.bidVolume;
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (typeof message.askVolume === 'number')
            object.askVolume =
              options.longs === String
                ? String(message.askVolume)
                : message.askVolume;
          else
            object.askVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.askVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.askVolume.low >>> 0,
                      message.askVolume.high >>> 0,
                    ).toNumber(true)
                  : message.askVolume;
        if (message.period != null && message.hasOwnProperty('period'))
          object.period = message.period;
        if (message.profilePrice && message.profilePrice.length) {
          object.profilePrice = [];
          for (var j = 0; j < message.profilePrice.length; ++j)
            object.profilePrice[j] =
              options.json && !isFinite(message.profilePrice[j])
                ? String(message.profilePrice[j])
                : message.profilePrice[j];
        }
        if (
          message.profileNoAggressorVolume &&
          message.profileNoAggressorVolume.length
        ) {
          object.profileNoAggressorVolume = [];
          for (var j = 0; j < message.profileNoAggressorVolume.length; ++j)
            object.profileNoAggressorVolume[j] =
              message.profileNoAggressorVolume[j];
        }
        if (message.profileBidVolume && message.profileBidVolume.length) {
          object.profileBidVolume = [];
          for (var j = 0; j < message.profileBidVolume.length; ++j)
            object.profileBidVolume[j] = message.profileBidVolume[j];
        }
        if (message.profileAskVolume && message.profileAskVolume.length) {
          object.profileAskVolume = [];
          for (var j = 0; j < message.profileAskVolume.length; ++j)
            object.profileAskVolume[j] = message.profileAskVolume[j];
        }
        if (
          message.profileNoAggressorTrades &&
          message.profileNoAggressorTrades.length
        ) {
          object.profileNoAggressorTrades = [];
          for (var j = 0; j < message.profileNoAggressorTrades.length; ++j)
            object.profileNoAggressorTrades[j] =
              message.profileNoAggressorTrades[j];
        }
        if (
          message.profileBidAggressorTrades &&
          message.profileBidAggressorTrades.length
        ) {
          object.profileBidAggressorTrades = [];
          for (var j = 0; j < message.profileBidAggressorTrades.length; ++j)
            object.profileBidAggressorTrades[j] =
              message.profileBidAggressorTrades[j];
        }
        if (
          message.profileAskAggressorTrades &&
          message.profileAskAggressorTrades.length
        ) {
          object.profileAskAggressorTrades = [];
          for (var j = 0; j < message.profileAskAggressorTrades.length; ++j)
            object.profileAskAggressorTrades[j] =
              message.profileAskAggressorTrades[j];
        }
        if (message.requestKey != null && message.hasOwnProperty('requestKey'))
          object.requestKey = message.requestKey;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseVolumeProfileMinuteBars to JSON.
       * @function toJSON
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseVolumeProfileMinuteBars.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseVolumeProfileMinuteBars
       * @function getTypeUrl
       * @memberof rti.ResponseVolumeProfileMinuteBars
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseVolumeProfileMinuteBars.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseVolumeProfileMinuteBars';
      };

      return ResponseVolumeProfileMinuteBars;
    })();

    rti.RequestResumeBars = (function () {
      /**
       * Properties of a RequestResumeBars.
       * @memberof rti
       * @interface IRequestResumeBars
       * @property {number} templateId RequestResumeBars templateId
       * @property {Array.<string>|null} [userMsg] RequestResumeBars userMsg
       * @property {string|null} [requestKey] RequestResumeBars requestKey
       */

      /**
       * Constructs a new RequestResumeBars.
       * @memberof rti
       * @classdesc Represents a RequestResumeBars.
       * @implements IRequestResumeBars
       * @constructor
       * @param {rti.IRequestResumeBars=} [properties] Properties to set
       */
      function RequestResumeBars(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestResumeBars templateId.
       * @member {number} templateId
       * @memberof rti.RequestResumeBars
       * @instance
       */
      RequestResumeBars.prototype.templateId = 0;

      /**
       * RequestResumeBars userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestResumeBars
       * @instance
       */
      RequestResumeBars.prototype.userMsg = $util.emptyArray;

      /**
       * RequestResumeBars requestKey.
       * @member {string} requestKey
       * @memberof rti.RequestResumeBars
       * @instance
       */
      RequestResumeBars.prototype.requestKey = '';

      /**
       * Creates a new RequestResumeBars instance using the specified properties.
       * @function create
       * @memberof rti.RequestResumeBars
       * @static
       * @param {rti.IRequestResumeBars=} [properties] Properties to set
       * @returns {rti.RequestResumeBars} RequestResumeBars instance
       */
      RequestResumeBars.create = function create(properties) {
        return new RequestResumeBars(properties);
      };

      /**
       * Encodes the specified RequestResumeBars message. Does not implicitly {@link rti.RequestResumeBars.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestResumeBars
       * @static
       * @param {rti.IRequestResumeBars} message RequestResumeBars message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestResumeBars.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.requestKey != null &&
          Object.hasOwnProperty.call(message, 'requestKey')
        )
          writer
            .uint32(/* id 132758, wireType 2 =*/ 1062066)
            .string(message.requestKey);
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestResumeBars message, length delimited. Does not implicitly {@link rti.RequestResumeBars.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestResumeBars
       * @static
       * @param {rti.IRequestResumeBars} message RequestResumeBars message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestResumeBars.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestResumeBars message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestResumeBars
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestResumeBars} RequestResumeBars
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestResumeBars.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestResumeBars();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132758:
              message.requestKey = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestResumeBars message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestResumeBars
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestResumeBars} RequestResumeBars
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestResumeBars.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestResumeBars message.
       * @function verify
       * @memberof rti.RequestResumeBars
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestResumeBars.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.requestKey != null && message.hasOwnProperty('requestKey'))
          if (!$util.isString(message.requestKey))
            return 'requestKey: string expected';
        return null;
      };

      /**
       * Creates a RequestResumeBars message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestResumeBars
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestResumeBars} RequestResumeBars
       */
      RequestResumeBars.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestResumeBars) return object;
        var message = new $root.rti.RequestResumeBars();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.RequestResumeBars.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.requestKey != null)
          message.requestKey = String(object.requestKey);
        return message;
      };

      /**
       * Creates a plain object from a RequestResumeBars message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestResumeBars
       * @static
       * @param {rti.RequestResumeBars} message RequestResumeBars
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestResumeBars.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.requestKey = '';
          object.templateId = 0;
        }
        if (message.requestKey != null && message.hasOwnProperty('requestKey'))
          object.requestKey = message.requestKey;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestResumeBars to JSON.
       * @function toJSON
       * @memberof rti.RequestResumeBars
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestResumeBars.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestResumeBars
       * @function getTypeUrl
       * @memberof rti.RequestResumeBars
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestResumeBars.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestResumeBars';
      };

      return RequestResumeBars;
    })();

    rti.ResponseResumeBars = (function () {
      /**
       * Properties of a ResponseResumeBars.
       * @memberof rti
       * @interface IResponseResumeBars
       * @property {number} templateId ResponseResumeBars templateId
       * @property {Array.<string>|null} [userMsg] ResponseResumeBars userMsg
       * @property {Array.<string>|null} [rpCode] ResponseResumeBars rpCode
       */

      /**
       * Constructs a new ResponseResumeBars.
       * @memberof rti
       * @classdesc Represents a ResponseResumeBars.
       * @implements IResponseResumeBars
       * @constructor
       * @param {rti.IResponseResumeBars=} [properties] Properties to set
       */
      function ResponseResumeBars(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseResumeBars templateId.
       * @member {number} templateId
       * @memberof rti.ResponseResumeBars
       * @instance
       */
      ResponseResumeBars.prototype.templateId = 0;

      /**
       * ResponseResumeBars userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseResumeBars
       * @instance
       */
      ResponseResumeBars.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseResumeBars rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseResumeBars
       * @instance
       */
      ResponseResumeBars.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseResumeBars instance using the specified properties.
       * @function create
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {rti.IResponseResumeBars=} [properties] Properties to set
       * @returns {rti.ResponseResumeBars} ResponseResumeBars instance
       */
      ResponseResumeBars.create = function create(properties) {
        return new ResponseResumeBars(properties);
      };

      /**
       * Encodes the specified ResponseResumeBars message. Does not implicitly {@link rti.ResponseResumeBars.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {rti.IResponseResumeBars} message ResponseResumeBars message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseResumeBars.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseResumeBars message, length delimited. Does not implicitly {@link rti.ResponseResumeBars.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {rti.IResponseResumeBars} message ResponseResumeBars message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseResumeBars.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseResumeBars message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseResumeBars} ResponseResumeBars
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseResumeBars.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseResumeBars();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseResumeBars message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseResumeBars} ResponseResumeBars
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseResumeBars.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseResumeBars message.
       * @function verify
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseResumeBars.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseResumeBars message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseResumeBars} ResponseResumeBars
       */
      ResponseResumeBars.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseResumeBars) return object;
        var message = new $root.rti.ResponseResumeBars();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError('.rti.ResponseResumeBars.userMsg: array expected');
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError('.rti.ResponseResumeBars.rpCode: array expected');
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseResumeBars message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {rti.ResponseResumeBars} message ResponseResumeBars
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseResumeBars.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseResumeBars to JSON.
       * @function toJSON
       * @memberof rti.ResponseResumeBars
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseResumeBars.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseResumeBars
       * @function getTypeUrl
       * @memberof rti.ResponseResumeBars
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseResumeBars.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseResumeBars';
      };

      return ResponseResumeBars;
    })();

    rti.TickBar = (function () {
      /**
       * Properties of a TickBar.
       * @memberof rti
       * @interface ITickBar
       * @property {number} templateId TickBar templateId
       * @property {string|null} [symbol] TickBar symbol
       * @property {string|null} [exchange] TickBar exchange
       * @property {rti.TickBar.BarType|null} [type] TickBar type
       * @property {rti.TickBar.BarSubType|null} [subType] TickBar subType
       * @property {string|null} [typeSpecifier] TickBar typeSpecifier
       * @property {number|Long|null} [numTrades] TickBar numTrades
       * @property {number|Long|null} [volume] TickBar volume
       * @property {number|Long|null} [bidVolume] TickBar bidVolume
       * @property {number|Long|null} [askVolume] TickBar askVolume
       * @property {number|null} [openPrice] TickBar openPrice
       * @property {number|null} [closePrice] TickBar closePrice
       * @property {number|null} [highPrice] TickBar highPrice
       * @property {number|null} [lowPrice] TickBar lowPrice
       * @property {number|null} [customSessionOpenSsm] TickBar customSessionOpenSsm
       * @property {Array.<number>|null} [dataBarSsboe] TickBar dataBarSsboe
       * @property {Array.<number>|null} [dataBarUsecs] TickBar dataBarUsecs
       */

      /**
       * Constructs a new TickBar.
       * @memberof rti
       * @classdesc Represents a TickBar.
       * @implements ITickBar
       * @constructor
       * @param {rti.ITickBar=} [properties] Properties to set
       */
      function TickBar(properties) {
        this.dataBarSsboe = [];
        this.dataBarUsecs = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * TickBar templateId.
       * @member {number} templateId
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.templateId = 0;

      /**
       * TickBar symbol.
       * @member {string} symbol
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.symbol = '';

      /**
       * TickBar exchange.
       * @member {string} exchange
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.exchange = '';

      /**
       * TickBar type.
       * @member {rti.TickBar.BarType} type
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.type = 1;

      /**
       * TickBar subType.
       * @member {rti.TickBar.BarSubType} subType
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.subType = 1;

      /**
       * TickBar typeSpecifier.
       * @member {string} typeSpecifier
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.typeSpecifier = '';

      /**
       * TickBar numTrades.
       * @member {number|Long} numTrades
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.numTrades = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * TickBar volume.
       * @member {number|Long} volume
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.volume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * TickBar bidVolume.
       * @member {number|Long} bidVolume
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.bidVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * TickBar askVolume.
       * @member {number|Long} askVolume
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.askVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * TickBar openPrice.
       * @member {number} openPrice
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.openPrice = 0;

      /**
       * TickBar closePrice.
       * @member {number} closePrice
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.closePrice = 0;

      /**
       * TickBar highPrice.
       * @member {number} highPrice
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.highPrice = 0;

      /**
       * TickBar lowPrice.
       * @member {number} lowPrice
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.lowPrice = 0;

      /**
       * TickBar customSessionOpenSsm.
       * @member {number} customSessionOpenSsm
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.customSessionOpenSsm = 0;

      /**
       * TickBar dataBarSsboe.
       * @member {Array.<number>} dataBarSsboe
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.dataBarSsboe = $util.emptyArray;

      /**
       * TickBar dataBarUsecs.
       * @member {Array.<number>} dataBarUsecs
       * @memberof rti.TickBar
       * @instance
       */
      TickBar.prototype.dataBarUsecs = $util.emptyArray;

      /**
       * Creates a new TickBar instance using the specified properties.
       * @function create
       * @memberof rti.TickBar
       * @static
       * @param {rti.ITickBar=} [properties] Properties to set
       * @returns {rti.TickBar} TickBar instance
       */
      TickBar.create = function create(properties) {
        return new TickBar(properties);
      };

      /**
       * Encodes the specified TickBar message. Does not implicitly {@link rti.TickBar.verify|verify} messages.
       * @function encode
       * @memberof rti.TickBar
       * @static
       * @param {rti.ITickBar} message TickBar message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TickBar.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.highPrice != null &&
          Object.hasOwnProperty.call(message, 'highPrice')
        )
          writer
            .uint32(/* id 100012, wireType 1 =*/ 800097)
            .double(message.highPrice);
        if (
          message.lowPrice != null &&
          Object.hasOwnProperty.call(message, 'lowPrice')
        )
          writer
            .uint32(/* id 100013, wireType 1 =*/ 800105)
            .double(message.lowPrice);
        if (
          message.openPrice != null &&
          Object.hasOwnProperty.call(message, 'openPrice')
        )
          writer
            .uint32(/* id 100019, wireType 1 =*/ 800153)
            .double(message.openPrice);
        if (
          message.closePrice != null &&
          Object.hasOwnProperty.call(message, 'closePrice')
        )
          writer
            .uint32(/* id 100021, wireType 1 =*/ 800169)
            .double(message.closePrice);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
          writer
            .uint32(/* id 119200, wireType 0 =*/ 953600)
            .int32(message.type);
        if (message.dataBarSsboe != null && message.dataBarSsboe.length)
          for (var i = 0; i < message.dataBarSsboe.length; ++i)
            writer
              .uint32(/* id 119202, wireType 0 =*/ 953616)
              .int32(message.dataBarSsboe[i]);
        if (message.dataBarUsecs != null && message.dataBarUsecs.length)
          for (var i = 0; i < message.dataBarUsecs.length; ++i)
            writer
              .uint32(/* id 119203, wireType 0 =*/ 953624)
              .int32(message.dataBarUsecs[i]);
        if (
          message.numTrades != null &&
          Object.hasOwnProperty.call(message, 'numTrades')
        )
          writer
            .uint32(/* id 119204, wireType 0 =*/ 953632)
            .uint64(message.numTrades);
        if (
          message.volume != null &&
          Object.hasOwnProperty.call(message, 'volume')
        )
          writer
            .uint32(/* id 119205, wireType 0 =*/ 953640)
            .uint64(message.volume);
        if (
          message.subType != null &&
          Object.hasOwnProperty.call(message, 'subType')
        )
          writer
            .uint32(/* id 119208, wireType 0 =*/ 953664)
            .int32(message.subType);
        if (
          message.customSessionOpenSsm != null &&
          Object.hasOwnProperty.call(message, 'customSessionOpenSsm')
        )
          writer
            .uint32(/* id 119209, wireType 0 =*/ 953672)
            .int32(message.customSessionOpenSsm);
        if (
          message.bidVolume != null &&
          Object.hasOwnProperty.call(message, 'bidVolume')
        )
          writer
            .uint32(/* id 119213, wireType 0 =*/ 953704)
            .uint64(message.bidVolume);
        if (
          message.askVolume != null &&
          Object.hasOwnProperty.call(message, 'askVolume')
        )
          writer
            .uint32(/* id 119214, wireType 0 =*/ 953712)
            .uint64(message.askVolume);
        if (
          message.typeSpecifier != null &&
          Object.hasOwnProperty.call(message, 'typeSpecifier')
        )
          writer
            .uint32(/* id 148162, wireType 2 =*/ 1185298)
            .string(message.typeSpecifier);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified TickBar message, length delimited. Does not implicitly {@link rti.TickBar.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.TickBar
       * @static
       * @param {rti.ITickBar} message TickBar message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TickBar.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a TickBar message from the specified reader or buffer.
       * @function decode
       * @memberof rti.TickBar
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.TickBar} TickBar
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TickBar.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.TickBar();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 119200:
              message.type = reader.int32();
              break;
            case 119208:
              message.subType = reader.int32();
              break;
            case 148162:
              message.typeSpecifier = reader.string();
              break;
            case 119204:
              message.numTrades = reader.uint64();
              break;
            case 119205:
              message.volume = reader.uint64();
              break;
            case 119213:
              message.bidVolume = reader.uint64();
              break;
            case 119214:
              message.askVolume = reader.uint64();
              break;
            case 100019:
              message.openPrice = reader.double();
              break;
            case 100021:
              message.closePrice = reader.double();
              break;
            case 100012:
              message.highPrice = reader.double();
              break;
            case 100013:
              message.lowPrice = reader.double();
              break;
            case 119209:
              message.customSessionOpenSsm = reader.int32();
              break;
            case 119202:
              if (!(message.dataBarSsboe && message.dataBarSsboe.length))
                message.dataBarSsboe = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.dataBarSsboe.push(reader.int32());
              } else message.dataBarSsboe.push(reader.int32());
              break;
            case 119203:
              if (!(message.dataBarUsecs && message.dataBarUsecs.length))
                message.dataBarUsecs = [];
              if ((tag & 7) === 2) {
                var end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2)
                  message.dataBarUsecs.push(reader.int32());
              } else message.dataBarUsecs.push(reader.int32());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a TickBar message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.TickBar
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.TickBar} TickBar
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TickBar.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a TickBar message.
       * @function verify
       * @memberof rti.TickBar
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      TickBar.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.type != null && message.hasOwnProperty('type'))
          switch (message.type) {
            default:
              return 'type: enum value expected';
            case 1:
            case 2:
            case 3:
              break;
          }
        if (message.subType != null && message.hasOwnProperty('subType'))
          switch (message.subType) {
            default:
              return 'subType: enum value expected';
            case 1:
            case 2:
              break;
          }
        if (
          message.typeSpecifier != null &&
          message.hasOwnProperty('typeSpecifier')
        )
          if (!$util.isString(message.typeSpecifier))
            return 'typeSpecifier: string expected';
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (
            !$util.isInteger(message.numTrades) &&
            !(
              message.numTrades &&
              $util.isInteger(message.numTrades.low) &&
              $util.isInteger(message.numTrades.high)
            )
          )
            return 'numTrades: integer|Long expected';
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (
            !$util.isInteger(message.volume) &&
            !(
              message.volume &&
              $util.isInteger(message.volume.low) &&
              $util.isInteger(message.volume.high)
            )
          )
            return 'volume: integer|Long expected';
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (
            !$util.isInteger(message.bidVolume) &&
            !(
              message.bidVolume &&
              $util.isInteger(message.bidVolume.low) &&
              $util.isInteger(message.bidVolume.high)
            )
          )
            return 'bidVolume: integer|Long expected';
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (
            !$util.isInteger(message.askVolume) &&
            !(
              message.askVolume &&
              $util.isInteger(message.askVolume.low) &&
              $util.isInteger(message.askVolume.high)
            )
          )
            return 'askVolume: integer|Long expected';
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          if (typeof message.openPrice !== 'number')
            return 'openPrice: number expected';
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          if (typeof message.closePrice !== 'number')
            return 'closePrice: number expected';
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          if (typeof message.highPrice !== 'number')
            return 'highPrice: number expected';
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          if (typeof message.lowPrice !== 'number')
            return 'lowPrice: number expected';
        if (
          message.customSessionOpenSsm != null &&
          message.hasOwnProperty('customSessionOpenSsm')
        )
          if (!$util.isInteger(message.customSessionOpenSsm))
            return 'customSessionOpenSsm: integer expected';
        if (
          message.dataBarSsboe != null &&
          message.hasOwnProperty('dataBarSsboe')
        ) {
          if (!Array.isArray(message.dataBarSsboe))
            return 'dataBarSsboe: array expected';
          for (var i = 0; i < message.dataBarSsboe.length; ++i)
            if (!$util.isInteger(message.dataBarSsboe[i]))
              return 'dataBarSsboe: integer[] expected';
        }
        if (
          message.dataBarUsecs != null &&
          message.hasOwnProperty('dataBarUsecs')
        ) {
          if (!Array.isArray(message.dataBarUsecs))
            return 'dataBarUsecs: array expected';
          for (var i = 0; i < message.dataBarUsecs.length; ++i)
            if (!$util.isInteger(message.dataBarUsecs[i]))
              return 'dataBarUsecs: integer[] expected';
        }
        return null;
      };

      /**
       * Creates a TickBar message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.TickBar
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.TickBar} TickBar
       */
      TickBar.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.TickBar) return object;
        var message = new $root.rti.TickBar();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.type) {
          case 'TICK_BAR':
          case 1:
            message.type = 1;
            break;
          case 'RANGE_BAR':
          case 2:
            message.type = 2;
            break;
          case 'VOLUME_BAR':
          case 3:
            message.type = 3;
            break;
        }
        switch (object.subType) {
          case 'REGULAR':
          case 1:
            message.subType = 1;
            break;
          case 'CUSTOM':
          case 2:
            message.subType = 2;
            break;
        }
        if (object.typeSpecifier != null)
          message.typeSpecifier = String(object.typeSpecifier);
        if (object.numTrades != null)
          if ($util.Long)
            (message.numTrades = $util.Long.fromValue(
              object.numTrades,
            )).unsigned = true;
          else if (typeof object.numTrades === 'string')
            message.numTrades = parseInt(object.numTrades, 10);
          else if (typeof object.numTrades === 'number')
            message.numTrades = object.numTrades;
          else if (typeof object.numTrades === 'object')
            message.numTrades = new $util.LongBits(
              object.numTrades.low >>> 0,
              object.numTrades.high >>> 0,
            ).toNumber(true);
        if (object.volume != null)
          if ($util.Long)
            (message.volume = $util.Long.fromValue(object.volume)).unsigned =
              true;
          else if (typeof object.volume === 'string')
            message.volume = parseInt(object.volume, 10);
          else if (typeof object.volume === 'number')
            message.volume = object.volume;
          else if (typeof object.volume === 'object')
            message.volume = new $util.LongBits(
              object.volume.low >>> 0,
              object.volume.high >>> 0,
            ).toNumber(true);
        if (object.bidVolume != null)
          if ($util.Long)
            (message.bidVolume = $util.Long.fromValue(
              object.bidVolume,
            )).unsigned = true;
          else if (typeof object.bidVolume === 'string')
            message.bidVolume = parseInt(object.bidVolume, 10);
          else if (typeof object.bidVolume === 'number')
            message.bidVolume = object.bidVolume;
          else if (typeof object.bidVolume === 'object')
            message.bidVolume = new $util.LongBits(
              object.bidVolume.low >>> 0,
              object.bidVolume.high >>> 0,
            ).toNumber(true);
        if (object.askVolume != null)
          if ($util.Long)
            (message.askVolume = $util.Long.fromValue(
              object.askVolume,
            )).unsigned = true;
          else if (typeof object.askVolume === 'string')
            message.askVolume = parseInt(object.askVolume, 10);
          else if (typeof object.askVolume === 'number')
            message.askVolume = object.askVolume;
          else if (typeof object.askVolume === 'object')
            message.askVolume = new $util.LongBits(
              object.askVolume.low >>> 0,
              object.askVolume.high >>> 0,
            ).toNumber(true);
        if (object.openPrice != null)
          message.openPrice = Number(object.openPrice);
        if (object.closePrice != null)
          message.closePrice = Number(object.closePrice);
        if (object.highPrice != null)
          message.highPrice = Number(object.highPrice);
        if (object.lowPrice != null) message.lowPrice = Number(object.lowPrice);
        if (object.customSessionOpenSsm != null)
          message.customSessionOpenSsm = object.customSessionOpenSsm | 0;
        if (object.dataBarSsboe) {
          if (!Array.isArray(object.dataBarSsboe))
            throw TypeError('.rti.TickBar.dataBarSsboe: array expected');
          message.dataBarSsboe = [];
          for (var i = 0; i < object.dataBarSsboe.length; ++i)
            message.dataBarSsboe[i] = object.dataBarSsboe[i] | 0;
        }
        if (object.dataBarUsecs) {
          if (!Array.isArray(object.dataBarUsecs))
            throw TypeError('.rti.TickBar.dataBarUsecs: array expected');
          message.dataBarUsecs = [];
          for (var i = 0; i < object.dataBarUsecs.length; ++i)
            message.dataBarUsecs[i] = object.dataBarUsecs[i] | 0;
        }
        return message;
      };

      /**
       * Creates a plain object from a TickBar message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.TickBar
       * @static
       * @param {rti.TickBar} message TickBar
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      TickBar.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.dataBarSsboe = [];
          object.dataBarUsecs = [];
        }
        if (options.defaults) {
          object.highPrice = 0;
          object.lowPrice = 0;
          object.openPrice = 0;
          object.closePrice = 0;
          object.symbol = '';
          object.exchange = '';
          object.type = options.enums === String ? 'TICK_BAR' : 1;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.numTrades =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.numTrades = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.volume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.volume = options.longs === String ? '0' : 0;
          object.subType = options.enums === String ? 'REGULAR' : 1;
          object.customSessionOpenSsm = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.bidVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.bidVolume = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.askVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.askVolume = options.longs === String ? '0' : 0;
          object.typeSpecifier = '';
          object.templateId = 0;
        }
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          object.highPrice =
            options.json && !isFinite(message.highPrice)
              ? String(message.highPrice)
              : message.highPrice;
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          object.lowPrice =
            options.json && !isFinite(message.lowPrice)
              ? String(message.lowPrice)
              : message.lowPrice;
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          object.openPrice =
            options.json && !isFinite(message.openPrice)
              ? String(message.openPrice)
              : message.openPrice;
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          object.closePrice =
            options.json && !isFinite(message.closePrice)
              ? String(message.closePrice)
              : message.closePrice;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.type != null && message.hasOwnProperty('type'))
          object.type =
            options.enums === String
              ? $root.rti.TickBar.BarType[message.type]
              : message.type;
        if (message.dataBarSsboe && message.dataBarSsboe.length) {
          object.dataBarSsboe = [];
          for (var j = 0; j < message.dataBarSsboe.length; ++j)
            object.dataBarSsboe[j] = message.dataBarSsboe[j];
        }
        if (message.dataBarUsecs && message.dataBarUsecs.length) {
          object.dataBarUsecs = [];
          for (var j = 0; j < message.dataBarUsecs.length; ++j)
            object.dataBarUsecs[j] = message.dataBarUsecs[j];
        }
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (typeof message.numTrades === 'number')
            object.numTrades =
              options.longs === String
                ? String(message.numTrades)
                : message.numTrades;
          else
            object.numTrades =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.numTrades)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.numTrades.low >>> 0,
                      message.numTrades.high >>> 0,
                    ).toNumber(true)
                  : message.numTrades;
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (typeof message.volume === 'number')
            object.volume =
              options.longs === String
                ? String(message.volume)
                : message.volume;
          else
            object.volume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.volume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.volume.low >>> 0,
                      message.volume.high >>> 0,
                    ).toNumber(true)
                  : message.volume;
        if (message.subType != null && message.hasOwnProperty('subType'))
          object.subType =
            options.enums === String
              ? $root.rti.TickBar.BarSubType[message.subType]
              : message.subType;
        if (
          message.customSessionOpenSsm != null &&
          message.hasOwnProperty('customSessionOpenSsm')
        )
          object.customSessionOpenSsm = message.customSessionOpenSsm;
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (typeof message.bidVolume === 'number')
            object.bidVolume =
              options.longs === String
                ? String(message.bidVolume)
                : message.bidVolume;
          else
            object.bidVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.bidVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.bidVolume.low >>> 0,
                      message.bidVolume.high >>> 0,
                    ).toNumber(true)
                  : message.bidVolume;
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (typeof message.askVolume === 'number')
            object.askVolume =
              options.longs === String
                ? String(message.askVolume)
                : message.askVolume;
          else
            object.askVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.askVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.askVolume.low >>> 0,
                      message.askVolume.high >>> 0,
                    ).toNumber(true)
                  : message.askVolume;
        if (
          message.typeSpecifier != null &&
          message.hasOwnProperty('typeSpecifier')
        )
          object.typeSpecifier = message.typeSpecifier;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this TickBar to JSON.
       * @function toJSON
       * @memberof rti.TickBar
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      TickBar.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for TickBar
       * @function getTypeUrl
       * @memberof rti.TickBar
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      TickBar.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.TickBar';
      };

      /**
       * BarType enum.
       * @name rti.TickBar.BarType
       * @enum {number}
       * @property {number} TICK_BAR=1 TICK_BAR value
       * @property {number} RANGE_BAR=2 RANGE_BAR value
       * @property {number} VOLUME_BAR=3 VOLUME_BAR value
       */
      TickBar.BarType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'TICK_BAR')] = 1;
        values[(valuesById[2] = 'RANGE_BAR')] = 2;
        values[(valuesById[3] = 'VOLUME_BAR')] = 3;
        return values;
      })();

      /**
       * BarSubType enum.
       * @name rti.TickBar.BarSubType
       * @enum {number}
       * @property {number} REGULAR=1 REGULAR value
       * @property {number} CUSTOM=2 CUSTOM value
       */
      TickBar.BarSubType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'REGULAR')] = 1;
        values[(valuesById[2] = 'CUSTOM')] = 2;
        return values;
      })();

      return TickBar;
    })();

    rti.TimeBar = (function () {
      /**
       * Properties of a TimeBar.
       * @memberof rti
       * @interface ITimeBar
       * @property {number} templateId TimeBar templateId
       * @property {string|null} [symbol] TimeBar symbol
       * @property {string|null} [exchange] TimeBar exchange
       * @property {rti.TimeBar.BarType|null} [type] TimeBar type
       * @property {string|null} [period] TimeBar period
       * @property {number|null} [marker] TimeBar marker
       * @property {number|Long|null} [numTrades] TimeBar numTrades
       * @property {number|Long|null} [volume] TimeBar volume
       * @property {number|Long|null} [bidVolume] TimeBar bidVolume
       * @property {number|Long|null} [askVolume] TimeBar askVolume
       * @property {number|null} [openPrice] TimeBar openPrice
       * @property {number|null} [closePrice] TimeBar closePrice
       * @property {number|null} [highPrice] TimeBar highPrice
       * @property {number|null} [lowPrice] TimeBar lowPrice
       * @property {number|null} [settlementPrice] TimeBar settlementPrice
       * @property {boolean|null} [hasSettlementPrice] TimeBar hasSettlementPrice
       * @property {boolean|null} [mustClearSettlementPrice] TimeBar mustClearSettlementPrice
       */

      /**
       * Constructs a new TimeBar.
       * @memberof rti
       * @classdesc Represents a TimeBar.
       * @implements ITimeBar
       * @constructor
       * @param {rti.ITimeBar=} [properties] Properties to set
       */
      function TimeBar(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * TimeBar templateId.
       * @member {number} templateId
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.templateId = 0;

      /**
       * TimeBar symbol.
       * @member {string} symbol
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.symbol = '';

      /**
       * TimeBar exchange.
       * @member {string} exchange
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.exchange = '';

      /**
       * TimeBar type.
       * @member {rti.TimeBar.BarType} type
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.type = 1;

      /**
       * TimeBar period.
       * @member {string} period
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.period = '';

      /**
       * TimeBar marker.
       * @member {number} marker
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.marker = 0;

      /**
       * TimeBar numTrades.
       * @member {number|Long} numTrades
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.numTrades = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * TimeBar volume.
       * @member {number|Long} volume
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.volume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * TimeBar bidVolume.
       * @member {number|Long} bidVolume
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.bidVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * TimeBar askVolume.
       * @member {number|Long} askVolume
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.askVolume = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      /**
       * TimeBar openPrice.
       * @member {number} openPrice
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.openPrice = 0;

      /**
       * TimeBar closePrice.
       * @member {number} closePrice
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.closePrice = 0;

      /**
       * TimeBar highPrice.
       * @member {number} highPrice
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.highPrice = 0;

      /**
       * TimeBar lowPrice.
       * @member {number} lowPrice
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.lowPrice = 0;

      /**
       * TimeBar settlementPrice.
       * @member {number} settlementPrice
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.settlementPrice = 0;

      /**
       * TimeBar hasSettlementPrice.
       * @member {boolean} hasSettlementPrice
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.hasSettlementPrice = false;

      /**
       * TimeBar mustClearSettlementPrice.
       * @member {boolean} mustClearSettlementPrice
       * @memberof rti.TimeBar
       * @instance
       */
      TimeBar.prototype.mustClearSettlementPrice = false;

      /**
       * Creates a new TimeBar instance using the specified properties.
       * @function create
       * @memberof rti.TimeBar
       * @static
       * @param {rti.ITimeBar=} [properties] Properties to set
       * @returns {rti.TimeBar} TimeBar instance
       */
      TimeBar.create = function create(properties) {
        return new TimeBar(properties);
      };

      /**
       * Encodes the specified TimeBar message. Does not implicitly {@link rti.TimeBar.verify|verify} messages.
       * @function encode
       * @memberof rti.TimeBar
       * @static
       * @param {rti.ITimeBar} message TimeBar message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TimeBar.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.highPrice != null &&
          Object.hasOwnProperty.call(message, 'highPrice')
        )
          writer
            .uint32(/* id 100012, wireType 1 =*/ 800097)
            .double(message.highPrice);
        if (
          message.lowPrice != null &&
          Object.hasOwnProperty.call(message, 'lowPrice')
        )
          writer
            .uint32(/* id 100013, wireType 1 =*/ 800105)
            .double(message.lowPrice);
        if (
          message.openPrice != null &&
          Object.hasOwnProperty.call(message, 'openPrice')
        )
          writer
            .uint32(/* id 100019, wireType 1 =*/ 800153)
            .double(message.openPrice);
        if (
          message.closePrice != null &&
          Object.hasOwnProperty.call(message, 'closePrice')
        )
          writer
            .uint32(/* id 100021, wireType 1 =*/ 800169)
            .double(message.closePrice);
        if (
          message.settlementPrice != null &&
          Object.hasOwnProperty.call(message, 'settlementPrice')
        )
          writer
            .uint32(/* id 100070, wireType 1 =*/ 800561)
            .double(message.settlementPrice);
        if (
          message.symbol != null &&
          Object.hasOwnProperty.call(message, 'symbol')
        )
          writer
            .uint32(/* id 110100, wireType 2 =*/ 880802)
            .string(message.symbol);
        if (
          message.exchange != null &&
          Object.hasOwnProperty.call(message, 'exchange')
        )
          writer
            .uint32(/* id 110101, wireType 2 =*/ 880810)
            .string(message.exchange);
        if (
          message.marker != null &&
          Object.hasOwnProperty.call(message, 'marker')
        )
          writer
            .uint32(/* id 119100, wireType 0 =*/ 952800)
            .int32(message.marker);
        if (
          message.numTrades != null &&
          Object.hasOwnProperty.call(message, 'numTrades')
        )
          writer
            .uint32(/* id 119109, wireType 0 =*/ 952872)
            .uint64(message.numTrades);
        if (
          message.volume != null &&
          Object.hasOwnProperty.call(message, 'volume')
        )
          writer
            .uint32(/* id 119110, wireType 0 =*/ 952880)
            .uint64(message.volume);
        if (
          message.period != null &&
          Object.hasOwnProperty.call(message, 'period')
        )
          writer
            .uint32(/* id 119112, wireType 2 =*/ 952898)
            .string(message.period);
        if (
          message.bidVolume != null &&
          Object.hasOwnProperty.call(message, 'bidVolume')
        )
          writer
            .uint32(/* id 119117, wireType 0 =*/ 952936)
            .uint64(message.bidVolume);
        if (
          message.askVolume != null &&
          Object.hasOwnProperty.call(message, 'askVolume')
        )
          writer
            .uint32(/* id 119118, wireType 0 =*/ 952944)
            .uint64(message.askVolume);
        if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
          writer
            .uint32(/* id 119200, wireType 0 =*/ 953600)
            .int32(message.type);
        if (
          message.hasSettlementPrice != null &&
          Object.hasOwnProperty.call(message, 'hasSettlementPrice')
        )
          writer
            .uint32(/* id 149138, wireType 0 =*/ 1193104)
            .bool(message.hasSettlementPrice);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        if (
          message.mustClearSettlementPrice != null &&
          Object.hasOwnProperty.call(message, 'mustClearSettlementPrice')
        )
          writer
            .uint32(/* id 154571, wireType 0 =*/ 1236568)
            .bool(message.mustClearSettlementPrice);
        return writer;
      };

      /**
       * Encodes the specified TimeBar message, length delimited. Does not implicitly {@link rti.TimeBar.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.TimeBar
       * @static
       * @param {rti.ITimeBar} message TimeBar message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TimeBar.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a TimeBar message from the specified reader or buffer.
       * @function decode
       * @memberof rti.TimeBar
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.TimeBar} TimeBar
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TimeBar.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.TimeBar();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 110100:
              message.symbol = reader.string();
              break;
            case 110101:
              message.exchange = reader.string();
              break;
            case 119200:
              message.type = reader.int32();
              break;
            case 119112:
              message.period = reader.string();
              break;
            case 119100:
              message.marker = reader.int32();
              break;
            case 119109:
              message.numTrades = reader.uint64();
              break;
            case 119110:
              message.volume = reader.uint64();
              break;
            case 119117:
              message.bidVolume = reader.uint64();
              break;
            case 119118:
              message.askVolume = reader.uint64();
              break;
            case 100019:
              message.openPrice = reader.double();
              break;
            case 100021:
              message.closePrice = reader.double();
              break;
            case 100012:
              message.highPrice = reader.double();
              break;
            case 100013:
              message.lowPrice = reader.double();
              break;
            case 100070:
              message.settlementPrice = reader.double();
              break;
            case 149138:
              message.hasSettlementPrice = reader.bool();
              break;
            case 154571:
              message.mustClearSettlementPrice = reader.bool();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a TimeBar message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.TimeBar
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.TimeBar} TimeBar
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TimeBar.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a TimeBar message.
       * @function verify
       * @memberof rti.TimeBar
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      TimeBar.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          if (!$util.isString(message.symbol)) return 'symbol: string expected';
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          if (!$util.isString(message.exchange))
            return 'exchange: string expected';
        if (message.type != null && message.hasOwnProperty('type'))
          switch (message.type) {
            default:
              return 'type: enum value expected';
            case 1:
            case 2:
            case 3:
            case 4:
              break;
          }
        if (message.period != null && message.hasOwnProperty('period'))
          if (!$util.isString(message.period)) return 'period: string expected';
        if (message.marker != null && message.hasOwnProperty('marker'))
          if (!$util.isInteger(message.marker))
            return 'marker: integer expected';
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (
            !$util.isInteger(message.numTrades) &&
            !(
              message.numTrades &&
              $util.isInteger(message.numTrades.low) &&
              $util.isInteger(message.numTrades.high)
            )
          )
            return 'numTrades: integer|Long expected';
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (
            !$util.isInteger(message.volume) &&
            !(
              message.volume &&
              $util.isInteger(message.volume.low) &&
              $util.isInteger(message.volume.high)
            )
          )
            return 'volume: integer|Long expected';
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (
            !$util.isInteger(message.bidVolume) &&
            !(
              message.bidVolume &&
              $util.isInteger(message.bidVolume.low) &&
              $util.isInteger(message.bidVolume.high)
            )
          )
            return 'bidVolume: integer|Long expected';
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (
            !$util.isInteger(message.askVolume) &&
            !(
              message.askVolume &&
              $util.isInteger(message.askVolume.low) &&
              $util.isInteger(message.askVolume.high)
            )
          )
            return 'askVolume: integer|Long expected';
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          if (typeof message.openPrice !== 'number')
            return 'openPrice: number expected';
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          if (typeof message.closePrice !== 'number')
            return 'closePrice: number expected';
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          if (typeof message.highPrice !== 'number')
            return 'highPrice: number expected';
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          if (typeof message.lowPrice !== 'number')
            return 'lowPrice: number expected';
        if (
          message.settlementPrice != null &&
          message.hasOwnProperty('settlementPrice')
        )
          if (typeof message.settlementPrice !== 'number')
            return 'settlementPrice: number expected';
        if (
          message.hasSettlementPrice != null &&
          message.hasOwnProperty('hasSettlementPrice')
        )
          if (typeof message.hasSettlementPrice !== 'boolean')
            return 'hasSettlementPrice: boolean expected';
        if (
          message.mustClearSettlementPrice != null &&
          message.hasOwnProperty('mustClearSettlementPrice')
        )
          if (typeof message.mustClearSettlementPrice !== 'boolean')
            return 'mustClearSettlementPrice: boolean expected';
        return null;
      };

      /**
       * Creates a TimeBar message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.TimeBar
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.TimeBar} TimeBar
       */
      TimeBar.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.TimeBar) return object;
        var message = new $root.rti.TimeBar();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.symbol != null) message.symbol = String(object.symbol);
        if (object.exchange != null) message.exchange = String(object.exchange);
        switch (object.type) {
          case 'SECOND_BAR':
          case 1:
            message.type = 1;
            break;
          case 'MINUTE_BAR':
          case 2:
            message.type = 2;
            break;
          case 'DAILY_BAR':
          case 3:
            message.type = 3;
            break;
          case 'WEEKLY_BAR':
          case 4:
            message.type = 4;
            break;
        }
        if (object.period != null) message.period = String(object.period);
        if (object.marker != null) message.marker = object.marker | 0;
        if (object.numTrades != null)
          if ($util.Long)
            (message.numTrades = $util.Long.fromValue(
              object.numTrades,
            )).unsigned = true;
          else if (typeof object.numTrades === 'string')
            message.numTrades = parseInt(object.numTrades, 10);
          else if (typeof object.numTrades === 'number')
            message.numTrades = object.numTrades;
          else if (typeof object.numTrades === 'object')
            message.numTrades = new $util.LongBits(
              object.numTrades.low >>> 0,
              object.numTrades.high >>> 0,
            ).toNumber(true);
        if (object.volume != null)
          if ($util.Long)
            (message.volume = $util.Long.fromValue(object.volume)).unsigned =
              true;
          else if (typeof object.volume === 'string')
            message.volume = parseInt(object.volume, 10);
          else if (typeof object.volume === 'number')
            message.volume = object.volume;
          else if (typeof object.volume === 'object')
            message.volume = new $util.LongBits(
              object.volume.low >>> 0,
              object.volume.high >>> 0,
            ).toNumber(true);
        if (object.bidVolume != null)
          if ($util.Long)
            (message.bidVolume = $util.Long.fromValue(
              object.bidVolume,
            )).unsigned = true;
          else if (typeof object.bidVolume === 'string')
            message.bidVolume = parseInt(object.bidVolume, 10);
          else if (typeof object.bidVolume === 'number')
            message.bidVolume = object.bidVolume;
          else if (typeof object.bidVolume === 'object')
            message.bidVolume = new $util.LongBits(
              object.bidVolume.low >>> 0,
              object.bidVolume.high >>> 0,
            ).toNumber(true);
        if (object.askVolume != null)
          if ($util.Long)
            (message.askVolume = $util.Long.fromValue(
              object.askVolume,
            )).unsigned = true;
          else if (typeof object.askVolume === 'string')
            message.askVolume = parseInt(object.askVolume, 10);
          else if (typeof object.askVolume === 'number')
            message.askVolume = object.askVolume;
          else if (typeof object.askVolume === 'object')
            message.askVolume = new $util.LongBits(
              object.askVolume.low >>> 0,
              object.askVolume.high >>> 0,
            ).toNumber(true);
        if (object.openPrice != null)
          message.openPrice = Number(object.openPrice);
        if (object.closePrice != null)
          message.closePrice = Number(object.closePrice);
        if (object.highPrice != null)
          message.highPrice = Number(object.highPrice);
        if (object.lowPrice != null) message.lowPrice = Number(object.lowPrice);
        if (object.settlementPrice != null)
          message.settlementPrice = Number(object.settlementPrice);
        if (object.hasSettlementPrice != null)
          message.hasSettlementPrice = Boolean(object.hasSettlementPrice);
        if (object.mustClearSettlementPrice != null)
          message.mustClearSettlementPrice = Boolean(
            object.mustClearSettlementPrice,
          );
        return message;
      };

      /**
       * Creates a plain object from a TimeBar message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.TimeBar
       * @static
       * @param {rti.TimeBar} message TimeBar
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      TimeBar.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.highPrice = 0;
          object.lowPrice = 0;
          object.openPrice = 0;
          object.closePrice = 0;
          object.settlementPrice = 0;
          object.symbol = '';
          object.exchange = '';
          object.marker = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.numTrades =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.numTrades = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.volume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.volume = options.longs === String ? '0' : 0;
          object.period = '';
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.bidVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.bidVolume = options.longs === String ? '0' : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.askVolume =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                  ? long.toNumber()
                  : long;
          } else object.askVolume = options.longs === String ? '0' : 0;
          object.type = options.enums === String ? 'SECOND_BAR' : 1;
          object.hasSettlementPrice = false;
          object.templateId = 0;
          object.mustClearSettlementPrice = false;
        }
        if (message.highPrice != null && message.hasOwnProperty('highPrice'))
          object.highPrice =
            options.json && !isFinite(message.highPrice)
              ? String(message.highPrice)
              : message.highPrice;
        if (message.lowPrice != null && message.hasOwnProperty('lowPrice'))
          object.lowPrice =
            options.json && !isFinite(message.lowPrice)
              ? String(message.lowPrice)
              : message.lowPrice;
        if (message.openPrice != null && message.hasOwnProperty('openPrice'))
          object.openPrice =
            options.json && !isFinite(message.openPrice)
              ? String(message.openPrice)
              : message.openPrice;
        if (message.closePrice != null && message.hasOwnProperty('closePrice'))
          object.closePrice =
            options.json && !isFinite(message.closePrice)
              ? String(message.closePrice)
              : message.closePrice;
        if (
          message.settlementPrice != null &&
          message.hasOwnProperty('settlementPrice')
        )
          object.settlementPrice =
            options.json && !isFinite(message.settlementPrice)
              ? String(message.settlementPrice)
              : message.settlementPrice;
        if (message.symbol != null && message.hasOwnProperty('symbol'))
          object.symbol = message.symbol;
        if (message.exchange != null && message.hasOwnProperty('exchange'))
          object.exchange = message.exchange;
        if (message.marker != null && message.hasOwnProperty('marker'))
          object.marker = message.marker;
        if (message.numTrades != null && message.hasOwnProperty('numTrades'))
          if (typeof message.numTrades === 'number')
            object.numTrades =
              options.longs === String
                ? String(message.numTrades)
                : message.numTrades;
          else
            object.numTrades =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.numTrades)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.numTrades.low >>> 0,
                      message.numTrades.high >>> 0,
                    ).toNumber(true)
                  : message.numTrades;
        if (message.volume != null && message.hasOwnProperty('volume'))
          if (typeof message.volume === 'number')
            object.volume =
              options.longs === String
                ? String(message.volume)
                : message.volume;
          else
            object.volume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.volume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.volume.low >>> 0,
                      message.volume.high >>> 0,
                    ).toNumber(true)
                  : message.volume;
        if (message.period != null && message.hasOwnProperty('period'))
          object.period = message.period;
        if (message.bidVolume != null && message.hasOwnProperty('bidVolume'))
          if (typeof message.bidVolume === 'number')
            object.bidVolume =
              options.longs === String
                ? String(message.bidVolume)
                : message.bidVolume;
          else
            object.bidVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.bidVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.bidVolume.low >>> 0,
                      message.bidVolume.high >>> 0,
                    ).toNumber(true)
                  : message.bidVolume;
        if (message.askVolume != null && message.hasOwnProperty('askVolume'))
          if (typeof message.askVolume === 'number')
            object.askVolume =
              options.longs === String
                ? String(message.askVolume)
                : message.askVolume;
          else
            object.askVolume =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.askVolume)
                : options.longs === Number
                  ? new $util.LongBits(
                      message.askVolume.low >>> 0,
                      message.askVolume.high >>> 0,
                    ).toNumber(true)
                  : message.askVolume;
        if (message.type != null && message.hasOwnProperty('type'))
          object.type =
            options.enums === String
              ? $root.rti.TimeBar.BarType[message.type]
              : message.type;
        if (
          message.hasSettlementPrice != null &&
          message.hasOwnProperty('hasSettlementPrice')
        )
          object.hasSettlementPrice = message.hasSettlementPrice;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        if (
          message.mustClearSettlementPrice != null &&
          message.hasOwnProperty('mustClearSettlementPrice')
        )
          object.mustClearSettlementPrice = message.mustClearSettlementPrice;
        return object;
      };

      /**
       * Converts this TimeBar to JSON.
       * @function toJSON
       * @memberof rti.TimeBar
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      TimeBar.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for TimeBar
       * @function getTypeUrl
       * @memberof rti.TimeBar
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      TimeBar.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.TimeBar';
      };

      /**
       * BarType enum.
       * @name rti.TimeBar.BarType
       * @enum {number}
       * @property {number} SECOND_BAR=1 SECOND_BAR value
       * @property {number} MINUTE_BAR=2 MINUTE_BAR value
       * @property {number} DAILY_BAR=3 DAILY_BAR value
       * @property {number} WEEKLY_BAR=4 WEEKLY_BAR value
       */
      TimeBar.BarType = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[1] = 'SECOND_BAR')] = 1;
        values[(valuesById[2] = 'MINUTE_BAR')] = 2;
        values[(valuesById[3] = 'DAILY_BAR')] = 3;
        values[(valuesById[4] = 'WEEKLY_BAR')] = 4;
        return values;
      })();

      return TimeBar;
    })();

    rti.RequestListUnacceptedAgreements = (function () {
      /**
       * Properties of a RequestListUnacceptedAgreements.
       * @memberof rti
       * @interface IRequestListUnacceptedAgreements
       * @property {number} templateId RequestListUnacceptedAgreements templateId
       * @property {Array.<string>|null} [userMsg] RequestListUnacceptedAgreements userMsg
       */

      /**
       * Constructs a new RequestListUnacceptedAgreements.
       * @memberof rti
       * @classdesc Represents a RequestListUnacceptedAgreements.
       * @implements IRequestListUnacceptedAgreements
       * @constructor
       * @param {rti.IRequestListUnacceptedAgreements=} [properties] Properties to set
       */
      function RequestListUnacceptedAgreements(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestListUnacceptedAgreements templateId.
       * @member {number} templateId
       * @memberof rti.RequestListUnacceptedAgreements
       * @instance
       */
      RequestListUnacceptedAgreements.prototype.templateId = 0;

      /**
       * RequestListUnacceptedAgreements userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestListUnacceptedAgreements
       * @instance
       */
      RequestListUnacceptedAgreements.prototype.userMsg = $util.emptyArray;

      /**
       * Creates a new RequestListUnacceptedAgreements instance using the specified properties.
       * @function create
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {rti.IRequestListUnacceptedAgreements=} [properties] Properties to set
       * @returns {rti.RequestListUnacceptedAgreements} RequestListUnacceptedAgreements instance
       */
      RequestListUnacceptedAgreements.create = function create(properties) {
        return new RequestListUnacceptedAgreements(properties);
      };

      /**
       * Encodes the specified RequestListUnacceptedAgreements message. Does not implicitly {@link rti.RequestListUnacceptedAgreements.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {rti.IRequestListUnacceptedAgreements} message RequestListUnacceptedAgreements message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestListUnacceptedAgreements.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestListUnacceptedAgreements message, length delimited. Does not implicitly {@link rti.RequestListUnacceptedAgreements.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {rti.IRequestListUnacceptedAgreements} message RequestListUnacceptedAgreements message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestListUnacceptedAgreements.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestListUnacceptedAgreements message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestListUnacceptedAgreements} RequestListUnacceptedAgreements
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestListUnacceptedAgreements.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestListUnacceptedAgreements();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestListUnacceptedAgreements message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestListUnacceptedAgreements} RequestListUnacceptedAgreements
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestListUnacceptedAgreements.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestListUnacceptedAgreements message.
       * @function verify
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestListUnacceptedAgreements.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        return null;
      };

      /**
       * Creates a RequestListUnacceptedAgreements message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestListUnacceptedAgreements} RequestListUnacceptedAgreements
       */
      RequestListUnacceptedAgreements.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestListUnacceptedAgreements)
          return object;
        var message = new $root.rti.RequestListUnacceptedAgreements();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestListUnacceptedAgreements.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestListUnacceptedAgreements message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {rti.RequestListUnacceptedAgreements} message RequestListUnacceptedAgreements
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestListUnacceptedAgreements.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestListUnacceptedAgreements to JSON.
       * @function toJSON
       * @memberof rti.RequestListUnacceptedAgreements
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestListUnacceptedAgreements.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestListUnacceptedAgreements
       * @function getTypeUrl
       * @memberof rti.RequestListUnacceptedAgreements
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestListUnacceptedAgreements.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestListUnacceptedAgreements';
      };

      return RequestListUnacceptedAgreements;
    })();

    rti.ResponseListUnacceptedAgreements = (function () {
      /**
       * Properties of a ResponseListUnacceptedAgreements.
       * @memberof rti
       * @interface IResponseListUnacceptedAgreements
       * @property {number} templateId ResponseListUnacceptedAgreements templateId
       * @property {Array.<string>|null} [userMsg] ResponseListUnacceptedAgreements userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseListUnacceptedAgreements rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseListUnacceptedAgreements rpCode
       * @property {string|null} [fcmId] ResponseListUnacceptedAgreements fcmId
       * @property {string|null} [ibId] ResponseListUnacceptedAgreements ibId
       * @property {string|null} [agreementTitle] ResponseListUnacceptedAgreements agreementTitle
       * @property {string|null} [agreementId] ResponseListUnacceptedAgreements agreementId
       * @property {string|null} [agreementAcceptanceRequest] ResponseListUnacceptedAgreements agreementAcceptanceRequest
       */

      /**
       * Constructs a new ResponseListUnacceptedAgreements.
       * @memberof rti
       * @classdesc Represents a ResponseListUnacceptedAgreements.
       * @implements IResponseListUnacceptedAgreements
       * @constructor
       * @param {rti.IResponseListUnacceptedAgreements=} [properties] Properties to set
       */
      function ResponseListUnacceptedAgreements(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseListUnacceptedAgreements templateId.
       * @member {number} templateId
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.templateId = 0;

      /**
       * ResponseListUnacceptedAgreements userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseListUnacceptedAgreements rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.rqHandlerRpCode =
        $util.emptyArray;

      /**
       * ResponseListUnacceptedAgreements rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseListUnacceptedAgreements fcmId.
       * @member {string} fcmId
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.fcmId = '';

      /**
       * ResponseListUnacceptedAgreements ibId.
       * @member {string} ibId
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.ibId = '';

      /**
       * ResponseListUnacceptedAgreements agreementTitle.
       * @member {string} agreementTitle
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.agreementTitle = '';

      /**
       * ResponseListUnacceptedAgreements agreementId.
       * @member {string} agreementId
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.agreementId = '';

      /**
       * ResponseListUnacceptedAgreements agreementAcceptanceRequest.
       * @member {string} agreementAcceptanceRequest
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       */
      ResponseListUnacceptedAgreements.prototype.agreementAcceptanceRequest =
        '';

      /**
       * Creates a new ResponseListUnacceptedAgreements instance using the specified properties.
       * @function create
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {rti.IResponseListUnacceptedAgreements=} [properties] Properties to set
       * @returns {rti.ResponseListUnacceptedAgreements} ResponseListUnacceptedAgreements instance
       */
      ResponseListUnacceptedAgreements.create = function create(properties) {
        return new ResponseListUnacceptedAgreements(properties);
      };

      /**
       * Encodes the specified ResponseListUnacceptedAgreements message. Does not implicitly {@link rti.ResponseListUnacceptedAgreements.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {rti.IResponseListUnacceptedAgreements} message ResponseListUnacceptedAgreements message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseListUnacceptedAgreements.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.agreementTitle != null &&
          Object.hasOwnProperty.call(message, 'agreementTitle')
        )
          writer
            .uint32(/* id 153406, wireType 2 =*/ 1227250)
            .string(message.agreementTitle);
        if (
          message.agreementId != null &&
          Object.hasOwnProperty.call(message, 'agreementId')
        )
          writer
            .uint32(/* id 153407, wireType 2 =*/ 1227258)
            .string(message.agreementId);
        if (
          message.agreementAcceptanceRequest != null &&
          Object.hasOwnProperty.call(message, 'agreementAcceptanceRequest')
        )
          writer
            .uint32(/* id 153430, wireType 2 =*/ 1227442)
            .string(message.agreementAcceptanceRequest);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseListUnacceptedAgreements message, length delimited. Does not implicitly {@link rti.ResponseListUnacceptedAgreements.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {rti.IResponseListUnacceptedAgreements} message ResponseListUnacceptedAgreements message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseListUnacceptedAgreements.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseListUnacceptedAgreements message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseListUnacceptedAgreements} ResponseListUnacceptedAgreements
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseListUnacceptedAgreements.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseListUnacceptedAgreements();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 153406:
              message.agreementTitle = reader.string();
              break;
            case 153407:
              message.agreementId = reader.string();
              break;
            case 153430:
              message.agreementAcceptanceRequest = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseListUnacceptedAgreements message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseListUnacceptedAgreements} ResponseListUnacceptedAgreements
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseListUnacceptedAgreements.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseListUnacceptedAgreements message.
       * @function verify
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseListUnacceptedAgreements.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (
          message.agreementTitle != null &&
          message.hasOwnProperty('agreementTitle')
        )
          if (!$util.isString(message.agreementTitle))
            return 'agreementTitle: string expected';
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          if (!$util.isString(message.agreementId))
            return 'agreementId: string expected';
        if (
          message.agreementAcceptanceRequest != null &&
          message.hasOwnProperty('agreementAcceptanceRequest')
        )
          if (!$util.isString(message.agreementAcceptanceRequest))
            return 'agreementAcceptanceRequest: string expected';
        return null;
      };

      /**
       * Creates a ResponseListUnacceptedAgreements message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseListUnacceptedAgreements} ResponseListUnacceptedAgreements
       */
      ResponseListUnacceptedAgreements.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.ResponseListUnacceptedAgreements)
          return object;
        var message = new $root.rti.ResponseListUnacceptedAgreements();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseListUnacceptedAgreements.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseListUnacceptedAgreements.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseListUnacceptedAgreements.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.agreementTitle != null)
          message.agreementTitle = String(object.agreementTitle);
        if (object.agreementId != null)
          message.agreementId = String(object.agreementId);
        if (object.agreementAcceptanceRequest != null)
          message.agreementAcceptanceRequest = String(
            object.agreementAcceptanceRequest,
          );
        return message;
      };

      /**
       * Creates a plain object from a ResponseListUnacceptedAgreements message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {rti.ResponseListUnacceptedAgreements} message ResponseListUnacceptedAgreements
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseListUnacceptedAgreements.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.agreementTitle = '';
          object.agreementId = '';
          object.agreementAcceptanceRequest = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.agreementTitle != null &&
          message.hasOwnProperty('agreementTitle')
        )
          object.agreementTitle = message.agreementTitle;
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          object.agreementId = message.agreementId;
        if (
          message.agreementAcceptanceRequest != null &&
          message.hasOwnProperty('agreementAcceptanceRequest')
        )
          object.agreementAcceptanceRequest =
            message.agreementAcceptanceRequest;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseListUnacceptedAgreements to JSON.
       * @function toJSON
       * @memberof rti.ResponseListUnacceptedAgreements
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseListUnacceptedAgreements.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseListUnacceptedAgreements
       * @function getTypeUrl
       * @memberof rti.ResponseListUnacceptedAgreements
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseListUnacceptedAgreements.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseListUnacceptedAgreements';
      };

      return ResponseListUnacceptedAgreements;
    })();

    rti.RequestListAcceptedAgreements = (function () {
      /**
       * Properties of a RequestListAcceptedAgreements.
       * @memberof rti
       * @interface IRequestListAcceptedAgreements
       * @property {number} templateId RequestListAcceptedAgreements templateId
       * @property {Array.<string>|null} [userMsg] RequestListAcceptedAgreements userMsg
       */

      /**
       * Constructs a new RequestListAcceptedAgreements.
       * @memberof rti
       * @classdesc Represents a RequestListAcceptedAgreements.
       * @implements IRequestListAcceptedAgreements
       * @constructor
       * @param {rti.IRequestListAcceptedAgreements=} [properties] Properties to set
       */
      function RequestListAcceptedAgreements(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestListAcceptedAgreements templateId.
       * @member {number} templateId
       * @memberof rti.RequestListAcceptedAgreements
       * @instance
       */
      RequestListAcceptedAgreements.prototype.templateId = 0;

      /**
       * RequestListAcceptedAgreements userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestListAcceptedAgreements
       * @instance
       */
      RequestListAcceptedAgreements.prototype.userMsg = $util.emptyArray;

      /**
       * Creates a new RequestListAcceptedAgreements instance using the specified properties.
       * @function create
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {rti.IRequestListAcceptedAgreements=} [properties] Properties to set
       * @returns {rti.RequestListAcceptedAgreements} RequestListAcceptedAgreements instance
       */
      RequestListAcceptedAgreements.create = function create(properties) {
        return new RequestListAcceptedAgreements(properties);
      };

      /**
       * Encodes the specified RequestListAcceptedAgreements message. Does not implicitly {@link rti.RequestListAcceptedAgreements.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {rti.IRequestListAcceptedAgreements} message RequestListAcceptedAgreements message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestListAcceptedAgreements.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestListAcceptedAgreements message, length delimited. Does not implicitly {@link rti.RequestListAcceptedAgreements.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {rti.IRequestListAcceptedAgreements} message RequestListAcceptedAgreements message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestListAcceptedAgreements.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestListAcceptedAgreements message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestListAcceptedAgreements} RequestListAcceptedAgreements
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestListAcceptedAgreements.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestListAcceptedAgreements();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestListAcceptedAgreements message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestListAcceptedAgreements} RequestListAcceptedAgreements
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestListAcceptedAgreements.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestListAcceptedAgreements message.
       * @function verify
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestListAcceptedAgreements.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        return null;
      };

      /**
       * Creates a RequestListAcceptedAgreements message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestListAcceptedAgreements} RequestListAcceptedAgreements
       */
      RequestListAcceptedAgreements.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestListAcceptedAgreements)
          return object;
        var message = new $root.rti.RequestListAcceptedAgreements();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestListAcceptedAgreements.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a RequestListAcceptedAgreements message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {rti.RequestListAcceptedAgreements} message RequestListAcceptedAgreements
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestListAcceptedAgreements.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestListAcceptedAgreements to JSON.
       * @function toJSON
       * @memberof rti.RequestListAcceptedAgreements
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestListAcceptedAgreements.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestListAcceptedAgreements
       * @function getTypeUrl
       * @memberof rti.RequestListAcceptedAgreements
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestListAcceptedAgreements.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestListAcceptedAgreements';
      };

      return RequestListAcceptedAgreements;
    })();

    rti.ResponseListAcceptedAgreements = (function () {
      /**
       * Properties of a ResponseListAcceptedAgreements.
       * @memberof rti
       * @interface IResponseListAcceptedAgreements
       * @property {number} templateId ResponseListAcceptedAgreements templateId
       * @property {Array.<string>|null} [userMsg] ResponseListAcceptedAgreements userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseListAcceptedAgreements rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseListAcceptedAgreements rpCode
       * @property {string|null} [fcmId] ResponseListAcceptedAgreements fcmId
       * @property {string|null} [ibId] ResponseListAcceptedAgreements ibId
       * @property {number|null} [agreementAcceptanceSsboe] ResponseListAcceptedAgreements agreementAcceptanceSsboe
       * @property {string|null} [agreementAcceptanceStatus] ResponseListAcceptedAgreements agreementAcceptanceStatus
       * @property {string|null} [agreementAcceptanceRequest] ResponseListAcceptedAgreements agreementAcceptanceRequest
       * @property {string|null} [agreementTitle] ResponseListAcceptedAgreements agreementTitle
       * @property {string|null} [agreementId] ResponseListAcceptedAgreements agreementId
       */

      /**
       * Constructs a new ResponseListAcceptedAgreements.
       * @memberof rti
       * @classdesc Represents a ResponseListAcceptedAgreements.
       * @implements IResponseListAcceptedAgreements
       * @constructor
       * @param {rti.IResponseListAcceptedAgreements=} [properties] Properties to set
       */
      function ResponseListAcceptedAgreements(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseListAcceptedAgreements templateId.
       * @member {number} templateId
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.templateId = 0;

      /**
       * ResponseListAcceptedAgreements userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseListAcceptedAgreements rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.rqHandlerRpCode =
        $util.emptyArray;

      /**
       * ResponseListAcceptedAgreements rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseListAcceptedAgreements fcmId.
       * @member {string} fcmId
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.fcmId = '';

      /**
       * ResponseListAcceptedAgreements ibId.
       * @member {string} ibId
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.ibId = '';

      /**
       * ResponseListAcceptedAgreements agreementAcceptanceSsboe.
       * @member {number} agreementAcceptanceSsboe
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.agreementAcceptanceSsboe = 0;

      /**
       * ResponseListAcceptedAgreements agreementAcceptanceStatus.
       * @member {string} agreementAcceptanceStatus
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.agreementAcceptanceStatus = '';

      /**
       * ResponseListAcceptedAgreements agreementAcceptanceRequest.
       * @member {string} agreementAcceptanceRequest
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.agreementAcceptanceRequest = '';

      /**
       * ResponseListAcceptedAgreements agreementTitle.
       * @member {string} agreementTitle
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.agreementTitle = '';

      /**
       * ResponseListAcceptedAgreements agreementId.
       * @member {string} agreementId
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       */
      ResponseListAcceptedAgreements.prototype.agreementId = '';

      /**
       * Creates a new ResponseListAcceptedAgreements instance using the specified properties.
       * @function create
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {rti.IResponseListAcceptedAgreements=} [properties] Properties to set
       * @returns {rti.ResponseListAcceptedAgreements} ResponseListAcceptedAgreements instance
       */
      ResponseListAcceptedAgreements.create = function create(properties) {
        return new ResponseListAcceptedAgreements(properties);
      };

      /**
       * Encodes the specified ResponseListAcceptedAgreements message. Does not implicitly {@link rti.ResponseListAcceptedAgreements.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {rti.IResponseListAcceptedAgreements} message ResponseListAcceptedAgreements message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseListAcceptedAgreements.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.agreementTitle != null &&
          Object.hasOwnProperty.call(message, 'agreementTitle')
        )
          writer
            .uint32(/* id 153406, wireType 2 =*/ 1227250)
            .string(message.agreementTitle);
        if (
          message.agreementId != null &&
          Object.hasOwnProperty.call(message, 'agreementId')
        )
          writer
            .uint32(/* id 153407, wireType 2 =*/ 1227258)
            .string(message.agreementId);
        if (
          message.agreementAcceptanceStatus != null &&
          Object.hasOwnProperty.call(message, 'agreementAcceptanceStatus')
        )
          writer
            .uint32(/* id 153426, wireType 2 =*/ 1227410)
            .string(message.agreementAcceptanceStatus);
        if (
          message.agreementAcceptanceSsboe != null &&
          Object.hasOwnProperty.call(message, 'agreementAcceptanceSsboe')
        )
          writer
            .uint32(/* id 153427, wireType 0 =*/ 1227416)
            .int32(message.agreementAcceptanceSsboe);
        if (
          message.agreementAcceptanceRequest != null &&
          Object.hasOwnProperty.call(message, 'agreementAcceptanceRequest')
        )
          writer
            .uint32(/* id 153430, wireType 2 =*/ 1227442)
            .string(message.agreementAcceptanceRequest);
        if (
          message.fcmId != null &&
          Object.hasOwnProperty.call(message, 'fcmId')
        )
          writer
            .uint32(/* id 154013, wireType 2 =*/ 1232106)
            .string(message.fcmId);
        if (message.ibId != null && Object.hasOwnProperty.call(message, 'ibId'))
          writer
            .uint32(/* id 154014, wireType 2 =*/ 1232114)
            .string(message.ibId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseListAcceptedAgreements message, length delimited. Does not implicitly {@link rti.ResponseListAcceptedAgreements.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {rti.IResponseListAcceptedAgreements} message ResponseListAcceptedAgreements message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseListAcceptedAgreements.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseListAcceptedAgreements message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseListAcceptedAgreements} ResponseListAcceptedAgreements
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseListAcceptedAgreements.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseListAcceptedAgreements();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 154013:
              message.fcmId = reader.string();
              break;
            case 154014:
              message.ibId = reader.string();
              break;
            case 153427:
              message.agreementAcceptanceSsboe = reader.int32();
              break;
            case 153426:
              message.agreementAcceptanceStatus = reader.string();
              break;
            case 153430:
              message.agreementAcceptanceRequest = reader.string();
              break;
            case 153406:
              message.agreementTitle = reader.string();
              break;
            case 153407:
              message.agreementId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseListAcceptedAgreements message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseListAcceptedAgreements} ResponseListAcceptedAgreements
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseListAcceptedAgreements.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseListAcceptedAgreements message.
       * @function verify
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseListAcceptedAgreements.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          if (!$util.isString(message.fcmId)) return 'fcmId: string expected';
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          if (!$util.isString(message.ibId)) return 'ibId: string expected';
        if (
          message.agreementAcceptanceSsboe != null &&
          message.hasOwnProperty('agreementAcceptanceSsboe')
        )
          if (!$util.isInteger(message.agreementAcceptanceSsboe))
            return 'agreementAcceptanceSsboe: integer expected';
        if (
          message.agreementAcceptanceStatus != null &&
          message.hasOwnProperty('agreementAcceptanceStatus')
        )
          if (!$util.isString(message.agreementAcceptanceStatus))
            return 'agreementAcceptanceStatus: string expected';
        if (
          message.agreementAcceptanceRequest != null &&
          message.hasOwnProperty('agreementAcceptanceRequest')
        )
          if (!$util.isString(message.agreementAcceptanceRequest))
            return 'agreementAcceptanceRequest: string expected';
        if (
          message.agreementTitle != null &&
          message.hasOwnProperty('agreementTitle')
        )
          if (!$util.isString(message.agreementTitle))
            return 'agreementTitle: string expected';
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          if (!$util.isString(message.agreementId))
            return 'agreementId: string expected';
        return null;
      };

      /**
       * Creates a ResponseListAcceptedAgreements message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseListAcceptedAgreements} ResponseListAcceptedAgreements
       */
      ResponseListAcceptedAgreements.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseListAcceptedAgreements)
          return object;
        var message = new $root.rti.ResponseListAcceptedAgreements();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseListAcceptedAgreements.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseListAcceptedAgreements.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseListAcceptedAgreements.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.fcmId != null) message.fcmId = String(object.fcmId);
        if (object.ibId != null) message.ibId = String(object.ibId);
        if (object.agreementAcceptanceSsboe != null)
          message.agreementAcceptanceSsboe =
            object.agreementAcceptanceSsboe | 0;
        if (object.agreementAcceptanceStatus != null)
          message.agreementAcceptanceStatus = String(
            object.agreementAcceptanceStatus,
          );
        if (object.agreementAcceptanceRequest != null)
          message.agreementAcceptanceRequest = String(
            object.agreementAcceptanceRequest,
          );
        if (object.agreementTitle != null)
          message.agreementTitle = String(object.agreementTitle);
        if (object.agreementId != null)
          message.agreementId = String(object.agreementId);
        return message;
      };

      /**
       * Creates a plain object from a ResponseListAcceptedAgreements message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {rti.ResponseListAcceptedAgreements} message ResponseListAcceptedAgreements
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseListAcceptedAgreements.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          object.agreementTitle = '';
          object.agreementId = '';
          object.agreementAcceptanceStatus = '';
          object.agreementAcceptanceSsboe = 0;
          object.agreementAcceptanceRequest = '';
          object.fcmId = '';
          object.ibId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (
          message.agreementTitle != null &&
          message.hasOwnProperty('agreementTitle')
        )
          object.agreementTitle = message.agreementTitle;
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          object.agreementId = message.agreementId;
        if (
          message.agreementAcceptanceStatus != null &&
          message.hasOwnProperty('agreementAcceptanceStatus')
        )
          object.agreementAcceptanceStatus = message.agreementAcceptanceStatus;
        if (
          message.agreementAcceptanceSsboe != null &&
          message.hasOwnProperty('agreementAcceptanceSsboe')
        )
          object.agreementAcceptanceSsboe = message.agreementAcceptanceSsboe;
        if (
          message.agreementAcceptanceRequest != null &&
          message.hasOwnProperty('agreementAcceptanceRequest')
        )
          object.agreementAcceptanceRequest =
            message.agreementAcceptanceRequest;
        if (message.fcmId != null && message.hasOwnProperty('fcmId'))
          object.fcmId = message.fcmId;
        if (message.ibId != null && message.hasOwnProperty('ibId'))
          object.ibId = message.ibId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseListAcceptedAgreements to JSON.
       * @function toJSON
       * @memberof rti.ResponseListAcceptedAgreements
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseListAcceptedAgreements.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseListAcceptedAgreements
       * @function getTypeUrl
       * @memberof rti.ResponseListAcceptedAgreements
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseListAcceptedAgreements.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseListAcceptedAgreements';
      };

      return ResponseListAcceptedAgreements;
    })();

    rti.RequestAcceptAgreement = (function () {
      /**
       * Properties of a RequestAcceptAgreement.
       * @memberof rti
       * @interface IRequestAcceptAgreement
       * @property {number} templateId RequestAcceptAgreement templateId
       * @property {Array.<string>|null} [userMsg] RequestAcceptAgreement userMsg
       * @property {string|null} [agreementId] RequestAcceptAgreement agreementId
       * @property {string|null} [marketDataUsageCapacity] RequestAcceptAgreement marketDataUsageCapacity
       */

      /**
       * Constructs a new RequestAcceptAgreement.
       * @memberof rti
       * @classdesc Represents a RequestAcceptAgreement.
       * @implements IRequestAcceptAgreement
       * @constructor
       * @param {rti.IRequestAcceptAgreement=} [properties] Properties to set
       */
      function RequestAcceptAgreement(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestAcceptAgreement templateId.
       * @member {number} templateId
       * @memberof rti.RequestAcceptAgreement
       * @instance
       */
      RequestAcceptAgreement.prototype.templateId = 0;

      /**
       * RequestAcceptAgreement userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestAcceptAgreement
       * @instance
       */
      RequestAcceptAgreement.prototype.userMsg = $util.emptyArray;

      /**
       * RequestAcceptAgreement agreementId.
       * @member {string} agreementId
       * @memberof rti.RequestAcceptAgreement
       * @instance
       */
      RequestAcceptAgreement.prototype.agreementId = '';

      /**
       * RequestAcceptAgreement marketDataUsageCapacity.
       * @member {string} marketDataUsageCapacity
       * @memberof rti.RequestAcceptAgreement
       * @instance
       */
      RequestAcceptAgreement.prototype.marketDataUsageCapacity = '';

      /**
       * Creates a new RequestAcceptAgreement instance using the specified properties.
       * @function create
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {rti.IRequestAcceptAgreement=} [properties] Properties to set
       * @returns {rti.RequestAcceptAgreement} RequestAcceptAgreement instance
       */
      RequestAcceptAgreement.create = function create(properties) {
        return new RequestAcceptAgreement(properties);
      };

      /**
       * Encodes the specified RequestAcceptAgreement message. Does not implicitly {@link rti.RequestAcceptAgreement.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {rti.IRequestAcceptAgreement} message RequestAcceptAgreement message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAcceptAgreement.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.agreementId != null &&
          Object.hasOwnProperty.call(message, 'agreementId')
        )
          writer
            .uint32(/* id 153407, wireType 2 =*/ 1227258)
            .string(message.agreementId);
        if (
          message.marketDataUsageCapacity != null &&
          Object.hasOwnProperty.call(message, 'marketDataUsageCapacity')
        )
          writer
            .uint32(/* id 153431, wireType 2 =*/ 1227450)
            .string(message.marketDataUsageCapacity);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestAcceptAgreement message, length delimited. Does not implicitly {@link rti.RequestAcceptAgreement.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {rti.IRequestAcceptAgreement} message RequestAcceptAgreement message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestAcceptAgreement.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestAcceptAgreement message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestAcceptAgreement} RequestAcceptAgreement
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAcceptAgreement.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestAcceptAgreement();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 153407:
              message.agreementId = reader.string();
              break;
            case 153431:
              message.marketDataUsageCapacity = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestAcceptAgreement message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestAcceptAgreement} RequestAcceptAgreement
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestAcceptAgreement.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestAcceptAgreement message.
       * @function verify
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestAcceptAgreement.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          if (!$util.isString(message.agreementId))
            return 'agreementId: string expected';
        if (
          message.marketDataUsageCapacity != null &&
          message.hasOwnProperty('marketDataUsageCapacity')
        )
          if (!$util.isString(message.marketDataUsageCapacity))
            return 'marketDataUsageCapacity: string expected';
        return null;
      };

      /**
       * Creates a RequestAcceptAgreement message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestAcceptAgreement} RequestAcceptAgreement
       */
      RequestAcceptAgreement.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestAcceptAgreement) return object;
        var message = new $root.rti.RequestAcceptAgreement();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestAcceptAgreement.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.agreementId != null)
          message.agreementId = String(object.agreementId);
        if (object.marketDataUsageCapacity != null)
          message.marketDataUsageCapacity = String(
            object.marketDataUsageCapacity,
          );
        return message;
      };

      /**
       * Creates a plain object from a RequestAcceptAgreement message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {rti.RequestAcceptAgreement} message RequestAcceptAgreement
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestAcceptAgreement.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.agreementId = '';
          object.marketDataUsageCapacity = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          object.agreementId = message.agreementId;
        if (
          message.marketDataUsageCapacity != null &&
          message.hasOwnProperty('marketDataUsageCapacity')
        )
          object.marketDataUsageCapacity = message.marketDataUsageCapacity;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestAcceptAgreement to JSON.
       * @function toJSON
       * @memberof rti.RequestAcceptAgreement
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestAcceptAgreement.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestAcceptAgreement
       * @function getTypeUrl
       * @memberof rti.RequestAcceptAgreement
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestAcceptAgreement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestAcceptAgreement';
      };

      return RequestAcceptAgreement;
    })();

    rti.ResponseAcceptAgreement = (function () {
      /**
       * Properties of a ResponseAcceptAgreement.
       * @memberof rti
       * @interface IResponseAcceptAgreement
       * @property {number} templateId ResponseAcceptAgreement templateId
       * @property {Array.<string>|null} [userMsg] ResponseAcceptAgreement userMsg
       * @property {Array.<string>|null} [rpCode] ResponseAcceptAgreement rpCode
       */

      /**
       * Constructs a new ResponseAcceptAgreement.
       * @memberof rti
       * @classdesc Represents a ResponseAcceptAgreement.
       * @implements IResponseAcceptAgreement
       * @constructor
       * @param {rti.IResponseAcceptAgreement=} [properties] Properties to set
       */
      function ResponseAcceptAgreement(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseAcceptAgreement templateId.
       * @member {number} templateId
       * @memberof rti.ResponseAcceptAgreement
       * @instance
       */
      ResponseAcceptAgreement.prototype.templateId = 0;

      /**
       * ResponseAcceptAgreement userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseAcceptAgreement
       * @instance
       */
      ResponseAcceptAgreement.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseAcceptAgreement rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseAcceptAgreement
       * @instance
       */
      ResponseAcceptAgreement.prototype.rpCode = $util.emptyArray;

      /**
       * Creates a new ResponseAcceptAgreement instance using the specified properties.
       * @function create
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {rti.IResponseAcceptAgreement=} [properties] Properties to set
       * @returns {rti.ResponseAcceptAgreement} ResponseAcceptAgreement instance
       */
      ResponseAcceptAgreement.create = function create(properties) {
        return new ResponseAcceptAgreement(properties);
      };

      /**
       * Encodes the specified ResponseAcceptAgreement message. Does not implicitly {@link rti.ResponseAcceptAgreement.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {rti.IResponseAcceptAgreement} message ResponseAcceptAgreement message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAcceptAgreement.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseAcceptAgreement message, length delimited. Does not implicitly {@link rti.ResponseAcceptAgreement.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {rti.IResponseAcceptAgreement} message ResponseAcceptAgreement message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseAcceptAgreement.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseAcceptAgreement message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseAcceptAgreement} ResponseAcceptAgreement
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAcceptAgreement.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseAcceptAgreement();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseAcceptAgreement message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseAcceptAgreement} ResponseAcceptAgreement
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseAcceptAgreement.decodeDelimited = function decodeDelimited(
        reader,
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseAcceptAgreement message.
       * @function verify
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseAcceptAgreement.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseAcceptAgreement message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseAcceptAgreement} ResponseAcceptAgreement
       */
      ResponseAcceptAgreement.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseAcceptAgreement) return object;
        var message = new $root.rti.ResponseAcceptAgreement();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseAcceptAgreement.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseAcceptAgreement.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseAcceptAgreement message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {rti.ResponseAcceptAgreement} message ResponseAcceptAgreement
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseAcceptAgreement.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseAcceptAgreement to JSON.
       * @function toJSON
       * @memberof rti.ResponseAcceptAgreement
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseAcceptAgreement.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseAcceptAgreement
       * @function getTypeUrl
       * @memberof rti.ResponseAcceptAgreement
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseAcceptAgreement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseAcceptAgreement';
      };

      return ResponseAcceptAgreement;
    })();

    rti.RequestSetRithmicMrktDataSelfCertStatus = (function () {
      /**
       * Properties of a RequestSetRithmicMrktDataSelfCertStatus.
       * @memberof rti
       * @interface IRequestSetRithmicMrktDataSelfCertStatus
       * @property {number} templateId RequestSetRithmicMrktDataSelfCertStatus templateId
       * @property {Array.<string>|null} [userMsg] RequestSetRithmicMrktDataSelfCertStatus userMsg
       * @property {string|null} [agreementId] RequestSetRithmicMrktDataSelfCertStatus agreementId
       * @property {string|null} [marketDataUsageCapacity] RequestSetRithmicMrktDataSelfCertStatus marketDataUsageCapacity
       */

      /**
       * Constructs a new RequestSetRithmicMrktDataSelfCertStatus.
       * @memberof rti
       * @classdesc Represents a RequestSetRithmicMrktDataSelfCertStatus.
       * @implements IRequestSetRithmicMrktDataSelfCertStatus
       * @constructor
       * @param {rti.IRequestSetRithmicMrktDataSelfCertStatus=} [properties] Properties to set
       */
      function RequestSetRithmicMrktDataSelfCertStatus(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestSetRithmicMrktDataSelfCertStatus templateId.
       * @member {number} templateId
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @instance
       */
      RequestSetRithmicMrktDataSelfCertStatus.prototype.templateId = 0;

      /**
       * RequestSetRithmicMrktDataSelfCertStatus userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @instance
       */
      RequestSetRithmicMrktDataSelfCertStatus.prototype.userMsg =
        $util.emptyArray;

      /**
       * RequestSetRithmicMrktDataSelfCertStatus agreementId.
       * @member {string} agreementId
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @instance
       */
      RequestSetRithmicMrktDataSelfCertStatus.prototype.agreementId = '';

      /**
       * RequestSetRithmicMrktDataSelfCertStatus marketDataUsageCapacity.
       * @member {string} marketDataUsageCapacity
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @instance
       */
      RequestSetRithmicMrktDataSelfCertStatus.prototype.marketDataUsageCapacity =
        '';

      /**
       * Creates a new RequestSetRithmicMrktDataSelfCertStatus instance using the specified properties.
       * @function create
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {rti.IRequestSetRithmicMrktDataSelfCertStatus=} [properties] Properties to set
       * @returns {rti.RequestSetRithmicMrktDataSelfCertStatus} RequestSetRithmicMrktDataSelfCertStatus instance
       */
      RequestSetRithmicMrktDataSelfCertStatus.create = function create(
        properties,
      ) {
        return new RequestSetRithmicMrktDataSelfCertStatus(properties);
      };

      /**
       * Encodes the specified RequestSetRithmicMrktDataSelfCertStatus message. Does not implicitly {@link rti.RequestSetRithmicMrktDataSelfCertStatus.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {rti.IRequestSetRithmicMrktDataSelfCertStatus} message RequestSetRithmicMrktDataSelfCertStatus message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestSetRithmicMrktDataSelfCertStatus.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.agreementId != null &&
          Object.hasOwnProperty.call(message, 'agreementId')
        )
          writer
            .uint32(/* id 153407, wireType 2 =*/ 1227258)
            .string(message.agreementId);
        if (
          message.marketDataUsageCapacity != null &&
          Object.hasOwnProperty.call(message, 'marketDataUsageCapacity')
        )
          writer
            .uint32(/* id 153431, wireType 2 =*/ 1227450)
            .string(message.marketDataUsageCapacity);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestSetRithmicMrktDataSelfCertStatus message, length delimited. Does not implicitly {@link rti.RequestSetRithmicMrktDataSelfCertStatus.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {rti.IRequestSetRithmicMrktDataSelfCertStatus} message RequestSetRithmicMrktDataSelfCertStatus message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestSetRithmicMrktDataSelfCertStatus.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a RequestSetRithmicMrktDataSelfCertStatus message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestSetRithmicMrktDataSelfCertStatus} RequestSetRithmicMrktDataSelfCertStatus
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestSetRithmicMrktDataSelfCertStatus.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestSetRithmicMrktDataSelfCertStatus();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 153407:
              message.agreementId = reader.string();
              break;
            case 153431:
              message.marketDataUsageCapacity = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestSetRithmicMrktDataSelfCertStatus message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestSetRithmicMrktDataSelfCertStatus} RequestSetRithmicMrktDataSelfCertStatus
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestSetRithmicMrktDataSelfCertStatus.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a RequestSetRithmicMrktDataSelfCertStatus message.
       * @function verify
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestSetRithmicMrktDataSelfCertStatus.verify = function verify(
        message,
      ) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          if (!$util.isString(message.agreementId))
            return 'agreementId: string expected';
        if (
          message.marketDataUsageCapacity != null &&
          message.hasOwnProperty('marketDataUsageCapacity')
        )
          if (!$util.isString(message.marketDataUsageCapacity))
            return 'marketDataUsageCapacity: string expected';
        return null;
      };

      /**
       * Creates a RequestSetRithmicMrktDataSelfCertStatus message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestSetRithmicMrktDataSelfCertStatus} RequestSetRithmicMrktDataSelfCertStatus
       */
      RequestSetRithmicMrktDataSelfCertStatus.fromObject = function fromObject(
        object,
      ) {
        if (object instanceof $root.rti.RequestSetRithmicMrktDataSelfCertStatus)
          return object;
        var message = new $root.rti.RequestSetRithmicMrktDataSelfCertStatus();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestSetRithmicMrktDataSelfCertStatus.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.agreementId != null)
          message.agreementId = String(object.agreementId);
        if (object.marketDataUsageCapacity != null)
          message.marketDataUsageCapacity = String(
            object.marketDataUsageCapacity,
          );
        return message;
      };

      /**
       * Creates a plain object from a RequestSetRithmicMrktDataSelfCertStatus message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {rti.RequestSetRithmicMrktDataSelfCertStatus} message RequestSetRithmicMrktDataSelfCertStatus
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestSetRithmicMrktDataSelfCertStatus.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.agreementId = '';
          object.marketDataUsageCapacity = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          object.agreementId = message.agreementId;
        if (
          message.marketDataUsageCapacity != null &&
          message.hasOwnProperty('marketDataUsageCapacity')
        )
          object.marketDataUsageCapacity = message.marketDataUsageCapacity;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestSetRithmicMrktDataSelfCertStatus to JSON.
       * @function toJSON
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestSetRithmicMrktDataSelfCertStatus.prototype.toJSON =
        function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

      /**
       * Gets the default type url for RequestSetRithmicMrktDataSelfCertStatus
       * @function getTypeUrl
       * @memberof rti.RequestSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestSetRithmicMrktDataSelfCertStatus.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestSetRithmicMrktDataSelfCertStatus';
      };

      return RequestSetRithmicMrktDataSelfCertStatus;
    })();

    rti.ResponseSetRithmicMrktDataSelfCertStatus = (function () {
      /**
       * Properties of a ResponseSetRithmicMrktDataSelfCertStatus.
       * @memberof rti
       * @interface IResponseSetRithmicMrktDataSelfCertStatus
       * @property {number} templateId ResponseSetRithmicMrktDataSelfCertStatus templateId
       * @property {Array.<string>|null} [userMsg] ResponseSetRithmicMrktDataSelfCertStatus userMsg
       * @property {Array.<string>|null} [rpCode] ResponseSetRithmicMrktDataSelfCertStatus rpCode
       */

      /**
       * Constructs a new ResponseSetRithmicMrktDataSelfCertStatus.
       * @memberof rti
       * @classdesc Represents a ResponseSetRithmicMrktDataSelfCertStatus.
       * @implements IResponseSetRithmicMrktDataSelfCertStatus
       * @constructor
       * @param {rti.IResponseSetRithmicMrktDataSelfCertStatus=} [properties] Properties to set
       */
      function ResponseSetRithmicMrktDataSelfCertStatus(properties) {
        this.userMsg = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseSetRithmicMrktDataSelfCertStatus templateId.
       * @member {number} templateId
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @instance
       */
      ResponseSetRithmicMrktDataSelfCertStatus.prototype.templateId = 0;

      /**
       * ResponseSetRithmicMrktDataSelfCertStatus userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @instance
       */
      ResponseSetRithmicMrktDataSelfCertStatus.prototype.userMsg =
        $util.emptyArray;

      /**
       * ResponseSetRithmicMrktDataSelfCertStatus rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @instance
       */
      ResponseSetRithmicMrktDataSelfCertStatus.prototype.rpCode =
        $util.emptyArray;

      /**
       * Creates a new ResponseSetRithmicMrktDataSelfCertStatus instance using the specified properties.
       * @function create
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {rti.IResponseSetRithmicMrktDataSelfCertStatus=} [properties] Properties to set
       * @returns {rti.ResponseSetRithmicMrktDataSelfCertStatus} ResponseSetRithmicMrktDataSelfCertStatus instance
       */
      ResponseSetRithmicMrktDataSelfCertStatus.create = function create(
        properties,
      ) {
        return new ResponseSetRithmicMrktDataSelfCertStatus(properties);
      };

      /**
       * Encodes the specified ResponseSetRithmicMrktDataSelfCertStatus message. Does not implicitly {@link rti.ResponseSetRithmicMrktDataSelfCertStatus.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {rti.IResponseSetRithmicMrktDataSelfCertStatus} message ResponseSetRithmicMrktDataSelfCertStatus message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseSetRithmicMrktDataSelfCertStatus.encode = function encode(
        message,
        writer,
      ) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseSetRithmicMrktDataSelfCertStatus message, length delimited. Does not implicitly {@link rti.ResponseSetRithmicMrktDataSelfCertStatus.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {rti.IResponseSetRithmicMrktDataSelfCertStatus} message ResponseSetRithmicMrktDataSelfCertStatus message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseSetRithmicMrktDataSelfCertStatus.encodeDelimited =
        function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

      /**
       * Decodes a ResponseSetRithmicMrktDataSelfCertStatus message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseSetRithmicMrktDataSelfCertStatus} ResponseSetRithmicMrktDataSelfCertStatus
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseSetRithmicMrktDataSelfCertStatus.decode = function decode(
        reader,
        length,
      ) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseSetRithmicMrktDataSelfCertStatus();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseSetRithmicMrktDataSelfCertStatus message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseSetRithmicMrktDataSelfCertStatus} ResponseSetRithmicMrktDataSelfCertStatus
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseSetRithmicMrktDataSelfCertStatus.decodeDelimited =
        function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

      /**
       * Verifies a ResponseSetRithmicMrktDataSelfCertStatus message.
       * @function verify
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseSetRithmicMrktDataSelfCertStatus.verify = function verify(
        message,
      ) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        return null;
      };

      /**
       * Creates a ResponseSetRithmicMrktDataSelfCertStatus message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseSetRithmicMrktDataSelfCertStatus} ResponseSetRithmicMrktDataSelfCertStatus
       */
      ResponseSetRithmicMrktDataSelfCertStatus.fromObject = function fromObject(
        object,
      ) {
        if (
          object instanceof $root.rti.ResponseSetRithmicMrktDataSelfCertStatus
        )
          return object;
        var message = new $root.rti.ResponseSetRithmicMrktDataSelfCertStatus();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseSetRithmicMrktDataSelfCertStatus.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseSetRithmicMrktDataSelfCertStatus.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        return message;
      };

      /**
       * Creates a plain object from a ResponseSetRithmicMrktDataSelfCertStatus message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {rti.ResponseSetRithmicMrktDataSelfCertStatus} message ResponseSetRithmicMrktDataSelfCertStatus
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseSetRithmicMrktDataSelfCertStatus.toObject = function toObject(
        message,
        options,
      ) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rpCode = [];
        }
        if (options.defaults) object.templateId = 0;
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseSetRithmicMrktDataSelfCertStatus to JSON.
       * @function toJSON
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseSetRithmicMrktDataSelfCertStatus.prototype.toJSON =
        function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

      /**
       * Gets the default type url for ResponseSetRithmicMrktDataSelfCertStatus
       * @function getTypeUrl
       * @memberof rti.ResponseSetRithmicMrktDataSelfCertStatus
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseSetRithmicMrktDataSelfCertStatus.getTypeUrl = function getTypeUrl(
        typeUrlPrefix,
      ) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseSetRithmicMrktDataSelfCertStatus';
      };

      return ResponseSetRithmicMrktDataSelfCertStatus;
    })();

    rti.RequestShowAgreement = (function () {
      /**
       * Properties of a RequestShowAgreement.
       * @memberof rti
       * @interface IRequestShowAgreement
       * @property {number} templateId RequestShowAgreement templateId
       * @property {Array.<string>|null} [userMsg] RequestShowAgreement userMsg
       * @property {string|null} [agreementId] RequestShowAgreement agreementId
       */

      /**
       * Constructs a new RequestShowAgreement.
       * @memberof rti
       * @classdesc Represents a RequestShowAgreement.
       * @implements IRequestShowAgreement
       * @constructor
       * @param {rti.IRequestShowAgreement=} [properties] Properties to set
       */
      function RequestShowAgreement(properties) {
        this.userMsg = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * RequestShowAgreement templateId.
       * @member {number} templateId
       * @memberof rti.RequestShowAgreement
       * @instance
       */
      RequestShowAgreement.prototype.templateId = 0;

      /**
       * RequestShowAgreement userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.RequestShowAgreement
       * @instance
       */
      RequestShowAgreement.prototype.userMsg = $util.emptyArray;

      /**
       * RequestShowAgreement agreementId.
       * @member {string} agreementId
       * @memberof rti.RequestShowAgreement
       * @instance
       */
      RequestShowAgreement.prototype.agreementId = '';

      /**
       * Creates a new RequestShowAgreement instance using the specified properties.
       * @function create
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {rti.IRequestShowAgreement=} [properties] Properties to set
       * @returns {rti.RequestShowAgreement} RequestShowAgreement instance
       */
      RequestShowAgreement.create = function create(properties) {
        return new RequestShowAgreement(properties);
      };

      /**
       * Encodes the specified RequestShowAgreement message. Does not implicitly {@link rti.RequestShowAgreement.verify|verify} messages.
       * @function encode
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {rti.IRequestShowAgreement} message RequestShowAgreement message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowAgreement.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (
          message.agreementId != null &&
          Object.hasOwnProperty.call(message, 'agreementId')
        )
          writer
            .uint32(/* id 153407, wireType 2 =*/ 1227258)
            .string(message.agreementId);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified RequestShowAgreement message, length delimited. Does not implicitly {@link rti.RequestShowAgreement.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {rti.IRequestShowAgreement} message RequestShowAgreement message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      RequestShowAgreement.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a RequestShowAgreement message from the specified reader or buffer.
       * @function decode
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.RequestShowAgreement} RequestShowAgreement
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowAgreement.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.RequestShowAgreement();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 153407:
              message.agreementId = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a RequestShowAgreement message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.RequestShowAgreement} RequestShowAgreement
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      RequestShowAgreement.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a RequestShowAgreement message.
       * @function verify
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      RequestShowAgreement.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          if (!$util.isString(message.agreementId))
            return 'agreementId: string expected';
        return null;
      };

      /**
       * Creates a RequestShowAgreement message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.RequestShowAgreement} RequestShowAgreement
       */
      RequestShowAgreement.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.RequestShowAgreement) return object;
        var message = new $root.rti.RequestShowAgreement();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.RequestShowAgreement.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.agreementId != null)
          message.agreementId = String(object.agreementId);
        return message;
      };

      /**
       * Creates a plain object from a RequestShowAgreement message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {rti.RequestShowAgreement} message RequestShowAgreement
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      RequestShowAgreement.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.userMsg = [];
        if (options.defaults) {
          object.agreementId = '';
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          object.agreementId = message.agreementId;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this RequestShowAgreement to JSON.
       * @function toJSON
       * @memberof rti.RequestShowAgreement
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      RequestShowAgreement.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for RequestShowAgreement
       * @function getTypeUrl
       * @memberof rti.RequestShowAgreement
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      RequestShowAgreement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.RequestShowAgreement';
      };

      return RequestShowAgreement;
    })();

    rti.ResponseShowAgreement = (function () {
      /**
       * Properties of a ResponseShowAgreement.
       * @memberof rti
       * @interface IResponseShowAgreement
       * @property {number} templateId ResponseShowAgreement templateId
       * @property {Array.<string>|null} [userMsg] ResponseShowAgreement userMsg
       * @property {Array.<string>|null} [rqHandlerRpCode] ResponseShowAgreement rqHandlerRpCode
       * @property {Array.<string>|null} [rpCode] ResponseShowAgreement rpCode
       * @property {string|null} [agreementTitle] ResponseShowAgreement agreementTitle
       * @property {string|null} [agreementId] ResponseShowAgreement agreementId
       * @property {Uint8Array|null} [agreement] ResponseShowAgreement agreement
       * @property {Uint8Array|null} [agreementHtml] ResponseShowAgreement agreementHtml
       * @property {string|null} [agreementMandatoryFlag] ResponseShowAgreement agreementMandatoryFlag
       * @property {string|null} [agreementStatus] ResponseShowAgreement agreementStatus
       * @property {string|null} [agreementAcceptanceRequest] ResponseShowAgreement agreementAcceptanceRequest
       */

      /**
       * Constructs a new ResponseShowAgreement.
       * @memberof rti
       * @classdesc Represents a ResponseShowAgreement.
       * @implements IResponseShowAgreement
       * @constructor
       * @param {rti.IResponseShowAgreement=} [properties] Properties to set
       */
      function ResponseShowAgreement(properties) {
        this.userMsg = [];
        this.rqHandlerRpCode = [];
        this.rpCode = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ResponseShowAgreement templateId.
       * @member {number} templateId
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.templateId = 0;

      /**
       * ResponseShowAgreement userMsg.
       * @member {Array.<string>} userMsg
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.userMsg = $util.emptyArray;

      /**
       * ResponseShowAgreement rqHandlerRpCode.
       * @member {Array.<string>} rqHandlerRpCode
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.rqHandlerRpCode = $util.emptyArray;

      /**
       * ResponseShowAgreement rpCode.
       * @member {Array.<string>} rpCode
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.rpCode = $util.emptyArray;

      /**
       * ResponseShowAgreement agreementTitle.
       * @member {string} agreementTitle
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.agreementTitle = '';

      /**
       * ResponseShowAgreement agreementId.
       * @member {string} agreementId
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.agreementId = '';

      /**
       * ResponseShowAgreement agreement.
       * @member {Uint8Array} agreement
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.agreement = $util.newBuffer([]);

      /**
       * ResponseShowAgreement agreementHtml.
       * @member {Uint8Array} agreementHtml
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.agreementHtml = $util.newBuffer([]);

      /**
       * ResponseShowAgreement agreementMandatoryFlag.
       * @member {string} agreementMandatoryFlag
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.agreementMandatoryFlag = '';

      /**
       * ResponseShowAgreement agreementStatus.
       * @member {string} agreementStatus
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.agreementStatus = '';

      /**
       * ResponseShowAgreement agreementAcceptanceRequest.
       * @member {string} agreementAcceptanceRequest
       * @memberof rti.ResponseShowAgreement
       * @instance
       */
      ResponseShowAgreement.prototype.agreementAcceptanceRequest = '';

      /**
       * Creates a new ResponseShowAgreement instance using the specified properties.
       * @function create
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {rti.IResponseShowAgreement=} [properties] Properties to set
       * @returns {rti.ResponseShowAgreement} ResponseShowAgreement instance
       */
      ResponseShowAgreement.create = function create(properties) {
        return new ResponseShowAgreement(properties);
      };

      /**
       * Encodes the specified ResponseShowAgreement message. Does not implicitly {@link rti.ResponseShowAgreement.verify|verify} messages.
       * @function encode
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {rti.IResponseShowAgreement} message ResponseShowAgreement message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowAgreement.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.userMsg != null && message.userMsg.length)
          for (var i = 0; i < message.userMsg.length; ++i)
            writer
              .uint32(/* id 132760, wireType 2 =*/ 1062082)
              .string(message.userMsg[i]);
        if (message.rqHandlerRpCode != null && message.rqHandlerRpCode.length)
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            writer
              .uint32(/* id 132764, wireType 2 =*/ 1062114)
              .string(message.rqHandlerRpCode[i]);
        if (message.rpCode != null && message.rpCode.length)
          for (var i = 0; i < message.rpCode.length; ++i)
            writer
              .uint32(/* id 132766, wireType 2 =*/ 1062130)
              .string(message.rpCode[i]);
        if (
          message.agreement != null &&
          Object.hasOwnProperty.call(message, 'agreement')
        )
          writer
            .uint32(/* id 153405, wireType 2 =*/ 1227242)
            .bytes(message.agreement);
        if (
          message.agreementTitle != null &&
          Object.hasOwnProperty.call(message, 'agreementTitle')
        )
          writer
            .uint32(/* id 153406, wireType 2 =*/ 1227250)
            .string(message.agreementTitle);
        if (
          message.agreementId != null &&
          Object.hasOwnProperty.call(message, 'agreementId')
        )
          writer
            .uint32(/* id 153407, wireType 2 =*/ 1227258)
            .string(message.agreementId);
        if (
          message.agreementMandatoryFlag != null &&
          Object.hasOwnProperty.call(message, 'agreementMandatoryFlag')
        )
          writer
            .uint32(/* id 153410, wireType 2 =*/ 1227282)
            .string(message.agreementMandatoryFlag);
        if (
          message.agreementStatus != null &&
          Object.hasOwnProperty.call(message, 'agreementStatus')
        )
          writer
            .uint32(/* id 153415, wireType 2 =*/ 1227322)
            .string(message.agreementStatus);
        if (
          message.agreementAcceptanceRequest != null &&
          Object.hasOwnProperty.call(message, 'agreementAcceptanceRequest')
        )
          writer
            .uint32(/* id 153430, wireType 2 =*/ 1227442)
            .string(message.agreementAcceptanceRequest);
        if (
          message.agreementHtml != null &&
          Object.hasOwnProperty.call(message, 'agreementHtml')
        )
          writer
            .uint32(/* id 153432, wireType 2 =*/ 1227458)
            .bytes(message.agreementHtml);
        writer
          .uint32(/* id 154467, wireType 0 =*/ 1235736)
          .int32(message.templateId);
        return writer;
      };

      /**
       * Encodes the specified ResponseShowAgreement message, length delimited. Does not implicitly {@link rti.ResponseShowAgreement.verify|verify} messages.
       * @function encodeDelimited
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {rti.IResponseShowAgreement} message ResponseShowAgreement message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ResponseShowAgreement.encodeDelimited = function encodeDelimited(
        message,
        writer,
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ResponseShowAgreement message from the specified reader or buffer.
       * @function decode
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {rti.ResponseShowAgreement} ResponseShowAgreement
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowAgreement.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.rti.ResponseShowAgreement();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 154467:
              message.templateId = reader.int32();
              break;
            case 132760:
              if (!(message.userMsg && message.userMsg.length))
                message.userMsg = [];
              message.userMsg.push(reader.string());
              break;
            case 132764:
              if (!(message.rqHandlerRpCode && message.rqHandlerRpCode.length))
                message.rqHandlerRpCode = [];
              message.rqHandlerRpCode.push(reader.string());
              break;
            case 132766:
              if (!(message.rpCode && message.rpCode.length))
                message.rpCode = [];
              message.rpCode.push(reader.string());
              break;
            case 153406:
              message.agreementTitle = reader.string();
              break;
            case 153407:
              message.agreementId = reader.string();
              break;
            case 153405:
              message.agreement = reader.bytes();
              break;
            case 153432:
              message.agreementHtml = reader.bytes();
              break;
            case 153410:
              message.agreementMandatoryFlag = reader.string();
              break;
            case 153415:
              message.agreementStatus = reader.string();
              break;
            case 153430:
              message.agreementAcceptanceRequest = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        if (!message.hasOwnProperty('templateId'))
          throw $util.ProtocolError("missing required 'templateId'", {
            instance: message,
          });
        return message;
      };

      /**
       * Decodes a ResponseShowAgreement message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {rti.ResponseShowAgreement} ResponseShowAgreement
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ResponseShowAgreement.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ResponseShowAgreement message.
       * @function verify
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ResponseShowAgreement.verify = function verify(message) {
        if (typeof message !== 'object' || message === null)
          return 'object expected';
        if (!$util.isInteger(message.templateId))
          return 'templateId: integer expected';
        if (message.userMsg != null && message.hasOwnProperty('userMsg')) {
          if (!Array.isArray(message.userMsg)) return 'userMsg: array expected';
          for (var i = 0; i < message.userMsg.length; ++i)
            if (!$util.isString(message.userMsg[i]))
              return 'userMsg: string[] expected';
        }
        if (
          message.rqHandlerRpCode != null &&
          message.hasOwnProperty('rqHandlerRpCode')
        ) {
          if (!Array.isArray(message.rqHandlerRpCode))
            return 'rqHandlerRpCode: array expected';
          for (var i = 0; i < message.rqHandlerRpCode.length; ++i)
            if (!$util.isString(message.rqHandlerRpCode[i]))
              return 'rqHandlerRpCode: string[] expected';
        }
        if (message.rpCode != null && message.hasOwnProperty('rpCode')) {
          if (!Array.isArray(message.rpCode)) return 'rpCode: array expected';
          for (var i = 0; i < message.rpCode.length; ++i)
            if (!$util.isString(message.rpCode[i]))
              return 'rpCode: string[] expected';
        }
        if (
          message.agreementTitle != null &&
          message.hasOwnProperty('agreementTitle')
        )
          if (!$util.isString(message.agreementTitle))
            return 'agreementTitle: string expected';
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          if (!$util.isString(message.agreementId))
            return 'agreementId: string expected';
        if (message.agreement != null && message.hasOwnProperty('agreement'))
          if (
            !(
              (message.agreement &&
                typeof message.agreement.length === 'number') ||
              $util.isString(message.agreement)
            )
          )
            return 'agreement: buffer expected';
        if (
          message.agreementHtml != null &&
          message.hasOwnProperty('agreementHtml')
        )
          if (
            !(
              (message.agreementHtml &&
                typeof message.agreementHtml.length === 'number') ||
              $util.isString(message.agreementHtml)
            )
          )
            return 'agreementHtml: buffer expected';
        if (
          message.agreementMandatoryFlag != null &&
          message.hasOwnProperty('agreementMandatoryFlag')
        )
          if (!$util.isString(message.agreementMandatoryFlag))
            return 'agreementMandatoryFlag: string expected';
        if (
          message.agreementStatus != null &&
          message.hasOwnProperty('agreementStatus')
        )
          if (!$util.isString(message.agreementStatus))
            return 'agreementStatus: string expected';
        if (
          message.agreementAcceptanceRequest != null &&
          message.hasOwnProperty('agreementAcceptanceRequest')
        )
          if (!$util.isString(message.agreementAcceptanceRequest))
            return 'agreementAcceptanceRequest: string expected';
        return null;
      };

      /**
       * Creates a ResponseShowAgreement message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {rti.ResponseShowAgreement} ResponseShowAgreement
       */
      ResponseShowAgreement.fromObject = function fromObject(object) {
        if (object instanceof $root.rti.ResponseShowAgreement) return object;
        var message = new $root.rti.ResponseShowAgreement();
        if (object.templateId != null)
          message.templateId = object.templateId | 0;
        if (object.userMsg) {
          if (!Array.isArray(object.userMsg))
            throw TypeError(
              '.rti.ResponseShowAgreement.userMsg: array expected',
            );
          message.userMsg = [];
          for (var i = 0; i < object.userMsg.length; ++i)
            message.userMsg[i] = String(object.userMsg[i]);
        }
        if (object.rqHandlerRpCode) {
          if (!Array.isArray(object.rqHandlerRpCode))
            throw TypeError(
              '.rti.ResponseShowAgreement.rqHandlerRpCode: array expected',
            );
          message.rqHandlerRpCode = [];
          for (var i = 0; i < object.rqHandlerRpCode.length; ++i)
            message.rqHandlerRpCode[i] = String(object.rqHandlerRpCode[i]);
        }
        if (object.rpCode) {
          if (!Array.isArray(object.rpCode))
            throw TypeError(
              '.rti.ResponseShowAgreement.rpCode: array expected',
            );
          message.rpCode = [];
          for (var i = 0; i < object.rpCode.length; ++i)
            message.rpCode[i] = String(object.rpCode[i]);
        }
        if (object.agreementTitle != null)
          message.agreementTitle = String(object.agreementTitle);
        if (object.agreementId != null)
          message.agreementId = String(object.agreementId);
        if (object.agreement != null)
          if (typeof object.agreement === 'string')
            $util.base64.decode(
              object.agreement,
              (message.agreement = $util.newBuffer(
                $util.base64.length(object.agreement),
              )),
              0,
            );
          else if (object.agreement.length)
            message.agreement = object.agreement;
        if (object.agreementHtml != null)
          if (typeof object.agreementHtml === 'string')
            $util.base64.decode(
              object.agreementHtml,
              (message.agreementHtml = $util.newBuffer(
                $util.base64.length(object.agreementHtml),
              )),
              0,
            );
          else if (object.agreementHtml.length)
            message.agreementHtml = object.agreementHtml;
        if (object.agreementMandatoryFlag != null)
          message.agreementMandatoryFlag = String(
            object.agreementMandatoryFlag,
          );
        if (object.agreementStatus != null)
          message.agreementStatus = String(object.agreementStatus);
        if (object.agreementAcceptanceRequest != null)
          message.agreementAcceptanceRequest = String(
            object.agreementAcceptanceRequest,
          );
        return message;
      };

      /**
       * Creates a plain object from a ResponseShowAgreement message. Also converts values to other types if specified.
       * @function toObject
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {rti.ResponseShowAgreement} message ResponseShowAgreement
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ResponseShowAgreement.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) {
          object.userMsg = [];
          object.rqHandlerRpCode = [];
          object.rpCode = [];
        }
        if (options.defaults) {
          if (options.bytes === String) object.agreement = '';
          else {
            object.agreement = [];
            if (options.bytes !== Array)
              object.agreement = $util.newBuffer(object.agreement);
          }
          object.agreementTitle = '';
          object.agreementId = '';
          object.agreementMandatoryFlag = '';
          object.agreementStatus = '';
          object.agreementAcceptanceRequest = '';
          if (options.bytes === String) object.agreementHtml = '';
          else {
            object.agreementHtml = [];
            if (options.bytes !== Array)
              object.agreementHtml = $util.newBuffer(object.agreementHtml);
          }
          object.templateId = 0;
        }
        if (message.userMsg && message.userMsg.length) {
          object.userMsg = [];
          for (var j = 0; j < message.userMsg.length; ++j)
            object.userMsg[j] = message.userMsg[j];
        }
        if (message.rqHandlerRpCode && message.rqHandlerRpCode.length) {
          object.rqHandlerRpCode = [];
          for (var j = 0; j < message.rqHandlerRpCode.length; ++j)
            object.rqHandlerRpCode[j] = message.rqHandlerRpCode[j];
        }
        if (message.rpCode && message.rpCode.length) {
          object.rpCode = [];
          for (var j = 0; j < message.rpCode.length; ++j)
            object.rpCode[j] = message.rpCode[j];
        }
        if (message.agreement != null && message.hasOwnProperty('agreement'))
          object.agreement =
            options.bytes === String
              ? $util.base64.encode(
                  message.agreement,
                  0,
                  message.agreement.length,
                )
              : options.bytes === Array
                ? Array.prototype.slice.call(message.agreement)
                : message.agreement;
        if (
          message.agreementTitle != null &&
          message.hasOwnProperty('agreementTitle')
        )
          object.agreementTitle = message.agreementTitle;
        if (
          message.agreementId != null &&
          message.hasOwnProperty('agreementId')
        )
          object.agreementId = message.agreementId;
        if (
          message.agreementMandatoryFlag != null &&
          message.hasOwnProperty('agreementMandatoryFlag')
        )
          object.agreementMandatoryFlag = message.agreementMandatoryFlag;
        if (
          message.agreementStatus != null &&
          message.hasOwnProperty('agreementStatus')
        )
          object.agreementStatus = message.agreementStatus;
        if (
          message.agreementAcceptanceRequest != null &&
          message.hasOwnProperty('agreementAcceptanceRequest')
        )
          object.agreementAcceptanceRequest =
            message.agreementAcceptanceRequest;
        if (
          message.agreementHtml != null &&
          message.hasOwnProperty('agreementHtml')
        )
          object.agreementHtml =
            options.bytes === String
              ? $util.base64.encode(
                  message.agreementHtml,
                  0,
                  message.agreementHtml.length,
                )
              : options.bytes === Array
                ? Array.prototype.slice.call(message.agreementHtml)
                : message.agreementHtml;
        if (message.templateId != null && message.hasOwnProperty('templateId'))
          object.templateId = message.templateId;
        return object;
      };

      /**
       * Converts this ResponseShowAgreement to JSON.
       * @function toJSON
       * @memberof rti.ResponseShowAgreement
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ResponseShowAgreement.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ResponseShowAgreement
       * @function getTypeUrl
       * @memberof rti.ResponseShowAgreement
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ResponseShowAgreement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/rti.ResponseShowAgreement';
      };

      return ResponseShowAgreement;
    })();

    return rti;
  })();

  return $root;
});
