<div class="market-subs-stats-container">
  <h2>Internal Subscribers Statistics <small>by Data Level and Feed Type</small>
    <button nz-button nzType="primary" [nzSize]="'small'" (click)="refreshStats()">
      Refresh
    </button>
  </h2>


  <div class="stats-container">
    <p *ngIf="!subscriberStats.length"><small>(Click refresh to retrieve or refresh the stats)</small></p>
    <div *ngFor="let connection of subscriberStats" class="connection-stats">
      <div class="connection-name">
        <span class="connection-name-text" [title]="connection.connectionName">{{ connection.connectionName }}</span>
      </div>
      <div *ngFor="let instrumentId of getInstruments(connection)" class="instrument-stats">
        <div class="instrument-id">{{ instrumentId }}</div>
        <div *ngFor="let dataLevel of getDataLevels(connection, instrumentId)" class="data-level-stats">
          <div class="data-level">{{ dataLevel }}</div>
          <div *ngFor="let feedType of getFeedTypes(connection, instrumentId, dataLevel)" class="feed-type-stats">
            <span class="feed-type">{{ feedType }}</span>
            <span class="subscribers">{{ connection.subscribers[instrumentId][dataLevel][feedType] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

