import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Id, IPaginationResponse } from 'communication';
import {
  IDeletePositionsParams,
  IPosition,
  PositionsRepository,
  PositionStatus,
} from 'trading';

import { BaseRepository } from './base-repository';

@Injectable()
export class RealPositionsRepository
  extends BaseRepository<IPosition>
  implements PositionsRepository
{
  protected get suffix(): string {
    return 'Position';
  }
  static transformPosition(item, connectionId?): IPosition {
    if (isPositionTransformed(item)) return item;

    const { averageFillPrice: price, volume: size, instrument } = item;

    return {
      id: `${instrument?.id}.${item.account?.id}`,
      instrument,
      accountId: item.account?.id,
      price,
      size,
      sellVolume: item.sellVolume,
      buyVolume: item.buyVolume,
      realized: item.realisedPL,
      unrealized: 0,
      total: size * price,
      side: item.type,
      connectionId: connectionId ?? item.connectionId,
      status: PositionStatus.Open,
    };
  }

  getItems(params: any = {}) {
    const _params = { ...params };

    if (_params.accountId) {
      _params.id = _params.accountId;
      // delete _params.accountId;
      // needed to exit eternal recursion
    } else if (
      _params.hasOwnProperty('connectionId') &&
      !_params.hasOwnProperty('accounts')
    ) {
      _params.accounts = this._connectionContainer.getAccountsByConnection(
        params.connectionId,
      );
    }

    return super.getItems(_params).pipe(
      map((res: any) => {
        const data = res.data
          .filter((item: any) => this._filter(item, _params))
          .map((item: any) => {
            return RealPositionsRepository.transformPosition(item);
          });

        return { data } as IPaginationResponse<IPosition>;
      }),
    );
  }

  /**
   * @deprecated Deprecated. Please use RProtocolConnectionWebSocketService.requestExitPosition instead.
   * @see RProtocolConnectionWebSocketService.requestExitPosition
   */
  deleteItem(item: IPosition | Id): Observable<any> {
    console.warn(
      'Deprecated. Please use RProtocolConnectionWebSocketService.requestExitPosition instead.',
    );

    if (typeof item !== 'object') throw new Error('Invalid position');

    return this._http.post<IPosition>(this._getRESTURL(item.accountId), null, {
      ...this.getApiHeadersByAccount(item.accountId),
      params: {
        Symbol: item.instrument.symbol,
        Exchange: item.instrument.exchange,
      },
    });
  }
  /**
   * @deprecated Deprecated. Please use RProtocolConnectionWebSocketService.requestExitPosition instead.
   * @see RProtocolConnectionWebSocketService.requestExitPosition
   */
  deleteMany({
    accountId,
    ...params
  }: IDeletePositionsParams | any): Observable<any> {
    console.warn(
      'Deprecated. Please use RProtocolConnectionWebSocketService.requestExitPosition instead.',
    );

    return this._http.post(this._getRESTURL(accountId), null, {
      ...this.getApiHeadersByAccount(accountId),
      params,
    });
  }

  protected _filter(item: IPosition, params: any = {}) {
    const { instrument } = params;

    const symbol =
      item.instrument.tradingSymbol == null
        ? item.instrument.symbol
        : item.instrument.symbol;

    if (instrument) {
      return instrument.symbol === symbol;
    }

    return true;
  }
}

function isPositionTransformed(
  position: IPosition | any,
): position is IPosition {
  return (
    (position as IPosition).accountId !== undefined && position.id !== undefined
  );
}
