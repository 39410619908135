import {html} from './html'
import {Window} from './Window'
import {windowOptions} from './windowOptions'

export {Window} from './Window'

const windowManagerOptions = {
    quiet: false,
    keepInside: true,
}

/**
 * Creates a windowing system to create and manage windows
 *
 * @extends EventEmitter
 * @example
 * var wm = new WindowManager();
 *
 * wm.createWindow({ x: 20, y: 20, width: 200 })
 * wm.content.innerHTML = 'Hello there!'
 */
export class WindowManager {

    /** @type {Bounds} */
    get bounds() {
        if (this._customBounds) {
            return this._customBounds;
        }

        if (!this._cachedBounds) {
            const top = this.container.offsetTop || 0;
            const left = this.container.offsetLeft || 0;

            this._cachedBounds = {
                top,
                left,
                bottom: top + this.container.offsetHeight,
                right: left + this.container.offsetWidth,
            }
        }

        return this._cachedBounds;
    }

    get activeWindow() {
        // return this.windows[this.windows.length - 1];
        return this.windows.find(i => i.active) || this.windows[this.windows.length - 1];
    }

    /**
     * @param {object} [options]
     * @param {HTMLElement} [options.parent=document.body]
     * @param {boolean} [options.quiet] suppress the simple-window-manager console message
     * @param {(boolean|'horizontal'|'vertical')} [options.keepInside=true] keep windows inside the parent in a certain direction
     * @param {WindowOptions} [defaultOptions] default WindowOptions used when createWindow is called
     */
    constructor(options = {}, defaultOptions = {}) {
        this.windows = [];
        this._cachedBounds = null;
        this._customBounds = null;
        this.options = Object.assign({}, windowManagerOptions, options)
        this.defaultOptions = Object.assign({}, windowOptions, defaultOptions)

        if (!this.options.quiet) {
            console.log('%c ☕ simple-window-manager initialized ☕', 'color: #ff00ff')
        }

        this._createDom(options.parent || this._createDefaultParent());
        window.addEventListener('resize', () => this.resize())
    }

    _createDefaultParent() {
        const parent = document.createElement('div');

        parent.style.width = '100%';
        parent.style.height = '100%';
        parent.style.position = 'relative';

        document.body.appendChild(parent);

        return parent;
    }

    /**
     * Create a window
     * @param {WindowOptions} [options]
     * @param {string} [options.title]
     * @param {number} [options.x] position
     * @param {number} [options.y] position
     * @param {(number|*)} [options.id] if not provide, id will be assigned in order of creation (0, 1, 2...)
     * @returns {Window} the created window
     */
    createWindow(options = {}) {
        options.globalOffset = this.globalOffset;

        const win = new Window(this, Object.assign({}, this.defaultOptions, options));

        const prevActive = this.windows[this.windows.length - 1];
        if (prevActive)
            prevActive.blur();

        this.windows.push(win);
        win.focus(false);
        win.resizePlacement(this.bounds, this.options.keepInside)
        this._reorder();

        this.setContainerMinSize();

        return win;
    }

    /**
     * save the state of all the windows
     * @returns {object} use this object in load() to restore the state of all windows
     */
    save() {
        const state = []
        const windows = this.windows.filter(item => item.visible);
        for (let i = 0; i < windows.length; i++) {
            const entry = windows[i];
            state[i] = entry.save();
            state[i].order = i;
        }

        return state;
    }

    /**
     * restores the state of all the windows
     * NOTE: this requires that the windows have the same id as when save() was called
     * @param {object} data created by save()
     */
    load(data) {
        for (let item of data) {
            let entry = this.windows.find(win => win.id === item.id);

            if (!entry) {
                entry = this.createWindow(item)
            }

            entry.load(item);
        }

        return this.windows
    }

    /**
     * close all windows
     */
    closeAll() {
        for (let win of this.windows) {
            win.close()
        }
        this.windows = []
    }

    /**
     * hide all windows
     */
    hideAll() {
        for (let win of this.windows) {
            win.visible = false;
        }
    }

    /**
     * update global offset for all windows
     */
    updateGlobalOffset() {
        this.globalOffset = getGlobalOffset(this.container);
        this.windows.forEach(window => window.globalOffset = this.globalOffset);
    }

    /**
     * update windows placement bounds
     * if set null bounds will be calculated automatically
     * @param {object} [bounds]
     * @param {number} [bounds.top] position
     * @param {number} [bounds.bottom] position
     * @param {number} [bounds.left] position
     * @param {number} [bounds.right] position
     */
    setCustomBounds(bounds) {
        this._customBounds = bounds;
        this.resize();
    }

    /**
     * reorder windows
     * @private
     * @returns {number} available z-index for top window
     */
    _reorder() {
        let i = 0;
        for (const win of this.windows) {
                win.z = ++i;
        }
    }

    /**
     * @param {HTMLElement} parent
     */
    _createDom(parent) {
        /**
         * This is the top-level DOM element
         * @type {HTMLElement}
         * @readonly
         */

        this.container = html({
            parent,
            className: 'wm-container',
            styles: {
                'user-select': 'none',
                'width': '100%',
                'height': '100%',
                'position': 'relative',
                'cursor': 'default'
            }
        })

        this.globalOffset = getGlobalOffset(this.container);

        this.container.addEventListener('pointermove', (e) => this._move(e));
        this.container.addEventListener('pointerup', (e) => this._up(e));
        this.container.addEventListener('pointerleave', (e) => this._up(e));
        this.container.addEventListener('pointerdown', (e) => this._down(e));
    }

    _close(win) {
        const index = this.windows.indexOf(win)
        console.assert(index !== -1, 'WindowManager._close should find window in this.windows')

        if (!~index)
            return;

        this.windows[index]._container.remove();
        this.windows.splice(index, 1)

        const next = this.windows[this.windows.length - 1]
        if (next)
            next.focus()
        this.setContainerMinSize();
    }

    _down(e) {
        const point = getPoint(e);
        const windows = this._getSortedWindow();
        for (let i = windows.length - 1; i >= 0; i--) {
            const window = windows[i];

            if (window.handleMousedown(e, point) || window.hitTest(point)) {

                if (this.activeWindow === window && window.active)
                    break;

                if (this.activeWindow) {
                    this.activeWindow.blur();
                }

                window.focus();
                break;
            }
        }
    }

    _move(e) {
        const isGrapped = isTouchEvent(e) || e.which === 1;

        const rect = this.activeWindow && this.activeWindow.getRect();

        if (!rect)
            return;

        const rBottom = rect.y + rect.height;
        const rRight = rect.x + rect.width;
        const offset = this.options.snapOffset || 12;

        let left = null;
        let right = null;
        let top = null;
        let bottom = null;

        let leftOffset = offset;
        let rightOffset = offset;
        let topOffset = offset;
        let bottomOffset = offset;
        const point = getPoint(e);
        const windows = this._getSortedWindow();
        // -2 because last is active panel
        for (let i = windows.length - 2; i >= 0; i--) {
            const win = windows[i];
            win.handleMovePoint(point);

            if (!isGrapped)
                continue;

            const winRect = win.getRect();
            const wBottom = winRect.y + winRect.height;
            const wRight = winRect.x + winRect.width;

            let _leftOffset = Math.abs(rect.x - wRight);
            if (_leftOffset < leftOffset) {
                leftOffset = _leftOffset
                left = wRight;
            }

            _leftOffset = Math.abs(rect.x - winRect.x);
            if (_leftOffset < leftOffset) {
                leftOffset = _leftOffset
                left = winRect.x;
            }

            let _rightOffset = Math.abs(rRight - winRect.x);
            if (_rightOffset < rightOffset) {
                rightOffset = _rightOffset;
                right = winRect.x;
            }

            _rightOffset = Math.abs(rRight - wRight);
            if (_rightOffset < rightOffset) {
                rightOffset = _rightOffset;
                right = wRight;
            }

            let _topOffset = Math.abs(rect.y - wBottom);
            if (_topOffset < topOffset) {
                topOffset = _topOffset;
                top = wBottom;
            }

            _topOffset = Math.abs(rect.y - winRect.y);
            if (_topOffset < topOffset) {
                topOffset = _topOffset;
                top = winRect.y;
            }

            let _bottomOffset = Math.abs(rBottom - winRect.y);
            if (_bottomOffset < bottomOffset) {
                bottomOffset = _bottomOffset;
                bottom = winRect.y;
            }

            _bottomOffset = Math.abs(rBottom - wBottom);
            if (_bottomOffset < bottomOffset) {
                bottomOffset = _bottomOffset;
                bottom = wBottom;
            }
        }

        let bindings;

        if (isGrapped && left != null || right != null || top != null || bottom != null)
            bindings = {
                top,
                left,
                right,
                bottom,
            };
        if ((this.activeWindow && this.activeWindow.handleMovePoint(point, bindings)) || isGrapped) {
            e.preventDefault();

            this.setContainerMinSize();
        }
    }

    _up(e) {
        if (!this.activeWindow)
            return;

        this.activeWindow.handleMouseup();
    }

    resize() {
        this._cachedBounds = null;
        const bounds = this.bounds
        for (const key in this.windows) {
            this.windows[key].resizePlacement(bounds, this.options.keepInside)
        }
    }

    setContainerMinSize() {
        const { container } = this;

        let minWidth = 0;
        let minHeight = 0;
        const windows =  this.windows.filter(item => item.visible);
        for (let i = 0; i <windows.length; i++) {
            const win = windows[i];

            minWidth = Math.max(minWidth, win.options.x + win.width);
            minHeight = Math.max(minHeight, win.options.y + win.height);
        }

        container.style.minWidth = `${minWidth}px`;
        container.style.minHeight = `${minHeight}px`;
    }

    handleFocus(win) {
        const index = this.windows.indexOf(win);
        if (index === -1)
            return;

        const prevActive = this.windows[this.windows.length - 1];
        if (win !== prevActive)
            prevActive.blur();

        this.windows.splice(index, 1);
        this.windows.push(win);

        this._reorder()
    }

    handleBlur(win) {
        // if (this.activeWindow != win)
        //     return;
        //
        // const length = this.windows.length;
        // if (length < 2)
        //     return;

        // this.focus(this.windows[length - 2]);
    }

    _getSortedWindow() {
        return this.windows.sort((a, b) => getZIndex(a.z) - getZIndex(b.z));
    }
}

function getZIndex(z) {
    if (isNaN(z) || z == null)
        return 0;

    return z;
}


/**
 * @typedef {object} Bounds
 * @property {number} left
 * @property {number} right
 * @property {number} top
 * @property {number} bottom
 */

var getGlobalOffset = function (element) {
    var top = 0, left = 0;

    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while (element);

    return {
        top: top,
        left: left
    };
};

function isTouchEvent(e) {
    return !!window.TouchEvent && (e instanceof window.TouchEvent)
}

function convertMoveEventIfNeed(e) {
    return isTouchEvent(e) ? e.changedTouches[0] : e
}

function getPoint(event) {
    const _event = convertMoveEventIfNeed(event)
    const element = event.currentTarget.parentElement;

    return {
        x: _event.pageX + element.scrollLeft,
        y: _event.pageY + element.scrollTop,
    }
}
