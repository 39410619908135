declare const jQuery: any;

/**
 * @todo Rename this to something to better reflect the fact that this is merely a wrapper and not the actual indicator itself,
 * and its purpose it's to hold indicator's settings and an instance of the actual indicator
 */
export abstract class Indicator {
  /**
   * @type {any} instance - Instance of a StockChartX indicator
   */
  instance: any;
  name: string;
  config: any;
  private _settings: any;

  get settings(): any {
    return this._settings;
  }

  set settings(value: any) {
    this._settings = value;
  }

  get indicatorSettings(): any {
    return this.instance.settings || this.instance.plots[0].settings;
  }

  set indicatorSettings(settings: any) {
    if (this.instance.settings) {
      this.instance.settings = settings;
    }
    if (this.instance.plots?.length > 0) {
      this.instance.plots.forEach((plot: any) => {
        plot.settings = settings;
      });
    }
  }

  constructor(instance: any) {
    this.instance = instance;
    this.name = instance.constructor.className;

    const _settings = this._mapGetSettings(
      jQuery.extend(true, {}, this.indicatorSettings),
    );

    this.settings = _settings;
  }

  applySettings(settings: any) {
    const _settings = this._mapSetSettings(jQuery.extend(true, {}, settings));

    this.indicatorSettings = _settings;
  }

  protected _mapGetSettings(settings: any): any {
    return settings;
  }

  protected _mapSetSettings(settings: any): any {
    return settings;
  }
}
