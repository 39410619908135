<div class="log-controls">
  <button nz-button nzType="default" size="small" nzShape="circle" class="icon-button" (click)="copyLogsToClipboard()">
    <i nz-icon class="icon-save"></i>
  </button>

  <button nz-button nzType="default" size="small" nzShape="circle" class="icon-button" (click)="eraseLogs()">
    <i nz-icon class="icon-reset"></i>
  </button>
</div>

<ng-container *ngIf="formattedLogEntries$ | async as logEntries">
  <cdk-virtual-scroll-viewport *ngIf="logEntries.length > 0; else noLogs" [itemSize]="itemSize" class="log-container" minBufferPx="1000" maxBufferPx="1500">
    <div *cdkVirtualFor="let entry of logEntries" class="log-entry" #logEntry>
      <span class="timestamp">{{entry.timestamp}}</span>
      <span class="level" [ngClass]="entry.level.toLowerCase()">{{entry.level}}</span>
      <span class="message">{{entry.message}}</span>
    </div>
  </cdk-virtual-scroll-viewport>

  <ng-template #noLogs>
    <div class="no-logs-message">Waiting for logs</div>
  </ng-template>
</ng-container>
