import { NgModule } from '@angular/core';

import { FixedNumberPipe } from './pipes';

@NgModule({
  declarations: [FixedNumberPipe],
  imports: [],
  exports: [FixedNumberPipe],
})
export class BaseComponentsModule {}
