import { Injectable } from '@angular/core';

@Injectable()
export class PerformanceService {
  private _chartUpdatesPrevented: number = 0;
  private _intervalID: number;
  private readonly _PRINT_INFO_INTERVAL: number = 1000;

  constructor() {}

  start(): void {
    this._intervalID = setInterval(
      (): void => this._printPerformancekInfo(),
      this._PRINT_INFO_INTERVAL,
    );
  }

  stop(): void {
    clearInterval(this._intervalID);
    this._intervalID = null;
  }

  private _printPerformancekInfo(): void {
    switch (true) {
      case this._chartUpdatesPrevented !== 0:
        console.log(
          `🛑 Prevented ${this._chartUpdatesPrevented} unnecessary chart update(s) in ${this._PRINT_INFO_INTERVAL / 1000} second(s).`,
        );
    }

    this._chartUpdatesPrevented = 0;
  }

  addChartUpdatePreventionInfo(): void {
    this._chartUpdatesPrevented += 1;
  }
}
