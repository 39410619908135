import { Injectable } from '@angular/core';

import { TradeLogEntry, TradeLogger } from './TradeLogger';

type PendingTask = () => void;

/**
 * PersistentTradeLogger is a TradeLogger implementation that persists trade logs to IndexedDB.
 */
@Injectable({
  providedIn: 'root',
})
export class PersistentTradeLogger extends TradeLogger {
  private readonly DB_NAME: string = 'TradeLogsDB';
  private readonly STORE_NAME: string = 'logs';
  /**
   * Minimum time to wait between saves to IndexedDB, in milliseconds.
   */
  private readonly MIN_SAVE_INTERVAL_MS = 10000; // 10 seconds
  /**
   * Time to wait before forcing a save to IndexedDB, in milliseconds.
   */
  private readonly FORCE_SAVE_TIMEOUT_MS = 30000; // 30 seconds
  private db: IDBDatabase | null = null;
  private pendingTasks: PendingTask[] = [];
  private saveLogsTimeout: number | null = null;
  private lastSaveTime: number = 0;
  private forceSaveTimeout: number | null = null;
  private unloadListener: PendingTask | null = null;

  constructor() {
    super();
    this.initDB().then(() => {
      this.scheduleIdleTask(() => this.loadLogs());
      this.scheduleIdleTask(() => this.cleanExpiredLogs());
    });
    this.setupUnloadListener();
    (window as any).tradeLogger = this;
  }

  private initDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request: IDBOpenDBRequest = indexedDB.open(this.DB_NAME, 1);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => {
        this.db = request.result;
        resolve();
      };

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore(this.STORE_NAME, { keyPath: 'id' });
      };
    });
  }

  protected loadLogs(): void {
    if (!this.db) return;

    const transaction: IDBTransaction = this.db.transaction(
      this.STORE_NAME,
      'readonly',
    );
    const store: IDBObjectStore = transaction.objectStore(this.STORE_NAME);
    const request: IDBRequest = store.getAll();

    request.onsuccess = () => {
      this.logs.next(request.result);
    };
  }

  protected onLogsUpdated(): void {
    this.scheduleSave();
  }

  private enqueueSaveLogs(): void {
    this.scheduleIdleTask((): void => this.saveLogs());
  }

  protected scheduleSave(): void {
    this.enqueueSaveLogs();

    // Set a force save timeout if it's not already set
    if (this.forceSaveTimeout === null) {
      this.forceSaveTimeout = window.setTimeout(() => {
        this.saveLogs();
      }, this.FORCE_SAVE_TIMEOUT_MS); // Force save after x seconds if idle callback hasn't run
    }
  }

  protected saveLogs(): void {
    const currentTime: number = Date.now();
    if (currentTime - this.lastSaveTime < this.MIN_SAVE_INTERVAL_MS) {
      // If less than x seconds have passed since the last save, schedule a save for later
      if (this.saveLogsTimeout === null) {
        this.saveLogsTimeout = window.setTimeout(
          () => {
            this.saveLogsTimeout = null;
            this.enqueueSaveLogs();
          },
          this.MIN_SAVE_INTERVAL_MS - (currentTime - this.lastSaveTime),
        );
      }
      return;
    }

    this.lastSaveTime = currentTime;
    this.saveLogsTimeout = null;
    if (this.forceSaveTimeout !== null) {
      clearTimeout(this.forceSaveTimeout);
      this.forceSaveTimeout = null;
    }

    if (!this.db) return;

    const transaction: IDBTransaction = this.db.transaction(
      this.STORE_NAME,
      'readwrite',
    );
    const store: IDBObjectStore = transaction.objectStore(this.STORE_NAME);

    // Clear existing logs
    store.clear();

    // Add current logs
    this.logs.value.forEach((log: TradeLogEntry) => {
      store.add(log);
    });
  }

  private setupUnloadListener(): void {
    this.unloadListener = () => {
      this.saveLogsSync();
    };
    window.addEventListener('beforeunload', this.unloadListener);
  }

  private saveLogsSync(): void {
    if (!this.db) return;

    const transaction: IDBTransaction = this.db.transaction(
      this.STORE_NAME,
      'readwrite',
    );
    const store: IDBObjectStore = transaction.objectStore(this.STORE_NAME);

    store.clear();

    this.logs.value.forEach((log: TradeLogEntry) => {
      store.add(log);
    });

    // Use a synchronous request to ensure the operation completes before the page unloads
    transaction.oncomplete = () => {};
    transaction.onerror = () => {};
  }

  private scheduleIdleTask(task: PendingTask): void {
    this.pendingTasks.push(task);
    this.scheduleNextIdleCallback();
  }

  private scheduleNextIdleCallback(): void {
    if (this.pendingTasks.length === 0) return;

    if ('requestIdleCallback' in window) {
      (window as any).requestIdleCallback((deadline) => {
        while (deadline.timeRemaining() > 0 && this.pendingTasks.length > 0) {
          const task: PendingTask = this.pendingTasks.shift();
          if (task) task();
        }
        this.scheduleNextIdleCallback();
      });
    } else {
      // Fallback for browsers that don't support requestIdleCallback
      setTimeout(() => {
        const task: PendingTask = this.pendingTasks.shift();
        if (task) task();
        this.scheduleNextIdleCallback();
      }, 1);
    }
  }

  ngOnDestroy(): void {
    if (this.unloadListener) {
      window.removeEventListener('beforeunload', this.unloadListener);
    }
  }
}
