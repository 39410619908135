import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NzMessageModule } from 'ng-zorro-antd/message';

import { SaveLoaderService } from './save-loader.service';

@NgModule({
  imports: [CommonModule, NzMessageModule],
  providers: [SaveLoaderService],
})
export class SaveLoaderModule {}
