import { IInstrument } from '../models';
import { DataFeed } from './data-feed';

export interface SettleData {
  timestamp: number;
  instrument: IInstrument;
  price: number;
}

export abstract class SettleDataFeed extends DataFeed<SettleData> {}
