import { RealtimeType } from './realtime';

export interface IFeedRelayStats {
  sent: {
    [key: string]: number;
  };
  received: {
    [key: string]: number;
  };
}

export class FeedRelayStats {
  private statsIntervalId: number;
  private _stats: IFeedRelayStats = {
    sent: {},
    received: {},
  };

  constructor() {}

  startStatsTimer(): void {
    if (!this.statsIntervalId) {
      this.statsIntervalId = setInterval((): void => {
        console.log(
          'RealFeed Relay stats - sent: ',
          this._stats.sent,
          ', received: ',
          this._stats.received,
        );
      }, 5000);
    }
  }

  increaseSent(messageType: RealtimeType): void {
    if (!this._stats.sent[messageType]) this._stats.sent[messageType] = 0;
    this._stats.sent[messageType]++;
  }

  increaseReceived(messageType: RealtimeType): void {
    if (!this._stats.received[messageType])
      this._stats.received[messageType] = 0;
    this._stats.received[messageType]++;
  }
}
