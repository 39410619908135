export const EmptyLayout = {
  settings: {
    showMaximiseIcon: false,
    selectionEnabled: true,
  },
  dimensions: {
    borderWidth: 3,
  },
  content: [],
};
