export interface IHashCode {
    hashCode(): string;
}

export class HashMap<K extends IHashCode, T> implements Map<K, T> {

    private _map: Map<string, [K, T]> = new Map();

    clear(): void {
        this._map.clear();    
    }
    delete(key: K): boolean {
        return this._map.delete(key.hashCode())
    }
    forEach(callbackfn: (value: T, key: K, map: Map<K, T>) => void, thisArg?: any): void {
        for(const [key, value] of this.entries()) {
            callbackfn.apply(thisArg ?? {}, [value, key, this])
        }
    }
    get(key: K): T | undefined {
        return this._map.get(key.hashCode())[1];
    }
    has(key: K): boolean {
        return this._map.has(key.hashCode());
    }
    set(key: K, value: T): this {
        this._map.set(key.hashCode(), [key, value]);
        return this;
    }
    get size() {
        return this._map.size;
    }
    [Symbol.iterator](): IterableIterator<[K, T]> {
        return this._map.values();
    }
    entries(): IterableIterator<[K, T]> {
        return this._map.values()
    }
    keys(): IterableIterator<K> {
        return Array.from(this._map.values()).map(([k, _]) => k)[Symbol.iterator]()
    }
    values(): IterableIterator<T> {
        return Array.from(this._map.values()).map(([_, v]) => v)[Symbol.iterator]()
    }
    search(value: T): K {
        const data =  Array.from(this._map.entries()).find(([hash, data]) => data[1] === value);
        if(!data) 
            return;
        return data[1][0];
    }
    get [Symbol.toStringTag]() { return this._map[Symbol.toStringTag] };

}   