<div class="app-stats-container">
  <h2>Market Data Statistics <small>by Instrument</small></h2>

  <table class="compact-table">
    <thead>
    <tr>
      <th>Symbol</th>
      <th>Total</th>
      <th *ngFor="let messageTypeHeader of sortedMessageTypes()">{{ messageTypeHeader.messageType }} {{ messageTypeHeader.dataLevel }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let instrument of instrumentStats">
      <td>{{ instrument.symbol }}</td>
      <td>{{ instrument.totalCount }}</td>
      <td *ngFor="let messageTypeHeader of sortedMessageTypes()">
        {{ getMessageTypeCount(instrument, messageTypeHeader.messageType) }}
      </td>
    </tr>
    </tbody>
  </table>

  <lib-market-data-subscriber-stats></lib-market-data-subscriber-stats>
</div>
