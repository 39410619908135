import { rti } from './messages-js/otps_proto_pool';

/**
 * @description
 * Decompiled with JetBrains decompiler
 * Type = com.omnesys.rapi.Constants
 * Assembly = rapiplus, Version=12.3.0.0, Culture=neutral, PublicKeyToken=null
 * MVID = 9BE6553B-BB36-4646-99D3-DBFADAD3289C
 * Assembly location = rithmic-datafeed-api\Deploy\12.3.0.0\rapiplus.dll
 * @see R API+.NET - Constants Class Reference
 */
export enum RapiConstants {
  ENV_MAX_BARS = 'RAPI_MAX_BARS',
  DEFAULT_MAX_BARS = 10000,
  IH_OSE_NAME = 'IhStreamEngine',
  MD_OSE_NAME = 'MdStreamEngine',
  PNL_OSE_NAME = 'PnlStreamEngine',
  REP_OSE_NAME = 'RepStreamEngine',
  TS_OSE_NAME = 'TsStreamEngine',
  ENV_IH_ENCODING = 'RAPI_IH_ENCODING',
  DEFAULT_IH_ENCODING = 2,
  ENV_MD_ENCODING = 'RAPI_MD_ENCODING',
  DEFAULT_MD_ENCODING = 2,
  ENV_OR_ENCODING = 'RAPI_OR_ENCODING',
  DEFAULT_OR_ENCODING = 1,
  ENV_PNL_ENCODING = 'RAPI_PNL_ENCODING',
  DEFAULT_PNL_ENCODING = 1,
  ENV_REP_ENCODING = 'RAPI_REP_ENCODING',
  DEFAULT_REP_ENCODING = 2,
  ENV_TS_ENCODING = 'RAPI_TS_ENCODING',
  DEFAULT_TS_ENCODING = 1,
  ENV_ADMIN_NAME = 'RAPI_ADMIN_NAME',
  ENV_ADM_OPEN_CNNCTRS = 'RAPI_ADM_OPEN_CNNCTRS',
  ENV_ADM_CNNCT_PT = 'RAPI_ADM_CNNCT_PT',
  ENV_ADM_ENCODING = 'RAPI_ADM_ENCODING',
  ENV_HEARTBEAT_FREQ = 'RAPI_HEARTBEAT_FREQ_MILLISECS',
  DEFAULT_HEARTBEAT_FREQ = 40000,
  ENV_RECOVER_FREQ = 'RAPI_RECOVER_FREQ_MILLISECS',
  DEFAULT_RECOVER_FREQ = 5021,
  ENV_INIT_RECOVER_FREQ = 'RAPI_INIT_RECOVER_FREQ_MILLISECS',
  DEFAULT_INIT_RECOVER_FREQ = 100,
  DEFAULT_CERT_NAME = 'rithmic.com',
  HISTORY_CB = 10000,
  TRADE_ROUTE_STATUS_UP = 'UP',
  TRADE_ROUTE_STATUS_DOWN = 'DOWN',
  ORDER_TYPE_EXTERNAL = 'E',
  ORDER_TYPE_LIMIT = 'L',
  ORDER_TYPE_LMT_IF_TOUCHED = 'LIT',
  ORDER_TYPE_MARKET = 'M',
  ORDER_TYPE_MKT_IF_TOUCHED = 'MIT',
  ORDER_TYPE_STOP_LIMIT = 'SLMT',
  ORDER_TYPE_STOP_MARKET = 'STP',
  BUY_SELL_TYPE_BUY = 'B',
  BUY_SELL_TYPE_SELL = 'S',
  BUY_SELL_TYPE_SELL_SHORT = 'SS',
  BUY_SELL_TYPE_SELL_SHORT_EXEMPT = 'SSE',
  ORDER_DURATION_DAY = 'DAY',
  ORDER_DURATION_FOK = 'FOK',
  ORDER_DURATION_GTC = 'GTC',
  ORDER_DURATION_IOC = 'OC',
  ORDER_ENTRY_TYPE_AUTO = 'A',
  ORDER_ENTRY_TYPE_MANUAL = 'M',
  RELEASE_COMMAND_HOLD_ORDER = 'hold_order',
  ORDER_OPERATION_TYPE_AFOCCA = 'AFOCCA',
  ORDER_OPERATION_TYPE_FOCCA = 'FOCCA',
  ORDER_OPERATION_TYPE_CCA = 'CCA',
  ORDER_OPERATION_TYPE_FCA = 'FCA',
  ORDER_OPERATION_TYPE_LIST = 'LIST',
  BRACKET_TYPE_TARGET_AND_STOP = 'target and stop',
  BRACKET_TYPE_TARGET_ONLY = 'target only',
  BRACKET_TYPE_STOP_ONLY = 'stop only',
  BRACKET_TYPE_TARGET_AND_STOP_STATIC = 'target and stop static',
  BRACKET_TYPE_TARGET_ONLY_STATIC = 'target only static',
  BRACKET_TYPE_STOP_ONLY_STATIC = 'stop only static',
  REPORT_TYPE_BUST = 'bust',
  REPORT_TYPE_CANCEL = 'cancel',
  REPORT_TYPE_COMMISSION = 'commission',
  REPORT_TYPE_FAILURE = 'failure',
  REPORT_TYPE_FILL = 'fill',
  REPORT_TYPE_MODIFY = 'modify',
  REPORT_TYPE_NOT_CNCLLD = 'not cancelled',
  REPORT_TYPE_NOT_MODIFIED = 'not modified',
  REPORT_TYPE_REJECT = 'reject',
  REPORT_TYPE_SOD = 'sod',
  REPORT_TYPE_SOD_MODIFY = 'sod_modify',
  REPORT_TYPE_STATUS = 'status',
  REPORT_TYPE_TRADE_CORRECT = 'trade correct',
  REPORT_TYPE_TRIGGER = 'trigger',
  REPORT_TYPE_TRIGGER_PULLED = 'trigger pulled',
  LINE_STATUS_OPEN_PENDING = 'open pending',
  LINE_STATUS_OPEN = 'open',
  LINE_STATUS_MOD_TRIGGER_PENDING = 'modified, trigger pending',
  LINE_STATUS_TRIGGER_PENDING = 'trigger pending',
  LINE_STATUS_PARTIAL = 'partial',
  LINE_STATUS_CANCEL_PENDING = 'cancel pending',
  LINE_STATUS_COMPLETE = 'complete',
  LINE_STATUS_MODIFY_PENDING = 'modify pending',
  LINE_STATUS_MODIFIED = 'modified',
  LINE_STATUS_ORDER_SENT_TO_EXCH = 'order sent to exch',
  LINE_STATUS_CANCEL_SENT_TO_EXCH = 'cancel sent to exch',
  LINE_STATUS_MODIFY_SENT_TO_EXCH = 'modify sent to exch',
  LINE_STATUS_ORDER_RCVD_BY_EXCH_GWAY = 'order received by exch gateway',
  LINE_STATUS_CANCEL_RCVD_BY_EXCH_GWAY = 'cancel received by exch gateway',
  LINE_STATUS_MODIFY_RCVD_BY_EXCH_GWAY = 'modify received by exch gateway',
  LINE_STATUS_ORDER_RECEIVED = 'Order received from client',
  LINE_STATUS_MODIFY_RECEIVED = 'Modify received from client',
  LINE_STATUS_CANCEL_RECEIVED = 'Cancel received from client',
  LINE_STATUS_MODIFY_FAILED = 'Modification Failed',
  LINE_STATUS_CANCEL_FAILED = 'Cancellation Failed',
  COMPLETION_REASON_CANCEL = 'C',
  COMPLETION_REASON_REJECT = 'R',
  COMPLETION_REASON_FILL = 'F',
  COMPLETION_REASON_FAILURE = 'FA',
  COMPLETION_REASON_PFBC = 'PFBC',
  COMPLETION_REASON_UNKNOWN = 'U',
  ACCOUNT_STATUS_ACTIVE = 'active',
  ACCOUNT_STATUS_INACTIVE = 'inactive',
  ACCOUNT_STATUS_ADMIN_ONLY = 'admin only',
  ADD_ACCOUNT = 'add_account',
  ASSIGN_ACCOUNT_TO_USER = 'assign_account_to_user',
  REMOVE_ACCOUNT_FROM_USER = 'remove_account_from_user',
  PRICE_ID_BEST_ASK = 25,
  PRICE_ID_BEST_BID = 22,
  PRICE_ID_LEAN_PRICE = 54909,
  PRICE_ID_TRADE = 6,
  SIZE_ID_BEST_ASK = 31,
  SIZE_ID_BEST_BID = 30,
  SETTLEMENT_PRICE_TYPE_PRELIM_OFF_TICK = 'prelim off tick',
  SETTLEMENT_PRICE_TYPE_PRELIM_ON_TICK = 'prelim on tick',
  SETTLEMENT_PRICE_TYPE_FINAL = 'final',
  SETTLEMENT_PRICE_TYPE_THEORETICAL = 'theoretical',
  DEFAULT_ENVIRONMENT_KEY = 'system',
  USER_TYPE_FCM = '1',
  USER_TYPE_IB = '2',
  USER_TYPE_TRADER = '3',
  PACKAGE_NAME = 'com.omnesys.rapi',
  RENGINE_NAME = 'REngine',
  RAPI_NAME = 'R | API+.NET',
  RAPI_VERSION = '12.3.0.0',
  LOGIN_RQ = 'rithmic_login',
  AUX_REF_DATA_RQ = 'mrv',
  GET_ORDER_BOOK_RQ = 'get_order_book',
  GET_POSITION_IN_QUEUE_INFO_RQ = 'get_position_in_queue_info',
  GET_VOLUME_AT_PRICE_RQ = 'get_volume_at_price',
  IS_THERE_AN_AGGREGATOR_RQ = 'is_there_an_aggregator',
  MRV_EQUITY_OPTION_STRATEGY_RQ = 'mrv_equity_option_strategy',
  MRV_BINARY_CONTRACT_RQ = 'mrv_binary_contract',
  MRV_BINARY_CONTRACT_STK_RQ = 'mrv_binary_contract_show_tree_koi',
  MRV_OPTION_RQ = 'mrv_option',
  MRV_OPTION_STK_RQ = 'mrv_option_show_tree_koi',
  PRICE_REF_DATA_RQ = 'mrv_refdata',
  MRV_REF_DATA_RQ = 'mrv_refdata',
  MRV_RQ = 'mrv',
  MRV_STRATEGY_EX_RQ = 'mrv_strategy_exchange',
  MRV_STRATEGY_STK_RQ = 'mrv_strategy_show_tree_koi',
  MRV_UNDERLYING_RQ = 'mrv_underlying',
  MRV_UNDERLYING_STK_RQ = 'mrv_underlying_show_tree_koi',
  SELECT_ONCNI_RQ = 'mrv_refdata_select',
  TICK_SIZE_TABLE_RQ = 'give_tstype_tbl',
  DAILY_TIME_BAR_PERIOD = 'daily',
  WEEKLY_TIME_BAR_PERIOD = 'weekly',
  GET_TIME_BARS_RQ = 'get_time_bars',
  SUPPRESS_EMPTY_BARS = 'suppress_empty_bars',
  ROTH_TRADE_RQ = 'roth_trade',
  BAR_TYPE_DAILY = 'daily bar',
  BAR_TYPE_MINUTE = 'minute bar',
  BAR_TYPE_RANGE = 'range bar',
  BAR_TYPE_SECOND = 'second bar',
  BAR_TYPE_TICK = 'tick bar',
  BAR_TYPE_VOLUME = 'volume bar',
  BAR_TYPE_WEEKLY = 'weekly bar',
  BAR_SUB_TYPE_CUSTOM = 'custom',
  BAR_SUB_TYPE_REGULAR = 'regular',
  GET_WEEKLY_BARS = 'get_weekly_bars',
  GET_DAILY_BARS = 'get_daily_bars',
  GET_SECOND_BARS = 'get_sec_bars',
  GET_AGG_SECOND_BARS = 'get_aggregated_sec_bars',
  PUBLISH_AGG_SECOND_BARS = 'subscribe_aggregated_sec_bars',
  GET_MINUTE_BARS_TICK = 'get_min_bars',
  GET_MINUTE_BARS = 'get_time_bars',
  GET_AGG_MINUTE_BARS = 'get_aggregated_time_bars',
  PUBLISH_AGG_MINUTE_BARS = 'subscribe_aggregated_time_bars',
  GET_RANGE_BARS = 'get_range_bars',
  PUBLISH_RANGE_BARS = 'publish_range_bars',
  UNPUBLISH_RANGE_BARS = 'unpublish_range_bars',
  GET_CUSTOM_RANGE_BARS = 'get_custom_range_bars',
  PUBLISH_CUSTOM_RANGE_BARS = 'publish_custom_range_bars',
  UNPUBLISH_CUSTOM_RANGE_BARS = 'unpublish_custom_range_bars',
  GET_TICK_BARS = 'get_tick_bars',
  PUBLISH_TICK_BARS = 'publish_tick_bars',
  UNPUBLISH_TICK_BARS = 'unpublish_tick_bars',
  GET_CUSTOM_TICK_BARS = 'get_custom_tick_bars',
  PUBLISH_CUSTOM_TICK_BARS = 'publish_custom_tick_bars',
  UNPUBLISH_CUSTOM_TICK_BARS = 'unpublish_custom_tick_bars',
  GET_SINGLE_TICKS = 'get_single_ticks',
  GET_SINGLE_TICKS_BAR_TYPE = 'trade tick',
  GET_VOLUME_BARS = 'get_volume_bars',
  PUBLISH_VOLUME_BARS = 'publish_volume_bars',
  UNPUBLISH_VOLUME_BARS = 'unpublish_volume_bars',
  GET_CUSTOM_VOLUME_BARS = 'get_custom_volume_bars',
  PUBLISH_CUSTOM_VOLUME_BARS = 'publish_custom_volume_bars',
  UNPUBLISH_CUSTOM_VOLUME_BARS = 'unpublish_custom_volume_bars',
  CANCEL_ORDER_RQ = 'om_process_cancel_order_list',
  CHANGE_PASSWORD_RQ = 'om_process_change_password',
  CREATE_USER_DEFINED_SPREAD_RQ = 'om_process_create_user_defined_spread',
  GET_ACCOUNTS_FCM_BY_STATUS_RQ = 'get_accounts_fcm_by_status',
  GET_ACCOUNTS_IB_BY_STATUS_RQ = 'get_accounts_ib_by_status',
  GET_EASY_TO_BORROW_LIST_RQ = 'get_easy_to_borrow_list',
  GET_EXCHANGE_ROUTES_RQ = 'get_exchange_routes',
  EXIT_POSITION_RQ = 'om_process_position_exit',
  EXIT_POSITION_CONTRACTWISE_RQ = 'om_process_position_exit_contractwise',
  GET_PRODUCT_RMS_SETTINGS_RQ = 'om_process_get_product_code_rms_settings_all',
  LINK_ORDERS_RQ = 'om_process_link_orders',
  LIST_ACCTS_FCM_RQ = 'om_process_list_accounts_fcm',
  LIST_ACCTS_IB_RQ = 'om_process_list_accounts_ib',
  LIST_ACCTS_USER_RQ = 'om_process_list_accounts_user',
  LIST_EXCHANGE_PERMISSIONS_RQ = 'list_exchange_permissions',
  LOAD_REF_DATA_RQ = 'load_reference_data',
  LOGIN_INFO_RQ = 'om_process_login_info',
  MODIFY_ORDER_RQ = 'om_process_modify_order_list',
  MODIFY_ORDER_REF_DATA_RQ = 'modify_order_reference_data',
  PULL_ORDERS_RQ = 'om_process_pull_orders',
  RMS_CALC_PNL_RQ = 'om_process_rms_calc_pnl',
  ROTH_FILLS_RQ = 'om_process_roth_fills',
  SHOW_BRACKETS_RQ = 'show_brackets',
  SHOW_BRACKET_STOPS_RQ = 'show_bracket_stops',
  SHOW_ORDERS_RQ = 'om_process_show_orders',
  SHOW_ORDER_HISTORY_RQ = 'om_process_show_order_history',
  SHOW_ORDER_HISTORY_DATES_RQ = 'om_process_show_order_history_dates',
  SHOW_ORDER_HISTORY_DETAIL_RQ = 'om_process_show_order_history_detail',
  SHOW_ORDER_HISTORY_SUMMARY_RQ = 'om_process_show_order_history_summary',
  SHOW_QUOTES_RQ = 'show_quotes',
  SUBMIT_BRACKET_RQ = 'om_process_submit_bracket_order',
  SUBMIT_OCA_RQ = 'om_process_submit_oco_order',
  SUBMIT_ORDER_RQ = 'om_process_submit_order',
  SUBMIT_ORDER_LIST_RQ = 'om_process_submit_order_list',
  UPDATE_TARGET_BRACKET_LEVEL_RQ = 'update_target_bracket_level',
  UPDATE_STOP_BRACKET_LEVEL_RQ = 'update_stop_bracket_level',
  UPDATE_EASY_TO_BORROW_RQ_TYPE = 'update_easy_to_borrow_list',
  ORDER_QTY_CONDITION_DO_NOT_INCREASE = 'dni',
  LIST_ACCEPTED_AGREEMENTS_RQ = 'list_accepted_agreements',
  LIST_UNACCEPTED_AGREEMENTS_RQ = 'list_unaccepted_agreements',
  USER_TYPE_SYS_ADMIN = '0',
}

/**
 * @see request_cancel_order.proto
 * @see R API+.NET - Constants Class Reference
 */
export enum OrderPlacement {
  /**
   * @description Order operations initiated by humans manually.
   */
  MANUAL = 1,
  /**
   * @description Order operations initiated by trading algorithms or programs.
   */
  AUTO = 2,
}

/**
 * @see request_bracket_order.proto
 */
export enum UserType {
  USER_TYPE_ADMIN = 0,
  USER_TYPE_FCM = 1,
  USER_TYPE_IB = 2,
  USER_TYPE_TRADER = 3,
}

/**
 * @see request_bracket_order.proto
 */
export enum BracketType {
  STOP_ONLY = 1,
  TARGET_ONLY = 2,
  TARGET_AND_STOP = 3,
  STOP_ONLY_STATIC = 4,
  TARGET_ONLY_STATIC = 5,
  TARGET_AND_STOP_STATIC = 6,
}

const MarketDataUpdateBits = rti.RequestMarketDataUpdate.UpdateBits;

/**
 * Includes basic market information such as last traded price
 */
export const DataLevel0Mask: number =
  MarketDataUpdateBits.LAST_TRADE |
  MarketDataUpdateBits.OPEN |
  MarketDataUpdateBits.HIGH_LOW |
  MarketDataUpdateBits.CLOSE |
  MarketDataUpdateBits.MARKET_MODE |
  MarketDataUpdateBits.HIGH_PRICE_LIMIT |
  MarketDataUpdateBits.LOW_PRICE_LIMIT |
  MarketDataUpdateBits.ADJUSTED_CLOSE;

/**
 * Includes the Best Bid and Offer quotes (20 levels above and under the current price)
 */
export const DataLevel1Mask: number = MarketDataUpdateBits.BBO;

export const DataLevel2Mask: number =
  MarketDataUpdateBits.ORDER_BOOK | MarketDataUpdateBits.HIGH_BID_LOW_ASK;

export const DataLevelSpecializedMask: number =
  MarketDataUpdateBits.OPENING_INDICATOR |
  MarketDataUpdateBits.CLOSING_INDICATOR |
  MarketDataUpdateBits.OPEN_INTEREST |
  MarketDataUpdateBits.MARGIN_RATE;
