<div class="metrics-panel">

  <div class="button-container">
    <div>
      <button nz-button nzType="default" size="small" nzShape="circle" class="icon-button" (click)="copyMetricsToClipboard()">
        <i nz-icon class="icon-save"></i>
      </button>
    </div>
    <div>
      <button nz-button nzType="default" size="small" nzShape="circle" class="icon-button" (click)="resetMetrics()">
        <i nz-icon class="icon-reset"></i>
      </button>
    </div>
  </div>

  <div class="metrics-container">
    <div class="metrics-column">
      <div class="metrics-group">
        <h6>Rithmic Metrics</h6>
        <div class="metrics-grid">
          <div class="d-flex perf-metric" [ngClass]="colorByThreshold(tradeLatency)" title="Trade latency - Time difference between trade execution at the exchange and the app's receipt and processing of the trade">
            <i class="icon-clock"></i>
            TL
            <span title="Trade latency - instantaneous" *ngIf="tradeLatency; else valueNotAvailable" class="perf-value">{{ formatNumber(tradeLatency) }}</span>
            ·
            <span title="Trade latency - average" *ngIf="averageTradeLatency; else valueNotAvailable" [ngClass]="colorByThreshold(averageTradeLatency)" class="perf-value">{{ formatNumber(averageTradeLatency) }}</span>
            ms
          </div>

          <div class="d-flex perf-metric" [ngClass]="colorByThreshold(domTradeLatency)" title="DOM Trade E2E latency - Time difference between trade execution at the exchange and the app's rendering of the trade in the DOM">
            <i class="icon-clock"></i>
            DOMTL
            <span title="DOM Trade latency - instantaneous" *ngIf="domTradeLatency; else valueNotAvailable" class="perf-value">{{ formatNumber(domTradeLatency) }}</span>
            ·
            <span title="DOM Trade latency - average" *ngIf="domAverageTradeLatency; else valueNotAvailable" [ngClass]="colorByThreshold(domAverageTradeLatency)" class="perf-value">{{ formatNumber(domAverageTradeLatency) }}</span>
            ms
          </div>

          <div class="d-flex perf-metric" [ngClass]="colorByThreshold(messagesPerSec,[0, 2000, 6000])" title="Incoming Messages rate (busiest web socket) ">
            <i class="icon-tool-circle"></i>
            IMws
            <span title="Incoming Messages - instantaneous rate" *ngIf="messagesPerSec; else valueNotAvailable" class="perf-value medium">{{ formatNumber(messagesPerSec) }}</span>
            ·
            <span title="Incoming Messages - average rate" *ngIf="averageMessagesPerSec; else valueNotAvailable" [ngClass]="colorByThreshold(averageMessagesPerSec,[0, 2000, 6000])" class="perf-value medium">{{ formatNumber(averageMessagesPerSec) }}</span>
            ·
            <span title="Incoming Messages - peak rate" *ngIf="peakMessagesPerSec; else valueNotAvailable" [ngClass]="colorByThreshold(peakMessagesPerSec,[0, 2000, 6000])" class="perf-value medium">{{ formatNumber(peakMessagesPerSec) }}</span>
            msg/s
          </div>

          <div class="d-flex">
            <span class="d-flex perf-metric" [ngClass]="colorByThreshold(webSocketTickerPlantLatency)" title="Ticker Plant web socket latency">
              <i class="icon-signal" [ngClass]="colorByHealthIndicator(webSocketTickerPlantHealth)"
              title="Ticker Plant web socket connection health ({{ webSocketTickerPlantHealth }})"></i>
              TPws
              <span *ngIf="webSocketTickerPlantLatency; else valueNotAvailable" class="perf-value">{{ formatNumber(webSocketTickerPlantLatency) }}</span>
              ms
            </span>
          </div>

          <div class="d-flex">
            <span class="d-flex perf-metric" [ngClass]="colorByThreshold(webSocketOrderPlantLatency)" title="Order Plant web socket latency">
              <i class="icon-signal" [ngClass]="colorByHealthIndicator(webSocketOrderPlantHealth)"
                 title="Order Plant web socket connection health ({{webSocketOrderPlantHealth}})"
              ></i>
              OPws
              <span *ngIf="webSocketOrderPlantLatency; else valueNotAvailable" class="perf-value">{{ formatNumber(webSocketOrderPlantLatency) }}</span>
              ms
            </span>
          </div>

        </div>
      </div>

      <div class="metrics-group">
        <h6>User Network Metrics</h6>
        <div class="metrics-grid">
          <div class="d-flex perf-metric" [ngClass]="colorByThreshold(userNetworkLatency)" title="TBD">
            <i class="icon-clock"></i>
            Network Latency:
            <span title="TBD" *ngIf="userNetworkLatency; else valueNotAvailable" class="perf-value">{{ formatNumber(userNetworkLatency) }}</span>
            ms
          </div>
        </div>
      </div>

    </div>

    <div class="metrics-column">
      <div class="metrics-group">
        <h6>Browser Metrics</h6>
        <div class="metrics-grid">
          <div class="d-flex perf-metric" [ngClass]="colorByThreshold(timeoutDrift)" title="TBD">
            <i class="icon-clock"></i>
            Timeout Drift:
            <span title="TBD" *ngIf="timeoutDrift; else valueNotAvailable" class="perf-value">{{ formatNumber(timeoutDrift) }}</span>
            ms
          </div>

          <div class="d-flex perf-metric" [ngClass]="colorByThreshold(requestAnimationFrameDrift)" title="TBD">
            <i class="icon-clock"></i>
            RAF Drift:
            <span title="TBD" *ngIf="requestAnimationFrameDrift; else valueNotAvailable" class="perf-value">{{ formatNumber(requestAnimationFrameDrift) }}</span>
            ms
          </div>

          <div class="d-flex perf-metric" title="Battery Level">
            Battery Level:
            <span *ngIf="batteryLevel; else valueNotAvailable" class="perf-value">{{ formatNumber(batteryLevel) }}%</span>
          </div>

          <div class="d-flex perf-metric" title="Battery Charging">
            Battery Charging:
            <span class="perf-value">{{ batteryCharging ? "Yes" : "No" }}</span>
          </div>

          <div class="d-flex perf-metric" title="">
            User Idle:
            <span class="perf-value">{{ userIdleState ? "Yes" : "No" }}</span>
          </div>

          <div class="d-flex perf-metric" title="">
            User Idle Time:
            <span class="perf-value">{{ userIdleTime }}</span>
          </div>

          <div class="d-flex perf-metric" title="Page Visibility">
            Page Visibility:
            <span class="perf-value">{{ documentVisibility === 1 ? "Visible" : "Hidden" }}</span>
          </div>
        </div>
      </div>

      <div class="metrics-group">
        <h6>Browser Throttling Metrics</h6>
        <div class="metrics-grid">
          <div class="d-flex perf-metric" [ngClass]="colorByThreshold(browserThrottlingDetected, [0, 1, 1])" title="TBD">
            <i class="icon-clock"></i>
            Is Throttling:
            <span title="TBD" *ngIf="browserThrottlingDetected !== null; else valueNotAvailable" class="perf-value">{{ browserThrottlingDetected ? 'Yes' : 'No' }}</span>
          </div>

          <div class="d-flex perf-metric" [ngClass]="colorByThreshold(browserThrottlingDuration, [0, 1, 1])" title="TBD">
            <i class="icon-clock"></i>
            Throttling Duration:
            <span title="TBD" *ngIf="browserThrottlingDuration !== null; else valueNotAvailable" class="perf-value">{{ formatNumber(browserThrottlingDuration) }}ms</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #valueNotAvailable>
  <span class="perf-value">&hellip;</span>
</ng-template>
