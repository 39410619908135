import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NzFormModule } from 'ng-zorro-antd';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

import { InstrumentDialogComponent } from './instrument-dialog.component';

@NgModule({
  declarations: [InstrumentDialogComponent],
  imports: [
    CommonModule,
    NzInputModule,
    NzTabsModule,
    NzFormModule,
    ReactiveFormsModule,
    ScrollingModule,
  ],
  exports: [InstrumentDialogComponent],
})
export class InstrumentDialogModule {}
