import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  IPaginationResponse,
  RepositoryAction,
  RepositoryActionData,
} from 'communication';
import { ISession, SessionsRepository } from 'trading';

@Injectable({
  providedIn: 'root',
})
export class SessionsService {
  private sessionsSubject: BehaviorSubject<ISession[]> = new BehaviorSubject<
    ISession[]
  >([]);
  private sessions$: Observable<ISession[]> =
    this.sessionsSubject.asObservable();
  private isLoading: boolean = false;

  constructor(private realSessionsRepository: SessionsRepository) {
    this.initializeSessionsListener();
    this.loadSessions();
  }

  private initializeSessionsListener(): void {
    this.realSessionsRepository.actions.subscribe(
      (action: RepositoryActionData<ISession>): void => {
        if (
          action.action === RepositoryAction.Create ||
          action.action === RepositoryAction.Update ||
          action.action === RepositoryAction.Delete
        ) {
          this.loadSessions();
        }
      },
    );
  }

  private loadSessions(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.realSessionsRepository
      .getItems()
      .pipe(
        map(
          (response: IPaginationResponse<ISession>): ISession[] =>
            response.data,
        ),
        tap((sessions: ISession[]) => {
          this.sessionsSubject.next(sessions);
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  getSessions(): Observable<ISession[]> {
    return this.sessions$;
  }
}
