import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FastMarketModeToggleHandler {
  private _enabledSubject = new BehaviorSubject<boolean>(false);

  $modeChange = this._enabledSubject.asObservable();

  get isEnabled() {
    return this._enabledSubject.value;
  }

  toggle() {
    this.setEnabled(!this.isEnabled);
  }

  setEnabled(isEnabled: boolean) {
    this._enabledSubject.next(isEnabled);
  }
}
