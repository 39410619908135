import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Repository } from 'communication';
import { IInstrument } from 'trading';

@Injectable()
export abstract class InstrumentsRepository extends Repository<IInstrument> {
  abstract rollInstrument(
    instrument: IInstrument,
    params,
  ): Observable<IInstrument>;
}
