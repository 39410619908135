import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsService } from 'settings';

import { IAlert } from './trade-lock.model';
import { TradeLockService } from './trade-lock.service';

const ALERT_DISPLAY_TIME: number = 1500;

@Component({
  selector: 'app-trade-lock',
  templateUrl: './trade-lock.component.html',
  styleUrls: ['./trade-lock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class TradeLockComponent implements OnInit {
  isTradingGloballyEnabled: boolean = true;
  alert: IAlert;

  private _alertTimerId: number;

  constructor(
    private _changeDetection: ChangeDetectorRef,
    private _settingsService: SettingsService,
    private _tradeLockService: TradeLockService,
  ) {}

  ngOnInit(): void {
    this._tradeLockService.$isTradingGloballyEnabled
      .pipe(untilDestroyed(this))
      .subscribe((isTradingGloballyEnabled: boolean): void => {
        this.isTradingGloballyEnabled = isTradingGloballyEnabled;
        this._changeDetection.detectChanges();
      });
  }

  displayAlert(): void {
    const alertConfig: Pick<IAlert, 'visible'> = {
      visible: true,
    };
    const lockConfig: IAlert = {
      ...alertConfig,
      text: 'Trading is locked',
      type: 'lock',
    };
    const unlockConfig: IAlert = {
      ...alertConfig,
      text: 'Trading is unlocked',
      type: 'unlock',
    };

    if (this._alertTimerId) {
      clearTimeout(this._alertTimerId);
      this._alertTimerId = null;
    }

    this.alert = this.isTradingGloballyEnabled ? unlockConfig : lockConfig;

    this._alertTimerId = setTimeout((): void => {
      this.alert.visible = false;
      this._changeDetection.detectChanges();
    }, ALERT_DISPLAY_TIME);

    this._changeDetection.detectChanges();
  }

  toggleGlobalTradingLock(): void {
    this.isTradingGloballyEnabled = !this.isTradingGloballyEnabled;
    this._tradeLockService.$isTradingGloballyEnabled.next(
      this.isTradingGloballyEnabled,
    );
    this._settingsService.updateTradingLock(this.isTradingGloballyEnabled);
    this.displayAlert();
    this._changeDetection.detectChanges();
  }
}
