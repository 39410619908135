import { Injectable } from '@angular/core';

import { Id } from 'communication';

import { IInstrument, IQuote } from '../models/';
import { DataFeed } from './data-feed';

@Injectable()
export abstract class OrderBookSnapshotDataFeed extends DataFeed<IQuote> {
  abstract requestSnapshot(instrument: IInstrument, connectionId: Id): void;
}
