import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConnectionHealthIndicator } from 'communication';
import { numberWithCommas } from 'projects/base-components/src/pipes/fixed-number.pipe';

import {
  IMetric,
  MetricName,
  PerformanceMetricsService,
} from '../performance-metrics.service';

/**
 * @description This component is responsible displaying performance & app-health
 * metrics. It subscribes to the `PerformanceMetricsService` and receives the
 * latest metrics values.
 */
@Component({
  selector: 'performance-metrics',
  templateUrl: './performance-metrics.component.html',
  styleUrls: ['./performance-metrics.component.scss'],
})
export class PerformanceMetricsComponent implements OnInit {
  metrics: { [key: string]: IMetric | null } = {};
  private subscription: Subscription = new Subscription();

  // Trade Metrics
  tradeLatency: number | null = null;
  averageTradeLatency: number | null = null;
  domTradeLatency: number | null = null;
  domAverageTradeLatency: number | null = null;

  // WebSocket Metrics
  messagesPerSec: number | null = null;
  averageMessagesPerSec: number | null = null;
  peakMessagesPerSec: number | null = null;
  webSocketOrderPlantLatency: number | null = null;
  webSocketTickerPlantLatency: number | null = null;
  webSocketTickerPlantHealth: ConnectionHealthIndicator | null = null;
  webSocketOrderPlantHealth: ConnectionHealthIndicator | null = null;

  // BrowserThrottlingDetector Metrics
  batteryLevel: number | null = null;
  batteryCharging: number | null = null;
  documentVisibility: number | null = null;
  timeoutDrift: number | null = null;
  requestAnimationFrameDrift: number | null = null;

  // UserIdleDetector Metrics
  userIdleState: number | null = null;
  userIdleTime: number | null = null;

  // UserNetworkLatency Metrics
  userNetworkLatency: number | null = null;

  // BrowserThrottlingCompositeDetector Metrics
  browserThrottlingDetected: number | null = null;
  browserThrottlingDuration: number | null = null;

  protected readonly ConnectionHealthIndicator = ConnectionHealthIndicator;

  constructor(private metricsService: PerformanceMetricsService) {}

  ngOnInit(): void {
    this.subscribeToMetrics();
  }

  subscribeToMetrics(): void {
    const metricsToObserve: MetricName[] = [
      MetricName.TradeLatency,
      MetricName.AverageTradeLatency,
      MetricName.DOMTradeLatency,
      MetricName.DOMAverageTradeLatency,
      MetricName.MessagesPerSec,
      MetricName.PeakMessagesPerSec,
      MetricName.AverageMessagesPerSec,
      MetricName.WebSocketOrderPlantLatency,
      MetricName.WebSocketTickerPlantLatency,
      MetricName.WebSocketOrderPlantHealth,
      MetricName.WebSocketTickerPlantHealth,
      MetricName.BatterLevel,
      MetricName.BatterCharging,
      MetricName.DocumentVisibility,
      MetricName.TimeoutDrift,
      MetricName.RequestAnimationFrameDrift,
      MetricName.UserIdleState,
      MetricName.UserIdleTime,
      MetricName.UserNetworkLatency,
      MetricName.BrowserThrottlingDetected,
      MetricName.BrowserThrottlingDuration,
    ];
    // Subscribe to multiple metrics updates
    this.subscription = this.metricsService
      .getMultipleMetricUpdates(metricsToObserve)
      .subscribe((metrics: { [key: string]: IMetric }): void => {
        this.metrics = metrics;
        metricsToObserve.forEach((name: MetricName) => {
          if (metrics[name]) {
            this[name] = this.metrics[name]?.value;
          }
        });
      });
  }

  resetMetrics(): void {
    this.metricsService.resetMetrics();
  }

  copyMetricsToClipboard(): void {
    this.metricsService.copyMetricsToClipboard().then((): void => {
      // do nothing
    });
  }

  formatNumber(value: number) {
    return numberWithCommas(value, 0);
  }

  colorByThreshold(
    value: number,
    thresholds: number[] = [0, 200, 1000],
  ): string {
    if (value >= thresholds[0] && value < thresholds[1]) {
      return 'text-success';
    } else if (value >= thresholds[1] && value < thresholds[2]) {
      return 'text-warning';
    } else if (value > thresholds[2]) {
      return 'text-danger';
    }
    return 'text-muted-3';
  }

  colorByHealthIndicator(
    value: ConnectionHealthIndicator | null,
    thresholds: number[] = [
      ConnectionHealthIndicator.Unhealthy as number,
      ConnectionHealthIndicator.Degraded as number,
      ConnectionHealthIndicator.Healthy as number,
    ],
  ): string {
    if (value === null) {
      return 'text-muted-3';
    }
    const valueNumber: number = value as number;
    if (valueNumber <= thresholds[0]) {
      return 'text-danger';
    } else if (value >= thresholds[1] && value < thresholds[2]) {
      return 'text-warning';
    } else if (value >= thresholds[2]) {
      return 'text-success';
    }
    return 'text-muted-3';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
