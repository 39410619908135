export function isInTimeRange(date, session) {
  if (!session?.workingTimes) return true;

  const _date = (moment as any).tz(date, session.timezoneId);
  const currentDay = _date.day();
  const time = getTimeFromDate(_date);
  return session?.workingTimes.some(
    ({ endDay, endTime, startDay, startTime }) =>
      (currentDay < endDay || (currentDay === endDay && time <= endTime)) &&
      (currentDay > startDay || (currentDay === startDay && time >= startTime)),
  );
}

export function getTimeFromDate(date: any) {
  return (date.hours() * 3600 + date.minutes() * 60) * 1000;
}
