<data-grid
  [disableSaveAsDefault]="!ordersPanelPresets"
  [columns]="columns"
  [items]="items"
  [handlers]="handlers"
  [loadedPresets]="loadedPresets"
  (savePresets)="savePresets()"
  (createPresets)="openNewPresetPopup(Components.OrdersPanel)"
  (saveAsDefault)="saveAsDefault(Components.OrdersPanel)"
></data-grid>
<div class="button-container">
  <button class="dark-button">
    Cancel
    <ng-container *ngIf="selectedItemsCount"
      >({{ selectedItemsCount }})</ng-container
    >
  </button>
  <button class="dark-button">Discard</button>
  <button class="primary">Apply</button>
</div>
