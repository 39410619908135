import { NumberWithCommasPipe } from './../../../../../src/app/pipes/number-with-commas.pipe';

export class NumberWithCommasFormatter {
  private _pipe: NumberWithCommasPipe = new NumberWithCommasPipe();

  constructor(private _space: number) {
    if (this._space == null) {
      throw new Error('Please provide digits');
    }
  }

  format(value: number): string {
    return this._pipe.transform(value, this._space);
  }
}
