import { Context } from "./context";

export class Subscription {

    constructor(private readonly _context: Context, private readonly _callback: Function,  private readonly _command?: string) { };

    get context() {
        return this._context;
    }

    get command() {
        return this._command;
    }

    handle(command: string) {
        return this._callback(command);
    }

 }