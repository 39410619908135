import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  ExcludeId,
  Id,
  IPaginationResponse,
  RProtocolConnectionWebSocketService,
} from 'communication';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import {
  ConnectionContainer,
  IConnection,
  IOrder,
  OrderAccount,
  OrderDuration,
  OrdersRepository,
  OrderStatus,
  OrderType,
} from 'trading';

import { TradeLockService } from 'src/app/components';
import { BaseRepository } from './base-repository';

interface IUpdateOrderRequestParams {
  orderId: Id;
  symbol: string;
  exchange: string;
  accountId: Id;
  duration: OrderDuration;
  type: OrderType;
  quantity: number;
  limitPrice?: number;
  stopPrice?: number;
}

@Injectable()
export class RealOrdersRepository
  extends BaseRepository<IOrder>
  implements OrdersRepository
{
  protected get suffix(): string {
    return 'Order';
  }

  private _webSocketRegistryService: WebSocketRegistryService;
  private _tradeLockService: TradeLockService;
  isTradingGloballyEnabled: boolean;

  onInit() {
    super.onInit();
    this._webSocketRegistryService = this._injector.get(
      WebSocketRegistryService,
    );
    this._tradeLockService = this._injector.get(TradeLockService);
    this._connectionContainer = this._injector.get(ConnectionContainer);
    this._tradeLockService.$isTradingGloballyEnabled.subscribe(
      (isTradingGloballyEnabled: boolean): void => {
        this.isTradingGloballyEnabled = isTradingGloballyEnabled;
      },
    );
  }

  protected _mapItemsParams(params: any = {}) {
    const _params = { ...super._mapItemsParams(params) };

    if (_params.accountId) {
      _params.id = _params.accountId;
      delete _params.accountId;
    }

    if (_params.StartDate == null)
      _params.StartDate = new Date(0).toUTCString();
    if (_params.EndDate == null)
      _params.EndDate = new Date(Date.now()).toUTCString();

    return _params;
  }

  protected _responseToItems(res: any, params: any) {
    return res.result.filter((item: any) => this._filter(item, params));
  }

  /**
   * @todo Refactor in a way that it will be using WebSocket connection (Order PLant).
   *       At the moment of writing this comment, the endpoint used in the method below
   *       is used for the MarketWatch’s Order Play/Stop function which isn’t working
   *       correctly, and has been already hidden in the UI.
   */
  play(order: IOrder): Observable<IOrder> {
    return this._http
      .post<IOrder>(this._getRESTURL(`${order.id}/play`), null, {
        ...this.getApiHeadersByAccount(order.accountId ?? order.account?.id),
      })
      .pipe(
        map((item: any) => this._mapResponseItem(item.result)),
        tap((item) => this._onCreate(item)),
      ) as Observable<IOrder>;
  }

  /**
   * @todo Refactor in a way that it will be using WebSocket connection (Order PLant).
   *       At the moment of writing this comment, the endpoint used in method below is
   *       used for the MarketWatch’s Order Play/Stop function which isn’t working
   *       correctly, and has been already hidden in the UI.
   */
  stop(order: IOrder): Observable<IOrder> {
    return this._http
      .post<IOrder>(this._getRESTURL(`${order.id}/stop`), null, {
        ...this.getApiHeadersByAccount(order.accountId ?? order.account?.id),
      })
      .pipe(
        map((item: any) => {
          const order = item.result;
          order.status = OrderStatus.Stopped;

          return this._mapResponseItem(order);
        }),
        tap((item) => this._onUpdate(item)),
      ) as Observable<IOrder>;
  }

  /**
   * @deprecated Deprecated. Please use "fetchOrdersFromWebSockets" instead.
   * @see this.fetchOrdersFromWebSockets
   */
  getItems(params?: any): Observable<IPaginationResponse<IOrder>> {
    console.warn('Deprecated. Please use "fetchOrdersFromWebSockets" instead.');

    if (!params.accounts && params.id && !params.hideStopped) {
      return forkJoin([
        super.getItems(params),
        this.getStoppedItems(params),
      ]).pipe(
        map((item) => {
          const [ordersResponse, stoppedOrdersResponse] = item;
          return {
            requestParams: ordersResponse.requestParams,
            data: [
              ...ordersResponse.data,
              ...stoppedOrdersResponse.data.map((item) => {
                item.status = OrderStatus.Stopped;
                return item;
              }),
            ],
          } as IPaginationResponse;
        }),
      );
    }

    return super.getItems(params);
  }

  fetchOrdersFromWebSockets(
    accounts: OrderAccount[],
    userMsg?: string[],
  ): void {
    if (!accounts) {
      return;
    }

    accounts.forEach(({ id, fcmId, ibId }: OrderAccount): void => {
      const connection: IConnection = this.getConnectionByAccount(id);
      const orderPlantWebSocket: RProtocolConnectionWebSocketService = <
        RProtocolConnectionWebSocketService
      >this._webSocketRegistryService.getRProtocolOrderPlant(connection);

      orderPlantWebSocket.requestShowOrders(id, fcmId, ibId, userMsg);
    });
  }

  cancelOrder(accounts: OrderAccount[], orders: IOrder[]): void {
    if (!accounts) {
      return;
    }

    accounts.forEach((account: OrderAccount): void => {
      const orderPlantWebSocket: RProtocolConnectionWebSocketService = <
        RProtocolConnectionWebSocketService
      >this._webSocketRegistryService.getRProtocolOrderPlant(
        this.getConnectionByAccount(account.id),
      );

      orders.forEach((order: IOrder): void => {
        if (account.id !== order.accountId) {
          return;
        }

        orderPlantWebSocket.requestCancelOrder(
          order,
          this._tradeLockService.$isTradingGloballyEnabled.getValue(),
        );
      });
    });
  }

  fetchShowOrderHistoryFromWebSockets(accounts: any[], basketId: string): void {
    if (!accounts) {
      return;
    }

    accounts.forEach(({ id, fcmId, ibId }: OrderAccount): void => {
      const connection: IConnection = this.getConnectionByAccount(id);
      const orderPlantWebSocket: RProtocolConnectionWebSocketService = <
        RProtocolConnectionWebSocketService
      >this._webSocketRegistryService.getRProtocolOrderPlant(connection);

      const requestId: string = `RequestShowOrderHistory: ${Date.now()}`;

      orderPlantWebSocket.requestShowOrderHistory({
        id,
        fcmId,
        ibId,
        basketId,
        userMsg: [requestId],
      });
    });
  }

  /**
   * @todo Refactor in a way that it will be using WebSocket connection (Order PLant).
   *       At the moment of writing this comment, the endpoint used in method below is
   *       used for the MarketWatch’s Order Play/Stop function which isn’t working
   *       correctly, and has been already hidden in the UI.
   */
  getStoppedItems(params): Observable<any> {
    return this._http
      .get<any>(this._getRESTURL(`${params.id}/stopped`), {
        ...params,
      })
      .pipe(map((item: any) => ({ ...item, data: item.result })));
  }

  /**
   * @todo Refactor in a way that it will be using WebSocket connection (Order PLant).
   *       At the moment of writing this comment, the endpoint used in method below is
   *       used for the MarketWatch’s Order Play/Stop function which isn’t working
   *       correctly, and has been already hidden in the UI.
   */
  deleteItem(item: IOrder | Id): Observable<any> {
    if (typeof item !== 'object') throw new Error('Invalid order');

    const accountId = item?.accountId ?? item?.account?.id;

    if (item.status === OrderStatus.Stopped)
      return this._http
        .delete<IOrder>(this._getRESTURL(`${item.id}/delete`), {
          ...this.getApiHeadersByAccount(accountId),
          params: {
            AccountId: accountId,
          } as any,
        })
        .pipe(
          map((res: any) => {
            const order = res.result;
            order.status = OrderStatus.Canceled;
            return order;
          }),
        );

    return this._http
      .post<IOrder>(this._getRESTURL(`${item.id}/cancel`), null, {
        ...this.getApiHeadersByAccount(accountId),
        params: {
          AccountId: accountId,
        } as any,
      })
      .pipe(map((res: any) => res.result));
  }

  /**
   * @deprecated Deprecated. Please use RProtocolConnectionWebSocketService.requestModifyOrder instead.
   * @see RProtocolConnectionWebSocketService.requestModifyOrder
   */
  updateItem(item: IOrder, query?: any): Observable<IOrder> {
    console.warn(
      'Deprecated. Please use RProtocolConnectionWebSocketService.requestModifyOrder instead',
    );

    const dto: IUpdateOrderRequestParams = {
      ...item,
      orderId: item.id,
      accountId: item.account?.id,
      symbol: item.instrument.symbol,
      exchange: item.instrument.exchange,
    };

    return this._http
      .put<{ result: IOrder }>(this._getRESTURL(), dto, {
        ...this.getApiHeadersByAccount(item.accountId ?? dto.accountId),
        params: query,
      })
      .pipe(
        map((response: any) => this._mapResponseItem(response.result)),
        tap(this._onUpdate),
      );
  }

  /**
   * @deprecated Deprecated. Please use "RProtocolConnectionWebSocketService.requestNewOrder" instead.
   * @see RProtocolConnectionWebSocketService.requestNewOrder
   */
  createItem(item: ExcludeId<IOrder>, options?: any): Observable<IOrder> {
    console.warn(
      'Deprecated. Please use RProtocolConnectionWebSocketService.requestNewOrder instead',
    );

    if (this.isTradingGloballyEnabled) {
      return super.createItem(item, {
        ...options,
        ...this.getApiHeadersByAccount(item.accountId),
      }) as Observable<IOrder>;
    }

    return throwError("You can't create order when trading is locked ");
  }

  /**
   * @deprecated Deprecated. Please use "cancelOrder" method (web sockets).
   * @see this.cancelOrder
   */
  deleteMany(orders: IOrder[]): Observable<any> {
    console.warn('Deprecated. Please use "cancelOrder" method.');

    if (!Array.isArray(orders))
      return throwError('Please provide array of orders');

    orders = orders.filter(
      (i) =>
        i.status == OrderStatus.Pending ||
        i.status == OrderStatus.New ||
        i.status == OrderStatus.PartialFilled,
    );

    if (!orders.length) return of(null);

    const map = new Map();

    for (const order of orders) {
      const key = this.getApiKey(order);

      if (!map.has(key)) {
        map.set(key, [order]);
      } else {
        map.get(key).push(order);
      }
    }

    return forkJoin(
      Array.from(map.keys()).map((key: any) =>
        this._http.post(this._getRESTURL(`cancel`), null, {
          headers: {
            ...this.getApiHeaders(key),
          },
          params: {
            orderIds: map.get(key).map((item) => item.id),
            accountId: map.get(key)[0].account.id,
          },
        }),
      ),
    );
  }

  _mapResponseItem(item: IOrder) {
    item.description = item.instrument.description ?? '';

    return item;
  }

  protected _filter(item: IOrder, params: any = {}) {
    const { instrument } = params;

    const symbol =
      item.instrument.tradingSymbol == null
        ? item.instrument.symbol
        : item.instrument.symbol;

    if (instrument) {
      return instrument.symbol === symbol;
    }

    return true;
  }
}
