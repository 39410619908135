import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  IWebSocketAllStats,
  IWebSocketStats,
  RITHMIC_INFRA_TYPE,
} from 'communication';
import { RProtocolMessageTemplateNameEnum } from 'projects/communication/src/services/rprotocol/rprotocol-message-template-name-enum';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { DataLevelShort } from 'trading';
import {
  clearInterval as clearIntervalWorker,
  setInterval as setIntervalWorker,
} from 'worker-timers';

interface InstrumentStats {
  symbol: string;
  messageTypes: { [messageType: string]: number };
  totalCount: number;
  panelExpanded: boolean;
}

interface MessageTypeHeader {
  messageType: string;
  dataLevel: DataLevelShort;
}

@Component({
  selector: 'app-stats',
  templateUrl: './app-stats.component.html',
  styleUrls: ['./app-stats.component.scss'],
})
export class AppStatsComponent implements OnInit, OnDestroy {
  private intervalId: number;
  instrumentStats: InstrumentStats[] = [];
  allMessageTypes: Map<string, MessageTypeHeader> = new Map();
  dataLevels: {
    [messageType in RProtocolMessageTemplateNameEnum]?: DataLevelShort;
  } = {
    [RProtocolMessageTemplateNameEnum.BestBidOffer]: DataLevelShort.Level1,
    [RProtocolMessageTemplateNameEnum.OrderBook]: DataLevelShort.Level2,
    [RProtocolMessageTemplateNameEnum.LastTrade]: DataLevelShort.Level0,
  };

  constructor(private _webSocketRegistryService: WebSocketRegistryService) {}

  ngOnInit(): void {
    this.intervalId = setIntervalWorker(() => {
      this.updateStats();
    }, 1000);
  }

  ngOnDestroy(): void {
    clearIntervalWorker(this.intervalId);
  }

  private updateStats(): void {
    const allStats: IWebSocketAllStats =
      this._webSocketRegistryService.getWebSocketsStats();
    const tempStats: { [symbol: string]: InstrumentStats } =
      this.processWebSocketStats(allStats);
    this.sortAndSetInstrumentStats(tempStats);
    this.updateAllMessageTypes();
  }

  private processWebSocketStats(allStats: IWebSocketAllStats): {
    [symbol: string]: InstrumentStats;
  } {
    const tempStats: { [symbol: string]: InstrumentStats } = {};

    const tickerPlantStats: IWebSocketStats[] = allStats.webSocketStats.filter(
      (item: IWebSocketStats) =>
        item.infraType === RITHMIC_INFRA_TYPE.TICKER_PLANT,
    );

    tickerPlantStats.forEach((item: IWebSocketStats) => {
      this.processStatsItem(item, tempStats);
    });

    return tempStats;
  }

  private processStatsItem(
    item: IWebSocketStats,
    tempStats: { [symbol: string]: InstrumentStats },
  ): void {
    Object.keys(item.counters).forEach(
      (messageType: RProtocolMessageTemplateNameEnum) => {
        Object.keys(item.counters[messageType]).forEach((symbol: string) => {
          this.updateInstrumentStats(
            tempStats,
            symbol,
            messageType,
            item.counters[messageType][symbol],
          );
        });
      },
    );
  }

  private updateInstrumentStats(
    tempStats: { [symbol: string]: InstrumentStats },
    symbol: string,
    messageType: string,
    count: number,
  ): void {
    if (!tempStats[symbol]) {
      tempStats[symbol] = {
        symbol,
        messageTypes: {},
        totalCount: 0,
        panelExpanded: false,
      };
    }

    if (!tempStats[symbol].messageTypes[messageType]) {
      tempStats[symbol].messageTypes[messageType] = 0;
    }

    tempStats[symbol].messageTypes[messageType] += count;
    tempStats[symbol].totalCount += count;
  }

  private sortAndSetInstrumentStats(tempStats: {
    [symbol: string]: InstrumentStats;
  }): void {
    this.instrumentStats = Object.values(tempStats).sort(
      (a: InstrumentStats, b: InstrumentStats): number =>
        b.totalCount - a.totalCount,
    );
  }

  private updateAllMessageTypes(): void {
    this.allMessageTypes.clear();
    this.instrumentStats.forEach((instrument: InstrumentStats): void => {
      Object.keys(instrument.messageTypes).forEach(
        (messageType: RProtocolMessageTemplateNameEnum): void => {
          this.allMessageTypes.set(messageType, {
            messageType,
            dataLevel: this.dataLevels[messageType],
          });
        },
      );
    });
  }

  getMessageTypeCount(
    instrument: InstrumentStats,
    messageType: string,
  ): number {
    return instrument.messageTypes[messageType] || 0;
  }

  sortedMessageTypes(): MessageTypeHeader[] {
    return Array.from(this.allMessageTypes.values()).sort(
      (a: MessageTypeHeader, b: MessageTypeHeader): number => {
        if (a.dataLevel === b.dataLevel) {
          return a.messageType.localeCompare(b.messageType);
        }
        return a.dataLevel.localeCompare(b.dataLevel);
      },
    );
  }
}
