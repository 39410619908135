import { IEnvironment } from '../app/app.config';
import { environment as baseEnvironment } from './environment.base';

export const environment: Partial<IEnvironment> = {
  ...baseEnvironment,
  appEnv: 'dv2',
  production: false,
  isDev: true,
  config: 'config/config.dv2.json',
  rProtocolSimulator: 'wss://rprotocol-gateway-simulator-dv3.tradrr.dev:50055/', // points to the simulator running on dv3 for now
  sentry: {
    ...baseEnvironment.sentry,
    tracePropagationTargets: [
      'dv2.tradrr.dev',
      'userdata-dv2.tradrr.dev',
      'datafeed-dv2.tradrr.dev',
      'zonedecoder-dv2.tradrr.dev',
      'auth-dv2.tradrr.dev',
      'notification-dv2.tradrr.dev',
    ],
  },
  isPerformanceInfoEnabled: true,
  isBrowserThrottlingDetectorEnabled: true,
  isUserIdleDetectionEnabled: true,
  isUserNetworkLatencyMeasuringEnabled: true,
};
