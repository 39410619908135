import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, take } from 'rxjs/operators';

import { SettingsData, SettingsService } from 'settings';

@Injectable({
  providedIn: 'root',
})
export class TradeLockService {
  $isTradingGloballyEnabled: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );

  constructor(private _settingsService: SettingsService) {
    this._settingsService.settings
      .pipe(
        skip(1), // Skip is needed to ignore the default value.
        take(1),
      )
      .subscribe((settings: SettingsData): void => {
        this.$isTradingGloballyEnabled.next(settings.tradingEnabled);
      });
  }
}
