import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { ExcludeId } from 'communication';
import { IOrder } from 'trading';

import { RealOrdersRepository } from './real-orders.repository';

@Injectable()
export class RealOrdersV2Repository extends RealOrdersRepository {
  protected get suffix(): string {
    return 'Order';
  }

  createItem(item: ExcludeId<IOrder>, options?: any): Observable<IOrder> {
    if (this.isTradingGloballyEnabled) {
      if (!options) {
        options = {};
      }
      // Overrides the API endpoint suffix, so that this particular request goes to the v2 endpoint
      options.overrideSuffix = 'v2/Order';
      return super.createItem(item, {
        ...options,
        ...this.getApiHeadersByAccount(item.accountId),
      }) as Observable<IOrder>;
    }

    return throwError("You can't create order when trading is locked ");
  }
}
