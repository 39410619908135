import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContextItem, TradingContextService } from './trading-context.service';

@Directive()
export abstract class ContextAwareComponent implements OnInit, OnDestroy {
  protected context: ContextItem = {};
  private contextSubscription: Subscription;

  constructor(protected tradingContextService: TradingContextService) {}

  ngOnInit() {
    this.contextSubscription = this.tradingContextService
      .getContextObservable()
      .subscribe((context: ContextItem) => (this.context = context));
  }

  ngOnDestroy() {
    this.contextSubscription.unsubscribe();
    this.tradingContextService.popContext();
  }
}
