import { Injectable } from '@angular/core';

import { Id, WEB_SOCKET_TYPE } from 'communication';
import { IFeedRelayConfig } from 'projects/real-trading/src/trading/repositories/feed-relay.service';

// TODO: remove optional
export type OnUpdateFn<T> = (item: T, connectionId?: Id) => void;
export type UnsubscribeFn = () => void;

@Injectable()
export abstract class Feed<T> {
  get webSocketType(): WEB_SOCKET_TYPE {
    return WEB_SOCKET_TYPE.RAPI;
  }
  abstract on(fn: OnUpdateFn<T>): UnsubscribeFn;
  get useFeedRelay(): boolean {
    return false;
  }
  get provideFeedRelayConfig(): IFeedRelayConfig {
    return {
      flushBufferInterval: null,
    };
  }
}
