import { NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppHealthLogger } from '../app-health-logs/app-health-logger';

type IdleDetector = any;
declare const IdleDetector: any;

/**
 * @description This class is responsible for detecting user idle time and providing
 * a stream of idle state updates.
 */
export class UserIdleDetector {
  private readonly DEFAULT_IDLE_TIME: number = 60; // 1 minute
  private idleTime: number;
  private idleState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  private timer$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  protected idleDetector: IdleDetector | null = null;
  private idleStartTime: number | null = null;
  private animationFrameId: number | null = null;
  private controller: AbortController;

  constructor(
    private ngZone: NgZone,
    private logger: AppHealthLogger,
  ) {
    this.idleTime = this.DEFAULT_IDLE_TIME;
  }

  start(): void {
    // @todo Is there a way to start Idle Detection automatically, without requiring a user gesture?
    // noop
  }

  public async startWatching(): Promise<void> {
    if (!('IdleDetector' in window)) {
      this.logger?.error('Idle Detection API is not supported in this browser');
      return;
    }

    try {
      // @todo Is there a way to start Idle Detection automatically, without requiring a user gesture?
      // const state = await IdleDetector.requestPermission();
      // if (state !== 'granted') {
      //   this.logger?.error('Idle detection permission denied');
      //   return;
      // }

      this.controller = new AbortController();
      this.idleDetector = new IdleDetector();
      this.idleDetector.addEventListener('change', (): void => {
        this.ngZone.run((): void => {
          const isIdle: boolean =
            this.idleDetector!.userState === 'idle' ||
            this.idleDetector!.screenState === 'locked';

          this.idleState$.next(isIdle);
          if (isIdle) {
            this.logger?.info('User is idle');
            this.startIdleTimer();
          } else {
            if (this.timer$.getValue() > 0) {
              this.logger?.info(
                `User is active after idling for ${this.timer$.getValue()} seconds`,
              );
            } else {
              this.logger?.info('User is active');
            }
            this.stopIdleTimer();
          }
        });
      });

      await this.idleDetector.start({
        threshold: this.idleTime * 1000, // Convert seconds to milliseconds
        signal: this.controller.signal,
      });

      this.logger?.info('Idle detector started');
    } catch (err) {
      this.logger?.error('Error starting idle detector:', err);
    }
  }

  public async requestPermissionAndStartWatching(): Promise<void> {
    if (!('IdleDetector' in window)) {
      this.logger?.error('Idle Detection API is not supported in this browser');
      return;
    }

    try {
      const state: string = await IdleDetector.requestPermission();
      if (state !== 'granted') {
        this.logger?.error('Idle detection permission denied');
        return;
      }

      await this.startWatching();
    } catch (err) {
      this.logger?.error('Error requesting idle detection permission:', err);
    }
  }

  private startIdleTimer(): void {
    this.idleStartTime = performance.now();
    this.updateIdleTimer();
  }

  isWatching(): boolean {
    return this.idleDetector !== null;
  }

  private updateIdleTimer(): void {
    if (this.idleStartTime !== null) {
      const idleDuration: number =
        (performance.now() - this.idleStartTime) / 1000;
      this.timer$.next(Math.floor(idleDuration));
      this.animationFrameId = requestAnimationFrame((): void =>
        this.updateIdleTimer(),
      );
    }
  }

  private stopIdleTimer(): void {
    this.idleStartTime = null;
    this.timer$.next(0);
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  public getIdleState(): Observable<boolean> {
    return this.idleState$.asObservable();
  }

  public getIdleStateValue(): boolean {
    return this.idleState$.getValue();
  }

  public getIdleTimer(): Observable<number> {
    return this.timer$.asObservable();
  }

  public getIdleTime(): number {
    return this.timer$.getValue();
  }

  public resetTimer(): void {
    this.timer$.next(0);
    this.idleState$.next(false);
  }

  public stopWatching(): void {
    if (this.idleDetector) {
      this.controller.abort();
      this.idleDetector = null;
    }
    this.stopIdleTimer();
  }
}
