import { Injectable } from '@angular/core';

import { IBar } from 'chart';
import { IBaseItem, Repository } from 'communication';

export interface IHistoryItem extends IBaseItem, IBar {
  netChange?: number;
  percentChange?: number;
}

@Injectable()
export abstract class HistoryRepository extends Repository<IHistoryItem> {}
