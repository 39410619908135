import { Id } from '../common/item';

export interface IWebSocketAllStats {
  webSocketStats: IWebSocketStats[];
  busiestWebSocketStats: IWebSocketStats;
}

export interface IWebSocketStats {
  messages: number;
  events: number;
  startTime: Date;
  maxTime: number;
  minTime: number;
  time: {
    [key: string]: number;
  };
  typology: {
    [key: string]: number;
  };
  counters: {
    [key: string]: {
      [key: string]: number;
    };
  };
  upTime: string;
  messagesPerSecBuffer: number;
  messagesPerSec: number;
  peakMessagesPerSec: number;
  averageMessages: string;
  averageMessagesValue: number;
  averageEvents: string;
  eventsInMessages: string;
  instanceType: string;
  infraType: string;
}

export interface IWebSocketConfig {
  url: string;
  protocols?: string;
}

export enum ConnectionId {
  TradingSystem = 'TradingSystem',
  PnL = 'PnL',
  History = 'History',
  MarketData = 'MarketData',
}

export enum AlertType {
  Undefined = 'Undefined',
  ConnectionOpened = 'ConnectionOpened',
  ConnectionClosed = 'ConnectionClosed',
  ConnectionBroken = 'ConnectionBroken',
  LoginComplete = 'LoginComplete',
  LoginFailed = 'LoginFailed',
  ServiceError = 'ServiceError',
  ForcedLogout = 'ForcedLogout',
  QuietHeartbeat = 'QuietHeartbeat',
  TradingDisabled = 'TradingDisabled',
  TradingEnabled = 'TradingEnabled',
  ShutdownSignal = 'ShutdownSignal',
}

export enum ConnectionHealthIndicator {
  Unhealthy = 0,
  Degraded = 1,
  Healthy = 2,
}

export enum ConnectionStatus {
  Broken = 'Connection broken',
  Degrading = 'Connection degrading',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Disconnecting = 'Disconnecting',
}

export enum WSEventType {
  Open = 'open',
  Close = 'close',
  Error = 'error',
  Message = 'message',
}

export type IWSListener = (event: Event, connectionId: Id) => void;

export type IWSListeners = {
  [key in WSEventType]: Set<IWSListener>;
};

export type IWSEventListeners = {
  [key in WSEventType]: IWSListener;
};

export type IWSListenerUnsubscribe = () => void;

export enum WEB_SOCKET_TYPE {
  RAPI = 'RAPI',
  RPROTOCOL = 'RPROTOCOL',
}

/**
 * Rithmic infrastructure types.
 * @see R API+.NET - Programmers' Guide - Connections: Connect Points
 */
export enum RITHMIC_INFRA_TYPE {
  /**
   * Tradrr connection (Order Plant + PnL plant + History plant via R API)
   * @description Tradrr connection; provides PnL updates, Order updates, and handles History data requests
   */
  TRADRR_PLANT = 'TRADRR_PLANT',
  /**
   * Ticker plant
   * @description Market data connection; provides real-time market data services.
   * @see R API+.NET - Programmers' Guide - Connections: Connect Points
   * @see R Protocol API 1.2 Templates Specific to Market Data Infrastructure.
   */
  TICKER_PLANT = 'TICKER_PLANT',
  /**
   * Order plant
   * @description Trading system connect point; provides order handling services.
   * @see R API+.NET - Programmers' Guide - Connections: Connect Points
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   */
  ORDER_PLANT = 'ORDER_PLANT',
}
