import { Context } from "./context";
import { KeyBinding } from "./key-binding";
import { KeyBindingPart } from "./key-binding-part";
import { SubscriptionManager } from "./subscriptions-manager";

export class KeyboardListener extends KeyBinding {

    protected _onChangedCallback: Function = () => {};

    constructor() {
        super();
    }

    handle(event: KeyboardEvent) {
        switch (event.type) {
            case "keydown": {
                this.onKeyDown(event);
                break;
            }
            case "keyup": {
                this.onKeyUp(event);
                break;
            }
            default:
                throw new Error('Invalid event type');
        }
    }

    push(part: KeyBindingPart) {
        super.push(part);
        this._onChangedCallback();
    }

    snapshot() {
        return this.clone();
    }

    onChanged(callback: Function) {
        this._onChangedCallback = callback;
    }

    protected onKeyDown(event: KeyboardEvent) {
        this.push(KeyBindingPart.fromKeyboardEvent(event));
    }

    protected onKeyUp(event: KeyboardEvent) {
        this.remove(KeyBindingPart.fromKeyboardEvent(event));
    }


}