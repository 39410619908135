import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ContextItemField {
  Workspace = 'workspace',
  Window = 'window',
  TradingTool = 'tradingTool',
  Entry = 'entry',
  Origination = 'origination',
}

export type ContextItem = {
  [key in ContextItemField]?: string;
};

@Injectable()
export class TradingContextService {
  public static instances: TradingContextService[] = [];
  public static printAllContexts() {
    TradingContextService.instances.forEach((instance, index) => {
      console.log(index, 'Context Stack:');
      instance.getFullContextStack().forEach((context) => console.log(context));
    });
  }
  private contextStack: ContextItem[] = [];
  private contextSubject = new BehaviorSubject<ContextItem[]>([]);

  constructor() {}

  pushContext(context: ContextItem): void {
    const newContext = { ...this.getCurrentContext(), ...context };
    this.contextStack.push(newContext);
    this.contextSubject.next(this.contextStack);
  }

  popContext(): void {
    if (this.contextStack.length > 0) {
      this.contextStack.pop();
      this.contextSubject.next(this.contextStack);
    }
  }

  getCurrentContext(): ContextItem {
    return this.contextStack.length > 0
      ? this.contextStack[this.contextStack.length - 1]
      : ({} as ContextItem);
  }

  getContextObservable(): Observable<ContextItem> {
    return this.contextSubject
      .asObservable()
      .pipe(map((stack) => (stack.length > 0 ? stack[stack.length - 1] : {})));
  }

  getFullContextStack(): ContextItem[] {
    return [...this.contextStack];
  }

  createChildContext(newContextData?: ContextItem): TradingContextService {
    const childService: TradingContextService = new TradingContextService();
    childService.contextStack = [...this.contextStack];
    childService.contextSubject.next(childService.contextStack);
    if (newContextData) {
      childService.pushContext(newContextData);
    }
    return childService;
  }

  getCurrentContextAsString(): string {
    const fields: ContextItemField[] = [
      ContextItemField.Workspace,
      ContextItemField.Window,
      ContextItemField.TradingTool,
      ContextItemField.Entry,
      ContextItemField.Origination,
    ];
    const currentContext: ContextItem = this.getCurrentContext();
    const result: string[] = [];
    fields.forEach((field: string) => {
      if (currentContext[field]) {
        result.push(currentContext[field]);
      }
    });

    return result.join(',');
  }
}
