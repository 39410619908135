<span *ngIf="_connect || items.length" class="number-label">
  <nz-select
    [class]="className"
    [nzDropdownClassName]="nzDropdownClassName"
    [nzPlaceHolder]="placeholder"
    [nzOptionHeightPx]="23"
    [nzOptionOverflowSize]="10"
    [compareWith]="compareAccounts"
    [(nzOpen)]="isOpened"
    [(ngModel)]="account"
    [nzCustomTemplate]="accountFrameTemplate"
    [nzDropdownMatchSelectWidth]="false"
  >
    <ng-template #accountFrameTemplate let-selected>
      {{ labelTransformer(selected.nzLabel) }}
    </ng-template>
    <nz-option
      *ngFor="let item of items"
      [nzLabel]="
        account?.id == item.id && isOpened ? item.id.toString() : item.id
      "
      [nzValue]="item"
    ></nz-option>
  </nz-select>
</span>
