export interface IndicatorGroup {
  name: IndicatorGroupNameEnum;
  indicators: Array<string | TradrrIndicatorEnum>;
  filteredIndicators?: string[];
  expanded?: boolean;
}

export enum IndicatorGroupNameEnum {
  Tradrr = 'Tradrr',
  TradrrDebug = 'Tradrr Debug',
  General = 'General',
  Bands = 'Bands',
  Index = 'Index',
  MovingAverage = 'Moving Average',
  Oscillator = 'Oscillator',
  Regression = 'Regression',
  Others = 'Others',
}

export enum TradrrIndicatorEnum {
  Footprint = 'Footprint',
  VolumeProfile = 'VolumeProfile',
  CompositeProfile = 'CompositeProfile',
  PriceStats = 'PriceStats',
  SessionStats = 'SessionStats',
  VolumeBreakdown = 'VolumeBreakdown',
  ZigZag = 'ZigZag',
  ZigZagOscillator = 'ZigZagOscillator',
  VWAP = 'VWAP',
  BarStats = 'BarStats',
  Volume = 'Volume',
}
