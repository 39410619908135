import { window } from 'rxjs/operators';

import moment from 'moment';

import { TradePrint } from '../../../projects/trading';

const MILLISECONDS_IN_SECOND = 1000;

export interface ILatencyMetrics {
  latency: number;
  averageLatency: number;
}

/**
 * Measures end-to-end latency from the moment a LastTrade was issued at the Exchange to the moment the LastTrade was rendered on
 * a canvas.
 */
export class TradeLatencyMonitor {
  /**
   * Skips the first `minWarmUpDrawCount` renderings to allow the target component to "warm up"
   */
  minWarmUpDrawCount: number = 50;
  private _lastMeasureTime: number;
  private _lastTradeDiffs: number[] = [];
  private _lastTradeDiff: number = 0;
  private _drawCount: number = 0;

  private _lastTrade: TradePrint | null = null;
  set lastTrade(value: TradePrint) {
    this._lastTrade = value;
  }

  constructor() {}

  increaseDrawCount() {
    this._drawCount++;
  }

  disableWarmUp(): void {
    this.minWarmUpDrawCount = 0;
  }

  reset(): void {
    this._lastMeasureTime = 0;
    this._drawCount = 0;
    this._lastTradeDiffs = [];
    this._lastTradeDiff = 0;
    this._lastTrade = null;
  }

  /**
   * Decides whether to take a sample or not
   * @returns {boolean} - true if a sample should be taken, false otherwise
   */
  shouldSample(): boolean {
    if (!this.shouldSampleBasedOnTime()) {
      return false;
    }
    if (!this._lastTrade?.timestamp) {
      return false;
    }
    return true;
  }

  shouldSampleBasedOnTime(): boolean {
    // Control the sampling rate by measuring one trade per second at most
    if (
      !this._lastMeasureTime ||
      this._lastMeasureTime + MILLISECONDS_IN_SECOND < Date.now()
    ) {
      if (!this.minWarmUpDrawCount) {
        return true;
      }

      // Waits for the DOM to "warm up" before starting to measure. Drawing the
      // first few trades is usually slower and adds noise to the metric.
      return this._drawCount >= this.minWarmUpDrawCount;
    }

    return false;
  }

  /**
   * Takes a sample of the trade latency measurement
   * @param lastTrade
   */
  takeSampledMeasurement(lastTrade: TradePrint): void {
    this.lastTrade = lastTrade;
    if (!this.shouldSample()) {
      return;
    }

    requestAnimationFrame(() => {
      this.measure();
      if ((window as any).debug) {
        this.displayInfo();
      }
    });
  }

  /**
   * Measures the end-to-end latency
   */
  measure(): void {
    const tradeDate = new Date(this._lastTrade.timestamp);
    const nowUtc = moment.utc().toDate();
    this._lastTradeDiff = nowUtc.getTime() - tradeDate.getTime();

    // Keeps track of the average difference over time
    if (this._lastTradeDiffs.length > 100) this._lastTradeDiffs.shift();
    this._lastTradeDiffs.push(this._lastTradeDiff);
    this._lastMeasureTime = Date.now();
  }

  collectLatencyMetrics(): ILatencyMetrics {
    const averageDiff: number =
      this._lastTradeDiffs.length === 0
        ? 0
        : this._lastTradeDiffs.reduce((a: number, b: number) => a + b, 0) /
          this._lastTradeDiffs.length;

    return {
      latency: this._lastTradeDiff,
      averageLatency: averageDiff,
    } as ILatencyMetrics;
  }

  /**
   * Displays trade latency information to the console
   */
  displayInfo(): void {
    const nowLocalTime: string = moment().format('hh:mm:ss');
    const nowUtc: Date = moment.utc().toDate();
    const tradeDate: Date = new Date(this._lastTrade.timestamp);
    const averageDiff: number =
      this._lastTradeDiffs.reduce((a: number, b: number) => a + b, 0) /
      this._lastTradeDiffs.length;
    const additionalDetails: any = {
      times: { tradeDate, nowUtc, nowLocalTime },
      lastTrade: this._lastTrade,
    };
    console.log(
      `[${nowLocalTime}] [DOM ${this._drawCount}] ` +
        `[Last Trade: ${this._lastTrade.side.padEnd(4, ' ')} ${this._lastTrade.volume.toString().padEnd(2, ' ')} x ${this._lastTrade.price.toString().padEnd(8, ' ')}]  ` +
        `[End-to-End Latency: ${this._lastTradeDiff} ms, Avg: ${averageDiff.toFixed(2)} ms]   ` +
        `More details:`,
      additionalDetails,
    );
  }

  destroy() {}
}
