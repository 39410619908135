import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { NotifierService } from 'notifier';

import { NotificationService } from './notification.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [NotificationService],
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        NotificationService,
        { provide: NotifierService, useExisting: NotificationService },
      ],
    };
  }
}
