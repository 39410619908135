import {
  IInstrument,
  InstrumentType,
} from 'projects/trading/src/trading/models/instrument';

const defaultInstrument1: IInstrument = {
  id: 449,
  symbol: 'ES',
  exchange: 'CME',
  tradingSymbol: 'ESZ4',
  tradingExchange: 'CME',
  stringTypeRepresentation: 'Future',
  productCode: 'ES',
  description: 'Front Month for ES - ESZ4.CME',
  increment: 0.25,
  tickSize: 0.25,
  precision: 2,
  contractSize: 50,
  type: InstrumentType.Future,
};

const defaultInstrument2: IInstrument = {
  id: 2804,
  symbol: 'MESZ4',
  exchange: 'CME',
  stringTypeRepresentation: 'Future',
  productCode: 'MES',
  description: 'Micro E-mini S&P 500',
  increment: 0.25,
  tickSize: 0.25,
  precision: 2,
  contractSize: 5,
  type: InstrumentType.Future,
};

export const defaultConfigData = {
  id: 0,
  name: 'Window',
  config: [
    {
      x: 1778,
      y: 36,
      id: '1699907937383_0.013807055828700232',
      order: 0,
      width: 225,
      height: 442,
      visible: true,
      minWidth: 225,
      component: {
        name: 'notification-list',
      },
      minHeight: 150,
      resizable: true,
      allowPopup: false,
      maximizable: false,
      minimizable: false,
    },
    {
      x: 0,
      y: 119,
      id: '1698770407984_0.7079912167002815',
      order: 1,
      width: 1873,
      height: 1042,
      visible: true,
      minWidth: 320,
      component: {
        name: 'chart',
        state: {
          link: 0.5337737957836111,
          settings: {
            general: {
              font: {
                fontSize: 10,
                textColor: '#fff',
                fontFamily: 'Arial',
              },
              gradient1: 'rgb(27, 29, 34)',
              gradient2: 'rgb(27, 29, 34)',
              gridColor: '#24262C',
              lineColor: '#fff',
              wickColor: '#fff',
              upCandleColor: '#0C62F7',
              dateScaleColor: 'rgb(27, 29, 34)',
              downCandleColor: '#C93B3B',
              valueScaleColor: 'rgb(27, 29, 34)',
              upCandleBorderColor: '#D0D0D2',
              downCandleBorderColor: '#D0D0D2',
              upCandleBorderColorEnabled: false,
              downCandleBorderColorEnabled: false,
            },
            session: {
              sessionEnabled: true,
              sessionTemplate: {
                id: 5,
                name: '24/7',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                workingTimes: [
                  {
                    endDay: 2,
                    endTime: 86100000,
                    startDay: 2,
                    startTime: 0,
                  },
                  {
                    endDay: 3,
                    endTime: 86100000,
                    startDay: 3,
                    startTime: 0,
                  },
                  {
                    endDay: 4,
                    endTime: 86100000,
                    startDay: 4,
                    startTime: 0,
                  },
                  {
                    endDay: 1,
                    endTime: 86100000,
                    startDay: 1,
                    startTime: 0,
                  },
                  {
                    endDay: 5,
                    endTime: 86100000,
                    startDay: 5,
                    startTime: 0,
                  },
                  {
                    endDay: 6,
                    endTime: 86100000,
                    startDay: 6,
                    startTime: 0,
                  },
                  {
                    endDay: 0,
                    endTime: 86100000,
                    startDay: 0,
                    startTime: 0,
                  },
                ],
              },
            },
            trading: {
              tif: {
                DAY: true,
                /**
                 * Property disabled until the feature is fully working.
                 * FOK: true,
                 */
                GTC: true,
                /**
                 * Property disabled until the feature is fully working.
                 * IOC: true,
                 */
                default: 'DAY',
              },
              trading: {
                showPLInfo: true,
                displayUnit: 'points',
                orderBarUnit: 'pixels',
                showOHLVInfo: false,
                bracketButton: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: true,
                tradingBarLength: 40,
                includeRealizedPL: true,
                showCancelConfirm: true,
                showWorkingOrders: true,
                negativePLFontColor: 'red',
                positivePLFontColor: 'white',
                showInstrumentChange: false,
                negativePLBackgroundColor: 'black',
                positivePLBackgroundColor: 'black',
              },
              orderArea: {
                formData: {
                  quantity: 10,
                  stopLoss: {
                    type: 'StopMarket',
                    stopLoss: false,
                  },
                  takeProfit: {
                    takeProfit: false,
                  },
                },
                settings: {
                  tif: {
                    DAY: true,
                    /**
                     * Property disabled until the feature is fully working.
                     * FOK: true,
                     */
                    GTC: true,
                    /**
                     * Property disabled until the feature is fully working.
                     * IOC: true,
                     */
                    default: 'DAY',
                  },
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  orderArea: {
                    showPLInfo: true,
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  formSettings: {
                    showPLInfo: true,
                    showBracket: true,
                    showBuyButton: true,
                    showSellButton: true,
                    showCancelButton: true,
                    showFlattenButton: true,
                    showIcebergButton: true,
                    closePositionButton: true,
                    showLiquidateButton: true,
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  icebergFontColor: '#fff',
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  buyButtonsFontColor: '#D0D0D2',
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  flatButtonsFontColor: '#D0D0D2',
                  sellButtonsFontColor: '#D0D0D2',
                  cancelButtonFontColor: '#D0D0D2',
                  closePositionFontColor: '#D0D0D2',
                  icebergBackgroundColor: '#51535A',
                  buyButtonsBackgroundColor: '#0C62F7',
                  flatButtonsBackgroundColor: '#51535A',
                  sellButtonsBackgroundColor: '#C93B3B',
                  cancelButtonBackgroundColor: '#51535A',
                  closePositionBackgroundColor: '#51535A',
                },
                amountButtons: [
                  {
                    value: 1,
                  },
                  {
                    black: true,
                    value: 1,
                  },
                  {
                    value: 3,
                  },
                  {
                    value: 5,
                  },
                  {
                    value: 10,
                  },
                  {
                    value: 25,
                  },
                ],
              },
              chartMarkers: {
                buyMarkerType: 'triangle',
                buyMarkerColor: 'blue',
                sellMarkerType: 'square',
                sellMarkerColor: 'red',
                buyMarkerFontWeight: 12,
                sellMarkerFontWeight: 12,
                dataBoxBackgroundColor: 'black',
                showBarMarkerTobBottom: true,
                showBarTextWithSizePrice: true,
                showBarMarketExecutionPrice: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
                orderBarUnit: 'pixels',
                orderBarLength: 100,
              },
            },
            valueScale: {
              valueScale: {
                isAutomatic: 'automatic',
                pixelsPrice: 8,
              },
            },
          },
          showOHLV: false,
          timeFrame: {
            interval: 5,
            periodicity: '',
          },
          instrument: defaultInstrument1,
          showChanges: false,
          periodToLoad: {
            disabled: false,
            interval: 5,
            periodicity: 'd',
          },
          periodOptions: [
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 5,
                  periodicity: 'd',
                },
              ],
              periodicity: 'd',
            },
            {
              active: false,
              period: 'Weeks',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 2,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'w',
                },
              ],
              periodicity: 'w',
            },
            {
              active: false,
              period: 'Months',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 6,
                  periodicity: 'm',
                },
              ],
              periodicity: 'm',
            },
          ],
          intervalOptions: [
            {
              active: false,
              period: 'AMS REVS Bar',
              timeFrames: [
                {
                  interval: 4,
                  periodicity: 'revs',
                },
                {
                  interval: 8,
                  periodicity: 'revs',
                },
                {
                  interval: 12,
                  periodicity: 'revs',
                },
                {
                  interval: 16,
                  periodicity: 'revs',
                },
              ],
              periodicities: ['revs'],
            },
            {
              active: false,
              period: 'Seconds',
              timeFrames: [
                {
                  interval: 30,
                  periodicity: 's',
                },
                {
                  interval: 40,
                  periodicity: 's',
                },
              ],
              periodicities: ['s'],
            },
            {
              active: false,
              period: 'Minutes',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: '',
                },
                {
                  interval: 3,
                  periodicity: '',
                },
                {
                  interval: 5,
                  periodicity: '',
                },
                {
                  interval: 15,
                  periodicity: '',
                },
                {
                  interval: 30,
                  periodicity: '',
                },
              ],
              periodicities: [''],
            },
            {
              active: false,
              period: 'Hours',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'h',
                },
                {
                  interval: 2,
                  periodicity: 'h',
                },
                {
                  interval: 3,
                  periodicity: 'h',
                },
                {
                  interval: 4,
                  periodicity: 'h',
                },
              ],
              periodicities: ['h'],
            },
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 1,
                  periodicity: 'm',
                },
              ],
              periodicities: ['d', 'm', 'w', 'y'],
            },
          ],
          stockChartXState: {
            chart: {
              scale: 2,
              theme: {
                name: 'Dark',
                plot: {
                  bar: {
                    HL: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    HLC: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    OHLC: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    kagi: {
                      upCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                          strokeEnabled: true,
                        },
                      },
                      downCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                          strokeEnabled: true,
                        },
                      },
                    },
                    renko: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                    },
                    candle: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredHL: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    lineBreak: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                          fillEnabled: true,
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                          fillEnabled: true,
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    equiVolume: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    heikinAshi: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredOHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#0C62F7',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#C93B3B',
                      },
                    },
                    candleVolume: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    hollowCandle: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      upHollowCandle: {
                        wick: {
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                      },
                      downHollowCandle: {
                        wick: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    pointAndFigure: {
                      upCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                          strokeEnabled: true,
                        },
                      },
                      downCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                          strokeEnabled: true,
                        },
                      },
                    },
                    equiVolumeShadow: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                  },
                  line: {
                    step: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    simple: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    mountain: {
                      fill: {
                        linearGradient: [
                          'rgba(63, 167, 225, 0.4) ',
                          'rgba(63, 167, 225, 0)',
                        ],
                      },
                      line: {
                        width: 1,
                        strokeColor: '#fff',
                      },
                    },
                  },
                  point: {
                    dot: {
                      fill: {
                        fillColor: 'white',
                      },
                    },
                  },
                  histogram: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    column: {
                      fill: {
                        fillColor: 'blue',
                      },
                      line: {
                        width: 1,
                        strokeColor: 'white',
                        strokeEnabled: false,
                      },
                    },
                    coloredLine: {
                      upBar: {
                        width: 1,
                        strokeColor: 'grey',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                    coloredColumn: {
                      upBar: {
                        fill: {
                          fillColor: 'green',
                        },
                        line: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                      downBar: {
                        fill: {
                          fillColor: 'red',
                        },
                        line: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                    },
                  },
                  indicator: {
                    line1: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'white',
                    },
                    line2: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#3FA7E1',
                    },
                    line3: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#E85050',
                    },
                    line4: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0000FF',
                    },
                    line5: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'yellow',
                    },
                    levels: {
                      line1: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FF0000',
                      },
                      line2: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#32CD32',
                      },
                      line3: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFD500',
                      },
                      line4: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0000FF',
                      },
                      line5: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#808080',
                      },
                    },
                    blackText: {
                      fontSize: 10,
                      fillColor: 'black',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    histogram: {
                      color: '#32CD32',
                    },
                  },
                },
                chart: {
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'grey',
                  },
                  background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
                  instrumentWatermark: {
                    symbol: {
                      fontSize: 70,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    details: {
                      fontSize: 40,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                  },
                },
                button: {
                  none: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                  accept: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'green',
                    },
                  },
                  cancel: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'red',
                    },
                  },
                },
                spread: {
                  ask: {
                    line: {
                      width: 1,
                      strokeColor: '#00D533',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#00D533',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        fontFamily: 'Calibri',
                      },
                    },
                  },
                  bid: {
                    line: {
                      width: 1,
                      strokeColor: '#F20500',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#F20500',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        fontFamily: 'Calibri',
                      },
                    },
                  },
                },
                zoomIn: {
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'white',
                  },
                },
                drawing: {
                  note: {
                    fill: {
                      fillColor: 'orange',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                    centerPointFill: {
                      fillColor: 'rgb(68, 68, 68)',
                    },
                  },
                  text: {
                    text: {
                      fontSize: 12,
                      fillColor: '#FFFFFF',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                  },
                  image: {
                    noImage: {
                      line: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                  },
                  arrowUp: {
                    fill: {
                      fillColor: 'limegreen',
                    },
                  },
                  measure: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.5)',
                      fillEnabled: true,
                    },
                    line: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    border: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    balloon: {
                      fill: {
                        fillColor: 'rgba(255, 255, 255, 0.5)',
                        fillEnabled: true,
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontFamily: 'Calibri',
                      },
                      border: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: 'darkgray',
                        strokeEnabled: true,
                      },
                    },
                  },
                  abstract: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                  },
                  arrowDown: {
                    fill: {
                      fillColor: 'red',
                    },
                  },
                  fibonacci: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                    trendLine: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                    },
                  },
                  trendAngle: {
                    arc: {
                      width: 1,
                      lineStyle: 'dot',
                      strokeColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                    horizontalLine: {
                      width: 1,
                      lineStyle: 'dot',
                      strokeColor: 'white',
                    },
                  },
                  measureTool: {
                    fill: {
                      fillColor: 'rgba(0, 0, 0, 0.8)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                  },
                  abstractMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                tooltip: {
                  fill: {
                    color: 'rgb(44, 44, 44)',
                    enabled: true,
                  },
                  text: {
                    fontSize: 12,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                    fontWeight: 'normal',
                  },
                  border: {
                    color: 'orange',
                    width: 1,
                    enabled: true,
                    lineStyle: 'solid',
                  },
                },
                splitter: {
                  fillColor: '#292C38',
                  hoverFillColor: 'white',
                },
                crossHair: {
                  fill: {
                    fillColor: '#FFFFFF',
                  },
                  line: {
                    width: 1,
                    lineStyle: 'dash',
                    strokeColor: 'darkgray',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: '#000000',
                    fontFamily: 'Calibri',
                  },
                },
                dateScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  text: {
                    fontSize: 10,
                    fillColor: '#fff',
                    fontFamily: 'Arial',
                  },
                  border: {
                    width: 0,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                  },
                  breakLine: {
                    stroke: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: '#545454',
                    },
                  },
                  dateMarker: {
                    fill: {
                      fillColor: 'green',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: '#FFFFFF',
                      fontFamily: 'Calibri',
                    },
                    stroke: {
                      width: 1,
                      lineCap: 'butt',
                      lineJoin: 'miter',
                      lineStyle: 'solid',
                      strokeColor: '#696969',
                      strokePriority: 'color',
                    },
                  },
                },
                chartPanel: {
                  grid: {
                    width: 1,
                    strokeColor: '#24262C',
                  },
                  title: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    fontFamily: 'Calibri',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontSize: 70,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    details: {
                      fontSize: 40,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                  },
                },
                valueScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  text: {
                    fontSize: 10,
                    fillColor: '#fff',
                    fontStyle: 'normal',
                    fontFamily: 'Arial',
                  },
                  border: {
                    width: 0,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                  },
                  valueMarker: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                  },
                },
                chartMarkers: {
                  buyMarkerType: 'triangle',
                  buyMarkerColor: 'blue',
                  sellMarkerType: 'square',
                  sellMarkerColor: 'red',
                  buyMarkerFontWeight: 1,
                  sellMarkerFontWeight: 1,
                  dataBoxBackgroundColor: 'black',
                  showBarMarkerTobBottom: true,
                  showBarTextWithSizePrice: true,
                  showBarMarketExecutionPrice: true,
                },
                tradingPanel: {
                  showPLInfo: true,
                  displayUnit: 'points',
                  tradingBarUnit: 'pixels',
                  markerFontColor: '#fff',
                  tradingBarLength: 40,
                  includeRealizedPL: true,
                  buyPanelBackground: 'rgba(12,98,247, 0.3)',
                  buyMarkerBackground: '#0C62F7',
                  negativePLFontColor: 'red',
                  positivePLFontColor: 'white',
                  sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
                  sellMarkerBackground: '#C93B3B',
                  negativePLBackgroundColor: 'black',
                  positivePLBackgroundColor: 'black',
                },
                orderSideMarker: {
                  buy: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#33537C',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#33537C',
                    },
                  },
                  sell: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#C93B3B',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#C93B3B',
                    },
                  },
                  ocoStopLimit: '#FFFF00',
                  ocoStopOrder: '#FFFF00',
                  orderBarUnit: 'pixels',
                  orderBarLength: 100,
                },
                selectionMarker: {
                  fill: {
                    fillColor: 'black',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'white',
                  },
                },
                orderChartMarker: {
                  buy: {
                    fill: {
                      fillColor: 'blue',
                    },
                    stroke: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    tooltip: {
                      fill: {
                        color: 'black',
                        enabled: true,
                      },
                      text: {
                        fontSize: 12,
                        fillColor: 'blue',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                        fontWeight: 'normal',
                      },
                      border: {
                        color: 'orange',
                        width: 1,
                        enabled: false,
                        lineStyle: 'solid',
                      },
                    },
                    markerType: 'triangle',
                  },
                  sell: {
                    fill: {
                      fillColor: 'red',
                    },
                    stroke: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    tooltip: {
                      fill: {
                        color: 'black',
                        enabled: true,
                      },
                      text: {
                        fontSize: 12,
                        fillColor: 'red',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                        fontWeight: 'normal',
                      },
                      border: {
                        color: 'orange',
                        width: 1,
                        enabled: false,
                        lineStyle: 'solid',
                      },
                    },
                    markerType: 'square',
                  },
                  showTopBottomMarkers: true,
                  showTextWithSizeAndPrice: true,
                  showMarkerAtExecutionPrice: true,
                },
                highlightedColumn: {
                  fill: {
                    fillColor: 'rgba(90, 130, 182, 0.45)',
                  },
                },
                positionSideMarker: {
                  long: {
                    kind: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    line: {
                      width: 1,
                      strokeColor: 'green',
                    },
                    price: {
                      fill: {
                        fillColor: 'blue',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    length: 1,
                    lineType: 'solid',
                    quantity: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    lineColor: '#0C62F7',
                    lengthUnit: 'pixels',
                  },
                  short: {
                    kind: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    line: {
                      width: 1,
                      strokeColor: 'red',
                    },
                    price: {
                      fill: {
                        fillColor: 'red',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    length: 1,
                    lineType: 'solid',
                    quantity: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    lineColor: '#FF0000',
                    lengthUnit: 'pixels',
                  },
                  showPLInfo: true,
                  displayUnit: 'points',
                  positionBarUnit: 'pixels',
                  includeRealizedPL: true,
                  positionBarLength: 100,
                  negativePLFontColor: 'red',
                  positivePLFontColor: 'white',
                  negativePLBackgroundColor: 'black',
                  positivePLBackgroundColor: 'black',
                },
              },
              locale: 'en',
              priceStyle: 'candle',
              zoomEnabled: true,
              xGridVisible: true,
              yGridVisible: true,
              zoomAutoSave: false,
              scrollEnabled: true,
              enableMouseEvents: true,
              showBarInfoInTitle: true,
              enableKeyboardEvents: false,
              showInstrumentWatermark: false,
            },
            plots: {
              VolumeProfile: {
                eth: {
                  lines: {
                    dev: {
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                    },
                    prev: {
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                    },
                    current: {
                      va: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#FFFF00',
                        },
                        labelEnabled: false,
                      },
                      poc: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#FFFF00',
                        },
                        labelEnabled: false,
                      },
                    },
                  },
                  profile: {
                    type: 'solidgram',
                    color: {
                      type: 'profileColor',
                      value: '#7F7F7F',
                    },
                    extendNaked: {
                      type: 'closesHighsLows',
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0C62F7',
                      },
                    },
                    profileColor: '',
                    extendNakedPocs: true,
                    vaInsideOpacity: 0.5,
                    vaOutsideOpacity: 0.5,
                    widthCorrelation: 0.33,
                  },
                  session: {
                    id: 63,
                    name: 'US ETH--RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    workingTimes: [
                      {
                        endDay: 1,
                        endTime: 34200000,
                        startDay: 0,
                        startTime: 64800000,
                      },
                      {
                        endDay: 3,
                        endTime: 34200000,
                        startDay: 2,
                        startTime: 58500000,
                      },
                      {
                        endDay: 5,
                        endTime: 34200000,
                        startDay: 4,
                        startTime: 58500000,
                      },
                      {
                        endDay: 4,
                        endTime: 34200000,
                        startDay: 3,
                        startTime: 58500000,
                      },
                      {
                        endDay: 2,
                        endTime: 34200000,
                        startDay: 1,
                        startTime: 58500000,
                      },
                    ],
                  },
                },
                lines: {
                  dev: {
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#8D8E30',
                      },
                    },
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#8D8E30',
                      },
                    },
                  },
                  prev: {
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#8D8E30',
                      },
                    },
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#8D8E30',
                      },
                    },
                  },
                  current: {
                    va: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFFF00',
                      },
                      labelEnabled: false,
                    },
                    poc: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFFF00',
                      },
                      labelEnabled: false,
                    },
                  },
                },
                general: {
                  hide: {
                    value: 'lastProfile',
                    enabled: false,
                  },
                  type: 'volume',
                  align: 'left',
                  width: {
                    unit: 'pixel',
                    value: 70,
                  },
                  margin: {
                    unit: 'pixel',
                    value: 4,
                  },
                  period: {
                    date: null,
                    type: 'every',
                    unit: 'day',
                    value: 1,
                  },
                  sessions: {
                    days: {
                      count: 10,
                      width: {
                        unit: 'pixel',
                        value: 70,
                      },
                      enabled: true,
                      includeCurrentSession: false,
                    },
                    prev: {
                      width: {
                        unit: 'pixel',
                        value: 70,
                      },
                      enabled: true,
                    },
                    current: {
                      width: {
                        unit: 'pixel',
                        value: 70,
                      },
                      enabled: true,
                    },
                  },
                  smoothed: {
                    value: 9,
                    enabled: false,
                  },
                  transparency: 35,
                  vaCorrelation: 0.7,
                  customTickSize: {
                    value: 5,
                    enabled: false,
                  },
                  calculateXProfiles: 999,
                },
                profile: {
                  type: 'solidgram',
                  color: {
                    type: 'profileColor',
                    value: '#0C62F7',
                  },
                  extendNaked: {
                    type: 'closesHighsLows',
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0C62F7',
                    },
                  },
                  profileColor: '',
                  extendNakedPocs: true,
                  vaInsideOpacity: 0.5,
                  vaOutsideOpacity: 0.5,
                  widthCorrelation: 1,
                },
                session: {
                  id: 30,
                  name: 'RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  workingTimes: [
                    {
                      endDay: 2,
                      endTime: 58500000,
                      startDay: 1,
                      startTime: 34200000,
                    },
                    {
                      endDay: 3,
                      endTime: 58500000,
                      startDay: 2,
                      startTime: 34200000,
                    },
                    {
                      endDay: 4,
                      endTime: 58500000,
                      startDay: 3,
                      startTime: 34200000,
                    },
                    {
                      endDay: 5,
                      endTime: 58500000,
                      startDay: 4,
                      startTime: 34200000,
                    },
                    {
                      endDay: 6,
                      endTime: 58500000,
                      startDay: 5,
                      startTime: 34200000,
                    },
                  ],
                },
                graphics: {
                  showPrices: true,
                  summaryFont: {
                    fontSize: 12,
                    fillColor: '#0C62F7',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                  },
                  summaryEnabled: true,
                },
                highlight: {
                  va: {
                    value: '#A1A2A5',
                    enabled: false,
                  },
                  poc: {
                    value: '#A1A2A5',
                    enabled: true,
                  },
                },
                splitProfile: true,
                overlayEthOverRth: false,
              },
            },
            drawings: [
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
            ],
            barsCount: false,
            crossHair: {
              crossHairType: 'crossBars',
            },
            dateScale: {
              height: 15,
              zoomKind: 'autoscaled',
              zoomMode: 'pin_mouse',
              formatter: {
                locale: 'en',
                className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
                timeInterval: 300000,
              },
              calibrator: {
                options: {},
                className: 'StockChartX.CustomDateScaleCalibrator',
              },
              scrollKind: 'autoscaled',
              textPadding: {
                top: null,
                left: 3,
                right: 3,
                bottom: 3,
              },
              autoScrollKind: 'visible_bar',
              autoScrollMode: 'new_bar',
              useManualHeight: false,
              lastVisibleRecord: 1086.533965812581,
              minVisibleRecords: 5,
              firstVisibleRecord: 871.0314960025809,
              allowPartialRecords: true,
              majorTickMarkLength: 5,
              minorTickMarkLength: 3,
              rightAdditionalSpaceRatio: 0.5,
            },
            timeFrame: {
              interval: 5,
              periodicity: '',
            },
            indicators: [
              {
                zIndex: 1000,
                visible: true,
                className: 'VolumeProfile',
                showTitle: true,
                panelIndex: 0,
                parameters: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                _prevParams: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                showLineLevels: false,
                allowContextMenu: true,
                panelHeightRatio: 1,
                showValueMarkers: true,
                showParamsInTitle: true,
                showValuesInTitle: true,
                coloredVolumeTheme: null,
                allowSettingsDialog: false,
              },
            ],
            priceStyle: {
              options: {
                showValueLines: false,
                extendValueLines: false,
              },
              className: 'bar',
            },
            valueScales: [
              {
                width: 100,
                showLeftPanel: false,
                showRightPanel: true,
                useManualWidth: false,
              },
            ],
            periodToLoad: {
              disabled: false,
              interval: 5,
              periodicity: 'd',
            },
            chartPanelsContainer: {
              panels: [
                {
                  options: {
                    state: 0,
                    visible: true,
                    moveKind: 'normal',
                    showXGrid: true,
                    showYGrid: true,
                    heightRatio: 1,
                    moveDirection: 'any',
                    drawValueScale: true,
                    maxHeightRatio: 1,
                    minHeightRatio: 0.05,
                    showIndicatorTitles: true,
                  },
                  valueScales: [
                    {
                      options: {
                        padding: {
                          top: 3,
                          left: 6,
                          right: 3,
                          bottom: 3,
                        },
                        maxAllowedValue: null,
                        maxVisibleValue: 4436.75,
                        minAllowedValue: null,
                        minVisibleValue: 4407.25,
                        maxValueRangeRatio: 5,
                        minValueRangeRatio: 0.1,
                        majorTickMarkLength: 3,
                        minorTickMarkLength: 3,
                        maxAllowedValueRatio: 0.8,
                        minAllowedValueRatio: 0.8,
                      },
                      formatter: {
                        options: {
                          style: 'decimal',
                          locale: 'en',
                          notation: 'standard',
                          signDisplay: 'auto',
                          useGrouping: 'auto',
                          roundingMode: 'halfExpand',
                          numberingSystem: 'latn',
                          roundingPriority: 'auto',
                          roundingIncrement: 1,
                          trailingZeroDisplay: 'auto',
                          minimumIntegerDigits: 1,
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        },
                        className: 'StockChartX.IntlNumberFormat',
                      },
                      calibrator: {
                        options: {
                          suffix: null,
                          divider: null,
                        },
                        className: 'StockChartX.IntervalValueScaleCalibrator',
                      },
                    },
                  ],
                },
              ],
              panelPadding: {
                top: 10,
                left: 5,
                right: 5,
                bottom: 10,
              },
              newPanelHeightRatio: 0.2,
            },
            instrumentComparisonHandler: {
              instrumentsOptions: [],
            },
          },
          showCancelConfirm: true,
          componentInstanceId: 1698770407970,
        },
      },
      minHeight: 150,
      resizable: true,
      allowPopup: true,
      maximizable: true,
      minimizable: true,
    },
    {
      x: 25,
      y: 36,
      id: '1699460173935_0.029054157246979573',
      order: 2,
      width: 628,
      height: 1105,
      visible: true,
      minWidth: 320,
      component: {
        name: 'chart',
        state: {
          link: 0.5337547759380616,
          settings: {
            general: {
              font: {
                fontSize: 10,
                textColor: '#fff',
                fontFamily: 'Arial',
              },
              gradient1: 'rgb(27, 29, 34)',
              gradient2: 'rgb(27, 29, 34)',
              gridColor: '#24262C',
              lineColor: '#fff',
              wickColor: '#fff',
              upCandleColor: '#0C62F7',
              dateScaleColor: 'rgb(27, 29, 34)',
              downCandleColor: '#C93B3B',
              valueScaleColor: 'rgb(27, 29, 34)',
              upCandleBorderColor: '#D0D0D2',
              downCandleBorderColor: '#D0D0D2',
              upCandleBorderColorEnabled: false,
              downCandleBorderColorEnabled: false,
            },
            session: {
              sessionEnabled: true,
              sessionTemplate: {
                id: 628,
                name: 'US Extended Trading Hours',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                workingTimes: [
                  {
                    endDay: 5,
                    endTime: 61200000,
                    startDay: 4,
                    startTime: 64800000,
                    tradingDay: 5,
                  },
                  {
                    endDay: 4,
                    endTime: 61200000,
                    startDay: 3,
                    startTime: 64800000,
                    tradingDay: 4,
                  },
                  {
                    endDay: 3,
                    endTime: 61200000,
                    startDay: 2,
                    startTime: 64800000,
                    tradingDay: 3,
                  },
                  {
                    endDay: 2,
                    endTime: 61200000,
                    startDay: 1,
                    startTime: 64800000,
                    tradingDay: 2,
                  },
                  {
                    endDay: 1,
                    endTime: 61200000,
                    startDay: 0,
                    startTime: 64800000,
                    tradingDay: 1,
                  },
                ],
              },
            },
            trading: {
              tif: {
                DAY: true,
                /**
                 * Property disabled until the feature is fully working.
                 * FOK: true,
                 */
                GTC: true,
                /**
                 * Property disabled until the feature is fully working.
                 * IOC: true,
                 */
                default: 'DAY',
              },
              trading: {
                showPLInfo: true,
                displayUnit: 'points',
                orderBarUnit: 'pixels',
                showOHLVInfo: false,
                bracketButton: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: true,
                tradingBarLength: 40,
                includeRealizedPL: true,
                showCancelConfirm: true,
                showWorkingOrders: true,
                negativePLFontColor: 'red',
                positivePLFontColor: 'white',
                showInstrumentChange: false,
                negativePLBackgroundColor: 'black',
                positivePLBackgroundColor: 'black',
              },
              orderArea: {
                formData: {
                  quantity: 10,
                  stopLoss: {
                    type: 'StopMarket',
                    stopLoss: false,
                  },
                  takeProfit: {
                    takeProfit: false,
                  },
                },
                settings: {
                  tif: {
                    DAY: true,
                    /**
                     * Property disabled until the feature is fully working.
                     * FOK: true,
                     */
                    GTC: true,
                    /**
                     * Property disabled until the feature is fully working.
                     * IOC: true,
                     */
                    default: 'DAY',
                  },
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  orderArea: {
                    showPLInfo: true,
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  formSettings: {
                    showPLInfo: true,
                    showBracket: true,
                    showBuyButton: true,
                    showSellButton: true,
                    showCancelButton: true,
                    showFlattenButton: true,
                    showIcebergButton: true,
                    closePositionButton: true,
                    showLiquidateButton: true,
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  icebergFontColor: '#fff',
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  buyButtonsFontColor: '#D0D0D2',
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  flatButtonsFontColor: '#D0D0D2',
                  sellButtonsFontColor: '#D0D0D2',
                  cancelButtonFontColor: '#D0D0D2',
                  closePositionFontColor: '#D0D0D2',
                  icebergBackgroundColor: '#51535A',
                  buyButtonsBackgroundColor: '#0C62F7',
                  flatButtonsBackgroundColor: '#51535A',
                  sellButtonsBackgroundColor: '#C93B3B',
                  cancelButtonBackgroundColor: '#51535A',
                  closePositionBackgroundColor: '#51535A',
                },
                amountButtons: [
                  {
                    value: 1,
                  },
                  {
                    black: true,
                    value: 1,
                  },
                  {
                    value: 3,
                  },
                  {
                    value: 5,
                  },
                  {
                    value: 10,
                  },
                  {
                    value: 25,
                  },
                ],
              },
              chartMarkers: {
                buyMarkerType: 'triangle',
                buyMarkerColor: 'blue',
                sellMarkerType: 'square',
                sellMarkerColor: 'red',
                buyMarkerFontWeight: 12,
                sellMarkerFontWeight: 12,
                dataBoxBackgroundColor: 'black',
                showBarMarkerTobBottom: true,
                showBarTextWithSizePrice: true,
                showBarMarketExecutionPrice: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
                orderBarUnit: 'pixels',
                orderBarLength: 100,
              },
            },
            valueScale: {
              valueScale: {
                isAutomatic: 'pixels-price',
                pixelsPrice: 10,
              },
            },
          },
          showOHLV: false,
          timeFrame: {
            interval: 1,
            periodicity: 'h',
          },
          instrument: defaultInstrument2,
          showChanges: false,
          periodToLoad: {
            interval: 3,
            periodicity: 'd',
          },
          periodOptions: [
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 3,
                  periodicity: 'd',
                },
                {
                  interval: 5,
                  periodicity: 'd',
                },
              ],
              periodicity: 'd',
            },
            {
              active: false,
              period: 'Weeks',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 2,
                  periodicity: 'w',
                },
                {
                  interval: 3,
                  periodicity: 'w',
                },
              ],
              periodicity: 'w',
            },
            {
              active: false,
              period: 'Months',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'm',
                },
                {
                  interval: 3,
                  periodicity: 'm',
                },
                {
                  interval: 6,
                  periodicity: 'm',
                },
              ],
              periodicity: 'm',
            },
          ],
          intervalOptions: [
            {
              active: false,
              period: 'AMS REVS Bar',
              timeFrames: [
                {
                  interval: 4,
                  periodicity: 'revs',
                },
                {
                  interval: 8,
                  periodicity: 'revs',
                },
                {
                  interval: 12,
                  periodicity: 'revs',
                },
                {
                  interval: 16,
                  periodicity: 'revs',
                },
              ],
              periodicities: ['revs'],
            },
            {
              active: false,
              period: 'Seconds',
              timeFrames: [
                {
                  interval: 30,
                  periodicity: 's',
                },
                {
                  interval: 40,
                  periodicity: 's',
                },
              ],
              periodicities: ['s'],
            },
            {
              active: false,
              period: 'Minutes',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: '',
                },
                {
                  interval: 3,
                  periodicity: '',
                },
                {
                  interval: 5,
                  periodicity: '',
                },
                {
                  interval: 15,
                  periodicity: '',
                },
                {
                  interval: 30,
                  periodicity: '',
                },
              ],
              periodicities: [''],
            },
            {
              active: false,
              period: 'Hours',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'h',
                },
                {
                  interval: 2,
                  periodicity: 'h',
                },
                {
                  interval: 3,
                  periodicity: 'h',
                },
                {
                  interval: 4,
                  periodicity: 'h',
                },
              ],
              periodicities: ['h'],
            },
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 1,
                  periodicity: 'm',
                },
              ],
              periodicities: ['d', 'm', 'w', 'y'],
            },
          ],
          stockChartXState: {
            chart: {
              scale: 2,
              theme: {
                name: 'Dark',
                plot: {
                  bar: {
                    HL: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    HLC: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    OHLC: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    kagi: {
                      upCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                          strokeEnabled: true,
                        },
                      },
                      downCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                          strokeEnabled: true,
                        },
                      },
                    },
                    renko: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                    },
                    candle: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredHL: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    lineBreak: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                          fillEnabled: true,
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                          fillEnabled: true,
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    equiVolume: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    heikinAshi: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredOHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#0C62F7',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#C93B3B',
                      },
                    },
                    candleVolume: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    hollowCandle: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      upHollowCandle: {
                        wick: {
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                      },
                      downHollowCandle: {
                        wick: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    pointAndFigure: {
                      upCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                          strokeEnabled: true,
                        },
                      },
                      downCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                          strokeEnabled: true,
                        },
                      },
                    },
                    equiVolumeShadow: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                  },
                  line: {
                    step: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    simple: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    mountain: {
                      fill: {
                        linearGradient: [
                          'rgba(63, 167, 225, 0.4) ',
                          'rgba(63, 167, 225, 0)',
                        ],
                      },
                      line: {
                        width: 1,
                        strokeColor: '#fff',
                      },
                    },
                  },
                  point: {
                    dot: {
                      fill: {
                        fillColor: 'white',
                      },
                    },
                  },
                  histogram: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    column: {
                      fill: {
                        fillColor: 'blue',
                      },
                      line: {
                        width: 1,
                        strokeColor: 'white',
                        strokeEnabled: false,
                      },
                    },
                    coloredLine: {
                      upBar: {
                        width: 1,
                        strokeColor: 'grey',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                    coloredColumn: {
                      upBar: {
                        fill: {
                          fillColor: 'green',
                        },
                        line: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                      downBar: {
                        fill: {
                          fillColor: 'red',
                        },
                        line: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                    },
                  },
                  indicator: {
                    line1: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'white',
                    },
                    line2: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#3FA7E1',
                    },
                    line3: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#E85050',
                    },
                    line4: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0000FF',
                    },
                    line5: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'yellow',
                    },
                    levels: {
                      line1: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FF0000',
                      },
                      line2: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#32CD32',
                      },
                      line3: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFD500',
                      },
                      line4: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0000FF',
                      },
                      line5: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#808080',
                      },
                    },
                    blackText: {
                      fontSize: 10,
                      fillColor: 'black',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    histogram: {
                      color: '#32CD32',
                    },
                  },
                },
                chart: {
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'grey',
                  },
                  background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
                  instrumentWatermark: {
                    symbol: {
                      fontSize: 70,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    details: {
                      fontSize: 40,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                  },
                },
                button: {
                  none: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                  accept: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'green',
                    },
                  },
                  cancel: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'red',
                    },
                  },
                },
                spread: {
                  ask: {
                    line: {
                      width: 1,
                      strokeColor: '#00D533',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#00D533',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        fontFamily: 'Calibri',
                      },
                    },
                  },
                  bid: {
                    line: {
                      width: 1,
                      strokeColor: '#F20500',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#F20500',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        fontFamily: 'Calibri',
                      },
                    },
                  },
                },
                zoomIn: {
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'white',
                  },
                },
                drawing: {
                  note: {
                    fill: {
                      fillColor: 'orange',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                    centerPointFill: {
                      fillColor: 'rgb(68, 68, 68)',
                    },
                  },
                  text: {
                    text: {
                      fontSize: 12,
                      fillColor: '#FFFFFF',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                  },
                  image: {
                    noImage: {
                      line: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                  },
                  arrowUp: {
                    fill: {
                      fillColor: 'limegreen',
                    },
                  },
                  measure: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.5)',
                      fillEnabled: true,
                    },
                    line: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    border: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    balloon: {
                      fill: {
                        fillColor: 'rgba(255, 255, 255, 0.5)',
                        fillEnabled: true,
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontFamily: 'Calibri',
                      },
                      border: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: 'darkgray',
                        strokeEnabled: true,
                      },
                    },
                  },
                  abstract: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                  },
                  arrowDown: {
                    fill: {
                      fillColor: 'red',
                    },
                  },
                  fibonacci: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                    trendLine: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                    },
                  },
                  trendAngle: {
                    arc: {
                      width: 1,
                      lineStyle: 'dot',
                      strokeColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                    horizontalLine: {
                      width: 1,
                      lineStyle: 'dot',
                      strokeColor: 'white',
                    },
                  },
                  measureTool: {
                    fill: {
                      fillColor: 'rgba(0, 0, 0, 0.8)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                  },
                  abstractMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                tooltip: {
                  fill: {
                    color: 'rgb(44, 44, 44)',
                    enabled: true,
                  },
                  text: {
                    fontSize: 12,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                    fontWeight: 'normal',
                  },
                  border: {
                    color: 'orange',
                    width: 1,
                    enabled: true,
                    lineStyle: 'solid',
                  },
                },
                splitter: {
                  fillColor: '#292C38',
                  hoverFillColor: 'white',
                },
                crossHair: {
                  fill: {
                    fillColor: '#FFFFFF',
                  },
                  line: {
                    width: 1,
                    lineStyle: 'dash',
                    strokeColor: 'darkgray',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: '#000000',
                    fontFamily: 'Calibri',
                  },
                },
                dateScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  text: {
                    fontSize: 10,
                    fillColor: '#fff',
                    fontFamily: 'Arial',
                  },
                  border: {
                    width: 0,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                  },
                  breakLine: {
                    stroke: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: '#545454',
                    },
                  },
                  dateMarker: {
                    fill: {
                      fillColor: 'green',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: '#FFFFFF',
                      fontFamily: 'Calibri',
                    },
                    stroke: {
                      width: 1,
                      lineCap: 'butt',
                      lineJoin: 'miter',
                      lineStyle: 'solid',
                      strokeColor: '#696969',
                      strokePriority: 'color',
                    },
                  },
                },
                chartPanel: {
                  grid: {
                    width: 1,
                    strokeColor: '#24262C',
                  },
                  title: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    fontFamily: 'Calibri',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontSize: 70,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    details: {
                      fontSize: 40,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                  },
                },
                valueScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  text: {
                    fontSize: 10,
                    fillColor: '#fff',
                    fontStyle: 'normal',
                    fontFamily: 'Arial',
                  },
                  border: {
                    width: 0,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                  },
                  valueMarker: {
                    fill: {
                      fillColor: '#3452F1',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                  },
                },
                chartMarkers: {
                  buyMarkerType: 'triangle',
                  buyMarkerColor: 'blue',
                  sellMarkerType: 'square',
                  sellMarkerColor: 'red',
                  buyMarkerFontWeight: 1,
                  sellMarkerFontWeight: 1,
                  dataBoxBackgroundColor: 'black',
                  showBarMarkerTobBottom: true,
                  showBarTextWithSizePrice: true,
                  showBarMarketExecutionPrice: true,
                },
                tradingPanel: {
                  showPLInfo: true,
                  displayUnit: 'points',
                  tradingBarUnit: 'pixels',
                  markerFontColor: '#fff',
                  tradingBarLength: 40,
                  includeRealizedPL: true,
                  buyPanelBackground: 'rgba(12,98,247, 0.3)',
                  buyMarkerBackground: '#0C62F7',
                  negativePLFontColor: 'red',
                  positivePLFontColor: 'white',
                  sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
                  sellMarkerBackground: '#C93B3B',
                  negativePLBackgroundColor: 'black',
                  positivePLBackgroundColor: 'black',
                },
                orderSideMarker: {
                  buy: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#33537C',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#33537C',
                    },
                  },
                  sell: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#C93B3B',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#C93B3B',
                    },
                  },
                  ocoStopLimit: '#FFFF00',
                  ocoStopOrder: '#FFFF00',
                  orderBarUnit: 'pixels',
                  orderBarLength: 100,
                },
                selectionMarker: {
                  fill: {
                    fillColor: 'black',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'white',
                  },
                },
                orderChartMarker: {
                  buy: {
                    fill: {
                      fillColor: 'blue',
                    },
                    stroke: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    tooltip: {
                      fill: {
                        color: 'black',
                        enabled: true,
                      },
                      text: {
                        fontSize: 12,
                        fillColor: 'blue',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                        fontWeight: 'normal',
                      },
                      border: {
                        color: 'orange',
                        width: 1,
                        enabled: false,
                        lineStyle: 'solid',
                      },
                    },
                    markerType: 'triangle',
                  },
                  sell: {
                    fill: {
                      fillColor: 'red',
                    },
                    stroke: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    tooltip: {
                      fill: {
                        color: 'black',
                        enabled: true,
                      },
                      text: {
                        fontSize: 12,
                        fillColor: 'red',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                        fontWeight: 'normal',
                      },
                      border: {
                        color: 'orange',
                        width: 1,
                        enabled: false,
                        lineStyle: 'solid',
                      },
                    },
                    markerType: 'square',
                  },
                  showTopBottomMarkers: true,
                  showTextWithSizeAndPrice: true,
                  showMarkerAtExecutionPrice: true,
                },
                highlightedColumn: {
                  fill: {
                    fillColor: 'rgba(90, 130, 182, 0.45)',
                  },
                },
                positionSideMarker: {
                  long: {
                    kind: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    line: {
                      width: 1,
                      strokeColor: 'green',
                    },
                    price: {
                      fill: {
                        fillColor: 'blue',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    length: 1,
                    lineType: 'solid',
                    quantity: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    lineColor: '#0C62F7',
                    lengthUnit: 'pixels',
                  },
                  short: {
                    kind: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    line: {
                      width: 1,
                      strokeColor: 'red',
                    },
                    price: {
                      fill: {
                        fillColor: 'red',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    length: 1,
                    lineType: 'solid',
                    quantity: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    lineColor: '#FF0000',
                    lengthUnit: 'pixels',
                  },
                  showPLInfo: true,
                  displayUnit: 'points',
                  positionBarUnit: 'pixels',
                  includeRealizedPL: true,
                  positionBarLength: 100,
                  negativePLFontColor: 'red',
                  positivePLFontColor: 'white',
                  negativePLBackgroundColor: 'black',
                  positivePLBackgroundColor: 'black',
                },
              },
              locale: 'en',
              priceStyle: 'candle',
              zoomEnabled: true,
              xGridVisible: true,
              yGridVisible: true,
              zoomAutoSave: false,
              scrollEnabled: true,
              enableMouseEvents: true,
              showBarInfoInTitle: true,
              enableKeyboardEvents: false,
              showInstrumentWatermark: false,
            },
            plots: {
              Footprint: {
                font: {
                  fontSize: 11,
                  fillColor: '#666666',
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                },
                main: {
                  mode: 'volume',
                  fillColor: '#313131',
                  strokeColor: '#85857A00',
                  barStrokeColor: '#85857A00  ',
                  closeOpenColor: '#1ADE5D',
                  customTickSize: {
                    value: 1,
                    enabled: false,
                  },
                  openCloseColor: '#FF2E00',
                  enableOpenClose: true,
                },
                text: {
                  margin: 4,
                  hideSides: false,
                  separator: 'x',
                  textStyle: 'bidAsk',
                  initialBarWidth: 80,
                  barWidthTextThreshold: 60,
                  barHeightTextThreshold: 5,
                },
                profile: {
                  filterValue: 4000,
                  strokeWidth: 1,
                  profileFilter: 'autoMaxProfile',
                  autoProfilingRange: 'visible',
                  showPocOnlyOnBiggestBar: false,
                },
                intraBar: {
                  barColor: '#A9A9A9',
                  barStyle: 'volumeProfileBars',
                  barPocColor: '#FFA500',
                  barAlignment: 'left',
                  buyPocBarColor: 'gold',
                  sellPocBarColor: 'gold',
                  buyShadeBarColor: '#0C62F7',
                  buyVolumeBarColor: 'silver',
                  sellShadeBarColor: '#E95050',
                  sellVolumeBarColor: 'silver',
                  useDeltaShadingColors: true,
                  lastPriceOutlineColor: '#e8e8e8',
                },
                pullback: {
                  enabled: true,
                  fillColor: '#3F3F3F',
                  textColor: '#E5E5E5',
                  strokeColor: 'transparent',
                  currentBidAskColor: '#8B6D00',
                },
                volumeFilter: {
                  mode: 'greaterThan',
                  value: 100,
                  enabled: false,
                },
                deltaImbalance: {
                  enabled: true,
                  threshold: 3.5,
                  weakAskVolumeColor: '#0C62F7',
                  weakBidVolumeColor: '#0C62F7',
                  enableWeakAskVolume: false,
                  enableWeakBidVolume: false,
                  strongAskVolumeColor: '#00BD62',
                  strongBidVolumeColor: '#FF6347',
                },
              },
            },
            drawings: [
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
            ],
            barsCount: false,
            crossHair: {
              crossHairType: 'none',
            },
            dateScale: {
              height: 15,
              zoomKind: 'autoscaled',
              zoomMode: 'pin_mouse',
              formatter: {
                locale: 'en',
                className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
                timeInterval: 3600000,
              },
              calibrator: {
                options: {},
                className: 'StockChartX.CustomDateScaleCalibrator',
              },
              scrollKind: 'autoscaled',
              textPadding: {
                top: null,
                left: 3,
                right: 3,
                bottom: 3,
              },
              autoScrollKind: 'visible_bar',
              autoScrollMode: 'new_bar',
              useManualHeight: false,
              lastVisibleRecord: 45,
              minVisibleRecords: 5,
              firstVisibleRecord: 38.82402375,
              allowPartialRecords: true,
              majorTickMarkLength: 5,
              minorTickMarkLength: 3,
              rightAdditionalSpaceRatio: 0.5,
            },
            timeFrame: {
              interval: 1,
              periodicity: 'h',
            },
            indicators: [
              {
                zIndex: 1000,
                visible: true,
                className: 'Footprint',
                showTitle: true,
                panelIndex: 0,
                parameters: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                _prevParams: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                showLineLevels: false,
                allowContextMenu: true,
                panelHeightRatio: 1,
                showValueMarkers: true,
                showParamsInTitle: true,
                showValuesInTitle: true,
                coloredVolumeTheme: null,
                allowSettingsDialog: false,
              },
            ],
            priceStyle: {
              options: {},
              className: 'candle',
            },
            valueScales: [
              {
                width: 100,
                showLeftPanel: false,
                showRightPanel: true,
                useManualWidth: false,
              },
            ],
            periodToLoad: {
              interval: 3,
              periodicity: 'd',
            },
            chartPanelsContainer: {
              panels: [
                {
                  options: {
                    state: 0,
                    visible: true,
                    moveKind: 'normal',
                    showXGrid: true,
                    showYGrid: true,
                    heightRatio: 1,
                    moveDirection: 'any',
                    drawValueScale: true,
                    maxHeightRatio: 1,
                    minHeightRatio: 0.05,
                    showIndicatorTitles: true,
                  },
                  valueScales: [
                    {
                      options: {
                        padding: {
                          top: 3,
                          left: 6,
                          right: 3,
                          bottom: 3,
                        },
                        maxAllowedValue: null,
                        maxVisibleValue: 4441.95,
                        minAllowedValue: null,
                        minVisibleValue: 4415.55,
                        maxValueRangeRatio: 5,
                        minValueRangeRatio: 0.1,
                        majorTickMarkLength: 3,
                        minorTickMarkLength: 3,
                        maxAllowedValueRatio: 0.8,
                        minAllowedValueRatio: 0.8,
                      },
                      formatter: {
                        options: {
                          style: 'decimal',
                          locale: 'en',
                          notation: 'standard',
                          signDisplay: 'auto',
                          useGrouping: 'auto',
                          roundingMode: 'halfExpand',
                          numberingSystem: 'latn',
                          roundingPriority: 'auto',
                          roundingIncrement: 1,
                          trailingZeroDisplay: 'auto',
                          minimumIntegerDigits: 1,
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        },
                        className: 'StockChartX.IntlNumberFormat',
                      },
                      calibrator: {
                        options: {
                          suffix: null,
                          divider: null,
                        },
                        className: 'StockChartX.IntervalValueScaleCalibrator',
                      },
                    },
                  ],
                },
              ],
              panelPadding: {
                top: 10,
                left: 5,
                right: 5,
                bottom: 10,
              },
              newPanelHeightRatio: 0.2,
            },
            instrumentComparisonHandler: {
              instrumentsOptions: [],
            },
          },
          showCancelConfirm: true,
          componentInstanceId: 1699460173916,
        },
      },
      minHeight: 150,
      resizable: true,
      allowPopup: true,
      maximizable: true,
      minimizable: true,
    },
    {
      x: 348,
      y: 36,
      id: '1699890660697_0.3805030845652717',
      order: 3,
      width: 715,
      height: 1105,
      visible: true,
      minWidth: 470,
      component: {
        name: 'dom',
        state: {
          columns: [
            {
              name: 'orders',
              type: 'string',
              index: 0,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              title: 'ORDERS',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 0,
              tableViewName: 'Orders',
            },
            {
              name: 'buyOrders',
              type: 'string',
              index: 1,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247, 0.5)',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              title: 'BUY ORDERS',
              width: 100,
              hidden: true,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 1,
              tableViewName: 'Buy Orders',
            },
            {
              name: 'sellOrders',
              type: 'string',
              index: 2,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              title: 'SELL ORDERS',
              width: 100,
              hidden: true,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 2,
              tableViewName: 'Sell Orders',
            },
            {
              name: 'volume',
              type: 'histogram',
              index: 3,
              style: {
                ltq: false,
                poc: true,
                VWAP: true,
                color: '#fff',
                sessions: {
                  histogramEnabled: true,
                  overlayLineColor: '#fff',
                },
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                valueArea: true,
                textOverflow: false,
                histogramColor: 'rgba(73, 187, 169, 0.5)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
                histogramOrientation: 'right',
                valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
                highlightBackgroundColor: '#9D0A0A',
                lastTradingBackgroundColor: '#fff',
                pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
              },
              title: 'VOLUME',
              width: 90,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 3,
              tableViewName: 'Volume',
            },
            {
              name: 'price',
              type: 'string',
              index: 4,
              style: {
                color: 'rgba(208, 208, 210, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                highlightColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                priceMarkerColor: 'black',
                tradedPriceColor: 'rgba(208, 208, 210, 1)',
                hoveredhighlightColor: '#fff',
                hoveredBackgroundColor: '#383A40',
                hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
                highlightBackgroundColor: '#383A40',
                priceMarkerBackgroundColor: '#FFA500',
                tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
                priceMarkerpriceMarkerColor: 'black',
                hoveredhighlightBackgroundColor: '#383A40',
                longPositionOpenBackgroundColor: '#0C62F7',
                shortPositionOpenBackgroundColor: '#C93B3BFF',
                hoveredtradedPriceBackgroundColor: '#383A40',
                priceMarkerpriceMarkerBackgroundColor: '#FFA500',
                hoveredlongPositionOpenBackgroundColor: '#383A40',
                hoveredshortPositionOpenBackgroundColor: '#383A40',
              },
              title: 'PRICE',
              width: 62,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 4,
              tableViewName: 'Price',
            },
            {
              name: 'delta',
              type: 'string',
              index: 5,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                askDeltaColor: '#ffffff',
                askDeltacolor: '#ffffff',
                bidDeltaColor: '#fff',
                bidDeltacolor: '#fff',
                titleUpperCase: true,
                askDeltaTextAlign: 'center',
                askDeltatextAlign: 'center',
                bidDeltaTextAlign: 'center',
                bidDeltatextAlign: 'center',
                askDeltaMinToVisible: 0,
                askDeltaminToVisible: 0,
                bidDeltaMinToVisible: 0,
                bidDeltaminToVisible: 0,
                askDeltaOverlayOrders: true,
                askDeltaoverlayOrders: true,
                bidDeltaBuyOrderColor: '#fff',
                bidDeltaOverlayOrders: true,
                bidDeltabuyOrderColor: '#fff',
                bidDeltaoverlayOrders: true,
                askDeltaSellOrderColor: '#fff',
                askDeltahighlightColor: '#ffffff',
                askDeltasellOrderColor: '#fff',
                bidDeltahighlightColor: '#fff',
                askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
                askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
                bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
                bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
                askDeltahighlightTextAlign: 'center',
                bidDeltahighlightTextAlign: 'center',
                bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                askDeltaHighlightBackgroundColor: '#682a2d',
                askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                askDeltahighlightBackgroundColor: '#682a2d',
                askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                bidDeltaHighlightBackgroundColor: '#2b486e',
                bidDeltahighlightBackgroundColor: '#2b486e',
              },
              title: 'DELTA',
              width: 58,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 5,
              tableViewName: 'Delta',
            },
            {
              name: 'bidDelta',
              type: 'string',
              index: 6,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                minToVisible: 0,
                textOverflow: false,
                buyOrderColor: '#fff',
                overlayOrders: true,
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247,0.2)',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                highlightBackgroundColor: '#2b486e',
              },
              title: 'DELTA',
              width: 68,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 6,
              tableViewName: 'Bid Delta',
            },
            {
              name: 'bid',
              type: 'histogram',
              index: 7,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                largeSize: 14,
                textAlign: 'center',
                totalColor: '#fff',
                textOverflow: false,
                clearInterval: 9999,
                highlightLarge: false,
                histogramColor: 'rgba(72,149,245,0,3)',
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247, 0.3)',
                histogramEnabled: true,
                histogramOrientation: 'left',
                highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
              },
              title: 'BID',
              width: 88,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 7,
              tableViewName: 'Bid',
            },
            {
              name: 'ltq',
              type: 'string',
              index: 8,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                histogramColor: 'rgba(56, 58, 64, 0.5)',
                titleUpperCase: true,
                backgroundColor: '#012B36',
                accumulateTrades: true,
                buyBackgroundColor: '#0C62F7',
                sellBackgroundColor: 'rgba(201, 59, 59, 1)',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'LTQ',
              width: 49,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 8,
              tableViewName: 'LTQ',
            },
            {
              name: 'currentBid',
              type: 'histogram',
              index: 9,
              style: {
                color: '#EB5A5A',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                clearOnBest: true,
                textOverflow: false,
                clearInterval: 9999,
                levelInterval: 500,
                momentumTails: true,
                histogramColor: 'rgba(201, 59, 59, 0.4)',
                tailInsideBold: false,
                tailInsideFont: '700 14px "Open Sans", sans-serif',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                tailInsideColor: 'rgba(255, 255, 255, 1)',
                histogramEnabled: false,
                clearTradersTimer: 9999,
                tailInsidelevel1Bold: false,
                tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel2Bold: false,
                tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel3Bold: false,
                tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel4Bold: false,
                tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel5Bold: false,
                tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel6Bold: false,
                tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel7Bold: false,
                tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel8Bold: false,
                tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel9Bold: false,
                tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
                insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                level1BackgroundColor: 'rgba(128, 64, 64, 1)',
                level2BackgroundColor: 'rgba(112, 61, 63, 1)',
                level3BackgroundColor: 'rgba(96, 59, 62, 1)',
                level4BackgroundColor: 'rgba(80, 56, 60, 1)',
                level5BackgroundColor: 'rgba(64, 54, 59, 1)',
                level6BackgroundColor: 'rgba(48, 51, 58, 1)',
                level7BackgroundColor: 'rgba(32, 48, 57, 1)',
                level8BackgroundColor: 'rgba(16, 46, 55, 1)',
                tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
                tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
                tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
                tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
                tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
                tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
                tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
                tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
                tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
              },
              title: 'C.BID',
              width: 50,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 9,
              tableViewName: 'C.Bid',
            },
            {
              name: 'currentAsk',
              type: 'histogram',
              index: 10,
              style: {
                color: 'rgba(12,98,247,1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                clearOnBest: true,
                textOverflow: false,
                clearInterval: 9999,
                levelInterval: 500,
                momentumTails: true,
                histogramColor: 'rgba(4, 63, 128, 1)',
                tailInsideBold: false,
                tailInsideFont: '700 14px "Open Sans", sans-serif',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                tailInsideColor: 'rgba(255, 255, 255, 1)',
                histogramEnabled: false,
                clearTradersTimer: 9999,
                tailInsidelevel1Bold: false,
                tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel2Bold: false,
                tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel3Bold: false,
                tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel4Bold: false,
                tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel5Bold: false,
                tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel6Bold: false,
                tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel7Bold: false,
                tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel8Bold: false,
                tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel9Bold: false,
                tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
                insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                level1BackgroundColor: 'rgba(4, 63, 128, 1)',
                level2BackgroundColor: 'rgba(3, 60, 119, 1)',
                level3BackgroundColor: 'rgba(3, 59, 110, 1)',
                level4BackgroundColor: 'rgba(2, 56, 100, 1)',
                level5BackgroundColor: 'rgba(2, 54, 91, 1)',
                level6BackgroundColor: 'rgba(2, 51, 82, 1)',
                level7BackgroundColor: 'rgba(1, 48, 73, 1)',
                level8BackgroundColor: 'rgba(1, 46, 63, 1)',
                tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
                tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
                tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
                tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
                tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
                tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
                tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
                tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
                tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
              },
              title: 'C.ASK',
              width: 50,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 10,
              tableViewName: 'C.Ask',
            },
            {
              name: 'ask',
              type: 'histogram',
              index: 11,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                largeSize: 14,
                textAlign: 'center',
                totalColor: '#fff',
                textOverflow: false,
                clearInterval: 9999,
                highlightLarge: false,
                histogramColor: 'rgba(201, 59, 59, 0.4)',
                titleUpperCase: true,
                backgroundColor: 'rgba(201, 59, 59, 0.3)',
                histogramEnabled: true,
                histogramOrientation: 'left',
                highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
              },
              title: 'ASK',
              width: 88,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 11,
              tableViewName: 'Ask',
            },
            {
              name: 'askDelta',
              type: 'string',
              index: 12,
              style: {
                color: '#ffffff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                minToVisible: 0,
                textOverflow: false,
                overlayOrders: true,
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(201, 59, 59, 0.2)',
                highlightBackgroundColor: '#682a2d',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              },
              title: 'DELTA',
              width: 68,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 12,
              tableViewName: 'Ask Delta',
            },
            {
              name: 'totalBid',
              type: 'histogram',
              index: 13,
              style: {
                color: 'rgba(235, 90, 90, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                histogramColor: 'rgba(201, 59, 59, 0.3)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                histogramOrientation: 'right',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'T.BID',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 13,
              tableViewName: 'T.Bid',
            },
            {
              name: 'totalAsk',
              type: 'histogram',
              index: 14,
              style: {
                color: 'rgba(12,98,247, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                histogramColor: 'rgba(12,98,247, 0.3)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                histogramOrientation: 'left',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'T.ASK',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 14,
              tableViewName: 'T.Ask',
            },
          ],
          settings: {
            ask: {
              color: '#fff',
              largeSize: 14,
              textAlign: 'center',
              totalColor: '#fff',
              clearInterval: 9999,
              highlightLarge: false,
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              backgroundColor: 'rgba(201, 59, 59, 0.3)',
              histogramEnabled: true,
              histogramOrientation: 'left',
              highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
            },
            bid: {
              color: '#fff',
              largeSize: 14,
              textAlign: 'center',
              totalColor: '#fff',
              clearInterval: 9999,
              highlightLarge: false,
              histogramColor: 'rgba(72,149,245,0,3)',
              backgroundColor: 'rgba(12,98,247, 0.3)',
              histogramEnabled: true,
              histogramOrientation: 'left',
              highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
            },
            ltq: {
              color: '#fff',
              textAlign: 'center',
              histogramColor: 'rgba(56, 58, 64, 0.5)',
              backgroundColor: '#012B36',
              accumulateTrades: true,
              buyBackgroundColor: '#0C62F7',
              sellBackgroundColor: 'rgba(201, 59, 59, 1)',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            note: {
              color: '#fff',
              backgroundColor: 'transparent',
              addedOrdersColor: 'rgba(53, 104, 147, 1)',
              pulledOrdersColor: 'rgba(143, 60, 65, 1)',
            },
            delta: {
              askDeltaColor: '#ffffff',
              askDeltacolor: '#ffffff',
              bidDeltaColor: '#fff',
              bidDeltacolor: '#fff',
              askDeltaTextAlign: 'center',
              askDeltatextAlign: 'center',
              bidDeltaTextAlign: 'center',
              bidDeltatextAlign: 'center',
              askDeltaMinToVisible: 0,
              askDeltaminToVisible: 0,
              bidDeltaMinToVisible: 0,
              bidDeltaminToVisible: 0,
              askDeltaOverlayOrders: true,
              askDeltaoverlayOrders: true,
              bidDeltaBuyOrderColor: '#fff',
              bidDeltaOverlayOrders: true,
              bidDeltabuyOrderColor: '#fff',
              bidDeltaoverlayOrders: true,
              askDeltaSellOrderColor: '#fff',
              askDeltahighlightColor: '#ffffff',
              askDeltasellOrderColor: '#fff',
              bidDeltahighlightColor: '#fff',
              askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
              askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
              bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
              bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
              askDeltahighlightTextAlign: 'center',
              bidDeltahighlightTextAlign: 'center',
              bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              askDeltaHighlightBackgroundColor: '#682a2d',
              askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              askDeltahighlightBackgroundColor: '#682a2d',
              askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              bidDeltaHighlightBackgroundColor: '#2b486e',
              bidDeltahighlightBackgroundColor: '#2b486e',
            },
            price: {
              color: 'rgba(208, 208, 210, 1)',
              textAlign: 'center',
              highlightColor: '#fff',
              backgroundColor: 'transparent',
              priceMarkerColor: 'black',
              tradedPriceColor: 'rgba(208, 208, 210, 1)',
              hoveredhighlightColor: '#fff',
              hoveredBackgroundColor: '#383A40',
              hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
              highlightBackgroundColor: '#383A40',
              priceMarkerBackgroundColor: '#FFA500',
              tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
              priceMarkerpriceMarkerColor: 'black',
              hoveredhighlightBackgroundColor: '#383A40',
              longPositionOpenBackgroundColor: '#0C62F7',
              shortPositionOpenBackgroundColor: '#C93B3BFF',
              hoveredtradedPriceBackgroundColor: '#383A40',
              priceMarkerpriceMarkerBackgroundColor: '#FFA500',
              hoveredlongPositionOpenBackgroundColor: '#383A40',
              hoveredshortPositionOpenBackgroundColor: '#383A40',
            },
            common: {
              ask: true,
              bid: true,
              ltq: true,
              delta: false,
              notes: true,
              price: true,
              orders: false,
              volume: true,
              askDelta: true,
              bidDelta: true,
              fontSize: 14,
              totalAsk: false,
              totalBid: false,
              buyOrders: false,
              currentAsk: true,
              currentBid: true,
              fontFamily: '"Open Sans", sans-serif',
              fontWeight: '',
              sellOrders: false,
              generalColors: {
                gridLineColor: '#24262C',
                centerLineColor: '#A1A2A5',
                orderGridLineColor: 'rgba(88, 110, 117, 1)',
                enableOrderGridColor: true,
              },
            },
            orders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'transparent',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            volume: {
              ltq: false,
              poc: true,
              VWAP: true,
              color: '#fff',
              sessions: {
                histogramEnabled: true,
                overlayLineColor: '#fff',
              },
              textAlign: 'right',
              valueArea: true,
              histogramColor: 'rgba(73, 187, 169, 0.5)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
              histogramOrientation: 'right',
              valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
              highlightBackgroundColor: '#9D0A0A',
              lastTradingBackgroundColor: '#fff',
              pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
            },
            general: {
              recenter: true,
              intervals: {
                momentumTails: true,
                printOutlines: false,
                updateInterval: 100,
                clearTradersTimer: 9999,
                orderQuantityStep: 7,
                momentumIntervalMs: 500,
                scrollWheelSensitivity: 1,
              },
              commonView: {
                autoCenter: true,
                centerLine: true,
                autoCenterTicks: 20,
              },
              marketDepth: {
                marketDepth: 40,
                bidAskDeltaDepth: 9,
                showDepthHistory: false,
                bidAskDeltaFilter: 0,
              },
              digitsToHide: 4,
              hideFromLeft: false,
              hideFromRight: false,
              hideAccountName: false,
              clearTotalPrints: false,
              clearInsidePrints: false,
              currentTotalAllWindows: false,
              currentTradesAllWindows: false,
              recenterTotalAllWindows: false,
            },
            hotkeys: {
              hitBid: null,
              joinAsk: null,
              joinBid: null,
              liftOffer: null,
              quantity1: null,
              quantity2: null,
              quantity3: null,
              quantity4: null,
              quantity5: null,
              autoCenter: null,
              limitsToPrice: null,
              stopsToPrice: null,
              quantityToPos: null,
              clearAllTotals: null,
              clearTotalPrintsUp: null,
              clearVolumeProfile: null,
              autoCenterAllWindows: null,
              clearAllPriceMarkers: null,
              clearInsidePrintsUp: null,
              clearTotalPrintsDown: null,
              clearInsidePrintsDown: null,
              clearInsidePrintsAllWindows: null,
              clearTotalPrintsUpAllWindows: null,
              clearInsidePrintsUpAllWindows: null,
              clearTotalPrintsDownAllWindows: null,
              clearInsidePrintsDownAllWindows: null,
            },
            trading: {
              tif: {
                DAY: true,
                /**
                 * Property disabled until the feature is fully working.
                 * FOK: true,
                 */
                GTC: true,
                /**
                 * Property disabled until the feature is fully working.
                 * IOC: true,
                 */
                default: 'DAY',
              },
              trading: {
                split: false,
                orderBarUnit: 'pixels',
                overlayOrders: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: false,
                tradingBarLength: 40,
                showCancelConfirm: true,
                showWorkingOrders: true,
              },
              formData: {
                quantity: 1,
              },
              orderArea: {
                roundPL: false,
                settings: {
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                },
                showPLInfo: true,
                showOHLVInfo: true,
                bracketButton: true,
                includeRealizedPL: false,
                showInstrumentChange: false,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
              },
              amountButtons: [
                {
                  value: 1,
                },
                {
                  black: true,
                  value: 1,
                },
                {
                  value: 3,
                },
                {
                  value: 5,
                },
                {
                  value: 10,
                },
                {
                  value: 25,
                },
              ],
            },
            _columns: [
              {
                name: 'orders',
                type: 'string',
                index: 0,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
                title: 'ORDERS',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 0,
                tableViewName: 'Orders',
              },
              {
                name: 'buyOrders',
                type: 'string',
                index: 1,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247, 0.5)',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
                title: 'BUY ORDERS',
                width: 100,
                hidden: true,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 1,
                tableViewName: 'Buy Orders',
              },
              {
                name: 'sellOrders',
                type: 'string',
                index: 2,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
                title: 'SELL ORDERS',
                width: 100,
                hidden: true,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 2,
                tableViewName: 'Sell Orders',
              },
              {
                name: 'volume',
                type: 'histogram',
                index: 3,
                style: {
                  ltq: false,
                  poc: true,
                  VWAP: true,
                  color: '#fff',
                  sessions: {
                    histogramEnabled: true,
                    overlayLineColor: '#fff',
                  },
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'right',
                  valueArea: true,
                  textOverflow: false,
                  histogramColor: 'rgba(73, 187, 169, 0.5)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
                  histogramOrientation: 'right',
                  valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
                  highlightBackgroundColor: '#9D0A0A',
                  lastTradingBackgroundColor: '#fff',
                  pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
                },
                title: 'VOLUME',
                width: 90,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 3,
                tableViewName: 'Volume',
              },
              {
                name: 'price',
                type: 'string',
                index: 4,
                style: {
                  color: 'rgba(208, 208, 210, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  textOverflow: false,
                  highlightColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  priceMarkerColor: 'black',
                  tradedPriceColor: 'rgba(208, 208, 210, 1)',
                  hoveredhighlightColor: '#fff',
                  hoveredBackgroundColor: '#383A40',
                  hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
                  highlightBackgroundColor: '#383A40',
                  priceMarkerBackgroundColor: '#FFA500',
                  tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
                  priceMarkerpriceMarkerColor: 'black',
                  hoveredhighlightBackgroundColor: '#383A40',
                  longPositionOpenBackgroundColor: '#0C62F7',
                  shortPositionOpenBackgroundColor: '#C93B3BFF',
                  hoveredtradedPriceBackgroundColor: '#383A40',
                  priceMarkerpriceMarkerBackgroundColor: '#FFA500',
                  hoveredlongPositionOpenBackgroundColor: '#383A40',
                  hoveredshortPositionOpenBackgroundColor: '#383A40',
                },
                title: 'PRICE',
                width: 62,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 4,
                tableViewName: 'Price',
              },
              {
                name: 'delta',
                type: 'string',
                index: 5,
                style: {
                  color: '#D0D0D2',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'left',
                  textOverflow: false,
                  askDeltaColor: '#ffffff',
                  askDeltacolor: '#ffffff',
                  bidDeltaColor: '#fff',
                  bidDeltacolor: '#fff',
                  titleUpperCase: true,
                  askDeltaTextAlign: 'center',
                  askDeltatextAlign: 'center',
                  bidDeltaTextAlign: 'center',
                  bidDeltatextAlign: 'center',
                  askDeltaMinToVisible: 0,
                  askDeltaminToVisible: 0,
                  bidDeltaMinToVisible: 0,
                  bidDeltaminToVisible: 0,
                  askDeltaOverlayOrders: true,
                  askDeltaoverlayOrders: true,
                  bidDeltaBuyOrderColor: '#fff',
                  bidDeltaOverlayOrders: true,
                  bidDeltabuyOrderColor: '#fff',
                  bidDeltaoverlayOrders: true,
                  askDeltaSellOrderColor: '#fff',
                  askDeltahighlightColor: '#ffffff',
                  askDeltasellOrderColor: '#fff',
                  bidDeltahighlightColor: '#fff',
                  askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
                  askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
                  bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
                  bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
                  askDeltahighlightTextAlign: 'center',
                  bidDeltahighlightTextAlign: 'center',
                  bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  askDeltaHighlightBackgroundColor: '#682a2d',
                  askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  askDeltahighlightBackgroundColor: '#682a2d',
                  askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  bidDeltaHighlightBackgroundColor: '#2b486e',
                  bidDeltahighlightBackgroundColor: '#2b486e',
                },
                title: 'DELTA',
                width: 58,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 5,
                tableViewName: 'Delta',
              },
              {
                name: 'bidDelta',
                type: 'string',
                index: 6,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  minToVisible: 0,
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  overlayOrders: true,
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247,0.2)',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  highlightBackgroundColor: '#2b486e',
                },
                title: 'DELTA',
                width: 68,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 6,
                tableViewName: 'Bid Delta',
              },
              {
                name: 'bid',
                type: 'histogram',
                index: 7,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  largeSize: 14,
                  textAlign: 'center',
                  totalColor: '#fff',
                  textOverflow: false,
                  clearInterval: 9999,
                  highlightLarge: false,
                  histogramColor: 'rgba(72,149,245,0,3)',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247, 0.3)',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
                },
                title: 'BID',
                width: 88,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 7,
                tableViewName: 'Bid',
              },
              {
                name: 'ltq',
                type: 'string',
                index: 8,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  textOverflow: false,
                  histogramColor: 'rgba(56, 58, 64, 0.5)',
                  titleUpperCase: true,
                  backgroundColor: '#012B36',
                  accumulateTrades: true,
                  buyBackgroundColor: '#0C62F7',
                  sellBackgroundColor: 'rgba(201, 59, 59, 1)',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'LTQ',
                width: 49,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 8,
                tableViewName: 'LTQ',
              },
              {
                name: 'currentBid',
                type: 'histogram',
                index: 9,
                style: {
                  color: '#EB5A5A',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  clearOnBest: true,
                  textOverflow: false,
                  clearInterval: 9999,
                  levelInterval: 500,
                  momentumTails: true,
                  histogramColor: 'rgba(201, 59, 59, 0.4)',
                  tailInsideBold: false,
                  tailInsideFont: '700 14px "Open Sans", sans-serif',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  tailInsideColor: 'rgba(255, 255, 255, 1)',
                  histogramEnabled: false,
                  clearTradersTimer: 9999,
                  tailInsidelevel1Bold: false,
                  tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel2Bold: false,
                  tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel3Bold: false,
                  tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel4Bold: false,
                  tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel5Bold: false,
                  tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel6Bold: false,
                  tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel7Bold: false,
                  tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel8Bold: false,
                  tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel9Bold: false,
                  tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
                  insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                  level1BackgroundColor: 'rgba(128, 64, 64, 1)',
                  level2BackgroundColor: 'rgba(112, 61, 63, 1)',
                  level3BackgroundColor: 'rgba(96, 59, 62, 1)',
                  level4BackgroundColor: 'rgba(80, 56, 60, 1)',
                  level5BackgroundColor: 'rgba(64, 54, 59, 1)',
                  level6BackgroundColor: 'rgba(48, 51, 58, 1)',
                  level7BackgroundColor: 'rgba(32, 48, 57, 1)',
                  level8BackgroundColor: 'rgba(16, 46, 55, 1)',
                  tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                  highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
                  tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
                  tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
                  tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
                  tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
                  tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
                  tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
                  tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
                  tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
                },
                title: 'C.BID',
                width: 50,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 9,
                tableViewName: 'C.Bid',
              },
              {
                name: 'currentAsk',
                type: 'histogram',
                index: 10,
                style: {
                  color: 'rgba(12,98,247,1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  clearOnBest: true,
                  textOverflow: false,
                  clearInterval: 9999,
                  levelInterval: 500,
                  momentumTails: true,
                  histogramColor: 'rgba(4, 63, 128, 1)',
                  tailInsideBold: false,
                  tailInsideFont: '700 14px "Open Sans", sans-serif',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  tailInsideColor: 'rgba(255, 255, 255, 1)',
                  histogramEnabled: false,
                  clearTradersTimer: 9999,
                  tailInsidelevel1Bold: false,
                  tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel2Bold: false,
                  tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel3Bold: false,
                  tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel4Bold: false,
                  tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel5Bold: false,
                  tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel6Bold: false,
                  tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel7Bold: false,
                  tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel8Bold: false,
                  tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel9Bold: false,
                  tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
                  insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                  level1BackgroundColor: 'rgba(4, 63, 128, 1)',
                  level2BackgroundColor: 'rgba(3, 60, 119, 1)',
                  level3BackgroundColor: 'rgba(3, 59, 110, 1)',
                  level4BackgroundColor: 'rgba(2, 56, 100, 1)',
                  level5BackgroundColor: 'rgba(2, 54, 91, 1)',
                  level6BackgroundColor: 'rgba(2, 51, 82, 1)',
                  level7BackgroundColor: 'rgba(1, 48, 73, 1)',
                  level8BackgroundColor: 'rgba(1, 46, 63, 1)',
                  tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                  highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
                  tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
                  tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
                  tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
                  tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
                  tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
                  tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
                  tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
                  tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
                },
                title: 'C.ASK',
                width: 50,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 10,
                tableViewName: 'C.Ask',
              },
              {
                name: 'ask',
                type: 'histogram',
                index: 11,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  largeSize: 14,
                  textAlign: 'center',
                  totalColor: '#fff',
                  textOverflow: false,
                  clearInterval: 9999,
                  highlightLarge: false,
                  histogramColor: 'rgba(201, 59, 59, 0.4)',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(201, 59, 59, 0.3)',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
                },
                title: 'ASK',
                width: 88,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 11,
                tableViewName: 'Ask',
              },
              {
                name: 'askDelta',
                type: 'string',
                index: 12,
                style: {
                  color: '#ffffff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  minToVisible: 0,
                  textOverflow: false,
                  overlayOrders: true,
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(201, 59, 59, 0.2)',
                  highlightBackgroundColor: '#682a2d',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                },
                title: 'DELTA',
                width: 68,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 12,
                tableViewName: 'Ask Delta',
              },
              {
                name: 'totalBid',
                type: 'histogram',
                index: 13,
                style: {
                  color: 'rgba(235, 90, 90, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'left',
                  textOverflow: false,
                  histogramColor: 'rgba(201, 59, 59, 0.3)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  histogramOrientation: 'right',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'T.BID',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 13,
                tableViewName: 'T.Bid',
              },
              {
                name: 'totalAsk',
                type: 'histogram',
                index: 14,
                style: {
                  color: 'rgba(12,98,247, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'right',
                  textOverflow: false,
                  histogramColor: 'rgba(12,98,247, 0.3)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'T.ASK',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 14,
                tableViewName: 'T.Ask',
              },
            ],
            askDelta: {
              color: '#ffffff',
              textAlign: 'center',
              minToVisible: 0,
              overlayOrders: true,
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(201, 59, 59, 0.2)',
              highlightBackgroundColor: '#682a2d',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
            },
            bidDelta: {
              color: '#fff',
              textAlign: 'center',
              minToVisible: 0,
              buyOrderColor: '#fff',
              overlayOrders: true,
              backgroundColor: 'rgba(12,98,247,0.2)',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              highlightBackgroundColor: '#2b486e',
            },
            totalAsk: {
              color: 'rgba(12,98,247, 1)',
              textAlign: 'right',
              histogramColor: 'rgba(12,98,247, 0.3)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              histogramOrientation: 'left',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            totalBid: {
              color: 'rgba(235, 90, 90, 1)',
              textAlign: 'left',
              histogramColor: 'rgba(201, 59, 59, 0.3)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              histogramOrientation: 'right',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            buyOrders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(12,98,247, 0.5)',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            currentAsk: {
              color: 'rgba(12,98,247,1)',
              textAlign: 'center',
              clearOnBest: true,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              histogramColor: 'rgba(4, 63, 128, 1)',
              tailInsideBold: false,
              tailInsideFont: '700 14px "Open Sans", sans-serif',
              backgroundColor: 'transparent',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              histogramEnabled: false,
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              level1BackgroundColor: 'rgba(4, 63, 128, 1)',
              level2BackgroundColor: 'rgba(3, 60, 119, 1)',
              level3BackgroundColor: 'rgba(3, 59, 110, 1)',
              level4BackgroundColor: 'rgba(2, 56, 100, 1)',
              level5BackgroundColor: 'rgba(2, 54, 91, 1)',
              level6BackgroundColor: 'rgba(2, 51, 82, 1)',
              level7BackgroundColor: 'rgba(1, 48, 73, 1)',
              level8BackgroundColor: 'rgba(1, 46, 63, 1)',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
              tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
            },
            currentBid: {
              color: '#EB5A5A',
              textAlign: 'center',
              clearOnBest: true,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              tailInsideBold: false,
              tailInsideFont: '700 14px "Open Sans", sans-serif',
              backgroundColor: 'transparent',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              histogramEnabled: false,
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              level1BackgroundColor: 'rgba(128, 64, 64, 1)',
              level2BackgroundColor: 'rgba(112, 61, 63, 1)',
              level3BackgroundColor: 'rgba(96, 59, 62, 1)',
              level4BackgroundColor: 'rgba(80, 56, 60, 1)',
              level5BackgroundColor: 'rgba(64, 54, 59, 1)',
              level6BackgroundColor: 'rgba(48, 51, 58, 1)',
              level7BackgroundColor: 'rgba(32, 48, 57, 1)',
              level8BackgroundColor: 'rgba(16, 46, 55, 1)',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
              tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
            },
            sellOrders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
          },
          orderForm: {
            formData: {
              quantity: 10,
              stopLoss: {
                unit: 'ticks',
                stopLoss: false,
                unitItem: 10,
              },
              takeProfit: {
                unit: 'ticks',
                unitItem: 12,
                takeProfit: false,
              },
            },
            settings: {
              tif: {
                DAY: true,
                /**
                 * Property disabled until the feature is fully working.
                 * FOK: true,
                 */
                GTC: true,
                /**
                 * Property disabled until the feature is fully working.
                 * IOC: true,
                 */
                default: 'DAY',
              },
              formSettings: {
                roundPL: false,
                showPLInfo: true,
                showBracket: true,
                showOHLVInfo: true,
                showBuyButton: true,
                showSellButton: true,
                showCancelButton: true,
                showOrderConfirm: false,
                includeRealizedPL: false,
                showCancelConfirm: true,
                showFlattenButton: true,
                showIcebergButton: true,
                showLiquidateButton: true,
                showInstrumentChange: false,
              },
              amountButtons: [
                {
                  value: 1,
                },
                {
                  black: true,
                  value: 1,
                },
                {
                  value: 3,
                },
                {
                  value: 5,
                },
                {
                  value: 10,
                },
                {
                  value: 25,
                },
              ],
              icebergFontColor: '#fff',
              buyButtonsFontColor: '#D0D0D2',
              flatButtonsFontColor: '#D0D0D2',
              sellButtonsFontColor: '#D0D0D2',
              cancelButtonFontColor: '#D0D0D2',
              closePositionFontColor: '#D0D0D2',
              icebergBackgroundColor: '#51535A',
              buyButtonsBackgroundColor: '#0C62F7',
              flatButtonsBackgroundColor: '#51535A',
              sellButtonsBackgroundColor: '#C93B3B',
              cancelButtonBackgroundColor: '#51535A',
              closePositionBackgroundColor: '#51535A',
            },
            amountButtons: [
              {
                value: 1,
              },
              {
                black: true,
                value: 1,
              },
              {
                value: 3,
              },
              {
                value: 5,
              },
              {
                value: 10,
              },
              {
                value: 25,
              },
            ],
          },
          instrument: defaultInstrument1,
          priceMarkers: [],
          contextMenuState: {
            showHeaderPanel: true,
            showColumnHeaders: true,
          },
          componentInstanceId: 1699890660678,
        },
      },
      minHeight: 150,
      resizable: true,
      allowPopup: true,
      maximizable: true,
      minimizable: true,
    },
    {
      x: 1063,
      y: 36,
      id: '1690454474127_0.5829419327601297',
      order: 4,
      width: 715,
      height: 1133,
      visible: true,
      minWidth: 470,
      component: {
        name: 'dom',
        state: {
          columns: [
            {
              name: 'orders',
              type: 'string',
              index: 0,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              title: 'ORDERS',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 0,
              tableViewName: 'Orders',
            },
            {
              name: 'buyOrders',
              type: 'string',
              index: 1,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247, 0.5)',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              title: 'BUY ORDERS',
              width: 100,
              hidden: true,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 1,
              tableViewName: 'Buy Orders',
            },
            {
              name: 'sellOrders',
              type: 'string',
              index: 2,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              title: 'SELL ORDERS',
              width: 100,
              hidden: true,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 2,
              tableViewName: 'Sell Orders',
            },
            {
              name: 'volume',
              type: 'histogram',
              index: 3,
              style: {
                ltq: false,
                poc: true,
                VWAP: true,
                color: '#fff',
                sessions: {
                  eth: {
                    id: 63,
                    name: 'US ETH--RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    workingTimes: [
                      {
                        endDay: 1,
                        endTime: 34200000,
                        startDay: 0,
                        startTime: 64800000,
                      },
                      {
                        endDay: 3,
                        endTime: 34200000,
                        startDay: 2,
                        startTime: 58500000,
                      },
                      {
                        endDay: 5,
                        endTime: 34200000,
                        startDay: 4,
                        startTime: 58500000,
                      },
                      {
                        endDay: 4,
                        endTime: 34200000,
                        startDay: 3,
                        startTime: 58500000,
                      },
                      {
                        endDay: 2,
                        endTime: 34200000,
                        startDay: 1,
                        startTime: 58500000,
                      },
                    ],
                  },
                  rth: {
                    id: 64,
                    name: 'US RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    workingTimes: [
                      {
                        endDay: 4,
                        endTime: 58500000,
                        startDay: 4,
                        startTime: 34200000,
                      },
                      {
                        endDay: 5,
                        endTime: 58500000,
                        startDay: 5,
                        startTime: 34200000,
                      },
                      {
                        endDay: 2,
                        endTime: 58500000,
                        startDay: 2,
                        startTime: 34200000,
                      },
                      {
                        endDay: 3,
                        endTime: 58500000,
                        startDay: 3,
                        startTime: 34200000,
                      },
                      {
                        endDay: 1,
                        endTime: 58500000,
                        startDay: 1,
                        startTime: 34200000,
                      },
                    ],
                  },
                  histogramEnabled: true,
                  overlayLineColor: '#fff',
                },
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                valueArea: true,
                textOverflow: false,
                histogramColor: 'rgba(73, 187, 169, 0.5)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
                histogramOrientation: 'right',
                valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
                highlightBackgroundColor: '#9D0A0A',
                lastTradingBackgroundColor: '#fff',
                pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
              },
              title: 'VOLUME',
              width: 90,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 3,
              tableViewName: 'Volume',
            },
            {
              name: 'price',
              type: 'string',
              index: 4,
              style: {
                color: 'rgba(208, 208, 210, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                highlightColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                priceMarkerColor: 'black',
                tradedPriceColor: 'rgba(208, 208, 210, 1)',
                hoveredhighlightColor: '#fff',
                hoveredBackgroundColor: '#383A40',
                hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
                highlightBackgroundColor: '#383A40',
                priceMarkerBackgroundColor: '#FFDF6F',
                tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
                priceMarkerpriceMarkerColor: 'black',
                hoveredhighlightBackgroundColor: '#383A40',
                longPositionOpenBackgroundColor: '#0C62F7',
                shortPositionOpenBackgroundColor: '#C93B3BFF',
                hoveredtradedPriceBackgroundColor: '#383A40',
                priceMarkerpriceMarkerBackgroundColor: '#FFA500',
                hoveredlongPositionOpenBackgroundColor: '#383A40',
                hoveredshortPositionOpenBackgroundColor: '#383A40',
              },
              title: 'PRICE',
              width: 62,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 4,
              tableViewName: 'Price',
            },
            {
              name: 'delta',
              type: 'string',
              index: 5,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                askDeltaColor: '#ffffff',
                askDeltacolor: '#ffffff',
                bidDeltaColor: '#fff',
                bidDeltacolor: '#fff',
                titleUpperCase: true,
                askDeltaTextAlign: 'center',
                askDeltatextAlign: 'center',
                bidDeltaTextAlign: 'center',
                bidDeltatextAlign: 'center',
                askDeltaMinToVisible: 0,
                askDeltaminToVisible: 0,
                bidDeltaMinToVisible: 0,
                bidDeltaminToVisible: 0,
                askDeltaOverlayOrders: true,
                askDeltaoverlayOrders: true,
                bidDeltaBuyOrderColor: '#fff',
                bidDeltaOverlayOrders: true,
                bidDeltabuyOrderColor: '#fff',
                bidDeltaoverlayOrders: true,
                askDeltaSellOrderColor: '#fff',
                askDeltahighlightColor: '#ffffff',
                askDeltasellOrderColor: '#fff',
                bidDeltahighlightColor: '#fff',
                askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
                askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
                bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
                bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
                askDeltahighlightTextAlign: 'center',
                bidDeltahighlightTextAlign: 'center',
                bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                askDeltaHighlightBackgroundColor: '#682a2d',
                askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                askDeltahighlightBackgroundColor: '#682a2d',
                askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                bidDeltaHighlightBackgroundColor: '#2b486e',
                bidDeltahighlightBackgroundColor: '#2b486e',
              },
              title: 'DELTA',
              width: 58,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 5,
              tableViewName: 'Delta',
            },
            {
              name: 'bidDelta',
              type: 'string',
              index: 6,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                minToVisible: 0,
                textOverflow: false,
                buyOrderColor: '#fff',
                overlayOrders: true,
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247,0.2)',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                highlightBackgroundColor: '#2b486e',
              },
              title: 'DELTA',
              width: 68,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 6,
              tableViewName: 'Bid Delta',
            },
            {
              name: 'bid',
              type: 'histogram',
              index: 7,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                largeSize: 14,
                textAlign: 'center',
                totalColor: '#fff',
                textOverflow: false,
                clearInterval: 9999,
                highlightLarge: false,
                histogramColor: 'rgba(72,149,245,0,3)',
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247, 0.3)',
                histogramEnabled: true,
                histogramOrientation: 'left',
                highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
              },
              title: 'BID',
              width: 88,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 7,
              tableViewName: 'Bid',
            },
            {
              name: 'ltq',
              type: 'string',
              index: 8,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                histogramColor: 'rgba(56, 58, 64, 0.5)',
                titleUpperCase: true,
                backgroundColor: '#012B36',
                accumulateTrades: true,
                buyBackgroundColor: '#0C62F7',
                sellBackgroundColor: 'rgba(201, 59, 59, 1)',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'LTQ',
              width: 49,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 8,
              tableViewName: 'LTQ',
            },
            {
              name: 'currentBid',
              type: 'histogram',
              index: 9,
              style: {
                color: '#EB5A5A',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                clearOnBest: true,
                textOverflow: false,
                clearInterval: 9999,
                levelInterval: 500,
                momentumTails: true,
                histogramColor: 'rgba(201, 59, 59, 0.4)',
                tailInsideBold: false,
                tailInsideFont: '700 14px "Open Sans", sans-serif',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                tailInsideColor: 'rgba(255, 255, 255, 1)',
                histogramEnabled: false,
                clearTradersTimer: 9999,
                tailInsidelevel1Bold: false,
                tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel2Bold: false,
                tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel3Bold: false,
                tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel4Bold: false,
                tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel5Bold: false,
                tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel6Bold: false,
                tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel7Bold: false,
                tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel8Bold: false,
                tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel9Bold: false,
                tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
                insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                level1BackgroundColor: 'rgba(128, 64, 64, 1)',
                level2BackgroundColor: 'rgba(112, 61, 63, 1)',
                level3BackgroundColor: 'rgba(96, 59, 62, 1)',
                level4BackgroundColor: 'rgba(80, 56, 60, 1)',
                level5BackgroundColor: 'rgba(64, 54, 59, 1)',
                level6BackgroundColor: 'rgba(48, 51, 58, 1)',
                level7BackgroundColor: 'rgba(32, 48, 57, 1)',
                level8BackgroundColor: 'rgba(16, 46, 55, 1)',
                tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
                tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
                tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
                tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
                tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
                tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
                tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
                tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
                tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
              },
              title: 'C.BID',
              width: 50,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 9,
              tableViewName: 'C.Bid',
            },
            {
              name: 'currentAsk',
              type: 'histogram',
              index: 10,
              style: {
                color: 'rgba(12,98,247,1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                clearOnBest: true,
                textOverflow: false,
                clearInterval: 9999,
                levelInterval: 500,
                momentumTails: true,
                histogramColor: 'rgba(4, 63, 128, 1)',
                tailInsideBold: false,
                tailInsideFont: '700 14px "Open Sans", sans-serif',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                tailInsideColor: 'rgba(255, 255, 255, 1)',
                histogramEnabled: false,
                clearTradersTimer: 9999,
                tailInsidelevel1Bold: false,
                tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel2Bold: false,
                tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel3Bold: false,
                tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel4Bold: false,
                tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel5Bold: false,
                tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel6Bold: false,
                tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel7Bold: false,
                tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel8Bold: false,
                tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
                tailInsidelevel9Bold: false,
                tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
                insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                level1BackgroundColor: 'rgba(4, 63, 128, 1)',
                level2BackgroundColor: 'rgba(3, 60, 119, 1)',
                level3BackgroundColor: 'rgba(3, 59, 110, 1)',
                level4BackgroundColor: 'rgba(2, 56, 100, 1)',
                level5BackgroundColor: 'rgba(2, 54, 91, 1)',
                level6BackgroundColor: 'rgba(2, 51, 82, 1)',
                level7BackgroundColor: 'rgba(1, 48, 73, 1)',
                level8BackgroundColor: 'rgba(1, 46, 63, 1)',
                tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
                tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
                tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
                tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
                tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
                tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
                tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
                tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
                tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
              },
              title: 'C.ASK',
              width: 50,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 10,
              tableViewName: 'C.Ask',
            },
            {
              name: 'ask',
              type: 'histogram',
              index: 11,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                largeSize: 14,
                textAlign: 'center',
                totalColor: '#fff',
                textOverflow: false,
                clearInterval: 9999,
                highlightLarge: false,
                histogramColor: 'rgba(201, 59, 59, 0.4)',
                titleUpperCase: true,
                backgroundColor: 'rgba(201, 59, 59, 0.3)',
                histogramEnabled: true,
                histogramOrientation: 'left',
                highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
              },
              title: 'ASK',
              width: 88,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 11,
              tableViewName: 'Ask',
            },
            {
              name: 'askDelta',
              type: 'string',
              index: 12,
              style: {
                color: '#ffffff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                minToVisible: 0,
                textOverflow: false,
                overlayOrders: true,
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(201, 59, 59, 0.2)',
                highlightBackgroundColor: '#682a2d',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              },
              title: 'DELTA',
              width: 68,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 12,
              tableViewName: 'Ask Delta',
            },
            {
              name: 'totalBid',
              type: 'histogram',
              index: 13,
              style: {
                color: 'rgba(235, 90, 90, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                histogramColor: 'rgba(201, 59, 59, 0.3)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                histogramOrientation: 'right',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'T.BID',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 13,
              tableViewName: 'T.Bid',
            },
            {
              name: 'totalAsk',
              type: 'histogram',
              index: 14,
              style: {
                color: 'rgba(12,98,247, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                histogramColor: 'rgba(12,98,247, 0.3)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                histogramOrientation: 'left',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'T.ASK',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 14,
              tableViewName: 'T.Ask',
            },
          ],
          settings: {
            ask: {
              color: '#fff',
              largeSize: 14,
              textAlign: 'center',
              totalColor: '#fff',
              clearInterval: 9999,
              highlightLarge: false,
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              backgroundColor: 'rgba(201, 59, 59, 0.3)',
              histogramEnabled: true,
              histogramOrientation: 'left',
              highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
            },
            bid: {
              color: '#fff',
              largeSize: 14,
              textAlign: 'center',
              totalColor: '#fff',
              clearInterval: 9999,
              highlightLarge: false,
              histogramColor: 'rgba(72,149,245,0,3)',
              backgroundColor: 'rgba(12,98,247, 0.3)',
              histogramEnabled: true,
              histogramOrientation: 'left',
              highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
            },
            ltq: {
              color: '#fff',
              textAlign: 'center',
              histogramColor: 'rgba(56, 58, 64, 0.5)',
              backgroundColor: '#012B36',
              accumulateTrades: true,
              buyBackgroundColor: '#0C62F7',
              sellBackgroundColor: 'rgba(201, 59, 59, 1)',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            note: {
              color: '#fff',
              backgroundColor: 'transparent',
              addedOrdersColor: 'rgba(53, 104, 147, 1)',
              pulledOrdersColor: 'rgba(143, 60, 65, 1)',
            },
            delta: {
              askDeltaColor: '#ffffff',
              askDeltacolor: '#ffffff',
              bidDeltaColor: '#fff',
              bidDeltacolor: '#fff',
              askDeltaTextAlign: 'center',
              askDeltatextAlign: 'center',
              bidDeltaTextAlign: 'center',
              bidDeltatextAlign: 'center',
              askDeltaMinToVisible: 0,
              askDeltaminToVisible: 0,
              bidDeltaMinToVisible: 0,
              bidDeltaminToVisible: 0,
              askDeltaOverlayOrders: true,
              askDeltaoverlayOrders: true,
              bidDeltaBuyOrderColor: '#fff',
              bidDeltaOverlayOrders: true,
              bidDeltabuyOrderColor: '#fff',
              bidDeltaoverlayOrders: true,
              askDeltaSellOrderColor: '#fff',
              askDeltahighlightColor: '#ffffff',
              askDeltasellOrderColor: '#fff',
              bidDeltahighlightColor: '#fff',
              askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
              askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
              bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
              bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
              askDeltahighlightTextAlign: 'center',
              bidDeltahighlightTextAlign: 'center',
              bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              askDeltaHighlightBackgroundColor: '#682a2d',
              askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              askDeltahighlightBackgroundColor: '#682a2d',
              askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              bidDeltaHighlightBackgroundColor: '#2b486e',
              bidDeltahighlightBackgroundColor: '#2b486e',
            },
            price: {
              color: 'rgba(208, 208, 210, 1)',
              textAlign: 'center',
              highlightColor: '#fff',
              backgroundColor: 'transparent',
              priceMarkerColor: 'black',
              tradedPriceColor: 'rgba(208, 208, 210, 1)',
              hoveredhighlightColor: '#fff',
              hoveredBackgroundColor: '#383A40',
              hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
              highlightBackgroundColor: '#383A40',
              priceMarkerBackgroundColor: '#FFDF6F',
              tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
              priceMarkerpriceMarkerColor: 'black',
              hoveredhighlightBackgroundColor: '#383A40',
              longPositionOpenBackgroundColor: '#0C62F7',
              shortPositionOpenBackgroundColor: '#C93B3BFF',
              hoveredtradedPriceBackgroundColor: '#383A40',
              priceMarkerpriceMarkerBackgroundColor: '#FFA500',
              hoveredlongPositionOpenBackgroundColor: '#383A40',
              hoveredshortPositionOpenBackgroundColor: '#383A40',
            },
            common: {
              ask: true,
              bid: true,
              ltq: true,
              delta: false,
              notes: true,
              price: true,
              orders: false,
              volume: true,
              askDelta: true,
              bidDelta: true,
              fontSize: 14,
              totalAsk: false,
              totalBid: false,
              buyOrders: false,
              currentAsk: true,
              currentBid: true,
              fontFamily: '"Open Sans", sans-serif',
              fontWeight: '',
              sellOrders: false,
              generalColors: {
                gridLineColor: '#24262C',
                centerLineColor: '#A1A2A5',
                orderGridLineColor: 'rgba(88, 110, 117, 1)',
                enableOrderGridColor: true,
              },
            },
            orders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'transparent',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            volume: {
              ltq: false,
              poc: true,
              VWAP: true,
              color: '#fff',
              sessions: {
                eth: {
                  id: 63,
                  name: 'US ETH--RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  workingTimes: [
                    {
                      endDay: 1,
                      endTime: 34200000,
                      startDay: 0,
                      startTime: 64800000,
                    },
                    {
                      endDay: 3,
                      endTime: 34200000,
                      startDay: 2,
                      startTime: 58500000,
                    },
                    {
                      endDay: 5,
                      endTime: 34200000,
                      startDay: 4,
                      startTime: 58500000,
                    },
                    {
                      endDay: 4,
                      endTime: 34200000,
                      startDay: 3,
                      startTime: 58500000,
                    },
                    {
                      endDay: 2,
                      endTime: 34200000,
                      startDay: 1,
                      startTime: 58500000,
                    },
                  ],
                },
                rth: {
                  id: 64,
                  name: 'US RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  workingTimes: [
                    {
                      endDay: 4,
                      endTime: 58500000,
                      startDay: 4,
                      startTime: 34200000,
                    },
                    {
                      endDay: 5,
                      endTime: 58500000,
                      startDay: 5,
                      startTime: 34200000,
                    },
                    {
                      endDay: 2,
                      endTime: 58500000,
                      startDay: 2,
                      startTime: 34200000,
                    },
                    {
                      endDay: 3,
                      endTime: 58500000,
                      startDay: 3,
                      startTime: 34200000,
                    },
                    {
                      endDay: 1,
                      endTime: 58500000,
                      startDay: 1,
                      startTime: 34200000,
                    },
                  ],
                },
                histogramEnabled: true,
                overlayLineColor: '#fff',
              },
              textAlign: 'right',
              valueArea: true,
              histogramColor: 'rgba(73, 187, 169, 0.5)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
              histogramOrientation: 'right',
              valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
              highlightBackgroundColor: '#9D0A0A',
              lastTradingBackgroundColor: '#fff',
              pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
            },
            general: {
              recenter: true,
              intervals: {
                momentumTails: true,
                printOutlines: false,
                updateInterval: 100,
                clearTradersTimer: 9999,
                orderQuantityStep: 7,
                momentumIntervalMs: 500,
                resetDeltaInterval: '5000',
                scrollWheelSensitivity: 1,
              },
              commonView: {
                autoCenter: true,
                centerLine: true,
                autoCenterTicks: 20,
              },
              marketDepth: {
                marketDepth: 40,
                bidAskDeltaDepth: 9,
                bidAskDeltaFilter: 0,
              },
              digitsToHide: 4,
              hideFromLeft: false,
              hideFromRight: false,
              hideAccountName: false,
              clearTotalPrints: false,
              clearInsidePrints: false,
              currentTotalAllWindows: false,
              currentTradesAllWindows: false,
              recenterTotalAllWindows: false,
            },
            hotkeys: {
              oco: null,
              hitBid: null,
              flatten: null,
              joinAsk: null,
              joinBid: null,
              buyMarket: null,
              liftOffer: null,
              quantity1: null,
              quantity2: null,
              quantity3: null,
              quantity4: null,
              quantity5: null,
              autoCenter: null,
              sellMarket: null,
              clearAlerts: null,
              limitsToPrice: null,
              stopsToPrice: null,
              quantityToPos: null,
              clearAllTotals: null,
              cancelAllOrders: null,
              clearTotalPrintsUp: null,
              clearVolumeProfile: null,
              autoCenterAllWindows: null,
              clearAlertsAllWindow: null,
              clearAllPriceMarkers: null,
              clearInsidePrintsUp: null,
              clearTotalPrintsDown: null,
              clearInsidePrintsDown: null,
              clearInsidePrintsAllWindows: null,
              clearTotalPrintsUpAllWindows: null,
              clearInsidePrintsUpAllWindows: null,
              clearTotalPrintsDownAllWindows: null,
              clearInsidePrintsDownAllWindows: null,
            },
            trading: {
              tif: {
                DAY: true,
                /**
                 * Property disabled until the feature is fully working.
                 * FOK: true,
                 */
                GTC: true,
                /**
                 * Property disabled until the feature is fully working.
                 * IOC: true,
                 */
                default: 'DAY',
              },
              trading: {
                split: false,
                overlayOrders: true,
                showOrderConfirm: false,
                showCancelConfirm: false,
              },
              formData: {
                quantity: 1,
              },
              orderArea: {
                roundPL: false,
                settings: {
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                },
                showPLInfo: true,
                showBracket: null,
                showOHLVInfo: true,
                includeRealizedPL: false,
                showInstrumentChange: false,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
              },
              amountButtons: [
                {
                  value: 1,
                },
                {
                  black: true,
                  value: 1,
                },
                {
                  value: 3,
                },
                {
                  value: 5,
                },
                {
                  value: 10,
                },
                {
                  value: 25,
                },
              ],
            },
            _columns: [
              {
                name: 'orders',
                type: 'string',
                index: 0,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
                title: 'ORDERS',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 0,
                tableViewName: 'Orders',
              },
              {
                name: 'buyOrders',
                type: 'string',
                index: 1,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247, 0.5)',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
                title: 'BUY ORDERS',
                width: 100,
                hidden: true,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 1,
                tableViewName: 'Buy Orders',
              },
              {
                name: 'sellOrders',
                type: 'string',
                index: 2,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
                title: 'SELL ORDERS',
                width: 100,
                hidden: true,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 2,
                tableViewName: 'Sell Orders',
              },
              {
                name: 'volume',
                type: 'histogram',
                index: 3,
                style: {
                  ltq: false,
                  poc: true,
                  VWAP: true,
                  color: '#fff',
                  sessions: {
                    eth: {
                      id: 63,
                      name: 'US ETH--RTH',
                      exchange: 'CME',
                      timezoneId: 'America/New_York',
                      workingTimes: [
                        {
                          endDay: 1,
                          endTime: 34200000,
                          startDay: 0,
                          startTime: 64800000,
                        },
                        {
                          endDay: 3,
                          endTime: 34200000,
                          startDay: 2,
                          startTime: 58500000,
                        },
                        {
                          endDay: 5,
                          endTime: 34200000,
                          startDay: 4,
                          startTime: 58500000,
                        },
                        {
                          endDay: 4,
                          endTime: 34200000,
                          startDay: 3,
                          startTime: 58500000,
                        },
                        {
                          endDay: 2,
                          endTime: 34200000,
                          startDay: 1,
                          startTime: 58500000,
                        },
                      ],
                    },
                    rth: {
                      id: 64,
                      name: 'US RTH',
                      exchange: 'CME',
                      timezoneId: 'America/New_York',
                      workingTimes: [
                        {
                          endDay: 4,
                          endTime: 58500000,
                          startDay: 4,
                          startTime: 34200000,
                        },
                        {
                          endDay: 5,
                          endTime: 58500000,
                          startDay: 5,
                          startTime: 34200000,
                        },
                        {
                          endDay: 2,
                          endTime: 58500000,
                          startDay: 2,
                          startTime: 34200000,
                        },
                        {
                          endDay: 3,
                          endTime: 58500000,
                          startDay: 3,
                          startTime: 34200000,
                        },
                        {
                          endDay: 1,
                          endTime: 58500000,
                          startDay: 1,
                          startTime: 34200000,
                        },
                      ],
                    },
                    histogramEnabled: true,
                    overlayLineColor: '#fff',
                  },
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'right',
                  valueArea: true,
                  textOverflow: false,
                  histogramColor: 'rgba(73, 187, 169, 0.5)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
                  histogramOrientation: 'right',
                  valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
                  highlightBackgroundColor: '#9D0A0A',
                  lastTradingBackgroundColor: '#fff',
                  pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
                },
                title: 'VOLUME',
                width: 90,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 3,
                tableViewName: 'Volume',
              },
              {
                name: 'price',
                type: 'string',
                index: 4,
                style: {
                  color: 'rgba(208, 208, 210, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  textOverflow: false,
                  highlightColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  priceMarkerColor: 'black',
                  tradedPriceColor: 'rgba(208, 208, 210, 1)',
                  hoveredhighlightColor: '#fff',
                  hoveredBackgroundColor: '#383A40',
                  hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
                  highlightBackgroundColor: '#383A40',
                  priceMarkerBackgroundColor: '#FFDF6F',
                  tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
                  priceMarkerpriceMarkerColor: 'black',
                  hoveredhighlightBackgroundColor: '#383A40',
                  longPositionOpenBackgroundColor: '#0C62F7',
                  shortPositionOpenBackgroundColor: '#C93B3BFF',
                  hoveredtradedPriceBackgroundColor: '#383A40',
                  priceMarkerpriceMarkerBackgroundColor: '#FFA500',
                  hoveredlongPositionOpenBackgroundColor: '#383A40',
                  hoveredshortPositionOpenBackgroundColor: '#383A40',
                },
                title: 'PRICE',
                width: 62,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 4,
                tableViewName: 'Price',
              },
              {
                name: 'delta',
                type: 'string',
                index: 5,
                style: {
                  color: '#D0D0D2',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'left',
                  textOverflow: false,
                  askDeltaColor: '#ffffff',
                  askDeltacolor: '#ffffff',
                  bidDeltaColor: '#fff',
                  bidDeltacolor: '#fff',
                  titleUpperCase: true,
                  askDeltaTextAlign: 'center',
                  askDeltatextAlign: 'center',
                  bidDeltaTextAlign: 'center',
                  bidDeltatextAlign: 'center',
                  askDeltaMinToVisible: 0,
                  askDeltaminToVisible: 0,
                  bidDeltaMinToVisible: 0,
                  bidDeltaminToVisible: 0,
                  askDeltaOverlayOrders: true,
                  askDeltaoverlayOrders: true,
                  bidDeltaBuyOrderColor: '#fff',
                  bidDeltaOverlayOrders: true,
                  bidDeltabuyOrderColor: '#fff',
                  bidDeltaoverlayOrders: true,
                  askDeltaSellOrderColor: '#fff',
                  askDeltahighlightColor: '#ffffff',
                  askDeltasellOrderColor: '#fff',
                  bidDeltahighlightColor: '#fff',
                  askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
                  askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
                  bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
                  bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
                  askDeltahighlightTextAlign: 'center',
                  bidDeltahighlightTextAlign: 'center',
                  bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  askDeltaHighlightBackgroundColor: '#682a2d',
                  askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  askDeltahighlightBackgroundColor: '#682a2d',
                  askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  bidDeltaHighlightBackgroundColor: '#2b486e',
                  bidDeltahighlightBackgroundColor: '#2b486e',
                },
                title: 'DELTA',
                width: 58,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 5,
                tableViewName: 'Delta',
              },
              {
                name: 'bidDelta',
                type: 'string',
                index: 6,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  minToVisible: 0,
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  overlayOrders: true,
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247,0.2)',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  highlightBackgroundColor: '#2b486e',
                },
                title: 'DELTA',
                width: 68,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 6,
                tableViewName: 'Bid Delta',
              },
              {
                name: 'bid',
                type: 'histogram',
                index: 7,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  largeSize: 14,
                  textAlign: 'center',
                  totalColor: '#fff',
                  textOverflow: false,
                  clearInterval: 9999,
                  highlightLarge: false,
                  histogramColor: 'rgba(72,149,245,0,3)',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247, 0.3)',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
                },
                title: 'BID',
                width: 88,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 7,
                tableViewName: 'Bid',
              },
              {
                name: 'ltq',
                type: 'string',
                index: 8,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  textOverflow: false,
                  histogramColor: 'rgba(56, 58, 64, 0.5)',
                  titleUpperCase: true,
                  backgroundColor: '#012B36',
                  accumulateTrades: true,
                  buyBackgroundColor: '#0C62F7',
                  sellBackgroundColor: 'rgba(201, 59, 59, 1)',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'LTQ',
                width: 49,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 8,
                tableViewName: 'LTQ',
              },
              {
                name: 'currentBid',
                type: 'histogram',
                index: 9,
                style: {
                  color: '#EB5A5A',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  clearOnBest: true,
                  textOverflow: false,
                  clearInterval: 9999,
                  levelInterval: 500,
                  momentumTails: true,
                  histogramColor: 'rgba(201, 59, 59, 0.4)',
                  tailInsideBold: false,
                  tailInsideFont: '700 14px "Open Sans", sans-serif',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  tailInsideColor: 'rgba(255, 255, 255, 1)',
                  histogramEnabled: false,
                  clearTradersTimer: 9999,
                  tailInsidelevel1Bold: false,
                  tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel2Bold: false,
                  tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel3Bold: false,
                  tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel4Bold: false,
                  tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel5Bold: false,
                  tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel6Bold: false,
                  tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel7Bold: false,
                  tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel8Bold: false,
                  tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel9Bold: false,
                  tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
                  insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                  level1BackgroundColor: 'rgba(128, 64, 64, 1)',
                  level2BackgroundColor: 'rgba(112, 61, 63, 1)',
                  level3BackgroundColor: 'rgba(96, 59, 62, 1)',
                  level4BackgroundColor: 'rgba(80, 56, 60, 1)',
                  level5BackgroundColor: 'rgba(64, 54, 59, 1)',
                  level6BackgroundColor: 'rgba(48, 51, 58, 1)',
                  level7BackgroundColor: 'rgba(32, 48, 57, 1)',
                  level8BackgroundColor: 'rgba(16, 46, 55, 1)',
                  tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                  highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
                  tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
                  tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
                  tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
                  tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
                  tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
                  tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
                  tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
                  tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
                },
                title: 'C.BID',
                width: 50,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 9,
                tableViewName: 'C.Bid',
              },
              {
                name: 'currentAsk',
                type: 'histogram',
                index: 10,
                style: {
                  color: 'rgba(12,98,247,1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  clearOnBest: true,
                  textOverflow: false,
                  clearInterval: 9999,
                  levelInterval: 500,
                  momentumTails: true,
                  histogramColor: 'rgba(4, 63, 128, 1)',
                  tailInsideBold: false,
                  tailInsideFont: '700 14px "Open Sans", sans-serif',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  tailInsideColor: 'rgba(255, 255, 255, 1)',
                  histogramEnabled: false,
                  clearTradersTimer: 9999,
                  tailInsidelevel1Bold: false,
                  tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel2Bold: false,
                  tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel3Bold: false,
                  tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel4Bold: false,
                  tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel5Bold: false,
                  tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel6Bold: false,
                  tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel7Bold: false,
                  tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel8Bold: false,
                  tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
                  tailInsidelevel9Bold: false,
                  tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
                  insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                  level1BackgroundColor: 'rgba(4, 63, 128, 1)',
                  level2BackgroundColor: 'rgba(3, 60, 119, 1)',
                  level3BackgroundColor: 'rgba(3, 59, 110, 1)',
                  level4BackgroundColor: 'rgba(2, 56, 100, 1)',
                  level5BackgroundColor: 'rgba(2, 54, 91, 1)',
                  level6BackgroundColor: 'rgba(2, 51, 82, 1)',
                  level7BackgroundColor: 'rgba(1, 48, 73, 1)',
                  level8BackgroundColor: 'rgba(1, 46, 63, 1)',
                  tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                  highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
                  tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
                  tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
                  tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
                  tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
                  tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
                  tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
                  tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
                  tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
                },
                title: 'C.ASK',
                width: 50,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 10,
                tableViewName: 'C.Ask',
              },
              {
                name: 'ask',
                type: 'histogram',
                index: 11,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  largeSize: 14,
                  textAlign: 'center',
                  totalColor: '#fff',
                  textOverflow: false,
                  clearInterval: 9999,
                  highlightLarge: false,
                  histogramColor: 'rgba(201, 59, 59, 0.4)',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(201, 59, 59, 0.3)',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
                },
                title: 'ASK',
                width: 88,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 11,
                tableViewName: 'Ask',
              },
              {
                name: 'askDelta',
                type: 'string',
                index: 12,
                style: {
                  color: '#ffffff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  minToVisible: 0,
                  textOverflow: false,
                  overlayOrders: true,
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(201, 59, 59, 0.2)',
                  highlightBackgroundColor: '#682a2d',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                },
                title: 'DELTA',
                width: 68,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 12,
                tableViewName: 'Ask Delta',
              },
              {
                name: 'totalBid',
                type: 'histogram',
                index: 13,
                style: {
                  color: 'rgba(235, 90, 90, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'left',
                  textOverflow: false,
                  histogramColor: 'rgba(201, 59, 59, 0.3)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  histogramOrientation: 'right',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'T.BID',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 13,
                tableViewName: 'T.Bid',
              },
              {
                name: 'totalAsk',
                type: 'histogram',
                index: 14,
                style: {
                  color: 'rgba(12,98,247, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'right',
                  textOverflow: false,
                  histogramColor: 'rgba(12,98,247, 0.3)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'T.ASK',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 14,
                tableViewName: 'T.Ask',
              },
            ],
            askDelta: {
              color: '#ffffff',
              textAlign: 'center',
              minToVisible: 0,
              overlayOrders: true,
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(201, 59, 59, 0.2)',
              highlightBackgroundColor: '#682a2d',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
            },
            bidDelta: {
              color: '#fff',
              textAlign: 'center',
              minToVisible: 0,
              buyOrderColor: '#fff',
              overlayOrders: true,
              backgroundColor: 'rgba(12,98,247,0.2)',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              highlightBackgroundColor: '#2b486e',
            },
            totalAsk: {
              color: 'rgba(12,98,247, 1)',
              textAlign: 'right',
              histogramColor: 'rgba(12,98,247, 0.3)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              histogramOrientation: 'left',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            totalBid: {
              color: 'rgba(235, 90, 90, 1)',
              textAlign: 'left',
              histogramColor: 'rgba(201, 59, 59, 0.3)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              histogramOrientation: 'right',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            buyOrders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(12,98,247, 0.5)',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            currentAsk: {
              color: 'rgba(12,98,247,1)',
              textAlign: 'center',
              clearOnBest: true,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              histogramColor: 'rgba(4, 63, 128, 1)',
              tailInsideBold: false,
              tailInsideFont: '700 14px "Open Sans", sans-serif',
              backgroundColor: 'transparent',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              histogramEnabled: false,
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              level1BackgroundColor: 'rgba(4, 63, 128, 1)',
              level2BackgroundColor: 'rgba(3, 60, 119, 1)',
              level3BackgroundColor: 'rgba(3, 59, 110, 1)',
              level4BackgroundColor: 'rgba(2, 56, 100, 1)',
              level5BackgroundColor: 'rgba(2, 54, 91, 1)',
              level6BackgroundColor: 'rgba(2, 51, 82, 1)',
              level7BackgroundColor: 'rgba(1, 48, 73, 1)',
              level8BackgroundColor: 'rgba(1, 46, 63, 1)',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
              tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
            },
            currentBid: {
              color: '#EB5A5A',
              textAlign: 'center',
              clearOnBest: true,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              tailInsideBold: false,
              tailInsideFont: '700 14px "Open Sans", sans-serif',
              backgroundColor: 'transparent',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              histogramEnabled: false,
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 14px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 14px "Open Sans", sans-serif',
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              level1BackgroundColor: 'rgba(128, 64, 64, 1)',
              level2BackgroundColor: 'rgba(112, 61, 63, 1)',
              level3BackgroundColor: 'rgba(96, 59, 62, 1)',
              level4BackgroundColor: 'rgba(80, 56, 60, 1)',
              level5BackgroundColor: 'rgba(64, 54, 59, 1)',
              level6BackgroundColor: 'rgba(48, 51, 58, 1)',
              level7BackgroundColor: 'rgba(32, 48, 57, 1)',
              level8BackgroundColor: 'rgba(16, 46, 55, 1)',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
              tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
            },
            sellOrders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
          },
          orderForm: {
            formData: {
              quantity: 1,
              stopLoss: {
                type: 'StopMarket',
                unit: 'ticks',
                ticks: 10,
                amount: 1,
                stopLoss: true,
                unitItem: 10,
              },
              takeProfit: {
                unit: 'ticks',
                ticks: 16,
                unitItem: 16,
                takeProfit: true,
              },
            },
            settings: {
              tif: {
                DAY: true,
                /**
                 * Property disabled until the feature is fully working.
                 * FOK: true,
                 */
                GTC: true,
                /**
                 * Property disabled until the feature is fully working.
                 * IOC: true,
                 */
                default: 'DAY',
              },
              formSettings: {
                roundPL: false,
                showPLInfo: true,
                showOHLVInfo: true,
                showBuyButton: true,
                showSellButton: true,
                showCancelButton: true,
                showOrderConfirm: false,
                includeRealizedPL: false,
                showCancelConfirm: false,
                showFlattenButton: true,
                showIcebergButton: true,
                showLiquidateButton: true,
                showInstrumentChange: false,
              },
              amountButtons: [
                {
                  value: 1,
                },
                {
                  black: true,
                  value: 1,
                },
                {
                  value: 3,
                },
                {
                  value: 5,
                },
                {
                  value: 10,
                },
                {
                  value: 25,
                },
              ],
              icebergFontColor: '#fff',
              buyButtonsFontColor: '#D0D0D2',
              flatButtonsFontColor: '#D0D0D2',
              sellButtonsFontColor: '#D0D0D2',
              cancelButtonFontColor: '#D0D0D2',
              closePositionFontColor: '#D0D0D2',
              icebergBackgroundColor: '#51535A',
              buyButtonsBackgroundColor: '#0C62F7',
              flatButtonsBackgroundColor: '#51535A',
              sellButtonsBackgroundColor: '#C93B3B',
              cancelButtonBackgroundColor: '#51535A',
              closePositionBackgroundColor: '#51535A',
            },
            amountButtons: [
              {
                value: 1,
              },
              {
                black: true,
                value: 1,
              },
              {
                value: 3,
              },
              {
                value: 5,
              },
              {
                value: 10,
              },
              {
                value: 25,
              },
            ],
          },
          instrument: defaultInstrument2,
          priceMarkers: [],
          contextMenuState: {
            showHeaderPanel: true,
            showColumnHeaders: true,
          },
          componentInstanceId: 1690454474103,
        },
      },
      minHeight: 150,
      resizable: true,
      allowPopup: true,
      maximizable: true,
      minimizable: true,
    },
  ],
  isSelected: true,
  isOnStartUp: true,
};
