import { Component, Directive } from '@angular/core';

import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'lib-repeat-group',
  templateUrl: './repeat-group.component.html',
  styleUrls: ['./repeat-group.component.scss'],
})
export class RepeatGroupComponent extends FieldArrayType {
  constructor() {
    super();
  }
}
