export function addInstrumentId(item: any) {
  const symbol = item.tradingSymbol == null ? item.symbol : item.tradingSymbol;
  const exchange =
    item.tradingExchange == null ? item.tradingExchange : item.exchange;
  item.id = `${symbol}.${exchange}`;
  return item;
}

export function getTimestamp(obj: any): number {
  return obj.ssboe * 1000 + obj.usecs / 1000;
}

/**
 * Convert a date string in the format 'YYYYMMDD' to a Date object in the user's local time.
 * The date is set to 15:00:00 CT (CME Daily Settlement Time for Equities)
 * @param dateString
 */
export function getSettlementDateTime(dateString: string): Date {
  // Parse the date string and set time to 15:00:00 CT (CME Daily Settlement Time for Equities)
  // see https://cmegroupclientsite.atlassian.net/wiki/spaces/EPICSANDBOX/pages/46119151/Daily+Settlement+Time+Details
  const settlementTimezone: string = 'America/Chicago'; // CT
  // @todo Import `moment` via npm and add types
  const ctTime = (window as any).moment
    .tz(dateString, 'YYYYMMDD', settlementTimezone)
    .set({ hour: 15, minute: 0, second: 0 });

  // Convert to user's local time
  const localTime = ctTime.local();

  // Return a new Date object in the user's local time
  return localTime.toDate();
}
