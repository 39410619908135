import { Injectable } from '@angular/core';

import { HttpRepository, IBaseItem, Repository } from 'communication';
import { IWorldTimeInfo } from 'timezones-clock';

export interface IWorldTimeInfoResponse extends IWorldTimeInfo, IBaseItem {}

@Injectable()
export class TimezoneRepository
  extends HttpRepository<IWorldTimeInfoResponse>
  implements Repository<IWorldTimeInfoResponse>
{
  protected get suffix(): string {
    return 'Timezone';
  }

  protected get _baseUrl(): string {
    return this._communicationConfig.rithmic.http.url;
  }

  onInit() {
    super.onInit();
    this.cacheEnabled = true;
  }
}
