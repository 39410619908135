import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-communication',
  template: ` <p>communication works!</p> `,
  styles: [],
})
export class CommunicationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
