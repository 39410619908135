import { Component, OnInit } from '@angular/core';

import { AppVersion } from '../../../../../src/app/app.config';
import { isElectron } from '../../../../../src/app/is-electron';
import { VersionDetails } from './version-details';

/**
 * @description
 * This component is used to display the version information of the application.
 * It includes the web app version, desktop app version, and versions of some dependencies.
 */
@Component({
  selector: 'version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss'],
})
export class VersionInfoComponent implements OnInit {
  versionDetails: VersionDetails;

  constructor(public appVersion: AppVersion) {
    this.versionDetails = new VersionDetails(appVersion);
  }

  ngOnInit(): void {
    if (isElectron()) {
      this.requestElectronVersionInfo();
    }
  }

  private requestElectronVersionInfo(): void {
    // Available on Electron only
    const ipcBridge: any = (window as any).ipcBridge;

    if (!ipcBridge) {
      return;
    }

    ipcBridge
      .requestVersion()
      .then((res: VersionDetails) => {
        this.versionDetails.desktopAppVersion = res.desktopAppVersion;
        this.versionDetails.dependencyVersions = res.dependencyVersions;
      })
      .catch((err) => {
        console.warn('Failed to fetch Desktop app version', err);
      });
  }
}
